import { WorkflowRun } from '@/main/generated/api';
import { useDebugRunStatusQuery } from '@/main/queries/workflows/debug';
import { useGetWorkflowRunCreditUsageQuery } from '@/main/queries/workflows/runs/runs';
import {
  Badge,
  Box,
  Divider,
  Horizontal,
  Text,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { WorkflowRunDetailsOverview } from './WorkflowRunDetailsOverview';
import {
  WorkflowCreditInformation,
  WorkflowRunDuration,
  getRunArtifact,
  getWorkflowRunIcon,
  getWorkflowRunItemDetails,
} from './util';

interface WorkflowListItemProps {
  run: WorkflowRun;
  isPanelExpanded: boolean;
  isUpcoming?: boolean;
  isSelected?: boolean;
}

export const WorkflowRunListItem = ({
  run,
  isPanelExpanded,
  isUpcoming = false,
  isSelected = false,
}: WorkflowListItemProps) => {
  const theme = useMarkovTheme();

  const { data: creditDetails } = useGetWorkflowRunCreditUsageQuery(
    run.workflowId,
    run.workflowRunId,
    run.status,
  );
  const { data: runStatus } = useDebugRunStatusQuery(run.workflowId, run.workflowRunId);

  const { status } = run;
  const { title } = getWorkflowRunItemDetails(run);
  const icon = getWorkflowRunIcon(runStatus?.wflRunStatus ?? status, theme);

  if (!isPanelExpanded) {
    return (
      <Vertical
        px="md"
        py="lg"
        className={run.workflowRunId}
        sx={theme => ({
          border: isSelected
            ? `1px solid ${theme.colors.blue[2]}`
            : `1px solid ${theme.colors.gray[2]}`,
          borderRadius: theme.radius.md,
        })}
      >
        <Horizontal w="100%" align="start" spacing="sm" noWrap>
          <Box pt={2}>{icon}</Box>
          <Horizontal noWrap spacing="sm">
            <Text variant="subTitle02" color="gray.9">
              {title}
            </Text>
            {run.debugRun ? (
              <Badge variant="light" bg="yellow.0" color="yellow.7" size="small" px="sm">
                <Text variant="small03" color="yellow.7" tt="capitalize">
                  Sample run
                </Text>
              </Badge>
            ) : null}
          </Horizontal>
        </Horizontal>
      </Vertical>
    );
  }

  return (
    <Vertical
      px="md"
      py="lg"
      className={run.workflowRunId}
      sx={theme => ({
        border: isSelected
          ? `1px solid ${theme.colors.blue[2]}`
          : `1px solid ${theme.colors.gray[2]}`,
        borderRadius: theme.radius.md,
      })}
    >
      <Horizontal w="100%" align="start" spacing="sm" noWrap>
        <Box pt={2}>{icon}</Box>
        <WorkflowRunDetailsOverview
          run={run}
          withExecutionTypeLabel={!isUpcoming}
          activeFlowBadge
        />
      </Horizontal>
      <Divider size={1} color={isSelected ? theme.colors.blue[2] : theme.colors.gray[2]} />
      <Horizontal position="apart" noWrap>
        <Horizontal noWrap spacing="sm">
          <WorkflowRunDuration duration={run.totalDuration ?? '--'} />
          <WorkflowCreditInformation credits={creditDetails?.totalCreditsConsumed} />
        </Horizontal>
        {getRunArtifact(run, runStatus?.wflRunStatus)}
      </Horizontal>
    </Vertical>
  );
};
