import { useMediaQuery } from '@mantine/hooks';
import { IconApps } from '@tabler/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation, useNavigate } from 'react-router-dom';
import { ampli } from '@/shared/ampli';
import {
  IconBlurOn,
  IconDashboardNew,
  IconDeviceHub,
  IconDocs,
  IconGroupAdd,
  IconInsights,
  IconModelApp,
  IconMore,
  IconPhotoFilter,
  IconSnippets,
  IconStorageNew,
  IconWhatsNew,
  WorkflowGraph,
} from '@/shared/design-system/Icons';
import { ActionIcon, Box, Navbar, Overlay, useMarkovTheme } from '@/shared/design-system/v2';
import { WHATS_NEW_FLOW, startFlow } from '@/shared/initializers/userflow';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { useLayoutContext } from '../../../contexts/layout/LayoutContext';
import { MARKOVML_DOCS } from '../../../developer-docs/links';
import { AppRoutes, getRoute } from '../../../router/constants';
import { useAbsoluteRoutes } from '../../../router/hooks';
import { openWorkspaceInviteModal } from '../../user-invitation/useOpenInviteModal';
import { useSidebarStyles } from './Sidebar.style';
import { SideBarOptions, SidebarLinks } from './SidebarLinks';
import { SidebarMenu } from './SidebarMenu';

const NAV_BAR_EXPANDED_SIZE = 208;
export const NAV_BAR_COLLAPSED_SIZE = 62;

export const getActiveBar = (pathname: string) => {
  if (pathname.includes(getRoute(AppRoutes.WORKFLOWS_TEMPLATES))) {
    return SideBarOptions.WORKFLOWS_TEMPLATES;
  }
  if (pathname.includes(getRoute(AppRoutes.WORKFLOWS_LIST))) {
    return SideBarOptions.WORKFLOWS_LIST;
  }
  if (pathname.includes(getRoute(AppRoutes.WORKFLOW_APPS_LIST))) {
    return SideBarOptions.WORKFLOWS_APPS;
  }
  if (pathname.includes(getRoute(AppRoutes.WORKFLOWS_ARCHIVED))) {
    return SideBarOptions.WORKFLOWS_ARCHIVED;
  }
  if (pathname.includes(getRoute(AppRoutes.WORKFLOWS_HOME))) {
    return SideBarOptions.HOME;
  }
  if (pathname.includes(getRoute(AppRoutes.APP_BUILDER))) {
    return SideBarOptions.APP_BUILDER;
  }
  if (pathname.includes(getRoute(AppRoutes.AI_APPS))) {
    return SideBarOptions.AI_APPS;
  }
  if (pathname.includes(getRoute(AppRoutes.PROJECTS))) {
    return SideBarOptions.PROJECTS;
  }
  if (pathname.includes(getRoute(AppRoutes.EXPERIMENTS))) {
    return SideBarOptions.EXPERIMENTS;
  }
  if (pathname.includes(getRoute(AppRoutes.EVALUATIONS))) {
    return SideBarOptions.EVALUATION;
  }
  // TODO: Clean up once AppRoutes.Recordings is cleaned from app
  if (pathname.includes(getRoute(AppRoutes.RECORDINGS))) {
    return SideBarOptions.EVALUATION;
  }
  if (pathname.includes(getRoute(AppRoutes.MODELS))) {
    return SideBarOptions.MODELS;
  }
  if (pathname.includes(getRoute(AppRoutes.MODEL_APPS))) {
    return SideBarOptions.MODEL_APPS;
  }
  if (pathname.includes(getRoute(AppRoutes.APP_STORE))) {
    return SideBarOptions.APP_STORE;
  }
  if (pathname.includes(getRoute(AppRoutes.DATASETS))) {
    return SideBarOptions.DATASETS;
  }
  if (pathname.includes(getRoute(AppRoutes.DATA_LABELING))) {
    return SideBarOptions.DATA_LABELING;
  }
  if (pathname.includes(getRoute(AppRoutes.SNIPPETS))) {
    return SideBarOptions.SNIPPETS;
  }
  if (pathname.includes(getRoute(AppRoutes.SETTINGS))) {
    return SideBarOptions.SETTINGS;
  }
  return SideBarOptions.HOME;
};

export const SidebarV2 = (): JSX.Element => {
  const theme = useMarkovTheme();
  const { featureWhatsNew, featureWorkflows, featureAppStore } = useFlags();
  const { sidebarExpanded, setSidebarExpanded, setSidebarMouseEnter, sidebarMouseEnter } =
    useLayoutContext();

  const isMobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const { classes } = useSidebarStyles();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { workspaceId } = useAppMetadata();

  const {
    getWorkspaceRoute,
    getSnippetsRoute,
    getWorkflowsHomeRoute,
    getExperimentsRoute,
    getEvaluationsRoute,
    getModelsRoute,
    getModelAppsRoute,
    getProjectsRoute,
    getDatasetsRoute,
    getAppStoreRoute,
  } = useAbsoluteRoutes();

  const navigateTo = (link: string) => {
    if (isMobileScreen) {
      setSidebarExpanded(false);
    }

    navigate(link);
  };

  const profileNavBar = {
    group1: {
      properties: {
        showBorderBottom: false,
        grow: true,
      },
      elements: [
        {
          id: SideBarOptions.HOME,
          path: AppRoutes.BASE_ROUTE,
          label: 'Home',
          onClick: () => {
            ampli.navbarDashboardClicked();
            navigateTo(getWorkspaceRoute());
          },
          icon: <IconDashboardNew />,
        },
      ],
    },
    group2: {
      properties: {
        grow: false,
      },
      elements: [
        ...(featureWhatsNew
          ? [
              {
                id: SideBarOptions.WHATS_NEW,
                label: "What's new",
                onClick: () => {
                  ampli.navbarWhatsnewClicked({ workspaceId });
                  startFlow(WHATS_NEW_FLOW);
                },
                icon: <IconWhatsNew />,
              },
            ]
          : []),
        {
          id: SideBarOptions.HELP_DOCS,
          label: 'Document center',
          onClick: () => {
            ampli.docsButtonClicked();
            navigateTo(MARKOVML_DOCS);
          },
          icon: <IconDocs />,
        },
      ],
    },
  };

  const navBarGroups = {
    group1: {
      properties: {
        grow: true,
      },
      elements: [
        {
          id: SideBarOptions.HOME,
          path: AppRoutes.BASE_ROUTE,
          label: 'Home',
          onClick: () => {
            ampli.navbarDashboardClicked();
            navigateTo(getWorkspaceRoute());
          },
          icon: <IconDashboardNew />,
        },
        {
          id: SideBarOptions.DATASETS,
          path: AppRoutes.DATASETS,
          label: 'Datasets',
          onClick: () => {
            ampli.navbarDatasetsClicked();
            navigateTo(getDatasetsRoute());
          },
          icon: <IconStorageNew />,
        },
        ...(featureWorkflows
          ? [
              {
                id: SideBarOptions.WORKFLOWS,
                path: AppRoutes.WORKFLOWS_HOME,
                label: 'Workflows',
                onClick: () => {
                  navigateTo(getWorkflowsHomeRoute());
                },
                icon: <WorkflowGraph />,
              },
            ]
          : []),

        {
          id: SideBarOptions.MODEL_APPS,
          path: AppRoutes.MODEL_APPS,
          label: 'Model Apps',
          onClick: () => {
            // TODO: Add this event in amplitude
            // ampli.navbarModelAppsClicked();
            navigateTo(getModelAppsRoute());
          },
          icon: <IconModelApp />,
        },
        ...(featureAppStore
          ? [
              {
                id: SideBarOptions.APP_STORE,
                path: AppRoutes.APP_STORE,
                label: 'App Store',
                onClick: () => {
                  navigateTo(getAppStoreRoute());
                },
                icon: <IconApps />,
              },
            ]
          : []),
        {
          id: SideBarOptions.SNIPPETS,
          path: AppRoutes.SNIPPETS,
          label: 'Snippets',
          onClick: () => {
            ampli.navbarSnippetsClicked();
            navigateTo(getSnippetsRoute());
          },
          icon: <IconSnippets />,
        },
        {
          id: SideBarOptions.VIEW_MORE,
          label: 'More',
          icon: <IconMore />,
          isInitiallyOpened: true,
          links: [
            {
              id: SideBarOptions.MODELS,
              path: AppRoutes.MODELS,
              label: 'Models',
              onClick: () => {
                ampli.navbarModelClicked();
                navigateTo(getModelsRoute());
              },
              icon: <IconDeviceHub />,
            },
            {
              id: SideBarOptions.EXPERIMENTS,
              path: AppRoutes.EXPERIMENTS,
              label: 'Experiments',
              onClick: () => {
                ampli.navbarExperimentClicked();
                navigateTo(getExperimentsRoute());
              },
              icon: <IconBlurOn />,
            },
            {
              id: SideBarOptions.EVALUATION,
              path: AppRoutes.EVALUATIONS,
              label: 'Evaluations',
              onClick: () => {
                ampli.navbarEvaluationClicked();
                navigateTo(getEvaluationsRoute());
              },
              icon: <IconInsights />,
            },
            {
              id: SideBarOptions.PROJECTS,
              path: AppRoutes.PROJECTS,
              label: 'Projects',
              onClick: () => {
                ampli.navbarProjectsClicked();
                navigateTo(getProjectsRoute());
              },
              icon: <IconPhotoFilter />,
            },
          ],
        },
      ],
    },

    group2: {
      properties: {
        showBorderBottom: false,
        grow: false,
      },
      elements: [
        {
          id: SideBarOptions.INVITE_MEMBERS,
          label: 'Invite members',
          onClick: () => {
            ampli.navbarInviteMembersClicked({ workspaceId });
            openWorkspaceInviteModal();
          },
          icon: (
            <ActionIcon radius="xl" size="md" color="purple.6" variant="filled" p={4}>
              <IconGroupAdd />
            </ActionIcon>
          ),
        },
      ],
    },
  };

  const handleMouseEnter = () => {
    setSidebarMouseEnter(true);
  };

  const handleMouseLeave = () => {
    setSidebarMouseEnter(false);
  };
  const navGroups = pathname.includes('user')
    ? Object.values(profileNavBar)
    : Object.values(navBarGroups);

  const navBarSize = sidebarExpanded ? NAV_BAR_EXPANDED_SIZE : NAV_BAR_COLLAPSED_SIZE;

  return (
    <>
      <Navbar
        p={0}
        hiddenBreakpoint="sm"
        hidden={!sidebarExpanded}
        withBorder
        width={{
          base: sidebarExpanded ? NAV_BAR_EXPANDED_SIZE : 0,
          sm: navBarSize,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box
          h="100%"
          bg="dark.6"
          w={{
            // This needs to be in sync with width of Navbar
            base: sidebarExpanded ? NAV_BAR_EXPANDED_SIZE : 0,
            sm: sidebarMouseEnter ? NAV_BAR_EXPANDED_SIZE : navBarSize,
          }}
          className={classes.navSection}
        >
          {navGroups.map(({ properties, elements }, index) => (
            <Navbar.Section key={`navGroup-${index}`} grow={properties.grow} mx="sm">
              {elements.map((element, idx) => (
                <SidebarLinks key={`element-${idx}`} {...element} />
              ))}
            </Navbar.Section>
          ))}
          {!pathname.includes('user') && (
            <Navbar.Section>
              <SidebarMenu />
            </Navbar.Section>
          )}
        </Box>
      </Navbar>
      {isMobileScreen && (
        <Overlay
          display={sidebarExpanded ? 'block' : 'none'}
          w={`calc(100% - ${navBarSize}px)`}
          top={48}
          left={navBarSize}
          onClick={() => setSidebarExpanded(false)}
        />
      )}
    </>
  );
};
