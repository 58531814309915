import {
  IconActivity,
  IconApps,
  IconArrowsSplit2,
  IconBox,
  IconCloudNetwork,
  IconCompass,
  IconPresentation,
  IconSpaces,
  IconSparkles,
} from '@tabler/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { Navbar, Vertical } from '@/shared/design-system/v2';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { FeatureFamily } from '../../../router/constants';
import { useGetHomeRoute } from '../../../router/hooks';
import { DATA_ANALYTICS_NAME } from '../../data-analytics/util';
import { PrimaryNavbarLink } from './PrimaryNavbarLink';
import { NAV_BAR_COLLAPSED_SIZE } from './Sidebar';

export const PrimaryNavbar = (): JSX.Element => {
  const navigate = useNavigate();
  const getHomeRoute = useGetHomeRoute();
  const { activeSection, setActiveSection } = useAppMetadata();
  const {
    featureSnippets,
    featureGlobalHomepage,
    featureDataStudio,
    featureModelStudio,
    featureWorkflows,
    featureAppStore,
    featureGenaiAppBuilder,
    featureConnectors,
    featureTextToSql,
  } = useFlags();

  const handleChange = (nextValue: string) => {
    if (nextValue !== activeSection) {
      setActiveSection(nextValue as FeatureFamily);
      navigate(getHomeRoute(nextValue as FeatureFamily));
    }
  };

  const studioElements = [
    {
      id: FeatureFamily.WORKFLOWS,
      label: 'Workflows',
      icon: <IconArrowsSplit2 strokeWidth="1.5px" />,
      isVisible: !!featureWorkflows,
    },
    {
      id: FeatureFamily.DATA_ANALYTICS,
      label: DATA_ANALYTICS_NAME,
      icon: <IconActivity strokeWidth="1.5px" />,
      isVisible: !!featureTextToSql && !featureDataStudio,
    },
    {
      id: FeatureFamily.APP_STORE,
      label: 'App Store',
      icon: <IconApps strokeWidth="1.5px" />,
      isVisible: !!featureAppStore,
    },
    {
      id: FeatureFamily.APP_BUILDER,
      label: 'AI Apps',
      icon: <IconSparkles strokeWidth="1.5px" />,
      isVisible: !!featureGenaiAppBuilder,
    },
    {
      id: FeatureFamily.DATA_STUDIO,
      label: 'Data Studio',
      icon: <IconBox strokeWidth="1.5px" />,
      isVisible: !!featureDataStudio,
    },
    {
      id: FeatureFamily.SNIPPETS,
      label: 'Snippets',
      icon: <IconPresentation strokeWidth="1.5px" />,
      isVisible: !!featureSnippets,
    },
    {
      id: FeatureFamily.MODEL_STUDIO,
      label: 'Model Studio',
      icon: <IconSpaces strokeWidth="1.5px" />,
      isVisible: !!featureModelStudio,
    },
  ]
    .filter(({ isVisible = true }) => isVisible)
    .map(item => ({ ...item, onClick: () => handleChange(item.id) }));

  const groups = [
    {
      properties: { grow: true },
      elements: studioElements,
    },
  ];

  return (
    <Navbar p={0} withBorder={false} w={NAV_BAR_COLLAPSED_SIZE} pos="static">
      <Vertical spacing={0} h="100%" bg="dark.5">
        {groups.map(({ properties, elements }, index) => (
          <Navbar.Section key={`navGroup-${index}`} grow={properties.grow}>
            {elements.map((element, idx) => (
              <PrimaryNavbarLink key={`element-${idx}`} {...element} />
            ))}
          </Navbar.Section>
        ))}
        {featureConnectors && !featureDataStudio && (
          <Navbar.Section grow={false}>
            <PrimaryNavbarLink
              id={FeatureFamily.DATA_SOURCES}
              label="Data sources and connectors"
              onClick={() => handleChange(FeatureFamily.DATA_SOURCES)}
              icon={<IconCloudNetwork strokeWidth="1.5px" />}
            />
          </Navbar.Section>
        )}
        {featureGlobalHomepage && (
          <Navbar.Section grow={false}>
            <PrimaryNavbarLink
              id={FeatureFamily.GLOBAL_HOME}
              label="Explore markovML"
              onClick={() => handleChange(FeatureFamily.GLOBAL_HOME)}
              icon={<IconCompass strokeWidth="1.5px" />}
            />
          </Navbar.Section>
        )}
      </Vertical>
    </Navbar>
  );
};
