import { isObject } from 'lodash';
import {
  Button,
  Horizontal,
  MarkdownPreview,
  Text,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';

interface RowPreviewProps {
  data: any;
  schema: {
    name: string;
    type: string;
    placeholder?: boolean;
  }[];
  onModalClose: () => void;
  isRunsPreview?: boolean;
}

export const RowPreview = ({
  data,
  schema,
  onModalClose,
  isRunsPreview = false,
}: RowPreviewProps): JSX.Element => {
  const theme = useMarkovTheme();
  return (
    <Vertical spacing="sm" px="48px" py="md" h="100%">
      {schema
        .filter(col => col.name in data)
        .map((col, index) => {
          const value = data[col.name] ?? '-';
          const displayValue = isObject(value) ? JSON.stringify(value) : value.toString();

          return (
            <Vertical
              key={index}
              mb="xl"
              sx={{
                borderBottom:
                  index !== schema.filter(col => col.name in data).length - 1
                    ? `1px solid ${theme.colors.gray[2]}`
                    : 'none',
              }}
            >
              <Text variant="overline" color="gray.9" tt="uppercase">
                {col.name}
              </Text>
              {col.placeholder && !isRunsPreview ? (
                <Text variant="bodyLong03" color="gray.9">
                  This data would be available in the runs preview
                </Text>
              ) : (
                <MarkdownPreview>{displayValue}</MarkdownPreview>
              )}
            </Vertical>
          );
        })}
      <Horizontal position="right">
        <Button variant="subtle" color="gray.7" onClick={onModalClose}>
          Close
        </Button>
      </Horizontal>
    </Vertical>
  );
};
