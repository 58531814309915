/* tslint:disable */
/* eslint-disable */
/**
 * MarkovMumbaiService
 * API gateway and orchestrator service
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ACKResponseModel
 */
export interface ACKResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof ACKResponseModel
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof ACKResponseModel
     */
    'errorMessage'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum APIErrorCodes {
    ServerError = 'SERVER_ERROR'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum APIInfoCodes {
    MissingValue = 'MISSING_VALUE',
    Ok = 'OK',
    NameConflict = 'NAME_CONFLICT',
    Existing = 'EXISTING',
    PartialUpdate = 'PARTIAL_UPDATE',
    InvalidInput = 'INVALID_INPUT',
    NotSupported = 'NOT_SUPPORTED',
    ResourceNotFound = 'RESOURCE_NOT_FOUND'
}

/**
 * 
 * @export
 * @interface APIMessage
 */
export interface APIMessage {
    /**
     * 
     * @type {APIInfoCodes | APIErrorCodes | string}
     * @memberof APIMessage
     */
    'code': APIInfoCodes | APIErrorCodes | string;
    /**
     * 
     * @type {string}
     * @memberof APIMessage
     */
    'description': string;
}
/**
 * API Token model
 * @export
 * @interface APIToken
 */
export interface APIToken {
    /**
     * 
     * @type {any}
     * @memberof APIToken
     */
    'tokenId': any;
    /**
     * 
     * @type {string}
     * @memberof APIToken
     */
    'tokenName': string;
    /**
     * 
     * @type {string}
     * @memberof APIToken
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof APIToken
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof APIToken
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof APIToken
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof APIToken
     */
    'lastUsedDate': string;
    /**
     * 
     * @type {string}
     * @memberof APIToken
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof APIToken
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof APIToken
     */
    'expiryDate': string;
    /**
     * 
     * @type {string}
     * @memberof APIToken
     */
    'apiToken'?: string;
}
/**
 * 
 * @export
 * @interface AcceptAllRecommendationsResponse
 */
export interface AcceptAllRecommendationsResponse {
    /**
     * 
     * @type {string}
     * @memberof AcceptAllRecommendationsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {AckResponse}
     * @memberof AcceptAllRecommendationsResponse
     */
    'response': AckResponse;
    /**
     * 
     * @type {string}
     * @memberof AcceptAllRecommendationsResponse
     */
    'msg'?: string;
}
/**
 * Consolidated object that contains details from both UserOwnedAccount, and EnterpriseOwnedAccount.
 * @export
 * @interface AccountInfo
 */
export interface AccountInfo {
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    'accountId': string;
    /**
     * 
     * @type {AccountOwnerType}
     * @memberof AccountInfo
     */
    'accountType': AccountOwnerType;
    /**
     * 
     * @type {EnterpriseOwnedAccount}
     * @memberof AccountInfo
     */
    'enterpriseInfo'?: EnterpriseOwnedAccount;
    /**
     * 
     * @type {UserOwnedAccount}
     * @memberof AccountInfo
     */
    'userAccountInfo'?: UserOwnedAccount;
}
/**
 * Account Owner Type. Whether it\'s owned by a user or an enterprise.
 * @export
 * @enum {string}
 */

export enum AccountOwnerType {
    Enterprise = 'ENTERPRISE',
    User = 'USER'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum AccountState {
    Inactive = 'INACTIVE',
    Active = 'ACTIVE'
}

/**
 * Paginated list of accounts for get accounts api response
 * @export
 * @interface AccountsList
 */
export interface AccountsList {
    /**
     * 
     * @type {UserOwnedAccountsList}
     * @memberof AccountsList
     */
    'userOwnedAccounts': UserOwnedAccountsList;
    /**
     * 
     * @type {EnterpriseOwnedAccountsList}
     * @memberof AccountsList
     */
    'enterpriseOwnedAccounts': EnterpriseOwnedAccountsList;
}
/**
 * 
 * @export
 * @interface AckRequestModel
 */
export interface AckRequestModel {
    /**
     * 
     * @type {string}
     * @memberof AckRequestModel
     */
    'notificationRecordId': string;
    /**
     * 
     * @type {NotifierType}
     * @memberof AckRequestModel
     */
    'notifierType': NotifierType;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AckRequestModel
     */
    'ackResponse': { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof AckRequestModel
     */
    'statusCode': number;
    /**
     * 
     * @type {string}
     * @memberof AckRequestModel
     */
    'message'?: string;
    /**
     * 
     * @type {NotificationResponseStatus}
     * @memberof AckRequestModel
     */
    'status': NotificationResponseStatus;
}
/**
 * 
 * @export
 * @interface AckResponse
 */
export interface AckResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AckResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'userId': string;
    /**
     * 
     * @type {WorkspaceActivityType}
     * @memberof Activity
     */
    'activityType': WorkspaceActivityType;
    /**
     * 
     * @type {ActivityDetails}
     * @memberof Activity
     */
    'activityDetails': ActivityDetails;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'createDate': string;
}
/**
 * Activity details object for Activity Results. Only one of the following fields will be populated based on the activity type.
 * @export
 * @interface ActivityDetails
 */
export interface ActivityDetails {
    /**
     * 
     * @type {ProjectInfo}
     * @memberof ActivityDetails
     */
    'projectInfo'?: ProjectInfo;
    /**
     * 
     * @type {DatasetInfo}
     * @memberof ActivityDetails
     */
    'datasetInfo'?: DatasetInfo;
    /**
     * 
     * @type {EvaluationRecordingInfo}
     * @memberof ActivityDetails
     */
    'evaluationRecordingInfo'?: EvaluationRecordingInfo;
    /**
     * 
     * @type {ExperimentRecordingInfo}
     * @memberof ActivityDetails
     */
    'experimentRecordingInfo'?: ExperimentRecordingInfo;
    /**
     * 
     * @type {ModelInfo}
     * @memberof ActivityDetails
     */
    'modelInfo'?: ModelInfo;
    /**
     * 
     * @type {Team}
     * @memberof ActivityDetails
     */
    'teamInfo'?: Team;
}
/**
 * List of activities for get activities api response
 * @export
 * @interface ActivityList
 */
export interface ActivityList {
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ActivityList
     */
    'activities': Array<Activity>;
    /**
     * 
     * @type {{ [key: string]: BasicUserDetails; }}
     * @memberof ActivityList
     */
    'users': { [key: string]: BasicUserDetails; };
    /**
     * 
     * @type {string}
     * @memberof ActivityList
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface AddCategoryToProjectRequest
 */
export interface AddCategoryToProjectRequest {
    /**
     * 
     * @type {AmazonCategories}
     * @memberof AddCategoryToProjectRequest
     */
    'categoryType': AmazonCategories;
}
/**
 * 
 * @export
 * @interface AddCategoryToProjectResponse
 */
export interface AddCategoryToProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof AddCategoryToProjectResponse
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof AddCategoryToProjectResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface AddCommentRequest
 */
export interface AddCommentRequest {
    /**
     * 
     * @type {CommentResourceTypes}
     * @memberof AddCommentRequest
     */
    'resourceType': CommentResourceTypes;
    /**
     * 
     * @type {string}
     * @memberof AddCommentRequest
     */
    'resourceId': string;
    /**
     * 
     * @type {string}
     * @memberof AddCommentRequest
     */
    'conversationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddCommentRequest
     */
    'locationKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddCommentRequest
     */
    'comment': string;
    /**
     * 
     * @type {Array<UserMention>}
     * @memberof AddCommentRequest
     */
    'mentions'?: Array<UserMention>;
}
/**
 * 
 * @export
 * @interface AddCommentToConversationRequest
 */
export interface AddCommentToConversationRequest {
    /**
     * 
     * @type {string}
     * @memberof AddCommentToConversationRequest
     */
    'conversationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddCommentToConversationRequest
     */
    'comment': string;
    /**
     * 
     * @type {Array<UserMentions>}
     * @memberof AddCommentToConversationRequest
     */
    'mentions'?: Array<UserMentions>;
}
/**
 * Details: https://meskalero.slab.com/posts/api-design-for-dataset-registration-6h7c4h3e
 * @export
 * @interface AddCredentialRequest
 */
export interface AddCredentialRequest {
    /**
     * 
     * @type {string}
     * @memberof AddCredentialRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AddCredentialRequest
     */
    'description': string;
    /**
     * 
     * @type {StorageType}
     * @memberof AddCredentialRequest
     */
    'provider': StorageType;
    /**
     * 
     * @type {S3CredentialData | SnowflakeBasicCredentialData | PostgresCredentialData | object}
     * @memberof AddCredentialRequest
     */
    'data': S3CredentialData | SnowflakeBasicCredentialData | PostgresCredentialData | object;
}
/**
 * 
 * @export
 * @interface AddCredentialResponse
 */
export interface AddCredentialResponse {
    /**
     * 
     * @type {CredentialModel}
     * @memberof AddCredentialResponse
     */
    'response': CredentialModel;
    /**
     * 
     * @type {APIMessage}
     * @memberof AddCredentialResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {string}
     * @memberof AddCredentialResponse
     */
    'workspaceId'?: string;
}
/**
 * Request model for API method to store a list of labels that can be used during relabeling
 * @export
 * @interface AddRelabelingLabelsRequest
 */
export interface AddRelabelingLabelsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddRelabelingLabelsRequest
     */
    'labels': Array<string>;
}
/**
 * 
 * @export
 * @interface AddResourceRequest
 */
export interface AddResourceRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AddResourceRequest
     */
    'isDataSink'?: boolean;
    /**
     * 
     * @type {Array<ResourceRequest>}
     * @memberof AddResourceRequest
     */
    'resourceList': Array<ResourceRequest>;
    /**
     * 
     * @type {object}
     * @memberof AddResourceRequest
     */
    'details'?: object;
}
/**
 * 
 * @export
 * @interface AddResourceRequestV2Mig
 */
export interface AddResourceRequestV2Mig {
    /**
     * 
     * @type {boolean}
     * @memberof AddResourceRequestV2Mig
     */
    'isDataSink'?: boolean;
    /**
     * 
     * @type {ResourceRequestV2Mig}
     * @memberof AddResourceRequestV2Mig
     */
    'resource': ResourceRequestV2Mig;
}
/**
 * 
 * @export
 * @interface AddResourceResponse
 */
export interface AddResourceResponse {
    /**
     * 
     * @type {ResourceModel}
     * @memberof AddResourceResponse
     */
    'response'?: ResourceModel;
    /**
     * 
     * @type {string}
     * @memberof AddResourceResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {APIMessage}
     * @memberof AddResourceResponse
     */
    'message'?: APIMessage;
}
/**
 * 
 * @export
 * @interface AddRetailProductRequest
 */
export interface AddRetailProductRequest {
    /**
     * 
     * @type {string}
     * @memberof AddRetailProductRequest
     */
    'productUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AddRetailProductRequest
     */
    'brand': string;
    /**
     * 
     * @type {string}
     * @memberof AddRetailProductRequest
     */
    'projectId': string;
    /**
     * 
     * @type {AmazonCategories}
     * @memberof AddRetailProductRequest
     */
    'category': AmazonCategories;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddRetailProductRequest
     */
    'keywords'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddRetailProductRequest
     */
    'competitorUrls'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AddRetailProductRequest
     */
    'dataSourceId'?: string;
}
/**
 * 
 * @export
 * @interface AddRetailProjectRequest
 */
export interface AddRetailProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof AddRetailProjectRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AddRetailProjectRequest
     */
    'description'?: string;
}
/**
 * Request model for adding snippets
 * @export
 * @interface AddSnippetRequest
 */
export interface AddSnippetRequest {
    /**
     * 
     * @type {string}
     * @memberof AddSnippetRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddSnippetRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface AddSnippetResponse
 */
export interface AddSnippetResponse {
    /**
     * 
     * @type {APIMessage}
     * @memberof AddSnippetResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {SnippetModel}
     * @memberof AddSnippetResponse
     */
    'response'?: SnippetModel;
    /**
     * 
     * @type {string}
     * @memberof AddSnippetResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface AddStyleGuideRuleRequest
 */
export interface AddStyleGuideRuleRequest {
    /**
     * 
     * @type {StyleGuideRule}
     * @memberof AddStyleGuideRuleRequest
     */
    'rule': StyleGuideRule;
    /**
     * 
     * @type {StyleGuideRuleOptions}
     * @memberof AddStyleGuideRuleRequest
     */
    'options'?: StyleGuideRuleOptions;
    /**
     * 
     * @type {string}
     * @memberof AddStyleGuideRuleRequest
     */
    'description'?: string;
    /**
     * 
     * @type {StyleGuideRule}
     * @memberof AddStyleGuideRuleRequest
     */
    'example'?: StyleGuideRule;
    /**
     * 
     * @type {boolean}
     * @memberof AddStyleGuideRuleRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddStyleGuideRuleRequest
     */
    'recordId'?: string;
}
/**
 * 
 * @export
 * @interface AddStyleGuideRuleSetResponse
 */
export interface AddStyleGuideRuleSetResponse {
    /**
     * 
     * @type {string}
     * @memberof AddStyleGuideRuleSetResponse
     */
    'ruleSetName': string;
    /**
     * 
     * @type {string}
     * @memberof AddStyleGuideRuleSetResponse
     */
    'ruleSetId': string;
    /**
     * 
     * @type {string}
     * @memberof AddStyleGuideRuleSetResponse
     */
    'ruleSetDescription'?: string;
}
/**
 * 
 * @export
 * @interface AddUpdateStyleGuideRuleResponse
 */
export interface AddUpdateStyleGuideRuleResponse {
    /**
     * 
     * @type {string}
     * @memberof AddUpdateStyleGuideRuleResponse
     */
    'ruleId': string;
    /**
     * 
     * @type {StyleGuideRule}
     * @memberof AddUpdateStyleGuideRuleResponse
     */
    'rule': StyleGuideRule;
}
/**
 * 
 * @export
 * @interface AddUpdateStyleGuideRuleSetRequest
 */
export interface AddUpdateStyleGuideRuleSetRequest {
    /**
     * 
     * @type {string}
     * @memberof AddUpdateStyleGuideRuleSetRequest
     */
    'ruleSetName': string;
    /**
     * 
     * @type {string}
     * @memberof AddUpdateStyleGuideRuleSetRequest
     */
    'ruleSetDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddUpdateStyleGuideRuleSetRequest
     */
    'recordId'?: string;
}
/**
 * 
 * @export
 * @interface AddWordsToCustomDictionaryRequest
 */
export interface AddWordsToCustomDictionaryRequest {
    /**
     * 
     * @type {string}
     * @memberof AddWordsToCustomDictionaryRequest
     */
    'dictionaryId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddWordsToCustomDictionaryRequest
     */
    'customWords': Array<string>;
}
/**
 * 
 * @export
 * @interface AddWordsToCustomDictionaryResponse
 */
export interface AddWordsToCustomDictionaryResponse {
    /**
     * 
     * @type {string}
     * @memberof AddWordsToCustomDictionaryResponse
     */
    'dictionaryId': string;
}
/**
 * 
 * @export
 * @interface AdditionalData
 */
export interface AdditionalData {
    /**
     * 
     * @type {Array<Result>}
     * @memberof AdditionalData
     */
    'results': Array<Result>;
    /**
     * 
     * @type {Summary}
     * @memberof AdditionalData
     */
    'summary': Summary;
    /**
     * 
     * @type {string}
     * @memberof AdditionalData
     */
    'translation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalData
     */
    'modelVersion': string;
    /**
     * 
     * @type {number}
     * @memberof AdditionalData
     */
    'translationProvider': number;
}
/**
 * 
 * @export
 * @interface AirbyteConnectorMetadataModel
 */
export interface AirbyteConnectorMetadataModel {
    /**
     * 
     * @type {string}
     * @memberof AirbyteConnectorMetadataModel
     */
    'sourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AirbyteConnectorMetadataModel
     */
    'destinationId'?: string;
}
/**
 * 
 * @export
 * @interface AirbyteConnectorTypeResponse
 */
export interface AirbyteConnectorTypeResponse {
    /**
     * 
     * @type {Array<AirbyteStorageType>}
     * @memberof AirbyteConnectorTypeResponse
     */
    'connectorTypes': Array<AirbyteStorageType>;
    /**
     * 
     * @type {string}
     * @memberof AirbyteConnectorTypeResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface AirbyteJobDetailsResponse
 */
export interface AirbyteJobDetailsResponse {
    /**
     * 
     * @type {AirbyteJobStatus}
     * @memberof AirbyteJobDetailsResponse
     */
    'jobStatus': AirbyteJobStatus;
    /**
     * 
     * @type {string}
     * @memberof AirbyteJobDetailsResponse
     */
    'lastUpdateDate'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum AirbyteJobStatus {
    Running = 'running',
    Succeeded = 'succeeded',
    Failed = 'failed',
    Incomplete = 'incomplete'
}

/**
 * 
 * @export
 * @interface AirbyteStorageResourceModel
 */
export interface AirbyteStorageResourceModel {
    /**
     * 
     * @type {string}
     * @memberof AirbyteStorageResourceModel
     */
    'streamName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AirbyteStorageResourceModel
     */
    'startDate'?: string;
}
/**
 * Airbyte-specific storage types
 * @export
 * @enum {string}
 */

export enum AirbyteStorageType {
    Hubspot = 'hubspot',
    Airtable = 'airtable',
    GoogleAnalytics = 'google-analytics',
    LinkedinAds = 'linkedin-ads',
    Salesforce = 'salesforce',
    Zendesk = 'zendesk',
    GoogleAds = 'google_ads',
    Gong = 'gong'
}

/**
 * 
 * @export
 * @interface AirtableCredentialModel
 */
export interface AirtableCredentialModel {
    /**
     * 
     * @type {string}
     * @memberof AirtableCredentialModel
     */
    'authMethod'?: string;
    /**
     * 
     * @type {string}
     * @memberof AirtableCredentialModel
     */
    'apiKey': string;
}
/**
 * 
 * @export
 * @interface AllowedFilters
 */
export interface AllowedFilters {
    /**
     * 
     * @type {Array<ApplicableFilter>}
     * @memberof AllowedFilters
     */
    'filters': Array<ApplicableFilter>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllowedFilters
     */
    'sortable': Array<string>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum AmazonCategories {
    AmazonDeviceAccessories = 'Amazon Device Accessories',
    AmazonKindle = 'Amazon Kindle',
    AutomotivePowersports = 'Automotive & Powersports',
    BabyProducts = 'Baby Products',
    Beauty = 'Beauty',
    Books = 'Books',
    CameraPhoto = 'Camera & Photo',
    CellPhonesAndAccessories = 'Cell Phones and Accessories',
    CollectibleCoins = 'Collectible Coins',
    ConsumerElectronics = 'Consumer Electronics',
    EntertainmentCollectibles = 'Entertainment Collectibles',
    FineArt = 'Fine Art',
    GroceryAndGourmetFoods = 'Grocery and Gourmet Foods',
    HealthAndPersonalCare = 'Health and Personal Care',
    HomeAndGarden = 'Home and Garden',
    IndependentDesign = 'Independent Design',
    IndustrialAndScientific = 'Industrial and Scientific',
    KindleAccessoriesAndAmazonFireTvAccessories = 'Kindle Accessories and Amazon Fire TV Accessories',
    MajorAppliances = 'Major Appliances',
    MusicAndDvd = 'Music and DVD',
    MusicalInstruments = 'Musical Instruments',
    OfficeProducts = 'Office Products',
    Outdoors = 'Outdoors',
    PersonalComputers = 'Personal Computers',
    PetSupplies = 'Pet Supplies',
    Software = 'Software',
    Sports = 'Sports',
    SportsCollectibles = 'Sports Collectibles',
    ToolsAndHomeImprovement = 'Tools and Home Improvement',
    ToysAndGames = 'Toys and Games',
    VideoDvdAndBluRay = 'Video, DVD, and Blu-Ray',
    VideoGames = 'Video Games',
    Watches = 'Watches'
}

/**
 * Amplitude event model
 * @export
 * @interface AmplitudeEvent
 */
export interface AmplitudeEvent {
    /**
     * 
     * @type {string}
     * @memberof AmplitudeEvent
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof AmplitudeEvent
     */
    'deviceId': string;
    /**
     * 
     * @type {string}
     * @memberof AmplitudeEvent
     */
    'eventType': string;
    /**
     * 
     * @type {string}
     * @memberof AmplitudeEvent
     */
    'eventTime': string;
    /**
     * 
     * @type {object}
     * @memberof AmplitudeEvent
     */
    'eventProperties': object;
    /**
     * 
     * @type {object}
     * @memberof AmplitudeEvent
     */
    'userProperties': object;
}
/**
 * 
 * @export
 * @interface AnalyseFileRequest
 */
export interface AnalyseFileRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AnalyseFileRequest
     */
    'pages': Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof AnalyseFileRequest
     */
    'forceAnalyse'?: boolean;
}
/**
 * 
 * @export
 * @interface AnalysePageResponse
 */
export interface AnalysePageResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AnalysePageResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof AnalysePageResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface AnalysePageStatusResponse
 */
export interface AnalysePageStatusResponse {
    /**
     * 
     * @type {PageStatusType}
     * @memberof AnalysePageStatusResponse
     */
    'status': PageStatusType;
}
/**
 * 
 * @export
 * @interface Analysis
 */
export interface Analysis {
    /**
     * 
     * @type {AnalysisData}
     * @memberof Analysis
     */
    'data': AnalysisData;
    /**
     * 
     * @type {AnalysisMetadata}
     * @memberof Analysis
     */
    'metadata': AnalysisMetadata;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum AnalysisChartTypes {
    Line = 'line',
    BarChart = 'bar_chart',
    Histogram = 'histogram',
    HorizontalHistogram = 'horizontal_histogram',
    PieChart = 'pie_chart',
    PackedBubbleChart = 'packed_bubble_chart',
    TreeMap = 'tree_map',
    Table = 'table',
    Scatter = 'scatter'
}

/**
 *     data: {   // This values will come up on the x axis of the chart (on y in case of inverted chart)   categories: [\'Jan\', \'Feb\', \'March\', \'April\'],   series: [{       name: \'Tokyo\',       data: [7.0, 1.2]   }, {       name: \'London\',       data: [10.0, 11.2]   }] }
 * @export
 * @interface AnalysisData
 */
export interface AnalysisData {
    /**
     * 
     * @type {Array<number | string>}
     * @memberof AnalysisData
     */
    'categories': Array<number | string>;
    /**
     * 
     * @type {Array<AnalysisSeries>}
     * @memberof AnalysisData
     */
    'series': Array<AnalysisSeries>;
    /**
     * 
     * @type {Array<number | string>}
     * @memberof AnalysisData
     */
    'xcategories'?: Array<number | string>;
}
/**
 * 
 * @export
 * @interface AnalysisDataPoint
 */
export interface AnalysisDataPoint {
    /**
     * 
     * @type {number}
     * @memberof AnalysisDataPoint
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisDataPoint
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisDataPoint
     */
    'z'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnalysisDataPoint
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: Array<any> | number | string; }}
     * @memberof AnalysisDataPoint
     */
    'metadata'?: { [key: string]: Array<any> | number | string; };
}
/**
 * [{     id: int,     name: \"n-gram\",     n_graphs: 9, }]
 * @export
 * @interface AnalysisGroupModel
 */
export interface AnalysisGroupModel {
    /**
     * 
     * @type {string}
     * @memberof AnalysisGroupModel
     */
    'analysisGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisGroupModel
     */
    'analysisName': string;
    /**
     * 
     * @type {Array<AnalysisSegmentModel>}
     * @memberof AnalysisGroupModel
     */
    'analysisSegment': Array<AnalysisSegmentModel>;
}
/**
 * 
 * @export
 * @interface AnalysisGroupedByMetricModel
 */
export interface AnalysisGroupedByMetricModel {
    /**
     * 
     * @type {string}
     * @memberof AnalysisGroupedByMetricModel
     */
    'analysisGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisGroupedByMetricModel
     */
    'analysisName': string;
    /**
     * 
     * @type {Array<AnalysisMetricModel>}
     * @memberof AnalysisGroupedByMetricModel
     */
    'metrics': Array<AnalysisMetricModel>;
}
/**
 * 
 * @export
 * @interface AnalysisMetadata
 */
export interface AnalysisMetadata {
    /**
     * 
     * @type {string}
     * @memberof AnalysisMetadata
     */
    'analysisId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisMetadata
     */
    'datasetId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisMetadata
     */
    'analysisGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisMetadata
     */
    'metricName': string;
    /**
     * 
     * @type {SegmentType}
     * @memberof AnalysisMetadata
     */
    'segmentType': SegmentType;
    /**
     * 
     * @type {AnalysisChartTypes}
     * @memberof AnalysisMetadata
     */
    'preferredChartType'?: AnalysisChartTypes;
    /**
     * 
     * @type {string}
     * @memberof AnalysisMetadata
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisMetadata
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalysisMetadata
     */
    'classes'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AnalysisMetadata
     */
    'subsetId'?: string;
}
/**
 * 
 * @export
 * @interface AnalysisMetricModel
 */
export interface AnalysisMetricModel {
    /**
     * 
     * @type {string}
     * @memberof AnalysisMetricModel
     */
    'metricName': string;
    /**
     * 
     * @type {number}
     * @memberof AnalysisMetricModel
     */
    'numGraphs': number;
    /**
     * 
     * @type {string}
     * @memberof AnalysisMetricModel
     */
    'datasetId': string;
    /**
     * 
     * @type {Array<AnalysisSegmentModel>}
     * @memberof AnalysisMetricModel
     */
    'segments': Array<AnalysisSegmentModel>;
}
/**
 * Model representing the metadata for an analysis result.  Attributes:     analysis_id (str): The unique identifier of the analysis result.     segment_type (str): The type of segment the analysis result is applicable to.     title (str): The title of the analysis.     classes (List[str]): The classes involved in the analysis.     preferred_chart_type (AnalysisChartTypes): The preferred type of chart for visualizing the analysis result.
 * @export
 * @interface AnalysisResultMetadata
 */
export interface AnalysisResultMetadata {
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultMetadata
     */
    'analysisId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultMetadata
     */
    'segmentType': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultMetadata
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalysisResultMetadata
     */
    'classes': Array<string>;
    /**
     * 
     * @type {AnalysisChartTypes}
     * @memberof AnalysisResultMetadata
     */
    'preferredChartType': AnalysisChartTypes;
}
/**
 * Full data analysis results file for a specific tasklet segment.
 * @export
 * @interface AnalysisResultsDownload
 */
export interface AnalysisResultsDownload {
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultsDownload
     */
    'taskletId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultsDownload
     */
    'taskId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultsDownload
     */
    'segment': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultsDownload
     */
    'downloadLink': string;
}
/**
 * Response to API to create a download link for full data analysis results.  /v1/dataset/{id}/full-analyses/{tasklet_id}/segment/{segment_type}/results
 * @export
 * @interface AnalysisResultsDownloadLinkResponse
 */
export interface AnalysisResultsDownloadLinkResponse {
    /**
     * 
     * @type {AnalysisResultsDownload}
     * @memberof AnalysisResultsDownloadLinkResponse
     */
    'response': AnalysisResultsDownload;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultsDownloadLinkResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface AnalysisRetriggerResponse
 */
export interface AnalysisRetriggerResponse {
    /**
     * 
     * @type {RetriggerAck}
     * @memberof AnalysisRetriggerResponse
     */
    'response': RetriggerAck;
    /**
     * 
     * @type {string}
     * @memberof AnalysisRetriggerResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface AnalysisSegmentModel
 */
export interface AnalysisSegmentModel {
    /**
     * 
     * @type {SegmentType}
     * @memberof AnalysisSegmentModel
     */
    'segmentType': SegmentType;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSegmentModel
     */
    'numGraphs': number;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSegmentModel
     */
    'datasetId': string;
    /**
     * 
     * @type {Array<AnalysisMetadata>}
     * @memberof AnalysisSegmentModel
     */
    'metadata': Array<AnalysisMetadata>;
}
/**
 * A user can select a tasklet and select what segments to run the analysis on
 * @export
 * @interface AnalysisSelection
 */
export interface AnalysisSelection {
    /**
     * 
     * @type {string}
     * @memberof AnalysisSelection
     */
    'taskletId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalysisSelection
     */
    'segments': Array<string>;
}
/**
 * 
 * @export
 * @interface AnalysisSeries
 */
export interface AnalysisSeries {
    /**
     * Use `name` instead of this
     * @type {string}
     * @memberof AnalysisSeries
     * @deprecated
     */
    'className'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSeries
     */
    'name': string;
    /**
     * 
     * @type {Array<AnalysisDataPoint>}
     * @memberof AnalysisSeries
     */
    'data': Array<AnalysisDataPoint>;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSeries
     */
    'type'?: string;
}
/**
 * This enum defines states of all analysis run for a dataset. These states are used to define the status of analysis over an entire dataset, as well as a single analysis on a dataset
 * @export
 * @enum {string}
 */

export enum AnalysisStateNames {
    NotStarted = 'NOT_STARTED',
    Created = 'CREATED',
    Disabled = 'DISABLED',
    Completed = 'COMPLETED',
    InProgress = 'IN_PROGRESS',
    Error = 'ERROR',
    ResultsAvailable = 'RESULTS_AVAILABLE',
    NotSupported = 'NOT_SUPPORTED',
    Stopped = 'STOPPED',
    Outdated = 'OUTDATED'
}

/**
 * 
 * @export
 * @interface AnalysisStatus
 */
export interface AnalysisStatus {
    /**
     * 
     * @type {string}
     * @memberof AnalysisStatus
     */
    'status': string;
    /**
     * 
     * @type {TasksStateSummary}
     * @memberof AnalysisStatus
     */
    'tasksStateSummary': TasksStateSummary;
    /**
     * 
     * @type {number}
     * @memberof AnalysisStatus
     */
    'totalCount': number;
}
/**
 * 
 * @export
 * @interface AnalysisStatus1
 */
export interface AnalysisStatus1 {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof AnalysisStatus1
     */
    'status': AnalysisStateNames;
    /**
     * 
     * @type {TasksStateSummary1}
     * @memberof AnalysisStatus1
     */
    'tasksStateSummary'?: TasksStateSummary1;
    /**
     * total number of analysis would be removed from the API
     * @type {number}
     * @memberof AnalysisStatus1
     * @deprecated
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface AnalysisTask
 */
export interface AnalysisTask {
    /**
     * 
     * @type {AnalysisTaskData}
     * @memberof AnalysisTask
     */
    'data'?: AnalysisTaskData;
    /**
     * 
     * @type {APIMessage}
     * @memberof AnalysisTask
     */
    'message'?: APIMessage;
}
/**
 * 
 * @export
 * @interface AnalysisTaskData
 */
export interface AnalysisTaskData {
    /**
     * 
     * @type {string}
     * @memberof AnalysisTaskData
     */
    'taskId': string;
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof AnalysisTaskData
     */
    'state': AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof AnalysisTaskData
     */
    'analysisGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisTaskData
     */
    'analysisName': string;
    /**
     * 
     * @type {SegmentType}
     * @memberof AnalysisTaskData
     */
    'segmentType': SegmentType;
    /**
     * 
     * @type {string}
     * @memberof AnalysisTaskData
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisTaskData
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisTaskData
     */
    'endTime': string;
    /**
     * 
     * @type {number}
     * @memberof AnalysisTaskData
     */
    'runtimeEstimateSecs'?: number;
}
/**
 * 
 * @export
 * @interface AnalysisTasklet
 */
export interface AnalysisTasklet {
    /**
     * 
     * @type {string}
     * @memberof AnalysisTasklet
     */
    'taskletId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisTasklet
     */
    'name': string;
    /**
     * 
     * @type {Array<RunStatus1>}
     * @memberof AnalysisTasklet
     */
    'runStatus': Array<RunStatus1>;
    /**
     * 
     * @type {string}
     * @memberof AnalysisTasklet
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface AnalysisTasks
 */
export interface AnalysisTasks {
    /**
     * 
     * @type {DatasetInfo1}
     * @memberof AnalysisTasks
     */
    'datasetInfo': DatasetInfo1;
    /**
     * 
     * @type {Array<AnalysisTask>}
     * @memberof AnalysisTasks
     */
    'tasks': Array<AnalysisTask>;
    /**
     * 
     * @type {TasksStateSummary1}
     * @memberof AnalysisTasks
     */
    'tasksStateSummary'?: TasksStateSummary1;
}
/**
 * 
 * @export
 * @interface AnalyticsCopyEditResponse
 */
export interface AnalyticsCopyEditResponse {
    /**
     * 
     * @type {Array<CopyEditAnalyticsValue>}
     * @memberof AnalyticsCopyEditResponse
     */
    'generalInfo': Array<CopyEditAnalyticsValue>;
    /**
     * 
     * @type {Array<CopyEditAnalyticsChart>}
     * @memberof AnalyticsCopyEditResponse
     */
    'charts': Array<CopyEditAnalyticsChart>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum AnalyticsType {
    TotalUsers = 'TOTAL_USERS',
    DocumentsImproved = 'DOCUMENTS_IMPROVED',
    AvgWordsPerDocument = 'AVG_WORDS_PER_DOCUMENT',
    DocumentTopics = 'DOCUMENT_TOPICS',
    WritingSessions = 'WRITING_SESSIONS',
    DailyActiveUsersGraph = 'DAILY_ACTIVE_USERS_GRAPH',
    WritingSessionsGraph = 'WRITING_SESSIONS_GRAPH',
    SuggestionsGraph = 'SUGGESTIONS_GRAPH'
}

/**
 * Payload object for notification request on snippet comment
 * @export
 * @interface AnalyzersCompletionNotificationPayload
 */
export interface AnalyzersCompletionNotificationPayload {
    /**
     * 
     * @type {UserInfo}
     * @memberof AnalyzersCompletionNotificationPayload
     */
    'userTo': UserInfo;
    /**
     * 
     * @type {string}
     * @memberof AnalyzersCompletionNotificationPayload
     */
    'workspaceId': string;
    /**
     * 
     * @type {UserInfo}
     * @memberof AnalyzersCompletionNotificationPayload
     */
    'userFrom'?: UserInfo;
    /**
     * 
     * @type {Array<TaskInfo>}
     * @memberof AnalyzersCompletionNotificationPayload
     */
    'tasks'?: Array<TaskInfo>;
    /**
     * 
     * @type {DatasetInfo2}
     * @memberof AnalyzersCompletionNotificationPayload
     */
    'datasetInfo': DatasetInfo2;
    /**
     * 
     * @type {string}
     * @memberof AnalyzersCompletionNotificationPayload
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyzersCompletionNotificationPayload
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AnswerTypes {
    Text = 'TEXT',
    Image = 'IMAGE',
    Viz = 'VIZ',
    Table = 'TABLE',
    Dict = 'DICT',
    VizV2 = 'VIZ_V2',
    Sql = 'SQL',
    ErrorSql = 'ERROR_SQL'
}

/**
 * 
 * @export
 * @interface AppAnalyticsRequest
 */
export interface AppAnalyticsRequest {
    /**
     * 
     * @type {number}
     * @memberof AppAnalyticsRequest
     */
    'days': number;
}
/**
 * 
 * @export
 * @interface AppBuilder
 */
export interface AppBuilder {
    /**
     * 
     * @type {string}
     * @memberof AppBuilder
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof AppBuilder
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof AppBuilder
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof AppBuilder
     */
    'appId': string;
    /**
     * 
     * @type {string}
     * @memberof AppBuilder
     */
    'appName': string;
    /**
     * 
     * @type {string}
     * @memberof AppBuilder
     */
    'appDescription': string;
    /**
     * 
     * @type {AppBuilderClassKeys}
     * @memberof AppBuilder
     */
    'appType': AppBuilderClassKeys;
    /**
     * 
     * @type {AppBuilderStatusKeys}
     * @memberof AppBuilder
     */
    'appStatus': AppBuilderStatusKeys;
    /**
     * 
     * @type {string}
     * @memberof AppBuilder
     */
    'createDate': string;
    /**
     * 
     * @type {AppProperties}
     * @memberof AppBuilder
     */
    'appProperties': AppProperties;
    /**
     * 
     * @type {AppCategories}
     * @memberof AppBuilder
     */
    'appCategory': AppCategories;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum AppBuilderClassKeys {
    Classification = 'classification'
}

/**
 * DRAFT represents the state where the GenAI app is waiting for user action. For example, if the user needs to select the models they need to evaluate, the GenAI app will be in DRAFT state.  PROCESSING represents the state where the GenAI app is in `workflow` state but awaiting some result from backend. For example, if the evaluation of selected models is in progress, the GenAI app will be in PROCESSING state  LIVE represents the state where the GenAI app is active and can take user input to perform the desired action.  STOPPED represents the state where the GenAI app was created but then stopped from taking user input.  Lifecycle:     - Happy flow:         - (DRAFT -> PROCESSING) -> (DRAFT -> PROCESSING) .... -> LIVE -> STOPPED     - App rerun:         - STOPPED -> LIVE
 * @export
 * @enum {string}
 */

export enum AppBuilderStatusKeys {
    Draft = 'draft',
    Published = 'published',
    Archived = 'archived'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum AppCategories {
    ModelApp = 'model_app',
    AppBuilder = 'app_builder'
}

/**
 * App id for copy edit app
 * @export
 * @enum {string}
 */

export enum AppIdType {
    CopyEdit = 'COPY_EDIT',
    Summarization = 'SUMMARIZATION',
    QnaWithDoc = 'QNA_WITH_DOC'
}

/**
 * 
 * @export
 * @interface AppProperties
 */
export interface AppProperties {
    /**
     * 
     * @type {string}
     * @memberof AppProperties
     */
    'appContext': string;
    /**
     * 
     * @type {string}
     * @memberof AppProperties
     */
    'appPrompt': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppProperties
     */
    'examples': Array<string>;
    /**
     * 
     * @type {LLMModel}
     * @memberof AppProperties
     */
    'models': LLMModel;
}
/**
 * 
 * @export
 * @interface ApplicableFilter
 */
export interface ApplicableFilter {
    /**
     * 
     * @type {string}
     * @memberof ApplicableFilter
     */
    'field': string;
    /**
     * 
     * @type {Array<Operator>}
     * @memberof ApplicableFilter
     */
    'allowedOperators': Array<Operator>;
}
/**
 * Apply tags to a resource id
 * @export
 * @interface ApplyTagsRequest
 */
export interface ApplyTagsRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplyTagsRequest
     */
    'resourceId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplyTagsRequest
     */
    'tagIds': Array<string>;
    /**
     * 
     * @type {ResourceType}
     * @memberof ApplyTagsRequest
     */
    'resourceType': ResourceType;
}
/**
 * 
 * @export
 * @interface ApplyTagsResponse
 */
export interface ApplyTagsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ApplyTagsResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface ArchiveAppBuilderResponse
 */
export interface ArchiveAppBuilderResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ArchiveAppBuilderResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface AreEvaluationsComparableResponse
 */
export interface AreEvaluationsComparableResponse {
    /**
     * 
     * @type {EvaluationComparisonResultType}
     * @memberof AreEvaluationsComparableResponse
     */
    'response': EvaluationComparisonResultType;
    /**
     * 
     * @type {EvaluationComparisonData}
     * @memberof AreEvaluationsComparableResponse
     */
    'data'?: EvaluationComparisonData;
    /**
     * 
     * @type {string}
     * @memberof AreEvaluationsComparableResponse
     */
    'workspaceId': string;
}
/**
 * This model describes whether metrics for multiple evaluations are available or not
 * @export
 * @interface AreEvaluationsMetricAvailable
 */
export interface AreEvaluationsMetricAvailable {
    /**
     * 
     * @type {Array<AreSingleEvaluationMetricsAvailable>}
     * @memberof AreEvaluationsMetricAvailable
     */
    'response': Array<AreSingleEvaluationMetricsAvailable>;
    /**
     * 
     * @type {string}
     * @memberof AreEvaluationsMetricAvailable
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface AreExperimentsComparable
 */
export interface AreExperimentsComparable {
    /**
     * 
     * @type {ExperimentComparisonResultType}
     * @memberof AreExperimentsComparable
     */
    'returnCode': ExperimentComparisonResultType;
    /**
     * 
     * @type {Array<string>}
     * @memberof AreExperimentsComparable
     */
    'experimentIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AreExperimentsComparableResponse
 */
export interface AreExperimentsComparableResponse {
    /**
     * 
     * @type {AreExperimentsComparable}
     * @memberof AreExperimentsComparableResponse
     */
    'response': AreExperimentsComparable;
    /**
     * 
     * @type {string}
     * @memberof AreExperimentsComparableResponse
     */
    'workspaceId': string;
}
/**
 * This model describes whether metrics for a single evaluation are available or not Currently, it only gives availability of ROC and PR curve We will add information about other metrics going forward
 * @export
 * @interface AreSingleEvaluationMetricsAvailable
 */
export interface AreSingleEvaluationMetricsAvailable {
    /**
     * 
     * @type {string}
     * @memberof AreSingleEvaluationMetricsAvailable
     */
    'evaluationId': string;
    /**
     * 
     * @type {boolean}
     * @memberof AreSingleEvaluationMetricsAvailable
     */
    'isRocAndPrCurveAvailable': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ArtifactFilterState {
    Active = 'ACTIVE',
    Archived = 'ARCHIVED',
    PendingDelete = 'PENDING_DELETE'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ArtifactResourceType {
    Snippet = 'snippet',
    ProjectCopyEdit = 'project_copy_edit'
}

/**
 * 
 * @export
 * @interface ArtifactStateModel
 */
export interface ArtifactStateModel {
    /**
     * 
     * @type {string}
     * @memberof ArtifactStateModel
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtifactStateModel
     */
    'user_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtifactStateModel
     */
    'updated_date'?: string;
}
/**
 * 
 * @export
 * @interface ArtifactStateModelV2
 */
export interface ArtifactStateModelV2 {
    /**
     * 
     * @type {string}
     * @memberof ArtifactStateModelV2
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtifactStateModelV2
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ArtifactStateModelV2
     */
    'updatedDate': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ArtifactStateType {
    Active = 'ACTIVE',
    Archived = 'ARCHIVED',
    PendingDelete = 'PENDING_DELETE',
    Draft = 'DRAFT'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ArtifactStateTypes {
    Active = 'ACTIVE',
    Archived = 'ARCHIVED',
    PendingDelete = 'PENDING_DELETE',
    Draft = 'DRAFT'
}

/**
 * 
 * @export
 * @interface AssignUserToRelabelingRequest
 */
export interface AssignUserToRelabelingRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignUserToRelabelingRequest
     */
    'recordIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssignUserToRelabelingRequest
     */
    'assignTo': string;
}
/**
 * 
 * @export
 * @interface AssignUserToRelabelingResponse
 */
export interface AssignUserToRelabelingResponse {
    /**
     * 
     * @type {string}
     * @memberof AssignUserToRelabelingResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {AckResponse}
     * @memberof AssignUserToRelabelingResponse
     */
    'response': AckResponse;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum AsyncDropDownResources {
    Datasets = 'DATASETS',
    DataResources = 'DATA_RESOURCES',
    DataSinks = 'DATA_SINKS',
    WorkflowNodeColumns = 'WORKFLOW_NODE_COLUMNS',
    TopicModels = 'TOPIC_MODELS',
    WorkflowModels = 'WORKFLOW_MODELS',
    ClusteringModels = 'CLUSTERING_MODELS',
    S3Folders = 'S3_FOLDERS',
    GsheetWorksheets = 'GSHEET_WORKSHEETS'
}

/**
 * Async drop down resource pair
 * @export
 * @interface AsyncResourcePair
 */
export interface AsyncResourcePair {
    /**
     * 
     * @type {string}
     * @memberof AsyncResourcePair
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof AsyncResourcePair
     */
    'value': string;
    /**
     * Add extra details in free form dictionary format for client
     * @type {object}
     * @memberof AsyncResourcePair
     */
    'detail'?: object;
}
/**
 * Async drop down resource pair
 * @export
 * @interface AsyncResourceResponse
 */
export interface AsyncResourceResponse {
    /**
     * 
     * @type {Array<AsyncResourcePair>}
     * @memberof AsyncResourceResponse
     */
    'resources': Array<AsyncResourcePair>;
    /**
     * 
     * @type {AsyncDropDownResources}
     * @memberof AsyncResourceResponse
     */
    'resourceType': AsyncDropDownResources;
    /**
     * 
     * @type {number}
     * @memberof AsyncResourceResponse
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface AttachCustomDictionariesToDocumentRequest
 */
export interface AttachCustomDictionariesToDocumentRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AttachCustomDictionariesToDocumentRequest
     */
    'customDictionaryIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AttachCustomDictionariesToDocumentResponse
 */
export interface AttachCustomDictionariesToDocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof AttachCustomDictionariesToDocumentResponse
     */
    'documentId': string;
    /**
     * 
     * @type {string}
     * @memberof AttachCustomDictionariesToDocumentResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface AuthorInformationModel
 */
export interface AuthorInformationModel {
    /**
     * 
     * @type {PersonInfoModel | OrganizationInfoModel}
     * @memberof AuthorInformationModel
     */
    'authorInfo': PersonInfoModel | OrganizationInfoModel;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum AuthorRoleType {
    Author = 'author',
    Translator = 'translator',
    Director = 'director',
    Editor = 'editor',
    Illustrator = 'illustrator',
    Interviewer = 'interviewer',
    Uploader = 'uploader'
}

/**
 * This class defines the structure for the API response listing available analyses to the users. It\'s based on: https://meskalero.slab.com/posts/lld-api-design-dataset-registration-v-3-d1wvae6r  Attributes:     workspace_id (str): The ID of the workspace where the available analyses are listed.     response (TaskletsAndGroups): The details of available tasklets and their corresponding groups in the workspace.     num_records(int): Total number of tasklets
 * @export
 * @interface AvailableDatasetTaskletsResponse
 */
export interface AvailableDatasetTaskletsResponse {
    /**
     * 
     * @type {string}
     * @memberof AvailableDatasetTaskletsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {TaskletsAndGroups}
     * @memberof AvailableDatasetTaskletsResponse
     */
    'response': TaskletsAndGroups;
    /**
     * 
     * @type {number}
     * @memberof AvailableDatasetTaskletsResponse
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface AzureBlobConnectorMetadataModel
 */
export interface AzureBlobConnectorMetadataModel {
    /**
     * 
     * @type {string}
     * @memberof AzureBlobConnectorMetadataModel
     */
    'containerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AzureBlobConnectorMetadataModel
     */
    'containerUrl'?: string;
}
/**
 * Cloud providers have different credentials. These classes are to help enforce type safety.
 * @export
 * @interface AzureBlobCredentialModel
 */
export interface AzureBlobCredentialModel {
    /**
     * 
     * @type {string}
     * @memberof AzureBlobCredentialModel
     */
    'accessKey': string;
    /**
     * 
     * @type {string}
     * @memberof AzureBlobCredentialModel
     */
    'storageAccountName': string;
}
/**
 * 
 * @export
 * @interface AzureBlobStorageResourceModel
 */
export interface AzureBlobStorageResourceModel {
    /**
     * 
     * @type {string}
     * @memberof AzureBlobStorageResourceModel
     */
    'containerName': string;
    /**
     * 
     * @type {string}
     * @memberof AzureBlobStorageResourceModel
     */
    'folderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AzureBlobStorageResourceModel
     */
    'blobName'?: string;
}
/**
 * 
 * @export
 * @interface BaseAPIFilter
 */
export interface BaseAPIFilter {
    /**
     * 
     * @type {string}
     * @memberof BaseAPIFilter
     */
    'field': string;
    /**
     * 
     * @type {Operator}
     * @memberof BaseAPIFilter
     */
    'operator': Operator;
    /**
     * 
     * @type {any}
     * @memberof BaseAPIFilter
     */
    'value'?: any;
}
/**
 * 
 * @export
 * @interface BaseCitation
 */
export interface BaseCitation {
    /**
     * 
     * @type {CitationSourceType}
     * @memberof BaseCitation
     */
    'citationType': CitationSourceType;
    /**
     * Title of the reference
     * @type {string}
     * @memberof BaseCitation
     */
    'citationTitle': string;
    /**
     * 
     * @type {DateModel}
     * @memberof BaseCitation
     */
    'publishedDate'?: DateModel;
    /**
     * List of authors for the citation.
     * @type {Array<AuthorInformationModel>}
     * @memberof BaseCitation
     */
    'authors': Array<AuthorInformationModel>;
    /**
     * 
     * @type {string}
     * @memberof BaseCitation
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface BaseUrl
 */
export interface BaseUrl {
    /**
     * 
     * @type {string}
     * @memberof BaseUrl
     */
    'baseUrl': string;
}
/**
 * 
 * @export
 * @interface BaseUrlResponse
 */
export interface BaseUrlResponse {
    /**
     * 
     * @type {BaseUrl}
     * @memberof BaseUrlResponse
     */
    'response': BaseUrl;
    /**
     * 
     * @type {boolean}
     * @memberof BaseUrlResponse
     */
    'ack': boolean;
}
/**
 * Base User for API Responses
 * @export
 * @interface BaseUser
 */
export interface BaseUser {
    /**
     * 
     * @type {any}
     * @memberof BaseUser
     */
    'userId': any;
    /**
     * 
     * @type {string}
     * @memberof BaseUser
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BaseUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof BaseUser
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof BaseUser
     */
    'countryCode': string;
    /**
     * 
     * @type {UserProfileState}
     * @memberof BaseUser
     */
    'state'?: UserProfileState;
    /**
     * 
     * @type {UserType}
     * @memberof BaseUser
     */
    'userType'?: UserType;
    /**
     * 
     * @type {string}
     * @memberof BaseUser
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof BaseUser
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof BaseUser
     */
    'alias'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUser
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUser
     */
    'cliVersion'?: string;
    /**
     * 
     * @type {object}
     * @memberof BaseUser
     */
    'info'?: object;
    /**
     * 
     * @type {object}
     * @memberof BaseUser
     */
    'onboardingInfo'?: object;
}
/**
 * Base User Object for Create User APIs
 * @export
 * @interface BaseUserCreate
 */
export interface BaseUserCreate {
    /**
     * 
     * @type {string}
     * @memberof BaseUserCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BaseUserCreate
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof BaseUserCreate
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof BaseUserCreate
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUserCreate
     */
    'alias'?: string;
    /**
     * 
     * @type {object}
     * @memberof BaseUserCreate
     */
    'info'?: object;
}
/**
 * Base User Object for update related APIs
 * @export
 * @interface BaseUserUpdate
 */
export interface BaseUserUpdate {
    /**
     * 
     * @type {string}
     * @memberof BaseUserUpdate
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUserUpdate
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUserUpdate
     */
    'avatar'?: string;
    /**
     * 
     * @type {object}
     * @memberof BaseUserUpdate
     */
    'info'?: object;
    /**
     * 
     * @type {UserType}
     * @memberof BaseUserUpdate
     */
    'userType'?: UserType;
    /**
     * 
     * @type {object}
     * @memberof BaseUserUpdate
     */
    'onboardingInfo'?: object;
    /**
     * 
     * @type {string}
     * @memberof BaseUserUpdate
     */
    'cliVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUserUpdate
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface BaselineMetricsForClassification
 */
export interface BaselineMetricsForClassification {
    /**
     * 
     * @type {number}
     * @memberof BaselineMetricsForClassification
     */
    'precision'?: number;
    /**
     * 
     * @type {number}
     * @memberof BaselineMetricsForClassification
     */
    'recall'?: number;
    /**
     * 
     * @type {number}
     * @memberof BaselineMetricsForClassification
     */
    'f1score'?: number;
    /**
     * 
     * @type {number}
     * @memberof BaselineMetricsForClassification
     */
    'accuracy'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaselineMetricsForClassification
     */
    'modelType'?: BaselineMetricsForClassificationModelTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BaselineMetricsForClassificationModelTypeEnum {
    Classification = 'classification'
}

/**
 * 
 * @export
 * @interface BaselineMetricsForRegression
 */
export interface BaselineMetricsForRegression {
    /**
     * 
     * @type {number}
     * @memberof BaselineMetricsForRegression
     */
    'r2'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaselineMetricsForRegression
     */
    'modelType'?: BaselineMetricsForRegressionModelTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BaselineMetricsForRegressionModelTypeEnum {
    Regression = 'regression'
}

/**
 * 
 * @export
 * @interface BaselineMetricsResponse
 */
export interface BaselineMetricsResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof BaselineMetricsResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {BaselineMetricsForClassification | BaselineMetricsForRegression}
     * @memberof BaselineMetricsResponse
     */
    'response'?: BaselineMetricsForClassification | BaselineMetricsForRegression;
    /**
     * 
     * @type {string}
     * @memberof BaselineMetricsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof BaselineMetricsResponse
     */
    'datasetId': string;
}
/**
 * Lite weight user details object for api response for APIs such as Activity, Workspace Members Snapshot, etc.
 * @export
 * @interface BasicUserDetails
 */
export interface BasicUserDetails {
    /**
     * 
     * @type {any}
     * @memberof BasicUserDetails
     */
    'userId': any;
    /**
     * 
     * @type {string}
     * @memberof BasicUserDetails
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserDetails
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserDetails
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserDetails
     */
    'avatar'?: string;
}
/**
 * 
 * @export
 * @interface BodyAssignRoleToUserV1
 */
export interface BodyAssignRoleToUserV1 {
    /**
     * 
     * @type {string}
     * @memberof BodyAssignRoleToUserV1
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface BodyCheckEmailAvailableV1
 */
export interface BodyCheckEmailAvailableV1 {
    /**
     * 
     * @type {string}
     * @memberof BodyCheckEmailAvailableV1
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface BodyCheckUsernameUniqueV1
 */
export interface BodyCheckUsernameUniqueV1 {
    /**
     * 
     * @type {string}
     * @memberof BodyCheckUsernameUniqueV1
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface BodyCreateDemoWorkspaceV1
 */
export interface BodyCreateDemoWorkspaceV1 {
    /**
     * 
     * @type {string}
     * @memberof BodyCreateDemoWorkspaceV1
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface BodyCreateOrUpdateRBACRoleV1
 */
export interface BodyCreateOrUpdateRBACRoleV1 {
    /**
     * 
     * @type {string}
     * @memberof BodyCreateOrUpdateRBACRoleV1
     */
    'role_name': string;
    /**
     * 
     * @type {string}
     * @memberof BodyCreateOrUpdateRBACRoleV1
     */
    'role_description': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof BodyCreateOrUpdateRBACRoleV1
     */
    'scope_ids': Array<any>;
}
/**
 * 
 * @export
 * @interface BodyCreateOrUpdateRBACScopeV1
 */
export interface BodyCreateOrUpdateRBACScopeV1 {
    /**
     * 
     * @type {string}
     * @memberof BodyCreateOrUpdateRBACScopeV1
     */
    'scope_name': string;
    /**
     * 
     * @type {string}
     * @memberof BodyCreateOrUpdateRBACScopeV1
     */
    'scope_description': string;
}
/**
 * 
 * @export
 * @interface BodyCreatePioneerWorkspaceV1
 */
export interface BodyCreatePioneerWorkspaceV1 {
    /**
     * 
     * @type {string}
     * @memberof BodyCreatePioneerWorkspaceV1
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface BodyCreateTestingWorkspaceV2
 */
export interface BodyCreateTestingWorkspaceV2 {
    /**
     * 
     * @type {string}
     * @memberof BodyCreateTestingWorkspaceV2
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface BodyDeleteTestingWorkspaceV1
 */
export interface BodyDeleteTestingWorkspaceV1 {
    /**
     * 
     * @type {string}
     * @memberof BodyDeleteTestingWorkspaceV1
     */
    'workspace_id': string;
}
/**
 * 
 * @export
 * @interface BodyFetchPageSuggestionsFastV1
 */
export interface BodyFetchPageSuggestionsFastV1 {
    /**
     * 
     * @type {string}
     * @memberof BodyFetchPageSuggestionsFastV1
     */
    'page_text': string;
}
/**
 * 
 * @export
 * @interface BodyGetEmailInfoV1
 */
export interface BodyGetEmailInfoV1 {
    /**
     * 
     * @type {string}
     * @memberof BodyGetEmailInfoV1
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface BodyMigrateSuggestionsRulesV1
 */
export interface BodyMigrateSuggestionsRulesV1 {
    /**
     * 
     * @type {object}
     * @memberof BodyMigrateSuggestionsRulesV1
     */
    'query': object;
}
/**
 * 
 * @export
 * @interface BodyParseAndAddBulkCitationTextV1
 */
export interface BodyParseAndAddBulkCitationTextV1 {
    /**
     * 
     * @type {string}
     * @memberof BodyParseAndAddBulkCitationTextV1
     */
    'citation_text': string;
}
/**
 * 
 * @export
 * @interface BodyParseCitationTextV1
 */
export interface BodyParseCitationTextV1 {
    /**
     * 
     * @type {string}
     * @memberof BodyParseCitationTextV1
     */
    'citation_text': string;
}
/**
 * 
 * @export
 * @interface BodyPredictDataCategoryV1
 */
export interface BodyPredictDataCategoryV1 {
    /**
     * 
     * @type {DatasetPreviewData}
     * @memberof BodyPredictDataCategoryV1
     */
    'dataset_preview': DatasetPreviewData;
    /**
     * 
     * @type {Array<string>}
     * @memberof BodyPredictDataCategoryV1
     */
    'features'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BodyPredictDataCategoryV1
     */
    'target'?: string;
}
/**
 * 
 * @export
 * @interface BodyRegisterAppDocumentV2
 */
export interface BodyRegisterAppDocumentV2 {
    /**
     * 
     * @type {Array<StyleGuideType>}
     * @memberof BodyRegisterAppDocumentV2
     */
    'style_guides'?: Array<StyleGuideType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BodyRegisterAppDocumentV2
     */
    'custom_style_rules'?: Array<string>;
}
/**
 * 
 * @export
 * @interface BodyRegisterAppDocumentV3
 */
export interface BodyRegisterAppDocumentV3 {
    /**
     * 
     * @type {Array<StyleGuideType>}
     * @memberof BodyRegisterAppDocumentV3
     */
    'style_guides'?: Array<StyleGuideType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BodyRegisterAppDocumentV3
     */
    'custom_style_rules'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BodyRegisterAppDocumentV3
     */
    'custom_dictionary_ids'?: Array<string>;
    /**
     * 
     * @type {CitationStyleGuideType}
     * @memberof BodyRegisterAppDocumentV3
     */
    'citation_style'?: CitationStyleGuideType;
}
/**
 * 
 * @export
 * @interface BodyRemoveWorkspaceShareResourceV1
 */
export interface BodyRemoveWorkspaceShareResourceV1 {
    /**
     * 
     * @type {string}
     * @memberof BodyRemoveWorkspaceShareResourceV1
     */
    'resource_id': string;
    /**
     * 
     * @type {ArtifactResourceType}
     * @memberof BodyRemoveWorkspaceShareResourceV1
     */
    'resource_type': ArtifactResourceType;
}
/**
 * 
 * @export
 * @interface BodySaveDocumentSummaryV1
 */
export interface BodySaveDocumentSummaryV1 {
    /**
     * 
     * @type {string}
     * @memberof BodySaveDocumentSummaryV1
     */
    'formatted_document_summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof BodySaveDocumentSummaryV1
     */
    'binary_formatted_document_summary'?: string;
}
/**
 * 
 * @export
 * @interface BodySaveTaskletSelectionsV1
 */
export interface BodySaveTaskletSelectionsV1 {
    /**
     * 
     * @type {Array<string>}
     * @memberof BodySaveTaskletSelectionsV1
     */
    'tasklet_ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BodySaveTaskletSelectionsV1
     */
    'selection_name': string;
    /**
     * 
     * @type {DataCategory}
     * @memberof BodySaveTaskletSelectionsV1
     */
    'dataset_category'?: DataCategory;
}
/**
 * 
 * @export
 * @interface BodyShareResourceWithWorkspaceV1
 */
export interface BodyShareResourceWithWorkspaceV1 {
    /**
     * 
     * @type {string}
     * @memberof BodyShareResourceWithWorkspaceV1
     */
    'resource_id': string;
    /**
     * 
     * @type {ArtifactResourceType}
     * @memberof BodyShareResourceWithWorkspaceV1
     */
    'resource_type': ArtifactResourceType;
}
/**
 * 
 * @export
 * @interface BookInformationModel
 */
export interface BookInformationModel {
    /**
     * 
     * @type {CitationSourceType}
     * @memberof BookInformationModel
     */
    'citationType': CitationSourceType;
    /**
     * Title of the reference
     * @type {string}
     * @memberof BookInformationModel
     */
    'citationTitle': string;
    /**
     * 
     * @type {DateModel}
     * @memberof BookInformationModel
     */
    'publishedDate'?: DateModel;
    /**
     * List of authors for the citation.
     * @type {Array<AuthorInformationModel>}
     * @memberof BookInformationModel
     */
    'authors': Array<AuthorInformationModel>;
    /**
     * 
     * @type {string}
     * @memberof BookInformationModel
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookInformationModel
     */
    'bookEdition'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookInformationModel
     */
    'bookVolume'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookInformationModel
     */
    'bookMedium'?: string;
    /**
     * 
     * @type {PublisherInfoModel}
     * @memberof BookInformationModel
     */
    'publisherInfo'?: PublisherInfoModel;
    /**
     * 
     * @type {string}
     * @memberof BookInformationModel
     */
    'pdf'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookInformationModel
     */
    'doi'?: string;
}
/**
 * 
 * @export
 * @interface BulkGetUsersRequest
 */
export interface BulkGetUsersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkGetUsersRequest
     */
    'userIds': Array<string>;
}
/**
 * Response of user\'s basic details in bulk. It contains map of user_id to basic details.
 * @export
 * @interface BulkGetUsersResponse
 */
export interface BulkGetUsersResponse {
    /**
     * 
     * @type {{ [key: string]: BasicUserDetails; }}
     * @memberof BulkGetUsersResponse
     */
    'userDetails': { [key: string]: BasicUserDetails; };
}
/**
 * payload: List of any, here `any` should be a child class of `BaseNotificationPayload`
 * @export
 * @interface BulkNotifyRequestModel
 */
export interface BulkNotifyRequestModel {
    /**
     * 
     * @type {string}
     * @memberof BulkNotifyRequestModel
     */
    'originOfService': string;
    /**
     * 
     * @type {string}
     * @memberof BulkNotifyRequestModel
     */
    'notificationType': string;
    /**
     * 
     * @type {Array<SnippetInvitationPayload | SnippetCommentNotificationPayload | AnalyzersCompletionNotificationPayload | UserMentionsOnSnippetContentRequestPayload | DefaultAlertNotificationPayload | ZurichAlertNotificationPayload | ModelAppsReadyNotificationPayload | UserWorkspaceInvitationPayload>}
     * @memberof BulkNotifyRequestModel
     */
    'payload': Array<SnippetInvitationPayload | SnippetCommentNotificationPayload | AnalyzersCompletionNotificationPayload | UserMentionsOnSnippetContentRequestPayload | DefaultAlertNotificationPayload | ZurichAlertNotificationPayload | ModelAppsReadyNotificationPayload | UserWorkspaceInvitationPayload>;
}
/**
 * 
 * @export
 * @interface BulkNotifyResponseModel
 */
export interface BulkNotifyResponseModel {
    /**
     * 
     * @type {Array<NotifyResponseModel>}
     * @memberof BulkNotifyResponseModel
     */
    'response': Array<NotifyResponseModel>;
}
/**
 * 
 * @export
 * @interface BulkStopAnalysersRequest
 */
export interface BulkStopAnalysersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkStopAnalysersRequest
     */
    'taskIds': Array<string>;
}
/**
 * 
 * @export
 * @interface BulkUploadRulesResponse
 */
export interface BulkUploadRulesResponse {
    /**
     * 
     * @type {string}
     * @memberof BulkUploadRulesResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof BulkUploadRulesResponse
     */
    'ruleSetId': string;
    /**
     * 
     * @type {boolean}
     * @memberof BulkUploadRulesResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof BulkUploadRulesResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface CDESegmentClassData
 */
export interface CDESegmentClassData {
    /**
     * 
     * @type {number}
     * @memberof CDESegmentClassData
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof CDESegmentClassData
     */
    'name': string;
    /**
     * 
     * @type {object}
     * @memberof CDESegmentClassData
     */
    'metaData'?: object;
}
/**
 * Response for CLI version get/update
 * @export
 * @interface CLIVersionResponse
 */
export interface CLIVersionResponse {
    /**
     * 
     * @type {string}
     * @memberof CLIVersionResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof CLIVersionResponse
     */
    'cliVersion': string;
}
/**
 * 
 * @export
 * @interface ChartData
 */
export interface ChartData {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChartData
     */
    'categories': Array<string>;
    /**
     * 
     * @type {Array<ChartValues>}
     * @memberof ChartData
     */
    'series': Array<ChartValues>;
}
/**
 * 
 * @export
 * @interface ChartMetadata
 */
export interface ChartMetadata {
    /**
     * 
     * @type {string}
     * @memberof ChartMetadata
     */
    'xLabel': string;
    /**
     * 
     * @type {string}
     * @memberof ChartMetadata
     */
    'yLabel': string;
    /**
     * 
     * @type {string}
     * @memberof ChartMetadata
     */
    'xUnit'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChartMetadata
     */
    'yUnit'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ChartTypes {
    Line = 'line',
    BarChart = 'bar_chart',
    Histogram = 'histogram',
    PieChart = 'pie_chart',
    BubbleChart = 'bubble_chart',
    VerticalHistogram = 'vertical_histogram',
    KdePlot = 'kde_plot',
    NormalQqPlot = 'normal_qq_plot',
    BoxPlot = 'box_plot',
    WordCloud = 'word_cloud',
    Table = 'table'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ChartTypes1 {
    Line = 'line',
    BarChart = 'bar_chart',
    Histogram = 'histogram',
    PieChart = 'pie_chart',
    BubbleChart = 'bubble_chart',
    VerticalHistogram = 'vertical_histogram',
    KdePlot = 'kde_plot',
    NormalQqPlot = 'normal_qq_plot',
    BoxPlot = 'box_plot',
    WordCloud = 'word_cloud',
    Table = 'table'
}

/**
 * 
 * @export
 * @interface ChartValues
 */
export interface ChartValues {
    /**
     * 
     * @type {string}
     * @memberof ChartValues
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChartValues
     */
    'data': Array<number>;
}
/**
 * 
 * @export
 * @interface ChartingResponse
 */
export interface ChartingResponse {
    /**
     * 
     * @type {ChartMetadata}
     * @memberof ChartingResponse
     */
    'metadata': ChartMetadata;
    /**
     * 
     * @type {ChartData}
     * @memberof ChartingResponse
     */
    'data': ChartData;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ChatConversationAnswerTypes {
    Text = 'TEXT',
    Image = 'IMAGE',
    Viz = 'VIZ',
    Table = 'TABLE'
}

/**
 * 
 * @export
 * @interface ChatConversationModel
 */
export interface ChatConversationModel {
    /**
     * 
     * @type {string}
     * @memberof ChatConversationModel
     */
    'threadId': string;
    /**
     * 
     * @type {string}
     * @memberof ChatConversationModel
     */
    'conversationId': string;
    /**
     * 
     * @type {string}
     * @memberof ChatConversationModel
     */
    'question': string;
    /**
     * 
     * @type {ChatConversationQuestionTypes}
     * @memberof ChatConversationModel
     */
    'questionType': ChatConversationQuestionTypes;
    /**
     * 
     * @type {WorkflowSimilarityResponse}
     * @memberof ChatConversationModel
     */
    'answer': WorkflowSimilarityResponse;
    /**
     * 
     * @type {ChatConversationAnswerTypes}
     * @memberof ChatConversationModel
     */
    'answerType': ChatConversationAnswerTypes;
    /**
     * 
     * @type {boolean}
     * @memberof ChatConversationModel
     */
    'isAnswerEdited'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChatConversationModel
     */
    'createDate': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ChatConversationQuestionTypes {
    Text = 'text',
    Text2sql = 'text2sql',
    Sql = 'sql'
}

/**
 * 
 * @export
 * @interface ChatConversationRequestModel
 */
export interface ChatConversationRequestModel {
    /**
     * 
     * @type {string}
     * @memberof ChatConversationRequestModel
     */
    'threadId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatConversationRequestModel
     */
    'query': string;
    /**
     * 
     * @type {ChatConversationQuestionTypes}
     * @memberof ChatConversationRequestModel
     */
    'queryType'?: ChatConversationQuestionTypes;
}
/**
 * 
 * @export
 * @interface ChatConversationThreadModel
 */
export interface ChatConversationThreadModel {
    /**
     * 
     * @type {string}
     * @memberof ChatConversationThreadModel
     */
    'threadId': string;
    /**
     * 
     * @type {string}
     * @memberof ChatConversationThreadModel
     */
    'threadTitle': string;
    /**
     * 
     * @type {string}
     * @memberof ChatConversationThreadModel
     */
    'createDate': string;
    /**
     * Fetch artifacts based on query param filter ACTIVE/ARCHIVED
     * @type {ArtifactStateModelV2}
     * @memberof ChatConversationThreadModel
     */
    'artifactState': ArtifactStateModelV2;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ChatItemType {
    Prompt = 'Prompt',
    Answer = 'Answer'
}

/**
 * 
 * @export
 * @interface ChatterRequestModel
 */
export interface ChatterRequestModel {
    /**
     * 
     * @type {string}
     * @memberof ChatterRequestModel
     */
    'threadId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatterRequestModel
     */
    'question': string;
    /**
     * 
     * @type {QuestionTypes}
     * @memberof ChatterRequestModel
     */
    'questionType': QuestionTypes;
}
/**
 * 
 * @export
 * @interface ChatterRequestModelV2
 */
export interface ChatterRequestModelV2 {
    /**
     * 
     * @type {string}
     * @memberof ChatterRequestModelV2
     */
    'chat': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatterRequestModelV2
     */
    'recordIdList'?: Array<string>;
    /**
     * 
     * @type {LLMTypes}
     * @memberof ChatterRequestModelV2
     */
    'llm'?: LLMTypes;
}
/**
 * 
 * @export
 * @interface ChatterResponseModel
 */
export interface ChatterResponseModel {
    /**
     * 
     * @type {string}
     * @memberof ChatterResponseModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof ChatterResponseModel
     */
    'dsId': string;
    /**
     * 
     * @type {LLMResponse}
     * @memberof ChatterResponseModel
     */
    'llmResponse': LLMResponse;
}
/**
 * 
 * @export
 * @interface ChatterSuggestedQuestionsResponseModel
 */
export interface ChatterSuggestedQuestionsResponseModel {
    /**
     * 
     * @type {string}
     * @memberof ChatterSuggestedQuestionsResponseModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof ChatterSuggestedQuestionsResponseModel
     */
    'dsId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatterSuggestedQuestionsResponseModel
     */
    'suggestedQuestions': Array<string>;
}
/**
 * 
 * @export
 * @interface CheckContentPlagiarismRequestModel
 */
export interface CheckContentPlagiarismRequestModel {
    /**
     * 
     * @type {string}
     * @memberof CheckContentPlagiarismRequestModel
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface CheckContentPlagiarismResponse
 */
export interface CheckContentPlagiarismResponse {
    /**
     * 
     * @type {string}
     * @memberof CheckContentPlagiarismResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof CheckContentPlagiarismResponse
     */
    'scanId': string;
    /**
     * 
     * @type {PlagiarismCheckStatusType}
     * @memberof CheckContentPlagiarismResponse
     */
    'status': PlagiarismCheckStatusType;
    /**
     * 
     * @type {number}
     * @memberof CheckContentPlagiarismResponse
     */
    'plagiarismScore'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CheckContentPlagiarismResponse
     */
    'matchedLinks'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CheckContentPlagiarismResponse
     */
    'matchedLinksCount'?: number;
    /**
     * 
     * @type {PlagiarismResponse}
     * @memberof CheckContentPlagiarismResponse
     */
    'plagiarismResponse'?: PlagiarismResponse;
    /**
     * 
     * @type {AdditionalData}
     * @memberof CheckContentPlagiarismResponse
     */
    'aiContentScore'?: AdditionalData;
}
/**
 * Send the normalized name of the secret along with data to see if it exists
 * @export
 * @interface CheckCredentialByNormNameRequest
 */
export interface CheckCredentialByNormNameRequest {
    /**
     * 
     * @type {object}
     * @memberof CheckCredentialByNormNameRequest
     */
    'data': object;
    /**
     * 
     * @type {string}
     * @memberof CheckCredentialByNormNameRequest
     */
    'norm_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CheckCredentialByNormNameRequest
     */
    'send_secret'?: boolean;
}
/**
 * 
 * @export
 * @interface CheckSecretDataResponse
 */
export interface CheckSecretDataResponse {
    /**
     * 
     * @type {SecretModel}
     * @memberof CheckSecretDataResponse
     */
    'response': SecretModel;
    /**
     * 
     * @type {string}
     * @memberof CheckSecretDataResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CheckSecretDataResponse
     */
    'dataMatch'?: boolean;
}
/**
 * 
 * @export
 * @interface CheckUserAccessRequest
 */
export interface CheckUserAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof CheckUserAccessRequest
     */
    'resourceName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CheckUserAccessRequest
     */
    'scopes': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CheckUserAccessRequest
     */
    'rbacDomain': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CheckoutSessionModeType {
    Payment = 'payment',
    Setup = 'setup',
    Subscription = 'subscription'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum CheckoutSessionPaymentStatusType {
    NoPaymentRequired = 'no_payment_required',
    Paid = 'paid',
    Unpaid = 'unpaid'
}

/**
 * Response model for retrieving checkout session details.
 * @export
 * @interface CheckoutSessionResponseModel
 */
export interface CheckoutSessionResponseModel {
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionResponseModel
     */
    'checkoutSessionId': string;
    /**
     * 
     * @type {CheckoutSessionStatusType}
     * @memberof CheckoutSessionResponseModel
     */
    'checkoutSessionStatus': CheckoutSessionStatusType;
    /**
     * 
     * @type {CheckoutSessionPaymentStatusType}
     * @memberof CheckoutSessionResponseModel
     */
    'checkoutSessionPaymentStatus': CheckoutSessionPaymentStatusType;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionResponseModel
     */
    'customerId': string;
    /**
     * 
     * @type {number}
     * @memberof CheckoutSessionResponseModel
     */
    'amountTotal': number;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionResponseModel
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionResponseModel
     */
    'subscription': string;
    /**
     * 
     * @type {CheckoutSessionModeType}
     * @memberof CheckoutSessionResponseModel
     */
    'mode': CheckoutSessionModeType;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionResponseModel
     */
    'customerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionResponseModel
     */
    'clientReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionResponseModel
     */
    'clientSecret': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CheckoutSessionStatusType {
    Complete = 'complete',
    Expired = 'expired',
    Open = 'open'
}

/**
 * response for the chunk api
 * @export
 * @interface ChunkResponse
 */
export interface ChunkResponse {
    /**
     * 
     * @type {number}
     * @memberof ChunkResponse
     */
    'chunkId': number;
    /**
     * 
     * @type {DocumentChunkStatusType}
     * @memberof ChunkResponse
     */
    'status': DocumentChunkStatusType;
    /**
     * 
     * @type {ParsedChunk}
     * @memberof ChunkResponse
     */
    'parsedChunk': ParsedChunk;
    /**
     * 
     * @type {Array<Suggestion>}
     * @memberof ChunkResponse
     */
    'suggestions': Array<Suggestion>;
}
/**
 * 
 * @export
 * @interface Citation
 */
export interface Citation {
    /**
     * 
     * @type {any}
     * @memberof Citation
     */
    'rawCitationDetails'?: any;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum CitationAPIType {
    Arxiv = 'arxiv',
    Perplexity = 'perplexity'
}

/**
 * 
 * @export
 * @interface CitationModelsResponse
 */
export interface CitationModelsResponse {
    /**
     * 
     * @type {BaseCitation | JournalInformationModel | WebsiteInformationModel | BookInformationModel | OthersInformationModel}
     * @memberof CitationModelsResponse
     */
    'citationModels': BaseCitation | JournalInformationModel | WebsiteInformationModel | BookInformationModel | OthersInformationModel;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum CitationPositionType {
    InText = 'in_text',
    Bibliography = 'bibliography'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum CitationSourceType {
    JournalArticle = 'journal_article',
    Book = 'book',
    NewspaperArticle = 'newspaper_article',
    Report = 'report',
    Video = 'video',
    Website = 'website',
    Others = 'others'
}

/**
 * Citation Style Guide Type for a document.
 * @export
 * @enum {string}
 */

export enum CitationStyleGuideType {
    Cmos = 'CMOS',
    Apa = 'APA',
    None = 'NONE'
}

/**
 * 
 * @export
 * @interface ClassOverlapInfo
 */
export interface ClassOverlapInfo {
    /**
     * 
     * @type {string}
     * @memberof ClassOverlapInfo
     */
    'primaryClass': string;
    /**
     * 
     * @type {string}
     * @memberof ClassOverlapInfo
     */
    'secondaryClass': string;
    /**
     * 
     * @type {number}
     * @memberof ClassOverlapInfo
     */
    'overlapCount': number;
    /**
     * 
     * @type {number}
     * @memberof ClassOverlapInfo
     */
    'overlapProb': number;
}
/**
 * 
 * @export
 * @interface ClassOverlapInfoResponse
 */
export interface ClassOverlapInfoResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof ClassOverlapInfoResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {Array<ClassOverlapInfo>}
     * @memberof ClassOverlapInfoResponse
     */
    'response': Array<ClassOverlapInfo>;
    /**
     * 
     * @type {string}
     * @memberof ClassOverlapInfoResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof ClassOverlapInfoResponse
     */
    'datasetId': string;
    /**
     * 
     * @type {number}
     * @memberof ClassOverlapInfoResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface ClassWisePerformance
 */
export interface ClassWisePerformance {
    /**
     * 
     * @type {number}
     * @memberof ClassWisePerformance
     */
    'precision': number;
    /**
     * 
     * @type {number}
     * @memberof ClassWisePerformance
     */
    'recall': number;
    /**
     * 
     * @type {string}
     * @memberof ClassWisePerformance
     */
    'classLabel': string;
}
/**
 * 
 * @export
 * @interface ClassificationClassMetrics
 */
export interface ClassificationClassMetrics {
    /**
     * 
     * @type {number}
     * @memberof ClassificationClassMetrics
     */
    'precision': number;
    /**
     * 
     * @type {number}
     * @memberof ClassificationClassMetrics
     */
    'recall': number;
    /**
     * 
     * @type {number}
     * @memberof ClassificationClassMetrics
     */
    'f1-score': number;
    /**
     * 
     * @type {number}
     * @memberof ClassificationClassMetrics
     */
    'support'?: number;
}
/**
 * 
 * @export
 * @interface ClassificationMetricsInfo
 */
export interface ClassificationMetricsInfo {
    /**
     * 
     * @type {string}
     * @memberof ClassificationMetricsInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ClassificationMetricsInfo
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ClassificationOverallMetrics
 */
export interface ClassificationOverallMetrics {
    /**
     * 
     * @type {number}
     * @memberof ClassificationOverallMetrics
     */
    'accuracy': number;
    /**
     * 
     * @type {ClassificationClassMetrics}
     * @memberof ClassificationOverallMetrics
     */
    'macroAvg': ClassificationClassMetrics;
    /**
     * 
     * @type {ClassificationClassMetrics}
     * @memberof ClassificationOverallMetrics
     */
    'weightedAvg': ClassificationClassMetrics;
}
/**
 * 
 * @export
 * @interface CloneWorkflowRequest
 */
export interface CloneWorkflowRequest {
    /**
     * 
     * @type {string}
     * @memberof CloneWorkflowRequest
     */
    'workflowId': string;
    /**
     * 
     * @type {string}
     * @memberof CloneWorkflowRequest
     */
    'workflowName': string;
}
/**
 * 
 * @export
 * @interface CloneWorkflowResponse
 */
export interface CloneWorkflowResponse {
    /**
     * 
     * @type {string}
     * @memberof CloneWorkflowResponse
     */
    'workflowId': string;
    /**
     * 
     * @type {string}
     * @memberof CloneWorkflowResponse
     */
    'dagId': string;
}
/**
 * 
 * @export
 * @interface ClusterRawDataResponse
 */
export interface ClusterRawDataResponse {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ClusterRawDataResponse
     */
    'recordIdMapping': { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof ClusterRawDataResponse
     */
    'colsUsed'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ClusteringRawDataInternalRequest
 */
export interface ClusteringRawDataInternalRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClusteringRawDataInternalRequest
     */
    'recordIdList': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClusteringRawDataInternalRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ClusteringRawDataInternalRequest
     */
    'teamId': string;
}
/**
 * 
 * @export
 * @interface ClusteringRawDataRequest
 */
export interface ClusteringRawDataRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClusteringRawDataRequest
     */
    'recordIdList': Array<string>;
}
/**
 * 
 * @export
 * @interface ClusteringRawDataResponse
 */
export interface ClusteringRawDataResponse {
    /**
     * 
     * @type {ClusterRawDataResponse}
     * @memberof ClusteringRawDataResponse
     */
    'response': ClusterRawDataResponse;
    /**
     * 
     * @type {string}
     * @memberof ClusteringRawDataResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ClusteringSimilarityResponseV1
 */
export interface ClusteringSimilarityResponseV1 {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClusteringSimilarityResponseV1
     */
    'recordIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClusteringSimilarityResponseV1
     */
    'workspaceId': string;
}
/**
 * Base class that serves as the foundation for all graph related responses. It includes an attribute analysis_state, which represents the state of analysis run for a dataset. The possible analysis states are defined in the AnalysisStateNames enum.
 * @export
 * @interface ClusteringStateResponse
 */
export interface ClusteringStateResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof ClusteringStateResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof ClusteringStateResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<DataAnalysesStatus>}
     * @memberof ClusteringStateResponse
     */
    'details': Array<DataAnalysesStatus>;
}
/**
 * Describes details of a column in a dataset.  Attributes: - name (str): The name of the column. - type (str): The data type of the column.
 * @export
 * @interface ColumnDetail
 */
export interface ColumnDetail {
    /**
     * 
     * @type {string}
     * @memberof ColumnDetail
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ColumnDetail
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ColumnExpression
 */
export interface ColumnExpression {
    /**
     * 
     * @type {ExpressionType}
     * @memberof ColumnExpression
     */
    'type': ExpressionType;
    /**
     * 
     * @type {string}
     * @memberof ColumnExpression
     */
    'column': string;
    /**
     * 
     * @type {string | number}
     * @memberof ColumnExpression
     */
    'value'?: string | number;
}
/**
 * 
 * @export
 * @interface ColumnSchema
 */
export interface ColumnSchema {
    /**
     * 
     * @type {string}
     * @memberof ColumnSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ColumnSchema
     */
    'type': string;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnSchema
     */
    'placeholder'?: boolean;
}
/**
 * Categorical data has value defined in a fixed set. E.g. Days of Week, Months of Year
 * @export
 * @enum {string}
 */

export enum ColumnType {
    Categorical = 'categorical',
    CatOrdinal = 'cat_ordinal',
    CatNominal = 'cat_nominal',
    Numeric = 'numeric',
    NumContinuous = 'num_continuous',
    NumDiscrete = 'num_discrete',
    NumSmallCard = 'num_small_card',
    Text = 'text',
    DateTime = 'date_time',
    Email = 'email',
    Country = 'country',
    PhoneNumber = 'phone_number'
}

/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'commentId': string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'addedBy': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Comment
     */
    'mentions'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'userName'?: string;
}
/**
 * 
 * @export
 * @interface CommentModel
 */
export interface CommentModel {
    /**
     * 
     * @type {string}
     * @memberof CommentModel
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CommentModel
     */
    'addedBy'?: string;
    /**
     * 
     * @type {Array<UserMention>}
     * @memberof CommentModel
     */
    'mentions'?: Array<UserMention>;
    /**
     * 
     * @type {string}
     * @memberof CommentModel
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentModel
     */
    'updateDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentModel
     */
    'commentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentModel
     */
    'userName'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum CommentResourceTypes {
    Snippets = 'snippets',
    DatasetRelabeling = 'dataset_relabeling',
    CopyEdit = 'copy_edit',
    Summarization = 'summarization',
    Testing = 'testing'
}

/**
 * 
 * @export
 * @interface ComparisonDisagreementResponse
 */
export interface ComparisonDisagreementResponse {
    /**
     * 
     * @type {DisagreementResponse}
     * @memberof ComparisonDisagreementResponse
     */
    'response': DisagreementResponse;
    /**
     * 
     * @type {string}
     * @memberof ComparisonDisagreementResponse
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComparisonDisagreementResponse
     */
    'teamId'?: string;
}
/**
 * 
 * @export
 * @interface CompleteFolderMultipartUploadRequest
 */
export interface CompleteFolderMultipartUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof CompleteFolderMultipartUploadRequest
     */
    'folderName'?: string;
    /**
     * 
     * @type {ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest}
     * @memberof CompleteFolderMultipartUploadRequest
     */
    'request': ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest;
}
/**
 * 
 * @export
 * @interface CompleteMultiPartUploadResponse
 */
export interface CompleteMultiPartUploadResponse {
    /**
     * 
     * @type {APIMessage}
     * @memberof CompleteMultiPartUploadResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {string}
     * @memberof CompleteMultiPartUploadResponse
     */
    'workspaceId': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ComponentType {
    Datasets = 'datasets'
}

/**
 * 
 * @export
 * @interface ComputedCustomMetricsForRecording
 */
export interface ComputedCustomMetricsForRecording {
    /**
     * 
     * @type {string}
     * @memberof ComputedCustomMetricsForRecording
     */
    'createDate': string;
    /**
     * 
     * @type {Array<EvaluatedCustomMetrics>}
     * @memberof ComputedCustomMetricsForRecording
     */
    'customMetrics'?: Array<EvaluatedCustomMetrics>;
    /**
     * 
     * @type {string}
     * @memberof ComputedCustomMetricsForRecording
     */
    'recordingId': string;
}
/**
 * 
 * @export
 * @interface ComputedDistributionsForRecording
 */
export interface ComputedDistributionsForRecording {
    /**
     * 
     * @type {string}
     * @memberof ComputedDistributionsForRecording
     */
    'createDate': string;
    /**
     * 
     * @type {DistributionsSummary}
     * @memberof ComputedDistributionsForRecording
     */
    'distributions': DistributionsSummary;
    /**
     * 
     * @type {string}
     * @memberof ComputedDistributionsForRecording
     */
    'recordingId': string;
    /**
     * 
     * @type {number}
     * @memberof ComputedDistributionsForRecording
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ComputedDistributionsForRecording
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComputedDistributionsForRecording
     */
    'teamId'?: string;
}
/**
 * 
 * @export
 * @interface ComputedMetricsForRecording
 */
export interface ComputedMetricsForRecording {
    /**
     * 
     * @type {string}
     * @memberof ComputedMetricsForRecording
     */
    'createDate': string;
    /**
     * 
     * @type {FormattedMetrics}
     * @memberof ComputedMetricsForRecording
     */
    'metrics': FormattedMetrics;
    /**
     * 
     * @type {DistributionsSummary}
     * @memberof ComputedMetricsForRecording
     */
    'distributions': DistributionsSummary;
    /**
     * 
     * @type {string}
     * @memberof ComputedMetricsForRecording
     */
    'recordingId': string;
    /**
     * 
     * @type {number}
     * @memberof ComputedMetricsForRecording
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ComputedMetricsForRecording
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComputedMetricsForRecording
     */
    'teamId'?: string;
}
/**
 * 
 * @export
 * @interface ConceptDriftMonitorState
 */
export interface ConceptDriftMonitorState {
    /**
     * 
     * @type {ModelDriftStatus}
     * @memberof ConceptDriftMonitorState
     */
    'driftStatus': ModelDriftStatus;
    /**
     * 
     * @type {string}
     * @memberof ConceptDriftMonitorState
     */
    'conceptDriftMonitorPath': string;
    /**
     * 
     * @type {string}
     * @memberof ConceptDriftMonitorState
     */
    'conceptDriftMonitorStorage': string;
    /**
     * 
     * @type {number}
     * @memberof ConceptDriftMonitorState
     */
    'prequentialErrorMetric': number;
}
/**
 * 
 * @export
 * @interface Condition
 */
export interface Condition {
    /**
     * 
     * @type {ConditionType}
     * @memberof Condition
     */
    'type': ConditionType;
    /**
     * 
     * @type {LogicalOperator}
     * @memberof Condition
     */
    'operator': LogicalOperator;
    /**
     * 
     * @type {Array<ColumnExpression | StringColumnExpression>}
     * @memberof Condition
     */
    'expressions': Array<ColumnExpression | StringColumnExpression>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ConditionType {
    ColumnCondition = 'COLUMN_CONDITION'
}

/**
 * More information about why this is being done is here: https://meskalero.atlassian.net/browse/MKV-1987
 * @export
 * @interface ConfusionMatrixChartData
 */
export interface ConfusionMatrixChartData {
    /**
     * 
     * @type {ConfusionMatrixMetadata}
     * @memberof ConfusionMatrixChartData
     */
    'metadata': ConfusionMatrixMetadata;
    /**
     * 
     * @type {HeatMapDataModel}
     * @memberof ConfusionMatrixChartData
     */
    'data': HeatMapDataModel;
}
/**
 * 
 * @export
 * @interface ConfusionMatrixMetadata
 */
export interface ConfusionMatrixMetadata {
    /**
     * 
     * @type {string}
     * @memberof ConfusionMatrixMetadata
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfusionMatrixMetadata
     */
    'allLabels': Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ConfusionMatrixMetadata
     */
    'actualTotals': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ConfusionMatrixMetadata
     */
    'predictionTotals': Array<number>;
}
/**
 * 
 * @export
 * @interface ConnectorFormResponse
 */
export interface ConnectorFormResponse {
    /**
     * 
     * @type {object}
     * @memberof ConnectorFormResponse
     */
    'connectorForm'?: object;
    /**
     * 
     * @type {string}
     * @memberof ConnectorFormResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ConnectorModel
 */
export interface ConnectorModel {
    /**
     * 
     * @type {string}
     * @memberof ConnectorModel
     */
    'userId': string;
    /**
     * 
     * @type {StorageType}
     * @memberof ConnectorModel
     */
    'connectorType': StorageType;
    /**
     * 
     * @type {string}
     * @memberof ConnectorModel
     */
    'credId': string;
    /**
     * 
     * @type {GoogleSheetConnectorMetadataModel | SnowflakeConnectorMetadataModel | PostgresConnectorMetadataModel | AirbyteConnectorMetadataModel | AzureBlobConnectorMetadataModel | S3ConnectorMetadataModel | GoogleDriveConnectorMetadataModel | ZendeskCredentialModel | GoogleAdsCredentialModel | GongCredentialModel}
     * @memberof ConnectorModel
     */
    'connectorMetadata': GoogleSheetConnectorMetadataModel | SnowflakeConnectorMetadataModel | PostgresConnectorMetadataModel | AirbyteConnectorMetadataModel | AzureBlobConnectorMetadataModel | S3ConnectorMetadataModel | GoogleDriveConnectorMetadataModel | ZendeskCredentialModel | GoogleAdsCredentialModel | GongCredentialModel;
    /**
     * 
     * @type {string}
     * @memberof ConnectorModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorModel
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorModel
     */
    'connectorId': string;
}
/**
 * 
 * @export
 * @interface ConnectorTokenResponse
 */
export interface ConnectorTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof ConnectorTokenResponse
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface ConsentUrlResponse
 */
export interface ConsentUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof ConsentUrlResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface Contributor
 */
export interface Contributor {
    /**
     * 
     * @type {string}
     * @memberof Contributor
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface Conversation
 */
export interface Conversation {
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'conversationId': string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'snippetId': string;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof Conversation
     */
    'comments': Array<Comment>;
    /**
     * 
     * @type {boolean}
     * @memberof Conversation
     */
    'isResolved': boolean;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'updateDate': string;
}
/**
 * 
 * @export
 * @interface ConversationModel
 */
export interface ConversationModel {
    /**
     * 
     * @type {string}
     * @memberof ConversationModel
     */
    'conversationId'?: string;
    /**
     * 
     * @type {Array<CommentModel>}
     * @memberof ConversationModel
     */
    'comments'?: Array<CommentModel>;
    /**
     * 
     * @type {number}
     * @memberof ConversationModel
     */
    'numComments'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConversationModel
     */
    'isResolved'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConversationModel
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationModel
     */
    'updateDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationModel
     */
    'locationKey'?: string;
}
/**
 * Enum class to determine what all details regarding a conversation is required by FE.     FULL: it means full details including comments list of a conversation.     MINIMUM_DETAILS: it means minimum details (only conversation_id) will be returned.
 * @export
 * @enum {string}
 */

export enum ConversationProjections {
    FullDetails = 'FULL_DETAILS',
    MinimumDetails = 'MINIMUM_DETAILS'
}

/**
 * 
 * @export
 * @interface CopyEditAnalyticsChart
 */
export interface CopyEditAnalyticsChart {
    /**
     * 
     * @type {string}
     * @memberof CopyEditAnalyticsChart
     */
    'label': string;
    /**
     * 
     * @type {object}
     * @memberof CopyEditAnalyticsChart
     */
    'metadata': object;
    /**
     * 
     * @type {GenericChartDataModel}
     * @memberof CopyEditAnalyticsChart
     */
    'data': GenericChartDataModel;
    /**
     * 
     * @type {AnalyticsType}
     * @memberof CopyEditAnalyticsChart
     */
    'type': AnalyticsType;
    /**
     * 
     * @type {string}
     * @memberof CopyEditAnalyticsChart
     */
    'info': string;
}
/**
 * 
 * @export
 * @interface CopyEditAnalyticsValue
 */
export interface CopyEditAnalyticsValue {
    /**
     * 
     * @type {string}
     * @memberof CopyEditAnalyticsValue
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof CopyEditAnalyticsValue
     */
    'value': number;
    /**
     * 
     * @type {AnalyticsType}
     * @memberof CopyEditAnalyticsValue
     */
    'type': AnalyticsType;
    /**
     * 
     * @type {string}
     * @memberof CopyEditAnalyticsValue
     */
    'info': string;
}
/**
 * 
 * @export
 * @interface CopyEditCitation
 */
export interface CopyEditCitation {
    /**
     * 
     * @type {string}
     * @memberof CopyEditCitation
     */
    'citationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CopyEditCitation
     */
    'citationHash'?: string;
    /**
     * 
     * @type {Citation}
     * @memberof CopyEditCitation
     */
    'citation': Citation;
    /**
     * 
     * @type {boolean}
     * @memberof CopyEditCitation
     */
    'hasMissingFields'?: boolean;
}
/**
 * 
 * @export
 * @interface CopyEditCitationText
 */
export interface CopyEditCitationText {
    /**
     * 
     * @type {string}
     * @memberof CopyEditCitationText
     */
    'citationId': string;
    /**
     * 
     * @type {string}
     * @memberof CopyEditCitationText
     */
    'citationHash': string;
    /**
     * 
     * @type {CitationSourceType}
     * @memberof CopyEditCitationText
     */
    'citationType': CitationSourceType;
    /**
     * 
     * @type {string}
     * @memberof CopyEditCitationText
     */
    'citationText': string;
    /**
     * 
     * @type {string}
     * @memberof CopyEditCitationText
     */
    'citationHtml': string;
}
/**
 * 
 * @export
 * @interface CopyEditCitationWithSummary
 */
export interface CopyEditCitationWithSummary {
    /**
     * 
     * @type {string}
     * @memberof CopyEditCitationWithSummary
     */
    'citationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CopyEditCitationWithSummary
     */
    'citationHash'?: string;
    /**
     * 
     * @type {Citation}
     * @memberof CopyEditCitationWithSummary
     */
    'citation': Citation;
    /**
     * 
     * @type {boolean}
     * @memberof CopyEditCitationWithSummary
     */
    'hasMissingFields'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CopyEditCitationWithSummary
     */
    'summary': string;
    /**
     * 
     * @type {CitationAPIType}
     * @memberof CopyEditCitationWithSummary
     */
    'apiSourceType': CitationAPIType;
}
/**
 * Request Model for - /v1/user/{user_id}/token
 * @export
 * @interface CreateAPITokenRequest
 */
export interface CreateAPITokenRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAPITokenRequest
     */
    'tokenName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPITokenRequest
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPITokenRequest
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPITokenRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPITokenRequest
     */
    'expiryDate'?: string;
}
/**
 * Response Model for - /v1/user/{user_id}/token
 * @export
 * @interface CreateAPITokenResponse
 */
export interface CreateAPITokenResponse {
    /**
     * 
     * @type {APIToken}
     * @memberof CreateAPITokenResponse
     */
    'response'?: APIToken;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAPITokenResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface CreateAppBuilderRequest
 */
export interface CreateAppBuilderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAppBuilderRequest
     */
    'appName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppBuilderRequest
     */
    'appDescription'?: string;
    /**
     * 
     * @type {AppBuilderClassKeys}
     * @memberof CreateAppBuilderRequest
     */
    'appType'?: AppBuilderClassKeys;
}
/**
 * 
 * @export
 * @interface CreateAppBuilderResponse
 */
export interface CreateAppBuilderResponse {
    /**
     * 
     * @type {AppBuilder}
     * @memberof CreateAppBuilderResponse
     */
    'data': AppBuilder;
    /**
     * 
     * @type {string}
     * @memberof CreateAppBuilderResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface CreateConnectorRequest
 */
export interface CreateConnectorRequest {
    /**
     * 
     * @type {StorageType}
     * @memberof CreateConnectorRequest
     */
    'connectorType': StorageType;
    /**
     * 
     * @type {S3CredentialModel | AzureBlobCredentialModel | SnowflakeBasicCredentialModel | PostgresCredentialModel | ZendeskCredentialModel | HubspotCredentialModel | AirtableCredentialModel | LinkedInAdsCredentialModel | GoogleAdsCredentialModel | GongCredentialModel | GoogleAnalyticsCredentialModel | OauthCredentialModel | object}
     * @memberof CreateConnectorRequest
     */
    'credData': S3CredentialModel | AzureBlobCredentialModel | SnowflakeBasicCredentialModel | PostgresCredentialModel | ZendeskCredentialModel | HubspotCredentialModel | AirtableCredentialModel | LinkedInAdsCredentialModel | GoogleAdsCredentialModel | GongCredentialModel | GoogleAnalyticsCredentialModel | OauthCredentialModel | object;
    /**
     * 
     * @type {GoogleSheetConnectorMetadataModel | SnowflakeConnectorMetadataModel | PostgresConnectorMetadataModel | GoogleDriveConnectorMetadataModel | AzureBlobConnectorMetadataModel | S3ConnectorMetadataModel | AirbyteConnectorMetadataModel}
     * @memberof CreateConnectorRequest
     */
    'connectorMetadata': GoogleSheetConnectorMetadataModel | SnowflakeConnectorMetadataModel | PostgresConnectorMetadataModel | GoogleDriveConnectorMetadataModel | AzureBlobConnectorMetadataModel | S3ConnectorMetadataModel | AirbyteConnectorMetadataModel;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectorRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateConnectorRequestV2Mig
 */
export interface CreateConnectorRequestV2Mig {
    /**
     * 
     * @type {StorageType}
     * @memberof CreateConnectorRequestV2Mig
     */
    'connectorType': StorageType;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectorRequestV2Mig
     */
    'credId': string;
    /**
     * 
     * @type {SnowflakeConnectorMetadataModel | PostgresConnectorMetadataModel | AirbyteConnectorMetadataModel | S3ConnectorMetadataModel}
     * @memberof CreateConnectorRequestV2Mig
     */
    'connectorMetadata': SnowflakeConnectorMetadataModel | PostgresConnectorMetadataModel | AirbyteConnectorMetadataModel | S3ConnectorMetadataModel;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectorRequestV2Mig
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectorRequestV2Mig
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectorRequestV2Mig
     */
    'createDate': string;
}
/**
 * 
 * @export
 * @interface CreateConnectorResponse
 */
export interface CreateConnectorResponse {
    /**
     * 
     * @type {APIMessage}
     * @memberof CreateConnectorResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {ConnectorModel}
     * @memberof CreateConnectorResponse
     */
    'response'?: ConnectorModel;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectorResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface CreateCustomDictionaryResponse
 */
export interface CreateCustomDictionaryResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomDictionaryResponse
     */
    'dictionaryId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomDictionaryResponse
     */
    'dictionaryName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomDictionaryResponse
     */
    'dictionaryDescription'?: string;
}
/**
 * 
 * @export
 * @interface CreateDataAnalyticsRequestModel
 */
export interface CreateDataAnalyticsRequestModel {
    /**
     * 
     * @type {string}
     * @memberof CreateDataAnalyticsRequestModel
     */
    'resourceId': string;
}
/**
 * 
 * @export
 * @interface CreateDataAnalyticsResponseModel
 */
export interface CreateDataAnalyticsResponseModel {
    /**
     * 
     * @type {string}
     * @memberof CreateDataAnalyticsResponseModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDataAnalyticsResponseModel
     */
    'resourceId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDataAnalyticsResponseModel
     */
    'dataAnalyticsId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDataAnalyticsResponseModel
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDataAnalyticsResponseModel
     */
    'createDate': string;
    /**
     * Fetch artifacts based on query param filter ACTIVE/ARCHIVED
     * @type {ArtifactStateModelV2}
     * @memberof CreateDataAnalyticsResponseModel
     */
    'artifactState': ArtifactStateModelV2;
}
/**
 * Represents a high level collection of DataSets that solve similar purpose and have similar expected behavior. This object is returned after a DataFamily is registered with Meskalero Ex:     CreditCardFraudFamily en-US would contain all the english US datasets for CreditCardFraud in the US.
 * @export
 * @interface CreateDataFamilyRequest
 */
export interface CreateDataFamilyRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDataFamilyRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDataFamilyRequest
     */
    'description'?: string;
}
/**
 * Outcome for create datafamily request
 * @export
 * @interface CreateDataFamilyResponse
 */
export interface CreateDataFamilyResponse {
    /**
     * 
     * @type {DataFamilyModel}
     * @memberof CreateDataFamilyResponse
     */
    'outcome'?: DataFamilyModel;
    /**
     * 
     * @type {APIMessage}
     * @memberof CreateDataFamilyResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {string}
     * @memberof CreateDataFamilyResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface CreateDataOperationRequest
 */
export interface CreateDataOperationRequest {
    /**
     * 
     * @type {DataOperationType}
     * @memberof CreateDataOperationRequest
     */
    'dataOperationType': DataOperationType;
    /**
     * 
     * @type {RuleBasedRelabelCreate | SubsetRelabelCreate | ManualRelabelCreate | object}
     * @memberof CreateDataOperationRequest
     */
    'details'?: RuleBasedRelabelCreate | SubsetRelabelCreate | ManualRelabelCreate | object;
    /**
     * 
     * @type {string}
     * @memberof CreateDataOperationRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CreateDataOperationResponse
 */
export interface CreateDataOperationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CreateDataOperationResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateDataOperationResponse
     */
    'dataOperationId': string;
}
/**
 * 
 * @export
 * @interface CreateDataOperationRuleRequest
 */
export interface CreateDataOperationRuleRequest {
    /**
     * 
     * @type {LabelingRuleBody}
     * @memberof CreateDataOperationRuleRequest
     */
    'ruleBody': LabelingRuleBody;
}
/**
 * 
 * @export
 * @interface CreateDatasetRegistrationWorkflowResponse
 */
export interface CreateDatasetRegistrationWorkflowResponse {
    /**
     * 
     * @type {DatasetRegistrationWorkflowModel}
     * @memberof CreateDatasetRegistrationWorkflowResponse
     */
    'response'?: DatasetRegistrationWorkflowModel;
    /**
     * 
     * @type {string}
     * @memberof CreateDatasetRegistrationWorkflowResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface CreateDemoArtifactsRequest
 */
export interface CreateDemoArtifactsRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDemoArtifactsRequest
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDemoArtifactsRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDemoArtifactsRequest
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDemoArtifactsRequest
     */
    'teamId'?: string;
}
/**
 * 
 * @export
 * @interface CreateEnterpriseSettings
 */
export interface CreateEnterpriseSettings {
    /**
     * 
     * @type {string}
     * @memberof CreateEnterpriseSettings
     */
    'enterpriseName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEnterpriseSettings
     */
    'enterpriseDomain': string;
    /**
     * 
     * @type {SubscriptionType}
     * @memberof CreateEnterpriseSettings
     */
    'subscriptionType': SubscriptionType;
    /**
     * 
     * @type {string}
     * @memberof CreateEnterpriseSettings
     */
    'adminEmail': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEnterpriseSettings
     */
    'adminName': string;
}
/**
 * 
 * @export
 * @interface CreateGenAIProjectRequest
 */
export interface CreateGenAIProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateGenAIProjectRequest
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateGenAIProjectRequest
     */
    'projectDescription': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateGenAIProjectRequest
     */
    'inviteUsers'?: Array<string>;
    /**
     * 
     * @type {CitationStyleGuideType}
     * @memberof CreateGenAIProjectRequest
     */
    'defaultCitationStyleGuide'?: CitationStyleGuideType;
    /**
     * 
     * @type {Array<StyleGuideType>}
     * @memberof CreateGenAIProjectRequest
     */
    'defaultStyleGuides'?: Array<StyleGuideType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateGenAIProjectRequest
     */
    'defaultCustomStyleRules'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateGenAIProjectRequest
     */
    'defaultCustomDictionaryIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateMilestoneResponse
 */
export interface CreateMilestoneResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateMilestoneResponse
     */
    'documentId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateMilestoneResponse
     */
    'milestoneId': number;
}
/**
 * 
 * @export
 * @interface CreateModelAppRequest
 */
export interface CreateModelAppRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateModelAppRequest
     */
    'modelAppName': string;
    /**
     * 
     * @type {DataViewEssentials}
     * @memberof CreateModelAppRequest
     */
    'dataView': DataViewEssentials;
    /**
     * 
     * @type {string}
     * @memberof CreateModelAppRequest
     */
    'modelAppDescription'?: string;
    /**
     * 
     * @type {ModelAppClassKeys}
     * @memberof CreateModelAppRequest
     */
    'modelAppType'?: ModelAppClassKeys;
}
/**
 * 
 * @export
 * @interface CreateModelAppResponse
 */
export interface CreateModelAppResponse {
    /**
     * 
     * @type {ModelApp}
     * @memberof CreateModelAppResponse
     */
    'data': ModelApp;
    /**
     * 
     * @type {string}
     * @memberof CreateModelAppResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface CreateModelRegistryRequest
 */
export interface CreateModelRegistryRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateModelRegistryRequest
     */
    'name': string;
    /**
     * 
     * @type {MLModelClass}
     * @memberof CreateModelRegistryRequest
     */
    'registryType': MLModelClass;
    /**
     * 
     * @type {string}
     * @memberof CreateModelRegistryRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateModelRegistryResponse
 */
export interface CreateModelRegistryResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateModelRegistryResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateModelRegistryResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateModelRegistryResponse
     */
    'registryId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateModelRegistryResponse
     */
    'latestVersion': number;
}
/**
 * 
 * @export
 * @interface CreateMultipleResourceUploadRequest
 */
export interface CreateMultipleResourceUploadRequest {
    /**
     * 
     * @type {Array<FileInfo>}
     * @memberof CreateMultipleResourceUploadRequest
     */
    'fileInfos': Array<FileInfo>;
    /**
     * 
     * @type {string}
     * @memberof CreateMultipleResourceUploadRequest
     */
    'folderName': string;
}
/**
 * 
 * @export
 * @interface CreateMultipleResourceUploadResponse
 */
export interface CreateMultipleResourceUploadResponse {
    /**
     * 
     * @type {Array<Urls>}
     * @memberof CreateMultipleResourceUploadResponse
     */
    'response': Array<Urls>;
    /**
     * 
     * @type {string}
     * @memberof CreateMultipleResourceUploadResponse
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {APIMessage}
     * @memberof CreateMultipleResourceUploadResponse
     */
    'message'?: APIMessage;
}
/**
 * API request for create subset
 * @export
 * @interface CreateNewVersionDatasetRequest
 */
export interface CreateNewVersionDatasetRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNewVersionDatasetRequest
     */
    'newDatasetName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateNewVersionDatasetRequest
     */
    'taskletIds'?: Array<string>;
}
/**
 * API response for create subset
 * @export
 * @interface CreateNewVersionDatasetResponse
 */
export interface CreateNewVersionDatasetResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateNewVersionDatasetResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {AckResponse}
     * @memberof CreateNewVersionDatasetResponse
     */
    'response': AckResponse;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateCitationsResponse
 */
export interface CreateOrUpdateCitationsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateCitationsResponse
     */
    'isDuplicate': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateCitationsResponse
     */
    'citationId': string;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateCustomDictionaryRequest
 */
export interface CreateOrUpdateCustomDictionaryRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateCustomDictionaryRequest
     */
    'dictionaryName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateCustomDictionaryRequest
     */
    'dictionaryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateCustomDictionaryRequest
     */
    'dictionaryDescription'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrUpdateCustomDictionaryRequest
     */
    'customWords'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateRBACResourceRequest
 */
export interface CreateOrUpdateRBACResourceRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateRBACResourceRequest
     */
    'resourceName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateRBACResourceRequest
     */
    'resourceDescription': string;
}
/**
 * 
 * @export
 * @interface CreatePendingModelAppRequest
 */
export interface CreatePendingModelAppRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePendingModelAppRequest
     */
    'appName': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePendingModelAppRequest
     */
    'durationInMins': number;
    /**
     * 
     * @type {string}
     * @memberof CreatePendingModelAppRequest
     */
    'modelId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePendingModelAppRequest
     */
    'datasetId'?: string;
}
/**
 * 
 * @export
 * @interface CreatePendingModelAppResponse
 */
export interface CreatePendingModelAppResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CreatePendingModelAppResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePendingModelAppResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface CreatePlatformAssetsUploadUrlRequest
 */
export interface CreatePlatformAssetsUploadUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePlatformAssetsUploadUrlRequest
     */
    'objectPath': string;
}
/**
 * 
 * @export
 * @interface CreateProjectAckResponse
 */
export interface CreateProjectAckResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CreateProjectAckResponse
     */
    'ack': boolean;
}
/**
 * Create project request
 * @export
 * @interface CreateProjectRequest
 */
export interface CreateProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'description'?: string;
    /**
     * 
     * @type {ProjectScope}
     * @memberof CreateProjectRequest
     */
    'scope'?: ProjectScope;
}
/**
 * 
 * @export
 * @interface CreateProjectResponse
 */
export interface CreateProjectResponse {
    /**
     * 
     * @type {CreateProjectAckResponse}
     * @memberof CreateProjectResponse
     */
    'response': CreateProjectAckResponse;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface CreateRuleSetUploadURLResponse
 */
export interface CreateRuleSetUploadURLResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateRuleSetUploadURLResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRuleSetUploadURLResponse
     */
    'ruleSetId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRuleSetUploadURLResponse
     */
    'signedUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRuleSetUploadURLResponse
     */
    'ruleSetName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRuleSetUploadURLResponse
     */
    'uploadDocumentId': string;
}
/**
 * Request model for scheduling a workflow. Attributes:     schedule: [ScheduleRequestModel]: Workflow Schedule details     workflow_name (Optional[str]): Name to be given to workflow while publishing it
 * @export
 * @interface CreateScheduledWorkflowRequest
 */
export interface CreateScheduledWorkflowRequest {
    /**
     * 
     * @type {ScheduleDetailsModel}
     * @memberof CreateScheduledWorkflowRequest
     */
    'schedule': ScheduleDetailsModel;
    /**
     * 
     * @type {string}
     * @memberof CreateScheduledWorkflowRequest
     */
    'workflowName'?: string;
}
/**
 * 
 * @export
 * @interface CreateScheduledWorkflowResponse
 */
export interface CreateScheduledWorkflowResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CreateScheduledWorkflowResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateScheduledWorkflowResponse
     */
    'recurringWorkflowRunId': string;
}
/**
 * 
 * @export
 * @interface CreateSnippetsAssetsUploadUrlResponse
 */
export interface CreateSnippetsAssetsUploadUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateSnippetsAssetsUploadUrlResponse
     */
    'uploadUrl': string;
}
/**
 * 
 * @export
 * @interface CreateSubscriptionResponse
 */
export interface CreateSubscriptionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CreateSubscriptionResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {Subscription}
     * @memberof CreateSubscriptionResponse
     */
    'response'?: Subscription;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionResponse
     */
    'message'?: string;
}
/**
 * API request for create subset
 * @export
 * @interface CreateSubsetRequest
 */
export interface CreateSubsetRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSubsetRequest
     */
    'datasetId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubsetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubsetRequest
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSubsetRequest
     */
    'datasetRecords': Array<string>;
    /**
     * 
     * @type {Array<SubsetRegion>}
     * @memberof CreateSubsetRequest
     */
    'regions'?: Array<SubsetRegion>;
}
/**
 * API response for create subset
 * @export
 * @interface CreateSubsetResponse
 */
export interface CreateSubsetResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateSubsetResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {AckResponse}
     * @memberof CreateSubsetResponse
     */
    'response': AckResponse;
}
/**
 * Create tag request
 * @export
 * @interface CreateTagRequest
 */
export interface CreateTagRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTagRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTagRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTagRequest
     */
    'color'?: string;
}
/**
 * Ack response indicating tag creation success or failure
 * @export
 * @interface CreateTagResponse
 */
export interface CreateTagResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CreateTagResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {TagMetadata}
     * @memberof CreateTagResponse
     */
    'tagMetadata'?: TagMetadata;
}
/**
 * 
 * @export
 * @interface CreateUploadResourceRequest
 */
export interface CreateUploadResourceRequest {
    /**
     * 
     * @type {FileInfo}
     * @memberof CreateUploadResourceRequest
     */
    'fileInfo': FileInfo;
}
/**
 * 
 * @export
 * @interface CreateUploadResourceResponse
 */
export interface CreateUploadResourceResponse {
    /**
     * 
     * @type {Urls}
     * @memberof CreateUploadResourceResponse
     */
    'response': Urls;
    /**
     * 
     * @type {string}
     * @memberof CreateUploadResourceResponse
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {APIMessage}
     * @memberof CreateUploadResourceResponse
     */
    'message'?: APIMessage;
}
/**
 * Create Dataset Upload Url Request ORM
 * @export
 * @interface CreateUploadUrlRequest
 */
export interface CreateUploadUrlRequest {
    /**
     * 
     * @type {{ [key: string]: FileInfo; }}
     * @memberof CreateUploadUrlRequest
     */
    'filesInfo': { [key: string]: FileInfo; };
}
/**
 * 
 * @export
 * @interface CreateWorkflowAppModelRequest
 */
export interface CreateWorkflowAppModelRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowAppModelRequest
     */
    'workflowAppName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowAppModelRequest
     */
    'workflowAppDescription'?: string;
}
/**
 * 
 * @export
 * @interface CreateWorkflowAppModelResponse
 */
export interface CreateWorkflowAppModelResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowAppModelResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowAppModelResponse
     */
    'workflowId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowAppModelResponse
     */
    'workflowAppId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowAppModelResponse
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowAppModelResponse
     */
    'workflowAppName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowAppModelResponse
     */
    'workflowAppDescription': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowAppModelResponse
     */
    'dagId': string;
    /**
     * 
     * @type {GetDAGResponse}
     * @memberof CreateWorkflowAppModelResponse
     */
    'dag': GetDAGResponse;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowAppModelResponse
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowAppModelResponse
     */
    'updateDate': string;
    /**
     * Fetch artifacts based on query  param filter ACTIVE/ARCHIVED
     * @type {ArtifactStateModel}
     * @memberof CreateWorkflowAppModelResponse
     */
    'artifactState': ArtifactStateModel;
    /**
     * 
     * @type {WorkflowAppCategory}
     * @memberof CreateWorkflowAppModelResponse
     */
    'category'?: WorkflowAppCategory;
}
/**
 * This is deprecated after lock/unlock feature
 * @export
 * @interface CreateWorkflowDebugRunRequest
 */
export interface CreateWorkflowDebugRunRequest {
    /**
     * 
     * @type {ExecutionType}
     * @memberof CreateWorkflowDebugRunRequest
     */
    'executionType'?: ExecutionType;
    /**
     * 
     * @type {boolean}
     * @memberof CreateWorkflowDebugRunRequest
     */
    'useAllData'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateWorkflowRequest
 */
export interface CreateWorkflowRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowRequest
     */
    'id': string;
    /**
     * 
     * @type {WorkflowMode}
     * @memberof CreateWorkflowRequest
     */
    'mode'?: WorkflowMode;
}
/**
 * 
 * @export
 * @interface CreateWorkflowResponse
 */
export interface CreateWorkflowResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowResponse
     */
    'workflowId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowResponse
     */
    'dagId'?: string;
}
/**
 * 
 * @export
 * @interface CreateWorkflowRunRequest
 */
export interface CreateWorkflowRunRequest {
    /**
     * 
     * @type {ExecutionType}
     * @memberof CreateWorkflowRunRequest
     */
    'executionType'?: ExecutionType;
    /**
     * 
     * @type {boolean}
     * @memberof CreateWorkflowRunRequest
     */
    'debugRun'?: boolean;
    /**
     * 
     * @type {ExecutedFromSourceType}
     * @memberof CreateWorkflowRunRequest
     */
    'executedFrom'?: ExecutedFromSourceType;
}
/**
 * 
 * @export
 * @interface CreateWorkflowRunResponse
 */
export interface CreateWorkflowRunResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CreateWorkflowRunResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowRunResponse
     */
    'workflowRunId': string;
}
/**
 * 
 * @export
 * @interface CreateWorkspaceBillingPortalSessionRequestModel
 */
export interface CreateWorkspaceBillingPortalSessionRequestModel {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceBillingPortalSessionRequestModel
     */
    'returnUrl': string;
}
/**
 * 
 * @export
 * @interface CreateWorkspaceBillingPortalSessionResponseModel
 */
export interface CreateWorkspaceBillingPortalSessionResponseModel {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceBillingPortalSessionResponseModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceBillingPortalSessionResponseModel
     */
    'portalUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceBillingPortalSessionResponseModel
     */
    'returnUrl': string;
}
/**
 * Response model for creating a new workspace checkout session.
 * @export
 * @interface CreateWorkspaceCheckoutSessionResponseModel
 */
export interface CreateWorkspaceCheckoutSessionResponseModel {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceCheckoutSessionResponseModel
     */
    'checkoutSessionId': string;
    /**
     * 
     * @type {CheckoutSessionStatusType}
     * @memberof CreateWorkspaceCheckoutSessionResponseModel
     */
    'checkoutSessionStatus': CheckoutSessionStatusType;
    /**
     * 
     * @type {CheckoutSessionPaymentStatusType}
     * @memberof CreateWorkspaceCheckoutSessionResponseModel
     */
    'checkoutSessionPaymentStatus': CheckoutSessionPaymentStatusType;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceCheckoutSessionResponseModel
     */
    'customerId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateWorkspaceCheckoutSessionResponseModel
     */
    'amountTotal': number;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceCheckoutSessionResponseModel
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceCheckoutSessionResponseModel
     */
    'subscription': string;
    /**
     * 
     * @type {CheckoutSessionModeType}
     * @memberof CreateWorkspaceCheckoutSessionResponseModel
     */
    'mode': CheckoutSessionModeType;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceCheckoutSessionResponseModel
     */
    'customerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceCheckoutSessionResponseModel
     */
    'clientReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceCheckoutSessionResponseModel
     */
    'clientSecret': string;
}
/**
 * Response for a newly created workspace
 * @export
 * @interface CreatedWorkspaceWithDefaultEntities
 */
export interface CreatedWorkspaceWithDefaultEntities {
    /**
     * 
     * @type {Workspace}
     * @memberof CreatedWorkspaceWithDefaultEntities
     */
    'workspace': Workspace;
    /**
     * 
     * @type {Subscription}
     * @memberof CreatedWorkspaceWithDefaultEntities
     */
    'subscription': Subscription;
    /**
     * 
     * @type {Team}
     * @memberof CreatedWorkspaceWithDefaultEntities
     */
    'defaultTeam': Team;
    /**
     * 
     * @type {EnterpriseOwnedAccount | UserOwnedAccount}
     * @memberof CreatedWorkspaceWithDefaultEntities
     */
    'ownerAccount': EnterpriseOwnedAccount | UserOwnedAccount;
}
/**
 * Details: https://meskalero.slab.com/posts/api-design-for-dataset-registration-6h7c4h3e
 * @export
 * @interface CredentialModel
 */
export interface CredentialModel {
    /**
     * 
     * @type {string}
     * @memberof CredentialModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CredentialModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CredentialModel
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof CredentialModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof CredentialModel
     */
    'credentialId'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CredentialModel
     */
    'data'?: { [key: string]: string; };
}
/**
 * Types of credits a user can purchase
 * @export
 * @enum {string}
 */

export enum CreditsType {
    Storage = 'STORAGE',
    Compute = 'COMPUTE',
    Tracking = 'TRACKING'
}

/**
 * 
 * @export
 * @interface CustomMetricAllStats
 */
export interface CustomMetricAllStats {
    /**
     * 
     * @type {CustomMetricSummaryStats}
     * @memberof CustomMetricAllStats
     */
    'summary': CustomMetricSummaryStats;
    /**
     * 
     * @type {CustomMetricSymmetry}
     * @memberof CustomMetricAllStats
     */
    'symmetry': CustomMetricSymmetry;
}
/**
 * 
 * @export
 * @interface CustomMetricSummaryStats
 */
export interface CustomMetricSummaryStats {
    /**
     * 
     * @type {number}
     * @memberof CustomMetricSummaryStats
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomMetricSummaryStats
     */
    'mean'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomMetricSummaryStats
     */
    'std'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomMetricSummaryStats
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomMetricSummaryStats
     */
    'max'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomMetricSummaryStats
     */
    '25%'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomMetricSummaryStats
     */
    '50%'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomMetricSummaryStats
     */
    '75%'?: number;
}
/**
 * 
 * @export
 * @interface CustomMetricSymmetry
 */
export interface CustomMetricSymmetry {
    /**
     * 
     * @type {MetricInfoTuple}
     * @memberof CustomMetricSymmetry
     */
    'fisherKurtosis': MetricInfoTuple;
    /**
     * 
     * @type {MetricInfoTuple}
     * @memberof CustomMetricSymmetry
     */
    'skewness': MetricInfoTuple;
}
/**
 * 
 * @export
 * @interface DSAnalysisSegmentClassData
 */
export interface DSAnalysisSegmentClassData {
    /**
     * 
     * @type {Array<string>}
     * @memberof DSAnalysisSegmentClassData
     */
    'categories': Array<string>;
    /**
     * 
     * @type {Array<DSSeriesElement>}
     * @memberof DSAnalysisSegmentClassData
     */
    'series': Array<DSSeriesElement>;
    /**
     * 
     * @type {string}
     * @memberof DSAnalysisSegmentClassData
     */
    'segmentType': string;
}
/**
 * 
 * @export
 * @interface DSAnalysisSegmentClassDetailResponse
 */
export interface DSAnalysisSegmentClassDetailResponse {
    /**
     * 
     * @type {DSAnalysisSegmentClassData}
     * @memberof DSAnalysisSegmentClassDetailResponse
     */
    'response': DSAnalysisSegmentClassData;
    /**
     * 
     * @type {string}
     * @memberof DSAnalysisSegmentClassDetailResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface DSExistResponse
 */
export interface DSExistResponse {
    /**
     * 
     * @type {Array<DSExistStatus>}
     * @memberof DSExistResponse
     */
    'response': Array<DSExistStatus>;
    /**
     * 
     * @type {string}
     * @memberof DSExistResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface DSExistStatus
 */
export interface DSExistStatus {
    /**
     * 
     * @type {string}
     * @memberof DSExistStatus
     */
    'dsId': string;
    /**
     * 
     * @type {boolean}
     * @memberof DSExistStatus
     */
    'exists': boolean;
}
/**
 * 
 * @export
 * @interface DSSeriesElement
 */
export interface DSSeriesElement {
    /**
     * 
     * @type {string}
     * @memberof DSSeriesElement
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DSSeriesElement
     */
    'dsId': string;
    /**
     * 
     * @type {Array<CDESegmentClassData>}
     * @memberof DSSeriesElement
     */
    'data': Array<CDESegmentClassData>;
}
/**
 * This object is used to report status of an individual tasklet, {     tasklet_id: wflow_nv001 (string),     tasklet_name: Noun Verb Analysis (string),     task_id: string,     segment: train (enum),     status: IN_PROGRESS / ERROR / COMPLETED (enum),     createDate: 2023-03-27T12:40:50.494274+00:00 (string),     userId: uid001 (string),     userName: Kris Heinrich (string) }
 * @export
 * @interface DataAnalysesStatus
 */
export interface DataAnalysesStatus {
    /**
     * 
     * @type {string}
     * @memberof DataAnalysesStatus
     */
    'taskletId': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalysesStatus
     */
    'taskletName': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalysesStatus
     */
    'taskId': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalysesStatus
     */
    'segment': string;
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof DataAnalysesStatus
     */
    'status': AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof DataAnalysesStatus
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalysesStatus
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalysesStatus
     */
    'userName'?: string;
}
/**
 * Model representing a summary of data analyses for a tasklet.  Attributes:     tasklet_id (str): The unique identifier of the tasklet.     tasklet_name (str): The display name of the tasklet.     tasklet_metrics (List[TaskletMetrics]): List of tasklet metrics.     description (str, optional): A description of the tasklet.
 * @export
 * @interface DataAnalysesSummary
 */
export interface DataAnalysesSummary {
    /**
     * 
     * @type {string}
     * @memberof DataAnalysesSummary
     */
    'taskletId': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalysesSummary
     */
    'taskletName': string;
    /**
     * 
     * @type {Array<TaskletMetrics>}
     * @memberof DataAnalysesSummary
     */
    'taskletMetrics': Array<TaskletMetrics>;
    /**
     * 
     * @type {string}
     * @memberof DataAnalysesSummary
     */
    'description'?: string;
}
/**
 * Model representing a data analysis result.  Attributes:     tasklet_id (str): The unique identifier of the tasklet the result is associated with.     segment_type (str): The type of segment the result is applicable to.     analyses (List[AnalysisResultMetadata]): List of metadata for each analysis result.
 * @export
 * @interface DataAnalysisResult
 */
export interface DataAnalysisResult {
    /**
     * 
     * @type {string}
     * @memberof DataAnalysisResult
     */
    'taskletId': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalysisResult
     */
    'segmentType': string;
    /**
     * 
     * @type {Array<AnalysisResultMetadata>}
     * @memberof DataAnalysisResult
     */
    'analyses': Array<AnalysisResultMetadata>;
}
/**
 * Model representing the response to a data analysis results request.  Attributes:     workspace_id (str): The unique identifier of the workspace.     response (List[DataAnalysisResult]): List of data analysis results.
 * @export
 * @interface DataAnalysisResultsResponse
 */
export interface DataAnalysisResultsResponse {
    /**
     * 
     * @type {string}
     * @memberof DataAnalysisResultsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<DataAnalysisResult>}
     * @memberof DataAnalysisResultsResponse
     */
    'response': Array<DataAnalysisResult>;
}
/**
 * Model representing the response to a data analysis summary request.  Attributes:     workspace_id (str): The unique identifier of the workspace.     response (List[DataAnalysesSummary]): List of data analyses summaries.
 * @export
 * @interface DataAnalysisSummaryResponse
 */
export interface DataAnalysisSummaryResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof DataAnalysisSummaryResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof DataAnalysisSummaryResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<DataAnalysesSummary>}
     * @memberof DataAnalysisSummaryResponse
     */
    'response': Array<DataAnalysesSummary>;
}
/**
 * AI fix suggestion that a non-technical person can understand.
 * @export
 * @interface DataAnalyticsAISuggestedFix
 */
export interface DataAnalyticsAISuggestedFix {
    /**
     * Problem description that a non-technical person can understand in **markdown** format.
     * @type {string}
     * @memberof DataAnalyticsAISuggestedFix
     */
    'problemDescription': string;
    /**
     * Proposed solution that a non-technical person can understand in **markdown** format.
     * @type {string}
     * @memberof DataAnalyticsAISuggestedFix
     */
    'proposedSolution': string;
}
/**
 * 
 * @export
 * @interface DataAnalyticsDetailsResponseModel
 */
export interface DataAnalyticsDetailsResponseModel {
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsDetailsResponseModel
     */
    'userId': string;
    /**
     * 
     * @type {StorageType}
     * @memberof DataAnalyticsDetailsResponseModel
     */
    'connectorType': StorageType;
    /**
     * 
     * @type {MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel}
     * @memberof DataAnalyticsDetailsResponseModel
     */
    'resourceMetadata'?: MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel;
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsDetailsResponseModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsDetailsResponseModel
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsDetailsResponseModel
     */
    'connectorId': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsDetailsResponseModel
     */
    'resourceId': string;
    /**
     * 
     * @type {boolean}
     * @memberof DataAnalyticsDetailsResponseModel
     */
    'isDataSink'?: boolean;
    /**
     * 
     * @type {DataResourceType1}
     * @memberof DataAnalyticsDetailsResponseModel
     */
    'resourceType'?: DataResourceType1;
    /**
     * 
     * @type {object}
     * @memberof DataAnalyticsDetailsResponseModel
     */
    'details'?: object;
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsDetailsResponseModel
     */
    'connectionName'?: string;
}
/**
 * 
 * @export
 * @interface DataAnalyticsFollowUpQuestions
 */
export interface DataAnalyticsFollowUpQuestions {
    /**
     * 
     * @type {Array<FollowUpQuestionWithTableReference>}
     * @memberof DataAnalyticsFollowUpQuestions
     */
    'followUpQuestionsWithTableReference': Array<FollowUpQuestionWithTableReference>;
    /**
     * 
     * @type {number}
     * @memberof DataAnalyticsFollowUpQuestions
     */
    'numberOfQuestions': number;
}
/**
 * 
 * @export
 * @interface DataAnalyticsFollowUpQuestionsV2
 */
export interface DataAnalyticsFollowUpQuestionsV2 {
    /**
     * 
     * @type {Array<FollowUpQuestionTemplate>}
     * @memberof DataAnalyticsFollowUpQuestionsV2
     */
    'followUpQuestionsTemplate': Array<FollowUpQuestionTemplate>;
    /**
     * 
     * @type {number}
     * @memberof DataAnalyticsFollowUpQuestionsV2
     */
    'numberOfQuestions': number;
}
/**
 * 
 * @export
 * @interface DataAnalyticsLeafResourcesResponseModel
 */
export interface DataAnalyticsLeafResourcesResponseModel {
    /**
     * 
     * @type {Array<MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel>}
     * @memberof DataAnalyticsLeafResourcesResponseModel
     */
    'leafResourceMetadataModels': Array<MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel>;
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsLeafResourcesResponseModel
     */
    'connectorId': string;
    /**
     * 
     * @type {StorageType}
     * @memberof DataAnalyticsLeafResourcesResponseModel
     */
    'connectorType': StorageType;
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsLeafResourcesResponseModel
     */
    'connectorName': string;
}
/**
 * 
 * @export
 * @interface DataAnalyticsListResponseModel
 */
export interface DataAnalyticsListResponseModel {
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsListResponseModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<DataAnalyticsModel>}
     * @memberof DataAnalyticsListResponseModel
     */
    'response': Array<DataAnalyticsModel>;
    /**
     * 
     * @type {number}
     * @memberof DataAnalyticsListResponseModel
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface DataAnalyticsListResponseModelV2
 */
export interface DataAnalyticsListResponseModelV2 {
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsListResponseModelV2
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<DataAnalyticsModelV2>}
     * @memberof DataAnalyticsListResponseModelV2
     */
    'response': Array<DataAnalyticsModelV2>;
    /**
     * 
     * @type {number}
     * @memberof DataAnalyticsListResponseModelV2
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface DataAnalyticsModel
 */
export interface DataAnalyticsModel {
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsModel
     */
    'resourceId': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsModel
     */
    'dataAnalyticsId': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsModel
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsModel
     */
    'createDate': string;
    /**
     * Fetch artifacts based on query param filter ACTIVE/ARCHIVED
     * @type {ArtifactStateModelV2}
     * @memberof DataAnalyticsModel
     */
    'artifactState': ArtifactStateModelV2;
}
/**
 * 
 * @export
 * @interface DataAnalyticsModelV2
 */
export interface DataAnalyticsModelV2 {
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsModelV2
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsModelV2
     */
    'resourceId': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsModelV2
     */
    'dataAnalyticsId': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsModelV2
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof DataAnalyticsModelV2
     */
    'createDate': string;
    /**
     * Fetch artifacts based on query param filter ACTIVE/ARCHIVED
     * @type {ArtifactStateModelV2}
     * @memberof DataAnalyticsModelV2
     */
    'artifactState': ArtifactStateModelV2;
    /**
     * 
     * @type {StorageType}
     * @memberof DataAnalyticsModelV2
     */
    'connectorType': StorageType;
    /**
     * 
     * @type {MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel}
     * @memberof DataAnalyticsModelV2
     */
    'resourceMetadata': MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel;
}
/**
 * Defines the type of data_analyzer that this data set hold
 * @export
 * @enum {string}
 */

export enum DataCategory {
    Numerical = 'numerical',
    Text = 'text',
    Timeseries = 'timeseries',
    Image = 'image',
    MixedOrCategorical = 'mixed_or_categorical',
    NumericContinuousTarget = 'numeric_continuous_target',
    MixedCatContinuousTarget = 'mixed_cat_continuous_target',
    NumericUnbTarget = 'numeric_unb_target',
    MixedCatUnlabeledTarget = 'mixed_cat_unlabeled_target',
    Unknown = 'unknown',
    Embedding = 'embedding'
}

/**
 * Response for Triggering Dataset compare
 * @export
 * @interface DataCompareResponse
 */
export interface DataCompareResponse {
    /**
     * 
     * @type {string}
     * @memberof DataCompareResponse
     */
    'runId': string;
    /**
     * 
     * @type {string}
     * @memberof DataCompareResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataCompareResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface DataComparisonDetails
 */
export interface DataComparisonDetails {
    /**
     * 
     * @type {ServicesViennaApiModelsDatasetModelsDataset}
     * @memberof DataComparisonDetails
     */
    'primaryDataset': ServicesViennaApiModelsDatasetModelsDataset;
    /**
     * 
     * @type {Array<ServicesViennaApiModelsDatasetModelsDataset>}
     * @memberof DataComparisonDetails
     */
    'compareWithDataset': Array<ServicesViennaApiModelsDatasetModelsDataset>;
}
/**
 * 
 * @export
 * @interface DataDriftColumnOverviewResponse
 */
export interface DataDriftColumnOverviewResponse {
    /**
     * 
     * @type {number}
     * @memberof DataDriftColumnOverviewResponse
     */
    'numColumnsWithDrift': number;
    /**
     * 
     * @type {number}
     * @memberof DataDriftColumnOverviewResponse
     */
    'numColumnsWithWarning': number;
    /**
     * 
     * @type {number}
     * @memberof DataDriftColumnOverviewResponse
     */
    'numColumnsWithNoDrift': number;
}
/**
 * 
 * @export
 * @interface DataDriftInferenceSource
 */
export interface DataDriftInferenceSource {
    /**
     * 
     * @type {InferenceSourceType}
     * @memberof DataDriftInferenceSource
     */
    'sourceType': InferenceSourceType;
    /**
     * 
     * @type {string}
     * @memberof DataDriftInferenceSource
     */
    'sourceIdentifier'?: string;
}
/**
 * 
 * @export
 * @interface DataDriftModelDetailsResponse
 */
export interface DataDriftModelDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof DataDriftModelDetailsResponse
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof DataDriftModelDetailsResponse
     */
    'dataDriftResultId': string;
    /**
     * 
     * @type {string}
     * @memberof DataDriftModelDetailsResponse
     */
    'modelName': string;
    /**
     * 
     * @type {string}
     * @memberof DataDriftModelDetailsResponse
     */
    'modelClass': string;
    /**
     * 
     * @type {DataDriftInferenceSource}
     * @memberof DataDriftModelDetailsResponse
     */
    'source': DataDriftInferenceSource;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataDriftModelDetailsResponse
     */
    'features'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DataDriftModelDetailsResponse
     */
    'target'?: string;
}
/**
 * 
 * @export
 * @interface DataDriftOutput
 */
export interface DataDriftOutput {
    /**
     * 
     * @type {string}
     * @memberof DataDriftOutput
     */
    'dataDriftId': string;
}
/**
 * 
 * @export
 * @interface DataDriftOverviewResponse
 */
export interface DataDriftOverviewResponse {
    /**
     * 
     * @type {ModelDriftStatus}
     * @memberof DataDriftOverviewResponse
     */
    'status': ModelDriftStatus;
    /**
     * 
     * @type {number}
     * @memberof DataDriftOverviewResponse
     */
    'overallDriftScore': number;
    /**
     * 
     * @type {number}
     * @memberof DataDriftOverviewResponse
     */
    'numberOfColumnsDrifted': number;
    /**
     * 
     * @type {number}
     * @memberof DataDriftOverviewResponse
     */
    'totalColumns': number;
    /**
     * 
     * @type {string}
     * @memberof DataDriftOverviewResponse
     */
    'recommendation': string;
    /**
     * 
     * @type {DataDriftColumnOverviewResponse}
     * @memberof DataDriftOverviewResponse
     */
    'columnOverview': DataDriftColumnOverviewResponse;
    /**
     * 
     * @type {string}
     * @memberof DataDriftOverviewResponse
     */
    'timeOfDetection': string;
}
/**
 * Represents a high level collection of DataSets that solve similar purpose and have similar expected behavior. This object is returned after a DataFamily is registered with Meskalero Ex:     CreditCardFraudFamily en-US would contain all the english US datasets for CreditCardFraud in the US.
 * @export
 * @interface DataFamilyModel
 */
export interface DataFamilyModel {
    /**
     * 
     * @type {string}
     * @memberof DataFamilyModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DataFamilyModel
     */
    'dataFamilyId': string;
    /**
     * 
     * @type {string}
     * @memberof DataFamilyModel
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface DataOperationStateModel
 */
export interface DataOperationStateModel {
    /**
     * 
     * @type {string}
     * @memberof DataOperationStateModel
     */
    'dataOperationId': string;
    /**
     * 
     * @type {DataOperationType}
     * @memberof DataOperationStateModel
     */
    'type': DataOperationType;
    /**
     * 
     * @type {string}
     * @memberof DataOperationStateModel
     */
    'updateDate': string;
    /**
     * 
     * @type {DatasetOperationState}
     * @memberof DataOperationStateModel
     */
    'state': DatasetOperationState;
    /**
     * 
     * @type {FullAnalysisState | RuleBasedRelabelingState | ManualRelabelingState}
     * @memberof DataOperationStateModel
     */
    'stateDetail'?: FullAnalysisState | RuleBasedRelabelingState | ManualRelabelingState;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum DataOperationType {
    RuleBasedRelabeling = 'RULE_BASED_RELABELING',
    ManualRelabeling = 'MANUAL_RELABELING',
    FullAnalysis = 'FULL_ANALYSIS',
    SubsetRelabeling = 'SUBSET_RELABELING'
}

/**
 * 
 * @export
 * @interface DataProperties
 */
export interface DataProperties {
    /**
     * 
     * @type {string}
     * @memberof DataProperties
     */
    'type'?: DataPropertiesTypeEnum;
    /**
     * 
     * @type {SchemaChange}
     * @memberof DataProperties
     */
    'schemaChanges'?: SchemaChange;
    /**
     * 
     * @type {Array<ColumnSchema>}
     * @memberof DataProperties
     */
    'columnSchema'?: Array<ColumnSchema>;
    /**
     * 
     * @type {Array<object>}
     * @memberof DataProperties
     */
    'sampleData'?: Array<object>;
}

/**
    * @export
    * @enum {string}
    */
export enum DataPropertiesTypeEnum {
    Dataframe = 'DATAFRAME'
}

/**
 * 
 * @export
 * @interface DataQualityClassMetric
 */
export interface DataQualityClassMetric {
    /**
     * 
     * @type {string}
     * @memberof DataQualityClassMetric
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof DataQualityClassMetric
     */
    'score': number;
}
/**
 * 
 * @export
 * @interface DataQualityClassResponse
 */
export interface DataQualityClassResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof DataQualityClassResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {Array<DataQualityClassMetric>}
     * @memberof DataQualityClassResponse
     */
    'response': Array<DataQualityClassMetric>;
    /**
     * 
     * @type {string}
     * @memberof DataQualityClassResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof DataQualityClassResponse
     */
    'datasetId': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum DataResourceType {
    Pdf = 'pdf',
    Csv = 'csv',
    Database = 'database',
    Table = 'table',
    Folder = 'folder',
    Default = 'default'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum DataResourceType1 {
    Pdf = 'pdf',
    Csv = 'csv',
    Database = 'database',
    Table = 'table',
    Folder = 'folder',
    Default = 'default'
}

/**
 * Path of this DataSegment
 * @export
 * @interface DataSegment
 */
export interface DataSegment {
    /**
     * 
     * @type {SegmentType}
     * @memberof DataSegment
     */
    'segmentType': SegmentType;
    /**
     * 
     * @type {string}
     * @memberof DataSegment
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSegment
     */
    'resourceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof DataSegment
     */
    'numRecords'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataSegment
     */
    'numCols'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataSegment
     */
    'classNames'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DataSetModel
 */
export interface DataSetModel {
    /**
     * 
     * @type {string}
     * @memberof DataSetModel
     */
    'name': string;
    /**
     * 
     * @type {DataFamilyModel}
     * @memberof DataSetModel
     */
    'dataFamily': DataFamilyModel;
    /**
     * 
     * @type {DataCategory}
     * @memberof DataSetModel
     */
    'dataType': DataCategory;
    /**
     * 
     * @type {string}
     * @memberof DataSetModel
     */
    'source': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof DataSetModel
     */
    'xColumns': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DataSetModel
     */
    'yColumn': number;
    /**
     * 
     * @type {string}
     * @memberof DataSetModel
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof DataSetModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof DataSetModel
     */
    'credId': string;
    /**
     * 
     * @type {string}
     * @memberof DataSetModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof DataSetModel
     */
    'datasetId': string;
    /**
     * 
     * @type {DatasetState}
     * @memberof DataSetModel
     */
    'state'?: DatasetState;
    /**
     * 
     * @type {AnalysisStatus1}
     * @memberof DataSetModel
     */
    'analysis'?: AnalysisStatus1;
    /**
     * 
     * @type {string}
     * @memberof DataSetModel
     */
    'delimiter'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataSetModel
     */
    'xColNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DataSetModel
     */
    'yColName'?: string;
    /**
     * 
     * @type {Array<DataSegment>}
     * @memberof DataSetModel
     */
    'segments'?: Array<DataSegment>;
    /**
     * 
     * @type {number | string}
     * @memberof DataSetModel
     */
    'numRecords'?: number | string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataSetModel
     */
    'classNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DataSetModel
     */
    'teamId'?: string;
    /**
     * Data quality score. It\'s a computed value. Value of -1 indicates that data isn\'t available.
     * @type {number}
     * @memberof DataSetModel
     */
    'dataQualityScore'?: number;
    /**
     * Number of mislabeled records. It\'s a computed value. Value of -1 indicates that data isn\'t available.
     * @type {number}
     * @memberof DataSetModel
     */
    'numMislabeled'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataSetModel
     */
    'parentDatasetId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataSetModel
     */
    'childrenDatasetIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DataSetModel
     */
    'demoArtifact'?: boolean;
    /**
     * 
     * @type {ArtifactStateModel}
     * @memberof DataSetModel
     */
    'artifactState'?: ArtifactStateModel;
    /**
     * 
     * @type {number}
     * @memberof DataSetModel
     */
    'numFeatureColumns'?: number;
}
/**
 * Response of comprehensive comparison of more  two or more dataset across all the segments
 * @export
 * @interface DataSetMultiPairResponse
 */
export interface DataSetMultiPairResponse {
    /**
     * 
     * @type {DataSetMultiPairResult | object}
     * @memberof DataSetMultiPairResponse
     */
    'response': DataSetMultiPairResult | object;
    /**
     * 
     * @type {string}
     * @memberof DataSetMultiPairResponse
     */
    'workspaceId'?: string;
}
/**
 * Return the comprehensive result of multi-dataset comparison across all segments. This is expensive
 * @export
 * @interface DataSetMultiPairResponseDetails
 */
export interface DataSetMultiPairResponseDetails {
    /**
     * 
     * @type {string}
     * @memberof DataSetMultiPairResponseDetails
     */
    'primary': string;
    /**
     * 
     * @type {Array<DataSetPairComparisonResults>}
     * @memberof DataSetMultiPairResponseDetails
     */
    'comparisons': Array<DataSetPairComparisonResults>;
}
/**
 * Returns the response of comparison of more than two datasets across all the respective segments
 * @export
 * @interface DataSetMultiPairResult
 */
export interface DataSetMultiPairResult {
    /**
     * 
     * @type {Array<SimMetricDetail>}
     * @memberof DataSetMultiPairResult
     */
    'metrics': Array<SimMetricDetail>;
    /**
     * 
     * @type {DataSetMultiPairResponseDetails}
     * @memberof DataSetMultiPairResult
     */
    'details': DataSetMultiPairResponseDetails;
    /**
     * 
     * @type {number}
     * @memberof DataSetMultiPairResult
     */
    'numMetrics': number;
}
/**
 * 
 * @export
 * @interface DataSetPair
 */
export interface DataSetPair {
    /**
     * 
     * @type {string}
     * @memberof DataSetPair
     */
    'primary': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataSetPair
     */
    'compareWith': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DataSetPair
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSetPair
     */
    'dataType'?: string;
}
/**
 * Stores the result of comparison of all segments of a primary dataset against all the segments of a single compare_with dataset with an id compare_with
 * @export
 * @interface DataSetPairComparisonResults
 */
export interface DataSetPairComparisonResults {
    /**
     * 
     * @type {string}
     * @memberof DataSetPairComparisonResults
     */
    'compareWith': string;
    /**
     * 
     * @type {Array<DataSetPairSegmentSimilarity>}
     * @memberof DataSetPairComparisonResults
     */
    'results': Array<DataSetPairSegmentSimilarity>;
    /**
     * 
     * @type {string}
     * @memberof DataSetPairComparisonResults
     */
    'status': string;
}
/**
 * Return the similarity of two segments belonging to two different datasets For example Primary-Segment-Train compared with compare_with-Segment-Test
 * @export
 * @interface DataSetPairSegmentSimilarity
 */
export interface DataSetPairSegmentSimilarity {
    /**
     * 
     * @type {string}
     * @memberof DataSetPairSegmentSimilarity
     */
    'primarySegment': string;
    /**
     * 
     * @type {string}
     * @memberof DataSetPairSegmentSimilarity
     */
    'compareWithSegment': string;
    /**
     * 
     * @type {Array<SimilarityMetricScore> | Array<any>}
     * @memberof DataSetPairSegmentSimilarity
     */
    'similarities': Array<SimilarityMetricScore> | Array<any>;
}
/**
 * Returns the similarity result of two dataset pairs given their respective segments for comparison
 * @export
 * @interface DataSetPairSegmentSimilarityResponse
 */
export interface DataSetPairSegmentSimilarityResponse {
    /**
     * 
     * @type {DataSetPairSegmentSimilarityResult}
     * @memberof DataSetPairSegmentSimilarityResponse
     */
    'response': DataSetPairSegmentSimilarityResult;
    /**
     * 
     * @type {string}
     * @memberof DataSetPairSegmentSimilarityResponse
     */
    'workspaceId': string;
}
/**
 * Results of comparing two datasets and given segments
 * @export
 * @interface DataSetPairSegmentSimilarityResult
 */
export interface DataSetPairSegmentSimilarityResult {
    /**
     * 
     * @type {Array<SimMetricDetail>}
     * @memberof DataSetPairSegmentSimilarityResult
     */
    'metrics': Array<SimMetricDetail>;
    /**
     * 
     * @type {DataSetPairSimDetail}
     * @memberof DataSetPairSegmentSimilarityResult
     */
    'details': DataSetPairSimDetail;
}
/**
 * 
 * @export
 * @interface DataSetPairSimDetail
 */
export interface DataSetPairSimDetail {
    /**
     * 
     * @type {string}
     * @memberof DataSetPairSimDetail
     */
    'primary': string;
    /**
     * 
     * @type {string}
     * @memberof DataSetPairSimDetail
     */
    'compareWith': string;
    /**
     * 
     * @type {DataSetPairSegmentSimilarity}
     * @memberof DataSetPairSimDetail
     */
    'comparison': DataSetPairSegmentSimilarity;
}
/**
 * 
 * @export
 * @interface DataSetSegmentSimilarity
 */
export interface DataSetSegmentSimilarity {
    /**
     * 
     * @type {string}
     * @memberof DataSetSegmentSimilarity
     */
    'primary': string;
    /**
     * 
     * @type {string}
     * @memberof DataSetSegmentSimilarity
     */
    'primarySegment': string;
    /**
     * 
     * @type {string}
     * @memberof DataSetSegmentSimilarity
     */
    'compareWith': string;
    /**
     * 
     * @type {string}
     * @memberof DataSetSegmentSimilarity
     */
    'compareWithSegment': string;
}
/**
 * 
 * @export
 * @interface DataSourceConversationModel
 */
export interface DataSourceConversationModel {
    /**
     * 
     * @type {string}
     * @memberof DataSourceConversationModel
     */
    'threadId': string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceConversationModel
     */
    'conversationId': string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceConversationModel
     */
    'question': string;
    /**
     * 
     * @type {QuestionTypes}
     * @memberof DataSourceConversationModel
     */
    'questionType': QuestionTypes;
    /**
     * 
     * @type {any}
     * @memberof DataSourceConversationModel
     */
    'answer': any;
    /**
     * 
     * @type {AnswerTypes}
     * @memberof DataSourceConversationModel
     */
    'answerType': AnswerTypes;
    /**
     * 
     * @type {boolean}
     * @memberof DataSourceConversationModel
     */
    'isAnswerEdited'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DataSourceConversationModel
     */
    'createDate': string;
}
/**
 * 
 * @export
 * @interface DataSourceInitConnectionResponseModel
 */
export interface DataSourceInitConnectionResponseModel {
    /**
     * 
     * @type {string}
     * @memberof DataSourceInitConnectionResponseModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceInitConnectionResponseModel
     */
    'dataSourceId': string;
    /**
     * 
     * @type {LLMTypes}
     * @memberof DataSourceInitConnectionResponseModel
     */
    'llmType': LLMTypes;
    /**
     * 
     * @type {boolean}
     * @memberof DataSourceInitConnectionResponseModel
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface DataSourceThreadModel
 */
export interface DataSourceThreadModel {
    /**
     * 
     * @type {string}
     * @memberof DataSourceThreadModel
     */
    'threadId': string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceThreadModel
     */
    'threadTitle': string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceThreadModel
     */
    'createDate': string;
    /**
     * Fetch artifacts based on query param filter ACTIVE/ARCHIVED
     * @type {ArtifactStateModelV2}
     * @memberof DataSourceThreadModel
     */
    'artifactState': ArtifactStateModelV2;
}
/**
 * 
 * @export
 * @interface DataStoreModel
 */
export interface DataStoreModel {
    /**
     * 
     * @type {string}
     * @memberof DataStoreModel
     */
    'dataStoreId': string;
    /**
     * 
     * @type {string}
     * @memberof DataStoreModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DataStoreModel
     */
    'pathPrefix'?: string;
}
/**
 * 
 * @export
 * @interface DataViewEssentials
 */
export interface DataViewEssentials {
    /**
     * 
     * @type {string}
     * @memberof DataViewEssentials
     */
    'dataViewId': string;
    /**
     * 
     * @type {string}
     * @memberof DataViewEssentials
     */
    'datasetId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataViewEssentials
     */
    'features': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DataViewEssentials
     */
    'target'?: string;
}
/**
 * 
 * @export
 * @interface Dataset
 */
export interface Dataset {
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DatasetAnalyticsExplainWithAIRequestModel
 */
export interface DatasetAnalyticsExplainWithAIRequestModel {
    /**
     * 
     * @type {string}
     * @memberof DatasetAnalyticsExplainWithAIRequestModel
     */
    'svgImage': string;
}
/**
 * 
 * @export
 * @interface DatasetAnalyticsExplainWithAIResponseModel
 */
export interface DatasetAnalyticsExplainWithAIResponseModel {
    /**
     * A short description of what the chart shows, in markdown format.
     * @type {string}
     * @memberof DatasetAnalyticsExplainWithAIResponseModel
     */
    'chartExplanation': string;
    /**
     * Key takeaways that explain the pattern in the data, in markdown format.
     * @type {string}
     * @memberof DatasetAnalyticsExplainWithAIResponseModel
     */
    'keyTakeaways': string;
    /**
     * What insights or conclusions the user can draw from the chart, in markdown format.
     * @type {string}
     * @memberof DatasetAnalyticsExplainWithAIResponseModel
     */
    'dataInsight': string;
}
/**
 * Base class that serves as the foundation for all graph related responses. It includes an attribute analysis_state, which represents the state of analysis run for a dataset. The possible analysis states are defined in the AnalysisStateNames enum.
 * @export
 * @interface DatasetClassDistributionResponse
 */
export interface DatasetClassDistributionResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof DatasetClassDistributionResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {Array<Analysis>}
     * @memberof DatasetClassDistributionResponse
     */
    'response': Array<Analysis>;
    /**
     * 
     * @type {string}
     * @memberof DatasetClassDistributionResponse
     */
    'workspaceId': string;
}
/**
 * Datastructure to map order of analysis widgets in comparison view.
 * @export
 * @interface DatasetComparisonLayout
 */
export interface DatasetComparisonLayout {
    /**
     * 
     * @type {string}
     * @memberof DatasetComparisonLayout
     */
    'primary': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetComparisonLayout
     */
    'secondary': string;
    /**
     * 
     * @type {Array<LayoutMapping>}
     * @memberof DatasetComparisonLayout
     */
    'primaryAnalysisOrder'?: Array<LayoutMapping>;
    /**
     * 
     * @type {Array<LayoutMapping>}
     * @memberof DatasetComparisonLayout
     */
    'secondaryAnalysisOrder'?: Array<LayoutMapping>;
}
/**
 * API response for fetching dataset comparison layout
 * @export
 * @interface DatasetComparisonLayoutResponse
 */
export interface DatasetComparisonLayoutResponse {
    /**
     * 
     * @type {string}
     * @memberof DatasetComparisonLayoutResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {DatasetComparisonLayout}
     * @memberof DatasetComparisonLayoutResponse
     */
    'response': DatasetComparisonLayout;
}
/**
 * 
 * @export
 * @interface DatasetCorrelationInfo
 */
export interface DatasetCorrelationInfo {
    /**
     * 
     * @type {DatasetCorrelationInfoMetadata}
     * @memberof DatasetCorrelationInfo
     */
    'metadata'?: DatasetCorrelationInfoMetadata;
    /**
     * 
     * @type {HeatMapDataModel1}
     * @memberof DatasetCorrelationInfo
     */
    'data'?: HeatMapDataModel1;
}
/**
 * 
 * @export
 * @interface DatasetCorrelationInfoMetadata
 */
export interface DatasetCorrelationInfoMetadata {
    /**
     * 
     * @type {string}
     * @memberof DatasetCorrelationInfoMetadata
     */
    'name': string;
}
/**
 * Base class that serves as the foundation for all graph related responses. It includes an attribute analysis_state, which represents the state of analysis run for a dataset. The possible analysis states are defined in the AnalysisStateNames enum.
 * @export
 * @interface DatasetCorrelationInfoResponse
 */
export interface DatasetCorrelationInfoResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof DatasetCorrelationInfoResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof DatasetCorrelationInfoResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {DatasetCorrelationInfo}
     * @memberof DatasetCorrelationInfoResponse
     */
    'response'?: DatasetCorrelationInfo;
}
/**
 * 
 * @export
 * @interface DatasetCorrelationInfoTypes
 */
export interface DatasetCorrelationInfoTypes {
    /**
     * 
     * @type {Array<string>}
     * @memberof DatasetCorrelationInfoTypes
     */
    'analyzedCorrelations': Array<string>;
}
/**
 * Base class that serves as the foundation for all graph related responses. It includes an attribute analysis_state, which represents the state of analysis run for a dataset. The possible analysis states are defined in the AnalysisStateNames enum.
 * @export
 * @interface DatasetCorrelationInfoTypesResponse
 */
export interface DatasetCorrelationInfoTypesResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof DatasetCorrelationInfoTypesResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof DatasetCorrelationInfoTypesResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {DatasetCorrelationInfoTypes}
     * @memberof DatasetCorrelationInfoTypesResponse
     */
    'response'?: DatasetCorrelationInfoTypes;
}
/**
 * 
 * @export
 * @interface DatasetDelimiterData
 */
export interface DatasetDelimiterData {
    /**
     * 
     * @type {Array<Delimiter>}
     * @memberof DatasetDelimiterData
     */
    'delimiters': Array<Delimiter>;
}
/**
 * 
 * @export
 * @interface DatasetEditableFields
 */
export interface DatasetEditableFields {
    /**
     * 
     * @type {string}
     * @memberof DatasetEditableFields
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetEditableFields
     */
    'name'?: string;
}
/**
 * Dataset details object for Activity/ Search Results
 * @export
 * @interface DatasetInfo
 */
export interface DatasetInfo {
    /**
     * 
     * @type {any}
     * @memberof DatasetInfo
     */
    'datasetId': any;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfo
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfo
     */
    'createDate': string;
}
/**
 * 
 * @export
 * @interface DatasetInfo1
 */
export interface DatasetInfo1 {
    /**
     * 
     * @type {string}
     * @memberof DatasetInfo1
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfo1
     */
    'datasetId': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfo1
     */
    'createDate': string;
    /**
     * Instead of providing an overall status, API would provide granular status under `tasksSummary` of AnalysisTasks
     * @type {AnalysisStateNames}
     * @memberof DatasetInfo1
     * @deprecated
     */
    'status': AnalysisStateNames;
}
/**
 * 
 * @export
 * @interface DatasetInfo2
 */
export interface DatasetInfo2 {
    /**
     * 
     * @type {string}
     * @memberof DatasetInfo2
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfo2
     */
    'datasetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfo2
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface DatasetInfoResponse
 */
export interface DatasetInfoResponse {
    /**
     * 
     * @type {DataSetModel}
     * @memberof DatasetInfoResponse
     */
    'response'?: DataSetModel;
    /**
     * 
     * @type {APIMessage}
     * @memberof DatasetInfoResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfoResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface DatasetLabelsInfo
 */
export interface DatasetLabelsInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof DatasetLabelsInfo
     */
    'labels': Array<string>;
}
/**
 * 
 * @export
 * @interface DatasetMissingValuesBarChart
 */
export interface DatasetMissingValuesBarChart {
    /**
     * 
     * @type {DatasetMissingValuesMetadata}
     * @memberof DatasetMissingValuesBarChart
     */
    'metadata'?: DatasetMissingValuesMetadata;
    /**
     * 
     * @type {GenericChartDataModel1}
     * @memberof DatasetMissingValuesBarChart
     */
    'data'?: GenericChartDataModel1;
}
/**
 * Base class that serves as the foundation for all graph related responses. It includes an attribute analysis_state, which represents the state of analysis run for a dataset. The possible analysis states are defined in the AnalysisStateNames enum.
 * @export
 * @interface DatasetMissingValuesBarChartResponse
 */
export interface DatasetMissingValuesBarChartResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof DatasetMissingValuesBarChartResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof DatasetMissingValuesBarChartResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {DatasetMissingValuesBarChart}
     * @memberof DatasetMissingValuesBarChartResponse
     */
    'response'?: DatasetMissingValuesBarChart;
}
/**
 * 
 * @export
 * @interface DatasetMissingValuesMetadata
 */
export interface DatasetMissingValuesMetadata {
    /**
     * 
     * @type {string}
     * @memberof DatasetMissingValuesMetadata
     */
    'name': string;
    /**
     * 
     * @type {ChartTypes1}
     * @memberof DatasetMissingValuesMetadata
     */
    'preferredChartType'?: ChartTypes1;
}
/**
 * 
 * @export
 * @interface DatasetNameInfo
 */
export interface DatasetNameInfo {
    /**
     * 
     * @type {string}
     * @memberof DatasetNameInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetNameInfo
     */
    'datasetId': string;
}
/**
 * 
 * @export
 * @interface DatasetOperationDetailsResponse
 */
export interface DatasetOperationDetailsResponse {
    /**
     * 
     * @type {RuleBasedRelabelingDetailsModel | SubsetRelabelingDetailsModel | ManualRelabelingDetailsModel | FullAnalysisDetailsModel}
     * @memberof DatasetOperationDetailsResponse
     */
    'response': RuleBasedRelabelingDetailsModel | SubsetRelabelingDetailsModel | ManualRelabelingDetailsModel | FullAnalysisDetailsModel;
    /**
     * 
     * @type {DataOperationType}
     * @memberof DatasetOperationDetailsResponse
     */
    'dataOperationType': DataOperationType;
    /**
     * 
     * @type {string}
     * @memberof DatasetOperationDetailsResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface DatasetOperationModel
 */
export interface DatasetOperationModel {
    /**
     * 
     * @type {string}
     * @memberof DatasetOperationModel
     */
    'dataOperationId': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetOperationModel
     */
    'name': string;
    /**
     * Fetch artifacts based on query  param filter ACTIVE/ARCHIVED
     * @type {ArtifactStateModel}
     * @memberof DatasetOperationModel
     */
    'artifactState': ArtifactStateModel;
    /**
     * 
     * @type {string}
     * @memberof DatasetOperationModel
     */
    'datasetId': string;
    /**
     * 
     * @type {DataOperationType}
     * @memberof DatasetOperationModel
     */
    'type': DataOperationType;
    /**
     * 
     * @type {string}
     * @memberof DatasetOperationModel
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetOperationModel
     */
    'updatedBy': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatasetOperationModel
     */
    'contributors': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DatasetOperationModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetOperationModel
     */
    'updateDate': string;
    /**
     * 
     * @type {DatasetOperationState}
     * @memberof DatasetOperationModel
     */
    'state': DatasetOperationState;
    /**
     * 
     * @type {FullAnalysisState | RuleBasedRelabelingState | ManualRelabelingState | SubsetRelabelingState}
     * @memberof DatasetOperationModel
     */
    'stateDetail'?: FullAnalysisState | RuleBasedRelabelingState | ManualRelabelingState | SubsetRelabelingState;
    /**
     * 
     * @type {RuleBasedRelabelingDetailsModel | SubsetRelabelingDetailsModel | ManualRelabelingDetailsModel | FullAnalysisDetailsModel}
     * @memberof DatasetOperationModel
     */
    'details'?: RuleBasedRelabelingDetailsModel | SubsetRelabelingDetailsModel | ManualRelabelingDetailsModel | FullAnalysisDetailsModel;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum DatasetOperationState {
    NotStarted = 'NOT_STARTED',
    Ongoing = 'ONGOING',
    Processing = 'PROCESSING',
    Failed = 'FAILED',
    Completed = 'COMPLETED',
    Stopped = 'STOPPED'
}

/**
 * 
 * @export
 * @interface DatasetOverview
 */
export interface DatasetOverview {
    /**
     * 
     * @type {number}
     * @memberof DatasetOverview
     */
    'numFeatures': number;
    /**
     * 
     * @type {number}
     * @memberof DatasetOverview
     */
    'numRows': number;
    /**
     * 
     * @type {number}
     * @memberof DatasetOverview
     */
    'missingCells': number;
    /**
     * 
     * @type {number}
     * @memberof DatasetOverview
     */
    'percentMissingCell': number;
    /**
     * 
     * @type {number}
     * @memberof DatasetOverview
     */
    'dupRows': number;
    /**
     * 
     * @type {number}
     * @memberof DatasetOverview
     */
    'percentDupRows': number;
    /**
     * 
     * @type {number}
     * @memberof DatasetOverview
     */
    'memorySizeInMegaBytes': number;
    /**
     * 
     * @type {number}
     * @memberof DatasetOverview
     */
    'avgRowMemorySizeInBytes': number;
    /**
     * 
     * @type {object}
     * @memberof DatasetOverview
     */
    'dtypeCount': object;
    /**
     * 
     * @type {Array<Insight>}
     * @memberof DatasetOverview
     */
    'insights'?: Array<Insight>;
}
/**
 * Base class that serves as the foundation for all graph related responses. It includes an attribute analysis_state, which represents the state of analysis run for a dataset. The possible analysis states are defined in the AnalysisStateNames enum.
 * @export
 * @interface DatasetOverviewResponse
 */
export interface DatasetOverviewResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof DatasetOverviewResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof DatasetOverviewResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {DatasetOverview}
     * @memberof DatasetOverviewResponse
     */
    'response'?: DatasetOverview;
}
/**
 * 
 * @export
 * @interface DatasetPreviewData
 */
export interface DatasetPreviewData {
    /**
     * 
     * @type {Array<SegmentType>}
     * @memberof DatasetPreviewData
     */
    'segments': Array<SegmentType>;
    /**
     * 
     * @type {{ [key: string]: FilePreview; }}
     * @memberof DatasetPreviewData
     */
    'preview': { [key: string]: FilePreview; };
    /**
     * 
     * @type {string}
     * @memberof DatasetPreviewData
     */
    'delimiter'?: string;
}
/**
 * Model for the workflow object for data registration
 * @export
 * @interface DatasetRegistrationWorkflowModel
 */
export interface DatasetRegistrationWorkflowModel {
    /**
     * 
     * @type {string}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'workflowId': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'datastoreId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'credentialId'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'segments'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'connectorResources'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'delimiter'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'features'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'predictor'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'dataFamilyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'source'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'analyze'?: boolean;
    /**
     * 
     * @type {WorkflowMode}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'mode': WorkflowMode;
    /**
     * 
     * @type {RegistrationFlowStatus}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'status': RegistrationFlowStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'taskletIds'?: Array<string>;
    /**
     * 
     * @type {DataCategory}
     * @memberof DatasetRegistrationWorkflowModel
     */
    'dataCategory'?: DataCategory;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum DatasetSamplingStrategy {
    Sequential = 'SEQUENTIAL'
}

/**
 * Paginated list of datasets for get datasets api response
 * @export
 * @interface DatasetSearchResults
 */
export interface DatasetSearchResults {
    /**
     * 
     * @type {Array<DatasetInfo>}
     * @memberof DatasetSearchResults
     */
    'results'?: Array<DatasetInfo>;
    /**
     * 
     * @type {number}
     * @memberof DatasetSearchResults
     */
    'numRecords'?: number;
}
/**
 * 
 * @export
 * @interface DatasetState
 */
export interface DatasetState {
    /**
     * 
     * @type {DatasetStateNames}
     * @memberof DatasetState
     */
    'name': DatasetStateNames;
    /**
     * 
     * @type {string}
     * @memberof DatasetState
     */
    'on': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetState
     */
    'by': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum DatasetStateNames {
    HardDelete = 'hard_delete',
    SoftDelete = 'soft_delete',
    Created = 'created'
}

/**
 * 
 * @export
 * @interface DatasetTasklet
 */
export interface DatasetTasklet {
    /**
     * 
     * @type {string}
     * @memberof DatasetTasklet
     */
    'taskletId': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetTasklet
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetTasklet
     */
    'taskletRunType': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetTasklet
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DatasetTasklet
     */
    'isDefault'?: boolean;
}
/**
 * This class defines the structure for the Tasklet Groups in the Vienna framework.  Attributes:     name (str): The name of the Tasklet Group.     description (str): A brief description of the Tasklet Group.     tasklet_ids (List[str]): A list of tasklets included in the group.
 * @export
 * @interface DatasetTaskletGroup
 */
export interface DatasetTaskletGroup {
    /**
     * 
     * @type {string}
     * @memberof DatasetTaskletGroup
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetTaskletGroup
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatasetTaskletGroup
     */
    'taskletIds': Array<string>;
}
/**
 * 
 * @export
 * @interface DatasetTopologyResponse
 */
export interface DatasetTopologyResponse {
    /**
     * 
     * @type {Array<object>}
     * @memberof DatasetTopologyResponse
     */
    'response'?: Array<object>;
}
/**
 * 
 * @export
 * @interface DatasetVariableChartData
 */
export interface DatasetVariableChartData {
    /**
     * 
     * @type {DatasetVariableChartMetadata}
     * @memberof DatasetVariableChartData
     */
    'metadata': DatasetVariableChartMetadata;
    /**
     * 
     * @type {GenericChartDataModel1}
     * @memberof DatasetVariableChartData
     */
    'data': GenericChartDataModel1;
}
/**
 * 
 * @export
 * @interface DatasetVariableChartMetadata
 */
export interface DatasetVariableChartMetadata {
    /**
     * 
     * @type {string}
     * @memberof DatasetVariableChartMetadata
     */
    'name': string;
    /**
     * 
     * @type {ChartTypes1}
     * @memberof DatasetVariableChartMetadata
     */
    'preferredChartType': ChartTypes1;
}
/**
 * Base class that serves as the foundation for all graph related responses. It includes an attribute analysis_state, which represents the state of analysis run for a dataset. The possible analysis states are defined in the AnalysisStateNames enum.
 * @export
 * @interface DatasetVariableChartResponse
 */
export interface DatasetVariableChartResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof DatasetVariableChartResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof DatasetVariableChartResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {DatasetVariableChartData}
     * @memberof DatasetVariableChartResponse
     */
    'response'?: DatasetVariableChartData;
}
/**
 * 
 * @export
 * @interface DatasetVariableKeyValuePair
 */
export interface DatasetVariableKeyValuePair {
    /**
     * 
     * @type {string}
     * @memberof DatasetVariableKeyValuePair
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVariableKeyValuePair
     */
    'columnType': string;
}
/**
 * 
 * @export
 * @interface DatasetVariableStatsLayout
 */
export interface DatasetVariableStatsLayout {
    /**
     * 
     * @type {ColumnType}
     * @memberof DatasetVariableStatsLayout
     */
    'variableType': ColumnType;
    /**
     * 
     * @type {Array<DatasetVariableStatsVisualization>}
     * @memberof DatasetVariableStatsLayout
     */
    'layout': Array<DatasetVariableStatsVisualization>;
}
/**
 * 
 * @export
 * @interface DatasetVariableStatsVisualization
 */
export interface DatasetVariableStatsVisualization {
    /**
     * 
     * @type {string}
     * @memberof DatasetVariableStatsVisualization
     */
    'visualizationId': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVariableStatsVisualization
     */
    'chartType': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetVariableStatsVisualization
     */
    'title': string;
    /**
     * 
     * @type {VisualizationType}
     * @memberof DatasetVariableStatsVisualization
     */
    'visualizationType': VisualizationType;
}
/**
 * 
 * @export
 * @interface DatasetVariableTableData
 */
export interface DatasetVariableTableData {
    /**
     * 
     * @type {string}
     * @memberof DatasetVariableTableData
     */
    'key': string;
    /**
     * 
     * @type {any}
     * @memberof DatasetVariableTableData
     */
    'value'?: any;
    /**
     * 
     * @type {string}
     * @memberof DatasetVariableTableData
     */
    'displayName': string;
}
/**
 * Base class that serves as the foundation for all graph related responses. It includes an attribute analysis_state, which represents the state of analysis run for a dataset. The possible analysis states are defined in the AnalysisStateNames enum.
 * @export
 * @interface DatasetVariableTableResponse
 */
export interface DatasetVariableTableResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof DatasetVariableTableResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof DatasetVariableTableResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<DatasetVariableTableData>}
     * @memberof DatasetVariableTableResponse
     */
    'response': Array<DatasetVariableTableData>;
}
/**
 * 
 * @export
 * @interface DatasetVariables
 */
export interface DatasetVariables {
    /**
     * 
     * @type {Array<DatasetVariableKeyValuePair>}
     * @memberof DatasetVariables
     */
    'variables': Array<DatasetVariableKeyValuePair>;
}
/**
 * Base class that serves as the foundation for all graph related responses. It includes an attribute analysis_state, which represents the state of analysis run for a dataset. The possible analysis states are defined in the AnalysisStateNames enum.
 * @export
 * @interface DatasetVariablesLayoutResponse
 */
export interface DatasetVariablesLayoutResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof DatasetVariablesLayoutResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof DatasetVariablesLayoutResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {DatasetVariableStatsLayout}
     * @memberof DatasetVariablesLayoutResponse
     */
    'response': DatasetVariableStatsLayout;
}
/**
 * Base class that serves as the foundation for all graph related responses. It includes an attribute analysis_state, which represents the state of analysis run for a dataset. The possible analysis states are defined in the AnalysisStateNames enum.
 * @export
 * @interface DatasetVariablesListResponse
 */
export interface DatasetVariablesListResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof DatasetVariablesListResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof DatasetVariablesListResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {DatasetVariables}
     * @memberof DatasetVariablesListResponse
     */
    'response'?: DatasetVariables;
}
/**
 * 
 * @export
 * @interface DatasetWithTags
 */
export interface DatasetWithTags {
    /**
     * 
     * @type {string}
     * @memberof DatasetWithTags
     */
    'datasetId': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetWithTags
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetWithTags
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetWithTags
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetWithTags
     */
    'userId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatasetWithTags
     */
    'classNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DatasetWithTags
     */
    'delimiter'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetWithTags
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetWithTags
     */
    'credId': string;
    /**
     * 
     * @type {string}
     * @memberof DatasetWithTags
     */
    'teamId'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof DatasetWithTags
     */
    'xColumns': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DatasetWithTags
     */
    'yColumn': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatasetWithTags
     */
    'xColNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DatasetWithTags
     */
    'yColName'?: string;
    /**
     * 
     * @type {number | string}
     * @memberof DatasetWithTags
     */
    'numRecords'?: number | string;
    /**
     * 
     * @type {number}
     * @memberof DatasetWithTags
     */
    'dataQualityScore': number;
    /**
     * 
     * @type {number}
     * @memberof DatasetWithTags
     */
    'numMislabeled': number;
    /**
     * 
     * @type {string}
     * @memberof DatasetWithTags
     */
    'parentDatasetId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatasetWithTags
     */
    'childrenDatasetIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DatasetWithTags
     */
    'demoArtifact': boolean;
    /**
     * 
     * @type {AnalysisStatus}
     * @memberof DatasetWithTags
     */
    'analysis'?: AnalysisStatus;
    /**
     * 
     * @type {DataFamilyModel}
     * @memberof DatasetWithTags
     */
    'dataFamily': DataFamilyModel;
    /**
     * 
     * @type {DataCategory}
     * @memberof DatasetWithTags
     */
    'dataType': DataCategory;
    /**
     * 
     * @type {Array<DataSegment>}
     * @memberof DatasetWithTags
     */
    'segments'?: Array<DataSegment>;
    /**
     * 
     * @type {DatasetState}
     * @memberof DatasetWithTags
     */
    'state'?: DatasetState;
    /**
     * 
     * @type {Array<TagMetadata>}
     * @memberof DatasetWithTags
     */
    'tags'?: Array<TagMetadata>;
}
/**
 * 
 * @export
 * @interface DateModel
 */
export interface DateModel {
    /**
     * Year in which citation is published/accessed.
     * @type {string}
     * @memberof DateModel
     */
    'year'?: string;
    /**
     * Month in which the citation is published/accessed, (from 1-12)
     * @type {string}
     * @memberof DateModel
     */
    'month'?: string;
    /**
     * Day on which the citation is published/accessed, (from 1-31 / 1-30 / 1-29)
     * @type {string}
     * @memberof DateModel
     */
    'day'?: string;
}
/**
 * Deactivate users request
 * @export
 * @interface DeactivateUsersFromWorkspaceRequest
 */
export interface DeactivateUsersFromWorkspaceRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeactivateUsersFromWorkspaceRequest
     */
    'userIds': Array<string>;
}
/**
 * Deactivate users response
 * @export
 * @interface DeactivatedUsersFromWorkspaceResponse
 */
export interface DeactivatedUsersFromWorkspaceResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeactivatedUsersFromWorkspaceResponse
     */
    'userIds': Array<string>;
}
/**
 * 
 * @export
 * @interface DefaultAlertNotificationPayload
 */
export interface DefaultAlertNotificationPayload {
    /**
     * 
     * @type {UserInfo}
     * @memberof DefaultAlertNotificationPayload
     */
    'userTo': UserInfo;
    /**
     * 
     * @type {string}
     * @memberof DefaultAlertNotificationPayload
     */
    'workspaceId': string;
    /**
     * 
     * @type {UserInfo}
     * @memberof DefaultAlertNotificationPayload
     */
    'userFrom'?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof DefaultAlertNotificationPayload
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof DefaultAlertNotificationPayload
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof DefaultAlertNotificationPayload
     */
    'redirectionLink': string;
}
/**
 * 
 * @export
 * @interface DeleteCitationsResponse
 */
export interface DeleteCitationsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteCitationsResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof DeleteCitationsResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DeleteCommentFromConversationResponse
 */
export interface DeleteCommentFromConversationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteCommentFromConversationResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface DeleteCommentResponse
 */
export interface DeleteCommentResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteCommentResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface DeleteConversationThreadResponseModel
 */
export interface DeleteConversationThreadResponseModel {
    /**
     * 
     * @type {string}
     * @memberof DeleteConversationThreadResponseModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteConversationThreadResponseModel
     */
    'threadId': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteConversationThreadResponseModel
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface DeleteCustomDictionaryResponse
 */
export interface DeleteCustomDictionaryResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteCustomDictionaryResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof DeleteCustomDictionaryResponse
     */
    'dictionaryId': string;
}
/**
 * 
 * @export
 * @interface DeleteDocumentResponse
 */
export interface DeleteDocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof DeleteDocumentResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteDocumentResponse
     */
    'documentIds': Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteEmbeddingResponse
 */
export interface DeleteEmbeddingResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEmbeddingResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface DeleteGenAIProjectResponse
 */
export interface DeleteGenAIProjectResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteGenAIProjectResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface DeleteModelRegistryResponse
 */
export interface DeleteModelRegistryResponse {
    /**
     * 
     * @type {string}
     * @memberof DeleteModelRegistryResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteModelRegistryResponse
     */
    'registryId': string;
}
/**
 * 
 * @export
 * @interface DeletePageSuggestionsResponse
 */
export interface DeletePageSuggestionsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeletePageSuggestionsResponse
     */
    'deletedResources'?: Array<string>;
}
/**
 * Delete Recordings request
 * @export
 * @interface DeleteRecordingRequest
 */
export interface DeleteRecordingRequest {
    /**
     * 
     * @type {Array<any>}
     * @memberof DeleteRecordingRequest
     */
    'recordingIds': Array<any>;
    /**
     * 
     * @type {string}
     * @memberof DeleteRecordingRequest
     * @deprecated
     */
    'memberId'?: string;
}
/**
 * 
 * @export
 * @interface DeleteRecordingsAck
 */
export interface DeleteRecordingsAck {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteRecordingsAck
     */
    'ack': boolean;
    /**
     * 
     * @type {number}
     * @memberof DeleteRecordingsAck
     */
    'recordsDeleted': number;
    /**
     * 
     * @type {number}
     * @memberof DeleteRecordingsAck
     */
    'totalRecordings': number;
}
/**
 * Response Model for - /v1/workspace/{workspace_id}/recordings/delete  Delete the list of recordings
 * @export
 * @interface DeleteRecordingsResponse
 */
export interface DeleteRecordingsResponse {
    /**
     * 
     * @type {DeleteRecordingsAck}
     * @memberof DeleteRecordingsResponse
     */
    'response': DeleteRecordingsAck;
    /**
     * 
     * @type {string}
     * @memberof DeleteRecordingsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface DeleteScheduledWorkflowResponse
 */
export interface DeleteScheduledWorkflowResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteScheduledWorkflowResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface DeleteStyleGuideRuleResponse
 */
export interface DeleteStyleGuideRuleResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteStyleGuideRuleResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof DeleteStyleGuideRuleResponse
     */
    'ruleId': string;
}
/**
 * 
 * @export
 * @interface DeleteStyleGuideRuleSetResponse
 */
export interface DeleteStyleGuideRuleSetResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteStyleGuideRuleSetResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof DeleteStyleGuideRuleSetResponse
     */
    'ruleSetId': string;
}
/**
 * 
 * @export
 * @interface DeleteWordsFromCustomDictionaryRequest
 */
export interface DeleteWordsFromCustomDictionaryRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteWordsFromCustomDictionaryRequest
     */
    'words': Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteWordsFromCustomDictionaryResponse
 */
export interface DeleteWordsFromCustomDictionaryResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteWordsFromCustomDictionaryResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof DeleteWordsFromCustomDictionaryResponse
     */
    'dictionaryId': string;
}
/**
 * 
 * @export
 * @interface DeleteWorkflowsRequest
 */
export interface DeleteWorkflowsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteWorkflowsRequest
     */
    'workflowIds': Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteWorkflowsResponse
 */
export interface DeleteWorkflowsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteWorkflowsResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface Delimiter
 */
export interface Delimiter {
    /**
     * 
     * @type {string}
     * @memberof Delimiter
     */
    'delimiter': string;
    /**
     * 
     * @type {string}
     * @memberof Delimiter
     */
    'name': string;
}
/**
 * Request for callback route after deployment in customer environment.
 * @export
 * @interface DeploymentAnalyzerVersionUpdateRequest
 */
export interface DeploymentAnalyzerVersionUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DeploymentAnalyzerVersionUpdateRequest
     */
    'analyzer_version': string;
}
/**
 * 
 * @export
 * @interface DeploymentInfo
 */
export interface DeploymentInfo {
    /**
     * 
     * @type {DeploymentMode}
     * @memberof DeploymentInfo
     */
    'deploymentMode': DeploymentMode;
    /**
     * 
     * @type {HybridDeploymentInfo}
     * @memberof DeploymentInfo
     */
    'info'?: HybridDeploymentInfo;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DeploymentMode {
    MarkovCloud = 'MARKOV_CLOUD',
    HybridDeployment = 'HYBRID_DEPLOYMENT'
}

/**
 * Request for callback route after deployment in customer environment.
 * @export
 * @interface DeploymentUpdateRequest
 */
export interface DeploymentUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DeploymentUpdateRequest
     */
    'base_url': string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentUpdateRequest
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentUpdateRequest
     */
    'domain'?: string;
    /**
     * 
     * @type {object}
     * @memberof DeploymentUpdateRequest
     */
    'info'?: object;
    /**
     * 
     * @type {object}
     * @memberof DeploymentUpdateRequest
     */
    'secret_info'?: object;
}
/**
 * Request for route to update base_url for an account
 * @export
 * @interface DeploymentUpdateResponse
 */
export interface DeploymentUpdateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeploymentUpdateResponse
     */
    'ack'?: boolean;
}
/**
 * 
 * @export
 * @interface DescriptiveStatistics
 */
export interface DescriptiveStatistics {
    /**
     * 
     * @type {number}
     * @memberof DescriptiveStatistics
     */
    'charCount': number;
    /**
     * 
     * @type {string}
     * @memberof DescriptiveStatistics
     */
    'charCountToolTip'?: string;
    /**
     * 
     * @type {number}
     * @memberof DescriptiveStatistics
     */
    'sentenceCount': number;
    /**
     * 
     * @type {string}
     * @memberof DescriptiveStatistics
     */
    'sentenceCountToolTip'?: string;
}
/**
 * 
 * @export
 * @interface DisInfo
 */
export interface DisInfo {
    /**
     * 
     * @type {string}
     * @memberof DisInfo
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof DisInfo
     */
    'inferred': string;
    /**
     * 
     * @type {number}
     * @memberof DisInfo
     */
    'score'?: number;
    /**
     * 
     * @type {string}
     * @memberof DisInfo
     */
    'recordingId': string;
}
/**
 * 
 * @export
 * @interface DisagreementInstance
 */
export interface DisagreementInstance {
    /**
     * 
     * @type {string}
     * @memberof DisagreementInstance
     */
    'urid': string;
    /**
     * 
     * @type {string}
     * @memberof DisagreementInstance
     */
    'actual': string;
    /**
     * 
     * @type {Array<DisInfo>}
     * @memberof DisagreementInstance
     */
    'disagreements'?: Array<DisInfo>;
}
/**
 * 
 * @export
 * @interface DisagreementResponse
 */
export interface DisagreementResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof DisagreementResponse
     */
    'userModelIds': Array<string>;
    /**
     * 
     * @type {Array<DisagreementInstance>}
     * @memberof DisagreementResponse
     */
    'records': Array<DisagreementInstance>;
    /**
     * 
     * @type {number}
     * @memberof DisagreementResponse
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface DistributionsSummary
 */
export interface DistributionsSummary {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof DistributionsSummary
     */
    'trueDistribution': { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof DistributionsSummary
     */
    'predictedDistribution': { [key: string]: number; };
}
/**
 * Segment level status for analyzing the segment from open ai and formulating text suggestions
 * @export
 * @enum {string}
 */

export enum DocumentChunkStatusType {
    Parsed = 'PARSED',
    Analysing = 'ANALYSING',
    Analysed = 'ANALYSED',
    AnalysisFailed = 'ANALYSIS_FAILED'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum DocumentDownloadFormats {
    Pdf = 'pdf',
    Docx = 'docx'
}

/**
 * Enum to store the download status type for a particular document.
 * @export
 * @enum {string}
 */

export enum DocumentDownloadStatusType {
    Processing = 'processing',
    Failed = 'failed',
    Completed = 'completed'
}

/**
 * 
 * @export
 * @interface DocumentParsingStatusResponse
 */
export interface DocumentParsingStatusResponse {
    /**
     * 
     * @type {DocumentStatusType}
     * @memberof DocumentParsingStatusResponse
     */
    'status': DocumentStatusType;
    /**
     * 
     * @type {string}
     * @memberof DocumentParsingStatusResponse
     */
    'documentId': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentParsingStatusResponse
     */
    'documentName': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentParsingStatusResponse
     */
    'createDate': string;
}
/**
 * 
 * @export
 * @interface DocumentResponse
 */
export interface DocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof DocumentResponse
     */
    'documentId': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponse
     */
    'documentName': string;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentResponse
     */
    'documentType': DocumentType;
    /**
     * 
     * @type {DocumentStatusType}
     * @memberof DocumentResponse
     */
    'status': DocumentStatusType;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponse
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponse
     */
    'uploadedBy': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponse
     */
    'llmType': string;
    /**
     * 
     * @type {Array<StyleGuideType>}
     * @memberof DocumentResponse
     */
    'styleGuides': Array<StyleGuideType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentResponse
     */
    'customStyleRules': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponse
     */
    'stylesheet'?: string;
}
/**
 * Track the upload status and parsing status of the whole pdf
 * @export
 * @enum {string}
 */

export enum DocumentStatusType {
    Uploading = 'UPLOADING',
    Uploaded = 'UPLOADED',
    InProgress = 'IN_PROGRESS',
    Parsed = 'PARSED',
    Finished = 'FINISHED',
    Failed = 'FAILED'
}

/**
 * Type of document that can be uploaded
 * @export
 * @enum {string}
 */

export enum DocumentType {
    Pdf = 'PDF',
    Text = 'TEXT',
    Docx = 'DOCX'
}

/**
 * 
 * @export
 * @interface DocumentUploadRequest
 */
export interface DocumentUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentUploadRequest
     */
    'documentName': string;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentUploadRequest
     */
    'docType': DocumentType;
    /**
     * 
     * @type {string}
     * @memberof DocumentUploadRequest
     */
    'llmType'?: string;
}
/**
 * DOCx parser types
 * @export
 * @enum {string}
 */

export enum DocxParserType {
    V2Parser = 'DOCX_V2_PARSER',
    V3Parser = 'DOCX_V3_PARSER'
}

/**
 * 
 * @export
 * @interface Edge
 */
export interface Edge {
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    'target': string;
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    'sourceHandle'?: string;
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    'targetHandle'?: string;
}
/**
 * 
 * @export
 * @interface EditCommentRequest
 */
export interface EditCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof EditCommentRequest
     */
    'content': string;
    /**
     * 
     * @type {Array<UserMentions>}
     * @memberof EditCommentRequest
     */
    'mentions'?: Array<UserMentions>;
}
/**
 * 
 * @export
 * @interface EditCommentRequestModel
 */
export interface EditCommentRequestModel {
    /**
     * 
     * @type {string}
     * @memberof EditCommentRequestModel
     */
    'content': string;
    /**
     * 
     * @type {Array<UserMention>}
     * @memberof EditCommentRequestModel
     */
    'mentions'?: Array<UserMention>;
    /**
     * 
     * @type {string}
     * @memberof EditCommentRequestModel
     */
    'resourceId': string;
    /**
     * 
     * @type {CommentResourceTypes}
     * @memberof EditCommentRequestModel
     */
    'resourceType': CommentResourceTypes;
    /**
     * 
     * @type {string}
     * @memberof EditCommentRequestModel
     */
    'locationKey'?: string;
}
/**
 * 
 * @export
 * @interface EditCommentResponse
 */
export interface EditCommentResponse {
    /**
     * 
     * @type {string}
     * @memberof EditCommentResponse
     */
    'commentId': string;
    /**
     * 
     * @type {string}
     * @memberof EditCommentResponse
     */
    'conversationId': string;
    /**
     * 
     * @type {string}
     * @memberof EditCommentResponse
     */
    'snippetId': string;
}
/**
 * 
 * @export
 * @interface EditCommentResponseModel
 */
export interface EditCommentResponseModel {
    /**
     * 
     * @type {string}
     * @memberof EditCommentResponseModel
     */
    'commentId': string;
    /**
     * 
     * @type {string}
     * @memberof EditCommentResponseModel
     */
    'conversationId': string;
}
/**
 * 
 * @export
 * @interface EditCredentialRequest
 */
export interface EditCredentialRequest {
    /**
     * 
     * @type {string}
     * @memberof EditCredentialRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditCredentialRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof EditCredentialRequest
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof EditCredentialRequest
     */
    'createDate': string;
}
/**
 * 
 * @export
 * @interface EditCredentialResponse
 */
export interface EditCredentialResponse {
    /**
     * 
     * @type {CredentialModel}
     * @memberof EditCredentialResponse
     */
    'response': CredentialModel;
    /**
     * 
     * @type {APIMessage}
     * @memberof EditCredentialResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {string}
     * @memberof EditCredentialResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface EditGeneratedSQLRequestModel
 */
export interface EditGeneratedSQLRequestModel {
    /**
     * 
     * @type {string}
     * @memberof EditGeneratedSQLRequestModel
     */
    'editedSqlQuery': string;
}
/**
 * 
 * @export
 * @interface EditSecretResponse
 */
export interface EditSecretResponse {
    /**
     * 
     * @type {SecretModel}
     * @memberof EditSecretResponse
     */
    'response': SecretModel;
    /**
     * 
     * @type {string}
     * @memberof EditSecretResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface EditableEvalFields
 */
export interface EditableEvalFields {
    /**
     * 
     * @type {string}
     * @memberof EditableEvalFields
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditableEvalFields
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface EmailInfo
 */
export interface EmailInfo {
    /**
     * 
     * @type {UserWithAccountDetails | object}
     * @memberof EmailInfo
     */
    'userInfo': UserWithAccountDetails | object;
    /**
     * 
     * @type {UserWithEmailState}
     * @memberof EmailInfo
     */
    'emailState': UserWithEmailState;
}
/**
 * 
 * @export
 * @interface EmailValidationResponse
 */
export interface EmailValidationResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailValidationResponse
     */
    'reason': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EmailValidationResponse
     */
    'result': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailValidationResponse
     */
    'isDeliverable': boolean;
}
/**
 * 
 * @export
 * @interface EmbeddingModel
 */
export interface EmbeddingModel {
    /**
     * 
     * @type {string}
     * @memberof EmbeddingModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EmbeddingModel
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof EmbeddingModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof EmbeddingModel
     */
    'embeddingId': string;
    /**
     * 
     * @type {string}
     * @memberof EmbeddingModel
     */
    'datasetId': string;
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof EmbeddingModel
     */
    'analysis'?: AnalysisStateNames;
}
/**
 * Settings required for enterprise on-boarding
 * @export
 * @interface EnterpriseOwnedAccount
 */
export interface EnterpriseOwnedAccount {
    /**
     * 
     * @type {any}
     * @memberof EnterpriseOwnedAccount
     */
    'accountId': any;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseOwnedAccount
     */
    'enterpriseName': string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseOwnedAccount
     */
    'enterpriseDomain': string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseOwnedAccount
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseOwnedAccount
     */
    'updateDate': string;
    /**
     * 
     * @type {AccountState}
     * @memberof EnterpriseOwnedAccount
     */
    'state'?: AccountState;
    /**
     * 
     * @type {boolean}
     * @memberof EnterpriseOwnedAccount
     */
    'autoEnrollEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseOwnedAccount
     */
    'enterpriseAvatar'?: string;
}
/**
 * Enterprise owned account info with workspace details
 * @export
 * @interface EnterpriseOwnedAccountDetailedInfo
 */
export interface EnterpriseOwnedAccountDetailedInfo {
    /**
     * 
     * @type {EnterpriseOwnedAccount}
     * @memberof EnterpriseOwnedAccountDetailedInfo
     */
    'enterpriseOwnedAccount': EnterpriseOwnedAccount;
    /**
     * 
     * @type {Array<Workspace>}
     * @memberof EnterpriseOwnedAccountDetailedInfo
     */
    'enterpriseWorkspaceDetails': Array<Workspace>;
    /**
     * 
     * @type {Array<Workspace>}
     * @memberof EnterpriseOwnedAccountDetailedInfo
     */
    'autoEnrollWorkspaceList': Array<Workspace>;
}
/**
 * List response for all the enterprise owned accounts
 * @export
 * @interface EnterpriseOwnedAccountDetailedInfoList
 */
export interface EnterpriseOwnedAccountDetailedInfoList {
    /**
     * 
     * @type {number}
     * @memberof EnterpriseOwnedAccountDetailedInfoList
     */
    'count': number;
    /**
     * 
     * @type {Array<EnterpriseOwnedAccountDetailedInfo>}
     * @memberof EnterpriseOwnedAccountDetailedInfoList
     */
    'enterpriseOwnedAccountDetails': Array<EnterpriseOwnedAccountDetailedInfo>;
}
/**
 * Paginated list of enterprise owned accounts for get enterprise owned accounts api response
 * @export
 * @interface EnterpriseOwnedAccountsList
 */
export interface EnterpriseOwnedAccountsList {
    /**
     * 
     * @type {Array<EnterpriseOwnedAccount>}
     * @memberof EnterpriseOwnedAccountsList
     */
    'accounts': Array<EnterpriseOwnedAccount>;
    /**
     * 
     * @type {number}
     * @memberof EnterpriseOwnedAccountsList
     */
    'numRecords': number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ErrorCode {
    SchemaMismatch = 'SCHEMA_MISMATCH',
    MissingInput = 'MISSING_INPUT',
    MissingParameter = 'MISSING_PARAMETER',
    InvalidInput = 'INVALID_INPUT'
}

/**
 * 
 * @export
 * @interface ErrorObject
 */
export interface ErrorObject {
    /**
     * 
     * @type {ErrorCode}
     * @memberof ErrorObject
     */
    'errorCode': ErrorCode;
    /**
     * 
     * @type {string}
     * @memberof ErrorObject
     */
    'errorText': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorObject
     */
    'fields'?: Array<string>;
}
/**
 * 
 * @export
 * @interface EvalEditableFields
 */
export interface EvalEditableFields {
    /**
     * 
     * @type {string}
     * @memberof EvalEditableFields
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EvalEditableFields
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface EvaluatedCustomMetrics
 */
export interface EvaluatedCustomMetrics {
    /**
     * 
     * @type {string}
     * @memberof EvaluatedCustomMetrics
     */
    'name': string;
    /**
     * 
     * @type {CustomMetricAllStats}
     * @memberof EvaluatedCustomMetrics
     */
    'statistics': CustomMetricAllStats;
}
/**
 * 
 * @export
 * @interface Evaluation
 */
export interface Evaluation {
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'datasetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'modelClass': string;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'notes': string;
    /**
     * 
     * @type {object}
     * @memberof Evaluation
     */
    'info': object;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'recordingId': string;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'evaluationId': string;
    /**
     * 
     * @type {TaggingMetrics}
     * @memberof Evaluation
     */
    'metrics'?: TaggingMetrics;
    /**
     * 
     * @type {RecordingState}
     * @memberof Evaluation
     */
    'state': RecordingState;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Evaluation
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'teamId'?: string;
    /**
     * 
     * @type {EvaluationStatus}
     * @memberof Evaluation
     */
    'status'?: EvaluationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof Evaluation
     */
    'demoArtifact'?: boolean;
    /**
     * 
     * @type {ArtifactStateModel}
     * @memberof Evaluation
     */
    'artifactState'?: ArtifactStateModel;
}
/**
 * 
 * @export
 * @interface EvaluationClassificationClassLevelMetrics
 */
export interface EvaluationClassificationClassLevelMetrics {
    /**
     * 
     * @type {string}
     * @memberof EvaluationClassificationClassLevelMetrics
     */
    'evaluationId': string;
    /**
     * 
     * @type {ClassificationClassMetrics}
     * @memberof EvaluationClassificationClassLevelMetrics
     */
    'metrics': ClassificationClassMetrics;
}
/**
 * 
 * @export
 * @interface EvaluationClassificationOverallMetrics
 */
export interface EvaluationClassificationOverallMetrics {
    /**
     * 
     * @type {string}
     * @memberof EvaluationClassificationOverallMetrics
     */
    'evaluationId': string;
    /**
     * 
     * @type {ClassificationOverallMetrics}
     * @memberof EvaluationClassificationOverallMetrics
     */
    'metrics': ClassificationOverallMetrics;
}
/**
 * 
 * @export
 * @interface EvaluationComparisonData
 */
export interface EvaluationComparisonData {
    /**
     * 
     * @type {Array<string>}
     * @memberof EvaluationComparisonData
     */
    'datasetIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EvaluationComparisonData
     */
    'modelClass': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum EvaluationComparisonResultType {
    Ok = 'OK',
    NotEnoughEvaluations = 'NOT_ENOUGH_EVALUATIONS',
    DifferentDataset = 'DIFFERENT_DATASET',
    DifferentLabelClasses = 'DIFFERENT_LABEL_CLASSES',
    DifferentModelClass = 'DIFFERENT_MODEL_CLASS',
    InvalidDataset = 'INVALID_DATASET'
}

/**
 * 
 * @export
 * @interface EvaluationConfusionMatrixChartData
 */
export interface EvaluationConfusionMatrixChartData {
    /**
     * 
     * @type {string}
     * @memberof EvaluationConfusionMatrixChartData
     */
    'evaluationId': string;
    /**
     * 
     * @type {ConfusionMatrixChartData}
     * @memberof EvaluationConfusionMatrixChartData
     */
    'confusionMatrixData': ConfusionMatrixChartData;
}
/**
 * 
 * @export
 * @interface EvaluationDetails
 */
export interface EvaluationDetails {
    /**
     * 
     * @type {string}
     * @memberof EvaluationDetails
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDetails
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDetails
     */
    'datasetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDetails
     */
    'modelClass': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDetails
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDetails
     */
    'notes': string;
    /**
     * 
     * @type {object}
     * @memberof EvaluationDetails
     */
    'info': object;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDetails
     */
    'createDate': string;
    /**
     * 
     * @type {number}
     * @memberof EvaluationDetails
     */
    'recordsCount': number;
}
/**
 * 
 * @export
 * @interface EvaluationDisagreementsPaginatedResponse
 */
export interface EvaluationDisagreementsPaginatedResponse {
    /**
     * 
     * @type {Array<EvaluationsDisagreementData>}
     * @memberof EvaluationDisagreementsPaginatedResponse
     */
    'response': Array<EvaluationsDisagreementData>;
    /**
     * 
     * @type {number}
     * @memberof EvaluationDisagreementsPaginatedResponse
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof EvaluationDisagreementsPaginatedResponse
     */
    'end'?: number;
    /**
     * 
     * @type {number}
     * @memberof EvaluationDisagreementsPaginatedResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDisagreementsPaginatedResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface EvaluationFullDetails
 */
export interface EvaluationFullDetails {
    /**
     * 
     * @type {EvaluationMetadata}
     * @memberof EvaluationFullDetails
     */
    'evaluation': EvaluationMetadata;
    /**
     * 
     * @type {ModelNameInfo}
     * @memberof EvaluationFullDetails
     */
    'model': ModelNameInfo;
    /**
     * 
     * @type {DatasetNameInfo}
     * @memberof EvaluationFullDetails
     */
    'dataset': DatasetNameInfo;
}
/**
 * 
 * @export
 * @interface EvaluationMetadata
 */
export interface EvaluationMetadata {
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadata
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadata
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadata
     */
    'datasetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadata
     */
    'modelClass': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadata
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadata
     */
    'notes': string;
    /**
     * 
     * @type {object}
     * @memberof EvaluationMetadata
     */
    'info': object;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadata
     */
    'createDate': string;
    /**
     * 
     * @type {number}
     * @memberof EvaluationMetadata
     */
    'recordsCount': number;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadata
     */
    'recordingId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadata
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationMetadata
     */
    'demoArtifact'?: boolean;
}
/**
 * 
 * @export
 * @interface EvaluationMetadataWithTags
 */
export interface EvaluationMetadataWithTags {
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadataWithTags
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadataWithTags
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadataWithTags
     */
    'datasetId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadataWithTags
     */
    'modelClass': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadataWithTags
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadataWithTags
     */
    'notes': string;
    /**
     * 
     * @type {object}
     * @memberof EvaluationMetadataWithTags
     */
    'info': object;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadataWithTags
     */
    'createDate': string;
    /**
     * 
     * @type {number}
     * @memberof EvaluationMetadataWithTags
     */
    'recordsCount': number;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadataWithTags
     */
    'recordingId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationMetadataWithTags
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationMetadataWithTags
     */
    'demoArtifact': boolean;
    /**
     * 
     * @type {Array<TagMetadata>}
     * @memberof EvaluationMetadataWithTags
     */
    'tags': Array<TagMetadata>;
}
/**
 * 
 * @export
 * @interface EvaluationMetrics
 */
export interface EvaluationMetrics {
    /**
     * 
     * @type {number}
     * @memberof EvaluationMetrics
     */
    'precision': number;
    /**
     * 
     * @type {number}
     * @memberof EvaluationMetrics
     */
    'recall': number;
    /**
     * 
     * @type {number}
     * @memberof EvaluationMetrics
     */
    'f1Score': number;
    /**
     * 
     * @type {number}
     * @memberof EvaluationMetrics
     */
    'support': number;
    /**
     * 
     * @type {number}
     * @memberof EvaluationMetrics
     */
    'accuracy': number;
}
/**
 * 
 * @export
 * @interface EvaluationModel
 */
export interface EvaluationModel {
    /**
     * 
     * @type {string}
     * @memberof EvaluationModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationModel
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationModel
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationModel
     */
    'modelClass': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationModel
     */
    'datasetId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationModel
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationModel
     */
    'evaluationId': string;
    /**
     * 
     * @type {Metrics}
     * @memberof EvaluationModel
     */
    'metrics'?: Metrics;
    /**
     * 
     * @type {EvaluationStatus}
     * @memberof EvaluationModel
     */
    'status'?: EvaluationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationModel
     */
    'demoArtifact'?: boolean;
}
/**
 * 
 * @export
 * @interface EvaluationModelWithTags
 */
export interface EvaluationModelWithTags {
    /**
     * 
     * @type {any}
     * @memberof EvaluationModelWithTags
     */
    'name': any;
    /**
     * 
     * @type {string}
     * @memberof EvaluationModelWithTags
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationModelWithTags
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationModelWithTags
     */
    'modelClass': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationModelWithTags
     */
    'datasetId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationModelWithTags
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationModelWithTags
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationModelWithTags
     */
    'evaluationId': string;
    /**
     * 
     * @type {EvaluationMetrics}
     * @memberof EvaluationModelWithTags
     */
    'metrics': EvaluationMetrics;
    /**
     * 
     * @type {EvaluationStatus}
     * @memberof EvaluationModelWithTags
     */
    'status': EvaluationStatus;
    /**
     * 
     * @type {Array<TagMetadata>}
     * @memberof EvaluationModelWithTags
     */
    'tags': Array<TagMetadata>;
}
/**
 * 
 * @export
 * @interface EvaluationPredictions
 */
export interface EvaluationPredictions {
    /**
     * 
     * @type {string}
     * @memberof EvaluationPredictions
     */
    'evaluationId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationPredictions
     */
    'inferred': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationPredictions
     */
    'score': string;
    /**
     * 
     * @type {Array<RecordMetaData>}
     * @memberof EvaluationPredictions
     */
    'metadata'?: Array<RecordMetaData>;
}
/**
 * Evaluation Recording details object for Activity/ Search Results
 * @export
 * @interface EvaluationRecordingInfo
 */
export interface EvaluationRecordingInfo {
    /**
     * 
     * @type {any}
     * @memberof EvaluationRecordingInfo
     */
    'evaluationId': any;
    /**
     * 
     * @type {string}
     * @memberof EvaluationRecordingInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationRecordingInfo
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationRecordingInfo
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationRecordingInfo
     */
    'modelClass': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationRecordingInfo
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationRecordingInfo
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationRecordingInfo
     */
    'datasetId'?: string;
}
/**
 * 
 * @export
 * @interface EvaluationRocCurveChartResponse
 */
export interface EvaluationRocCurveChartResponse {
    /**
     * 
     * @type {EvaluationsRocCurveChartModel}
     * @memberof EvaluationRocCurveChartResponse
     */
    'response': EvaluationsRocCurveChartModel;
    /**
     * 
     * @type {string}
     * @memberof EvaluationRocCurveChartResponse
     */
    'workspaceId': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum EvaluationStatus {
    Running = 'RUNNING',
    Success = 'SUCCESS',
    Pending = 'PENDING',
    Failure = 'FAILURE',
    TaskNotRun = 'TASK_NOT_RUN'
}

/**
 * 
 * @export
 * @interface EvaluationWithDisagreementCount
 */
export interface EvaluationWithDisagreementCount {
    /**
     * 
     * @type {string}
     * @memberof EvaluationWithDisagreementCount
     */
    'evaluationId': string;
    /**
     * 
     * @type {number}
     * @memberof EvaluationWithDisagreementCount
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface EvaluationWithTags
 */
export interface EvaluationWithTags {
    /**
     * 
     * @type {string}
     * @memberof EvaluationWithTags
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationWithTags
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationWithTags
     */
    'datasetId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationWithTags
     */
    'modelClass': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationWithTags
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationWithTags
     */
    'notes': string;
    /**
     * 
     * @type {object}
     * @memberof EvaluationWithTags
     */
    'info': object;
    /**
     * 
     * @type {string}
     * @memberof EvaluationWithTags
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationWithTags
     */
    'recordingId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationWithTags
     */
    'evaluationId': string;
    /**
     * 
     * @type {EvaluationMetrics}
     * @memberof EvaluationWithTags
     */
    'metrics': EvaluationMetrics;
    /**
     * 
     * @type {DatasetState}
     * @memberof EvaluationWithTags
     */
    'state': DatasetState;
    /**
     * 
     * @type {string}
     * @memberof EvaluationWithTags
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationWithTags
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof EvaluationWithTags
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationWithTags
     */
    'teamId': string;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationWithTags
     */
    'demoArtifact': boolean;
    /**
     * 
     * @type {Array<TagMetadata>}
     * @memberof EvaluationWithTags
     */
    'tags': Array<TagMetadata>;
}
/**
 * 
 * @export
 * @interface EvaluationsClassLevelMetricsResponse
 */
export interface EvaluationsClassLevelMetricsResponse {
    /**
     * 
     * @type {MultipleEvaluationsClassificationMetricsAllClasses}
     * @memberof EvaluationsClassLevelMetricsResponse
     */
    'response': MultipleEvaluationsClassificationMetricsAllClasses;
    /**
     * 
     * @type {string}
     * @memberof EvaluationsClassLevelMetricsResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface EvaluationsConfusionMatrixResponse
 */
export interface EvaluationsConfusionMatrixResponse {
    /**
     * 
     * @type {Array<EvaluationConfusionMatrixChartData>}
     * @memberof EvaluationsConfusionMatrixResponse
     */
    'response': Array<EvaluationConfusionMatrixChartData>;
    /**
     * 
     * @type {string}
     * @memberof EvaluationsConfusionMatrixResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface EvaluationsDisagreementData
 */
export interface EvaluationsDisagreementData {
    /**
     * 
     * @type {string}
     * @memberof EvaluationsDisagreementData
     */
    'groundTruth': string;
    /**
     * 
     * @type {Array<EvaluationPredictions>}
     * @memberof EvaluationsDisagreementData
     */
    'predictions': Array<EvaluationPredictions>;
    /**
     * 
     * @type {string}
     * @memberof EvaluationsDisagreementData
     */
    'urid': string;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationsDisagreementData
     */
    'areAllDisagreements'?: boolean;
}
/**
 * 
 * @export
 * @interface EvaluationsDisagreementSummaryData
 */
export interface EvaluationsDisagreementSummaryData {
    /**
     * 
     * @type {any}
     * @memberof EvaluationsDisagreementSummaryData
     */
    'className'?: any;
    /**
     * 
     * @type {Array<EvaluationWithDisagreementCount>}
     * @memberof EvaluationsDisagreementSummaryData
     */
    'summary': Array<EvaluationWithDisagreementCount>;
    /**
     * 
     * @type {number}
     * @memberof EvaluationsDisagreementSummaryData
     */
    'totalDisagreements': number;
}
/**
 * 
 * @export
 * @interface EvaluationsDisagreementSummaryDataResponse
 */
export interface EvaluationsDisagreementSummaryDataResponse {
    /**
     * 
     * @type {Array<EvaluationsDisagreementSummaryData>}
     * @memberof EvaluationsDisagreementSummaryDataResponse
     */
    'response': Array<EvaluationsDisagreementSummaryData>;
    /**
     * 
     * @type {string}
     * @memberof EvaluationsDisagreementSummaryDataResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface EvaluationsDistributionsChartResponse
 */
export interface EvaluationsDistributionsChartResponse {
    /**
     * 
     * @type {ChartData}
     * @memberof EvaluationsDistributionsChartResponse
     */
    'response': ChartData;
    /**
     * 
     * @type {string}
     * @memberof EvaluationsDistributionsChartResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface EvaluationsOverallMetricsResponse
 */
export interface EvaluationsOverallMetricsResponse {
    /**
     * 
     * @type {Array<EvaluationClassificationOverallMetrics>}
     * @memberof EvaluationsOverallMetricsResponse
     */
    'response': Array<EvaluationClassificationOverallMetrics>;
    /**
     * 
     * @type {string}
     * @memberof EvaluationsOverallMetricsResponse
     */
    'workspaceId': string;
}
/**
 * Chart Metadata for multiple PR Curves plotted on the same chart. This metadata supports multiple \"comparable\" evaluations. The metadata for each \"comparable\" evaluation will have the same x_label, y_label etc. except best_threshold and best_f1_score. So we store best_threshold and best_f1_score for each evaluation as a list.
 * @export
 * @interface EvaluationsPRCurveChartMetadata
 */
export interface EvaluationsPRCurveChartMetadata {
    /**
     * 
     * @type {string}
     * @memberof EvaluationsPRCurveChartMetadata
     */
    'xLabel': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationsPRCurveChartMetadata
     */
    'yLabel': string;
    /**
     * 
     * @type {ChartTypes}
     * @memberof EvaluationsPRCurveChartMetadata
     */
    'preferredChartType': ChartTypes;
    /**
     * 
     * @type {string}
     * @memberof EvaluationsPRCurveChartMetadata
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof EvaluationsPRCurveChartMetadata
     */
    'bestThresholds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EvaluationsPRCurveChartMetadata
     */
    'bestF1Scores'?: Array<number>;
}
/**
 * 
 * @export
 * @interface EvaluationsPRCurveChartModel
 */
export interface EvaluationsPRCurveChartModel {
    /**
     * 
     * @type {EvaluationsPRCurveChartMetadata}
     * @memberof EvaluationsPRCurveChartModel
     */
    'metadata': EvaluationsPRCurveChartMetadata;
    /**
     * 
     * @type {GenericChartDataModel}
     * @memberof EvaluationsPRCurveChartModel
     */
    'data': GenericChartDataModel;
}
/**
 * 
 * @export
 * @interface EvaluationsPRCurveChartResponse
 */
export interface EvaluationsPRCurveChartResponse {
    /**
     * 
     * @type {EvaluationsPRCurveChartModel}
     * @memberof EvaluationsPRCurveChartResponse
     */
    'response': EvaluationsPRCurveChartModel;
    /**
     * 
     * @type {string}
     * @memberof EvaluationsPRCurveChartResponse
     */
    'workspaceId': string;
}
/**
 * Chart Metadata for multiple ROC curves plotted on the same chart. This metadata supports multiple \"comparable\" evaluations. The metadata for each \"comparable\" evaluation will have the same x_label, y_label etc. except auc_score. So, we store auc_score for each evaluation as a list.
 * @export
 * @interface EvaluationsRocCurveChartMetadata
 */
export interface EvaluationsRocCurveChartMetadata {
    /**
     * 
     * @type {string}
     * @memberof EvaluationsRocCurveChartMetadata
     */
    'xLabel': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationsRocCurveChartMetadata
     */
    'yLabel': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationsRocCurveChartMetadata
     */
    'aucLabel': string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationsRocCurveChartMetadata
     */
    'posLabel': string;
    /**
     * 
     * @type {ChartTypes}
     * @memberof EvaluationsRocCurveChartMetadata
     */
    'preferredChartType': ChartTypes;
    /**
     * 
     * @type {string}
     * @memberof EvaluationsRocCurveChartMetadata
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof EvaluationsRocCurveChartMetadata
     */
    'aucScores'?: Array<number>;
}
/**
 * 
 * @export
 * @interface EvaluationsRocCurveChartModel
 */
export interface EvaluationsRocCurveChartModel {
    /**
     * 
     * @type {EvaluationsRocCurveChartMetadata}
     * @memberof EvaluationsRocCurveChartModel
     */
    'metadata': EvaluationsRocCurveChartMetadata;
    /**
     * 
     * @type {GenericChartDataModel}
     * @memberof EvaluationsRocCurveChartModel
     */
    'data': GenericChartDataModel;
}
/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'eventType': string;
    /**
     * 
     * @type {object}
     * @memberof Event
     */
    'eventMetadata': object;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ExecuteAppBuilderRequest
 */
export interface ExecuteAppBuilderRequest {
    /**
     * 
     * @type {string}
     * @memberof ExecuteAppBuilderRequest
     */
    'question': string;
}
/**
 * 
 * @export
 * @interface ExecuteAppBuilderResponse
 */
export interface ExecuteAppBuilderResponse {
    /**
     * 
     * @type {string}
     * @memberof ExecuteAppBuilderResponse
     */
    'executionId': string;
    /**
     * 
     * @type {string}
     * @memberof ExecuteAppBuilderResponse
     */
    'modelResponse': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ExecutedFromSourceType {
    Builder = 'workflow-builder',
    App = 'workflow-app'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ExecutionType {
    OneTime = 'ONE_TIME',
    Scheduled = 'SCHEDULED',
    TriggerOnEmail = 'TRIGGER_ON_EMAIL'
}

/**
 * 
 * @export
 * @interface ExpEditableFields
 */
export interface ExpEditableFields {
    /**
     * 
     * @type {string}
     * @memberof ExpEditableFields
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpEditableFields
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface Experiment
 */
export interface Experiment {
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    'experimentId': string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    'recordId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    'name'?: string;
    /**
     * 
     * @type {RunStatus}
     * @memberof Experiment
     */
    'runStatus'?: RunStatus;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    'datasetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    'normName': string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    'teamId'?: string;
    /**
     * 
     * @type {object}
     * @memberof Experiment
     */
    'config'?: object;
    /**
     * 
     * @type {object}
     * @memberof Experiment
     */
    'hyperParameters'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof Experiment
     */
    'demoArtifact'?: boolean;
    /**
     * 
     * @type {ArtifactStateModel}
     * @memberof Experiment
     */
    'artifactState'?: ArtifactStateModel;
}
/**
 * 
 * @export
 * @interface ExperimentChartKey
 */
export interface ExperimentChartKey {
    /**
     * 
     * @type {string}
     * @memberof ExperimentChartKey
     */
    'key': string;
    /**
     * 
     * @type {ExperimentChartKeyType}
     * @memberof ExperimentChartKey
     */
    'keyType': ExperimentChartKeyType;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ExperimentChartKeyType {
    UserMetric = 'USER_METRIC',
    SystemCpu = 'SYSTEM_CPU',
    SystemGpu = 'SYSTEM_GPU',
    SystemMemory = 'SYSTEM_MEMORY'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ExperimentComparisonResultType {
    Ok = 'OK',
    RunningExperiment = 'RUNNING_EXPERIMENT',
    NotEnoughExperiments = 'NOT_ENOUGH_EXPERIMENTS'
}

/**
 * 
 * @export
 * @interface ExperimentFullDetails
 */
export interface ExperimentFullDetails {
    /**
     * 
     * @type {Experiment}
     * @memberof ExperimentFullDetails
     */
    'experiment': Experiment;
    /**
     * 
     * @type {ModelNameInfo}
     * @memberof ExperimentFullDetails
     */
    'model': ModelNameInfo;
    /**
     * 
     * @type {DatasetNameInfo}
     * @memberof ExperimentFullDetails
     */
    'dataset'?: DatasetNameInfo;
}
/**
 * 
 * @export
 * @interface ExperimentMetricKeys
 */
export interface ExperimentMetricKeys {
    /**
     * 
     * @type {Array<string>}
     * @memberof ExperimentMetricKeys
     */
    'metrics': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExperimentMetricKeys
     */
    'recordId': string;
}
/**
 * 
 * @export
 * @interface ExperimentMetricResponse
 */
export interface ExperimentMetricResponse {
    /**
     * 
     * @type {string}
     * @memberof ExperimentMetricResponse
     */
    'runId': string;
    /**
     * 
     * @type {RunStatus}
     * @memberof ExperimentMetricResponse
     */
    'runStatus'?: RunStatus;
    /**
     * 
     * @type {ChartingResponse}
     * @memberof ExperimentMetricResponse
     */
    'chartingResponse': ChartingResponse;
}
/**
 * 
 * @export
 * @interface ExperimentPackageRequirement
 */
export interface ExperimentPackageRequirement {
    /**
     * 
     * @type {string}
     * @memberof ExperimentPackageRequirement
     */
    'packageName': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentPackageRequirement
     */
    'packageVersion': string;
}
/**
 * 
 * @export
 * @interface ExperimentRecording
 */
export interface ExperimentRecording {
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecording
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecording
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecording
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecording
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecording
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecording
     */
    'recordId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecording
     */
    'experimentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecording
     */
    'name'?: string;
    /**
     * 
     * @type {RunStatus}
     * @memberof ExperimentRecording
     */
    'runStatus'?: RunStatus;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecording
     */
    'datasetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecording
     */
    'normName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecording
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecording
     */
    'teamId'?: string;
    /**
     * 
     * @type {object}
     * @memberof ExperimentRecording
     */
    'config'?: object;
    /**
     * 
     * @type {object}
     * @memberof ExperimentRecording
     */
    'hyperParameters'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof ExperimentRecording
     */
    'demoArtifact'?: boolean;
    /**
     * 
     * @type {ArtifactStateModel}
     * @memberof ExperimentRecording
     */
    'artifactState'?: ArtifactStateModel;
}
/**
 * Experiment Recording details object for Activity/ Search Results
 * @export
 * @interface ExperimentRecordingInfo
 */
export interface ExperimentRecordingInfo {
    /**
     * 
     * @type {any}
     * @memberof ExperimentRecordingInfo
     */
    'experimentId': any;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecordingInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecordingInfo
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecordingInfo
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecordingInfo
     */
    'datasetId': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecordingInfo
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecordingInfo
     */
    'createDate': string;
}
/**
 * Paginated list of experiment recordings for get experiment recordings api response
 * @export
 * @interface ExperimentRecordingSearchResults
 */
export interface ExperimentRecordingSearchResults {
    /**
     * 
     * @type {Array<ExperimentRecordingInfo>}
     * @memberof ExperimentRecordingSearchResults
     */
    'results'?: Array<ExperimentRecordingInfo>;
    /**
     * 
     * @type {number}
     * @memberof ExperimentRecordingSearchResults
     */
    'numRecords'?: number;
}
/**
 * 
 * @export
 * @interface ExperimentRecordingsMetaData
 */
export interface ExperimentRecordingsMetaData {
    /**
     * 
     * @type {Array<ExperimentRecording>}
     * @memberof ExperimentRecordingsMetaData
     */
    'response': Array<ExperimentRecording>;
    /**
     * 
     * @type {number}
     * @memberof ExperimentRecordingsMetaData
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof ExperimentRecordingsMetaData
     */
    'pageCount': number;
    /**
     * 
     * @type {string}
     * @memberof ExperimentRecordingsMetaData
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ExperimentSummary
 */
export interface ExperimentSummary {
    /**
     * 
     * @type {string}
     * @memberof ExperimentSummary
     */
    'trainingLoss'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentSummary
     */
    'validationLoss'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentSummary
     */
    'trainingAccuracy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentSummary
     */
    'validationAccuracy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentSummary
     */
    'numberOfEpochs'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ExperimentSummary
     */
    'customFields'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ExperimentSummaryResponse
 */
export interface ExperimentSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof ExperimentSummaryResponse
     */
    'experimentId': string;
    /**
     * 
     * @type {ExperimentSummary}
     * @memberof ExperimentSummaryResponse
     */
    'summary'?: ExperimentSummary;
}
/**
 * 
 * @export
 * @interface ExperimentWithTags
 */
export interface ExperimentWithTags {
    /**
     * 
     * @type {string}
     * @memberof ExperimentWithTags
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentWithTags
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentWithTags
     */
    'experimentId': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentWithTags
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentWithTags
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentWithTags
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentWithTags
     */
    'recordId': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentWithTags
     */
    'name': string;
    /**
     * 
     * @type {RunStatus}
     * @memberof ExperimentWithTags
     */
    'runStatus': RunStatus;
    /**
     * 
     * @type {string}
     * @memberof ExperimentWithTags
     */
    'datasetId': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentWithTags
     */
    'normName': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentWithTags
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentWithTags
     */
    'teamId': string;
    /**
     * 
     * @type {object}
     * @memberof ExperimentWithTags
     */
    'config': object;
    /**
     * 
     * @type {object}
     * @memberof ExperimentWithTags
     */
    'hyperParameters': object;
    /**
     * 
     * @type {boolean}
     * @memberof ExperimentWithTags
     */
    'demoArtifact': boolean;
    /**
     * 
     * @type {Array<TagMetadata>}
     * @memberof ExperimentWithTags
     */
    'tags': Array<TagMetadata>;
}
/**
 * 
 * @export
 * @interface ExperimentsChartKeysResponse
 */
export interface ExperimentsChartKeysResponse {
    /**
     * 
     * @type {Array<ExperimentChartKey>}
     * @memberof ExperimentsChartKeysResponse
     */
    'response': Array<ExperimentChartKey>;
    /**
     * 
     * @type {string}
     * @memberof ExperimentsChartKeysResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ExperimentsKeyMetricChartResponse
 */
export interface ExperimentsKeyMetricChartResponse {
    /**
     * 
     * @type {ChartMetadata}
     * @memberof ExperimentsKeyMetricChartResponse
     */
    'metadata': ChartMetadata;
    /**
     * 
     * @type {GenericChartDataModel}
     * @memberof ExperimentsKeyMetricChartResponse
     */
    'data': GenericChartDataModel;
}
/**
 * 
 * @export
 * @interface ExperimentsKeyMetricResponse
 */
export interface ExperimentsKeyMetricResponse {
    /**
     * 
     * @type {ExperimentsKeyMetricChartResponse}
     * @memberof ExperimentsKeyMetricResponse
     */
    'chartResponse': ExperimentsKeyMetricChartResponse;
    /**
     * 
     * @type {string}
     * @memberof ExperimentsKeyMetricResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ExportTemplateRequest
 */
export interface ExportTemplateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ExportTemplateRequest
     */
    'workflowIds': Array<string>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ExpressionType {
    Contains = 'CONTAINS',
    Lte = 'LTE',
    Gte = 'GTE',
    Regex = 'REGEX',
    Equal = 'EQUAL'
}

/**
 * 
 * @export
 * @interface FetchGenAIPaginatedProjectsResponse
 */
export interface FetchGenAIPaginatedProjectsResponse {
    /**
     * 
     * @type {Array<GenAIProjectMetadataResponse>}
     * @memberof FetchGenAIPaginatedProjectsResponse
     */
    'response': Array<GenAIProjectMetadataResponse>;
    /**
     * 
     * @type {number}
     * @memberof FetchGenAIPaginatedProjectsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof FetchGenAIPaginatedProjectsResponse
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface FetchGenAIProjectsResponse
 */
export interface FetchGenAIProjectsResponse {
    /**
     * 
     * @type {Array<GenAIProjectMetadataResponse>}
     * @memberof FetchGenAIProjectsResponse
     */
    'projects': Array<GenAIProjectMetadataResponse>;
}
/**
 * 
 * @export
 * @interface FetchMilestoneResponse
 */
export interface FetchMilestoneResponse {
    /**
     * 
     * @type {string}
     * @memberof FetchMilestoneResponse
     */
    'documentId': string;
    /**
     * 
     * @type {number}
     * @memberof FetchMilestoneResponse
     */
    'milestoneId': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof FetchMilestoneResponse
     */
    'pageMetadata': Array<object>;
}
/**
 * 
 * @export
 * @interface FetchNumberOfMilestonesForDocResponse
 */
export interface FetchNumberOfMilestonesForDocResponse {
    /**
     * 
     * @type {string}
     * @memberof FetchNumberOfMilestonesForDocResponse
     */
    'documentId': string;
    /**
     * 
     * @type {number}
     * @memberof FetchNumberOfMilestonesForDocResponse
     */
    'numAvailableMilestones': number;
}
/**
 * 
 * @export
 * @interface FetchNumberOfVersionsForPageResponse
 */
export interface FetchNumberOfVersionsForPageResponse {
    /**
     * 
     * @type {string}
     * @memberof FetchNumberOfVersionsForPageResponse
     */
    'documentId': string;
    /**
     * 
     * @type {number}
     * @memberof FetchNumberOfVersionsForPageResponse
     */
    'pageId': number;
    /**
     * 
     * @type {number}
     * @memberof FetchNumberOfVersionsForPageResponse
     */
    'numAvailableVersions': number;
}
/**
 * 
 * @export
 * @interface FetchPageSuggestionsResponse
 */
export interface FetchPageSuggestionsResponse {
    /**
     * 
     * @type {Array<ParagraphTextSuggestions>}
     * @memberof FetchPageSuggestionsResponse
     */
    'suggestions': Array<ParagraphTextSuggestions>;
    /**
     * 
     * @type {SuggestionAnalysisStatus}
     * @memberof FetchPageSuggestionsResponse
     */
    'status'?: SuggestionAnalysisStatus;
}
/**
 * 
 * @export
 * @interface FetchPageVersionResponse
 */
export interface FetchPageVersionResponse {
    /**
     * 
     * @type {number}
     * @memberof FetchPageVersionResponse
     */
    'pageId': number;
    /**
     * 
     * @type {number}
     * @memberof FetchPageVersionResponse
     */
    'pageVersion': number;
    /**
     * 
     * @type {object}
     * @memberof FetchPageVersionResponse
     */
    'pageContent': object;
}
/**
 * 
 * @export
 * @interface FetchStyleGuideRuleSetsResponse
 */
export interface FetchStyleGuideRuleSetsResponse {
    /**
     * 
     * @type {Array<StyleGuideRuleSetResponse>}
     * @memberof FetchStyleGuideRuleSetsResponse
     */
    'ruleSetList': Array<StyleGuideRuleSetResponse>;
}
/**
 * 
 * @export
 * @interface FetchStyleGuideRulesResponse
 */
export interface FetchStyleGuideRulesResponse {
    /**
     * 
     * @type {Array<StyleGuideRuleResponse>}
     * @memberof FetchStyleGuideRulesResponse
     */
    'styleGuideRules': Array<StyleGuideRuleResponse>;
}
/**
 * 
 * @export
 * @interface FileDownloadMeta
 */
export interface FileDownloadMeta {
    /**
     * 
     * @type {string}
     * @memberof FileDownloadMeta
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof FileDownloadMeta
     */
    'format': string;
    /**
     * 
     * @type {string}
     * @memberof FileDownloadMeta
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface FileDownloadOutput
 */
export interface FileDownloadOutput {
    /**
     * 
     * @type {FileDownloadMeta}
     * @memberof FileDownloadOutput
     */
    'fileDownload': FileDownloadMeta;
}
/**
 * 
 * @export
 * @interface FileInfo
 */
export interface FileInfo {
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FileInfo
     */
    'size': number;
    /**
     * 
     * @type {number}
     * @memberof FileInfo
     */
    'uploadChunkSize': number;
}
/**
 * 
 * @export
 * @interface FileInfoModel
 */
export interface FileInfoModel {
    /**
     * 
     * @type {string}
     * @memberof FileInfoModel
     */
    'fileKey': string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoModel
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoModel
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoModel
     */
    'lastModified': string;
}
/**
 * 
 * @export
 * @interface FilePartInfo
 */
export interface FilePartInfo {
    /**
     * 
     * @type {string}
     * @memberof FilePartInfo
     */
    'etag': string;
    /**
     * 
     * @type {number}
     * @memberof FilePartInfo
     */
    'partNumber': number;
}
/**
 * 
 * @export
 * @interface FilePreview
 */
export interface FilePreview {
    /**
     * 
     * @type {Array<string>}
     * @memberof FilePreview
     */
    'data': Array<string>;
    /**
     * 
     * @type {FilePreviewMetadata}
     * @memberof FilePreview
     */
    'metadata': FilePreviewMetadata;
}
/**
 * 
 * @export
 * @interface FilePreviewMetadata
 */
export interface FilePreviewMetadata {
    /**
     * 
     * @type {string}
     * @memberof FilePreviewMetadata
     */
    'lineSeparator'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilePreviewMetadata
     */
    'previewKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilePreviewMetadata
     */
    'previewPath'?: string;
}
/**
 * Filter resources by tags
 * @export
 * @interface FilterByTagsRequest
 */
export interface FilterByTagsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterByTagsRequest
     */
    'tagIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterByTagsRequest
     */
    'resourceTypes'?: Array<string>;
}
/**
 * Constraints to apply to filter results
 * @export
 * @interface FilterConstraints
 */
export interface FilterConstraints {
    /**
     * 
     * @type {string}
     * @memberof FilterConstraints
     */
    'inferred': string;
    /**
     * 
     * @type {string}
     * @memberof FilterConstraints
     */
    'actual': string;
}
/**
 * 
 * @export
 * @interface FindCitationsResponse
 */
export interface FindCitationsResponse {
    /**
     * 
     * @type {Array<CopyEditCitationWithSummary>}
     * @memberof FindCitationsResponse
     */
    'citations': Array<CopyEditCitationWithSummary>;
}
/**
 * 
 * @export
 * @interface FolderResourceFilesResponse
 */
export interface FolderResourceFilesResponse {
    /**
     * 
     * @type {Array<FileInfoModel>}
     * @memberof FolderResourceFilesResponse
     */
    'files': Array<FileInfoModel>;
}
/**
 * Represents a follow-up question template with placeholders that map to table and column names.
 * @export
 * @interface FollowUpQuestionTemplate
 */
export interface FollowUpQuestionTemplate {
    /**
     * A question with placeholders for table and column name(s).
     * @type {string}
     * @memberof FollowUpQuestionTemplate
     */
    'questionTemplate': string;
    /**
     * A list of mappings from placeholders to table and column names.
     * @type {Array<PlaceholderMapping>}
     * @memberof FollowUpQuestionTemplate
     */
    'placeholderMappings': Array<PlaceholderMapping>;
}
/**
 * 
 * @export
 * @interface FollowUpQuestionWithTableReference
 */
export interface FollowUpQuestionWithTableReference {
    /**
     * Follow-up questions that can be created from input prompt.
     * @type {string}
     * @memberof FollowUpQuestionWithTableReference
     */
    'question': string;
    /**
     * **Exact** name of the table used to create this question
     * @type {string}
     * @memberof FollowUpQuestionWithTableReference
     */
    'referenceTable': string;
}
/**
 * 
 * @export
 * @interface FormattedMetrics
 */
export interface FormattedMetrics {
    /**
     * 
     * @type {number}
     * @memberof FormattedMetrics
     */
    'accuracy': number;
    /**
     * 
     * @type {{ [key: string]: TaggingMetrics; }}
     * @memberof FormattedMetrics
     */
    'classMetrics': { [key: string]: TaggingMetrics; };
    /**
     * 
     * @type {TaggingMetrics}
     * @memberof FormattedMetrics
     */
    'macroAvg': TaggingMetrics;
    /**
     * 
     * @type {TaggingMetrics}
     * @memberof FormattedMetrics
     */
    'weightedAvg': TaggingMetrics;
}
/**
 * This object is used to report status of an individual tasklet, {     tasklet_id: wflow_nv001 (string),     tasklet_name: Noun Verb Analysis (string),     task_id: string,     segment: train (enum),     status: IN_PROGRESS / ERROR / COMPLETED (enum),     createDate: 2023-03-27T12:40:50.494274+00:00 (string),     userId: uid001 (string),     userName: Kris Heinrich (string) }
 * @export
 * @interface FullAnalysesStatus
 */
export interface FullAnalysesStatus {
    /**
     * 
     * @type {string}
     * @memberof FullAnalysesStatus
     */
    'taskletId': string;
    /**
     * 
     * @type {string}
     * @memberof FullAnalysesStatus
     */
    'taskletName': string;
    /**
     * 
     * @type {string}
     * @memberof FullAnalysesStatus
     */
    'taskId': string;
    /**
     * 
     * @type {string}
     * @memberof FullAnalysesStatus
     */
    'segment': string;
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof FullAnalysesStatus
     */
    'status': AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof FullAnalysesStatus
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof FullAnalysesStatus
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof FullAnalysesStatus
     */
    'userName'?: string;
}
/**
 * 
 * @export
 * @interface FullAnalysisDetailsModel
 */
export interface FullAnalysisDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof FullAnalysisDetailsModel
     */
    'newTargetName': string;
    /**
     * 
     * @type {boolean}
     * @memberof FullAnalysisDetailsModel
     */
    'recommendationsAvailable': boolean;
    /**
     * 
     * @type {string}
     * @memberof FullAnalysisDetailsModel
     */
    'originalTarget': string;
    /**
     * 
     * @type {number}
     * @memberof FullAnalysisDetailsModel
     */
    'numMislabeled': number;
    /**
     * 
     * @type {string}
     * @memberof FullAnalysisDetailsModel
     */
    'derivedDatasetId'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum FullAnalysisState {
    NotStarted = 'NOT_STARTED',
    Queued = 'QUEUED',
    InProgress = 'IN_PROGRESS',
    Completed = 'COMPLETED',
    Error = 'ERROR',
    NotSupported = 'NOT_SUPPORTED',
    Stopped = 'STOPPED'
}

/**
 * 
 * @export
 * @interface GenAIAnalyticsCopyEditFilteredResponse
 */
export interface GenAIAnalyticsCopyEditFilteredResponse {
    /**
     * 
     * @type {number}
     * @memberof GenAIAnalyticsCopyEditFilteredResponse
     */
    'totalMembers'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenAIAnalyticsCopyEditFilteredResponse
     */
    'documentsImproved'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenAIAnalyticsCopyEditFilteredResponse
     */
    'writingSessions'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenAIAnalyticsCopyEditFilteredResponse
     */
    'averageWordsPerDocument'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenAIAnalyticsCopyEditFilteredResponse
     */
    'documentTopics'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof GenAIAnalyticsCopyEditFilteredResponse
     */
    'dau'?: Array<number>;
    /**
     * 
     * @type {SuggestionGeneratedMetrics}
     * @memberof GenAIAnalyticsCopyEditFilteredResponse
     */
    'suggestionGenerated': SuggestionGeneratedMetrics;
}
/**
 * 
 * @export
 * @interface GenAIAnalyticsCopyEditResponse
 */
export interface GenAIAnalyticsCopyEditResponse {
    /**
     * 
     * @type {number}
     * @memberof GenAIAnalyticsCopyEditResponse
     */
    'totalMembers': number;
    /**
     * 
     * @type {number}
     * @memberof GenAIAnalyticsCopyEditResponse
     */
    'documentsImproved': number;
    /**
     * 
     * @type {number}
     * @memberof GenAIAnalyticsCopyEditResponse
     */
    'writingSessions': number;
    /**
     * 
     * @type {number}
     * @memberof GenAIAnalyticsCopyEditResponse
     */
    'averageWordsPerDocument': number;
    /**
     * 
     * @type {number}
     * @memberof GenAIAnalyticsCopyEditResponse
     */
    'documentTopics': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof GenAIAnalyticsCopyEditResponse
     */
    'dau': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GenAIAnalyticsCopyEditResponse
     */
    'writingSessionsPerDay': Array<number>;
    /**
     * 
     * @type {SuggestionGeneratedMetrics}
     * @memberof GenAIAnalyticsCopyEditResponse
     */
    'suggestionGenerated': SuggestionGeneratedMetrics;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum GenAICopyEditEventType {
    UserInvite = 'USER_INVITE',
    DocumentsCreated = 'DOCUMENTS_CREATED',
    ProjectsCreated = 'PROJECTS_CREATED',
    WritingSessions = 'WRITING_SESSIONS',
    DauEvent = 'DAU_EVENT',
    SuggestionGenerated = 'SUGGESTION_GENERATED',
    SuggestionAccepted = 'SUGGESTION_ACCEPTED',
    SuggestionDeclined = 'SUGGESTION_DECLINED',
    PageWordCount = 'PAGE_WORD_COUNT'
}

/**
 * 
 * @export
 * @interface GenAIProjectMembership
 */
export interface GenAIProjectMembership {
    /**
     * 
     * @type {string}
     * @memberof GenAIProjectMembership
     */
    'userId': string;
    /**
     * 
     * @type {GenAIProjectRoleType}
     * @memberof GenAIProjectMembership
     */
    'role': GenAIProjectRoleType;
}
/**
 * 
 * @export
 * @interface GenAIProjectMetadataResponse
 */
export interface GenAIProjectMetadataResponse {
    /**
     * 
     * @type {string}
     * @memberof GenAIProjectMetadataResponse
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof GenAIProjectMetadataResponse
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof GenAIProjectMetadataResponse
     */
    'projectDescription': string;
    /**
     * 
     * @type {string}
     * @memberof GenAIProjectMetadataResponse
     */
    'appId': string;
    /**
     * 
     * @type {string}
     * @memberof GenAIProjectMetadataResponse
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof GenAIProjectMetadataResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<GenAIProjectMembership>}
     * @memberof GenAIProjectMetadataResponse
     */
    'invitedUsers'?: Array<GenAIProjectMembership>;
    /**
     * 
     * @type {boolean}
     * @memberof GenAIProjectMetadataResponse
     */
    'sharedWithWorkspace'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenAIProjectMetadataResponse
     */
    'countOfDocuments'?: number;
    /**
     * 
     * @type {CitationStyleGuideType}
     * @memberof GenAIProjectMetadataResponse
     */
    'defaultCitationStyleGuide'?: CitationStyleGuideType;
    /**
     * 
     * @type {Array<StyleGuideType>}
     * @memberof GenAIProjectMetadataResponse
     */
    'defaultStyleGuides'?: Array<StyleGuideType>;
    /**
     * 
     * @type {Array<IdNamePair>}
     * @memberof GenAIProjectMetadataResponse
     */
    'defaultCustomStyleRules'?: Array<IdNamePair>;
    /**
     * 
     * @type {Array<IdNamePair>}
     * @memberof GenAIProjectMetadataResponse
     */
    'defaultCustomDictionaryIds'?: Array<IdNamePair>;
}
/**
 * Membership types for GenAI projects
 * @export
 * @enum {string}
 */

export enum GenAIProjectRoleType {
    Owner = 'OWNER',
    Member = 'MEMBER'
}

/**
 * 
 * @export
 * @interface GenerateProductKeywordsResponse
 */
export interface GenerateProductKeywordsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateProductKeywordsResponse
     */
    'response': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenerateProductKeywordsResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface GeneratePromptForLLMRequest
 */
export interface GeneratePromptForLLMRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneratePromptForLLMRequest
     */
    'question': string;
}
/**
 * 
 * @export
 * @interface GeneratePromptForLLMResponse
 */
export interface GeneratePromptForLLMResponse {
    /**
     * 
     * @type {string}
     * @memberof GeneratePromptForLLMResponse
     */
    'modelResponse': string;
}
/**
 * 
 * @export
 * @interface GenerateRegexResponse
 */
export interface GenerateRegexResponse {
    /**
     * 
     * @type {string}
     * @memberof GenerateRegexResponse
     */
    'match': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateRegexResponse
     */
    'replaceBy': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenerateRegexResponse
     */
    'ruleDescription'?: string;
}
/**
 * 
 * @export
 * @interface GenerateTextResponse
 */
export interface GenerateTextResponse {
    /**
     * 
     * @type {string}
     * @memberof GenerateTextResponse
     */
    'generatedText': string;
    /**
     * 
     * @type {string}
     * @memberof GenerateTextResponse
     */
    'latency': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum GenerationMode {
    Chat = 'chat',
    Completion = 'completion'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum GenerationMode1 {
    Chat = 'chat',
    Completion = 'completion'
}

/**
 * 
 * @export
 * @interface GenericChartDataModel
 */
export interface GenericChartDataModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericChartDataModel
     */
    'xCategories'?: Array<string>;
    /**
     * 
     * @type {Array<GenericChartSeriesModel> | Array<any>}
     * @memberof GenericChartDataModel
     */
    'series'?: Array<GenericChartSeriesModel> | Array<any>;
}
/**
 * 
 * @export
 * @interface GenericChartDataModel1
 */
export interface GenericChartDataModel1 {
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericChartDataModel1
     */
    'xCategories'?: Array<string>;
    /**
     * 
     * @type {Array<GenericChartSeriesModel1>}
     * @memberof GenericChartDataModel1
     */
    'series'?: Array<GenericChartSeriesModel1>;
}
/**
 * 
 * @export
 * @interface GenericChartSeriesDataModel
 */
export interface GenericChartSeriesDataModel {
    /**
     * 
     * @type {number}
     * @memberof GenericChartSeriesDataModel
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof GenericChartSeriesDataModel
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof GenericChartSeriesDataModel
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface GenericChartSeriesModel
 */
export interface GenericChartSeriesModel {
    /**
     * 
     * @type {string}
     * @memberof GenericChartSeriesModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GenericChartSeriesModel
     */
    'type'?: string;
    /**
     * 
     * @type {Array<GenericChartSeriesDataModel>}
     * @memberof GenericChartSeriesModel
     */
    'data'?: Array<GenericChartSeriesDataModel>;
}
/**
 * 
 * @export
 * @interface GenericChartSeriesModel1
 */
export interface GenericChartSeriesModel1 {
    /**
     * 
     * @type {string}
     * @memberof GenericChartSeriesModel1
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GenericChartSeriesModel1
     */
    'type'?: string;
    /**
     * 
     * @type {Array<Array<any>>}
     * @memberof GenericChartSeriesModel1
     */
    'data'?: Array<Array<any>>;
}
/**
 * 
 * @export
 * @interface GetAllCitationsTextResponse
 */
export interface GetAllCitationsTextResponse {
    /**
     * 
     * @type {Array<CopyEditCitationText>}
     * @memberof GetAllCitationsTextResponse
     */
    'citationTexts': Array<CopyEditCitationText>;
}
/**
 * 
 * @export
 * @interface GetAllConversationsResponse
 */
export interface GetAllConversationsResponse {
    /**
     * 
     * @type {Array<UserConversationModel>}
     * @memberof GetAllConversationsResponse
     */
    'conversations': Array<UserConversationModel>;
}
/**
 * 
 * @export
 * @interface GetAllEvaluationsListWithTagsResponse
 */
export interface GetAllEvaluationsListWithTagsResponse {
    /**
     * 
     * @type {Array<EvaluationWithTags>}
     * @memberof GetAllEvaluationsListWithTagsResponse
     */
    'response': Array<EvaluationWithTags>;
    /**
     * 
     * @type {number}
     * @memberof GetAllEvaluationsListWithTagsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof GetAllEvaluationsListWithTagsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetAnalysisResponse
 */
export interface GetAnalysisResponse {
    /**
     * 
     * @type {Analysis}
     * @memberof GetAnalysisResponse
     */
    'response'?: Analysis;
    /**
     * 
     * @type {string}
     * @memberof GetAnalysisResponse
     */
    'workspaceId'?: string;
}
/**
 * Response for GetAnalysisTasksResponseV1 API. This object contains details of analysis triggered for a dataset, and their status
 * @export
 * @interface GetAnalysisTasksResponse
 */
export interface GetAnalysisTasksResponse {
    /**
     * 
     * @type {AnalysisTasks}
     * @memberof GetAnalysisTasksResponse
     */
    'response': AnalysisTasks;
    /**
     * 
     * @type {number}
     * @memberof GetAnalysisTasksResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof GetAnalysisTasksResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetAppBuilderResponse
 */
export interface GetAppBuilderResponse {
    /**
     * 
     * @type {AppBuilder}
     * @memberof GetAppBuilderResponse
     */
    'data': AppBuilder;
}
/**
 * Request for route to get connector info to connect to language models like openai, etc.
 * @export
 * @interface GetAppCredentialResponse
 */
export interface GetAppCredentialResponse {
    /**
     * 
     * @type {object}
     * @memberof GetAppCredentialResponse
     */
    'apiKeysInfo'?: object;
}
/**
 * 
 * @export
 * @interface GetAppDocumentsListResponse
 */
export interface GetAppDocumentsListResponse {
    /**
     * 
     * @type {Array<DocumentResponse>}
     * @memberof GetAppDocumentsListResponse
     */
    'documentList': Array<DocumentResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetAppDocumentsListResponse
     */
    'supportedLlmTypes': Array<string>;
    /**
     * 
     * @type {GenAIProjectMetadataResponse}
     * @memberof GetAppDocumentsListResponse
     */
    'projectMetadata'?: GenAIProjectMetadataResponse;
}
/**
 * 
 * @export
 * @interface GetAppMetadataResponse
 */
export interface GetAppMetadataResponse {
    /**
     * 
     * @type {any}
     * @memberof GetAppMetadataResponse
     */
    'data'?: any;
}
/**
 * 
 * @export
 * @interface GetAppResponse
 */
export interface GetAppResponse {
    /**
     * 
     * @type {any}
     * @memberof GetAppResponse
     */
    'data'?: any;
}
/**
 * API response for fetching list of available full data tasklets
 * @export
 * @interface GetAvailableAnalysisTaskletsResponse
 */
export interface GetAvailableAnalysisTaskletsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetAvailableAnalysisTaskletsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<DatasetTasklet>}
     * @memberof GetAvailableAnalysisTaskletsResponse
     */
    'response': Array<DatasetTasklet>;
}
/**
 * 
 * @export
 * @interface GetBaselineModelTaskletStatusResponse
 */
export interface GetBaselineModelTaskletStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof GetBaselineModelTaskletStatusResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof GetBaselineModelTaskletStatusResponse
     */
    'datasetId': string;
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof GetBaselineModelTaskletStatusResponse
     */
    'baselineModelTaskletStatus': AnalysisStateNames;
}
/**
 * 
 * @export
 * @interface GetCitationsResponse
 */
export interface GetCitationsResponse {
    /**
     * 
     * @type {Array<CopyEditCitation>}
     * @memberof GetCitationsResponse
     */
    'citations'?: Array<CopyEditCitation>;
}
/**
 * 
 * @export
 * @interface GetCommentsDetailsResponse
 */
export interface GetCommentsDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetCommentsDetailsResponse
     */
    'conversationId': string;
    /**
     * 
     * @type {Array<UserCommentModel>}
     * @memberof GetCommentsDetailsResponse
     */
    'userComments': Array<UserCommentModel>;
}
/**
 * 
 * @export
 * @interface GetCommentsInfoResponse
 */
export interface GetCommentsInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof GetCommentsInfoResponse
     */
    'snippetId': string;
    /**
     * 
     * @type {string}
     * @memberof GetCommentsInfoResponse
     */
    'conversationId': string;
    /**
     * 
     * @type {Array<UserComment>}
     * @memberof GetCommentsInfoResponse
     */
    'userComments': Array<UserComment>;
}
/**
 * Response Model for - /v1/workspace/{workspace_id}/recordings/{recording_id}/custom-metrics   Return the custom-metrics computed for a recording
 * @export
 * @interface GetComputedCustomMetricsForRecordingResponse
 */
export interface GetComputedCustomMetricsForRecordingResponse {
    /**
     * 
     * @type {ComputedCustomMetricsForRecording | object}
     * @memberof GetComputedCustomMetricsForRecordingResponse
     */
    'response': ComputedCustomMetricsForRecording | object;
    /**
     * 
     * @type {string}
     * @memberof GetComputedCustomMetricsForRecordingResponse
     */
    'workspaceId'?: string;
}
/**
 * Response Model for Return the metrics computed for a recording
 * @export
 * @interface GetComputedDistributionsForRecordingResponse
 */
export interface GetComputedDistributionsForRecordingResponse {
    /**
     * 
     * @type {ComputedDistributionsForRecording}
     * @memberof GetComputedDistributionsForRecordingResponse
     */
    'response': ComputedDistributionsForRecording;
    /**
     * 
     * @type {string}
     * @memberof GetComputedDistributionsForRecordingResponse
     */
    'workspaceId'?: string;
}
/**
 * Response Model for Return the metrics computed for a recording
 * @export
 * @interface GetComputedMetricsForRecordingResponse
 */
export interface GetComputedMetricsForRecordingResponse {
    /**
     * 
     * @type {ComputedMetricsForRecording | object}
     * @memberof GetComputedMetricsForRecordingResponse
     */
    'response': ComputedMetricsForRecording | object;
    /**
     * 
     * @type {string}
     * @memberof GetComputedMetricsForRecordingResponse
     */
    'workspaceId'?: string;
}
/**
 * Response Model for - /v1/workspace/{workspace_id}/recordings/{recording_id}/confusion-matrix   Return the confusion matrix and related metadata in the response.
 * @export
 * @interface GetConfusionMatrixResponse
 */
export interface GetConfusionMatrixResponse {
    /**
     * 
     * @type {ConfusionMatrixChartData}
     * @memberof GetConfusionMatrixResponse
     */
    'response'?: ConfusionMatrixChartData;
    /**
     * 
     * @type {string}
     * @memberof GetConfusionMatrixResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetConnectorResponse
 */
export interface GetConnectorResponse {
    /**
     * 
     * @type {ConnectorModel}
     * @memberof GetConnectorResponse
     */
    'response'?: ConnectorModel;
    /**
     * 
     * @type {APIMessage}
     * @memberof GetConnectorResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {string}
     * @memberof GetConnectorResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface GetConversationDetailsResponse
 */
export interface GetConversationDetailsResponse {
    /**
     * 
     * @type {ConversationModel}
     * @memberof GetConversationDetailsResponse
     */
    'response': ConversationModel;
}
/**
 * 
 * @export
 * @interface GetConversationModelsResponse
 */
export interface GetConversationModelsResponse {
    /**
     * 
     * @type {Array<ConversationModel>}
     * @memberof GetConversationModelsResponse
     */
    'response': Array<ConversationModel>;
    /**
     * 
     * @type {string}
     * @memberof GetConversationModelsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetConversationResponse
 */
export interface GetConversationResponse {
    /**
     * 
     * @type {string}
     * @memberof GetConversationResponse
     */
    'snippetId': string;
    /**
     * 
     * @type {Array<UserConversation>}
     * @memberof GetConversationResponse
     */
    'conversations': Array<UserConversation>;
}
/**
 * 
 * @export
 * @interface GetConversationsResponse
 */
export interface GetConversationsResponse {
    /**
     * 
     * @type {Array<Conversation>}
     * @memberof GetConversationsResponse
     */
    'response': Array<Conversation>;
    /**
     * 
     * @type {string}
     * @memberof GetConversationsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetCreditUsageForWorkflowRunResponse
 */
export interface GetCreditUsageForWorkflowRunResponse {
    /**
     * 
     * @type {string}
     * @memberof GetCreditUsageForWorkflowRunResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof GetCreditUsageForWorkflowRunResponse
     */
    'workflowId': string;
    /**
     * 
     * @type {string}
     * @memberof GetCreditUsageForWorkflowRunResponse
     */
    'workflowRunId': string;
    /**
     * 
     * @type {number}
     * @memberof GetCreditUsageForWorkflowRunResponse
     */
    'totalCreditsConsumed': number;
    /**
     * 
     * @type {Array<OperatorCreditConsumed>}
     * @memberof GetCreditUsageForWorkflowRunResponse
     */
    'operatorCreditConsumedDetails': Array<OperatorCreditConsumed>;
}
/**
 * 
 * @export
 * @interface GetCustomDictionaryListResponse
 */
export interface GetCustomDictionaryListResponse {
    /**
     * 
     * @type {Array<GetCustomDictionaryResponse>}
     * @memberof GetCustomDictionaryListResponse
     */
    'customDictionaries': Array<GetCustomDictionaryResponse>;
}
/**
 * 
 * @export
 * @interface GetCustomDictionaryPaginatedResponse
 */
export interface GetCustomDictionaryPaginatedResponse {
    /**
     * 
     * @type {Array<GetCustomDictionaryResponse>}
     * @memberof GetCustomDictionaryPaginatedResponse
     */
    'response': Array<GetCustomDictionaryResponse>;
    /**
     * 
     * @type {number}
     * @memberof GetCustomDictionaryPaginatedResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof GetCustomDictionaryPaginatedResponse
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface GetCustomDictionaryResponse
 */
export interface GetCustomDictionaryResponse {
    /**
     * 
     * @type {string}
     * @memberof GetCustomDictionaryResponse
     */
    'dictionaryId': string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomDictionaryResponse
     */
    'dictionaryName': string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomDictionaryResponse
     */
    'dictionaryDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCustomDictionaryResponse
     */
    'wordCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCustomDictionaryResponse
     */
    'createDate': string;
}
/**
 * 
 * @export
 * @interface GetCustomDictionaryWordsListResponse
 */
export interface GetCustomDictionaryWordsListResponse {
    /**
     * 
     * @type {string}
     * @memberof GetCustomDictionaryWordsListResponse
     */
    'dictionaryId': string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomDictionaryWordsListResponse
     */
    'dictionaryName': string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomDictionaryWordsListResponse
     */
    'dictionaryDescription'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCustomDictionaryWordsListResponse
     */
    'customWords': Array<string>;
}
/**
 * 
 * @export
 * @interface GetDAGResponse
 */
export interface GetDAGResponse {
    /**
     * 
     * @type {Array<Node>}
     * @memberof GetDAGResponse
     */
    'nodes': Array<Node>;
    /**
     * 
     * @type {Array<Edge>}
     * @memberof GetDAGResponse
     */
    'edges': Array<Edge>;
    /**
     * 
     * @type {string}
     * @memberof GetDAGResponse
     */
    'dagHash': string;
}
/**
 * API response for fetching status of full data analyses
 * @export
 * @interface GetDataAnalysesStatusResponse
 */
export interface GetDataAnalysesStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof GetDataAnalysesStatusResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<DataAnalysesStatus>}
     * @memberof GetDataAnalysesStatusResponse
     */
    'response': Array<DataAnalysesStatus>;
}
/**
 * Base class that serves as the foundation for all graph related responses. It includes an attribute analysis_state, which represents the state of analysis run for a dataset. The possible analysis states are defined in the AnalysisStateNames enum.
 * @export
 * @interface GetDataQualityStatusResponse
 */
export interface GetDataQualityStatusResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof GetDataQualityStatusResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof GetDataQualityStatusResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface GetDatasetDelimiterDataResponse
 */
export interface GetDatasetDelimiterDataResponse {
    /**
     * 
     * @type {DatasetDelimiterData}
     * @memberof GetDatasetDelimiterDataResponse
     */
    'response': DatasetDelimiterData;
    /**
     * 
     * @type {string}
     * @memberof GetDatasetDelimiterDataResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetDatasetDetailsResponse
 */
export interface GetDatasetDetailsResponse {
    /**
     * 
     * @type {APIMessage}
     * @memberof GetDatasetDetailsResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {DatasetWithTags}
     * @memberof GetDatasetDetailsResponse
     */
    'response'?: DatasetWithTags;
    /**
     * 
     * @type {string}
     * @memberof GetDatasetDetailsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetDatasetPreviewDataResponse
 */
export interface GetDatasetPreviewDataResponse {
    /**
     * 
     * @type {DatasetPreviewData}
     * @memberof GetDatasetPreviewDataResponse
     */
    'response': DatasetPreviewData;
    /**
     * 
     * @type {string}
     * @memberof GetDatasetPreviewDataResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetDatasetSampleResponse
 */
export interface GetDatasetSampleResponse {
    /**
     * 
     * @type {string}
     * @memberof GetDatasetSampleResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof GetDatasetSampleResponse
     */
    'sampleId': string;
    /**
     * 
     * @type {FilePreview}
     * @memberof GetDatasetSampleResponse
     */
    'data': FilePreview;
    /**
     * 
     * @type {DataViewEssentials}
     * @memberof GetDatasetSampleResponse
     */
    'parentDataview'?: DataViewEssentials;
    /**
     * 
     * @type {number}
     * @memberof GetDatasetSampleResponse
     */
    'numRows'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetDatasetSampleResponse
     */
    'samplingStrategy'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetDatasetSampleResponse
     */
    'metrics'?: object;
}
/**
 * Request for route to get deployment info of customer environment.
 * @export
 * @interface GetDeploymentResponse
 */
export interface GetDeploymentResponse {
    /**
     * 
     * @type {string}
     * @memberof GetDeploymentResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof GetDeploymentResponse
     */
    'baseUrl': string;
    /**
     * 
     * @type {string}
     * @memberof GetDeploymentResponse
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof GetDeploymentResponse
     */
    'recordId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeploymentResponse
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeploymentResponse
     */
    'deploymentId'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetDeploymentResponse
     */
    'info'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetDeploymentResponse
     */
    'secretInfo'?: object;
}
/**
 * 
 * @export
 * @interface GetDocumentDownloadStatusResponse
 */
export interface GetDocumentDownloadStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof GetDocumentDownloadStatusResponse
     */
    'taskId': string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentDownloadStatusResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentDownloadStatusResponse
     */
    'versionId': string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentDownloadStatusResponse
     */
    'createDate': string;
    /**
     * 
     * @type {DocumentDownloadStatusType}
     * @memberof GetDocumentDownloadStatusResponse
     */
    'status'?: DocumentDownloadStatusType;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentDownloadStatusResponse
     */
    'signedUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentDownloadStatusResponse
     */
    'errorMessage'?: string;
}
/**
 * Response for the get document metadata api with presigned s3 url for the document
 * @export
 * @interface GetDocumentMetadataResponse
 */
export interface GetDocumentMetadataResponse {
    /**
     * 
     * @type {string}
     * @memberof GetDocumentMetadataResponse
     */
    'documentId': string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentMetadataResponse
     */
    'documentName': string;
    /**
     * 
     * @type {number}
     * @memberof GetDocumentMetadataResponse
     */
    'pageCount': number;
    /**
     * 
     * @type {DocumentType}
     * @memberof GetDocumentMetadataResponse
     */
    'documentType': DocumentType;
    /**
     * 
     * @type {DocumentStatusType}
     * @memberof GetDocumentMetadataResponse
     */
    'status': DocumentStatusType;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentMetadataResponse
     */
    's3Uri': string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentMetadataResponse
     */
    'signedS3Url': string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentMetadataResponse
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentMetadataResponse
     */
    'uploadedBy': string;
    /**
     * 
     * @type {CitationStyleGuideType}
     * @memberof GetDocumentMetadataResponse
     */
    'citationStyle'?: CitationStyleGuideType;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentMetadataResponse
     */
    'documentContent'?: string;
    /**
     * 
     * @type {Array<StyleGuideType>}
     * @memberof GetDocumentMetadataResponse
     */
    'styleGuides'?: Array<StyleGuideType>;
    /**
     * 
     * @type {Array<IdNamePair>}
     * @memberof GetDocumentMetadataResponse
     */
    'customStyleRules'?: Array<IdNamePair>;
    /**
     * 
     * @type {Array<IdNamePair>}
     * @memberof GetDocumentMetadataResponse
     */
    'customDictionaryIds'?: Array<IdNamePair>;
    /**
     * 
     * @type {DocxParserType}
     * @memberof GetDocumentMetadataResponse
     */
    'parserType'?: DocxParserType;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentMetadataResponse
     */
    'stylesheet'?: string;
}
/**
 * 
 * @export
 * @interface GetDocumentStatsForInputTextRequest
 */
export interface GetDocumentStatsForInputTextRequest {
    /**
     * 
     * @type {string}
     * @memberof GetDocumentStatsForInputTextRequest
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface GetDocumentSummaryResponse
 */
export interface GetDocumentSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof GetDocumentSummaryResponse
     */
    'documentId': string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentSummaryResponse
     */
    'documentName': string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentSummaryResponse
     */
    'summary': string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentSummaryResponse
     */
    'binaryFormattedSummary'?: string;
}
/**
 * 
 * @export
 * @interface GetDocumentUploadUriResponse
 */
export interface GetDocumentUploadUriResponse {
    /**
     * 
     * @type {string}
     * @memberof GetDocumentUploadUriResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentUploadUriResponse
     */
    'documentUri': string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentUploadUriResponse
     */
    'signedUrl': string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentUploadUriResponse
     */
    'documentId': string;
}
/**
 * 
 * @export
 * @interface GetEvaluationMetadataWithTagsResponse
 */
export interface GetEvaluationMetadataWithTagsResponse {
    /**
     * 
     * @type {EvaluationMetadataWithTags}
     * @memberof GetEvaluationMetadataWithTagsResponse
     */
    'response'?: EvaluationMetadataWithTags;
    /**
     * 
     * @type {string}
     * @memberof GetEvaluationMetadataWithTagsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetEvaluationWithModelAndDatasetResponse
 */
export interface GetEvaluationWithModelAndDatasetResponse {
    /**
     * 
     * @type {EvaluationFullDetails}
     * @memberof GetEvaluationWithModelAndDatasetResponse
     */
    'response': EvaluationFullDetails;
    /**
     * 
     * @type {string}
     * @memberof GetEvaluationWithModelAndDatasetResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetEvaluationsDisagreementsSummaryRequest
 */
export interface GetEvaluationsDisagreementsSummaryRequest {
    /**
     * 
     * @type {Array<any>}
     * @memberof GetEvaluationsDisagreementsSummaryRequest
     */
    'classNames': Array<any>;
}
/**
 * 
 * @export
 * @interface GetEvaluationsPaginated
 */
export interface GetEvaluationsPaginated {
    /**
     * 
     * @type {Array<EvaluationModel>}
     * @memberof GetEvaluationsPaginated
     */
    'response': Array<EvaluationModel>;
    /**
     * 
     * @type {number}
     * @memberof GetEvaluationsPaginated
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof GetEvaluationsPaginated
     */
    'pageCount': number;
    /**
     * 
     * @type {string}
     * @memberof GetEvaluationsPaginated
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetExperimentDetailsResponse
 */
export interface GetExperimentDetailsResponse {
    /**
     * 
     * @type {ExperimentWithTags}
     * @memberof GetExperimentDetailsResponse
     */
    'response'?: ExperimentWithTags;
    /**
     * 
     * @type {string}
     * @memberof GetExperimentDetailsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetExperimentPackageRequirementsResponse
 */
export interface GetExperimentPackageRequirementsResponse {
    /**
     * 
     * @type {Array<ExperimentPackageRequirement>}
     * @memberof GetExperimentPackageRequirementsResponse
     */
    'response': Array<ExperimentPackageRequirement>;
    /**
     * 
     * @type {string}
     * @memberof GetExperimentPackageRequirementsResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface GetExperimentResponse
 */
export interface GetExperimentResponse {
    /**
     * 
     * @type {Experiment}
     * @memberof GetExperimentResponse
     */
    'response': Experiment;
    /**
     * 
     * @type {string}
     * @memberof GetExperimentResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface GetExperimentWithModelAndDatasetResponse
 */
export interface GetExperimentWithModelAndDatasetResponse {
    /**
     * 
     * @type {ExperimentFullDetails}
     * @memberof GetExperimentWithModelAndDatasetResponse
     */
    'response': ExperimentFullDetails;
    /**
     * 
     * @type {string}
     * @memberof GetExperimentWithModelAndDatasetResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetExperimentsPaginated
 */
export interface GetExperimentsPaginated {
    /**
     * 
     * @type {Array<Experiment>}
     * @memberof GetExperimentsPaginated
     */
    'response': Array<Experiment>;
    /**
     * 
     * @type {number}
     * @memberof GetExperimentsPaginated
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof GetExperimentsPaginated
     */
    'pageCount': number;
    /**
     * 
     * @type {string}
     * @memberof GetExperimentsPaginated
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface GetFileInferenceResultsResponse
 */
export interface GetFileInferenceResultsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetFileInferenceResultsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof GetFileInferenceResultsResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof GetFileInferenceResultsResponse
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof GetFileInferenceResultsResponse
     */
    'inferenceId': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetFileInferenceResultsResponse
     */
    'outputs': Array<any>;
    /**
     * 
     * @type {string}
     * @memberof GetFileInferenceResultsResponse
     */
    'dataDriftResultId'?: string;
}
/**
 * 
 * @export
 * @interface GetFileInferenceStatusResponse
 */
export interface GetFileInferenceStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof GetFileInferenceStatusResponse
     */
    'inferenceId': string;
    /**
     * 
     * @type {ModelInferenceStatus}
     * @memberof GetFileInferenceStatusResponse
     */
    'status': ModelInferenceStatus;
}
/**
 * API response for fetching full data analyses This considers run_status in response
 * @export
 * @interface GetFullAnalysesResponse
 */
export interface GetFullAnalysesResponse {
    /**
     * 
     * @type {string}
     * @memberof GetFullAnalysesResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<AnalysisTasklet>}
     * @memberof GetFullAnalysesResponse
     */
    'response': Array<AnalysisTasklet>;
}
/**
 * API response for fetching status of full data analyses
 * @export
 * @interface GetFullAnalysesStatusResponse
 */
export interface GetFullAnalysesStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof GetFullAnalysesStatusResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<FullAnalysesStatus>}
     * @memberof GetFullAnalysesStatusResponse
     */
    'response': Array<FullAnalysesStatus>;
}
/**
 * 
 * @export
 * @interface GetInputFileUriResponse
 */
export interface GetInputFileUriResponse {
    /**
     * 
     * @type {string}
     * @memberof GetInputFileUriResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof GetInputFileUriResponse
     */
    'fileUri': string;
    /**
     * 
     * @type {string}
     * @memberof GetInputFileUriResponse
     */
    'signedUrl': string;
}
/**
 * 
 * @export
 * @interface GetModelAppResponse
 */
export interface GetModelAppResponse {
    /**
     * 
     * @type {ModelApp}
     * @memberof GetModelAppResponse
     */
    'data': ModelApp;
    /**
     * 
     * @type {string}
     * @memberof GetModelAppResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface GetModelDetailsResponse
 */
export interface GetModelDetailsResponse {
    /**
     * 
     * @type {ModelWithTags}
     * @memberof GetModelDetailsResponse
     */
    'response'?: ModelWithTags;
    /**
     * 
     * @type {string}
     * @memberof GetModelDetailsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetModelProjectionResponse
 */
export interface GetModelProjectionResponse {
    /**
     * 
     * @type {ModelProjection}
     * @memberof GetModelProjectionResponse
     */
    'response': ModelProjection;
    /**
     * 
     * @type {string}
     * @memberof GetModelProjectionResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetModelResponse
 */
export interface GetModelResponse {
    /**
     * 
     * @type {Model}
     * @memberof GetModelResponse
     */
    'response': Model;
    /**
     * 
     * @type {string}
     * @memberof GetModelResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetModelsInRegistryPaginated
 */
export interface GetModelsInRegistryPaginated {
    /**
     * 
     * @type {string}
     * @memberof GetModelsInRegistryPaginated
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<RegistryModelMapping>}
     * @memberof GetModelsInRegistryPaginated
     */
    'response': Array<RegistryModelMapping>;
    /**
     * 
     * @type {number}
     * @memberof GetModelsInRegistryPaginated
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof GetModelsInRegistryPaginated
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface GetModelsPaginated
 */
export interface GetModelsPaginated {
    /**
     * 
     * @type {Array<Model>}
     * @memberof GetModelsPaginated
     */
    'response': Array<Model>;
    /**
     * 
     * @type {number}
     * @memberof GetModelsPaginated
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof GetModelsPaginated
     */
    'pageCount': number;
    /**
     * 
     * @type {string}
     * @memberof GetModelsPaginated
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetModelsWithAppMetadataPaginated
 */
export interface GetModelsWithAppMetadataPaginated {
    /**
     * 
     * @type {Array<ModelWithAppMetadata>}
     * @memberof GetModelsWithAppMetadataPaginated
     */
    'response': Array<ModelWithAppMetadata>;
    /**
     * 
     * @type {number}
     * @memberof GetModelsWithAppMetadataPaginated
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof GetModelsWithAppMetadataPaginated
     */
    'pageCount': number;
    /**
     * 
     * @type {string}
     * @memberof GetModelsWithAppMetadataPaginated
     */
    'workspaceId'?: string;
}
/**
 * This object contains details of new analyzers available for a dataset
 * @export
 * @interface GetNewTasksResponse
 */
export interface GetNewTasksResponse {
    /**
     * 
     * @type {Array<Task>}
     * @memberof GetNewTasksResponse
     */
    'response': Array<Task>;
    /**
     * 
     * @type {number}
     * @memberof GetNewTasksResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof GetNewTasksResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetPageSummaryResponse
 */
export interface GetPageSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPageSummaryResponse
     */
    'documentId': string;
    /**
     * 
     * @type {number}
     * @memberof GetPageSummaryResponse
     */
    'pageId': number;
    /**
     * 
     * @type {string}
     * @memberof GetPageSummaryResponse
     */
    'pageSummary': string;
}
/**
 * 
 * @export
 * @interface GetPaginatedAppDocumentsListResponse
 */
export interface GetPaginatedAppDocumentsListResponse {
    /**
     * 
     * @type {Array<DocumentResponse>}
     * @memberof GetPaginatedAppDocumentsListResponse
     */
    'response': Array<DocumentResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPaginatedAppDocumentsListResponse
     */
    'supportedLlmTypes': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedAppDocumentsListResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedAppDocumentsListResponse
     */
    'pageCount': number;
    /**
     * 
     * @type {GenAIProjectMetadataResponse}
     * @memberof GetPaginatedAppDocumentsListResponse
     */
    'projectMetadata'?: GenAIProjectMetadataResponse;
}
/**
 * Response Model for - /v1/workspace/{workspace_id}/recordings/{recording_id}/disagreement  Return the disagreements between actual and the inferred in this recording.
 * @export
 * @interface GetPaginatedDisagreementsResponse
 */
export interface GetPaginatedDisagreementsResponse {
    /**
     * 
     * @type {PaginatedDisagreements | Array<any>}
     * @memberof GetPaginatedDisagreementsResponse
     */
    'response': PaginatedDisagreements | Array<any>;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedDisagreementsResponse
     */
    'workspaceId'?: string;
}
/**
 * Response Model for - /v1/workspace/{workspace_id}/recordings/{recording_id}  Get the records for the given recording id.
 * @export
 * @interface GetPaginatedRecordsResponse
 */
export interface GetPaginatedRecordsResponse {
    /**
     * 
     * @type {PaginatedRecords | Array<any>}
     * @memberof GetPaginatedRecordsResponse
     */
    'response': PaginatedRecords | Array<any>;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedRecordsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetParsedPageResponse
 */
export interface GetParsedPageResponse {
    /**
     * 
     * @type {string}
     * @memberof GetParsedPageResponse
     */
    'documentId': string;
    /**
     * 
     * @type {number}
     * @memberof GetParsedPageResponse
     */
    'pageId': number;
    /**
     * 
     * @type {string}
     * @memberof GetParsedPageResponse
     */
    'formattedPageContent': string;
    /**
     * 
     * @type {Array<PageSuggestion>}
     * @memberof GetParsedPageResponse
     */
    'pageContentWithSuggestions'?: Array<PageSuggestion>;
}
/**
 * 
 * @export
 * @interface GetParsedPageResponseV2
 */
export interface GetParsedPageResponseV2 {
    /**
     * 
     * @type {string}
     * @memberof GetParsedPageResponseV2
     */
    'documentId': string;
    /**
     * 
     * @type {number}
     * @memberof GetParsedPageResponseV2
     */
    'pageId': number;
    /**
     * 
     * @type {string}
     * @memberof GetParsedPageResponseV2
     */
    'formattedPageContent': string;
    /**
     * 
     * @type {string}
     * @memberof GetParsedPageResponseV2
     */
    'binaryFormattedPageContent'?: string;
    /**
     * 
     * @type {Array<PageSuggestion>}
     * @memberof GetParsedPageResponseV2
     */
    'pageContentWithSuggestions'?: Array<PageSuggestion>;
}
/**
 * 
 * @export
 * @interface GetProductCurrentDetailsResponse
 */
export interface GetProductCurrentDetailsResponse {
    /**
     * 
     * @type {ProductCurrentDetails}
     * @memberof GetProductCurrentDetailsResponse
     */
    'response': ProductCurrentDetails;
    /**
     * 
     * @type {string}
     * @memberof GetProductCurrentDetailsResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface GetProjectDetailsResponse
 */
export interface GetProjectDetailsResponse {
    /**
     * 
     * @type {APIMessage}
     * @memberof GetProjectDetailsResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {ProjectWithTags}
     * @memberof GetProjectDetailsResponse
     */
    'response'?: ProjectWithTags;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDetailsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetProjectEvaluationsListWithTagResponse
 */
export interface GetProjectEvaluationsListWithTagResponse {
    /**
     * 
     * @type {Array<EvaluationModelWithTags>}
     * @memberof GetProjectEvaluationsListWithTagResponse
     */
    'response': Array<EvaluationModelWithTags>;
    /**
     * 
     * @type {number}
     * @memberof GetProjectEvaluationsListWithTagResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectEvaluationsListWithTagResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetProjectFromDocumentResponse
 */
export interface GetProjectFromDocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof GetProjectFromDocumentResponse
     */
    'projectId': string;
}
/**
 * 
 * @export
 * @interface GetProjectResponse
 */
export interface GetProjectResponse {
    /**
     * 
     * @type {ProjectModel}
     * @memberof GetProjectResponse
     */
    'response': ProjectModel;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetProjectsPaginated
 */
export interface GetProjectsPaginated {
    /**
     * 
     * @type {Array<ProjectModel>}
     * @memberof GetProjectsPaginated
     */
    'response': Array<ProjectModel>;
    /**
     * 
     * @type {number}
     * @memberof GetProjectsPaginated
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof GetProjectsPaginated
     */
    'pageCount': number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectsPaginated
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetPromptSuggestionsResponse
 */
export interface GetPromptSuggestionsResponse {
    /**
     * 
     * @type {Array<PromptSuggestion>}
     * @memberof GetPromptSuggestionsResponse
     */
    'suggestions': Array<PromptSuggestion>;
}
/**
 * Response Model for - /v1/workspace/{workspace_id}/recordings/{recording_id}/metadata  Return the metadata associated with a recording.
 * @export
 * @interface GetRecordingMetadataResponse
 */
export interface GetRecordingMetadataResponse {
    /**
     * 
     * @type {EvaluationMetadata | object}
     * @memberof GetRecordingMetadataResponse
     */
    'response': EvaluationMetadata | object;
    /**
     * 
     * @type {string}
     * @memberof GetRecordingMetadataResponse
     */
    'workspaceId'?: string;
}
/**
 * Response Model for - /v1/workspace/{workspace_id}/recordings - /v1/workspace/{workspace_id}/recordings/model-class/{model_class} - /v1/workspace/{workspace_id}/deleted-recordings/model-class/{model_class}
 * @export
 * @interface GetRecordingsListResponse
 */
export interface GetRecordingsListResponse {
    /**
     * 
     * @type {Array<Evaluation>}
     * @memberof GetRecordingsListResponse
     */
    'response': Array<Evaluation>;
    /**
     * 
     * @type {number}
     * @memberof GetRecordingsListResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof GetRecordingsListResponse
     */
    'pageCount': number;
    /**
     * 
     * @type {string}
     * @memberof GetRecordingsListResponse
     */
    'workspaceId'?: string;
}
/**
 * Response Model for - /v1/workspace/{workspace_id}/recordings-summary - /v1/workspace/{workspace_id}/deleted-recordings-summary   Return summary of the recordings grouped by model_class
 * @export
 * @interface GetRecordingsSummaryResponse
 */
export interface GetRecordingsSummaryResponse {
    /**
     * 
     * @type {RecordingsSummary | object}
     * @memberof GetRecordingsSummaryResponse
     */
    'response': RecordingsSummary | object;
    /**
     * 
     * @type {string}
     * @memberof GetRecordingsSummaryResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetRelabelingAppliedLabelsResponse
 */
export interface GetRelabelingAppliedLabelsResponse {
    /**
     * Flat list of available labels
     * @type {Array<string>}
     * @memberof GetRelabelingAppliedLabelsResponse
     */
    'labels'?: Array<string>;
    /**
     * Contains a map of available labels, and how many rows they have been applied to
     * @type {{ [key: string]: number; }}
     * @memberof GetRelabelingAppliedLabelsResponse
     */
    'counts'?: { [key: string]: number; };
    /**
     * 
     * @type {string}
     * @memberof GetRelabelingAppliedLabelsResponse
     */
    'msg'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRelabelingAppliedLabelsResponse
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface GetRelabelingContributorsResponse
 */
export interface GetRelabelingContributorsResponse {
    /**
     * 
     * @type {Array<Contributor>}
     * @memberof GetRelabelingContributorsResponse
     */
    'contributors'?: Array<Contributor>;
    /**
     * 
     * @type {number}
     * @memberof GetRelabelingContributorsResponse
     */
    'numContributors'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetRelabelingContributorsResponse
     */
    'msg'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRelabelingContributorsResponse
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface GetRelabelingRegionsClustersResponse
 */
export interface GetRelabelingRegionsClustersResponse {
    /**
     * Flat list of available regions in the data to be relabeled
     * @type {Array<number>}
     * @memberof GetRelabelingRegionsClustersResponse
     */
    'regions'?: Array<number>;
    /**
     * Flat list of available clusters in the data to be relabeled
     * @type {Array<number>}
     * @memberof GetRelabelingRegionsClustersResponse
     */
    'clusters'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof GetRelabelingRegionsClustersResponse
     */
    'msg'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRelabelingRegionsClustersResponse
     */
    'code'?: string;
}
/**
 * API response for fetching recommendation summary
 * @export
 * @interface GetRelabelingSummaryResponse
 */
export interface GetRelabelingSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof GetRelabelingSummaryResponse
     */
    'workspaceId': string;
    /**
     * This field is deprecated. Use other fields to get details of resolved points.
     * @type {number}
     * @memberof GetRelabelingSummaryResponse
     * @deprecated
     */
    'numMislabeled': number;
    /**
     * This field is deprecated. Use other fields to get details of resolved points.
     * @type {number}
     * @memberof GetRelabelingSummaryResponse
     * @deprecated
     */
    'numResolved': number;
    /**
     * 
     * @type {number}
     * @memberof GetRelabelingSummaryResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof GetRelabelingSummaryResponse
     */
    'numResolvedRecords': number;
    /**
     * 
     * @type {number}
     * @memberof GetRelabelingSummaryResponse
     */
    'numMislabeledRecords': number;
    /**
     * 
     * @type {number}
     * @memberof GetRelabelingSummaryResponse
     */
    'numMislabeledResolved': number;
}
/**
 * API response for fetching recommendation status
 * @export
 * @interface GetRelabelingWorkbenchStatusResponse
 */
export interface GetRelabelingWorkbenchStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof GetRelabelingWorkbenchStatusResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {number}
     * @memberof GetRelabelingWorkbenchStatusResponse
     */
    'numMislabeled': number;
    /**
     * 
     * @type {RelabelingWorkbenchStatus}
     * @memberof GetRelabelingWorkbenchStatusResponse
     */
    'status': RelabelingWorkbenchStatus;
    /**
     * 
     * @type {string}
     * @memberof GetRelabelingWorkbenchStatusResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GetResourcePreviewDataResponse
 */
export interface GetResourcePreviewDataResponse {
    /**
     * 
     * @type {ResourcePreviewData}
     * @memberof GetResourcePreviewDataResponse
     */
    'response': ResourcePreviewData;
    /**
     * 
     * @type {string}
     * @memberof GetResourcePreviewDataResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface GetResourcePreviewUrlResponse
 */
export interface GetResourcePreviewUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof GetResourcePreviewUrlResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof GetResourcePreviewUrlResponse
     */
    'workspaceId'?: string;
}
/**
 * Get tags for a resource id response resource_tag_id_mapping: {     resource_id: List[tag_ids] }, tag_metadata: {     tag_id: {         name         description         category         color         norm_name         create_date         update_date     } }
 * @export
 * @interface GetResourceTagsResponse
 */
export interface GetResourceTagsResponse {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof GetResourceTagsResponse
     */
    'resourceTagIdMapping': { [key: string]: Array<string>; };
    /**
     * 
     * @type {{ [key: string]: TagMetadata; }}
     * @memberof GetResourceTagsResponse
     */
    'tagMetadata': { [key: string]: TagMetadata; };
}
/**
 * 
 * @export
 * @interface GetSecretResponse
 */
export interface GetSecretResponse {
    /**
     * 
     * @type {SecretModel}
     * @memberof GetSecretResponse
     */
    'response': SecretModel;
    /**
     * 
     * @type {string}
     * @memberof GetSecretResponse
     */
    'workspaceId': string;
}
/**
 * Response Class for \'/v1/account/{account_id}/datasets/{dataset_id}/segment-comparison\'
 * @export
 * @interface GetSegmentComparisonResponse
 */
export interface GetSegmentComparisonResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof GetSegmentComparisonResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {SegmentComparison}
     * @memberof GetSegmentComparisonResponse
     */
    'response'?: SegmentComparison;
    /**
     * 
     * @type {string}
     * @memberof GetSegmentComparisonResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface GetSlackConfigurationResponseModel
 */
export interface GetSlackConfigurationResponseModel {
    /**
     * 
     * @type {string}
     * @memberof GetSlackConfigurationResponseModel
     */
    'appId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSlackConfigurationResponseModel
     */
    'enterpriseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSlackConfigurationResponseModel
     */
    'enterpriseName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSlackConfigurationResponseModel
     */
    'teamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSlackConfigurationResponseModel
     */
    'teamName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSlackConfigurationResponseModel
     */
    'channelId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSlackConfigurationResponseModel
     */
    'channelName'?: string;
}
/**
 * 
 * @export
 * @interface GetSnippetChartResponse
 */
export interface GetSnippetChartResponse {
    /**
     * 
     * @type {any}
     * @memberof GetSnippetChartResponse
     */
    'data'?: any;
}
/**
 * 
 * @export
 * @interface GetSnippetContentResponse
 */
export interface GetSnippetContentResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSnippetContentResponse
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof GetSnippetContentResponse
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface GetSnippetDocumentResponse
 */
export interface GetSnippetDocumentResponse {
    /**
     * 
     * @type {APIMessage}
     * @memberof GetSnippetDocumentResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {string}
     * @memberof GetSnippetDocumentResponse
     */
    'document'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSnippetDocumentResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface GetStatusForSupersetWorkflowSetupResponse
 */
export interface GetStatusForSupersetWorkflowSetupResponse {
    /**
     * 
     * @type {string}
     * @memberof GetStatusForSupersetWorkflowSetupResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {SupersetWorkflowSetupStatus}
     * @memberof GetStatusForSupersetWorkflowSetupResponse
     */
    'status': SupersetWorkflowSetupStatus;
    /**
     * 
     * @type {SupersetWorkflowSetupResult}
     * @memberof GetStatusForSupersetWorkflowSetupResponse
     */
    'result'?: SupersetWorkflowSetupResult;
}
/**
 * 
 * @export
 * @interface GetStyleGuideConfigPaginatedResponse
 */
export interface GetStyleGuideConfigPaginatedResponse {
    /**
     * 
     * @type {Array<StyleGuideConfigRow>}
     * @memberof GetStyleGuideConfigPaginatedResponse
     */
    'response': Array<StyleGuideConfigRow>;
    /**
     * 
     * @type {number}
     * @memberof GetStyleGuideConfigPaginatedResponse
     */
    'numRecords': number;
}
/**
 * API response for fetching analysers run on subset
 * @export
 * @interface GetSubsetAnalysesResponse
 */
export interface GetSubsetAnalysesResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSubsetAnalysesResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<DatasetTasklet>}
     * @memberof GetSubsetAnalysesResponse
     */
    'response': Array<DatasetTasklet>;
}
/**
 * API response for fetching analyser tasks run on subset
 * @export
 * @interface GetSubsetAnalysisTasksResponse
 */
export interface GetSubsetAnalysisTasksResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSubsetAnalysisTasksResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<SubsetAnalysisTask>}
     * @memberof GetSubsetAnalysisTasksResponse
     */
    'response': Array<SubsetAnalysisTask>;
}
/**
 * API response for fetching available analyses run on subset
 * @export
 * @interface GetSubsetAvailableAnalysesResponse
 */
export interface GetSubsetAvailableAnalysesResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSubsetAvailableAnalysesResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<DatasetTasklet>}
     * @memberof GetSubsetAvailableAnalysesResponse
     */
    'response': Array<DatasetTasklet>;
}
/**
 * API response for fetching subset analysis detail
 * @export
 * @interface GetSubsetDetailsResponse
 */
export interface GetSubsetDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSubsetDetailsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Subset}
     * @memberof GetSubsetDetailsResponse
     */
    'response': Subset;
}
/**
 * API response for fetching subset analyses
 * @export
 * @interface GetSubsetsResponse
 */
export interface GetSubsetsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSubsetsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {number}
     * @memberof GetSubsetsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {Array<Subset>}
     * @memberof GetSubsetsResponse
     */
    'response': Array<Subset>;
}
/**
 * 
 * @export
 * @interface GetSupersetUsernameForTokenResponse
 */
export interface GetSupersetUsernameForTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSupersetUsernameForTokenResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupersetUsernameForTokenResponse
     */
    'validationSuccessful'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSupersetUsernameForTokenResponse
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSupersetUsernameForTokenResponse
     */
    'username'?: string;
}
/**
 * Get tags for a resource id request
 * @export
 * @interface GetTagsRequest
 */
export interface GetTagsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetTagsRequest
     */
    'resourceIds': Array<string>;
}
/**
 * Fetch all tags api response
 * @export
 * @interface GetTagsResponse
 */
export interface GetTagsResponse {
    /**
     * 
     * @type {Array<TagMetadata>}
     * @memberof GetTagsResponse
     */
    'tags': Array<TagMetadata>;
}
/**
 * 
 * @export
 * @interface GetTaskIdForTaskletId
 */
export interface GetTaskIdForTaskletId {
    /**
     * 
     * @type {string}
     * @memberof GetTaskIdForTaskletId
     */
    'taskId': string;
}
/**
 * 
 * @export
 * @interface GetTokenRequestModel
 */
export interface GetTokenRequestModel {
    /**
     * 
     * @type {string}
     * @memberof GetTokenRequestModel
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof GetTokenRequestModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof GetTokenRequestModel
     */
    'teamId'?: string;
}
/**
 * 
 * @export
 * @interface GetTransactionsBySourceResponseModel
 */
export interface GetTransactionsBySourceResponseModel {
    /**
     * 
     * @type {Array<MarkovCreditsUsageResponseModel>}
     * @memberof GetTransactionsBySourceResponseModel
     */
    'transactions': Array<MarkovCreditsUsageResponseModel>;
}
/**
 * 
 * @export
 * @interface GetTriggerEmailResponse
 */
export interface GetTriggerEmailResponse {
    /**
     * 
     * @type {string}
     * @memberof GetTriggerEmailResponse
     */
    'email': string;
}
/**
 * Response to fetch credential id to access a dataset uploaded in the uploads bucket
 * @export
 * @interface GetUploadCredentialResponse
 */
export interface GetUploadCredentialResponse {
    /**
     * 
     * @type {string}
     * @memberof GetUploadCredentialResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof GetUploadCredentialResponse
     */
    'credentialId': string;
    /**
     * 
     * @type {string}
     * @memberof GetUploadCredentialResponse
     */
    'bucket': string;
}
/**
 * 
 * @export
 * @interface GetWorkflowAppDetailResponse
 */
export interface GetWorkflowAppDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof GetWorkflowAppDetailResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof GetWorkflowAppDetailResponse
     */
    'workflowId': string;
    /**
     * 
     * @type {string}
     * @memberof GetWorkflowAppDetailResponse
     */
    'workflowAppId': string;
    /**
     * 
     * @type {string}
     * @memberof GetWorkflowAppDetailResponse
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof GetWorkflowAppDetailResponse
     */
    'workflowAppName': string;
    /**
     * 
     * @type {string}
     * @memberof GetWorkflowAppDetailResponse
     */
    'workflowAppDescription': string;
    /**
     * 
     * @type {string}
     * @memberof GetWorkflowAppDetailResponse
     */
    'dagId': string;
    /**
     * 
     * @type {GetDAGResponse}
     * @memberof GetWorkflowAppDetailResponse
     */
    'dag': GetDAGResponse;
    /**
     * 
     * @type {string}
     * @memberof GetWorkflowAppDetailResponse
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetWorkflowAppDetailResponse
     */
    'updateDate': string;
    /**
     * Fetch artifacts based on query  param filter ACTIVE/ARCHIVED
     * @type {ArtifactStateModel}
     * @memberof GetWorkflowAppDetailResponse
     */
    'artifactState': ArtifactStateModel;
    /**
     * 
     * @type {WorkflowAppCategory}
     * @memberof GetWorkflowAppDetailResponse
     */
    'category'?: WorkflowAppCategory;
}
/**
 * Schema for the response of retrieving workflow run nodes.  Attributes: - response (Dict[str, WorkflowRunNodeIOSchema]): A dictionary where keys are node IDs and values are WorkflowRunNodeIOSchema objects.
 * @export
 * @interface GetWorkflowDagNodesSchemaResponse
 */
export interface GetWorkflowDagNodesSchemaResponse {
    /**
     * 
     * @type {{ [key: string]: WorkflowDagNodeIOSchema; }}
     * @memberof GetWorkflowDagNodesSchemaResponse
     */
    'response': { [key: string]: WorkflowDagNodeIOSchema; };
}
/**
 * 
 * @export
 * @interface GetWorkflowRunStatusDetailsResponse
 */
export interface GetWorkflowRunStatusDetailsResponse {
    /**
     * 
     * @type {WorkflowRunStatusDetailsResponse}
     * @memberof GetWorkflowRunStatusDetailsResponse
     */
    'response': WorkflowRunStatusDetailsResponse;
}
/**
 * 
 * @export
 * @interface GetWorkspaceCurrentSubscriptionDetailResponseModel
 */
export interface GetWorkspaceCurrentSubscriptionDetailResponseModel {
    /**
     * 
     * @type {string}
     * @memberof GetWorkspaceCurrentSubscriptionDetailResponseModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof GetWorkspaceCurrentSubscriptionDetailResponseModel
     */
    'subscriptionId': string;
    /**
     * 
     * @type {PaymentSubscriptionType}
     * @memberof GetWorkspaceCurrentSubscriptionDetailResponseModel
     */
    'subscriptionType': PaymentSubscriptionType;
    /**
     * 
     * @type {number}
     * @memberof GetWorkspaceCurrentSubscriptionDetailResponseModel
     */
    'currentPeriodStart': number;
    /**
     * 
     * @type {number}
     * @memberof GetWorkspaceCurrentSubscriptionDetailResponseModel
     */
    'currentPeriodEnd': number;
    /**
     * 
     * @type {PaymentSubscriptionStatusType}
     * @memberof GetWorkspaceCurrentSubscriptionDetailResponseModel
     */
    'status': PaymentSubscriptionStatusType;
    /**
     * 
     * @type {number}
     * @memberof GetWorkspaceCurrentSubscriptionDetailResponseModel
     */
    'usedCredit': number;
    /**
     * 
     * @type {number}
     * @memberof GetWorkspaceCurrentSubscriptionDetailResponseModel
     */
    'totalCredit': number;
}
/**
 * 
 * @export
 * @interface GongCredentialModel
 */
export interface GongCredentialModel {
    /**
     * 
     * @type {string}
     * @memberof GongCredentialModel
     */
    'accessKey': string;
    /**
     * 
     * @type {string}
     * @memberof GongCredentialModel
     */
    'accessKeySecret': string;
    /**
     * 
     * @type {string}
     * @memberof GongCredentialModel
     */
    'startDate'?: string;
}
/**
 * 
 * @export
 * @interface GoogleAdsCredentialModel
 */
export interface GoogleAdsCredentialModel {
    /**
     * 
     * @type {string}
     * @memberof GoogleAdsCredentialModel
     */
    'developerToken': string;
    /**
     * 
     * @type {string}
     * @memberof GoogleAdsCredentialModel
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof GoogleAdsCredentialModel
     */
    'clientSecret': string;
    /**
     * 
     * @type {string}
     * @memberof GoogleAdsCredentialModel
     */
    'refreshToken': string;
    /**
     * 
     * @type {string}
     * @memberof GoogleAdsCredentialModel
     */
    'startDate'?: string;
}
/**
 * 
 * @export
 * @interface GoogleAnalyticsCredentialModel
 */
export interface GoogleAnalyticsCredentialModel {
    /**
     * 
     * @type {string}
     * @memberof GoogleAnalyticsCredentialModel
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {number}
     * @memberof GoogleAnalyticsCredentialModel
     */
    'expiryDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof GoogleAnalyticsCredentialModel
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleAnalyticsCredentialModel
     */
    'tokenType'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleAnalyticsCredentialModel
     */
    'idToken'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GoogleAnalyticsCredentialModel
     */
    'scope'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GoogleAnalyticsCredentialModel
     */
    'propertyIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GoogleAnalyticsCredentialModel
     */
    'dateRangesStartDate'?: string;
}
/**
 * 
 * @export
 * @interface GoogleDriveConnectorMetadataModel
 */
export interface GoogleDriveConnectorMetadataModel {
    /**
     * 
     * @type {string}
     * @memberof GoogleDriveConnectorMetadataModel
     */
    'accessToken'?: string;
}
/**
 * 
 * @export
 * @interface GoogleDriveResourceModel
 */
export interface GoogleDriveResourceModel {
    /**
     * 
     * @type {string}
     * @memberof GoogleDriveResourceModel
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof GoogleDriveResourceModel
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface GoogleSheetConnectorMetadataModel
 */
export interface GoogleSheetConnectorMetadataModel {
    /**
     * 
     * @type {string}
     * @memberof GoogleSheetConnectorMetadataModel
     */
    'spreadsheetUrl': string;
}
/**
 * 
 * @export
 * @interface GoogleSheetResourceModel
 */
export interface GoogleSheetResourceModel {
    /**
     * 
     * @type {string}
     * @memberof GoogleSheetResourceModel
     */
    'spreadsheetId': string;
    /**
     * 
     * @type {string}
     * @memberof GoogleSheetResourceModel
     */
    'worksheetTitle': string;
}
/**
 * 
 * @export
 * @interface GroupByTargetStatusResponse
 */
export interface GroupByTargetStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof GroupByTargetStatusResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof GroupByTargetStatusResponse
     */
    'datasetId': string;
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof GroupByTargetStatusResponse
     */
    'status': AnalysisStateNames;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface HeatMapDataModel
 */
export interface HeatMapDataModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof HeatMapDataModel
     */
    'xCategories'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HeatMapDataModel
     */
    'yCategories'?: Array<string>;
    /**
     * 
     * @type {Array<HeatMapSeriesModel>}
     * @memberof HeatMapDataModel
     */
    'series'?: Array<HeatMapSeriesModel>;
}
/**
 * 
 * @export
 * @interface HeatMapDataModel1
 */
export interface HeatMapDataModel1 {
    /**
     * 
     * @type {Array<string>}
     * @memberof HeatMapDataModel1
     */
    'xCategories'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HeatMapDataModel1
     */
    'yCategories'?: Array<string>;
    /**
     * 
     * @type {Array<HeatMapSeriesModel>}
     * @memberof HeatMapDataModel1
     */
    'series'?: Array<HeatMapSeriesModel>;
}
/**
 * 
 * @export
 * @interface HeatMapSeriesDataModel
 */
export interface HeatMapSeriesDataModel {
    /**
     * 
     * @type {number}
     * @memberof HeatMapSeriesDataModel
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof HeatMapSeriesDataModel
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof HeatMapSeriesDataModel
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof HeatMapSeriesDataModel
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface HeatMapSeriesDataModel1
 */
export interface HeatMapSeriesDataModel1 {
    /**
     * 
     * @type {number}
     * @memberof HeatMapSeriesDataModel1
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof HeatMapSeriesDataModel1
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof HeatMapSeriesDataModel1
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface HeatMapSeriesModel
 */
export interface HeatMapSeriesModel {
    /**
     * 
     * @type {string}
     * @memberof HeatMapSeriesModel
     */
    'name': string;
    /**
     * 
     * @type {Array<HeatMapSeriesDataModel1>}
     * @memberof HeatMapSeriesModel
     */
    'data'?: Array<HeatMapSeriesDataModel1>;
}
/**
 * 
 * @export
 * @interface HubspotCredentialModel
 */
export interface HubspotCredentialModel {
    /**
     * 
     * @type {string}
     * @memberof HubspotCredentialModel
     */
    'credentialsTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof HubspotCredentialModel
     */
    'accessToken': string;
    /**
     * 
     * @type {string}
     * @memberof HubspotCredentialModel
     */
    'startDate'?: string;
}
/**
 * 
 * @export
 * @interface HybridDeploymentInfo
 */
export interface HybridDeploymentInfo {
    /**
     * 
     * @type {string}
     * @memberof HybridDeploymentInfo
     */
    'baseUrl': string;
    /**
     * 
     * @type {string}
     * @memberof HybridDeploymentInfo
     */
    'installedVersion': string;
    /**
     * 
     * @type {string}
     * @memberof HybridDeploymentInfo
     */
    'updateDate': string;
}
/**
 * 
 * @export
 * @interface IDPAuthExchangeRequest
 */
export interface IDPAuthExchangeRequest {
    /**
     * 
     * @type {string}
     * @memberof IDPAuthExchangeRequest
     */
    'authCode': string;
    /**
     * 
     * @type {StorageType}
     * @memberof IDPAuthExchangeRequest
     */
    'connectorType': StorageType;
    /**
     * 
     * @type {string}
     * @memberof IDPAuthExchangeRequest
     */
    'connectorName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IDPAuthExchangeRequest
     */
    'redirectUri'?: string;
}
/**
 * Information to be added to ID tokens Ref: https://meskalero.slab.com/posts/structure-of-custom-claims-in-access-and-id-tokens-dja565gr
 * @export
 * @interface IDTokenInfo
 */
export interface IDTokenInfo {
    /**
     * 
     * @type {string}
     * @memberof IDTokenInfo
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof IDTokenInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof IDTokenInfo
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof IDTokenInfo
     */
    'username'?: string;
    /**
     * 
     * @type {Array<WorkspaceAndTeam>}
     * @memberof IDTokenInfo
     */
    'workspaces': Array<WorkspaceAndTeam>;
}
/**
 * 
 * @export
 * @interface IOSchema
 */
export interface IOSchema {
    /**
     * 
     * @type {NodeIOType}
     * @memberof IOSchema
     */
    'type': NodeIOType;
    /**
     * 
     * @type {DataProperties | ModelProperties}
     * @memberof IOSchema
     */
    'properties': DataProperties | ModelProperties;
}
/**
 * 
 * @export
 * @interface IdNamePair
 */
export interface IdNamePair {
    /**
     * 
     * @type {string}
     * @memberof IdNamePair
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IdNamePair
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface InAppAckRequest
 */
export interface InAppAckRequest {
    /**
     * 
     * @type {object}
     * @memberof InAppAckRequest
     */
    'queueData': object;
}
/**
 * 
 * @export
 * @interface InAppNotificationModel
 */
export interface InAppNotificationModel {
    /**
     * 
     * @type {NotificationTypes}
     * @memberof InAppNotificationModel
     */
    'notificationType': NotificationTypes;
    /**
     * 
     * @type {SnippetInvitationPayload | SnippetCommentNotificationPayload | AnalyzersCompletionNotificationPayload | UserMentionsOnSnippetContentRequestPayload | SnippetMentionCommentPayload | ModelAppsReadyNotificationPayload | UserWorkspaceInvitationPayload}
     * @memberof InAppNotificationModel
     */
    'payload': SnippetInvitationPayload | SnippetCommentNotificationPayload | AnalyzersCompletionNotificationPayload | UserMentionsOnSnippetContentRequestPayload | SnippetMentionCommentPayload | ModelAppsReadyNotificationPayload | UserWorkspaceInvitationPayload;
    /**
     * 
     * @type {string}
     * @memberof InAppNotificationModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof InAppNotificationModel
     */
    'recordId': string;
    /**
     * 
     * @type {boolean}
     * @memberof InAppNotificationModel
     */
    'read'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InAppNotificationModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof InAppNotificationModel
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface InAppNotificationRecordCountResponseModel
 */
export interface InAppNotificationRecordCountResponseModel {
    /**
     * 
     * @type {number}
     * @memberof InAppNotificationRecordCountResponseModel
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface InAppRequestModel
 */
export interface InAppRequestModel {
    /**
     * 
     * @type {object}
     * @memberof InAppRequestModel
     */
    'queueData': object;
}
/**
 * 
 * @export
 * @interface InferenceServiceStatusResponse
 */
export interface InferenceServiceStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InferenceServiceStatusResponse
     */
    'ready': boolean;
    /**
     * 
     * @type {string}
     * @memberof InferenceServiceStatusResponse
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof InferenceServiceStatusResponse
     */
    'status': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum InferenceSourceType {
    ModelApp = 'MODEL_APP',
    WorkflowRun = 'WORKFLOW_RUN',
    Sdk = 'SDK'
}

/**
 * 
 * @export
 * @interface InitInferenceRequest
 */
export interface InitInferenceRequest {
    /**
     * 
     * @type {string}
     * @memberof InitInferenceRequest
     */
    'appName': string;
    /**
     * 
     * @type {number}
     * @memberof InitInferenceRequest
     */
    'durationInMins': number;
    /**
     * 
     * @type {string}
     * @memberof InitInferenceRequest
     */
    'modelId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InitInferenceRequest
     */
    'datasetId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InitInferenceRequest
     */
    'isRestart'?: boolean;
}
/**
 * 
 * @export
 * @interface InitInferenceServiceResponse
 */
export interface InitInferenceServiceResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InitInferenceServiceResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof InitInferenceServiceResponse
     */
    'status': string;
    /**
     * 
     * @type {object}
     * @memberof InitInferenceServiceResponse
     */
    'data': object;
    /**
     * 
     * @type {string}
     * @memberof InitInferenceServiceResponse
     */
    'modelId': string;
}
/**
 * 
 * @export
 * @interface InitSupersetForWorkflowResponse
 */
export interface InitSupersetForWorkflowResponse {
    /**
     * 
     * @type {string}
     * @memberof InitSupersetForWorkflowResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {boolean}
     * @memberof InitSupersetForWorkflowResponse
     */
    'ack': boolean;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum InputMode {
    Files = 'files',
    SourceContent = 'source_content',
    Url = 'url'
}

/**
 * Base model for insights for a dataset. This has custom serializer based on https://github.com/pydantic/pydantic/discussions/3091
 * @export
 * @interface Insight
 */
export interface Insight {
    /**
     * 
     * @type {InsightIdentifier}
     * @memberof Insight
     */
    'insightIdentifier'?: InsightIdentifier;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    'insightType': string;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    'msg': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum InsightIdentifier {
    DuplicateRows = 'DuplicateRows',
    SimilarColumn = 'SimilarColumn',
    AllDistinct = 'AllDistinct',
    UniformDistributed = 'UniformDistributed',
    HighCardinality = 'HighCardinality',
    MissingValue = 'MissingValue',
    SkewedDistribution = 'SkewedDistribution',
    ConstantValues = 'ConstantValues',
    ConstantLength = 'ConstantLength',
    InfinityCount = 'InfinityCount',
    ZeroCount = 'ZeroCount',
    NegativeCount = 'NegativeCount',
    NormalDistributed = 'NormalDistributed',
    PosCorrelation = 'PosCorrelation',
    NegCorrelation = 'NegCorrelation',
    NoCorrelation = 'NoCorrelation',
    Unknown = 'Unknown'
}

/**
 * 
 * @export
 * @interface InternetModel
 */
export interface InternetModel {
    /**
     * 
     * @type {string}
     * @memberof InternetModel
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof InternetModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InternetModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof InternetModel
     */
    'introduction': string;
    /**
     * 
     * @type {number}
     * @memberof InternetModel
     */
    'matchedWords': number;
    /**
     * 
     * @type {number}
     * @memberof InternetModel
     */
    'identicalWords': number;
    /**
     * 
     * @type {number}
     * @memberof InternetModel
     */
    'similarWords': number;
    /**
     * 
     * @type {number}
     * @memberof InternetModel
     */
    'paraphrasedWords': number;
    /**
     * 
     * @type {number}
     * @memberof InternetModel
     */
    'totalWords': number;
    /**
     * 
     * @type {object}
     * @memberof InternetModel
     */
    'metadata': object;
    /**
     * 
     * @type {Array<any>}
     * @memberof InternetModel
     */
    'tags': Array<any>;
}
/**
 * Invitation request used when a user is creating a fresh invite to a email id
 * @export
 * @interface InvitationRequest
 */
export interface InvitationRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof InvitationRequest
     */
    'emails': Array<string>;
    /**
     * 
     * @type {WorkspaceMembershipRoles}
     * @memberof InvitationRequest
     */
    'role': WorkspaceMembershipRoles;
    /**
     * 
     * @type {string}
     * @memberof InvitationRequest
     */
    'invitedByUser': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InvitationRequest
     */
    'invitedToTeams'?: { [key: string]: string; };
    /**
     * 
     * @type {RedirectToAppType}
     * @memberof InvitationRequest
     */
    'redirectToAppType'?: RedirectToAppType;
}
/**
 * 
 * @export
 * @interface InviteGenAIProjectRequest
 */
export interface InviteGenAIProjectRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteGenAIProjectRequest
     */
    'inviteUsers': Array<string>;
}
/**
 * 
 * @export
 * @interface InviteGenAIProjectResponse
 */
export interface InviteGenAIProjectResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InviteGenAIProjectResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof InviteGenAIProjectResponse
     */
    'projectId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteGenAIProjectResponse
     */
    'invitedUsers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InviteGenAIProjectResponse
     */
    'message'?: string;
}
/**
 * Invitation response to create invite for a user, get all invites for workspace and get all invites for a user
 * @export
 * @interface InviteUserToWorkspaceResponse
 */
export interface InviteUserToWorkspaceResponse {
    /**
     * 
     * @type {string}
     * @memberof InviteUserToWorkspaceResponse
     */
    'email': string;
    /**
     * 
     * @type {WorkspaceMembershipRoles}
     * @memberof InviteUserToWorkspaceResponse
     */
    'role': WorkspaceMembershipRoles;
    /**
     * 
     * @type {WorkspaceInviteStatus}
     * @memberof InviteUserToWorkspaceResponse
     */
    'status': WorkspaceInviteStatus;
    /**
     * 
     * @type {string}
     * @memberof InviteUserToWorkspaceResponse
     */
    'expiryDate': string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserToWorkspaceResponse
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserToWorkspaceResponse
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserToWorkspaceResponse
     */
    'invitedByUser': string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserToWorkspaceResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserToWorkspaceResponse
     */
    'inviteId': string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserToWorkspaceResponse
     */
    'notificationRecordId': string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserToWorkspaceResponse
     */
    'message': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InviteUserToWorkspaceResponse
     */
    'invitedToTeams': { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof InviteUserToWorkspaceResponse
     */
    'isExpired'?: boolean;
}
/**
 * Invite multiple emails to a workspace
 * @export
 * @interface InviteUsersToWorkspaceResponse
 */
export interface InviteUsersToWorkspaceResponse {
    /**
     * 
     * @type {Array<InviteUserToWorkspaceResponse>}
     * @memberof InviteUsersToWorkspaceResponse
     */
    'invitedUsers': Array<InviteUserToWorkspaceResponse>;
}
/**
 * 
 * @export
 * @interface IsVisualizationSupportedResponse
 */
export interface IsVisualizationSupportedResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IsVisualizationSupportedResponse
     */
    'isSupported': boolean;
    /**
     * 
     * @type {string}
     * @memberof IsVisualizationSupportedResponse
     */
    'workflowId': string;
    /**
     * 
     * @type {string}
     * @memberof IsVisualizationSupportedResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface JournalInformationModel
 */
export interface JournalInformationModel {
    /**
     * 
     * @type {CitationSourceType}
     * @memberof JournalInformationModel
     */
    'citationType': CitationSourceType;
    /**
     * Title of the reference
     * @type {string}
     * @memberof JournalInformationModel
     */
    'citationTitle': string;
    /**
     * 
     * @type {DateModel}
     * @memberof JournalInformationModel
     */
    'publishedDate'?: DateModel;
    /**
     * List of authors for the citation.
     * @type {Array<AuthorInformationModel>}
     * @memberof JournalInformationModel
     */
    'authors': Array<AuthorInformationModel>;
    /**
     * 
     * @type {string}
     * @memberof JournalInformationModel
     */
    'url'?: string;
    /**
     * Name of the journal where the article is published.
     * @type {string}
     * @memberof JournalInformationModel
     */
    'journalName': string;
    /**
     * Status of publication, i.e. published or in_press
     * @type {PublicationStatusType}
     * @memberof JournalInformationModel
     */
    'publicationStatus'?: PublicationStatusType;
    /**
     * 
     * @type {string}
     * @memberof JournalInformationModel
     */
    'journalVolume'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalInformationModel
     */
    'journalIssue'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalInformationModel
     */
    'pages'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalInformationModel
     */
    'articleNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalInformationModel
     */
    'libraryDatabase'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalInformationModel
     */
    'pdf'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalInformationModel
     */
    'doi'?: string;
}
/**
 * 
 * @export
 * @interface KeywordAnalysis
 */
export interface KeywordAnalysis {
    /**
     * 
     * @type {number}
     * @memberof KeywordAnalysis
     */
    'organicPresence'?: number;
    /**
     * 
     * @type {number}
     * @memberof KeywordAnalysis
     */
    'sponsoredPresence'?: number;
    /**
     * 
     * @type {Array<SuggestionModel>}
     * @memberof KeywordAnalysis
     */
    'adsSuggestions'?: Array<SuggestionModel>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum KeywordAnalysisSpendSuggestion {
    IncreaseSpend = 'Increase spend',
    DecreaseSpend = 'Decrease spend',
    MaintainSpend = 'Maintain spend',
    NoSuggestion = 'No Suggestion'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum KeywordAnalysisStatusType {
    NotAvailable = 'NOT_AVAILABLE',
    Available = 'AVAILABLE'
}

/**
 * 
 * @export
 * @interface LLMModel
 */
export interface LLMModel {
    /**
     * 
     * @type {string}
     * @memberof LLMModel
     */
    'modelId': string;
    /**
     * 
     * @type {Array<ModelConfigurations>}
     * @memberof LLMModel
     */
    'modelConfigurations': Array<ModelConfigurations>;
}
/**
 * 
 * @export
 * @interface LLMResponse
 */
export interface LLMResponse {
    /**
     * 
     * @type {ChatItemType}
     * @memberof LLMResponse
     */
    'chatItemType': ChatItemType;
    /**
     * 
     * @type {LLMResponseType}
     * @memberof LLMResponse
     */
    'llmResponseType': LLMResponseType;
    /**
     * 
     * @type {any}
     * @memberof LLMResponse
     */
    'content': any;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LLMResponseType {
    Text = 'TEXT',
    Image = 'IMAGE',
    Viz = 'VIZ',
    Table = 'TABLE'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum LLMTypes {
    Gpt35Turbo = 'gpt-3.5-turbo',
    Gpt4 = 'gpt-4',
    Gpt4o = 'gpt-4o',
    Gpt4oMini = 'gpt-4o-mini',
    Gpt41106Preview = 'gpt-4-1106-preview',
    Mistral = 'mistral',
    GeminiGeminiPro = 'gemini/gemini-pro',
    Claude35Haiku20241022 = 'claude-3-5-haiku-20241022',
    Claude35Sonnet20241022 = 'claude-3-5-sonnet-20241022'
}

/**
 * 
 * @export
 * @interface LabelingRuleBody
 */
export interface LabelingRuleBody {
    /**
     * 
     * @type {LogicalOperator}
     * @memberof LabelingRuleBody
     */
    'operator': LogicalOperator;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof LabelingRuleBody
     */
    'conditions': Array<Condition>;
    /**
     * 
     * @type {string | number}
     * @memberof LabelingRuleBody
     */
    'targetValue': string | number;
    /**
     * 
     * @type {RuleState}
     * @memberof LabelingRuleBody
     */
    'state': RuleState;
    /**
     * 
     * @type {string}
     * @memberof LabelingRuleBody
     */
    'targetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabelingRuleBody
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface LayoutMapping
 */
export interface LayoutMapping {
    /**
     * 
     * @type {string}
     * @memberof LayoutMapping
     */
    'className': string;
    /**
     * 
     * @type {string}
     * @memberof LayoutMapping
     */
    'segment': string;
    /**
     * 
     * @type {string}
     * @memberof LayoutMapping
     */
    'analysisId': string;
}
/**
 * 
 * @export
 * @interface LexicalDiversityMetrics
 */
export interface LexicalDiversityMetrics {
    /**
     * 
     * @type {number}
     * @memberof LexicalDiversityMetrics
     */
    'lexicalDensity': number;
    /**
     * 
     * @type {string}
     * @memberof LexicalDiversityMetrics
     */
    'lexicalDensityLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof LexicalDiversityMetrics
     */
    'lexicalDensityToolTip'?: string;
    /**
     * 
     * @type {number}
     * @memberof LexicalDiversityMetrics
     */
    'typeTokenRatio': number;
    /**
     * 
     * @type {string}
     * @memberof LexicalDiversityMetrics
     */
    'typeTokenRatioToolTip'?: string;
}
/**
 * 
 * @export
 * @interface LinkModelToRegistryInternalRequest
 */
export interface LinkModelToRegistryInternalRequest {
    /**
     * 
     * @type {string}
     * @memberof LinkModelToRegistryInternalRequest
     */
    'registryId': string;
    /**
     * 
     * @type {string}
     * @memberof LinkModelToRegistryInternalRequest
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface LinkModelToRegistryRequest
 */
export interface LinkModelToRegistryRequest {
    /**
     * 
     * @type {string}
     * @memberof LinkModelToRegistryRequest
     */
    'registryId': string;
}
/**
 * 
 * @export
 * @interface LinkModelToRegistryResponse
 */
export interface LinkModelToRegistryResponse {
    /**
     * 
     * @type {string}
     * @memberof LinkModelToRegistryResponse
     */
    'registryId': string;
    /**
     * 
     * @type {string}
     * @memberof LinkModelToRegistryResponse
     */
    'modelId': string;
    /**
     * 
     * @type {number}
     * @memberof LinkModelToRegistryResponse
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface LinkedInAdsCredentialModel
 */
export interface LinkedInAdsCredentialModel {
    /**
     * 
     * @type {string}
     * @memberof LinkedInAdsCredentialModel
     */
    'authMethod'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkedInAdsCredentialModel
     */
    'accessToken': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedInAdsCredentialModel
     */
    'startDate'?: string;
}
/**
 * Base class that serves as the foundation for all graph related responses. It includes an attribute analysis_state, which represents the state of analysis run for a dataset. The possible analysis states are defined in the AnalysisStateNames enum.
 * @export
 * @interface ListAnalysisGroupsByMetricResponse
 */
export interface ListAnalysisGroupsByMetricResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof ListAnalysisGroupsByMetricResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {Array<AnalysisGroupedByMetricModel>}
     * @memberof ListAnalysisGroupsByMetricResponse
     */
    'response': Array<AnalysisGroupedByMetricModel>;
    /**
     * 
     * @type {string}
     * @memberof ListAnalysisGroupsByMetricResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ListAnalysisGroupsResponse
 */
export interface ListAnalysisGroupsResponse {
    /**
     * 
     * @type {Array<AnalysisGroupModel>}
     * @memberof ListAnalysisGroupsResponse
     */
    'response': Array<AnalysisGroupModel>;
    /**
     * 
     * @type {string}
     * @memberof ListAnalysisGroupsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ListAnalysisMetadataResponse
 */
export interface ListAnalysisMetadataResponse {
    /**
     * 
     * @type {Array<AnalysisMetadata>}
     * @memberof ListAnalysisMetadataResponse
     */
    'response': Array<AnalysisMetadata>;
    /**
     * 
     * @type {number}
     * @memberof ListAnalysisMetadataResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ListAnalysisMetadataResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ListAnalysisMetricsResponse
 */
export interface ListAnalysisMetricsResponse {
    /**
     * 
     * @type {Array<AnalysisMetadata>}
     * @memberof ListAnalysisMetricsResponse
     */
    'response': Array<AnalysisMetadata>;
    /**
     * 
     * @type {number}
     * @memberof ListAnalysisMetricsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ListAnalysisMetricsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ListAppBuilderPaginatedResponse
 */
export interface ListAppBuilderPaginatedResponse {
    /**
     * 
     * @type {Array<AppBuilder>}
     * @memberof ListAppBuilderPaginatedResponse
     */
    'response': Array<AppBuilder>;
    /**
     * 
     * @type {number}
     * @memberof ListAppBuilderPaginatedResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof ListAppBuilderPaginatedResponse
     */
    'pageCount': number;
    /**
     * 
     * @type {string}
     * @memberof ListAppBuilderPaginatedResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ListChatConversationModels
 */
export interface ListChatConversationModels {
    /**
     * 
     * @type {string}
     * @memberof ListChatConversationModels
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<ChatConversationModel>}
     * @memberof ListChatConversationModels
     */
    'response': Array<ChatConversationModel>;
    /**
     * 
     * @type {number}
     * @memberof ListChatConversationModels
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface ListChatConversationThreadsModel
 */
export interface ListChatConversationThreadsModel {
    /**
     * 
     * @type {string}
     * @memberof ListChatConversationThreadsModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<ChatConversationThreadModel>}
     * @memberof ListChatConversationThreadsModel
     */
    'response': Array<ChatConversationThreadModel>;
    /**
     * 
     * @type {number}
     * @memberof ListChatConversationThreadsModel
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface ListChatWithResourceConversationsModel
 */
export interface ListChatWithResourceConversationsModel {
    /**
     * 
     * @type {string}
     * @memberof ListChatWithResourceConversationsModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<DataSourceConversationModel>}
     * @memberof ListChatWithResourceConversationsModel
     */
    'response': Array<DataSourceConversationModel>;
    /**
     * 
     * @type {number}
     * @memberof ListChatWithResourceConversationsModel
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface ListChatWithResourceThreadsModel
 */
export interface ListChatWithResourceThreadsModel {
    /**
     * 
     * @type {string}
     * @memberof ListChatWithResourceThreadsModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<DataSourceThreadModel>}
     * @memberof ListChatWithResourceThreadsModel
     */
    'response': Array<DataSourceThreadModel>;
    /**
     * 
     * @type {number}
     * @memberof ListChatWithResourceThreadsModel
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface ListConnectorResponse
 */
export interface ListConnectorResponse {
    /**
     * 
     * @type {Array<ConnectorModel>}
     * @memberof ListConnectorResponse
     */
    'response': Array<ConnectorModel>;
    /**
     * 
     * @type {number}
     * @memberof ListConnectorResponse
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListConnectorResponse
     */
    'workspaceId': string;
}
/**
 * Response Class for - \'/v1/workspace/{workspace_id}/credentials\'
 * @export
 * @interface ListCredentialsResponse
 */
export interface ListCredentialsResponse {
    /**
     * 
     * @type {Array<CredentialModel>}
     * @memberof ListCredentialsResponse
     */
    'response'?: Array<CredentialModel>;
    /**
     * 
     * @type {number}
     * @memberof ListCredentialsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ListCredentialsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ListDataOperationsResponse
 */
export interface ListDataOperationsResponse {
    /**
     * 
     * @type {Array<DatasetOperationModel>}
     * @memberof ListDataOperationsResponse
     */
    'response': Array<DatasetOperationModel>;
    /**
     * 
     * @type {string}
     * @memberof ListDataOperationsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {number}
     * @memberof ListDataOperationsResponse
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface ListDataStoresResponse
 */
export interface ListDataStoresResponse {
    /**
     * 
     * @type {Array<DataStoreModel>}
     * @memberof ListDataStoresResponse
     */
    'response'?: Array<DataStoreModel>;
    /**
     * 
     * @type {number}
     * @memberof ListDataStoresResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ListDataStoresResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ListDatasetFamilyResponse
 */
export interface ListDatasetFamilyResponse {
    /**
     * 
     * @type {Array<DataFamilyModel>}
     * @memberof ListDatasetFamilyResponse
     */
    'response'?: Array<DataFamilyModel>;
    /**
     * 
     * @type {number}
     * @memberof ListDatasetFamilyResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ListDatasetFamilyResponse
     */
    'workspaceId'?: string;
}
/**
 * Paginated API response for fetching features of a dataset
 * @export
 * @interface ListDatasetFeaturesResponse
 */
export interface ListDatasetFeaturesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListDatasetFeaturesResponse
     */
    'response': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ListDatasetFeaturesResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ListDatasetFeaturesResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ListDatasetRegistrationWorkflowResponse
 */
export interface ListDatasetRegistrationWorkflowResponse {
    /**
     * 
     * @type {Array<DatasetRegistrationWorkflowModel>}
     * @memberof ListDatasetRegistrationWorkflowResponse
     */
    'response': Array<DatasetRegistrationWorkflowModel>;
    /**
     * 
     * @type {number}
     * @memberof ListDatasetRegistrationWorkflowResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ListDatasetRegistrationWorkflowResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ListDatasetResponse
 */
export interface ListDatasetResponse {
    /**
     * 
     * @type {Array<ServicesViennaApiModelsV2DatasetsDatasetModelsDataset>}
     * @memberof ListDatasetResponse
     */
    'response': Array<ServicesViennaApiModelsV2DatasetsDatasetModelsDataset>;
    /**
     * 
     * @type {number}
     * @memberof ListDatasetResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ListDatasetResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ListDatasetsResponse
 */
export interface ListDatasetsResponse {
    /**
     * 
     * @type {Array<DataSetModel>}
     * @memberof ListDatasetsResponse
     */
    'response': Array<DataSetModel>;
    /**
     * 
     * @type {number}
     * @memberof ListDatasetsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ListDatasetsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ListDatasetsWithTagsResponse
 */
export interface ListDatasetsWithTagsResponse {
    /**
     * 
     * @type {Array<DatasetWithTags>}
     * @memberof ListDatasetsWithTagsResponse
     */
    'response': Array<DatasetWithTags>;
    /**
     * 
     * @type {number}
     * @memberof ListDatasetsWithTagsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ListDatasetsWithTagsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ListEmbeddingsResponse
 */
export interface ListEmbeddingsResponse {
    /**
     * 
     * @type {Array<EmbeddingModel>}
     * @memberof ListEmbeddingsResponse
     */
    'response': Array<EmbeddingModel>;
    /**
     * 
     * @type {string}
     * @memberof ListEmbeddingsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ListExperimentsWithTagsResponse
 */
export interface ListExperimentsWithTagsResponse {
    /**
     * 
     * @type {Array<ExperimentWithTags>}
     * @memberof ListExperimentsWithTagsResponse
     */
    'response': Array<ExperimentWithTags>;
    /**
     * 
     * @type {number}
     * @memberof ListExperimentsWithTagsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ListExperimentsWithTagsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ListInAppNotificationResponseModel
 */
export interface ListInAppNotificationResponseModel {
    /**
     * 
     * @type {Array<InAppNotificationModel>}
     * @memberof ListInAppNotificationResponseModel
     */
    'response': Array<InAppNotificationModel>;
    /**
     * 
     * @type {number}
     * @memberof ListInAppNotificationResponseModel
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface ListInAppNotificationsResponse
 */
export interface ListInAppNotificationsResponse {
    /**
     * 
     * @type {Array<InAppNotificationModel>}
     * @memberof ListInAppNotificationsResponse
     */
    'response': Array<InAppNotificationModel>;
    /**
     * 
     * @type {string}
     * @memberof ListInAppNotificationsResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListInAppNotificationsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof ListInAppNotificationsResponse
     */
    'numUnreads': number;
}
/**
 * 
 * @export
 * @interface ListIntegrationResponseModel
 */
export interface ListIntegrationResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof ListIntegrationResponseModel
     */
    'slack': boolean;
}
/**
 * 
 * @export
 * @interface ListLLMModelsResponse
 */
export interface ListLLMModelsResponse {
    /**
     * 
     * @type {Array<LLMModel>}
     * @memberof ListLLMModelsResponse
     */
    'models': Array<LLMModel>;
}
/**
 * API response for fetching recommendations
 * @export
 * @interface ListMislabeledRecordsResponse
 */
export interface ListMislabeledRecordsResponse {
    /**
     * 
     * @type {string}
     * @memberof ListMislabeledRecordsResponse
     */
    'workspaceId': string;
    /**
     * This field is deprecated. Use other fields to get details of resolved points.
     * @type {number}
     * @memberof ListMislabeledRecordsResponse
     * @deprecated
     */
    'numResolved': number;
    /**
     * 
     * @type {number}
     * @memberof ListMislabeledRecordsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof ListMislabeledRecordsResponse
     */
    'numResolvedRecords': number;
    /**
     * 
     * @type {number}
     * @memberof ListMislabeledRecordsResponse
     */
    'numMislabeledRecords': number;
    /**
     * 
     * @type {number}
     * @memberof ListMislabeledRecordsResponse
     */
    'numMislabeledResolved': number;
    /**
     * 
     * @type {Array<MislabeledRecord>}
     * @memberof ListMislabeledRecordsResponse
     */
    'response': Array<MislabeledRecord>;
}
/**
 * 
 * @export
 * @interface ListModelAppsPaginatedResponse
 */
export interface ListModelAppsPaginatedResponse {
    /**
     * 
     * @type {Array<ModelApp>}
     * @memberof ListModelAppsPaginatedResponse
     */
    'response': Array<ModelApp>;
    /**
     * 
     * @type {number}
     * @memberof ListModelAppsPaginatedResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof ListModelAppsPaginatedResponse
     */
    'pageCount': number;
    /**
     * 
     * @type {string}
     * @memberof ListModelAppsPaginatedResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ListModelsWithTagsResponse
 */
export interface ListModelsWithTagsResponse {
    /**
     * 
     * @type {Array<ModelWithTags>}
     * @memberof ListModelsWithTagsResponse
     */
    'response': Array<ModelWithTags>;
    /**
     * 
     * @type {number}
     * @memberof ListModelsWithTagsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ListModelsWithTagsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ListProjectsWithTagsResponse
 */
export interface ListProjectsWithTagsResponse {
    /**
     * 
     * @type {Array<ProjectWithTags>}
     * @memberof ListProjectsWithTagsResponse
     */
    'response': Array<ProjectWithTags>;
    /**
     * 
     * @type {number}
     * @memberof ListProjectsWithTagsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ListProjectsWithTagsResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface ListRBACResourceResponseModel
 */
export interface ListRBACResourceResponseModel {
    /**
     * 
     * @type {Array<RBACResourceResponseModel>}
     * @memberof ListRBACResourceResponseModel
     */
    'resources': Array<RBACResourceResponseModel>;
}
/**
 * 
 * @export
 * @interface ListRBACResourceRoles
 */
export interface ListRBACResourceRoles {
    /**
     * 
     * @type {Array<RBACResourceRoleResponseModel>}
     * @memberof ListRBACResourceRoles
     */
    'roles': Array<RBACResourceRoleResponseModel>;
}
/**
 * 
 * @export
 * @interface ListRBACResourceScopeResponseModel
 */
export interface ListRBACResourceScopeResponseModel {
    /**
     * 
     * @type {Array<RBACResourceScopeResponseModel>}
     * @memberof ListRBACResourceScopeResponseModel
     */
    'scopes': Array<RBACResourceScopeResponseModel>;
}
/**
 * 
 * @export
 * @interface ListResourceRecordResponse
 */
export interface ListResourceRecordResponse {
    /**
     * 
     * @type {Array<ResourceModel>}
     * @memberof ListResourceRecordResponse
     */
    'response': Array<ResourceModel>;
    /**
     * 
     * @type {number}
     * @memberof ListResourceRecordResponse
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListResourceRecordResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ListResourceRequest
 */
export interface ListResourceRequest {
    /**
     * 
     * @type {Array<ResourceRequest>}
     * @memberof ListResourceRequest
     */
    'resourceList': Array<ResourceRequest>;
}
/**
 * 
 * @export
 * @interface ListResourceResponse
 */
export interface ListResourceResponse {
    /**
     * 
     * @type {Array<ResourceResponse>}
     * @memberof ListResourceResponse
     */
    'resourceList': Array<ResourceResponse>;
    /**
     * 
     * @type {string}
     * @memberof ListResourceResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ListRetailProjectsResponse
 */
export interface ListRetailProjectsResponse {
    /**
     * 
     * @type {Array<RetailProject>}
     * @memberof ListRetailProjectsResponse
     */
    'projects': Array<RetailProject>;
    /**
     * 
     * @type {string}
     * @memberof ListRetailProjectsResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ListRulesResponse
 */
export interface ListRulesResponse {
    /**
     * 
     * @type {RulesWithOrder}
     * @memberof ListRulesResponse
     */
    'response': RulesWithOrder;
    /**
     * 
     * @type {string}
     * @memberof ListRulesResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ListRunsResponse
 */
export interface ListRunsResponse {
    /**
     * 
     * @type {Array<Run1>}
     * @memberof ListRunsResponse
     */
    'response': Array<Run1>;
    /**
     * 
     * @type {number}
     * @memberof ListRunsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ListRunsResponse
     */
    'workspaceId'?: string;
}
/**
 * This class defines the structure for the API response listing saved tasklet selections.  Attributes:     workspace_id (str): The ID of the workspace where the saved tasklet selections are listed.     response (List[SavedTaskletSelection]): The details of saved tasklet selections in the workspace.     num_records(int): Total number of saved tasklet selections
 * @export
 * @interface ListSavedTaskletSelectionsResponse
 */
export interface ListSavedTaskletSelectionsResponse {
    /**
     * 
     * @type {string}
     * @memberof ListSavedTaskletSelectionsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<SavedTaskletSelection>}
     * @memberof ListSavedTaskletSelectionsResponse
     */
    'response': Array<SavedTaskletSelection>;
    /**
     * 
     * @type {number}
     * @memberof ListSavedTaskletSelectionsResponse
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface ListSearchAppsResponse
 */
export interface ListSearchAppsResponse {
    /**
     * 
     * @type {Array<WorkflowSearchAppModelResponse>}
     * @memberof ListSearchAppsResponse
     */
    'data': Array<WorkflowSearchAppModelResponse>;
    /**
     * 
     * @type {number}
     * @memberof ListSearchAppsResponse
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListSearchAppsResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ListSnippetResponse
 */
export interface ListSnippetResponse {
    /**
     * 
     * @type {Array<SnippetModel>}
     * @memberof ListSnippetResponse
     */
    'response': Array<SnippetModel>;
    /**
     * 
     * @type {string}
     * @memberof ListSnippetResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ListSnippetVersionResponse
 */
export interface ListSnippetVersionResponse {
    /**
     * 
     * @type {APIMessage}
     * @memberof ListSnippetVersionResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {Array<SnippetVersionModel>}
     * @memberof ListSnippetVersionResponse
     */
    'response': Array<SnippetVersionModel>;
    /**
     * 
     * @type {string}
     * @memberof ListSnippetVersionResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {number}
     * @memberof ListSnippetVersionResponse
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface ListWorkflowAppsResponse
 */
export interface ListWorkflowAppsResponse {
    /**
     * 
     * @type {Array<WorkflowAppWithoutDAG>}
     * @memberof ListWorkflowAppsResponse
     */
    'response': Array<WorkflowAppWithoutDAG>;
    /**
     * 
     * @type {number}
     * @memberof ListWorkflowAppsResponse
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface ListWorkflowRunsResponse
 */
export interface ListWorkflowRunsResponse {
    /**
     * 
     * @type {number}
     * @memberof ListWorkflowRunsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {Array<WorkflowRun>}
     * @memberof ListWorkflowRunsResponse
     */
    'response': Array<WorkflowRun>;
}
/**
 * 
 * @export
 * @interface ListWorkflowsResponse
 */
export interface ListWorkflowsResponse {
    /**
     * 
     * @type {number}
     * @memberof ListWorkflowsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {Array<Workflow>}
     * @memberof ListWorkflowsResponse
     */
    'response': Array<Workflow>;
}
/**
 * 
 * @export
 * @interface ListingAPIParams
 */
export interface ListingAPIParams {
    /**
     * 
     * @type {Array<BaseAPIFilter>}
     * @memberof ListingAPIParams
     */
    'filters': Array<BaseAPIFilter>;
    /**
     * 
     * @type {SortOrder}
     * @memberof ListingAPIParams
     */
    'sortOrder'?: SortOrder;
    /**
     * 
     * @type {string}
     * @memberof ListingAPIParams
     */
    'sortKey'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListingAPIParams
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListingAPIParams
     */
    'limit'?: number;
}
/**
 * Response Model for - /live
 * @export
 * @interface LiveAPIResponse
 */
export interface LiveAPIResponse {
    /**
     * 
     * @type {string}
     * @memberof LiveAPIResponse
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface LiveAPIResponse1
 */
export interface LiveAPIResponse1 {
    /**
     * 
     * @type {string}
     * @memberof LiveAPIResponse1
     */
    'status': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum LogicalOperator {
    And = 'AND',
    Or = 'OR',
    Equal = 'EQUAL'
}

/**
 * 
 * @export
 * @interface MKVLibraryStorageResourceModel
 */
export interface MKVLibraryStorageResourceModel {
    /**
     * 
     * @type {string}
     * @memberof MKVLibraryStorageResourceModel
     */
    'filePath': string;
    /**
     * 
     * @type {string}
     * @memberof MKVLibraryStorageResourceModel
     */
    'originalName'?: string;
    /**
     * 
     * @type {object}
     * @memberof MKVLibraryStorageResourceModel
     */
    'fileKeyMap'?: object;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum MLModelClass {
    Regression = 'regression',
    Classification = 'classification',
    Tagging = 'tagging'
}

/**
 * 
 * @export
 * @interface ManualExperimentEndResponse
 */
export interface ManualExperimentEndResponse {
    /**
     * 
     * @type {string}
     * @memberof ManualExperimentEndResponse
     */
    'experimentId': string;
    /**
     * 
     * @type {string}
     * @memberof ManualExperimentEndResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof ManualExperimentEndResponse
     */
    'projectId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManualExperimentEndResponse
     */
    'statusCode': number;
    /**
     * 
     * @type {string}
     * @memberof ManualExperimentEndResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ManualRelabelCreate
 */
export interface ManualRelabelCreate {
    /**
     * Name to be given to the new target. If not provided, original target name would be used.
     * @type {string}
     * @memberof ManualRelabelCreate
     */
    'newTargetName'?: string;
    /**
     * List of labels user wants to use in the data operation. There are in addition to the already identified classes.
     * @type {Array<string>}
     * @memberof ManualRelabelCreate
     */
    'labels'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ManualRelabelingDetailsModel
 */
export interface ManualRelabelingDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof ManualRelabelingDetailsModel
     */
    'newTargetName': string;
    /**
     * 
     * @type {boolean}
     * @memberof ManualRelabelingDetailsModel
     */
    'recommendationsAvailable': boolean;
    /**
     * 
     * @type {string}
     * @memberof ManualRelabelingDetailsModel
     */
    'originalTarget': string;
    /**
     * 
     * @type {number}
     * @memberof ManualRelabelingDetailsModel
     */
    'numMislabeled': number;
    /**
     * 
     * @type {string}
     * @memberof ManualRelabelingDetailsModel
     */
    'derivedDatasetId'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ManualRelabelingState {
    NotStarted = 'NOT_STARTED',
    NotSupported = 'NOT_SUPPORTED',
    DataQualityNotAvailable = 'DATA_QUALITY_NOT_AVAILABLE',
    Prepared = 'PREPARED',
    Preparing = 'PREPARING',
    Failed = 'FAILED',
    Frozen = 'FROZEN',
    CreatingNewDatasetVersion = 'CREATING_NEW_DATASET_VERSION'
}

/**
 * 
 * @export
 * @interface MarkAllAsReadInAppNotificationsRequestModel
 */
export interface MarkAllAsReadInAppNotificationsRequestModel {
    /**
     * 
     * @type {string}
     * @memberof MarkAllAsReadInAppNotificationsRequestModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof MarkAllAsReadInAppNotificationsRequestModel
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface MarkovArtifactCount
 */
export interface MarkovArtifactCount {
    /**
     * 
     * @type {number}
     * @memberof MarkovArtifactCount
     */
    'demoArtifacts': number;
    /**
     * 
     * @type {number}
     * @memberof MarkovArtifactCount
     */
    'totalArtifacts': number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum MarkovArtifactMigrationStatus {
    NotAvailable = 'NOT_AVAILABLE',
    Loading = 'LOADING',
    Completed = 'COMPLETED',
    Error = 'ERROR'
}

/**
 * 
 * @export
 * @interface MarkovArtifactMigrationStatusResponse
 */
export interface MarkovArtifactMigrationStatusResponse {
    /**
     * 
     * @type {MarkovArtifactMigrationStatus}
     * @memberof MarkovArtifactMigrationStatusResponse
     */
    'status': MarkovArtifactMigrationStatus;
    /**
     * 
     * @type {string}
     * @memberof MarkovArtifactMigrationStatusResponse
     */
    'message'?: string;
}
/**
 * Artifact type which will be used for fetching demo/non demo artifact demo count
 * @export
 * @enum {string}
 */

export enum MarkovArtifacts {
    Projects = 'projects',
    Models = 'models',
    Evaluations = 'evaluations',
    Experiments = 'experiments',
    Datasets = 'datasets',
    Dataanalytics = 'dataanalytics'
}

/**
 * 
 * @export
 * @interface MarkovCreditsAvailabilityRequestModel
 */
export interface MarkovCreditsAvailabilityRequestModel {
    /**
     * 
     * @type {number}
     * @memberof MarkovCreditsAvailabilityRequestModel
     */
    'requiredCredits': number;
}
/**
 * 
 * @export
 * @interface MarkovCreditsAvailabilityResponseModel
 */
export interface MarkovCreditsAvailabilityResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof MarkovCreditsAvailabilityResponseModel
     */
    'hasSufficientCredits': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MarkovCreditsTransactionActionType {
    Debit = 'debit',
    Credit = 'credit',
    System = 'system'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum MarkovCreditsTransactionSourceType {
    WorkflowRunUsage = 'workflow_run_usage',
    DataAnalyticsConversationUsage = 'data_analytics_conversation_usage',
    StripeSubscriptionTrial = 'stripe_subscription_trial',
    StripeSubscriptionPurchase = 'stripe_subscription_purchase',
    StripeSubscriptionRenewal = 'stripe_subscription_renewal',
    CreditsExpired = 'credits_expired'
}

/**
 * 
 * @export
 * @interface MarkovCreditsUsageResponseModel
 */
export interface MarkovCreditsUsageResponseModel {
    /**
     * 
     * @type {string}
     * @memberof MarkovCreditsUsageResponseModel
     */
    'transactionSourceId': string;
    /**
     * 
     * @type {MarkovCreditsTransactionSourceType}
     * @memberof MarkovCreditsUsageResponseModel
     */
    'transactionSourceType': MarkovCreditsTransactionSourceType;
    /**
     * 
     * @type {number}
     * @memberof MarkovCreditsUsageResponseModel
     */
    'amount': number;
    /**
     * 
     * @type {MarkovCreditsTransactionActionType}
     * @memberof MarkovCreditsUsageResponseModel
     */
    'transactionType': MarkovCreditsTransactionActionType;
    /**
     * 
     * @type {string}
     * @memberof MarkovCreditsUsageResponseModel
     */
    'transactionId': string;
}
/**
 * 
 * @export
 * @interface Match
 */
export interface Match {
    /**
     * 
     * @type {Text}
     * @memberof Match
     */
    'text': Text;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    'html'?: string;
}
/**
 * 
 * @export
 * @interface MetricInfoTuple
 */
export interface MetricInfoTuple {
    /**
     * 
     * @type {number}
     * @memberof MetricInfoTuple
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof MetricInfoTuple
     */
    'info'?: string;
}
/**
 * 
 * @export
 * @interface Metrics
 */
export interface Metrics {
    /**
     * 
     * @type {number}
     * @memberof Metrics
     */
    'precision': number;
    /**
     * 
     * @type {number}
     * @memberof Metrics
     */
    'recall': number;
    /**
     * 
     * @type {number}
     * @memberof Metrics
     */
    'f1-score': number;
    /**
     * 
     * @type {number}
     * @memberof Metrics
     */
    'support': number;
    /**
     * 
     * @type {number}
     * @memberof Metrics
     */
    'accuracy'?: number;
}
/**
 * 
 * @export
 * @interface MigrateDemoArtifactSecretsRequest
 */
export interface MigrateDemoArtifactSecretsRequest {
    /**
     * 
     * @type {string}
     * @memberof MigrateDemoArtifactSecretsRequest
     */
    'fromWorkspace': string;
    /**
     * 
     * @type {string}
     * @memberof MigrateDemoArtifactSecretsRequest
     */
    'toWorkspace': string;
}
/**
 * 
 * @export
 * @interface MigrateDemoArtifactSecretsResponse
 */
export interface MigrateDemoArtifactSecretsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof MigrateDemoArtifactSecretsResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof MigrateDemoArtifactSecretsResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface MislabeledRecord
 */
export interface MislabeledRecord {
    /**
     * 
     * @type {string}
     * @memberof MislabeledRecord
     */
    'recordId': string;
    /**
     * 
     * @type {string}
     * @memberof MislabeledRecord
     */
    'rawData': string;
    /**
     * 
     * @type {string}
     * @memberof MislabeledRecord
     */
    'targetClass': string;
    /**
     * 
     * @type {string}
     * @memberof MislabeledRecord
     */
    'recommendedClass': string;
    /**
     * 
     * @type {number}
     * @memberof MislabeledRecord
     */
    'confidence': number;
    /**
     * 
     * @type {string}
     * @memberof MislabeledRecord
     */
    'resolvedClass': string;
    /**
     * 
     * @type {string}
     * @memberof MislabeledRecord
     */
    'segment': string;
    /**
     * 
     * @type {boolean}
     * @memberof MislabeledRecord
     */
    'isResolved': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MislabeledRecord
     */
    'isAssigned': boolean;
    /**
     * 
     * @type {string}
     * @memberof MislabeledRecord
     */
    'assignedTo': string;
    /**
     * 
     * @type {string}
     * @memberof MislabeledRecord
     */
    'resolvedBy': string;
    /**
     * 
     * @type {number}
     * @memberof MislabeledRecord
     */
    'region': number;
    /**
     * 
     * @type {number}
     * @memberof MislabeledRecord
     */
    'cluster': number;
}
/**
 * 
 * @export
 * @interface Model
 */
export interface Model {
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    'experimentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    'modelClass': string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    'projectId': string;
    /**
     * 
     * @type {ConceptDriftMonitorState}
     * @memberof Model
     */
    'conceptDriftMonitorState'?: ConceptDriftMonitorState;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    'updateDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    'registryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    'registryName'?: string;
    /**
     * 
     * @type {StageStates}
     * @memberof Model
     */
    'stage'?: StageStates;
    /**
     * 
     * @type {boolean}
     * @memberof Model
     */
    'isBaselineModel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Model
     */
    'artifactUploaded'?: boolean;
    /**
     * 
     * @type {ModelAppStatus}
     * @memberof Model
     */
    'appStatus'?: ModelAppStatus;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    'appName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Model
     */
    'demoArtifact'?: boolean;
}
/**
 * 
 * @export
 * @interface ModelApp
 */
export interface ModelApp {
    /**
     * 
     * @type {string}
     * @memberof ModelApp
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelApp
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelApp
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelApp
     */
    'modelAppId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelApp
     */
    'modelAppName': string;
    /**
     * 
     * @type {string}
     * @memberof ModelApp
     */
    'modelAppDescription': string;
    /**
     * 
     * @type {ModelAppClassKeys}
     * @memberof ModelApp
     */
    'modelAppType': ModelAppClassKeys;
    /**
     * 
     * @type {ModelAppStatusKeys}
     * @memberof ModelApp
     */
    'modelAppStatus': ModelAppStatusKeys;
    /**
     * 
     * @type {AppCategories}
     * @memberof ModelApp
     */
    'modelAppCategory': AppCategories;
    /**
     * 
     * @type {string}
     * @memberof ModelApp
     */
    'createDate': string;
    /**
     * 
     * @type {DataViewEssentials}
     * @memberof ModelApp
     */
    'dataView': DataViewEssentials;
    /**
     * 
     * @type {ModelAppWorkflow}
     * @memberof ModelApp
     */
    'workflow'?: ModelAppWorkflow;
    /**
     * 
     * @type {Array<ModelEssentials>}
     * @memberof ModelApp
     */
    'models'?: Array<ModelEssentials>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ModelAppClassKeys {
    Classification = 'classification'
}

/**
 * 
 * @export
 * @interface ModelAppInferenceRequest
 */
export interface ModelAppInferenceRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelAppInferenceRequest
     */
    'models': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelAppInferenceRequest
     */
    'inputs': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ModelAppInferenceRequest
     */
    'delimiter'?: string;
}
/**
 * 
 * @export
 * @interface ModelAppInfo
 */
export interface ModelAppInfo {
    /**
     * 
     * @type {string}
     * @memberof ModelAppInfo
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelAppInfo
     */
    'modelName': string;
    /**
     * 
     * @type {string}
     * @memberof ModelAppInfo
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface ModelAppInputRequest
 */
export interface ModelAppInputRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelAppInputRequest
     */
    'inputs': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ModelAppInputRequest
     */
    'delimiter'?: string;
}
/**
 * Lifecycle:  - Happy flow:      - NOT_CREATED -> INITIALIZED -> RUNNING -> STOPPED      - NOT_CREATED -> PENDING -> INITIALIZED -> RUNNING -> STOPPED (when baseline model is not trained)  - App rerun:      - STOPPED -> INITIALIZED  - App delete      - INITIALIZED -> NOT_CREATED      - RUNNING -> NOT_CREATED      - STOPPED -> NOT_CREATED
 * @export
 * @enum {string}
 */

export enum ModelAppStatus {
    NotCreated = 'NOT_CREATED',
    Initialized = 'INITIALIZED',
    Pending = 'PENDING',
    Running = 'RUNNING',
    Stopped = 'STOPPED'
}

/**
 * DRAFT represents the state where the GenAI app is waiting for user action. For example, if the user needs to select the models they need to evaluate, the GenAI app will be in DRAFT state.  PROCESSING represents the state where the GenAI app is in `workflow` state but awaiting some result from backend. For example, if the evaluation of selected models is in progress, the GenAI app will be in PROCESSING state  LIVE represents the state where the GenAI app is active and can take user input to perform the desired action.  STOPPED represents the state where the GenAI app was created but then stopped from taking user input.  Lifecycle:     - Happy flow:         - (DRAFT -> PROCESSING) -> (DRAFT -> PROCESSING) .... -> LIVE -> STOPPED     - App rerun:         - STOPPED -> LIVE
 * @export
 * @enum {string}
 */

export enum ModelAppStatusKeys {
    Live = 'live',
    Stopped = 'stopped',
    Draft = 'draft',
    Processing = 'processing',
    Failed = 'failed'
}

/**
 * 
 * @export
 * @interface ModelAppWorkflow
 */
export interface ModelAppWorkflow {
    /**
     * 
     * @type {ModelAppWorkflowStep}
     * @memberof ModelAppWorkflow
     */
    'currentStep': ModelAppWorkflowStep;
    /**
     * 
     * @type {ModelAppStatusKeys}
     * @memberof ModelAppWorkflow
     */
    'workflowStatus': ModelAppStatusKeys;
    /**
     * 
     * @type {string}
     * @memberof ModelAppWorkflow
     */
    'datasetSampleId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelAppWorkflow
     */
    'modelForEvaluation'?: Array<string>;
    /**
     * 
     * @type {Array<object>}
     * @memberof ModelAppWorkflow
     */
    'evaluationData'?: Array<object>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelAppWorkflow
     */
    'modelForApp'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ModelAppWorkflow
     */
    'evaluationStartTime'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ModelAppWorkflowStep {
    NotStarted = 'not_started',
    Sampling = 'sampling',
    ModelSelection = 'model_selection',
    Evaluation = 'evaluation',
    GetApp = 'get_app'
}

/**
 * Payload for notification when model app is in ready state.
 * @export
 * @interface ModelAppsReadyNotificationPayload
 */
export interface ModelAppsReadyNotificationPayload {
    /**
     * 
     * @type {UserInfo}
     * @memberof ModelAppsReadyNotificationPayload
     */
    'userTo': UserInfo;
    /**
     * 
     * @type {string}
     * @memberof ModelAppsReadyNotificationPayload
     */
    'workspaceId': string;
    /**
     * 
     * @type {UserInfo}
     * @memberof ModelAppsReadyNotificationPayload
     */
    'userFrom'?: UserInfo;
    /**
     * 
     * @type {ModelAppInfo}
     * @memberof ModelAppsReadyNotificationPayload
     */
    'modelAppInfo': ModelAppInfo;
    /**
     * 
     * @type {string}
     * @memberof ModelAppsReadyNotificationPayload
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAppsReadyNotificationPayload
     */
    'redirectionLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAppsReadyNotificationPayload
     */
    'title'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ModelArtifactColumnDataTypes {
    Bytes = 'BYTES',
    Bool = 'BOOL',
    Uint8 = 'UINT8',
    Uint16 = 'UINT16',
    Uint32 = 'UINT32',
    Uint64 = 'UINT64',
    Int8 = 'INT8',
    Int16 = 'INT16',
    Int32 = 'INT32',
    Int64 = 'INT64',
    Fp16 = 'FP16',
    Fp32 = 'FP32',
    Fp64 = 'FP64'
}

/**
 * 
 * @export
 * @interface ModelArtifactColumnSchemaResponse
 */
export interface ModelArtifactColumnSchemaResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelArtifactColumnSchemaResponse
     */
    'featureName': string;
    /**
     * 
     * @type {ModelArtifactColumnDataTypes}
     * @memberof ModelArtifactColumnSchemaResponse
     */
    'featureType'?: ModelArtifactColumnDataTypes;
}
/**
 * 
 * @export
 * @interface ModelConfigurations
 */
export interface ModelConfigurations {
    /**
     * 
     * @type {string}
     * @memberof ModelConfigurations
     */
    'configName': string;
    /**
     * 
     * @type {string}
     * @memberof ModelConfigurations
     */
    'configDescription': string;
    /**
     * 
     * @type {number}
     * @memberof ModelConfigurations
     */
    'configValue': number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ModelDriftStatus {
    NotStarted = 'NOT_STARTED',
    Running = 'RUNNING',
    NoDrift = 'NO_DRIFT',
    Warning = 'WARNING',
    Drift = 'DRIFT',
    Failure = 'FAILURE'
}

/**
 * 
 * @export
 * @interface ModelEditableFields
 */
export interface ModelEditableFields {
    /**
     * 
     * @type {string}
     * @memberof ModelEditableFields
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelEditableFields
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ModelEssentials
 */
export interface ModelEssentials {
    /**
     * 
     * @type {string}
     * @memberof ModelEssentials
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelEssentials
     */
    'modelName': string;
    /**
     * 
     * @type {ModelTypeKeys}
     * @memberof ModelEssentials
     */
    'modelType'?: ModelTypeKeys;
    /**
     * 
     * @type {object}
     * @memberof ModelEssentials
     */
    'evaluationData'?: object;
    /**
     * 
     * @type {string}
     * @memberof ModelEssentials
     */
    'credId'?: string;
}
/**
 * 
 * @export
 * @interface ModelInferenceRecord
 */
export interface ModelInferenceRecord {
    /**
     * 
     * @type {any}
     * @memberof ModelInferenceRecord
     */
    'inferred'?: any;
    /**
     * 
     * @type {any}
     * @memberof ModelInferenceRecord
     */
    'actual'?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelInferenceRecord
     */
    'recordingId': string;
    /**
     * 
     * @type {number}
     * @memberof ModelInferenceRecord
     */
    'score'?: number;
    /**
     * 
     * @type {Array<RecordMetaData>}
     * @memberof ModelInferenceRecord
     */
    'metaData': Array<RecordMetaData>;
    /**
     * 
     * @type {Array<RecordCustomMetric>}
     * @memberof ModelInferenceRecord
     */
    'customMetrics'?: Array<RecordCustomMetric>;
    /**
     * 
     * @type {string}
     * @memberof ModelInferenceRecord
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof ModelInferenceRecord
     */
    'urid': string;
    /**
     * 
     * @type {string}
     * @memberof ModelInferenceRecord
     */
    'recordId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelInferenceRecord
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInferenceRecord
     */
    'teamId'?: string;
}
/**
 * 
 * @export
 * @interface ModelInferenceRequest
 */
export interface ModelInferenceRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelInferenceRequest
     */
    'inputs': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ModelInferenceRequest
     */
    'delimiter'?: string;
}
/**
 * Model Inference Status: Represents the status of the inference done using a model
 * @export
 * @enum {string}
 */

export enum ModelInferenceStatus {
    Pending = 'PENDING',
    Running = 'RUNNING',
    Success = 'SUCCESS',
    Failure = 'FAILURE',
    NotFound = 'NOT_FOUND'
}

/**
 * Model Recording details object for Activity/Search Results
 * @export
 * @interface ModelInfo
 */
export interface ModelInfo {
    /**
     * 
     * @type {string}
     * @memberof ModelInfo
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ModelInfo
     */
    'experimentId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelInfo
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelInfo
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ModelInfo
     */
    'createDate': string;
}
/**
 * 
 * @export
 * @interface ModelMonitoringCheckDrift
 */
export interface ModelMonitoringCheckDrift {
    /**
     * 
     * @type {ModelDriftStatus}
     * @memberof ModelMonitoringCheckDrift
     */
    'drift': ModelDriftStatus;
}
/**
 * 
 * @export
 * @interface ModelMonitoringConceptDriftResponse
 */
export interface ModelMonitoringConceptDriftResponse {
    /**
     * 
     * @type {ChartMetadata}
     * @memberof ModelMonitoringConceptDriftResponse
     */
    'metadata': ChartMetadata;
    /**
     * 
     * @type {GenericChartDataModel}
     * @memberof ModelMonitoringConceptDriftResponse
     */
    'data': GenericChartDataModel;
}
/**
 * 
 * @export
 * @interface ModelNameInfo
 */
export interface ModelNameInfo {
    /**
     * 
     * @type {string}
     * @memberof ModelNameInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ModelNameInfo
     */
    'modelId': string;
}
/**
 * 
 * @export
 * @interface ModelPerformanceData
 */
export interface ModelPerformanceData {
    /**
     * 
     * @type {Array<ClassWisePerformance> | Array<any>}
     * @memberof ModelPerformanceData
     */
    'data': Array<ClassWisePerformance> | Array<any>;
}
/**
 * 
 * @export
 * @interface ModelProjection
 */
export interface ModelProjection {
    /**
     * 
     * @type {string}
     * @memberof ModelProjection
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProjection
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProjection
     */
    'teamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProjection
     */
    'modelId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProjection
     */
    'experimentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProjection
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProjection
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProjection
     */
    'modelClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProjection
     */
    'projectId'?: string;
    /**
     * 
     * @type {ConceptDriftMonitorState}
     * @memberof ModelProjection
     */
    'conceptDriftMonitorState'?: ConceptDriftMonitorState;
    /**
     * 
     * @type {string}
     * @memberof ModelProjection
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProjection
     */
    'updateDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProjection
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProjection
     */
    'registryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProjection
     */
    'registryName'?: string;
    /**
     * 
     * @type {StageStates}
     * @memberof ModelProjection
     */
    'stage'?: StageStates;
    /**
     * 
     * @type {boolean}
     * @memberof ModelProjection
     */
    'isBaselineModel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelProjection
     */
    'artifactUploaded'?: boolean;
    /**
     * 
     * @type {ModelAppStatus}
     * @memberof ModelProjection
     */
    'appStatus'?: ModelAppStatus;
    /**
     * 
     * @type {string}
     * @memberof ModelProjection
     */
    'appName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelProjection
     */
    'demoArtifact'?: boolean;
}
/**
 * 
 * @export
 * @interface ModelProperties
 */
export interface ModelProperties {
    /**
     * 
     * @type {string}
     * @memberof ModelProperties
     */
    'type'?: ModelPropertiesTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ModelProperties
     */
    'name'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ModelPropertiesTypeEnum {
    Model = 'MODEL'
}

/**
 * Paginated list of model recordings for get model recordings api response
 * @export
 * @interface ModelRecordingSearchResults
 */
export interface ModelRecordingSearchResults {
    /**
     * 
     * @type {Array<EvaluationRecordingInfo>}
     * @memberof ModelRecordingSearchResults
     */
    'results'?: Array<EvaluationRecordingInfo>;
    /**
     * 
     * @type {number}
     * @memberof ModelRecordingSearchResults
     */
    'numRecords'?: number;
}
/**
 * 
 * @export
 * @interface ModelRegistry
 */
export interface ModelRegistry {
    /**
     * 
     * @type {string}
     * @memberof ModelRegistry
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ModelRegistry
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof ModelRegistry
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof ModelRegistry
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelRegistry
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ModelRegistry
     */
    'registryId': string;
    /**
     * 
     * @type {number}
     * @memberof ModelRegistry
     */
    'latestVersion': number;
    /**
     * 
     * @type {string}
     * @memberof ModelRegistry
     */
    'numModelsLinked'?: string;
    /**
     * 
     * @type {UserDetails}
     * @memberof ModelRegistry
     */
    'userDetails'?: UserDetails;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelRegistry
     */
    'deployedStages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ModelRegistry1
 */
export interface ModelRegistry1 {
    /**
     * 
     * @type {string}
     * @memberof ModelRegistry1
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ModelRegistry1
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof ModelRegistry1
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof ModelRegistry1
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelRegistry1
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ModelRegistry1
     */
    'registryId': string;
    /**
     * 
     * @type {number}
     * @memberof ModelRegistry1
     */
    'latestVersion': number;
    /**
     * 
     * @type {string}
     * @memberof ModelRegistry1
     */
    'recordId': string;
    /**
     * 
     * @type {number}
     * @memberof ModelRegistry1
     */
    'numModelsLinked'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelRegistry1
     */
    'deployedStages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ModelRegistryListResponse
 */
export interface ModelRegistryListResponse {
    /**
     * 
     * @type {Array<ModelRegistry>}
     * @memberof ModelRegistryListResponse
     */
    'response': Array<ModelRegistry>;
    /**
     * 
     * @type {number}
     * @memberof ModelRegistryListResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ModelRegistryListResponse
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelRegistryListResponse
     */
    'pageCount'?: number;
}
/**
 * 
 * @export
 * @interface ModelRegistryPaginatedResponse
 */
export interface ModelRegistryPaginatedResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelRegistryPaginatedResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<ModelRegistry1>}
     * @memberof ModelRegistryPaginatedResponse
     */
    'response': Array<ModelRegistry1>;
    /**
     * 
     * @type {number}
     * @memberof ModelRegistryPaginatedResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof ModelRegistryPaginatedResponse
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface ModelRegistryResponse
 */
export interface ModelRegistryResponse {
    /**
     * 
     * @type {ModelRegistry1}
     * @memberof ModelRegistryResponse
     */
    'response': ModelRegistry1;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ModelSource {
    Huggingface = 'HUGGINGFACE',
    Markov = 'MARKOV',
    S3 = 'S3'
}

/**
 * 
 * @export
 * @interface ModelTransitionHistoryListResponse
 */
export interface ModelTransitionHistoryListResponse {
    /**
     * 
     * @type {Array<TransitionHistory>}
     * @memberof ModelTransitionHistoryListResponse
     */
    'response': Array<TransitionHistory>;
    /**
     * 
     * @type {number}
     * @memberof ModelTransitionHistoryListResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ModelTransitionHistoryListResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {number}
     * @memberof ModelTransitionHistoryListResponse
     */
    'pageCount': number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ModelTransitionHistoryType {
    Link = 'link',
    Unlink = 'unlink',
    Transition = 'transition'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ModelTypeKeys {
    Classical = 'classical',
    Openai = 'OPENAI',
    Cohere = 'cohere'
}

/**
 * 
 * @export
 * @interface ModelWithAppMetadata
 */
export interface ModelWithAppMetadata {
    /**
     * 
     * @type {string}
     * @memberof ModelWithAppMetadata
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithAppMetadata
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithAppMetadata
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithAppMetadata
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithAppMetadata
     */
    'experimentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithAppMetadata
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithAppMetadata
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithAppMetadata
     */
    'modelAppLaunchDate': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithAppMetadata
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithAppMetadata
     */
    'modelClass': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithAppMetadata
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithAppMetadata
     */
    'datasetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithAppMetadata
     */
    'updateDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelWithAppMetadata
     */
    'isBaselineModel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelWithAppMetadata
     */
    'modelAppName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelWithAppMetadata
     */
    'durationInMins'?: number;
    /**
     * 
     * @type {ModelAppStatus}
     * @memberof ModelWithAppMetadata
     */
    'modelAppStatus'?: ModelAppStatus;
    /**
     * 
     * @type {string}
     * @memberof ModelWithAppMetadata
     */
    'modelAppEndTime'?: string;
    /**
     * 
     * @type {Array<ModelArtifactColumnSchemaResponse>}
     * @memberof ModelWithAppMetadata
     */
    'modelInputSchema'?: Array<ModelArtifactColumnSchemaResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelWithAppMetadata
     */
    'modelInputSamples'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ModelWithAppMetadata
     */
    'demoArtifact'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ModelWithAppMetadata
     */
    'info'?: object;
}
/**
 * 
 * @export
 * @interface ModelWithTags
 */
export interface ModelWithTags {
    /**
     * 
     * @type {string}
     * @memberof ModelWithTags
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithTags
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithTags
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithTags
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithTags
     */
    'experimentId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithTags
     */
    'name': string;
    /**
     * 
     * @type {any}
     * @memberof ModelWithTags
     */
    'description': any;
    /**
     * 
     * @type {string}
     * @memberof ModelWithTags
     */
    'modelClass': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithTags
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithTags
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof ModelWithTags
     */
    'updateDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelWithTags
     */
    'demoArtifact': boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelWithTags
     */
    'registryId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelWithTags
     */
    'artifactUploaded'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelWithTags
     */
    'appName'?: string;
    /**
     * 
     * @type {ModelAppStatus}
     * @memberof ModelWithTags
     */
    'appStatus'?: ModelAppStatus;
    /**
     * 
     * @type {Array<TagMetadata>}
     * @memberof ModelWithTags
     */
    'tags': Array<TagMetadata>;
    /**
     * 
     * @type {ConceptDriftMonitorState}
     * @memberof ModelWithTags
     */
    'conceptDriftMonitorState': ConceptDriftMonitorState;
}
/**
 * 
 * @export
 * @interface MultiModelComparisonData
 */
export interface MultiModelComparisonData {
    /**
     * 
     * @type {Array<string>}
     * @memberof MultiModelComparisonData
     */
    'models': Array<string>;
    /**
     * 
     * @type {Array<ModelPerformanceData>}
     * @memberof MultiModelComparisonData
     */
    'series': Array<ModelPerformanceData>;
}
/**
 * 
 * @export
 * @interface MultiModelComparisonResponse
 */
export interface MultiModelComparisonResponse {
    /**
     * 
     * @type {MultiModelComparisonData}
     * @memberof MultiModelComparisonResponse
     */
    'response': MultiModelComparisonData;
    /**
     * 
     * @type {string}
     * @memberof MultiModelComparisonResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface MultiModelParsedInferenceResponse
 */
export interface MultiModelParsedInferenceResponse {
    /**
     * 
     * @type {Array<ParsedInferenceResponse>}
     * @memberof MultiModelParsedInferenceResponse
     */
    'response': Array<ParsedInferenceResponse>;
    /**
     * 
     * @type {string}
     * @memberof MultiModelParsedInferenceResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface MultipleEvaluationsClassificationMetricsAllClasses
 */
export interface MultipleEvaluationsClassificationMetricsAllClasses {
    /**
     * 
     * @type {Array<ClassificationMetricsInfo>}
     * @memberof MultipleEvaluationsClassificationMetricsAllClasses
     */
    'metricsInfo': Array<ClassificationMetricsInfo>;
    /**
     * 
     * @type {Array<MultipleEvaluationsClassificationMetricsPerClass>}
     * @memberof MultipleEvaluationsClassificationMetricsAllClasses
     */
    'metricsPerClass': Array<MultipleEvaluationsClassificationMetricsPerClass>;
}
/**
 * 
 * @export
 * @interface MultipleEvaluationsClassificationMetricsPerClass
 */
export interface MultipleEvaluationsClassificationMetricsPerClass {
    /**
     * 
     * @type {string}
     * @memberof MultipleEvaluationsClassificationMetricsPerClass
     */
    'className': string;
    /**
     * 
     * @type {Array<EvaluationClassificationClassLevelMetrics>}
     * @memberof MultipleEvaluationsClassificationMetricsPerClass
     */
    'classLevelMetrics': Array<EvaluationClassificationClassLevelMetrics>;
}
/**
 * 
 * @export
 * @interface MultipleExperimentsSummaryResponse
 */
export interface MultipleExperimentsSummaryResponse {
    /**
     * 
     * @type {Array<ExperimentSummaryResponse>}
     * @memberof MultipleExperimentsSummaryResponse
     */
    'response': Array<ExperimentSummaryResponse>;
    /**
     * 
     * @type {string}
     * @memberof MultipleExperimentsSummaryResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'id': string;
    /**
     * 
     * @type {UINodeData}
     * @memberof Node
     */
    'uiData': UINodeData;
    /**
     * 
     * @type {NodeData}
     * @memberof Node
     */
    'data': NodeData;
}
/**
 * This stores information of a node; operator_id, and it\'s user selected config
 * @export
 * @interface NodeData
 */
export interface NodeData {
    /**
     * 
     * @type {string}
     * @memberof NodeData
     */
    'id': string;
    /**
     * 
     * @type {object}
     * @memberof NodeData
     */
    'configuration': object;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum NodeIOType {
    Dataframe = 'DATAFRAME',
    Model = 'MODEL',
    Embeddings = 'EMBEDDINGS'
}

/**
 * 
 * @export
 * @interface NodeOutput
 */
export interface NodeOutput {
    /**
     * 
     * @type {FilePreview}
     * @memberof NodeOutput
     */
    'data': FilePreview;
}
/**
 * 
 * @export
 * @interface NodeSchema
 */
export interface NodeSchema {
    /**
     * 
     * @type {string}
     * @memberof NodeSchema
     */
    'nodeId': string;
    /**
     * 
     * @type {{ [key: string]: Array<IOSchema>; }}
     * @memberof NodeSchema
     */
    'inputs'?: { [key: string]: Array<IOSchema>; };
    /**
     * 
     * @type {Array<IOSchema>}
     * @memberof NodeSchema
     */
    'outputs'?: Array<IOSchema>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NodeSchema
     */
    'errors'?: Array<string>;
    /**
     * 
     * @type {Array<ErrorObject>}
     * @memberof NodeSchema
     */
    'errorsWithCode'?: Array<ErrorObject>;
}
/**
 * 
 * @export
 * @interface NotificationACKResponse
 */
export interface NotificationACKResponse {
    /**
     * 
     * @type {number}
     * @memberof NotificationACKResponse
     */
    'statusCode': number;
    /**
     * 
     * @type {object}
     * @memberof NotificationACKResponse
     */
    'ackResponse'?: object;
    /**
     * 
     * @type {string}
     * @memberof NotificationACKResponse
     */
    'errorMessage'?: string;
}
/**
 * 
 * @export
 * @interface NotificationDetails
 */
export interface NotificationDetails {
    /**
     * 
     * @type {{ [key: string]: NotificationRequestDetails; }}
     * @memberof NotificationDetails
     */
    'notificationRequest': { [key: string]: NotificationRequestDetails; };
    /**
     * 
     * @type {{ [key: string]: NotificationResponseDetails; }}
     * @memberof NotificationDetails
     */
    'notificationResponse': { [key: string]: NotificationResponseDetails; };
}
/**
 * 
 * @export
 * @interface NotificationIdList
 */
export interface NotificationIdList {
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationIdList
     */
    'notificationIds': Array<string>;
}
/**
 * 
 * @export
 * @interface NotificationRequestDetails
 */
export interface NotificationRequestDetails {
    /**
     * 
     * @type {string}
     * @memberof NotificationRequestDetails
     */
    'originOfService': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRequestDetails
     */
    'notificationRecordId': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRequestDetails
     */
    'notificationType': string;
    /**
     * 
     * @type {object}
     * @memberof NotificationRequestDetails
     */
    'payload'?: object;
    /**
     * 
     * @type {string}
     * @memberof NotificationRequestDetails
     */
    'dbStage': string;
    /**
     * 
     * @type {any}
     * @memberof NotificationRequestDetails
     */
    'notificationPreferences'?: any;
    /**
     * 
     * @type {string}
     * @memberof NotificationRequestDetails
     */
    'createDate': string;
}
/**
 * 
 * @export
 * @interface NotificationResponseDetails
 */
export interface NotificationResponseDetails {
    /**
     * 
     * @type {string}
     * @memberof NotificationResponseDetails
     */
    'notifierType': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponseDetails
     */
    'notificationRecordId': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof NotificationResponseDetails
     */
    'ackResponse': { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof NotificationResponseDetails
     */
    'statusCode': number;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponseDetails
     */
    'message': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum NotificationResponseStatus {
    Created = 'CREATED',
    Success = 'SUCCESS',
    Failed = 'FAILED'
}

/**
 * Verifier that Paris supports the notification type of the payload sent.
 * @export
 * @enum {string}
 */

export enum NotificationTypes {
    InviteEmail = 'invite-email',
    SnippetCommentNotification = 'snippet_comment_notification',
    SnippetInviteEmail = 'snippet_invite_email',
    AnalyzersCompletionResultNotification = 'analyzers_completion_result_notification',
    ZurichAlertEmail = 'zurich-alert-email',
    ZurichAlert = 'zurich-alert',
    SnippetContentUserMentionNotification = 'snippet_content_user_mention_notification',
    SnippetCommentUserMentionNotification = 'snippet_comment_user_mention_notification',
    ModelAppsReadyNotification = 'model_apps_ready_notification'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum NotifierType {
    Slack = 'slack',
    Email = 'email',
    InApp = 'in_app'
}

/**
 * 
 * @export
 * @interface NotifyRequestModel
 */
export interface NotifyRequestModel {
    /**
     * 
     * @type {string}
     * @memberof NotifyRequestModel
     */
    'origin_of_service': string;
    /**
     * 
     * @type {string}
     * @memberof NotifyRequestModel
     */
    'notification_type': string;
    /**
     * 
     * @type {object}
     * @memberof NotifyRequestModel
     */
    'payload'?: object;
}
/**
 * 
 * @export
 * @interface NotifyResponseModel
 */
export interface NotifyResponseModel {
    /**
     * 
     * @type {string}
     * @memberof NotifyResponseModel
     */
    'origin_of_service': string;
    /**
     * 
     * @type {string}
     * @memberof NotifyResponseModel
     */
    'notification_record_id': string;
    /**
     * 
     * @type {string}
     * @memberof NotifyResponseModel
     */
    'notification_type': string;
    /**
     * 
     * @type {object}
     * @memberof NotifyResponseModel
     */
    'payload'?: object;
    /**
     * 
     * @type {string}
     * @memberof NotifyResponseModel
     */
    'db_stage': string;
    /**
     * 
     * @type {any}
     * @memberof NotifyResponseModel
     */
    'notification_preferences'?: any;
    /**
     * 
     * @type {string}
     * @memberof NotifyResponseModel
     */
    'create_date': string;
}
/**
 * 
 * @export
 * @interface NotionResourceMetadataModel
 */
export interface NotionResourceMetadataModel {
    /**
     * 
     * @type {string}
     * @memberof NotionResourceMetadataModel
     */
    'connectorType'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotionResourceMetadataModel
     */
    'pageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotionResourceMetadataModel
     */
    'pageTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotionResourceMetadataModel
     */
    'pageUrl'?: string;
}
/**
 * 
 * @export
 * @interface OauthCredentialModel
 */
export interface OauthCredentialModel {
    /**
     * 
     * @type {string}
     * @memberof OauthCredentialModel
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {number}
     * @memberof OauthCredentialModel
     */
    'expiryDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof OauthCredentialModel
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof OauthCredentialModel
     */
    'tokenType'?: string;
    /**
     * 
     * @type {string}
     * @memberof OauthCredentialModel
     */
    'idToken'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OauthCredentialModel
     */
    'scope'?: Array<string>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum Operator {
    In = 'IN',
    NotIn = 'NOT_IN',
    Lte = 'LTE',
    Gte = 'GTE',
    Eq = 'EQ',
    NotEq = 'NOT_EQ',
    Regex = 'REGEX'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum OperatorCategory {
    Source = 'Source',
    Process = 'Process',
    Sink = 'Sink'
}

/**
 * 
 * @export
 * @interface OperatorCreditConsumed
 */
export interface OperatorCreditConsumed {
    /**
     * 
     * @type {string}
     * @memberof OperatorCreditConsumed
     */
    'operatorId': string;
    /**
     * 
     * @type {string}
     * @memberof OperatorCreditConsumed
     */
    'nodeId': string;
    /**
     * 
     * @type {number}
     * @memberof OperatorCreditConsumed
     */
    'creditsConsumed': number;
}
/**
 * 
 * @export
 * @interface OperatorIODescription
 */
export interface OperatorIODescription {
    /**
     * 
     * @type {string}
     * @memberof OperatorIODescription
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OperatorIODescription
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OperatorIODescription
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof OperatorIODescription
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface OperatorListResponse
 */
export interface OperatorListResponse {
    /**
     * 
     * @type {Array<OperatorModel>}
     * @memberof OperatorListResponse
     */
    'operators': Array<OperatorModel>;
    /**
     * 
     * @type {number}
     * @memberof OperatorListResponse
     */
    'count': number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum OperatorMembershipScope {
    Free = 'Free',
    Solo = 'Solo',
    Team = 'Team',
    Enterprise = 'Enterprise'
}

/**
 * Represents an operator in the Workflow Engine.  Attributes: - id (str): Unique ID for the operator. - schema_version (str): Schema Version, specifying how to parse the rest of the JSON. - name (str): Display Name of the operator. - description (str): A textual description of what the operator does. - input (dict): Input Type for the operator.     - type (str): Type of input (e.g., \"DataFrame\"). - output (dict): Output Type for the operator.     - type (str): Type of output (e.g., \"Model\"). -example (str): Example of input output for the operator. - parameters (List[Any]): List of parameters required for rendering the operator, this have type     Each parameter usually has the following structure:     - name (str): The name of the parameter for internal purposes.     - label (str): A user-friendly name for the parameter.     - type (str): The data type of the parameter.Based on this type, the optional attributes are defined.     - required (Optional[bool]): Whether the parameter is required.     - default (Optional[Any]): The default value for the parameter (if applicable).     - items (Optional[dict]): Additional information for parameters with types like array,list
 * @export
 * @interface OperatorModel
 */
export interface OperatorModel {
    /**
     * 
     * @type {string}
     * @memberof OperatorModel
     */
    'operatorId': string;
    /**
     * 
     * @type {string}
     * @memberof OperatorModel
     */
    'schemaVersion': string;
    /**
     * 
     * @type {OperatorCategory}
     * @memberof OperatorModel
     */
    'category': OperatorCategory;
    /**
     * 
     * @type {string}
     * @memberof OperatorModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OperatorModel
     */
    'description': string;
    /**
     * 
     * @type {Array<OperatorIODescription>}
     * @memberof OperatorModel
     */
    'inputs': Array<OperatorIODescription>;
    /**
     * 
     * @type {Array<OperatorIODescription>}
     * @memberof OperatorModel
     */
    'outputs': Array<OperatorIODescription>;
    /**
     * 
     * @type {string}
     * @memberof OperatorModel
     */
    'example': string;
    /**
     * 
     * @type {string}
     * @memberof OperatorModel
     */
    'iconUrl': string;
    /**
     * 
     * @type {object}
     * @memberof OperatorModel
     */
    'parameters': object;
    /**
     * 
     * @type {Array<any>}
     * @memberof OperatorModel
     */
    'tags'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof OperatorModel
     */
    'creditRateString'?: string;
    /**
     * 
     * @type {OperatorMembershipScope}
     * @memberof OperatorModel
     */
    'membershipScope'?: OperatorMembershipScope;
}
/**
 * 
 * @export
 * @interface OperatorStatusDetails
 */
export interface OperatorStatusDetails {
    /**
     * 
     * @type {string}
     * @memberof OperatorStatusDetails
     */
    'operatorId': string;
    /**
     * 
     * @type {WorkflowRunOperatorStatus}
     * @memberof OperatorStatusDetails
     */
    'status': WorkflowRunOperatorStatus;
}
/**
 * 
 * @export
 * @interface OrganizationInfoModel
 */
export interface OrganizationInfoModel {
    /**
     * 
     * @type {string}
     * @memberof OrganizationInfoModel
     */
    'authorType': OrganizationInfoModelAuthorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInfoModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInfoModel
     */
    'screenName'?: string;
    /**
     * Role associated with the organization.
     * @type {AuthorRoleType}
     * @memberof OrganizationInfoModel
     */
    'role'?: AuthorRoleType;
}

/**
    * @export
    * @enum {string}
    */
export enum OrganizationInfoModelAuthorTypeEnum {
    Organization = 'organization'
}

/**
 * 
 * @export
 * @interface OthersInformationModel
 */
export interface OthersInformationModel {
    /**
     * 
     * @type {string}
     * @memberof OthersInformationModel
     */
    'citationType': OthersInformationModelCitationTypeEnum;
    /**
     * Title of the reference
     * @type {string}
     * @memberof OthersInformationModel
     */
    'citationTitle': string;
    /**
     * 
     * @type {DateModel}
     * @memberof OthersInformationModel
     */
    'publishedDate'?: DateModel;
    /**
     * List of authors for the citation.
     * @type {Array<AuthorInformationModel>}
     * @memberof OthersInformationModel
     */
    'authors': Array<AuthorInformationModel>;
    /**
     * 
     * @type {string}
     * @memberof OthersInformationModel
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof OthersInformationModel
     */
    'resourceName'?: string;
    /**
     * 
     * @type {DateModel}
     * @memberof OthersInformationModel
     */
    'accessedDate'?: DateModel;
}

/**
    * @export
    * @enum {string}
    */
export enum OthersInformationModelCitationTypeEnum {
    Others = 'others'
}

/**
 * Page response
 * @export
 * @interface PageResponse
 */
export interface PageResponse {
    /**
     * 
     * @type {number}
     * @memberof PageResponse
     */
    'pageId': number;
    /**
     * 
     * @type {PageStatusType}
     * @memberof PageResponse
     */
    'status': PageStatusType;
    /**
     * 
     * @type {string}
     * @memberof PageResponse
     */
    'formattedPageContent': string;
}
/**
 * Page level status whether the whole page (all the segments in the page) has been successfully analyzed
 * @export
 * @enum {string}
 */

export enum PageStatusType {
    Parsed = 'PARSED',
    Analysing = 'ANALYSING',
    Analysed = 'ANALYSED',
    AnalysisFailed = 'ANALYSIS_FAILED',
    Failed = 'FAILED',
    ReAnalyse = 'RE_ANALYSE'
}

/**
 * Suggestions for the page
 * @export
 * @interface PageSuggestion
 */
export interface PageSuggestion {
    /**
     * 
     * @type {string}
     * @memberof PageSuggestion
     */
    'text': string;
    /**
     * 
     * @type {Suggestion}
     * @memberof PageSuggestion
     */
    'suggestion'?: Suggestion;
}
/**
 * Suggestions for the page
 * @export
 * @interface PageSuggestions
 */
export interface PageSuggestions {
    /**
     * 
     * @type {Array<PageSuggestion>}
     * @memberof PageSuggestions
     */
    'suggestions'?: Array<PageSuggestion>;
}
/**
 * 
 * @export
 * @interface PageTextResources
 */
export interface PageTextResources {
    /**
     * 
     * @type {Array<ParagraphTextResource>}
     * @memberof PageTextResources
     */
    'pageTextResources': Array<ParagraphTextResource>;
}
/**
 * 
 * @export
 * @interface PaginatedDisagreements
 */
export interface PaginatedDisagreements {
    /**
     * 
     * @type {string}
     * @memberof PaginatedDisagreements
     */
    'modelId': string;
    /**
     * 
     * @type {Array<ModelInferenceRecord>}
     * @memberof PaginatedDisagreements
     */
    'disagreements': Array<ModelInferenceRecord>;
    /**
     * 
     * @type {string}
     * @memberof PaginatedDisagreements
     */
    'recordingId': string;
    /**
     * 
     * @type {number}
     * @memberof PaginatedDisagreements
     */
    'start': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedDisagreements
     */
    'end': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedDisagreements
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedDisagreements
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface PaginatedRecords
 */
export interface PaginatedRecords {
    /**
     * 
     * @type {number}
     * @memberof PaginatedRecords
     */
    'numRecords': number;
    /**
     * 
     * @type {EvaluationDetails}
     * @memberof PaginatedRecords
     */
    'details': EvaluationDetails;
    /**
     * 
     * @type {number}
     * @memberof PaginatedRecords
     */
    'start': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedRecords
     */
    'end': number;
    /**
     * 
     * @type {Array<ModelInferenceRecord>}
     * @memberof PaginatedRecords
     */
    'records': Array<ModelInferenceRecord>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ParagraphActionType {
    Insert = 'INSERT',
    Delete = 'DELETE',
    Update = 'UPDATE'
}

/**
 * 
 * @export
 * @interface ParagraphTextResource
 */
export interface ParagraphTextResource {
    /**
     * 
     * @type {string}
     * @memberof ParagraphTextResource
     */
    'paraId': string;
    /**
     * 
     * @type {string}
     * @memberof ParagraphTextResource
     */
    'paraText': string;
    /**
     * 
     * @type {Array<StyleGuideContextType>}
     * @memberof ParagraphTextResource
     */
    'styleGuideContextType'?: Array<StyleGuideContextType>;
}
/**
 * 
 * @export
 * @interface ParagraphTextSuggestions
 */
export interface ParagraphTextSuggestions {
    /**
     * 
     * @type {string}
     * @memberof ParagraphTextSuggestions
     */
    'workspaceId': string;
    /**
     * 
     * @type {number}
     * @memberof ParagraphTextSuggestions
     */
    'pageId': number;
    /**
     * 
     * @type {string}
     * @memberof ParagraphTextSuggestions
     */
    'paragraphId': string;
    /**
     * 
     * @type {PageSuggestions}
     * @memberof ParagraphTextSuggestions
     */
    'pageSuggestions': PageSuggestions;
    /**
     * 
     * @type {SuggestionAnalysisStatus}
     * @memberof ParagraphTextSuggestions
     */
    'status'?: SuggestionAnalysisStatus;
}
/**
 * 
 * @export
 * @interface ParsedChunk
 */
export interface ParsedChunk {
    /**
     * 
     * @type {string}
     * @memberof ParsedChunk
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface ParsedInferenceResponse
 */
export interface ParsedInferenceResponse {
    /**
     * 
     * @type {string}
     * @memberof ParsedInferenceResponse
     */
    'modelName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParsedInferenceResponse
     */
    'id'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof ParsedInferenceResponse
     */
    'outputs'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof ParsedInferenceResponse
     */
    'status': string;
}
/**
 * Refer to the status types meaning here: - https://docs.stripe.com/api/subscriptions/object - https://mrcoles.com/stripe-api-subscription-status
 * @export
 * @enum {string}
 */

export enum PaymentSubscriptionStatusType {
    Active = 'active',
    All = 'all',
    Canceled = 'canceled',
    Ended = 'ended',
    Incomplete = 'incomplete',
    IncompleteExpired = 'incomplete_expired',
    PastDue = 'past_due',
    Paused = 'paused',
    Trialing = 'trialing',
    Unpaid = 'unpaid'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum PaymentSubscriptionType {
    FreemiumMonthly = 'freemium_monthly',
    SoloMonthly99 = 'solo_monthly_99',
    SoloAnnually948 = 'solo_annually_948',
    TeamMonthly599 = 'team_monthly_599',
    TeamAnnually5988 = 'team_annually_5988',
    EnterprisePlan = 'enterprise_plan'
}

/**
 * List of invites which are in pending state (user has not yet accepted the invitation)
 * @export
 * @interface PendingUserInvitesResponse
 */
export interface PendingUserInvitesResponse {
    /**
     * 
     * @type {Array<WorkspaceInvitationDetails>}
     * @memberof PendingUserInvitesResponse
     */
    'invitations': Array<WorkspaceInvitationDetails>;
}
/**
 * 
 * @export
 * @interface PersonInfoModel
 */
export interface PersonInfoModel {
    /**
     * 
     * @type {string}
     * @memberof PersonInfoModel
     */
    'authorType': PersonInfoModelAuthorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonInfoModel
     */
    'initials'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonInfoModel
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonInfoModel
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonInfoModel
     */
    'screenName'?: string;
    /**
     * Role associated with the author.
     * @type {AuthorRoleType}
     * @memberof PersonInfoModel
     */
    'role'?: AuthorRoleType;
}

/**
    * @export
    * @enum {string}
    */
export enum PersonInfoModelAuthorTypeEnum {
    Person = 'person'
}

/**
 * Maps a placeholder to a table and column name.
 * @export
 * @interface PlaceholderMapping
 */
export interface PlaceholderMapping {
    /**
     * The placeholder type used in the question template.
     * @type {PlaceholderType}
     * @memberof PlaceholderMapping
     */
    'type': PlaceholderType;
    /**
     * The placeholder key used in the question template. Use `t*` for table and `c*` for column placeholders
     * @type {string}
     * @memberof PlaceholderMapping
     */
    'key': string;
    /**
     * The placeholder value used in the question template.
     * @type {string}
     * @memberof PlaceholderMapping
     */
    'value': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PlaceholderType {
    Table = 'table',
    Column = 'column'
}

/**
 * Status of the plagiarism check
 * @export
 * @enum {string}
 */

export enum PlagiarismCheckStatusType {
    Success = 'SUCCESS',
    Error = 'ERROR',
    CreditsChecked = 'CREDITS_CHECKED',
    Indexed = 'INDEXED',
    Pending = 'PENDING'
}

/**
 * 
 * @export
 * @interface PlagiarismCheckSubmitAckResponse
 */
export interface PlagiarismCheckSubmitAckResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PlagiarismCheckSubmitAckResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof PlagiarismCheckSubmitAckResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface PlagiarismResponse
 */
export interface PlagiarismResponse {
    /**
     * 
     * @type {ScannedDocumentModel}
     * @memberof PlagiarismResponse
     */
    'scannedDocument': ScannedDocumentModel;
    /**
     * 
     * @type {ResultsModel}
     * @memberof PlagiarismResponse
     */
    'results': ResultsModel;
    /**
     * 
     * @type {{ [key: string]: Array<any>; }}
     * @memberof PlagiarismResponse
     */
    'notifications': { [key: string]: Array<any>; };
    /**
     * 
     * @type {number}
     * @memberof PlagiarismResponse
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof PlagiarismResponse
     */
    'developerPayload': string;
}
/**
 * 
 * @export
 * @interface Position
 */
export interface Position {
    /**
     * 
     * @type {number}
     * @memberof Position
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof Position
     */
    'y': number;
}
/**
 * Payload for a callback to Vienna after completion of a tasklet.
 * @export
 * @interface PostTaskletCompletionCallbackRequest
 */
export interface PostTaskletCompletionCallbackRequest {
    /**
     * 
     * @type {string}
     * @memberof PostTaskletCompletionCallbackRequest
     */
    'taskId': string;
    /**
     * 
     * @type {string}
     * @memberof PostTaskletCompletionCallbackRequest
     */
    'taskletId': string;
    /**
     * 
     * @type {string}
     * @memberof PostTaskletCompletionCallbackRequest
     */
    'compositeKey': string;
    /**
     * 
     * @type {string}
     * @memberof PostTaskletCompletionCallbackRequest
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface PostUrl
 */
export interface PostUrl {
    /**
     * 
     * @type {string}
     * @memberof PostUrl
     */
    'url': string;
    /**
     * 
     * @type {UrlPostFields}
     * @memberof PostUrl
     */
    'fields': UrlPostFields;
}
/**
 * 
 * @export
 * @interface PostgresConnectorMetadataModel
 */
export interface PostgresConnectorMetadataModel {
    /**
     * 
     * @type {string}
     * @memberof PostgresConnectorMetadataModel
     */
    'dbName': string;
    /**
     * 
     * @type {string}
     * @memberof PostgresConnectorMetadataModel
     */
    'hostName': string;
    /**
     * 
     * @type {number}
     * @memberof PostgresConnectorMetadataModel
     */
    'port'?: number;
}
/**
 * 
 * @export
 * @interface PostgresCredentialData
 */
export interface PostgresCredentialData {
    /**
     * 
     * @type {string}
     * @memberof PostgresCredentialData
     */
    'postgres_username': string;
    /**
     * 
     * @type {string}
     * @memberof PostgresCredentialData
     */
    'postgres_password': string;
}
/**
 * 
 * @export
 * @interface PostgresCredentialModel
 */
export interface PostgresCredentialModel {
    /**
     * 
     * @type {string}
     * @memberof PostgresCredentialModel
     */
    'postgresUsername': string;
    /**
     * 
     * @type {string}
     * @memberof PostgresCredentialModel
     */
    'postgresPassword': string;
}
/**
 * 
 * @export
 * @interface PostgresStorageResourceModel
 */
export interface PostgresStorageResourceModel {
    /**
     * 
     * @type {string}
     * @memberof PostgresStorageResourceModel
     */
    'dbName': string;
    /**
     * 
     * @type {string}
     * @memberof PostgresStorageResourceModel
     */
    'tableName'?: string;
}
/**
 * 
 * @export
 * @interface PredictDataCategoryResponse
 */
export interface PredictDataCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof PredictDataCategoryResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {DataCategory}
     * @memberof PredictDataCategoryResponse
     */
    'response': DataCategory;
    /**
     * 
     * @type {string}
     * @memberof PredictDataCategoryResponse
     */
    'reason'?: string;
}
/**
 * 
 * @export
 * @interface ProductCategoryAnalysis
 */
export interface ProductCategoryAnalysis {
    /**
     * 
     * @type {ReviewAnalysisCategories}
     * @memberof ProductCategoryAnalysis
     */
    'category': ReviewAnalysisCategories;
    /**
     * 
     * @type {Array<ProductReviewCategoryModel>}
     * @memberof ProductCategoryAnalysis
     */
    'categoryResult': Array<ProductReviewCategoryModel>;
}
/**
 * 
 * @export
 * @interface ProductCompetitorsChangeEventsResponse
 */
export interface ProductCompetitorsChangeEventsResponse {
    /**
     * 
     * @type {Array<ProductSingleCompetitorChangeEventsResponse>}
     * @memberof ProductCompetitorsChangeEventsResponse
     */
    'response': Array<ProductSingleCompetitorChangeEventsResponse>;
    /**
     * 
     * @type {string}
     * @memberof ProductCompetitorsChangeEventsResponse
     */
    'workspaceId': string;
}
/**
 * Provides the retail products\' current title and description
 * @export
 * @interface ProductCurrentDetails
 */
export interface ProductCurrentDetails {
    /**
     * 
     * @type {string}
     * @memberof ProductCurrentDetails
     */
    'currentTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCurrentDetails
     */
    'currentDescription'?: string;
}
/**
 * 
 * @export
 * @interface ProductDetailsChangeEvent
 */
export interface ProductDetailsChangeEvent {
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsChangeEvent
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsChangeEvent
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsChangeEvent
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsChangeEvent
     */
    'price': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDetailsChangeEvent
     */
    'changedFields'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProductDetailsChangeEventsResponse
 */
export interface ProductDetailsChangeEventsResponse {
    /**
     * 
     * @type {Array<ProductDetailsChangeEvent>}
     * @memberof ProductDetailsChangeEventsResponse
     */
    'response': Array<ProductDetailsChangeEvent>;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsChangeEventsResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ProductDetailsRecommendation
 */
export interface ProductDetailsRecommendation {
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsRecommendation
     */
    'detailType': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsRecommendation
     */
    'originalValue': string;
    /**
     * 
     * @type {Array<ProductRecommendation>}
     * @memberof ProductDetailsRecommendation
     */
    'recommendations': Array<ProductRecommendation>;
}
/**
 * 
 * @export
 * @interface ProductDetailsRecommendationsResponse
 */
export interface ProductDetailsRecommendationsResponse {
    /**
     * 
     * @type {Array<ProductDetailsRecommendation>}
     * @memberof ProductDetailsRecommendationsResponse
     */
    'result': Array<ProductDetailsRecommendation>;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsRecommendationsResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsRecommendationsResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ProductDetailsStatusResponse
 */
export interface ProductDetailsStatusResponse {
    /**
     * 
     * @type {RetailAppPagesCommonStatus}
     * @memberof ProductDetailsStatusResponse
     */
    'status': RetailAppPagesCommonStatus;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsStatusResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ProductKeywordAnalysisResponse
 */
export interface ProductKeywordAnalysisResponse {
    /**
     * 
     * @type {KeywordAnalysis}
     * @memberof ProductKeywordAnalysisResponse
     */
    'response': KeywordAnalysis;
    /**
     * 
     * @type {KeywordAnalysisStatusType}
     * @memberof ProductKeywordAnalysisResponse
     */
    'status'?: KeywordAnalysisStatusType;
    /**
     * 
     * @type {string}
     * @memberof ProductKeywordAnalysisResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ProductPriceChangeResponse
 */
export interface ProductPriceChangeResponse {
    /**
     * 
     * @type {Array<ProductPriceChanges>}
     * @memberof ProductPriceChangeResponse
     */
    'response'?: Array<ProductPriceChanges>;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceChangeResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ProductPriceChanges
 */
export interface ProductPriceChanges {
    /**
     * 
     * @type {string}
     * @memberof ProductPriceChanges
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceChanges
     */
    'originalPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceChanges
     */
    'updatedPrice'?: string;
}
/**
 * 
 * @export
 * @interface ProductRecommendation
 */
export interface ProductRecommendation {
    /**
     * 
     * @type {string}
     * @memberof ProductRecommendation
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof ProductRecommendation
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface ProductReviewAnalysisResponse
 */
export interface ProductReviewAnalysisResponse {
    /**
     * 
     * @type {ReviewAnalysisStatusType}
     * @memberof ProductReviewAnalysisResponse
     */
    'status': ReviewAnalysisStatusType;
    /**
     * 
     * @type {ReviewAnalysis}
     * @memberof ProductReviewAnalysisResponse
     */
    'response': ReviewAnalysis;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewAnalysisResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ProductReviewAnalysisStatusResponse
 */
export interface ProductReviewAnalysisStatusResponse {
    /**
     * 
     * @type {ReviewAnalysisStatusType}
     * @memberof ProductReviewAnalysisStatusResponse
     */
    'status': ReviewAnalysisStatusType;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewAnalysisStatusResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface ProductReviewCategoryModel
 */
export interface ProductReviewCategoryModel {
    /**
     * 
     * @type {string}
     * @memberof ProductReviewCategoryModel
     */
    'topic': string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewCategoryModel
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof ProductReviewCategoryModel
     */
    'frequency': number;
    /**
     * 
     * @type {ReviewAnalysisSentiment}
     * @memberof ProductReviewCategoryModel
     */
    'sentiment'?: ReviewAnalysisSentiment;
}
/**
 * 
 * @export
 * @interface ProductSingleCompetitorChangeEventsResponse
 */
export interface ProductSingleCompetitorChangeEventsResponse {
    /**
     * 
     * @type {Array<ProductDetailsChangeEvent>}
     * @memberof ProductSingleCompetitorChangeEventsResponse
     */
    'changeEvents': Array<ProductDetailsChangeEvent>;
    /**
     * 
     * @type {string}
     * @memberof ProductSingleCompetitorChangeEventsResponse
     */
    'productUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ProductSingleCompetitorChangeEventsResponse
     */
    'currentTitle': string;
    /**
     * 
     * @type {string}
     * @memberof ProductSingleCompetitorChangeEventsResponse
     */
    'currentPrice': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSingleCompetitorChangeEventsResponse
     */
    'isCompetitor'?: boolean;
}
/**
 * Profile field properties. Each profile field has a value, and a flag to determine if it can be edited.
 * @export
 * @interface ProfileFieldProperties
 */
export interface ProfileFieldProperties {
    /**
     * 
     * @type {boolean}
     * @memberof ProfileFieldProperties
     */
    'isEditable': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileFieldProperties
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ProjEditableFields
 */
export interface ProjEditableFields {
    /**
     * 
     * @type {string}
     * @memberof ProjEditableFields
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjEditableFields
     */
    'name'?: string;
}
/**
 * Project details object for Activity/Search Results
 * @export
 * @interface ProjectInfo
 */
export interface ProjectInfo {
    /**
     * 
     * @type {any}
     * @memberof ProjectInfo
     */
    'projectId': any;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfo
     */
    'createDate': string;
}
/**
 * 
 * @export
 * @interface ProjectModel
 */
export interface ProjectModel {
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    'scope'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModel
     */
    'updateDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectModel
     */
    'demoArtifact'?: boolean;
    /**
     * 
     * @type {ArtifactStateModel}
     * @memberof ProjectModel
     */
    'artifactState'?: ArtifactStateModel;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ProjectScope {
    Public = 'PUBLIC',
    Private = 'PRIVATE'
}

/**
 * 
 * @export
 * @interface ProjectWithTags
 */
export interface ProjectWithTags {
    /**
     * 
     * @type {string}
     * @memberof ProjectWithTags
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithTags
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithTags
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithTags
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithTags
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithTags
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithTags
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithTags
     */
    'scope': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithTags
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithTags
     */
    'updateDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWithTags
     */
    'demoArtifact': boolean;
    /**
     * 
     * @type {Array<TagMetadata>}
     * @memberof ProjectWithTags
     */
    'tags': Array<TagMetadata>;
}
/**
 * 
 * @export
 * @interface PromptSuggestion
 */
export interface PromptSuggestion {
    /**
     * 
     * @type {string}
     * @memberof PromptSuggestion
     */
    'topic': string;
    /**
     * 
     * @type {string}
     * @memberof PromptSuggestion
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface PublicSnippetDataRequest
 */
export interface PublicSnippetDataRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicSnippetDataRequest
     */
    'content': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PublicSnippetDataRequest
     */
    'charts': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PublicSnippetDataRequest
     */
    'app'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface PublicSnippetDataResponse
 */
export interface PublicSnippetDataResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PublicSnippetDataResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof PublicSnippetDataResponse
     */
    'message'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum PublicationStatusType {
    Published = 'published',
    InPress = 'in_press'
}

/**
 * 
 * @export
 * @interface PublishAppBuilderResponse
 */
export interface PublishAppBuilderResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PublishAppBuilderResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface PublishPlatformEventsResponse
 */
export interface PublishPlatformEventsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PublishPlatformEventsResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof PublishPlatformEventsResponse
     */
    'eventId'?: string;
}
/**
 * 
 * @export
 * @interface PublishSnippetRequest
 */
export interface PublishSnippetRequest {
    /**
     * 
     * @type {string}
     * @memberof PublishSnippetRequest
     */
    'snippetId': string;
}
/**
 * 
 * @export
 * @interface PublishUsageEventRequest
 */
export interface PublishUsageEventRequest {
    /**
     * 
     * @type {UsageTrackingEventType1 | GenAICopyEditEventType}
     * @memberof PublishUsageEventRequest
     */
    'eventType': UsageTrackingEventType1 | GenAICopyEditEventType;
    /**
     * 
     * @type {object}
     * @memberof PublishUsageEventRequest
     */
    'eventMetadata': object;
}
/**
 * 
 * @export
 * @interface PublishUsageEventResponse
 */
export interface PublishUsageEventResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PublishUsageEventResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {object}
     * @memberof PublishUsageEventResponse
     */
    'response'?: object;
}
/**
 * 
 * @export
 * @interface PublishUsageEventsRequest
 */
export interface PublishUsageEventsRequest {
    /**
     * 
     * @type {Array<PublishUsageEventRequest>}
     * @memberof PublishUsageEventsRequest
     */
    'usageEvents': Array<PublishUsageEventRequest>;
}
/**
 * 
 * @export
 * @interface PublisherInfoModel
 */
export interface PublisherInfoModel {
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoModel
     */
    'publisherName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoModel
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoModel
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherInfoModel
     */
    'city'?: string;
}
/**
 * 
 * @export
 * @interface QnaResponse
 */
export interface QnaResponse {
    /**
     * 
     * @type {string}
     * @memberof QnaResponse
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof QnaResponse
     */
    'answer': string;
}
/**
 * 
 * @export
 * @interface QnaWithPdfRequestBody
 */
export interface QnaWithPdfRequestBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof QnaWithPdfRequestBody
     */
    'questions': Array<string>;
}
/**
 * 
 * @export
 * @interface QnaWithPdfResponse
 */
export interface QnaWithPdfResponse {
    /**
     * 
     * @type {Array<QnaResponse>}
     * @memberof QnaWithPdfResponse
     */
    'response': Array<QnaResponse>;
    /**
     * 
     * @type {string}
     * @memberof QnaWithPdfResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum QuestionTypes {
    Text = 'text',
    Text2sql = 'text2sql',
    Sql = 'sql'
}

/**
 * 
 * @export
 * @interface RBACResourceResponseModel
 */
export interface RBACResourceResponseModel {
    /**
     * 
     * @type {string}
     * @memberof RBACResourceResponseModel
     */
    'resourceName': string;
    /**
     * 
     * @type {string}
     * @memberof RBACResourceResponseModel
     */
    'resourceDescription': string;
    /**
     * 
     * @type {string}
     * @memberof RBACResourceResponseModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof RBACResourceResponseModel
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof RBACResourceResponseModel
     */
    'recordId': string;
    /**
     * 
     * @type {string}
     * @memberof RBACResourceResponseModel
     */
    'normName': string;
}
/**
 * 
 * @export
 * @interface RBACResourceRoleResponseModel
 */
export interface RBACResourceRoleResponseModel {
    /**
     * 
     * @type {string}
     * @memberof RBACResourceRoleResponseModel
     */
    'roleName': string;
    /**
     * 
     * @type {string}
     * @memberof RBACResourceRoleResponseModel
     */
    'resourceRoleDescription': string;
    /**
     * 
     * @type {string}
     * @memberof RBACResourceRoleResponseModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof RBACResourceRoleResponseModel
     */
    'updateDate': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RBACResourceRoleResponseModel
     */
    'scopeIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RBACResourceRoleResponseModel
     */
    'roleId': string;
}
/**
 * 
 * @export
 * @interface RBACResourceScopeResponseModel
 */
export interface RBACResourceScopeResponseModel {
    /**
     * 
     * @type {string}
     * @memberof RBACResourceScopeResponseModel
     */
    'scopeName': string;
    /**
     * 
     * @type {string}
     * @memberof RBACResourceScopeResponseModel
     */
    'scopeDescription': string;
    /**
     * 
     * @type {string}
     * @memberof RBACResourceScopeResponseModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof RBACResourceScopeResponseModel
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof RBACResourceScopeResponseModel
     */
    'recordId': string;
}
/**
 * 
 * @export
 * @interface RBACUserResourceRoleMappingResponseModel
 */
export interface RBACUserResourceRoleMappingResponseModel {
    /**
     * 
     * @type {string}
     * @memberof RBACUserResourceRoleMappingResponseModel
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof RBACUserResourceRoleMappingResponseModel
     */
    'resourceId': string;
    /**
     * 
     * @type {string}
     * @memberof RBACUserResourceRoleMappingResponseModel
     */
    'roleId': string;
    /**
     * 
     * @type {string}
     * @memberof RBACUserResourceRoleMappingResponseModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof RBACUserResourceRoleMappingResponseModel
     */
    'updateDate': string;
}
/**
 * 
 * @export
 * @interface ReadabilityMetrics
 */
export interface ReadabilityMetrics {
    /**
     * 
     * @type {number}
     * @memberof ReadabilityMetrics
     */
    'fleschKincaidReadingEase': number;
    /**
     * 
     * @type {string}
     * @memberof ReadabilityMetrics
     */
    'fleschKincaidReadingEaseToolTip'?: string;
    /**
     * 
     * @type {number | string}
     * @memberof ReadabilityMetrics
     */
    'fleschKincaidGradeLevel': number | string;
    /**
     * 
     * @type {string}
     * @memberof ReadabilityMetrics
     */
    'fleschKincaidGradeLevelToolTip'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadabilityMetrics
     */
    'colemanReadability': number;
    /**
     * 
     * @type {string}
     * @memberof ReadabilityMetrics
     */
    'colemanReadabilityToolTip'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadabilityMetrics
     */
    'easyListening': number;
    /**
     * 
     * @type {string}
     * @memberof ReadabilityMetrics
     */
    'easyListeningToolTip'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadabilityMetrics
     */
    'overallReadabilityToolTip'?: string;
}
/**
 * 
 * @export
 * @interface RecordCustomMetric
 */
export interface RecordCustomMetric {
    /**
     * 
     * @type {string}
     * @memberof RecordCustomMetric
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof RecordCustomMetric
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface RecordMetaData
 */
export interface RecordMetaData {
    /**
     * 
     * @type {string}
     * @memberof RecordMetaData
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof RecordMetaData
     */
    'value': string;
    /**
     * 
     * @type {RecordMetaType}
     * @memberof RecordMetaData
     */
    'metaType': RecordMetaType;
}
/**
 * Supported MetaData type
 * @export
 * @enum {string}
 */

export enum RecordMetaType {
    Text = 'text',
    ImageUrl = 'imageUrl',
    Uri = 'uri',
    Histogram = 'histogram'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum RecordState {
    HardDelete = 'hard_delete',
    SoftDelete = 'soft_delete',
    Created = 'created'
}

/**
 * 
 * @export
 * @interface RecordingState
 */
export interface RecordingState {
    /**
     * 
     * @type {RecordState}
     * @memberof RecordingState
     */
    'name': RecordState;
    /**
     * 
     * @type {string}
     * @memberof RecordingState
     */
    'on': string;
    /**
     * 
     * @type {string}
     * @memberof RecordingState
     */
    'by': string;
}
/**
 * Number of recordings in a model class
 * @export
 * @interface RecordingsCountInModelClass
 */
export interface RecordingsCountInModelClass {
    /**
     * 
     * @type {MLModelClass}
     * @memberof RecordingsCountInModelClass
     */
    'name': MLModelClass;
    /**
     * 
     * @type {number}
     * @memberof RecordingsCountInModelClass
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface RecordingsSummary
 */
export interface RecordingsSummary {
    /**
     * 
     * @type {number}
     * @memberof RecordingsSummary
     */
    'totalModelClasses': number;
    /**
     * 
     * @type {Array<RecordingsCountInModelClass>}
     * @memberof RecordingsSummary
     */
    'modelClasses': Array<RecordingsCountInModelClass>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordingsSummary
     */
    'labels': Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum RedirectToAppType {
    Markov = 'markov',
    CopyCompass = 'copy_compass'
}

/**
 * 
 * @export
 * @interface RegisterAppDocumentResponse
 */
export interface RegisterAppDocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof RegisterAppDocumentResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterAppDocumentResponse
     */
    'ack': boolean;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum RegistrationFlowStatus {
    Created = 'CREATED',
    DatasetImport = 'DATASET_IMPORT',
    ConfirmDelimiter = 'CONFIRM_DELIMITER',
    ApplyPredictor = 'APPLY_PREDICTOR',
    DatasetUploading = 'DATASET_UPLOADING',
    Completed = 'COMPLETED',
    Error = 'ERROR'
}

/**
 * 
 * @export
 * @interface RegistryModelMapping
 */
export interface RegistryModelMapping {
    /**
     * 
     * @type {string}
     * @memberof RegistryModelMapping
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RegistryModelMapping
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof RegistryModelMapping
     */
    'updateDate': string;
    /**
     * 
     * @type {number}
     * @memberof RegistryModelMapping
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof RegistryModelMapping
     */
    'registryId': string;
    /**
     * 
     * @type {string}
     * @memberof RegistryModelMapping
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof RegistryModelMapping
     */
    'recordId': string;
    /**
     * 
     * @type {string}
     * @memberof RegistryModelMapping
     */
    'stage'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistryModelMapping
     */
    'projectId'?: string;
    /**
     * 
     * @type {object}
     * @memberof RegistryModelMapping
     */
    'info'?: object;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum RelabelingSortField {
    RecordId = 'RECORD_ID',
    Region = 'REGION',
    Cluster = 'CLUSTER'
}

/**
 * 
 * @export
 * @interface RelabelingSummary
 */
export interface RelabelingSummary {
    /**
     * This field is deprecated. Use other fields to get details of resolved points.
     * @type {number}
     * @memberof RelabelingSummary
     * @deprecated
     */
    'numMislabeled': number;
    /**
     * This field is deprecated. Use other fields to get details of resolved points.
     * @type {number}
     * @memberof RelabelingSummary
     * @deprecated
     */
    'numResolved': number;
    /**
     * 
     * @type {number}
     * @memberof RelabelingSummary
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof RelabelingSummary
     */
    'numResolvedRecords': number;
    /**
     * 
     * @type {number}
     * @memberof RelabelingSummary
     */
    'numMislabeledRecords': number;
    /**
     * 
     * @type {number}
     * @memberof RelabelingSummary
     */
    'numMislabeledResolved': number;
}
/**
 * Enum for status of relabeling-workbench Relabeling workbench is the interactive UI where a user can relabel their dataset  Attributes:     NOT_STARTED: workbench not prepared; data would need to be loaded first     NOT_SUPPORTED: relabeling not supported for this dataset     DATA_QUALITY_NOT_AVAILABLE: relabeling is supported, but data quality results are not available     PREPARED: label recommendation is prepared     PREPARING: label recommendation is being prepared     FAILED: label recommendation preparation does not succeed     FROZEN: workbench has data, but modifications are not allowed
 * @export
 * @enum {string}
 */

export enum RelabelingWorkbenchStatus {
    NotStarted = 'NOT_STARTED',
    NotSupported = 'NOT_SUPPORTED',
    DataQualityNotAvailable = 'DATA_QUALITY_NOT_AVAILABLE',
    Prepared = 'PREPARED',
    Preparing = 'PREPARING',
    CreatingNewDatasetVersion = 'CREATING_NEW_DATASET_VERSION',
    Failed = 'FAILED',
    Frozen = 'FROZEN'
}

/**
 * 
 * @export
 * @interface RemoveInviteGenAIProjectRequest
 */
export interface RemoveInviteGenAIProjectRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RemoveInviteGenAIProjectRequest
     */
    'removeUsers': Array<string>;
}
/**
 * 
 * @export
 * @interface RemoveInviteGenAIProjectResponse
 */
export interface RemoveInviteGenAIProjectResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RemoveInviteGenAIProjectResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof RemoveInviteGenAIProjectResponse
     */
    'projectId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RemoveInviteGenAIProjectResponse
     */
    'removedUsers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RemoveInviteGenAIProjectResponse
     */
    'message'?: string;
}
/**
 * Request for removing tag from resource
 * @export
 * @interface RemoveTagsRequest
 */
export interface RemoveTagsRequest {
    /**
     * 
     * @type {string}
     * @memberof RemoveTagsRequest
     */
    'resourceId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RemoveTagsRequest
     */
    'tagIds': Array<string>;
}
/**
 * Response for removing tag from given resource
 * @export
 * @interface RemoveTagsResponse
 */
export interface RemoveTagsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RemoveTagsResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface ReorderRulesRequest
 */
export interface ReorderRulesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReorderRulesRequest
     */
    'updatedOrder': Array<string>;
}
/**
 * 
 * @export
 * @interface ReorderRulesResponse
 */
export interface ReorderRulesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ReorderRulesResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface ResolveClustersRequest
 */
export interface ResolveClustersRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ResolveClustersRequest
     */
    'clusters': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ResolveClustersRequest
     */
    'resolvedClass': string;
}
/**
 * 
 * @export
 * @interface ResolveMislabeledRecordsRequest
 */
export interface ResolveMislabeledRecordsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResolveMislabeledRecordsRequest
     */
    'recordIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResolveMislabeledRecordsRequest
     */
    'resolvedClass': string;
}
/**
 * 
 * @export
 * @interface ResolveMislabeledRecordsResponse
 */
export interface ResolveMislabeledRecordsResponse {
    /**
     * 
     * @type {string}
     * @memberof ResolveMislabeledRecordsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {AckResponse}
     * @memberof ResolveMislabeledRecordsResponse
     */
    'response': AckResponse;
    /**
     * 
     * @type {RelabelingSummary}
     * @memberof ResolveMislabeledRecordsResponse
     */
    'summary': RelabelingSummary;
    /**
     * 
     * @type {string}
     * @memberof ResolveMislabeledRecordsResponse
     */
    'msg'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResolveMislabeledRecordsResponse
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface ResolveRegionRequest
 */
export interface ResolveRegionRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ResolveRegionRequest
     */
    'regions': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ResolveRegionRequest
     */
    'resolvedClass': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ResourceKey {
    SnowflakeDb = 'snowflake_db',
    SnowflakeSchema = 'snowflake_schema',
    S3Folder = 's3_folder',
    S3Bucket = 's3_bucket',
    SnowflakeTable = 'snowflake_table',
    S3File = 's3_file',
    AzureBlobContainer = 'azure_blob_container',
    AzureBlobFolder = 'azure_blob_folder',
    AzureBlob = 'azure_blob',
    PostgresDb = 'postgres_db',
    PostgresTable = 'postgres_table',
    SpreadsheetWorksheet = 'spreadsheet_worksheet',
    GoogledriveFile = 'googledrive_file',
    MkvLibrary = 'mkv_library',
    HubspotStream = 'hubspot_stream',
    AirtableStream = 'airtable_stream',
    GoogleAnalyticsStream = 'google_analytics_stream',
    LinkedinAdsStream = 'linkedin_ads_stream',
    SalesforceStream = 'salesforce_stream',
    ZendeskStream = 'zendesk_stream',
    GoogleAdsStream = 'google_ads_stream',
    GongStream = 'gong_stream',
    NotionStream = 'notion_stream'
}

/**
 * 
 * @export
 * @interface ResourceModel
 */
export interface ResourceModel {
    /**
     * 
     * @type {string}
     * @memberof ResourceModel
     */
    'userId': string;
    /**
     * 
     * @type {StorageType}
     * @memberof ResourceModel
     */
    'connectorType': StorageType;
    /**
     * 
     * @type {MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel}
     * @memberof ResourceModel
     */
    'resourceMetadata'?: MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel;
    /**
     * 
     * @type {string}
     * @memberof ResourceModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceModel
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceModel
     */
    'connectorId': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceModel
     */
    'resourceId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceModel
     */
    'isDataSink'?: boolean;
    /**
     * 
     * @type {DataResourceType}
     * @memberof ResourceModel
     */
    'resourceType'?: DataResourceType;
    /**
     * 
     * @type {object}
     * @memberof ResourceModel
     */
    'details'?: object;
    /**
     * 
     * @type {string}
     * @memberof ResourceModel
     */
    'connectionName'?: string;
}
/**
 * 
 * @export
 * @interface ResourcePreviewData
 */
export interface ResourcePreviewData {
    /**
     * 
     * @type {FilePreview}
     * @memberof ResourcePreviewData
     */
    'preview': FilePreview;
    /**
     * 
     * @type {string}
     * @memberof ResourcePreviewData
     */
    'delimiter'?: string;
}
/**
 * 
 * @export
 * @interface ResourceRequest
 */
export interface ResourceRequest {
    /**
     * 
     * @type {ResourceKey}
     * @memberof ResourceRequest
     */
    'key': ResourceKey;
    /**
     * 
     * @type {string}
     * @memberof ResourceRequest
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceRequest
     */
    'startDate'?: string;
}
/**
 * 
 * @export
 * @interface ResourceRequestV2Mig
 */
export interface ResourceRequestV2Mig {
    /**
     * 
     * @type {ResourceKey}
     * @memberof ResourceRequestV2Mig
     */
    'key': ResourceKey;
    /**
     * 
     * @type {string}
     * @memberof ResourceRequestV2Mig
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceRequestV2Mig
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceRequestV2Mig
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceRequestV2Mig
     */
    'originalName': string;
}
/**
 * 
 * @export
 * @interface ResourceResponse
 */
export interface ResourceResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ResourceResponse
     */
    'isFinal': boolean;
    /**
     * 
     * @type {ResourceKey}
     * @memberof ResourceResponse
     */
    'key': ResourceKey;
    /**
     * 
     * @type {string}
     * @memberof ResourceResponse
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceResponse
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceResponse
     */
    'resourceDisplayName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceResponse
     */
    'canDataSink'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceResponse
     */
    'isAdded'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceResponse
     */
    'resourceId'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ResourceType {
    Dataset = 'dataset',
    Experiment = 'experiment',
    Model = 'model',
    Project = 'project',
    Evaluation = 'evaluation',
    Snippet = 'snippet',
    Workflow = 'workflow',
    DataOperation = 'data_operation',
    DataAnalytics = 'data_analytics'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ResourceTypes {
    Model = 'model',
    Snippet = 'snippet',
    WorkflowSearchApp = 'workflow_search_app',
    ArcApi = 'arc_api'
}

/**
 * 
 * @export
 * @interface RestoreMilestoneResponse
 */
export interface RestoreMilestoneResponse {
    /**
     * 
     * @type {string}
     * @memberof RestoreMilestoneResponse
     */
    'documentId': string;
    /**
     * 
     * @type {number}
     * @memberof RestoreMilestoneResponse
     */
    'milestoneId': number;
    /**
     * 
     * @type {number}
     * @memberof RestoreMilestoneResponse
     */
    'newMilestoneId': number;
}
/**
 * 
 * @export
 * @interface RestorePageResponse
 */
export interface RestorePageResponse {
    /**
     * 
     * @type {string}
     * @memberof RestorePageResponse
     */
    'documentId': string;
    /**
     * 
     * @type {number}
     * @memberof RestorePageResponse
     */
    'pageId': number;
    /**
     * 
     * @type {number}
     * @memberof RestorePageResponse
     */
    'pageVersion': number;
    /**
     * 
     * @type {number}
     * @memberof RestorePageResponse
     */
    'newPageVersion': number;
}
/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {number}
     * @memberof Result
     */
    'classification': number;
    /**
     * 
     * @type {number}
     * @memberof Result
     */
    'probability': number;
    /**
     * 
     * @type {Array<Match>}
     * @memberof Result
     */
    'matches': Array<Match>;
}
/**
 * 
 * @export
 * @interface ResultsModel
 */
export interface ResultsModel {
    /**
     * 
     * @type {ScoreModel}
     * @memberof ResultsModel
     */
    'score': ScoreModel;
    /**
     * 
     * @type {Array<InternetModel>}
     * @memberof ResultsModel
     */
    'internet': Array<InternetModel>;
    /**
     * 
     * @type {Array<any>}
     * @memberof ResultsModel
     */
    'database': Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof ResultsModel
     */
    'batch': Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof ResultsModel
     */
    'repositories': Array<any>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum RetailAppPagesCommonStatus {
    Available = 'AVAILABLE',
    NotAvailable = 'NOT_AVAILABLE'
}

/**
 * 
 * @export
 * @interface RetailProduct
 */
export interface RetailProduct {
    /**
     * 
     * @type {string}
     * @memberof RetailProduct
     */
    'productUrl': string;
    /**
     * 
     * @type {string}
     * @memberof RetailProduct
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof RetailProduct
     */
    'projectId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RetailProduct
     */
    'keywords'?: Array<string>;
    /**
     * 
     * @type {AmazonCategories}
     * @memberof RetailProduct
     */
    'category'?: AmazonCategories;
    /**
     * 
     * @type {string}
     * @memberof RetailProduct
     */
    'brand'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RetailProduct
     */
    'competitorUrls'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RetailProduct
     */
    'dataSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailProduct
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof RetailProduct
     */
    'createDate': string;
    /**
     * 
     * @type {RetailerType}
     * @memberof RetailProduct
     */
    'retailerType': RetailerType;
    /**
     * 
     * @type {string}
     * @memberof RetailProduct
     */
    'productIdInRetailerApp'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailProduct
     */
    'productImageUrlInRetailerApp'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailProduct
     */
    'productTitleInRetailerApp'?: string;
}
/**
 * 
 * @export
 * @interface RetailProductListResponse
 */
export interface RetailProductListResponse {
    /**
     * 
     * @type {Array<RetailProduct>}
     * @memberof RetailProductListResponse
     */
    'response': Array<RetailProduct>;
    /**
     * 
     * @type {number}
     * @memberof RetailProductListResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof RetailProductListResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface RetailProject
 */
export interface RetailProject {
    /**
     * 
     * @type {string}
     * @memberof RetailProject
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof RetailProject
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof RetailProject
     */
    'description'?: string;
    /**
     * 
     * @type {Array<AmazonCategories>}
     * @memberof RetailProject
     */
    'categories'?: Array<AmazonCategories>;
    /**
     * 
     * @type {string}
     * @memberof RetailProject
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RetailProject
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof RetailProject
     */
    'createDate': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum RetailerType {
    Amazon = 'AMAZON'
}

/**
 * 
 * @export
 * @interface RetriggerAck
 */
export interface RetriggerAck {
    /**
     * 
     * @type {boolean}
     * @memberof RetriggerAck
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface ReviewAnalysis
 */
export interface ReviewAnalysis {
    /**
     * 
     * @type {Array<ProductCategoryAnalysis>}
     * @memberof ReviewAnalysis
     */
    'categoriesAnalyses'?: Array<ProductCategoryAnalysis>;
    /**
     * 
     * @type {number}
     * @memberof ReviewAnalysis
     */
    'pagesScraped': number;
    /**
     * 
     * @type {number}
     * @memberof ReviewAnalysis
     */
    'totalReviewsScraped': number;
    /**
     * 
     * @type {number}
     * @memberof ReviewAnalysis
     */
    'unprocessedReviews': number;
    /**
     * 
     * @type {number}
     * @memberof ReviewAnalysis
     */
    'analysedReviews': number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ReviewAnalysisCategories {
    Experience = 'EXPERIENCE',
    Quality = 'QUALITY',
    Features = 'FEATURES',
    Packaging = 'PACKAGING',
    Price = 'PRICE',
    Durability = 'DURABILITY'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ReviewAnalysisSentiment {
    Positive = 'POSITIVE',
    Negative = 'NEGATIVE',
    Neutral = 'NEUTRAL'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ReviewAnalysisStatusType {
    NotStarted = 'NOT_STARTED',
    Running = 'RUNNING',
    Finished = 'FINISHED',
    Failed = 'FAILED'
}

/**
 * Revoke user invite request model
 * @export
 * @interface RevokeUserInviteFromWorkspaceRequest
 */
export interface RevokeUserInviteFromWorkspaceRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RevokeUserInviteFromWorkspaceRequest
     */
    'inviteIds': Array<string>;
}
/**
 * Revoke invited users who have still not accepted the invite
 * @export
 * @interface RevokeUserInviteFromWorkspaceResponse
 */
export interface RevokeUserInviteFromWorkspaceResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof RevokeUserInviteFromWorkspaceResponse
     */
    'inviteIds': Array<string>;
}
/**
 * 
 * @export
 * @interface Rule
 */
export interface Rule {
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    'ruleId': string;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    'dataOperationId': string;
    /**
     * 
     * @type {LabelingRuleBody}
     * @memberof Rule
     */
    'ruleBody': LabelingRuleBody;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    'updateDate'?: string;
}
/**
 * 
 * @export
 * @interface RuleApplicationResponse
 */
export interface RuleApplicationResponse {
    /**
     * 
     * @type {Array<RuleApplicationResult>}
     * @memberof RuleApplicationResponse
     */
    'response': Array<RuleApplicationResult>;
    /**
     * 
     * @type {string}
     * @memberof RuleApplicationResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface RuleApplicationResult
 */
export interface RuleApplicationResult {
    /**
     * 
     * @type {SegmentType}
     * @memberof RuleApplicationResult
     */
    'segment': SegmentType;
    /**
     * 
     * @type {Array<number | string>}
     * @memberof RuleApplicationResult
     */
    'targetValues': Array<number | string>;
}
/**
 * 
 * @export
 * @interface RuleBasedRelabelCreate
 */
export interface RuleBasedRelabelCreate {
    /**
     * 
     * @type {string}
     * @memberof RuleBasedRelabelCreate
     */
    'newTargetName': string;
    /**
     * 
     * @type {DatasetSamplingStrategy}
     * @memberof RuleBasedRelabelCreate
     */
    'samplingStrategy': DatasetSamplingStrategy;
    /**
     * 
     * @type {Array<string>}
     * @memberof RuleBasedRelabelCreate
     */
    'featureColumns'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RuleBasedRelabelingDetailsModel
 */
export interface RuleBasedRelabelingDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof RuleBasedRelabelingDetailsModel
     */
    'newTargetName': string;
    /**
     * 
     * @type {string}
     * @memberof RuleBasedRelabelingDetailsModel
     */
    'samplingStrategy': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RuleBasedRelabelingDetailsModel
     */
    'featureColumns': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RuleBasedRelabelingDetailsModel
     */
    'originalTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleBasedRelabelingDetailsModel
     */
    'derivedDatasetId'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum RuleBasedRelabelingState {
    Draft = 'DRAFT',
    CreatingNewDatasetVersion = 'CREATING_NEW_DATASET_VERSION',
    Failed = 'FAILED',
    Success = 'SUCCESS'
}

/**
 * 
 * @export
 * @interface RuleCheckResponse
 */
export interface RuleCheckResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RuleCheckResponse
     */
    'conflict': boolean;
    /**
     * 
     * @type {Array<RuleConflict>}
     * @memberof RuleCheckResponse
     */
    'response': Array<RuleConflict>;
    /**
     * 
     * @type {string}
     * @memberof RuleCheckResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface RuleConflict
 */
export interface RuleConflict {
    /**
     * 
     * @type {string}
     * @memberof RuleConflict
     */
    'conflictingRuleId': string;
    /**
     * 
     * @type {number}
     * @memberof RuleConflict
     */
    'numConflicts': number;
    /**
     * 
     * @type {string}
     * @memberof RuleConflict
     */
    'detail'?: string;
}
/**
 * 
 * @export
 * @interface RuleCoverageDetail
 */
export interface RuleCoverageDetail {
    /**
     * 
     * @type {number}
     * @memberof RuleCoverageDetail
     */
    'coveredRecords': number;
    /**
     * 
     * @type {number}
     * @memberof RuleCoverageDetail
     */
    'precision': number;
    /**
     * 
     * @type {number}
     * @memberof RuleCoverageDetail
     */
    'difference': number;
    /**
     * 
     * @type {Rule}
     * @memberof RuleCoverageDetail
     */
    'rule': Rule;
}
/**
 * 
 * @export
 * @interface RuleCoverageSummary
 */
export interface RuleCoverageSummary {
    /**
     * 
     * @type {number}
     * @memberof RuleCoverageSummary
     */
    'totalRecords': number;
    /**
     * 
     * @type {number}
     * @memberof RuleCoverageSummary
     */
    'coveredRecords': number;
    /**
     * 
     * @type {Array<RuleCoverageDetail>}
     * @memberof RuleCoverageSummary
     */
    'ruleWiseCoverage': Array<RuleCoverageDetail>;
}
/**
 * 
 * @export
 * @interface RuleCoverageSummaryResponse
 */
export interface RuleCoverageSummaryResponse {
    /**
     * 
     * @type {RuleCoverageSummary}
     * @memberof RuleCoverageSummaryResponse
     */
    'response': RuleCoverageSummary;
    /**
     * 
     * @type {string}
     * @memberof RuleCoverageSummaryResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface RuleResponse
 */
export interface RuleResponse {
    /**
     * 
     * @type {string}
     * @memberof RuleResponse
     */
    'ruleId': string;
    /**
     * 
     * @type {string}
     * @memberof RuleResponse
     */
    'dataOperationId': string;
    /**
     * 
     * @type {string}
     * @memberof RuleResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface RuleSetUploadResponse
 */
export interface RuleSetUploadResponse {
    /**
     * 
     * @type {string}
     * @memberof RuleSetUploadResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof RuleSetUploadResponse
     */
    'ruleSetId': string;
    /**
     * 
     * @type {RulesFileUploadStatusType}
     * @memberof RuleSetUploadResponse
     */
    'status': RulesFileUploadStatusType;
    /**
     * 
     * @type {string}
     * @memberof RuleSetUploadResponse
     */
    'message'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum RuleState {
    Draft = 'DRAFT',
    Active = 'ACTIVE',
    Inactive = 'INACTIVE'
}

/**
 * Status of the file upload
 * @export
 * @enum {string}
 */

export enum RulesFileUploadStatusType {
    Success = 'SUCCESS',
    Uploading = 'UPLOADING',
    Error = 'ERROR'
}

/**
 * 
 * @export
 * @interface RulesWithOrder
 */
export interface RulesWithOrder {
    /**
     * 
     * @type {Array<Rule>}
     * @memberof RulesWithOrder
     */
    'rules'?: Array<Rule>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RulesWithOrder
     */
    'order'?: Array<string>;
}
/**
 * Response for DataComparison Runs
 * @export
 * @interface Run
 */
export interface Run {
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    'runId': string;
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    'runType': string;
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    'userId': string;
    /**
     * 
     * @type {AnalysisStatus}
     * @memberof Run
     */
    'status'?: AnalysisStatus;
    /**
     * 
     * @type {DataComparisonDetails}
     * @memberof Run
     */
    'details': DataComparisonDetails;
}
/**
 * Response for DataComparison Runs
 * @export
 * @interface Run1
 */
export interface Run1 {
    /**
     * 
     * @type {string}
     * @memberof Run1
     */
    'runId': string;
    /**
     * 
     * @type {string}
     * @memberof Run1
     */
    'runType': string;
    /**
     * 
     * @type {string}
     * @memberof Run1
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof Run1
     */
    'userId': string;
    /**
     * 
     * @type {AnalysisStatus1}
     * @memberof Run1
     */
    'status'?: AnalysisStatus1;
    /**
     * 
     * @type {DataComparisonDetails}
     * @memberof Run1
     */
    'details': DataComparisonDetails;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum RunStatus {
    Start = 'START',
    Finished = 'FINISHED',
    InProgress = 'IN_PROGRESS',
    Error = 'ERROR',
    Cancelled = 'CANCELLED'
}

/**
 * 
 * @export
 * @interface RunStatus1
 */
export interface RunStatus1 {
    /**
     * 
     * @type {string}
     * @memberof RunStatus1
     */
    'segment': string;
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof RunStatus1
     */
    'status': AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof RunStatus1
     */
    'taskId'?: string;
}
/**
 * API request for trigger analyses to run on subset
 * @export
 * @interface RunSubsetAnalysesRequest
 */
export interface RunSubsetAnalysesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RunSubsetAnalysesRequest
     */
    'taskletIds'?: Array<string>;
}
/**
 * API response for trigger analyses to run on subset
 * @export
 * @interface RunSubsetAnalysesResponse
 */
export interface RunSubsetAnalysesResponse {
    /**
     * 
     * @type {string}
     * @memberof RunSubsetAnalysesResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {AckResponse}
     * @memberof RunSubsetAnalysesResponse
     */
    'response': AckResponse;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum RunnerType {
    Fargate = 'FARGATE',
    Kubeflow = 'KUBEFLOW'
}

/**
 * List of runs for get runs api response
 * @export
 * @interface RunsList
 */
export interface RunsList {
    /**
     * 
     * @type {Array<Run>}
     * @memberof RunsList
     */
    'response': Array<Run>;
    /**
     * 
     * @type {number}
     * @memberof RunsList
     */
    'numRecords': number;
    /**
     * 
     * @type {string}
     * @memberof RunsList
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {{ [key: string]: BasicUserDetails; }}
     * @memberof RunsList
     */
    'users': { [key: string]: BasicUserDetails; };
}
/**
 * 
 * @export
 * @interface S3ConnectorMetadataModel
 */
export interface S3ConnectorMetadataModel {
    /**
     * 
     * @type {string}
     * @memberof S3ConnectorMetadataModel
     */
    'bucketName'?: string;
}
/**
 * 
 * @export
 * @interface S3CredentialData
 */
export interface S3CredentialData {
    /**
     * 
     * @type {string}
     * @memberof S3CredentialData
     */
    'access_secret': string;
    /**
     * 
     * @type {string}
     * @memberof S3CredentialData
     */
    'access_key': string;
}
/**
 * Cloud providers have different credentials. These classes are to help enforce type safety.
 * @export
 * @interface S3CredentialModel
 */
export interface S3CredentialModel {
    /**
     * 
     * @type {string}
     * @memberof S3CredentialModel
     */
    'accessSecret': string;
    /**
     * 
     * @type {string}
     * @memberof S3CredentialModel
     */
    'accessKey': string;
    /**
     * 
     * @type {string}
     * @memberof S3CredentialModel
     */
    'regionName': S3CredentialModelRegionNameEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum S3CredentialModelRegionNameEnum {
    UsEast1 = 'us-east-1',
    UsEast2 = 'us-east-2',
    UsWest1 = 'us-west-1',
    UsWest2 = 'us-west-2',
    AfSouth1 = 'af-south-1',
    ApEast1 = 'ap-east-1',
    ApSouth1 = 'ap-south-1',
    ApNortheast1 = 'ap-northeast-1',
    ApNortheast2 = 'ap-northeast-2',
    ApNortheast3 = 'ap-northeast-3',
    ApSoutheast1 = 'ap-southeast-1',
    ApSoutheast2 = 'ap-southeast-2',
    ApSoutheast3 = 'ap-southeast-3',
    CaCentral1 = 'ca-central-1',
    CnNorth1 = 'cn-north-1',
    CnNorthwest1 = 'cn-northwest-1',
    EuCentral1 = 'eu-central-1',
    EuWest1 = 'eu-west-1',
    EuWest2 = 'eu-west-2',
    EuWest3 = 'eu-west-3',
    EuNorth1 = 'eu-north-1',
    EuSouth1 = 'eu-south-1',
    EuSouth2 = 'eu-south-2',
    MeSouth1 = 'me-south-1',
    MeCentral1 = 'me-central-1',
    SaEast1 = 'sa-east-1',
    UsGovEast1 = 'us-gov-east-1',
    UsGovWest1 = 'us-gov-west-1'
}

/**
 * 
 * @export
 * @interface S3StorageResourceModel
 */
export interface S3StorageResourceModel {
    /**
     * 
     * @type {string}
     * @memberof S3StorageResourceModel
     */
    'filePath': string;
    /**
     * 
     * @type {string}
     * @memberof S3StorageResourceModel
     */
    'originalName'?: string;
}
/**
 * 
 * @export
 * @interface SaveContentRequestBody
 */
export interface SaveContentRequestBody {
    /**
     * 
     * @type {string}
     * @memberof SaveContentRequestBody
     */
    'pageContent': string;
    /**
     * 
     * @type {string}
     * @memberof SaveContentRequestBody
     */
    'binaryPageContent'?: string;
    /**
     * 
     * @type {Array<SuggestionState>}
     * @memberof SaveContentRequestBody
     */
    'latestPageSuggestionState'?: Array<SuggestionState>;
}
/**
 * 
 * @export
 * @interface SaveContentResponse
 */
export interface SaveContentResponse {
    /**
     * 
     * @type {number}
     * @memberof SaveContentResponse
     */
    'pageId': number;
}
/**
 * This class defines the structure for the API response listing saved tasklet selections.  Attributes:     selection_name (str): The name of the saved tasklet selection.     tasklet_ids (List[str]): A list of tasklets included in the selection.     data_category (DataCategory): The data category of the selection.     create_date (str): The date when the selection was created.     user_id (str): The user who created the selection.
 * @export
 * @interface SavedTaskletSelection
 */
export interface SavedTaskletSelection {
    /**
     * 
     * @type {string}
     * @memberof SavedTaskletSelection
     */
    'selectionName': string;
    /**
     * 
     * @type {string}
     * @memberof SavedTaskletSelection
     */
    'selectionId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SavedTaskletSelection
     */
    'taskletIds': Array<string>;
    /**
     * 
     * @type {DataCategory}
     * @memberof SavedTaskletSelection
     */
    'dataCategory': DataCategory;
    /**
     * 
     * @type {string}
     * @memberof SavedTaskletSelection
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof SavedTaskletSelection
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface ScannedDocumentModel
 */
export interface ScannedDocumentModel {
    /**
     * 
     * @type {string}
     * @memberof ScannedDocumentModel
     */
    'scanId': string;
    /**
     * 
     * @type {number}
     * @memberof ScannedDocumentModel
     */
    'totalWords': number;
    /**
     * 
     * @type {number}
     * @memberof ScannedDocumentModel
     */
    'totalExcluded': number;
    /**
     * 
     * @type {number}
     * @memberof ScannedDocumentModel
     */
    'credits': number;
    /**
     * 
     * @type {number}
     * @memberof ScannedDocumentModel
     */
    'expectedCredits': number;
    /**
     * 
     * @type {string}
     * @memberof ScannedDocumentModel
     */
    'creationTime': string;
    /**
     * 
     * @type {object}
     * @memberof ScannedDocumentModel
     */
    'metadata': object;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ScannedDocumentModel
     */
    'enabled': { [key: string]: boolean; };
}
/**
 * Workflow schedule details model Attributes:     schedule_type (WorkflowScheduleType): Type of schedule (e.g., CRON, PERIODIC).     start_date (str): Start date and time of the schedule.     frequency (Optional[str]): CRON expression for scheduling frequency.     interval (Optional[int]): Interval in seconds for periodic scheduling.     end_date (str): End date and time for the schedule.
 * @export
 * @interface ScheduleDetailsModel
 */
export interface ScheduleDetailsModel {
    /**
     * 
     * @type {WorkflowScheduleType}
     * @memberof ScheduleDetailsModel
     */
    'scheduleType': WorkflowScheduleType;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDetailsModel
     */
    'startDate': string;
    /**
     * 
     * @type {number}
     * @memberof ScheduleDetailsModel
     */
    'interval'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDetailsModel
     */
    'frequency'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDetailsModel
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface SchemaChange
 */
export interface SchemaChange {
    /**
     * 
     * @type {Array<string>}
     * @memberof SchemaChange
     */
    'added'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SchemaChange
     */
    'removed'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ScopeTypes {
    Read = 'read',
    Write = 'write'
}

/**
 * 
 * @export
 * @interface ScopedTokenRequestModel
 */
export interface ScopedTokenRequestModel {
    /**
     * 
     * @type {string}
     * @memberof ScopedTokenRequestModel
     */
    'resourceId': string;
    /**
     * 
     * @type {ResourceTypes}
     * @memberof ScopedTokenRequestModel
     */
    'resourceType': ResourceTypes;
    /**
     * 
     * @type {ScopeTypes}
     * @memberof ScopedTokenRequestModel
     */
    'scope': ScopeTypes;
    /**
     * 
     * @type {string}
     * @memberof ScopedTokenRequestModel
     */
    'expiry'?: string;
    /**
     * 
     * @type {object}
     * @memberof ScopedTokenRequestModel
     */
    'metadata'?: object;
}
/**
 * 
 * @export
 * @interface ScopedTokenResponseModel
 */
export interface ScopedTokenResponseModel {
    /**
     * 
     * @type {string}
     * @memberof ScopedTokenResponseModel
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ScopedTokenResponseModel
     */
    'expiresAt': string;
}
/**
 * 
 * @export
 * @interface ScopedTokenRevokeRequestModel
 */
export interface ScopedTokenRevokeRequestModel {
    /**
     * 
     * @type {string}
     * @memberof ScopedTokenRevokeRequestModel
     */
    'resourceId': string;
    /**
     * 
     * @type {ScopeTypes}
     * @memberof ScopedTokenRevokeRequestModel
     */
    'scope': ScopeTypes;
}
/**
 * 
 * @export
 * @interface ScopedTokenRevokeResponseModel
 */
export interface ScopedTokenRevokeResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof ScopedTokenRevokeResponseModel
     */
    'ok': boolean;
}
/**
 * 
 * @export
 * @interface ScopedTokenValidateRequestModel
 */
export interface ScopedTokenValidateRequestModel {
    /**
     * 
     * @type {string}
     * @memberof ScopedTokenValidateRequestModel
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ScopedTokenValidateRequestModel
     */
    'resourceId': string;
    /**
     * 
     * @type {ScopeTypes}
     * @memberof ScopedTokenValidateRequestModel
     */
    'scope': ScopeTypes;
}
/**
 * 
 * @export
 * @interface ScopedTokenValidateResponseModel
 */
export interface ScopedTokenValidateResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof ScopedTokenValidateResponseModel
     */
    'ok': boolean;
    /**
     * 
     * @type {object}
     * @memberof ScopedTokenValidateResponseModel
     */
    'metadata'?: object;
}
/**
 * 
 * @export
 * @interface ScoreModel
 */
export interface ScoreModel {
    /**
     * 
     * @type {number}
     * @memberof ScoreModel
     */
    'identicalWords': number;
    /**
     * 
     * @type {number}
     * @memberof ScoreModel
     */
    'minorChangedWords': number;
    /**
     * 
     * @type {number}
     * @memberof ScoreModel
     */
    'relatedMeaningWords': number;
    /**
     * 
     * @type {number}
     * @memberof ScoreModel
     */
    'aggregatedScore': number;
}
/**
 * 
 * @export
 * @interface SearchAppMetadataResponse
 */
export interface SearchAppMetadataResponse {
    /**
     * 
     * @type {string}
     * @memberof SearchAppMetadataResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof SearchAppMetadataResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SearchAppMetadataResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof SearchAppMetadataResponse
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof SearchAppMetadataResponse
     */
    'updateDate': string;
    /**
     * 
     * @type {Array<SearchIndexMetadataResponse>}
     * @memberof SearchAppMetadataResponse
     */
    'searchIndexes': Array<SearchIndexMetadataResponse>;
    /**
     * 
     * @type {string}
     * @memberof SearchAppMetadataResponse
     */
    'searchAppId': string;
    /**
     * 
     * @type {string}
     * @memberof SearchAppMetadataResponse
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof SearchAppMetadataResponse
     */
    'sourceId': string;
    /**
     * 
     * @type {string}
     * @memberof SearchAppMetadataResponse
     */
    'modelName'?: string;
    /**
     * 
     * @type {InputMode}
     * @memberof SearchAppMetadataResponse
     */
    'inputMode'?: InputMode;
    /**
     * 
     * @type {string}
     * @memberof SearchAppMetadataResponse
     */
    'resourceId'?: string;
}
/**
 * 
 * @export
 * @interface SearchIndexMetadataResponse
 */
export interface SearchIndexMetadataResponse {
    /**
     * 
     * @type {string}
     * @memberof SearchIndexMetadataResponse
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchIndexMetadataResponse
     */
    'featureColumns': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchIndexMetadataResponse
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof SearchIndexMetadataResponse
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof SearchIndexMetadataResponse
     */
    'indexId': string;
}
/**
 * 
 * @export
 * @interface SearchPodStatusResponse
 */
export interface SearchPodStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof SearchPodStatusResponse
     */
    'status': string;
}
/**
 * Response model for workspace search API - /v1/workspace/{workspace_id}/search  This model is used to return search results for all types of entities. Currently, we support search for - users, - datasets, - model recordings, - experiment recordings, and - teams
 * @export
 * @interface SearchResults
 */
export interface SearchResults {
    /**
     * 
     * @type {UserSearchResults}
     * @memberof SearchResults
     */
    'users'?: UserSearchResults;
    /**
     * 
     * @type {DatasetSearchResults}
     * @memberof SearchResults
     */
    'datasets'?: DatasetSearchResults;
    /**
     * 
     * @type {ModelRecordingSearchResults}
     * @memberof SearchResults
     */
    'modelRecordings'?: ModelRecordingSearchResults;
    /**
     * 
     * @type {ExperimentRecordingSearchResults}
     * @memberof SearchResults
     */
    'experimentRecordings'?: ExperimentRecordingSearchResults;
    /**
     * 
     * @type {TeamsSearchResults}
     * @memberof SearchResults
     */
    'teams'?: TeamsSearchResults;
}
/**
 * Enum for searchable entities
 * @export
 * @enum {string}
 */

export enum SearchableEntities {
    Datasets = 'datasets',
    ModelRecordings = 'model_recordings',
    ExperimentRecordings = 'experiment_recordings',
    Users = 'users'
}

/**
 * 
 * @export
 * @interface SecretMetadataResponse
 */
export interface SecretMetadataResponse {
    /**
     * 
     * @type {Array<SecretModel>}
     * @memberof SecretMetadataResponse
     */
    'response': Array<SecretModel>;
    /**
     * 
     * @type {number}
     * @memberof SecretMetadataResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof SecretMetadataResponse
     */
    'pageCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof SecretMetadataResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface SecretModel
 */
export interface SecretModel {
    /**
     * 
     * @type {string}
     * @memberof SecretModel
     */
    'secretId': string;
    /**
     * 
     * @type {object}
     * @memberof SecretModel
     */
    'data'?: object;
    /**
     * 
     * @type {object}
     * @memberof SecretModel
     */
    'metaData'?: object;
    /**
     * 
     * @type {string}
     * @memberof SecretModel
     */
    'vid'?: string;
}
/**
 * 
 * @export
 * @interface SecretRegistrationRequest
 */
export interface SecretRegistrationRequest {
    /**
     * 
     * @type {string}
     * @memberof SecretRegistrationRequest
     */
    'secret_id': string;
    /**
     * 
     * @type {object}
     * @memberof SecretRegistrationRequest
     */
    'data': object;
    /**
     * 
     * @type {object}
     * @memberof SecretRegistrationRequest
     */
    'meta_data': object;
    /**
     * 
     * @type {string}
     * @memberof SecretRegistrationRequest
     */
    'norm_name': string;
}
/**
 * 
 * @export
 * @interface SecretRegistrationResponse
 */
export interface SecretRegistrationResponse {
    /**
     * 
     * @type {object}
     * @memberof SecretRegistrationResponse
     */
    'response': object;
    /**
     * 
     * @type {string}
     * @memberof SecretRegistrationResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface SecretUpdateRequest
 */
export interface SecretUpdateRequest {
    /**
     * 
     * @type {object}
     * @memberof SecretUpdateRequest
     */
    'meta_data': object;
}
/**
 * 
 * @export
 * @interface SegmentComparedWith
 */
export interface SegmentComparedWith {
    /**
     * 
     * @type {SegmentType}
     * @memberof SegmentComparedWith
     */
    'compareWith': SegmentType;
    /**
     * 
     * @type {Array<SimilarityMetricScore>}
     * @memberof SegmentComparedWith
     */
    'similarities': Array<SimilarityMetricScore>;
}
/**
 * Details: https://meskalero.slab.com/posts/api-design-vienna-9szheehs#h4w73-updated-api-design
 * @export
 * @interface SegmentComparison
 */
export interface SegmentComparison {
    /**
     * 
     * @type {Array<SegmentComparisonMetric>}
     * @memberof SegmentComparison
     */
    'metrics': Array<SegmentComparisonMetric>;
    /**
     * 
     * @type {Array<SegmentComparisonDetail>}
     * @memberof SegmentComparison
     */
    'details': Array<SegmentComparisonDetail>;
}
/**
 * 
 * @export
 * @interface SegmentComparisonDetail
 */
export interface SegmentComparisonDetail {
    /**
     * 
     * @type {SegmentType}
     * @memberof SegmentComparisonDetail
     */
    'primary': SegmentType;
    /**
     * 
     * @type {Array<SegmentComparedWith>}
     * @memberof SegmentComparisonDetail
     */
    'comparisons': Array<SegmentComparedWith>;
}
/**
 * 
 * @export
 * @interface SegmentComparisonMetric
 */
export interface SegmentComparisonMetric {
    /**
     * 
     * @type {string}
     * @memberof SegmentComparisonMetric
     */
    'metricId': string;
    /**
     * 
     * @type {string}
     * @memberof SegmentComparisonMetric
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof SegmentComparisonMetric
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof SegmentComparisonMetric
     */
    'fullName': string;
}
/**
 * 
 * @export
 * @interface SegmentRunDetails
 */
export interface SegmentRunDetails {
    /**
     * 
     * @type {string}
     * @memberof SegmentRunDetails
     */
    'segment': string;
    /**
     * 
     * @type {boolean}
     * @memberof SegmentRunDetails
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof SegmentRunDetails
     */
    'runId': string;
    /**
     * 
     * @type {string}
     * @memberof SegmentRunDetails
     */
    'taskId': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum SegmentType {
    Train = 'train',
    Test = 'test',
    Validate = 'validate',
    Unknown = 'unknown',
    All = 'all'
}

/**
 * 
 * @export
 * @interface SelectionCreationResponse
 */
export interface SelectionCreationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SelectionCreationResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof SelectionCreationResponse
     */
    'selectionId': string;
}
/**
 * Response ORM returned by the service
 * @export
 * @interface ServiceResponse
 */
export interface ServiceResponse {
    /**
     * 
     * @type {string}
     * @memberof ServiceResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceResponse
     */
    'dsId': string;
    /**
     * 
     * @type {any}
     * @memberof ServiceResponse
     */
    'data': any;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceResponse
     */
    'ack'?: boolean;
}
/**
 * 
 * @export
 * @interface ServicesViennaApiModelsCommentsCommentModelsAddCommentToConversationResponse
 */
export interface ServicesViennaApiModelsCommentsCommentModelsAddCommentToConversationResponse {
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsCommentsCommentModelsAddCommentToConversationResponse
     */
    'latestCommentId': string;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsCommentsCommentModelsAddCommentToConversationResponse
     */
    'conversationId': string;
}
/**
 * Create Dataset Upload Url Request ORM
 * @export
 * @interface ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest
 */
export interface ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest
     */
    'key': string;
    /**
     * 
     * @type {Array<FilePartInfo>}
     * @memberof ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest
     */
    'parts': Array<FilePartInfo>;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest
     */
    'uploadId': string;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest
     */
    'name'?: string;
}
/**
 * Create Dataset Upload Url Request ORM
 * @export
 * @interface ServicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest
 */
export interface ServicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest
     */
    'key': string;
    /**
     * 
     * @type {Array<FilePartInfo>}
     * @memberof ServicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest
     */
    'parts': Array<FilePartInfo>;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest
     */
    'uploadId': string;
}
/**
 * 
 * @export
 * @interface ServicesViennaApiModelsDatasetModelsDataset
 */
export interface ServicesViennaApiModelsDatasetModelsDataset {
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsDatasetModelsDataset
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsDatasetModelsDataset
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ServicesViennaApiModelsSnippetCommentModelsAddCommentToConversationResponse
 */
export interface ServicesViennaApiModelsSnippetCommentModelsAddCommentToConversationResponse {
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsSnippetCommentModelsAddCommentToConversationResponse
     */
    'snippetId': string;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsSnippetCommentModelsAddCommentToConversationResponse
     */
    'conversationId': string;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsSnippetCommentModelsAddCommentToConversationResponse
     */
    'latestCommentId': string;
}
/**
 * 
 * @export
 * @interface ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
 */
export interface ServicesViennaApiModelsV2DatasetsDatasetModelsDataset {
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'datasetId': string;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'dataFamilyId': string;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'dataFamilyName': string;
    /**
     * 
     * @type {DataCategory}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'dataType': DataCategory;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'source': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'xColumns': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'numFeatureColumns': number;
    /**
     * 
     * @type {number}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'yColumn': number;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'credId': string;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'createDate': string;
    /**
     * 
     * @type {DatasetState}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'state'?: DatasetState;
    /**
     * 
     * @type {AnalysisStatus1}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'analysis'?: AnalysisStatus1;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'delimiter'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'xColNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'yColName'?: string;
    /**
     * 
     * @type {Array<DataSegment>}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'segments'?: Array<DataSegment>;
    /**
     * 
     * @type {number | string}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'numRecords'?: number | string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'classNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'teamId'?: string;
    /**
     * Data quality score. It\'s a computed value. Value of -1 indicates that data isn\'t available.
     * @type {number}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'dataQualityScore'?: number;
    /**
     * Number of mislabeled records. It\'s a computed value. Value of -1 indicates that data isn\'t available.
     * @type {number}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'numMislabeled'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'parentDatasetId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'childrenDatasetIds'?: Array<string>;
    /**
     * If its sample dataset it would be true
     * @type {boolean}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'demoArtifact'?: boolean;
    /**
     * Fetch artifacts based on query param filter ACTIVE/ARCHIVED
     * @type {ArtifactStateModel}
     * @memberof ServicesViennaApiModelsV2DatasetsDatasetModelsDataset
     */
    'artifactState'?: ArtifactStateModel;
}
/**
 * 
 * @export
 * @interface ShareResourceResponse
 */
export interface ShareResourceResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ShareResourceResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof ShareResourceResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ShareSnippetRequest
 */
export interface ShareSnippetRequest {
    /**
     * 
     * @type {string}
     * @memberof ShareSnippetRequest
     */
    'snippetId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShareSnippetRequest
     */
    'sharedUserIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ShareSnippetResponse
 */
export interface ShareSnippetResponse {
    /**
     * 
     * @type {string}
     * @memberof ShareSnippetResponse
     */
    'snippetId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ShareSnippetResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof ShareSnippetResponse
     */
    'message'?: string;
}
/**
 * Represent Metric Name, DisplayName and description
 * @export
 * @interface SimMetricDetail
 */
export interface SimMetricDetail {
    /**
     * 
     * @type {string}
     * @memberof SimMetricDetail
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof SimMetricDetail
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof SimMetricDetail
     */
    'description': string;
}
/**
 * Returns the Similarity Metrics computed
 * @export
 * @interface SimMetricsNameResponse
 */
export interface SimMetricsNameResponse {
    /**
     * 
     * @type {Array<SimMetricDetail>}
     * @memberof SimMetricsNameResponse
     */
    'response': Array<SimMetricDetail>;
    /**
     * 
     * @type {string}
     * @memberof SimMetricsNameResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface SimilarityDSPairRunStatus
 */
export interface SimilarityDSPairRunStatus {
    /**
     * 
     * @type {string}
     * @memberof SimilarityDSPairRunStatus
     */
    'compareWith': string;
    /**
     * 
     * @type {string}
     * @memberof SimilarityDSPairRunStatus
     */
    'status': string;
    /**
     * 
     * @type {boolean}
     * @memberof SimilarityDSPairRunStatus
     */
    'isAnalyzed'?: boolean;
}
/**
 * 
 * @export
 * @interface SimilarityMetricScore
 */
export interface SimilarityMetricScore {
    /**
     * 
     * @type {string}
     * @memberof SimilarityMetricScore
     */
    'metricId': string;
    /**
     * 
     * @type {string}
     * @memberof SimilarityMetricScore
     */
    'displayName': string;
    /**
     * 
     * @type {number}
     * @memberof SimilarityMetricScore
     */
    'score': number;
    /**
     * 
     * @type {string}
     * @memberof SimilarityMetricScore
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof SimilarityMetricScore
     */
    'className'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SimilarityMetricScore
     */
    'isSimilar'?: boolean;
}
/**
 * 
 * @export
 * @interface SimilarityRequest
 */
export interface SimilarityRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof SimilarityRequest
     */
    'recordIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimilarityRequest
     */
    'inputTextList'?: Array<string>;
}
/**
 * Returns that status of the runs
 * @export
 * @interface SimilarityRunStatus
 */
export interface SimilarityRunStatus {
    /**
     * 
     * @type {string}
     * @memberof SimilarityRunStatus
     */
    'primary': string;
    /**
     * 
     * @type {Array<SimilarityDSPairRunStatus>}
     * @memberof SimilarityRunStatus
     */
    'details': Array<SimilarityDSPairRunStatus>;
    /**
     * 
     * @type {string}
     * @memberof SimilarityRunStatus
     */
    'workspaceId'?: string;
}
/**
 * FilterResult Request ORM
 * @export
 * @interface SimpleFilterRequest
 */
export interface SimpleFilterRequest {
    /**
     * 
     * @type {string}
     * @memberof SimpleFilterRequest
     */
    'memberId': string;
    /**
     * 
     * @type {FilterConstraints}
     * @memberof SimpleFilterRequest
     */
    'constraints': FilterConstraints;
    /**
     * 
     * @type {number}
     * @memberof SimpleFilterRequest
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleFilterRequest
     */
    'end'?: number;
}
/**
 * Request schema for slack authorize handler API. /v1/slack/handle-authorize-code  At the end of slack authorize step Slack redirects to a URL (redirect_uri) defined by us, and sends a code. The code is to be exchanged for the access token
 * @export
 * @interface SlackAuthorizeHandleRequest
 */
export interface SlackAuthorizeHandleRequest {
    /**
     * 
     * @type {string}
     * @memberof SlackAuthorizeHandleRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SlackAuthorizeHandleRequest
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof SlackAuthorizeHandleRequest
     */
    'redirectUri': string;
}
/**
 * Represents the model for a Slack authorized response.  Inherits from ACKResponseModel, which is a base model representing an acknowledgement response with a success flag and an optional error message.
 * @export
 * @interface SlackAuthorizeHandleResponse
 */
export interface SlackAuthorizeHandleResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SlackAuthorizeHandleResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof SlackAuthorizeHandleResponse
     */
    'errorMessage'?: string;
}
/**
 * 
 * @export
 * @interface SlackBotAppInstallRequestModel
 */
export interface SlackBotAppInstallRequestModel {
    /**
     * 
     * @type {string}
     * @memberof SlackBotAppInstallRequestModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof SlackBotAppInstallRequestModel
     */
    'redirectUri'?: string;
}
/**
 * 
 * @export
 * @interface SlackBotAppInstallResponseModel
 */
export interface SlackBotAppInstallResponseModel {
    /**
     * 
     * @type {string}
     * @memberof SlackBotAppInstallResponseModel
     */
    'slackBotInstallUrl': string;
}
/**
 * 
 * @export
 * @interface SlackBotChannelResponseModel
 */
export interface SlackBotChannelResponseModel {
    /**
     * 
     * @type {string}
     * @memberof SlackBotChannelResponseModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SlackBotChannelResponseModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SlackIntegrationStatusRequest
 */
export interface SlackIntegrationStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof SlackIntegrationStatusRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof SlackIntegrationStatusRequest
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface SlackIntegrationStatusResponse
 */
export interface SlackIntegrationStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SlackIntegrationStatusResponse
     */
    'isInstalled': boolean;
    /**
     * 
     * @type {string}
     * @memberof SlackIntegrationStatusResponse
     */
    'slackTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SlackIntegrationStatusResponse
     */
    'slackChannelId'?: string;
}
/**
 * 
 * @export
 * @interface SnippetChartMetadataRequest
 */
export interface SnippetChartMetadataRequest {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SnippetChartMetadataRequest
     */
    'charts': { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface SnippetChartMetadataResponse
 */
export interface SnippetChartMetadataResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SnippetChartMetadataResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof SnippetChartMetadataResponse
     */
    'message'?: string;
}
/**
 * Payload object for notification request on snippet comment
 * @export
 * @interface SnippetCommentNotificationPayload
 */
export interface SnippetCommentNotificationPayload {
    /**
     * 
     * @type {UserInfo}
     * @memberof SnippetCommentNotificationPayload
     */
    'userTo': UserInfo;
    /**
     * 
     * @type {string}
     * @memberof SnippetCommentNotificationPayload
     */
    'workspaceId': string;
    /**
     * 
     * @type {UserInfo}
     * @memberof SnippetCommentNotificationPayload
     */
    'userFrom'?: UserInfo;
    /**
     * 
     * @type {SnippetInfo}
     * @memberof SnippetCommentNotificationPayload
     */
    'snippetInfo': SnippetInfo;
}
/**
 * 
 * @export
 * @interface SnippetContentRequest
 */
export interface SnippetContentRequest {
    /**
     * 
     * @type {string}
     * @memberof SnippetContentRequest
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface SnippetContentResponse
 */
export interface SnippetContentResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SnippetContentResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof SnippetContentResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface SnippetDeleteResponse
 */
export interface SnippetDeleteResponse {
    /**
     * 
     * @type {APIMessage}
     * @memberof SnippetDeleteResponse
     */
    'message': APIMessage;
    /**
     * 
     * @type {string}
     * @memberof SnippetDeleteResponse
     */
    'workspaceId': string;
}
/**
 * Snippet meta data.
 * @export
 * @interface SnippetInfo
 */
export interface SnippetInfo {
    /**
     * 
     * @type {string}
     * @memberof SnippetInfo
     */
    'snippetId': string;
    /**
     * 
     * @type {string}
     * @memberof SnippetInfo
     */
    'snippetName': string;
    /**
     * 
     * @type {string}
     * @memberof SnippetInfo
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface SnippetInfoResponse
 */
export interface SnippetInfoResponse {
    /**
     * 
     * @type {APIMessage}
     * @memberof SnippetInfoResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {SnippetModel}
     * @memberof SnippetInfoResponse
     */
    'response'?: SnippetModel;
    /**
     * 
     * @type {string}
     * @memberof SnippetInfoResponse
     */
    'workspaceId': string;
}
/**
 * Payload object for notification request on snippet sharing/invite to user.
 * @export
 * @interface SnippetInvitationPayload
 */
export interface SnippetInvitationPayload {
    /**
     * 
     * @type {UserInfo}
     * @memberof SnippetInvitationPayload
     */
    'userTo': UserInfo;
    /**
     * 
     * @type {string}
     * @memberof SnippetInvitationPayload
     */
    'workspaceId': string;
    /**
     * 
     * @type {UserInfo}
     * @memberof SnippetInvitationPayload
     */
    'userFrom'?: UserInfo;
    /**
     * 
     * @type {SnippetInfo}
     * @memberof SnippetInvitationPayload
     */
    'snippetInfo': SnippetInfo;
}
/**
 * Payload object for notification request on snippet mention to user.
 * @export
 * @interface SnippetMentionCommentPayload
 */
export interface SnippetMentionCommentPayload {
    /**
     * 
     * @type {UserInfo}
     * @memberof SnippetMentionCommentPayload
     */
    'userTo': UserInfo;
    /**
     * 
     * @type {string}
     * @memberof SnippetMentionCommentPayload
     */
    'workspaceId': string;
    /**
     * 
     * @type {UserInfo}
     * @memberof SnippetMentionCommentPayload
     */
    'userFrom'?: UserInfo;
    /**
     * 
     * @type {SnippetInfo}
     * @memberof SnippetMentionCommentPayload
     */
    'snippetInfo': SnippetInfo;
}
/**
 * 
 * @export
 * @interface SnippetModel
 */
export interface SnippetModel {
    /**
     * 
     * @type {string}
     * @memberof SnippetModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnippetModel
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnippetModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof SnippetModel
     */
    'updateDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnippetModel
     */
    'ownerId': string;
    /**
     * 
     * @type {SnippetState}
     * @memberof SnippetModel
     */
    'state': SnippetState;
    /**
     * 
     * @type {string}
     * @memberof SnippetModel
     */
    'snippetId': string;
    /**
     * 
     * @type {string}
     * @memberof SnippetModel
     */
    'versionId': string;
    /**
     * 
     * @type {string}
     * @memberof SnippetModel
     */
    'versionCreateDate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SnippetModel
     */
    'sharedWith'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SnippetModel
     */
    'lastEditUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnippetModel
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnippetModel
     */
    'document'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SnippetModel
     */
    'demoArtifact'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SnippetModel
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {ArtifactStateModel}
     * @memberof SnippetModel
     */
    'artifactState'?: ArtifactStateModel;
}
/**
 * 
 * @export
 * @interface SnippetPublishResponse
 */
export interface SnippetPublishResponse {
    /**
     * 
     * @type {APIMessage}
     * @memberof SnippetPublishResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {string}
     * @memberof SnippetPublishResponse
     */
    'workspaceId': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum SnippetState {
    Draft = 'draft',
    Publish = 'publish'
}

/**
 * 
 * @export
 * @interface SnippetVersionModel
 */
export interface SnippetVersionModel {
    /**
     * 
     * @type {string}
     * @memberof SnippetVersionModel
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof SnippetVersionModel
     */
    'snippetId': string;
    /**
     * 
     * @type {string}
     * @memberof SnippetVersionModel
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof SnippetVersionModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof SnippetVersionModel
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof SnippetVersionModel
     */
    'versionId': string;
    /**
     * 
     * @type {string}
     * @memberof SnippetVersionModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SnippetVersionModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof SnippetVersionModel
     */
    'document'?: string;
}
/**
 * 
 * @export
 * @interface SnowflakeBasicCredentialData
 */
export interface SnowflakeBasicCredentialData {
    /**
     * 
     * @type {string}
     * @memberof SnowflakeBasicCredentialData
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeBasicCredentialData
     */
    'password': string;
}
/**
 * Cloud providers have different credentials. These classes are to help enforce type safety.
 * @export
 * @interface SnowflakeBasicCredentialModel
 */
export interface SnowflakeBasicCredentialModel {
    /**
     * 
     * @type {string}
     * @memberof SnowflakeBasicCredentialModel
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeBasicCredentialModel
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface SnowflakeConnectorMetadataModel
 */
export interface SnowflakeConnectorMetadataModel {
    /**
     * 
     * @type {string}
     * @memberof SnowflakeConnectorMetadataModel
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeConnectorMetadataModel
     */
    'accountUrl'?: string;
}
/**
 * 
 * @export
 * @interface SnowflakeStorageResourceModel
 */
export interface SnowflakeStorageResourceModel {
    /**
     * 
     * @type {string}
     * @memberof SnowflakeStorageResourceModel
     */
    'dbName': string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeStorageResourceModel
     */
    'tableName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeStorageResourceModel
     */
    'snowflakeSchema'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum SortOrder {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum StageStates {
    None = 'none',
    Develop = 'develop',
    Staging = 'staging',
    Production = 'production'
}

/**
 * 
 * @export
 * @interface StartFileInferenceRequest
 */
export interface StartFileInferenceRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof StartFileInferenceRequest
     */
    'featureColumns': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StartFileInferenceRequest
     */
    'fileUri': string;
    /**
     * 
     * @type {string}
     * @memberof StartFileInferenceRequest
     */
    'fileStorageType'?: string;
    /**
     * 
     * @type {string}
     * @memberof StartFileInferenceRequest
     */
    'fileStorageFormat'?: string;
}
/**
 * 
 * @export
 * @interface StartFileInferenceResponse
 */
export interface StartFileInferenceResponse {
    /**
     * 
     * @type {string}
     * @memberof StartFileInferenceResponse
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof StartFileInferenceResponse
     */
    'inferenceId': string;
}
/**
 * 
 * @export
 * @interface StartPendingModelAppRequest
 */
export interface StartPendingModelAppRequest {
    /**
     * 
     * @type {string}
     * @memberof StartPendingModelAppRequest
     */
    'modelArtifactSignedUrl': string;
    /**
     * 
     * @type {string}
     * @memberof StartPendingModelAppRequest
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof StartPendingModelAppRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof StartPendingModelAppRequest
     */
    'datasetId': string;
}
/**
 * 
 * @export
 * @interface StartPendingModelAppResponse
 */
export interface StartPendingModelAppResponse {
    /**
     * 
     * @type {boolean}
     * @memberof StartPendingModelAppResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof StartPendingModelAppResponse
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof StartPendingModelAppResponse
     */
    'status'?: string;
    /**
     * 
     * @type {object}
     * @memberof StartPendingModelAppResponse
     */
    'data'?: object;
}
/**
 * 
 * @export
 * @interface StateChangeAck
 */
export interface StateChangeAck {
    /**
     * 
     * @type {boolean}
     * @memberof StateChangeAck
     */
    'ack': boolean;
}
/**
 * Response Model for - /v1/workspace/{workspace_id}/recordings/soft-delete - /v1/workspace/{workspace_id}/recordings/undo-soft-delete
 * @export
 * @interface StateChangeResponse
 */
export interface StateChangeResponse {
    /**
     * 
     * @type {StateChangeAck}
     * @memberof StateChangeResponse
     */
    'response': StateChangeAck;
    /**
     * 
     * @type {string}
     * @memberof StateChangeResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface StopAllAnalyzersRequest
 */
export interface StopAllAnalyzersRequest {
    /**
     * 
     * @type {string}
     * @memberof StopAllAnalyzersRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof StopAllAnalyzersRequest
     */
    'teamId'?: string;
}
/**
 * 
 * @export
 * @interface StopAnalysesResponse
 */
export interface StopAnalysesResponse {
    /**
     * 
     * @type {TaskWithStatus}
     * @memberof StopAnalysesResponse
     */
    'response': TaskWithStatus;
    /**
     * 
     * @type {boolean}
     * @memberof StopAnalysesResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof StopAnalysesResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface StopExpiredModelAppResponse
 */
export interface StopExpiredModelAppResponse {
    /**
     * 
     * @type {boolean}
     * @memberof StopExpiredModelAppResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof StopExpiredModelAppResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface StopModelAppsRequest
 */
export interface StopModelAppsRequest {
    /**
     * 
     * @type {Array<StopModelMetadata>}
     * @memberof StopModelAppsRequest
     */
    'modelMetadata': Array<StopModelMetadata>;
}
/**
 * 
 * @export
 * @interface StopModelMetadata
 */
export interface StopModelMetadata {
    /**
     * 
     * @type {string}
     * @memberof StopModelMetadata
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof StopModelMetadata
     */
    'appName': string;
}
/**
 * Where the data_analyzer is stored
 * @export
 * @enum {string}
 */

export enum StorageType {
    S3 = 's3',
    Gcs = 'gcs',
    Abfs = 'abfs',
    Local = 'local',
    London = 'london',
    SecretVault = 'secret_vault',
    FileStore = 'file_store',
    Snowflake = 'snowflake',
    AzureBlob = 'azure_blob',
    Postgresql = 'postgresql',
    GoogleSheet = 'google_sheet',
    MarkovLibrary = 'markov_library',
    GoogleDrive = 'google_drive',
    Notion = 'notion',
    Hubspot = 'hubspot',
    Airtable = 'airtable',
    GoogleAnalytics = 'google-analytics',
    LinkedinAds = 'linkedin-ads',
    Salesforce = 'salesforce',
    Zendesk = 'zendesk',
    GoogleAds = 'google_ads',
    Gong = 'gong'
}

/**
 * 
 * @export
 * @interface StringColumnExpression
 */
export interface StringColumnExpression {
    /**
     * 
     * @type {ExpressionType}
     * @memberof StringColumnExpression
     */
    'type': ExpressionType;
    /**
     * 
     * @type {string}
     * @memberof StringColumnExpression
     */
    'column': string;
    /**
     * 
     * @type {string | number}
     * @memberof StringColumnExpression
     */
    'value'?: string | number;
    /**
     * 
     * @type {boolean}
     * @memberof StringColumnExpression
     */
    'ignoreCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StringColumnExpression
     */
    'ignoreWhiteSpace'?: boolean;
}
/**
 * studio id for workspace
 * @export
 * @enum {string}
 */

export enum StudioIdType {
    DataStudio = 'DATA_STUDIO',
    ModelStudio = 'MODEL_STUDIO',
    Snippets = 'SNIPPETS',
    Workflows = 'WORKFLOWS',
    AppStore = 'APP_STORE'
}

/**
 * 
 * @export
 * @interface StyleGuideConfigRow
 */
export interface StyleGuideConfigRow {
    /**
     * 
     * @type {string}
     * @memberof StyleGuideConfigRow
     */
    'enabled': string;
    /**
     * 
     * @type {string}
     * @memberof StyleGuideConfigRow
     */
    'ruleId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleGuideConfigRow
     */
    'styleDescription': string;
    /**
     * 
     * @type {string}
     * @memberof StyleGuideConfigRow
     */
    'recordId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleGuideConfigRow
     */
    'createDate': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum StyleGuideContextType {
    Generic = 'generic',
    Title = 'title',
    Header = 'header',
    Footer = 'footer',
    Body = 'body',
    BulletPoints = 'bullet_points',
    Bibliography = 'bibliography',
    Table = 'table'
}

/**
 * 
 * @export
 * @interface StyleGuideRule
 */
export interface StyleGuideRule {
    /**
     * 
     * @type {string}
     * @memberof StyleGuideRule
     */
    'match': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleGuideRule
     */
    'replaceBy': Array<string>;
}
/**
 * 
 * @export
 * @interface StyleGuideRuleOptions
 */
export interface StyleGuideRuleOptions {
    /**
     * 
     * @type {boolean}
     * @memberof StyleGuideRuleOptions
     */
    'useRegex'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StyleGuideRuleOptions
     */
    'matchCase'?: boolean;
}
/**
 * 
 * @export
 * @interface StyleGuideRuleResponse
 */
export interface StyleGuideRuleResponse {
    /**
     * 
     * @type {string}
     * @memberof StyleGuideRuleResponse
     */
    'match': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleGuideRuleResponse
     */
    'replaceBy': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StyleGuideRuleResponse
     */
    'ruleId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleGuideRuleResponse
     */
    'ruleDescription'?: string;
}
/**
 * 
 * @export
 * @interface StyleGuideRuleSetResponse
 */
export interface StyleGuideRuleSetResponse {
    /**
     * 
     * @type {string}
     * @memberof StyleGuideRuleSetResponse
     */
    'ruleSetId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleGuideRuleSetResponse
     */
    'ruleSetName': string;
    /**
     * 
     * @type {string}
     * @memberof StyleGuideRuleSetResponse
     */
    'ruleSetDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof StyleGuideRuleSetResponse
     */
    'ruleCount'?: number;
}
/**
 * CMOS style guide type
 * @export
 * @enum {string}
 */

export enum StyleGuideType {
    Cmos = 'CMOS',
    Ada = 'ADA',
    CustomRules = 'CUSTOM_RULES',
    None = 'NONE'
}

/**
 * Subscription object for api response
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'subscriptionId': string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'subscriptionType': string;
    /**
     * 
     * @type {SubscriptionState}
     * @memberof Subscription
     */
    'subscriptionState': SubscriptionState;
    /**
     * 
     * @type {object}
     * @memberof Subscription
     */
    'usedUnits': object;
    /**
     * 
     * @type {object}
     * @memberof Subscription
     */
    'usableUnits': object;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'updateDate': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SubscriptionState {
    Inactive = 'INACTIVE',
    Active = 'ACTIVE',
    Expired = 'EXPIRED'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum SubscriptionType {
    Trial = 'TRIAL',
    Starter = 'STARTER',
    EnterpriseLite = 'ENTERPRISE_LITE',
    EnterprisePlus = 'ENTERPRISE_PLUS'
}

/**
 * Subscription object for api response
 * @export
 * @interface SubscriptionWithInfoOnCreditFactors
 */
export interface SubscriptionWithInfoOnCreditFactors {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionWithInfoOnCreditFactors
     */
    'subscriptionId': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionWithInfoOnCreditFactors
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionWithInfoOnCreditFactors
     */
    'subscriptionType': string;
    /**
     * 
     * @type {SubscriptionState}
     * @memberof SubscriptionWithInfoOnCreditFactors
     */
    'subscriptionState': SubscriptionState;
    /**
     * 
     * @type {object}
     * @memberof SubscriptionWithInfoOnCreditFactors
     */
    'usedUnits': object;
    /**
     * 
     * @type {object}
     * @memberof SubscriptionWithInfoOnCreditFactors
     */
    'usableUnits': object;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionWithInfoOnCreditFactors
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionWithInfoOnCreditFactors
     */
    'updateDate': string;
    /**
     * 
     * @type {object}
     * @memberof SubscriptionWithInfoOnCreditFactors
     */
    'creditsFactor': object;
}
/**
 * List of subscriptions
 * @export
 * @interface Subscriptions
 */
export interface Subscriptions {
    /**
     * 
     * @type {Array<Subscription>}
     * @memberof Subscriptions
     */
    'subscriptions': Array<Subscription>;
}
/**
 * 
 * @export
 * @interface Subset
 */
export interface Subset {
    /**
     * 
     * @type {string}
     * @memberof Subset
     */
    'datasetId': string;
    /**
     * 
     * @type {string}
     * @memberof Subset
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof Subset
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof Subset
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Subset
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Subset
     */
    'subsetId': string;
    /**
     * 
     * @type {string}
     * @memberof Subset
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Subset
     */
    'taskletIds': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Subset
     */
    'numDatasetRecords': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Subset
     */
    'datasetRecords': Array<string>;
    /**
     * 
     * @type {Array<SubsetRegion>}
     * @memberof Subset
     */
    'regions': Array<SubsetRegion>;
}
/**
 * Subset data analysis results file for a all tasklets
 * @export
 * @interface SubsetAnalysisResultsDownload
 */
export interface SubsetAnalysisResultsDownload {
    /**
     * 
     * @type {string}
     * @memberof SubsetAnalysisResultsDownload
     */
    'subsetId': string;
    /**
     * 
     * @type {string}
     * @memberof SubsetAnalysisResultsDownload
     */
    'downloadLink': string;
}
/**
 * Response to API to create a download link for subset analysis results
 * @export
 * @interface SubsetAnalysisResultsDownloadLinkResponse
 */
export interface SubsetAnalysisResultsDownloadLinkResponse {
    /**
     * 
     * @type {SubsetAnalysisResultsDownload}
     * @memberof SubsetAnalysisResultsDownloadLinkResponse
     */
    'response': SubsetAnalysisResultsDownload;
    /**
     * 
     * @type {string}
     * @memberof SubsetAnalysisResultsDownloadLinkResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface SubsetAnalysisTask
 */
export interface SubsetAnalysisTask {
    /**
     * 
     * @type {string}
     * @memberof SubsetAnalysisTask
     */
    'taskletId': string;
    /**
     * 
     * @type {string}
     * @memberof SubsetAnalysisTask
     */
    'taskletName': string;
    /**
     * 
     * @type {string}
     * @memberof SubsetAnalysisTask
     */
    'workspaceId': string;
    /**
     * 
     * @type {number}
     * @memberof SubsetAnalysisTask
     */
    'runtimeEstimate'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubsetAnalysisTask
     */
    'taskId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubsetAnalysisTask
     */
    'createDate'?: string;
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof SubsetAnalysisTask
     */
    'status'?: AnalysisStateNames;
    /**
     * 
     * @type {string}
     * @memberof SubsetAnalysisTask
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubsetAnalysisTask
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubsetAnalysisTask
     */
    'endTime'?: string;
}
/**
 *     User creates a subset by highlighting multiple regions in the embeddings view.     A region defines the set of points related to a single selection.      Embeddings:          °　　　•　　.°•　　　 ✯              ★　*　　　　　°　　　　　°·     .　　　•　° ★　• ☄     ☆  Subset and Regions:     °　　　•　　.°•　　　{ ✯ }  Region 1      ★　*　　　　　°　　　　　°· . 　　　{•　° ★　• ☄} Region 2 {☆} Region 3  All {selected points} belong to Subset 1. Subset 1 is composed of: Region 1, 2 and 3
 * @export
 * @interface SubsetRegion
 */
export interface SubsetRegion {
    /**
     * 
     * @type {number}
     * @memberof SubsetRegion
     */
    'region': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubsetRegion
     */
    'datasetRecords': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SubsetRegion
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface SubsetRelabelCreate
 */
export interface SubsetRelabelCreate {
    /**
     * Name to be given to the new target.
     * @type {string}
     * @memberof SubsetRelabelCreate
     */
    'newTargetName': string;
    /**
     * ID of the subset of dataset to be used in relabeling operation
     * @type {string}
     * @memberof SubsetRelabelCreate
     */
    'subsetId': string;
    /**
     * List of labels user wants to use in the data operation. There are in addition to the already identified classes.
     * @type {Array<string>}
     * @memberof SubsetRelabelCreate
     */
    'labels'?: Array<string>;
}
/**
 * 
 * @export
 * @interface SubsetRelabelingDetailsModel
 */
export interface SubsetRelabelingDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof SubsetRelabelingDetailsModel
     */
    'newTargetName': string;
    /**
     * 
     * @type {boolean}
     * @memberof SubsetRelabelingDetailsModel
     */
    'recommendationsAvailable': boolean;
    /**
     * 
     * @type {string}
     * @memberof SubsetRelabelingDetailsModel
     */
    'originalTarget': string;
    /**
     * 
     * @type {number}
     * @memberof SubsetRelabelingDetailsModel
     */
    'numMislabeled': number;
    /**
     * 
     * @type {string}
     * @memberof SubsetRelabelingDetailsModel
     */
    'subsetId': string;
    /**
     * ID of the dataset created after manually labeling the subsets
     * @type {string}
     * @memberof SubsetRelabelingDetailsModel
     */
    'derivedDatasetId'?: string;
    /**
     * Strategy used for relabeling, such as \'LLM\', \'Custom Model\', etc.
     * @type {SupervisedRelabelingStrategy}
     * @memberof SubsetRelabelingDetailsModel
     */
    'supervisedRelabelingStrategy'?: SupervisedRelabelingStrategy;
    /**
     * ID of model used for relabeling
     * @type {string}
     * @memberof SubsetRelabelingDetailsModel
     */
    'supervisionModelId'?: string;
    /**
     * Current state of the supervised relabeling process
     * @type {SupervisedRelabelingState}
     * @memberof SubsetRelabelingDetailsModel
     */
    'supervisedRelabelingState'?: SupervisedRelabelingState;
    /**
     * ID of the dataset created using the baseline model created on baseline model trained on subset labeled by the user
     * @type {string}
     * @memberof SubsetRelabelingDetailsModel
     */
    'supervisedDatasetId'?: string;
    /**
     * List of subset region ids (if available). A subset is composed of regions that user had selected together.Regions are identified by an integer index. List of points inside a region can be fetched through the subsets APIs.
     * @type {Array<number>}
     * @memberof SubsetRelabelingDetailsModel
     */
    'regions'?: Array<number>;
    /**
     * Name to be assigned to the auto-relabeled dataset
     * @type {string}
     * @memberof SubsetRelabelingDetailsModel
     */
    'supervisedDatasetName'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum SubsetRelabelingState {
    NotStarted = 'NOT_STARTED',
    NotSupported = 'NOT_SUPPORTED',
    Prepared = 'PREPARED',
    Preparing = 'PREPARING',
    Failed = 'FAILED',
    Frozen = 'FROZEN',
    CreatingNewDatasetVersion = 'CREATING_NEW_DATASET_VERSION'
}

/**
 * Suggestions for the chunk
 * @export
 * @interface Suggestion
 */
export interface Suggestion {
    /**
     * 
     * @type {string}
     * @memberof Suggestion
     */
    'suggestionId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Suggestion
     */
    'replaceBy': Array<string>;
    /**
     * 
     * @type {SuggestionType}
     * @memberof Suggestion
     */
    'type': SuggestionType;
    /**
     * 
     * @type {SuggestionStatusType}
     * @memberof Suggestion
     */
    'status'?: SuggestionStatusType;
    /**
     * 
     * @type {SuggestionMetadata}
     * @memberof Suggestion
     */
    'suggestionMetadata'?: SuggestionMetadata;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum SuggestionAnalysisStatus {
    Processing = 'PROCESSING',
    Success = 'SUCCESS',
    Failure = 'FAILURE'
}

/**
 * 
 * @export
 * @interface SuggestionAnalysisStatusResponse
 */
export interface SuggestionAnalysisStatusResponse {
    /**
     * 
     * @type {SuggestionAnalysisStatus}
     * @memberof SuggestionAnalysisStatusResponse
     */
    'status': SuggestionAnalysisStatus;
    /**
     * 
     * @type {string}
     * @memberof SuggestionAnalysisStatusResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface SuggestionGeneratedMetrics
 */
export interface SuggestionGeneratedMetrics {
    /**
     * 
     * @type {Array<number>}
     * @memberof SuggestionGeneratedMetrics
     */
    'suggestionAccepted': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SuggestionGeneratedMetrics
     */
    'suggestionDeclined': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SuggestionGeneratedMetrics
     */
    'suggestionGenerated': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SuggestionGeneratedMetrics
     */
    'suggestionUnresolved': Array<number>;
}
/**
 * 
 * @export
 * @interface SuggestionMetadata
 */
export interface SuggestionMetadata {
    /**
     * 
     * @type {string}
     * @memberof SuggestionMetadata
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SuggestionMetadata
     */
    'ruleSetName'?: string;
}
/**
 * 
 * @export
 * @interface SuggestionModel
 */
export interface SuggestionModel {
    /**
     * 
     * @type {string}
     * @memberof SuggestionModel
     */
    'keyword': string;
    /**
     * 
     * @type {number}
     * @memberof SuggestionModel
     */
    'organicRank'?: number;
    /**
     * 
     * @type {number}
     * @memberof SuggestionModel
     */
    'sponsoredRank'?: number;
    /**
     * 
     * @type {KeywordAnalysisSpendSuggestion}
     * @memberof SuggestionModel
     */
    'suggestion'?: KeywordAnalysisSpendSuggestion;
}
/**
 * 
 * @export
 * @interface SuggestionState
 */
export interface SuggestionState {
    /**
     * 
     * @type {string}
     * @memberof SuggestionState
     */
    'suggestionId': string;
    /**
     * 
     * @type {SuggestionStatusType}
     * @memberof SuggestionState
     */
    'status': SuggestionStatusType;
}
/**
 * Status of the suggestion
 * @export
 * @enum {string}
 */

export enum SuggestionStatusType {
    Accepted = 'ACCEPTED',
    Declined = 'DECLINED',
    Pending = 'PENDING'
}

/**
 * Types of the suggestion
 * @export
 * @enum {string}
 */

export enum SuggestionType {
    Grammar = 'GRAMMAR',
    Tone = 'TONE',
    Spelling = 'SPELLING',
    Clarity = 'CLARITY',
    Voice = 'VOICE',
    Cmos = 'CMOS',
    Custom = 'CUSTOM',
    SpellingWithNoCorrection = 'SPELLING_WITH_NO_CORRECTION'
}

/**
 * 
 * @export
 * @interface Summary
 */
export interface Summary {
    /**
     * 
     * @type {number}
     * @memberof Summary
     */
    'human': number;
    /**
     * 
     * @type {number}
     * @memberof Summary
     */
    'ai': number;
}
/**
 * 
 * @export
 * @interface SupersetAdminAuthDetails
 */
export interface SupersetAdminAuthDetails {
    /**
     * 
     * @type {string}
     * @memberof SupersetAdminAuthDetails
     */
    'host': string;
    /**
     * 
     * @type {string}
     * @memberof SupersetAdminAuthDetails
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupersetAdminAuthDetails
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface SupersetWorkflowSetupResult
 */
export interface SupersetWorkflowSetupResult {
    /**
     * 
     * @type {string}
     * @memberof SupersetWorkflowSetupResult
     */
    'token': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum SupersetWorkflowSetupStatus {
    Running = 'RUNNING',
    Ready = 'READY',
    Failed = 'FAILED',
    NotStarted = 'NOT_STARTED'
}

/**
 * Enumeration of possible states of a supervised relabeling process
 * @export
 * @enum {string}
 */

export enum SupervisedRelabelingState {
    NotStarted = 'NOT_STARTED',
    NotSupported = 'NOT_SUPPORTED',
    TrainingInProgress = 'TRAINING_IN_PROGRESS',
    TrainingCompleted = 'TRAINING_COMPLETED',
    RelabelingInProgress = 'RELABELING_IN_PROGRESS',
    RelabelingCompleted = 'RELABELING_COMPLETED',
    Failed = 'FAILED'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum SupervisedRelabelingStrategy {
    BaselineModel = 'BASELINE_MODEL',
    Llm = 'LLM',
    CustomModel = 'CUSTOM_MODEL'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum SupportedLLMs {
    Openai = 'openai',
    Gpt4 = 'gpt-4',
    GooglePalm = 'google_palm',
    AzureOpenai = 'azure_openai',
    LangchainLlm = 'langchain_llm',
    GoogleGemini = 'google_gemini'
}

/**
 * 
 * @export
 * @interface SyncAirbyteResourceResponse
 */
export interface SyncAirbyteResourceResponse {
    /**
     * 
     * @type {string}
     * @memberof SyncAirbyteResourceResponse
     */
    'resourceId': string;
    /**
     * 
     * @type {string}
     * @memberof SyncAirbyteResourceResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {boolean}
     * @memberof SyncAirbyteResourceResponse
     */
    'ack': boolean;
}
/**
 * Tag metadata
 * @export
 * @interface TagMetadata
 */
export interface TagMetadata {
    /**
     * 
     * @type {string}
     * @memberof TagMetadata
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TagMetadata
     */
    'tagId': string;
    /**
     * 
     * @type {string}
     * @memberof TagMetadata
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagMetadata
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagMetadata
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagMetadata
     */
    'normName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagMetadata
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagMetadata
     */
    'updateDate'?: string;
}
/**
 * 
 * @export
 * @interface TaggingMetrics
 */
export interface TaggingMetrics {
    /**
     * 
     * @type {number}
     * @memberof TaggingMetrics
     */
    'precision': number;
    /**
     * 
     * @type {number}
     * @memberof TaggingMetrics
     */
    'recall': number;
    /**
     * 
     * @type {number}
     * @memberof TaggingMetrics
     */
    'f1-score': number;
    /**
     * 
     * @type {number}
     * @memberof TaggingMetrics
     */
    'support': number;
    /**
     * 
     * @type {number}
     * @memberof TaggingMetrics
     */
    'accuracy'?: number;
}
/**
 * 
 * @export
 * @interface TargetCreditLimits
 */
export interface TargetCreditLimits {
    /**
     * 
     * @type {number}
     * @memberof TargetCreditLimits
     */
    'storageCredits': number;
    /**
     * 
     * @type {number}
     * @memberof TargetCreditLimits
     */
    'trackingCredits': number;
    /**
     * 
     * @type {number}
     * @memberof TargetCreditLimits
     */
    'computeCredits': number;
}
/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'taskletId': string;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'taskRunType': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'dataType': string;
}
/**
 * 
 * @export
 * @interface TaskInfo
 */
export interface TaskInfo {
    /**
     * 
     * @type {string}
     * @memberof TaskInfo
     */
    'taskRunType': string;
    /**
     * 
     * @type {string}
     * @memberof TaskInfo
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof TaskInfo
     */
    'taskletId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskInfo
     */
    'runId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskInfo
     */
    'recordId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskInfo
     */
    'compositeKey': string;
    /**
     * 
     * @type {string}
     * @memberof TaskInfo
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskInfo
     */
    'taskName'?: string;
}
/**
 * 
 * @export
 * @interface TaskWithStatus
 */
export interface TaskWithStatus {
    /**
     * 
     * @type {boolean}
     * @memberof TaskWithStatus
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof TaskWithStatus
     */
    'taskId': string;
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof TaskWithStatus
     */
    'status': AnalysisStateNames;
}
/**
 * Model representing a tasklet\'s metrics information.  Attributes:     tasklet_metric_id (str): A unique id provided to a tasklet metric. This field is unique across tasklets.     tasklet_metric (str): The type of metric, e.g. unigram / topic_distribution / sentiment etc.     tasklet_metric_name (str): Display name assigned to the metric.     description (str, optional): A description of the metric.
 * @export
 * @interface TaskletMetrics
 */
export interface TaskletMetrics {
    /**
     * 
     * @type {string}
     * @memberof TaskletMetrics
     */
    'taskletMetricId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskletMetrics
     */
    'taskletMetric': string;
    /**
     * 
     * @type {string}
     * @memberof TaskletMetrics
     */
    'taskletMetricName': string;
    /**
     * 
     * @type {string}
     * @memberof TaskletMetrics
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface TaskletRunDetails
 */
export interface TaskletRunDetails {
    /**
     * 
     * @type {string}
     * @memberof TaskletRunDetails
     */
    'taskletId': string;
    /**
     * 
     * @type {Array<SegmentRunDetails>}
     * @memberof TaskletRunDetails
     */
    'runDetails': Array<SegmentRunDetails>;
}
/**
 * This class defines the structure for Tasklets and Tasklet Groups in the Vienna framework.  Attributes:     tasklet_ids (List[DatasetTasklet]): A list of all available tasklets in the workspace.     groups (List[DatasetTaskletGroup]): A list of all Tasklet Groups in the workspace.
 * @export
 * @interface TaskletsAndGroups
 */
export interface TaskletsAndGroups {
    /**
     * 
     * @type {Array<DatasetTasklet>}
     * @memberof TaskletsAndGroups
     */
    'taskletIds': Array<DatasetTasklet>;
    /**
     * 
     * @type {Array<DatasetTaskletGroup>}
     * @memberof TaskletsAndGroups
     */
    'groups': Array<DatasetTaskletGroup>;
}
/**
 * This object contains summary of various tasks a dataset  num_tasks = num_created + num_in_progress + num_error + num_completed
 * @export
 * @interface TasksStateSummary
 */
export interface TasksStateSummary {
    /**
     * 
     * @type {number}
     * @memberof TasksStateSummary
     */
    'numTasks': number;
    /**
     * 
     * @type {number}
     * @memberof TasksStateSummary
     */
    'numCreated': number;
    /**
     * 
     * @type {number}
     * @memberof TasksStateSummary
     */
    'numInProgress': number;
    /**
     * 
     * @type {number}
     * @memberof TasksStateSummary
     */
    'numError': number;
    /**
     * 
     * @type {number}
     * @memberof TasksStateSummary
     */
    'numCompleted': number;
}
/**
 * This object contains summary of various tasks a dataset  num_tasks = num_created + num_in_progress + num_error + num_completed
 * @export
 * @interface TasksStateSummary1
 */
export interface TasksStateSummary1 {
    /**
     * 
     * @type {number}
     * @memberof TasksStateSummary1
     */
    'numTasks'?: number;
    /**
     * 
     * @type {number}
     * @memberof TasksStateSummary1
     */
    'numCreated'?: number;
    /**
     * 
     * @type {number}
     * @memberof TasksStateSummary1
     */
    'numInProgress'?: number;
    /**
     * 
     * @type {number}
     * @memberof TasksStateSummary1
     */
    'numError'?: number;
    /**
     * 
     * @type {number}
     * @memberof TasksStateSummary1
     */
    'numCompleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof TasksStateSummary1
     */
    'numStopped'?: number;
}
/**
 * Team object for api response
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {any}
     * @memberof Team
     */
    'teamId': any;
    /**
     * 
     * @type {any}
     * @memberof Team
     */
    'workspaceId': any;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'teamName': string;
    /**
     * 
     * @type {TeamState}
     * @memberof Team
     */
    'teamState'?: TeamState;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'updateDate': string;
    /**
     * 
     * @type {any}
     * @memberof Team
     */
    'parentTeamId'?: any;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'membersCount'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TeamState {
    Inactive = 'INACTIVE',
    Active = 'ACTIVE'
}

/**
 * Paginated list of teams for get teams api response
 * @export
 * @interface TeamsSearchResults
 */
export interface TeamsSearchResults {
    /**
     * 
     * @type {Array<Team>}
     * @memberof TeamsSearchResults
     */
    'results'?: Array<Team>;
    /**
     * 
     * @type {number}
     * @memberof TeamsSearchResults
     */
    'numRecords'?: number;
}
/**
 * 
 * @export
 * @interface TemplateListResponse
 */
export interface TemplateListResponse {
    /**
     * 
     * @type {Array<TemplateModel>}
     * @memberof TemplateListResponse
     */
    'templates': Array<TemplateModel>;
    /**
     * 
     * @type {number}
     * @memberof TemplateListResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface TemplateModel
 */
export interface TemplateModel {
    /**
     * 
     * @type {string}
     * @memberof TemplateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TemplateModel
     */
    'templateId': string;
    /**
     * 
     * @type {string}
     * @memberof TemplateModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TemplateModel
     */
    'author': string;
    /**
     * 
     * @type {UpsertDAGRequest}
     * @memberof TemplateModel
     */
    'dag': UpsertDAGRequest;
    /**
     * 
     * @type {Array<string>}
     * @memberof TemplateModel
     */
    'keyOperators'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TemplateModel
     */
    'category'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TemplateModel
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TemplateModel
     */
    'iconUrl': string;
    /**
     * 
     * @type {string}
     * @memberof TemplateModel
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface Text
 */
export interface Text {
    /**
     * 
     * @type {TextDetails}
     * @memberof Text
     */
    'chars': TextDetails;
    /**
     * 
     * @type {TextDetails}
     * @memberof Text
     */
    'words': TextDetails;
}
/**
 * 
 * @export
 * @interface TextAnalysisMetrics
 */
export interface TextAnalysisMetrics {
    /**
     * 
     * @type {DescriptiveStatistics}
     * @memberof TextAnalysisMetrics
     */
    'descriptiveStatistics'?: DescriptiveStatistics;
    /**
     * 
     * @type {LexicalDiversityMetrics}
     * @memberof TextAnalysisMetrics
     */
    'lexicalDiversity'?: LexicalDiversityMetrics;
    /**
     * 
     * @type {ReadabilityMetrics}
     * @memberof TextAnalysisMetrics
     */
    'readability'?: ReadabilityMetrics;
}
/**
 * 
 * @export
 * @interface TextDetails
 */
export interface TextDetails {
    /**
     * 
     * @type {Array<number>}
     * @memberof TextDetails
     */
    'starts': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TextDetails
     */
    'lengths': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TextDetails
     */
    'groupIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface TextToSpeechRequestModel
 */
export interface TextToSpeechRequestModel {
    /**
     * 
     * @type {string}
     * @memberof TextToSpeechRequestModel
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface TextToSpeechResponseModel
 */
export interface TextToSpeechResponseModel {
    /**
     * 
     * @type {string}
     * @memberof TextToSpeechResponseModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {any}
     * @memberof TextToSpeechResponseModel
     */
    'audioPayload': any;
}
/**
 * 
 * @export
 * @interface TimescaledbInsertRecords
 */
export interface TimescaledbInsertRecords {
    /**
     * 
     * @type {Array<object>}
     * @memberof TimescaledbInsertRecords
     */
    'records': Array<object>;
}
/**
 * 
 * @export
 * @interface TimescaledbInsertRecordsResponse
 */
export interface TimescaledbInsertRecordsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof TimescaledbInsertRecordsResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface TokenRequest
 */
export interface TokenRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'client_id': string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'client_secret': string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'audience': string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'grant_type': string;
    /**
     * 
     * @type {object}
     * @memberof TokenRequest
     */
    'claims'?: object;
}
/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'token_type': string;
    /**
     * 
     * @type {number}
     * @memberof TokenResponse
     */
    'expires_in': number;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'refresh_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'id_token'?: string;
}
/**
 * Response Model for - /v1/token/validate
 * @export
 * @interface TokenValidationResponse
 */
export interface TokenValidationResponse {
    /**
     * 
     * @type {APIToken}
     * @memberof TokenValidationResponse
     */
    'token'?: APIToken;
    /**
     * 
     * @type {boolean}
     * @memberof TokenValidationResponse
     */
    'isValid': boolean;
}
/**
 * 
 * @export
 * @interface TopKFeatures
 */
export interface TopKFeatures {
    /**
     * 
     * @type {AnalysisData}
     * @memberof TopKFeatures
     */
    'data': AnalysisData;
}
/**
 * 
 * @export
 * @interface TopKFeaturesResponse
 */
export interface TopKFeaturesResponse {
    /**
     * 
     * @type {AnalysisStateNames}
     * @memberof TopKFeaturesResponse
     */
    'analysisState': AnalysisStateNames;
    /**
     * 
     * @type {TopKFeatures}
     * @memberof TopKFeaturesResponse
     */
    'response'?: TopKFeatures;
    /**
     * 
     * @type {string}
     * @memberof TopKFeaturesResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof TopKFeaturesResponse
     */
    'datasetId': string;
}
/**
 * 
 * @export
 * @interface TopicExplanation
 */
export interface TopicExplanation {
    /**
     * 
     * @type {Array<string>}
     * @memberof TopicExplanation
     */
    'words': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TopicExplanation
     */
    'topic': string;
    /**
     * 
     * @type {string}
     * @memberof TopicExplanation
     */
    'explanation': string;
}
/**
 * 
 * @export
 * @interface TopicExplanationResponse
 */
export interface TopicExplanationResponse {
    /**
     * 
     * @type {Array<TopicExplanation>}
     * @memberof TopicExplanationResponse
     */
    'data': Array<TopicExplanation>;
}
/**
 * 
 * @export
 * @interface TransitionHistory
 */
export interface TransitionHistory {
    /**
     * 
     * @type {TransitionStageStates}
     * @memberof TransitionHistory
     */
    'transitionFrom': TransitionStageStates;
    /**
     * 
     * @type {TransitionStageStates}
     * @memberof TransitionHistory
     */
    'transitionTo': TransitionStageStates;
    /**
     * 
     * @type {TransitionHistoryType}
     * @memberof TransitionHistory
     */
    'transitionType': TransitionHistoryType;
    /**
     * 
     * @type {string}
     * @memberof TransitionHistory
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof TransitionHistory
     */
    'modelName': string;
    /**
     * 
     * @type {string}
     * @memberof TransitionHistory
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof TransitionHistory
     */
    'createDate': string;
    /**
     * 
     * @type {number}
     * @memberof TransitionHistory
     */
    'modelVersion'?: number;
    /**
     * 
     * @type {string}
     * @memberof TransitionHistory
     */
    'projectId'?: string;
    /**
     * 
     * @type {UserDetails}
     * @memberof TransitionHistory
     */
    'userDetails'?: UserDetails;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TransitionHistoryType {
    Link = 'link',
    Unlink = 'unlink',
    Transition = 'transition'
}

/**
 * 
 * @export
 * @interface TransitionModelStageHistoryResponse
 */
export interface TransitionModelStageHistoryResponse {
    /**
     * 
     * @type {string}
     * @memberof TransitionModelStageHistoryResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<TransitionModelStageResponse>}
     * @memberof TransitionModelStageHistoryResponse
     */
    'response': Array<TransitionModelStageResponse>;
    /**
     * 
     * @type {number}
     * @memberof TransitionModelStageHistoryResponse
     */
    'numRecords': number;
    /**
     * 
     * @type {number}
     * @memberof TransitionModelStageHistoryResponse
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface TransitionModelStageInternalRequest
 */
export interface TransitionModelStageInternalRequest {
    /**
     * 
     * @type {StageStates}
     * @memberof TransitionModelStageInternalRequest
     */
    'transitionTo': StageStates;
    /**
     * 
     * @type {string}
     * @memberof TransitionModelStageInternalRequest
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface TransitionModelStageRequest
 */
export interface TransitionModelStageRequest {
    /**
     * 
     * @type {StageStates}
     * @memberof TransitionModelStageRequest
     */
    'transitionTo': StageStates;
}
/**
 * 
 * @export
 * @interface TransitionModelStageResponse
 */
export interface TransitionModelStageResponse {
    /**
     * 
     * @type {StageStates}
     * @memberof TransitionModelStageResponse
     */
    'transitionFrom': StageStates;
    /**
     * 
     * @type {StageStates}
     * @memberof TransitionModelStageResponse
     */
    'transitionTo': StageStates;
    /**
     * 
     * @type {ModelTransitionHistoryType}
     * @memberof TransitionModelStageResponse
     */
    'transitionType': ModelTransitionHistoryType;
    /**
     * 
     * @type {string}
     * @memberof TransitionModelStageResponse
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof TransitionModelStageResponse
     */
    'modelName': string;
    /**
     * 
     * @type {string}
     * @memberof TransitionModelStageResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof TransitionModelStageResponse
     */
    'createDate': string;
    /**
     * 
     * @type {number}
     * @memberof TransitionModelStageResponse
     */
    'modelVersion'?: number;
    /**
     * 
     * @type {string}
     * @memberof TransitionModelStageResponse
     */
    'projectId'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TransitionStageStates {
    None = 'none',
    Develop = 'develop',
    Staging = 'staging',
    Production = 'production'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TranslateLanguage {
    Fr = 'fr',
    Es = 'es',
    ZhCn = 'zh-CN',
    En = 'en'
}

/**
 * 
 * @export
 * @interface TranslateSupportedLanguagesResponseModel
 */
export interface TranslateSupportedLanguagesResponseModel {
    /**
     * 
     * @type {string}
     * @memberof TranslateSupportedLanguagesResponseModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<TranslateLanguage>}
     * @memberof TranslateSupportedLanguagesResponseModel
     */
    'supportedLanguages': Array<TranslateLanguage>;
}
/**
 * 
 * @export
 * @interface TranslationRequestModel
 */
export interface TranslationRequestModel {
    /**
     * 
     * @type {string}
     * @memberof TranslationRequestModel
     */
    'text': string;
    /**
     * 
     * @type {TranslateLanguage}
     * @memberof TranslationRequestModel
     */
    'targetLang': TranslateLanguage;
}
/**
 * 
 * @export
 * @interface TranslationResponseModel
 */
export interface TranslationResponseModel {
    /**
     * 
     * @type {string}
     * @memberof TranslationResponseModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {any}
     * @memberof TranslationResponseModel
     */
    'translatedResponse': any;
}
/**
 * 
 * @export
 * @interface TriggerAnalysisResponse
 */
export interface TriggerAnalysisResponse {
    /**
     * 
     * @type {Array<TaskletRunDetails>}
     * @memberof TriggerAnalysisResponse
     */
    'response': Array<TaskletRunDetails>;
    /**
     * 
     * @type {string}
     * @memberof TriggerAnalysisResponse
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface TriggerAsyncDownloadResponse
 */
export interface TriggerAsyncDownloadResponse {
    /**
     * 
     * @type {boolean}
     * @memberof TriggerAsyncDownloadResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof TriggerAsyncDownloadResponse
     */
    'taskId': string;
    /**
     * 
     * @type {string}
     * @memberof TriggerAsyncDownloadResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof TriggerAsyncDownloadResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TriggerModelAppWorkflowRequest
 */
export interface TriggerModelAppWorkflowRequest {
    /**
     * 
     * @type {ModelAppWorkflowStep}
     * @memberof TriggerModelAppWorkflowRequest
     */
    'step': ModelAppWorkflowStep;
    /**
     * 
     * @type {Array<string>}
     * @memberof TriggerModelAppWorkflowRequest
     */
    'modelForEvaluation'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TriggerModelAppWorkflowRequest
     */
    'modelForApp'?: Array<string>;
}
/**
 * 
 * @export
 * @interface TriggerModelAppWorkflowResponse
 */
export interface TriggerModelAppWorkflowResponse {
    /**
     * 
     * @type {boolean}
     * @memberof TriggerModelAppWorkflowResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface TriggerNewTasksResponse
 */
export interface TriggerNewTasksResponse {
    /**
     * 
     * @type {boolean}
     * @memberof TriggerNewTasksResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface TriggerWorkflowOnEmailRequest
 */
export interface TriggerWorkflowOnEmailRequest {
    /**
     * 
     * @type {object}
     * @memberof TriggerWorkflowOnEmailRequest
     */
    'emailEvent': object;
}
/**
 * 
 * @export
 * @interface TriggerWorkflowOnEmailResponse
 */
export interface TriggerWorkflowOnEmailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof TriggerWorkflowOnEmailResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof TriggerWorkflowOnEmailResponse
     */
    'workflowId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TriggerWorkflowOnEmailResponse
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TriggerWorkflowOnEmailResponse
     */
    'workflowRunId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TriggerWorkflowOnEmailResponse
     */
    'messageId'?: string;
}
/**
 * This stores information required to render nodes on the UI
 * @export
 * @interface UINodeData
 */
export interface UINodeData {
    /**
     * 
     * @type {Position}
     * @memberof UINodeData
     */
    'position': Position;
    /**
     * 
     * @type {object}
     * @memberof UINodeData
     */
    'additional': object;
}
/**
 * 
 * @export
 * @interface UnlinkModelToRegistryRequest
 */
export interface UnlinkModelToRegistryRequest {
    /**
     * 
     * @type {string}
     * @memberof UnlinkModelToRegistryRequest
     */
    'modelId': string;
}
/**
 * 
 * @export
 * @interface UnlinkModelToRegistryResponse
 */
export interface UnlinkModelToRegistryResponse {
    /**
     * 
     * @type {string}
     * @memberof UnlinkModelToRegistryResponse
     */
    'registryId': string;
    /**
     * 
     * @type {string}
     * @memberof UnlinkModelToRegistryResponse
     */
    'modelId': string;
}
/**
 * 
 * @export
 * @interface UpdateAirbyteSourceStartDateRequest
 */
export interface UpdateAirbyteSourceStartDateRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAirbyteSourceStartDateRequest
     */
    'startDate': string;
}
/**
 * 
 * @export
 * @interface UpdateAirbyteSourceStartDateResponse
 */
export interface UpdateAirbyteSourceStartDateResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateAirbyteSourceStartDateResponse
     */
    'resourceId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAirbyteSourceStartDateResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAirbyteSourceStartDateResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface UpdateAllInAppNotificationsResponse
 */
export interface UpdateAllInAppNotificationsResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateAllInAppNotificationsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllInAppNotificationsResponse
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface UpdateAnalyseAddInRequest
 */
export interface UpdateAnalyseAddInRequest {
    /**
     * 
     * @type {ParagraphActionType}
     * @memberof UpdateAnalyseAddInRequest
     */
    'action': ParagraphActionType;
    /**
     * 
     * @type {Array<ParagraphTextResource>}
     * @memberof UpdateAnalyseAddInRequest
     */
    'paragraphs': Array<ParagraphTextResource>;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnalyseAddInRequest
     */
    'timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnalyseAddInRequest
     */
    'documentId': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAnalyseAddInRequest
     */
    'pageId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateAppBuilderRequest
 */
export interface UpdateAppBuilderRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppBuilderRequest
     */
    'appName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppBuilderRequest
     */
    'appDescription'?: string;
    /**
     * 
     * @type {AppProperties}
     * @memberof UpdateAppBuilderRequest
     */
    'appProperties'?: AppProperties;
}
/**
 * 
 * @export
 * @interface UpdateAppBuilderResponse
 */
export interface UpdateAppBuilderResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAppBuilderResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface UpdateAppDocumentResponse
 */
export interface UpdateAppDocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppDocumentResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAppDocumentResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface UpdateChatConversationThreadsArtifactStateRequestModel
 */
export interface UpdateChatConversationThreadsArtifactStateRequestModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateChatConversationThreadsArtifactStateRequestModel
     */
    'threadIds': Array<string>;
    /**
     * 
     * @type {ArtifactStateTypes}
     * @memberof UpdateChatConversationThreadsArtifactStateRequestModel
     */
    'artifactState': ArtifactStateTypes;
}
/**
 * 
 * @export
 * @interface UpdateChatConversationThreadsArtifactStateResponseModel
 */
export interface UpdateChatConversationThreadsArtifactStateResponseModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateChatConversationThreadsArtifactStateResponseModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateChatConversationThreadsArtifactStateResponseModel
     */
    'threadIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateChatConversationThreadsArtifactStateResponseModel
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface UpdateCitationStyleRequest
 */
export interface UpdateCitationStyleRequest {
    /**
     * 
     * @type {CitationStyleGuideType}
     * @memberof UpdateCitationStyleRequest
     */
    'citationStyle': CitationStyleGuideType;
}
/**
 * 
 * @export
 * @interface UpdateCitationStyleResponse
 */
export interface UpdateCitationStyleResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCitationStyleResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface UpdateCitationsRequest
 */
export interface UpdateCitationsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCitationsRequest
     */
    'citationIds': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateCitationsResponse
 */
export interface UpdateCitationsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCitationsResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface UpdateCommentConversationRequest
 */
export interface UpdateCommentConversationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCommentConversationRequest
     */
    'resourceId': string;
    /**
     * 
     * @type {CommentResourceTypes}
     * @memberof UpdateCommentConversationRequest
     */
    'resourceType': CommentResourceTypes;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommentConversationRequest
     */
    'locationKey'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCommentConversationRequest
     */
    'isResolved': boolean;
}
/**
 * 
 * @export
 * @interface UpdateCommentConversationResponse
 */
export interface UpdateCommentConversationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCommentConversationResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface UpdateConversationRequest
 */
export interface UpdateConversationRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConversationRequest
     */
    'isResolved': boolean;
}
/**
 * 
 * @export
 * @interface UpdateConversationResponse
 */
export interface UpdateConversationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConversationResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface UpdateConversationThreadsArtifactStateRequestModel
 */
export interface UpdateConversationThreadsArtifactStateRequestModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateConversationThreadsArtifactStateRequestModel
     */
    'threadIds': Array<string>;
    /**
     * 
     * @type {ArtifactStateTypes}
     * @memberof UpdateConversationThreadsArtifactStateRequestModel
     */
    'artifactState': ArtifactStateTypes;
}
/**
 * 
 * @export
 * @interface UpdateConversationThreadsArtifactStateResponseModel
 */
export interface UpdateConversationThreadsArtifactStateResponseModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateConversationThreadsArtifactStateResponseModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateConversationThreadsArtifactStateResponseModel
     */
    'threadIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConversationThreadsArtifactStateResponseModel
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface UpdateDataAnalyticsArtifactStateRequestModel
 */
export interface UpdateDataAnalyticsArtifactStateRequestModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDataAnalyticsArtifactStateRequestModel
     */
    'dataAnalyticsIds': Array<string>;
    /**
     * 
     * @type {ArtifactStateTypes}
     * @memberof UpdateDataAnalyticsArtifactStateRequestModel
     */
    'artifactState': ArtifactStateTypes;
}
/**
 * 
 * @export
 * @interface UpdateDataAnalyticsArtifactStateResponseModel
 */
export interface UpdateDataAnalyticsArtifactStateResponseModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateDataAnalyticsArtifactStateResponseModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDataAnalyticsArtifactStateResponseModel
     */
    'dataAnalyticsIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDataAnalyticsArtifactStateResponseModel
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface UpdateDataOperationArtifactStateRequest
 */
export interface UpdateDataOperationArtifactStateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDataOperationArtifactStateRequest
     */
    'dataOperationIds': Array<string>;
    /**
     * 
     * @type {ArtifactStateType}
     * @memberof UpdateDataOperationArtifactStateRequest
     */
    'artifactState'?: ArtifactStateType;
}
/**
 * 
 * @export
 * @interface UpdateDataOperationRequest
 */
export interface UpdateDataOperationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDataOperationRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDatasetArtifactState
 */
export interface UpdateDatasetArtifactState {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDatasetArtifactState
     */
    'datasetIds': Array<string>;
    /**
     * 
     * @type {ArtifactStateType}
     * @memberof UpdateDatasetArtifactState
     */
    'artifactState'?: ArtifactStateType;
}
/**
 * 
 * @export
 * @interface UpdateDatasetEditableFieldsRequest
 */
export interface UpdateDatasetEditableFieldsRequest {
    /**
     * 
     * @type {DatasetEditableFields}
     * @memberof UpdateDatasetEditableFieldsRequest
     */
    'updates': DatasetEditableFields;
}
/**
 * 
 * @export
 * @interface UpdateDatasetEditableFieldsResponse
 */
export interface UpdateDatasetEditableFieldsResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateDatasetEditableFieldsResponse
     */
    'dsId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDatasetEditableFieldsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {DatasetEditableFields}
     * @memberof UpdateDatasetEditableFieldsResponse
     */
    'updates': DatasetEditableFields;
    /**
     * 
     * @type {string}
     * @memberof UpdateDatasetEditableFieldsResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDatasetRegistrationWorkflowResponse
 */
export interface UpdateDatasetRegistrationWorkflowResponse {
    /**
     * 
     * @type {DatasetRegistrationWorkflowModel}
     * @memberof UpdateDatasetRegistrationWorkflowResponse
     */
    'response'?: DatasetRegistrationWorkflowModel;
    /**
     * 
     * @type {string}
     * @memberof UpdateDatasetRegistrationWorkflowResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDocumentMetadataRequest
 */
export interface UpdateDocumentMetadataRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentMetadataRequest
     */
    'documentName'?: string;
    /**
     * 
     * @type {Array<StyleGuideType>}
     * @memberof UpdateDocumentMetadataRequest
     */
    'styleGuides'?: Array<StyleGuideType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDocumentMetadataRequest
     */
    'customStyleRules'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDocumentMetadataRequest
     */
    'customDictionaryIds'?: Array<string>;
    /**
     * 
     * @type {CitationStyleGuideType}
     * @memberof UpdateDocumentMetadataRequest
     */
    'citationStyle'?: CitationStyleGuideType;
}
/**
 * 
 * @export
 * @interface UpdateDocumentMetadataResponse
 */
export interface UpdateDocumentMetadataResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDocumentMetadataResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface UpdateEditableEvalFieldsRequest
 */
export interface UpdateEditableEvalFieldsRequest {
    /**
     * 
     * @type {EditableEvalFields}
     * @memberof UpdateEditableEvalFieldsRequest
     */
    'updates': EditableEvalFields;
}
/**
 * 
 * @export
 * @interface UpdateEditableEvalFieldsResponse
 */
export interface UpdateEditableEvalFieldsResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateEditableEvalFieldsResponse
     */
    'recordingId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEditableEvalFieldsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {EditableEvalFields}
     * @memberof UpdateEditableEvalFieldsResponse
     */
    'updates': EditableEvalFields;
    /**
     * 
     * @type {string}
     * @memberof UpdateEditableEvalFieldsResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateEditableProjectFieldRequest
 */
export interface UpdateEditableProjectFieldRequest {
    /**
     * 
     * @type {ProjEditableFields}
     * @memberof UpdateEditableProjectFieldRequest
     */
    'updates': ProjEditableFields;
}
/**
 * 
 * @export
 * @interface UpdateEditableProjectFieldResponse
 */
export interface UpdateEditableProjectFieldResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateEditableProjectFieldResponse
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEditableProjectFieldResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {ProjEditableFields}
     * @memberof UpdateEditableProjectFieldResponse
     */
    'updates': ProjEditableFields;
    /**
     * 
     * @type {string}
     * @memberof UpdateEditableProjectFieldResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateEvalEditableFieldsRequest
 */
export interface UpdateEvalEditableFieldsRequest {
    /**
     * 
     * @type {EvalEditableFields}
     * @memberof UpdateEvalEditableFieldsRequest
     */
    'updates': EvalEditableFields;
}
/**
 * 
 * @export
 * @interface UpdateEvalEditableFieldsResponse
 */
export interface UpdateEvalEditableFieldsResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateEvalEditableFieldsResponse
     */
    'evalId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEvalEditableFieldsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {EvalEditableFields}
     * @memberof UpdateEvalEditableFieldsResponse
     */
    'updates': EvalEditableFields;
    /**
     * 
     * @type {string}
     * @memberof UpdateEvalEditableFieldsResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateEvaluationArtifactState
 */
export interface UpdateEvaluationArtifactState {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateEvaluationArtifactState
     */
    'evaluationIds': Array<string>;
    /**
     * 
     * @type {ArtifactStateType}
     * @memberof UpdateEvaluationArtifactState
     */
    'artifactState'?: ArtifactStateType;
}
/**
 * 
 * @export
 * @interface UpdateExpEditableFieldsRequest
 */
export interface UpdateExpEditableFieldsRequest {
    /**
     * 
     * @type {ExpEditableFields}
     * @memberof UpdateExpEditableFieldsRequest
     */
    'updates': ExpEditableFields;
}
/**
 * 
 * @export
 * @interface UpdateExpEditableFieldsResponse
 */
export interface UpdateExpEditableFieldsResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateExpEditableFieldsResponse
     */
    'experimentId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExpEditableFieldsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {ExpEditableFields}
     * @memberof UpdateExpEditableFieldsResponse
     */
    'updates': ExpEditableFields;
    /**
     * 
     * @type {string}
     * @memberof UpdateExpEditableFieldsResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateExperimentArtifactState
 */
export interface UpdateExperimentArtifactState {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateExperimentArtifactState
     */
    'experimentIds': Array<string>;
    /**
     * 
     * @type {ArtifactStateType}
     * @memberof UpdateExperimentArtifactState
     */
    'artifactState'?: ArtifactStateType;
}
/**
 * 
 * @export
 * @interface UpdateGenAIProjectRequest
 */
export interface UpdateGenAIProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateGenAIProjectRequest
     */
    'projectName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGenAIProjectRequest
     */
    'projectDescription'?: string;
    /**
     * 
     * @type {CitationStyleGuideType}
     * @memberof UpdateGenAIProjectRequest
     */
    'defaultCitationStyleGuide'?: CitationStyleGuideType;
    /**
     * 
     * @type {Array<StyleGuideType>}
     * @memberof UpdateGenAIProjectRequest
     */
    'defaultStyleGuides'?: Array<StyleGuideType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateGenAIProjectRequest
     */
    'defaultCustomStyleRules'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateGenAIProjectRequest
     */
    'defaultCustomDictionaryIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateGenAIProjectResponse
 */
export interface UpdateGenAIProjectResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGenAIProjectResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface UpdateInAppNotificationsRequestModel
 */
export interface UpdateInAppNotificationsRequestModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateInAppNotificationsRequestModel
     */
    'recordIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInAppNotificationsRequestModel
     */
    'read': boolean;
}
/**
 * 
 * @export
 * @interface UpdateInAppNotificationsResponse
 */
export interface UpdateInAppNotificationsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateInAppNotificationsResponse
     */
    'recordIds': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateModelEditableFieldsRequest
 */
export interface UpdateModelEditableFieldsRequest {
    /**
     * 
     * @type {ModelEditableFields}
     * @memberof UpdateModelEditableFieldsRequest
     */
    'updates': ModelEditableFields;
}
/**
 * 
 * @export
 * @interface UpdateModelEditableFieldsResponse
 */
export interface UpdateModelEditableFieldsResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateModelEditableFieldsResponse
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModelEditableFieldsResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {ModelEditableFields}
     * @memberof UpdateModelEditableFieldsResponse
     */
    'updates': ModelEditableFields;
    /**
     * 
     * @type {string}
     * @memberof UpdateModelEditableFieldsResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdatePageContentResponse
 */
export interface UpdatePageContentResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePageContentResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {PageResponse}
     * @memberof UpdatePageContentResponse
     */
    'response': PageResponse;
}
/**
 * 
 * @export
 * @interface UpdatePageSuggestions
 */
export interface UpdatePageSuggestions {
    /**
     * 
     * @type {Array<SuggestionState>}
     * @memberof UpdatePageSuggestions
     */
    'latestPageSuggestionState'?: Array<SuggestionState>;
}
/**
 * 
 * @export
 * @interface UpdateProjectArtifactState
 */
export interface UpdateProjectArtifactState {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProjectArtifactState
     */
    'projectIds': Array<string>;
    /**
     * 
     * @type {ArtifactStateType}
     * @memberof UpdateProjectArtifactState
     */
    'artifactState'?: ArtifactStateType;
}
/**
 * 
 * @export
 * @interface UpdateRelabelingRuleRequest
 */
export interface UpdateRelabelingRuleRequest {
    /**
     * 
     * @type {Rule}
     * @memberof UpdateRelabelingRuleRequest
     */
    'rule': Rule;
}
/**
 * 
 * @export
 * @interface UpdateRetailProductRequest
 */
export interface UpdateRetailProductRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRetailProductRequest
     */
    'keywords'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRetailProductRequest
     */
    'competitorUrls'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailProductRequest
     */
    'dataSourceId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRuleResponse
 */
export interface UpdateRuleResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRuleResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateRuleResponse
     */
    'workspaceId': string;
}
/**
 * Request model to update schedule details of a workflow. Attributes:     schedule: [ScheduleRequestModel]: Workflow Schedule details
 * @export
 * @interface UpdateScheduledWorkflowRequest
 */
export interface UpdateScheduledWorkflowRequest {
    /**
     * 
     * @type {ScheduleDetailsModel}
     * @memberof UpdateScheduledWorkflowRequest
     */
    'schedule': ScheduleDetailsModel;
}
/**
 * 
 * @export
 * @interface UpdateSegmentRequest
 */
export interface UpdateSegmentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSegmentRequest
     */
    'pageContent': string;
}
/**
 * 
 * @export
 * @interface UpdateSegmentRequestV2
 */
export interface UpdateSegmentRequestV2 {
    /**
     * 
     * @type {string}
     * @memberof UpdateSegmentRequestV2
     */
    'pageContent': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSegmentRequestV2
     */
    'binaryPageContent'?: string;
}
/**
 * 
 * @export
 * @interface UpdateSegmentResponse
 */
export interface UpdateSegmentResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSegmentResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {ChunkResponse}
     * @memberof UpdateSegmentResponse
     */
    'response': ChunkResponse;
}
/**
 * 
 * @export
 * @interface UpdateSlackChannelRequestModel
 */
export interface UpdateSlackChannelRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateSlackChannelRequestModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSlackChannelRequestModel
     */
    'channelId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSlackChannelRequestModel
     */
    'channelName': string;
}
/**
 * 
 * @export
 * @interface UpdateSlackChannelResponseModel
 */
export interface UpdateSlackChannelResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSlackChannelResponseModel
     */
    'ok': boolean;
}
/**
 * 
 * @export
 * @interface UpdateSnippetArtifactState
 */
export interface UpdateSnippetArtifactState {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSnippetArtifactState
     */
    'snippetIds': Array<string>;
    /**
     * 
     * @type {ArtifactStateType}
     * @memberof UpdateSnippetArtifactState
     */
    'artifactState'?: ArtifactStateType;
}
/**
 * Request model to update snippet\'s document
 * @export
 * @interface UpdateSnippetDocumentRequest
 */
export interface UpdateSnippetDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSnippetDocumentRequest
     */
    'document': string;
}
/**
 * 
 * @export
 * @interface UpdateSnippetDocumentResponse
 */
export interface UpdateSnippetDocumentResponse {
    /**
     * 
     * @type {APIMessage}
     * @memberof UpdateSnippetDocumentResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {string}
     * @memberof UpdateSnippetDocumentResponse
     */
    'document'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSnippetDocumentResponse
     */
    'workspaceId': string;
}
/**
 * Request model to update snippet
 * @export
 * @interface UpdateSnippetRequest
 */
export interface UpdateSnippetRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSnippetRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSnippetRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSnippetRequest
     */
    'content'?: string;
    /**
     * 
     * @type {Array<UserMentions>}
     * @memberof UpdateSnippetRequest
     */
    'mentions'?: Array<UserMentions>;
}
/**
 * 
 * @export
 * @interface UpdateSnippetResponse
 */
export interface UpdateSnippetResponse {
    /**
     * 
     * @type {APIMessage}
     * @memberof UpdateSnippetResponse
     */
    'message'?: APIMessage;
    /**
     * 
     * @type {SnippetModel}
     * @memberof UpdateSnippetResponse
     */
    'response'?: SnippetModel;
    /**
     * 
     * @type {string}
     * @memberof UpdateSnippetResponse
     */
    'workspaceId': string;
}
/**
 * Update tags to attached to a resource id
 * @export
 * @interface UpdateTagsRequest
 */
export interface UpdateTagsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTagsRequest
     */
    'resourceId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTagsRequest
     */
    'tagIds': Array<string>;
    /**
     * 
     * @type {ResourceType}
     * @memberof UpdateTagsRequest
     */
    'resourceType': ResourceType;
}
/**
 * 
 * @export
 * @interface UpdateTagsResponse
 */
export interface UpdateTagsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTagsResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateTagsResponse
     */
    'appliedTags': number;
}
/**
 * 
 * @export
 * @interface UpdateWorkflowAppDAGRequest
 */
export interface UpdateWorkflowAppDAGRequest {
    /**
     * 
     * @type {Array<Node>}
     * @memberof UpdateWorkflowAppDAGRequest
     */
    'nodes': Array<Node>;
    /**
     * 
     * @type {Array<Edge>}
     * @memberof UpdateWorkflowAppDAGRequest
     */
    'edges': Array<Edge>;
}
/**
 * 
 * @export
 * @interface UpdateWorkflowAppResponseModel
 */
export interface UpdateWorkflowAppResponseModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowAppResponseModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowAppResponseModel
     */
    'workflowId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowAppResponseModel
     */
    'workflowAppId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowAppResponseModel
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowAppResponseModel
     */
    'workflowAppName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowAppResponseModel
     */
    'workflowAppDescription': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowAppResponseModel
     */
    'dagId': string;
    /**
     * 
     * @type {GetDAGResponse}
     * @memberof UpdateWorkflowAppResponseModel
     */
    'dag': GetDAGResponse;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowAppResponseModel
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowAppResponseModel
     */
    'updateDate': string;
    /**
     * Fetch artifacts based on query  param filter ACTIVE/ARCHIVED
     * @type {ArtifactStateModel}
     * @memberof UpdateWorkflowAppResponseModel
     */
    'artifactState': ArtifactStateModel;
    /**
     * 
     * @type {WorkflowAppCategory}
     * @memberof UpdateWorkflowAppResponseModel
     */
    'category'?: WorkflowAppCategory;
}
/**
 * 
 * @export
 * @interface UpdateWorkflowArtifactStateRequest
 */
export interface UpdateWorkflowArtifactStateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateWorkflowArtifactStateRequest
     */
    'workflowIds': Array<string>;
    /**
     * 
     * @type {ArtifactStateType}
     * @memberof UpdateWorkflowArtifactStateRequest
     */
    'artifactState'?: ArtifactStateType;
}
/**
 * 
 * @export
 * @interface UpdateWorkflowRequest
 */
export interface UpdateWorkflowRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowRequest
     */
    'description'?: string;
    /**
     * 
     * @type {WorkflowStatus}
     * @memberof UpdateWorkflowRequest
     */
    'status'?: WorkflowStatus;
}
/**
 * 
 * @export
 * @interface UpdateWorkflowSearchAppRequest
 */
export interface UpdateWorkflowSearchAppRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowSearchAppRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowSearchAppRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdateWorkspaceMarkovCreditsUsageRequestModel
 */
export interface UpdateWorkspaceMarkovCreditsUsageRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkspaceMarkovCreditsUsageRequestModel
     */
    'transactionSourceId': string;
    /**
     * 
     * @type {MarkovCreditsTransactionSourceType}
     * @memberof UpdateWorkspaceMarkovCreditsUsageRequestModel
     */
    'transactionSourceType': MarkovCreditsTransactionSourceType;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkspaceMarkovCreditsUsageRequestModel
     */
    'amount': number;
    /**
     * 
     * @type {MarkovCreditsTransactionActionType}
     * @memberof UpdateWorkspaceMarkovCreditsUsageRequestModel
     */
    'transactionType': MarkovCreditsTransactionActionType;
}
/**
 * 
 * @export
 * @interface UpdateWorkspaceMarkovCreditsUsageResponseModel
 */
export interface UpdateWorkspaceMarkovCreditsUsageResponseModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkspaceMarkovCreditsUsageResponseModel
     */
    'transactionSourceId': string;
    /**
     * 
     * @type {MarkovCreditsTransactionSourceType}
     * @memberof UpdateWorkspaceMarkovCreditsUsageResponseModel
     */
    'transactionSourceType': MarkovCreditsTransactionSourceType;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkspaceMarkovCreditsUsageResponseModel
     */
    'amount': number;
    /**
     * 
     * @type {MarkovCreditsTransactionActionType}
     * @memberof UpdateWorkspaceMarkovCreditsUsageResponseModel
     */
    'transactionType': MarkovCreditsTransactionActionType;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkspaceMarkovCreditsUsageResponseModel
     */
    'transactionId': string;
}
/**
 * Request for uploading text file manually and analysing the text file for copy edit suggestions / summarization
 * @export
 * @interface UploadManualTextFileRequest
 */
export interface UploadManualTextFileRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadManualTextFileRequest
     */
    'documentName': string;
    /**
     * 
     * @type {string}
     * @memberof UploadManualTextFileRequest
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof UploadManualTextFileRequest
     */
    'llmType'?: string;
    /**
     * 
     * @type {CitationStyleGuideType}
     * @memberof UploadManualTextFileRequest
     */
    'citationStyleGuide'?: CitationStyleGuideType;
    /**
     * 
     * @type {Array<StyleGuideType>}
     * @memberof UploadManualTextFileRequest
     */
    'styleGuides'?: Array<StyleGuideType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadManualTextFileRequest
     */
    'customStyleRules'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadManualTextFileRequest
     */
    'customDictionaryIds'?: Array<string>;
}
/**
 * Request for uploading text file manually and analysing the text file for copy edit suggestions / summarization
 * @export
 * @interface UploadManualTextFileResponse
 */
export interface UploadManualTextFileResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadManualTextFileResponse
     */
    'documentName': string;
    /**
     * 
     * @type {string}
     * @memberof UploadManualTextFileResponse
     */
    'documentId': string;
    /**
     * 
     * @type {string}
     * @memberof UploadManualTextFileResponse
     */
    'createDate': string;
}
/**
 * 
 * @export
 * @interface UploadRuleSetRequest
 */
export interface UploadRuleSetRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadRuleSetRequest
     */
    'uploadDocumentId': string;
}
/**
 * 
 * @export
 * @interface UpsertDAGRequest
 */
export interface UpsertDAGRequest {
    /**
     * 
     * @type {Array<Node>}
     * @memberof UpsertDAGRequest
     */
    'nodes': Array<Node>;
    /**
     * 
     * @type {Array<Edge>}
     * @memberof UpsertDAGRequest
     */
    'edges': Array<Edge>;
}
/**
 * 
 * @export
 * @interface UpsertDagResponse
 */
export interface UpsertDagResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpsertDagResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertDagResponse
     */
    'dagId': string;
}
/**
 * 
 * @export
 * @interface UrlPostFields
 */
export interface UrlPostFields {
    /**
     * 
     * @type {string}
     * @memberof UrlPostFields
     */
    'acl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UrlPostFields
     */
    'aWSAccessKeyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UrlPostFields
     */
    'contenype'?: string;
    /**
     * 
     * @type {string}
     * @memberof UrlPostFields
     */
    'expires'?: string;
    /**
     * 
     * @type {string}
     * @memberof UrlPostFields
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof UrlPostFields
     */
    'policy'?: string;
    /**
     * 
     * @type {string}
     * @memberof UrlPostFields
     */
    'signature'?: string;
}
/**
 * 
 * @export
 * @interface UrlResponse
 */
export interface UrlResponse {
    /**
     * 
     * @type {{ [key: string]: Urls | PostUrl; }}
     * @memberof UrlResponse
     */
    'response': { [key: string]: Urls | PostUrl; };
    /**
     * 
     * @type {string}
     * @memberof UrlResponse
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface Urls
 */
export interface Urls {
    /**
     * 
     * @type {string}
     * @memberof Urls
     */
    'key': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Urls
     */
    'urls': Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Urls
     */
    'urlPartNumbers'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Urls
     */
    'uploadId': string;
    /**
     * 
     * @type {string}
     * @memberof Urls
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UsageMetadataModel
 */
export interface UsageMetadataModel {
    /**
     * 
     * @type {string}
     * @memberof UsageMetadataModel
     */
    'timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof UsageMetadataModel
     */
    'workspaceId': string;
    /**
     * 
     * @type {UsageTrackingEventType}
     * @memberof UsageMetadataModel
     */
    'eventType': UsageTrackingEventType;
    /**
     * 
     * @type {object}
     * @memberof UsageMetadataModel
     */
    'eventMetadata': object;
}
/**
 * Usage tracking event types
 * @export
 * @enum {string}
 */

export enum UsageTrackingEventType {
    Storage = 'STORAGE',
    Compute = 'COMPUTE',
    NotebookCompute = 'NOTEBOOK_COMPUTE',
    EvaluationTracking = 'EVALUATION_TRACKING',
    ExperimentTracking = 'EXPERIMENT_TRACKING'
}

/**
 * Usage tracking event types
 * @export
 * @enum {string}
 */

export enum UsageTrackingEventType1 {
    Storage = 'STORAGE',
    Compute = 'COMPUTE',
    NotebookCompute = 'NOTEBOOK_COMPUTE',
    EvaluationTracking = 'EVALUATION_TRACKING',
    ExperimentTracking = 'EXPERIMENT_TRACKING',
    UserSignups = 'USER_SIGNUPS',
    ExperimentCreation = 'EXPERIMENT_CREATION',
    EvaluationCreation = 'EVALUATION_CREATION',
    ProjectCreation = 'PROJECT_CREATION',
    DatasetCreation = 'DATASET_CREATION',
    WorkspaceCreation = 'WORKSPACE_CREATION',
    WorkspaceMembershipCreation = 'WORKSPACE_MEMBERSHIP_CREATION'
}

/**
 * 
 * @export
 * @interface UserAccessResponse
 */
export interface UserAccessResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserAccessResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {number}
     * @memberof UserAccessResponse
     */
    'statusCode': number;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UserComment
 */
export interface UserComment {
    /**
     * 
     * @type {string}
     * @memberof UserComment
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof UserComment
     */
    'addedBy'?: string;
    /**
     * 
     * @type {Array<UserMentions>}
     * @memberof UserComment
     */
    'mentions'?: Array<UserMentions>;
    /**
     * 
     * @type {string}
     * @memberof UserComment
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserComment
     */
    'updateDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserComment
     */
    'commentId'?: string;
}
/**
 * 
 * @export
 * @interface UserCommentModel
 */
export interface UserCommentModel {
    /**
     * 
     * @type {string}
     * @memberof UserCommentModel
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof UserCommentModel
     */
    'addedBy'?: string;
    /**
     * 
     * @type {Array<UserMention>}
     * @memberof UserCommentModel
     */
    'mentions'?: Array<UserMention>;
    /**
     * 
     * @type {string}
     * @memberof UserCommentModel
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCommentModel
     */
    'updateDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCommentModel
     */
    'commentId'?: string;
}
/**
 * 
 * @export
 * @interface UserConversation
 */
export interface UserConversation {
    /**
     * 
     * @type {string}
     * @memberof UserConversation
     */
    'snippetId'?: string;
    /**
     * 
     * @type {Array<UserComment>}
     * @memberof UserConversation
     */
    'comments'?: Array<UserComment>;
    /**
     * 
     * @type {boolean}
     * @memberof UserConversation
     */
    'isResolved'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserConversation
     */
    'conversationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserConversation
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserConversation
     */
    'updateDate'?: string;
}
/**
 * 
 * @export
 * @interface UserConversationModel
 */
export interface UserConversationModel {
    /**
     * 
     * @type {Array<UserCommentModel>}
     * @memberof UserConversationModel
     */
    'comments'?: Array<UserCommentModel>;
    /**
     * 
     * @type {number}
     * @memberof UserConversationModel
     */
    'numComments'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserConversationModel
     */
    'isResolved'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserConversationModel
     */
    'conversationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserConversationModel
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserConversationModel
     */
    'locationKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserConversationModel
     */
    'updateDate'?: string;
}
/**
 * Response for user deletion API
 * @export
 * @interface UserDeletionResponse
 */
export interface UserDeletionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserDeletionResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface UserDetails
 */
export interface UserDetails {
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'avatar'?: string;
}
/**
 * Markov\'s user info
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserInput
 */
export interface UserInput {
    /**
     * 
     * @type {string}
     * @memberof UserInput
     */
    'userInput': string;
    /**
     * 
     * @type {number}
     * @memberof UserInput
     */
    'maxTokens'?: number;
    /**
     * 
     * @type {GenerationMode}
     * @memberof UserInput
     */
    'mode'?: GenerationMode;
}
/**
 * 
 * @export
 * @interface UserInput1
 */
export interface UserInput1 {
    /**
     * 
     * @type {string}
     * @memberof UserInput1
     */
    'user_input': string;
    /**
     * 
     * @type {number}
     * @memberof UserInput1
     */
    'max_tokens'?: number;
    /**
     * 
     * @type {GenerationMode1}
     * @memberof UserInput1
     */
    'mode'?: GenerationMode1;
}
/**
 * Note: Here, mentionId is used for de-duplicating mentions in subsequent calls for same users, and it is created in the FE itself.
 * @export
 * @interface UserMention
 */
export interface UserMention {
    /**
     * 
     * @type {string}
     * @memberof UserMention
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMention
     */
    'mentionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMention
     */
    'mentionedBy'?: string;
}
/**
 * Note: Here, mentionId is used for de-duplicating mentions in subsequent calls for same users, and it is created in the FE itself.
 * @export
 * @interface UserMentions
 */
export interface UserMentions {
    /**
     * 
     * @type {string}
     * @memberof UserMentions
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMentions
     */
    'mentionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMentions
     */
    'mentionedBy'?: string;
}
/**
 * Payload object for notification to the user mentioned on any snippet content body.
 * @export
 * @interface UserMentionsOnSnippetContentRequestPayload
 */
export interface UserMentionsOnSnippetContentRequestPayload {
    /**
     * 
     * @type {UserInfo}
     * @memberof UserMentionsOnSnippetContentRequestPayload
     */
    'userTo': UserInfo;
    /**
     * 
     * @type {string}
     * @memberof UserMentionsOnSnippetContentRequestPayload
     */
    'workspaceId': string;
    /**
     * 
     * @type {UserInfo}
     * @memberof UserMentionsOnSnippetContentRequestPayload
     */
    'userFrom'?: UserInfo;
    /**
     * 
     * @type {SnippetInfo}
     * @memberof UserMentionsOnSnippetContentRequestPayload
     */
    'snippetInfo': SnippetInfo;
    /**
     * 
     * @type {string}
     * @memberof UserMentionsOnSnippetContentRequestPayload
     */
    'mentionType'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMentionsOnSnippetContentRequestPayload
     */
    'userEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMentionsOnSnippetContentRequestPayload
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMentionsOnSnippetContentRequestPayload
     */
    'title'?: string;
}
/**
 * API response for UserOwnedAccount. It optionally contains a user_details object as well. UserOwnedAccount is a link object between a workspace\'s owner account, and a user. Unlike enterprise owned accounts it doesn\'t have any special properties of its own.
 * @export
 * @interface UserOwnedAccount
 */
export interface UserOwnedAccount {
    /**
     * 
     * @type {any}
     * @memberof UserOwnedAccount
     */
    'accountId': any;
    /**
     * 
     * @type {string}
     * @memberof UserOwnedAccount
     */
    'accountName': string;
    /**
     * 
     * @type {string}
     * @memberof UserOwnedAccount
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof UserOwnedAccount
     */
    'updateDate': string;
    /**
     * 
     * @type {AccountState}
     * @memberof UserOwnedAccount
     */
    'state'?: AccountState;
    /**
     * 
     * @type {BasicUserDetails}
     * @memberof UserOwnedAccount
     */
    'userDetails'?: BasicUserDetails;
}
/**
 * Paginated list of user owned accounts for get user owned accounts api response
 * @export
 * @interface UserOwnedAccountsList
 */
export interface UserOwnedAccountsList {
    /**
     * 
     * @type {Array<UserOwnedAccount>}
     * @memberof UserOwnedAccountsList
     */
    'accounts': Array<UserOwnedAccount>;
    /**
     * 
     * @type {number}
     * @memberof UserOwnedAccountsList
     */
    'numRecords': number;
}
/**
 * User profile object. This object represents the public profile of a user.
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {ProfileFieldProperties}
     * @memberof UserProfile
     */
    'userId': ProfileFieldProperties;
    /**
     * 
     * @type {ProfileFieldProperties}
     * @memberof UserProfile
     */
    'email': ProfileFieldProperties;
    /**
     * 
     * @type {ProfileFieldProperties}
     * @memberof UserProfile
     */
    'name': ProfileFieldProperties;
    /**
     * 
     * @type {ProfileFieldProperties}
     * @memberof UserProfile
     */
    'pictureUrl': ProfileFieldProperties;
    /**
     * 
     * @type {ProfileFieldProperties}
     * @memberof UserProfile
     */
    'username': ProfileFieldProperties;
    /**
     * 
     * @type {ProfileFieldProperties}
     * @memberof UserProfile
     */
    'bio': ProfileFieldProperties;
    /**
     * 
     * @type {ProfileFieldProperties}
     * @memberof UserProfile
     */
    'company': ProfileFieldProperties;
    /**
     * 
     * @type {ProfileFieldProperties}
     * @memberof UserProfile
     */
    'role': ProfileFieldProperties;
    /**
     * 
     * @type {ProfileFieldProperties}
     * @memberof UserProfile
     */
    'location': ProfileFieldProperties;
    /**
     * 
     * @type {ProfileFieldProperties}
     * @memberof UserProfile
     */
    'linkedinUrl': ProfileFieldProperties;
    /**
     * 
     * @type {ProfileFieldProperties}
     * @memberof UserProfile
     */
    'githubUrl': ProfileFieldProperties;
    /**
     * 
     * @type {ProfileFieldProperties}
     * @memberof UserProfile
     */
    'createDate': ProfileFieldProperties;
}
/**
 * Response for user profile get/update APIs
 * @export
 * @interface UserProfileResponse
 */
export interface UserProfileResponse {
    /**
     * 
     * @type {UserProfile}
     * @memberof UserProfileResponse
     */
    'response': UserProfile;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum UserProfileState {
    Inactive = 'INACTIVE',
    Invited = 'INVITED',
    Active = 'ACTIVE'
}

/**
 * Request for user profile update API
 * @export
 * @interface UserProfileUpdateRequest
 */
export interface UserProfileUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateRequest
     */
    'bio'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateRequest
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateRequest
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateRequest
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateRequest
     */
    'linkedinUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateRequest
     */
    'githubUrl'?: string;
}
/**
 * Response for user profile update API
 * @export
 * @interface UserProfileUpdateResponse
 */
export interface UserProfileUpdateResponse {
    /**
     * 
     * @type {UserProfile}
     * @memberof UserProfileUpdateResponse
     */
    'response': UserProfile;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileUpdateResponse
     */
    'ack': boolean;
}
/**
 * Paginated list of users for get users api response
 * @export
 * @interface UserSearchResults
 */
export interface UserSearchResults {
    /**
     * 
     * @type {Array<BasicUserDetails>}
     * @memberof UserSearchResults
     */
    'results'?: Array<BasicUserDetails>;
    /**
     * 
     * @type {number}
     * @memberof UserSearchResults
     */
    'numRecords'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum UserType {
    Student = 'STUDENT',
    Business = 'BUSINESS',
    Engineering = 'ENGINEERING',
    Undefined = 'UNDEFINED'
}

/**
 * Response with details about a user and their default entities
 * @export
 * @interface UserWithAccountDetails
 */
export interface UserWithAccountDetails {
    /**
     * 
     * @type {BaseUser}
     * @memberof UserWithAccountDetails
     */
    'user': BaseUser;
    /**
     * 
     * @type {UserOwnedAccount}
     * @memberof UserWithAccountDetails
     */
    'primaryAccount'?: UserOwnedAccount;
    /**
     * 
     * @type {AccountsList}
     * @memberof UserWithAccountDetails
     */
    'otherAccounts'?: AccountsList;
}
/**
 * User with email exists or not
 * @export
 * @enum {string}
 */

export enum UserWithEmailState {
    Exists = 'USER_EXISTS',
    NotExists = 'USER_NOT_EXISTS'
}

/**
 * API Response for Workspace with user membership information.
 * @export
 * @interface UserWorkspace
 */
export interface UserWorkspace {
    /**
     * 
     * @type {any}
     * @memberof UserWorkspace
     */
    'workspaceId': any;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspace
     */
    'workspaceName': string;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspace
     */
    'baseUrl': string;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspace
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspace
     */
    'updateDate': string;
    /**
     * 
     * @type {WorkspaceMember}
     * @memberof UserWorkspace
     */
    'membershipDetails'?: WorkspaceMember;
}
/**
 * 
 * @export
 * @interface UserWorkspaceInvitationPayload
 */
export interface UserWorkspaceInvitationPayload {
    /**
     * 
     * @type {UserInfo}
     * @memberof UserWorkspaceInvitationPayload
     */
    'userTo': UserInfo;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspaceInvitationPayload
     */
    'workspaceId': string;
    /**
     * 
     * @type {UserInfo}
     * @memberof UserWorkspaceInvitationPayload
     */
    'userFrom'?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspaceInvitationPayload
     */
    'invitationLink': string;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspaceInvitationPayload
     */
    'invitedByUser': string;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspaceInvitationPayload
     */
    'workspaceName': string;
}
/**
 * API Response for list of workspaces with user membership information.
 * @export
 * @interface UserWorkspaceList
 */
export interface UserWorkspaceList {
    /**
     * 
     * @type {Array<UserWorkspace>}
     * @memberof UserWorkspaceList
     */
    'response': Array<UserWorkspace>;
    /**
     * 
     * @type {number}
     * @memberof UserWorkspaceList
     */
    'numRecords': number;
}
/**
 * Response for whether a user is member of the workspace or not
 * @export
 * @interface UserWorkspaceMembershipResponse
 */
export interface UserWorkspaceMembershipResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserWorkspaceMembershipResponse
     */
    'response': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspaceMembershipResponse
     */
    'message': string;
    /**
     * 
     * @type {WorkspaceUser}
     * @memberof UserWorkspaceMembershipResponse
     */
    'membershipDetails'?: WorkspaceUser;
}
/**
 * API Response for list of workspaces grouped by account with user membership information.
 * @export
 * @interface UserWorkspacesGrouped
 */
export interface UserWorkspacesGrouped {
    /**
     * 
     * @type {WorkspaceList}
     * @memberof UserWorkspacesGrouped
     */
    'personalWorkspaces': WorkspaceList;
    /**
     * 
     * @type {WorkspaceList}
     * @memberof UserWorkspacesGrouped
     */
    'enterpriseWorkspaces': WorkspaceList;
    /**
     * 
     * @type {WorkspaceList}
     * @memberof UserWorkspacesGrouped
     */
    'sharedWorkspaces': WorkspaceList;
    /**
     * 
     * @type {number}
     * @memberof UserWorkspacesGrouped
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface ValidateWorkspaceSubscriptionResponse
 */
export interface ValidateWorkspaceSubscriptionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ValidateWorkspaceSubscriptionResponse
     */
    'response': boolean;
    /**
     * 
     * @type {string}
     * @memberof ValidateWorkspaceSubscriptionResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string | number>}
     * @memberof ValidationError
     */
    'loc': Array<string | number>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface VerifyDatasetAccessResponse
 */
export interface VerifyDatasetAccessResponse {
    /**
     * 
     * @type {AckResponse}
     * @memberof VerifyDatasetAccessResponse
     */
    'response': AckResponse;
    /**
     * 
     * @type {string}
     * @memberof VerifyDatasetAccessResponse
     */
    'workspaceId'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum VisualizationType {
    Table = 'table',
    Chart = 'chart'
}

/**
 * 
 * @export
 * @interface WebsiteInformationModel
 */
export interface WebsiteInformationModel {
    /**
     * 
     * @type {CitationSourceType}
     * @memberof WebsiteInformationModel
     */
    'citationType': CitationSourceType;
    /**
     * Title of the reference
     * @type {string}
     * @memberof WebsiteInformationModel
     */
    'citationTitle': string;
    /**
     * 
     * @type {DateModel}
     * @memberof WebsiteInformationModel
     */
    'publishedDate'?: DateModel;
    /**
     * List of authors for the citation.
     * @type {Array<AuthorInformationModel>}
     * @memberof WebsiteInformationModel
     */
    'authors': Array<AuthorInformationModel>;
    /**
     * 
     * @type {string}
     * @memberof WebsiteInformationModel
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteInformationModel
     */
    'websiteName'?: string;
    /**
     * 
     * @type {DateModel}
     * @memberof WebsiteInformationModel
     */
    'accessedDate'?: DateModel;
}
/**
 * 
 * @export
 * @interface WordsForTopic
 */
export interface WordsForTopic {
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof WordsForTopic
     */
    'data': Array<Array<string>>;
}
/**
 * 
 * @export
 * @interface Workflow
 */
export interface Workflow {
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'name': string;
    /**
     * Fetch artifacts based on query  param filter ACTIVE/ARCHIVED
     * @type {ArtifactStateModel}
     * @memberof Workflow
     */
    'artifactState': ArtifactStateModel;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'dagId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'workflowId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    'createdBy'?: string;
    /**
     * 
     * @type {ExecutionType}
     * @memberof Workflow
     */
    'executionType'?: ExecutionType;
    /**
     * 
     * @type {WorkflowStatus}
     * @memberof Workflow
     * @deprecated
     */
    'status'?: WorkflowStatus;
    /**
     * 
     * @type {WorkflowSchedule}
     * @memberof Workflow
     */
    'workflowScheduleDetails'?: WorkflowSchedule;
    /**
     * It\'s a computed field to indicate whether the workflow is scheduled or not or paused
     * @type {WorkflowScheduleStatus}
     * @memberof Workflow
     */
    'scheduleStatus'?: WorkflowScheduleStatus;
    /**
     * This is a list of last 5 runs of the workflow. Sorted by start date.
     * @type {Array<WorkflowRunBrief>}
     * @memberof Workflow
     */
    'recentRuns'?: Array<WorkflowRunBrief>;
    /**
     * This is a list of operator ids used in the workflow
     * @type {Array<string>}
     * @memberof Workflow
     */
    'operators'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Workflow
     */
    'totalOperators'?: number;
    /**
     * 
     * @type {number}
     * @memberof Workflow
     */
    'totalRuns'?: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum WorkflowAppCategory {
    Marketing = 'Marketing',
    Sales = 'Sales',
    Others = 'Others'
}

/**
 * 
 * @export
 * @interface WorkflowAppWithoutDAG
 */
export interface WorkflowAppWithoutDAG {
    /**
     * 
     * @type {string}
     * @memberof WorkflowAppWithoutDAG
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowAppWithoutDAG
     */
    'workflowId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowAppWithoutDAG
     */
    'workflowAppId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowAppWithoutDAG
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowAppWithoutDAG
     */
    'workflowAppName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowAppWithoutDAG
     */
    'workflowAppDescription': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowAppWithoutDAG
     */
    'dagId': string;
    /**
     * 
     * @type {GetDAGResponse}
     * @memberof WorkflowAppWithoutDAG
     */
    'dag'?: GetDAGResponse;
    /**
     * 
     * @type {string}
     * @memberof WorkflowAppWithoutDAG
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowAppWithoutDAG
     */
    'updateDate': string;
    /**
     * Fetch artifacts based on query  param filter ACTIVE/ARCHIVED
     * @type {ArtifactStateModel}
     * @memberof WorkflowAppWithoutDAG
     */
    'artifactState': ArtifactStateModel;
    /**
     * 
     * @type {WorkflowAppCategory}
     * @memberof WorkflowAppWithoutDAG
     */
    'category'?: WorkflowAppCategory;
}
/**
 * Describes the input and output schema of a workflow run node.  Attributes: - input_schema (List[ColumnDetail]): A list of ColumnDetail objects representing the input schema. - output_schema (List[ColumnDetail]): A list of ColumnDetail objects representing the output schema.
 * @export
 * @interface WorkflowDagNodeIOSchema
 */
export interface WorkflowDagNodeIOSchema {
    /**
     * 
     * @type {Array<ColumnDetail>}
     * @memberof WorkflowDagNodeIOSchema
     */
    'inputSchema': Array<ColumnDetail>;
    /**
     * 
     * @type {Array<ColumnDetail>}
     * @memberof WorkflowDagNodeIOSchema
     */
    'outputSchema': Array<ColumnDetail>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum WorkflowErrorCodes {
    CycleDetected = 'CYCLE_DETECTED',
    OrphanNodes = 'ORPHAN_NODES',
    InvalidNodeConfiguration = 'INVALID_NODE_CONFIGURATION',
    EmptyWorkflow = 'EMPTY_WORKFLOW'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum WorkflowMode {
    LocalFileUpload = 'LOCAL_FILE_UPLOAD',
    ExternalCloudStorage = 'EXTERNAL_CLOUD_STORAGE'
}

/**
 * 
 * @export
 * @interface WorkflowNodeSchema
 */
export interface WorkflowNodeSchema {
    /**
     * 
     * @type {string}
     * @memberof WorkflowNodeSchema
     */
    'dagHash': string;
    /**
     * 
     * @type {Array<WorkflowErrorCodes>}
     * @memberof WorkflowNodeSchema
     */
    'workflowErrors'?: Array<WorkflowErrorCodes>;
    /**
     * 
     * @type {{ [key: string]: NodeSchema; }}
     * @memberof WorkflowNodeSchema
     */
    'nodeSchemas'?: { [key: string]: NodeSchema; };
}
/**
 * 
 * @export
 * @interface WorkflowNodeSchemaResponse
 */
export interface WorkflowNodeSchemaResponse {
    /**
     * 
     * @type {WorkflowNodeSchema}
     * @memberof WorkflowNodeSchemaResponse
     */
    'response': WorkflowNodeSchema;
}
/**
 * 
 * @export
 * @interface WorkflowRun
 */
export interface WorkflowRun {
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'workflowRunId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'workflowId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'dagId': string;
    /**
     * 
     * @type {WorkflowRunStatus}
     * @memberof WorkflowRun
     */
    'status': WorkflowRunStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'totalDuration'?: string;
    /**
     * 
     * @type {object}
     * @memberof WorkflowRun
     */
    'executionStatusMap': object;
    /**
     * 
     * @type {RunnerType}
     * @memberof WorkflowRun
     */
    'runnerType': RunnerType;
    /**
     * 
     * @type {ExecutionType}
     * @memberof WorkflowRun
     */
    'executionType': ExecutionType;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'executionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowRun
     */
    'outputAvailable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowRun
     */
    'debugRun'?: boolean;
    /**
     * 
     * @type {{ [key: string]: FileDownloadOutput | DataDriftOutput; }}
     * @memberof WorkflowRun
     */
    'nodeWiseOutput'?: { [key: string]: FileDownloadOutput | DataDriftOutput; };
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'scheduledTime'?: string;
    /**
     * This is a name assigned to a run for display purposes only. If this isn\'t set, default to workflow_run_id. Typically will be Run #number
     * @type {number}
     * @memberof WorkflowRun
     */
    'runNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'dagHash'?: string;
    /**
     * 
     * @type {ExecutedFromSourceType}
     * @memberof WorkflowRun
     */
    'executedFrom': ExecutedFromSourceType;
}
/**
 * This object contains minimal details of a workflow run; Used in listing APIs
 * @export
 * @interface WorkflowRunBrief
 */
export interface WorkflowRunBrief {
    /**
     * 
     * @type {string}
     * @memberof WorkflowRunBrief
     */
    'workflowRunId': string;
    /**
     * 
     * @type {WorkflowRunStatus}
     * @memberof WorkflowRunBrief
     */
    'status': WorkflowRunStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRunBrief
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRunBrief
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRunBrief
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface WorkflowRunDagNodeStatus
 */
export interface WorkflowRunDagNodeStatus {
    /**
     * 
     * @type {string}
     * @memberof WorkflowRunDagNodeStatus
     */
    'nodeId': string;
    /**
     * 
     * @type {OperatorStatusDetails}
     * @memberof WorkflowRunDagNodeStatus
     */
    'statusDetails': OperatorStatusDetails;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum WorkflowRunOperatorStatus {
    Success = 'SUCCESS',
    Running = 'RUNNING',
    NotStarted = 'NOT_STARTED',
    Failed = 'FAILED',
    Terminated = 'TERMINATED'
}

/**
 * Contains download link for the workflow output
 * @export
 * @interface WorkflowRunOutputDownloadLink
 */
export interface WorkflowRunOutputDownloadLink {
    /**
     * 
     * @type {string}
     * @memberof WorkflowRunOutputDownloadLink
     */
    'workflowId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRunOutputDownloadLink
     */
    'workflowRunId': string;
    /**
     * 
     * @type {WorkflowRunStatus}
     * @memberof WorkflowRunOutputDownloadLink
     */
    'status': WorkflowRunStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRunOutputDownloadLink
     */
    'downloadLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRunOutputDownloadLink
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRunOutputDownloadLink
     */
    'fileType'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRunOutputDownloadLink
     */
    'nodeId'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum WorkflowRunStatus {
    NotFound = 'NOT_FOUND',
    Success = 'SUCCESS',
    Running = 'RUNNING',
    CreationFailed = 'CREATION_FAILED',
    ExecutionFailed = 'EXECUTION_FAILED',
    Created = 'CREATED',
    Terminated = 'TERMINATED',
    Scheduled = 'SCHEDULED',
    Skipped = 'SKIPPED'
}

/**
 * 
 * @export
 * @interface WorkflowRunStatusDetailsResponse
 */
export interface WorkflowRunStatusDetailsResponse {
    /**
     * 
     * @type {WorkflowRunStatus}
     * @memberof WorkflowRunStatusDetailsResponse
     */
    'wflRunStatus': WorkflowRunStatus;
    /**
     * 
     * @type {Array<WorkflowRunDagNodeStatus>}
     * @memberof WorkflowRunStatusDetailsResponse
     */
    'operatorsStatus'?: Array<WorkflowRunDagNodeStatus>;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRunStatusDetailsResponse
     */
    'dagHash'?: string;
    /**
     * 
     * @type {GetDAGResponse}
     * @memberof WorkflowRunStatusDetailsResponse
     */
    'dag'?: GetDAGResponse;
}
/**
 * 
 * @export
 * @interface WorkflowSchedule
 */
export interface WorkflowSchedule {
    /**
     * 
     * @type {WorkflowScheduleType}
     * @memberof WorkflowSchedule
     */
    'scheduleType': WorkflowScheduleType;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSchedule
     */
    'scheduleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSchedule
     */
    'frequency'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowSchedule
     */
    'interval'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSchedule
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSchedule
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowSchedule
     */
    'maxFailedOccurrence'?: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum WorkflowScheduleStatus {
    Scheduled = 'SCHEDULED',
    NoScheduleSet = 'NO_SCHEDULE_SET',
    Paused = 'PAUSED'
}

/**
 * Periodic: for an interval-based scheduling of runs (for example: every 2 hours or every 45 minutes). Cron: for specifying `cron` semantics for scheduling runs.
 * @export
 * @enum {string}
 */

export enum WorkflowScheduleType {
    Cron = 'CRON',
    Periodic = 'PERIODIC'
}

/**
 * 
 * @export
 * @interface WorkflowSearchAppModelResponse
 */
export interface WorkflowSearchAppModelResponse {
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchAppModelResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchAppModelResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchAppModelResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchAppModelResponse
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchAppModelResponse
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchAppModelResponse
     */
    'searchAppId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchAppModelResponse
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchAppModelResponse
     */
    'workflowId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchAppModelResponse
     */
    'modelName'?: string;
    /**
     * 
     * @type {InputMode}
     * @memberof WorkflowSearchAppModelResponse
     */
    'inputMode'?: InputMode;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchAppModelResponse
     */
    'resourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchAppModelResponse
     */
    'workflowName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchAppModelResponse
     */
    'workflowDescription'?: string;
}
/**
 * 
 * @export
 * @interface WorkflowSearchData
 */
export interface WorkflowSearchData {
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchData
     */
    'similarText': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchData
     */
    'textSource': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchData
     */
    'pageNumber': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchData
     */
    'recordId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSearchData
     */
    'fileKey'?: string;
}
/**
 * 
 * @export
 * @interface WorkflowSimilarityRequest
 */
export interface WorkflowSimilarityRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowSimilarityRequest
     */
    'inputTextList'?: Array<string>;
}
/**
 * 
 * @export
 * @interface WorkflowSimilarityResponse
 */
export interface WorkflowSimilarityResponse {
    /**
     * 
     * @type {Array<WorkflowSearchData>}
     * @memberof WorkflowSimilarityResponse
     */
    'response': Array<WorkflowSearchData>;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSimilarityResponse
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSimilarityResponse
     */
    'workspaceId'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum WorkflowStatus {
    Empty = '',
    Enabled = 'ENABLED',
    Disabled = 'DISABLED'
}

/**
 * API Representation for Workspace
 * @export
 * @interface Workspace
 */
export interface Workspace {
    /**
     * 
     * @type {any}
     * @memberof Workspace
     */
    'workspaceId': any;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'workspaceName': string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'baseUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'updateDate': string;
}
/**
 * Workspace Activity Type
 * @export
 * @enum {string}
 */

export enum WorkspaceActivityType {
    DatasetRegistered = 'DATASET_REGISTERED',
    ModelCreated = 'MODEL_CREATED',
    ExperimentRecordingCreated = 'EXPERIMENT_RECORDING_CREATED',
    UserJoinedWorkspace = 'USER_JOINED_WORKSPACE',
    TeamCreated = 'TEAM_CREATED',
    EvaluationRecordingCreated = 'EVALUATION_RECORDING_CREATED',
    ProjectCreated = 'PROJECT_CREATED'
}

/**
 * Response with minimal details about a workspace and team. To be used in the context of a user.
 * @export
 * @interface WorkspaceAndTeam
 */
export interface WorkspaceAndTeam {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceAndTeam
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceAndTeam
     */
    'workspaceName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceAndTeam
     */
    'baseUrl': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceAndTeam
     */
    'teamId': string;
}
/**
 * Base User for API Responses
 * @export
 * @interface WorkspaceAutoEnrollSetting
 */
export interface WorkspaceAutoEnrollSetting {
    /**
     * 
     * @type {any}
     * @memberof WorkspaceAutoEnrollSetting
     */
    'accountId': any;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceAutoEnrollSetting
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceAutoEnrollSetting
     */
    'enterpriseDomain': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceAutoEnrollSetting
     */
    'createDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceAutoEnrollSetting
     */
    'enabled': boolean;
}
/**
 * List of enroll settings
 * @export
 * @interface WorkspaceAutoEnrollSettings
 */
export interface WorkspaceAutoEnrollSettings {
    /**
     * 
     * @type {Array<WorkspaceAutoEnrollSetting>}
     * @memberof WorkspaceAutoEnrollSettings
     */
    'workspaces': Array<WorkspaceAutoEnrollSetting>;
}
/**
 * Response for workspace deletion API
 * @export
 * @interface WorkspaceDeletionResponse
 */
export interface WorkspaceDeletionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceDeletionResponse
     */
    'ack': boolean;
}
/**
 * 
 * @export
 * @interface WorkspaceEnrollList
 */
export interface WorkspaceEnrollList {
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspaceEnrollList
     */
    'workspaceIds': Array<string>;
}
/**
 * Workspace invitation details with account details for get workspace invites api calls
 * @export
 * @interface WorkspaceInvitationDetails
 */
export interface WorkspaceInvitationDetails {
    /**
     * 
     * @type {InviteUserToWorkspaceResponse}
     * @memberof WorkspaceInvitationDetails
     */
    'invitation': InviteUserToWorkspaceResponse;
    /**
     * 
     * @type {BaseUser}
     * @memberof WorkspaceInvitationDetails
     */
    'invitedByUserDetails': BaseUser;
    /**
     * 
     * @type {WorkspaceWithAccountDetails}
     * @memberof WorkspaceInvitationDetails
     */
    'workspaceWithAccountDetails': WorkspaceWithAccountDetails;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum WorkspaceInviteStatus {
    Invited = 'invited',
    Revoked = 'revoked',
    Accepted = 'accepted'
}

/**
 * List of workspace invitation details
 * @export
 * @interface WorkspaceInvitesResponse
 */
export interface WorkspaceInvitesResponse {
    /**
     * 
     * @type {Array<WorkspaceInvitationDetails>}
     * @memberof WorkspaceInvitesResponse
     */
    'invitations': Array<WorkspaceInvitationDetails>;
}
/**
 * API Response for Workspace List
 * @export
 * @interface WorkspaceList
 */
export interface WorkspaceList {
    /**
     * 
     * @type {Array<Workspace>}
     * @memberof WorkspaceList
     */
    'response': Array<Workspace>;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceList
     */
    'numRecords': number;
}
/**
 * 
 * @export
 * @interface WorkspaceListWithMemberDetails
 */
export interface WorkspaceListWithMemberDetails {
    /**
     * 
     * @type {Array<WorkspaceWithMemberDetails>}
     * @memberof WorkspaceListWithMemberDetails
     */
    'response': Array<WorkspaceWithMemberDetails>;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceListWithMemberDetails
     */
    'numRecords': number;
}
/**
 * Member object for api response
 * @export
 * @interface WorkspaceMember
 */
export interface WorkspaceMember {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceMember
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceMember
     */
    'workspaceMembershipRole': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceMember
     */
    'workspaceId': string;
    /**
     * 
     * @type {WorkspaceMembershipState}
     * @memberof WorkspaceMember
     */
    'workspaceMembershipState': WorkspaceMembershipState;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceMember
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceMember
     */
    'updateDate': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspaceMember
     */
    'teamIds'?: Array<string>;
    /**
     * 
     * @type {BasicUserDetails}
     * @memberof WorkspaceMember
     */
    'userDetails'?: BasicUserDetails;
}
/**
 * Paginated list of members for get members api response
 * @export
 * @interface WorkspaceMembersList
 */
export interface WorkspaceMembersList {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceMembersList
     */
    'membersCount': number;
    /**
     * 
     * @type {Array<WorkspaceMember>}
     * @memberof WorkspaceMembersList
     */
    'workspaceMembers': Array<WorkspaceMember>;
}
/**
 * 
 * @export
 * @interface WorkspaceMembershipRoleUpdateResponse
 */
export interface WorkspaceMembershipRoleUpdateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceMembershipRoleUpdateResponse
     */
    'ack': boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceMembershipRoleUpdateResponse
     */
    'msg'?: string;
}
/**
 * Workspace Membership Roles
 * @export
 * @enum {string}
 */

export enum WorkspaceMembershipRoles {
    Owner = 'OWNER',
    Maintainer = 'MAINTAINER',
    Member = 'MEMBER'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum WorkspaceMembershipState {
    Inactive = 'INACTIVE',
    Invited = 'INVITED',
    Active = 'ACTIVE'
}

/**
 * 
 * @export
 * @interface WorkspaceServiceResponse
 */
export interface WorkspaceServiceResponse {
    /**
     * 
     * @type {object | any | string | Array<any>}
     * @memberof WorkspaceServiceResponse
     */
    'response'?: object | any | string | Array<any>;
    /**
     * 
     * @type {any}
     * @memberof WorkspaceServiceResponse
     */
    'workspaceId'?: any;
}
/**
 * Response object for Workspace Settings API. This response contains details about hybrid deployment, a snapshot of workspace admins, subscription details, and the owner account.
 * @export
 * @interface WorkspaceSettings
 */
export interface WorkspaceSettings {
    /**
     * 
     * @type {Workspace}
     * @memberof WorkspaceSettings
     */
    'workspace': Workspace;
    /**
     * 
     * @type {DeploymentInfo}
     * @memberof WorkspaceSettings
     */
    'deploymentInfo': DeploymentInfo;
    /**
     * 
     * @type {WorkspaceMembersList}
     * @memberof WorkspaceSettings
     */
    'admins': WorkspaceMembersList;
    /**
     * 
     * @type {SubscriptionWithInfoOnCreditFactors}
     * @memberof WorkspaceSettings
     */
    'subscription': SubscriptionWithInfoOnCreditFactors;
    /**
     * 
     * @type {AccountInfo}
     * @memberof WorkspaceSettings
     */
    'account': AccountInfo;
}
/**
 * Object for update operations on Workspace
 * @export
 * @interface WorkspaceUpdate
 */
export interface WorkspaceUpdate {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUpdate
     */
    'workspaceName'?: string;
}
/**
 * Workspace Usage Object
 * @export
 * @interface WorkspaceUsage
 */
export interface WorkspaceUsage {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceUsage
     */
    'numDatasetsRegistered': number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceUsage
     */
    'numRecordingsRegistered': number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceUsage
     */
    'numExperimentsRecorded': number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceUsage
     */
    'numAnalyzersRun': number;
}
/**
 * Workspace usage info object for api response
 * @export
 * @interface WorkspaceUsageInfo
 */
export interface WorkspaceUsageInfo {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceUsageInfo
     */
    'numDatasetsRegistered': number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceUsageInfo
     */
    'numRecordingsRegistered': number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceUsageInfo
     */
    'numExperimentsRecorded': number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceUsageInfo
     */
    'numAnalyzersRun': number;
}
/**
 * Response for Workspace Usage API
 * @export
 * @interface WorkspaceUsageResponse
 */
export interface WorkspaceUsageResponse {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUsageResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {WorkspaceUsage}
     * @memberof WorkspaceUsageResponse
     */
    'response': WorkspaceUsage;
}
/**
 * 
 * @export
 * @interface WorkspaceUsageResponse1
 */
export interface WorkspaceUsageResponse1 {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceUsageResponse1
     */
    'computeTime': number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceUsageResponse1
     */
    'storageInBytes': number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceUsageResponse1
     */
    'trackingRecords': number;
}
/**
 * Workspace identifies the user as a part of a workspace. A user (identified by user_id) inside a workspace (identified by workspace_id) belong to a team (identified by team_id)
 * @export
 * @interface WorkspaceUser
 */
export interface WorkspaceUser {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUser
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUser
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUser
     */
    'workspaceId': string;
}
/**
 * 
 * @export
 * @interface WorkspaceUserEnrollRequest
 */
export interface WorkspaceUserEnrollRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspaceUserEnrollRequest
     */
    'userIds': Array<string>;
}
/**
 * 
 * @export
 * @interface WorkspaceUserEnrollResponse
 */
export interface WorkspaceUserEnrollResponse {
    /**
     * 
     * @type {Array<WorkspaceMember>}
     * @memberof WorkspaceUserEnrollResponse
     */
    'workspaceMembers': Array<WorkspaceMember>;
}
/**
 * Workspace with account details
 * @export
 * @interface WorkspaceWithAccountDetails
 */
export interface WorkspaceWithAccountDetails {
    /**
     * 
     * @type {any}
     * @memberof WorkspaceWithAccountDetails
     */
    'workspaceId': any;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceWithAccountDetails
     */
    'workspaceName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceWithAccountDetails
     */
    'baseUrl': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceWithAccountDetails
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceWithAccountDetails
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceWithAccountDetails
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceWithAccountDetails
     */
    'accountType': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceWithAccountDetails
     */
    'accountName'?: string;
}
/**
 * API Response for Workspace with Member Details
 * @export
 * @interface WorkspaceWithMemberDetails
 */
export interface WorkspaceWithMemberDetails {
    /**
     * 
     * @type {any}
     * @memberof WorkspaceWithMemberDetails
     */
    'workspaceId': any;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceWithMemberDetails
     */
    'workspaceName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceWithMemberDetails
     */
    'baseUrl': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceWithMemberDetails
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceWithMemberDetails
     */
    'updateDate': string;
    /**
     * 
     * @type {WorkspaceMembersList}
     * @memberof WorkspaceWithMemberDetails
     */
    'members'?: WorkspaceMembersList;
    /**
     * 
     * @type {EnterpriseOwnedAccount | UserOwnedAccount}
     * @memberof WorkspaceWithMemberDetails
     */
    'ownerAccount'?: EnterpriseOwnedAccount | UserOwnedAccount;
    /**
     * 
     * @type {Subscription}
     * @memberof WorkspaceWithMemberDetails
     */
    'attachedSubscription'?: Subscription;
}
/**
 * 
 * @export
 * @interface ZendeskCredentialModel
 */
export interface ZendeskCredentialModel {
    /**
     * 
     * @type {string}
     * @memberof ZendeskCredentialModel
     */
    'subdomain': string;
    /**
     * 
     * @type {string}
     * @memberof ZendeskCredentialModel
     */
    'credentials'?: string;
    /**
     * 
     * @type {string}
     * @memberof ZendeskCredentialModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ZendeskCredentialModel
     */
    'apiToken': string;
    /**
     * 
     * @type {string}
     * @memberof ZendeskCredentialModel
     */
    'startDate'?: string;
}
/**
 * 
 * @export
 * @interface ZurichAlertNotificationPayload
 */
export interface ZurichAlertNotificationPayload {
    /**
     * 
     * @type {string}
     * @memberof ZurichAlertNotificationPayload
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof ZurichAlertNotificationPayload
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ZurichAlertNotificationPayload
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof ZurichAlertNotificationPayload
     */
    'title': string;
}

/**
 * APITokensRoutesApi - axios parameter creator
 * @export
 */
export const APITokensRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create an API token for a workspace. The token will be returned in the response body after the creation.By default, the token will expire in 30 days. The token can be used to access the Markov Lambda API endpoints.
         * @summary Create an API token for a workspace
         * @param {string} userId 
         * @param {CreateAPITokenRequest} createAPITokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAPITokenV1: async (userId: string, createAPITokenRequest: CreateAPITokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createAPITokenV1', 'userId', userId)
            // verify required parameter 'createAPITokenRequest' is not null or undefined
            assertParamExists('createAPITokenV1', 'createAPITokenRequest', createAPITokenRequest)
            const localVarPath = `/haifa/v1/user/{user_id}/token`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAPITokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get API token for a user. Token is scoped to a workspace and team. API will try to return an existing token. However, if no token exists, a new default token will be created.
         * @summary Get Markov API token for user, scoped to a workspace and team.
         * @param {GetTokenRequestModel} getTokenRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAPITokenForWorkspaceV1: async (getTokenRequestModel: GetTokenRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getTokenRequestModel' is not null or undefined
            assertParamExists('getAPITokenForWorkspaceV1', 'getTokenRequestModel', getTokenRequestModel)
            const localVarPath = `/haifa/v1/token/fetch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getTokenRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will return the details of the user from the token. Details will include the user_id, email, name, details of workspaces. Token is set as a bearer token in the header in the header.
         * @summary Get details of a user in exchange of Markov API token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenIdentityV1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/haifa/v1/token/identity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate API token, return token metadata if valid. If token is not valid, API will return 401. Token is set as a bearer token in the header in the header.
         * @summary Validate API token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateTokenV1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/haifa/v1/token/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * APITokensRoutesApi - functional programming interface
 * @export
 */
export const APITokensRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = APITokensRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create an API token for a workspace. The token will be returned in the response body after the creation.By default, the token will expire in 30 days. The token can be used to access the Markov Lambda API endpoints.
         * @summary Create an API token for a workspace
         * @param {string} userId 
         * @param {CreateAPITokenRequest} createAPITokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAPITokenV1(userId: string, createAPITokenRequest: CreateAPITokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAPITokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAPITokenV1(userId, createAPITokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get API token for a user. Token is scoped to a workspace and team. API will try to return an existing token. However, if no token exists, a new default token will be created.
         * @summary Get Markov API token for user, scoped to a workspace and team.
         * @param {GetTokenRequestModel} getTokenRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAPITokenForWorkspaceV1(getTokenRequestModel: GetTokenRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAPITokenForWorkspaceV1(getTokenRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will return the details of the user from the token. Details will include the user_id, email, name, details of workspaces. Token is set as a bearer token in the header in the header.
         * @summary Get details of a user in exchange of Markov API token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenIdentityV1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDTokenInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenIdentityV1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validate API token, return token metadata if valid. If token is not valid, API will return 401. Token is set as a bearer token in the header in the header.
         * @summary Validate API token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateTokenV1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenValidationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateTokenV1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * APITokensRoutesApi - factory interface
 * @export
 */
export const APITokensRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = APITokensRoutesApiFp(configuration)
    return {
        /**
         * Create an API token for a workspace. The token will be returned in the response body after the creation.By default, the token will expire in 30 days. The token can be used to access the Markov Lambda API endpoints.
         * @summary Create an API token for a workspace
         * @param {string} userId 
         * @param {CreateAPITokenRequest} createAPITokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAPITokenV1(userId: string, createAPITokenRequest: CreateAPITokenRequest, options?: any): AxiosPromise<CreateAPITokenResponse> {
            return localVarFp.createAPITokenV1(userId, createAPITokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get API token for a user. Token is scoped to a workspace and team. API will try to return an existing token. However, if no token exists, a new default token will be created.
         * @summary Get Markov API token for user, scoped to a workspace and team.
         * @param {GetTokenRequestModel} getTokenRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAPITokenForWorkspaceV1(getTokenRequestModel: GetTokenRequestModel, options?: any): AxiosPromise<APIToken> {
            return localVarFp.getAPITokenForWorkspaceV1(getTokenRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will return the details of the user from the token. Details will include the user_id, email, name, details of workspaces. Token is set as a bearer token in the header in the header.
         * @summary Get details of a user in exchange of Markov API token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenIdentityV1(options?: any): AxiosPromise<IDTokenInfo> {
            return localVarFp.tokenIdentityV1(options).then((request) => request(axios, basePath));
        },
        /**
         * Validate API token, return token metadata if valid. If token is not valid, API will return 401. Token is set as a bearer token in the header in the header.
         * @summary Validate API token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateTokenV1(options?: any): AxiosPromise<TokenValidationResponse> {
            return localVarFp.validateTokenV1(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * APITokensRoutesApi - object-oriented interface
 * @export
 * @class APITokensRoutesApi
 * @extends {BaseAPI}
 */
export class APITokensRoutesApi extends BaseAPI {
    /**
     * Create an API token for a workspace. The token will be returned in the response body after the creation.By default, the token will expire in 30 days. The token can be used to access the Markov Lambda API endpoints.
     * @summary Create an API token for a workspace
     * @param {string} userId 
     * @param {CreateAPITokenRequest} createAPITokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APITokensRoutesApi
     */
    public createAPITokenV1(userId: string, createAPITokenRequest: CreateAPITokenRequest, options?: AxiosRequestConfig) {
        return APITokensRoutesApiFp(this.configuration).createAPITokenV1(userId, createAPITokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get API token for a user. Token is scoped to a workspace and team. API will try to return an existing token. However, if no token exists, a new default token will be created.
     * @summary Get Markov API token for user, scoped to a workspace and team.
     * @param {GetTokenRequestModel} getTokenRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APITokensRoutesApi
     */
    public getAPITokenForWorkspaceV1(getTokenRequestModel: GetTokenRequestModel, options?: AxiosRequestConfig) {
        return APITokensRoutesApiFp(this.configuration).getAPITokenForWorkspaceV1(getTokenRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will return the details of the user from the token. Details will include the user_id, email, name, details of workspaces. Token is set as a bearer token in the header in the header.
     * @summary Get details of a user in exchange of Markov API token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APITokensRoutesApi
     */
    public tokenIdentityV1(options?: AxiosRequestConfig) {
        return APITokensRoutesApiFp(this.configuration).tokenIdentityV1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate API token, return token metadata if valid. If token is not valid, API will return 401. Token is set as a bearer token in the header in the header.
     * @summary Validate API token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APITokensRoutesApi
     */
    public validateTokenV1(options?: AxiosRequestConfig) {
        return APITokensRoutesApiFp(this.configuration).validateTokenV1(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApacheSupersetAPIsApi - axios parameter creator
 * @export
 */
export const ApacheSupersetAPIsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get status for superset workflow setup
         * @summary Get status for superset workflow setup
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusForSupersetWorkflowSetupV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getStatusForSupersetWorkflowSetupV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/superset/status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get username for token for superset workflow user
         * @param {string} workspaceId 
         * @param {any} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsernameForToken1: async (workspaceId: string, token: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getUsernameForToken1', 'workspaceId', workspaceId)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getUsernameForToken1', 'token', token)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/superset/username-for-token`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Setup the required, roles, datasources in superset according to the given workflow
         * @summary Initialize superset for a given workflow
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initSupersetForWorkflowV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('initSupersetForWorkflowV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/superset/init-for-workflow`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if superset visualization is supported for given workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isVisualizationSupportedV1: async (workspaceId: string, workflowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('isVisualizationSupportedV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('isVisualizationSupportedV1', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/superset/is-supported`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workflowId !== undefined) {
                localVarQueryParameter['workflow_id'] = workflowId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApacheSupersetAPIsApi - functional programming interface
 * @export
 */
export const ApacheSupersetAPIsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApacheSupersetAPIsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get status for superset workflow setup
         * @summary Get status for superset workflow setup
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatusForSupersetWorkflowSetupV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatusForSupersetWorkflowSetupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatusForSupersetWorkflowSetupV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get username for token for superset workflow user
         * @param {string} workspaceId 
         * @param {any} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsernameForToken1(workspaceId: string, token: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSupersetUsernameForTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsernameForToken1(workspaceId, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Setup the required, roles, datasources in superset according to the given workflow
         * @summary Initialize superset for a given workflow
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initSupersetForWorkflowV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitSupersetForWorkflowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initSupersetForWorkflowV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if superset visualization is supported for given workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isVisualizationSupportedV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IsVisualizationSupportedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isVisualizationSupportedV1(workspaceId, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApacheSupersetAPIsApi - factory interface
 * @export
 */
export const ApacheSupersetAPIsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApacheSupersetAPIsApiFp(configuration)
    return {
        /**
         * Get status for superset workflow setup
         * @summary Get status for superset workflow setup
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusForSupersetWorkflowSetupV1(workspaceId: string, options?: any): AxiosPromise<GetStatusForSupersetWorkflowSetupResponse> {
            return localVarFp.getStatusForSupersetWorkflowSetupV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get username for token for superset workflow user
         * @param {string} workspaceId 
         * @param {any} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsernameForToken1(workspaceId: string, token: any, options?: any): AxiosPromise<GetSupersetUsernameForTokenResponse> {
            return localVarFp.getUsernameForToken1(workspaceId, token, options).then((request) => request(axios, basePath));
        },
        /**
         * Setup the required, roles, datasources in superset according to the given workflow
         * @summary Initialize superset for a given workflow
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initSupersetForWorkflowV1(workspaceId: string, options?: any): AxiosPromise<InitSupersetForWorkflowResponse> {
            return localVarFp.initSupersetForWorkflowV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if superset visualization is supported for given workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isVisualizationSupportedV1(workspaceId: string, workflowId: string, options?: any): AxiosPromise<IsVisualizationSupportedResponse> {
            return localVarFp.isVisualizationSupportedV1(workspaceId, workflowId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApacheSupersetAPIsApi - object-oriented interface
 * @export
 * @class ApacheSupersetAPIsApi
 * @extends {BaseAPI}
 */
export class ApacheSupersetAPIsApi extends BaseAPI {
    /**
     * Get status for superset workflow setup
     * @summary Get status for superset workflow setup
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApacheSupersetAPIsApi
     */
    public getStatusForSupersetWorkflowSetupV1(workspaceId: string, options?: AxiosRequestConfig) {
        return ApacheSupersetAPIsApiFp(this.configuration).getStatusForSupersetWorkflowSetupV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get username for token for superset workflow user
     * @param {string} workspaceId 
     * @param {any} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApacheSupersetAPIsApi
     */
    public getUsernameForToken1(workspaceId: string, token: any, options?: AxiosRequestConfig) {
        return ApacheSupersetAPIsApiFp(this.configuration).getUsernameForToken1(workspaceId, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Setup the required, roles, datasources in superset according to the given workflow
     * @summary Initialize superset for a given workflow
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApacheSupersetAPIsApi
     */
    public initSupersetForWorkflowV1(workspaceId: string, options?: AxiosRequestConfig) {
        return ApacheSupersetAPIsApiFp(this.configuration).initSupersetForWorkflowV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if superset visualization is supported for given workflow
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApacheSupersetAPIsApi
     */
    public isVisualizationSupportedV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig) {
        return ApacheSupersetAPIsApiFp(this.configuration).isVisualizationSupportedV1(workspaceId, workflowId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AppBuilderApi - axios parameter creator
 * @export
 */
export const AppBuilderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Archive an app on Gen AI App Builder
         * @summary Archive an app on Gen AI App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveAppBuilderV1: async (workspaceId: string, appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('archiveAppBuilderV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('archiveAppBuilderV1', 'appId', appId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/builder/app/{app_id}/archive`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new app using Gen AI App Builder
         * @summary Create a new app using Gen AI App Builder
         * @param {string} workspaceId 
         * @param {CreateAppBuilderRequest} createAppBuilderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppBuilderV1: async (workspaceId: string, createAppBuilderRequest: CreateAppBuilderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createAppBuilderV1', 'workspaceId', workspaceId)
            // verify required parameter 'createAppBuilderRequest' is not null or undefined
            assertParamExists('createAppBuilderV1', 'createAppBuilderRequest', createAppBuilderRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/builder/app/create`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppBuilderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Execute an app on Gen AI App Builder
         * @summary Execute an app on Gen AI App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {ExecuteAppBuilderRequest} executeAppBuilderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeAppV1: async (workspaceId: string, appId: string, executeAppBuilderRequest: ExecuteAppBuilderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('executeAppV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('executeAppV1', 'appId', appId)
            // verify required parameter 'executeAppBuilderRequest' is not null or undefined
            assertParamExists('executeAppV1', 'executeAppBuilderRequest', executeAppBuilderRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/execute`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(executeAppBuilderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generates Prompt for LLM for given context in request.
         * @summary Generates Prompt for LLM for given context in request.
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {GeneratePromptForLLMRequest} generatePromptForLLMRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePromptForLLMV1: async (workspaceId: string, appId: string, generatePromptForLLMRequest: GeneratePromptForLLMRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('generatePromptForLLMV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('generatePromptForLLMV1', 'appId', appId)
            // verify required parameter 'generatePromptForLLMRequest' is not null or undefined
            assertParamExists('generatePromptForLLMV1', 'generatePromptForLLMRequest', generatePromptForLLMRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/generate-prompt`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generatePromptForLLMRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get details of app on Gen AI App Builder
         * @summary Get details of app on Gen AI App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppBuilderDetailsV1: async (workspaceId: string, appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAppBuilderDetailsV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppBuilderDetailsV1', 'appId', appId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/builder/app/{app_id}/detail`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches prompt suggestions
         * @summary Fetches prompt suggestions
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromptSuggestionsV1: async (workspaceId: string, appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getPromptSuggestionsV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getPromptSuggestionsV1', 'appId', appId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/prompt-suggestions`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List All the Apps available in GenAI Builder
         * @summary List All the Apps available in GenAI Builder
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAppBuilderV1: async (workspaceId: string, start?: number, end?: number, ts?: string, status?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listAppBuilderV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/builder/app/list`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the available models for App Builder
         * @summary List the available models for App Builder
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsAppBuilderV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('modelsAppBuilderV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/builder/models`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Publish an app on Gen AI App Builder
         * @summary Publish an app on Gen AI App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishAppBuilderV1: async (workspaceId: string, appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('publishAppBuilderV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('publishAppBuilderV1', 'appId', appId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/builder/app/{app_id}/publish`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update details for any app on Gen AI App Builder
         * @summary Update details for any app on Gen AI App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {UpdateAppBuilderRequest} updateAppBuilderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppBuilderV1: async (workspaceId: string, appId: string, updateAppBuilderRequest: UpdateAppBuilderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateAppBuilderV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('updateAppBuilderV1', 'appId', appId)
            // verify required parameter 'updateAppBuilderRequest' is not null or undefined
            assertParamExists('updateAppBuilderV1', 'updateAppBuilderRequest', updateAppBuilderRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/builder/app/{app_id}/update`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppBuilderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppBuilderApi - functional programming interface
 * @export
 */
export const AppBuilderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppBuilderApiAxiosParamCreator(configuration)
    return {
        /**
         * Archive an app on Gen AI App Builder
         * @summary Archive an app on Gen AI App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveAppBuilderV1(workspaceId: string, appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArchiveAppBuilderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveAppBuilderV1(workspaceId, appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new app using Gen AI App Builder
         * @summary Create a new app using Gen AI App Builder
         * @param {string} workspaceId 
         * @param {CreateAppBuilderRequest} createAppBuilderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppBuilderV1(workspaceId: string, createAppBuilderRequest: CreateAppBuilderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAppBuilderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppBuilderV1(workspaceId, createAppBuilderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Execute an app on Gen AI App Builder
         * @summary Execute an app on Gen AI App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {ExecuteAppBuilderRequest} executeAppBuilderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeAppV1(workspaceId: string, appId: string, executeAppBuilderRequest: ExecuteAppBuilderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExecuteAppBuilderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executeAppV1(workspaceId, appId, executeAppBuilderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generates Prompt for LLM for given context in request.
         * @summary Generates Prompt for LLM for given context in request.
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {GeneratePromptForLLMRequest} generatePromptForLLMRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePromptForLLMV1(workspaceId: string, appId: string, generatePromptForLLMRequest: GeneratePromptForLLMRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratePromptForLLMResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatePromptForLLMV1(workspaceId, appId, generatePromptForLLMRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get details of app on Gen AI App Builder
         * @summary Get details of app on Gen AI App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppBuilderDetailsV1(workspaceId: string, appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAppBuilderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppBuilderDetailsV1(workspaceId, appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches prompt suggestions
         * @summary Fetches prompt suggestions
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromptSuggestionsV1(workspaceId: string, appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPromptSuggestionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromptSuggestionsV1(workspaceId, appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List All the Apps available in GenAI Builder
         * @summary List All the Apps available in GenAI Builder
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAppBuilderV1(workspaceId: string, start?: number, end?: number, ts?: string, status?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAppBuilderPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAppBuilderV1(workspaceId, start, end, ts, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the available models for App Builder
         * @summary List the available models for App Builder
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelsAppBuilderV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLLMModelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelsAppBuilderV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Publish an app on Gen AI App Builder
         * @summary Publish an app on Gen AI App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishAppBuilderV1(workspaceId: string, appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublishAppBuilderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishAppBuilderV1(workspaceId, appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update details for any app on Gen AI App Builder
         * @summary Update details for any app on Gen AI App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {UpdateAppBuilderRequest} updateAppBuilderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppBuilderV1(workspaceId: string, appId: string, updateAppBuilderRequest: UpdateAppBuilderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAppBuilderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppBuilderV1(workspaceId, appId, updateAppBuilderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppBuilderApi - factory interface
 * @export
 */
export const AppBuilderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppBuilderApiFp(configuration)
    return {
        /**
         * Archive an app on Gen AI App Builder
         * @summary Archive an app on Gen AI App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveAppBuilderV1(workspaceId: string, appId: string, options?: any): AxiosPromise<ArchiveAppBuilderResponse> {
            return localVarFp.archiveAppBuilderV1(workspaceId, appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new app using Gen AI App Builder
         * @summary Create a new app using Gen AI App Builder
         * @param {string} workspaceId 
         * @param {CreateAppBuilderRequest} createAppBuilderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppBuilderV1(workspaceId: string, createAppBuilderRequest: CreateAppBuilderRequest, options?: any): AxiosPromise<CreateAppBuilderResponse> {
            return localVarFp.createAppBuilderV1(workspaceId, createAppBuilderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Execute an app on Gen AI App Builder
         * @summary Execute an app on Gen AI App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {ExecuteAppBuilderRequest} executeAppBuilderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeAppV1(workspaceId: string, appId: string, executeAppBuilderRequest: ExecuteAppBuilderRequest, options?: any): AxiosPromise<ExecuteAppBuilderResponse> {
            return localVarFp.executeAppV1(workspaceId, appId, executeAppBuilderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Generates Prompt for LLM for given context in request.
         * @summary Generates Prompt for LLM for given context in request.
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {GeneratePromptForLLMRequest} generatePromptForLLMRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePromptForLLMV1(workspaceId: string, appId: string, generatePromptForLLMRequest: GeneratePromptForLLMRequest, options?: any): AxiosPromise<GeneratePromptForLLMResponse> {
            return localVarFp.generatePromptForLLMV1(workspaceId, appId, generatePromptForLLMRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get details of app on Gen AI App Builder
         * @summary Get details of app on Gen AI App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppBuilderDetailsV1(workspaceId: string, appId: string, options?: any): AxiosPromise<GetAppBuilderResponse> {
            return localVarFp.getAppBuilderDetailsV1(workspaceId, appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches prompt suggestions
         * @summary Fetches prompt suggestions
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromptSuggestionsV1(workspaceId: string, appId: string, options?: any): AxiosPromise<GetPromptSuggestionsResponse> {
            return localVarFp.getPromptSuggestionsV1(workspaceId, appId, options).then((request) => request(axios, basePath));
        },
        /**
         * List All the Apps available in GenAI Builder
         * @summary List All the Apps available in GenAI Builder
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAppBuilderV1(workspaceId: string, start?: number, end?: number, ts?: string, status?: string, options?: any): AxiosPromise<ListAppBuilderPaginatedResponse> {
            return localVarFp.listAppBuilderV1(workspaceId, start, end, ts, status, options).then((request) => request(axios, basePath));
        },
        /**
         * List the available models for App Builder
         * @summary List the available models for App Builder
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsAppBuilderV1(workspaceId: string, options?: any): AxiosPromise<ListLLMModelsResponse> {
            return localVarFp.modelsAppBuilderV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Publish an app on Gen AI App Builder
         * @summary Publish an app on Gen AI App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishAppBuilderV1(workspaceId: string, appId: string, options?: any): AxiosPromise<PublishAppBuilderResponse> {
            return localVarFp.publishAppBuilderV1(workspaceId, appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update details for any app on Gen AI App Builder
         * @summary Update details for any app on Gen AI App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {UpdateAppBuilderRequest} updateAppBuilderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppBuilderV1(workspaceId: string, appId: string, updateAppBuilderRequest: UpdateAppBuilderRequest, options?: any): AxiosPromise<UpdateAppBuilderResponse> {
            return localVarFp.updateAppBuilderV1(workspaceId, appId, updateAppBuilderRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppBuilderApi - object-oriented interface
 * @export
 * @class AppBuilderApi
 * @extends {BaseAPI}
 */
export class AppBuilderApi extends BaseAPI {
    /**
     * Archive an app on Gen AI App Builder
     * @summary Archive an app on Gen AI App Builder
     * @param {string} workspaceId 
     * @param {string} appId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppBuilderApi
     */
    public archiveAppBuilderV1(workspaceId: string, appId: string, options?: AxiosRequestConfig) {
        return AppBuilderApiFp(this.configuration).archiveAppBuilderV1(workspaceId, appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new app using Gen AI App Builder
     * @summary Create a new app using Gen AI App Builder
     * @param {string} workspaceId 
     * @param {CreateAppBuilderRequest} createAppBuilderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppBuilderApi
     */
    public createAppBuilderV1(workspaceId: string, createAppBuilderRequest: CreateAppBuilderRequest, options?: AxiosRequestConfig) {
        return AppBuilderApiFp(this.configuration).createAppBuilderV1(workspaceId, createAppBuilderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Execute an app on Gen AI App Builder
     * @summary Execute an app on Gen AI App Builder
     * @param {string} workspaceId 
     * @param {string} appId 
     * @param {ExecuteAppBuilderRequest} executeAppBuilderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppBuilderApi
     */
    public executeAppV1(workspaceId: string, appId: string, executeAppBuilderRequest: ExecuteAppBuilderRequest, options?: AxiosRequestConfig) {
        return AppBuilderApiFp(this.configuration).executeAppV1(workspaceId, appId, executeAppBuilderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generates Prompt for LLM for given context in request.
     * @summary Generates Prompt for LLM for given context in request.
     * @param {string} workspaceId 
     * @param {string} appId 
     * @param {GeneratePromptForLLMRequest} generatePromptForLLMRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppBuilderApi
     */
    public generatePromptForLLMV1(workspaceId: string, appId: string, generatePromptForLLMRequest: GeneratePromptForLLMRequest, options?: AxiosRequestConfig) {
        return AppBuilderApiFp(this.configuration).generatePromptForLLMV1(workspaceId, appId, generatePromptForLLMRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get details of app on Gen AI App Builder
     * @summary Get details of app on Gen AI App Builder
     * @param {string} workspaceId 
     * @param {string} appId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppBuilderApi
     */
    public getAppBuilderDetailsV1(workspaceId: string, appId: string, options?: AxiosRequestConfig) {
        return AppBuilderApiFp(this.configuration).getAppBuilderDetailsV1(workspaceId, appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches prompt suggestions
     * @summary Fetches prompt suggestions
     * @param {string} workspaceId 
     * @param {string} appId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppBuilderApi
     */
    public getPromptSuggestionsV1(workspaceId: string, appId: string, options?: AxiosRequestConfig) {
        return AppBuilderApiFp(this.configuration).getPromptSuggestionsV1(workspaceId, appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List All the Apps available in GenAI Builder
     * @summary List All the Apps available in GenAI Builder
     * @param {string} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppBuilderApi
     */
    public listAppBuilderV1(workspaceId: string, start?: number, end?: number, ts?: string, status?: string, options?: AxiosRequestConfig) {
        return AppBuilderApiFp(this.configuration).listAppBuilderV1(workspaceId, start, end, ts, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the available models for App Builder
     * @summary List the available models for App Builder
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppBuilderApi
     */
    public modelsAppBuilderV1(workspaceId: string, options?: AxiosRequestConfig) {
        return AppBuilderApiFp(this.configuration).modelsAppBuilderV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Publish an app on Gen AI App Builder
     * @summary Publish an app on Gen AI App Builder
     * @param {string} workspaceId 
     * @param {string} appId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppBuilderApi
     */
    public publishAppBuilderV1(workspaceId: string, appId: string, options?: AxiosRequestConfig) {
        return AppBuilderApiFp(this.configuration).publishAppBuilderV1(workspaceId, appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update details for any app on Gen AI App Builder
     * @summary Update details for any app on Gen AI App Builder
     * @param {string} workspaceId 
     * @param {string} appId 
     * @param {UpdateAppBuilderRequest} updateAppBuilderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppBuilderApi
     */
    public updateAppBuilderV1(workspaceId: string, appId: string, updateAppBuilderRequest: UpdateAppBuilderRequest, options?: AxiosRequestConfig) {
        return AppBuilderApiFp(this.configuration).updateAppBuilderV1(workspaceId, appId, updateAppBuilderRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AppStoreApi - axios parameter creator
 * @export
 */
export const AppStoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add or update a custom style guide rule set for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     service_request: Request body for adding a style guide rule set     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     AddUpdateStyleGuideRuleSetResponse: response of adding a style guide rule set
         * @summary Add or update a custom style guide rule set for a document
         * @param {string} workspaceId 
         * @param {AddUpdateStyleGuideRuleSetRequest} addUpdateStyleGuideRuleSetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUpdateStyleGuideRuleSetV1: async (workspaceId: string, addUpdateStyleGuideRuleSetRequest: AddUpdateStyleGuideRuleSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addUpdateStyleGuideRuleSetV1', 'workspaceId', workspaceId)
            // verify required parameter 'addUpdateStyleGuideRuleSetRequest' is not null or undefined
            assertParamExists('addUpdateStyleGuideRuleSetV1', 'addUpdateStyleGuideRuleSetRequest', addUpdateStyleGuideRuleSetRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/style-guide/add-update-ruleset`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUpdateStyleGuideRuleSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a custom style guide rule for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     rule_set_id: rule set to which the rule is to be added     service_request: Request body for adding a style guide rule     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     AddUpdateStyleGuideRuleResponse: response of adding a style guide rule
         * @summary Add a custom style guide rule for a document
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {AddStyleGuideRuleRequest} addStyleGuideRuleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUpdateStyleGuideRuleV1: async (workspaceId: string, ruleSetId: string, addStyleGuideRuleRequest: AddStyleGuideRuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addUpdateStyleGuideRuleV1', 'workspaceId', workspaceId)
            // verify required parameter 'ruleSetId' is not null or undefined
            assertParamExists('addUpdateStyleGuideRuleV1', 'ruleSetId', ruleSetId)
            // verify required parameter 'addStyleGuideRuleRequest' is not null or undefined
            assertParamExists('addUpdateStyleGuideRuleV1', 'addStyleGuideRuleRequest', addStyleGuideRuleRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/style-guide/rule_set/{rule_set_id}/add-update-rule`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"rule_set_id"}}`, encodeURIComponent(String(ruleSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addStyleGuideRuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add or update a custom style guide rule set for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     service_request: Request body for adding words to custom dictionary     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     AddWordsToCustomDictionaryResponse: response of add words to the custom dictionary
         * @summary Add words to the given custom dictionary
         * @param {string} workspaceId 
         * @param {AddWordsToCustomDictionaryRequest} addWordsToCustomDictionaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWordsToCustomDictionaryV1: async (workspaceId: string, addWordsToCustomDictionaryRequest: AddWordsToCustomDictionaryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addWordsToCustomDictionaryV1', 'workspaceId', workspaceId)
            // verify required parameter 'addWordsToCustomDictionaryRequest' is not null or undefined
            assertParamExists('addWordsToCustomDictionaryV1', 'addWordsToCustomDictionaryRequest', addWordsToCustomDictionaryRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/custom-dictionary/add-words`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addWordsToCustomDictionaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * each page in the pdf is parsed into segments and stored in the order in which they occur in the pdf
         * @summary start file analysis and parse all the segments in the page to be used for copy edit, this analysis is requested for one or more pages
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {AnalyseFileRequest} analyseFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyseFileV1: async (workspaceId: string, documentId: string, analyseFileRequest: AnalyseFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('analyseFileV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('analyseFileV1', 'documentId', documentId)
            // verify required parameter 'analyseFileRequest' is not null or undefined
            assertParamExists('analyseFileV1', 'analyseFileRequest', analyseFileRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document_id/{document_id}/analyse-file`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analyseFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches the suitable analytics
         * @summary Fetches analytics of the copy edit application based on the filters
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {number} [days] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [projectIds] 
         * @param {Array<string>} [documentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsCopyEditFiltersV1: async (workspaceId: string, appId: string, days?: number, userIds?: Array<string>, projectIds?: Array<string>, documentIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('analyticsCopyEditFiltersV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('analyticsCopyEditFiltersV1', 'appId', appId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/app/{app_id}/analytics-filtered`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (days !== undefined) {
                localVarQueryParameter['days'] = days;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (projectIds) {
                localVarQueryParameter['project_ids'] = projectIds;
            }

            if (documentIds) {
                localVarQueryParameter['document_ids'] = documentIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches user count, unique daily active users, documents improved, writing sessions,    suggestions generated
         * @summary Fetches analytics of the copy edit application
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {AppAnalyticsRequest} appAnalyticsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsCopyEditV1: async (workspaceId: string, appId: string, appAnalyticsRequest: AppAnalyticsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('analyticsCopyEditV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('analyticsCopyEditV1', 'appId', appId)
            // verify required parameter 'appAnalyticsRequest' is not null or undefined
            assertParamExists('analyticsCopyEditV1', 'appAnalyticsRequest', appAnalyticsRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/analytics`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appAnalyticsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analyze text resources for a page
         * @param {string} workspaceId 
         * @param {number} pageId 
         * @param {string} documentId 
         * @param {PageTextResources} pageTextResources 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyzeTextsV1: async (workspaceId: string, pageId: number, documentId: string, pageTextResources: PageTextResources, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('analyzeTextsV1', 'workspaceId', workspaceId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('analyzeTextsV1', 'pageId', pageId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('analyzeTextsV1', 'documentId', documentId)
            // verify required parameter 'pageTextResources' is not null or undefined
            assertParamExists('analyzeTextsV1', 'pageTextResources', pageTextResources)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/page/{page_id}/analyse`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (documentId !== undefined) {
                localVarQueryParameter['document_id'] = documentId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageTextResources, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Attach dictionaries to an existing. These will be used when document is re-analyzed
         * @summary Attach dictionaries to an existing document
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {AttachCustomDictionariesToDocumentRequest} attachCustomDictionariesToDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachDictionariesToDocumentV1: async (workspaceId: string, documentId: string, attachCustomDictionariesToDocumentRequest: AttachCustomDictionariesToDocumentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('attachDictionariesToDocumentV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('attachDictionariesToDocumentV1', 'documentId', documentId)
            // verify required parameter 'attachCustomDictionariesToDocumentRequest' is not null or undefined
            assertParamExists('attachDictionariesToDocumentV1', 'attachCustomDictionariesToDocumentRequest', attachCustomDictionariesToDocumentRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/dictionary/attach`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachCustomDictionariesToDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bulk upload rules for a rule set
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUploadRulesV1: async (workspaceId: string, ruleSetId: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('bulkUploadRulesV1', 'workspaceId', workspaceId)
            // verify required parameter 'ruleSetId' is not null or undefined
            assertParamExists('bulkUploadRulesV1', 'ruleSetId', ruleSetId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/style-guide/rule_set/{rule_set_id}/bulk-upload-rules`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"rule_set_id"}}`, encodeURIComponent(String(ruleSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Async call to check Plagiarism of the content provided
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {CheckContentPlagiarismRequestModel} checkContentPlagiarismRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkContentPlagiarismV1: async (workspaceId: string, documentId: string, pageId: number, checkContentPlagiarismRequestModel: CheckContentPlagiarismRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('checkContentPlagiarismV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('checkContentPlagiarismV1', 'documentId', documentId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('checkContentPlagiarismV1', 'pageId', pageId)
            // verify required parameter 'checkContentPlagiarismRequestModel' is not null or undefined
            assertParamExists('checkContentPlagiarismV1', 'checkContentPlagiarismRequestModel', checkContentPlagiarismRequestModel)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/page/{page_id}/plagiarism/check`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkContentPlagiarismRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a pre-signed s3 url for uploading document assets for copy edit app.
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {DocumentUploadRequest} documentUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentAssetsUploadUrlV1: async (workspaceId: string, appId: AppIdType, documentUploadRequest: DocumentUploadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createDocumentAssetsUploadUrlV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('createDocumentAssetsUploadUrlV1', 'appId', appId)
            // verify required parameter 'documentUploadRequest' is not null or undefined
            assertParamExists('createDocumentAssetsUploadUrlV1', 'documentUploadRequest', documentUploadRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/store/app/{app_id}/document/create-upload-url`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentUploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a citation for a page
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {CopyEditCitation} copyEditCitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentCitationV1: async (workspaceId: string, documentId: string, copyEditCitation: CopyEditCitation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createDocumentCitationV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('createDocumentCitationV1', 'documentId', documentId)
            // verify required parameter 'copyEditCitation' is not null or undefined
            assertParamExists('createDocumentCitationV1', 'copyEditCitation', copyEditCitation)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/citation`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyEditCitation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a project for the gen ai app Args:     workspace_id: workspace id     app_id: app id     request: request body     user: user object     background_tasks: to push analytics to bangalore service  Returns: CreateProjectResponse
         * @summary Create a project for the gen ai app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {CreateGenAIProjectRequest} createGenAIProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGenAIProjectV1: async (workspaceId: string, appId: AppIdType, createGenAIProjectRequest: CreateGenAIProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createGenAIProjectV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('createGenAIProjectV1', 'appId', appId)
            // verify required parameter 'createGenAIProjectRequest' is not null or undefined
            assertParamExists('createGenAIProjectV1', 'createGenAIProjectRequest', createGenAIProjectRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/project`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGenAIProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Will set page_ids with their latest page_versions against a particular milestone_id  Args:     workspace_id (str): workspace id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     CreateMilestoneResponse
         * @summary Save the latest page_versions of all the pages against the latest generated milestone
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMilestoneV1: async (workspaceId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createMilestoneV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('createMilestoneV1', 'documentId', documentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/create-milestone`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a custom dictionary for a workspace that can be applied to a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     service_request: Request body for creating a custom dictionary     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     CreateCustomDictionaryResponse: response of creating a custom dictionary
         * @summary Create a custom dictionary for a document
         * @param {string} workspaceId 
         * @param {CreateOrUpdateCustomDictionaryRequest} createOrUpdateCustomDictionaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateCustomDictionaryV1: async (workspaceId: string, createOrUpdateCustomDictionaryRequest: CreateOrUpdateCustomDictionaryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createOrUpdateCustomDictionaryV1', 'workspaceId', workspaceId)
            // verify required parameter 'createOrUpdateCustomDictionaryRequest' is not null or undefined
            assertParamExists('createOrUpdateCustomDictionaryV1', 'createOrUpdateCustomDictionaryRequest', createOrUpdateCustomDictionaryRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/custom-dictionary/create`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateCustomDictionaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create upload url for rule set
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createUploadURLForRuleSetV1: async (workspaceId: string, ruleSetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createUploadURLForRuleSetV1', 'workspaceId', workspaceId)
            // verify required parameter 'ruleSetId' is not null or undefined
            assertParamExists('createUploadURLForRuleSetV1', 'ruleSetId', ruleSetId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/style-guide/rule_set/{rule_set_id}/upload-url`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"rule_set_id"}}`, encodeURIComponent(String(ruleSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete citations for a particular document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCitationsV1: async (workspaceId: string, documentId: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteCitationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteCitationsV1', 'documentId', documentId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteCitationsV1', 'requestBody', requestBody)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/delete-citations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a custom dictionary from a workspace  Args:    workspace_id (str): workspace id that uniquely identifies the customer    dictionary_id (str): dictionary id that uniquely identifies the dictionary    request (Request): FastAPI Request object    response (Response): FastAPI Response object    user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:    CreateCustomDictionaryResponse: response of creating a custom dictionary
         * @summary Delete a custom dictionary from the document
         * @param {string} workspaceId 
         * @param {string} dictionaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomDictionaryV1: async (workspaceId: string, dictionaryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteCustomDictionaryV1', 'workspaceId', workspaceId)
            // verify required parameter 'dictionaryId' is not null or undefined
            assertParamExists('deleteCustomDictionaryV1', 'dictionaryId', dictionaryId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/custom-dictionary/{dictionary_id}/delete`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dictionary_id"}}`, encodeURIComponent(String(dictionaryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a custom dictionary from a workspace  Args:    workspace_id (str): workspace id that uniquely identifies the customer    dictionary_id (str): dictionary id that uniquely identifies the dictionary    dictionary_word_list: List of words that need to be deleted    request (Request): FastAPI Request object    response (Response): FastAPI Response object    user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:    CreateCustomDictionaryResponse: response of creating a custom dictionary
         * @summary Delete a custom dictionary word from the dictionary
         * @param {string} workspaceId 
         * @param {string} dictionaryId 
         * @param {DeleteWordsFromCustomDictionaryRequest} deleteWordsFromCustomDictionaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomDictionaryWordV1: async (workspaceId: string, dictionaryId: string, deleteWordsFromCustomDictionaryRequest: DeleteWordsFromCustomDictionaryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteCustomDictionaryWordV1', 'workspaceId', workspaceId)
            // verify required parameter 'dictionaryId' is not null or undefined
            assertParamExists('deleteCustomDictionaryWordV1', 'dictionaryId', dictionaryId)
            // verify required parameter 'deleteWordsFromCustomDictionaryRequest' is not null or undefined
            assertParamExists('deleteCustomDictionaryWordV1', 'deleteWordsFromCustomDictionaryRequest', deleteWordsFromCustomDictionaryRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/custom-dictionary/{dictionary_id}/delete-word`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dictionary_id"}}`, encodeURIComponent(String(dictionaryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteWordsFromCustomDictionaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the document and all its related data
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentV1: async (workspaceId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteDocumentV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteDocumentV1', 'documentId', documentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document_id/{document_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a project for the Gen AI app Args:     project_id: project id for the project     workspace_id: workspace id     app_id: app id     user: user object     background_tasks: to push analytics to bangalore service  Returns: CreateProjectResponse
         * @summary Delete a project for the Gen AI app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGenAIProjectV1: async (workspaceId: string, appId: AppIdType, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteGenAIProjectV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('deleteGenAIProjectV1', 'appId', appId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteGenAIProjectV1', 'projectId', projectId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/project/{project_id}/delete`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete suggestions for a page
         * @param {string} workspaceId 
         * @param {number} pageId 
         * @param {string} documentId 
         * @param {Array<string>} [resourceIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePageSuggestionsV1: async (workspaceId: string, pageId: number, documentId: string, resourceIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deletePageSuggestionsV1', 'workspaceId', workspaceId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('deletePageSuggestionsV1', 'pageId', pageId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deletePageSuggestionsV1', 'documentId', documentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/page/{page_id}/suggestions`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (documentId !== undefined) {
                localVarQueryParameter['document_id'] = documentId;
            }

            if (resourceIds) {
                localVarQueryParameter['resource_ids'] = resourceIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a custom style guide rule set for a document  Args:     rule_set_id: Rule set to be deleted     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     DeleteStyleGuideRuleSetResponse: response of deleting a style guide rule set
         * @summary Delete a custom style guide rule set for a document
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStyleGuideRuleSetV1: async (workspaceId: string, ruleSetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteStyleGuideRuleSetV1', 'workspaceId', workspaceId)
            // verify required parameter 'ruleSetId' is not null or undefined
            assertParamExists('deleteStyleGuideRuleSetV1', 'ruleSetId', ruleSetId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/style-guide/rule_set/{rule_set_id}/delete-ruleset`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"rule_set_id"}}`, encodeURIComponent(String(ruleSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a custom style guide rule for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     rule_id: rule to be deleted     rule_set_id: rule set from which the rule is to be deleted     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     DeleteStyleGuideRuleResponse: response of deleting a style guide rule
         * @summary Delete a custom style guide rule for a document
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStyleGuideRuleV1: async (workspaceId: string, ruleSetId: string, ruleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteStyleGuideRuleV1', 'workspaceId', workspaceId)
            // verify required parameter 'ruleSetId' is not null or undefined
            assertParamExists('deleteStyleGuideRuleV1', 'ruleSetId', ruleSetId)
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('deleteStyleGuideRuleV1', 'ruleId', ruleId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/style-guide/rule_set/{rule_set_id}/rule/{rule_id}/delete-rule`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"rule_set_id"}}`, encodeURIComponent(String(ruleSetId)))
                .replace(`{${"rule_id"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ask questions to a given document (currently only PDF) and get their result  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     service_request: Request body for qna with pdf  Returns:     QnaWithPdfResponse: response of the doing qna with pdf
         * @summary Get Results of questions asked to a pdf
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {QnaWithPdfRequestBody} qnaWithPdfRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doQNAWithPdfV1: async (workspaceId: string, documentId: string, qnaWithPdfRequestBody: QnaWithPdfRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('doQNAWithPdfV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('doQNAWithPdfV1', 'documentId', documentId)
            // verify required parameter 'qnaWithPdfRequestBody' is not null or undefined
            assertParamExists('doQNAWithPdfV1', 'qnaWithPdfRequestBody', qnaWithPdfRequestBody)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/store/app/QNA/document/{document_id}/do-qna`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(qnaWithPdfRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch documents uploaded by user
         * @param {string} workspaceId 
         * @param {Array<string>} [userIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDocumentsV1: async (workspaceId: string, userIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('fetchDocumentsV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/documents`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch projects for the gen ai app Args:     workspace_id: workspace id     app_id: app id     user: user object  Returns: FetchProjectsResponse
         * @summary Fetch projects for the gen ai app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchGenAIProjectsV1: async (workspaceId: string, appId: AppIdType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('fetchGenAIProjectsV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('fetchGenAIProjectsV1', 'appId', appId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/projects`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch projects for the gen ai app (paginated) Args:     workspace_id: workspace id     app_id: app id     user: user object  Returns: FetchProjectsResponse
         * @summary Fetch projects for the gen ai app (paginated)
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchGenAIProjectsV2: async (workspaceId: string, appId: AppIdType, start: number, end: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('fetchGenAIProjectsV2', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('fetchGenAIProjectsV2', 'appId', appId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('fetchGenAIProjectsV2', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('fetchGenAIProjectsV2', 'end', end)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/app/{app_id}/projects`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Will return with page_ids and their page_versions for the given milestone  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     milestone_id: milestone_id for which the whole document will be fetched  Returns:     FetchMilestoneResponse
         * @summary Get the stored page content for each page for a given milestone_id
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} milestoneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMilestoneV1: async (workspaceId: string, documentId: string, milestoneId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('fetchMilestoneV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('fetchMilestoneV1', 'documentId', documentId)
            // verify required parameter 'milestoneId' is not null or undefined
            assertParamExists('fetchMilestoneV1', 'milestoneId', milestoneId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/milestone/{milestone_id}/fetch-milestone`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"milestone_id"}}`, encodeURIComponent(String(milestoneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Will return with a number of milestones that a particular doc has  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made  Returns:     FetchNumberOfMilestonesForDocResponse
         * @summary Get number of milestones of a document we currently have
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchNumOfMilestonesForDocV1: async (workspaceId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('fetchNumOfMilestonesForDocV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('fetchNumOfMilestonesForDocV1', 'documentId', documentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/milestones`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Will return with a number of versions that a particular page has  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     page_id: page_id for which all the versions are required  Returns:     FetchNumberOfVersionsForPageResponse: status and number of versions available for a particular page_id
         * @summary Get number of versions of a page we currently have
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchNumOfVersionsOfPageV1: async (workspaceId: string, documentId: string, pageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('fetchNumOfVersionsOfPageV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('fetchNumOfVersionsOfPageV1', 'documentId', documentId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('fetchNumOfVersionsOfPageV1', 'pageId', pageId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/page/{page_id}/available-versions`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch suggestions for a page
         * @param {string} workspaceId 
         * @param {number} pageId 
         * @param {BodyFetchPageSuggestionsFastV1} bodyFetchPageSuggestionsFastV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPageSuggestionsFastV1: async (workspaceId: string, pageId: number, bodyFetchPageSuggestionsFastV1: BodyFetchPageSuggestionsFastV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('fetchPageSuggestionsFastV1', 'workspaceId', workspaceId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('fetchPageSuggestionsFastV1', 'pageId', pageId)
            // verify required parameter 'bodyFetchPageSuggestionsFastV1' is not null or undefined
            assertParamExists('fetchPageSuggestionsFastV1', 'bodyFetchPageSuggestionsFastV1', bodyFetchPageSuggestionsFastV1)
            const localVarPath = `/reno/v3/workspace/{workspace_id}/page/{page_id}/suggestions`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyFetchPageSuggestionsFastV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch suggestions for a page
         * @param {string} workspaceId 
         * @param {number} pageId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPageSuggestionsV1: async (workspaceId: string, pageId: number, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('fetchPageSuggestionsV1', 'workspaceId', workspaceId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('fetchPageSuggestionsV1', 'pageId', pageId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('fetchPageSuggestionsV1', 'documentId', documentId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/page/{page_id}/suggestions`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (documentId !== undefined) {
                localVarQueryParameter['document_id'] = documentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Will fetch the page content for a particular page_id and page_version  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     page_id: milestone_id for which we the document to get restored for     page_version:  Returns:     FetchPageVersionResponse
         * @summary Get page content for a page id for a page version
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {number} pageVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPageVersionContentV1: async (workspaceId: string, documentId: string, pageId: number, pageVersion: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('fetchPageVersionContentV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('fetchPageVersionContentV1', 'documentId', documentId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('fetchPageVersionContentV1', 'pageId', pageId)
            // verify required parameter 'pageVersion' is not null or undefined
            assertParamExists('fetchPageVersionContentV1', 'pageVersion', pageVersion)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/page/{page_id}/version/{page_version}/fetch`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)))
                .replace(`{${"page_version"}}`, encodeURIComponent(String(pageVersion)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all custom style guide rule sets for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     FetchStyleGuideRuleSetsResponse: response of fetching all style guide rule sets
         * @summary Fetch all custom style guide rule sets for a document
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchStyleGuideRuleSetsV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('fetchStyleGuideRuleSetsV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/style-guide/fetch-rulesets`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all custom style guide rules for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     rule_set_id: rule set for which the rules are to be fetched     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     FetchStyleGuideRulesResponse: response of fetching all style guide rules
         * @summary Fetch all custom style guide rules for a document
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchStyleGuideRulesV1: async (workspaceId: string, ruleSetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('fetchStyleGuideRulesV1', 'workspaceId', workspaceId)
            // verify required parameter 'ruleSetId' is not null or undefined
            assertParamExists('fetchStyleGuideRulesV1', 'ruleSetId', ruleSetId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/style-guide/rule_set/{rule_set_id}/fetch-rules`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"rule_set_id"}}`, encodeURIComponent(String(ruleSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch relevant citations from web.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {string} searchKeyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCitationsV1: async (workspaceId: string, documentId: string, searchKeyword: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('findCitationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('findCitationsV1', 'documentId', documentId)
            // verify required parameter 'searchKeyword' is not null or undefined
            assertParamExists('findCitationsV1', 'searchKeyword', searchKeyword)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/find-citations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchKeyword !== undefined) {
                localVarQueryParameter['search_keyword'] = searchKeyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate regex for a rule set Args:     query:     auth: authentication  Returns: GenerateRegexResponse
         * @summary Generate regex for a rule set
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRegexForRuleSetV1: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reno/v1/generate-regex`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches the references for a particular document.  Args:     citation_ids: Optional - Added to fetch response for a set of reference-ids.     workspace_id (str): customer id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetAllCitationsTextResponse: Response for fetching citations for the document.
         * @summary Fetch reference text for a particular document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {CitationPositionType} citationFormat 
         * @param {CitationStyleGuideType} citationStyle 
         * @param {Array<string>} [citationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCitationsTextV1: async (workspaceId: string, documentId: string, citationFormat: CitationPositionType, citationStyle: CitationStyleGuideType, citationIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAllCitationsTextV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getAllCitationsTextV1', 'documentId', documentId)
            // verify required parameter 'citationFormat' is not null or undefined
            assertParamExists('getAllCitationsTextV1', 'citationFormat', citationFormat)
            // verify required parameter 'citationStyle' is not null or undefined
            assertParamExists('getAllCitationsTextV1', 'citationStyle', citationStyle)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/get-all-citations-text`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (citationFormat !== undefined) {
                localVarQueryParameter['citation_format'] = citationFormat;
            }

            if (citationStyle !== undefined) {
                localVarQueryParameter['citation_style'] = citationStyle;
            }

            if (citationIds) {
                localVarQueryParameter['citation_ids'] = citationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches the references for a particular document.  Args:     citation_ids: Optional - Added to fetch response for a set of reference-ids.     workspace_id (str): customer id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetCitationsResponse: Response for fetching citations for the document.
         * @summary Fetch references for a particular document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {Array<string>} [citationIds] 
         * @param {string} [searchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCitationsV1: async (workspaceId: string, documentId: string, citationIds?: Array<string>, searchFilter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAllCitationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getAllCitationsV1', 'documentId', documentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/get-all-citations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (citationIds) {
                localVarQueryParameter['citation_ids'] = citationIds;
            }

            if (searchFilter !== undefined) {
                localVarQueryParameter['search_filter'] = searchFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch list of all custom dictionaries for a workspace  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetCustomDictionaryListResponse: response of fetching all dictionaries
         * @summary Fetch list of all custom dictionaries for a workspace
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDictionaryListV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAllDictionaryListV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/custom-dictionary/list`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch list of all custom dictionaries for a workspace (paginated)  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetCustomDictionaryPaginatedResponse: paginated response of fetching all dictionaries
         * @summary Fetch list of all custom dictionaries for a workspace (paginated)
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDictionaryListV2: async (workspaceId: string, start: number, end: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAllDictionaryListV2', 'workspaceId', workspaceId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getAllDictionaryListV2', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getAllDictionaryListV2', 'end', end)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/custom-dictionary/list`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the analysis status of the page  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page id inside the pdf for which parsed segments are to be fetched  Returns:     AnalysePageStatusResponse: status of the analysis
         * @summary Get the analysis status of the page
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyseStatusV1: async (workspaceId: string, documentId: string, pageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAnalyseStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getAnalyseStatusV1', 'documentId', documentId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('getAnalyseStatusV1', 'pageId', pageId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/page/{page_id}/analyse-status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of documents registered with the app  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     app_id: app id for which documents are to be fetched  Returns:     GetAppDocumentsListResponse: list of documents registered with the app
         * @summary Get list of documents registered with the app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDocumentsListV1: async (workspaceId: string, appId: AppIdType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAppDocumentsListV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppDocumentsListV1', 'appId', appId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/store/app/{app_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of documents registered with the app  Args:     project_id: project id for which documents are to be fetched     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     app_id: app id for which documents are to be fetched  Returns:     GetAppDocumentsListResponse: list of documents registered with the app
         * @summary Get list of documents registered with the app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDocumentsListV2: async (workspaceId: string, appId: AppIdType, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAppDocumentsListV2', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppDocumentsListV2', 'appId', appId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getAppDocumentsListV2', 'projectId', projectId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/store/app/{app_id}/project/{project_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch documents uploaded by user (paginated)
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDocumentsListV3: async (workspaceId: string, appId: AppIdType, projectId: string, start: number, end: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAppDocumentsListV3', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppDocumentsListV3', 'appId', appId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getAppDocumentsListV3', 'projectId', projectId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getAppDocumentsListV3', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getAppDocumentsListV3', 'end', end)
            const localVarPath = `/reno/v3/workspace/{workspace_id}/store/app/{app_id}/project/{project_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list Get project metadata for the associated project_id  Args:     project_id: project id for which documents are to be fetched     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     app_id: app id for which documents are to be fetched  Returns:     GenAIProjectMetadataResponse: Project metadata for the associated project.
         * @summary Get project metadata for the associated project_id
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppProjectMetadataV1: async (workspaceId: string, appId: AppIdType, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAppProjectMetadataV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppProjectMetadataV1', 'appId', appId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getAppProjectMetadataV1', 'projectId', projectId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/store/app/{app_id}/project/{project_id}/metadata`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get citation models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitationModelsV1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reno/v2/fetch-citation-models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the result of Plagiarism check
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentPlagiarismResultV1: async (workspaceId: string, documentId: string, pageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getContentPlagiarismResultV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getContentPlagiarismResultV1', 'documentId', documentId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('getContentPlagiarismResultV1', 'pageId', pageId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/page/{page_id}/plagiarism/result`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all word list for a given dictionary_id  Args:     workspace_id (str): workspace id that uniquely identifies the customer     dictionary_id: dictionary for which the words are to be fetched     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetCustomDictionaryWordsListResponse: response of fetching all words in dictionary
         * @summary Fetch all word list for a given dictionary_id
         * @param {string} workspaceId 
         * @param {string} dictionaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionaryWordsListV1: async (workspaceId: string, dictionaryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDictionaryWordsListV1', 'workspaceId', workspaceId)
            // verify required parameter 'dictionaryId' is not null or undefined
            assertParamExists('getDictionaryWordsListV1', 'dictionaryId', dictionaryId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/custom-dictionary/{dictionary_id}/words-list`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dictionary_id"}}`, encodeURIComponent(String(dictionaryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch document download status, if completed, also return the signed URL, if failed, return an error message.
         * @summary Get document download status response
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} documentId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentDownloadStatusV1: async (workspaceId: string, appId: AppIdType, documentId: string, taskId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDocumentDownloadStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getDocumentDownloadStatusV1', 'appId', appId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentDownloadStatusV1', 'documentId', documentId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getDocumentDownloadStatusV1', 'taskId', taskId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/document/{document_id}/download/{task_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get document download response
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} documentId 
         * @param {DocumentDownloadFormats} downloadFormat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentDownloadV1: async (workspaceId: string, appId: AppIdType, documentId: string, downloadFormat: DocumentDownloadFormats, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDocumentDownloadV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getDocumentDownloadV1', 'appId', appId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentDownloadV1', 'documentId', documentId)
            // verify required parameter 'downloadFormat' is not null or undefined
            assertParamExists('getDocumentDownloadV1', 'downloadFormat', downloadFormat)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/document/{document_id}/download`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (downloadFormat !== undefined) {
                localVarQueryParameter['download_format'] = downloadFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get document metadata which contains s3 presigned url  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
         * @summary Get document metadata response
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentMetadataV1: async (workspaceId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDocumentMetadataV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentMetadataV1', 'documentId', documentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/metadata`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the parsing status of the whole document uploaded  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     DocumentParsingStatusResponse: status of the parsing
         * @summary Get the parsing status of the whole document uploaded
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentParsingStatusV1: async (workspaceId: string, documentId: string, appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDocumentParsingStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentParsingStatusV1', 'documentId', documentId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getDocumentParsingStatusV1', 'appId', appId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/store/app/{app_id}/document/{document_id}/status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch document stats like lexical diversity, word count, sentence count, etc. for the given input
         * @summary Get document stats response for the given input text
         * @param {AppIdType} appId 
         * @param {string} workspaceId 
         * @param {GetDocumentStatsForInputTextRequest} getDocumentStatsForInputTextRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentStatsForTextSelectionV1: async (appId: AppIdType, workspaceId: string, getDocumentStatsForInputTextRequest: GetDocumentStatsForInputTextRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getDocumentStatsForTextSelectionV1', 'appId', appId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDocumentStatsForTextSelectionV1', 'workspaceId', workspaceId)
            // verify required parameter 'getDocumentStatsForInputTextRequest' is not null or undefined
            assertParamExists('getDocumentStatsForTextSelectionV1', 'getDocumentStatsForInputTextRequest', getDocumentStatsForInputTextRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/stats`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getDocumentStatsForInputTextRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch document stats like lexical diversity, word count, sentence count, etc.
         * @summary Get document stats response
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentStatsV1: async (workspaceId: string, appId: AppIdType, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDocumentStatsV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getDocumentStatsV1', 'appId', appId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentStatsV1', 'documentId', documentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/document/{document_id}/stats`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the summary of the document  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
         * @summary Get the summary of the document
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentSummaryV1: async (workspaceId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDocumentSummaryV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentSummaryV1', 'documentId', documentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/summary`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch the signed S3 URL to start download (for the latest available version) message.
         * @summary Fetch the signed URL of the latest version
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestDocumentDownloadV1: async (workspaceId: string, appId: AppIdType, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getLatestDocumentDownloadV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getLatestDocumentDownloadV1', 'appId', appId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getLatestDocumentDownloadV1', 'documentId', documentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/document/{document_id}/download-latest`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get analysis status for a page
         * @param {string} workspaceId 
         * @param {number} pageId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageAnalysisStatusV1: async (workspaceId: string, pageId: number, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getPageAnalysisStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('getPageAnalysisStatusV1', 'pageId', pageId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getPageAnalysisStatusV1', 'documentId', documentId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/page/{page_id}/analysis-status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (documentId !== undefined) {
                localVarQueryParameter['document_id'] = documentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the summary of the document  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page id for which summary needs to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
         * @summary Get page summary of the document
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageSummaryV1: async (workspaceId: string, documentId: string, pageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getPageSummaryV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getPageSummaryV1', 'documentId', documentId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('getPageSummaryV1', 'pageId', pageId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/page/{page_id}/summary`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch paginated style guide configs for a workspace  Args:     workspace_id (str): workspace id that uniquely identifies the customer     start (int): pagination start index     end (int): pagination end index     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (str, optional): timestamp for pagination, defaults to \"\"     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetStyleGuideConfigPaginatedResponse: paginated response of style guide configs
         * @summary Fetch paginated style guide configs for a workspace
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedStyleGuideConfigV1: async (workspaceId: string, start: number, end: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getPaginatedStyleGuideConfigV1', 'workspaceId', workspaceId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getPaginatedStyleGuideConfigV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getPaginatedStyleGuideConfigV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/style-guide-config/list`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get parsed page in pdf  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed page is to be fetched     page_id: page id inside the pdf     background_tasks:  Returns:     GetParsedSegmentsResponse: list of parsed segments
         * @summary Get parsed page response
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParsedSegmentsV1: async (workspaceId: string, documentId: string, pageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getParsedSegmentsV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getParsedSegmentsV1', 'documentId', documentId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('getParsedSegmentsV1', 'pageId', pageId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/page/{page_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * V2 version of get parsed page response api which is used for RTC in copy edit app  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed page is to be fetched     page_id: page id inside the pdf  Returns:     GetParsedSegmentsResponse: list of parsed segments
         * @summary Get parsed page response
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParsedSegmentsV2: async (workspaceId: string, documentId: string, pageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getParsedSegmentsV2', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getParsedSegmentsV2', 'documentId', documentId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('getParsedSegmentsV2', 'pageId', pageId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/document/{document_id}/page/{page_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project_id for the associated document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectFromDocumentV1: async (workspaceId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProjectFromDocumentV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getProjectFromDocumentV1', 'documentId', documentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get rule set file upload status
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRuleSetFileUploadStatusV1: async (workspaceId: string, ruleSetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getRuleSetFileUploadStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'ruleSetId' is not null or undefined
            assertParamExists('getRuleSetFileUploadStatusV1', 'ruleSetId', ruleSetId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/style-guide/rule_set/{rule_set_id}/status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"rule_set_id"}}`, encodeURIComponent(String(ruleSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ``` Invalidate the cache for copy edit analysis Args:     request (Request): FastAPI Request object. This is required by the decorator to extract header information     response (Response): FastAPI Response object. This is required by the decorator to set status     auth: authentication  Returns: ```
         * @summary Invalidate Copy Edit Analysis Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateCopyEditAnalysisCache: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reno/invalidate_copy_edit_analysis_cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invalidate suggestions across documents
         * @param {string} workspaceId 
         * @param {string} suggestionId ID of the suggestion to invalidate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateSuggestionsV1: async (workspaceId: string, suggestionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('invalidateSuggestionsV1', 'workspaceId', workspaceId)
            // verify required parameter 'suggestionId' is not null or undefined
            assertParamExists('invalidateSuggestionsV1', 'suggestionId', suggestionId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/suggestions/invalidate`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (suggestionId !== undefined) {
                localVarQueryParameter['suggestion_id'] = suggestionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invite users to a project Args:     workspace_id: workspace id     app_id: app id     project_id: project id     request: request body     user: user object     background_tasks: to push analytics to bangalore service  Returns: InviteProjectResponse
         * @summary Invite users to a project
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {InviteGenAIProjectRequest} inviteGenAIProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteGenAIProjectV1: async (workspaceId: string, appId: AppIdType, projectId: string, inviteGenAIProjectRequest: InviteGenAIProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('inviteGenAIProjectV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('inviteGenAIProjectV1', 'appId', appId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('inviteGenAIProjectV1', 'projectId', projectId)
            // verify required parameter 'inviteGenAIProjectRequest' is not null or undefined
            assertParamExists('inviteGenAIProjectV1', 'inviteGenAIProjectRequest', inviteGenAIProjectRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/project/{project_id}/invite`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteGenAIProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Migrate suggestions rules from one workspace to another Args:     workspace_id: workspace id     query: query to filter documents in the pagesuggestions     user: user object  Returns: None
         * @summary Migrate suggestions rules from one workspace to another
         * @param {string} workspaceId 
         * @param {BodyMigrateSuggestionsRulesV1} bodyMigrateSuggestionsRulesV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateSuggestionsRulesV1: async (workspaceId: string, bodyMigrateSuggestionsRulesV1: BodyMigrateSuggestionsRulesV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('migrateSuggestionsRulesV1', 'workspaceId', workspaceId)
            // verify required parameter 'bodyMigrateSuggestionsRulesV1' is not null or undefined
            assertParamExists('migrateSuggestionsRulesV1', 'bodyMigrateSuggestionsRulesV1', bodyMigrateSuggestionsRulesV1)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/migrate/suggestions-rules`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyMigrateSuggestionsRulesV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Parse the input citation text and add the entities present in the citation text into the DB
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {BodyParseAndAddBulkCitationTextV1} bodyParseAndAddBulkCitationTextV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseAndAddBulkCitationTextV1: async (workspaceId: string, documentId: string, bodyParseAndAddBulkCitationTextV1: BodyParseAndAddBulkCitationTextV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('parseAndAddBulkCitationTextV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('parseAndAddBulkCitationTextV1', 'documentId', documentId)
            // verify required parameter 'bodyParseAndAddBulkCitationTextV1' is not null or undefined
            assertParamExists('parseAndAddBulkCitationTextV1', 'bodyParseAndAddBulkCitationTextV1', bodyParseAndAddBulkCitationTextV1)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/add-bulk-references`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyParseAndAddBulkCitationTextV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Parse the input citation text and return the entities present in the citation text
         * @param {string} workspaceId 
         * @param {BodyParseCitationTextV1} bodyParseCitationTextV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseCitationTextV1: async (workspaceId: string, bodyParseCitationTextV1: BodyParseCitationTextV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('parseCitationTextV1', 'workspaceId', workspaceId)
            // verify required parameter 'bodyParseCitationTextV1' is not null or undefined
            assertParamExists('parseCitationTextV1', 'bodyParseCitationTextV1', bodyParseCitationTextV1)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/reference/entities`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyParseCitationTextV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Webhook to receive the result of the Plagiarism check  Args:     request (Request): FastAPI Request object     response (Response): FastAPI Response object  Returns:     None
         * @summary Webhook to receive the result of the Plagiarism check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receivePlagiarismCheckResult: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reno/copyleaks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register the document with the app  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     app_id: app id for which document is to be registered     background_tasks (BackgroundTasks): FastAPI BackgroundTasks object  Returns:     RegisterAppDocumentResponse: response of the registration
         * @summary Register the document with the app
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {AppIdType} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAppDocumentV1: async (workspaceId: string, documentId: string, appId: AppIdType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('registerAppDocumentV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('registerAppDocumentV1', 'documentId', documentId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('registerAppDocumentV1', 'appId', appId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/store/app/{app_id}/document/{document_id}/register`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register the document with the app v2  Args:     style_guides: ADA or CMOS     custom_style_rules: Custom style guide     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     app_id: app id for which document is to be registered     background_tasks (BackgroundTasks): FastAPI BackgroundTasks object  Returns:     RegisterAppDocumentResponse: response of the registration
         * @summary Register the document with the app
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {AppIdType} appId 
         * @param {BodyRegisterAppDocumentV2} [bodyRegisterAppDocumentV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAppDocumentV2: async (workspaceId: string, documentId: string, appId: AppIdType, bodyRegisterAppDocumentV2?: BodyRegisterAppDocumentV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('registerAppDocumentV2', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('registerAppDocumentV2', 'documentId', documentId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('registerAppDocumentV2', 'appId', appId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/store/app/{app_id}/document/{document_id}/register`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyRegisterAppDocumentV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register the document with the app v2  Args:     project_id: project where the document is associated with     style_guides: ADA or CMOS     custom_style_rules: Custom style guide     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     app_id: app id for which document is to be registered     custom_dictionary_ids (List[str]): The list of ids of dictionaries to be applied on this document     background_tasks (BackgroundTasks): FastAPI BackgroundTasks object  Returns:     RegisterAppDocumentResponse: response of the registration
         * @summary Register the document with the app
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {string} projectId 
         * @param {AppIdType} appId 
         * @param {BodyRegisterAppDocumentV3} [bodyRegisterAppDocumentV3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAppDocumentV3: async (workspaceId: string, documentId: string, projectId: string, appId: AppIdType, bodyRegisterAppDocumentV3?: BodyRegisterAppDocumentV3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('registerAppDocumentV3', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('registerAppDocumentV3', 'documentId', documentId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('registerAppDocumentV3', 'projectId', projectId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('registerAppDocumentV3', 'appId', appId)
            const localVarPath = `/reno/v3/workspace/{workspace_id}/store/project/{project_id}/app/{app_id}/document/{document_id}/register`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyRegisterAppDocumentV3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove user from a project Args:     workspace_id: workspace id     app_id: app id     project_id: project id     service_request: request body     user: user object  Returns: RemoveInviteProjectResponse
         * @summary Remove user from a project
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {RemoveInviteGenAIProjectRequest} removeInviteGenAIProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromGenAIProjectV1: async (workspaceId: string, appId: AppIdType, projectId: string, removeInviteGenAIProjectRequest: RemoveInviteGenAIProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('removeUserFromGenAIProjectV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('removeUserFromGenAIProjectV1', 'appId', appId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('removeUserFromGenAIProjectV1', 'projectId', projectId)
            // verify required parameter 'removeInviteGenAIProjectRequest' is not null or undefined
            assertParamExists('removeUserFromGenAIProjectV1', 'removeInviteGenAIProjectRequest', removeInviteGenAIProjectRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/project/{project_id}/remove-invite`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeInviteGenAIProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove workspace sharing for a resource
         * @param {string} workspaceId 
         * @param {BodyRemoveWorkspaceShareResourceV1} bodyRemoveWorkspaceShareResourceV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWorkspaceShareResourceV1: async (workspaceId: string, bodyRemoveWorkspaceShareResourceV1: BodyRemoveWorkspaceShareResourceV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('removeWorkspaceShareResourceV1', 'workspaceId', workspaceId)
            // verify required parameter 'bodyRemoveWorkspaceShareResourceV1' is not null or undefined
            assertParamExists('removeWorkspaceShareResourceV1', 'bodyRemoveWorkspaceShareResourceV1', bodyRemoveWorkspaceShareResourceV1)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/remove-workspace-share`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyRemoveWorkspaceShareResourceV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Will set the pointers for all the pages in a doc to their last milestone saved page_versions  Args:     workspace_id (str): workspace id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     milestone_id: milestone_id for which we the document to get restored for  Returns:     RestoreMilestoneResponse
         * @summary Get number of milestones of a document we currently have
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} milestoneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreMilestoneForDocV1: async (workspaceId: string, documentId: string, milestoneId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('restoreMilestoneForDocV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('restoreMilestoneForDocV1', 'documentId', documentId)
            // verify required parameter 'milestoneId' is not null or undefined
            assertParamExists('restoreMilestoneForDocV1', 'milestoneId', milestoneId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/milestone/{milestone_id}/restore-milestone`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"milestone_id"}}`, encodeURIComponent(String(milestoneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restores the current state of page to a page version provided, creating a new version maintaining linear history  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     page_id: concerned page     page_version: page version we want the current page to move to     background_tasks: to run push analytics call Returns:     RestorePageResponse
         * @summary Restores the current state of page to a page version provided
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {number} pageVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreVersionForDocV1: async (workspaceId: string, documentId: string, pageId: number, pageVersion: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('restoreVersionForDocV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('restoreVersionForDocV1', 'documentId', documentId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('restoreVersionForDocV1', 'pageId', pageId)
            // verify required parameter 'pageVersion' is not null or undefined
            assertParamExists('restoreVersionForDocV1', 'pageVersion', pageVersion)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/page/{page_id}/version/{page_version}/restore-page`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)))
                .replace(`{${"page_version"}}`, encodeURIComponent(String(pageVersion)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Will save the content for the current version and create a new version based on a set timelimit  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page_id for which the content has to be saved.     service_request: Request body for creating a version     background_tasks:  Returns:     SaveContentResponse: success status for saving content for a version
         * @summary Saving the actual changed content, creating and updating pointers for a session
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {SaveContentRequestBody} saveContentRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveContentForSessionV1: async (workspaceId: string, documentId: string, pageId: number, saveContentRequestBody: SaveContentRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('saveContentForSessionV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('saveContentForSessionV1', 'documentId', documentId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('saveContentForSessionV1', 'pageId', pageId)
            // verify required parameter 'saveContentRequestBody' is not null or undefined
            assertParamExists('saveContentForSessionV1', 'saveContentRequestBody', saveContentRequestBody)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/page/{page_id}/save-content`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveContentRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the summary of the document  Args:     binary_formatted_document_summary: save snippet summary content in binary format for RTC support     formatted_document_summary: this will save the summary content as snippet formatted content     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
         * @summary Save formatted snippet content which contains the summary of the document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {BodySaveDocumentSummaryV1} [bodySaveDocumentSummaryV1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDocumentSummaryV1: async (workspaceId: string, documentId: string, bodySaveDocumentSummaryV1?: BodySaveDocumentSummaryV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('saveDocumentSummaryV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('saveDocumentSummaryV1', 'documentId', documentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/summary`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodySaveDocumentSummaryV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Share a resource with the entire workspace
         * @param {string} workspaceId 
         * @param {BodyShareResourceWithWorkspaceV1} bodyShareResourceWithWorkspaceV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareResourceWithWorkspaceV1: async (workspaceId: string, bodyShareResourceWithWorkspaceV1: BodyShareResourceWithWorkspaceV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('shareResourceWithWorkspaceV1', 'workspaceId', workspaceId)
            // verify required parameter 'bodyShareResourceWithWorkspaceV1' is not null or undefined
            assertParamExists('shareResourceWithWorkspaceV1', 'bodyShareResourceWithWorkspaceV1', bodyShareResourceWithWorkspaceV1)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/share-workspace`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyShareResourceWithWorkspaceV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stream LLM response with optimized performance
         * @summary Stream LLM response
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamLLMResponse: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('streamLLMResponse', 'query', query)
            const localVarPath = `/reno/v1/llm/stream-response`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger asynchronous document download
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} documentId 
         * @param {DocumentDownloadFormats} downloadFormat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerAsyncDocumentDownloadV1: async (workspaceId: string, appId: AppIdType, documentId: string, downloadFormat: DocumentDownloadFormats, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('triggerAsyncDocumentDownloadV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('triggerAsyncDocumentDownloadV1', 'appId', appId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('triggerAsyncDocumentDownloadV1', 'documentId', documentId)
            // verify required parameter 'downloadFormat' is not null or undefined
            assertParamExists('triggerAsyncDocumentDownloadV1', 'downloadFormat', downloadFormat)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/document/{document_id}/trigger-async-download`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (downloadFormat !== undefined) {
                localVarQueryParameter['download_format'] = downloadFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger re-analyze on document metadata change.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerReAnalyzeTextsV1: async (workspaceId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('triggerReAnalyzeTextsV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('triggerReAnalyzeTextsV1', 'documentId', documentId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/document/{document_id}/trigger-re-analyse`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the analysis status of the page  Args:     status_change_request: update the current state of analyse page to this state     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page id inside the pdf for which parsed segments are to be fetched  Returns:     AnalysePageStatusResponse: status of the analysis
         * @summary Update the analysis status of the page
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {AnalysePageStatusResponse} analysePageStatusResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnalyseStatusV1: async (workspaceId: string, documentId: string, pageId: number, analysePageStatusResponse: AnalysePageStatusResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateAnalyseStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateAnalyseStatusV1', 'documentId', documentId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('updateAnalyseStatusV1', 'pageId', pageId)
            // verify required parameter 'analysePageStatusResponse' is not null or undefined
            assertParamExists('updateAnalyseStatusV1', 'analysePageStatusResponse', analysePageStatusResponse)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/page/{page_id}/analyse/update-status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysePageStatusResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update and analyse text resources for a text in add-in
         * @param {string} workspaceId 
         * @param {UpdateAnalyseAddInRequest} updateAnalyseAddInRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAndAnalyseV1: async (workspaceId: string, updateAnalyseAddInRequest: UpdateAnalyseAddInRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateAndAnalyseV1', 'workspaceId', workspaceId)
            // verify required parameter 'updateAnalyseAddInRequest' is not null or undefined
            assertParamExists('updateAndAnalyseV1', 'updateAnalyseAddInRequest', updateAnalyseAddInRequest)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/update-and-analyse`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnalyseAddInRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the document metadata for copy edit  Args:     workspace_id (str): workspace id that uniquely identifies the customer     document_id: document id for which parsed segments are to be fetched     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     UpdateAppDocumentResponse: response of the registration
         * @summary Update the document stylesheet metadata for copy edit
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppDocumentStylesheetMetadataV1: async (workspaceId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateAppDocumentStylesheetMetadataV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateAppDocumentStylesheetMetadataV1', 'documentId', documentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/store/document/{document_id}/update-stylesheet-metadata`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the citation style in document metadata
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {UpdateCitationStyleRequest} updateCitationStyleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCitationStyleRequestV1: async (workspaceId: string, documentId: string, updateCitationStyleRequest: UpdateCitationStyleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateCitationStyleRequestV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateCitationStyleRequestV1', 'documentId', documentId)
            // verify required parameter 'updateCitationStyleRequest' is not null or undefined
            assertParamExists('updateCitationStyleRequestV1', 'updateCitationStyleRequest', updateCitationStyleRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/update-citation-style`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCitationStyleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the references for a particular page.  Args:     page_id: page_id to update references.     update_request: references which need to be updated for a particular document.     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     UpdateCitationsResponse: response for updating references for the document.
         * @summary Update citations for a particular document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {UpdateCitationsRequest} updateCitationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCitationsV1: async (workspaceId: string, documentId: string, pageId: number, updateCitationsRequest: UpdateCitationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateCitationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateCitationsV1', 'documentId', documentId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('updateCitationsV1', 'pageId', pageId)
            // verify required parameter 'updateCitationsRequest' is not null or undefined
            assertParamExists('updateCitationsV1', 'updateCitationsRequest', updateCitationsRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/page/{page_id}/update-citations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCitationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the document metadata for copy edit documents
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {UpdateDocumentMetadataRequest} updateDocumentMetadataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentMetadataV1: async (workspaceId: string, documentId: string, updateDocumentMetadataRequest: UpdateDocumentMetadataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateDocumentMetadataV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateDocumentMetadataV1', 'documentId', documentId)
            // verify required parameter 'updateDocumentMetadataRequest' is not null or undefined
            assertParamExists('updateDocumentMetadataV1', 'updateDocumentMetadataRequest', updateDocumentMetadataRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document/{document_id}/update-metadata`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentMetadataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a project for the Gen AI app Args:     project_id:     workspace_id: workspace id     app_id: app id     request: request body     user: user object     background_tasks:  Returns: GenAIProjectMetadataResponse
         * @summary Create or update a project for the Gen AI app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {UpdateGenAIProjectRequest} updateGenAIProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGenAIProjectV1: async (workspaceId: string, appId: AppIdType, projectId: string, updateGenAIProjectRequest: UpdateGenAIProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateGenAIProjectV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('updateGenAIProjectV1', 'appId', appId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateGenAIProjectV1', 'projectId', projectId)
            // verify required parameter 'updateGenAIProjectRequest' is not null or undefined
            assertParamExists('updateGenAIProjectV1', 'updateGenAIProjectRequest', updateGenAIProjectRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/app/{app_id}/project/{project_id}/update`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGenAIProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the segments with the suggested changes Args:     user: auth check     workspace_id:     document_id:     page_id:     update_request:     request:     response:  Returns:
         * @summary Update page content with the latest version of formatted page content
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {UpdateSegmentRequest} updateSegmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePageContentV1: async (workspaceId: string, documentId: string, pageId: number, updateSegmentRequest: UpdateSegmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updatePageContentV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updatePageContentV1', 'documentId', documentId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('updatePageContentV1', 'pageId', pageId)
            // verify required parameter 'updateSegmentRequest' is not null or undefined
            assertParamExists('updatePageContentV1', 'updateSegmentRequest', updateSegmentRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document_id/{document_id}/page/{page_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSegmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * V2 Update the segments with the suggested changes for supporting RTC changes on copy edit app Args:     workspace_id:     document_id:     page_id:     update_request:     background_tasks:     request:     response:     user:  Returns:
         * @summary Update page content with the latest version of formatted page content
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {UpdateSegmentRequestV2} updateSegmentRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePageContentV2: async (workspaceId: string, documentId: string, pageId: number, updateSegmentRequestV2: UpdateSegmentRequestV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updatePageContentV2', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updatePageContentV2', 'documentId', documentId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('updatePageContentV2', 'pageId', pageId)
            // verify required parameter 'updateSegmentRequestV2' is not null or undefined
            assertParamExists('updatePageContentV2', 'updateSegmentRequestV2', updateSegmentRequestV2)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/document_id/{document_id}/page/{page_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSegmentRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This will be called when we accept suggestions in our copy edit app. These suggestions are from open ai model. After accepting the changes we will create a new entry in the Segments Table for the pdf
         * @summary Create a new segment with the latest version id with the suggested changes by openai.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {string} pageId 
         * @param {string} chunkId 
         * @param {UpdateSegmentRequest} updateSegmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSegmentV1: async (workspaceId: string, documentId: string, pageId: string, chunkId: string, updateSegmentRequest: UpdateSegmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateSegmentV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateSegmentV1', 'documentId', documentId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('updateSegmentV1', 'pageId', pageId)
            // verify required parameter 'chunkId' is not null or undefined
            assertParamExists('updateSegmentV1', 'chunkId', chunkId)
            // verify required parameter 'updateSegmentRequest' is not null or undefined
            assertParamExists('updateSegmentV1', 'updateSegmentRequest', updateSegmentRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/document_id/{document_id}/page/{page_id}/chunk/{chunk_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"page_id"}}`, encodeURIComponent(String(pageId)))
                .replace(`{${"chunk_id"}}`, encodeURIComponent(String(chunkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSegmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a specific style guide configuration record for a workspace.  Args:     workspace_id (str): The ID of the workspace where the configuration exists.     record_id (str): The specific record to update.     config_updates (Dict[str, Any]): Fields to update in the configuration.     request (Request): FastAPI Request object.     response (Response): FastAPI Response object.     user (WorkspaceUser, optional): Authenticated user performing the update.  Returns:     Dict[str, Any]: Response from Montreal service after updating the record.
         * @summary Update style guide config for a workspace
         * @param {string} workspaceId 
         * @param {string} recordId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStyleGuideConfigV1: async (workspaceId: string, recordId: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateStyleGuideConfigV1', 'workspaceId', workspaceId)
            // verify required parameter 'recordId' is not null or undefined
            assertParamExists('updateStyleGuideConfigV1', 'recordId', recordId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateStyleGuideConfigV1', 'body', body)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/style-guide-config/{record_id}/update`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"record_id"}}`, encodeURIComponent(String(recordId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update suggestion status for a document
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {UpdatePageSuggestions} updatePageSuggestions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSuggestionStatusV1: async (workspaceId: string, documentId: string, updatePageSuggestions: UpdatePageSuggestions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateSuggestionStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateSuggestionStatusV1', 'documentId', documentId)
            // verify required parameter 'updatePageSuggestions' is not null or undefined
            assertParamExists('updateSuggestionStatusV1', 'updatePageSuggestions', updatePageSuggestions)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/document/{document_id}/update-suggestion-status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePageSuggestions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload manual text file for copy edit / summarization analysis  Args:     project_id: project id for which document is to be registered     background_tasks: to trigger the summarization/copy edit analysis flow in the background process     upload_request: upload text file to be analysed     workspace_id (str): customer id that uniquely identifies the customer     app_id: app id for which document is to be registered     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     UploadManualTextFileResponse: response of the text file registration
         * @summary Upload manual text file for copy-edit or summarization analysis
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {AppIdType} appId 
         * @param {UploadManualTextFileRequest} uploadManualTextFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadManualTextFileRequestV1: async (workspaceId: string, projectId: string, appId: AppIdType, uploadManualTextFileRequest: UploadManualTextFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('uploadManualTextFileRequestV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('uploadManualTextFileRequestV1', 'projectId', projectId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('uploadManualTextFileRequestV1', 'appId', appId)
            // verify required parameter 'uploadManualTextFileRequest' is not null or undefined
            assertParamExists('uploadManualTextFileRequestV1', 'uploadManualTextFileRequest', uploadManualTextFileRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/store/app/{app_id}/project/{project_id}/analyze/manual-text`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadManualTextFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload rule set
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {UploadRuleSetRequest} uploadRuleSetRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        uploadRuleSetV1: async (workspaceId: string, ruleSetId: string, uploadRuleSetRequest: UploadRuleSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('uploadRuleSetV1', 'workspaceId', workspaceId)
            // verify required parameter 'ruleSetId' is not null or undefined
            assertParamExists('uploadRuleSetV1', 'ruleSetId', ruleSetId)
            // verify required parameter 'uploadRuleSetRequest' is not null or undefined
            assertParamExists('uploadRuleSetV1', 'uploadRuleSetRequest', uploadRuleSetRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/style-guide/rule_set/{rule_set_id}/start-upload`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"rule_set_id"}}`, encodeURIComponent(String(ruleSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadRuleSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppStoreApi - functional programming interface
 * @export
 */
export const AppStoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppStoreApiAxiosParamCreator(configuration)
    return {
        /**
         * Add or update a custom style guide rule set for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     service_request: Request body for adding a style guide rule set     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     AddUpdateStyleGuideRuleSetResponse: response of adding a style guide rule set
         * @summary Add or update a custom style guide rule set for a document
         * @param {string} workspaceId 
         * @param {AddUpdateStyleGuideRuleSetRequest} addUpdateStyleGuideRuleSetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUpdateStyleGuideRuleSetV1(workspaceId: string, addUpdateStyleGuideRuleSetRequest: AddUpdateStyleGuideRuleSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddStyleGuideRuleSetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUpdateStyleGuideRuleSetV1(workspaceId, addUpdateStyleGuideRuleSetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a custom style guide rule for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     rule_set_id: rule set to which the rule is to be added     service_request: Request body for adding a style guide rule     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     AddUpdateStyleGuideRuleResponse: response of adding a style guide rule
         * @summary Add a custom style guide rule for a document
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {AddStyleGuideRuleRequest} addStyleGuideRuleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUpdateStyleGuideRuleV1(workspaceId: string, ruleSetId: string, addStyleGuideRuleRequest: AddStyleGuideRuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddUpdateStyleGuideRuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUpdateStyleGuideRuleV1(workspaceId, ruleSetId, addStyleGuideRuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add or update a custom style guide rule set for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     service_request: Request body for adding words to custom dictionary     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     AddWordsToCustomDictionaryResponse: response of add words to the custom dictionary
         * @summary Add words to the given custom dictionary
         * @param {string} workspaceId 
         * @param {AddWordsToCustomDictionaryRequest} addWordsToCustomDictionaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWordsToCustomDictionaryV1(workspaceId: string, addWordsToCustomDictionaryRequest: AddWordsToCustomDictionaryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddWordsToCustomDictionaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addWordsToCustomDictionaryV1(workspaceId, addWordsToCustomDictionaryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * each page in the pdf is parsed into segments and stored in the order in which they occur in the pdf
         * @summary start file analysis and parse all the segments in the page to be used for copy edit, this analysis is requested for one or more pages
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {AnalyseFileRequest} analyseFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyseFileV1(workspaceId: string, documentId: string, analyseFileRequest: AnalyseFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysePageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analyseFileV1(workspaceId, documentId, analyseFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches the suitable analytics
         * @summary Fetches analytics of the copy edit application based on the filters
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {number} [days] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [projectIds] 
         * @param {Array<string>} [documentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsCopyEditFiltersV1(workspaceId: string, appId: string, days?: number, userIds?: Array<string>, projectIds?: Array<string>, documentIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsCopyEditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsCopyEditFiltersV1(workspaceId, appId, days, userIds, projectIds, documentIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches user count, unique daily active users, documents improved, writing sessions,    suggestions generated
         * @summary Fetches analytics of the copy edit application
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {AppAnalyticsRequest} appAnalyticsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsCopyEditV1(workspaceId: string, appId: string, appAnalyticsRequest: AppAnalyticsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsCopyEditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsCopyEditV1(workspaceId, appId, appAnalyticsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analyze text resources for a page
         * @param {string} workspaceId 
         * @param {number} pageId 
         * @param {string} documentId 
         * @param {PageTextResources} pageTextResources 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyzeTextsV1(workspaceId: string, pageId: number, documentId: string, pageTextResources: PageTextResources, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysePageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analyzeTextsV1(workspaceId, pageId, documentId, pageTextResources, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Attach dictionaries to an existing. These will be used when document is re-analyzed
         * @summary Attach dictionaries to an existing document
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {AttachCustomDictionariesToDocumentRequest} attachCustomDictionariesToDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachDictionariesToDocumentV1(workspaceId: string, documentId: string, attachCustomDictionariesToDocumentRequest: AttachCustomDictionariesToDocumentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachCustomDictionariesToDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachDictionariesToDocumentV1(workspaceId, documentId, attachCustomDictionariesToDocumentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Bulk upload rules for a rule set
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkUploadRulesV1(workspaceId: string, ruleSetId: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkUploadRulesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUploadRulesV1(workspaceId, ruleSetId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Async call to check Plagiarism of the content provided
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {CheckContentPlagiarismRequestModel} checkContentPlagiarismRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkContentPlagiarismV1(workspaceId: string, documentId: string, pageId: number, checkContentPlagiarismRequestModel: CheckContentPlagiarismRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlagiarismCheckSubmitAckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkContentPlagiarismV1(workspaceId, documentId, pageId, checkContentPlagiarismRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a pre-signed s3 url for uploading document assets for copy edit app.
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {DocumentUploadRequest} documentUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocumentAssetsUploadUrlV1(workspaceId: string, appId: AppIdType, documentUploadRequest: DocumentUploadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentUploadUriResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocumentAssetsUploadUrlV1(workspaceId, appId, documentUploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a citation for a page
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {CopyEditCitation} copyEditCitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocumentCitationV1(workspaceId: string, documentId: string, copyEditCitation: CopyEditCitation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrUpdateCitationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocumentCitationV1(workspaceId, documentId, copyEditCitation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a project for the gen ai app Args:     workspace_id: workspace id     app_id: app id     request: request body     user: user object     background_tasks: to push analytics to bangalore service  Returns: CreateProjectResponse
         * @summary Create a project for the gen ai app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {CreateGenAIProjectRequest} createGenAIProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGenAIProjectV1(workspaceId: string, appId: AppIdType, createGenAIProjectRequest: CreateGenAIProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenAIProjectMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGenAIProjectV1(workspaceId, appId, createGenAIProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Will set page_ids with their latest page_versions against a particular milestone_id  Args:     workspace_id (str): workspace id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     CreateMilestoneResponse
         * @summary Save the latest page_versions of all the pages against the latest generated milestone
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMilestoneV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMilestoneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMilestoneV1(workspaceId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a custom dictionary for a workspace that can be applied to a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     service_request: Request body for creating a custom dictionary     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     CreateCustomDictionaryResponse: response of creating a custom dictionary
         * @summary Create a custom dictionary for a document
         * @param {string} workspaceId 
         * @param {CreateOrUpdateCustomDictionaryRequest} createOrUpdateCustomDictionaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateCustomDictionaryV1(workspaceId: string, createOrUpdateCustomDictionaryRequest: CreateOrUpdateCustomDictionaryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCustomDictionaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateCustomDictionaryV1(workspaceId, createOrUpdateCustomDictionaryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create upload url for rule set
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createUploadURLForRuleSetV1(workspaceId: string, ruleSetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRuleSetUploadURLResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUploadURLForRuleSetV1(workspaceId, ruleSetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete citations for a particular document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCitationsV1(workspaceId: string, documentId: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteCitationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCitationsV1(workspaceId, documentId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a custom dictionary from a workspace  Args:    workspace_id (str): workspace id that uniquely identifies the customer    dictionary_id (str): dictionary id that uniquely identifies the dictionary    request (Request): FastAPI Request object    response (Response): FastAPI Response object    user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:    CreateCustomDictionaryResponse: response of creating a custom dictionary
         * @summary Delete a custom dictionary from the document
         * @param {string} workspaceId 
         * @param {string} dictionaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomDictionaryV1(workspaceId: string, dictionaryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteCustomDictionaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomDictionaryV1(workspaceId, dictionaryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a custom dictionary from a workspace  Args:    workspace_id (str): workspace id that uniquely identifies the customer    dictionary_id (str): dictionary id that uniquely identifies the dictionary    dictionary_word_list: List of words that need to be deleted    request (Request): FastAPI Request object    response (Response): FastAPI Response object    user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:    CreateCustomDictionaryResponse: response of creating a custom dictionary
         * @summary Delete a custom dictionary word from the dictionary
         * @param {string} workspaceId 
         * @param {string} dictionaryId 
         * @param {DeleteWordsFromCustomDictionaryRequest} deleteWordsFromCustomDictionaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomDictionaryWordV1(workspaceId: string, dictionaryId: string, deleteWordsFromCustomDictionaryRequest: DeleteWordsFromCustomDictionaryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteWordsFromCustomDictionaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomDictionaryWordV1(workspaceId, dictionaryId, deleteWordsFromCustomDictionaryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the document and all its related data
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumentV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumentV1(workspaceId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a project for the Gen AI app Args:     project_id: project id for the project     workspace_id: workspace id     app_id: app id     user: user object     background_tasks: to push analytics to bangalore service  Returns: CreateProjectResponse
         * @summary Delete a project for the Gen AI app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGenAIProjectV1(workspaceId: string, appId: AppIdType, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteGenAIProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGenAIProjectV1(workspaceId, appId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete suggestions for a page
         * @param {string} workspaceId 
         * @param {number} pageId 
         * @param {string} documentId 
         * @param {Array<string>} [resourceIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePageSuggestionsV1(workspaceId: string, pageId: number, documentId: string, resourceIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeletePageSuggestionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePageSuggestionsV1(workspaceId, pageId, documentId, resourceIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a custom style guide rule set for a document  Args:     rule_set_id: Rule set to be deleted     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     DeleteStyleGuideRuleSetResponse: response of deleting a style guide rule set
         * @summary Delete a custom style guide rule set for a document
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStyleGuideRuleSetV1(workspaceId: string, ruleSetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteStyleGuideRuleSetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStyleGuideRuleSetV1(workspaceId, ruleSetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a custom style guide rule for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     rule_id: rule to be deleted     rule_set_id: rule set from which the rule is to be deleted     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     DeleteStyleGuideRuleResponse: response of deleting a style guide rule
         * @summary Delete a custom style guide rule for a document
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStyleGuideRuleV1(workspaceId: string, ruleSetId: string, ruleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteStyleGuideRuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStyleGuideRuleV1(workspaceId, ruleSetId, ruleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ask questions to a given document (currently only PDF) and get their result  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     service_request: Request body for qna with pdf  Returns:     QnaWithPdfResponse: response of the doing qna with pdf
         * @summary Get Results of questions asked to a pdf
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {QnaWithPdfRequestBody} qnaWithPdfRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doQNAWithPdfV1(workspaceId: string, documentId: string, qnaWithPdfRequestBody: QnaWithPdfRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QnaWithPdfResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doQNAWithPdfV1(workspaceId, documentId, qnaWithPdfRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch documents uploaded by user
         * @param {string} workspaceId 
         * @param {Array<string>} [userIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchDocumentsV1(workspaceId: string, userIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAppDocumentsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchDocumentsV1(workspaceId, userIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch projects for the gen ai app Args:     workspace_id: workspace id     app_id: app id     user: user object  Returns: FetchProjectsResponse
         * @summary Fetch projects for the gen ai app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchGenAIProjectsV1(workspaceId: string, appId: AppIdType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchGenAIProjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchGenAIProjectsV1(workspaceId, appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch projects for the gen ai app (paginated) Args:     workspace_id: workspace id     app_id: app id     user: user object  Returns: FetchProjectsResponse
         * @summary Fetch projects for the gen ai app (paginated)
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchGenAIProjectsV2(workspaceId: string, appId: AppIdType, start: number, end: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchGenAIPaginatedProjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchGenAIProjectsV2(workspaceId, appId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Will return with page_ids and their page_versions for the given milestone  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     milestone_id: milestone_id for which the whole document will be fetched  Returns:     FetchMilestoneResponse
         * @summary Get the stored page content for each page for a given milestone_id
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} milestoneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMilestoneV1(workspaceId: string, documentId: string, milestoneId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchMilestoneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMilestoneV1(workspaceId, documentId, milestoneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Will return with a number of milestones that a particular doc has  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made  Returns:     FetchNumberOfMilestonesForDocResponse
         * @summary Get number of milestones of a document we currently have
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchNumOfMilestonesForDocV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchNumberOfMilestonesForDocResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchNumOfMilestonesForDocV1(workspaceId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Will return with a number of versions that a particular page has  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     page_id: page_id for which all the versions are required  Returns:     FetchNumberOfVersionsForPageResponse: status and number of versions available for a particular page_id
         * @summary Get number of versions of a page we currently have
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchNumOfVersionsOfPageV1(workspaceId: string, documentId: string, pageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchNumberOfVersionsForPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchNumOfVersionsOfPageV1(workspaceId, documentId, pageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch suggestions for a page
         * @param {string} workspaceId 
         * @param {number} pageId 
         * @param {BodyFetchPageSuggestionsFastV1} bodyFetchPageSuggestionsFastV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPageSuggestionsFastV1(workspaceId: string, pageId: number, bodyFetchPageSuggestionsFastV1: BodyFetchPageSuggestionsFastV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParagraphTextSuggestions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPageSuggestionsFastV1(workspaceId, pageId, bodyFetchPageSuggestionsFastV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch suggestions for a page
         * @param {string} workspaceId 
         * @param {number} pageId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPageSuggestionsV1(workspaceId: string, pageId: number, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchPageSuggestionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPageSuggestionsV1(workspaceId, pageId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Will fetch the page content for a particular page_id and page_version  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     page_id: milestone_id for which we the document to get restored for     page_version:  Returns:     FetchPageVersionResponse
         * @summary Get page content for a page id for a page version
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {number} pageVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPageVersionContentV1(workspaceId: string, documentId: string, pageId: number, pageVersion: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchPageVersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPageVersionContentV1(workspaceId, documentId, pageId, pageVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all custom style guide rule sets for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     FetchStyleGuideRuleSetsResponse: response of fetching all style guide rule sets
         * @summary Fetch all custom style guide rule sets for a document
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchStyleGuideRuleSetsV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchStyleGuideRuleSetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchStyleGuideRuleSetsV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all custom style guide rules for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     rule_set_id: rule set for which the rules are to be fetched     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     FetchStyleGuideRulesResponse: response of fetching all style guide rules
         * @summary Fetch all custom style guide rules for a document
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchStyleGuideRulesV1(workspaceId: string, ruleSetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchStyleGuideRulesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchStyleGuideRulesV1(workspaceId, ruleSetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch relevant citations from web.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {string} searchKeyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCitationsV1(workspaceId: string, documentId: string, searchKeyword: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindCitationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCitationsV1(workspaceId, documentId, searchKeyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate regex for a rule set Args:     query:     auth: authentication  Returns: GenerateRegexResponse
         * @summary Generate regex for a rule set
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateRegexForRuleSetV1(query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateRegexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateRegexForRuleSetV1(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches the references for a particular document.  Args:     citation_ids: Optional - Added to fetch response for a set of reference-ids.     workspace_id (str): customer id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetAllCitationsTextResponse: Response for fetching citations for the document.
         * @summary Fetch reference text for a particular document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {CitationPositionType} citationFormat 
         * @param {CitationStyleGuideType} citationStyle 
         * @param {Array<string>} [citationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCitationsTextV1(workspaceId: string, documentId: string, citationFormat: CitationPositionType, citationStyle: CitationStyleGuideType, citationIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllCitationsTextResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCitationsTextV1(workspaceId, documentId, citationFormat, citationStyle, citationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches the references for a particular document.  Args:     citation_ids: Optional - Added to fetch response for a set of reference-ids.     workspace_id (str): customer id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetCitationsResponse: Response for fetching citations for the document.
         * @summary Fetch references for a particular document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {Array<string>} [citationIds] 
         * @param {string} [searchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCitationsV1(workspaceId: string, documentId: string, citationIds?: Array<string>, searchFilter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCitationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCitationsV1(workspaceId, documentId, citationIds, searchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch list of all custom dictionaries for a workspace  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetCustomDictionaryListResponse: response of fetching all dictionaries
         * @summary Fetch list of all custom dictionaries for a workspace
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDictionaryListV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomDictionaryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDictionaryListV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch list of all custom dictionaries for a workspace (paginated)  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetCustomDictionaryPaginatedResponse: paginated response of fetching all dictionaries
         * @summary Fetch list of all custom dictionaries for a workspace (paginated)
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDictionaryListV2(workspaceId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomDictionaryPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDictionaryListV2(workspaceId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the analysis status of the page  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page id inside the pdf for which parsed segments are to be fetched  Returns:     AnalysePageStatusResponse: status of the analysis
         * @summary Get the analysis status of the page
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalyseStatusV1(workspaceId: string, documentId: string, pageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysePageStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalyseStatusV1(workspaceId, documentId, pageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of documents registered with the app  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     app_id: app id for which documents are to be fetched  Returns:     GetAppDocumentsListResponse: list of documents registered with the app
         * @summary Get list of documents registered with the app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppDocumentsListV1(workspaceId: string, appId: AppIdType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAppDocumentsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppDocumentsListV1(workspaceId, appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of documents registered with the app  Args:     project_id: project id for which documents are to be fetched     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     app_id: app id for which documents are to be fetched  Returns:     GetAppDocumentsListResponse: list of documents registered with the app
         * @summary Get list of documents registered with the app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppDocumentsListV2(workspaceId: string, appId: AppIdType, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAppDocumentsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppDocumentsListV2(workspaceId, appId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch documents uploaded by user (paginated)
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppDocumentsListV3(workspaceId: string, appId: AppIdType, projectId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaginatedAppDocumentsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppDocumentsListV3(workspaceId, appId, projectId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list Get project metadata for the associated project_id  Args:     project_id: project id for which documents are to be fetched     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     app_id: app id for which documents are to be fetched  Returns:     GenAIProjectMetadataResponse: Project metadata for the associated project.
         * @summary Get project metadata for the associated project_id
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppProjectMetadataV1(workspaceId: string, appId: AppIdType, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenAIProjectMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppProjectMetadataV1(workspaceId, appId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get citation models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitationModelsV1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CitationModelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitationModelsV1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the result of Plagiarism check
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentPlagiarismResultV1(workspaceId: string, documentId: string, pageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckContentPlagiarismResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentPlagiarismResultV1(workspaceId, documentId, pageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all word list for a given dictionary_id  Args:     workspace_id (str): workspace id that uniquely identifies the customer     dictionary_id: dictionary for which the words are to be fetched     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetCustomDictionaryWordsListResponse: response of fetching all words in dictionary
         * @summary Fetch all word list for a given dictionary_id
         * @param {string} workspaceId 
         * @param {string} dictionaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDictionaryWordsListV1(workspaceId: string, dictionaryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomDictionaryWordsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDictionaryWordsListV1(workspaceId, dictionaryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch document download status, if completed, also return the signed URL, if failed, return an error message.
         * @summary Get document download status response
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} documentId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentDownloadStatusV1(workspaceId: string, appId: AppIdType, documentId: string, taskId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentDownloadStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentDownloadStatusV1(workspaceId, appId, documentId, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get document download response
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} documentId 
         * @param {DocumentDownloadFormats} downloadFormat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentDownloadV1(workspaceId: string, appId: AppIdType, documentId: string, downloadFormat: DocumentDownloadFormats, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentDownloadV1(workspaceId, appId, documentId, downloadFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get document metadata which contains s3 presigned url  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
         * @summary Get document metadata response
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentMetadataV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentMetadataV1(workspaceId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the parsing status of the whole document uploaded  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     DocumentParsingStatusResponse: status of the parsing
         * @summary Get the parsing status of the whole document uploaded
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentParsingStatusV1(workspaceId: string, documentId: string, appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentParsingStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentParsingStatusV1(workspaceId, documentId, appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch document stats like lexical diversity, word count, sentence count, etc. for the given input
         * @summary Get document stats response for the given input text
         * @param {AppIdType} appId 
         * @param {string} workspaceId 
         * @param {GetDocumentStatsForInputTextRequest} getDocumentStatsForInputTextRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentStatsForTextSelectionV1(appId: AppIdType, workspaceId: string, getDocumentStatsForInputTextRequest: GetDocumentStatsForInputTextRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextAnalysisMetrics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentStatsForTextSelectionV1(appId, workspaceId, getDocumentStatsForInputTextRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch document stats like lexical diversity, word count, sentence count, etc.
         * @summary Get document stats response
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentStatsV1(workspaceId: string, appId: AppIdType, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextAnalysisMetrics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentStatsV1(workspaceId, appId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the summary of the document  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
         * @summary Get the summary of the document
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentSummaryV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentSummaryV1(workspaceId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch the signed S3 URL to start download (for the latest available version) message.
         * @summary Fetch the signed URL of the latest version
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestDocumentDownloadV1(workspaceId: string, appId: AppIdType, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentDownloadStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestDocumentDownloadV1(workspaceId, appId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get analysis status for a page
         * @param {string} workspaceId 
         * @param {number} pageId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPageAnalysisStatusV1(workspaceId: string, pageId: number, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestionAnalysisStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPageAnalysisStatusV1(workspaceId, pageId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the summary of the document  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page id for which summary needs to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
         * @summary Get page summary of the document
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPageSummaryV1(workspaceId: string, documentId: string, pageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPageSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPageSummaryV1(workspaceId, documentId, pageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch paginated style guide configs for a workspace  Args:     workspace_id (str): workspace id that uniquely identifies the customer     start (int): pagination start index     end (int): pagination end index     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (str, optional): timestamp for pagination, defaults to \"\"     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetStyleGuideConfigPaginatedResponse: paginated response of style guide configs
         * @summary Fetch paginated style guide configs for a workspace
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedStyleGuideConfigV1(workspaceId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStyleGuideConfigPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedStyleGuideConfigV1(workspaceId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get parsed page in pdf  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed page is to be fetched     page_id: page id inside the pdf     background_tasks:  Returns:     GetParsedSegmentsResponse: list of parsed segments
         * @summary Get parsed page response
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParsedSegmentsV1(workspaceId: string, documentId: string, pageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetParsedPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParsedSegmentsV1(workspaceId, documentId, pageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * V2 version of get parsed page response api which is used for RTC in copy edit app  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed page is to be fetched     page_id: page id inside the pdf  Returns:     GetParsedSegmentsResponse: list of parsed segments
         * @summary Get parsed page response
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParsedSegmentsV2(workspaceId: string, documentId: string, pageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetParsedPageResponseV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParsedSegmentsV2(workspaceId, documentId, pageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get project_id for the associated document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectFromDocumentV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectFromDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectFromDocumentV1(workspaceId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get rule set file upload status
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getRuleSetFileUploadStatusV1(workspaceId: string, ruleSetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuleSetUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRuleSetFileUploadStatusV1(workspaceId, ruleSetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ``` Invalidate the cache for copy edit analysis Args:     request (Request): FastAPI Request object. This is required by the decorator to extract header information     response (Response): FastAPI Response object. This is required by the decorator to set status     auth: authentication  Returns: ```
         * @summary Invalidate Copy Edit Analysis Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invalidateCopyEditAnalysisCache(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invalidateCopyEditAnalysisCache(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invalidate suggestions across documents
         * @param {string} workspaceId 
         * @param {string} suggestionId ID of the suggestion to invalidate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invalidateSuggestionsV1(workspaceId: string, suggestionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysePageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invalidateSuggestionsV1(workspaceId, suggestionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invite users to a project Args:     workspace_id: workspace id     app_id: app id     project_id: project id     request: request body     user: user object     background_tasks: to push analytics to bangalore service  Returns: InviteProjectResponse
         * @summary Invite users to a project
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {InviteGenAIProjectRequest} inviteGenAIProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteGenAIProjectV1(workspaceId: string, appId: AppIdType, projectId: string, inviteGenAIProjectRequest: InviteGenAIProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteGenAIProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteGenAIProjectV1(workspaceId, appId, projectId, inviteGenAIProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Migrate suggestions rules from one workspace to another Args:     workspace_id: workspace id     query: query to filter documents in the pagesuggestions     user: user object  Returns: None
         * @summary Migrate suggestions rules from one workspace to another
         * @param {string} workspaceId 
         * @param {BodyMigrateSuggestionsRulesV1} bodyMigrateSuggestionsRulesV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateSuggestionsRulesV1(workspaceId: string, bodyMigrateSuggestionsRulesV1: BodyMigrateSuggestionsRulesV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateSuggestionsRulesV1(workspaceId, bodyMigrateSuggestionsRulesV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Parse the input citation text and add the entities present in the citation text into the DB
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {BodyParseAndAddBulkCitationTextV1} bodyParseAndAddBulkCitationTextV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parseAndAddBulkCitationTextV1(workspaceId: string, documentId: string, bodyParseAndAddBulkCitationTextV1: BodyParseAndAddBulkCitationTextV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCitationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parseAndAddBulkCitationTextV1(workspaceId, documentId, bodyParseAndAddBulkCitationTextV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Parse the input citation text and return the entities present in the citation text
         * @param {string} workspaceId 
         * @param {BodyParseCitationTextV1} bodyParseCitationTextV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parseCitationTextV1(workspaceId: string, bodyParseCitationTextV1: BodyParseCitationTextV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CopyEditCitation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parseCitationTextV1(workspaceId, bodyParseCitationTextV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Webhook to receive the result of the Plagiarism check  Args:     request (Request): FastAPI Request object     response (Response): FastAPI Response object  Returns:     None
         * @summary Webhook to receive the result of the Plagiarism check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receivePlagiarismCheckResult(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receivePlagiarismCheckResult(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Register the document with the app  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     app_id: app id for which document is to be registered     background_tasks (BackgroundTasks): FastAPI BackgroundTasks object  Returns:     RegisterAppDocumentResponse: response of the registration
         * @summary Register the document with the app
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {AppIdType} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerAppDocumentV1(workspaceId: string, documentId: string, appId: AppIdType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterAppDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerAppDocumentV1(workspaceId, documentId, appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Register the document with the app v2  Args:     style_guides: ADA or CMOS     custom_style_rules: Custom style guide     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     app_id: app id for which document is to be registered     background_tasks (BackgroundTasks): FastAPI BackgroundTasks object  Returns:     RegisterAppDocumentResponse: response of the registration
         * @summary Register the document with the app
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {AppIdType} appId 
         * @param {BodyRegisterAppDocumentV2} [bodyRegisterAppDocumentV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerAppDocumentV2(workspaceId: string, documentId: string, appId: AppIdType, bodyRegisterAppDocumentV2?: BodyRegisterAppDocumentV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterAppDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerAppDocumentV2(workspaceId, documentId, appId, bodyRegisterAppDocumentV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Register the document with the app v2  Args:     project_id: project where the document is associated with     style_guides: ADA or CMOS     custom_style_rules: Custom style guide     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     app_id: app id for which document is to be registered     custom_dictionary_ids (List[str]): The list of ids of dictionaries to be applied on this document     background_tasks (BackgroundTasks): FastAPI BackgroundTasks object  Returns:     RegisterAppDocumentResponse: response of the registration
         * @summary Register the document with the app
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {string} projectId 
         * @param {AppIdType} appId 
         * @param {BodyRegisterAppDocumentV3} [bodyRegisterAppDocumentV3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerAppDocumentV3(workspaceId: string, documentId: string, projectId: string, appId: AppIdType, bodyRegisterAppDocumentV3?: BodyRegisterAppDocumentV3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterAppDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerAppDocumentV3(workspaceId, documentId, projectId, appId, bodyRegisterAppDocumentV3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove user from a project Args:     workspace_id: workspace id     app_id: app id     project_id: project id     service_request: request body     user: user object  Returns: RemoveInviteProjectResponse
         * @summary Remove user from a project
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {RemoveInviteGenAIProjectRequest} removeInviteGenAIProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromGenAIProjectV1(workspaceId: string, appId: AppIdType, projectId: string, removeInviteGenAIProjectRequest: RemoveInviteGenAIProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoveInviteGenAIProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserFromGenAIProjectV1(workspaceId, appId, projectId, removeInviteGenAIProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove workspace sharing for a resource
         * @param {string} workspaceId 
         * @param {BodyRemoveWorkspaceShareResourceV1} bodyRemoveWorkspaceShareResourceV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWorkspaceShareResourceV1(workspaceId: string, bodyRemoveWorkspaceShareResourceV1: BodyRemoveWorkspaceShareResourceV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWorkspaceShareResourceV1(workspaceId, bodyRemoveWorkspaceShareResourceV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Will set the pointers for all the pages in a doc to their last milestone saved page_versions  Args:     workspace_id (str): workspace id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     milestone_id: milestone_id for which we the document to get restored for  Returns:     RestoreMilestoneResponse
         * @summary Get number of milestones of a document we currently have
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} milestoneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreMilestoneForDocV1(workspaceId: string, documentId: string, milestoneId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestoreMilestoneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreMilestoneForDocV1(workspaceId, documentId, milestoneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Restores the current state of page to a page version provided, creating a new version maintaining linear history  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     page_id: concerned page     page_version: page version we want the current page to move to     background_tasks: to run push analytics call Returns:     RestorePageResponse
         * @summary Restores the current state of page to a page version provided
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {number} pageVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreVersionForDocV1(workspaceId: string, documentId: string, pageId: number, pageVersion: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestorePageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreVersionForDocV1(workspaceId, documentId, pageId, pageVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Will save the content for the current version and create a new version based on a set timelimit  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page_id for which the content has to be saved.     service_request: Request body for creating a version     background_tasks:  Returns:     SaveContentResponse: success status for saving content for a version
         * @summary Saving the actual changed content, creating and updating pointers for a session
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {SaveContentRequestBody} saveContentRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveContentForSessionV1(workspaceId: string, documentId: string, pageId: number, saveContentRequestBody: SaveContentRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveContentForSessionV1(workspaceId, documentId, pageId, saveContentRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the summary of the document  Args:     binary_formatted_document_summary: save snippet summary content in binary format for RTC support     formatted_document_summary: this will save the summary content as snippet formatted content     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
         * @summary Save formatted snippet content which contains the summary of the document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {BodySaveDocumentSummaryV1} [bodySaveDocumentSummaryV1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDocumentSummaryV1(workspaceId: string, documentId: string, bodySaveDocumentSummaryV1?: BodySaveDocumentSummaryV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDocumentSummaryV1(workspaceId, documentId, bodySaveDocumentSummaryV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Share a resource with the entire workspace
         * @param {string} workspaceId 
         * @param {BodyShareResourceWithWorkspaceV1} bodyShareResourceWithWorkspaceV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareResourceWithWorkspaceV1(workspaceId: string, bodyShareResourceWithWorkspaceV1: BodyShareResourceWithWorkspaceV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shareResourceWithWorkspaceV1(workspaceId, bodyShareResourceWithWorkspaceV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stream LLM response with optimized performance
         * @summary Stream LLM response
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamLLMResponse(query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamLLMResponse(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Trigger asynchronous document download
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} documentId 
         * @param {DocumentDownloadFormats} downloadFormat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerAsyncDocumentDownloadV1(workspaceId: string, appId: AppIdType, documentId: string, downloadFormat: DocumentDownloadFormats, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerAsyncDownloadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerAsyncDocumentDownloadV1(workspaceId, appId, documentId, downloadFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Trigger re-analyze on document metadata change.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerReAnalyzeTextsV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysePageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerReAnalyzeTextsV1(workspaceId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the analysis status of the page  Args:     status_change_request: update the current state of analyse page to this state     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page id inside the pdf for which parsed segments are to be fetched  Returns:     AnalysePageStatusResponse: status of the analysis
         * @summary Update the analysis status of the page
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {AnalysePageStatusResponse} analysePageStatusResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnalyseStatusV1(workspaceId: string, documentId: string, pageId: number, analysePageStatusResponse: AnalysePageStatusResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysePageStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnalyseStatusV1(workspaceId, documentId, pageId, analysePageStatusResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update and analyse text resources for a text in add-in
         * @param {string} workspaceId 
         * @param {UpdateAnalyseAddInRequest} updateAnalyseAddInRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAndAnalyseV1(workspaceId: string, updateAnalyseAddInRequest: UpdateAnalyseAddInRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysePageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAndAnalyseV1(workspaceId, updateAnalyseAddInRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the document metadata for copy edit  Args:     workspace_id (str): workspace id that uniquely identifies the customer     document_id: document id for which parsed segments are to be fetched     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     UpdateAppDocumentResponse: response of the registration
         * @summary Update the document stylesheet metadata for copy edit
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppDocumentStylesheetMetadataV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAppDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppDocumentStylesheetMetadataV1(workspaceId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the citation style in document metadata
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {UpdateCitationStyleRequest} updateCitationStyleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCitationStyleRequestV1(workspaceId: string, documentId: string, updateCitationStyleRequest: UpdateCitationStyleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCitationStyleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCitationStyleRequestV1(workspaceId, documentId, updateCitationStyleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the references for a particular page.  Args:     page_id: page_id to update references.     update_request: references which need to be updated for a particular document.     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     UpdateCitationsResponse: response for updating references for the document.
         * @summary Update citations for a particular document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {UpdateCitationsRequest} updateCitationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCitationsV1(workspaceId: string, documentId: string, pageId: number, updateCitationsRequest: UpdateCitationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCitationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCitationsV1(workspaceId, documentId, pageId, updateCitationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the document metadata for copy edit documents
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {UpdateDocumentMetadataRequest} updateDocumentMetadataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocumentMetadataV1(workspaceId: string, documentId: string, updateDocumentMetadataRequest: UpdateDocumentMetadataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDocumentMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocumentMetadataV1(workspaceId, documentId, updateDocumentMetadataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a project for the Gen AI app Args:     project_id:     workspace_id: workspace id     app_id: app id     request: request body     user: user object     background_tasks:  Returns: GenAIProjectMetadataResponse
         * @summary Create or update a project for the Gen AI app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {UpdateGenAIProjectRequest} updateGenAIProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGenAIProjectV1(workspaceId: string, appId: AppIdType, projectId: string, updateGenAIProjectRequest: UpdateGenAIProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateGenAIProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGenAIProjectV1(workspaceId, appId, projectId, updateGenAIProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the segments with the suggested changes Args:     user: auth check     workspace_id:     document_id:     page_id:     update_request:     request:     response:  Returns:
         * @summary Update page content with the latest version of formatted page content
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {UpdateSegmentRequest} updateSegmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePageContentV1(workspaceId: string, documentId: string, pageId: number, updateSegmentRequest: UpdateSegmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePageContentV1(workspaceId, documentId, pageId, updateSegmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * V2 Update the segments with the suggested changes for supporting RTC changes on copy edit app Args:     workspace_id:     document_id:     page_id:     update_request:     background_tasks:     request:     response:     user:  Returns:
         * @summary Update page content with the latest version of formatted page content
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {UpdateSegmentRequestV2} updateSegmentRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePageContentV2(workspaceId: string, documentId: string, pageId: number, updateSegmentRequestV2: UpdateSegmentRequestV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePageContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePageContentV2(workspaceId, documentId, pageId, updateSegmentRequestV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This will be called when we accept suggestions in our copy edit app. These suggestions are from open ai model. After accepting the changes we will create a new entry in the Segments Table for the pdf
         * @summary Create a new segment with the latest version id with the suggested changes by openai.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {string} pageId 
         * @param {string} chunkId 
         * @param {UpdateSegmentRequest} updateSegmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSegmentV1(workspaceId: string, documentId: string, pageId: string, chunkId: string, updateSegmentRequest: UpdateSegmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSegmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSegmentV1(workspaceId, documentId, pageId, chunkId, updateSegmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a specific style guide configuration record for a workspace.  Args:     workspace_id (str): The ID of the workspace where the configuration exists.     record_id (str): The specific record to update.     config_updates (Dict[str, Any]): Fields to update in the configuration.     request (Request): FastAPI Request object.     response (Response): FastAPI Response object.     user (WorkspaceUser, optional): Authenticated user performing the update.  Returns:     Dict[str, Any]: Response from Montreal service after updating the record.
         * @summary Update style guide config for a workspace
         * @param {string} workspaceId 
         * @param {string} recordId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStyleGuideConfigV1(workspaceId: string, recordId: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStyleGuideConfigV1(workspaceId, recordId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update suggestion status for a document
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {UpdatePageSuggestions} updatePageSuggestions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSuggestionStatusV1(workspaceId: string, documentId: string, updatePageSuggestions: UpdatePageSuggestions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysePageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSuggestionStatusV1(workspaceId, documentId, updatePageSuggestions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload manual text file for copy edit / summarization analysis  Args:     project_id: project id for which document is to be registered     background_tasks: to trigger the summarization/copy edit analysis flow in the background process     upload_request: upload text file to be analysed     workspace_id (str): customer id that uniquely identifies the customer     app_id: app id for which document is to be registered     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     UploadManualTextFileResponse: response of the text file registration
         * @summary Upload manual text file for copy-edit or summarization analysis
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {AppIdType} appId 
         * @param {UploadManualTextFileRequest} uploadManualTextFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadManualTextFileRequestV1(workspaceId: string, projectId: string, appId: AppIdType, uploadManualTextFileRequest: UploadManualTextFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadManualTextFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadManualTextFileRequestV1(workspaceId, projectId, appId, uploadManualTextFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload rule set
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {UploadRuleSetRequest} uploadRuleSetRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async uploadRuleSetV1(workspaceId: string, ruleSetId: string, uploadRuleSetRequest: UploadRuleSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuleSetUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadRuleSetV1(workspaceId, ruleSetId, uploadRuleSetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppStoreApi - factory interface
 * @export
 */
export const AppStoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppStoreApiFp(configuration)
    return {
        /**
         * Add or update a custom style guide rule set for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     service_request: Request body for adding a style guide rule set     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     AddUpdateStyleGuideRuleSetResponse: response of adding a style guide rule set
         * @summary Add or update a custom style guide rule set for a document
         * @param {string} workspaceId 
         * @param {AddUpdateStyleGuideRuleSetRequest} addUpdateStyleGuideRuleSetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUpdateStyleGuideRuleSetV1(workspaceId: string, addUpdateStyleGuideRuleSetRequest: AddUpdateStyleGuideRuleSetRequest, options?: any): AxiosPromise<AddStyleGuideRuleSetResponse> {
            return localVarFp.addUpdateStyleGuideRuleSetV1(workspaceId, addUpdateStyleGuideRuleSetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a custom style guide rule for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     rule_set_id: rule set to which the rule is to be added     service_request: Request body for adding a style guide rule     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     AddUpdateStyleGuideRuleResponse: response of adding a style guide rule
         * @summary Add a custom style guide rule for a document
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {AddStyleGuideRuleRequest} addStyleGuideRuleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUpdateStyleGuideRuleV1(workspaceId: string, ruleSetId: string, addStyleGuideRuleRequest: AddStyleGuideRuleRequest, options?: any): AxiosPromise<AddUpdateStyleGuideRuleResponse> {
            return localVarFp.addUpdateStyleGuideRuleV1(workspaceId, ruleSetId, addStyleGuideRuleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Add or update a custom style guide rule set for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     service_request: Request body for adding words to custom dictionary     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     AddWordsToCustomDictionaryResponse: response of add words to the custom dictionary
         * @summary Add words to the given custom dictionary
         * @param {string} workspaceId 
         * @param {AddWordsToCustomDictionaryRequest} addWordsToCustomDictionaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWordsToCustomDictionaryV1(workspaceId: string, addWordsToCustomDictionaryRequest: AddWordsToCustomDictionaryRequest, options?: any): AxiosPromise<AddWordsToCustomDictionaryResponse> {
            return localVarFp.addWordsToCustomDictionaryV1(workspaceId, addWordsToCustomDictionaryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * each page in the pdf is parsed into segments and stored in the order in which they occur in the pdf
         * @summary start file analysis and parse all the segments in the page to be used for copy edit, this analysis is requested for one or more pages
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {AnalyseFileRequest} analyseFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyseFileV1(workspaceId: string, documentId: string, analyseFileRequest: AnalyseFileRequest, options?: any): AxiosPromise<AnalysePageResponse> {
            return localVarFp.analyseFileV1(workspaceId, documentId, analyseFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches the suitable analytics
         * @summary Fetches analytics of the copy edit application based on the filters
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {number} [days] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [projectIds] 
         * @param {Array<string>} [documentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsCopyEditFiltersV1(workspaceId: string, appId: string, days?: number, userIds?: Array<string>, projectIds?: Array<string>, documentIds?: Array<string>, options?: any): AxiosPromise<AnalyticsCopyEditResponse> {
            return localVarFp.analyticsCopyEditFiltersV1(workspaceId, appId, days, userIds, projectIds, documentIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches user count, unique daily active users, documents improved, writing sessions,    suggestions generated
         * @summary Fetches analytics of the copy edit application
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {AppAnalyticsRequest} appAnalyticsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsCopyEditV1(workspaceId: string, appId: string, appAnalyticsRequest: AppAnalyticsRequest, options?: any): AxiosPromise<AnalyticsCopyEditResponse> {
            return localVarFp.analyticsCopyEditV1(workspaceId, appId, appAnalyticsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analyze text resources for a page
         * @param {string} workspaceId 
         * @param {number} pageId 
         * @param {string} documentId 
         * @param {PageTextResources} pageTextResources 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyzeTextsV1(workspaceId: string, pageId: number, documentId: string, pageTextResources: PageTextResources, options?: any): AxiosPromise<AnalysePageResponse> {
            return localVarFp.analyzeTextsV1(workspaceId, pageId, documentId, pageTextResources, options).then((request) => request(axios, basePath));
        },
        /**
         * Attach dictionaries to an existing. These will be used when document is re-analyzed
         * @summary Attach dictionaries to an existing document
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {AttachCustomDictionariesToDocumentRequest} attachCustomDictionariesToDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachDictionariesToDocumentV1(workspaceId: string, documentId: string, attachCustomDictionariesToDocumentRequest: AttachCustomDictionariesToDocumentRequest, options?: any): AxiosPromise<AttachCustomDictionariesToDocumentResponse> {
            return localVarFp.attachDictionariesToDocumentV1(workspaceId, documentId, attachCustomDictionariesToDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bulk upload rules for a rule set
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUploadRulesV1(workspaceId: string, ruleSetId: string, file?: any, options?: any): AxiosPromise<BulkUploadRulesResponse> {
            return localVarFp.bulkUploadRulesV1(workspaceId, ruleSetId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Async call to check Plagiarism of the content provided
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {CheckContentPlagiarismRequestModel} checkContentPlagiarismRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkContentPlagiarismV1(workspaceId: string, documentId: string, pageId: number, checkContentPlagiarismRequestModel: CheckContentPlagiarismRequestModel, options?: any): AxiosPromise<PlagiarismCheckSubmitAckResponse> {
            return localVarFp.checkContentPlagiarismV1(workspaceId, documentId, pageId, checkContentPlagiarismRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a pre-signed s3 url for uploading document assets for copy edit app.
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {DocumentUploadRequest} documentUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentAssetsUploadUrlV1(workspaceId: string, appId: AppIdType, documentUploadRequest: DocumentUploadRequest, options?: any): AxiosPromise<GetDocumentUploadUriResponse> {
            return localVarFp.createDocumentAssetsUploadUrlV1(workspaceId, appId, documentUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a citation for a page
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {CopyEditCitation} copyEditCitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentCitationV1(workspaceId: string, documentId: string, copyEditCitation: CopyEditCitation, options?: any): AxiosPromise<CreateOrUpdateCitationsResponse> {
            return localVarFp.createDocumentCitationV1(workspaceId, documentId, copyEditCitation, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a project for the gen ai app Args:     workspace_id: workspace id     app_id: app id     request: request body     user: user object     background_tasks: to push analytics to bangalore service  Returns: CreateProjectResponse
         * @summary Create a project for the gen ai app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {CreateGenAIProjectRequest} createGenAIProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGenAIProjectV1(workspaceId: string, appId: AppIdType, createGenAIProjectRequest: CreateGenAIProjectRequest, options?: any): AxiosPromise<GenAIProjectMetadataResponse> {
            return localVarFp.createGenAIProjectV1(workspaceId, appId, createGenAIProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Will set page_ids with their latest page_versions against a particular milestone_id  Args:     workspace_id (str): workspace id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     CreateMilestoneResponse
         * @summary Save the latest page_versions of all the pages against the latest generated milestone
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMilestoneV1(workspaceId: string, documentId: string, options?: any): AxiosPromise<CreateMilestoneResponse> {
            return localVarFp.createMilestoneV1(workspaceId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a custom dictionary for a workspace that can be applied to a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     service_request: Request body for creating a custom dictionary     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     CreateCustomDictionaryResponse: response of creating a custom dictionary
         * @summary Create a custom dictionary for a document
         * @param {string} workspaceId 
         * @param {CreateOrUpdateCustomDictionaryRequest} createOrUpdateCustomDictionaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateCustomDictionaryV1(workspaceId: string, createOrUpdateCustomDictionaryRequest: CreateOrUpdateCustomDictionaryRequest, options?: any): AxiosPromise<CreateCustomDictionaryResponse> {
            return localVarFp.createOrUpdateCustomDictionaryV1(workspaceId, createOrUpdateCustomDictionaryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create upload url for rule set
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createUploadURLForRuleSetV1(workspaceId: string, ruleSetId: string, options?: any): AxiosPromise<CreateRuleSetUploadURLResponse> {
            return localVarFp.createUploadURLForRuleSetV1(workspaceId, ruleSetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete citations for a particular document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCitationsV1(workspaceId: string, documentId: string, requestBody: Array<string>, options?: any): AxiosPromise<DeleteCitationsResponse> {
            return localVarFp.deleteCitationsV1(workspaceId, documentId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a custom dictionary from a workspace  Args:    workspace_id (str): workspace id that uniquely identifies the customer    dictionary_id (str): dictionary id that uniquely identifies the dictionary    request (Request): FastAPI Request object    response (Response): FastAPI Response object    user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:    CreateCustomDictionaryResponse: response of creating a custom dictionary
         * @summary Delete a custom dictionary from the document
         * @param {string} workspaceId 
         * @param {string} dictionaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomDictionaryV1(workspaceId: string, dictionaryId: string, options?: any): AxiosPromise<DeleteCustomDictionaryResponse> {
            return localVarFp.deleteCustomDictionaryV1(workspaceId, dictionaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a custom dictionary from a workspace  Args:    workspace_id (str): workspace id that uniquely identifies the customer    dictionary_id (str): dictionary id that uniquely identifies the dictionary    dictionary_word_list: List of words that need to be deleted    request (Request): FastAPI Request object    response (Response): FastAPI Response object    user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:    CreateCustomDictionaryResponse: response of creating a custom dictionary
         * @summary Delete a custom dictionary word from the dictionary
         * @param {string} workspaceId 
         * @param {string} dictionaryId 
         * @param {DeleteWordsFromCustomDictionaryRequest} deleteWordsFromCustomDictionaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomDictionaryWordV1(workspaceId: string, dictionaryId: string, deleteWordsFromCustomDictionaryRequest: DeleteWordsFromCustomDictionaryRequest, options?: any): AxiosPromise<DeleteWordsFromCustomDictionaryResponse> {
            return localVarFp.deleteCustomDictionaryWordV1(workspaceId, dictionaryId, deleteWordsFromCustomDictionaryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the document and all its related data
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentV1(workspaceId: string, documentId: string, options?: any): AxiosPromise<DeleteDocumentResponse> {
            return localVarFp.deleteDocumentV1(workspaceId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a project for the Gen AI app Args:     project_id: project id for the project     workspace_id: workspace id     app_id: app id     user: user object     background_tasks: to push analytics to bangalore service  Returns: CreateProjectResponse
         * @summary Delete a project for the Gen AI app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGenAIProjectV1(workspaceId: string, appId: AppIdType, projectId: string, options?: any): AxiosPromise<DeleteGenAIProjectResponse> {
            return localVarFp.deleteGenAIProjectV1(workspaceId, appId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete suggestions for a page
         * @param {string} workspaceId 
         * @param {number} pageId 
         * @param {string} documentId 
         * @param {Array<string>} [resourceIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePageSuggestionsV1(workspaceId: string, pageId: number, documentId: string, resourceIds?: Array<string>, options?: any): AxiosPromise<DeletePageSuggestionsResponse> {
            return localVarFp.deletePageSuggestionsV1(workspaceId, pageId, documentId, resourceIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a custom style guide rule set for a document  Args:     rule_set_id: Rule set to be deleted     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     DeleteStyleGuideRuleSetResponse: response of deleting a style guide rule set
         * @summary Delete a custom style guide rule set for a document
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStyleGuideRuleSetV1(workspaceId: string, ruleSetId: string, options?: any): AxiosPromise<DeleteStyleGuideRuleSetResponse> {
            return localVarFp.deleteStyleGuideRuleSetV1(workspaceId, ruleSetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a custom style guide rule for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     rule_id: rule to be deleted     rule_set_id: rule set from which the rule is to be deleted     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     DeleteStyleGuideRuleResponse: response of deleting a style guide rule
         * @summary Delete a custom style guide rule for a document
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStyleGuideRuleV1(workspaceId: string, ruleSetId: string, ruleId: string, options?: any): AxiosPromise<DeleteStyleGuideRuleResponse> {
            return localVarFp.deleteStyleGuideRuleV1(workspaceId, ruleSetId, ruleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Ask questions to a given document (currently only PDF) and get their result  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     service_request: Request body for qna with pdf  Returns:     QnaWithPdfResponse: response of the doing qna with pdf
         * @summary Get Results of questions asked to a pdf
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {QnaWithPdfRequestBody} qnaWithPdfRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doQNAWithPdfV1(workspaceId: string, documentId: string, qnaWithPdfRequestBody: QnaWithPdfRequestBody, options?: any): AxiosPromise<QnaWithPdfResponse> {
            return localVarFp.doQNAWithPdfV1(workspaceId, documentId, qnaWithPdfRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch documents uploaded by user
         * @param {string} workspaceId 
         * @param {Array<string>} [userIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDocumentsV1(workspaceId: string, userIds?: Array<string>, options?: any): AxiosPromise<GetAppDocumentsListResponse> {
            return localVarFp.fetchDocumentsV1(workspaceId, userIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch projects for the gen ai app Args:     workspace_id: workspace id     app_id: app id     user: user object  Returns: FetchProjectsResponse
         * @summary Fetch projects for the gen ai app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchGenAIProjectsV1(workspaceId: string, appId: AppIdType, options?: any): AxiosPromise<FetchGenAIProjectsResponse> {
            return localVarFp.fetchGenAIProjectsV1(workspaceId, appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch projects for the gen ai app (paginated) Args:     workspace_id: workspace id     app_id: app id     user: user object  Returns: FetchProjectsResponse
         * @summary Fetch projects for the gen ai app (paginated)
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchGenAIProjectsV2(workspaceId: string, appId: AppIdType, start: number, end: number, ts?: string, options?: any): AxiosPromise<FetchGenAIPaginatedProjectsResponse> {
            return localVarFp.fetchGenAIProjectsV2(workspaceId, appId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * Will return with page_ids and their page_versions for the given milestone  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     milestone_id: milestone_id for which the whole document will be fetched  Returns:     FetchMilestoneResponse
         * @summary Get the stored page content for each page for a given milestone_id
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} milestoneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMilestoneV1(workspaceId: string, documentId: string, milestoneId: number, options?: any): AxiosPromise<FetchMilestoneResponse> {
            return localVarFp.fetchMilestoneV1(workspaceId, documentId, milestoneId, options).then((request) => request(axios, basePath));
        },
        /**
         * Will return with a number of milestones that a particular doc has  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made  Returns:     FetchNumberOfMilestonesForDocResponse
         * @summary Get number of milestones of a document we currently have
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchNumOfMilestonesForDocV1(workspaceId: string, documentId: string, options?: any): AxiosPromise<FetchNumberOfMilestonesForDocResponse> {
            return localVarFp.fetchNumOfMilestonesForDocV1(workspaceId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Will return with a number of versions that a particular page has  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     page_id: page_id for which all the versions are required  Returns:     FetchNumberOfVersionsForPageResponse: status and number of versions available for a particular page_id
         * @summary Get number of versions of a page we currently have
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchNumOfVersionsOfPageV1(workspaceId: string, documentId: string, pageId: number, options?: any): AxiosPromise<FetchNumberOfVersionsForPageResponse> {
            return localVarFp.fetchNumOfVersionsOfPageV1(workspaceId, documentId, pageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch suggestions for a page
         * @param {string} workspaceId 
         * @param {number} pageId 
         * @param {BodyFetchPageSuggestionsFastV1} bodyFetchPageSuggestionsFastV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPageSuggestionsFastV1(workspaceId: string, pageId: number, bodyFetchPageSuggestionsFastV1: BodyFetchPageSuggestionsFastV1, options?: any): AxiosPromise<ParagraphTextSuggestions> {
            return localVarFp.fetchPageSuggestionsFastV1(workspaceId, pageId, bodyFetchPageSuggestionsFastV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch suggestions for a page
         * @param {string} workspaceId 
         * @param {number} pageId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPageSuggestionsV1(workspaceId: string, pageId: number, documentId: string, options?: any): AxiosPromise<FetchPageSuggestionsResponse> {
            return localVarFp.fetchPageSuggestionsV1(workspaceId, pageId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Will fetch the page content for a particular page_id and page_version  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     page_id: milestone_id for which we the document to get restored for     page_version:  Returns:     FetchPageVersionResponse
         * @summary Get page content for a page id for a page version
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {number} pageVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPageVersionContentV1(workspaceId: string, documentId: string, pageId: number, pageVersion: number, options?: any): AxiosPromise<FetchPageVersionResponse> {
            return localVarFp.fetchPageVersionContentV1(workspaceId, documentId, pageId, pageVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all custom style guide rule sets for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     FetchStyleGuideRuleSetsResponse: response of fetching all style guide rule sets
         * @summary Fetch all custom style guide rule sets for a document
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchStyleGuideRuleSetsV1(workspaceId: string, options?: any): AxiosPromise<FetchStyleGuideRuleSetsResponse> {
            return localVarFp.fetchStyleGuideRuleSetsV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all custom style guide rules for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     rule_set_id: rule set for which the rules are to be fetched     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     FetchStyleGuideRulesResponse: response of fetching all style guide rules
         * @summary Fetch all custom style guide rules for a document
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchStyleGuideRulesV1(workspaceId: string, ruleSetId: string, options?: any): AxiosPromise<FetchStyleGuideRulesResponse> {
            return localVarFp.fetchStyleGuideRulesV1(workspaceId, ruleSetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch relevant citations from web.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {string} searchKeyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCitationsV1(workspaceId: string, documentId: string, searchKeyword: string, options?: any): AxiosPromise<FindCitationsResponse> {
            return localVarFp.findCitationsV1(workspaceId, documentId, searchKeyword, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate regex for a rule set Args:     query:     auth: authentication  Returns: GenerateRegexResponse
         * @summary Generate regex for a rule set
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRegexForRuleSetV1(query?: string, options?: any): AxiosPromise<GenerateRegexResponse> {
            return localVarFp.generateRegexForRuleSetV1(query, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches the references for a particular document.  Args:     citation_ids: Optional - Added to fetch response for a set of reference-ids.     workspace_id (str): customer id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetAllCitationsTextResponse: Response for fetching citations for the document.
         * @summary Fetch reference text for a particular document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {CitationPositionType} citationFormat 
         * @param {CitationStyleGuideType} citationStyle 
         * @param {Array<string>} [citationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCitationsTextV1(workspaceId: string, documentId: string, citationFormat: CitationPositionType, citationStyle: CitationStyleGuideType, citationIds?: Array<string>, options?: any): AxiosPromise<GetAllCitationsTextResponse> {
            return localVarFp.getAllCitationsTextV1(workspaceId, documentId, citationFormat, citationStyle, citationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches the references for a particular document.  Args:     citation_ids: Optional - Added to fetch response for a set of reference-ids.     workspace_id (str): customer id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetCitationsResponse: Response for fetching citations for the document.
         * @summary Fetch references for a particular document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {Array<string>} [citationIds] 
         * @param {string} [searchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCitationsV1(workspaceId: string, documentId: string, citationIds?: Array<string>, searchFilter?: string, options?: any): AxiosPromise<GetCitationsResponse> {
            return localVarFp.getAllCitationsV1(workspaceId, documentId, citationIds, searchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch list of all custom dictionaries for a workspace  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetCustomDictionaryListResponse: response of fetching all dictionaries
         * @summary Fetch list of all custom dictionaries for a workspace
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDictionaryListV1(workspaceId: string, options?: any): AxiosPromise<GetCustomDictionaryListResponse> {
            return localVarFp.getAllDictionaryListV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch list of all custom dictionaries for a workspace (paginated)  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetCustomDictionaryPaginatedResponse: paginated response of fetching all dictionaries
         * @summary Fetch list of all custom dictionaries for a workspace (paginated)
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDictionaryListV2(workspaceId: string, start: number, end: number, ts?: string, options?: any): AxiosPromise<GetCustomDictionaryPaginatedResponse> {
            return localVarFp.getAllDictionaryListV2(workspaceId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the analysis status of the page  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page id inside the pdf for which parsed segments are to be fetched  Returns:     AnalysePageStatusResponse: status of the analysis
         * @summary Get the analysis status of the page
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyseStatusV1(workspaceId: string, documentId: string, pageId: number, options?: any): AxiosPromise<AnalysePageStatusResponse> {
            return localVarFp.getAnalyseStatusV1(workspaceId, documentId, pageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of documents registered with the app  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     app_id: app id for which documents are to be fetched  Returns:     GetAppDocumentsListResponse: list of documents registered with the app
         * @summary Get list of documents registered with the app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDocumentsListV1(workspaceId: string, appId: AppIdType, options?: any): AxiosPromise<GetAppDocumentsListResponse> {
            return localVarFp.getAppDocumentsListV1(workspaceId, appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of documents registered with the app  Args:     project_id: project id for which documents are to be fetched     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     app_id: app id for which documents are to be fetched  Returns:     GetAppDocumentsListResponse: list of documents registered with the app
         * @summary Get list of documents registered with the app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDocumentsListV2(workspaceId: string, appId: AppIdType, projectId: string, options?: any): AxiosPromise<GetAppDocumentsListResponse> {
            return localVarFp.getAppDocumentsListV2(workspaceId, appId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch documents uploaded by user (paginated)
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDocumentsListV3(workspaceId: string, appId: AppIdType, projectId: string, start: number, end: number, ts?: string, options?: any): AxiosPromise<GetPaginatedAppDocumentsListResponse> {
            return localVarFp.getAppDocumentsListV3(workspaceId, appId, projectId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list Get project metadata for the associated project_id  Args:     project_id: project id for which documents are to be fetched     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     app_id: app id for which documents are to be fetched  Returns:     GenAIProjectMetadataResponse: Project metadata for the associated project.
         * @summary Get project metadata for the associated project_id
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppProjectMetadataV1(workspaceId: string, appId: AppIdType, projectId: string, options?: any): AxiosPromise<GenAIProjectMetadataResponse> {
            return localVarFp.getAppProjectMetadataV1(workspaceId, appId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get citation models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitationModelsV1(options?: any): AxiosPromise<CitationModelsResponse> {
            return localVarFp.getCitationModelsV1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the result of Plagiarism check
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentPlagiarismResultV1(workspaceId: string, documentId: string, pageId: number, options?: any): AxiosPromise<CheckContentPlagiarismResponse> {
            return localVarFp.getContentPlagiarismResultV1(workspaceId, documentId, pageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all word list for a given dictionary_id  Args:     workspace_id (str): workspace id that uniquely identifies the customer     dictionary_id: dictionary for which the words are to be fetched     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetCustomDictionaryWordsListResponse: response of fetching all words in dictionary
         * @summary Fetch all word list for a given dictionary_id
         * @param {string} workspaceId 
         * @param {string} dictionaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionaryWordsListV1(workspaceId: string, dictionaryId: string, options?: any): AxiosPromise<GetCustomDictionaryWordsListResponse> {
            return localVarFp.getDictionaryWordsListV1(workspaceId, dictionaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch document download status, if completed, also return the signed URL, if failed, return an error message.
         * @summary Get document download status response
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} documentId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentDownloadStatusV1(workspaceId: string, appId: AppIdType, documentId: string, taskId: string, options?: any): AxiosPromise<GetDocumentDownloadStatusResponse> {
            return localVarFp.getDocumentDownloadStatusV1(workspaceId, appId, documentId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get document download response
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} documentId 
         * @param {DocumentDownloadFormats} downloadFormat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentDownloadV1(workspaceId: string, appId: AppIdType, documentId: string, downloadFormat: DocumentDownloadFormats, options?: any): AxiosPromise<any> {
            return localVarFp.getDocumentDownloadV1(workspaceId, appId, documentId, downloadFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Get document metadata which contains s3 presigned url  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
         * @summary Get document metadata response
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentMetadataV1(workspaceId: string, documentId: string, options?: any): AxiosPromise<GetDocumentMetadataResponse> {
            return localVarFp.getDocumentMetadataV1(workspaceId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the parsing status of the whole document uploaded  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     DocumentParsingStatusResponse: status of the parsing
         * @summary Get the parsing status of the whole document uploaded
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentParsingStatusV1(workspaceId: string, documentId: string, appId: string, options?: any): AxiosPromise<DocumentParsingStatusResponse> {
            return localVarFp.getDocumentParsingStatusV1(workspaceId, documentId, appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch document stats like lexical diversity, word count, sentence count, etc. for the given input
         * @summary Get document stats response for the given input text
         * @param {AppIdType} appId 
         * @param {string} workspaceId 
         * @param {GetDocumentStatsForInputTextRequest} getDocumentStatsForInputTextRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentStatsForTextSelectionV1(appId: AppIdType, workspaceId: string, getDocumentStatsForInputTextRequest: GetDocumentStatsForInputTextRequest, options?: any): AxiosPromise<TextAnalysisMetrics> {
            return localVarFp.getDocumentStatsForTextSelectionV1(appId, workspaceId, getDocumentStatsForInputTextRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch document stats like lexical diversity, word count, sentence count, etc.
         * @summary Get document stats response
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentStatsV1(workspaceId: string, appId: AppIdType, documentId: string, options?: any): AxiosPromise<TextAnalysisMetrics> {
            return localVarFp.getDocumentStatsV1(workspaceId, appId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the summary of the document  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
         * @summary Get the summary of the document
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentSummaryV1(workspaceId: string, documentId: string, options?: any): AxiosPromise<GetDocumentSummaryResponse> {
            return localVarFp.getDocumentSummaryV1(workspaceId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch the signed S3 URL to start download (for the latest available version) message.
         * @summary Fetch the signed URL of the latest version
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestDocumentDownloadV1(workspaceId: string, appId: AppIdType, documentId: string, options?: any): AxiosPromise<GetDocumentDownloadStatusResponse> {
            return localVarFp.getLatestDocumentDownloadV1(workspaceId, appId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get analysis status for a page
         * @param {string} workspaceId 
         * @param {number} pageId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageAnalysisStatusV1(workspaceId: string, pageId: number, documentId: string, options?: any): AxiosPromise<SuggestionAnalysisStatusResponse> {
            return localVarFp.getPageAnalysisStatusV1(workspaceId, pageId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the summary of the document  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page id for which summary needs to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
         * @summary Get page summary of the document
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageSummaryV1(workspaceId: string, documentId: string, pageId: number, options?: any): AxiosPromise<GetPageSummaryResponse> {
            return localVarFp.getPageSummaryV1(workspaceId, documentId, pageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch paginated style guide configs for a workspace  Args:     workspace_id (str): workspace id that uniquely identifies the customer     start (int): pagination start index     end (int): pagination end index     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (str, optional): timestamp for pagination, defaults to \"\"     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetStyleGuideConfigPaginatedResponse: paginated response of style guide configs
         * @summary Fetch paginated style guide configs for a workspace
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedStyleGuideConfigV1(workspaceId: string, start: number, end: number, ts?: string, options?: any): AxiosPromise<GetStyleGuideConfigPaginatedResponse> {
            return localVarFp.getPaginatedStyleGuideConfigV1(workspaceId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * Get parsed page in pdf  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed page is to be fetched     page_id: page id inside the pdf     background_tasks:  Returns:     GetParsedSegmentsResponse: list of parsed segments
         * @summary Get parsed page response
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParsedSegmentsV1(workspaceId: string, documentId: string, pageId: number, options?: any): AxiosPromise<GetParsedPageResponse> {
            return localVarFp.getParsedSegmentsV1(workspaceId, documentId, pageId, options).then((request) => request(axios, basePath));
        },
        /**
         * V2 version of get parsed page response api which is used for RTC in copy edit app  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed page is to be fetched     page_id: page id inside the pdf  Returns:     GetParsedSegmentsResponse: list of parsed segments
         * @summary Get parsed page response
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParsedSegmentsV2(workspaceId: string, documentId: string, pageId: number, options?: any): AxiosPromise<GetParsedPageResponseV2> {
            return localVarFp.getParsedSegmentsV2(workspaceId, documentId, pageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project_id for the associated document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectFromDocumentV1(workspaceId: string, documentId: string, options?: any): AxiosPromise<GetProjectFromDocumentResponse> {
            return localVarFp.getProjectFromDocumentV1(workspaceId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get rule set file upload status
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRuleSetFileUploadStatusV1(workspaceId: string, ruleSetId: string, options?: any): AxiosPromise<RuleSetUploadResponse> {
            return localVarFp.getRuleSetFileUploadStatusV1(workspaceId, ruleSetId, options).then((request) => request(axios, basePath));
        },
        /**
         * ``` Invalidate the cache for copy edit analysis Args:     request (Request): FastAPI Request object. This is required by the decorator to extract header information     response (Response): FastAPI Response object. This is required by the decorator to set status     auth: authentication  Returns: ```
         * @summary Invalidate Copy Edit Analysis Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateCopyEditAnalysisCache(options?: any): AxiosPromise<any> {
            return localVarFp.invalidateCopyEditAnalysisCache(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invalidate suggestions across documents
         * @param {string} workspaceId 
         * @param {string} suggestionId ID of the suggestion to invalidate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateSuggestionsV1(workspaceId: string, suggestionId: string, options?: any): AxiosPromise<AnalysePageResponse> {
            return localVarFp.invalidateSuggestionsV1(workspaceId, suggestionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Invite users to a project Args:     workspace_id: workspace id     app_id: app id     project_id: project id     request: request body     user: user object     background_tasks: to push analytics to bangalore service  Returns: InviteProjectResponse
         * @summary Invite users to a project
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {InviteGenAIProjectRequest} inviteGenAIProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteGenAIProjectV1(workspaceId: string, appId: AppIdType, projectId: string, inviteGenAIProjectRequest: InviteGenAIProjectRequest, options?: any): AxiosPromise<InviteGenAIProjectResponse> {
            return localVarFp.inviteGenAIProjectV1(workspaceId, appId, projectId, inviteGenAIProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Migrate suggestions rules from one workspace to another Args:     workspace_id: workspace id     query: query to filter documents in the pagesuggestions     user: user object  Returns: None
         * @summary Migrate suggestions rules from one workspace to another
         * @param {string} workspaceId 
         * @param {BodyMigrateSuggestionsRulesV1} bodyMigrateSuggestionsRulesV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateSuggestionsRulesV1(workspaceId: string, bodyMigrateSuggestionsRulesV1: BodyMigrateSuggestionsRulesV1, options?: any): AxiosPromise<any> {
            return localVarFp.migrateSuggestionsRulesV1(workspaceId, bodyMigrateSuggestionsRulesV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Parse the input citation text and add the entities present in the citation text into the DB
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {BodyParseAndAddBulkCitationTextV1} bodyParseAndAddBulkCitationTextV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseAndAddBulkCitationTextV1(workspaceId: string, documentId: string, bodyParseAndAddBulkCitationTextV1: BodyParseAndAddBulkCitationTextV1, options?: any): AxiosPromise<GetCitationsResponse> {
            return localVarFp.parseAndAddBulkCitationTextV1(workspaceId, documentId, bodyParseAndAddBulkCitationTextV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Parse the input citation text and return the entities present in the citation text
         * @param {string} workspaceId 
         * @param {BodyParseCitationTextV1} bodyParseCitationTextV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseCitationTextV1(workspaceId: string, bodyParseCitationTextV1: BodyParseCitationTextV1, options?: any): AxiosPromise<CopyEditCitation> {
            return localVarFp.parseCitationTextV1(workspaceId, bodyParseCitationTextV1, options).then((request) => request(axios, basePath));
        },
        /**
         * Webhook to receive the result of the Plagiarism check  Args:     request (Request): FastAPI Request object     response (Response): FastAPI Response object  Returns:     None
         * @summary Webhook to receive the result of the Plagiarism check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receivePlagiarismCheckResult(options?: any): AxiosPromise<any> {
            return localVarFp.receivePlagiarismCheckResult(options).then((request) => request(axios, basePath));
        },
        /**
         * Register the document with the app  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     app_id: app id for which document is to be registered     background_tasks (BackgroundTasks): FastAPI BackgroundTasks object  Returns:     RegisterAppDocumentResponse: response of the registration
         * @summary Register the document with the app
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {AppIdType} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAppDocumentV1(workspaceId: string, documentId: string, appId: AppIdType, options?: any): AxiosPromise<RegisterAppDocumentResponse> {
            return localVarFp.registerAppDocumentV1(workspaceId, documentId, appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Register the document with the app v2  Args:     style_guides: ADA or CMOS     custom_style_rules: Custom style guide     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     app_id: app id for which document is to be registered     background_tasks (BackgroundTasks): FastAPI BackgroundTasks object  Returns:     RegisterAppDocumentResponse: response of the registration
         * @summary Register the document with the app
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {AppIdType} appId 
         * @param {BodyRegisterAppDocumentV2} [bodyRegisterAppDocumentV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAppDocumentV2(workspaceId: string, documentId: string, appId: AppIdType, bodyRegisterAppDocumentV2?: BodyRegisterAppDocumentV2, options?: any): AxiosPromise<RegisterAppDocumentResponse> {
            return localVarFp.registerAppDocumentV2(workspaceId, documentId, appId, bodyRegisterAppDocumentV2, options).then((request) => request(axios, basePath));
        },
        /**
         * Register the document with the app v2  Args:     project_id: project where the document is associated with     style_guides: ADA or CMOS     custom_style_rules: Custom style guide     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     app_id: app id for which document is to be registered     custom_dictionary_ids (List[str]): The list of ids of dictionaries to be applied on this document     background_tasks (BackgroundTasks): FastAPI BackgroundTasks object  Returns:     RegisterAppDocumentResponse: response of the registration
         * @summary Register the document with the app
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {string} projectId 
         * @param {AppIdType} appId 
         * @param {BodyRegisterAppDocumentV3} [bodyRegisterAppDocumentV3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAppDocumentV3(workspaceId: string, documentId: string, projectId: string, appId: AppIdType, bodyRegisterAppDocumentV3?: BodyRegisterAppDocumentV3, options?: any): AxiosPromise<RegisterAppDocumentResponse> {
            return localVarFp.registerAppDocumentV3(workspaceId, documentId, projectId, appId, bodyRegisterAppDocumentV3, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove user from a project Args:     workspace_id: workspace id     app_id: app id     project_id: project id     service_request: request body     user: user object  Returns: RemoveInviteProjectResponse
         * @summary Remove user from a project
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {RemoveInviteGenAIProjectRequest} removeInviteGenAIProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromGenAIProjectV1(workspaceId: string, appId: AppIdType, projectId: string, removeInviteGenAIProjectRequest: RemoveInviteGenAIProjectRequest, options?: any): AxiosPromise<RemoveInviteGenAIProjectResponse> {
            return localVarFp.removeUserFromGenAIProjectV1(workspaceId, appId, projectId, removeInviteGenAIProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove workspace sharing for a resource
         * @param {string} workspaceId 
         * @param {BodyRemoveWorkspaceShareResourceV1} bodyRemoveWorkspaceShareResourceV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWorkspaceShareResourceV1(workspaceId: string, bodyRemoveWorkspaceShareResourceV1: BodyRemoveWorkspaceShareResourceV1, options?: any): AxiosPromise<ShareResourceResponse> {
            return localVarFp.removeWorkspaceShareResourceV1(workspaceId, bodyRemoveWorkspaceShareResourceV1, options).then((request) => request(axios, basePath));
        },
        /**
         * Will set the pointers for all the pages in a doc to their last milestone saved page_versions  Args:     workspace_id (str): workspace id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     milestone_id: milestone_id for which we the document to get restored for  Returns:     RestoreMilestoneResponse
         * @summary Get number of milestones of a document we currently have
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} milestoneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreMilestoneForDocV1(workspaceId: string, documentId: string, milestoneId: number, options?: any): AxiosPromise<RestoreMilestoneResponse> {
            return localVarFp.restoreMilestoneForDocV1(workspaceId, documentId, milestoneId, options).then((request) => request(axios, basePath));
        },
        /**
         * Restores the current state of page to a page version provided, creating a new version maintaining linear history  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     page_id: concerned page     page_version: page version we want the current page to move to     background_tasks: to run push analytics call Returns:     RestorePageResponse
         * @summary Restores the current state of page to a page version provided
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {number} pageVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreVersionForDocV1(workspaceId: string, documentId: string, pageId: number, pageVersion: number, options?: any): AxiosPromise<RestorePageResponse> {
            return localVarFp.restoreVersionForDocV1(workspaceId, documentId, pageId, pageVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * Will save the content for the current version and create a new version based on a set timelimit  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page_id for which the content has to be saved.     service_request: Request body for creating a version     background_tasks:  Returns:     SaveContentResponse: success status for saving content for a version
         * @summary Saving the actual changed content, creating and updating pointers for a session
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {SaveContentRequestBody} saveContentRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveContentForSessionV1(workspaceId: string, documentId: string, pageId: number, saveContentRequestBody: SaveContentRequestBody, options?: any): AxiosPromise<SaveContentResponse> {
            return localVarFp.saveContentForSessionV1(workspaceId, documentId, pageId, saveContentRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the summary of the document  Args:     binary_formatted_document_summary: save snippet summary content in binary format for RTC support     formatted_document_summary: this will save the summary content as snippet formatted content     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
         * @summary Save formatted snippet content which contains the summary of the document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {BodySaveDocumentSummaryV1} [bodySaveDocumentSummaryV1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDocumentSummaryV1(workspaceId: string, documentId: string, bodySaveDocumentSummaryV1?: BodySaveDocumentSummaryV1, options?: any): AxiosPromise<GetDocumentSummaryResponse> {
            return localVarFp.saveDocumentSummaryV1(workspaceId, documentId, bodySaveDocumentSummaryV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Share a resource with the entire workspace
         * @param {string} workspaceId 
         * @param {BodyShareResourceWithWorkspaceV1} bodyShareResourceWithWorkspaceV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareResourceWithWorkspaceV1(workspaceId: string, bodyShareResourceWithWorkspaceV1: BodyShareResourceWithWorkspaceV1, options?: any): AxiosPromise<ShareResourceResponse> {
            return localVarFp.shareResourceWithWorkspaceV1(workspaceId, bodyShareResourceWithWorkspaceV1, options).then((request) => request(axios, basePath));
        },
        /**
         * Stream LLM response with optimized performance
         * @summary Stream LLM response
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamLLMResponse(query: string, options?: any): AxiosPromise<any> {
            return localVarFp.streamLLMResponse(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger asynchronous document download
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} documentId 
         * @param {DocumentDownloadFormats} downloadFormat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerAsyncDocumentDownloadV1(workspaceId: string, appId: AppIdType, documentId: string, downloadFormat: DocumentDownloadFormats, options?: any): AxiosPromise<TriggerAsyncDownloadResponse> {
            return localVarFp.triggerAsyncDocumentDownloadV1(workspaceId, appId, documentId, downloadFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger re-analyze on document metadata change.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerReAnalyzeTextsV1(workspaceId: string, documentId: string, options?: any): AxiosPromise<AnalysePageResponse> {
            return localVarFp.triggerReAnalyzeTextsV1(workspaceId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the analysis status of the page  Args:     status_change_request: update the current state of analyse page to this state     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page id inside the pdf for which parsed segments are to be fetched  Returns:     AnalysePageStatusResponse: status of the analysis
         * @summary Update the analysis status of the page
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {AnalysePageStatusResponse} analysePageStatusResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnalyseStatusV1(workspaceId: string, documentId: string, pageId: number, analysePageStatusResponse: AnalysePageStatusResponse, options?: any): AxiosPromise<AnalysePageStatusResponse> {
            return localVarFp.updateAnalyseStatusV1(workspaceId, documentId, pageId, analysePageStatusResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update and analyse text resources for a text in add-in
         * @param {string} workspaceId 
         * @param {UpdateAnalyseAddInRequest} updateAnalyseAddInRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAndAnalyseV1(workspaceId: string, updateAnalyseAddInRequest: UpdateAnalyseAddInRequest, options?: any): AxiosPromise<AnalysePageResponse> {
            return localVarFp.updateAndAnalyseV1(workspaceId, updateAnalyseAddInRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the document metadata for copy edit  Args:     workspace_id (str): workspace id that uniquely identifies the customer     document_id: document id for which parsed segments are to be fetched     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     UpdateAppDocumentResponse: response of the registration
         * @summary Update the document stylesheet metadata for copy edit
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppDocumentStylesheetMetadataV1(workspaceId: string, documentId: string, options?: any): AxiosPromise<UpdateAppDocumentResponse> {
            return localVarFp.updateAppDocumentStylesheetMetadataV1(workspaceId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the citation style in document metadata
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {UpdateCitationStyleRequest} updateCitationStyleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCitationStyleRequestV1(workspaceId: string, documentId: string, updateCitationStyleRequest: UpdateCitationStyleRequest, options?: any): AxiosPromise<UpdateCitationStyleResponse> {
            return localVarFp.updateCitationStyleRequestV1(workspaceId, documentId, updateCitationStyleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the references for a particular page.  Args:     page_id: page_id to update references.     update_request: references which need to be updated for a particular document.     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     UpdateCitationsResponse: response for updating references for the document.
         * @summary Update citations for a particular document.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {UpdateCitationsRequest} updateCitationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCitationsV1(workspaceId: string, documentId: string, pageId: number, updateCitationsRequest: UpdateCitationsRequest, options?: any): AxiosPromise<UpdateCitationsResponse> {
            return localVarFp.updateCitationsV1(workspaceId, documentId, pageId, updateCitationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the document metadata for copy edit documents
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {UpdateDocumentMetadataRequest} updateDocumentMetadataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentMetadataV1(workspaceId: string, documentId: string, updateDocumentMetadataRequest: UpdateDocumentMetadataRequest, options?: any): AxiosPromise<UpdateDocumentMetadataResponse> {
            return localVarFp.updateDocumentMetadataV1(workspaceId, documentId, updateDocumentMetadataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a project for the Gen AI app Args:     project_id:     workspace_id: workspace id     app_id: app id     request: request body     user: user object     background_tasks:  Returns: GenAIProjectMetadataResponse
         * @summary Create or update a project for the Gen AI app
         * @param {string} workspaceId 
         * @param {AppIdType} appId 
         * @param {string} projectId 
         * @param {UpdateGenAIProjectRequest} updateGenAIProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGenAIProjectV1(workspaceId: string, appId: AppIdType, projectId: string, updateGenAIProjectRequest: UpdateGenAIProjectRequest, options?: any): AxiosPromise<UpdateGenAIProjectResponse> {
            return localVarFp.updateGenAIProjectV1(workspaceId, appId, projectId, updateGenAIProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the segments with the suggested changes Args:     user: auth check     workspace_id:     document_id:     page_id:     update_request:     request:     response:  Returns:
         * @summary Update page content with the latest version of formatted page content
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {UpdateSegmentRequest} updateSegmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePageContentV1(workspaceId: string, documentId: string, pageId: number, updateSegmentRequest: UpdateSegmentRequest, options?: any): AxiosPromise<UpdatePageContentResponse> {
            return localVarFp.updatePageContentV1(workspaceId, documentId, pageId, updateSegmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * V2 Update the segments with the suggested changes for supporting RTC changes on copy edit app Args:     workspace_id:     document_id:     page_id:     update_request:     background_tasks:     request:     response:     user:  Returns:
         * @summary Update page content with the latest version of formatted page content
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {number} pageId 
         * @param {UpdateSegmentRequestV2} updateSegmentRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePageContentV2(workspaceId: string, documentId: string, pageId: number, updateSegmentRequestV2: UpdateSegmentRequestV2, options?: any): AxiosPromise<UpdatePageContentResponse> {
            return localVarFp.updatePageContentV2(workspaceId, documentId, pageId, updateSegmentRequestV2, options).then((request) => request(axios, basePath));
        },
        /**
         * This will be called when we accept suggestions in our copy edit app. These suggestions are from open ai model. After accepting the changes we will create a new entry in the Segments Table for the pdf
         * @summary Create a new segment with the latest version id with the suggested changes by openai.
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {string} pageId 
         * @param {string} chunkId 
         * @param {UpdateSegmentRequest} updateSegmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSegmentV1(workspaceId: string, documentId: string, pageId: string, chunkId: string, updateSegmentRequest: UpdateSegmentRequest, options?: any): AxiosPromise<UpdateSegmentResponse> {
            return localVarFp.updateSegmentV1(workspaceId, documentId, pageId, chunkId, updateSegmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a specific style guide configuration record for a workspace.  Args:     workspace_id (str): The ID of the workspace where the configuration exists.     record_id (str): The specific record to update.     config_updates (Dict[str, Any]): Fields to update in the configuration.     request (Request): FastAPI Request object.     response (Response): FastAPI Response object.     user (WorkspaceUser, optional): Authenticated user performing the update.  Returns:     Dict[str, Any]: Response from Montreal service after updating the record.
         * @summary Update style guide config for a workspace
         * @param {string} workspaceId 
         * @param {string} recordId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStyleGuideConfigV1(workspaceId: string, recordId: string, body: object, options?: any): AxiosPromise<any> {
            return localVarFp.updateStyleGuideConfigV1(workspaceId, recordId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update suggestion status for a document
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {UpdatePageSuggestions} updatePageSuggestions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSuggestionStatusV1(workspaceId: string, documentId: string, updatePageSuggestions: UpdatePageSuggestions, options?: any): AxiosPromise<AnalysePageResponse> {
            return localVarFp.updateSuggestionStatusV1(workspaceId, documentId, updatePageSuggestions, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload manual text file for copy edit / summarization analysis  Args:     project_id: project id for which document is to be registered     background_tasks: to trigger the summarization/copy edit analysis flow in the background process     upload_request: upload text file to be analysed     workspace_id (str): customer id that uniquely identifies the customer     app_id: app id for which document is to be registered     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     UploadManualTextFileResponse: response of the text file registration
         * @summary Upload manual text file for copy-edit or summarization analysis
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {AppIdType} appId 
         * @param {UploadManualTextFileRequest} uploadManualTextFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadManualTextFileRequestV1(workspaceId: string, projectId: string, appId: AppIdType, uploadManualTextFileRequest: UploadManualTextFileRequest, options?: any): AxiosPromise<UploadManualTextFileResponse> {
            return localVarFp.uploadManualTextFileRequestV1(workspaceId, projectId, appId, uploadManualTextFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload rule set
         * @param {string} workspaceId 
         * @param {string} ruleSetId 
         * @param {UploadRuleSetRequest} uploadRuleSetRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        uploadRuleSetV1(workspaceId: string, ruleSetId: string, uploadRuleSetRequest: UploadRuleSetRequest, options?: any): AxiosPromise<RuleSetUploadResponse> {
            return localVarFp.uploadRuleSetV1(workspaceId, ruleSetId, uploadRuleSetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppStoreApi - object-oriented interface
 * @export
 * @class AppStoreApi
 * @extends {BaseAPI}
 */
export class AppStoreApi extends BaseAPI {
    /**
     * Add or update a custom style guide rule set for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     service_request: Request body for adding a style guide rule set     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     AddUpdateStyleGuideRuleSetResponse: response of adding a style guide rule set
     * @summary Add or update a custom style guide rule set for a document
     * @param {string} workspaceId 
     * @param {AddUpdateStyleGuideRuleSetRequest} addUpdateStyleGuideRuleSetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public addUpdateStyleGuideRuleSetV1(workspaceId: string, addUpdateStyleGuideRuleSetRequest: AddUpdateStyleGuideRuleSetRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).addUpdateStyleGuideRuleSetV1(workspaceId, addUpdateStyleGuideRuleSetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a custom style guide rule for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     rule_set_id: rule set to which the rule is to be added     service_request: Request body for adding a style guide rule     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     AddUpdateStyleGuideRuleResponse: response of adding a style guide rule
     * @summary Add a custom style guide rule for a document
     * @param {string} workspaceId 
     * @param {string} ruleSetId 
     * @param {AddStyleGuideRuleRequest} addStyleGuideRuleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public addUpdateStyleGuideRuleV1(workspaceId: string, ruleSetId: string, addStyleGuideRuleRequest: AddStyleGuideRuleRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).addUpdateStyleGuideRuleV1(workspaceId, ruleSetId, addStyleGuideRuleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add or update a custom style guide rule set for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     service_request: Request body for adding words to custom dictionary     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     AddWordsToCustomDictionaryResponse: response of add words to the custom dictionary
     * @summary Add words to the given custom dictionary
     * @param {string} workspaceId 
     * @param {AddWordsToCustomDictionaryRequest} addWordsToCustomDictionaryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public addWordsToCustomDictionaryV1(workspaceId: string, addWordsToCustomDictionaryRequest: AddWordsToCustomDictionaryRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).addWordsToCustomDictionaryV1(workspaceId, addWordsToCustomDictionaryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * each page in the pdf is parsed into segments and stored in the order in which they occur in the pdf
     * @summary start file analysis and parse all the segments in the page to be used for copy edit, this analysis is requested for one or more pages
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {AnalyseFileRequest} analyseFileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public analyseFileV1(workspaceId: string, documentId: string, analyseFileRequest: AnalyseFileRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).analyseFileV1(workspaceId, documentId, analyseFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches the suitable analytics
     * @summary Fetches analytics of the copy edit application based on the filters
     * @param {string} workspaceId 
     * @param {string} appId 
     * @param {number} [days] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [projectIds] 
     * @param {Array<string>} [documentIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public analyticsCopyEditFiltersV1(workspaceId: string, appId: string, days?: number, userIds?: Array<string>, projectIds?: Array<string>, documentIds?: Array<string>, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).analyticsCopyEditFiltersV1(workspaceId, appId, days, userIds, projectIds, documentIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches user count, unique daily active users, documents improved, writing sessions,    suggestions generated
     * @summary Fetches analytics of the copy edit application
     * @param {string} workspaceId 
     * @param {string} appId 
     * @param {AppAnalyticsRequest} appAnalyticsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public analyticsCopyEditV1(workspaceId: string, appId: string, appAnalyticsRequest: AppAnalyticsRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).analyticsCopyEditV1(workspaceId, appId, appAnalyticsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analyze text resources for a page
     * @param {string} workspaceId 
     * @param {number} pageId 
     * @param {string} documentId 
     * @param {PageTextResources} pageTextResources 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public analyzeTextsV1(workspaceId: string, pageId: number, documentId: string, pageTextResources: PageTextResources, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).analyzeTextsV1(workspaceId, pageId, documentId, pageTextResources, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Attach dictionaries to an existing. These will be used when document is re-analyzed
     * @summary Attach dictionaries to an existing document
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {AttachCustomDictionariesToDocumentRequest} attachCustomDictionariesToDocumentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public attachDictionariesToDocumentV1(workspaceId: string, documentId: string, attachCustomDictionariesToDocumentRequest: AttachCustomDictionariesToDocumentRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).attachDictionariesToDocumentV1(workspaceId, documentId, attachCustomDictionariesToDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bulk upload rules for a rule set
     * @param {string} workspaceId 
     * @param {string} ruleSetId 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public bulkUploadRulesV1(workspaceId: string, ruleSetId: string, file?: any, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).bulkUploadRulesV1(workspaceId, ruleSetId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Async call to check Plagiarism of the content provided
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} pageId 
     * @param {CheckContentPlagiarismRequestModel} checkContentPlagiarismRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public checkContentPlagiarismV1(workspaceId: string, documentId: string, pageId: number, checkContentPlagiarismRequestModel: CheckContentPlagiarismRequestModel, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).checkContentPlagiarismV1(workspaceId, documentId, pageId, checkContentPlagiarismRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a pre-signed s3 url for uploading document assets for copy edit app.
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {DocumentUploadRequest} documentUploadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public createDocumentAssetsUploadUrlV1(workspaceId: string, appId: AppIdType, documentUploadRequest: DocumentUploadRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).createDocumentAssetsUploadUrlV1(workspaceId, appId, documentUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a citation for a page
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {CopyEditCitation} copyEditCitation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public createDocumentCitationV1(workspaceId: string, documentId: string, copyEditCitation: CopyEditCitation, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).createDocumentCitationV1(workspaceId, documentId, copyEditCitation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a project for the gen ai app Args:     workspace_id: workspace id     app_id: app id     request: request body     user: user object     background_tasks: to push analytics to bangalore service  Returns: CreateProjectResponse
     * @summary Create a project for the gen ai app
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {CreateGenAIProjectRequest} createGenAIProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public createGenAIProjectV1(workspaceId: string, appId: AppIdType, createGenAIProjectRequest: CreateGenAIProjectRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).createGenAIProjectV1(workspaceId, appId, createGenAIProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Will set page_ids with their latest page_versions against a particular milestone_id  Args:     workspace_id (str): workspace id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     CreateMilestoneResponse
     * @summary Save the latest page_versions of all the pages against the latest generated milestone
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public createMilestoneV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).createMilestoneV1(workspaceId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a custom dictionary for a workspace that can be applied to a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     service_request: Request body for creating a custom dictionary     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     CreateCustomDictionaryResponse: response of creating a custom dictionary
     * @summary Create a custom dictionary for a document
     * @param {string} workspaceId 
     * @param {CreateOrUpdateCustomDictionaryRequest} createOrUpdateCustomDictionaryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public createOrUpdateCustomDictionaryV1(workspaceId: string, createOrUpdateCustomDictionaryRequest: CreateOrUpdateCustomDictionaryRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).createOrUpdateCustomDictionaryV1(workspaceId, createOrUpdateCustomDictionaryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create upload url for rule set
     * @param {string} workspaceId 
     * @param {string} ruleSetId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public createUploadURLForRuleSetV1(workspaceId: string, ruleSetId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).createUploadURLForRuleSetV1(workspaceId, ruleSetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete citations for a particular document.
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public deleteCitationsV1(workspaceId: string, documentId: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).deleteCitationsV1(workspaceId, documentId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a custom dictionary from a workspace  Args:    workspace_id (str): workspace id that uniquely identifies the customer    dictionary_id (str): dictionary id that uniquely identifies the dictionary    request (Request): FastAPI Request object    response (Response): FastAPI Response object    user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:    CreateCustomDictionaryResponse: response of creating a custom dictionary
     * @summary Delete a custom dictionary from the document
     * @param {string} workspaceId 
     * @param {string} dictionaryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public deleteCustomDictionaryV1(workspaceId: string, dictionaryId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).deleteCustomDictionaryV1(workspaceId, dictionaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a custom dictionary from a workspace  Args:    workspace_id (str): workspace id that uniquely identifies the customer    dictionary_id (str): dictionary id that uniquely identifies the dictionary    dictionary_word_list: List of words that need to be deleted    request (Request): FastAPI Request object    response (Response): FastAPI Response object    user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:    CreateCustomDictionaryResponse: response of creating a custom dictionary
     * @summary Delete a custom dictionary word from the dictionary
     * @param {string} workspaceId 
     * @param {string} dictionaryId 
     * @param {DeleteWordsFromCustomDictionaryRequest} deleteWordsFromCustomDictionaryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public deleteCustomDictionaryWordV1(workspaceId: string, dictionaryId: string, deleteWordsFromCustomDictionaryRequest: DeleteWordsFromCustomDictionaryRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).deleteCustomDictionaryWordV1(workspaceId, dictionaryId, deleteWordsFromCustomDictionaryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the document and all its related data
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public deleteDocumentV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).deleteDocumentV1(workspaceId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a project for the Gen AI app Args:     project_id: project id for the project     workspace_id: workspace id     app_id: app id     user: user object     background_tasks: to push analytics to bangalore service  Returns: CreateProjectResponse
     * @summary Delete a project for the Gen AI app
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public deleteGenAIProjectV1(workspaceId: string, appId: AppIdType, projectId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).deleteGenAIProjectV1(workspaceId, appId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete suggestions for a page
     * @param {string} workspaceId 
     * @param {number} pageId 
     * @param {string} documentId 
     * @param {Array<string>} [resourceIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public deletePageSuggestionsV1(workspaceId: string, pageId: number, documentId: string, resourceIds?: Array<string>, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).deletePageSuggestionsV1(workspaceId, pageId, documentId, resourceIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a custom style guide rule set for a document  Args:     rule_set_id: Rule set to be deleted     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     DeleteStyleGuideRuleSetResponse: response of deleting a style guide rule set
     * @summary Delete a custom style guide rule set for a document
     * @param {string} workspaceId 
     * @param {string} ruleSetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public deleteStyleGuideRuleSetV1(workspaceId: string, ruleSetId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).deleteStyleGuideRuleSetV1(workspaceId, ruleSetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a custom style guide rule for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     rule_id: rule to be deleted     rule_set_id: rule set from which the rule is to be deleted     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     DeleteStyleGuideRuleResponse: response of deleting a style guide rule
     * @summary Delete a custom style guide rule for a document
     * @param {string} workspaceId 
     * @param {string} ruleSetId 
     * @param {string} ruleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public deleteStyleGuideRuleV1(workspaceId: string, ruleSetId: string, ruleId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).deleteStyleGuideRuleV1(workspaceId, ruleSetId, ruleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ask questions to a given document (currently only PDF) and get their result  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     service_request: Request body for qna with pdf  Returns:     QnaWithPdfResponse: response of the doing qna with pdf
     * @summary Get Results of questions asked to a pdf
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {QnaWithPdfRequestBody} qnaWithPdfRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public doQNAWithPdfV1(workspaceId: string, documentId: string, qnaWithPdfRequestBody: QnaWithPdfRequestBody, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).doQNAWithPdfV1(workspaceId, documentId, qnaWithPdfRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch documents uploaded by user
     * @param {string} workspaceId 
     * @param {Array<string>} [userIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public fetchDocumentsV1(workspaceId: string, userIds?: Array<string>, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).fetchDocumentsV1(workspaceId, userIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch projects for the gen ai app Args:     workspace_id: workspace id     app_id: app id     user: user object  Returns: FetchProjectsResponse
     * @summary Fetch projects for the gen ai app
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public fetchGenAIProjectsV1(workspaceId: string, appId: AppIdType, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).fetchGenAIProjectsV1(workspaceId, appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch projects for the gen ai app (paginated) Args:     workspace_id: workspace id     app_id: app id     user: user object  Returns: FetchProjectsResponse
     * @summary Fetch projects for the gen ai app (paginated)
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public fetchGenAIProjectsV2(workspaceId: string, appId: AppIdType, start: number, end: number, ts?: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).fetchGenAIProjectsV2(workspaceId, appId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Will return with page_ids and their page_versions for the given milestone  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     milestone_id: milestone_id for which the whole document will be fetched  Returns:     FetchMilestoneResponse
     * @summary Get the stored page content for each page for a given milestone_id
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} milestoneId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public fetchMilestoneV1(workspaceId: string, documentId: string, milestoneId: number, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).fetchMilestoneV1(workspaceId, documentId, milestoneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Will return with a number of milestones that a particular doc has  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made  Returns:     FetchNumberOfMilestonesForDocResponse
     * @summary Get number of milestones of a document we currently have
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public fetchNumOfMilestonesForDocV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).fetchNumOfMilestonesForDocV1(workspaceId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Will return with a number of versions that a particular page has  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     page_id: page_id for which all the versions are required  Returns:     FetchNumberOfVersionsForPageResponse: status and number of versions available for a particular page_id
     * @summary Get number of versions of a page we currently have
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public fetchNumOfVersionsOfPageV1(workspaceId: string, documentId: string, pageId: number, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).fetchNumOfVersionsOfPageV1(workspaceId, documentId, pageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch suggestions for a page
     * @param {string} workspaceId 
     * @param {number} pageId 
     * @param {BodyFetchPageSuggestionsFastV1} bodyFetchPageSuggestionsFastV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public fetchPageSuggestionsFastV1(workspaceId: string, pageId: number, bodyFetchPageSuggestionsFastV1: BodyFetchPageSuggestionsFastV1, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).fetchPageSuggestionsFastV1(workspaceId, pageId, bodyFetchPageSuggestionsFastV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch suggestions for a page
     * @param {string} workspaceId 
     * @param {number} pageId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public fetchPageSuggestionsV1(workspaceId: string, pageId: number, documentId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).fetchPageSuggestionsV1(workspaceId, pageId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Will fetch the page content for a particular page_id and page_version  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     page_id: milestone_id for which we the document to get restored for     page_version:  Returns:     FetchPageVersionResponse
     * @summary Get page content for a page id for a page version
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} pageId 
     * @param {number} pageVersion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public fetchPageVersionContentV1(workspaceId: string, documentId: string, pageId: number, pageVersion: number, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).fetchPageVersionContentV1(workspaceId, documentId, pageId, pageVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all custom style guide rule sets for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     FetchStyleGuideRuleSetsResponse: response of fetching all style guide rule sets
     * @summary Fetch all custom style guide rule sets for a document
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public fetchStyleGuideRuleSetsV1(workspaceId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).fetchStyleGuideRuleSetsV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all custom style guide rules for a document  Args:     workspace_id (str): workspace id that uniquely identifies the customer     rule_set_id: rule set for which the rules are to be fetched     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     FetchStyleGuideRulesResponse: response of fetching all style guide rules
     * @summary Fetch all custom style guide rules for a document
     * @param {string} workspaceId 
     * @param {string} ruleSetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public fetchStyleGuideRulesV1(workspaceId: string, ruleSetId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).fetchStyleGuideRulesV1(workspaceId, ruleSetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch relevant citations from web.
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {string} searchKeyword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public findCitationsV1(workspaceId: string, documentId: string, searchKeyword: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).findCitationsV1(workspaceId, documentId, searchKeyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate regex for a rule set Args:     query:     auth: authentication  Returns: GenerateRegexResponse
     * @summary Generate regex for a rule set
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public generateRegexForRuleSetV1(query?: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).generateRegexForRuleSetV1(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches the references for a particular document.  Args:     citation_ids: Optional - Added to fetch response for a set of reference-ids.     workspace_id (str): customer id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetAllCitationsTextResponse: Response for fetching citations for the document.
     * @summary Fetch reference text for a particular document.
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {CitationPositionType} citationFormat 
     * @param {CitationStyleGuideType} citationStyle 
     * @param {Array<string>} [citationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getAllCitationsTextV1(workspaceId: string, documentId: string, citationFormat: CitationPositionType, citationStyle: CitationStyleGuideType, citationIds?: Array<string>, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getAllCitationsTextV1(workspaceId, documentId, citationFormat, citationStyle, citationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches the references for a particular document.  Args:     citation_ids: Optional - Added to fetch response for a set of reference-ids.     workspace_id (str): customer id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetCitationsResponse: Response for fetching citations for the document.
     * @summary Fetch references for a particular document.
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {Array<string>} [citationIds] 
     * @param {string} [searchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getAllCitationsV1(workspaceId: string, documentId: string, citationIds?: Array<string>, searchFilter?: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getAllCitationsV1(workspaceId, documentId, citationIds, searchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch list of all custom dictionaries for a workspace  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetCustomDictionaryListResponse: response of fetching all dictionaries
     * @summary Fetch list of all custom dictionaries for a workspace
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getAllDictionaryListV1(workspaceId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getAllDictionaryListV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch list of all custom dictionaries for a workspace (paginated)  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetCustomDictionaryPaginatedResponse: paginated response of fetching all dictionaries
     * @summary Fetch list of all custom dictionaries for a workspace (paginated)
     * @param {string} workspaceId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getAllDictionaryListV2(workspaceId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getAllDictionaryListV2(workspaceId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the analysis status of the page  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page id inside the pdf for which parsed segments are to be fetched  Returns:     AnalysePageStatusResponse: status of the analysis
     * @summary Get the analysis status of the page
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getAnalyseStatusV1(workspaceId: string, documentId: string, pageId: number, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getAnalyseStatusV1(workspaceId, documentId, pageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of documents registered with the app  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     app_id: app id for which documents are to be fetched  Returns:     GetAppDocumentsListResponse: list of documents registered with the app
     * @summary Get list of documents registered with the app
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getAppDocumentsListV1(workspaceId: string, appId: AppIdType, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getAppDocumentsListV1(workspaceId, appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of documents registered with the app  Args:     project_id: project id for which documents are to be fetched     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     app_id: app id for which documents are to be fetched  Returns:     GetAppDocumentsListResponse: list of documents registered with the app
     * @summary Get list of documents registered with the app
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getAppDocumentsListV2(workspaceId: string, appId: AppIdType, projectId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getAppDocumentsListV2(workspaceId, appId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch documents uploaded by user (paginated)
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {string} projectId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getAppDocumentsListV3(workspaceId: string, appId: AppIdType, projectId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getAppDocumentsListV3(workspaceId, appId, projectId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list Get project metadata for the associated project_id  Args:     project_id: project id for which documents are to be fetched     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     app_id: app id for which documents are to be fetched  Returns:     GenAIProjectMetadataResponse: Project metadata for the associated project.
     * @summary Get project metadata for the associated project_id
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getAppProjectMetadataV1(workspaceId: string, appId: AppIdType, projectId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getAppProjectMetadataV1(workspaceId, appId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get citation models
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getCitationModelsV1(options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getCitationModelsV1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the result of Plagiarism check
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getContentPlagiarismResultV1(workspaceId: string, documentId: string, pageId: number, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getContentPlagiarismResultV1(workspaceId, documentId, pageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all word list for a given dictionary_id  Args:     workspace_id (str): workspace id that uniquely identifies the customer     dictionary_id: dictionary for which the words are to be fetched     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetCustomDictionaryWordsListResponse: response of fetching all words in dictionary
     * @summary Fetch all word list for a given dictionary_id
     * @param {string} workspaceId 
     * @param {string} dictionaryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getDictionaryWordsListV1(workspaceId: string, dictionaryId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getDictionaryWordsListV1(workspaceId, dictionaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch document download status, if completed, also return the signed URL, if failed, return an error message.
     * @summary Get document download status response
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {string} documentId 
     * @param {string} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getDocumentDownloadStatusV1(workspaceId: string, appId: AppIdType, documentId: string, taskId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getDocumentDownloadStatusV1(workspaceId, appId, documentId, taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get document download response
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {string} documentId 
     * @param {DocumentDownloadFormats} downloadFormat 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getDocumentDownloadV1(workspaceId: string, appId: AppIdType, documentId: string, downloadFormat: DocumentDownloadFormats, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getDocumentDownloadV1(workspaceId, appId, documentId, downloadFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get document metadata which contains s3 presigned url  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
     * @summary Get document metadata response
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getDocumentMetadataV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getDocumentMetadataV1(workspaceId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the parsing status of the whole document uploaded  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     DocumentParsingStatusResponse: status of the parsing
     * @summary Get the parsing status of the whole document uploaded
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {string} appId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getDocumentParsingStatusV1(workspaceId: string, documentId: string, appId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getDocumentParsingStatusV1(workspaceId, documentId, appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch document stats like lexical diversity, word count, sentence count, etc. for the given input
     * @summary Get document stats response for the given input text
     * @param {AppIdType} appId 
     * @param {string} workspaceId 
     * @param {GetDocumentStatsForInputTextRequest} getDocumentStatsForInputTextRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getDocumentStatsForTextSelectionV1(appId: AppIdType, workspaceId: string, getDocumentStatsForInputTextRequest: GetDocumentStatsForInputTextRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getDocumentStatsForTextSelectionV1(appId, workspaceId, getDocumentStatsForInputTextRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch document stats like lexical diversity, word count, sentence count, etc.
     * @summary Get document stats response
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getDocumentStatsV1(workspaceId: string, appId: AppIdType, documentId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getDocumentStatsV1(workspaceId, appId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the summary of the document  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
     * @summary Get the summary of the document
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getDocumentSummaryV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getDocumentSummaryV1(workspaceId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch the signed S3 URL to start download (for the latest available version) message.
     * @summary Fetch the signed URL of the latest version
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getLatestDocumentDownloadV1(workspaceId: string, appId: AppIdType, documentId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getLatestDocumentDownloadV1(workspaceId, appId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get analysis status for a page
     * @param {string} workspaceId 
     * @param {number} pageId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getPageAnalysisStatusV1(workspaceId: string, pageId: number, documentId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getPageAnalysisStatusV1(workspaceId, pageId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the summary of the document  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page id for which summary needs to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
     * @summary Get page summary of the document
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getPageSummaryV1(workspaceId: string, documentId: string, pageId: number, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getPageSummaryV1(workspaceId, documentId, pageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch paginated style guide configs for a workspace  Args:     workspace_id (str): workspace id that uniquely identifies the customer     start (int): pagination start index     end (int): pagination end index     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (str, optional): timestamp for pagination, defaults to \"\"     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetStyleGuideConfigPaginatedResponse: paginated response of style guide configs
     * @summary Fetch paginated style guide configs for a workspace
     * @param {string} workspaceId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getPaginatedStyleGuideConfigV1(workspaceId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getPaginatedStyleGuideConfigV1(workspaceId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get parsed page in pdf  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed page is to be fetched     page_id: page id inside the pdf     background_tasks:  Returns:     GetParsedSegmentsResponse: list of parsed segments
     * @summary Get parsed page response
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getParsedSegmentsV1(workspaceId: string, documentId: string, pageId: number, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getParsedSegmentsV1(workspaceId, documentId, pageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * V2 version of get parsed page response api which is used for RTC in copy edit app  Args:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed page is to be fetched     page_id: page id inside the pdf  Returns:     GetParsedSegmentsResponse: list of parsed segments
     * @summary Get parsed page response
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getParsedSegmentsV2(workspaceId: string, documentId: string, pageId: number, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getParsedSegmentsV2(workspaceId, documentId, pageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project_id for the associated document.
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getProjectFromDocumentV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getProjectFromDocumentV1(workspaceId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get rule set file upload status
     * @param {string} workspaceId 
     * @param {string} ruleSetId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public getRuleSetFileUploadStatusV1(workspaceId: string, ruleSetId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).getRuleSetFileUploadStatusV1(workspaceId, ruleSetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ``` Invalidate the cache for copy edit analysis Args:     request (Request): FastAPI Request object. This is required by the decorator to extract header information     response (Response): FastAPI Response object. This is required by the decorator to set status     auth: authentication  Returns: ```
     * @summary Invalidate Copy Edit Analysis Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public invalidateCopyEditAnalysisCache(options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).invalidateCopyEditAnalysisCache(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invalidate suggestions across documents
     * @param {string} workspaceId 
     * @param {string} suggestionId ID of the suggestion to invalidate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public invalidateSuggestionsV1(workspaceId: string, suggestionId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).invalidateSuggestionsV1(workspaceId, suggestionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invite users to a project Args:     workspace_id: workspace id     app_id: app id     project_id: project id     request: request body     user: user object     background_tasks: to push analytics to bangalore service  Returns: InviteProjectResponse
     * @summary Invite users to a project
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {string} projectId 
     * @param {InviteGenAIProjectRequest} inviteGenAIProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public inviteGenAIProjectV1(workspaceId: string, appId: AppIdType, projectId: string, inviteGenAIProjectRequest: InviteGenAIProjectRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).inviteGenAIProjectV1(workspaceId, appId, projectId, inviteGenAIProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Migrate suggestions rules from one workspace to another Args:     workspace_id: workspace id     query: query to filter documents in the pagesuggestions     user: user object  Returns: None
     * @summary Migrate suggestions rules from one workspace to another
     * @param {string} workspaceId 
     * @param {BodyMigrateSuggestionsRulesV1} bodyMigrateSuggestionsRulesV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public migrateSuggestionsRulesV1(workspaceId: string, bodyMigrateSuggestionsRulesV1: BodyMigrateSuggestionsRulesV1, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).migrateSuggestionsRulesV1(workspaceId, bodyMigrateSuggestionsRulesV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Parse the input citation text and add the entities present in the citation text into the DB
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {BodyParseAndAddBulkCitationTextV1} bodyParseAndAddBulkCitationTextV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public parseAndAddBulkCitationTextV1(workspaceId: string, documentId: string, bodyParseAndAddBulkCitationTextV1: BodyParseAndAddBulkCitationTextV1, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).parseAndAddBulkCitationTextV1(workspaceId, documentId, bodyParseAndAddBulkCitationTextV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Parse the input citation text and return the entities present in the citation text
     * @param {string} workspaceId 
     * @param {BodyParseCitationTextV1} bodyParseCitationTextV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public parseCitationTextV1(workspaceId: string, bodyParseCitationTextV1: BodyParseCitationTextV1, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).parseCitationTextV1(workspaceId, bodyParseCitationTextV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Webhook to receive the result of the Plagiarism check  Args:     request (Request): FastAPI Request object     response (Response): FastAPI Response object  Returns:     None
     * @summary Webhook to receive the result of the Plagiarism check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public receivePlagiarismCheckResult(options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).receivePlagiarismCheckResult(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register the document with the app  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     app_id: app id for which document is to be registered     background_tasks (BackgroundTasks): FastAPI BackgroundTasks object  Returns:     RegisterAppDocumentResponse: response of the registration
     * @summary Register the document with the app
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {AppIdType} appId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public registerAppDocumentV1(workspaceId: string, documentId: string, appId: AppIdType, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).registerAppDocumentV1(workspaceId, documentId, appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register the document with the app v2  Args:     style_guides: ADA or CMOS     custom_style_rules: Custom style guide     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     app_id: app id for which document is to be registered     background_tasks (BackgroundTasks): FastAPI BackgroundTasks object  Returns:     RegisterAppDocumentResponse: response of the registration
     * @summary Register the document with the app
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {AppIdType} appId 
     * @param {BodyRegisterAppDocumentV2} [bodyRegisterAppDocumentV2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public registerAppDocumentV2(workspaceId: string, documentId: string, appId: AppIdType, bodyRegisterAppDocumentV2?: BodyRegisterAppDocumentV2, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).registerAppDocumentV2(workspaceId, documentId, appId, bodyRegisterAppDocumentV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register the document with the app v2  Args:     project_id: project where the document is associated with     style_guides: ADA or CMOS     custom_style_rules: Custom style guide     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     app_id: app id for which document is to be registered     custom_dictionary_ids (List[str]): The list of ids of dictionaries to be applied on this document     background_tasks (BackgroundTasks): FastAPI BackgroundTasks object  Returns:     RegisterAppDocumentResponse: response of the registration
     * @summary Register the document with the app
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {string} projectId 
     * @param {AppIdType} appId 
     * @param {BodyRegisterAppDocumentV3} [bodyRegisterAppDocumentV3] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public registerAppDocumentV3(workspaceId: string, documentId: string, projectId: string, appId: AppIdType, bodyRegisterAppDocumentV3?: BodyRegisterAppDocumentV3, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).registerAppDocumentV3(workspaceId, documentId, projectId, appId, bodyRegisterAppDocumentV3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove user from a project Args:     workspace_id: workspace id     app_id: app id     project_id: project id     service_request: request body     user: user object  Returns: RemoveInviteProjectResponse
     * @summary Remove user from a project
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {string} projectId 
     * @param {RemoveInviteGenAIProjectRequest} removeInviteGenAIProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public removeUserFromGenAIProjectV1(workspaceId: string, appId: AppIdType, projectId: string, removeInviteGenAIProjectRequest: RemoveInviteGenAIProjectRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).removeUserFromGenAIProjectV1(workspaceId, appId, projectId, removeInviteGenAIProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove workspace sharing for a resource
     * @param {string} workspaceId 
     * @param {BodyRemoveWorkspaceShareResourceV1} bodyRemoveWorkspaceShareResourceV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public removeWorkspaceShareResourceV1(workspaceId: string, bodyRemoveWorkspaceShareResourceV1: BodyRemoveWorkspaceShareResourceV1, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).removeWorkspaceShareResourceV1(workspaceId, bodyRemoveWorkspaceShareResourceV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Will set the pointers for all the pages in a doc to their last milestone saved page_versions  Args:     workspace_id (str): workspace id that uniquely identifies the customer     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     milestone_id: milestone_id for which we the document to get restored for  Returns:     RestoreMilestoneResponse
     * @summary Get number of milestones of a document we currently have
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} milestoneId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public restoreMilestoneForDocV1(workspaceId: string, documentId: string, milestoneId: number, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).restoreMilestoneForDocV1(workspaceId, documentId, milestoneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restores the current state of page to a page version provided, creating a new version maintaining linear history  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which the query is to be made     page_id: concerned page     page_version: page version we want the current page to move to     background_tasks: to run push analytics call Returns:     RestorePageResponse
     * @summary Restores the current state of page to a page version provided
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} pageId 
     * @param {number} pageVersion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public restoreVersionForDocV1(workspaceId: string, documentId: string, pageId: number, pageVersion: number, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).restoreVersionForDocV1(workspaceId, documentId, pageId, pageVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Will save the content for the current version and create a new version based on a set timelimit  Args:     workspace_id (str): workspace id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page_id for which the content has to be saved.     service_request: Request body for creating a version     background_tasks:  Returns:     SaveContentResponse: success status for saving content for a version
     * @summary Saving the actual changed content, creating and updating pointers for a session
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} pageId 
     * @param {SaveContentRequestBody} saveContentRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public saveContentForSessionV1(workspaceId: string, documentId: string, pageId: number, saveContentRequestBody: SaveContentRequestBody, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).saveContentForSessionV1(workspaceId, documentId, pageId, saveContentRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the summary of the document  Args:     binary_formatted_document_summary: save snippet summary content in binary format for RTC support     formatted_document_summary: this will save the summary content as snippet formatted content     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     GetDocumentSummaryResponse: summary of the document
     * @summary Save formatted snippet content which contains the summary of the document.
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {BodySaveDocumentSummaryV1} [bodySaveDocumentSummaryV1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public saveDocumentSummaryV1(workspaceId: string, documentId: string, bodySaveDocumentSummaryV1?: BodySaveDocumentSummaryV1, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).saveDocumentSummaryV1(workspaceId, documentId, bodySaveDocumentSummaryV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Share a resource with the entire workspace
     * @param {string} workspaceId 
     * @param {BodyShareResourceWithWorkspaceV1} bodyShareResourceWithWorkspaceV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public shareResourceWithWorkspaceV1(workspaceId: string, bodyShareResourceWithWorkspaceV1: BodyShareResourceWithWorkspaceV1, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).shareResourceWithWorkspaceV1(workspaceId, bodyShareResourceWithWorkspaceV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stream LLM response with optimized performance
     * @summary Stream LLM response
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public streamLLMResponse(query: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).streamLLMResponse(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trigger asynchronous document download
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {string} documentId 
     * @param {DocumentDownloadFormats} downloadFormat 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public triggerAsyncDocumentDownloadV1(workspaceId: string, appId: AppIdType, documentId: string, downloadFormat: DocumentDownloadFormats, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).triggerAsyncDocumentDownloadV1(workspaceId, appId, documentId, downloadFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trigger re-analyze on document metadata change.
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public triggerReAnalyzeTextsV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).triggerReAnalyzeTextsV1(workspaceId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the analysis status of the page  Args:     status_change_request: update the current state of analyse page to this state     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched     page_id: page id inside the pdf for which parsed segments are to be fetched  Returns:     AnalysePageStatusResponse: status of the analysis
     * @summary Update the analysis status of the page
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} pageId 
     * @param {AnalysePageStatusResponse} analysePageStatusResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public updateAnalyseStatusV1(workspaceId: string, documentId: string, pageId: number, analysePageStatusResponse: AnalysePageStatusResponse, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).updateAnalyseStatusV1(workspaceId, documentId, pageId, analysePageStatusResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update and analyse text resources for a text in add-in
     * @param {string} workspaceId 
     * @param {UpdateAnalyseAddInRequest} updateAnalyseAddInRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public updateAndAnalyseV1(workspaceId: string, updateAnalyseAddInRequest: UpdateAnalyseAddInRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).updateAndAnalyseV1(workspaceId, updateAnalyseAddInRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the document metadata for copy edit  Args:     workspace_id (str): workspace id that uniquely identifies the customer     document_id: document id for which parsed segments are to be fetched     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     UpdateAppDocumentResponse: response of the registration
     * @summary Update the document stylesheet metadata for copy edit
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public updateAppDocumentStylesheetMetadataV1(workspaceId: string, documentId: string, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).updateAppDocumentStylesheetMetadataV1(workspaceId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the citation style in document metadata
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {UpdateCitationStyleRequest} updateCitationStyleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public updateCitationStyleRequestV1(workspaceId: string, documentId: string, updateCitationStyleRequest: UpdateCitationStyleRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).updateCitationStyleRequestV1(workspaceId, documentId, updateCitationStyleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the references for a particular page.  Args:     page_id: page_id to update references.     update_request: references which need to be updated for a particular document.     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     document_id: document id for which parsed segments are to be fetched  Returns:     UpdateCitationsResponse: response for updating references for the document.
     * @summary Update citations for a particular document.
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} pageId 
     * @param {UpdateCitationsRequest} updateCitationsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public updateCitationsV1(workspaceId: string, documentId: string, pageId: number, updateCitationsRequest: UpdateCitationsRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).updateCitationsV1(workspaceId, documentId, pageId, updateCitationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the document metadata for copy edit documents
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {UpdateDocumentMetadataRequest} updateDocumentMetadataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public updateDocumentMetadataV1(workspaceId: string, documentId: string, updateDocumentMetadataRequest: UpdateDocumentMetadataRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).updateDocumentMetadataV1(workspaceId, documentId, updateDocumentMetadataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a project for the Gen AI app Args:     project_id:     workspace_id: workspace id     app_id: app id     request: request body     user: user object     background_tasks:  Returns: GenAIProjectMetadataResponse
     * @summary Create or update a project for the Gen AI app
     * @param {string} workspaceId 
     * @param {AppIdType} appId 
     * @param {string} projectId 
     * @param {UpdateGenAIProjectRequest} updateGenAIProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public updateGenAIProjectV1(workspaceId: string, appId: AppIdType, projectId: string, updateGenAIProjectRequest: UpdateGenAIProjectRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).updateGenAIProjectV1(workspaceId, appId, projectId, updateGenAIProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the segments with the suggested changes Args:     user: auth check     workspace_id:     document_id:     page_id:     update_request:     request:     response:  Returns:
     * @summary Update page content with the latest version of formatted page content
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} pageId 
     * @param {UpdateSegmentRequest} updateSegmentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public updatePageContentV1(workspaceId: string, documentId: string, pageId: number, updateSegmentRequest: UpdateSegmentRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).updatePageContentV1(workspaceId, documentId, pageId, updateSegmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * V2 Update the segments with the suggested changes for supporting RTC changes on copy edit app Args:     workspace_id:     document_id:     page_id:     update_request:     background_tasks:     request:     response:     user:  Returns:
     * @summary Update page content with the latest version of formatted page content
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {number} pageId 
     * @param {UpdateSegmentRequestV2} updateSegmentRequestV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public updatePageContentV2(workspaceId: string, documentId: string, pageId: number, updateSegmentRequestV2: UpdateSegmentRequestV2, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).updatePageContentV2(workspaceId, documentId, pageId, updateSegmentRequestV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This will be called when we accept suggestions in our copy edit app. These suggestions are from open ai model. After accepting the changes we will create a new entry in the Segments Table for the pdf
     * @summary Create a new segment with the latest version id with the suggested changes by openai.
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {string} pageId 
     * @param {string} chunkId 
     * @param {UpdateSegmentRequest} updateSegmentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public updateSegmentV1(workspaceId: string, documentId: string, pageId: string, chunkId: string, updateSegmentRequest: UpdateSegmentRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).updateSegmentV1(workspaceId, documentId, pageId, chunkId, updateSegmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a specific style guide configuration record for a workspace.  Args:     workspace_id (str): The ID of the workspace where the configuration exists.     record_id (str): The specific record to update.     config_updates (Dict[str, Any]): Fields to update in the configuration.     request (Request): FastAPI Request object.     response (Response): FastAPI Response object.     user (WorkspaceUser, optional): Authenticated user performing the update.  Returns:     Dict[str, Any]: Response from Montreal service after updating the record.
     * @summary Update style guide config for a workspace
     * @param {string} workspaceId 
     * @param {string} recordId 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public updateStyleGuideConfigV1(workspaceId: string, recordId: string, body: object, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).updateStyleGuideConfigV1(workspaceId, recordId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update suggestion status for a document
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {UpdatePageSuggestions} updatePageSuggestions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public updateSuggestionStatusV1(workspaceId: string, documentId: string, updatePageSuggestions: UpdatePageSuggestions, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).updateSuggestionStatusV1(workspaceId, documentId, updatePageSuggestions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload manual text file for copy edit / summarization analysis  Args:     project_id: project id for which document is to be registered     background_tasks: to trigger the summarization/copy edit analysis flow in the background process     upload_request: upload text file to be analysed     workspace_id (str): customer id that uniquely identifies the customer     app_id: app id for which document is to be registered     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     UploadManualTextFileResponse: response of the text file registration
     * @summary Upload manual text file for copy-edit or summarization analysis
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {AppIdType} appId 
     * @param {UploadManualTextFileRequest} uploadManualTextFileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public uploadManualTextFileRequestV1(workspaceId: string, projectId: string, appId: AppIdType, uploadManualTextFileRequest: UploadManualTextFileRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).uploadManualTextFileRequestV1(workspaceId, projectId, appId, uploadManualTextFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload rule set
     * @param {string} workspaceId 
     * @param {string} ruleSetId 
     * @param {UploadRuleSetRequest} uploadRuleSetRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AppStoreApi
     */
    public uploadRuleSetV1(workspaceId: string, ruleSetId: string, uploadRuleSetRequest: UploadRuleSetRequest, options?: AxiosRequestConfig) {
        return AppStoreApiFp(this.configuration).uploadRuleSetV1(workspaceId, ruleSetId, uploadRuleSetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthorizationApi - axios parameter creator
 * @export
 */
export const AuthorizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Check if the user has enough permissions to perform action in the workspace.
         * @summary Check if user has enough permissions to perform action in the workspace.
         * @param {string} userId 
         * @param {string} workspaceId 
         * @param {CheckUserAccessRequest} checkUserAccessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUserAccessV1: async (userId: string, workspaceId: string, checkUserAccessRequest: CheckUserAccessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('checkUserAccessV1', 'userId', userId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('checkUserAccessV1', 'workspaceId', workspaceId)
            // verify required parameter 'checkUserAccessRequest' is not null or undefined
            assertParamExists('checkUserAccessV1', 'checkUserAccessRequest', checkUserAccessRequest)
            const localVarPath = `/tokyo/v1/internal/workspace/{workspace_id}/user/{user_id}/check-access`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkUserAccessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if the user is member of the workspace or not. This will be used by other services to check if the user can perform actions in the workspace or not based on membership.
         * @summary Check if user is part of workspace or not.
         * @param {string} userId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUserWorkspaceMembershipV1: async (userId: string, workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('checkUserWorkspaceMembershipV1', 'userId', userId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('checkUserWorkspaceMembershipV1', 'workspaceId', workspaceId)
            const localVarPath = `/tokyo/v1/workspace/{workspace_id}/user/{user_id}/check-membership`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorizationApi - functional programming interface
 * @export
 */
export const AuthorizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorizationApiAxiosParamCreator(configuration)
    return {
        /**
         * Check if the user has enough permissions to perform action in the workspace.
         * @summary Check if user has enough permissions to perform action in the workspace.
         * @param {string} userId 
         * @param {string} workspaceId 
         * @param {CheckUserAccessRequest} checkUserAccessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkUserAccessV1(userId: string, workspaceId: string, checkUserAccessRequest: CheckUserAccessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkUserAccessV1(userId, workspaceId, checkUserAccessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check if the user is member of the workspace or not. This will be used by other services to check if the user can perform actions in the workspace or not based on membership.
         * @summary Check if user is part of workspace or not.
         * @param {string} userId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkUserWorkspaceMembershipV1(userId: string, workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWorkspaceMembershipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkUserWorkspaceMembershipV1(userId, workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthorizationApi - factory interface
 * @export
 */
export const AuthorizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorizationApiFp(configuration)
    return {
        /**
         * Check if the user has enough permissions to perform action in the workspace.
         * @summary Check if user has enough permissions to perform action in the workspace.
         * @param {string} userId 
         * @param {string} workspaceId 
         * @param {CheckUserAccessRequest} checkUserAccessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUserAccessV1(userId: string, workspaceId: string, checkUserAccessRequest: CheckUserAccessRequest, options?: any): AxiosPromise<UserAccessResponse> {
            return localVarFp.checkUserAccessV1(userId, workspaceId, checkUserAccessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Check if the user is member of the workspace or not. This will be used by other services to check if the user can perform actions in the workspace or not based on membership.
         * @summary Check if user is part of workspace or not.
         * @param {string} userId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUserWorkspaceMembershipV1(userId: string, workspaceId: string, options?: any): AxiosPromise<UserWorkspaceMembershipResponse> {
            return localVarFp.checkUserWorkspaceMembershipV1(userId, workspaceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorizationApi - object-oriented interface
 * @export
 * @class AuthorizationApi
 * @extends {BaseAPI}
 */
export class AuthorizationApi extends BaseAPI {
    /**
     * Check if the user has enough permissions to perform action in the workspace.
     * @summary Check if user has enough permissions to perform action in the workspace.
     * @param {string} userId 
     * @param {string} workspaceId 
     * @param {CheckUserAccessRequest} checkUserAccessRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public checkUserAccessV1(userId: string, workspaceId: string, checkUserAccessRequest: CheckUserAccessRequest, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).checkUserAccessV1(userId, workspaceId, checkUserAccessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check if the user is member of the workspace or not. This will be used by other services to check if the user can perform actions in the workspace or not based on membership.
     * @summary Check if user is part of workspace or not.
     * @param {string} userId 
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public checkUserWorkspaceMembershipV1(userId: string, workspaceId: string, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).checkUserWorkspaceMembershipV1(userId, workspaceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChatterRoutesApi - axios parameter creator
 * @export
 */
export const ChatterRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns chat response for user prompt generated from llm
         * @param {string} workspaceId 
         * @param {string} dataSourceId 
         * @param {ChatterRequestModel} chatterRequestModel 
         * @param {LLMTypes} [llmType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        askMarkovForDataSourceV1: async (workspaceId: string, dataSourceId: string, chatterRequestModel: ChatterRequestModel, llmType?: LLMTypes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('askMarkovForDataSourceV1', 'workspaceId', workspaceId)
            // verify required parameter 'dataSourceId' is not null or undefined
            assertParamExists('askMarkovForDataSourceV1', 'dataSourceId', dataSourceId)
            // verify required parameter 'chatterRequestModel' is not null or undefined
            assertParamExists('askMarkovForDataSourceV1', 'chatterRequestModel', chatterRequestModel)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/chatter/data_source/{data_source_id}/chat`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"data_source_id"}}`, encodeURIComponent(String(dataSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (llmType !== undefined) {
                localVarQueryParameter['llm_type'] = llmType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatterRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns chat response for user prompt generated from llm for App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {ChatterRequestModel} chatterRequestModel 
         * @param {LLMTypes} [llmType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatWithAppBuilderV1: async (workspaceId: string, appId: string, chatterRequestModel: ChatterRequestModel, llmType?: LLMTypes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('chatWithAppBuilderV1', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('chatWithAppBuilderV1', 'appId', appId)
            // verify required parameter 'chatterRequestModel' is not null or undefined
            assertParamExists('chatWithAppBuilderV1', 'chatterRequestModel', chatterRequestModel)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/app/{app_id}/chat`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (llmType !== undefined) {
                localVarQueryParameter['llm_type'] = llmType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatterRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns chat response for user prompt generated from llm
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {ChatterRequestModel} chatterRequestModel 
         * @param {LLMTypes} [llmType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatWithCopyEditV1: async (workspaceId: string, documentId: string, chatterRequestModel: ChatterRequestModel, llmType?: LLMTypes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('chatWithCopyEditV1', 'workspaceId', workspaceId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('chatWithCopyEditV1', 'documentId', documentId)
            // verify required parameter 'chatterRequestModel' is not null or undefined
            assertParamExists('chatWithCopyEditV1', 'chatterRequestModel', chatterRequestModel)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/document/{document_id}/chat`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (llmType !== undefined) {
                localVarQueryParameter['llm_type'] = llmType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatterRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clear chatter memory
         * @param {any} workspaceId 
         * @param {any} dsId 
         * @param {SupportedLLMs} [llm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearChatterMemoryV1: async (workspaceId: any, dsId: any, llm?: SupportedLLMs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('clearChatterMemoryV1', 'workspaceId', workspaceId)
            // verify required parameter 'dsId' is not null or undefined
            assertParamExists('clearChatterMemoryV1', 'dsId', dsId)
            const localVarPath = `/oslo/v1/chatter/workspace/{workspace_id}/dataset/{ds_id}/clear`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"ds_id"}}`, encodeURIComponent(String(dsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (llm !== undefined) {
                localVarQueryParameter['llm'] = llm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete thread conversations and thread for the given Thread ID.
         * @param {string} workspaceId 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConversationThreadV11: async (workspaceId: string, threadId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteConversationThreadV11', 'workspaceId', workspaceId)
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('deleteConversationThreadV11', 'threadId', threadId)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/chatter/thread/{thread_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"thread_id"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Generated SQL in data-analytics.
         * @param {string} workspaceId 
         * @param {string} conversationId 
         * @param {EditGeneratedSQLRequestModel} editGeneratedSQLRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editGeneratedSQLV1: async (workspaceId: string, conversationId: string, editGeneratedSQLRequestModel: EditGeneratedSQLRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('editGeneratedSQLV1', 'workspaceId', workspaceId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('editGeneratedSQLV1', 'conversationId', conversationId)
            // verify required parameter 'editGeneratedSQLRequestModel' is not null or undefined
            assertParamExists('editGeneratedSQLV1', 'editGeneratedSQLRequestModel', editGeneratedSQLRequestModel)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/chatter/conversation/{conversation_id}/sql`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editGeneratedSQLRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate user query response from llm
         * @param {string} workspaceId 
         * @param {string} dsId 
         * @param {ChatterRequestModelV2} chatterRequestModelV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatterAnswerV2: async (workspaceId: string, dsId: string, chatterRequestModelV2: ChatterRequestModelV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getChatterAnswerV2', 'workspaceId', workspaceId)
            // verify required parameter 'dsId' is not null or undefined
            assertParamExists('getChatterAnswerV2', 'dsId', dsId)
            // verify required parameter 'chatterRequestModelV2' is not null or undefined
            assertParamExists('getChatterAnswerV2', 'chatterRequestModelV2', chatterRequestModelV2)
            const localVarPath = `/oslo/v1/chatter/workspace/{workspace_id}/dataset/{ds_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"ds_id"}}`, encodeURIComponent(String(dsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatterRequestModelV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get suggested questions to ask on a dataset
         * @param {string} workspaceId 
         * @param {string} dsId 
         * @param {LLMTypes} [llm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestedQuestionsV1: async (workspaceId: string, dsId: string, llm?: LLMTypes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getSuggestedQuestionsV1', 'workspaceId', workspaceId)
            // verify required parameter 'dsId' is not null or undefined
            assertParamExists('getSuggestedQuestionsV1', 'dsId', dsId)
            const localVarPath = `/oslo/v1/chatter/workspace/{workspace_id}/dataset/{ds_id}/suggested_questions`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"ds_id"}}`, encodeURIComponent(String(dsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (llm !== undefined) {
                localVarQueryParameter['llm'] = llm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initialises the connection setup for given data source with the llm.
         * @param {string} workspaceId 
         * @param {string} dataSourceId 
         * @param {LLMTypes} [llmType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initDataSourceConnectionSetupV1: async (workspaceId: string, dataSourceId: string, llmType?: LLMTypes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('initDataSourceConnectionSetupV1', 'workspaceId', workspaceId)
            // verify required parameter 'dataSourceId' is not null or undefined
            assertParamExists('initDataSourceConnectionSetupV1', 'dataSourceId', dataSourceId)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/chatter/data_source/{data_source_id}/init-connection`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"data_source_id"}}`, encodeURIComponent(String(dataSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (llmType !== undefined) {
                localVarQueryParameter['llm_type'] = llmType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of conversations for a given thread IDs and user.
         * @param {string} workspaceId 
         * @param {string} threadId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChatWithResourceConversationsV2: async (workspaceId: string, threadId: string, start?: number, end?: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listChatWithResourceConversationsV2', 'workspaceId', workspaceId)
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('listChatWithResourceConversationsV2', 'threadId', threadId)
            const localVarPath = `/oslo/v2/workspace/{workspace_id}/chatter/thread/{thread_id}/conversations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"thread_id"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of conversations for a given thread IDs and user.
         * @param {string} workspaceId 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataSourceConversationsV1: async (workspaceId: string, threadId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listDataSourceConversationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('listDataSourceConversationsV1', 'threadId', threadId)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/chatter/thread/{thread_id}/conversations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"thread_id"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a paginated list of threads for data source llm conversations for a user
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataSourceThreadsV2: async (workspaceId: string, resourceId: string, start?: number, end?: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listDataSourceThreadsV2', 'workspaceId', workspaceId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('listDataSourceThreadsV2', 'resourceId', resourceId)
            const localVarPath = `/oslo/v2/workspace/{workspace_id}/chatter/resource/{resource_id}/threads`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update thread artifact state for the given Thread IDs.
         * @param {string} workspaceId 
         * @param {UpdateConversationThreadsArtifactStateRequestModel} updateConversationThreadsArtifactStateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversationThreadsArtifactStateV1: async (workspaceId: string, updateConversationThreadsArtifactStateRequestModel: UpdateConversationThreadsArtifactStateRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateConversationThreadsArtifactStateV1', 'workspaceId', workspaceId)
            // verify required parameter 'updateConversationThreadsArtifactStateRequestModel' is not null or undefined
            assertParamExists('updateConversationThreadsArtifactStateV1', 'updateConversationThreadsArtifactStateRequestModel', updateConversationThreadsArtifactStateRequestModel)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/chatter/thread/artifact-state`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateConversationThreadsArtifactStateRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatterRoutesApi - functional programming interface
 * @export
 */
export const ChatterRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatterRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns chat response for user prompt generated from llm
         * @param {string} workspaceId 
         * @param {string} dataSourceId 
         * @param {ChatterRequestModel} chatterRequestModel 
         * @param {LLMTypes} [llmType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async askMarkovForDataSourceV1(workspaceId: string, dataSourceId: string, chatterRequestModel: ChatterRequestModel, llmType?: LLMTypes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSourceConversationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.askMarkovForDataSourceV1(workspaceId, dataSourceId, chatterRequestModel, llmType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns chat response for user prompt generated from llm for App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {ChatterRequestModel} chatterRequestModel 
         * @param {LLMTypes} [llmType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatWithAppBuilderV1(workspaceId: string, appId: string, chatterRequestModel: ChatterRequestModel, llmType?: LLMTypes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSourceConversationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatWithAppBuilderV1(workspaceId, appId, chatterRequestModel, llmType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns chat response for user prompt generated from llm
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {ChatterRequestModel} chatterRequestModel 
         * @param {LLMTypes} [llmType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatWithCopyEditV1(workspaceId: string, documentId: string, chatterRequestModel: ChatterRequestModel, llmType?: LLMTypes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSourceConversationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatWithCopyEditV1(workspaceId, documentId, chatterRequestModel, llmType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Clear chatter memory
         * @param {any} workspaceId 
         * @param {any} dsId 
         * @param {SupportedLLMs} [llm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearChatterMemoryV1(workspaceId: any, dsId: any, llm?: SupportedLLMs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearChatterMemoryV1(workspaceId, dsId, llm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete thread conversations and thread for the given Thread ID.
         * @param {string} workspaceId 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConversationThreadV11(workspaceId: string, threadId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteConversationThreadResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConversationThreadV11(workspaceId, threadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Generated SQL in data-analytics.
         * @param {string} workspaceId 
         * @param {string} conversationId 
         * @param {EditGeneratedSQLRequestModel} editGeneratedSQLRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editGeneratedSQLV1(workspaceId: string, conversationId: string, editGeneratedSQLRequestModel: EditGeneratedSQLRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSourceConversationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editGeneratedSQLV1(workspaceId, conversationId, editGeneratedSQLRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate user query response from llm
         * @param {string} workspaceId 
         * @param {string} dsId 
         * @param {ChatterRequestModelV2} chatterRequestModelV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatterAnswerV2(workspaceId: string, dsId: string, chatterRequestModelV2: ChatterRequestModelV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatterResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatterAnswerV2(workspaceId, dsId, chatterRequestModelV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get suggested questions to ask on a dataset
         * @param {string} workspaceId 
         * @param {string} dsId 
         * @param {LLMTypes} [llm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSuggestedQuestionsV1(workspaceId: string, dsId: string, llm?: LLMTypes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatterSuggestedQuestionsResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSuggestedQuestionsV1(workspaceId, dsId, llm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Initialises the connection setup for given data source with the llm.
         * @param {string} workspaceId 
         * @param {string} dataSourceId 
         * @param {LLMTypes} [llmType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initDataSourceConnectionSetupV1(workspaceId: string, dataSourceId: string, llmType?: LLMTypes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSourceInitConnectionResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initDataSourceConnectionSetupV1(workspaceId, dataSourceId, llmType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of conversations for a given thread IDs and user.
         * @param {string} workspaceId 
         * @param {string} threadId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listChatWithResourceConversationsV2(workspaceId: string, threadId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListChatWithResourceConversationsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listChatWithResourceConversationsV2(workspaceId, threadId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of conversations for a given thread IDs and user.
         * @param {string} workspaceId 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDataSourceConversationsV1(workspaceId: string, threadId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataSourceConversationModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDataSourceConversationsV1(workspaceId, threadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a paginated list of threads for data source llm conversations for a user
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDataSourceThreadsV2(workspaceId: string, resourceId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListChatWithResourceThreadsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDataSourceThreadsV2(workspaceId, resourceId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update thread artifact state for the given Thread IDs.
         * @param {string} workspaceId 
         * @param {UpdateConversationThreadsArtifactStateRequestModel} updateConversationThreadsArtifactStateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConversationThreadsArtifactStateV1(workspaceId: string, updateConversationThreadsArtifactStateRequestModel: UpdateConversationThreadsArtifactStateRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateConversationThreadsArtifactStateResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConversationThreadsArtifactStateV1(workspaceId, updateConversationThreadsArtifactStateRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatterRoutesApi - factory interface
 * @export
 */
export const ChatterRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatterRoutesApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns chat response for user prompt generated from llm
         * @param {string} workspaceId 
         * @param {string} dataSourceId 
         * @param {ChatterRequestModel} chatterRequestModel 
         * @param {LLMTypes} [llmType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        askMarkovForDataSourceV1(workspaceId: string, dataSourceId: string, chatterRequestModel: ChatterRequestModel, llmType?: LLMTypes, options?: any): AxiosPromise<DataSourceConversationModel> {
            return localVarFp.askMarkovForDataSourceV1(workspaceId, dataSourceId, chatterRequestModel, llmType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns chat response for user prompt generated from llm for App Builder
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {ChatterRequestModel} chatterRequestModel 
         * @param {LLMTypes} [llmType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatWithAppBuilderV1(workspaceId: string, appId: string, chatterRequestModel: ChatterRequestModel, llmType?: LLMTypes, options?: any): AxiosPromise<DataSourceConversationModel> {
            return localVarFp.chatWithAppBuilderV1(workspaceId, appId, chatterRequestModel, llmType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns chat response for user prompt generated from llm
         * @param {string} workspaceId 
         * @param {string} documentId 
         * @param {ChatterRequestModel} chatterRequestModel 
         * @param {LLMTypes} [llmType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatWithCopyEditV1(workspaceId: string, documentId: string, chatterRequestModel: ChatterRequestModel, llmType?: LLMTypes, options?: any): AxiosPromise<DataSourceConversationModel> {
            return localVarFp.chatWithCopyEditV1(workspaceId, documentId, chatterRequestModel, llmType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clear chatter memory
         * @param {any} workspaceId 
         * @param {any} dsId 
         * @param {SupportedLLMs} [llm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearChatterMemoryV1(workspaceId: any, dsId: any, llm?: SupportedLLMs, options?: any): AxiosPromise<ServiceResponse> {
            return localVarFp.clearChatterMemoryV1(workspaceId, dsId, llm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete thread conversations and thread for the given Thread ID.
         * @param {string} workspaceId 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConversationThreadV11(workspaceId: string, threadId: string, options?: any): AxiosPromise<DeleteConversationThreadResponseModel> {
            return localVarFp.deleteConversationThreadV11(workspaceId, threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Generated SQL in data-analytics.
         * @param {string} workspaceId 
         * @param {string} conversationId 
         * @param {EditGeneratedSQLRequestModel} editGeneratedSQLRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editGeneratedSQLV1(workspaceId: string, conversationId: string, editGeneratedSQLRequestModel: EditGeneratedSQLRequestModel, options?: any): AxiosPromise<DataSourceConversationModel> {
            return localVarFp.editGeneratedSQLV1(workspaceId, conversationId, editGeneratedSQLRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate user query response from llm
         * @param {string} workspaceId 
         * @param {string} dsId 
         * @param {ChatterRequestModelV2} chatterRequestModelV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatterAnswerV2(workspaceId: string, dsId: string, chatterRequestModelV2: ChatterRequestModelV2, options?: any): AxiosPromise<ChatterResponseModel> {
            return localVarFp.getChatterAnswerV2(workspaceId, dsId, chatterRequestModelV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get suggested questions to ask on a dataset
         * @param {string} workspaceId 
         * @param {string} dsId 
         * @param {LLMTypes} [llm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestedQuestionsV1(workspaceId: string, dsId: string, llm?: LLMTypes, options?: any): AxiosPromise<ChatterSuggestedQuestionsResponseModel> {
            return localVarFp.getSuggestedQuestionsV1(workspaceId, dsId, llm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initialises the connection setup for given data source with the llm.
         * @param {string} workspaceId 
         * @param {string} dataSourceId 
         * @param {LLMTypes} [llmType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initDataSourceConnectionSetupV1(workspaceId: string, dataSourceId: string, llmType?: LLMTypes, options?: any): AxiosPromise<DataSourceInitConnectionResponseModel> {
            return localVarFp.initDataSourceConnectionSetupV1(workspaceId, dataSourceId, llmType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of conversations for a given thread IDs and user.
         * @param {string} workspaceId 
         * @param {string} threadId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChatWithResourceConversationsV2(workspaceId: string, threadId: string, start?: number, end?: number, ts?: string, options?: any): AxiosPromise<ListChatWithResourceConversationsModel> {
            return localVarFp.listChatWithResourceConversationsV2(workspaceId, threadId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of conversations for a given thread IDs and user.
         * @param {string} workspaceId 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataSourceConversationsV1(workspaceId: string, threadId: string, options?: any): AxiosPromise<Array<DataSourceConversationModel>> {
            return localVarFp.listDataSourceConversationsV1(workspaceId, threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a paginated list of threads for data source llm conversations for a user
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataSourceThreadsV2(workspaceId: string, resourceId: string, start?: number, end?: number, ts?: string, options?: any): AxiosPromise<ListChatWithResourceThreadsModel> {
            return localVarFp.listDataSourceThreadsV2(workspaceId, resourceId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update thread artifact state for the given Thread IDs.
         * @param {string} workspaceId 
         * @param {UpdateConversationThreadsArtifactStateRequestModel} updateConversationThreadsArtifactStateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversationThreadsArtifactStateV1(workspaceId: string, updateConversationThreadsArtifactStateRequestModel: UpdateConversationThreadsArtifactStateRequestModel, options?: any): AxiosPromise<UpdateConversationThreadsArtifactStateResponseModel> {
            return localVarFp.updateConversationThreadsArtifactStateV1(workspaceId, updateConversationThreadsArtifactStateRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatterRoutesApi - object-oriented interface
 * @export
 * @class ChatterRoutesApi
 * @extends {BaseAPI}
 */
export class ChatterRoutesApi extends BaseAPI {
    /**
     * 
     * @summary Returns chat response for user prompt generated from llm
     * @param {string} workspaceId 
     * @param {string} dataSourceId 
     * @param {ChatterRequestModel} chatterRequestModel 
     * @param {LLMTypes} [llmType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatterRoutesApi
     */
    public askMarkovForDataSourceV1(workspaceId: string, dataSourceId: string, chatterRequestModel: ChatterRequestModel, llmType?: LLMTypes, options?: AxiosRequestConfig) {
        return ChatterRoutesApiFp(this.configuration).askMarkovForDataSourceV1(workspaceId, dataSourceId, chatterRequestModel, llmType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns chat response for user prompt generated from llm for App Builder
     * @param {string} workspaceId 
     * @param {string} appId 
     * @param {ChatterRequestModel} chatterRequestModel 
     * @param {LLMTypes} [llmType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatterRoutesApi
     */
    public chatWithAppBuilderV1(workspaceId: string, appId: string, chatterRequestModel: ChatterRequestModel, llmType?: LLMTypes, options?: AxiosRequestConfig) {
        return ChatterRoutesApiFp(this.configuration).chatWithAppBuilderV1(workspaceId, appId, chatterRequestModel, llmType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns chat response for user prompt generated from llm
     * @param {string} workspaceId 
     * @param {string} documentId 
     * @param {ChatterRequestModel} chatterRequestModel 
     * @param {LLMTypes} [llmType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatterRoutesApi
     */
    public chatWithCopyEditV1(workspaceId: string, documentId: string, chatterRequestModel: ChatterRequestModel, llmType?: LLMTypes, options?: AxiosRequestConfig) {
        return ChatterRoutesApiFp(this.configuration).chatWithCopyEditV1(workspaceId, documentId, chatterRequestModel, llmType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clear chatter memory
     * @param {any} workspaceId 
     * @param {any} dsId 
     * @param {SupportedLLMs} [llm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatterRoutesApi
     */
    public clearChatterMemoryV1(workspaceId: any, dsId: any, llm?: SupportedLLMs, options?: AxiosRequestConfig) {
        return ChatterRoutesApiFp(this.configuration).clearChatterMemoryV1(workspaceId, dsId, llm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete thread conversations and thread for the given Thread ID.
     * @param {string} workspaceId 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatterRoutesApi
     */
    public deleteConversationThreadV11(workspaceId: string, threadId: string, options?: AxiosRequestConfig) {
        return ChatterRoutesApiFp(this.configuration).deleteConversationThreadV11(workspaceId, threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Generated SQL in data-analytics.
     * @param {string} workspaceId 
     * @param {string} conversationId 
     * @param {EditGeneratedSQLRequestModel} editGeneratedSQLRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatterRoutesApi
     */
    public editGeneratedSQLV1(workspaceId: string, conversationId: string, editGeneratedSQLRequestModel: EditGeneratedSQLRequestModel, options?: AxiosRequestConfig) {
        return ChatterRoutesApiFp(this.configuration).editGeneratedSQLV1(workspaceId, conversationId, editGeneratedSQLRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate user query response from llm
     * @param {string} workspaceId 
     * @param {string} dsId 
     * @param {ChatterRequestModelV2} chatterRequestModelV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatterRoutesApi
     */
    public getChatterAnswerV2(workspaceId: string, dsId: string, chatterRequestModelV2: ChatterRequestModelV2, options?: AxiosRequestConfig) {
        return ChatterRoutesApiFp(this.configuration).getChatterAnswerV2(workspaceId, dsId, chatterRequestModelV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get suggested questions to ask on a dataset
     * @param {string} workspaceId 
     * @param {string} dsId 
     * @param {LLMTypes} [llm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatterRoutesApi
     */
    public getSuggestedQuestionsV1(workspaceId: string, dsId: string, llm?: LLMTypes, options?: AxiosRequestConfig) {
        return ChatterRoutesApiFp(this.configuration).getSuggestedQuestionsV1(workspaceId, dsId, llm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initialises the connection setup for given data source with the llm.
     * @param {string} workspaceId 
     * @param {string} dataSourceId 
     * @param {LLMTypes} [llmType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatterRoutesApi
     */
    public initDataSourceConnectionSetupV1(workspaceId: string, dataSourceId: string, llmType?: LLMTypes, options?: AxiosRequestConfig) {
        return ChatterRoutesApiFp(this.configuration).initDataSourceConnectionSetupV1(workspaceId, dataSourceId, llmType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of conversations for a given thread IDs and user.
     * @param {string} workspaceId 
     * @param {string} threadId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatterRoutesApi
     */
    public listChatWithResourceConversationsV2(workspaceId: string, threadId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig) {
        return ChatterRoutesApiFp(this.configuration).listChatWithResourceConversationsV2(workspaceId, threadId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of conversations for a given thread IDs and user.
     * @param {string} workspaceId 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatterRoutesApi
     */
    public listDataSourceConversationsV1(workspaceId: string, threadId: string, options?: AxiosRequestConfig) {
        return ChatterRoutesApiFp(this.configuration).listDataSourceConversationsV1(workspaceId, threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a paginated list of threads for data source llm conversations for a user
     * @param {string} workspaceId 
     * @param {string} resourceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatterRoutesApi
     */
    public listDataSourceThreadsV2(workspaceId: string, resourceId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig) {
        return ChatterRoutesApiFp(this.configuration).listDataSourceThreadsV2(workspaceId, resourceId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update thread artifact state for the given Thread IDs.
     * @param {string} workspaceId 
     * @param {UpdateConversationThreadsArtifactStateRequestModel} updateConversationThreadsArtifactStateRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatterRoutesApi
     */
    public updateConversationThreadsArtifactStateV1(workspaceId: string, updateConversationThreadsArtifactStateRequestModel: UpdateConversationThreadsArtifactStateRequestModel, options?: AxiosRequestConfig) {
        return ChatterRoutesApiFp(this.configuration).updateConversationThreadsArtifactStateV1(workspaceId, updateConversationThreadsArtifactStateRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChennaiRouterApi - axios parameter creator
 * @export
 */
export const ChennaiRouterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generate text from a model loaded in memory (GPU or RAM depending on loader) Args:     request : UserInput     json_format: string for the desired return format  Returns:     GenerateTextResponse: generated_text and latency
         * @summary Generate Text
         * @param {UserInput} userInput 
         * @param {string} [jsonFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTextResponseV0: async (userInput: UserInput, jsonFormat?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userInput' is not null or undefined
            assertParamExists('generateTextResponseV0', 'userInput', userInput)
            const localVarPath = `/reno/v0/model/text-wrapper`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jsonFormat !== undefined) {
                localVarQueryParameter['json_format'] = jsonFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChennaiRouterApi - functional programming interface
 * @export
 */
export const ChennaiRouterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChennaiRouterApiAxiosParamCreator(configuration)
    return {
        /**
         * Generate text from a model loaded in memory (GPU or RAM depending on loader) Args:     request : UserInput     json_format: string for the desired return format  Returns:     GenerateTextResponse: generated_text and latency
         * @summary Generate Text
         * @param {UserInput} userInput 
         * @param {string} [jsonFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTextResponseV0(userInput: UserInput, jsonFormat?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateTextResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTextResponseV0(userInput, jsonFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChennaiRouterApi - factory interface
 * @export
 */
export const ChennaiRouterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChennaiRouterApiFp(configuration)
    return {
        /**
         * Generate text from a model loaded in memory (GPU or RAM depending on loader) Args:     request : UserInput     json_format: string for the desired return format  Returns:     GenerateTextResponse: generated_text and latency
         * @summary Generate Text
         * @param {UserInput} userInput 
         * @param {string} [jsonFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTextResponseV0(userInput: UserInput, jsonFormat?: string, options?: any): AxiosPromise<GenerateTextResponse> {
            return localVarFp.generateTextResponseV0(userInput, jsonFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChennaiRouterApi - object-oriented interface
 * @export
 * @class ChennaiRouterApi
 * @extends {BaseAPI}
 */
export class ChennaiRouterApi extends BaseAPI {
    /**
     * Generate text from a model loaded in memory (GPU or RAM depending on loader) Args:     request : UserInput     json_format: string for the desired return format  Returns:     GenerateTextResponse: generated_text and latency
     * @summary Generate Text
     * @param {UserInput} userInput 
     * @param {string} [jsonFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChennaiRouterApi
     */
    public generateTextResponseV0(userInput: UserInput, jsonFormat?: string, options?: AxiosRequestConfig) {
        return ChennaiRouterApiFp(this.configuration).generateTextResponseV0(userInput, jsonFormat, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClusteringRoutesApi - axios parameter creator
 * @export
 */
export const ClusteringRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes Embedding for given embedding id
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} embeddingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmbeddingV1: async (datasetId: string, workspaceId: string, embeddingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('deleteEmbeddingV1', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteEmbeddingV1', 'workspaceId', workspaceId)
            // verify required parameter 'embeddingId' is not null or undefined
            assertParamExists('deleteEmbeddingV1', 'embeddingId', embeddingId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/embedding/{embedding_id}`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"embedding_id"}}`, encodeURIComponent(String(embeddingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns raw data for clustering record ids
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {ClusteringRawDataRequest} clusteringRawDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetClusteringRawDataV1: async (datasetId: string, workspaceId: string, clusteringRawDataRequest: ClusteringRawDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDatasetClusteringRawDataV1', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetClusteringRawDataV1', 'workspaceId', workspaceId)
            // verify required parameter 'clusteringRawDataRequest' is not null or undefined
            assertParamExists('getDatasetClusteringRawDataV1', 'clusteringRawDataRequest', clusteringRawDataRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/clustering-rawdata`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusteringRawDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {SimilarityRequest} similarityRequest 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetClusteringSimilarityV1: async (datasetId: string, workspaceId: string, similarityRequest: SimilarityRequest, embeddingId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDatasetClusteringSimilarityV1', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetClusteringSimilarityV1', 'workspaceId', workspaceId)
            // verify required parameter 'similarityRequest' is not null or undefined
            assertParamExists('getDatasetClusteringSimilarityV1', 'similarityRequest', similarityRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/similar-points`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (embeddingId !== undefined) {
                localVarQueryParameter['embedding_id'] = embeddingId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(similarityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns clustering status for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetClusteringStateV1: async (datasetId: string, workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDatasetClusteringStateV1', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetClusteringStateV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/clustering-state`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns clustering information for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {string} [embeddingsTaskletId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetClusteringV3: async (datasetId: string, workspaceId: string, embeddingId?: string, embeddingsTaskletId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDatasetClusteringV3', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetClusteringV3', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v3/workspace/{workspace_id}/datasets/{dataset_id}/clustering`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (embeddingId !== undefined) {
                localVarQueryParameter['embedding_id'] = embeddingId;
            }

            if (embeddingsTaskletId !== undefined) {
                localVarQueryParameter['embeddings_tasklet_id'] = embeddingsTaskletId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the embeddings for given dataset id
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbeddingsListV1: async (workspaceId: string, datasetId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getEmbeddingsListV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getEmbeddingsListV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/embeddings`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns if index file is available for similarity.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexFileExists: async (datasetId: string, workspaceId: string, embeddingId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('indexFileExists', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('indexFileExists', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v3/workspace/{workspace_id}/datasets/{dataset_id}/is-index-file-available`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (embeddingId !== undefined) {
                localVarQueryParameter['embedding_id'] = embeddingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks status of the search pod for given dataset_id
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPodStatus: async (datasetId: string, workspaceId: string, embeddingId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('searchPodStatus', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('searchPodStatus', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/check-status`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (embeddingId !== undefined) {
                localVarQueryParameter['embedding_id'] = embeddingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spinPodForClusteringSimilarity: async (datasetId: string, workspaceId: string, embeddingId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('spinPodForClusteringSimilarity', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('spinPodForClusteringSimilarity', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/create-pod`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (embeddingId !== undefined) {
                localVarQueryParameter['embedding_id'] = embeddingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClusteringRoutesApi - functional programming interface
 * @export
 */
export const ClusteringRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClusteringRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes Embedding for given embedding id
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} embeddingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmbeddingV1(datasetId: string, workspaceId: string, embeddingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteEmbeddingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmbeddingV1(datasetId, workspaceId, embeddingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns raw data for clustering record ids
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {ClusteringRawDataRequest} clusteringRawDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetClusteringRawDataV1(datasetId: string, workspaceId: string, clusteringRawDataRequest: ClusteringRawDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusteringRawDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetClusteringRawDataV1(datasetId, workspaceId, clusteringRawDataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {SimilarityRequest} similarityRequest 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetClusteringSimilarityV1(datasetId: string, workspaceId: string, similarityRequest: SimilarityRequest, embeddingId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusteringSimilarityResponseV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetClusteringSimilarityV1(datasetId, workspaceId, similarityRequest, embeddingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns clustering status for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetClusteringStateV1(datasetId: string, workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusteringStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetClusteringStateV1(datasetId, workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns clustering information for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {string} [embeddingsTaskletId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetClusteringV3(datasetId: string, workspaceId: string, embeddingId?: string, embeddingsTaskletId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetClusteringV3(datasetId, workspaceId, embeddingId, embeddingsTaskletId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the embeddings for given dataset id
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmbeddingsListV1(workspaceId: string, datasetId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEmbeddingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmbeddingsListV1(workspaceId, datasetId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeDemoArtifacts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns if index file is available for similarity.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexFileExists(datasetId: string, workspaceId: string, embeddingId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexFileExists(datasetId, workspaceId, embeddingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks status of the search pod for given dataset_id
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPodStatus(datasetId: string, workspaceId: string, embeddingId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchPodStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPodStatus(datasetId, workspaceId, embeddingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spinPodForClusteringSimilarity(datasetId: string, workspaceId: string, embeddingId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spinPodForClusteringSimilarity(datasetId, workspaceId, embeddingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClusteringRoutesApi - factory interface
 * @export
 */
export const ClusteringRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClusteringRoutesApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes Embedding for given embedding id
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} embeddingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmbeddingV1(datasetId: string, workspaceId: string, embeddingId: string, options?: any): AxiosPromise<DeleteEmbeddingResponse> {
            return localVarFp.deleteEmbeddingV1(datasetId, workspaceId, embeddingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns raw data for clustering record ids
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {ClusteringRawDataRequest} clusteringRawDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetClusteringRawDataV1(datasetId: string, workspaceId: string, clusteringRawDataRequest: ClusteringRawDataRequest, options?: any): AxiosPromise<ClusteringRawDataResponse> {
            return localVarFp.getDatasetClusteringRawDataV1(datasetId, workspaceId, clusteringRawDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {SimilarityRequest} similarityRequest 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetClusteringSimilarityV1(datasetId: string, workspaceId: string, similarityRequest: SimilarityRequest, embeddingId?: string, options?: any): AxiosPromise<ClusteringSimilarityResponseV1> {
            return localVarFp.getDatasetClusteringSimilarityV1(datasetId, workspaceId, similarityRequest, embeddingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns clustering status for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetClusteringStateV1(datasetId: string, workspaceId: string, options?: any): AxiosPromise<ClusteringStateResponse> {
            return localVarFp.getDatasetClusteringStateV1(datasetId, workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns clustering information for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {string} [embeddingsTaskletId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetClusteringV3(datasetId: string, workspaceId: string, embeddingId?: string, embeddingsTaskletId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.getDatasetClusteringV3(datasetId, workspaceId, embeddingId, embeddingsTaskletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the embeddings for given dataset id
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbeddingsListV1(workspaceId: string, datasetId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options?: any): AxiosPromise<ListEmbeddingsResponse> {
            return localVarFp.getEmbeddingsListV1(workspaceId, datasetId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeDemoArtifacts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns if index file is available for similarity.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexFileExists(datasetId: string, workspaceId: string, embeddingId?: string, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.indexFileExists(datasetId, workspaceId, embeddingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks status of the search pod for given dataset_id
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPodStatus(datasetId: string, workspaceId: string, embeddingId?: string, options?: any): AxiosPromise<SearchPodStatusResponse> {
            return localVarFp.searchPodStatus(datasetId, workspaceId, embeddingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spinPodForClusteringSimilarity(datasetId: string, workspaceId: string, embeddingId?: string, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.spinPodForClusteringSimilarity(datasetId, workspaceId, embeddingId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClusteringRoutesApi - object-oriented interface
 * @export
 * @class ClusteringRoutesApi
 * @extends {BaseAPI}
 */
export class ClusteringRoutesApi extends BaseAPI {
    /**
     * 
     * @summary Deletes Embedding for given embedding id
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {string} embeddingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusteringRoutesApi
     */
    public deleteEmbeddingV1(datasetId: string, workspaceId: string, embeddingId: string, options?: AxiosRequestConfig) {
        return ClusteringRoutesApiFp(this.configuration).deleteEmbeddingV1(datasetId, workspaceId, embeddingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns raw data for clustering record ids
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {ClusteringRawDataRequest} clusteringRawDataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusteringRoutesApi
     */
    public getDatasetClusteringRawDataV1(datasetId: string, workspaceId: string, clusteringRawDataRequest: ClusteringRawDataRequest, options?: AxiosRequestConfig) {
        return ClusteringRoutesApiFp(this.configuration).getDatasetClusteringRawDataV1(datasetId, workspaceId, clusteringRawDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns similar points in the cluster for the dataset.
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {SimilarityRequest} similarityRequest 
     * @param {string} [embeddingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusteringRoutesApi
     */
    public getDatasetClusteringSimilarityV1(datasetId: string, workspaceId: string, similarityRequest: SimilarityRequest, embeddingId?: string, options?: AxiosRequestConfig) {
        return ClusteringRoutesApiFp(this.configuration).getDatasetClusteringSimilarityV1(datasetId, workspaceId, similarityRequest, embeddingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns clustering status for the dataset.
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusteringRoutesApi
     */
    public getDatasetClusteringStateV1(datasetId: string, workspaceId: string, options?: AxiosRequestConfig) {
        return ClusteringRoutesApiFp(this.configuration).getDatasetClusteringStateV1(datasetId, workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns clustering information for the dataset.
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {string} [embeddingId] 
     * @param {string} [embeddingsTaskletId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusteringRoutesApi
     */
    public getDatasetClusteringV3(datasetId: string, workspaceId: string, embeddingId?: string, embeddingsTaskletId?: string, options?: AxiosRequestConfig) {
        return ClusteringRoutesApiFp(this.configuration).getDatasetClusteringV3(datasetId, workspaceId, embeddingId, embeddingsTaskletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the embeddings for given dataset id
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusteringRoutesApi
     */
    public getEmbeddingsListV1(workspaceId: string, datasetId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options?: AxiosRequestConfig) {
        return ClusteringRoutesApiFp(this.configuration).getEmbeddingsListV1(workspaceId, datasetId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeDemoArtifacts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns if index file is available for similarity.
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {string} [embeddingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusteringRoutesApi
     */
    public indexFileExists(datasetId: string, workspaceId: string, embeddingId?: string, options?: AxiosRequestConfig) {
        return ClusteringRoutesApiFp(this.configuration).indexFileExists(datasetId, workspaceId, embeddingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks status of the search pod for given dataset_id
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {string} [embeddingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusteringRoutesApi
     */
    public searchPodStatus(datasetId: string, workspaceId: string, embeddingId?: string, options?: AxiosRequestConfig) {
        return ClusteringRoutesApiFp(this.configuration).searchPodStatus(datasetId, workspaceId, embeddingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns similar points in the cluster for the dataset.
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {string} [embeddingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusteringRoutesApi
     */
    public spinPodForClusteringSimilarity(datasetId: string, workspaceId: string, embeddingId?: string, options?: AxiosRequestConfig) {
        return ClusteringRoutesApiFp(this.configuration).spinPodForClusteringSimilarity(datasetId, workspaceId, embeddingId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommentsRoutesApi - axios parameter creator
 * @export
 */
export const CommentsRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add comment to the conversation, create a conversation first and add comment to it, if not exists
         * @summary Add comment to the conversation, create a conversation first and add comment to it, if not exists
         * @param {string} workspaceId 
         * @param {AddCommentRequest} addCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentToConversationV1: async (workspaceId: string, addCommentRequest: AddCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addCommentToConversationV1', 'workspaceId', workspaceId)
            // verify required parameter 'addCommentRequest' is not null or undefined
            assertParamExists('addCommentToConversationV1', 'addCommentRequest', addCommentRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/collaborations/conversations/comment`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete comment from a conversation
         * @summary Delete comment from a conversation
         * @param {string} workspaceId 
         * @param {string} conversationId 
         * @param {string} commentId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentV1: async (workspaceId: string, conversationId: string, commentId: string, resourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteCommentV1', 'workspaceId', workspaceId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('deleteCommentV1', 'conversationId', conversationId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteCommentV1', 'commentId', commentId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('deleteCommentV1', 'resourceId', resourceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/collaborations/conversations/{conversation_id}/comments/{comment_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceId !== undefined) {
                localVarQueryParameter['resource_id'] = resourceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit a comment in a conversation
         * @summary Edit a comment in a conversation
         * @param {string} workspaceId 
         * @param {string} conversationId 
         * @param {string} commentId 
         * @param {EditCommentRequestModel} editCommentRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCommentV1: async (workspaceId: string, conversationId: string, commentId: string, editCommentRequestModel: EditCommentRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('editCommentV1', 'workspaceId', workspaceId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('editCommentV1', 'conversationId', conversationId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('editCommentV1', 'commentId', commentId)
            // verify required parameter 'editCommentRequestModel' is not null or undefined
            assertParamExists('editCommentV1', 'editCommentRequestModel', editCommentRequestModel)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/collaborations/conversations/{conversation_id}/comments/{comment_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editCommentRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Conversations
         * @summary Get all Conversations
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {CommentResourceTypes} resourceType 
         * @param {ConversationProjections} [projection] 
         * @param {Array<string>} [locationKeys] 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConversationsV1: async (workspaceId: string, resourceId: string, resourceType: CommentResourceTypes, projection?: ConversationProjections, locationKeys?: Array<string>, start?: number, end?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAllConversationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getAllConversationsV1', 'resourceId', resourceId)
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('getAllConversationsV1', 'resourceType', resourceType)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/collaborations/conversations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceId !== undefined) {
                localVarQueryParameter['resource_id'] = resourceId;
            }

            if (resourceType !== undefined) {
                localVarQueryParameter['resource_type'] = resourceType;
            }

            if (projection !== undefined) {
                localVarQueryParameter['projection'] = projection;
            }

            if (locationKeys) {
                localVarQueryParameter['location_keys'] = locationKeys;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Comments Info
         * @summary Get Comments Info
         * @param {string} workspaceId 
         * @param {string} conversationId 
         * @param {string} resourceId 
         * @param {CommentResourceTypes} resourceType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationCommentsV1: async (workspaceId: string, conversationId: string, resourceId: string, resourceType: CommentResourceTypes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getConversationCommentsV1', 'workspaceId', workspaceId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getConversationCommentsV1', 'conversationId', conversationId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getConversationCommentsV1', 'resourceId', resourceId)
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('getConversationCommentsV1', 'resourceType', resourceType)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/collaborations/conversations/{conversation_id}/comments`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceId !== undefined) {
                localVarQueryParameter['resource_id'] = resourceId;
            }

            if (resourceType !== undefined) {
                localVarQueryParameter['resource_type'] = resourceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set Resolve status for a conversation
         * @summary Set Resolve status for a  conversation
         * @param {string} workspaceId 
         * @param {string} conversationId 
         * @param {UpdateCommentConversationRequest} updateCommentConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResolveStatusOfConversationV1: async (workspaceId: string, conversationId: string, updateCommentConversationRequest: UpdateCommentConversationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateResolveStatusOfConversationV1', 'workspaceId', workspaceId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('updateResolveStatusOfConversationV1', 'conversationId', conversationId)
            // verify required parameter 'updateCommentConversationRequest' is not null or undefined
            assertParamExists('updateResolveStatusOfConversationV1', 'updateCommentConversationRequest', updateCommentConversationRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/collaborations/conversations/{conversation_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommentConversationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentsRoutesApi - functional programming interface
 * @export
 */
export const CommentsRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentsRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Add comment to the conversation, create a conversation first and add comment to it, if not exists
         * @summary Add comment to the conversation, create a conversation first and add comment to it, if not exists
         * @param {string} workspaceId 
         * @param {AddCommentRequest} addCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCommentToConversationV1(workspaceId: string, addCommentRequest: AddCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicesViennaApiModelsCommentsCommentModelsAddCommentToConversationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCommentToConversationV1(workspaceId, addCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete comment from a conversation
         * @summary Delete comment from a conversation
         * @param {string} workspaceId 
         * @param {string} conversationId 
         * @param {string} commentId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommentV1(workspaceId: string, conversationId: string, commentId: string, resourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteCommentFromConversationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommentV1(workspaceId, conversationId, commentId, resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit a comment in a conversation
         * @summary Edit a comment in a conversation
         * @param {string} workspaceId 
         * @param {string} conversationId 
         * @param {string} commentId 
         * @param {EditCommentRequestModel} editCommentRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editCommentV1(workspaceId: string, conversationId: string, commentId: string, editCommentRequestModel: EditCommentRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditCommentResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editCommentV1(workspaceId, conversationId, commentId, editCommentRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Conversations
         * @summary Get all Conversations
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {CommentResourceTypes} resourceType 
         * @param {ConversationProjections} [projection] 
         * @param {Array<string>} [locationKeys] 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllConversationsV1(workspaceId: string, resourceId: string, resourceType: CommentResourceTypes, projection?: ConversationProjections, locationKeys?: Array<string>, start?: number, end?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllConversationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllConversationsV1(workspaceId, resourceId, resourceType, projection, locationKeys, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Comments Info
         * @summary Get Comments Info
         * @param {string} workspaceId 
         * @param {string} conversationId 
         * @param {string} resourceId 
         * @param {CommentResourceTypes} resourceType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationCommentsV1(workspaceId: string, conversationId: string, resourceId: string, resourceType: CommentResourceTypes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommentsDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationCommentsV1(workspaceId, conversationId, resourceId, resourceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set Resolve status for a conversation
         * @summary Set Resolve status for a  conversation
         * @param {string} workspaceId 
         * @param {string} conversationId 
         * @param {UpdateCommentConversationRequest} updateCommentConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateResolveStatusOfConversationV1(workspaceId: string, conversationId: string, updateCommentConversationRequest: UpdateCommentConversationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCommentConversationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateResolveStatusOfConversationV1(workspaceId, conversationId, updateCommentConversationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommentsRoutesApi - factory interface
 * @export
 */
export const CommentsRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentsRoutesApiFp(configuration)
    return {
        /**
         * Add comment to the conversation, create a conversation first and add comment to it, if not exists
         * @summary Add comment to the conversation, create a conversation first and add comment to it, if not exists
         * @param {string} workspaceId 
         * @param {AddCommentRequest} addCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentToConversationV1(workspaceId: string, addCommentRequest: AddCommentRequest, options?: any): AxiosPromise<ServicesViennaApiModelsCommentsCommentModelsAddCommentToConversationResponse> {
            return localVarFp.addCommentToConversationV1(workspaceId, addCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete comment from a conversation
         * @summary Delete comment from a conversation
         * @param {string} workspaceId 
         * @param {string} conversationId 
         * @param {string} commentId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentV1(workspaceId: string, conversationId: string, commentId: string, resourceId: string, options?: any): AxiosPromise<DeleteCommentFromConversationResponse> {
            return localVarFp.deleteCommentV1(workspaceId, conversationId, commentId, resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit a comment in a conversation
         * @summary Edit a comment in a conversation
         * @param {string} workspaceId 
         * @param {string} conversationId 
         * @param {string} commentId 
         * @param {EditCommentRequestModel} editCommentRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCommentV1(workspaceId: string, conversationId: string, commentId: string, editCommentRequestModel: EditCommentRequestModel, options?: any): AxiosPromise<EditCommentResponseModel> {
            return localVarFp.editCommentV1(workspaceId, conversationId, commentId, editCommentRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Conversations
         * @summary Get all Conversations
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {CommentResourceTypes} resourceType 
         * @param {ConversationProjections} [projection] 
         * @param {Array<string>} [locationKeys] 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConversationsV1(workspaceId: string, resourceId: string, resourceType: CommentResourceTypes, projection?: ConversationProjections, locationKeys?: Array<string>, start?: number, end?: number, options?: any): AxiosPromise<GetAllConversationsResponse> {
            return localVarFp.getAllConversationsV1(workspaceId, resourceId, resourceType, projection, locationKeys, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Comments Info
         * @summary Get Comments Info
         * @param {string} workspaceId 
         * @param {string} conversationId 
         * @param {string} resourceId 
         * @param {CommentResourceTypes} resourceType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationCommentsV1(workspaceId: string, conversationId: string, resourceId: string, resourceType: CommentResourceTypes, options?: any): AxiosPromise<GetCommentsDetailsResponse> {
            return localVarFp.getConversationCommentsV1(workspaceId, conversationId, resourceId, resourceType, options).then((request) => request(axios, basePath));
        },
        /**
         * Set Resolve status for a conversation
         * @summary Set Resolve status for a  conversation
         * @param {string} workspaceId 
         * @param {string} conversationId 
         * @param {UpdateCommentConversationRequest} updateCommentConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResolveStatusOfConversationV1(workspaceId: string, conversationId: string, updateCommentConversationRequest: UpdateCommentConversationRequest, options?: any): AxiosPromise<UpdateCommentConversationResponse> {
            return localVarFp.updateResolveStatusOfConversationV1(workspaceId, conversationId, updateCommentConversationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentsRoutesApi - object-oriented interface
 * @export
 * @class CommentsRoutesApi
 * @extends {BaseAPI}
 */
export class CommentsRoutesApi extends BaseAPI {
    /**
     * Add comment to the conversation, create a conversation first and add comment to it, if not exists
     * @summary Add comment to the conversation, create a conversation first and add comment to it, if not exists
     * @param {string} workspaceId 
     * @param {AddCommentRequest} addCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsRoutesApi
     */
    public addCommentToConversationV1(workspaceId: string, addCommentRequest: AddCommentRequest, options?: AxiosRequestConfig) {
        return CommentsRoutesApiFp(this.configuration).addCommentToConversationV1(workspaceId, addCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete comment from a conversation
     * @summary Delete comment from a conversation
     * @param {string} workspaceId 
     * @param {string} conversationId 
     * @param {string} commentId 
     * @param {string} resourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsRoutesApi
     */
    public deleteCommentV1(workspaceId: string, conversationId: string, commentId: string, resourceId: string, options?: AxiosRequestConfig) {
        return CommentsRoutesApiFp(this.configuration).deleteCommentV1(workspaceId, conversationId, commentId, resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit a comment in a conversation
     * @summary Edit a comment in a conversation
     * @param {string} workspaceId 
     * @param {string} conversationId 
     * @param {string} commentId 
     * @param {EditCommentRequestModel} editCommentRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsRoutesApi
     */
    public editCommentV1(workspaceId: string, conversationId: string, commentId: string, editCommentRequestModel: EditCommentRequestModel, options?: AxiosRequestConfig) {
        return CommentsRoutesApiFp(this.configuration).editCommentV1(workspaceId, conversationId, commentId, editCommentRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Conversations
     * @summary Get all Conversations
     * @param {string} workspaceId 
     * @param {string} resourceId 
     * @param {CommentResourceTypes} resourceType 
     * @param {ConversationProjections} [projection] 
     * @param {Array<string>} [locationKeys] 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsRoutesApi
     */
    public getAllConversationsV1(workspaceId: string, resourceId: string, resourceType: CommentResourceTypes, projection?: ConversationProjections, locationKeys?: Array<string>, start?: number, end?: number, options?: AxiosRequestConfig) {
        return CommentsRoutesApiFp(this.configuration).getAllConversationsV1(workspaceId, resourceId, resourceType, projection, locationKeys, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Comments Info
     * @summary Get Comments Info
     * @param {string} workspaceId 
     * @param {string} conversationId 
     * @param {string} resourceId 
     * @param {CommentResourceTypes} resourceType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsRoutesApi
     */
    public getConversationCommentsV1(workspaceId: string, conversationId: string, resourceId: string, resourceType: CommentResourceTypes, options?: AxiosRequestConfig) {
        return CommentsRoutesApiFp(this.configuration).getConversationCommentsV1(workspaceId, conversationId, resourceId, resourceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set Resolve status for a conversation
     * @summary Set Resolve status for a  conversation
     * @param {string} workspaceId 
     * @param {string} conversationId 
     * @param {UpdateCommentConversationRequest} updateCommentConversationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsRoutesApi
     */
    public updateResolveStatusOfConversationV1(workspaceId: string, conversationId: string, updateCommentConversationRequest: UpdateCommentConversationRequest, options?: AxiosRequestConfig) {
        return CommentsRoutesApiFp(this.configuration).updateResolveStatusOfConversationV1(workspaceId, conversationId, updateCommentConversationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConnectorRoutesApi - axios parameter creator
 * @export
 */
export const ConnectorRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create presigned url for bulk resource upload
         * @summary Create presigned urls for bulk resource upload
         * @param {string} workspaceId 
         * @param {CreateMultipleResourceUploadRequest} createMultipleResourceUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addResourceUploadV2: async (workspaceId: string, createMultipleResourceUploadRequest: CreateMultipleResourceUploadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addResourceUploadV2', 'workspaceId', workspaceId)
            // verify required parameter 'createMultipleResourceUploadRequest' is not null or undefined
            assertParamExists('addResourceUploadV2', 'createMultipleResourceUploadRequest', createMultipleResourceUploadRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/resources/create-folder-resource-upload--url`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMultipleResourceUploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save/Bookmark Resource
         * @summary Save/Bookmark Resource
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {AddResourceRequest} addResourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addResourcesV1: async (workspaceId: string, connectorId: string, addResourceRequest: AddResourceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addResourcesV1', 'workspaceId', workspaceId)
            // verify required parameter 'connectorId' is not null or undefined
            assertParamExists('addResourcesV1', 'connectorId', connectorId)
            // verify required parameter 'addResourceRequest' is not null or undefined
            assertParamExists('addResourcesV1', 'addResourceRequest', addResourceRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/connector/{connector_id}/add-new-resource`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"connector_id"}}`, encodeURIComponent(String(connectorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addResourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create presigned url for resource upload
         * @summary Create presigned url for resource upload
         * @param {string} workspaceId 
         * @param {CreateUploadResourceRequest} createUploadResourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUploadResourceV1: async (workspaceId: string, createUploadResourceRequest: CreateUploadResourceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addUploadResourceV1', 'workspaceId', workspaceId)
            // verify required parameter 'createUploadResourceRequest' is not null or undefined
            assertParamExists('addUploadResourceV1', 'createUploadResourceRequest', createUploadResourceRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/resources/create-upload-resource-url`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUploadResourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Completes multi part upload for a resource
         * @summary Completes multi part upload for a resource
         * @param {string} workspaceId 
         * @param {ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest} servicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeUploadResourceV1: async (workspaceId: string, servicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest: ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('completeUploadResourceV1', 'workspaceId', workspaceId)
            // verify required parameter 'servicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest' is not null or undefined
            assertParamExists('completeUploadResourceV1', 'servicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest', servicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/resources/complete-multipart-upload`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(servicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Completes multi part upload for a resource
         * @summary Completes multi part upload for a resource
         * @param {string} workspaceId 
         * @param {CompleteFolderMultipartUploadRequest} completeFolderMultipartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeUploadResourceV2: async (workspaceId: string, completeFolderMultipartUploadRequest: CompleteFolderMultipartUploadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('completeUploadResourceV2', 'workspaceId', workspaceId)
            // verify required parameter 'completeFolderMultipartUploadRequest' is not null or undefined
            assertParamExists('completeUploadResourceV2', 'completeFolderMultipartUploadRequest', completeFolderMultipartUploadRequest)
            const localVarPath = `/vienna/v2/workspace/{workspace_id}/resources/complete-multipart-upload`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeFolderMultipartUploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return authorisation url
         * @summary Return consent url consisting of scope, redirect uri 
         * @param {string} workspaceId 
         * @param {string} source 
         * @param {string} scope 
         * @param {StorageType} connectorType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consentUrlV1: async (workspaceId: string, source: string, scope: string, connectorType: StorageType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('consentUrlV1', 'workspaceId', workspaceId)
            // verify required parameter 'source' is not null or undefined
            assertParamExists('consentUrlV1', 'source', source)
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('consentUrlV1', 'scope', scope)
            // verify required parameter 'connectorType' is not null or undefined
            assertParamExists('consentUrlV1', 'connectorType', connectorType)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/consent-url`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (connectorType !== undefined) {
                localVarQueryParameter['connector_type'] = connectorType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new connector
         * @summary Create new connector
         * @param {string} workspaceId 
         * @param {CreateConnectorRequest} createConnectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnectorV1: async (workspaceId: string, createConnectorRequest: CreateConnectorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createConnectorV1', 'workspaceId', workspaceId)
            // verify required parameter 'createConnectorRequest' is not null or undefined
            assertParamExists('createConnectorV1', 'createConnectorRequest', createConnectorRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/connector/create-connector`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConnectorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns airbyte types class to compare and generate connector type in creation request
         * @summary Returns airbyte types class to compare and generate connector type in creation request
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirbyteConnectorTypeV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAirbyteConnectorTypeV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/connector/get-airbyte-storage`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get metadata details of an airbyte job
         * @summary Return metadata details of an airbyte job
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirbyteJobDetailsV1: async (workspaceId: string, resourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAirbyteJobDetailsV1', 'workspaceId', workspaceId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getAirbyteJobDetailsV1', 'resourceId', resourceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/resources/{resource_id}/job-id`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Json form to create any airbyte based connector
         * @summary Get Json form to create any airbyte based connector
         * @param {string} workspaceId 
         * @param {StorageType} connectorType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorFormV1: async (workspaceId: string, connectorType: StorageType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getConnectorFormV1', 'workspaceId', workspaceId)
            // verify required parameter 'connectorType' is not null or undefined
            assertParamExists('getConnectorFormV1', 'connectorType', connectorType)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/connector/{connector_type}/get-form`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"connector_type"}}`, encodeURIComponent(String(connectorType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get token for frontend actions for connector
         * @summary Get token for frontend actions for connector
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorTokenV1: async (workspaceId: string, connectorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getConnectorTokenV1', 'workspaceId', workspaceId)
            // verify required parameter 'connectorId' is not null or undefined
            assertParamExists('getConnectorTokenV1', 'connectorId', connectorId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/connector/{connector_id}/token`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"connector_id"}}`, encodeURIComponent(String(connectorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns connector
         * @summary Returns connector
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorV1: async (workspaceId: string, connectorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getConnectorV1', 'workspaceId', workspaceId)
            // verify required parameter 'connectorId' is not null or undefined
            assertParamExists('getConnectorV1', 'connectorId', connectorId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/connectors/{connector_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"connector_id"}}`, encodeURIComponent(String(connectorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get metadata of a resource
         * @summary Return metadata details of a resource
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderResourceFilesV1: async (workspaceId: string, resourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getFolderResourceFilesV1', 'workspaceId', workspaceId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getFolderResourceFilesV1', 'resourceId', resourceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/folder-resources/{resource_id}/files`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get metadata of a resource
         * @summary Return metadata details of a resource
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceDetailsV1: async (workspaceId: string, resourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getResourceDetailsV1', 'workspaceId', workspaceId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getResourceDetailsV1', 'resourceId', resourceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/resources/{resource_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns file preview for selected resource
         * @summary Returns Streaming file preview for selected resource
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceFilePreviewV1: async (workspaceId: string, workflowId: string, filePath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getResourceFilePreviewV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getResourceFilePreviewV1', 'workflowId', workflowId)
            // verify required parameter 'filePath' is not null or undefined
            assertParamExists('getResourceFilePreviewV1', 'filePath', filePath)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/file-preview`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workflowId !== undefined) {
                localVarQueryParameter['workflow_id'] = workflowId;
            }

            if (filePath !== undefined) {
                localVarQueryParameter['file_path'] = filePath;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns preview for selected resource
         * @summary Returns preview for selected resource
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {string} [fileKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourcePreviewV1: async (workspaceId: string, resourceId: string, fileKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getResourcePreviewV1', 'workspaceId', workspaceId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getResourcePreviewV1', 'resourceId', resourceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/resource/{resource_id}/preview`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fileKey !== undefined) {
                localVarQueryParameter['file_key'] = fileKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns preview for selected resource
         * @summary Returns preview for selected resource
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {string} [fileKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourcePreviewV2: async (workspaceId: string, resourceId: string, fileKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getResourcePreviewV2', 'workspaceId', workspaceId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getResourcePreviewV2', 'resourceId', resourceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/resource/{resource_id}/preview-url`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fileKey !== undefined) {
                localVarQueryParameter['file_key'] = fileKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns created connector data
         * @summary Exchange auth code for tokens in oauth flow
         * @param {string} workspaceId 
         * @param {IDPAuthExchangeRequest} iDPAuthExchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iDPExchangeAuthCodeV2: async (workspaceId: string, iDPAuthExchangeRequest: IDPAuthExchangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('iDPExchangeAuthCodeV2', 'workspaceId', workspaceId)
            // verify required parameter 'iDPAuthExchangeRequest' is not null or undefined
            assertParamExists('iDPExchangeAuthCodeV2', 'iDPAuthExchangeRequest', iDPAuthExchangeRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/connector/idp-code-exchange`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iDPAuthExchangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns airbyte types class to compare and generate connector type in creation request
         * @summary Returns if the given folder name is allowed
         * @param {string} workspaceId 
         * @param {string} folderName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isFoldernameAllowedV1: async (workspaceId: string, folderName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('isFoldernameAllowedV1', 'workspaceId', workspaceId)
            // verify required parameter 'folderName' is not null or undefined
            assertParamExists('isFoldernameAllowedV1', 'folderName', folderName)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/connector/is-folder-name-available`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (folderName !== undefined) {
                localVarQueryParameter['folder_name'] = folderName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list of added resources present for connector
         * @summary Returns list of all added resources
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAddedResourcesV1: async (workspaceId: string, start?: number, end?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listAddedResourcesV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/get-added-resources`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list of added resources present for connectors
         * @summary Returns list of all added connector resources
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {boolean} [isDataSink] This param controls if connector resources should be of data sink type or not. Default is None which returns all the resources irrespective of data sink type.
         * @param {Array<StorageType>} [connectorTypes] This param filters only the selected connector_types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectorResourcesV2: async (workspaceId: string, start?: number, end?: number, isDataSink?: boolean, connectorTypes?: Array<StorageType>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listConnectorResourcesV2', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v2/workspace/{workspace_id}/connector/resource/list`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (isDataSink !== undefined) {
                localVarQueryParameter['is_data_sink'] = isDataSink;
            }

            if (connectorTypes) {
                localVarQueryParameter['connector_types'] = connectorTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list of added connector resources for the workspace
         * @summary Return list of all added connector resources for the workspace
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectorResourcesV3: async (workspaceId: string, listingAPIParams: ListingAPIParams, searchQuery?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listConnectorResourcesV3', 'workspaceId', workspaceId)
            // verify required parameter 'listingAPIParams' is not null or undefined
            assertParamExists('listConnectorResourcesV3', 'listingAPIParams', listingAPIParams)
            const localVarPath = `/vienna/v3/workspace/{workspace_id}/connector/resource/list`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchQuery !== undefined) {
                localVarQueryParameter['search_query'] = searchQuery;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingAPIParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list of connectors
         * @summary Returns list of connectors
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {StorageType} [storageType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectorV1: async (workspaceId: string, start?: number, end?: number, storageType?: StorageType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listConnectorV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/connectors`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (storageType !== undefined) {
                localVarQueryParameter['storage_type'] = storageType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list of connectors for the workspace.
         * @summary Return list of connectors for the workspace.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectorsV2: async (workspaceId: string, listingAPIParams: ListingAPIParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listConnectorsV2', 'workspaceId', workspaceId)
            // verify required parameter 'listingAPIParams' is not null or undefined
            assertParamExists('listConnectorsV2', 'listingAPIParams', listingAPIParams)
            const localVarPath = `/vienna/v2/workspace/{workspace_id}/connector/list`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingAPIParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns preview for selected resource
         * @summary Returns preview for selected resource
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {ListResourceRequest} listResourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listResourcesPreviewV1: async (workspaceId: string, connectorId: string, listResourceRequest: ListResourceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listResourcesPreviewV1', 'workspaceId', workspaceId)
            // verify required parameter 'connectorId' is not null or undefined
            assertParamExists('listResourcesPreviewV1', 'connectorId', connectorId)
            // verify required parameter 'listResourceRequest' is not null or undefined
            assertParamExists('listResourcesPreviewV1', 'listResourceRequest', listResourceRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/connector/{connector_id}/preview`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"connector_id"}}`, encodeURIComponent(String(connectorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listResourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list of resources present for connector
         * @summary Returns list of all resources
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {ListResourceRequest} listResourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listResourcesV1: async (workspaceId: string, connectorId: string, listResourceRequest: ListResourceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listResourcesV1', 'workspaceId', workspaceId)
            // verify required parameter 'connectorId' is not null or undefined
            assertParamExists('listResourcesV1', 'connectorId', connectorId)
            // verify required parameter 'listResourceRequest' is not null or undefined
            assertParamExists('listResourcesV1', 'listResourceRequest', listResourceRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/connector/{connector_id}/get-all-resources`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"connector_id"}}`, encodeURIComponent(String(connectorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listResourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Syncs airbyte based resources data by triggering a new job run
         * @summary Syncs airbyte based resources data
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAirbyteResourceV1: async (workspaceId: string, resourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('syncAirbyteResourceV1', 'workspaceId', workspaceId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('syncAirbyteResourceV1', 'resourceId', resourceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/connector/{connector_id}/resource/{resource_id}/sync`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates airbyte source start date
         * @summary Updates airbyte source start date
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {UpdateAirbyteSourceStartDateRequest} updateAirbyteSourceStartDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceStartDateV1: async (workspaceId: string, resourceId: string, updateAirbyteSourceStartDateRequest: UpdateAirbyteSourceStartDateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateResourceStartDateV1', 'workspaceId', workspaceId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('updateResourceStartDateV1', 'resourceId', resourceId)
            // verify required parameter 'updateAirbyteSourceStartDateRequest' is not null or undefined
            assertParamExists('updateResourceStartDateV1', 'updateAirbyteSourceStartDateRequest', updateAirbyteSourceStartDateRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/connector/{connector_id}/resource/{resource_id}/start-date`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAirbyteSourceStartDateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectorRoutesApi - functional programming interface
 * @export
 */
export const ConnectorRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectorRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create presigned url for bulk resource upload
         * @summary Create presigned urls for bulk resource upload
         * @param {string} workspaceId 
         * @param {CreateMultipleResourceUploadRequest} createMultipleResourceUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addResourceUploadV2(workspaceId: string, createMultipleResourceUploadRequest: CreateMultipleResourceUploadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMultipleResourceUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addResourceUploadV2(workspaceId, createMultipleResourceUploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Save/Bookmark Resource
         * @summary Save/Bookmark Resource
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {AddResourceRequest} addResourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addResourcesV1(workspaceId: string, connectorId: string, addResourceRequest: AddResourceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addResourcesV1(workspaceId, connectorId, addResourceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create presigned url for resource upload
         * @summary Create presigned url for resource upload
         * @param {string} workspaceId 
         * @param {CreateUploadResourceRequest} createUploadResourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUploadResourceV1(workspaceId: string, createUploadResourceRequest: CreateUploadResourceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUploadResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUploadResourceV1(workspaceId, createUploadResourceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Completes multi part upload for a resource
         * @summary Completes multi part upload for a resource
         * @param {string} workspaceId 
         * @param {ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest} servicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeUploadResourceV1(workspaceId: string, servicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest: ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteMultiPartUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeUploadResourceV1(workspaceId, servicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Completes multi part upload for a resource
         * @summary Completes multi part upload for a resource
         * @param {string} workspaceId 
         * @param {CompleteFolderMultipartUploadRequest} completeFolderMultipartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeUploadResourceV2(workspaceId: string, completeFolderMultipartUploadRequest: CompleteFolderMultipartUploadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteMultiPartUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeUploadResourceV2(workspaceId, completeFolderMultipartUploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return authorisation url
         * @summary Return consent url consisting of scope, redirect uri 
         * @param {string} workspaceId 
         * @param {string} source 
         * @param {string} scope 
         * @param {StorageType} connectorType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consentUrlV1(workspaceId: string, source: string, scope: string, connectorType: StorageType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsentUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consentUrlV1(workspaceId, source, scope, connectorType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create new connector
         * @summary Create new connector
         * @param {string} workspaceId 
         * @param {CreateConnectorRequest} createConnectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConnectorV1(workspaceId: string, createConnectorRequest: CreateConnectorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateConnectorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConnectorV1(workspaceId, createConnectorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns airbyte types class to compare and generate connector type in creation request
         * @summary Returns airbyte types class to compare and generate connector type in creation request
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAirbyteConnectorTypeV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AirbyteConnectorTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAirbyteConnectorTypeV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get metadata details of an airbyte job
         * @summary Return metadata details of an airbyte job
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAirbyteJobDetailsV1(workspaceId: string, resourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AirbyteJobDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAirbyteJobDetailsV1(workspaceId, resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Json form to create any airbyte based connector
         * @summary Get Json form to create any airbyte based connector
         * @param {string} workspaceId 
         * @param {StorageType} connectorType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectorFormV1(workspaceId: string, connectorType: StorageType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectorFormResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectorFormV1(workspaceId, connectorType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get token for frontend actions for connector
         * @summary Get token for frontend actions for connector
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectorTokenV1(workspaceId: string, connectorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectorTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectorTokenV1(workspaceId, connectorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns connector
         * @summary Returns connector
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectorV1(workspaceId: string, connectorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConnectorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectorV1(workspaceId, connectorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get metadata of a resource
         * @summary Return metadata details of a resource
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolderResourceFilesV1(workspaceId: string, resourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderResourceFilesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolderResourceFilesV1(workspaceId, resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get metadata of a resource
         * @summary Return metadata details of a resource
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResourceDetailsV1(workspaceId: string, resourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResourceDetailsV1(workspaceId, resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns file preview for selected resource
         * @summary Returns Streaming file preview for selected resource
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResourceFilePreviewV1(workspaceId: string, workflowId: string, filePath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResourceFilePreviewV1(workspaceId, workflowId, filePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns preview for selected resource
         * @summary Returns preview for selected resource
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {string} [fileKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResourcePreviewV1(workspaceId: string, resourceId: string, fileKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetResourcePreviewDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResourcePreviewV1(workspaceId, resourceId, fileKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns preview for selected resource
         * @summary Returns preview for selected resource
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {string} [fileKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResourcePreviewV2(workspaceId: string, resourceId: string, fileKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetResourcePreviewUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResourcePreviewV2(workspaceId, resourceId, fileKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns created connector data
         * @summary Exchange auth code for tokens in oauth flow
         * @param {string} workspaceId 
         * @param {IDPAuthExchangeRequest} iDPAuthExchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async iDPExchangeAuthCodeV2(workspaceId: string, iDPAuthExchangeRequest: IDPAuthExchangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateConnectorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.iDPExchangeAuthCodeV2(workspaceId, iDPAuthExchangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns airbyte types class to compare and generate connector type in creation request
         * @summary Returns if the given folder name is allowed
         * @param {string} workspaceId 
         * @param {string} folderName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isFoldernameAllowedV1(workspaceId: string, folderName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isFoldernameAllowedV1(workspaceId, folderName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns list of added resources present for connector
         * @summary Returns list of all added resources
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAddedResourcesV1(workspaceId: string, start?: number, end?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResourceRecordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAddedResourcesV1(workspaceId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns list of added resources present for connectors
         * @summary Returns list of all added connector resources
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {boolean} [isDataSink] This param controls if connector resources should be of data sink type or not. Default is None which returns all the resources irrespective of data sink type.
         * @param {Array<StorageType>} [connectorTypes] This param filters only the selected connector_types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectorResourcesV2(workspaceId: string, start?: number, end?: number, isDataSink?: boolean, connectorTypes?: Array<StorageType>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResourceRecordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectorResourcesV2(workspaceId, start, end, isDataSink, connectorTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns list of added connector resources for the workspace
         * @summary Return list of all added connector resources for the workspace
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectorResourcesV3(workspaceId: string, listingAPIParams: ListingAPIParams, searchQuery?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResourceRecordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectorResourcesV3(workspaceId, listingAPIParams, searchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns list of connectors
         * @summary Returns list of connectors
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {StorageType} [storageType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectorV1(workspaceId: string, start?: number, end?: number, storageType?: StorageType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectorV1(workspaceId, start, end, storageType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns list of connectors for the workspace.
         * @summary Return list of connectors for the workspace.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectorsV2(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectorsV2(workspaceId, listingAPIParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns preview for selected resource
         * @summary Returns preview for selected resource
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {ListResourceRequest} listResourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listResourcesPreviewV1(workspaceId: string, connectorId: string, listResourceRequest: ListResourceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetResourcePreviewDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listResourcesPreviewV1(workspaceId, connectorId, listResourceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns list of resources present for connector
         * @summary Returns list of all resources
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {ListResourceRequest} listResourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listResourcesV1(workspaceId: string, connectorId: string, listResourceRequest: ListResourceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listResourcesV1(workspaceId, connectorId, listResourceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Syncs airbyte based resources data by triggering a new job run
         * @summary Syncs airbyte based resources data
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAirbyteResourceV1(workspaceId: string, resourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncAirbyteResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAirbyteResourceV1(workspaceId, resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates airbyte source start date
         * @summary Updates airbyte source start date
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {UpdateAirbyteSourceStartDateRequest} updateAirbyteSourceStartDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateResourceStartDateV1(workspaceId: string, resourceId: string, updateAirbyteSourceStartDateRequest: UpdateAirbyteSourceStartDateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAirbyteSourceStartDateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateResourceStartDateV1(workspaceId, resourceId, updateAirbyteSourceStartDateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConnectorRoutesApi - factory interface
 * @export
 */
export const ConnectorRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectorRoutesApiFp(configuration)
    return {
        /**
         * Create presigned url for bulk resource upload
         * @summary Create presigned urls for bulk resource upload
         * @param {string} workspaceId 
         * @param {CreateMultipleResourceUploadRequest} createMultipleResourceUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addResourceUploadV2(workspaceId: string, createMultipleResourceUploadRequest: CreateMultipleResourceUploadRequest, options?: any): AxiosPromise<CreateMultipleResourceUploadResponse> {
            return localVarFp.addResourceUploadV2(workspaceId, createMultipleResourceUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Save/Bookmark Resource
         * @summary Save/Bookmark Resource
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {AddResourceRequest} addResourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addResourcesV1(workspaceId: string, connectorId: string, addResourceRequest: AddResourceRequest, options?: any): AxiosPromise<AddResourceResponse> {
            return localVarFp.addResourcesV1(workspaceId, connectorId, addResourceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create presigned url for resource upload
         * @summary Create presigned url for resource upload
         * @param {string} workspaceId 
         * @param {CreateUploadResourceRequest} createUploadResourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUploadResourceV1(workspaceId: string, createUploadResourceRequest: CreateUploadResourceRequest, options?: any): AxiosPromise<CreateUploadResourceResponse> {
            return localVarFp.addUploadResourceV1(workspaceId, createUploadResourceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Completes multi part upload for a resource
         * @summary Completes multi part upload for a resource
         * @param {string} workspaceId 
         * @param {ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest} servicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeUploadResourceV1(workspaceId: string, servicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest: ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest, options?: any): AxiosPromise<CompleteMultiPartUploadResponse> {
            return localVarFp.completeUploadResourceV1(workspaceId, servicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Completes multi part upload for a resource
         * @summary Completes multi part upload for a resource
         * @param {string} workspaceId 
         * @param {CompleteFolderMultipartUploadRequest} completeFolderMultipartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeUploadResourceV2(workspaceId: string, completeFolderMultipartUploadRequest: CompleteFolderMultipartUploadRequest, options?: any): AxiosPromise<CompleteMultiPartUploadResponse> {
            return localVarFp.completeUploadResourceV2(workspaceId, completeFolderMultipartUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Return authorisation url
         * @summary Return consent url consisting of scope, redirect uri 
         * @param {string} workspaceId 
         * @param {string} source 
         * @param {string} scope 
         * @param {StorageType} connectorType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consentUrlV1(workspaceId: string, source: string, scope: string, connectorType: StorageType, options?: any): AxiosPromise<ConsentUrlResponse> {
            return localVarFp.consentUrlV1(workspaceId, source, scope, connectorType, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new connector
         * @summary Create new connector
         * @param {string} workspaceId 
         * @param {CreateConnectorRequest} createConnectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnectorV1(workspaceId: string, createConnectorRequest: CreateConnectorRequest, options?: any): AxiosPromise<CreateConnectorResponse> {
            return localVarFp.createConnectorV1(workspaceId, createConnectorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns airbyte types class to compare and generate connector type in creation request
         * @summary Returns airbyte types class to compare and generate connector type in creation request
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirbyteConnectorTypeV1(workspaceId: string, options?: any): AxiosPromise<AirbyteConnectorTypeResponse> {
            return localVarFp.getAirbyteConnectorTypeV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get metadata details of an airbyte job
         * @summary Return metadata details of an airbyte job
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirbyteJobDetailsV1(workspaceId: string, resourceId: string, options?: any): AxiosPromise<AirbyteJobDetailsResponse> {
            return localVarFp.getAirbyteJobDetailsV1(workspaceId, resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Json form to create any airbyte based connector
         * @summary Get Json form to create any airbyte based connector
         * @param {string} workspaceId 
         * @param {StorageType} connectorType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorFormV1(workspaceId: string, connectorType: StorageType, options?: any): AxiosPromise<ConnectorFormResponse> {
            return localVarFp.getConnectorFormV1(workspaceId, connectorType, options).then((request) => request(axios, basePath));
        },
        /**
         * Get token for frontend actions for connector
         * @summary Get token for frontend actions for connector
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorTokenV1(workspaceId: string, connectorId: string, options?: any): AxiosPromise<ConnectorTokenResponse> {
            return localVarFp.getConnectorTokenV1(workspaceId, connectorId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns connector
         * @summary Returns connector
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorV1(workspaceId: string, connectorId: string, options?: any): AxiosPromise<GetConnectorResponse> {
            return localVarFp.getConnectorV1(workspaceId, connectorId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get metadata of a resource
         * @summary Return metadata details of a resource
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderResourceFilesV1(workspaceId: string, resourceId: string, options?: any): AxiosPromise<FolderResourceFilesResponse> {
            return localVarFp.getFolderResourceFilesV1(workspaceId, resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get metadata of a resource
         * @summary Return metadata details of a resource
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceDetailsV1(workspaceId: string, resourceId: string, options?: any): AxiosPromise<ResourceModel> {
            return localVarFp.getResourceDetailsV1(workspaceId, resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns file preview for selected resource
         * @summary Returns Streaming file preview for selected resource
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceFilePreviewV1(workspaceId: string, workflowId: string, filePath: string, options?: any): AxiosPromise<any> {
            return localVarFp.getResourceFilePreviewV1(workspaceId, workflowId, filePath, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns preview for selected resource
         * @summary Returns preview for selected resource
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {string} [fileKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourcePreviewV1(workspaceId: string, resourceId: string, fileKey?: string, options?: any): AxiosPromise<GetResourcePreviewDataResponse> {
            return localVarFp.getResourcePreviewV1(workspaceId, resourceId, fileKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns preview for selected resource
         * @summary Returns preview for selected resource
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {string} [fileKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourcePreviewV2(workspaceId: string, resourceId: string, fileKey?: string, options?: any): AxiosPromise<GetResourcePreviewUrlResponse> {
            return localVarFp.getResourcePreviewV2(workspaceId, resourceId, fileKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns created connector data
         * @summary Exchange auth code for tokens in oauth flow
         * @param {string} workspaceId 
         * @param {IDPAuthExchangeRequest} iDPAuthExchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iDPExchangeAuthCodeV2(workspaceId: string, iDPAuthExchangeRequest: IDPAuthExchangeRequest, options?: any): AxiosPromise<CreateConnectorResponse> {
            return localVarFp.iDPExchangeAuthCodeV2(workspaceId, iDPAuthExchangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns airbyte types class to compare and generate connector type in creation request
         * @summary Returns if the given folder name is allowed
         * @param {string} workspaceId 
         * @param {string} folderName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isFoldernameAllowedV1(workspaceId: string, folderName: string, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.isFoldernameAllowedV1(workspaceId, folderName, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list of added resources present for connector
         * @summary Returns list of all added resources
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAddedResourcesV1(workspaceId: string, start?: number, end?: number, options?: any): AxiosPromise<ListResourceRecordResponse> {
            return localVarFp.listAddedResourcesV1(workspaceId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list of added resources present for connectors
         * @summary Returns list of all added connector resources
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {boolean} [isDataSink] This param controls if connector resources should be of data sink type or not. Default is None which returns all the resources irrespective of data sink type.
         * @param {Array<StorageType>} [connectorTypes] This param filters only the selected connector_types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectorResourcesV2(workspaceId: string, start?: number, end?: number, isDataSink?: boolean, connectorTypes?: Array<StorageType>, options?: any): AxiosPromise<ListResourceRecordResponse> {
            return localVarFp.listConnectorResourcesV2(workspaceId, start, end, isDataSink, connectorTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list of added connector resources for the workspace
         * @summary Return list of all added connector resources for the workspace
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectorResourcesV3(workspaceId: string, listingAPIParams: ListingAPIParams, searchQuery?: string, options?: any): AxiosPromise<ListResourceRecordResponse> {
            return localVarFp.listConnectorResourcesV3(workspaceId, listingAPIParams, searchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list of connectors
         * @summary Returns list of connectors
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {StorageType} [storageType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectorV1(workspaceId: string, start?: number, end?: number, storageType?: StorageType, options?: any): AxiosPromise<ListConnectorResponse> {
            return localVarFp.listConnectorV1(workspaceId, start, end, storageType, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list of connectors for the workspace.
         * @summary Return list of connectors for the workspace.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectorsV2(workspaceId: string, listingAPIParams: ListingAPIParams, options?: any): AxiosPromise<ListConnectorResponse> {
            return localVarFp.listConnectorsV2(workspaceId, listingAPIParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns preview for selected resource
         * @summary Returns preview for selected resource
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {ListResourceRequest} listResourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listResourcesPreviewV1(workspaceId: string, connectorId: string, listResourceRequest: ListResourceRequest, options?: any): AxiosPromise<GetResourcePreviewDataResponse> {
            return localVarFp.listResourcesPreviewV1(workspaceId, connectorId, listResourceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list of resources present for connector
         * @summary Returns list of all resources
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {ListResourceRequest} listResourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listResourcesV1(workspaceId: string, connectorId: string, listResourceRequest: ListResourceRequest, options?: any): AxiosPromise<ListResourceResponse> {
            return localVarFp.listResourcesV1(workspaceId, connectorId, listResourceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Syncs airbyte based resources data by triggering a new job run
         * @summary Syncs airbyte based resources data
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAirbyteResourceV1(workspaceId: string, resourceId: string, options?: any): AxiosPromise<SyncAirbyteResourceResponse> {
            return localVarFp.syncAirbyteResourceV1(workspaceId, resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates airbyte source start date
         * @summary Updates airbyte source start date
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {UpdateAirbyteSourceStartDateRequest} updateAirbyteSourceStartDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceStartDateV1(workspaceId: string, resourceId: string, updateAirbyteSourceStartDateRequest: UpdateAirbyteSourceStartDateRequest, options?: any): AxiosPromise<UpdateAirbyteSourceStartDateResponse> {
            return localVarFp.updateResourceStartDateV1(workspaceId, resourceId, updateAirbyteSourceStartDateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConnectorRoutesApi - object-oriented interface
 * @export
 * @class ConnectorRoutesApi
 * @extends {BaseAPI}
 */
export class ConnectorRoutesApi extends BaseAPI {
    /**
     * Create presigned url for bulk resource upload
     * @summary Create presigned urls for bulk resource upload
     * @param {string} workspaceId 
     * @param {CreateMultipleResourceUploadRequest} createMultipleResourceUploadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public addResourceUploadV2(workspaceId: string, createMultipleResourceUploadRequest: CreateMultipleResourceUploadRequest, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).addResourceUploadV2(workspaceId, createMultipleResourceUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save/Bookmark Resource
     * @summary Save/Bookmark Resource
     * @param {string} workspaceId 
     * @param {string} connectorId 
     * @param {AddResourceRequest} addResourceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public addResourcesV1(workspaceId: string, connectorId: string, addResourceRequest: AddResourceRequest, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).addResourcesV1(workspaceId, connectorId, addResourceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create presigned url for resource upload
     * @summary Create presigned url for resource upload
     * @param {string} workspaceId 
     * @param {CreateUploadResourceRequest} createUploadResourceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public addUploadResourceV1(workspaceId: string, createUploadResourceRequest: CreateUploadResourceRequest, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).addUploadResourceV1(workspaceId, createUploadResourceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Completes multi part upload for a resource
     * @summary Completes multi part upload for a resource
     * @param {string} workspaceId 
     * @param {ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest} servicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public completeUploadResourceV1(workspaceId: string, servicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest: ServicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).completeUploadResourceV1(workspaceId, servicesViennaApiModelsConnectorModelsCompleteMultipartUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Completes multi part upload for a resource
     * @summary Completes multi part upload for a resource
     * @param {string} workspaceId 
     * @param {CompleteFolderMultipartUploadRequest} completeFolderMultipartUploadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public completeUploadResourceV2(workspaceId: string, completeFolderMultipartUploadRequest: CompleteFolderMultipartUploadRequest, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).completeUploadResourceV2(workspaceId, completeFolderMultipartUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return authorisation url
     * @summary Return consent url consisting of scope, redirect uri 
     * @param {string} workspaceId 
     * @param {string} source 
     * @param {string} scope 
     * @param {StorageType} connectorType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public consentUrlV1(workspaceId: string, source: string, scope: string, connectorType: StorageType, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).consentUrlV1(workspaceId, source, scope, connectorType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new connector
     * @summary Create new connector
     * @param {string} workspaceId 
     * @param {CreateConnectorRequest} createConnectorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public createConnectorV1(workspaceId: string, createConnectorRequest: CreateConnectorRequest, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).createConnectorV1(workspaceId, createConnectorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns airbyte types class to compare and generate connector type in creation request
     * @summary Returns airbyte types class to compare and generate connector type in creation request
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public getAirbyteConnectorTypeV1(workspaceId: string, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).getAirbyteConnectorTypeV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get metadata details of an airbyte job
     * @summary Return metadata details of an airbyte job
     * @param {string} workspaceId 
     * @param {string} resourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public getAirbyteJobDetailsV1(workspaceId: string, resourceId: string, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).getAirbyteJobDetailsV1(workspaceId, resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Json form to create any airbyte based connector
     * @summary Get Json form to create any airbyte based connector
     * @param {string} workspaceId 
     * @param {StorageType} connectorType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public getConnectorFormV1(workspaceId: string, connectorType: StorageType, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).getConnectorFormV1(workspaceId, connectorType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get token for frontend actions for connector
     * @summary Get token for frontend actions for connector
     * @param {string} workspaceId 
     * @param {string} connectorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public getConnectorTokenV1(workspaceId: string, connectorId: string, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).getConnectorTokenV1(workspaceId, connectorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns connector
     * @summary Returns connector
     * @param {string} workspaceId 
     * @param {string} connectorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public getConnectorV1(workspaceId: string, connectorId: string, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).getConnectorV1(workspaceId, connectorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get metadata of a resource
     * @summary Return metadata details of a resource
     * @param {string} workspaceId 
     * @param {string} resourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public getFolderResourceFilesV1(workspaceId: string, resourceId: string, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).getFolderResourceFilesV1(workspaceId, resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get metadata of a resource
     * @summary Return metadata details of a resource
     * @param {string} workspaceId 
     * @param {string} resourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public getResourceDetailsV1(workspaceId: string, resourceId: string, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).getResourceDetailsV1(workspaceId, resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns file preview for selected resource
     * @summary Returns Streaming file preview for selected resource
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {string} filePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public getResourceFilePreviewV1(workspaceId: string, workflowId: string, filePath: string, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).getResourceFilePreviewV1(workspaceId, workflowId, filePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns preview for selected resource
     * @summary Returns preview for selected resource
     * @param {string} workspaceId 
     * @param {string} resourceId 
     * @param {string} [fileKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public getResourcePreviewV1(workspaceId: string, resourceId: string, fileKey?: string, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).getResourcePreviewV1(workspaceId, resourceId, fileKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns preview for selected resource
     * @summary Returns preview for selected resource
     * @param {string} workspaceId 
     * @param {string} resourceId 
     * @param {string} [fileKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public getResourcePreviewV2(workspaceId: string, resourceId: string, fileKey?: string, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).getResourcePreviewV2(workspaceId, resourceId, fileKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns created connector data
     * @summary Exchange auth code for tokens in oauth flow
     * @param {string} workspaceId 
     * @param {IDPAuthExchangeRequest} iDPAuthExchangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public iDPExchangeAuthCodeV2(workspaceId: string, iDPAuthExchangeRequest: IDPAuthExchangeRequest, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).iDPExchangeAuthCodeV2(workspaceId, iDPAuthExchangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns airbyte types class to compare and generate connector type in creation request
     * @summary Returns if the given folder name is allowed
     * @param {string} workspaceId 
     * @param {string} folderName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public isFoldernameAllowedV1(workspaceId: string, folderName: string, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).isFoldernameAllowedV1(workspaceId, folderName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list of added resources present for connector
     * @summary Returns list of all added resources
     * @param {string} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public listAddedResourcesV1(workspaceId: string, start?: number, end?: number, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).listAddedResourcesV1(workspaceId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list of added resources present for connectors
     * @summary Returns list of all added connector resources
     * @param {string} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {boolean} [isDataSink] This param controls if connector resources should be of data sink type or not. Default is None which returns all the resources irrespective of data sink type.
     * @param {Array<StorageType>} [connectorTypes] This param filters only the selected connector_types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public listConnectorResourcesV2(workspaceId: string, start?: number, end?: number, isDataSink?: boolean, connectorTypes?: Array<StorageType>, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).listConnectorResourcesV2(workspaceId, start, end, isDataSink, connectorTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list of added connector resources for the workspace
     * @summary Return list of all added connector resources for the workspace
     * @param {string} workspaceId 
     * @param {ListingAPIParams} listingAPIParams 
     * @param {string} [searchQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public listConnectorResourcesV3(workspaceId: string, listingAPIParams: ListingAPIParams, searchQuery?: string, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).listConnectorResourcesV3(workspaceId, listingAPIParams, searchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list of connectors
     * @summary Returns list of connectors
     * @param {string} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {StorageType} [storageType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public listConnectorV1(workspaceId: string, start?: number, end?: number, storageType?: StorageType, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).listConnectorV1(workspaceId, start, end, storageType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list of connectors for the workspace.
     * @summary Return list of connectors for the workspace.
     * @param {string} workspaceId 
     * @param {ListingAPIParams} listingAPIParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public listConnectorsV2(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).listConnectorsV2(workspaceId, listingAPIParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns preview for selected resource
     * @summary Returns preview for selected resource
     * @param {string} workspaceId 
     * @param {string} connectorId 
     * @param {ListResourceRequest} listResourceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public listResourcesPreviewV1(workspaceId: string, connectorId: string, listResourceRequest: ListResourceRequest, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).listResourcesPreviewV1(workspaceId, connectorId, listResourceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list of resources present for connector
     * @summary Returns list of all resources
     * @param {string} workspaceId 
     * @param {string} connectorId 
     * @param {ListResourceRequest} listResourceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public listResourcesV1(workspaceId: string, connectorId: string, listResourceRequest: ListResourceRequest, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).listResourcesV1(workspaceId, connectorId, listResourceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Syncs airbyte based resources data by triggering a new job run
     * @summary Syncs airbyte based resources data
     * @param {string} workspaceId 
     * @param {string} resourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public syncAirbyteResourceV1(workspaceId: string, resourceId: string, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).syncAirbyteResourceV1(workspaceId, resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates airbyte source start date
     * @summary Updates airbyte source start date
     * @param {string} workspaceId 
     * @param {string} resourceId 
     * @param {UpdateAirbyteSourceStartDateRequest} updateAirbyteSourceStartDateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorRoutesApi
     */
    public updateResourceStartDateV1(workspaceId: string, resourceId: string, updateAirbyteSourceStartDateRequest: UpdateAirbyteSourceStartDateRequest, options?: AxiosRequestConfig) {
        return ConnectorRoutesApiFp(this.configuration).updateResourceStartDateV1(workspaceId, resourceId, updateAirbyteSourceStartDateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContentAddonsRoutesApi - axios parameter creator
 * @export
 */
export const ContentAddonsRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Text to Speech
         * @param {string} workspaceId 
         * @param {TextToSpeechRequestModel} textToSpeechRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textToSpeechV1: async (workspaceId: string, textToSpeechRequestModel: TextToSpeechRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('textToSpeechV1', 'workspaceId', workspaceId)
            // verify required parameter 'textToSpeechRequestModel' is not null or undefined
            assertParamExists('textToSpeechV1', 'textToSpeechRequestModel', textToSpeechRequestModel)
            const localVarPath = `/oslo/v1/contentaddon/texttospeech/workspace/{workspace_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(textToSpeechRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Translate Text
         * @param {string} workspaceId 
         * @param {TranslationRequestModel} translationRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translateV1: async (workspaceId: string, translationRequestModel: TranslationRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('translateV1', 'workspaceId', workspaceId)
            // verify required parameter 'translationRequestModel' is not null or undefined
            assertParamExists('translateV1', 'translationRequestModel', translationRequestModel)
            const localVarPath = `/oslo/v1/contentaddon/translate/workspace/{workspace_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translationRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Translation Supported Languages
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationSupportedLanguagesV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('translationSupportedLanguagesV1', 'workspaceId', workspaceId)
            const localVarPath = `/oslo/v1/contentaddon/translate/supportedlang/workspace/{workspace_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentAddonsRoutesApi - functional programming interface
 * @export
 */
export const ContentAddonsRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentAddonsRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Text to Speech
         * @param {string} workspaceId 
         * @param {TextToSpeechRequestModel} textToSpeechRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async textToSpeechV1(workspaceId: string, textToSpeechRequestModel: TextToSpeechRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextToSpeechResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.textToSpeechV1(workspaceId, textToSpeechRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Translate Text
         * @param {string} workspaceId 
         * @param {TranslationRequestModel} translationRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translateV1(workspaceId: string, translationRequestModel: TranslationRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translateV1(workspaceId, translationRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Translation Supported Languages
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationSupportedLanguagesV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslateSupportedLanguagesResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationSupportedLanguagesV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContentAddonsRoutesApi - factory interface
 * @export
 */
export const ContentAddonsRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentAddonsRoutesApiFp(configuration)
    return {
        /**
         * 
         * @summary Text to Speech
         * @param {string} workspaceId 
         * @param {TextToSpeechRequestModel} textToSpeechRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textToSpeechV1(workspaceId: string, textToSpeechRequestModel: TextToSpeechRequestModel, options?: any): AxiosPromise<TextToSpeechResponseModel> {
            return localVarFp.textToSpeechV1(workspaceId, textToSpeechRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Translate Text
         * @param {string} workspaceId 
         * @param {TranslationRequestModel} translationRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translateV1(workspaceId: string, translationRequestModel: TranslationRequestModel, options?: any): AxiosPromise<TranslationResponseModel> {
            return localVarFp.translateV1(workspaceId, translationRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Translation Supported Languages
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationSupportedLanguagesV1(workspaceId: string, options?: any): AxiosPromise<TranslateSupportedLanguagesResponseModel> {
            return localVarFp.translationSupportedLanguagesV1(workspaceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentAddonsRoutesApi - object-oriented interface
 * @export
 * @class ContentAddonsRoutesApi
 * @extends {BaseAPI}
 */
export class ContentAddonsRoutesApi extends BaseAPI {
    /**
     * 
     * @summary Text to Speech
     * @param {string} workspaceId 
     * @param {TextToSpeechRequestModel} textToSpeechRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentAddonsRoutesApi
     */
    public textToSpeechV1(workspaceId: string, textToSpeechRequestModel: TextToSpeechRequestModel, options?: AxiosRequestConfig) {
        return ContentAddonsRoutesApiFp(this.configuration).textToSpeechV1(workspaceId, textToSpeechRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Translate Text
     * @param {string} workspaceId 
     * @param {TranslationRequestModel} translationRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentAddonsRoutesApi
     */
    public translateV1(workspaceId: string, translationRequestModel: TranslationRequestModel, options?: AxiosRequestConfig) {
        return ContentAddonsRoutesApiFp(this.configuration).translateV1(workspaceId, translationRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Translation Supported Languages
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentAddonsRoutesApi
     */
    public translationSupportedLanguagesV1(workspaceId: string, options?: AxiosRequestConfig) {
        return ContentAddonsRoutesApiFp(this.configuration).translationSupportedLanguagesV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataAnalyticsRoutesApi - axios parameter creator
 * @export
 */
export const DataAnalyticsRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a data-analytics record for a given resource ID.
         * @summary Create a data-analytics record for a given resource ID.
         * @param {string} workspaceId 
         * @param {CreateDataAnalyticsRequestModel} createDataAnalyticsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataAnalyticsV1: async (workspaceId: string, createDataAnalyticsRequestModel: CreateDataAnalyticsRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createDataAnalyticsV1', 'workspaceId', workspaceId)
            // verify required parameter 'createDataAnalyticsRequestModel' is not null or undefined
            assertParamExists('createDataAnalyticsV1', 'createDataAnalyticsRequestModel', createDataAnalyticsRequestModel)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/data-analytics`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDataAnalyticsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get AI based fix suggestion on the invalid SQL generated for user prompt.
         * @param {string} conversationId 
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAnalyticsAISuggestedFixV1: async (conversationId: string, workspaceId: string, dataAnalyticsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getDataAnalyticsAISuggestedFixV1', 'conversationId', conversationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataAnalyticsAISuggestedFixV1', 'workspaceId', workspaceId)
            // verify required parameter 'dataAnalyticsId' is not null or undefined
            assertParamExists('getDataAnalyticsAISuggestedFixV1', 'dataAnalyticsId', dataAnalyticsId)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/data-analytics/{data_analytics_id}/conversation/{conversation_id}/ai-suggested-fix`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"data_analytics_id"}}`, encodeURIComponent(String(dataAnalyticsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Data Analytics details
         * @summary Get Data Analytics details
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAnalyticsDetailsV1: async (workspaceId: string, dataAnalyticsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataAnalyticsDetailsV1', 'workspaceId', workspaceId)
            // verify required parameter 'dataAnalyticsId' is not null or undefined
            assertParamExists('getDataAnalyticsDetailsV1', 'dataAnalyticsId', dataAnalyticsId)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/data-analytics/{data_analytics_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"data_analytics_id"}}`, encodeURIComponent(String(dataAnalyticsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get `n` number of follow-up questions for the previously asked question.
         * @summary Get follow-up questions for the previously asked question.
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {string} [previousQuestion] 
         * @param {number} [numberOfQuestions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAnalyticsFollowUpQuestionsV1: async (workspaceId: string, dataAnalyticsId: string, previousQuestion?: string, numberOfQuestions?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataAnalyticsFollowUpQuestionsV1', 'workspaceId', workspaceId)
            // verify required parameter 'dataAnalyticsId' is not null or undefined
            assertParamExists('getDataAnalyticsFollowUpQuestionsV1', 'dataAnalyticsId', dataAnalyticsId)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/data-analytics/{data_analytics_id}/follow-up-questions`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"data_analytics_id"}}`, encodeURIComponent(String(dataAnalyticsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (previousQuestion !== undefined) {
                localVarQueryParameter['previous_question'] = previousQuestion;
            }

            if (numberOfQuestions !== undefined) {
                localVarQueryParameter['number_of_questions'] = numberOfQuestions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get `n` number of follow-up questions for the previously asked question.
         * @summary Get follow-up questions for the previously asked question.
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {string} [previousQuestion] 
         * @param {number} [numberOfQuestions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAnalyticsFollowUpQuestionsV2: async (workspaceId: string, dataAnalyticsId: string, previousQuestion?: string, numberOfQuestions?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataAnalyticsFollowUpQuestionsV2', 'workspaceId', workspaceId)
            // verify required parameter 'dataAnalyticsId' is not null or undefined
            assertParamExists('getDataAnalyticsFollowUpQuestionsV2', 'dataAnalyticsId', dataAnalyticsId)
            const localVarPath = `/oslo/v2/workspace/{workspace_id}/data-analytics/{data_analytics_id}/follow-up-questions`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"data_analytics_id"}}`, encodeURIComponent(String(dataAnalyticsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (previousQuestion !== undefined) {
                localVarQueryParameter['previous_question'] = previousQuestion;
            }

            if (numberOfQuestions !== undefined) {
                localVarQueryParameter['number_of_questions'] = numberOfQuestions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data-analytics resource preview
         * @summary Get data-analytics resource preview
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel} mKVLibraryStorageResourceModelGoogleDriveResourceModelGoogleSheetResourceModelSnowflakeStorageResourceModelS3StorageResourceModelAzureBlobStorageResourceModelPostgresStorageResourceModelNotionResourceMetadataModelAirbyteStorageResourceModel 
         * @param {number} [nRows] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAnalyticsResourcePreviewV1: async (workspaceId: string, dataAnalyticsId: string, mKVLibraryStorageResourceModelGoogleDriveResourceModelGoogleSheetResourceModelSnowflakeStorageResourceModelS3StorageResourceModelAzureBlobStorageResourceModelPostgresStorageResourceModelNotionResourceMetadataModelAirbyteStorageResourceModel: MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel, nRows?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataAnalyticsResourcePreviewV1', 'workspaceId', workspaceId)
            // verify required parameter 'dataAnalyticsId' is not null or undefined
            assertParamExists('getDataAnalyticsResourcePreviewV1', 'dataAnalyticsId', dataAnalyticsId)
            // verify required parameter 'mKVLibraryStorageResourceModelGoogleDriveResourceModelGoogleSheetResourceModelSnowflakeStorageResourceModelS3StorageResourceModelAzureBlobStorageResourceModelPostgresStorageResourceModelNotionResourceMetadataModelAirbyteStorageResourceModel' is not null or undefined
            assertParamExists('getDataAnalyticsResourcePreviewV1', 'mKVLibraryStorageResourceModelGoogleDriveResourceModelGoogleSheetResourceModelSnowflakeStorageResourceModelS3StorageResourceModelAzureBlobStorageResourceModelPostgresStorageResourceModelNotionResourceMetadataModelAirbyteStorageResourceModel', mKVLibraryStorageResourceModelGoogleDriveResourceModelGoogleSheetResourceModelSnowflakeStorageResourceModelS3StorageResourceModelAzureBlobStorageResourceModelPostgresStorageResourceModelNotionResourceMetadataModelAirbyteStorageResourceModel)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/data-analytics/{data_analytics_id}/resource/preview`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"data_analytics_id"}}`, encodeURIComponent(String(dataAnalyticsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nRows !== undefined) {
                localVarQueryParameter['n_rows'] = nRows;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mKVLibraryStorageResourceModelGoogleDriveResourceModelGoogleSheetResourceModelSnowflakeStorageResourceModelS3StorageResourceModelAzureBlobStorageResourceModelPostgresStorageResourceModelNotionResourceMetadataModelAirbyteStorageResourceModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List data-analytics leaf resources. Leaf resources are those beyond which we cannot further list resources in connectors.
         * @summary List data-analytics leaf resources
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataAnalyticsLeafResourcesV1: async (workspaceId: string, dataAnalyticsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listDataAnalyticsLeafResourcesV1', 'workspaceId', workspaceId)
            // verify required parameter 'dataAnalyticsId' is not null or undefined
            assertParamExists('listDataAnalyticsLeafResourcesV1', 'dataAnalyticsId', dataAnalyticsId)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/data-analytics/{data_analytics_id}/leaf-resources`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"data_analytics_id"}}`, encodeURIComponent(String(dataAnalyticsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists data-analytics record response models for the workspace ID.
         * @summary List data-analytics record response for the workspace ID.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataAnalyticsV1: async (workspaceId: string, listingAPIParams: ListingAPIParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listDataAnalyticsV1', 'workspaceId', workspaceId)
            // verify required parameter 'listingAPIParams' is not null or undefined
            assertParamExists('listDataAnalyticsV1', 'listingAPIParams', listingAPIParams)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/data-analytics/list`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingAPIParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists data-analytics record response models for the workspace ID.
         * @summary List data-analytics record response for the workspace ID.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataAnalyticsV2: async (workspaceId: string, listingAPIParams: ListingAPIParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listDataAnalyticsV2', 'workspaceId', workspaceId)
            // verify required parameter 'listingAPIParams' is not null or undefined
            assertParamExists('listDataAnalyticsV2', 'listingAPIParams', listingAPIParams)
            const localVarPath = `/oslo/v2/workspace/{workspace_id}/data-analytics/list`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingAPIParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates data-analytics records artifact state.
         * @summary Update data-analytics records artifact state.
         * @param {string} workspaceId 
         * @param {UpdateDataAnalyticsArtifactStateRequestModel} updateDataAnalyticsArtifactStateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataAnalyticsArtifactStateV1: async (workspaceId: string, updateDataAnalyticsArtifactStateRequestModel: UpdateDataAnalyticsArtifactStateRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateDataAnalyticsArtifactStateV1', 'workspaceId', workspaceId)
            // verify required parameter 'updateDataAnalyticsArtifactStateRequestModel' is not null or undefined
            assertParamExists('updateDataAnalyticsArtifactStateV1', 'updateDataAnalyticsArtifactStateRequestModel', updateDataAnalyticsArtifactStateRequestModel)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/data-analytics/artifact-state`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataAnalyticsArtifactStateRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataAnalyticsRoutesApi - functional programming interface
 * @export
 */
export const DataAnalyticsRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataAnalyticsRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a data-analytics record for a given resource ID.
         * @summary Create a data-analytics record for a given resource ID.
         * @param {string} workspaceId 
         * @param {CreateDataAnalyticsRequestModel} createDataAnalyticsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDataAnalyticsV1(workspaceId: string, createDataAnalyticsRequestModel: CreateDataAnalyticsRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAnalyticsModel | CreateDataAnalyticsResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDataAnalyticsV1(workspaceId, createDataAnalyticsRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get AI based fix suggestion on the invalid SQL generated for user prompt.
         * @param {string} conversationId 
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataAnalyticsAISuggestedFixV1(conversationId: string, workspaceId: string, dataAnalyticsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAnalyticsAISuggestedFix>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataAnalyticsAISuggestedFixV1(conversationId, workspaceId, dataAnalyticsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Data Analytics details
         * @summary Get Data Analytics details
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataAnalyticsDetailsV1(workspaceId: string, dataAnalyticsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAnalyticsDetailsResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataAnalyticsDetailsV1(workspaceId, dataAnalyticsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get `n` number of follow-up questions for the previously asked question.
         * @summary Get follow-up questions for the previously asked question.
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {string} [previousQuestion] 
         * @param {number} [numberOfQuestions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataAnalyticsFollowUpQuestionsV1(workspaceId: string, dataAnalyticsId: string, previousQuestion?: string, numberOfQuestions?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAnalyticsFollowUpQuestions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataAnalyticsFollowUpQuestionsV1(workspaceId, dataAnalyticsId, previousQuestion, numberOfQuestions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get `n` number of follow-up questions for the previously asked question.
         * @summary Get follow-up questions for the previously asked question.
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {string} [previousQuestion] 
         * @param {number} [numberOfQuestions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataAnalyticsFollowUpQuestionsV2(workspaceId: string, dataAnalyticsId: string, previousQuestion?: string, numberOfQuestions?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAnalyticsFollowUpQuestionsV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataAnalyticsFollowUpQuestionsV2(workspaceId, dataAnalyticsId, previousQuestion, numberOfQuestions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get data-analytics resource preview
         * @summary Get data-analytics resource preview
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel} mKVLibraryStorageResourceModelGoogleDriveResourceModelGoogleSheetResourceModelSnowflakeStorageResourceModelS3StorageResourceModelAzureBlobStorageResourceModelPostgresStorageResourceModelNotionResourceMetadataModelAirbyteStorageResourceModel 
         * @param {number} [nRows] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataAnalyticsResourcePreviewV1(workspaceId: string, dataAnalyticsId: string, mKVLibraryStorageResourceModelGoogleDriveResourceModelGoogleSheetResourceModelSnowflakeStorageResourceModelS3StorageResourceModelAzureBlobStorageResourceModelPostgresStorageResourceModelNotionResourceMetadataModelAirbyteStorageResourceModel: MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel, nRows?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilePreview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataAnalyticsResourcePreviewV1(workspaceId, dataAnalyticsId, mKVLibraryStorageResourceModelGoogleDriveResourceModelGoogleSheetResourceModelSnowflakeStorageResourceModelS3StorageResourceModelAzureBlobStorageResourceModelPostgresStorageResourceModelNotionResourceMetadataModelAirbyteStorageResourceModel, nRows, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List data-analytics leaf resources. Leaf resources are those beyond which we cannot further list resources in connectors.
         * @summary List data-analytics leaf resources
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDataAnalyticsLeafResourcesV1(workspaceId: string, dataAnalyticsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAnalyticsLeafResourcesResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDataAnalyticsLeafResourcesV1(workspaceId, dataAnalyticsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists data-analytics record response models for the workspace ID.
         * @summary List data-analytics record response for the workspace ID.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDataAnalyticsV1(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAnalyticsListResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDataAnalyticsV1(workspaceId, listingAPIParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists data-analytics record response models for the workspace ID.
         * @summary List data-analytics record response for the workspace ID.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDataAnalyticsV2(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAnalyticsListResponseModelV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDataAnalyticsV2(workspaceId, listingAPIParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates data-analytics records artifact state.
         * @summary Update data-analytics records artifact state.
         * @param {string} workspaceId 
         * @param {UpdateDataAnalyticsArtifactStateRequestModel} updateDataAnalyticsArtifactStateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDataAnalyticsArtifactStateV1(workspaceId: string, updateDataAnalyticsArtifactStateRequestModel: UpdateDataAnalyticsArtifactStateRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDataAnalyticsArtifactStateResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDataAnalyticsArtifactStateV1(workspaceId, updateDataAnalyticsArtifactStateRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataAnalyticsRoutesApi - factory interface
 * @export
 */
export const DataAnalyticsRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataAnalyticsRoutesApiFp(configuration)
    return {
        /**
         * Creates a data-analytics record for a given resource ID.
         * @summary Create a data-analytics record for a given resource ID.
         * @param {string} workspaceId 
         * @param {CreateDataAnalyticsRequestModel} createDataAnalyticsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataAnalyticsV1(workspaceId: string, createDataAnalyticsRequestModel: CreateDataAnalyticsRequestModel, options?: any): AxiosPromise<DataAnalyticsModel | CreateDataAnalyticsResponseModel> {
            return localVarFp.createDataAnalyticsV1(workspaceId, createDataAnalyticsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get AI based fix suggestion on the invalid SQL generated for user prompt.
         * @param {string} conversationId 
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAnalyticsAISuggestedFixV1(conversationId: string, workspaceId: string, dataAnalyticsId: string, options?: any): AxiosPromise<DataAnalyticsAISuggestedFix> {
            return localVarFp.getDataAnalyticsAISuggestedFixV1(conversationId, workspaceId, dataAnalyticsId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Data Analytics details
         * @summary Get Data Analytics details
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAnalyticsDetailsV1(workspaceId: string, dataAnalyticsId: string, options?: any): AxiosPromise<DataAnalyticsDetailsResponseModel> {
            return localVarFp.getDataAnalyticsDetailsV1(workspaceId, dataAnalyticsId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get `n` number of follow-up questions for the previously asked question.
         * @summary Get follow-up questions for the previously asked question.
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {string} [previousQuestion] 
         * @param {number} [numberOfQuestions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAnalyticsFollowUpQuestionsV1(workspaceId: string, dataAnalyticsId: string, previousQuestion?: string, numberOfQuestions?: number, options?: any): AxiosPromise<DataAnalyticsFollowUpQuestions> {
            return localVarFp.getDataAnalyticsFollowUpQuestionsV1(workspaceId, dataAnalyticsId, previousQuestion, numberOfQuestions, options).then((request) => request(axios, basePath));
        },
        /**
         * Get `n` number of follow-up questions for the previously asked question.
         * @summary Get follow-up questions for the previously asked question.
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {string} [previousQuestion] 
         * @param {number} [numberOfQuestions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAnalyticsFollowUpQuestionsV2(workspaceId: string, dataAnalyticsId: string, previousQuestion?: string, numberOfQuestions?: number, options?: any): AxiosPromise<DataAnalyticsFollowUpQuestionsV2> {
            return localVarFp.getDataAnalyticsFollowUpQuestionsV2(workspaceId, dataAnalyticsId, previousQuestion, numberOfQuestions, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data-analytics resource preview
         * @summary Get data-analytics resource preview
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel} mKVLibraryStorageResourceModelGoogleDriveResourceModelGoogleSheetResourceModelSnowflakeStorageResourceModelS3StorageResourceModelAzureBlobStorageResourceModelPostgresStorageResourceModelNotionResourceMetadataModelAirbyteStorageResourceModel 
         * @param {number} [nRows] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAnalyticsResourcePreviewV1(workspaceId: string, dataAnalyticsId: string, mKVLibraryStorageResourceModelGoogleDriveResourceModelGoogleSheetResourceModelSnowflakeStorageResourceModelS3StorageResourceModelAzureBlobStorageResourceModelPostgresStorageResourceModelNotionResourceMetadataModelAirbyteStorageResourceModel: MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel, nRows?: number, options?: any): AxiosPromise<FilePreview> {
            return localVarFp.getDataAnalyticsResourcePreviewV1(workspaceId, dataAnalyticsId, mKVLibraryStorageResourceModelGoogleDriveResourceModelGoogleSheetResourceModelSnowflakeStorageResourceModelS3StorageResourceModelAzureBlobStorageResourceModelPostgresStorageResourceModelNotionResourceMetadataModelAirbyteStorageResourceModel, nRows, options).then((request) => request(axios, basePath));
        },
        /**
         * List data-analytics leaf resources. Leaf resources are those beyond which we cannot further list resources in connectors.
         * @summary List data-analytics leaf resources
         * @param {string} workspaceId 
         * @param {string} dataAnalyticsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataAnalyticsLeafResourcesV1(workspaceId: string, dataAnalyticsId: string, options?: any): AxiosPromise<DataAnalyticsLeafResourcesResponseModel> {
            return localVarFp.listDataAnalyticsLeafResourcesV1(workspaceId, dataAnalyticsId, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists data-analytics record response models for the workspace ID.
         * @summary List data-analytics record response for the workspace ID.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataAnalyticsV1(workspaceId: string, listingAPIParams: ListingAPIParams, options?: any): AxiosPromise<DataAnalyticsListResponseModel> {
            return localVarFp.listDataAnalyticsV1(workspaceId, listingAPIParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists data-analytics record response models for the workspace ID.
         * @summary List data-analytics record response for the workspace ID.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataAnalyticsV2(workspaceId: string, listingAPIParams: ListingAPIParams, options?: any): AxiosPromise<DataAnalyticsListResponseModelV2> {
            return localVarFp.listDataAnalyticsV2(workspaceId, listingAPIParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates data-analytics records artifact state.
         * @summary Update data-analytics records artifact state.
         * @param {string} workspaceId 
         * @param {UpdateDataAnalyticsArtifactStateRequestModel} updateDataAnalyticsArtifactStateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataAnalyticsArtifactStateV1(workspaceId: string, updateDataAnalyticsArtifactStateRequestModel: UpdateDataAnalyticsArtifactStateRequestModel, options?: any): AxiosPromise<UpdateDataAnalyticsArtifactStateResponseModel> {
            return localVarFp.updateDataAnalyticsArtifactStateV1(workspaceId, updateDataAnalyticsArtifactStateRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataAnalyticsRoutesApi - object-oriented interface
 * @export
 * @class DataAnalyticsRoutesApi
 * @extends {BaseAPI}
 */
export class DataAnalyticsRoutesApi extends BaseAPI {
    /**
     * Creates a data-analytics record for a given resource ID.
     * @summary Create a data-analytics record for a given resource ID.
     * @param {string} workspaceId 
     * @param {CreateDataAnalyticsRequestModel} createDataAnalyticsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnalyticsRoutesApi
     */
    public createDataAnalyticsV1(workspaceId: string, createDataAnalyticsRequestModel: CreateDataAnalyticsRequestModel, options?: AxiosRequestConfig) {
        return DataAnalyticsRoutesApiFp(this.configuration).createDataAnalyticsV1(workspaceId, createDataAnalyticsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get AI based fix suggestion on the invalid SQL generated for user prompt.
     * @param {string} conversationId 
     * @param {string} workspaceId 
     * @param {string} dataAnalyticsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnalyticsRoutesApi
     */
    public getDataAnalyticsAISuggestedFixV1(conversationId: string, workspaceId: string, dataAnalyticsId: string, options?: AxiosRequestConfig) {
        return DataAnalyticsRoutesApiFp(this.configuration).getDataAnalyticsAISuggestedFixV1(conversationId, workspaceId, dataAnalyticsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Data Analytics details
     * @summary Get Data Analytics details
     * @param {string} workspaceId 
     * @param {string} dataAnalyticsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnalyticsRoutesApi
     */
    public getDataAnalyticsDetailsV1(workspaceId: string, dataAnalyticsId: string, options?: AxiosRequestConfig) {
        return DataAnalyticsRoutesApiFp(this.configuration).getDataAnalyticsDetailsV1(workspaceId, dataAnalyticsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get `n` number of follow-up questions for the previously asked question.
     * @summary Get follow-up questions for the previously asked question.
     * @param {string} workspaceId 
     * @param {string} dataAnalyticsId 
     * @param {string} [previousQuestion] 
     * @param {number} [numberOfQuestions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnalyticsRoutesApi
     */
    public getDataAnalyticsFollowUpQuestionsV1(workspaceId: string, dataAnalyticsId: string, previousQuestion?: string, numberOfQuestions?: number, options?: AxiosRequestConfig) {
        return DataAnalyticsRoutesApiFp(this.configuration).getDataAnalyticsFollowUpQuestionsV1(workspaceId, dataAnalyticsId, previousQuestion, numberOfQuestions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get `n` number of follow-up questions for the previously asked question.
     * @summary Get follow-up questions for the previously asked question.
     * @param {string} workspaceId 
     * @param {string} dataAnalyticsId 
     * @param {string} [previousQuestion] 
     * @param {number} [numberOfQuestions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnalyticsRoutesApi
     */
    public getDataAnalyticsFollowUpQuestionsV2(workspaceId: string, dataAnalyticsId: string, previousQuestion?: string, numberOfQuestions?: number, options?: AxiosRequestConfig) {
        return DataAnalyticsRoutesApiFp(this.configuration).getDataAnalyticsFollowUpQuestionsV2(workspaceId, dataAnalyticsId, previousQuestion, numberOfQuestions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data-analytics resource preview
     * @summary Get data-analytics resource preview
     * @param {string} workspaceId 
     * @param {string} dataAnalyticsId 
     * @param {MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel} mKVLibraryStorageResourceModelGoogleDriveResourceModelGoogleSheetResourceModelSnowflakeStorageResourceModelS3StorageResourceModelAzureBlobStorageResourceModelPostgresStorageResourceModelNotionResourceMetadataModelAirbyteStorageResourceModel 
     * @param {number} [nRows] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnalyticsRoutesApi
     */
    public getDataAnalyticsResourcePreviewV1(workspaceId: string, dataAnalyticsId: string, mKVLibraryStorageResourceModelGoogleDriveResourceModelGoogleSheetResourceModelSnowflakeStorageResourceModelS3StorageResourceModelAzureBlobStorageResourceModelPostgresStorageResourceModelNotionResourceMetadataModelAirbyteStorageResourceModel: MKVLibraryStorageResourceModel | GoogleDriveResourceModel | GoogleSheetResourceModel | SnowflakeStorageResourceModel | S3StorageResourceModel | AzureBlobStorageResourceModel | PostgresStorageResourceModel | NotionResourceMetadataModel | AirbyteStorageResourceModel, nRows?: number, options?: AxiosRequestConfig) {
        return DataAnalyticsRoutesApiFp(this.configuration).getDataAnalyticsResourcePreviewV1(workspaceId, dataAnalyticsId, mKVLibraryStorageResourceModelGoogleDriveResourceModelGoogleSheetResourceModelSnowflakeStorageResourceModelS3StorageResourceModelAzureBlobStorageResourceModelPostgresStorageResourceModelNotionResourceMetadataModelAirbyteStorageResourceModel, nRows, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List data-analytics leaf resources. Leaf resources are those beyond which we cannot further list resources in connectors.
     * @summary List data-analytics leaf resources
     * @param {string} workspaceId 
     * @param {string} dataAnalyticsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnalyticsRoutesApi
     */
    public listDataAnalyticsLeafResourcesV1(workspaceId: string, dataAnalyticsId: string, options?: AxiosRequestConfig) {
        return DataAnalyticsRoutesApiFp(this.configuration).listDataAnalyticsLeafResourcesV1(workspaceId, dataAnalyticsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists data-analytics record response models for the workspace ID.
     * @summary List data-analytics record response for the workspace ID.
     * @param {string} workspaceId 
     * @param {ListingAPIParams} listingAPIParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnalyticsRoutesApi
     */
    public listDataAnalyticsV1(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig) {
        return DataAnalyticsRoutesApiFp(this.configuration).listDataAnalyticsV1(workspaceId, listingAPIParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists data-analytics record response models for the workspace ID.
     * @summary List data-analytics record response for the workspace ID.
     * @param {string} workspaceId 
     * @param {ListingAPIParams} listingAPIParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnalyticsRoutesApi
     */
    public listDataAnalyticsV2(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig) {
        return DataAnalyticsRoutesApiFp(this.configuration).listDataAnalyticsV2(workspaceId, listingAPIParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates data-analytics records artifact state.
     * @summary Update data-analytics records artifact state.
     * @param {string} workspaceId 
     * @param {UpdateDataAnalyticsArtifactStateRequestModel} updateDataAnalyticsArtifactStateRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnalyticsRoutesApi
     */
    public updateDataAnalyticsArtifactStateV1(workspaceId: string, updateDataAnalyticsArtifactStateRequestModel: UpdateDataAnalyticsArtifactStateRequestModel, options?: AxiosRequestConfig) {
        return DataAnalyticsRoutesApiFp(this.configuration).updateDataAnalyticsArtifactStateV1(workspaceId, updateDataAnalyticsArtifactStateRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DatasetAnalysesRoutesApi - axios parameter creator
 * @export
 */
export const DatasetAnalysesRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lists available analysis tasklets for a workspace. If dataset_id is provided, data_category need not be provided
         * @summary Lists available analysis tasklets for a workspace.
         * @param {string} workspaceId 
         * @param {DataCategory} [dataCategory] 
         * @param {string} [datasetId] 
         * @param {boolean} [isDataUnlabeled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableTaskletsV1: async (workspaceId: string, dataCategory?: DataCategory, datasetId?: string, isDataUnlabeled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAvailableTaskletsV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/analyses/analyses-tasklets`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dataCategory !== undefined) {
                localVarQueryParameter['data_category'] = dataCategory;
            }

            if (datasetId !== undefined) {
                localVarQueryParameter['dataset_id'] = datasetId;
            }

            if (isDataUnlabeled !== undefined) {
                localVarQueryParameter['is_data_unlabeled'] = isDataUnlabeled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the status of data analyses tasks executed for a dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAnalysesStatusV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataAnalysesStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDataAnalysesStatusV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/data-analyses/status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List saved tasklet id selections for a workspace.
         * @summary List saved tasklet id selections for a workspace.
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [dataCategory] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedTaskletSelectionsV1: async (workspaceId: string, start?: number, end?: number, ts?: string, dataCategory?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getSavedTaskletSelectionsV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/analyses/list-tasklet-selections`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (dataCategory !== undefined) {
                localVarQueryParameter['data_category'] = dataCategory;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint retrieves a list of data analysis results for a given workspace and dataset. It returns a list of DataAnalysesResult objects, each representing the analysis results for a specific tasklet in the dataset.
         * @summary List Data Analysis Results
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskletId 
         * @param {string} metricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataAnalysisResultsMetadataV1: async (workspaceId: string, datasetId: string, taskletId: string, metricId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listDataAnalysisResultsMetadataV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('listDataAnalysisResultsMetadataV1', 'datasetId', datasetId)
            // verify required parameter 'taskletId' is not null or undefined
            assertParamExists('listDataAnalysisResultsMetadataV1', 'taskletId', taskletId)
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('listDataAnalysisResultsMetadataV1', 'metricId', metricId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/data-analyses/tasklet/{tasklet_id}/metric/{metric_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"tasklet_id"}}`, encodeURIComponent(String(taskletId)))
                .replace(`{${"metric_id"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint retrieves a list of data analysis results for a given workspace and dataset. It returns a list of DataAnalysesResult objects, each representing the analysis results for a specific tasklet in the dataset.
         * @summary List Data Analysis Results
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataAnalysisResultsSummaryV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listDataAnalysisResultsSummaryV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('listDataAnalysisResultsSummaryV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/data-analyses`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Predict data category for a dataset. This takes a dataset preview in request, and returns the data category based on a heuristic. Note: preview data must have at least 10 rows to predict data category.
         * @summary Predict data category for a dataset.
         * @param {string} workspaceId 
         * @param {BodyPredictDataCategoryV1} bodyPredictDataCategoryV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictDataCategoryV1: async (workspaceId: string, bodyPredictDataCategoryV1: BodyPredictDataCategoryV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('predictDataCategoryV1', 'workspaceId', workspaceId)
            // verify required parameter 'bodyPredictDataCategoryV1' is not null or undefined
            assertParamExists('predictDataCategoryV1', 'bodyPredictDataCategoryV1', bodyPredictDataCategoryV1)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/analysis/predict-data-category`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyPredictDataCategoryV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save tasklet id selections for a workspace. This takes a list of tasklet ids, and a name for the selection. The selection is saved in the database, and can be retrieved later.
         * @summary Save tasklet id selections for a workspace.
         * @param {string} workspaceId 
         * @param {BodySaveTaskletSelectionsV1} bodySaveTaskletSelectionsV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTaskletSelectionsV1: async (workspaceId: string, bodySaveTaskletSelectionsV1: BodySaveTaskletSelectionsV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('saveTaskletSelectionsV1', 'workspaceId', workspaceId)
            // verify required parameter 'bodySaveTaskletSelectionsV1' is not null or undefined
            assertParamExists('saveTaskletSelectionsV1', 'bodySaveTaskletSelectionsV1', bodySaveTaskletSelectionsV1)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/analyses/save-tasklet-selections`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodySaveTaskletSelectionsV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Triggers data analysis on a dataset with selected tasklet ids
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerDataAnalysisV1: async (workspaceId: string, datasetId: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('triggerDataAnalysisV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('triggerDataAnalysisV1', 'datasetId', datasetId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('triggerDataAnalysisV1', 'requestBody', requestBody)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/data-analyses/trigger`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stops the running task for given task_id.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerStopAnalysesV1: async (workspaceId: string, datasetId: string, taskId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('triggerStopAnalysesV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('triggerStopAnalysesV1', 'datasetId', datasetId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('triggerStopAnalysesV1', 'taskId', taskId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/data-analyses/tasks/{task_id}/stop`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetAnalysesRoutesApi - functional programming interface
 * @export
 */
export const DatasetAnalysesRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetAnalysesRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Lists available analysis tasklets for a workspace. If dataset_id is provided, data_category need not be provided
         * @summary Lists available analysis tasklets for a workspace.
         * @param {string} workspaceId 
         * @param {DataCategory} [dataCategory] 
         * @param {string} [datasetId] 
         * @param {boolean} [isDataUnlabeled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableTaskletsV1(workspaceId: string, dataCategory?: DataCategory, datasetId?: string, isDataUnlabeled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableDatasetTaskletsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableTaskletsV1(workspaceId, dataCategory, datasetId, isDataUnlabeled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the status of data analyses tasks executed for a dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataAnalysesStatusV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDataAnalysesStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataAnalysesStatusV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List saved tasklet id selections for a workspace.
         * @summary List saved tasklet id selections for a workspace.
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [dataCategory] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSavedTaskletSelectionsV1(workspaceId: string, start?: number, end?: number, ts?: string, dataCategory?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSavedTaskletSelectionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSavedTaskletSelectionsV1(workspaceId, start, end, ts, dataCategory, name, userIds, excludeUserIds, recordIds, excludeDemoArtifacts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint retrieves a list of data analysis results for a given workspace and dataset. It returns a list of DataAnalysesResult objects, each representing the analysis results for a specific tasklet in the dataset.
         * @summary List Data Analysis Results
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskletId 
         * @param {string} metricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDataAnalysisResultsMetadataV1(workspaceId: string, datasetId: string, taskletId: string, metricId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAnalysisResultsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDataAnalysisResultsMetadataV1(workspaceId, datasetId, taskletId, metricId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint retrieves a list of data analysis results for a given workspace and dataset. It returns a list of DataAnalysesResult objects, each representing the analysis results for a specific tasklet in the dataset.
         * @summary List Data Analysis Results
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDataAnalysisResultsSummaryV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAnalysisSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDataAnalysisResultsSummaryV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Predict data category for a dataset. This takes a dataset preview in request, and returns the data category based on a heuristic. Note: preview data must have at least 10 rows to predict data category.
         * @summary Predict data category for a dataset.
         * @param {string} workspaceId 
         * @param {BodyPredictDataCategoryV1} bodyPredictDataCategoryV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predictDataCategoryV1(workspaceId: string, bodyPredictDataCategoryV1: BodyPredictDataCategoryV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PredictDataCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.predictDataCategoryV1(workspaceId, bodyPredictDataCategoryV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Save tasklet id selections for a workspace. This takes a list of tasklet ids, and a name for the selection. The selection is saved in the database, and can be retrieved later.
         * @summary Save tasklet id selections for a workspace.
         * @param {string} workspaceId 
         * @param {BodySaveTaskletSelectionsV1} bodySaveTaskletSelectionsV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTaskletSelectionsV1(workspaceId: string, bodySaveTaskletSelectionsV1: BodySaveTaskletSelectionsV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTaskletSelectionsV1(workspaceId, bodySaveTaskletSelectionsV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Triggers data analysis on a dataset with selected tasklet ids
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerDataAnalysisV1(workspaceId: string, datasetId: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerAnalysisResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerDataAnalysisV1(workspaceId, datasetId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stops the running task for given task_id.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerStopAnalysesV1(workspaceId: string, datasetId: string, taskId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StopAnalysesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerStopAnalysesV1(workspaceId, datasetId, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DatasetAnalysesRoutesApi - factory interface
 * @export
 */
export const DatasetAnalysesRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetAnalysesRoutesApiFp(configuration)
    return {
        /**
         * Lists available analysis tasklets for a workspace. If dataset_id is provided, data_category need not be provided
         * @summary Lists available analysis tasklets for a workspace.
         * @param {string} workspaceId 
         * @param {DataCategory} [dataCategory] 
         * @param {string} [datasetId] 
         * @param {boolean} [isDataUnlabeled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableTaskletsV1(workspaceId: string, dataCategory?: DataCategory, datasetId?: string, isDataUnlabeled?: boolean, options?: any): AxiosPromise<AvailableDatasetTaskletsResponse> {
            return localVarFp.getAvailableTaskletsV1(workspaceId, dataCategory, datasetId, isDataUnlabeled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the status of data analyses tasks executed for a dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAnalysesStatusV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<GetDataAnalysesStatusResponse> {
            return localVarFp.getDataAnalysesStatusV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * List saved tasklet id selections for a workspace.
         * @summary List saved tasklet id selections for a workspace.
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [dataCategory] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedTaskletSelectionsV1(workspaceId: string, start?: number, end?: number, ts?: string, dataCategory?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options?: any): AxiosPromise<ListSavedTaskletSelectionsResponse> {
            return localVarFp.getSavedTaskletSelectionsV1(workspaceId, start, end, ts, dataCategory, name, userIds, excludeUserIds, recordIds, excludeDemoArtifacts, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint retrieves a list of data analysis results for a given workspace and dataset. It returns a list of DataAnalysesResult objects, each representing the analysis results for a specific tasklet in the dataset.
         * @summary List Data Analysis Results
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskletId 
         * @param {string} metricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataAnalysisResultsMetadataV1(workspaceId: string, datasetId: string, taskletId: string, metricId: string, options?: any): AxiosPromise<DataAnalysisResultsResponse> {
            return localVarFp.listDataAnalysisResultsMetadataV1(workspaceId, datasetId, taskletId, metricId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint retrieves a list of data analysis results for a given workspace and dataset. It returns a list of DataAnalysesResult objects, each representing the analysis results for a specific tasklet in the dataset.
         * @summary List Data Analysis Results
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataAnalysisResultsSummaryV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<DataAnalysisSummaryResponse> {
            return localVarFp.listDataAnalysisResultsSummaryV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Predict data category for a dataset. This takes a dataset preview in request, and returns the data category based on a heuristic. Note: preview data must have at least 10 rows to predict data category.
         * @summary Predict data category for a dataset.
         * @param {string} workspaceId 
         * @param {BodyPredictDataCategoryV1} bodyPredictDataCategoryV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictDataCategoryV1(workspaceId: string, bodyPredictDataCategoryV1: BodyPredictDataCategoryV1, options?: any): AxiosPromise<PredictDataCategoryResponse> {
            return localVarFp.predictDataCategoryV1(workspaceId, bodyPredictDataCategoryV1, options).then((request) => request(axios, basePath));
        },
        /**
         * Save tasklet id selections for a workspace. This takes a list of tasklet ids, and a name for the selection. The selection is saved in the database, and can be retrieved later.
         * @summary Save tasklet id selections for a workspace.
         * @param {string} workspaceId 
         * @param {BodySaveTaskletSelectionsV1} bodySaveTaskletSelectionsV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTaskletSelectionsV1(workspaceId: string, bodySaveTaskletSelectionsV1: BodySaveTaskletSelectionsV1, options?: any): AxiosPromise<SelectionCreationResponse> {
            return localVarFp.saveTaskletSelectionsV1(workspaceId, bodySaveTaskletSelectionsV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Triggers data analysis on a dataset with selected tasklet ids
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerDataAnalysisV1(workspaceId: string, datasetId: string, requestBody: Array<string>, options?: any): AxiosPromise<TriggerAnalysisResponse> {
            return localVarFp.triggerDataAnalysisV1(workspaceId, datasetId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stops the running task for given task_id.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerStopAnalysesV1(workspaceId: string, datasetId: string, taskId: string, options?: any): AxiosPromise<StopAnalysesResponse> {
            return localVarFp.triggerStopAnalysesV1(workspaceId, datasetId, taskId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatasetAnalysesRoutesApi - object-oriented interface
 * @export
 * @class DatasetAnalysesRoutesApi
 * @extends {BaseAPI}
 */
export class DatasetAnalysesRoutesApi extends BaseAPI {
    /**
     * Lists available analysis tasklets for a workspace. If dataset_id is provided, data_category need not be provided
     * @summary Lists available analysis tasklets for a workspace.
     * @param {string} workspaceId 
     * @param {DataCategory} [dataCategory] 
     * @param {string} [datasetId] 
     * @param {boolean} [isDataUnlabeled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAnalysesRoutesApi
     */
    public getAvailableTaskletsV1(workspaceId: string, dataCategory?: DataCategory, datasetId?: string, isDataUnlabeled?: boolean, options?: AxiosRequestConfig) {
        return DatasetAnalysesRoutesApiFp(this.configuration).getAvailableTaskletsV1(workspaceId, dataCategory, datasetId, isDataUnlabeled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the status of data analyses tasks executed for a dataset
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAnalysesRoutesApi
     */
    public getDataAnalysesStatusV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return DatasetAnalysesRoutesApiFp(this.configuration).getDataAnalysesStatusV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List saved tasklet id selections for a workspace.
     * @summary List saved tasklet id selections for a workspace.
     * @param {string} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [dataCategory] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAnalysesRoutesApi
     */
    public getSavedTaskletSelectionsV1(workspaceId: string, start?: number, end?: number, ts?: string, dataCategory?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options?: AxiosRequestConfig) {
        return DatasetAnalysesRoutesApiFp(this.configuration).getSavedTaskletSelectionsV1(workspaceId, start, end, ts, dataCategory, name, userIds, excludeUserIds, recordIds, excludeDemoArtifacts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint retrieves a list of data analysis results for a given workspace and dataset. It returns a list of DataAnalysesResult objects, each representing the analysis results for a specific tasklet in the dataset.
     * @summary List Data Analysis Results
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} taskletId 
     * @param {string} metricId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAnalysesRoutesApi
     */
    public listDataAnalysisResultsMetadataV1(workspaceId: string, datasetId: string, taskletId: string, metricId: string, options?: AxiosRequestConfig) {
        return DatasetAnalysesRoutesApiFp(this.configuration).listDataAnalysisResultsMetadataV1(workspaceId, datasetId, taskletId, metricId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint retrieves a list of data analysis results for a given workspace and dataset. It returns a list of DataAnalysesResult objects, each representing the analysis results for a specific tasklet in the dataset.
     * @summary List Data Analysis Results
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAnalysesRoutesApi
     */
    public listDataAnalysisResultsSummaryV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return DatasetAnalysesRoutesApiFp(this.configuration).listDataAnalysisResultsSummaryV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Predict data category for a dataset. This takes a dataset preview in request, and returns the data category based on a heuristic. Note: preview data must have at least 10 rows to predict data category.
     * @summary Predict data category for a dataset.
     * @param {string} workspaceId 
     * @param {BodyPredictDataCategoryV1} bodyPredictDataCategoryV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAnalysesRoutesApi
     */
    public predictDataCategoryV1(workspaceId: string, bodyPredictDataCategoryV1: BodyPredictDataCategoryV1, options?: AxiosRequestConfig) {
        return DatasetAnalysesRoutesApiFp(this.configuration).predictDataCategoryV1(workspaceId, bodyPredictDataCategoryV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save tasklet id selections for a workspace. This takes a list of tasklet ids, and a name for the selection. The selection is saved in the database, and can be retrieved later.
     * @summary Save tasklet id selections for a workspace.
     * @param {string} workspaceId 
     * @param {BodySaveTaskletSelectionsV1} bodySaveTaskletSelectionsV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAnalysesRoutesApi
     */
    public saveTaskletSelectionsV1(workspaceId: string, bodySaveTaskletSelectionsV1: BodySaveTaskletSelectionsV1, options?: AxiosRequestConfig) {
        return DatasetAnalysesRoutesApiFp(this.configuration).saveTaskletSelectionsV1(workspaceId, bodySaveTaskletSelectionsV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Triggers data analysis on a dataset with selected tasklet ids
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAnalysesRoutesApi
     */
    public triggerDataAnalysisV1(workspaceId: string, datasetId: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return DatasetAnalysesRoutesApiFp(this.configuration).triggerDataAnalysisV1(workspaceId, datasetId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stops the running task for given task_id.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAnalysesRoutesApi
     */
    public triggerStopAnalysesV1(workspaceId: string, datasetId: string, taskId: string, options?: AxiosRequestConfig) {
        return DatasetAnalysesRoutesApiFp(this.configuration).triggerStopAnalysesV1(workspaceId, datasetId, taskId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DatasetAnalyticsRoutesApi - axios parameter creator
 * @export
 */
export const DatasetAnalyticsRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generate AI explanation for the dataset chart image.
         * @summary Generate AI explanation for the dataset chart image.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {DatasetAnalyticsExplainWithAIRequestModel} datasetAnalyticsExplainWithAIRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDatasetChartAIExplanation: async (workspaceId: string, datasetId: string, datasetAnalyticsExplainWithAIRequestModel: DatasetAnalyticsExplainWithAIRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('generateDatasetChartAIExplanation', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('generateDatasetChartAIExplanation', 'datasetId', datasetId)
            // verify required parameter 'datasetAnalyticsExplainWithAIRequestModel' is not null or undefined
            assertParamExists('generateDatasetChartAIExplanation', 'datasetAnalyticsExplainWithAIRequestModel', datasetAnalyticsExplainWithAIRequestModel)
            const localVarPath = `/oslo/v1/workspace/{workspace_id}/dataset/{dataset_id}/chart/explain-with-ai`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetAnalyticsExplainWithAIRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetAnalyticsRoutesApi - functional programming interface
 * @export
 */
export const DatasetAnalyticsRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetAnalyticsRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Generate AI explanation for the dataset chart image.
         * @summary Generate AI explanation for the dataset chart image.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {DatasetAnalyticsExplainWithAIRequestModel} datasetAnalyticsExplainWithAIRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateDatasetChartAIExplanation(workspaceId: string, datasetId: string, datasetAnalyticsExplainWithAIRequestModel: DatasetAnalyticsExplainWithAIRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetAnalyticsExplainWithAIResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateDatasetChartAIExplanation(workspaceId, datasetId, datasetAnalyticsExplainWithAIRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DatasetAnalyticsRoutesApi - factory interface
 * @export
 */
export const DatasetAnalyticsRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetAnalyticsRoutesApiFp(configuration)
    return {
        /**
         * Generate AI explanation for the dataset chart image.
         * @summary Generate AI explanation for the dataset chart image.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {DatasetAnalyticsExplainWithAIRequestModel} datasetAnalyticsExplainWithAIRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDatasetChartAIExplanation(workspaceId: string, datasetId: string, datasetAnalyticsExplainWithAIRequestModel: DatasetAnalyticsExplainWithAIRequestModel, options?: any): AxiosPromise<DatasetAnalyticsExplainWithAIResponseModel> {
            return localVarFp.generateDatasetChartAIExplanation(workspaceId, datasetId, datasetAnalyticsExplainWithAIRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatasetAnalyticsRoutesApi - object-oriented interface
 * @export
 * @class DatasetAnalyticsRoutesApi
 * @extends {BaseAPI}
 */
export class DatasetAnalyticsRoutesApi extends BaseAPI {
    /**
     * Generate AI explanation for the dataset chart image.
     * @summary Generate AI explanation for the dataset chart image.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {DatasetAnalyticsExplainWithAIRequestModel} datasetAnalyticsExplainWithAIRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetAnalyticsRoutesApi
     */
    public generateDatasetChartAIExplanation(workspaceId: string, datasetId: string, datasetAnalyticsExplainWithAIRequestModel: DatasetAnalyticsExplainWithAIRequestModel, options?: AxiosRequestConfig) {
        return DatasetAnalyticsRoutesApiFp(this.configuration).generateDatasetChartAIExplanation(workspaceId, datasetId, datasetAnalyticsExplainWithAIRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DatasetOperationRoutesApi - axios parameter creator
 * @export
 */
export const DatasetOperationRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new data_operation for the specified dataset within a given workspace.
         * @summary Create a new Dataset DataOperations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {CreateDataOperationRequest} createDataOperationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataOperationsV1: async (workspaceId: string, datasetId: string, createDataOperationRequest: CreateDataOperationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createDataOperationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('createDataOperationsV1', 'datasetId', datasetId)
            // verify required parameter 'createDataOperationRequest' is not null or undefined
            assertParamExists('createDataOperationsV1', 'createDataOperationRequest', createDataOperationRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/data_operation`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDataOperationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update state of data operation resource (Active, Archive) Args:     update_request:         list{data_operation_id}: list of data operation ids to be modified         artifact_state: ARCHIVED/ACTIVE     workspace_id:     user:  Returns:
         * @summary Update data operation artifact state
         * @param {string} workspaceId 
         * @param {UpdateDataOperationArtifactStateRequest} updateDataOperationArtifactStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataOperationStateChangeV1: async (workspaceId: string, updateDataOperationArtifactStateRequest: UpdateDataOperationArtifactStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('dataOperationStateChangeV1', 'workspaceId', workspaceId)
            // verify required parameter 'updateDataOperationArtifactStateRequest' is not null or undefined
            assertParamExists('dataOperationStateChangeV1', 'updateDataOperationArtifactStateRequest', updateDataOperationArtifactStateRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/data_operation/state`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataOperationArtifactStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a small sample of a dataset. Sampling strategies: random, sequential. Once sample is generated, same sample would be returned. Sample can be regenerated on demand by setting regenerate=True. Note: regenerate would have no effect if sampling strategy is sequential.
         * @summary Retrieves a small sample of a dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {number} [numRows] 
         * @param {DatasetSamplingStrategy} [samplingStrategy] 
         * @param {boolean} [regenerate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDatasetSampleForDataOperationsV1: async (workspaceId: string, datasetId: string, dataOperationId: string, numRows?: number, samplingStrategy?: DatasetSamplingStrategy, regenerate?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('generateDatasetSampleForDataOperationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('generateDatasetSampleForDataOperationsV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('generateDatasetSampleForDataOperationsV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/data_operation/{data_operation_id}/dataset-sample`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (numRows !== undefined) {
                localVarQueryParameter['num_rows'] = numRows;
            }

            if (samplingStrategy !== undefined) {
                localVarQueryParameter['sampling_strategy'] = samplingStrategy;
            }

            if (regenerate !== undefined) {
                localVarQueryParameter['regenerate'] = regenerate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a data_operation\'s details
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataOperationDetailsV1: async (workspaceId: string, datasetId: string, dataOperationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataOperationDetailsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDataOperationDetailsV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('getDataOperationDetailsV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/data_operation/{data_operation_id}/details`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches the current status of a dataset data_operation in the workspace. This API can be used for quick checks without retrieving full data_operation details.
         * @summary Get a data_operation\'s status
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataOperationsStatusV1: async (workspaceId: string, datasetId: string, dataOperationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataOperationsStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDataOperationsStatusV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('getDataOperationsStatusV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/data_operation/{data_operation_id}/status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a data_operation model
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataOperationsV1: async (workspaceId: string, datasetId: string, dataOperationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataOperationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDataOperationsV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('getDataOperationsV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/data_operation/{data_operation_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginated list of data_operations that have been triggered for a dataset. Data Operations can be filtered by data_operation type, and status
         * @summary Paginated list of data_operations available for dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataOperationsV1: async (workspaceId: string, datasetId: string, start?: number, end?: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listDataOperationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('listDataOperationsV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/data_operations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginated list of data_operations that have been triggered for a workspace
         * @summary Paginated list of all available data_operations
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataOperationsV2: async (workspaceId: string, listingAPIParams: ListingAPIParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listDataOperationsV2', 'workspaceId', workspaceId)
            // verify required parameter 'listingAPIParams' is not null or undefined
            assertParamExists('listDataOperationsV2', 'listingAPIParams', listingAPIParams)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/data_operations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingAPIParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint modifies data operation attributes like name, description                     of the given data operation
         * @summary Update Data Operation
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {UpdateDataOperationRequest} updateDataOperationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataOperationV1: async (workspaceId: string, datasetId: string, dataOperationId: string, updateDataOperationRequest: UpdateDataOperationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateDataOperationV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('updateDataOperationV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('updateDataOperationV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'updateDataOperationRequest' is not null or undefined
            assertParamExists('updateDataOperationV1', 'updateDataOperationRequest', updateDataOperationRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/data_operation/{data_operation_id}/update`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataOperationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetOperationRoutesApi - functional programming interface
 * @export
 */
export const DatasetOperationRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetOperationRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new data_operation for the specified dataset within a given workspace.
         * @summary Create a new Dataset DataOperations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {CreateDataOperationRequest} createDataOperationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDataOperationsV1(workspaceId: string, datasetId: string, createDataOperationRequest: CreateDataOperationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDataOperationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDataOperationsV1(workspaceId, datasetId, createDataOperationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update state of data operation resource (Active, Archive) Args:     update_request:         list{data_operation_id}: list of data operation ids to be modified         artifact_state: ARCHIVED/ACTIVE     workspace_id:     user:  Returns:
         * @summary Update data operation artifact state
         * @param {string} workspaceId 
         * @param {UpdateDataOperationArtifactStateRequest} updateDataOperationArtifactStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataOperationStateChangeV1(workspaceId: string, updateDataOperationArtifactStateRequest: UpdateDataOperationArtifactStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataOperationStateChangeV1(workspaceId, updateDataOperationArtifactStateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a small sample of a dataset. Sampling strategies: random, sequential. Once sample is generated, same sample would be returned. Sample can be regenerated on demand by setting regenerate=True. Note: regenerate would have no effect if sampling strategy is sequential.
         * @summary Retrieves a small sample of a dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {number} [numRows] 
         * @param {DatasetSamplingStrategy} [samplingStrategy] 
         * @param {boolean} [regenerate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateDatasetSampleForDataOperationsV1(workspaceId: string, datasetId: string, dataOperationId: string, numRows?: number, samplingStrategy?: DatasetSamplingStrategy, regenerate?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDatasetPreviewDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateDatasetSampleForDataOperationsV1(workspaceId, datasetId, dataOperationId, numRows, samplingStrategy, regenerate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a data_operation\'s details
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataOperationDetailsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetOperationDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataOperationDetailsV1(workspaceId, datasetId, dataOperationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches the current status of a dataset data_operation in the workspace. This API can be used for quick checks without retrieving full data_operation details.
         * @summary Get a data_operation\'s status
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataOperationsStatusV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataOperationStateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataOperationsStatusV1(workspaceId, datasetId, dataOperationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a data_operation model
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataOperationsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetOperationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataOperationsV1(workspaceId, datasetId, dataOperationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Paginated list of data_operations that have been triggered for a dataset. Data Operations can be filtered by data_operation type, and status
         * @summary Paginated list of data_operations available for dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDataOperationsV1(workspaceId: string, datasetId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDataOperationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDataOperationsV1(workspaceId, datasetId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Paginated list of data_operations that have been triggered for a workspace
         * @summary Paginated list of all available data_operations
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDataOperationsV2(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDataOperationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDataOperationsV2(workspaceId, listingAPIParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint modifies data operation attributes like name, description                     of the given data operation
         * @summary Update Data Operation
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {UpdateDataOperationRequest} updateDataOperationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDataOperationV1(workspaceId: string, datasetId: string, dataOperationId: string, updateDataOperationRequest: UpdateDataOperationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDataOperationV1(workspaceId, datasetId, dataOperationId, updateDataOperationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DatasetOperationRoutesApi - factory interface
 * @export
 */
export const DatasetOperationRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetOperationRoutesApiFp(configuration)
    return {
        /**
         * Creates a new data_operation for the specified dataset within a given workspace.
         * @summary Create a new Dataset DataOperations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {CreateDataOperationRequest} createDataOperationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataOperationsV1(workspaceId: string, datasetId: string, createDataOperationRequest: CreateDataOperationRequest, options?: any): AxiosPromise<CreateDataOperationResponse> {
            return localVarFp.createDataOperationsV1(workspaceId, datasetId, createDataOperationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update state of data operation resource (Active, Archive) Args:     update_request:         list{data_operation_id}: list of data operation ids to be modified         artifact_state: ARCHIVED/ACTIVE     workspace_id:     user:  Returns:
         * @summary Update data operation artifact state
         * @param {string} workspaceId 
         * @param {UpdateDataOperationArtifactStateRequest} updateDataOperationArtifactStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataOperationStateChangeV1(workspaceId: string, updateDataOperationArtifactStateRequest: UpdateDataOperationArtifactStateRequest, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.dataOperationStateChangeV1(workspaceId, updateDataOperationArtifactStateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a small sample of a dataset. Sampling strategies: random, sequential. Once sample is generated, same sample would be returned. Sample can be regenerated on demand by setting regenerate=True. Note: regenerate would have no effect if sampling strategy is sequential.
         * @summary Retrieves a small sample of a dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {number} [numRows] 
         * @param {DatasetSamplingStrategy} [samplingStrategy] 
         * @param {boolean} [regenerate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDatasetSampleForDataOperationsV1(workspaceId: string, datasetId: string, dataOperationId: string, numRows?: number, samplingStrategy?: DatasetSamplingStrategy, regenerate?: boolean, options?: any): AxiosPromise<GetDatasetPreviewDataResponse> {
            return localVarFp.generateDatasetSampleForDataOperationsV1(workspaceId, datasetId, dataOperationId, numRows, samplingStrategy, regenerate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a data_operation\'s details
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataOperationDetailsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: any): AxiosPromise<DatasetOperationDetailsResponse> {
            return localVarFp.getDataOperationDetailsV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches the current status of a dataset data_operation in the workspace. This API can be used for quick checks without retrieving full data_operation details.
         * @summary Get a data_operation\'s status
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataOperationsStatusV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: any): AxiosPromise<DataOperationStateModel> {
            return localVarFp.getDataOperationsStatusV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a data_operation model
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataOperationsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: any): AxiosPromise<DatasetOperationModel> {
            return localVarFp.getDataOperationsV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginated list of data_operations that have been triggered for a dataset. Data Operations can be filtered by data_operation type, and status
         * @summary Paginated list of data_operations available for dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataOperationsV1(workspaceId: string, datasetId: string, start?: number, end?: number, ts?: string, options?: any): AxiosPromise<ListDataOperationsResponse> {
            return localVarFp.listDataOperationsV1(workspaceId, datasetId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginated list of data_operations that have been triggered for a workspace
         * @summary Paginated list of all available data_operations
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDataOperationsV2(workspaceId: string, listingAPIParams: ListingAPIParams, options?: any): AxiosPromise<ListDataOperationsResponse> {
            return localVarFp.listDataOperationsV2(workspaceId, listingAPIParams, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint modifies data operation attributes like name, description                     of the given data operation
         * @summary Update Data Operation
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {UpdateDataOperationRequest} updateDataOperationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataOperationV1(workspaceId: string, datasetId: string, dataOperationId: string, updateDataOperationRequest: UpdateDataOperationRequest, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.updateDataOperationV1(workspaceId, datasetId, dataOperationId, updateDataOperationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatasetOperationRoutesApi - object-oriented interface
 * @export
 * @class DatasetOperationRoutesApi
 * @extends {BaseAPI}
 */
export class DatasetOperationRoutesApi extends BaseAPI {
    /**
     * Creates a new data_operation for the specified dataset within a given workspace.
     * @summary Create a new Dataset DataOperations
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {CreateDataOperationRequest} createDataOperationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetOperationRoutesApi
     */
    public createDataOperationsV1(workspaceId: string, datasetId: string, createDataOperationRequest: CreateDataOperationRequest, options?: AxiosRequestConfig) {
        return DatasetOperationRoutesApiFp(this.configuration).createDataOperationsV1(workspaceId, datasetId, createDataOperationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update state of data operation resource (Active, Archive) Args:     update_request:         list{data_operation_id}: list of data operation ids to be modified         artifact_state: ARCHIVED/ACTIVE     workspace_id:     user:  Returns:
     * @summary Update data operation artifact state
     * @param {string} workspaceId 
     * @param {UpdateDataOperationArtifactStateRequest} updateDataOperationArtifactStateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetOperationRoutesApi
     */
    public dataOperationStateChangeV1(workspaceId: string, updateDataOperationArtifactStateRequest: UpdateDataOperationArtifactStateRequest, options?: AxiosRequestConfig) {
        return DatasetOperationRoutesApiFp(this.configuration).dataOperationStateChangeV1(workspaceId, updateDataOperationArtifactStateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a small sample of a dataset. Sampling strategies: random, sequential. Once sample is generated, same sample would be returned. Sample can be regenerated on demand by setting regenerate=True. Note: regenerate would have no effect if sampling strategy is sequential.
     * @summary Retrieves a small sample of a dataset
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {number} [numRows] 
     * @param {DatasetSamplingStrategy} [samplingStrategy] 
     * @param {boolean} [regenerate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetOperationRoutesApi
     */
    public generateDatasetSampleForDataOperationsV1(workspaceId: string, datasetId: string, dataOperationId: string, numRows?: number, samplingStrategy?: DatasetSamplingStrategy, regenerate?: boolean, options?: AxiosRequestConfig) {
        return DatasetOperationRoutesApiFp(this.configuration).generateDatasetSampleForDataOperationsV1(workspaceId, datasetId, dataOperationId, numRows, samplingStrategy, regenerate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a data_operation\'s details
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetOperationRoutesApi
     */
    public getDataOperationDetailsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig) {
        return DatasetOperationRoutesApiFp(this.configuration).getDataOperationDetailsV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches the current status of a dataset data_operation in the workspace. This API can be used for quick checks without retrieving full data_operation details.
     * @summary Get a data_operation\'s status
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetOperationRoutesApi
     */
    public getDataOperationsStatusV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig) {
        return DatasetOperationRoutesApiFp(this.configuration).getDataOperationsStatusV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a data_operation model
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetOperationRoutesApi
     */
    public getDataOperationsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig) {
        return DatasetOperationRoutesApiFp(this.configuration).getDataOperationsV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginated list of data_operations that have been triggered for a dataset. Data Operations can be filtered by data_operation type, and status
     * @summary Paginated list of data_operations available for dataset
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetOperationRoutesApi
     */
    public listDataOperationsV1(workspaceId: string, datasetId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig) {
        return DatasetOperationRoutesApiFp(this.configuration).listDataOperationsV1(workspaceId, datasetId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginated list of data_operations that have been triggered for a workspace
     * @summary Paginated list of all available data_operations
     * @param {string} workspaceId 
     * @param {ListingAPIParams} listingAPIParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetOperationRoutesApi
     */
    public listDataOperationsV2(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig) {
        return DatasetOperationRoutesApiFp(this.configuration).listDataOperationsV2(workspaceId, listingAPIParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint modifies data operation attributes like name, description                     of the given data operation
     * @summary Update Data Operation
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {UpdateDataOperationRequest} updateDataOperationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetOperationRoutesApi
     */
    public updateDataOperationV1(workspaceId: string, datasetId: string, dataOperationId: string, updateDataOperationRequest: UpdateDataOperationRequest, options?: AxiosRequestConfig) {
        return DatasetOperationRoutesApiFp(this.configuration).updateDataOperationV1(workspaceId, datasetId, dataOperationId, updateDataOperationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DatasetOperationRulesRoutesApi - axios parameter creator
 * @export
 */
export const DatasetOperationRulesRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a rule in a given data_operations.
         * @summary Create a new Rule in a data_operations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {CreateDataOperationRuleRequest} createDataOperationRuleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataOperationsRuleV1: async (workspaceId: string, datasetId: string, dataOperationId: string, createDataOperationRuleRequest: CreateDataOperationRuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createDataOperationsRuleV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('createDataOperationsRuleV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('createDataOperationsRuleV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'createDataOperationRuleRequest' is not null or undefined
            assertParamExists('createDataOperationsRuleV1', 'createDataOperationRuleRequest', createDataOperationRuleRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/data_operations/{data_operation_id}/rule`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDataOperationRuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches all the rules associated with the specified relabeling data_operations.
         * @summary List relabeling data_operations\'s rules
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRelabelingRulesV1: async (workspaceId: string, datasetId: string, dataOperationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listRelabelingRulesV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('listRelabelingRulesV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('listRelabelingRulesV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/data_operations/{data_operation_id}/rules`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the order of existing rules of a data_operations
         * @summary Reorder data_operations\'s rules
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {ReorderRulesRequest} reorderRulesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderDataOperationsRulesV1: async (workspaceId: string, datasetId: string, dataOperationId: string, reorderRulesRequest: ReorderRulesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('reorderDataOperationsRulesV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('reorderDataOperationsRulesV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('reorderDataOperationsRulesV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'reorderRulesRequest' is not null or undefined
            assertParamExists('reorderDataOperationsRulesV1', 'reorderRulesRequest', reorderRulesRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/data_operations/{data_operation_id}/rules/reorder`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reorderRulesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the settings of a rule in the relabeling data_operations.Conflicts with other are checked if rule is being saved with \'ACTIVE\' state.In case of conflict, a 409 response will be returned.
         * @summary Modify an Existing Relabeling Rule
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {UpdateRelabelingRuleRequest} updateRelabelingRuleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRelabelingRuleV1: async (workspaceId: string, datasetId: string, dataOperationId: string, updateRelabelingRuleRequest: UpdateRelabelingRuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateRelabelingRuleV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('updateRelabelingRuleV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('updateRelabelingRuleV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'updateRelabelingRuleRequest' is not null or undefined
            assertParamExists('updateRelabelingRuleV1', 'updateRelabelingRuleRequest', updateRelabelingRuleRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/rule-relabeling/{data_operation_id}/rule`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRelabelingRuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetOperationRulesRoutesApi - functional programming interface
 * @export
 */
export const DatasetOperationRulesRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetOperationRulesRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a rule in a given data_operations.
         * @summary Create a new Rule in a data_operations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {CreateDataOperationRuleRequest} createDataOperationRuleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDataOperationsRuleV1(workspaceId: string, datasetId: string, dataOperationId: string, createDataOperationRuleRequest: CreateDataOperationRuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDataOperationsRuleV1(workspaceId, datasetId, dataOperationId, createDataOperationRuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches all the rules associated with the specified relabeling data_operations.
         * @summary List relabeling data_operations\'s rules
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRelabelingRulesV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRulesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRelabelingRulesV1(workspaceId, datasetId, dataOperationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the order of existing rules of a data_operations
         * @summary Reorder data_operations\'s rules
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {ReorderRulesRequest} reorderRulesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reorderDataOperationsRulesV1(workspaceId: string, datasetId: string, dataOperationId: string, reorderRulesRequest: ReorderRulesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReorderRulesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reorderDataOperationsRulesV1(workspaceId, datasetId, dataOperationId, reorderRulesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the settings of a rule in the relabeling data_operations.Conflicts with other are checked if rule is being saved with \'ACTIVE\' state.In case of conflict, a 409 response will be returned.
         * @summary Modify an Existing Relabeling Rule
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {UpdateRelabelingRuleRequest} updateRelabelingRuleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRelabelingRuleV1(workspaceId: string, datasetId: string, dataOperationId: string, updateRelabelingRuleRequest: UpdateRelabelingRuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateRuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRelabelingRuleV1(workspaceId, datasetId, dataOperationId, updateRelabelingRuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DatasetOperationRulesRoutesApi - factory interface
 * @export
 */
export const DatasetOperationRulesRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetOperationRulesRoutesApiFp(configuration)
    return {
        /**
         * Create a rule in a given data_operations.
         * @summary Create a new Rule in a data_operations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {CreateDataOperationRuleRequest} createDataOperationRuleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataOperationsRuleV1(workspaceId: string, datasetId: string, dataOperationId: string, createDataOperationRuleRequest: CreateDataOperationRuleRequest, options?: any): AxiosPromise<RuleResponse> {
            return localVarFp.createDataOperationsRuleV1(workspaceId, datasetId, dataOperationId, createDataOperationRuleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches all the rules associated with the specified relabeling data_operations.
         * @summary List relabeling data_operations\'s rules
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRelabelingRulesV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: any): AxiosPromise<ListRulesResponse> {
            return localVarFp.listRelabelingRulesV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the order of existing rules of a data_operations
         * @summary Reorder data_operations\'s rules
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {ReorderRulesRequest} reorderRulesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderDataOperationsRulesV1(workspaceId: string, datasetId: string, dataOperationId: string, reorderRulesRequest: ReorderRulesRequest, options?: any): AxiosPromise<ReorderRulesResponse> {
            return localVarFp.reorderDataOperationsRulesV1(workspaceId, datasetId, dataOperationId, reorderRulesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the settings of a rule in the relabeling data_operations.Conflicts with other are checked if rule is being saved with \'ACTIVE\' state.In case of conflict, a 409 response will be returned.
         * @summary Modify an Existing Relabeling Rule
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {UpdateRelabelingRuleRequest} updateRelabelingRuleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRelabelingRuleV1(workspaceId: string, datasetId: string, dataOperationId: string, updateRelabelingRuleRequest: UpdateRelabelingRuleRequest, options?: any): AxiosPromise<UpdateRuleResponse> {
            return localVarFp.updateRelabelingRuleV1(workspaceId, datasetId, dataOperationId, updateRelabelingRuleRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatasetOperationRulesRoutesApi - object-oriented interface
 * @export
 * @class DatasetOperationRulesRoutesApi
 * @extends {BaseAPI}
 */
export class DatasetOperationRulesRoutesApi extends BaseAPI {
    /**
     * Create a rule in a given data_operations.
     * @summary Create a new Rule in a data_operations
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {CreateDataOperationRuleRequest} createDataOperationRuleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetOperationRulesRoutesApi
     */
    public createDataOperationsRuleV1(workspaceId: string, datasetId: string, dataOperationId: string, createDataOperationRuleRequest: CreateDataOperationRuleRequest, options?: AxiosRequestConfig) {
        return DatasetOperationRulesRoutesApiFp(this.configuration).createDataOperationsRuleV1(workspaceId, datasetId, dataOperationId, createDataOperationRuleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches all the rules associated with the specified relabeling data_operations.
     * @summary List relabeling data_operations\'s rules
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetOperationRulesRoutesApi
     */
    public listRelabelingRulesV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig) {
        return DatasetOperationRulesRoutesApiFp(this.configuration).listRelabelingRulesV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the order of existing rules of a data_operations
     * @summary Reorder data_operations\'s rules
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {ReorderRulesRequest} reorderRulesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetOperationRulesRoutesApi
     */
    public reorderDataOperationsRulesV1(workspaceId: string, datasetId: string, dataOperationId: string, reorderRulesRequest: ReorderRulesRequest, options?: AxiosRequestConfig) {
        return DatasetOperationRulesRoutesApiFp(this.configuration).reorderDataOperationsRulesV1(workspaceId, datasetId, dataOperationId, reorderRulesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the settings of a rule in the relabeling data_operations.Conflicts with other are checked if rule is being saved with \'ACTIVE\' state.In case of conflict, a 409 response will be returned.
     * @summary Modify an Existing Relabeling Rule
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {UpdateRelabelingRuleRequest} updateRelabelingRuleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetOperationRulesRoutesApi
     */
    public updateRelabelingRuleV1(workspaceId: string, datasetId: string, dataOperationId: string, updateRelabelingRuleRequest: UpdateRelabelingRuleRequest, options?: AxiosRequestConfig) {
        return DatasetOperationRulesRoutesApiFp(this.configuration).updateRelabelingRuleV1(workspaceId, datasetId, dataOperationId, updateRelabelingRuleRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DatasetV2APIsApi - axios parameter creator
 * @export
 */
export const DatasetV2APIsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns features of a dataset. This is a paginated API, and meant to be used for datasets with large number of features.
         * @summary Returns features of a datasets
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetFeaturesV1: async (workspaceId: string, datasetId: string, start?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetFeaturesV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDatasetFeaturesV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v2/workspace/{workspace_id}/datasets/{dataset_id}/features`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns datasets of a workspace. This API supports filtering on columns. List of supported filters can be fetched using `/v2/workspace/datasets-filters`.The `include_features` parameters can be used to include the names of features in the response. Setting to `True` may impact performance due to the additional data retrieval.
         * @summary Returns all datasets.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {boolean} [includeFeatures] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetListV2: async (workspaceId: string, listingAPIParams: ListingAPIParams, includeFeatures?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetListV2', 'workspaceId', workspaceId)
            // verify required parameter 'listingAPIParams' is not null or undefined
            assertParamExists('getDatasetListV2', 'listingAPIParams', listingAPIParams)
            const localVarPath = `/vienna/v2/workspace/{workspace_id}/datasets`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeFeatures !== undefined) {
                localVarQueryParameter['include_features'] = includeFeatures;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingAPIParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns datasets of a workspace. This API supports filtering on columns. List of supported filters can be fetched using `/v2/workspace/datasets-filters`
         * @summary Returns all datasets.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetListV3: async (workspaceId: string, listingAPIParams: ListingAPIParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetListV3', 'workspaceId', workspaceId)
            // verify required parameter 'listingAPIParams' is not null or undefined
            assertParamExists('getDatasetListV3', 'listingAPIParams', listingAPIParams)
            const localVarPath = `/vienna/v2/workspace/{workspace_id}/datasets/dataviews`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingAPIParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all supported filters for datasets of a workspace.
         * @summary Returns all datasets filters.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetListingFiltersV2: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetListingFiltersV2', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v2/workspace/{workspace_id}/datasets/dataset-filters`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetV2APIsApi - functional programming interface
 * @export
 */
export const DatasetV2APIsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetV2APIsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns features of a dataset. This is a paginated API, and meant to be used for datasets with large number of features.
         * @summary Returns features of a datasets
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetFeaturesV1(workspaceId: string, datasetId: string, start?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDatasetFeaturesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetFeaturesV1(workspaceId, datasetId, start, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns datasets of a workspace. This API supports filtering on columns. List of supported filters can be fetched using `/v2/workspace/datasets-filters`.The `include_features` parameters can be used to include the names of features in the response. Setting to `True` may impact performance due to the additional data retrieval.
         * @summary Returns all datasets.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {boolean} [includeFeatures] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetListV2(workspaceId: string, listingAPIParams: ListingAPIParams, includeFeatures?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDatasetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetListV2(workspaceId, listingAPIParams, includeFeatures, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns datasets of a workspace. This API supports filtering on columns. List of supported filters can be fetched using `/v2/workspace/datasets-filters`
         * @summary Returns all datasets.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetListV3(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDatasetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetListV3(workspaceId, listingAPIParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all supported filters for datasets of a workspace.
         * @summary Returns all datasets filters.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetListingFiltersV2(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllowedFilters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetListingFiltersV2(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DatasetV2APIsApi - factory interface
 * @export
 */
export const DatasetV2APIsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetV2APIsApiFp(configuration)
    return {
        /**
         * Returns features of a dataset. This is a paginated API, and meant to be used for datasets with large number of features.
         * @summary Returns features of a datasets
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetFeaturesV1(workspaceId: string, datasetId: string, start?: number, limit?: number, options?: any): AxiosPromise<ListDatasetFeaturesResponse> {
            return localVarFp.getDatasetFeaturesV1(workspaceId, datasetId, start, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns datasets of a workspace. This API supports filtering on columns. List of supported filters can be fetched using `/v2/workspace/datasets-filters`.The `include_features` parameters can be used to include the names of features in the response. Setting to `True` may impact performance due to the additional data retrieval.
         * @summary Returns all datasets.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {boolean} [includeFeatures] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetListV2(workspaceId: string, listingAPIParams: ListingAPIParams, includeFeatures?: boolean, options?: any): AxiosPromise<ListDatasetResponse> {
            return localVarFp.getDatasetListV2(workspaceId, listingAPIParams, includeFeatures, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns datasets of a workspace. This API supports filtering on columns. List of supported filters can be fetched using `/v2/workspace/datasets-filters`
         * @summary Returns all datasets.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetListV3(workspaceId: string, listingAPIParams: ListingAPIParams, options?: any): AxiosPromise<ListDatasetResponse> {
            return localVarFp.getDatasetListV3(workspaceId, listingAPIParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all supported filters for datasets of a workspace.
         * @summary Returns all datasets filters.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetListingFiltersV2(workspaceId: string, options?: any): AxiosPromise<AllowedFilters> {
            return localVarFp.getDatasetListingFiltersV2(workspaceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatasetV2APIsApi - object-oriented interface
 * @export
 * @class DatasetV2APIsApi
 * @extends {BaseAPI}
 */
export class DatasetV2APIsApi extends BaseAPI {
    /**
     * Returns features of a dataset. This is a paginated API, and meant to be used for datasets with large number of features.
     * @summary Returns features of a datasets
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {number} [start] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetV2APIsApi
     */
    public getDatasetFeaturesV1(workspaceId: string, datasetId: string, start?: number, limit?: number, options?: AxiosRequestConfig) {
        return DatasetV2APIsApiFp(this.configuration).getDatasetFeaturesV1(workspaceId, datasetId, start, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns datasets of a workspace. This API supports filtering on columns. List of supported filters can be fetched using `/v2/workspace/datasets-filters`.The `include_features` parameters can be used to include the names of features in the response. Setting to `True` may impact performance due to the additional data retrieval.
     * @summary Returns all datasets.
     * @param {string} workspaceId 
     * @param {ListingAPIParams} listingAPIParams 
     * @param {boolean} [includeFeatures] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetV2APIsApi
     */
    public getDatasetListV2(workspaceId: string, listingAPIParams: ListingAPIParams, includeFeatures?: boolean, options?: AxiosRequestConfig) {
        return DatasetV2APIsApiFp(this.configuration).getDatasetListV2(workspaceId, listingAPIParams, includeFeatures, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns datasets of a workspace. This API supports filtering on columns. List of supported filters can be fetched using `/v2/workspace/datasets-filters`
     * @summary Returns all datasets.
     * @param {string} workspaceId 
     * @param {ListingAPIParams} listingAPIParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetV2APIsApi
     */
    public getDatasetListV3(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig) {
        return DatasetV2APIsApiFp(this.configuration).getDatasetListV3(workspaceId, listingAPIParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all supported filters for datasets of a workspace.
     * @summary Returns all datasets filters.
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetV2APIsApi
     */
    public getDatasetListingFiltersV2(workspaceId: string, options?: AxiosRequestConfig) {
        return DatasetV2APIsApiFp(this.configuration).getDatasetListingFiltersV2(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Endpoint to fetch details about a single evalaution including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} evaluationId ID of the evaluation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationMetadataWithModelAndDatasetInfoV1: async (workspaceId: string, evaluationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getEvaluationMetadataWithModelAndDatasetInfoV1', 'workspaceId', workspaceId)
            // verify required parameter 'evaluationId' is not null or undefined
            assertParamExists('getEvaluationMetadataWithModelAndDatasetInfoV1', 'evaluationId', evaluationId)
            const localVarPath = `/v1/workspaces/{workspaceId}/evaluations/{evaluationId}/full-details`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"evaluationId"}}`, encodeURIComponent(String(evaluationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to fetch details about a single experiment including model and dataset info.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} experimentId ID of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentWithModelAndDatasetInfoV1: async (workspaceId: string, experimentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getExperimentWithModelAndDatasetInfoV1', 'workspaceId', workspaceId)
            // verify required parameter 'experimentId' is not null or undefined
            assertParamExists('getExperimentWithModelAndDatasetInfoV1', 'experimentId', experimentId)
            const localVarPath = `/v1/workspaces/{workspaceId}/experiments/{experimentId}/full-details`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * To fetch in app notifications in a paginated form from time to time for a particular user for all the workspaces he/she uses
         * @summary To fetch in app notifications for a user
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {Array<string>} [workspaceIds] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getInAppNotificationsV1: async (userId: string, start?: number, end?: number, ts?: string, workspaceIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getInAppNotificationsV1', 'userId', userId)
            const localVarPath = `/paris/v1/notifications/in-app/user/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (workspaceIds) {
                localVarQueryParameter['workspace_ids'] = workspaceIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get notification request and response details. Requests and responses are kept in two separate databases.NotificationRequests & NotificationResponses.
         * @summary Get notification request and response details
         * @param {NotificationIdList} notificationIdList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationDetailsV1: async (notificationIdList: NotificationIdList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationIdList' is not null or undefined
            assertParamExists('getNotificationDetailsV1', 'notificationIdList', notificationIdList)
            const localVarPath = `/paris/v1/notification/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationIdList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Slack integration status by looking up the MARKOV_NOTIFICATION_DB.
         * @summary Integration Status
         * @param {SlackIntegrationStatusRequest} slackIntegrationStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlackIntegrationStatus: async (slackIntegrationStatusRequest: SlackIntegrationStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slackIntegrationStatusRequest' is not null or undefined
            assertParamExists('getSlackIntegrationStatus', 'slackIntegrationStatusRequest', slackIntegrationStatusRequest)
            const localVarPath = `/paris/slack_integration_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(slackIntegrationStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This api endpoint is called by milan when user click on the Add Slack button which is present on the integration tab of setting page. This route takes code received at the end of slack authorize step. The code is used to fetch incoming webhook from slack. Incoming webhook is needed for sending messages to slack
         * @summary Handle slack authorize code to persist incoming webhook
         * @param {string} userId 
         * @param {SlackAuthorizeHandleRequest} slackAuthorizeHandleRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        handleSlackAuthorizeCodeV1: async (userId: string, slackAuthorizeHandleRequest: SlackAuthorizeHandleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('handleSlackAuthorizeCodeV1', 'userId', userId)
            // verify required parameter 'slackAuthorizeHandleRequest' is not null or undefined
            assertParamExists('handleSlackAuthorizeCodeV1', 'slackAuthorizeHandleRequest', slackAuthorizeHandleRequest)
            const localVarPath = `/paris/v1/slack/handle-authorize-code/user/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(slackAuthorizeHandleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Integrations
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntegrations: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listIntegrations', 'workspaceId', workspaceId)
            const localVarPath = `/paris/v1/integrations/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspaceId'] = workspaceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Notify a user with the given account id and member id  Args:     user_id:     notify_request (NotifyRequestModel): Notify Request Model     authenticate (bool): whether the token is authenticated or not  Returns:     NotifyResponseModel
         * @summary Notify Member By User Id
         * @param {string} userId 
         * @param {NotifyRequestModel} notifyRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyMemberByUserIdV1NotifyUserUserIdPost: async (userId: string, notifyRequestModel: NotifyRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('notifyMemberByUserIdV1NotifyUserUserIdPost', 'userId', userId)
            // verify required parameter 'notifyRequestModel' is not null or undefined
            assertParamExists('notifyMemberByUserIdV1NotifyUserUserIdPost', 'notifyRequestModel', notifyRequestModel)
            const localVarPath = `/paris/v1/notify/user/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notifyRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Notify a user with the given account id and member id  Args:     notify_request (NotifyRequestModel): Notify Request Model     authenticate (bool): whether the token is authenticated or not  Returns:     NotifyResponseModel
         * @summary Notify Member
         * @param {NotifyRequestModel} notifyRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyMemberV1NotifyPost: async (notifyRequestModel: NotifyRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notifyRequestModel' is not null or undefined
            assertParamExists('notifyMemberV1NotifyPost', 'notifyRequestModel', notifyRequestModel)
            const localVarPath = `/paris/v1/notify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notifyRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Notify users in bulk with given payload.  Args:     notify_request (BulkNotifyRequestModel): Notify Request Model containing info and metadata about     the bulk notifications to be sent.     authenticate (bool): whether the token is authenticated or not  Returns:     BulkNotifyResponseModel: contains basic payload and acknowledgement on the requested task done
         * @summary Notify Users In Bulk
         * @param {BulkNotifyRequestModel} bulkNotifyRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyUsersInBulkV1NotifyUsersPost: async (bulkNotifyRequestModel: BulkNotifyRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkNotifyRequestModel' is not null or undefined
            assertParamExists('notifyUsersInBulkV1NotifyUsersPost', 'bulkNotifyRequestModel', bulkNotifyRequestModel)
            const localVarPath = `/paris/v1/notify/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkNotifyRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add ack responses of each notification to database
         * @param {AckRequestModel} ackRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveACKResponseP1: async (ackRequestModel: AckRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ackRequestModel' is not null or undefined
            assertParamExists('saveACKResponseP1', 'ackRequestModel', ackRequestModel)
            const localVarPath = `/paris/ack_dbrecord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ackRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add in-app notification to the database
         * @param {InAppAckRequest} inAppAckRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        saveAckInAppNotifications: async (inAppAckRequest: InAppAckRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inAppAckRequest' is not null or undefined
            assertParamExists('saveAckInAppNotifications', 'inAppAckRequest', inAppAckRequest)
            const localVarPath = `/paris/add-in-app-record`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inAppAckRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * When the mark all as read button from the frontend will be clicked, we have to mark all the notifications of that user for that workspace as read
         * @summary Marks all notifications corresponding to a user and workspace as read
         * @param {string} userId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateAllNotificationsStatusV1: async (userId: string, workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateAllNotificationsStatusV1', 'userId', userId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateAllNotificationsStatusV1', 'workspaceId', workspaceId)
            const localVarPath = `/paris/v1/notifications/in-app/workspace/{workspace_id}/user/{user_id}/mark-all-as-read`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * We receive the notification ids for which we have to mark the notifications as read
         * @summary Changed the status of a certain notification to read
         * @param {string} recordId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateNotificationStatusV1: async (recordId: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordId' is not null or undefined
            assertParamExists('updateNotificationStatusV1', 'recordId', recordId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateNotificationStatusV1', 'requestBody', requestBody)
            const localVarPath = `/paris/v1/notifications/in-app/{record_id}/mark-as-read`
                .replace(`{${"record_id"}}`, encodeURIComponent(String(recordId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate email address whether it is valid and exists or not.
         * @summary Validate email address.
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmailAddressV1: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('validateEmailAddressV1', 'email', email)
            const localVarPath = `/paris/v1/email/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Endpoint to fetch details about a single evalaution including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} evaluationId ID of the evaluation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvaluationMetadataWithModelAndDatasetInfoV1(workspaceId: string, evaluationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEvaluationWithModelAndDatasetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvaluationMetadataWithModelAndDatasetInfoV1(workspaceId, evaluationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to fetch details about a single experiment including model and dataset info.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} experimentId ID of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentWithModelAndDatasetInfoV1(workspaceId: string, experimentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExperimentWithModelAndDatasetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentWithModelAndDatasetInfoV1(workspaceId, experimentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * To fetch in app notifications in a paginated form from time to time for a particular user for all the workspaces he/she uses
         * @summary To fetch in app notifications for a user
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {Array<string>} [workspaceIds] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getInAppNotificationsV1(userId: string, start?: number, end?: number, ts?: string, workspaceIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInAppNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInAppNotificationsV1(userId, start, end, ts, workspaceIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get notification request and response details. Requests and responses are kept in two separate databases.NotificationRequests & NotificationResponses.
         * @summary Get notification request and response details
         * @param {NotificationIdList} notificationIdList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationDetailsV1(notificationIdList: NotificationIdList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationDetailsV1(notificationIdList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Slack integration status by looking up the MARKOV_NOTIFICATION_DB.
         * @summary Integration Status
         * @param {SlackIntegrationStatusRequest} slackIntegrationStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSlackIntegrationStatus(slackIntegrationStatusRequest: SlackIntegrationStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlackIntegrationStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSlackIntegrationStatus(slackIntegrationStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This api endpoint is called by milan when user click on the Add Slack button which is present on the integration tab of setting page. This route takes code received at the end of slack authorize step. The code is used to fetch incoming webhook from slack. Incoming webhook is needed for sending messages to slack
         * @summary Handle slack authorize code to persist incoming webhook
         * @param {string} userId 
         * @param {SlackAuthorizeHandleRequest} slackAuthorizeHandleRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async handleSlackAuthorizeCodeV1(userId: string, slackAuthorizeHandleRequest: SlackAuthorizeHandleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlackAuthorizeHandleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleSlackAuthorizeCodeV1(userId, slackAuthorizeHandleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Integrations
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listIntegrations(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListIntegrationResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listIntegrations(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Notify a user with the given account id and member id  Args:     user_id:     notify_request (NotifyRequestModel): Notify Request Model     authenticate (bool): whether the token is authenticated or not  Returns:     NotifyResponseModel
         * @summary Notify Member By User Id
         * @param {string} userId 
         * @param {NotifyRequestModel} notifyRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyMemberByUserIdV1NotifyUserUserIdPost(userId: string, notifyRequestModel: NotifyRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifyMemberByUserIdV1NotifyUserUserIdPost(userId, notifyRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Notify a user with the given account id and member id  Args:     notify_request (NotifyRequestModel): Notify Request Model     authenticate (bool): whether the token is authenticated or not  Returns:     NotifyResponseModel
         * @summary Notify Member
         * @param {NotifyRequestModel} notifyRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyMemberV1NotifyPost(notifyRequestModel: NotifyRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifyMemberV1NotifyPost(notifyRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Notify users in bulk with given payload.  Args:     notify_request (BulkNotifyRequestModel): Notify Request Model containing info and metadata about     the bulk notifications to be sent.     authenticate (bool): whether the token is authenticated or not  Returns:     BulkNotifyResponseModel: contains basic payload and acknowledgement on the requested task done
         * @summary Notify Users In Bulk
         * @param {BulkNotifyRequestModel} bulkNotifyRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyUsersInBulkV1NotifyUsersPost(bulkNotifyRequestModel: BulkNotifyRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkNotifyResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifyUsersInBulkV1NotifyUsersPost(bulkNotifyRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add ack responses of each notification to database
         * @param {AckRequestModel} ackRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveACKResponseP1(ackRequestModel: AckRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ACKResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveACKResponseP1(ackRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add in-app notification to the database
         * @param {InAppAckRequest} inAppAckRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async saveAckInAppNotifications(inAppAckRequest: InAppAckRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationACKResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAckInAppNotifications(inAppAckRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * When the mark all as read button from the frontend will be clicked, we have to mark all the notifications of that user for that workspace as read
         * @summary Marks all notifications corresponding to a user and workspace as read
         * @param {string} userId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateAllNotificationsStatusV1(userId: string, workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAllInAppNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllNotificationsStatusV1(userId, workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * We receive the notification ids for which we have to mark the notifications as read
         * @summary Changed the status of a certain notification to read
         * @param {string} recordId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateNotificationStatusV1(recordId: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateInAppNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationStatusV1(recordId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validate email address whether it is valid and exists or not.
         * @summary Validate email address.
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateEmailAddressV1(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailValidationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateEmailAddressV1(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Endpoint to fetch details about a single evalaution including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} evaluationId ID of the evaluation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationMetadataWithModelAndDatasetInfoV1(workspaceId: string, evaluationId: string, options?: any): AxiosPromise<GetEvaluationWithModelAndDatasetResponse> {
            return localVarFp.getEvaluationMetadataWithModelAndDatasetInfoV1(workspaceId, evaluationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to fetch details about a single experiment including model and dataset info.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} experimentId ID of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentWithModelAndDatasetInfoV1(workspaceId: string, experimentId: string, options?: any): AxiosPromise<GetExperimentWithModelAndDatasetResponse> {
            return localVarFp.getExperimentWithModelAndDatasetInfoV1(workspaceId, experimentId, options).then((request) => request(axios, basePath));
        },
        /**
         * To fetch in app notifications in a paginated form from time to time for a particular user for all the workspaces he/she uses
         * @summary To fetch in app notifications for a user
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {Array<string>} [workspaceIds] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getInAppNotificationsV1(userId: string, start?: number, end?: number, ts?: string, workspaceIds?: Array<string>, options?: any): AxiosPromise<ListInAppNotificationsResponse> {
            return localVarFp.getInAppNotificationsV1(userId, start, end, ts, workspaceIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Get notification request and response details. Requests and responses are kept in two separate databases.NotificationRequests & NotificationResponses.
         * @summary Get notification request and response details
         * @param {NotificationIdList} notificationIdList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationDetailsV1(notificationIdList: NotificationIdList, options?: any): AxiosPromise<NotificationDetails> {
            return localVarFp.getNotificationDetailsV1(notificationIdList, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Slack integration status by looking up the MARKOV_NOTIFICATION_DB.
         * @summary Integration Status
         * @param {SlackIntegrationStatusRequest} slackIntegrationStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlackIntegrationStatus(slackIntegrationStatusRequest: SlackIntegrationStatusRequest, options?: any): AxiosPromise<SlackIntegrationStatusResponse> {
            return localVarFp.getSlackIntegrationStatus(slackIntegrationStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This api endpoint is called by milan when user click on the Add Slack button which is present on the integration tab of setting page. This route takes code received at the end of slack authorize step. The code is used to fetch incoming webhook from slack. Incoming webhook is needed for sending messages to slack
         * @summary Handle slack authorize code to persist incoming webhook
         * @param {string} userId 
         * @param {SlackAuthorizeHandleRequest} slackAuthorizeHandleRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        handleSlackAuthorizeCodeV1(userId: string, slackAuthorizeHandleRequest: SlackAuthorizeHandleRequest, options?: any): AxiosPromise<SlackAuthorizeHandleResponse> {
            return localVarFp.handleSlackAuthorizeCodeV1(userId, slackAuthorizeHandleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Integrations
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntegrations(workspaceId: string, options?: any): AxiosPromise<ListIntegrationResponseModel> {
            return localVarFp.listIntegrations(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Notify a user with the given account id and member id  Args:     user_id:     notify_request (NotifyRequestModel): Notify Request Model     authenticate (bool): whether the token is authenticated or not  Returns:     NotifyResponseModel
         * @summary Notify Member By User Id
         * @param {string} userId 
         * @param {NotifyRequestModel} notifyRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyMemberByUserIdV1NotifyUserUserIdPost(userId: string, notifyRequestModel: NotifyRequestModel, options?: any): AxiosPromise<any> {
            return localVarFp.notifyMemberByUserIdV1NotifyUserUserIdPost(userId, notifyRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Notify a user with the given account id and member id  Args:     notify_request (NotifyRequestModel): Notify Request Model     authenticate (bool): whether the token is authenticated or not  Returns:     NotifyResponseModel
         * @summary Notify Member
         * @param {NotifyRequestModel} notifyRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyMemberV1NotifyPost(notifyRequestModel: NotifyRequestModel, options?: any): AxiosPromise<any> {
            return localVarFp.notifyMemberV1NotifyPost(notifyRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Notify users in bulk with given payload.  Args:     notify_request (BulkNotifyRequestModel): Notify Request Model containing info and metadata about     the bulk notifications to be sent.     authenticate (bool): whether the token is authenticated or not  Returns:     BulkNotifyResponseModel: contains basic payload and acknowledgement on the requested task done
         * @summary Notify Users In Bulk
         * @param {BulkNotifyRequestModel} bulkNotifyRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyUsersInBulkV1NotifyUsersPost(bulkNotifyRequestModel: BulkNotifyRequestModel, options?: any): AxiosPromise<BulkNotifyResponseModel> {
            return localVarFp.notifyUsersInBulkV1NotifyUsersPost(bulkNotifyRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add ack responses of each notification to database
         * @param {AckRequestModel} ackRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveACKResponseP1(ackRequestModel: AckRequestModel, options?: any): AxiosPromise<ACKResponseModel> {
            return localVarFp.saveACKResponseP1(ackRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add in-app notification to the database
         * @param {InAppAckRequest} inAppAckRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        saveAckInAppNotifications(inAppAckRequest: InAppAckRequest, options?: any): AxiosPromise<NotificationACKResponse> {
            return localVarFp.saveAckInAppNotifications(inAppAckRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * When the mark all as read button from the frontend will be clicked, we have to mark all the notifications of that user for that workspace as read
         * @summary Marks all notifications corresponding to a user and workspace as read
         * @param {string} userId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateAllNotificationsStatusV1(userId: string, workspaceId: string, options?: any): AxiosPromise<UpdateAllInAppNotificationsResponse> {
            return localVarFp.updateAllNotificationsStatusV1(userId, workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * We receive the notification ids for which we have to mark the notifications as read
         * @summary Changed the status of a certain notification to read
         * @param {string} recordId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateNotificationStatusV1(recordId: string, requestBody: Array<string>, options?: any): AxiosPromise<UpdateInAppNotificationsResponse> {
            return localVarFp.updateNotificationStatusV1(recordId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Validate email address whether it is valid and exists or not.
         * @summary Validate email address.
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmailAddressV1(email: string, options?: any): AxiosPromise<EmailValidationResponse> {
            return localVarFp.validateEmailAddressV1(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Endpoint to fetch details about a single evalaution including any associated tags.
     * @param {string} workspaceId ID of the workspace.
     * @param {string} evaluationId ID of the evaluation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEvaluationMetadataWithModelAndDatasetInfoV1(workspaceId: string, evaluationId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEvaluationMetadataWithModelAndDatasetInfoV1(workspaceId, evaluationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to fetch details about a single experiment including model and dataset info.
     * @param {string} workspaceId ID of the workspace.
     * @param {string} experimentId ID of the experiment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getExperimentWithModelAndDatasetInfoV1(workspaceId: string, experimentId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getExperimentWithModelAndDatasetInfoV1(workspaceId, experimentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * To fetch in app notifications in a paginated form from time to time for a particular user for all the workspaces he/she uses
     * @summary To fetch in app notifications for a user
     * @param {string} userId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {Array<string>} [workspaceIds] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getInAppNotificationsV1(userId: string, start?: number, end?: number, ts?: string, workspaceIds?: Array<string>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getInAppNotificationsV1(userId, start, end, ts, workspaceIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get notification request and response details. Requests and responses are kept in two separate databases.NotificationRequests & NotificationResponses.
     * @summary Get notification request and response details
     * @param {NotificationIdList} notificationIdList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNotificationDetailsV1(notificationIdList: NotificationIdList, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getNotificationDetailsV1(notificationIdList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Slack integration status by looking up the MARKOV_NOTIFICATION_DB.
     * @summary Integration Status
     * @param {SlackIntegrationStatusRequest} slackIntegrationStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSlackIntegrationStatus(slackIntegrationStatusRequest: SlackIntegrationStatusRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSlackIntegrationStatus(slackIntegrationStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This api endpoint is called by milan when user click on the Add Slack button which is present on the integration tab of setting page. This route takes code received at the end of slack authorize step. The code is used to fetch incoming webhook from slack. Incoming webhook is needed for sending messages to slack
     * @summary Handle slack authorize code to persist incoming webhook
     * @param {string} userId 
     * @param {SlackAuthorizeHandleRequest} slackAuthorizeHandleRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public handleSlackAuthorizeCodeV1(userId: string, slackAuthorizeHandleRequest: SlackAuthorizeHandleRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).handleSlackAuthorizeCodeV1(userId, slackAuthorizeHandleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Integrations
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listIntegrations(workspaceId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listIntegrations(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Notify a user with the given account id and member id  Args:     user_id:     notify_request (NotifyRequestModel): Notify Request Model     authenticate (bool): whether the token is authenticated or not  Returns:     NotifyResponseModel
     * @summary Notify Member By User Id
     * @param {string} userId 
     * @param {NotifyRequestModel} notifyRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notifyMemberByUserIdV1NotifyUserUserIdPost(userId: string, notifyRequestModel: NotifyRequestModel, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).notifyMemberByUserIdV1NotifyUserUserIdPost(userId, notifyRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Notify a user with the given account id and member id  Args:     notify_request (NotifyRequestModel): Notify Request Model     authenticate (bool): whether the token is authenticated or not  Returns:     NotifyResponseModel
     * @summary Notify Member
     * @param {NotifyRequestModel} notifyRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notifyMemberV1NotifyPost(notifyRequestModel: NotifyRequestModel, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).notifyMemberV1NotifyPost(notifyRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Notify users in bulk with given payload.  Args:     notify_request (BulkNotifyRequestModel): Notify Request Model containing info and metadata about     the bulk notifications to be sent.     authenticate (bool): whether the token is authenticated or not  Returns:     BulkNotifyResponseModel: contains basic payload and acknowledgement on the requested task done
     * @summary Notify Users In Bulk
     * @param {BulkNotifyRequestModel} bulkNotifyRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notifyUsersInBulkV1NotifyUsersPost(bulkNotifyRequestModel: BulkNotifyRequestModel, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).notifyUsersInBulkV1NotifyUsersPost(bulkNotifyRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add ack responses of each notification to database
     * @param {AckRequestModel} ackRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveACKResponseP1(ackRequestModel: AckRequestModel, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveACKResponseP1(ackRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add in-app notification to the database
     * @param {InAppAckRequest} inAppAckRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveAckInAppNotifications(inAppAckRequest: InAppAckRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveAckInAppNotifications(inAppAckRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * When the mark all as read button from the frontend will be clicked, we have to mark all the notifications of that user for that workspace as read
     * @summary Marks all notifications corresponding to a user and workspace as read
     * @param {string} userId 
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAllNotificationsStatusV1(userId: string, workspaceId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateAllNotificationsStatusV1(userId, workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * We receive the notification ids for which we have to mark the notifications as read
     * @summary Changed the status of a certain notification to read
     * @param {string} recordId 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateNotificationStatusV1(recordId: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateNotificationStatusV1(recordId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate email address whether it is valid and exists or not.
     * @summary Validate email address.
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public validateEmailAddressV1(email: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).validateEmailAddressV1(email, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemoArtifactOperationRoutesApi - axios parameter creator
 * @export
 */
export const DemoArtifactOperationRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This is an internal method for fetching demo artifacts from {demo-wsp-8vbr266tis}. This will be copied at the time of creation of user workspace.
         * @summary Fetch demo artifacts from demo workspace id {demo-wsp-8vbr266tis}
         * @param {CreateDemoArtifactsRequest} createDemoArtifactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyDemoArtifactsV1: async (createDemoArtifactsRequest: CreateDemoArtifactsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDemoArtifactsRequest' is not null or undefined
            assertParamExists('copyDemoArtifactsV1', 'createDemoArtifactsRequest', createDemoArtifactsRequest)
            const localVarPath = `/vienna/v1/artifact/demo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDemoArtifactsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch status of migration of artifacts from demo workspace.
         * @summary Fetch demo artifacts migration status
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoArtifactsMigrationStatusV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('demoArtifactsMigrationStatusV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/artifact/demo/migration-status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch count of artifacts which are demo and non demo.
         * @summary Fetch demo artifacts and non demo artifacts count
         * @param {string} workspaceId 
         * @param {MarkovArtifacts} artifactType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarkovArtifactCountV1: async (workspaceId: string, artifactType: MarkovArtifacts, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getMarkovArtifactCountV1', 'workspaceId', workspaceId)
            // verify required parameter 'artifactType' is not null or undefined
            assertParamExists('getMarkovArtifactCountV1', 'artifactType', artifactType)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/artifact/{artifact_type}/count`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"artifact_type"}}`, encodeURIComponent(String(artifactType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemoArtifactOperationRoutesApi - functional programming interface
 * @export
 */
export const DemoArtifactOperationRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemoArtifactOperationRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * This is an internal method for fetching demo artifacts from {demo-wsp-8vbr266tis}. This will be copied at the time of creation of user workspace.
         * @summary Fetch demo artifacts from demo workspace id {demo-wsp-8vbr266tis}
         * @param {CreateDemoArtifactsRequest} createDemoArtifactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyDemoArtifactsV1(createDemoArtifactsRequest: CreateDemoArtifactsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyDemoArtifactsV1(createDemoArtifactsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch status of migration of artifacts from demo workspace.
         * @summary Fetch demo artifacts migration status
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demoArtifactsMigrationStatusV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkovArtifactMigrationStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.demoArtifactsMigrationStatusV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch count of artifacts which are demo and non demo.
         * @summary Fetch demo artifacts and non demo artifacts count
         * @param {string} workspaceId 
         * @param {MarkovArtifacts} artifactType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarkovArtifactCountV1(workspaceId: string, artifactType: MarkovArtifacts, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkovArtifactCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarkovArtifactCountV1(workspaceId, artifactType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemoArtifactOperationRoutesApi - factory interface
 * @export
 */
export const DemoArtifactOperationRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemoArtifactOperationRoutesApiFp(configuration)
    return {
        /**
         * This is an internal method for fetching demo artifacts from {demo-wsp-8vbr266tis}. This will be copied at the time of creation of user workspace.
         * @summary Fetch demo artifacts from demo workspace id {demo-wsp-8vbr266tis}
         * @param {CreateDemoArtifactsRequest} createDemoArtifactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyDemoArtifactsV1(createDemoArtifactsRequest: CreateDemoArtifactsRequest, options?: any): AxiosPromise<any> {
            return localVarFp.copyDemoArtifactsV1(createDemoArtifactsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch status of migration of artifacts from demo workspace.
         * @summary Fetch demo artifacts migration status
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoArtifactsMigrationStatusV1(workspaceId: string, options?: any): AxiosPromise<MarkovArtifactMigrationStatusResponse> {
            return localVarFp.demoArtifactsMigrationStatusV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch count of artifacts which are demo and non demo.
         * @summary Fetch demo artifacts and non demo artifacts count
         * @param {string} workspaceId 
         * @param {MarkovArtifacts} artifactType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarkovArtifactCountV1(workspaceId: string, artifactType: MarkovArtifacts, options?: any): AxiosPromise<MarkovArtifactCount> {
            return localVarFp.getMarkovArtifactCountV1(workspaceId, artifactType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemoArtifactOperationRoutesApi - object-oriented interface
 * @export
 * @class DemoArtifactOperationRoutesApi
 * @extends {BaseAPI}
 */
export class DemoArtifactOperationRoutesApi extends BaseAPI {
    /**
     * This is an internal method for fetching demo artifacts from {demo-wsp-8vbr266tis}. This will be copied at the time of creation of user workspace.
     * @summary Fetch demo artifacts from demo workspace id {demo-wsp-8vbr266tis}
     * @param {CreateDemoArtifactsRequest} createDemoArtifactsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoArtifactOperationRoutesApi
     */
    public copyDemoArtifactsV1(createDemoArtifactsRequest: CreateDemoArtifactsRequest, options?: AxiosRequestConfig) {
        return DemoArtifactOperationRoutesApiFp(this.configuration).copyDemoArtifactsV1(createDemoArtifactsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch status of migration of artifacts from demo workspace.
     * @summary Fetch demo artifacts migration status
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoArtifactOperationRoutesApi
     */
    public demoArtifactsMigrationStatusV1(workspaceId: string, options?: AxiosRequestConfig) {
        return DemoArtifactOperationRoutesApiFp(this.configuration).demoArtifactsMigrationStatusV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch count of artifacts which are demo and non demo.
     * @summary Fetch demo artifacts and non demo artifacts count
     * @param {string} workspaceId 
     * @param {MarkovArtifacts} artifactType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoArtifactOperationRoutesApi
     */
    public getMarkovArtifactCountV1(workspaceId: string, artifactType: MarkovArtifacts, options?: AxiosRequestConfig) {
        return DemoArtifactOperationRoutesApiFp(this.configuration).getMarkovArtifactCountV1(workspaceId, artifactType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventsTrackingManagementApi - axios parameter creator
 * @export
 */
export const EventsTrackingManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Event endpoint for Amplitude
         * @summary Amplitude webhook endpoint
         * @param {AmplitudeEvent} amplitudeEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        amplitudeWebhook: async (amplitudeEvent: AmplitudeEvent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'amplitudeEvent' is not null or undefined
            assertParamExists('amplitudeWebhook', 'amplitudeEvent', amplitudeEvent)
            const localVarPath = `/bangalore/amplitude/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(amplitudeEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsTrackingManagementApi - functional programming interface
 * @export
 */
export const EventsTrackingManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsTrackingManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Event endpoint for Amplitude
         * @summary Amplitude webhook endpoint
         * @param {AmplitudeEvent} amplitudeEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async amplitudeWebhook(amplitudeEvent: AmplitudeEvent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.amplitudeWebhook(amplitudeEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventsTrackingManagementApi - factory interface
 * @export
 */
export const EventsTrackingManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsTrackingManagementApiFp(configuration)
    return {
        /**
         * Event endpoint for Amplitude
         * @summary Amplitude webhook endpoint
         * @param {AmplitudeEvent} amplitudeEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        amplitudeWebhook(amplitudeEvent: AmplitudeEvent, options?: any): AxiosPromise<any> {
            return localVarFp.amplitudeWebhook(amplitudeEvent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventsTrackingManagementApi - object-oriented interface
 * @export
 * @class EventsTrackingManagementApi
 * @extends {BaseAPI}
 */
export class EventsTrackingManagementApi extends BaseAPI {
    /**
     * Event endpoint for Amplitude
     * @summary Amplitude webhook endpoint
     * @param {AmplitudeEvent} amplitudeEvent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsTrackingManagementApi
     */
    public amplitudeWebhook(amplitudeEvent: AmplitudeEvent, options?: AxiosRequestConfig) {
        return EventsTrackingManagementApiFp(this.configuration).amplitudeWebhook(amplitudeEvent, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FullDataAnalysesRoutesApi - axios parameter creator
 * @export
 */
export const FullDataAnalysesRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a download link for the results of a full data analysis tasklet segment for a dataset.
         * @summary Download link for full data analysis results
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskletId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadLinkForFullDataAnalysisResultsV1: async (workspaceId: string, datasetId: string, taskletId: string, segmentType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('downloadLinkForFullDataAnalysisResultsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('downloadLinkForFullDataAnalysisResultsV1', 'datasetId', datasetId)
            // verify required parameter 'taskletId' is not null or undefined
            assertParamExists('downloadLinkForFullDataAnalysisResultsV1', 'taskletId', taskletId)
            // verify required parameter 'segmentType' is not null or undefined
            assertParamExists('downloadLinkForFullDataAnalysisResultsV1', 'segmentType', segmentType)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/full-analyses/{tasklet_id}/segment/{segment_type}/results`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"tasklet_id"}}`, encodeURIComponent(String(taskletId)))
                .replace(`{${"segment_type"}}`, encodeURIComponent(String(segmentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List available full data analysis tasklets. Tasklets can be different for different data categories. This API does not consider whether a tasklet has already been triggered. The input dataset_id is used to determinedataset type
         * @summary Lists available full data analysis tasklets for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableFullDataAnalysisV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAvailableFullDataAnalysisV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getAvailableFullDataAnalysisV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/full-analyses/available-analysis`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the status of full analyses tasks executed for a dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetAnalysesStatusV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetAnalysesStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDatasetAnalysesStatusV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/full-analyses/status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List available full data analysis tasks. Tasks can be different for different data categories. If results of some analysis are already available this API would report the status as well.
         * @summary Lists full data analysis tasks for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullAnalysisForDatasetV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getFullAnalysisForDatasetV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getFullAnalysisForDatasetV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/full-analyses`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger a full data analysis on the selected tasklet ids and segments
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {Array<AnalysisSelection>} analysisSelection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerFullAnalysisV1: async (workspaceId: string, datasetId: string, analysisSelection: Array<AnalysisSelection>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('triggerFullAnalysisV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('triggerFullAnalysisV1', 'datasetId', datasetId)
            // verify required parameter 'analysisSelection' is not null or undefined
            assertParamExists('triggerFullAnalysisV1', 'analysisSelection', analysisSelection)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/full-analyses/trigger`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysisSelection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FullDataAnalysesRoutesApi - functional programming interface
 * @export
 */
export const FullDataAnalysesRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FullDataAnalysesRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a download link for the results of a full data analysis tasklet segment for a dataset.
         * @summary Download link for full data analysis results
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskletId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadLinkForFullDataAnalysisResultsV1(workspaceId: string, datasetId: string, taskletId: string, segmentType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisResultsDownloadLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadLinkForFullDataAnalysisResultsV1(workspaceId, datasetId, taskletId, segmentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List available full data analysis tasklets. Tasklets can be different for different data categories. This API does not consider whether a tasklet has already been triggered. The input dataset_id is used to determinedataset type
         * @summary Lists available full data analysis tasklets for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableFullDataAnalysisV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAvailableAnalysisTaskletsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableFullDataAnalysisV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the status of full analyses tasks executed for a dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetAnalysesStatusV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFullAnalysesStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetAnalysesStatusV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List available full data analysis tasks. Tasks can be different for different data categories. If results of some analysis are already available this API would report the status as well.
         * @summary Lists full data analysis tasks for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFullAnalysisForDatasetV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFullAnalysesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFullAnalysisForDatasetV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Trigger a full data analysis on the selected tasklet ids and segments
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {Array<AnalysisSelection>} analysisSelection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerFullAnalysisV1(workspaceId: string, datasetId: string, analysisSelection: Array<AnalysisSelection>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerAnalysisResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerFullAnalysisV1(workspaceId, datasetId, analysisSelection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FullDataAnalysesRoutesApi - factory interface
 * @export
 */
export const FullDataAnalysesRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FullDataAnalysesRoutesApiFp(configuration)
    return {
        /**
         * Create a download link for the results of a full data analysis tasklet segment for a dataset.
         * @summary Download link for full data analysis results
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskletId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadLinkForFullDataAnalysisResultsV1(workspaceId: string, datasetId: string, taskletId: string, segmentType: string, options?: any): AxiosPromise<AnalysisResultsDownloadLinkResponse> {
            return localVarFp.downloadLinkForFullDataAnalysisResultsV1(workspaceId, datasetId, taskletId, segmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * List available full data analysis tasklets. Tasklets can be different for different data categories. This API does not consider whether a tasklet has already been triggered. The input dataset_id is used to determinedataset type
         * @summary Lists available full data analysis tasklets for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableFullDataAnalysisV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<GetAvailableAnalysisTaskletsResponse> {
            return localVarFp.getAvailableFullDataAnalysisV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the status of full analyses tasks executed for a dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetAnalysesStatusV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<GetFullAnalysesStatusResponse> {
            return localVarFp.getDatasetAnalysesStatusV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * List available full data analysis tasks. Tasks can be different for different data categories. If results of some analysis are already available this API would report the status as well.
         * @summary Lists full data analysis tasks for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullAnalysisForDatasetV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<GetFullAnalysesResponse> {
            return localVarFp.getFullAnalysisForDatasetV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger a full data analysis on the selected tasklet ids and segments
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {Array<AnalysisSelection>} analysisSelection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerFullAnalysisV1(workspaceId: string, datasetId: string, analysisSelection: Array<AnalysisSelection>, options?: any): AxiosPromise<TriggerAnalysisResponse> {
            return localVarFp.triggerFullAnalysisV1(workspaceId, datasetId, analysisSelection, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FullDataAnalysesRoutesApi - object-oriented interface
 * @export
 * @class FullDataAnalysesRoutesApi
 * @extends {BaseAPI}
 */
export class FullDataAnalysesRoutesApi extends BaseAPI {
    /**
     * Create a download link for the results of a full data analysis tasklet segment for a dataset.
     * @summary Download link for full data analysis results
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} taskletId 
     * @param {string} segmentType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FullDataAnalysesRoutesApi
     */
    public downloadLinkForFullDataAnalysisResultsV1(workspaceId: string, datasetId: string, taskletId: string, segmentType: string, options?: AxiosRequestConfig) {
        return FullDataAnalysesRoutesApiFp(this.configuration).downloadLinkForFullDataAnalysisResultsV1(workspaceId, datasetId, taskletId, segmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List available full data analysis tasklets. Tasklets can be different for different data categories. This API does not consider whether a tasklet has already been triggered. The input dataset_id is used to determinedataset type
     * @summary Lists available full data analysis tasklets for a dataset.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FullDataAnalysesRoutesApi
     */
    public getAvailableFullDataAnalysisV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return FullDataAnalysesRoutesApiFp(this.configuration).getAvailableFullDataAnalysisV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the status of full analyses tasks executed for a dataset
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FullDataAnalysesRoutesApi
     */
    public getDatasetAnalysesStatusV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return FullDataAnalysesRoutesApiFp(this.configuration).getDatasetAnalysesStatusV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List available full data analysis tasks. Tasks can be different for different data categories. If results of some analysis are already available this API would report the status as well.
     * @summary Lists full data analysis tasks for a dataset.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FullDataAnalysesRoutesApi
     */
    public getFullAnalysisForDatasetV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return FullDataAnalysesRoutesApiFp(this.configuration).getFullAnalysisForDatasetV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trigger a full data analysis on the selected tasklet ids and segments
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {Array<AnalysisSelection>} analysisSelection 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FullDataAnalysesRoutesApi
     */
    public triggerFullAnalysisV1(workspaceId: string, datasetId: string, analysisSelection: Array<AnalysisSelection>, options?: AxiosRequestConfig) {
        return FullDataAnalysesRoutesApiFp(this.configuration).triggerFullAnalysisV1(workspaceId, datasetId, analysisSelection, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HybridDeploymentApi - axios parameter creator
 * @export
 */
export const HybridDeploymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get deployment information.
         * @summary Url to be called to get deployment information. This route is to be used for customers who deploy analyzers in their own cloud provider instances through Zurich command
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentResponseV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDeploymentResponseV1', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/v1/callbacks/deployment/workspace/{workspace_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Callback url to be called to save deployment information.
         * @summary Callback url to be called to save deployment information. This route is to be used for customers who deploy analyzers in their own cloud provider instances through Zurich command
         * @param {string} workspaceId 
         * @param {DeploymentUpdateRequest} deploymentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceDeploymentInfoV1: async (workspaceId: string, deploymentUpdateRequest: DeploymentUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateWorkspaceDeploymentInfoV1', 'workspaceId', workspaceId)
            // verify required parameter 'deploymentUpdateRequest' is not null or undefined
            assertParamExists('updateWorkspaceDeploymentInfoV1', 'deploymentUpdateRequest', deploymentUpdateRequest)
            const localVarPath = `/haifa/v1/callbacks/deployment/workspace/{workspace_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deploymentUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Callback url to be called to update deployment analyzer version information.
         * @summary Callback url to be called to update deployment analyzer version information. This route is to be used for customers who upgrade analyzers in their own cloud provider instances through Zurich command
         * @param {string} workspaceId 
         * @param {DeploymentAnalyzerVersionUpdateRequest} deploymentAnalyzerVersionUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceDeploymentVersionInfoV1: async (workspaceId: string, deploymentAnalyzerVersionUpdateRequest: DeploymentAnalyzerVersionUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateWorkspaceDeploymentVersionInfoV1', 'workspaceId', workspaceId)
            // verify required parameter 'deploymentAnalyzerVersionUpdateRequest' is not null or undefined
            assertParamExists('updateWorkspaceDeploymentVersionInfoV1', 'deploymentAnalyzerVersionUpdateRequest', deploymentAnalyzerVersionUpdateRequest)
            const localVarPath = `/haifa/v1/callbacks/deployment/workspace/{workspace_id}/update-analyzer-version`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deploymentAnalyzerVersionUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HybridDeploymentApi - functional programming interface
 * @export
 */
export const HybridDeploymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HybridDeploymentApiAxiosParamCreator(configuration)
    return {
        /**
         * Get deployment information.
         * @summary Url to be called to get deployment information. This route is to be used for customers who deploy analyzers in their own cloud provider instances through Zurich command
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeploymentResponseV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDeploymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeploymentResponseV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Callback url to be called to save deployment information.
         * @summary Callback url to be called to save deployment information. This route is to be used for customers who deploy analyzers in their own cloud provider instances through Zurich command
         * @param {string} workspaceId 
         * @param {DeploymentUpdateRequest} deploymentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkspaceDeploymentInfoV1(workspaceId: string, deploymentUpdateRequest: DeploymentUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkspaceDeploymentInfoV1(workspaceId, deploymentUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Callback url to be called to update deployment analyzer version information.
         * @summary Callback url to be called to update deployment analyzer version information. This route is to be used for customers who upgrade analyzers in their own cloud provider instances through Zurich command
         * @param {string} workspaceId 
         * @param {DeploymentAnalyzerVersionUpdateRequest} deploymentAnalyzerVersionUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkspaceDeploymentVersionInfoV1(workspaceId: string, deploymentAnalyzerVersionUpdateRequest: DeploymentAnalyzerVersionUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkspaceDeploymentVersionInfoV1(workspaceId, deploymentAnalyzerVersionUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HybridDeploymentApi - factory interface
 * @export
 */
export const HybridDeploymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HybridDeploymentApiFp(configuration)
    return {
        /**
         * Get deployment information.
         * @summary Url to be called to get deployment information. This route is to be used for customers who deploy analyzers in their own cloud provider instances through Zurich command
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentResponseV1(workspaceId: string, options?: any): AxiosPromise<GetDeploymentResponse> {
            return localVarFp.getDeploymentResponseV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Callback url to be called to save deployment information.
         * @summary Callback url to be called to save deployment information. This route is to be used for customers who deploy analyzers in their own cloud provider instances through Zurich command
         * @param {string} workspaceId 
         * @param {DeploymentUpdateRequest} deploymentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceDeploymentInfoV1(workspaceId: string, deploymentUpdateRequest: DeploymentUpdateRequest, options?: any): AxiosPromise<DeploymentUpdateResponse> {
            return localVarFp.updateWorkspaceDeploymentInfoV1(workspaceId, deploymentUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Callback url to be called to update deployment analyzer version information.
         * @summary Callback url to be called to update deployment analyzer version information. This route is to be used for customers who upgrade analyzers in their own cloud provider instances through Zurich command
         * @param {string} workspaceId 
         * @param {DeploymentAnalyzerVersionUpdateRequest} deploymentAnalyzerVersionUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceDeploymentVersionInfoV1(workspaceId: string, deploymentAnalyzerVersionUpdateRequest: DeploymentAnalyzerVersionUpdateRequest, options?: any): AxiosPromise<DeploymentUpdateResponse> {
            return localVarFp.updateWorkspaceDeploymentVersionInfoV1(workspaceId, deploymentAnalyzerVersionUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HybridDeploymentApi - object-oriented interface
 * @export
 * @class HybridDeploymentApi
 * @extends {BaseAPI}
 */
export class HybridDeploymentApi extends BaseAPI {
    /**
     * Get deployment information.
     * @summary Url to be called to get deployment information. This route is to be used for customers who deploy analyzers in their own cloud provider instances through Zurich command
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HybridDeploymentApi
     */
    public getDeploymentResponseV1(workspaceId: string, options?: AxiosRequestConfig) {
        return HybridDeploymentApiFp(this.configuration).getDeploymentResponseV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Callback url to be called to save deployment information.
     * @summary Callback url to be called to save deployment information. This route is to be used for customers who deploy analyzers in their own cloud provider instances through Zurich command
     * @param {string} workspaceId 
     * @param {DeploymentUpdateRequest} deploymentUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HybridDeploymentApi
     */
    public updateWorkspaceDeploymentInfoV1(workspaceId: string, deploymentUpdateRequest: DeploymentUpdateRequest, options?: AxiosRequestConfig) {
        return HybridDeploymentApiFp(this.configuration).updateWorkspaceDeploymentInfoV1(workspaceId, deploymentUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Callback url to be called to update deployment analyzer version information.
     * @summary Callback url to be called to update deployment analyzer version information. This route is to be used for customers who upgrade analyzers in their own cloud provider instances through Zurich command
     * @param {string} workspaceId 
     * @param {DeploymentAnalyzerVersionUpdateRequest} deploymentAnalyzerVersionUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HybridDeploymentApi
     */
    public updateWorkspaceDeploymentVersionInfoV1(workspaceId: string, deploymentAnalyzerVersionUpdateRequest: DeploymentAnalyzerVersionUpdateRequest, options?: AxiosRequestConfig) {
        return HybridDeploymentApiFp(this.configuration).updateWorkspaceDeploymentVersionInfoV1(workspaceId, deploymentAnalyzerVersionUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InAppApi - axios parameter creator
 * @export
 */
export const InAppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetch all notifications for the given workspace and user
         * @summary Fetch all notifications for the given workspace and user. Returns a paginated response.
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {boolean} [markedAsRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInAppNotificationsV1: async (workspaceId: string, userId: string, start?: number, end?: number, ts?: string, markedAsRead?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAllInAppNotificationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllInAppNotificationsV1', 'userId', userId)
            const localVarPath = `/paris/v1/notification/in-app/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspace_id'] = workspaceId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (markedAsRead !== undefined) {
                localVarQueryParameter['marked_as_read'] = markedAsRead;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get count for in-app notification records for the given workspace and user
         * @summary Get count for in-app notification records for the given workspace and user
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {boolean} [markedAsRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInAppNotificationRecordCountV1: async (workspaceId: string, userId: string, markedAsRead?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getInAppNotificationRecordCountV1', 'workspaceId', workspaceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getInAppNotificationRecordCountV1', 'userId', userId)
            const localVarPath = `/paris/v1/notifications/in-app/records/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspace_id'] = workspaceId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (markedAsRead !== undefined) {
                localVarQueryParameter['marked_as_read'] = markedAsRead;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ``` Used by GCD to add in-app notification record  Args:     in_app_request (InAppRequestModel)     _ (bool): Validates the bearer token. Defaults to Depends(authenticate_token).  Returns:     NotificationACKResponse ```
         * @summary Used by GCD to add in-app notification record
         * @param {InAppRequestModel} inAppRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveInAppNotificationRecordV1: async (inAppRequestModel: InAppRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inAppRequestModel' is not null or undefined
            assertParamExists('saveInAppNotificationRecordV1', 'inAppRequestModel', inAppRequestModel)
            const localVarPath = `/paris/v1/notification/in-app/record`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inAppRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Marks all the notifications as read for given workspace and user
         * @summary Update All Notifications As Read
         * @param {MarkAllAsReadInAppNotificationsRequestModel} markAllAsReadInAppNotificationsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllInAppNotificationAsReadV1: async (markAllAsReadInAppNotificationsRequestModel: MarkAllAsReadInAppNotificationsRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'markAllAsReadInAppNotificationsRequestModel' is not null or undefined
            assertParamExists('updateAllInAppNotificationAsReadV1', 'markAllAsReadInAppNotificationsRequestModel', markAllAsReadInAppNotificationsRequestModel)
            const localVarPath = `/paris/v1/notification/in-app/mark-all-as-read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markAllAsReadInAppNotificationsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates notification fields for the given notificaiton_ids.
         * @summary Update Inapp Notifications
         * @param {UpdateInAppNotificationsRequestModel} updateInAppNotificationsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInAppNotificationsV1: async (updateInAppNotificationsRequestModel: UpdateInAppNotificationsRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateInAppNotificationsRequestModel' is not null or undefined
            assertParamExists('updateInAppNotificationsV1', 'updateInAppNotificationsRequestModel', updateInAppNotificationsRequestModel)
            const localVarPath = `/paris/v1/notification/in-app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInAppNotificationsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InAppApi - functional programming interface
 * @export
 */
export const InAppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InAppApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetch all notifications for the given workspace and user
         * @summary Fetch all notifications for the given workspace and user. Returns a paginated response.
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {boolean} [markedAsRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllInAppNotificationsV1(workspaceId: string, userId: string, start?: number, end?: number, ts?: string, markedAsRead?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInAppNotificationResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllInAppNotificationsV1(workspaceId, userId, start, end, ts, markedAsRead, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get count for in-app notification records for the given workspace and user
         * @summary Get count for in-app notification records for the given workspace and user
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {boolean} [markedAsRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInAppNotificationRecordCountV1(workspaceId: string, userId: string, markedAsRead?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InAppNotificationRecordCountResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInAppNotificationRecordCountV1(workspaceId, userId, markedAsRead, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ``` Used by GCD to add in-app notification record  Args:     in_app_request (InAppRequestModel)     _ (bool): Validates the bearer token. Defaults to Depends(authenticate_token).  Returns:     NotificationACKResponse ```
         * @summary Used by GCD to add in-app notification record
         * @param {InAppRequestModel} inAppRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveInAppNotificationRecordV1(inAppRequestModel: InAppRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationACKResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveInAppNotificationRecordV1(inAppRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Marks all the notifications as read for given workspace and user
         * @summary Update All Notifications As Read
         * @param {MarkAllAsReadInAppNotificationsRequestModel} markAllAsReadInAppNotificationsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAllInAppNotificationAsReadV1(markAllAsReadInAppNotificationsRequestModel: MarkAllAsReadInAppNotificationsRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAllInAppNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllInAppNotificationAsReadV1(markAllAsReadInAppNotificationsRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates notification fields for the given notificaiton_ids.
         * @summary Update Inapp Notifications
         * @param {UpdateInAppNotificationsRequestModel} updateInAppNotificationsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInAppNotificationsV1(updateInAppNotificationsRequestModel: UpdateInAppNotificationsRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateInAppNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInAppNotificationsV1(updateInAppNotificationsRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InAppApi - factory interface
 * @export
 */
export const InAppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InAppApiFp(configuration)
    return {
        /**
         * Fetch all notifications for the given workspace and user
         * @summary Fetch all notifications for the given workspace and user. Returns a paginated response.
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {boolean} [markedAsRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInAppNotificationsV1(workspaceId: string, userId: string, start?: number, end?: number, ts?: string, markedAsRead?: boolean, options?: any): AxiosPromise<ListInAppNotificationResponseModel> {
            return localVarFp.getAllInAppNotificationsV1(workspaceId, userId, start, end, ts, markedAsRead, options).then((request) => request(axios, basePath));
        },
        /**
         * Get count for in-app notification records for the given workspace and user
         * @summary Get count for in-app notification records for the given workspace and user
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {boolean} [markedAsRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInAppNotificationRecordCountV1(workspaceId: string, userId: string, markedAsRead?: boolean, options?: any): AxiosPromise<InAppNotificationRecordCountResponseModel> {
            return localVarFp.getInAppNotificationRecordCountV1(workspaceId, userId, markedAsRead, options).then((request) => request(axios, basePath));
        },
        /**
         * ``` Used by GCD to add in-app notification record  Args:     in_app_request (InAppRequestModel)     _ (bool): Validates the bearer token. Defaults to Depends(authenticate_token).  Returns:     NotificationACKResponse ```
         * @summary Used by GCD to add in-app notification record
         * @param {InAppRequestModel} inAppRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveInAppNotificationRecordV1(inAppRequestModel: InAppRequestModel, options?: any): AxiosPromise<NotificationACKResponse> {
            return localVarFp.saveInAppNotificationRecordV1(inAppRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Marks all the notifications as read for given workspace and user
         * @summary Update All Notifications As Read
         * @param {MarkAllAsReadInAppNotificationsRequestModel} markAllAsReadInAppNotificationsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllInAppNotificationAsReadV1(markAllAsReadInAppNotificationsRequestModel: MarkAllAsReadInAppNotificationsRequestModel, options?: any): AxiosPromise<UpdateAllInAppNotificationsResponse> {
            return localVarFp.updateAllInAppNotificationAsReadV1(markAllAsReadInAppNotificationsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates notification fields for the given notificaiton_ids.
         * @summary Update Inapp Notifications
         * @param {UpdateInAppNotificationsRequestModel} updateInAppNotificationsRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInAppNotificationsV1(updateInAppNotificationsRequestModel: UpdateInAppNotificationsRequestModel, options?: any): AxiosPromise<UpdateInAppNotificationsResponse> {
            return localVarFp.updateInAppNotificationsV1(updateInAppNotificationsRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InAppApi - object-oriented interface
 * @export
 * @class InAppApi
 * @extends {BaseAPI}
 */
export class InAppApi extends BaseAPI {
    /**
     * Fetch all notifications for the given workspace and user
     * @summary Fetch all notifications for the given workspace and user. Returns a paginated response.
     * @param {string} workspaceId 
     * @param {string} userId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {boolean} [markedAsRead] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InAppApi
     */
    public getAllInAppNotificationsV1(workspaceId: string, userId: string, start?: number, end?: number, ts?: string, markedAsRead?: boolean, options?: AxiosRequestConfig) {
        return InAppApiFp(this.configuration).getAllInAppNotificationsV1(workspaceId, userId, start, end, ts, markedAsRead, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get count for in-app notification records for the given workspace and user
     * @summary Get count for in-app notification records for the given workspace and user
     * @param {string} workspaceId 
     * @param {string} userId 
     * @param {boolean} [markedAsRead] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InAppApi
     */
    public getInAppNotificationRecordCountV1(workspaceId: string, userId: string, markedAsRead?: boolean, options?: AxiosRequestConfig) {
        return InAppApiFp(this.configuration).getInAppNotificationRecordCountV1(workspaceId, userId, markedAsRead, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ``` Used by GCD to add in-app notification record  Args:     in_app_request (InAppRequestModel)     _ (bool): Validates the bearer token. Defaults to Depends(authenticate_token).  Returns:     NotificationACKResponse ```
     * @summary Used by GCD to add in-app notification record
     * @param {InAppRequestModel} inAppRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InAppApi
     */
    public saveInAppNotificationRecordV1(inAppRequestModel: InAppRequestModel, options?: AxiosRequestConfig) {
        return InAppApiFp(this.configuration).saveInAppNotificationRecordV1(inAppRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Marks all the notifications as read for given workspace and user
     * @summary Update All Notifications As Read
     * @param {MarkAllAsReadInAppNotificationsRequestModel} markAllAsReadInAppNotificationsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InAppApi
     */
    public updateAllInAppNotificationAsReadV1(markAllAsReadInAppNotificationsRequestModel: MarkAllAsReadInAppNotificationsRequestModel, options?: AxiosRequestConfig) {
        return InAppApiFp(this.configuration).updateAllInAppNotificationAsReadV1(markAllAsReadInAppNotificationsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates notification fields for the given notificaiton_ids.
     * @summary Update Inapp Notifications
     * @param {UpdateInAppNotificationsRequestModel} updateInAppNotificationsRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InAppApi
     */
    public updateInAppNotificationsV1(updateInAppNotificationsRequestModel: UpdateInAppNotificationsRequestModel, options?: AxiosRequestConfig) {
        return InAppApiFp(this.configuration).updateInAppNotificationsV1(updateInAppNotificationsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalAPIRoutesApi - axios parameter creator
 * @export
 */
export const InternalAPIRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Check if workspace has enough Markov credits available.
         * @summary Internal Check Workspace Credits Availability V1
         * @param {string} workspaceId 
         * @param {MarkovCreditsAvailabilityRequestModel} markovCreditsAvailabilityRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCheckWorkspaceMarkovCreditsAvailabilityV1: async (workspaceId: string, markovCreditsAvailabilityRequestModel: MarkovCreditsAvailabilityRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('internalCheckWorkspaceMarkovCreditsAvailabilityV1', 'workspaceId', workspaceId)
            // verify required parameter 'markovCreditsAvailabilityRequestModel' is not null or undefined
            assertParamExists('internalCheckWorkspaceMarkovCreditsAvailabilityV1', 'markovCreditsAvailabilityRequestModel', markovCreditsAvailabilityRequestModel)
            const localVarPath = `/haifa/v1/internal/workspace/{workspace_id}/credits/check-availability`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markovCreditsAvailabilityRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch transactions based on transaction_source_id
         * @summary Get Transactions By Source
         * @param {string} workspaceId 
         * @param {string} transactionSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetTransactionsBySourceV1: async (workspaceId: string, transactionSourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('internalGetTransactionsBySourceV1', 'workspaceId', workspaceId)
            // verify required parameter 'transactionSourceId' is not null or undefined
            assertParamExists('internalGetTransactionsBySourceV1', 'transactionSourceId', transactionSourceId)
            const localVarPath = `/haifa/v1/internal/workspace/{workspace_id}/transactions/source/{transaction_source_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"transaction_source_id"}}`, encodeURIComponent(String(transactionSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Record a Markov credits transaction for the workspace
         * @summary Internal Record Workspace Credits Transaction
         * @param {string} workspaceId 
         * @param {UpdateWorkspaceMarkovCreditsUsageRequestModel} updateWorkspaceMarkovCreditsUsageRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalRecordWorkspaceMarkovCreditsTransactionV1: async (workspaceId: string, updateWorkspaceMarkovCreditsUsageRequestModel: UpdateWorkspaceMarkovCreditsUsageRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('internalRecordWorkspaceMarkovCreditsTransactionV1', 'workspaceId', workspaceId)
            // verify required parameter 'updateWorkspaceMarkovCreditsUsageRequestModel' is not null or undefined
            assertParamExists('internalRecordWorkspaceMarkovCreditsTransactionV1', 'updateWorkspaceMarkovCreditsUsageRequestModel', updateWorkspaceMarkovCreditsUsageRequestModel)
            const localVarPath = `/haifa/v1/internal/workspace/{workspace_id}/credits/usage`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkspaceMarkovCreditsUsageRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalAPIRoutesApi - functional programming interface
 * @export
 */
export const InternalAPIRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalAPIRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Check if workspace has enough Markov credits available.
         * @summary Internal Check Workspace Credits Availability V1
         * @param {string} workspaceId 
         * @param {MarkovCreditsAvailabilityRequestModel} markovCreditsAvailabilityRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCheckWorkspaceMarkovCreditsAvailabilityV1(workspaceId: string, markovCreditsAvailabilityRequestModel: MarkovCreditsAvailabilityRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkovCreditsAvailabilityResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCheckWorkspaceMarkovCreditsAvailabilityV1(workspaceId, markovCreditsAvailabilityRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch transactions based on transaction_source_id
         * @summary Get Transactions By Source
         * @param {string} workspaceId 
         * @param {string} transactionSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetTransactionsBySourceV1(workspaceId: string, transactionSourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTransactionsBySourceResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetTransactionsBySourceV1(workspaceId, transactionSourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Record a Markov credits transaction for the workspace
         * @summary Internal Record Workspace Credits Transaction
         * @param {string} workspaceId 
         * @param {UpdateWorkspaceMarkovCreditsUsageRequestModel} updateWorkspaceMarkovCreditsUsageRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalRecordWorkspaceMarkovCreditsTransactionV1(workspaceId: string, updateWorkspaceMarkovCreditsUsageRequestModel: UpdateWorkspaceMarkovCreditsUsageRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateWorkspaceMarkovCreditsUsageResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalRecordWorkspaceMarkovCreditsTransactionV1(workspaceId, updateWorkspaceMarkovCreditsUsageRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalAPIRoutesApi - factory interface
 * @export
 */
export const InternalAPIRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalAPIRoutesApiFp(configuration)
    return {
        /**
         * Check if workspace has enough Markov credits available.
         * @summary Internal Check Workspace Credits Availability V1
         * @param {string} workspaceId 
         * @param {MarkovCreditsAvailabilityRequestModel} markovCreditsAvailabilityRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCheckWorkspaceMarkovCreditsAvailabilityV1(workspaceId: string, markovCreditsAvailabilityRequestModel: MarkovCreditsAvailabilityRequestModel, options?: any): AxiosPromise<MarkovCreditsAvailabilityResponseModel> {
            return localVarFp.internalCheckWorkspaceMarkovCreditsAvailabilityV1(workspaceId, markovCreditsAvailabilityRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch transactions based on transaction_source_id
         * @summary Get Transactions By Source
         * @param {string} workspaceId 
         * @param {string} transactionSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetTransactionsBySourceV1(workspaceId: string, transactionSourceId: string, options?: any): AxiosPromise<GetTransactionsBySourceResponseModel> {
            return localVarFp.internalGetTransactionsBySourceV1(workspaceId, transactionSourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Record a Markov credits transaction for the workspace
         * @summary Internal Record Workspace Credits Transaction
         * @param {string} workspaceId 
         * @param {UpdateWorkspaceMarkovCreditsUsageRequestModel} updateWorkspaceMarkovCreditsUsageRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalRecordWorkspaceMarkovCreditsTransactionV1(workspaceId: string, updateWorkspaceMarkovCreditsUsageRequestModel: UpdateWorkspaceMarkovCreditsUsageRequestModel, options?: any): AxiosPromise<UpdateWorkspaceMarkovCreditsUsageResponseModel> {
            return localVarFp.internalRecordWorkspaceMarkovCreditsTransactionV1(workspaceId, updateWorkspaceMarkovCreditsUsageRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalAPIRoutesApi - object-oriented interface
 * @export
 * @class InternalAPIRoutesApi
 * @extends {BaseAPI}
 */
export class InternalAPIRoutesApi extends BaseAPI {
    /**
     * Check if workspace has enough Markov credits available.
     * @summary Internal Check Workspace Credits Availability V1
     * @param {string} workspaceId 
     * @param {MarkovCreditsAvailabilityRequestModel} markovCreditsAvailabilityRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalAPIRoutesApi
     */
    public internalCheckWorkspaceMarkovCreditsAvailabilityV1(workspaceId: string, markovCreditsAvailabilityRequestModel: MarkovCreditsAvailabilityRequestModel, options?: AxiosRequestConfig) {
        return InternalAPIRoutesApiFp(this.configuration).internalCheckWorkspaceMarkovCreditsAvailabilityV1(workspaceId, markovCreditsAvailabilityRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch transactions based on transaction_source_id
     * @summary Get Transactions By Source
     * @param {string} workspaceId 
     * @param {string} transactionSourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalAPIRoutesApi
     */
    public internalGetTransactionsBySourceV1(workspaceId: string, transactionSourceId: string, options?: AxiosRequestConfig) {
        return InternalAPIRoutesApiFp(this.configuration).internalGetTransactionsBySourceV1(workspaceId, transactionSourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Record a Markov credits transaction for the workspace
     * @summary Internal Record Workspace Credits Transaction
     * @param {string} workspaceId 
     * @param {UpdateWorkspaceMarkovCreditsUsageRequestModel} updateWorkspaceMarkovCreditsUsageRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalAPIRoutesApi
     */
    public internalRecordWorkspaceMarkovCreditsTransactionV1(workspaceId: string, updateWorkspaceMarkovCreditsUsageRequestModel: UpdateWorkspaceMarkovCreditsUsageRequestModel, options?: AxiosRequestConfig) {
        return InternalAPIRoutesApiFp(this.configuration).internalRecordWorkspaceMarkovCreditsTransactionV1(workspaceId, updateWorkspaceMarkovCreditsUsageRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalAPIsApi - axios parameter creator
 * @export
 */
export const InternalAPIsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get product price change.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPriceChange: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProductPriceChange', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/products/detect-price-change`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the modelRecordings, this route supports m2m authentication.Pagination is supported by passing start and end query params. ModelRecordings can be filtered by name by passing the name query param.ModelRecordings can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
         * @summary Returns evaluation recordings for a workspace
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalEvaluationRecordingsListV1: async (workspaceId: string, start?: number, end?: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('internalEvaluationRecordingsListV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/internal/workspace/{workspace_id}/recordings`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (datasetId !== undefined) {
                localVarQueryParameter['dataset_id'] = datasetId;
            }

            if (modelId !== undefined) {
                localVarQueryParameter['model_id'] = modelId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the modelRecordings, this route supports m2m authentication.Pagination is supported by passing start and end query params. ModelRecordings can be filtered by name by passing the name query param.ModelRecordings can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
         * @summary Returns experiments for a workspace
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalExperimentsListV1: async (workspaceId: string, start?: number, end?: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('internalExperimentsListV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/internal/workspace/{workspace_id}/experiments`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (datasetId !== undefined) {
                localVarQueryParameter['dataset_id'] = datasetId;
            }

            if (modelId !== undefined) {
                localVarQueryParameter['model_id'] = modelId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the modelRecordings, this route supports m2m authentication.Pagination is supported by passing start and end query params. ModelRecordings can be filtered by name by passing the name query param.ModelRecordings can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
         * @summary Returns modelRecordings for a workspace
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalModelsListV1: async (workspaceId: string, userId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('internalModelsListV1', 'workspaceId', workspaceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('internalModelsListV1', 'userId', userId)
            const localVarPath = `/reno/v1/internal/workspace/{workspace_id}/models`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the projects, this route supports m2m authentication.Pagination is supported by passing start and end query params.
         * @summary Returns projects for a workspace
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalProjectsListV1: async (workspaceId: string, userId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('internalProjectsListV1', 'workspaceId', workspaceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('internalProjectsListV1', 'userId', userId)
            const localVarPath = `/reno/v1/internal/workspace/{workspace_id}/projects`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts the model app given by model_id and starts it if it is pending state
         * @summary Starts the model app if it is in pending state
         * @param {string} workspaceId 
         * @param {StartPendingModelAppRequest} startPendingModelAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalStartPendingModelAppV1: async (workspaceId: string, startPendingModelAppRequest: StartPendingModelAppRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('internalStartPendingModelAppV1', 'workspaceId', workspaceId)
            // verify required parameter 'startPendingModelAppRequest' is not null or undefined
            assertParamExists('internalStartPendingModelAppV1', 'startPendingModelAppRequest', startPendingModelAppRequest)
            const localVarPath = `/reno/v1/internal/workspace/{workspace_id}/models/start-pending-model-app`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startPendingModelAppRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Link model to registry  Args:      model_id: model which needs to be linked     link_model_to_registry_request: registry which needs to be linked with model     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     authenticated: api authentication     validate_request: validate link model request  Returns:     LinkModelToRegistryRequest: registry linked with model
         * @summary Link model to registry, internal api to be used via delhi
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {LinkModelToRegistryInternalRequest} linkModelToRegistryInternalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkModelToRegistryInternalV1: async (workspaceId: string, modelId: string, linkModelToRegistryInternalRequest: LinkModelToRegistryInternalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('linkModelToRegistryInternalV1', 'workspaceId', workspaceId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('linkModelToRegistryInternalV1', 'modelId', modelId)
            // verify required parameter 'linkModelToRegistryInternalRequest' is not null or undefined
            assertParamExists('linkModelToRegistryInternalV1', 'linkModelToRegistryInternalRequest', linkModelToRegistryInternalRequest)
            const localVarPath = `/reno/v1/internal/workspace/{workspace_id}/model/{model_id}/link`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkModelToRegistryInternalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Each model app is created with an end-time, this API stops the apps in a given workspace that are beyond their endtime
         * @summary Stop the model apps that are beyond their end-time
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopExpiredAppsV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('stopExpiredAppsV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/internal/workspace/{workspace_id}/models/stop-expired-apps`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Each model app is created with an end-time, this API stops the apps in a given workspace for given model ids that are beyond their endtime
         * @summary Stop the model apps that are beyond their end-time for given model ids
         * @param {string} workspaceId 
         * @param {StopModelAppsRequest} stopModelAppsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopExpiredAppsV2: async (workspaceId: string, stopModelAppsRequest: StopModelAppsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('stopExpiredAppsV2', 'workspaceId', workspaceId)
            // verify required parameter 'stopModelAppsRequest' is not null or undefined
            assertParamExists('stopExpiredAppsV2', 'stopModelAppsRequest', stopModelAppsRequest)
            const localVarPath = `/reno/v2/internal/workspace/{workspace_id}/models/stop-expired-apps`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stopModelAppsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Transition model to corresponding stage  Args:     model_id: transition model     transition_model_stage: transition model to stage     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     authenticated: api authentication  Returns:     TransitionModelStageResponse: transition model to stage
         * @summary Transition model stage.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {string} modelId 
         * @param {TransitionModelStageInternalRequest} transitionModelStageInternalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionModelStageInternalV1: async (workspaceId: string, registryId: string, modelId: string, transitionModelStageInternalRequest: TransitionModelStageInternalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('transitionModelStageInternalV1', 'workspaceId', workspaceId)
            // verify required parameter 'registryId' is not null or undefined
            assertParamExists('transitionModelStageInternalV1', 'registryId', registryId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('transitionModelStageInternalV1', 'modelId', modelId)
            // verify required parameter 'transitionModelStageInternalRequest' is not null or undefined
            assertParamExists('transitionModelStageInternalV1', 'transitionModelStageInternalRequest', transitionModelStageInternalRequest)
            const localVarPath = `/reno/v1/internal/workspace/{workspace_id}/registry/{registry_id}/model/{model_id}/transition`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"registry_id"}}`, encodeURIComponent(String(registryId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transitionModelStageInternalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalAPIsApi - functional programming interface
 * @export
 */
export const InternalAPIsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalAPIsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get product price change.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductPriceChange(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPriceChangeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductPriceChange(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all the modelRecordings, this route supports m2m authentication.Pagination is supported by passing start and end query params. ModelRecordings can be filtered by name by passing the name query param.ModelRecordings can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
         * @summary Returns evaluation recordings for a workspace
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalEvaluationRecordingsListV1(workspaceId: string, start?: number, end?: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecordingsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalEvaluationRecordingsListV1(workspaceId, start, end, ts, datasetId, modelId, projectId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all the modelRecordings, this route supports m2m authentication.Pagination is supported by passing start and end query params. ModelRecordings can be filtered by name by passing the name query param.ModelRecordings can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
         * @summary Returns experiments for a workspace
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalExperimentsListV1(workspaceId: string, start?: number, end?: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperimentRecordingsMetaData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalExperimentsListV1(workspaceId, start, end, ts, datasetId, modelId, projectId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all the modelRecordings, this route supports m2m authentication.Pagination is supported by passing start and end query params. ModelRecordings can be filtered by name by passing the name query param.ModelRecordings can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
         * @summary Returns modelRecordings for a workspace
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalModelsListV1(workspaceId: string, userId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelsPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalModelsListV1(workspaceId, userId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all the projects, this route supports m2m authentication.Pagination is supported by passing start and end query params.
         * @summary Returns projects for a workspace
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalProjectsListV1(workspaceId: string, userId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectsPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalProjectsListV1(workspaceId, userId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Starts the model app given by model_id and starts it if it is pending state
         * @summary Starts the model app if it is in pending state
         * @param {string} workspaceId 
         * @param {StartPendingModelAppRequest} startPendingModelAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalStartPendingModelAppV1(workspaceId: string, startPendingModelAppRequest: StartPendingModelAppRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartPendingModelAppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalStartPendingModelAppV1(workspaceId, startPendingModelAppRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Link model to registry  Args:      model_id: model which needs to be linked     link_model_to_registry_request: registry which needs to be linked with model     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     authenticated: api authentication     validate_request: validate link model request  Returns:     LinkModelToRegistryRequest: registry linked with model
         * @summary Link model to registry, internal api to be used via delhi
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {LinkModelToRegistryInternalRequest} linkModelToRegistryInternalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkModelToRegistryInternalV1(workspaceId: string, modelId: string, linkModelToRegistryInternalRequest: LinkModelToRegistryInternalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkModelToRegistryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkModelToRegistryInternalV1(workspaceId, modelId, linkModelToRegistryInternalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Each model app is created with an end-time, this API stops the apps in a given workspace that are beyond their endtime
         * @summary Stop the model apps that are beyond their end-time
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopExpiredAppsV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StopExpiredModelAppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopExpiredAppsV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Each model app is created with an end-time, this API stops the apps in a given workspace for given model ids that are beyond their endtime
         * @summary Stop the model apps that are beyond their end-time for given model ids
         * @param {string} workspaceId 
         * @param {StopModelAppsRequest} stopModelAppsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopExpiredAppsV2(workspaceId: string, stopModelAppsRequest: StopModelAppsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StopExpiredModelAppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopExpiredAppsV2(workspaceId, stopModelAppsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Transition model to corresponding stage  Args:     model_id: transition model     transition_model_stage: transition model to stage     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     authenticated: api authentication  Returns:     TransitionModelStageResponse: transition model to stage
         * @summary Transition model stage.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {string} modelId 
         * @param {TransitionModelStageInternalRequest} transitionModelStageInternalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionModelStageInternalV1(workspaceId: string, registryId: string, modelId: string, transitionModelStageInternalRequest: TransitionModelStageInternalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionModelStageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transitionModelStageInternalV1(workspaceId, registryId, modelId, transitionModelStageInternalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalAPIsApi - factory interface
 * @export
 */
export const InternalAPIsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalAPIsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get product price change.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPriceChange(workspaceId: string, options?: any): AxiosPromise<ProductPriceChangeResponse> {
            return localVarFp.getProductPriceChange(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the modelRecordings, this route supports m2m authentication.Pagination is supported by passing start and end query params. ModelRecordings can be filtered by name by passing the name query param.ModelRecordings can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
         * @summary Returns evaluation recordings for a workspace
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalEvaluationRecordingsListV1(workspaceId: string, start?: number, end?: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<GetRecordingsListResponse> {
            return localVarFp.internalEvaluationRecordingsListV1(workspaceId, start, end, ts, datasetId, modelId, projectId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the modelRecordings, this route supports m2m authentication.Pagination is supported by passing start and end query params. ModelRecordings can be filtered by name by passing the name query param.ModelRecordings can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
         * @summary Returns experiments for a workspace
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalExperimentsListV1(workspaceId: string, start?: number, end?: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<ExperimentRecordingsMetaData> {
            return localVarFp.internalExperimentsListV1(workspaceId, start, end, ts, datasetId, modelId, projectId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the modelRecordings, this route supports m2m authentication.Pagination is supported by passing start and end query params. ModelRecordings can be filtered by name by passing the name query param.ModelRecordings can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
         * @summary Returns modelRecordings for a workspace
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalModelsListV1(workspaceId: string, userId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<GetModelsPaginated> {
            return localVarFp.internalModelsListV1(workspaceId, userId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the projects, this route supports m2m authentication.Pagination is supported by passing start and end query params.
         * @summary Returns projects for a workspace
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalProjectsListV1(workspaceId: string, userId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<GetProjectsPaginated> {
            return localVarFp.internalProjectsListV1(workspaceId, userId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts the model app given by model_id and starts it if it is pending state
         * @summary Starts the model app if it is in pending state
         * @param {string} workspaceId 
         * @param {StartPendingModelAppRequest} startPendingModelAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalStartPendingModelAppV1(workspaceId: string, startPendingModelAppRequest: StartPendingModelAppRequest, options?: any): AxiosPromise<StartPendingModelAppResponse> {
            return localVarFp.internalStartPendingModelAppV1(workspaceId, startPendingModelAppRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Link model to registry  Args:      model_id: model which needs to be linked     link_model_to_registry_request: registry which needs to be linked with model     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     authenticated: api authentication     validate_request: validate link model request  Returns:     LinkModelToRegistryRequest: registry linked with model
         * @summary Link model to registry, internal api to be used via delhi
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {LinkModelToRegistryInternalRequest} linkModelToRegistryInternalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkModelToRegistryInternalV1(workspaceId: string, modelId: string, linkModelToRegistryInternalRequest: LinkModelToRegistryInternalRequest, options?: any): AxiosPromise<LinkModelToRegistryResponse> {
            return localVarFp.linkModelToRegistryInternalV1(workspaceId, modelId, linkModelToRegistryInternalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Each model app is created with an end-time, this API stops the apps in a given workspace that are beyond their endtime
         * @summary Stop the model apps that are beyond their end-time
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopExpiredAppsV1(workspaceId: string, options?: any): AxiosPromise<StopExpiredModelAppResponse> {
            return localVarFp.stopExpiredAppsV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Each model app is created with an end-time, this API stops the apps in a given workspace for given model ids that are beyond their endtime
         * @summary Stop the model apps that are beyond their end-time for given model ids
         * @param {string} workspaceId 
         * @param {StopModelAppsRequest} stopModelAppsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopExpiredAppsV2(workspaceId: string, stopModelAppsRequest: StopModelAppsRequest, options?: any): AxiosPromise<StopExpiredModelAppResponse> {
            return localVarFp.stopExpiredAppsV2(workspaceId, stopModelAppsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Transition model to corresponding stage  Args:     model_id: transition model     transition_model_stage: transition model to stage     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     authenticated: api authentication  Returns:     TransitionModelStageResponse: transition model to stage
         * @summary Transition model stage.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {string} modelId 
         * @param {TransitionModelStageInternalRequest} transitionModelStageInternalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionModelStageInternalV1(workspaceId: string, registryId: string, modelId: string, transitionModelStageInternalRequest: TransitionModelStageInternalRequest, options?: any): AxiosPromise<TransitionModelStageResponse> {
            return localVarFp.transitionModelStageInternalV1(workspaceId, registryId, modelId, transitionModelStageInternalRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalAPIsApi - object-oriented interface
 * @export
 * @class InternalAPIsApi
 * @extends {BaseAPI}
 */
export class InternalAPIsApi extends BaseAPI {
    /**
     * 
     * @summary Get product price change.
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalAPIsApi
     */
    public getProductPriceChange(workspaceId: string, options?: AxiosRequestConfig) {
        return InternalAPIsApiFp(this.configuration).getProductPriceChange(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the modelRecordings, this route supports m2m authentication.Pagination is supported by passing start and end query params. ModelRecordings can be filtered by name by passing the name query param.ModelRecordings can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
     * @summary Returns evaluation recordings for a workspace
     * @param {string} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [datasetId] 
     * @param {string} [modelId] 
     * @param {string} [projectId] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalAPIsApi
     */
    public internalEvaluationRecordingsListV1(workspaceId: string, start?: number, end?: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return InternalAPIsApiFp(this.configuration).internalEvaluationRecordingsListV1(workspaceId, start, end, ts, datasetId, modelId, projectId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the modelRecordings, this route supports m2m authentication.Pagination is supported by passing start and end query params. ModelRecordings can be filtered by name by passing the name query param.ModelRecordings can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
     * @summary Returns experiments for a workspace
     * @param {string} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [datasetId] 
     * @param {string} [modelId] 
     * @param {string} [projectId] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalAPIsApi
     */
    public internalExperimentsListV1(workspaceId: string, start?: number, end?: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return InternalAPIsApiFp(this.configuration).internalExperimentsListV1(workspaceId, start, end, ts, datasetId, modelId, projectId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the modelRecordings, this route supports m2m authentication.Pagination is supported by passing start and end query params. ModelRecordings can be filtered by name by passing the name query param.ModelRecordings can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
     * @summary Returns modelRecordings for a workspace
     * @param {string} workspaceId 
     * @param {string} userId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalAPIsApi
     */
    public internalModelsListV1(workspaceId: string, userId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return InternalAPIsApiFp(this.configuration).internalModelsListV1(workspaceId, userId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the projects, this route supports m2m authentication.Pagination is supported by passing start and end query params.
     * @summary Returns projects for a workspace
     * @param {string} workspaceId 
     * @param {string} userId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalAPIsApi
     */
    public internalProjectsListV1(workspaceId: string, userId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return InternalAPIsApiFp(this.configuration).internalProjectsListV1(workspaceId, userId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts the model app given by model_id and starts it if it is pending state
     * @summary Starts the model app if it is in pending state
     * @param {string} workspaceId 
     * @param {StartPendingModelAppRequest} startPendingModelAppRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalAPIsApi
     */
    public internalStartPendingModelAppV1(workspaceId: string, startPendingModelAppRequest: StartPendingModelAppRequest, options?: AxiosRequestConfig) {
        return InternalAPIsApiFp(this.configuration).internalStartPendingModelAppV1(workspaceId, startPendingModelAppRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Link model to registry  Args:      model_id: model which needs to be linked     link_model_to_registry_request: registry which needs to be linked with model     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     authenticated: api authentication     validate_request: validate link model request  Returns:     LinkModelToRegistryRequest: registry linked with model
     * @summary Link model to registry, internal api to be used via delhi
     * @param {string} workspaceId 
     * @param {string} modelId 
     * @param {LinkModelToRegistryInternalRequest} linkModelToRegistryInternalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalAPIsApi
     */
    public linkModelToRegistryInternalV1(workspaceId: string, modelId: string, linkModelToRegistryInternalRequest: LinkModelToRegistryInternalRequest, options?: AxiosRequestConfig) {
        return InternalAPIsApiFp(this.configuration).linkModelToRegistryInternalV1(workspaceId, modelId, linkModelToRegistryInternalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Each model app is created with an end-time, this API stops the apps in a given workspace that are beyond their endtime
     * @summary Stop the model apps that are beyond their end-time
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalAPIsApi
     */
    public stopExpiredAppsV1(workspaceId: string, options?: AxiosRequestConfig) {
        return InternalAPIsApiFp(this.configuration).stopExpiredAppsV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Each model app is created with an end-time, this API stops the apps in a given workspace for given model ids that are beyond their endtime
     * @summary Stop the model apps that are beyond their end-time for given model ids
     * @param {string} workspaceId 
     * @param {StopModelAppsRequest} stopModelAppsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalAPIsApi
     */
    public stopExpiredAppsV2(workspaceId: string, stopModelAppsRequest: StopModelAppsRequest, options?: AxiosRequestConfig) {
        return InternalAPIsApiFp(this.configuration).stopExpiredAppsV2(workspaceId, stopModelAppsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Transition model to corresponding stage  Args:     model_id: transition model     transition_model_stage: transition model to stage     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     authenticated: api authentication  Returns:     TransitionModelStageResponse: transition model to stage
     * @summary Transition model stage.
     * @param {string} workspaceId 
     * @param {string} registryId 
     * @param {string} modelId 
     * @param {TransitionModelStageInternalRequest} transitionModelStageInternalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalAPIsApi
     */
    public transitionModelStageInternalV1(workspaceId: string, registryId: string, modelId: string, transitionModelStageInternalRequest: TransitionModelStageInternalRequest, options?: AxiosRequestConfig) {
        return InternalAPIsApiFp(this.configuration).transitionModelStageInternalV1(workspaceId, registryId, modelId, transitionModelStageInternalRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalRoutesApi - axios parameter creator
 * @export
 */
export const InternalRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Save/Bookmark Resource
         * @summary Save/Bookmark Resource
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {AddResourceRequestV2Mig} addResourceRequestV2Mig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addResourcesV2Mig: async (workspaceId: string, connectorId: string, addResourceRequestV2Mig: AddResourceRequestV2Mig, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addResourcesV2Mig', 'workspaceId', workspaceId)
            // verify required parameter 'connectorId' is not null or undefined
            assertParamExists('addResourcesV2Mig', 'connectorId', connectorId)
            // verify required parameter 'addResourceRequestV2Mig' is not null or undefined
            assertParamExists('addResourcesV2Mig', 'addResourceRequestV2Mig', addResourceRequestV2Mig)
            const localVarPath = `/vienna/v2migration/workspace/{workspace_id}/connector/{connector_id}/add-new-resource`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"connector_id"}}`, encodeURIComponent(String(connectorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addResourceRequestV2Mig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is an internal method for pre-emptively caching analysis results for a dataset analysis results
         * @summary Cache analysis graphs data
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        cacheAnalysisResultsV1: async (workspaceId: string, datasetId: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('cacheAnalysisResultsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('cacheAnalysisResultsV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/cache-analysis-results`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new connector
         * @summary Create new connector
         * @param {string} workspaceId 
         * @param {CreateConnectorRequestV2Mig} createConnectorRequestV2Mig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnectorV2: async (workspaceId: string, createConnectorRequestV2Mig: CreateConnectorRequestV2Mig, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createConnectorV2', 'workspaceId', workspaceId)
            // verify required parameter 'createConnectorRequestV2Mig' is not null or undefined
            assertParamExists('createConnectorV2', 'createConnectorRequestV2Mig', createConnectorRequestV2Mig)
            const localVarPath = `/vienna/v2migration/workspace/{workspace_id}/connector/create-connector`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConnectorRequestV2Mig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns info associated with a dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSetsInfoInternalV1: async (datasetId: string, workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDataSetsInfoInternalV1', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataSetsInfoInternalV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/datasets/{dataset_id}/info`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns raw data for clustering record ids
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {ClusteringRawDataInternalRequest} clusteringRawDataInternalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetClusteringRawDataInternalV1: async (datasetId: string, workspaceId: string, clusteringRawDataInternalRequest: ClusteringRawDataInternalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDatasetClusteringRawDataInternalV1', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetClusteringRawDataInternalV1', 'workspaceId', workspaceId)
            // verify required parameter 'clusteringRawDataInternalRequest' is not null or undefined
            assertParamExists('getDatasetClusteringRawDataInternalV1', 'clusteringRawDataInternalRequest', clusteringRawDataInternalRequest)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/datasets/{dataset_id}/clustering-rawdata`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusteringRawDataInternalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {SimilarityRequest} similarityRequest 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetClusteringSimilarityInternalV1: async (datasetId: string, workspaceId: string, similarityRequest: SimilarityRequest, embeddingId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDatasetClusteringSimilarityInternalV1', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetClusteringSimilarityInternalV1', 'workspaceId', workspaceId)
            // verify required parameter 'similarityRequest' is not null or undefined
            assertParamExists('getDatasetClusteringSimilarityInternalV1', 'similarityRequest', similarityRequest)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/datasets/{dataset_id}/similar-points`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (embeddingId !== undefined) {
                localVarQueryParameter['embedding_id'] = embeddingId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(similarityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of variables used in the given dataset and segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {string} variable 
         * @param {string} visualizationId 
         * @param {boolean} [groupByTarget] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetVariableChartDataInternalV1: async (workspaceId: string, datasetId: string, segmentType: string, variable: string, visualizationId: string, groupByTarget?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetVariableChartDataInternalV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDatasetVariableChartDataInternalV1', 'datasetId', datasetId)
            // verify required parameter 'segmentType' is not null or undefined
            assertParamExists('getDatasetVariableChartDataInternalV1', 'segmentType', segmentType)
            // verify required parameter 'variable' is not null or undefined
            assertParamExists('getDatasetVariableChartDataInternalV1', 'variable', variable)
            // verify required parameter 'visualizationId' is not null or undefined
            assertParamExists('getDatasetVariableChartDataInternalV1', 'visualizationId', visualizationId)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/datasets/{dataset_id}/segment/{segment_type}/variables/chart`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"segment_type"}}`, encodeURIComponent(String(segmentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (variable !== undefined) {
                localVarQueryParameter['variable'] = variable;
            }

            if (visualizationId !== undefined) {
                localVarQueryParameter['visualization_id'] = visualizationId;
            }

            if (groupByTarget !== undefined) {
                localVarQueryParameter['group_by_target'] = groupByTarget;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Snapshot of the usage of the workspace in terms of number of datasets, number of recordings (model + experiments) and analyzers.
         * @summary Snapshot of the usage of the workspace.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetsUsageV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetsUsageV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/usage`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API returns the correct upload credential id.
         * @summary URL to be called to get upload credential id. It ensures correct credential ID is returned, considering hybrid deployment.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentResponseV11: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDeploymentResponseV11', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/upload-credential-id`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Internal API for get dataset preview data snapshot
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisteredDatasetPreviewInternalV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getRegisteredDatasetPreviewInternalV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getRegisteredDatasetPreviewInternalV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/dataset/{dataset_id}/preview`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Given tasklet ID get the task ID to re-run the analyser
         * @summary Get taskID for a given tasklet ID
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskIDForTaskletV1: async (workspaceId: string, datasetId: string, taskletId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getTaskIDForTaskletV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getTaskIDForTaskletV1', 'datasetId', datasetId)
            // verify required parameter 'taskletId' is not null or undefined
            assertParamExists('getTaskIDForTaskletV1', 'taskletId', taskletId)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/dataset/{dataset_id}/data-analyses/tasklet/{tasklet_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"tasklet_id"}}`, encodeURIComponent(String(taskletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * uses open ai to classify list of words into topic with explanation
         * @summary Given the list of words returns a topic and explanation for each
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {WordsForTopic} wordsForTopic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicExplanationForTaskletV1: async (workspaceId: string, datasetId: string, wordsForTopic: WordsForTopic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getTopicExplanationForTaskletV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getTopicExplanationForTaskletV1', 'datasetId', datasetId)
            // verify required parameter 'wordsForTopic' is not null or undefined
            assertParamExists('getTopicExplanationForTaskletV1', 'wordsForTopic', wordsForTopic)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/dataset/{dataset_id}/explain/topic`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wordsForTopic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * uses open ai to classify list of words into topic with explanation
         * @summary Given the list of words returns a topic and explanation for each
         * @param {string} workspaceId 
         * @param {WordsForTopic} wordsForTopic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicExplanationV1: async (workspaceId: string, wordsForTopic: WordsForTopic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getTopicExplanationV1', 'workspaceId', workspaceId)
            // verify required parameter 'wordsForTopic' is not null or undefined
            assertParamExists('getTopicExplanationV1', 'wordsForTopic', wordsForTopic)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/explain/topic`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wordsForTopic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is an internal method for fetching demo artifacts from {demo-wsp-8vbr266tis}. This will be copied at the time of creation of user workspace.
         * @summary Fetch demo artifacts from demo workspace id {demo-wsp-8vbr266tis}
         * @param {CreateDemoArtifactsRequest} createDemoArtifactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCopyDemoArtifactsV1: async (createDemoArtifactsRequest: CreateDemoArtifactsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDemoArtifactsRequest' is not null or undefined
            assertParamExists('internalCopyDemoArtifactsV1', 'createDemoArtifactsRequest', createDemoArtifactsRequest)
            const localVarPath = `/vienna/v1/internal/artefact/demo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDemoArtifactsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the datasets, this route supports m2m authentication.Pagination is supported by passing start and end query params. Datasets can be filtered by name by passing the name query param.Datasets can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
         * @summary Returns datasets for a workspace
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDataSetsListV1: async (workspaceId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('internalDataSetsListV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/datasets`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch status of migration of artifacts from demo workspace.
         * @summary Fetch demo artifacts migration status
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDemoArtifactsMigrationStatusV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('internalDemoArtifactsMigrationStatusV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/artefact/demo/migration-status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is required to send superset admin auth details to other services. The auth details are only kept in vienna, and all other services like SF can fetch them from here rather than storing separately as env vars.
         * @summary Get the authentication details of admin user of superset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetSupersetAdminAuthV1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vienna/v1/internal/superset/admin-auth-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets list of template from json file
         * @summary Get list of templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListTemplatesV1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vienna/v1/workflows/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of all manually triggered runs for each component. For ex, dataset component would return alldataset comparison runs. Filters can be provided on user_id and component_ids.
         * @summary Get list of manually triggered runs and their statuses
         * @param {string} workspaceId 
         * @param {ComponentType} componentType 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [componentId] 
         * @param {string} [ts] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRunsV11: async (workspaceId: string, componentType: ComponentType, start?: number, end?: number, componentId?: string, ts?: string, userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listRunsV11', 'workspaceId', workspaceId)
            // verify required parameter 'componentType' is not null or undefined
            assertParamExists('listRunsV11', 'componentType', componentType)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/component_type/{component_type}/runs`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"component_type"}}`, encodeURIComponent(String(componentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (componentId !== undefined) {
                localVarQueryParameter['component_id'] = componentId;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks status of the search pod for given dataset_id
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPodStatusInternal: async (datasetId: string, workspaceId: string, embeddingId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('searchPodStatusInternal', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('searchPodStatusInternal', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/datasets/{dataset_id}/check-status`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (embeddingId !== undefined) {
                localVarQueryParameter['embedding_id'] = embeddingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spinPodForClusteringSimilarityInternal: async (datasetId: string, workspaceId: string, embeddingId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('spinPodForClusteringSimilarityInternal', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('spinPodForClusteringSimilarityInternal', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/datasets/{dataset_id}/create-pod`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (embeddingId !== undefined) {
                localVarQueryParameter['embedding_id'] = embeddingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows you to stop all currently running data analysis tasks for a given workspace. Use this for automation purposes, such as after running an automation suite.
         * @summary Stops all the tasks running for given workspace
         * @param {string} workspaceId 
         * @param {StopAllAnalyzersRequest} stopAllAnalyzersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopAllAnalyzers: async (workspaceId: string, stopAllAnalyzersRequest: StopAllAnalyzersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('stopAllAnalyzers', 'workspaceId', workspaceId)
            // verify required parameter 'stopAllAnalyzersRequest' is not null or undefined
            assertParamExists('stopAllAnalyzers', 'stopAllAnalyzersRequest', stopAllAnalyzersRequest)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/data-analyses/stop`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stopAllAnalyzersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is called when any tasklet is completed. To be used for doing any post-processing actions
         * @summary API called on the completion of any tasklet
         * @param {string} workspaceId 
         * @param {PostTaskletCompletionCallbackRequest} postTaskletCompletionCallbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskletCompletionCallbackV1: async (workspaceId: string, postTaskletCompletionCallbackRequest: PostTaskletCompletionCallbackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('taskletCompletionCallbackV1', 'workspaceId', workspaceId)
            // verify required parameter 'postTaskletCompletionCallbackRequest' is not null or undefined
            assertParamExists('taskletCompletionCallbackV1', 'postTaskletCompletionCallbackRequest', postTaskletCompletionCallbackRequest)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/callbacks/post-tasklet-completion`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTaskletCompletionCallbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stops the running task for given task_ids list.
         * @param {string} workspaceId 
         * @param {BulkStopAnalysersRequest} bulkStopAnalysersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerStopTaskAnalysersV1: async (workspaceId: string, bulkStopAnalysersRequest: BulkStopAnalysersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('triggerStopTaskAnalysersV1', 'workspaceId', workspaceId)
            // verify required parameter 'bulkStopAnalysersRequest' is not null or undefined
            assertParamExists('triggerStopTaskAnalysersV1', 'bulkStopAnalysersRequest', bulkStopAnalysersRequest)
            const localVarPath = `/vienna/v1/internal/workspace/{workspace_id}/data-analyses/bulk-stop`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkStopAnalysersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Parses the email event and triggers the corresponding workflow for it
         * @param {TriggerWorkflowOnEmailRequest} triggerWorkflowOnEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerWorkflowOnEmailInternalV1: async (triggerWorkflowOnEmailRequest: TriggerWorkflowOnEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'triggerWorkflowOnEmailRequest' is not null or undefined
            assertParamExists('triggerWorkflowOnEmailInternalV1', 'triggerWorkflowOnEmailRequest', triggerWorkflowOnEmailRequest)
            const localVarPath = `/vienna/v1/internal/trigger-workflow-on-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(triggerWorkflowOnEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalRoutesApi - functional programming interface
 * @export
 */
export const InternalRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Save/Bookmark Resource
         * @summary Save/Bookmark Resource
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {AddResourceRequestV2Mig} addResourceRequestV2Mig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addResourcesV2Mig(workspaceId: string, connectorId: string, addResourceRequestV2Mig: AddResourceRequestV2Mig, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addResourcesV2Mig(workspaceId, connectorId, addResourceRequestV2Mig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This is an internal method for pre-emptively caching analysis results for a dataset analysis results
         * @summary Cache analysis graphs data
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async cacheAnalysisResultsV1(workspaceId: string, datasetId: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cacheAnalysisResultsV1(workspaceId, datasetId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create new connector
         * @summary Create new connector
         * @param {string} workspaceId 
         * @param {CreateConnectorRequestV2Mig} createConnectorRequestV2Mig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConnectorV2(workspaceId: string, createConnectorRequestV2Mig: CreateConnectorRequestV2Mig, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateConnectorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConnectorV2(workspaceId, createConnectorRequestV2Mig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns info associated with a dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataSetsInfoInternalV1(datasetId: string, workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataSetsInfoInternalV1(datasetId, workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns raw data for clustering record ids
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {ClusteringRawDataInternalRequest} clusteringRawDataInternalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetClusteringRawDataInternalV1(datasetId: string, workspaceId: string, clusteringRawDataInternalRequest: ClusteringRawDataInternalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusteringRawDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetClusteringRawDataInternalV1(datasetId, workspaceId, clusteringRawDataInternalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {SimilarityRequest} similarityRequest 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetClusteringSimilarityInternalV1(datasetId: string, workspaceId: string, similarityRequest: SimilarityRequest, embeddingId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusteringSimilarityResponseV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetClusteringSimilarityInternalV1(datasetId, workspaceId, similarityRequest, embeddingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the list of variables used in the given dataset and segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {string} variable 
         * @param {string} visualizationId 
         * @param {boolean} [groupByTarget] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetVariableChartDataInternalV1(workspaceId: string, datasetId: string, segmentType: string, variable: string, visualizationId: string, groupByTarget?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetVariableChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetVariableChartDataInternalV1(workspaceId, datasetId, segmentType, variable, visualizationId, groupByTarget, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Snapshot of the usage of the workspace in terms of number of datasets, number of recordings (model + experiments) and analyzers.
         * @summary Snapshot of the usage of the workspace.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetsUsageV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceUsageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetsUsageV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API returns the correct upload credential id.
         * @summary URL to be called to get upload credential id. It ensures correct credential ID is returned, considering hybrid deployment.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeploymentResponseV11(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUploadCredentialResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeploymentResponseV11(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Internal API for get dataset preview data snapshot
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegisteredDatasetPreviewInternalV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDatasetPreviewDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegisteredDatasetPreviewInternalV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Given tasklet ID get the task ID to re-run the analyser
         * @summary Get taskID for a given tasklet ID
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskIDForTaskletV1(workspaceId: string, datasetId: string, taskletId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTaskIdForTaskletId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskIDForTaskletV1(workspaceId, datasetId, taskletId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * uses open ai to classify list of words into topic with explanation
         * @summary Given the list of words returns a topic and explanation for each
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {WordsForTopic} wordsForTopic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopicExplanationForTaskletV1(workspaceId: string, datasetId: string, wordsForTopic: WordsForTopic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicExplanationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopicExplanationForTaskletV1(workspaceId, datasetId, wordsForTopic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * uses open ai to classify list of words into topic with explanation
         * @summary Given the list of words returns a topic and explanation for each
         * @param {string} workspaceId 
         * @param {WordsForTopic} wordsForTopic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopicExplanationV1(workspaceId: string, wordsForTopic: WordsForTopic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicExplanationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopicExplanationV1(workspaceId, wordsForTopic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This is an internal method for fetching demo artifacts from {demo-wsp-8vbr266tis}. This will be copied at the time of creation of user workspace.
         * @summary Fetch demo artifacts from demo workspace id {demo-wsp-8vbr266tis}
         * @param {CreateDemoArtifactsRequest} createDemoArtifactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCopyDemoArtifactsV1(createDemoArtifactsRequest: CreateDemoArtifactsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCopyDemoArtifactsV1(createDemoArtifactsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all the datasets, this route supports m2m authentication.Pagination is supported by passing start and end query params. Datasets can be filtered by name by passing the name query param.Datasets can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
         * @summary Returns datasets for a workspace
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDataSetsListV1(workspaceId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDatasetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDataSetsListV1(workspaceId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeDemoArtifacts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch status of migration of artifacts from demo workspace.
         * @summary Fetch demo artifacts migration status
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDemoArtifactsMigrationStatusV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDemoArtifactsMigrationStatusV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API is required to send superset admin auth details to other services. The auth details are only kept in vienna, and all other services like SF can fetch them from here rather than storing separately as env vars.
         * @summary Get the authentication details of admin user of superset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetSupersetAdminAuthV1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupersetAdminAuthDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetSupersetAdminAuthV1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets list of template from json file
         * @summary Get list of templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListTemplatesV1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListTemplatesV1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of all manually triggered runs for each component. For ex, dataset component would return alldataset comparison runs. Filters can be provided on user_id and component_ids.
         * @summary Get list of manually triggered runs and their statuses
         * @param {string} workspaceId 
         * @param {ComponentType} componentType 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [componentId] 
         * @param {string} [ts] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRunsV11(workspaceId: string, componentType: ComponentType, start?: number, end?: number, componentId?: string, ts?: string, userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRunsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRunsV11(workspaceId, componentType, start, end, componentId, ts, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks status of the search pod for given dataset_id
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPodStatusInternal(datasetId: string, workspaceId: string, embeddingId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchPodStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPodStatusInternal(datasetId, workspaceId, embeddingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spinPodForClusteringSimilarityInternal(datasetId: string, workspaceId: string, embeddingId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spinPodForClusteringSimilarityInternal(datasetId, workspaceId, embeddingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint allows you to stop all currently running data analysis tasks for a given workspace. Use this for automation purposes, such as after running an automation suite.
         * @summary Stops all the tasks running for given workspace
         * @param {string} workspaceId 
         * @param {StopAllAnalyzersRequest} stopAllAnalyzersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopAllAnalyzers(workspaceId: string, stopAllAnalyzersRequest: StopAllAnalyzersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskWithStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopAllAnalyzers(workspaceId, stopAllAnalyzersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API is called when any tasklet is completed. To be used for doing any post-processing actions
         * @summary API called on the completion of any tasklet
         * @param {string} workspaceId 
         * @param {PostTaskletCompletionCallbackRequest} postTaskletCompletionCallbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskletCompletionCallbackV1(workspaceId: string, postTaskletCompletionCallbackRequest: PostTaskletCompletionCallbackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskletCompletionCallbackV1(workspaceId, postTaskletCompletionCallbackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stops the running task for given task_ids list.
         * @param {string} workspaceId 
         * @param {BulkStopAnalysersRequest} bulkStopAnalysersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerStopTaskAnalysersV1(workspaceId: string, bulkStopAnalysersRequest: BulkStopAnalysersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskWithStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerStopTaskAnalysersV1(workspaceId, bulkStopAnalysersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Parses the email event and triggers the corresponding workflow for it
         * @param {TriggerWorkflowOnEmailRequest} triggerWorkflowOnEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerWorkflowOnEmailInternalV1(triggerWorkflowOnEmailRequest: TriggerWorkflowOnEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerWorkflowOnEmailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerWorkflowOnEmailInternalV1(triggerWorkflowOnEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalRoutesApi - factory interface
 * @export
 */
export const InternalRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalRoutesApiFp(configuration)
    return {
        /**
         * Save/Bookmark Resource
         * @summary Save/Bookmark Resource
         * @param {string} workspaceId 
         * @param {string} connectorId 
         * @param {AddResourceRequestV2Mig} addResourceRequestV2Mig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addResourcesV2Mig(workspaceId: string, connectorId: string, addResourceRequestV2Mig: AddResourceRequestV2Mig, options?: any): AxiosPromise<AddResourceResponse> {
            return localVarFp.addResourcesV2Mig(workspaceId, connectorId, addResourceRequestV2Mig, options).then((request) => request(axios, basePath));
        },
        /**
         * This is an internal method for pre-emptively caching analysis results for a dataset analysis results
         * @summary Cache analysis graphs data
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        cacheAnalysisResultsV1(workspaceId: string, datasetId: string, requestBody?: Array<string>, options?: any): AxiosPromise<any> {
            return localVarFp.cacheAnalysisResultsV1(workspaceId, datasetId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new connector
         * @summary Create new connector
         * @param {string} workspaceId 
         * @param {CreateConnectorRequestV2Mig} createConnectorRequestV2Mig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnectorV2(workspaceId: string, createConnectorRequestV2Mig: CreateConnectorRequestV2Mig, options?: any): AxiosPromise<CreateConnectorResponse> {
            return localVarFp.createConnectorV2(workspaceId, createConnectorRequestV2Mig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns info associated with a dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSetsInfoInternalV1(datasetId: string, workspaceId: string, options?: any): AxiosPromise<DatasetInfoResponse> {
            return localVarFp.getDataSetsInfoInternalV1(datasetId, workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns raw data for clustering record ids
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {ClusteringRawDataInternalRequest} clusteringRawDataInternalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetClusteringRawDataInternalV1(datasetId: string, workspaceId: string, clusteringRawDataInternalRequest: ClusteringRawDataInternalRequest, options?: any): AxiosPromise<ClusteringRawDataResponse> {
            return localVarFp.getDatasetClusteringRawDataInternalV1(datasetId, workspaceId, clusteringRawDataInternalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {SimilarityRequest} similarityRequest 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetClusteringSimilarityInternalV1(datasetId: string, workspaceId: string, similarityRequest: SimilarityRequest, embeddingId?: string, options?: any): AxiosPromise<ClusteringSimilarityResponseV1> {
            return localVarFp.getDatasetClusteringSimilarityInternalV1(datasetId, workspaceId, similarityRequest, embeddingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list of variables used in the given dataset and segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {string} variable 
         * @param {string} visualizationId 
         * @param {boolean} [groupByTarget] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetVariableChartDataInternalV1(workspaceId: string, datasetId: string, segmentType: string, variable: string, visualizationId: string, groupByTarget?: boolean, options?: any): AxiosPromise<DatasetVariableChartResponse> {
            return localVarFp.getDatasetVariableChartDataInternalV1(workspaceId, datasetId, segmentType, variable, visualizationId, groupByTarget, options).then((request) => request(axios, basePath));
        },
        /**
         * Snapshot of the usage of the workspace in terms of number of datasets, number of recordings (model + experiments) and analyzers.
         * @summary Snapshot of the usage of the workspace.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetsUsageV1(workspaceId: string, options?: any): AxiosPromise<WorkspaceUsageResponse> {
            return localVarFp.getDatasetsUsageV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * This API returns the correct upload credential id.
         * @summary URL to be called to get upload credential id. It ensures correct credential ID is returned, considering hybrid deployment.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentResponseV11(workspaceId: string, options?: any): AxiosPromise<GetUploadCredentialResponse> {
            return localVarFp.getDeploymentResponseV11(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Internal API for get dataset preview data snapshot
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisteredDatasetPreviewInternalV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<GetDatasetPreviewDataResponse> {
            return localVarFp.getRegisteredDatasetPreviewInternalV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Given tasklet ID get the task ID to re-run the analyser
         * @summary Get taskID for a given tasklet ID
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskIDForTaskletV1(workspaceId: string, datasetId: string, taskletId: string, options?: any): AxiosPromise<GetTaskIdForTaskletId> {
            return localVarFp.getTaskIDForTaskletV1(workspaceId, datasetId, taskletId, options).then((request) => request(axios, basePath));
        },
        /**
         * uses open ai to classify list of words into topic with explanation
         * @summary Given the list of words returns a topic and explanation for each
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {WordsForTopic} wordsForTopic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicExplanationForTaskletV1(workspaceId: string, datasetId: string, wordsForTopic: WordsForTopic, options?: any): AxiosPromise<TopicExplanationResponse> {
            return localVarFp.getTopicExplanationForTaskletV1(workspaceId, datasetId, wordsForTopic, options).then((request) => request(axios, basePath));
        },
        /**
         * uses open ai to classify list of words into topic with explanation
         * @summary Given the list of words returns a topic and explanation for each
         * @param {string} workspaceId 
         * @param {WordsForTopic} wordsForTopic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicExplanationV1(workspaceId: string, wordsForTopic: WordsForTopic, options?: any): AxiosPromise<TopicExplanationResponse> {
            return localVarFp.getTopicExplanationV1(workspaceId, wordsForTopic, options).then((request) => request(axios, basePath));
        },
        /**
         * This is an internal method for fetching demo artifacts from {demo-wsp-8vbr266tis}. This will be copied at the time of creation of user workspace.
         * @summary Fetch demo artifacts from demo workspace id {demo-wsp-8vbr266tis}
         * @param {CreateDemoArtifactsRequest} createDemoArtifactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCopyDemoArtifactsV1(createDemoArtifactsRequest: CreateDemoArtifactsRequest, options?: any): AxiosPromise<any> {
            return localVarFp.internalCopyDemoArtifactsV1(createDemoArtifactsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the datasets, this route supports m2m authentication.Pagination is supported by passing start and end query params. Datasets can be filtered by name by passing the name query param.Datasets can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
         * @summary Returns datasets for a workspace
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDataSetsListV1(workspaceId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options?: any): AxiosPromise<ListDatasetsResponse> {
            return localVarFp.internalDataSetsListV1(workspaceId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeDemoArtifacts, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch status of migration of artifacts from demo workspace.
         * @summary Fetch demo artifacts migration status
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDemoArtifactsMigrationStatusV1(workspaceId: string, options?: any): AxiosPromise<any> {
            return localVarFp.internalDemoArtifactsMigrationStatusV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is required to send superset admin auth details to other services. The auth details are only kept in vienna, and all other services like SF can fetch them from here rather than storing separately as env vars.
         * @summary Get the authentication details of admin user of superset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetSupersetAdminAuthV1(options?: any): AxiosPromise<SupersetAdminAuthDetails> {
            return localVarFp.internalGetSupersetAdminAuthV1(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets list of template from json file
         * @summary Get list of templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListTemplatesV1(options?: any): AxiosPromise<TemplateListResponse> {
            return localVarFp.internalListTemplatesV1(options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of all manually triggered runs for each component. For ex, dataset component would return alldataset comparison runs. Filters can be provided on user_id and component_ids.
         * @summary Get list of manually triggered runs and their statuses
         * @param {string} workspaceId 
         * @param {ComponentType} componentType 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [componentId] 
         * @param {string} [ts] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRunsV11(workspaceId: string, componentType: ComponentType, start?: number, end?: number, componentId?: string, ts?: string, userId?: string, options?: any): AxiosPromise<ListRunsResponse> {
            return localVarFp.listRunsV11(workspaceId, componentType, start, end, componentId, ts, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks status of the search pod for given dataset_id
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPodStatusInternal(datasetId: string, workspaceId: string, embeddingId?: string, options?: any): AxiosPromise<SearchPodStatusResponse> {
            return localVarFp.searchPodStatusInternal(datasetId, workspaceId, embeddingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {string} [embeddingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spinPodForClusteringSimilarityInternal(datasetId: string, workspaceId: string, embeddingId?: string, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.spinPodForClusteringSimilarityInternal(datasetId, workspaceId, embeddingId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows you to stop all currently running data analysis tasks for a given workspace. Use this for automation purposes, such as after running an automation suite.
         * @summary Stops all the tasks running for given workspace
         * @param {string} workspaceId 
         * @param {StopAllAnalyzersRequest} stopAllAnalyzersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopAllAnalyzers(workspaceId: string, stopAllAnalyzersRequest: StopAllAnalyzersRequest, options?: any): AxiosPromise<Array<TaskWithStatus>> {
            return localVarFp.stopAllAnalyzers(workspaceId, stopAllAnalyzersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is called when any tasklet is completed. To be used for doing any post-processing actions
         * @summary API called on the completion of any tasklet
         * @param {string} workspaceId 
         * @param {PostTaskletCompletionCallbackRequest} postTaskletCompletionCallbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskletCompletionCallbackV1(workspaceId: string, postTaskletCompletionCallbackRequest: PostTaskletCompletionCallbackRequest, options?: any): AxiosPromise<any> {
            return localVarFp.taskletCompletionCallbackV1(workspaceId, postTaskletCompletionCallbackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stops the running task for given task_ids list.
         * @param {string} workspaceId 
         * @param {BulkStopAnalysersRequest} bulkStopAnalysersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerStopTaskAnalysersV1(workspaceId: string, bulkStopAnalysersRequest: BulkStopAnalysersRequest, options?: any): AxiosPromise<Array<TaskWithStatus>> {
            return localVarFp.triggerStopTaskAnalysersV1(workspaceId, bulkStopAnalysersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Parses the email event and triggers the corresponding workflow for it
         * @param {TriggerWorkflowOnEmailRequest} triggerWorkflowOnEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerWorkflowOnEmailInternalV1(triggerWorkflowOnEmailRequest: TriggerWorkflowOnEmailRequest, options?: any): AxiosPromise<TriggerWorkflowOnEmailResponse> {
            return localVarFp.triggerWorkflowOnEmailInternalV1(triggerWorkflowOnEmailRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalRoutesApi - object-oriented interface
 * @export
 * @class InternalRoutesApi
 * @extends {BaseAPI}
 */
export class InternalRoutesApi extends BaseAPI {
    /**
     * Save/Bookmark Resource
     * @summary Save/Bookmark Resource
     * @param {string} workspaceId 
     * @param {string} connectorId 
     * @param {AddResourceRequestV2Mig} addResourceRequestV2Mig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public addResourcesV2Mig(workspaceId: string, connectorId: string, addResourceRequestV2Mig: AddResourceRequestV2Mig, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).addResourcesV2Mig(workspaceId, connectorId, addResourceRequestV2Mig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is an internal method for pre-emptively caching analysis results for a dataset analysis results
     * @summary Cache analysis graphs data
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public cacheAnalysisResultsV1(workspaceId: string, datasetId: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).cacheAnalysisResultsV1(workspaceId, datasetId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new connector
     * @summary Create new connector
     * @param {string} workspaceId 
     * @param {CreateConnectorRequestV2Mig} createConnectorRequestV2Mig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public createConnectorV2(workspaceId: string, createConnectorRequestV2Mig: CreateConnectorRequestV2Mig, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).createConnectorV2(workspaceId, createConnectorRequestV2Mig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns info associated with a dataset.
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public getDataSetsInfoInternalV1(datasetId: string, workspaceId: string, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).getDataSetsInfoInternalV1(datasetId, workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns raw data for clustering record ids
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {ClusteringRawDataInternalRequest} clusteringRawDataInternalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public getDatasetClusteringRawDataInternalV1(datasetId: string, workspaceId: string, clusteringRawDataInternalRequest: ClusteringRawDataInternalRequest, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).getDatasetClusteringRawDataInternalV1(datasetId, workspaceId, clusteringRawDataInternalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns similar points in the cluster for the dataset.
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {SimilarityRequest} similarityRequest 
     * @param {string} [embeddingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public getDatasetClusteringSimilarityInternalV1(datasetId: string, workspaceId: string, similarityRequest: SimilarityRequest, embeddingId?: string, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).getDatasetClusteringSimilarityInternalV1(datasetId, workspaceId, similarityRequest, embeddingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list of variables used in the given dataset and segment.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} segmentType 
     * @param {string} variable 
     * @param {string} visualizationId 
     * @param {boolean} [groupByTarget] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public getDatasetVariableChartDataInternalV1(workspaceId: string, datasetId: string, segmentType: string, variable: string, visualizationId: string, groupByTarget?: boolean, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).getDatasetVariableChartDataInternalV1(workspaceId, datasetId, segmentType, variable, visualizationId, groupByTarget, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Snapshot of the usage of the workspace in terms of number of datasets, number of recordings (model + experiments) and analyzers.
     * @summary Snapshot of the usage of the workspace.
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public getDatasetsUsageV1(workspaceId: string, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).getDatasetsUsageV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API returns the correct upload credential id.
     * @summary URL to be called to get upload credential id. It ensures correct credential ID is returned, considering hybrid deployment.
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public getDeploymentResponseV11(workspaceId: string, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).getDeploymentResponseV11(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Internal API for get dataset preview data snapshot
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public getRegisteredDatasetPreviewInternalV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).getRegisteredDatasetPreviewInternalV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Given tasklet ID get the task ID to re-run the analyser
     * @summary Get taskID for a given tasklet ID
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} taskletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public getTaskIDForTaskletV1(workspaceId: string, datasetId: string, taskletId: string, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).getTaskIDForTaskletV1(workspaceId, datasetId, taskletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * uses open ai to classify list of words into topic with explanation
     * @summary Given the list of words returns a topic and explanation for each
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {WordsForTopic} wordsForTopic 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public getTopicExplanationForTaskletV1(workspaceId: string, datasetId: string, wordsForTopic: WordsForTopic, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).getTopicExplanationForTaskletV1(workspaceId, datasetId, wordsForTopic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * uses open ai to classify list of words into topic with explanation
     * @summary Given the list of words returns a topic and explanation for each
     * @param {string} workspaceId 
     * @param {WordsForTopic} wordsForTopic 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public getTopicExplanationV1(workspaceId: string, wordsForTopic: WordsForTopic, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).getTopicExplanationV1(workspaceId, wordsForTopic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is an internal method for fetching demo artifacts from {demo-wsp-8vbr266tis}. This will be copied at the time of creation of user workspace.
     * @summary Fetch demo artifacts from demo workspace id {demo-wsp-8vbr266tis}
     * @param {CreateDemoArtifactsRequest} createDemoArtifactsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public internalCopyDemoArtifactsV1(createDemoArtifactsRequest: CreateDemoArtifactsRequest, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).internalCopyDemoArtifactsV1(createDemoArtifactsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the datasets, this route supports m2m authentication.Pagination is supported by passing start and end query params. Datasets can be filtered by name by passing the name query param.Datasets can be filtered by user_ids, and exclude_user_ids by passing the user_ids query param.
     * @summary Returns datasets for a workspace
     * @param {string} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public internalDataSetsListV1(workspaceId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).internalDataSetsListV1(workspaceId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeDemoArtifacts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch status of migration of artifacts from demo workspace.
     * @summary Fetch demo artifacts migration status
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public internalDemoArtifactsMigrationStatusV1(workspaceId: string, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).internalDemoArtifactsMigrationStatusV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is required to send superset admin auth details to other services. The auth details are only kept in vienna, and all other services like SF can fetch them from here rather than storing separately as env vars.
     * @summary Get the authentication details of admin user of superset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public internalGetSupersetAdminAuthV1(options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).internalGetSupersetAdminAuthV1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets list of template from json file
     * @summary Get list of templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public internalListTemplatesV1(options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).internalListTemplatesV1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of all manually triggered runs for each component. For ex, dataset component would return alldataset comparison runs. Filters can be provided on user_id and component_ids.
     * @summary Get list of manually triggered runs and their statuses
     * @param {string} workspaceId 
     * @param {ComponentType} componentType 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [componentId] 
     * @param {string} [ts] 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public listRunsV11(workspaceId: string, componentType: ComponentType, start?: number, end?: number, componentId?: string, ts?: string, userId?: string, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).listRunsV11(workspaceId, componentType, start, end, componentId, ts, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks status of the search pod for given dataset_id
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {string} [embeddingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public searchPodStatusInternal(datasetId: string, workspaceId: string, embeddingId?: string, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).searchPodStatusInternal(datasetId, workspaceId, embeddingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns similar points in the cluster for the dataset.
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {string} [embeddingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public spinPodForClusteringSimilarityInternal(datasetId: string, workspaceId: string, embeddingId?: string, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).spinPodForClusteringSimilarityInternal(datasetId, workspaceId, embeddingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows you to stop all currently running data analysis tasks for a given workspace. Use this for automation purposes, such as after running an automation suite.
     * @summary Stops all the tasks running for given workspace
     * @param {string} workspaceId 
     * @param {StopAllAnalyzersRequest} stopAllAnalyzersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public stopAllAnalyzers(workspaceId: string, stopAllAnalyzersRequest: StopAllAnalyzersRequest, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).stopAllAnalyzers(workspaceId, stopAllAnalyzersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is called when any tasklet is completed. To be used for doing any post-processing actions
     * @summary API called on the completion of any tasklet
     * @param {string} workspaceId 
     * @param {PostTaskletCompletionCallbackRequest} postTaskletCompletionCallbackRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public taskletCompletionCallbackV1(workspaceId: string, postTaskletCompletionCallbackRequest: PostTaskletCompletionCallbackRequest, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).taskletCompletionCallbackV1(workspaceId, postTaskletCompletionCallbackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stops the running task for given task_ids list.
     * @param {string} workspaceId 
     * @param {BulkStopAnalysersRequest} bulkStopAnalysersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public triggerStopTaskAnalysersV1(workspaceId: string, bulkStopAnalysersRequest: BulkStopAnalysersRequest, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).triggerStopTaskAnalysersV1(workspaceId, bulkStopAnalysersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Parses the email event and triggers the corresponding workflow for it
     * @param {TriggerWorkflowOnEmailRequest} triggerWorkflowOnEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalRoutesApi
     */
    public triggerWorkflowOnEmailInternalV1(triggerWorkflowOnEmailRequest: TriggerWorkflowOnEmailRequest, options?: AxiosRequestConfig) {
        return InternalRoutesApiFp(this.configuration).triggerWorkflowOnEmailInternalV1(triggerWorkflowOnEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvitationApi - axios parameter creator
 * @export
 */
export const InvitationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Invite the user to workspace and add the necessary memberships. This will be called when the user clicks on the accept invite email.
         * @summary Invite user to workspace
         * @param {string} userId 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInviteToWorkspaceV1: async (userId: string, inviteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('acceptInviteToWorkspaceV1', 'userId', userId)
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('acceptInviteToWorkspaceV1', 'inviteId', inviteId)
            const localVarPath = `/haifa/v1/user/{user_id}/invites/{invite_id}/accept`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create invitation for the list of users and send the invitation email to the user\'s email ids.This will be send to the user when the admin of a workspace will send an invitation to the user.
         * @summary Create invitation for the list of users and send email
         * @param {string} workspaceId 
         * @param {InvitationRequest} invitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserInviteToWorkspaceV1: async (workspaceId: string, invitationRequest: InvitationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createUserInviteToWorkspaceV1', 'workspaceId', workspaceId)
            // verify required parameter 'invitationRequest' is not null or undefined
            assertParamExists('createUserInviteToWorkspaceV1', 'invitationRequest', invitationRequest)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/invite`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all pending invites for a user.
         * @summary Get all pending invites for an user.
         * @param {string} userId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingInvitesForUserV1: async (userId: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPendingInvitesForUserV1', 'userId', userId)
            const localVarPath = `/haifa/v1/user/{user_id}/invites`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get invitation details for a particular invite id
         * @summary Get invitation details
         * @param {string} workspaceId 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInviteDetailsV1: async (workspaceId: string, inviteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getUserInviteDetailsV1', 'workspaceId', workspaceId)
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('getUserInviteDetailsV1', 'inviteId', inviteId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/invite/{invite_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all invites for a workspace. Fetch from the invite database all the invites which are inInvited state. We will only show invited ones. Error and Expired ones are not showed.
         * @summary Get all invites for a workspace
         * @param {string} workspaceId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceInvitesV1: async (workspaceId: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspaceInvitesV1', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/invited-members`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resend invite to a user
         * @summary Resend invite to a user for whom invitation was already sent
         * @param {string} inviteId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserInviteV1: async (inviteId: string, workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('resendUserInviteV1', 'inviteId', inviteId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('resendUserInviteV1', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/invite/{invite_id}/resend`
                .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Revoke invite of a user from the workspace
         * @summary Revoke invite of a user from the workspace
         * @param {string} workspaceId 
         * @param {RevokeUserInviteFromWorkspaceRequest} revokeUserInviteFromWorkspaceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeUserInviteFromWorkspaceV1: async (workspaceId: string, revokeUserInviteFromWorkspaceRequest: RevokeUserInviteFromWorkspaceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('revokeUserInviteFromWorkspaceV1', 'workspaceId', workspaceId)
            // verify required parameter 'revokeUserInviteFromWorkspaceRequest' is not null or undefined
            assertParamExists('revokeUserInviteFromWorkspaceV1', 'revokeUserInviteFromWorkspaceRequest', revokeUserInviteFromWorkspaceRequest)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/invite/revoke`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(revokeUserInviteFromWorkspaceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvitationApi - functional programming interface
 * @export
 */
export const InvitationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvitationApiAxiosParamCreator(configuration)
    return {
        /**
         * Invite the user to workspace and add the necessary memberships. This will be called when the user clicks on the accept invite email.
         * @summary Invite user to workspace
         * @param {string} userId 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptInviteToWorkspaceV1(userId: string, inviteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteUserToWorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptInviteToWorkspaceV1(userId, inviteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create invitation for the list of users and send the invitation email to the user\'s email ids.This will be send to the user when the admin of a workspace will send an invitation to the user.
         * @summary Create invitation for the list of users and send email
         * @param {string} workspaceId 
         * @param {InvitationRequest} invitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserInviteToWorkspaceV1(workspaceId: string, invitationRequest: InvitationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteUsersToWorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserInviteToWorkspaceV1(workspaceId, invitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all pending invites for a user.
         * @summary Get all pending invites for an user.
         * @param {string} userId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingInvitesForUserV1(userId: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PendingUserInvitesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingInvitesForUserV1(userId, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get invitation details for a particular invite id
         * @summary Get invitation details
         * @param {string} workspaceId 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInviteDetailsV1(workspaceId: string, inviteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteUserToWorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInviteDetailsV1(workspaceId, inviteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all invites for a workspace. Fetch from the invite database all the invites which are inInvited state. We will only show invited ones. Error and Expired ones are not showed.
         * @summary Get all invites for a workspace
         * @param {string} workspaceId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceInvitesV1(workspaceId: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceInvitesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceInvitesV1(workspaceId, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Resend invite to a user
         * @summary Resend invite to a user for whom invitation was already sent
         * @param {string} inviteId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendUserInviteV1(inviteId: string, workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteUserToWorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendUserInviteV1(inviteId, workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Revoke invite of a user from the workspace
         * @summary Revoke invite of a user from the workspace
         * @param {string} workspaceId 
         * @param {RevokeUserInviteFromWorkspaceRequest} revokeUserInviteFromWorkspaceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeUserInviteFromWorkspaceV1(workspaceId: string, revokeUserInviteFromWorkspaceRequest: RevokeUserInviteFromWorkspaceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RevokeUserInviteFromWorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeUserInviteFromWorkspaceV1(workspaceId, revokeUserInviteFromWorkspaceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvitationApi - factory interface
 * @export
 */
export const InvitationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvitationApiFp(configuration)
    return {
        /**
         * Invite the user to workspace and add the necessary memberships. This will be called when the user clicks on the accept invite email.
         * @summary Invite user to workspace
         * @param {string} userId 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInviteToWorkspaceV1(userId: string, inviteId: string, options?: any): AxiosPromise<InviteUserToWorkspaceResponse> {
            return localVarFp.acceptInviteToWorkspaceV1(userId, inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create invitation for the list of users and send the invitation email to the user\'s email ids.This will be send to the user when the admin of a workspace will send an invitation to the user.
         * @summary Create invitation for the list of users and send email
         * @param {string} workspaceId 
         * @param {InvitationRequest} invitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserInviteToWorkspaceV1(workspaceId: string, invitationRequest: InvitationRequest, options?: any): AxiosPromise<InviteUsersToWorkspaceResponse> {
            return localVarFp.createUserInviteToWorkspaceV1(workspaceId, invitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all pending invites for a user.
         * @summary Get all pending invites for an user.
         * @param {string} userId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingInvitesForUserV1(userId: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<PendingUserInvitesResponse> {
            return localVarFp.getPendingInvitesForUserV1(userId, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Get invitation details for a particular invite id
         * @summary Get invitation details
         * @param {string} workspaceId 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInviteDetailsV1(workspaceId: string, inviteId: string, options?: any): AxiosPromise<InviteUserToWorkspaceResponse> {
            return localVarFp.getUserInviteDetailsV1(workspaceId, inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all invites for a workspace. Fetch from the invite database all the invites which are inInvited state. We will only show invited ones. Error and Expired ones are not showed.
         * @summary Get all invites for a workspace
         * @param {string} workspaceId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceInvitesV1(workspaceId: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<WorkspaceInvitesResponse> {
            return localVarFp.getWorkspaceInvitesV1(workspaceId, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Resend invite to a user
         * @summary Resend invite to a user for whom invitation was already sent
         * @param {string} inviteId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserInviteV1(inviteId: string, workspaceId: string, options?: any): AxiosPromise<InviteUserToWorkspaceResponse> {
            return localVarFp.resendUserInviteV1(inviteId, workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Revoke invite of a user from the workspace
         * @summary Revoke invite of a user from the workspace
         * @param {string} workspaceId 
         * @param {RevokeUserInviteFromWorkspaceRequest} revokeUserInviteFromWorkspaceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeUserInviteFromWorkspaceV1(workspaceId: string, revokeUserInviteFromWorkspaceRequest: RevokeUserInviteFromWorkspaceRequest, options?: any): AxiosPromise<RevokeUserInviteFromWorkspaceResponse> {
            return localVarFp.revokeUserInviteFromWorkspaceV1(workspaceId, revokeUserInviteFromWorkspaceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvitationApi - object-oriented interface
 * @export
 * @class InvitationApi
 * @extends {BaseAPI}
 */
export class InvitationApi extends BaseAPI {
    /**
     * Invite the user to workspace and add the necessary memberships. This will be called when the user clicks on the accept invite email.
     * @summary Invite user to workspace
     * @param {string} userId 
     * @param {string} inviteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public acceptInviteToWorkspaceV1(userId: string, inviteId: string, options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).acceptInviteToWorkspaceV1(userId, inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create invitation for the list of users and send the invitation email to the user\'s email ids.This will be send to the user when the admin of a workspace will send an invitation to the user.
     * @summary Create invitation for the list of users and send email
     * @param {string} workspaceId 
     * @param {InvitationRequest} invitationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public createUserInviteToWorkspaceV1(workspaceId: string, invitationRequest: InvitationRequest, options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).createUserInviteToWorkspaceV1(workspaceId, invitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all pending invites for a user.
     * @summary Get all pending invites for an user.
     * @param {string} userId 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public getPendingInvitesForUserV1(userId: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).getPendingInvitesForUserV1(userId, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get invitation details for a particular invite id
     * @summary Get invitation details
     * @param {string} workspaceId 
     * @param {string} inviteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public getUserInviteDetailsV1(workspaceId: string, inviteId: string, options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).getUserInviteDetailsV1(workspaceId, inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all invites for a workspace. Fetch from the invite database all the invites which are inInvited state. We will only show invited ones. Error and Expired ones are not showed.
     * @summary Get all invites for a workspace
     * @param {string} workspaceId 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public getWorkspaceInvitesV1(workspaceId: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).getWorkspaceInvitesV1(workspaceId, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resend invite to a user
     * @summary Resend invite to a user for whom invitation was already sent
     * @param {string} inviteId 
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public resendUserInviteV1(inviteId: string, workspaceId: string, options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).resendUserInviteV1(inviteId, workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Revoke invite of a user from the workspace
     * @summary Revoke invite of a user from the workspace
     * @param {string} workspaceId 
     * @param {RevokeUserInviteFromWorkspaceRequest} revokeUserInviteFromWorkspaceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public revokeUserInviteFromWorkspaceV1(workspaceId: string, revokeUserInviteFromWorkspaceRequest: RevokeUserInviteFromWorkspaceRequest, options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).revokeUserInviteFromWorkspaceV1(workspaceId, revokeUserInviteFromWorkspaceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LLMClusterStatusApi - axios parameter creator
 * @export
 */
export const LLMClusterStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Status
         * @param {string} [modelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusV0StatusGet: async (modelId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chennai/v0/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (modelId !== undefined) {
                localVarQueryParameter['model_id'] = modelId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckV0HealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chennai/v0/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LLMClusterStatusApi - functional programming interface
 * @export
 */
export const LLMClusterStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LLMClusterStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Status
         * @param {string} [modelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatusV0StatusGet(modelId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatusV0StatusGet(modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheckV0HealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheckV0HealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LLMClusterStatusApi - factory interface
 * @export
 */
export const LLMClusterStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LLMClusterStatusApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Status
         * @param {string} [modelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusV0StatusGet(modelId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.getStatusV0StatusGet(modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckV0HealthGet(options?: any): AxiosPromise<any> {
            return localVarFp.healthCheckV0HealthGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LLMClusterStatusApi - object-oriented interface
 * @export
 * @class LLMClusterStatusApi
 * @extends {BaseAPI}
 */
export class LLMClusterStatusApi extends BaseAPI {
    /**
     * 
     * @summary Get Status
     * @param {string} [modelId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LLMClusterStatusApi
     */
    public getStatusV0StatusGet(modelId?: string, options?: AxiosRequestConfig) {
        return LLMClusterStatusApiFp(this.configuration).getStatusV0StatusGet(modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LLMClusterStatusApi
     */
    public healthCheckV0HealthGet(options?: AxiosRequestConfig) {
        return LLMClusterStatusApiFp(this.configuration).healthCheckV0HealthGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LLMDatasetApi - axios parameter creator
 * @export
 */
export const LLMDatasetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Download dataset from s3 for training / evaluation Args:     :param dataset_name : Unique name of the dataset to be used     :param dataset_key : S3 Bucket key for the location where dataset is present Returns:     Response: Provides confirmation of the path where dataset is downloaded
         * @summary Download Dataset S3
         * @param {string} datasetName 
         * @param {string} datasetKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDatasetS3V0DatasetGet: async (datasetName: string, datasetKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetName' is not null or undefined
            assertParamExists('downloadDatasetS3V0DatasetGet', 'datasetName', datasetName)
            // verify required parameter 'datasetKey' is not null or undefined
            assertParamExists('downloadDatasetS3V0DatasetGet', 'datasetKey', datasetKey)
            const localVarPath = `/chennai/v0/dataset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (datasetName !== undefined) {
                localVarQueryParameter['dataset_name'] = datasetName;
            }

            if (datasetKey !== undefined) {
                localVarQueryParameter['dataset_key'] = datasetKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LLMDatasetApi - functional programming interface
 * @export
 */
export const LLMDatasetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LLMDatasetApiAxiosParamCreator(configuration)
    return {
        /**
         * Download dataset from s3 for training / evaluation Args:     :param dataset_name : Unique name of the dataset to be used     :param dataset_key : S3 Bucket key for the location where dataset is present Returns:     Response: Provides confirmation of the path where dataset is downloaded
         * @summary Download Dataset S3
         * @param {string} datasetName 
         * @param {string} datasetKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDatasetS3V0DatasetGet(datasetName: string, datasetKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDatasetS3V0DatasetGet(datasetName, datasetKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LLMDatasetApi - factory interface
 * @export
 */
export const LLMDatasetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LLMDatasetApiFp(configuration)
    return {
        /**
         * Download dataset from s3 for training / evaluation Args:     :param dataset_name : Unique name of the dataset to be used     :param dataset_key : S3 Bucket key for the location where dataset is present Returns:     Response: Provides confirmation of the path where dataset is downloaded
         * @summary Download Dataset S3
         * @param {string} datasetName 
         * @param {string} datasetKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDatasetS3V0DatasetGet(datasetName: string, datasetKey: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadDatasetS3V0DatasetGet(datasetName, datasetKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LLMDatasetApi - object-oriented interface
 * @export
 * @class LLMDatasetApi
 * @extends {BaseAPI}
 */
export class LLMDatasetApi extends BaseAPI {
    /**
     * Download dataset from s3 for training / evaluation Args:     :param dataset_name : Unique name of the dataset to be used     :param dataset_key : S3 Bucket key for the location where dataset is present Returns:     Response: Provides confirmation of the path where dataset is downloaded
     * @summary Download Dataset S3
     * @param {string} datasetName 
     * @param {string} datasetKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LLMDatasetApi
     */
    public downloadDatasetS3V0DatasetGet(datasetName: string, datasetKey: string, options?: AxiosRequestConfig) {
        return LLMDatasetApiFp(this.configuration).downloadDatasetS3V0DatasetGet(datasetName, datasetKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LLMModelApi - axios parameter creator
 * @export
 */
export const LLMModelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes currently downloaded model files for all or specified model_id Args:     :param model_id  : ID of the model to be deleted Returns:     InferenceResponse: Model inference response on the basis of provided prompt
         * @summary Delete Model Files
         * @param {string} [modelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModelFilesV0: async (modelId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chennai/v0/model/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (modelId !== undefined) {
                localVarQueryParameter['model_id'] = modelId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download model onto local disk (temp folder) to load up in memory  Args:     :param model_id: (str) model_id/repo_id of the model to be downloaded (from HF or S3)     :param model_source: parameter specifying where pretrained model should be pulled from     :param specific_file: (str): Specific file_name to download from a repo, only for HF model download  Returns:     ModeDownloadResponse: Downloaded model response
         * @summary Download Model
         * @param {string} modelId 
         * @param {ModelSource} [modelSource] 
         * @param {string} [specificFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadModelV0: async (modelId: string, modelSource?: ModelSource, specificFile?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('downloadModelV0', 'modelId', modelId)
            const localVarPath = `/chennai/v0/model/download/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (modelId !== undefined) {
                localVarQueryParameter['model_id'] = modelId;
            }

            if (modelSource !== undefined) {
                localVarQueryParameter['model_source'] = modelSource;
            }

            if (specificFile !== undefined) {
                localVarQueryParameter['specific_file'] = specificFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate text from a model loaded in memory (GPU or RAM depending on loader) Args:     :param data : user input text on the basis of which new text is generated     :param json_format : Optional : Generates inference with in pre-specified JSON format Returns:     InferenceResponse: Model inference response on the basis of provided prompt
         * @summary Generate Text
         * @param {UserInput1} userInput1 
         * @param {string} [jsonFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTextV0: async (userInput1: UserInput1, jsonFormat?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userInput1' is not null or undefined
            assertParamExists('generateTextV0', 'userInput1', userInput1)
            const localVarPath = `/chennai/v0/model/generate/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jsonFormat !== undefined) {
                localVarQueryParameter['json_format'] = jsonFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInput1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load model in memory (GPU or RAM depending on loader) to draw inferences Args:     :param loader: Loader corresponding to model to be loaded     :param model_id: Optional : model_id of the model to be downloaded, loads last downloaded model if nothing     is provided Returns:     ModelLoadResponse: Loaded model response
         * @summary Load Model
         * @param {string} modelId 
         * @param {string} [loader] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadModelV0: async (modelId: string, loader?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('loadModelV0', 'modelId', modelId)
            const localVarPath = `/chennai/v0/model/load/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (modelId !== undefined) {
                localVarQueryParameter['model_id'] = modelId;
            }

            if (loader !== undefined) {
                localVarQueryParameter['loader'] = loader;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Uploads any downloaded model to pre-specified s3 location (model registry-HF) Args:     :param model_id : ID of the model to be pushed to s3 Returns:     InferenceResponse: Model inference response on the basis of provided prompt
         * @summary Push To S3
         * @param {string} [modelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushToS3V0: async (modelId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chennai/v0/s3/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (modelId !== undefined) {
                localVarQueryParameter['model_id'] = modelId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LLMModelApi - functional programming interface
 * @export
 */
export const LLMModelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LLMModelApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes currently downloaded model files for all or specified model_id Args:     :param model_id  : ID of the model to be deleted Returns:     InferenceResponse: Model inference response on the basis of provided prompt
         * @summary Delete Model Files
         * @param {string} [modelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteModelFilesV0(modelId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteModelFilesV0(modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download model onto local disk (temp folder) to load up in memory  Args:     :param model_id: (str) model_id/repo_id of the model to be downloaded (from HF or S3)     :param model_source: parameter specifying where pretrained model should be pulled from     :param specific_file: (str): Specific file_name to download from a repo, only for HF model download  Returns:     ModeDownloadResponse: Downloaded model response
         * @summary Download Model
         * @param {string} modelId 
         * @param {ModelSource} [modelSource] 
         * @param {string} [specificFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadModelV0(modelId: string, modelSource?: ModelSource, specificFile?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadModelV0(modelId, modelSource, specificFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate text from a model loaded in memory (GPU or RAM depending on loader) Args:     :param data : user input text on the basis of which new text is generated     :param json_format : Optional : Generates inference with in pre-specified JSON format Returns:     InferenceResponse: Model inference response on the basis of provided prompt
         * @summary Generate Text
         * @param {UserInput1} userInput1 
         * @param {string} [jsonFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTextV0(userInput1: UserInput1, jsonFormat?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTextV0(userInput1, jsonFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load model in memory (GPU or RAM depending on loader) to draw inferences Args:     :param loader: Loader corresponding to model to be loaded     :param model_id: Optional : model_id of the model to be downloaded, loads last downloaded model if nothing     is provided Returns:     ModelLoadResponse: Loaded model response
         * @summary Load Model
         * @param {string} modelId 
         * @param {string} [loader] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadModelV0(modelId: string, loader?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadModelV0(modelId, loader, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Uploads any downloaded model to pre-specified s3 location (model registry-HF) Args:     :param model_id : ID of the model to be pushed to s3 Returns:     InferenceResponse: Model inference response on the basis of provided prompt
         * @summary Push To S3
         * @param {string} [modelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pushToS3V0(modelId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pushToS3V0(modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LLMModelApi - factory interface
 * @export
 */
export const LLMModelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LLMModelApiFp(configuration)
    return {
        /**
         * Deletes currently downloaded model files for all or specified model_id Args:     :param model_id  : ID of the model to be deleted Returns:     InferenceResponse: Model inference response on the basis of provided prompt
         * @summary Delete Model Files
         * @param {string} [modelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModelFilesV0(modelId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteModelFilesV0(modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * Download model onto local disk (temp folder) to load up in memory  Args:     :param model_id: (str) model_id/repo_id of the model to be downloaded (from HF or S3)     :param model_source: parameter specifying where pretrained model should be pulled from     :param specific_file: (str): Specific file_name to download from a repo, only for HF model download  Returns:     ModeDownloadResponse: Downloaded model response
         * @summary Download Model
         * @param {string} modelId 
         * @param {ModelSource} [modelSource] 
         * @param {string} [specificFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadModelV0(modelId: string, modelSource?: ModelSource, specificFile?: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadModelV0(modelId, modelSource, specificFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate text from a model loaded in memory (GPU or RAM depending on loader) Args:     :param data : user input text on the basis of which new text is generated     :param json_format : Optional : Generates inference with in pre-specified JSON format Returns:     InferenceResponse: Model inference response on the basis of provided prompt
         * @summary Generate Text
         * @param {UserInput1} userInput1 
         * @param {string} [jsonFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTextV0(userInput1: UserInput1, jsonFormat?: string, options?: any): AxiosPromise<any> {
            return localVarFp.generateTextV0(userInput1, jsonFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Load model in memory (GPU or RAM depending on loader) to draw inferences Args:     :param loader: Loader corresponding to model to be loaded     :param model_id: Optional : model_id of the model to be downloaded, loads last downloaded model if nothing     is provided Returns:     ModelLoadResponse: Loaded model response
         * @summary Load Model
         * @param {string} modelId 
         * @param {string} [loader] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadModelV0(modelId: string, loader?: string, options?: any): AxiosPromise<any> {
            return localVarFp.loadModelV0(modelId, loader, options).then((request) => request(axios, basePath));
        },
        /**
         * Uploads any downloaded model to pre-specified s3 location (model registry-HF) Args:     :param model_id : ID of the model to be pushed to s3 Returns:     InferenceResponse: Model inference response on the basis of provided prompt
         * @summary Push To S3
         * @param {string} [modelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushToS3V0(modelId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.pushToS3V0(modelId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LLMModelApi - object-oriented interface
 * @export
 * @class LLMModelApi
 * @extends {BaseAPI}
 */
export class LLMModelApi extends BaseAPI {
    /**
     * Deletes currently downloaded model files for all or specified model_id Args:     :param model_id  : ID of the model to be deleted Returns:     InferenceResponse: Model inference response on the basis of provided prompt
     * @summary Delete Model Files
     * @param {string} [modelId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LLMModelApi
     */
    public deleteModelFilesV0(modelId?: string, options?: AxiosRequestConfig) {
        return LLMModelApiFp(this.configuration).deleteModelFilesV0(modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download model onto local disk (temp folder) to load up in memory  Args:     :param model_id: (str) model_id/repo_id of the model to be downloaded (from HF or S3)     :param model_source: parameter specifying where pretrained model should be pulled from     :param specific_file: (str): Specific file_name to download from a repo, only for HF model download  Returns:     ModeDownloadResponse: Downloaded model response
     * @summary Download Model
     * @param {string} modelId 
     * @param {ModelSource} [modelSource] 
     * @param {string} [specificFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LLMModelApi
     */
    public downloadModelV0(modelId: string, modelSource?: ModelSource, specificFile?: string, options?: AxiosRequestConfig) {
        return LLMModelApiFp(this.configuration).downloadModelV0(modelId, modelSource, specificFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate text from a model loaded in memory (GPU or RAM depending on loader) Args:     :param data : user input text on the basis of which new text is generated     :param json_format : Optional : Generates inference with in pre-specified JSON format Returns:     InferenceResponse: Model inference response on the basis of provided prompt
     * @summary Generate Text
     * @param {UserInput1} userInput1 
     * @param {string} [jsonFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LLMModelApi
     */
    public generateTextV0(userInput1: UserInput1, jsonFormat?: string, options?: AxiosRequestConfig) {
        return LLMModelApiFp(this.configuration).generateTextV0(userInput1, jsonFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load model in memory (GPU or RAM depending on loader) to draw inferences Args:     :param loader: Loader corresponding to model to be loaded     :param model_id: Optional : model_id of the model to be downloaded, loads last downloaded model if nothing     is provided Returns:     ModelLoadResponse: Loaded model response
     * @summary Load Model
     * @param {string} modelId 
     * @param {string} [loader] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LLMModelApi
     */
    public loadModelV0(modelId: string, loader?: string, options?: AxiosRequestConfig) {
        return LLMModelApiFp(this.configuration).loadModelV0(modelId, loader, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Uploads any downloaded model to pre-specified s3 location (model registry-HF) Args:     :param model_id : ID of the model to be pushed to s3 Returns:     InferenceResponse: Model inference response on the basis of provided prompt
     * @summary Push To S3
     * @param {string} [modelId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LLMModelApi
     */
    public pushToS3V0(modelId?: string, options?: AxiosRequestConfig) {
        return LLMModelApiFp(this.configuration).pushToS3V0(modelId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LLMTrainingApi - axios parameter creator
 * @export
 */
export const LLMTrainingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Applies the provided lora_path to the already loaded model. NOTE: Model should be compatible with LoRA specified Args:     :param lora_path : path of LoRA to be applied on local FS Returns:     Response: Provides confirmation of Loaded Lora
         * @summary Apply Lora To Model
         * @param {string} [loraPath] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyLoraToModelV0LoraGet: async (loraPath?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chennai/v0/lora/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (loraPath !== undefined) {
                localVarQueryParameter['lora_path'] = loraPath;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a LoRA adapter on the basis of provided dataset of ideal inputs and outputs for the model. User can also choose to provide a test dataset for evaluation of newly created LoRA.         Args:             :param lora_name : path of LoRA to be applied on local FS             :param dataset_path_train : Dataset name for the train_data used in LoRA creation             :param dataset_path_test : Dataset for evaluation of LoRA upon creation.         Returns:             Response: Provides confirmation once training is completed
         * @summary Train Lora
         * @param {string} loraName 
         * @param {string} datasetPathTrain 
         * @param {string} [datasetPathTest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainLoraV0LoraTrainGet: async (loraName: string, datasetPathTrain: string, datasetPathTest?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loraName' is not null or undefined
            assertParamExists('trainLoraV0LoraTrainGet', 'loraName', loraName)
            // verify required parameter 'datasetPathTrain' is not null or undefined
            assertParamExists('trainLoraV0LoraTrainGet', 'datasetPathTrain', datasetPathTrain)
            const localVarPath = `/chennai/v0/lora/train`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (loraName !== undefined) {
                localVarQueryParameter['lora_name'] = loraName;
            }

            if (datasetPathTrain !== undefined) {
                localVarQueryParameter['dataset_path_train'] = datasetPathTrain;
            }

            if (datasetPathTest !== undefined) {
                localVarQueryParameter['dataset_path_test'] = datasetPathTest;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LLMTrainingApi - functional programming interface
 * @export
 */
export const LLMTrainingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LLMTrainingApiAxiosParamCreator(configuration)
    return {
        /**
         * Applies the provided lora_path to the already loaded model. NOTE: Model should be compatible with LoRA specified Args:     :param lora_path : path of LoRA to be applied on local FS Returns:     Response: Provides confirmation of Loaded Lora
         * @summary Apply Lora To Model
         * @param {string} [loraPath] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyLoraToModelV0LoraGet(loraPath?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyLoraToModelV0LoraGet(loraPath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a LoRA adapter on the basis of provided dataset of ideal inputs and outputs for the model. User can also choose to provide a test dataset for evaluation of newly created LoRA.         Args:             :param lora_name : path of LoRA to be applied on local FS             :param dataset_path_train : Dataset name for the train_data used in LoRA creation             :param dataset_path_test : Dataset for evaluation of LoRA upon creation.         Returns:             Response: Provides confirmation once training is completed
         * @summary Train Lora
         * @param {string} loraName 
         * @param {string} datasetPathTrain 
         * @param {string} [datasetPathTest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainLoraV0LoraTrainGet(loraName: string, datasetPathTrain: string, datasetPathTest?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trainLoraV0LoraTrainGet(loraName, datasetPathTrain, datasetPathTest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LLMTrainingApi - factory interface
 * @export
 */
export const LLMTrainingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LLMTrainingApiFp(configuration)
    return {
        /**
         * Applies the provided lora_path to the already loaded model. NOTE: Model should be compatible with LoRA specified Args:     :param lora_path : path of LoRA to be applied on local FS Returns:     Response: Provides confirmation of Loaded Lora
         * @summary Apply Lora To Model
         * @param {string} [loraPath] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyLoraToModelV0LoraGet(loraPath?: string, options?: any): AxiosPromise<any> {
            return localVarFp.applyLoraToModelV0LoraGet(loraPath, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a LoRA adapter on the basis of provided dataset of ideal inputs and outputs for the model. User can also choose to provide a test dataset for evaluation of newly created LoRA.         Args:             :param lora_name : path of LoRA to be applied on local FS             :param dataset_path_train : Dataset name for the train_data used in LoRA creation             :param dataset_path_test : Dataset for evaluation of LoRA upon creation.         Returns:             Response: Provides confirmation once training is completed
         * @summary Train Lora
         * @param {string} loraName 
         * @param {string} datasetPathTrain 
         * @param {string} [datasetPathTest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainLoraV0LoraTrainGet(loraName: string, datasetPathTrain: string, datasetPathTest?: string, options?: any): AxiosPromise<any> {
            return localVarFp.trainLoraV0LoraTrainGet(loraName, datasetPathTrain, datasetPathTest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LLMTrainingApi - object-oriented interface
 * @export
 * @class LLMTrainingApi
 * @extends {BaseAPI}
 */
export class LLMTrainingApi extends BaseAPI {
    /**
     * Applies the provided lora_path to the already loaded model. NOTE: Model should be compatible with LoRA specified Args:     :param lora_path : path of LoRA to be applied on local FS Returns:     Response: Provides confirmation of Loaded Lora
     * @summary Apply Lora To Model
     * @param {string} [loraPath] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LLMTrainingApi
     */
    public applyLoraToModelV0LoraGet(loraPath?: string, options?: AxiosRequestConfig) {
        return LLMTrainingApiFp(this.configuration).applyLoraToModelV0LoraGet(loraPath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a LoRA adapter on the basis of provided dataset of ideal inputs and outputs for the model. User can also choose to provide a test dataset for evaluation of newly created LoRA.         Args:             :param lora_name : path of LoRA to be applied on local FS             :param dataset_path_train : Dataset name for the train_data used in LoRA creation             :param dataset_path_test : Dataset for evaluation of LoRA upon creation.         Returns:             Response: Provides confirmation once training is completed
     * @summary Train Lora
     * @param {string} loraName 
     * @param {string} datasetPathTrain 
     * @param {string} [datasetPathTest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LLMTrainingApi
     */
    public trainLoraV0LoraTrainGet(loraName: string, datasetPathTrain: string, datasetPathTest?: string, options?: AxiosRequestConfig) {
        return LLMTrainingApiFp(this.configuration).trainLoraV0LoraTrainGet(loraName, datasetPathTrain, datasetPathTest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LayoutsRoutesApi - axios parameter creator
 * @export
 */
export const LayoutsRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get dataset comparison layout for a primary/seconday datasets pair if available. If no layout is saved, 404 will be raised.
         * @summary Get dataset comparison layout for a primary/seconday datasets pair if available
         * @param {string} workspaceId 
         * @param {string} primary 
         * @param {string} secondary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetComparisonLayoutV1: async (workspaceId: string, primary: string, secondary: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetComparisonLayoutV1', 'workspaceId', workspaceId)
            // verify required parameter 'primary' is not null or undefined
            assertParamExists('getDatasetComparisonLayoutV1', 'primary', primary)
            // verify required parameter 'secondary' is not null or undefined
            assertParamExists('getDatasetComparisonLayoutV1', 'secondary', secondary)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/comparison-layout`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (primary !== undefined) {
                localVarQueryParameter['primary'] = primary;
            }

            if (secondary !== undefined) {
                localVarQueryParameter['secondary'] = secondary;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Store dataset comparison layout for a primary/seconday datasets pair
         * @param {string} workspaceId 
         * @param {DatasetComparisonLayout} datasetComparisonLayout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeDatasetComparisonLayoutV1: async (workspaceId: string, datasetComparisonLayout: DatasetComparisonLayout, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('storeDatasetComparisonLayoutV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetComparisonLayout' is not null or undefined
            assertParamExists('storeDatasetComparisonLayoutV1', 'datasetComparisonLayout', datasetComparisonLayout)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/comparison-layout`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetComparisonLayout, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LayoutsRoutesApi - functional programming interface
 * @export
 */
export const LayoutsRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LayoutsRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get dataset comparison layout for a primary/seconday datasets pair if available. If no layout is saved, 404 will be raised.
         * @summary Get dataset comparison layout for a primary/seconday datasets pair if available
         * @param {string} workspaceId 
         * @param {string} primary 
         * @param {string} secondary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetComparisonLayoutV1(workspaceId: string, primary: string, secondary: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetComparisonLayoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetComparisonLayoutV1(workspaceId, primary, secondary, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Store dataset comparison layout for a primary/seconday datasets pair
         * @param {string} workspaceId 
         * @param {DatasetComparisonLayout} datasetComparisonLayout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeDatasetComparisonLayoutV1(workspaceId: string, datasetComparisonLayout: DatasetComparisonLayout, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeDatasetComparisonLayoutV1(workspaceId, datasetComparisonLayout, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LayoutsRoutesApi - factory interface
 * @export
 */
export const LayoutsRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LayoutsRoutesApiFp(configuration)
    return {
        /**
         * Get dataset comparison layout for a primary/seconday datasets pair if available. If no layout is saved, 404 will be raised.
         * @summary Get dataset comparison layout for a primary/seconday datasets pair if available
         * @param {string} workspaceId 
         * @param {string} primary 
         * @param {string} secondary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetComparisonLayoutV1(workspaceId: string, primary: string, secondary: string, options?: any): AxiosPromise<DatasetComparisonLayoutResponse> {
            return localVarFp.getDatasetComparisonLayoutV1(workspaceId, primary, secondary, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Store dataset comparison layout for a primary/seconday datasets pair
         * @param {string} workspaceId 
         * @param {DatasetComparisonLayout} datasetComparisonLayout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeDatasetComparisonLayoutV1(workspaceId: string, datasetComparisonLayout: DatasetComparisonLayout, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.storeDatasetComparisonLayoutV1(workspaceId, datasetComparisonLayout, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LayoutsRoutesApi - object-oriented interface
 * @export
 * @class LayoutsRoutesApi
 * @extends {BaseAPI}
 */
export class LayoutsRoutesApi extends BaseAPI {
    /**
     * Get dataset comparison layout for a primary/seconday datasets pair if available. If no layout is saved, 404 will be raised.
     * @summary Get dataset comparison layout for a primary/seconday datasets pair if available
     * @param {string} workspaceId 
     * @param {string} primary 
     * @param {string} secondary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsRoutesApi
     */
    public getDatasetComparisonLayoutV1(workspaceId: string, primary: string, secondary: string, options?: AxiosRequestConfig) {
        return LayoutsRoutesApiFp(this.configuration).getDatasetComparisonLayoutV1(workspaceId, primary, secondary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Store dataset comparison layout for a primary/seconday datasets pair
     * @param {string} workspaceId 
     * @param {DatasetComparisonLayout} datasetComparisonLayout 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutsRoutesApi
     */
    public storeDatasetComparisonLayoutV1(workspaceId: string, datasetComparisonLayout: DatasetComparisonLayout, options?: AxiosRequestConfig) {
        return LayoutsRoutesApiFp(this.configuration).storeDatasetComparisonLayoutV1(workspaceId, datasetComparisonLayout, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManualRelabelingRoutesApi - axios parameter creator
 * @export
 */
export const ManualRelabelingRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lists recommendation for a dataset.
         * @summary Lists mislabeled records for a dataset. If available, a recommended label would be shared by the system.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {Array<string>} [targetClasses] 
         * @param {Array<SegmentType>} [segments] 
         * @param {boolean} [isResolved] 
         * @param {boolean} [isAssigned] 
         * @param {Array<string>} [assignedTo] 
         * @param {Array<string>} [resolvedBy] 
         * @param {SortOrder} [sortOrder] 
         * @param {boolean} [onlyMislabeled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManualRelabelingRecordsV1: async (workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, targetClasses?: Array<string>, segments?: Array<SegmentType>, isResolved?: boolean, isAssigned?: boolean, assignedTo?: Array<string>, resolvedBy?: Array<string>, sortOrder?: SortOrder, onlyMislabeled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getManualRelabelingRecordsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getManualRelabelingRecordsV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('getManualRelabelingRecordsV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/manual-relabeling/{data_operation_id}/rows`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (targetClasses) {
                localVarQueryParameter['target_classes'] = targetClasses;
            }

            if (segments) {
                localVarQueryParameter['segments'] = segments;
            }

            if (isResolved !== undefined) {
                localVarQueryParameter['is_resolved'] = isResolved;
            }

            if (isAssigned !== undefined) {
                localVarQueryParameter['is_assigned'] = isAssigned;
            }

            if (assignedTo) {
                localVarQueryParameter['assigned_to'] = assignedTo;
            }

            if (resolvedBy) {
                localVarQueryParameter['resolved_by'] = resolvedBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort_order'] = sortOrder;
            }

            if (onlyMislabeled !== undefined) {
                localVarQueryParameter['only_mislabeled'] = onlyMislabeled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary of relabeling workflow for a dataset.
         * @summary Get summary of relabeling workflow for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManualRelabelingSummaryV1: async (workspaceId: string, datasetId: string, dataOperationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getManualRelabelingSummaryV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getManualRelabelingSummaryV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('getManualRelabelingSummaryV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/manual-relabeling/{data_operation_id}/summary`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accept recommendations i.e set resolved_label to predicted_label.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRelabelingAcceptRecommendationsV1: async (workspaceId: string, datasetId: string, dataOperationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('manualRelabelingAcceptRecommendationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('manualRelabelingAcceptRecommendationsV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('manualRelabelingAcceptRecommendationsV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/manual-relabeling/{data_operation_id}/accept-recommendations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds to the list of possible labels that can be applied
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {AddRelabelingLabelsRequest} addRelabelingLabelsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRelabelingAddLabelsV1: async (workspaceId: string, datasetId: string, dataOperationId: string, addRelabelingLabelsRequest: AddRelabelingLabelsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('manualRelabelingAddLabelsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('manualRelabelingAddLabelsV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('manualRelabelingAddLabelsV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'addRelabelingLabelsRequest' is not null or undefined
            assertParamExists('manualRelabelingAddLabelsV1', 'addRelabelingLabelsRequest', addRelabelingLabelsRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/manual-relabeling/{data_operation_id}/labels`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addRelabelingLabelsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of applied labels
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRelabelingAppliedLabelsV1: async (workspaceId: string, datasetId: string, dataOperationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('manualRelabelingAppliedLabelsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('manualRelabelingAppliedLabelsV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('manualRelabelingAppliedLabelsV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/manual-relabeling/{data_operation_id}/applied-labels`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign mislabeled data records to relabeling  with assignee
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {AssignUserToRelabelingRequest} assignUserToRelabelingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRelabelingAssignUserV1: async (workspaceId: string, datasetId: string, dataOperationId: string, assignUserToRelabelingRequest: AssignUserToRelabelingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('manualRelabelingAssignUserV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('manualRelabelingAssignUserV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('manualRelabelingAssignUserV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'assignUserToRelabelingRequest' is not null or undefined
            assertParamExists('manualRelabelingAssignUserV1', 'assignUserToRelabelingRequest', assignUserToRelabelingRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/manual-relabeling/{data_operation_id}/assign`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignUserToRelabelingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new version of dataset with resolved recommendations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRelabelingCreateNewVersionDatasetV1: async (workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('manualRelabelingCreateNewVersionDatasetV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('manualRelabelingCreateNewVersionDatasetV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('manualRelabelingCreateNewVersionDatasetV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'createNewVersionDatasetRequest' is not null or undefined
            assertParamExists('manualRelabelingCreateNewVersionDatasetV1', 'createNewVersionDatasetRequest', createNewVersionDatasetRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/manual-relabeling/{data_operation_id}/register-new-version`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNewVersionDatasetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of dataset relabeling contributors
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRelabelingGetContributorsV1: async (workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('manualRelabelingGetContributorsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('manualRelabelingGetContributorsV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('manualRelabelingGetContributorsV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/manual-relabeling/{data_operation_id}/contributors`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update fields of recommendations i.e resolved_label using this api.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {ResolveMislabeledRecordsRequest} resolveMislabeledRecordsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRelabelingResolveV1: async (workspaceId: string, datasetId: string, dataOperationId: string, resolveMislabeledRecordsRequest: ResolveMislabeledRecordsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('manualRelabelingResolveV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('manualRelabelingResolveV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('manualRelabelingResolveV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'resolveMislabeledRecordsRequest' is not null or undefined
            assertParamExists('manualRelabelingResolveV1', 'resolveMislabeledRecordsRequest', resolveMislabeledRecordsRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/manual-relabeling/{data_operation_id}/resolve`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resolveMislabeledRecordsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManualRelabelingRoutesApi - functional programming interface
 * @export
 */
export const ManualRelabelingRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManualRelabelingRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Lists recommendation for a dataset.
         * @summary Lists mislabeled records for a dataset. If available, a recommended label would be shared by the system.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {Array<string>} [targetClasses] 
         * @param {Array<SegmentType>} [segments] 
         * @param {boolean} [isResolved] 
         * @param {boolean} [isAssigned] 
         * @param {Array<string>} [assignedTo] 
         * @param {Array<string>} [resolvedBy] 
         * @param {SortOrder} [sortOrder] 
         * @param {boolean} [onlyMislabeled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManualRelabelingRecordsV1(workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, targetClasses?: Array<string>, segments?: Array<SegmentType>, isResolved?: boolean, isAssigned?: boolean, assignedTo?: Array<string>, resolvedBy?: Array<string>, sortOrder?: SortOrder, onlyMislabeled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMislabeledRecordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManualRelabelingRecordsV1(workspaceId, datasetId, dataOperationId, start, end, targetClasses, segments, isResolved, isAssigned, assignedTo, resolvedBy, sortOrder, onlyMislabeled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get summary of relabeling workflow for a dataset.
         * @summary Get summary of relabeling workflow for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManualRelabelingSummaryV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelabelingSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManualRelabelingSummaryV1(workspaceId, datasetId, dataOperationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Accept recommendations i.e set resolved_label to predicted_label.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualRelabelingAcceptRecommendationsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptAllRecommendationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualRelabelingAcceptRecommendationsV1(workspaceId, datasetId, dataOperationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds to the list of possible labels that can be applied
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {AddRelabelingLabelsRequest} addRelabelingLabelsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualRelabelingAddLabelsV1(workspaceId: string, datasetId: string, dataOperationId: string, addRelabelingLabelsRequest: AddRelabelingLabelsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualRelabelingAddLabelsV1(workspaceId, datasetId, dataOperationId, addRelabelingLabelsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of applied labels
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualRelabelingAppliedLabelsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelabelingAppliedLabelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualRelabelingAppliedLabelsV1(workspaceId, datasetId, dataOperationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign mislabeled data records to relabeling  with assignee
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {AssignUserToRelabelingRequest} assignUserToRelabelingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualRelabelingAssignUserV1(workspaceId: string, datasetId: string, dataOperationId: string, assignUserToRelabelingRequest: AssignUserToRelabelingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignUserToRelabelingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualRelabelingAssignUserV1(workspaceId, datasetId, dataOperationId, assignUserToRelabelingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new version of dataset with resolved recommendations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualRelabelingCreateNewVersionDatasetV1(workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNewVersionDatasetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualRelabelingCreateNewVersionDatasetV1(workspaceId, datasetId, dataOperationId, createNewVersionDatasetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of dataset relabeling contributors
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualRelabelingGetContributorsV1(workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelabelingContributorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualRelabelingGetContributorsV1(workspaceId, datasetId, dataOperationId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update fields of recommendations i.e resolved_label using this api.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {ResolveMislabeledRecordsRequest} resolveMislabeledRecordsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualRelabelingResolveV1(workspaceId: string, datasetId: string, dataOperationId: string, resolveMislabeledRecordsRequest: ResolveMislabeledRecordsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResolveMislabeledRecordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualRelabelingResolveV1(workspaceId, datasetId, dataOperationId, resolveMislabeledRecordsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManualRelabelingRoutesApi - factory interface
 * @export
 */
export const ManualRelabelingRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManualRelabelingRoutesApiFp(configuration)
    return {
        /**
         * Lists recommendation for a dataset.
         * @summary Lists mislabeled records for a dataset. If available, a recommended label would be shared by the system.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {Array<string>} [targetClasses] 
         * @param {Array<SegmentType>} [segments] 
         * @param {boolean} [isResolved] 
         * @param {boolean} [isAssigned] 
         * @param {Array<string>} [assignedTo] 
         * @param {Array<string>} [resolvedBy] 
         * @param {SortOrder} [sortOrder] 
         * @param {boolean} [onlyMislabeled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManualRelabelingRecordsV1(workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, targetClasses?: Array<string>, segments?: Array<SegmentType>, isResolved?: boolean, isAssigned?: boolean, assignedTo?: Array<string>, resolvedBy?: Array<string>, sortOrder?: SortOrder, onlyMislabeled?: boolean, options?: any): AxiosPromise<ListMislabeledRecordsResponse> {
            return localVarFp.getManualRelabelingRecordsV1(workspaceId, datasetId, dataOperationId, start, end, targetClasses, segments, isResolved, isAssigned, assignedTo, resolvedBy, sortOrder, onlyMislabeled, options).then((request) => request(axios, basePath));
        },
        /**
         * Get summary of relabeling workflow for a dataset.
         * @summary Get summary of relabeling workflow for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManualRelabelingSummaryV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: any): AxiosPromise<GetRelabelingSummaryResponse> {
            return localVarFp.getManualRelabelingSummaryV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accept recommendations i.e set resolved_label to predicted_label.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRelabelingAcceptRecommendationsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: any): AxiosPromise<AcceptAllRecommendationsResponse> {
            return localVarFp.manualRelabelingAcceptRecommendationsV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds to the list of possible labels that can be applied
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {AddRelabelingLabelsRequest} addRelabelingLabelsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRelabelingAddLabelsV1(workspaceId: string, datasetId: string, dataOperationId: string, addRelabelingLabelsRequest: AddRelabelingLabelsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.manualRelabelingAddLabelsV1(workspaceId, datasetId, dataOperationId, addRelabelingLabelsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of applied labels
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRelabelingAppliedLabelsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: any): AxiosPromise<GetRelabelingAppliedLabelsResponse> {
            return localVarFp.manualRelabelingAppliedLabelsV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign mislabeled data records to relabeling  with assignee
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {AssignUserToRelabelingRequest} assignUserToRelabelingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRelabelingAssignUserV1(workspaceId: string, datasetId: string, dataOperationId: string, assignUserToRelabelingRequest: AssignUserToRelabelingRequest, options?: any): AxiosPromise<AssignUserToRelabelingResponse> {
            return localVarFp.manualRelabelingAssignUserV1(workspaceId, datasetId, dataOperationId, assignUserToRelabelingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new version of dataset with resolved recommendations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRelabelingCreateNewVersionDatasetV1(workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options?: any): AxiosPromise<CreateNewVersionDatasetResponse> {
            return localVarFp.manualRelabelingCreateNewVersionDatasetV1(workspaceId, datasetId, dataOperationId, createNewVersionDatasetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of dataset relabeling contributors
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRelabelingGetContributorsV1(workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, options?: any): AxiosPromise<GetRelabelingContributorsResponse> {
            return localVarFp.manualRelabelingGetContributorsV1(workspaceId, datasetId, dataOperationId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update fields of recommendations i.e resolved_label using this api.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {ResolveMislabeledRecordsRequest} resolveMislabeledRecordsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRelabelingResolveV1(workspaceId: string, datasetId: string, dataOperationId: string, resolveMislabeledRecordsRequest: ResolveMislabeledRecordsRequest, options?: any): AxiosPromise<ResolveMislabeledRecordsResponse> {
            return localVarFp.manualRelabelingResolveV1(workspaceId, datasetId, dataOperationId, resolveMislabeledRecordsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManualRelabelingRoutesApi - object-oriented interface
 * @export
 * @class ManualRelabelingRoutesApi
 * @extends {BaseAPI}
 */
export class ManualRelabelingRoutesApi extends BaseAPI {
    /**
     * Lists recommendation for a dataset.
     * @summary Lists mislabeled records for a dataset. If available, a recommended label would be shared by the system.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {Array<string>} [targetClasses] 
     * @param {Array<SegmentType>} [segments] 
     * @param {boolean} [isResolved] 
     * @param {boolean} [isAssigned] 
     * @param {Array<string>} [assignedTo] 
     * @param {Array<string>} [resolvedBy] 
     * @param {SortOrder} [sortOrder] 
     * @param {boolean} [onlyMislabeled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManualRelabelingRoutesApi
     */
    public getManualRelabelingRecordsV1(workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, targetClasses?: Array<string>, segments?: Array<SegmentType>, isResolved?: boolean, isAssigned?: boolean, assignedTo?: Array<string>, resolvedBy?: Array<string>, sortOrder?: SortOrder, onlyMislabeled?: boolean, options?: AxiosRequestConfig) {
        return ManualRelabelingRoutesApiFp(this.configuration).getManualRelabelingRecordsV1(workspaceId, datasetId, dataOperationId, start, end, targetClasses, segments, isResolved, isAssigned, assignedTo, resolvedBy, sortOrder, onlyMislabeled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get summary of relabeling workflow for a dataset.
     * @summary Get summary of relabeling workflow for a dataset.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManualRelabelingRoutesApi
     */
    public getManualRelabelingSummaryV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig) {
        return ManualRelabelingRoutesApiFp(this.configuration).getManualRelabelingSummaryV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accept recommendations i.e set resolved_label to predicted_label.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManualRelabelingRoutesApi
     */
    public manualRelabelingAcceptRecommendationsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig) {
        return ManualRelabelingRoutesApiFp(this.configuration).manualRelabelingAcceptRecommendationsV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds to the list of possible labels that can be applied
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {AddRelabelingLabelsRequest} addRelabelingLabelsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManualRelabelingRoutesApi
     */
    public manualRelabelingAddLabelsV1(workspaceId: string, datasetId: string, dataOperationId: string, addRelabelingLabelsRequest: AddRelabelingLabelsRequest, options?: AxiosRequestConfig) {
        return ManualRelabelingRoutesApiFp(this.configuration).manualRelabelingAddLabelsV1(workspaceId, datasetId, dataOperationId, addRelabelingLabelsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of applied labels
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManualRelabelingRoutesApi
     */
    public manualRelabelingAppliedLabelsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig) {
        return ManualRelabelingRoutesApiFp(this.configuration).manualRelabelingAppliedLabelsV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign mislabeled data records to relabeling  with assignee
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {AssignUserToRelabelingRequest} assignUserToRelabelingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManualRelabelingRoutesApi
     */
    public manualRelabelingAssignUserV1(workspaceId: string, datasetId: string, dataOperationId: string, assignUserToRelabelingRequest: AssignUserToRelabelingRequest, options?: AxiosRequestConfig) {
        return ManualRelabelingRoutesApiFp(this.configuration).manualRelabelingAssignUserV1(workspaceId, datasetId, dataOperationId, assignUserToRelabelingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new version of dataset with resolved recommendations
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManualRelabelingRoutesApi
     */
    public manualRelabelingCreateNewVersionDatasetV1(workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options?: AxiosRequestConfig) {
        return ManualRelabelingRoutesApiFp(this.configuration).manualRelabelingCreateNewVersionDatasetV1(workspaceId, datasetId, dataOperationId, createNewVersionDatasetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of dataset relabeling contributors
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManualRelabelingRoutesApi
     */
    public manualRelabelingGetContributorsV1(workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, options?: AxiosRequestConfig) {
        return ManualRelabelingRoutesApiFp(this.configuration).manualRelabelingGetContributorsV1(workspaceId, datasetId, dataOperationId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update fields of recommendations i.e resolved_label using this api.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {ResolveMislabeledRecordsRequest} resolveMislabeledRecordsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManualRelabelingRoutesApi
     */
    public manualRelabelingResolveV1(workspaceId: string, datasetId: string, dataOperationId: string, resolveMislabeledRecordsRequest: ResolveMislabeledRecordsRequest, options?: AxiosRequestConfig) {
        return ManualRelabelingRoutesApiFp(this.configuration).manualRelabelingResolveV1(workspaceId, datasetId, dataOperationId, resolveMislabeledRecordsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MislabeledDatasetResolutionRoutesApi - axios parameter creator
 * @export
 */
export const MislabeledDatasetResolutionRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accept recommendations i.e set resolved_label to predicted_label.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        acceptRecommendationsV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('acceptRecommendationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('acceptRecommendationsV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/relabeling/accept-recommendations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign mislabeled data records to relabeling  with assignee
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {AssignUserToRelabelingRequest} assignUserToRelabelingRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        assignUserToRelabelingV1: async (workspaceId: string, datasetId: string, assignUserToRelabelingRequest: AssignUserToRelabelingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('assignUserToRelabelingV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('assignUserToRelabelingV1', 'datasetId', datasetId)
            // verify required parameter 'assignUserToRelabelingRequest' is not null or undefined
            assertParamExists('assignUserToRelabelingV1', 'assignUserToRelabelingRequest', assignUserToRelabelingRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/relabeling/assign`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignUserToRelabelingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new version of dataset with resolved recommendations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createNewVersionDatasetV1: async (workspaceId: string, datasetId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createNewVersionDatasetV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('createNewVersionDatasetV1', 'datasetId', datasetId)
            // verify required parameter 'createNewVersionDatasetRequest' is not null or undefined
            assertParamExists('createNewVersionDatasetV1', 'createNewVersionDatasetRequest', createNewVersionDatasetRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/register-new-version`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNewVersionDatasetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get status of relabeling workbench preparation for a dataset.
         * @summary Get status of relabeling workbench preparation for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDataRelabelStatusV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataRelabelStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDataRelabelStatusV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/relabeling/prepare-status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of dataset relabeling contributors
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDatasetRelabelingContributorsV1: async (workspaceId: string, datasetId: string, start?: number, end?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetRelabelingContributorsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDatasetRelabelingContributorsV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/relabeling/contributors`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists recommendation for a dataset.
         * @summary Lists mislabeled records for a dataset. Recomendation is the suggested label by the system for low quality labels.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {Array<string>} [targetClasses] 
         * @param {Array<SegmentType>} [segments] 
         * @param {boolean} [isResolved] 
         * @param {boolean} [isAssigned] 
         * @param {Array<string>} [assignedTo] 
         * @param {Array<string>} [resolvedBy] 
         * @param {SortOrder} [sortOrder] 
         * @param {boolean} [onlyMislabeled] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMislabeledRecordsV1: async (workspaceId: string, datasetId: string, start?: number, end?: number, targetClasses?: Array<string>, segments?: Array<SegmentType>, isResolved?: boolean, isAssigned?: boolean, assignedTo?: Array<string>, resolvedBy?: Array<string>, sortOrder?: SortOrder, onlyMislabeled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getMislabeledRecordsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getMislabeledRecordsV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/relabeling/mislabeled`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (targetClasses) {
                localVarQueryParameter['target_classes'] = targetClasses;
            }

            if (segments) {
                localVarQueryParameter['segments'] = segments;
            }

            if (isResolved !== undefined) {
                localVarQueryParameter['is_resolved'] = isResolved;
            }

            if (isAssigned !== undefined) {
                localVarQueryParameter['is_assigned'] = isAssigned;
            }

            if (assignedTo) {
                localVarQueryParameter['assigned_to'] = assignedTo;
            }

            if (resolvedBy) {
                localVarQueryParameter['resolved_by'] = resolvedBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort_order'] = sortOrder;
            }

            if (onlyMislabeled !== undefined) {
                localVarQueryParameter['only_mislabeled'] = onlyMislabeled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary of relabeling workflow for a dataset.
         * @summary Get summary of relabeling workflow for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRelabelingSummaryV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getRelabelingSummaryV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getRelabelingSummaryV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/relabeling/summary`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Move data from cold to hot storage so user can start resolving points.
         * @summary Prepare the relabeling workbench.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        prepareDataRelabelingWorkbenchV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('prepareDataRelabelingWorkbenchV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('prepareDataRelabelingWorkbenchV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/relabeling/prepare`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update fields of recommendations i.e resolved_label using this api.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {ResolveMislabeledRecordsRequest} resolveMislabeledRecordsRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateRecommendationsFieldsV1: async (workspaceId: string, datasetId: string, resolveMislabeledRecordsRequest: ResolveMislabeledRecordsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateRecommendationsFieldsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('updateRecommendationsFieldsV1', 'datasetId', datasetId)
            // verify required parameter 'resolveMislabeledRecordsRequest' is not null or undefined
            assertParamExists('updateRecommendationsFieldsV1', 'resolveMislabeledRecordsRequest', resolveMislabeledRecordsRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/relabeling/resolve`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resolveMislabeledRecordsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MislabeledDatasetResolutionRoutesApi - functional programming interface
 * @export
 */
export const MislabeledDatasetResolutionRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MislabeledDatasetResolutionRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Accept recommendations i.e set resolved_label to predicted_label.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async acceptRecommendationsV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptAllRecommendationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptRecommendationsV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign mislabeled data records to relabeling  with assignee
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {AssignUserToRelabelingRequest} assignUserToRelabelingRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async assignUserToRelabelingV1(workspaceId: string, datasetId: string, assignUserToRelabelingRequest: AssignUserToRelabelingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignUserToRelabelingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignUserToRelabelingV1(workspaceId, datasetId, assignUserToRelabelingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new version of dataset with resolved recommendations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createNewVersionDatasetV1(workspaceId: string, datasetId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNewVersionDatasetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewVersionDatasetV1(workspaceId, datasetId, createNewVersionDatasetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get status of relabeling workbench preparation for a dataset.
         * @summary Get status of relabeling workbench preparation for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDataRelabelStatusV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelabelingWorkbenchStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataRelabelStatusV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of dataset relabeling contributors
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDatasetRelabelingContributorsV1(workspaceId: string, datasetId: string, start?: number, end?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelabelingContributorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetRelabelingContributorsV1(workspaceId, datasetId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists recommendation for a dataset.
         * @summary Lists mislabeled records for a dataset. Recomendation is the suggested label by the system for low quality labels.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {Array<string>} [targetClasses] 
         * @param {Array<SegmentType>} [segments] 
         * @param {boolean} [isResolved] 
         * @param {boolean} [isAssigned] 
         * @param {Array<string>} [assignedTo] 
         * @param {Array<string>} [resolvedBy] 
         * @param {SortOrder} [sortOrder] 
         * @param {boolean} [onlyMislabeled] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getMislabeledRecordsV1(workspaceId: string, datasetId: string, start?: number, end?: number, targetClasses?: Array<string>, segments?: Array<SegmentType>, isResolved?: boolean, isAssigned?: boolean, assignedTo?: Array<string>, resolvedBy?: Array<string>, sortOrder?: SortOrder, onlyMislabeled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMislabeledRecordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMislabeledRecordsV1(workspaceId, datasetId, start, end, targetClasses, segments, isResolved, isAssigned, assignedTo, resolvedBy, sortOrder, onlyMislabeled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get summary of relabeling workflow for a dataset.
         * @summary Get summary of relabeling workflow for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getRelabelingSummaryV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelabelingSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelabelingSummaryV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Move data from cold to hot storage so user can start resolving points.
         * @summary Prepare the relabeling workbench.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async prepareDataRelabelingWorkbenchV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelabelingWorkbenchStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prepareDataRelabelingWorkbenchV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update fields of recommendations i.e resolved_label using this api.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {ResolveMislabeledRecordsRequest} resolveMislabeledRecordsRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateRecommendationsFieldsV1(workspaceId: string, datasetId: string, resolveMislabeledRecordsRequest: ResolveMislabeledRecordsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResolveMislabeledRecordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRecommendationsFieldsV1(workspaceId, datasetId, resolveMislabeledRecordsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MislabeledDatasetResolutionRoutesApi - factory interface
 * @export
 */
export const MislabeledDatasetResolutionRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MislabeledDatasetResolutionRoutesApiFp(configuration)
    return {
        /**
         * 
         * @summary Accept recommendations i.e set resolved_label to predicted_label.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        acceptRecommendationsV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<AcceptAllRecommendationsResponse> {
            return localVarFp.acceptRecommendationsV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign mislabeled data records to relabeling  with assignee
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {AssignUserToRelabelingRequest} assignUserToRelabelingRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        assignUserToRelabelingV1(workspaceId: string, datasetId: string, assignUserToRelabelingRequest: AssignUserToRelabelingRequest, options?: any): AxiosPromise<AssignUserToRelabelingResponse> {
            return localVarFp.assignUserToRelabelingV1(workspaceId, datasetId, assignUserToRelabelingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new version of dataset with resolved recommendations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createNewVersionDatasetV1(workspaceId: string, datasetId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options?: any): AxiosPromise<CreateNewVersionDatasetResponse> {
            return localVarFp.createNewVersionDatasetV1(workspaceId, datasetId, createNewVersionDatasetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get status of relabeling workbench preparation for a dataset.
         * @summary Get status of relabeling workbench preparation for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDataRelabelStatusV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<GetRelabelingWorkbenchStatusResponse> {
            return localVarFp.getDataRelabelStatusV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of dataset relabeling contributors
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDatasetRelabelingContributorsV1(workspaceId: string, datasetId: string, start?: number, end?: number, options?: any): AxiosPromise<GetRelabelingContributorsResponse> {
            return localVarFp.getDatasetRelabelingContributorsV1(workspaceId, datasetId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists recommendation for a dataset.
         * @summary Lists mislabeled records for a dataset. Recomendation is the suggested label by the system for low quality labels.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {Array<string>} [targetClasses] 
         * @param {Array<SegmentType>} [segments] 
         * @param {boolean} [isResolved] 
         * @param {boolean} [isAssigned] 
         * @param {Array<string>} [assignedTo] 
         * @param {Array<string>} [resolvedBy] 
         * @param {SortOrder} [sortOrder] 
         * @param {boolean} [onlyMislabeled] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMislabeledRecordsV1(workspaceId: string, datasetId: string, start?: number, end?: number, targetClasses?: Array<string>, segments?: Array<SegmentType>, isResolved?: boolean, isAssigned?: boolean, assignedTo?: Array<string>, resolvedBy?: Array<string>, sortOrder?: SortOrder, onlyMislabeled?: boolean, options?: any): AxiosPromise<ListMislabeledRecordsResponse> {
            return localVarFp.getMislabeledRecordsV1(workspaceId, datasetId, start, end, targetClasses, segments, isResolved, isAssigned, assignedTo, resolvedBy, sortOrder, onlyMislabeled, options).then((request) => request(axios, basePath));
        },
        /**
         * Get summary of relabeling workflow for a dataset.
         * @summary Get summary of relabeling workflow for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRelabelingSummaryV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<GetRelabelingSummaryResponse> {
            return localVarFp.getRelabelingSummaryV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Move data from cold to hot storage so user can start resolving points.
         * @summary Prepare the relabeling workbench.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        prepareDataRelabelingWorkbenchV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<GetRelabelingWorkbenchStatusResponse> {
            return localVarFp.prepareDataRelabelingWorkbenchV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update fields of recommendations i.e resolved_label using this api.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {ResolveMislabeledRecordsRequest} resolveMislabeledRecordsRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateRecommendationsFieldsV1(workspaceId: string, datasetId: string, resolveMislabeledRecordsRequest: ResolveMislabeledRecordsRequest, options?: any): AxiosPromise<ResolveMislabeledRecordsResponse> {
            return localVarFp.updateRecommendationsFieldsV1(workspaceId, datasetId, resolveMislabeledRecordsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MislabeledDatasetResolutionRoutesApi - object-oriented interface
 * @export
 * @class MislabeledDatasetResolutionRoutesApi
 * @extends {BaseAPI}
 */
export class MislabeledDatasetResolutionRoutesApi extends BaseAPI {
    /**
     * 
     * @summary Accept recommendations i.e set resolved_label to predicted_label.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MislabeledDatasetResolutionRoutesApi
     */
    public acceptRecommendationsV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return MislabeledDatasetResolutionRoutesApiFp(this.configuration).acceptRecommendationsV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign mislabeled data records to relabeling  with assignee
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {AssignUserToRelabelingRequest} assignUserToRelabelingRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MislabeledDatasetResolutionRoutesApi
     */
    public assignUserToRelabelingV1(workspaceId: string, datasetId: string, assignUserToRelabelingRequest: AssignUserToRelabelingRequest, options?: AxiosRequestConfig) {
        return MislabeledDatasetResolutionRoutesApiFp(this.configuration).assignUserToRelabelingV1(workspaceId, datasetId, assignUserToRelabelingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new version of dataset with resolved recommendations
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MislabeledDatasetResolutionRoutesApi
     */
    public createNewVersionDatasetV1(workspaceId: string, datasetId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options?: AxiosRequestConfig) {
        return MislabeledDatasetResolutionRoutesApiFp(this.configuration).createNewVersionDatasetV1(workspaceId, datasetId, createNewVersionDatasetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get status of relabeling workbench preparation for a dataset.
     * @summary Get status of relabeling workbench preparation for a dataset.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MislabeledDatasetResolutionRoutesApi
     */
    public getDataRelabelStatusV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return MislabeledDatasetResolutionRoutesApiFp(this.configuration).getDataRelabelStatusV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of dataset relabeling contributors
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MislabeledDatasetResolutionRoutesApi
     */
    public getDatasetRelabelingContributorsV1(workspaceId: string, datasetId: string, start?: number, end?: number, options?: AxiosRequestConfig) {
        return MislabeledDatasetResolutionRoutesApiFp(this.configuration).getDatasetRelabelingContributorsV1(workspaceId, datasetId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists recommendation for a dataset.
     * @summary Lists mislabeled records for a dataset. Recomendation is the suggested label by the system for low quality labels.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {Array<string>} [targetClasses] 
     * @param {Array<SegmentType>} [segments] 
     * @param {boolean} [isResolved] 
     * @param {boolean} [isAssigned] 
     * @param {Array<string>} [assignedTo] 
     * @param {Array<string>} [resolvedBy] 
     * @param {SortOrder} [sortOrder] 
     * @param {boolean} [onlyMislabeled] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MislabeledDatasetResolutionRoutesApi
     */
    public getMislabeledRecordsV1(workspaceId: string, datasetId: string, start?: number, end?: number, targetClasses?: Array<string>, segments?: Array<SegmentType>, isResolved?: boolean, isAssigned?: boolean, assignedTo?: Array<string>, resolvedBy?: Array<string>, sortOrder?: SortOrder, onlyMislabeled?: boolean, options?: AxiosRequestConfig) {
        return MislabeledDatasetResolutionRoutesApiFp(this.configuration).getMislabeledRecordsV1(workspaceId, datasetId, start, end, targetClasses, segments, isResolved, isAssigned, assignedTo, resolvedBy, sortOrder, onlyMislabeled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get summary of relabeling workflow for a dataset.
     * @summary Get summary of relabeling workflow for a dataset.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MislabeledDatasetResolutionRoutesApi
     */
    public getRelabelingSummaryV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return MislabeledDatasetResolutionRoutesApiFp(this.configuration).getRelabelingSummaryV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Move data from cold to hot storage so user can start resolving points.
     * @summary Prepare the relabeling workbench.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MislabeledDatasetResolutionRoutesApi
     */
    public prepareDataRelabelingWorkbenchV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return MislabeledDatasetResolutionRoutesApiFp(this.configuration).prepareDataRelabelingWorkbenchV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update fields of recommendations i.e resolved_label using this api.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {ResolveMislabeledRecordsRequest} resolveMislabeledRecordsRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MislabeledDatasetResolutionRoutesApi
     */
    public updateRecommendationsFieldsV1(workspaceId: string, datasetId: string, resolveMislabeledRecordsRequest: ResolveMislabeledRecordsRequest, options?: AxiosRequestConfig) {
        return MislabeledDatasetResolutionRoutesApiFp(this.configuration).updateRecommendationsFieldsV1(workspaceId, datasetId, resolveMislabeledRecordsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModelAppsAPIsApi - axios parameter creator
 * @export
 */
export const ModelAppsAPIsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a model app, either GenAI or classical
         * @param {string} workspaceId 
         * @param {CreateModelAppRequest} createModelAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createModelApp: async (workspaceId: string, createModelAppRequest: CreateModelAppRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createModelApp', 'workspaceId', workspaceId)
            // verify required parameter 'createModelAppRequest' is not null or undefined
            assertParamExists('createModelApp', 'createModelAppRequest', createModelAppRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/model-app/create`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createModelAppRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pending status means that the model app is pending on model training to start; Once the model is trained, the pending app would start
         * @summary Create a pending model app to start once the corresponding model is trained
         * @param {string} workspaceId 
         * @param {CreatePendingModelAppRequest} createPendingModelAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createModelAppPendingV1: async (workspaceId: string, createPendingModelAppRequest: CreatePendingModelAppRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createModelAppPendingV1', 'workspaceId', workspaceId)
            // verify required parameter 'createPendingModelAppRequest' is not null or undefined
            assertParamExists('createModelAppPendingV1', 'createPendingModelAppRequest', createPendingModelAppRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/models/pending-model-app`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPendingModelAppRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The API will return the model artifact binary after downloading it using the pre-signed url
         * @summary Download the model artifact by getting pre-signed url
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {string} modelArtifactFilename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadModelArtifactV1: async (workspaceId: string, modelId: string, modelArtifactFilename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('downloadModelArtifactV1', 'workspaceId', workspaceId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('downloadModelArtifactV1', 'modelId', modelId)
            // verify required parameter 'modelArtifactFilename' is not null or undefined
            assertParamExists('downloadModelArtifactV1', 'modelArtifactFilename', modelArtifactFilename)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/models/{model_id}/artifact/binary/{model_artifact_filename}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)))
                .replace(`{${"model_artifact_filename"}}`, encodeURIComponent(String(modelArtifactFilename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get file inference results
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {string} inferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileInferResultV1: async (workspaceId: string, modelId: string, inferenceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getFileInferResultV1', 'workspaceId', workspaceId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('getFileInferResultV1', 'modelId', modelId)
            // verify required parameter 'inferenceId' is not null or undefined
            assertParamExists('getFileInferResultV1', 'inferenceId', inferenceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/get-file-infer-result/{model_id}/{inference_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)))
                .replace(`{${"inference_id"}}`, encodeURIComponent(String(inferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will get the status of the inference operation (as a background process) using given model (this will status of latest inference operation)
         * @summary Get the result for the file inference started using this model
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {string} inferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileInferenceResultV2: async (workspaceId: string, modelId: string, inferenceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getFileInferenceResultV2', 'workspaceId', workspaceId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('getFileInferenceResultV2', 'modelId', modelId)
            // verify required parameter 'inferenceId' is not null or undefined
            assertParamExists('getFileInferenceResultV2', 'inferenceId', inferenceId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/model/{model_id}/file-inference-result/{inference_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)))
                .replace(`{${"inference_id"}}`, encodeURIComponent(String(inferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will get the status of the inference operation (as a background process) using given model (this will status of latest inference operation)
         * @summary Get the status for the file inference started using this model.
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {string} inferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileInferenceStatusV1: async (workspaceId: string, modelId: string, inferenceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getFileInferenceStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('getFileInferenceStatusV1', 'modelId', modelId)
            // verify required parameter 'inferenceId' is not null or undefined
            assertParamExists('getFileInferenceStatusV1', 'inferenceId', inferenceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/model/{model_id}/file-inference-status/{inference_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)))
                .replace(`{${"inference_id"}}`, encodeURIComponent(String(inferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Given Input get the model output
         * @summary Given Input get the model output
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {ModelInferenceRequest} modelInferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInferenceV2: async (workspaceId: string, modelId: string, modelInferenceRequest: ModelInferenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getInferenceV2', 'workspaceId', workspaceId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('getInferenceV2', 'modelId', modelId)
            // verify required parameter 'modelInferenceRequest' is not null or undefined
            assertParamExists('getInferenceV2', 'modelInferenceRequest', modelInferenceRequest)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/model/{model_id}/infer`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modelInferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Given Input get the model output
         * @summary Mocked response for baseline models along with explanation
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {ModelInferenceRequest} modelInferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInferenceV3: async (workspaceId: string, modelId: string, modelInferenceRequest: ModelInferenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getInferenceV3', 'workspaceId', workspaceId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('getInferenceV3', 'modelId', modelId)
            // verify required parameter 'modelInferenceRequest' is not null or undefined
            assertParamExists('getInferenceV3', 'modelInferenceRequest', modelInferenceRequest)
            const localVarPath = `/reno/v3/workspace/{workspace_id}/model/{model_id}/infer`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modelInferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the url info for uploading the input file
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputFileUriV1: async (workspaceId: string, modelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getInputFileUriV1', 'workspaceId', workspaceId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('getInputFileUriV1', 'modelId', modelId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/file-inference/{model_id}/input-file-url`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of the model app using model_app_id
         * @param {string} workspaceId 
         * @param {string} modelAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAppDetails: async (workspaceId: string, modelAppId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getModelAppDetails', 'workspaceId', workspaceId)
            // verify required parameter 'modelAppId' is not null or undefined
            assertParamExists('getModelAppDetails', 'modelAppId', modelAppId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/model-app/{model_app_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_app_id"}}`, encodeURIComponent(String(modelAppId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the inference using model app
         * @param {string} workspaceId 
         * @param {string} modelAppId 
         * @param {ModelAppInferenceRequest} modelAppInferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAppInferenceV1: async (workspaceId: string, modelAppId: string, modelAppInferenceRequest: ModelAppInferenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getModelAppInferenceV1', 'workspaceId', workspaceId)
            // verify required parameter 'modelAppId' is not null or undefined
            assertParamExists('getModelAppInferenceV1', 'modelAppId', modelAppId)
            // verify required parameter 'modelAppInferenceRequest' is not null or undefined
            assertParamExists('getModelAppInferenceV1', 'modelAppInferenceRequest', modelAppInferenceRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/model-app/{model_app_id}/infer`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_app_id"}}`, encodeURIComponent(String(modelAppId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modelAppInferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the model app metadata by fetching it through either model_id or dataset_id. At least one needsto be provided in the request otherwise 400 status code will be returned.
         * @summary Get model app metadata.
         * @param {string} workspaceId 
         * @param {string} [modelId] 
         * @param {string} [datasetId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAppMetadataV1: async (workspaceId: string, modelId?: string, datasetId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getModelAppMetadataV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/model-app-metadata`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (modelId !== undefined) {
                localVarQueryParameter['model_id'] = modelId;
            }

            if (datasetId !== undefined) {
                localVarQueryParameter['dataset_id'] = datasetId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the current workflow object for a model-app
         * @param {string} workspaceId 
         * @param {string} modelAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAppWorkflowStatus: async (workspaceId: string, modelAppId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getModelAppWorkflowStatus', 'workspaceId', workspaceId)
            // verify required parameter 'modelAppId' is not null or undefined
            assertParamExists('getModelAppWorkflowStatus', 'modelAppId', modelAppId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/model-app/{model_app_id}/workflow/status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_app_id"}}`, encodeURIComponent(String(modelAppId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of model apps.
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAppsListV1: async (workspaceId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getModelAppsListV1', 'workspaceId', workspaceId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getModelAppsListV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getModelAppsListV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/model-apps`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List the model apps based on given filters
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [dataviewId] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAppsListV2: async (workspaceId: string, start?: number, end?: number, dataviewId?: string, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getModelAppsListV2', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/model-apps`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (dataviewId !== undefined) {
                localVarQueryParameter['dataview_id'] = dataviewId;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * status inference service
         * @summary status inference service
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inferenceServiceStatusV1: async (workspaceId: string, modelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('inferenceServiceStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('inferenceServiceStatusV1', 'modelId', modelId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/model/{model_id}/inference-service-status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * start inference service
         * @summary start inference service
         * @param {string} workspaceId 
         * @param {InitInferenceRequest} initInferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialiseInferenceServiceV1: async (workspaceId: string, initInferenceRequest: InitInferenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('initialiseInferenceServiceV1', 'workspaceId', workspaceId)
            // verify required parameter 'initInferenceRequest' is not null or undefined
            assertParamExists('initialiseInferenceServiceV1', 'initInferenceRequest', initInferenceRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/models/start-inference`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initInferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will start the inference (as a background process) on given file using the given model
         * @summary Start the inference on given file using given model
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {StartFileInferenceRequest} startFileInferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startFileInferenceV1: async (workspaceId: string, modelId: string, startFileInferenceRequest: StartFileInferenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('startFileInferenceV1', 'workspaceId', workspaceId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('startFileInferenceV1', 'modelId', modelId)
            // verify required parameter 'startFileInferenceRequest' is not null or undefined
            assertParamExists('startFileInferenceV1', 'startFileInferenceRequest', startFileInferenceRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/model/{model_id}/start-file-inference`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startFileInferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger a workflow action on a model-app
         * @param {string} workspaceId 
         * @param {string} modelAppId 
         * @param {TriggerModelAppWorkflowRequest} triggerModelAppWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerModelAppWorkflow: async (workspaceId: string, modelAppId: string, triggerModelAppWorkflowRequest: TriggerModelAppWorkflowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('triggerModelAppWorkflow', 'workspaceId', workspaceId)
            // verify required parameter 'modelAppId' is not null or undefined
            assertParamExists('triggerModelAppWorkflow', 'modelAppId', modelAppId)
            // verify required parameter 'triggerModelAppWorkflowRequest' is not null or undefined
            assertParamExists('triggerModelAppWorkflow', 'triggerModelAppWorkflowRequest', triggerModelAppWorkflowRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/model-app/{model_app_id}/workflow/trigger`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_app_id"}}`, encodeURIComponent(String(modelAppId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(triggerModelAppWorkflowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelAppsAPIsApi - functional programming interface
 * @export
 */
export const ModelAppsAPIsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModelAppsAPIsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a model app, either GenAI or classical
         * @param {string} workspaceId 
         * @param {CreateModelAppRequest} createModelAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createModelApp(workspaceId: string, createModelAppRequest: CreateModelAppRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateModelAppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createModelApp(workspaceId, createModelAppRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Pending status means that the model app is pending on model training to start; Once the model is trained, the pending app would start
         * @summary Create a pending model app to start once the corresponding model is trained
         * @param {string} workspaceId 
         * @param {CreatePendingModelAppRequest} createPendingModelAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createModelAppPendingV1(workspaceId: string, createPendingModelAppRequest: CreatePendingModelAppRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePendingModelAppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createModelAppPendingV1(workspaceId, createPendingModelAppRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The API will return the model artifact binary after downloading it using the pre-signed url
         * @summary Download the model artifact by getting pre-signed url
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {string} modelArtifactFilename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadModelArtifactV1(workspaceId: string, modelId: string, modelArtifactFilename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadModelArtifactV1(workspaceId, modelId, modelArtifactFilename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get file inference results
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {string} inferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileInferResultV1(workspaceId: string, modelId: string, inferenceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFileInferenceResultsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileInferResultV1(workspaceId, modelId, inferenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will get the status of the inference operation (as a background process) using given model (this will status of latest inference operation)
         * @summary Get the result for the file inference started using this model
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {string} inferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileInferenceResultV2(workspaceId: string, modelId: string, inferenceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFileInferenceResultsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileInferenceResultV2(workspaceId, modelId, inferenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will get the status of the inference operation (as a background process) using given model (this will status of latest inference operation)
         * @summary Get the status for the file inference started using this model.
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {string} inferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileInferenceStatusV1(workspaceId: string, modelId: string, inferenceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFileInferenceStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileInferenceStatusV1(workspaceId, modelId, inferenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Given Input get the model output
         * @summary Given Input get the model output
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {ModelInferenceRequest} modelInferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInferenceV2(workspaceId: string, modelId: string, modelInferenceRequest: ModelInferenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParsedInferenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInferenceV2(workspaceId, modelId, modelInferenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Given Input get the model output
         * @summary Mocked response for baseline models along with explanation
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {ModelInferenceRequest} modelInferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInferenceV3(workspaceId: string, modelId: string, modelInferenceRequest: ModelInferenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParsedInferenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInferenceV3(workspaceId, modelId, modelInferenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the url info for uploading the input file
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInputFileUriV1(workspaceId: string, modelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInputFileUriResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInputFileUriV1(workspaceId, modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of the model app using model_app_id
         * @param {string} workspaceId 
         * @param {string} modelAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelAppDetails(workspaceId: string, modelAppId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelAppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelAppDetails(workspaceId, modelAppId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the inference using model app
         * @param {string} workspaceId 
         * @param {string} modelAppId 
         * @param {ModelAppInferenceRequest} modelAppInferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelAppInferenceV1(workspaceId: string, modelAppId: string, modelAppInferenceRequest: ModelAppInferenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiModelParsedInferenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelAppInferenceV1(workspaceId, modelAppId, modelAppInferenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the model app metadata by fetching it through either model_id or dataset_id. At least one needsto be provided in the request otherwise 400 status code will be returned.
         * @summary Get model app metadata.
         * @param {string} workspaceId 
         * @param {string} [modelId] 
         * @param {string} [datasetId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelAppMetadataV1(workspaceId: string, modelId?: string, datasetId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelWithAppMetadata>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelAppMetadataV1(workspaceId, modelId, datasetId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the current workflow object for a model-app
         * @param {string} workspaceId 
         * @param {string} modelAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelAppWorkflowStatus(workspaceId: string, modelAppId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAppWorkflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelAppWorkflowStatus(workspaceId, modelAppId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of model apps.
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelAppsListV1(workspaceId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelsWithAppMetadataPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelAppsListV1(workspaceId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List the model apps based on given filters
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [dataviewId] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelAppsListV2(workspaceId: string, start?: number, end?: number, dataviewId?: string, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListModelAppsPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelAppsListV2(workspaceId, start, end, dataviewId, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * status inference service
         * @summary status inference service
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inferenceServiceStatusV1(workspaceId: string, modelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InferenceServiceStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inferenceServiceStatusV1(workspaceId, modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * start inference service
         * @summary start inference service
         * @param {string} workspaceId 
         * @param {InitInferenceRequest} initInferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initialiseInferenceServiceV1(workspaceId: string, initInferenceRequest: InitInferenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitInferenceServiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initialiseInferenceServiceV1(workspaceId, initInferenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will start the inference (as a background process) on given file using the given model
         * @summary Start the inference on given file using given model
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {StartFileInferenceRequest} startFileInferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startFileInferenceV1(workspaceId: string, modelId: string, startFileInferenceRequest: StartFileInferenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartFileInferenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startFileInferenceV1(workspaceId, modelId, startFileInferenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Trigger a workflow action on a model-app
         * @param {string} workspaceId 
         * @param {string} modelAppId 
         * @param {TriggerModelAppWorkflowRequest} triggerModelAppWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerModelAppWorkflow(workspaceId: string, modelAppId: string, triggerModelAppWorkflowRequest: TriggerModelAppWorkflowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerModelAppWorkflowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerModelAppWorkflow(workspaceId, modelAppId, triggerModelAppWorkflowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModelAppsAPIsApi - factory interface
 * @export
 */
export const ModelAppsAPIsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModelAppsAPIsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a model app, either GenAI or classical
         * @param {string} workspaceId 
         * @param {CreateModelAppRequest} createModelAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createModelApp(workspaceId: string, createModelAppRequest: CreateModelAppRequest, options?: any): AxiosPromise<CreateModelAppResponse> {
            return localVarFp.createModelApp(workspaceId, createModelAppRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Pending status means that the model app is pending on model training to start; Once the model is trained, the pending app would start
         * @summary Create a pending model app to start once the corresponding model is trained
         * @param {string} workspaceId 
         * @param {CreatePendingModelAppRequest} createPendingModelAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createModelAppPendingV1(workspaceId: string, createPendingModelAppRequest: CreatePendingModelAppRequest, options?: any): AxiosPromise<CreatePendingModelAppResponse> {
            return localVarFp.createModelAppPendingV1(workspaceId, createPendingModelAppRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * The API will return the model artifact binary after downloading it using the pre-signed url
         * @summary Download the model artifact by getting pre-signed url
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {string} modelArtifactFilename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadModelArtifactV1(workspaceId: string, modelId: string, modelArtifactFilename: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadModelArtifactV1(workspaceId, modelId, modelArtifactFilename, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get file inference results
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {string} inferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileInferResultV1(workspaceId: string, modelId: string, inferenceId: string, options?: any): AxiosPromise<GetFileInferenceResultsResponse> {
            return localVarFp.getFileInferResultV1(workspaceId, modelId, inferenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will get the status of the inference operation (as a background process) using given model (this will status of latest inference operation)
         * @summary Get the result for the file inference started using this model
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {string} inferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileInferenceResultV2(workspaceId: string, modelId: string, inferenceId: string, options?: any): AxiosPromise<GetFileInferenceResultsResponse> {
            return localVarFp.getFileInferenceResultV2(workspaceId, modelId, inferenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will get the status of the inference operation (as a background process) using given model (this will status of latest inference operation)
         * @summary Get the status for the file inference started using this model.
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {string} inferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileInferenceStatusV1(workspaceId: string, modelId: string, inferenceId: string, options?: any): AxiosPromise<GetFileInferenceStatusResponse> {
            return localVarFp.getFileInferenceStatusV1(workspaceId, modelId, inferenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Given Input get the model output
         * @summary Given Input get the model output
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {ModelInferenceRequest} modelInferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInferenceV2(workspaceId: string, modelId: string, modelInferenceRequest: ModelInferenceRequest, options?: any): AxiosPromise<ParsedInferenceResponse> {
            return localVarFp.getInferenceV2(workspaceId, modelId, modelInferenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Given Input get the model output
         * @summary Mocked response for baseline models along with explanation
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {ModelInferenceRequest} modelInferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInferenceV3(workspaceId: string, modelId: string, modelInferenceRequest: ModelInferenceRequest, options?: any): AxiosPromise<ParsedInferenceResponse> {
            return localVarFp.getInferenceV3(workspaceId, modelId, modelInferenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the url info for uploading the input file
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputFileUriV1(workspaceId: string, modelId: string, options?: any): AxiosPromise<GetInputFileUriResponse> {
            return localVarFp.getInputFileUriV1(workspaceId, modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of the model app using model_app_id
         * @param {string} workspaceId 
         * @param {string} modelAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAppDetails(workspaceId: string, modelAppId: string, options?: any): AxiosPromise<GetModelAppResponse> {
            return localVarFp.getModelAppDetails(workspaceId, modelAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the inference using model app
         * @param {string} workspaceId 
         * @param {string} modelAppId 
         * @param {ModelAppInferenceRequest} modelAppInferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAppInferenceV1(workspaceId: string, modelAppId: string, modelAppInferenceRequest: ModelAppInferenceRequest, options?: any): AxiosPromise<MultiModelParsedInferenceResponse> {
            return localVarFp.getModelAppInferenceV1(workspaceId, modelAppId, modelAppInferenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the model app metadata by fetching it through either model_id or dataset_id. At least one needsto be provided in the request otherwise 400 status code will be returned.
         * @summary Get model app metadata.
         * @param {string} workspaceId 
         * @param {string} [modelId] 
         * @param {string} [datasetId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAppMetadataV1(workspaceId: string, modelId?: string, datasetId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<ModelWithAppMetadata> {
            return localVarFp.getModelAppMetadataV1(workspaceId, modelId, datasetId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the current workflow object for a model-app
         * @param {string} workspaceId 
         * @param {string} modelAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAppWorkflowStatus(workspaceId: string, modelAppId: string, options?: any): AxiosPromise<ModelAppWorkflow> {
            return localVarFp.getModelAppWorkflowStatus(workspaceId, modelAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of model apps.
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAppsListV1(workspaceId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<GetModelsWithAppMetadataPaginated> {
            return localVarFp.getModelAppsListV1(workspaceId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List the model apps based on given filters
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [dataviewId] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAppsListV2(workspaceId: string, start?: number, end?: number, dataviewId?: string, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<ListModelAppsPaginatedResponse> {
            return localVarFp.getModelAppsListV2(workspaceId, start, end, dataviewId, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * status inference service
         * @summary status inference service
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inferenceServiceStatusV1(workspaceId: string, modelId: string, options?: any): AxiosPromise<InferenceServiceStatusResponse> {
            return localVarFp.inferenceServiceStatusV1(workspaceId, modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * start inference service
         * @summary start inference service
         * @param {string} workspaceId 
         * @param {InitInferenceRequest} initInferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialiseInferenceServiceV1(workspaceId: string, initInferenceRequest: InitInferenceRequest, options?: any): AxiosPromise<InitInferenceServiceResponse> {
            return localVarFp.initialiseInferenceServiceV1(workspaceId, initInferenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will start the inference (as a background process) on given file using the given model
         * @summary Start the inference on given file using given model
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {StartFileInferenceRequest} startFileInferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startFileInferenceV1(workspaceId: string, modelId: string, startFileInferenceRequest: StartFileInferenceRequest, options?: any): AxiosPromise<StartFileInferenceResponse> {
            return localVarFp.startFileInferenceV1(workspaceId, modelId, startFileInferenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger a workflow action on a model-app
         * @param {string} workspaceId 
         * @param {string} modelAppId 
         * @param {TriggerModelAppWorkflowRequest} triggerModelAppWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerModelAppWorkflow(workspaceId: string, modelAppId: string, triggerModelAppWorkflowRequest: TriggerModelAppWorkflowRequest, options?: any): AxiosPromise<TriggerModelAppWorkflowResponse> {
            return localVarFp.triggerModelAppWorkflow(workspaceId, modelAppId, triggerModelAppWorkflowRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModelAppsAPIsApi - object-oriented interface
 * @export
 * @class ModelAppsAPIsApi
 * @extends {BaseAPI}
 */
export class ModelAppsAPIsApi extends BaseAPI {
    /**
     * 
     * @summary Create a model app, either GenAI or classical
     * @param {string} workspaceId 
     * @param {CreateModelAppRequest} createModelAppRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public createModelApp(workspaceId: string, createModelAppRequest: CreateModelAppRequest, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).createModelApp(workspaceId, createModelAppRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Pending status means that the model app is pending on model training to start; Once the model is trained, the pending app would start
     * @summary Create a pending model app to start once the corresponding model is trained
     * @param {string} workspaceId 
     * @param {CreatePendingModelAppRequest} createPendingModelAppRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public createModelAppPendingV1(workspaceId: string, createPendingModelAppRequest: CreatePendingModelAppRequest, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).createModelAppPendingV1(workspaceId, createPendingModelAppRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The API will return the model artifact binary after downloading it using the pre-signed url
     * @summary Download the model artifact by getting pre-signed url
     * @param {string} workspaceId 
     * @param {string} modelId 
     * @param {string} modelArtifactFilename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public downloadModelArtifactV1(workspaceId: string, modelId: string, modelArtifactFilename: string, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).downloadModelArtifactV1(workspaceId, modelId, modelArtifactFilename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get file inference results
     * @param {string} workspaceId 
     * @param {string} modelId 
     * @param {string} inferenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public getFileInferResultV1(workspaceId: string, modelId: string, inferenceId: string, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).getFileInferResultV1(workspaceId, modelId, inferenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will get the status of the inference operation (as a background process) using given model (this will status of latest inference operation)
     * @summary Get the result for the file inference started using this model
     * @param {string} workspaceId 
     * @param {string} modelId 
     * @param {string} inferenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public getFileInferenceResultV2(workspaceId: string, modelId: string, inferenceId: string, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).getFileInferenceResultV2(workspaceId, modelId, inferenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will get the status of the inference operation (as a background process) using given model (this will status of latest inference operation)
     * @summary Get the status for the file inference started using this model.
     * @param {string} workspaceId 
     * @param {string} modelId 
     * @param {string} inferenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public getFileInferenceStatusV1(workspaceId: string, modelId: string, inferenceId: string, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).getFileInferenceStatusV1(workspaceId, modelId, inferenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Given Input get the model output
     * @summary Given Input get the model output
     * @param {string} workspaceId 
     * @param {string} modelId 
     * @param {ModelInferenceRequest} modelInferenceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public getInferenceV2(workspaceId: string, modelId: string, modelInferenceRequest: ModelInferenceRequest, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).getInferenceV2(workspaceId, modelId, modelInferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Given Input get the model output
     * @summary Mocked response for baseline models along with explanation
     * @param {string} workspaceId 
     * @param {string} modelId 
     * @param {ModelInferenceRequest} modelInferenceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public getInferenceV3(workspaceId: string, modelId: string, modelInferenceRequest: ModelInferenceRequest, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).getInferenceV3(workspaceId, modelId, modelInferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the url info for uploading the input file
     * @param {string} workspaceId 
     * @param {string} modelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public getInputFileUriV1(workspaceId: string, modelId: string, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).getInputFileUriV1(workspaceId, modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of the model app using model_app_id
     * @param {string} workspaceId 
     * @param {string} modelAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public getModelAppDetails(workspaceId: string, modelAppId: string, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).getModelAppDetails(workspaceId, modelAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the inference using model app
     * @param {string} workspaceId 
     * @param {string} modelAppId 
     * @param {ModelAppInferenceRequest} modelAppInferenceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public getModelAppInferenceV1(workspaceId: string, modelAppId: string, modelAppInferenceRequest: ModelAppInferenceRequest, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).getModelAppInferenceV1(workspaceId, modelAppId, modelAppInferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the model app metadata by fetching it through either model_id or dataset_id. At least one needsto be provided in the request otherwise 400 status code will be returned.
     * @summary Get model app metadata.
     * @param {string} workspaceId 
     * @param {string} [modelId] 
     * @param {string} [datasetId] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public getModelAppMetadataV1(workspaceId: string, modelId?: string, datasetId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).getModelAppMetadataV1(workspaceId, modelId, datasetId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the current workflow object for a model-app
     * @param {string} workspaceId 
     * @param {string} modelAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public getModelAppWorkflowStatus(workspaceId: string, modelAppId: string, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).getModelAppWorkflowStatus(workspaceId, modelAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of model apps.
     * @param {string} workspaceId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public getModelAppsListV1(workspaceId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).getModelAppsListV1(workspaceId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List the model apps based on given filters
     * @param {string} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [dataviewId] 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public getModelAppsListV2(workspaceId: string, start?: number, end?: number, dataviewId?: string, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).getModelAppsListV2(workspaceId, start, end, dataviewId, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * status inference service
     * @summary status inference service
     * @param {string} workspaceId 
     * @param {string} modelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public inferenceServiceStatusV1(workspaceId: string, modelId: string, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).inferenceServiceStatusV1(workspaceId, modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * start inference service
     * @summary start inference service
     * @param {string} workspaceId 
     * @param {InitInferenceRequest} initInferenceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public initialiseInferenceServiceV1(workspaceId: string, initInferenceRequest: InitInferenceRequest, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).initialiseInferenceServiceV1(workspaceId, initInferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will start the inference (as a background process) on given file using the given model
     * @summary Start the inference on given file using given model
     * @param {string} workspaceId 
     * @param {string} modelId 
     * @param {StartFileInferenceRequest} startFileInferenceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public startFileInferenceV1(workspaceId: string, modelId: string, startFileInferenceRequest: StartFileInferenceRequest, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).startFileInferenceV1(workspaceId, modelId, startFileInferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trigger a workflow action on a model-app
     * @param {string} workspaceId 
     * @param {string} modelAppId 
     * @param {TriggerModelAppWorkflowRequest} triggerModelAppWorkflowRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAppsAPIsApi
     */
    public triggerModelAppWorkflow(workspaceId: string, modelAppId: string, triggerModelAppWorkflowRequest: TriggerModelAppWorkflowRequest, options?: AxiosRequestConfig) {
        return ModelAppsAPIsApiFp(this.configuration).triggerModelAppWorkflow(workspaceId, modelAppId, triggerModelAppWorkflowRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModelMonitoringApi - axios parameter creator
 * @export
 */
export const ModelMonitoringApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the chart data for given key and given experiment_ids
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckConceptDriftResponseV1: async (workspaceId: string, modelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getCheckConceptDriftResponseV1', 'workspaceId', workspaceId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('getCheckConceptDriftResponseV1', 'modelId', modelId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/model/{model_id}/monitoring/check-drift`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the chart data for given key and given experiment_ids
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConceptDriftChartResponseV1: async (workspaceId: string, modelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getConceptDriftChartResponseV1', 'workspaceId', workspaceId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('getConceptDriftChartResponseV1', 'modelId', modelId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/model/{model_id}/monitoring/concept-drift`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the model details for a given drift analysis
         * @param {string} workspaceId 
         * @param {string} dataDriftResultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataDriftModelDetailsV1: async (workspaceId: string, dataDriftResultId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataDriftModelDetailsV1', 'workspaceId', workspaceId)
            // verify required parameter 'dataDriftResultId' is not null or undefined
            assertParamExists('getDataDriftModelDetailsV1', 'dataDriftResultId', dataDriftResultId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/data-drift/{data_drift_result_id}/model-details`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"data_drift_result_id"}}`, encodeURIComponent(String(dataDriftResultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the overview of Data Drift Result for given data_drift_result_id
         * @param {string} workspaceId 
         * @param {string} dataDriftResultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataDriftOverviewV1: async (workspaceId: string, dataDriftResultId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataDriftOverviewV1', 'workspaceId', workspaceId)
            // verify required parameter 'dataDriftResultId' is not null or undefined
            assertParamExists('getDataDriftOverviewV1', 'dataDriftResultId', dataDriftResultId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/data-drift/{data_drift_result_id}/drift-overview`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"data_drift_result_id"}}`, encodeURIComponent(String(dataDriftResultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelMonitoringApi - functional programming interface
 * @export
 */
export const ModelMonitoringApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModelMonitoringApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the chart data for given key and given experiment_ids
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCheckConceptDriftResponseV1(workspaceId: string, modelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelMonitoringCheckDrift>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCheckConceptDriftResponseV1(workspaceId, modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the chart data for given key and given experiment_ids
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConceptDriftChartResponseV1(workspaceId: string, modelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelMonitoringConceptDriftResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConceptDriftChartResponseV1(workspaceId, modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the model details for a given drift analysis
         * @param {string} workspaceId 
         * @param {string} dataDriftResultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataDriftModelDetailsV1(workspaceId: string, dataDriftResultId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataDriftModelDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataDriftModelDetailsV1(workspaceId, dataDriftResultId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the overview of Data Drift Result for given data_drift_result_id
         * @param {string} workspaceId 
         * @param {string} dataDriftResultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataDriftOverviewV1(workspaceId: string, dataDriftResultId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataDriftOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataDriftOverviewV1(workspaceId, dataDriftResultId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModelMonitoringApi - factory interface
 * @export
 */
export const ModelMonitoringApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModelMonitoringApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the chart data for given key and given experiment_ids
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckConceptDriftResponseV1(workspaceId: string, modelId: string, options?: any): AxiosPromise<ModelMonitoringCheckDrift> {
            return localVarFp.getCheckConceptDriftResponseV1(workspaceId, modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the chart data for given key and given experiment_ids
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConceptDriftChartResponseV1(workspaceId: string, modelId: string, options?: any): AxiosPromise<ModelMonitoringConceptDriftResponse> {
            return localVarFp.getConceptDriftChartResponseV1(workspaceId, modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the model details for a given drift analysis
         * @param {string} workspaceId 
         * @param {string} dataDriftResultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataDriftModelDetailsV1(workspaceId: string, dataDriftResultId: string, options?: any): AxiosPromise<DataDriftModelDetailsResponse> {
            return localVarFp.getDataDriftModelDetailsV1(workspaceId, dataDriftResultId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the overview of Data Drift Result for given data_drift_result_id
         * @param {string} workspaceId 
         * @param {string} dataDriftResultId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataDriftOverviewV1(workspaceId: string, dataDriftResultId: string, options?: any): AxiosPromise<DataDriftOverviewResponse> {
            return localVarFp.getDataDriftOverviewV1(workspaceId, dataDriftResultId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModelMonitoringApi - object-oriented interface
 * @export
 * @class ModelMonitoringApi
 * @extends {BaseAPI}
 */
export class ModelMonitoringApi extends BaseAPI {
    /**
     * 
     * @summary Get the chart data for given key and given experiment_ids
     * @param {string} workspaceId 
     * @param {string} modelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelMonitoringApi
     */
    public getCheckConceptDriftResponseV1(workspaceId: string, modelId: string, options?: AxiosRequestConfig) {
        return ModelMonitoringApiFp(this.configuration).getCheckConceptDriftResponseV1(workspaceId, modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the chart data for given key and given experiment_ids
     * @param {string} workspaceId 
     * @param {string} modelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelMonitoringApi
     */
    public getConceptDriftChartResponseV1(workspaceId: string, modelId: string, options?: AxiosRequestConfig) {
        return ModelMonitoringApiFp(this.configuration).getConceptDriftChartResponseV1(workspaceId, modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the model details for a given drift analysis
     * @param {string} workspaceId 
     * @param {string} dataDriftResultId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelMonitoringApi
     */
    public getDataDriftModelDetailsV1(workspaceId: string, dataDriftResultId: string, options?: AxiosRequestConfig) {
        return ModelMonitoringApiFp(this.configuration).getDataDriftModelDetailsV1(workspaceId, dataDriftResultId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the overview of Data Drift Result for given data_drift_result_id
     * @param {string} workspaceId 
     * @param {string} dataDriftResultId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelMonitoringApi
     */
    public getDataDriftOverviewV1(workspaceId: string, dataDriftResultId: string, options?: AxiosRequestConfig) {
        return ModelMonitoringApiFp(this.configuration).getDataDriftOverviewV1(workspaceId, dataDriftResultId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModelRegistryAPIsApi - axios parameter creator
 * @export
 */
export const ModelRegistryAPIsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create model registry  Args:     create_model_registry_request:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     validate_request: validate create registry request  Returns:     CreateModelRegistryResponse: create model registry
         * @summary Create model registry.
         * @param {string} workspaceId 
         * @param {CreateModelRegistryRequest} createModelRegistryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createModelRegistryV1: async (workspaceId: string, createModelRegistryRequest: CreateModelRegistryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createModelRegistryV1', 'workspaceId', workspaceId)
            // verify required parameter 'createModelRegistryRequest' is not null or undefined
            assertParamExists('createModelRegistryV1', 'createModelRegistryRequest', createModelRegistryRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/registry`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createModelRegistryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create model registry  Args:     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     DeleteModelRegistryResponse: delete model registry
         * @summary Create model registry.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModelRegistryV1: async (workspaceId: string, registryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteModelRegistryV1', 'workspaceId', workspaceId)
            // verify required parameter 'registryId' is not null or undefined
            assertParamExists('deleteModelRegistryV1', 'registryId', registryId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/registry/{registry_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"registry_id"}}`, encodeURIComponent(String(registryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get model registry by registry_id  Args:     workspace_id (str): customer id that uniquely identifies the customer     registry_id (str): registry which needs to be fetched     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetModelRegistryResponse: model registry response
         * @summary Model registry by id.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelRegistryV1: async (workspaceId: string, registryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getModelRegistryV1', 'workspaceId', workspaceId)
            // verify required parameter 'registryId' is not null or undefined
            assertParamExists('getModelRegistryV1', 'registryId', registryId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/registry/{registry_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"registry_id"}}`, encodeURIComponent(String(registryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Link model to registry  Args:     model_id: model which needs to be linked     link_model_to_registry_request: registry which needs to be linked with model     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     validate_request: validate link model request  Returns:     LinkModelToRegistryRequest: registry linked with model
         * @summary Link model to registry.
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {LinkModelToRegistryRequest} linkModelToRegistryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkModelToRegistryV1: async (workspaceId: string, modelId: string, linkModelToRegistryRequest: LinkModelToRegistryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('linkModelToRegistryV1', 'workspaceId', workspaceId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('linkModelToRegistryV1', 'modelId', modelId)
            // verify required parameter 'linkModelToRegistryRequest' is not null or undefined
            assertParamExists('linkModelToRegistryV1', 'linkModelToRegistryRequest', linkModelToRegistryRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/model/{model_id}/link`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkModelToRegistryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get paginated list of model registry  Args:     registry_type:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsResponse: list of model registries
         * @summary List model registry paginated.
         * @param {string} workspaceId 
         * @param {MLModelClass} [registryType] 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listModelRegistryV11: async (workspaceId: string, registryType?: MLModelClass, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listModelRegistryV11', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/registry`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (registryType !== undefined) {
                localVarQueryParameter['registry_type'] = registryType;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get paginated list of models linked with registry  Args:     workspace_id (str): customer id that uniquely identifies the customer     registry_id (str): registry linked with models     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsInRegistry: list of models in registry
         * @summary List models in a registry paginated.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listModelsInRegistryV1: async (workspaceId: string, registryId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listModelsInRegistryV1', 'workspaceId', workspaceId)
            // verify required parameter 'registryId' is not null or undefined
            assertParamExists('listModelsInRegistryV1', 'registryId', registryId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('listModelsInRegistryV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('listModelsInRegistryV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/registry/{registry_id}/models`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"registry_id"}}`, encodeURIComponent(String(registryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Staged models are the ones which have a stage assigned to it.
         * @summary List staged models in a registry paginated.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStagedModelsInRegistryV1: async (workspaceId: string, registryId: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listStagedModelsInRegistryV1', 'workspaceId', workspaceId)
            // verify required parameter 'registryId' is not null or undefined
            assertParamExists('listStagedModelsInRegistryV1', 'registryId', registryId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/registry/{registry_id}/staged-models`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"registry_id"}}`, encodeURIComponent(String(registryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Transition model to corresponding stage  Args:     ts:     end:     start:     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     TransitionModelStageResponse: transition model to stage
         * @summary Transition history for models in a registry.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTransitionHistoryV1: async (workspaceId: string, registryId: string, start: number, end: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('modelTransitionHistoryV1', 'workspaceId', workspaceId)
            // verify required parameter 'registryId' is not null or undefined
            assertParamExists('modelTransitionHistoryV1', 'registryId', registryId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('modelTransitionHistoryV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('modelTransitionHistoryV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/registry/{registry_id}/history`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"registry_id"}}`, encodeURIComponent(String(registryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Transition model to corresponding stage  Args:     model_id: transition model     transition_model_stage: transition model to stage     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     TransitionModelStageResponse: transition model to stage
         * @summary Transition model stage.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {string} modelId 
         * @param {TransitionModelStageRequest} transitionModelStageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionModelStageV1: async (workspaceId: string, registryId: string, modelId: string, transitionModelStageRequest: TransitionModelStageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('transitionModelStageV1', 'workspaceId', workspaceId)
            // verify required parameter 'registryId' is not null or undefined
            assertParamExists('transitionModelStageV1', 'registryId', registryId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('transitionModelStageV1', 'modelId', modelId)
            // verify required parameter 'transitionModelStageRequest' is not null or undefined
            assertParamExists('transitionModelStageV1', 'transitionModelStageRequest', transitionModelStageRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/registry/{registry_id}/model/{model_id}/transition`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"registry_id"}}`, encodeURIComponent(String(registryId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transitionModelStageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unlink model to registry  Args:     registry_id: registry which needs to be unlinked     unlink_model_to_registry_request: model which needs to be unlinked with model     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     UnlinkModelToRegistryRequest: registry unlinked with model
         * @summary Unlink model to registry.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {UnlinkModelToRegistryRequest} unlinkModelToRegistryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkModelToRegistryV1: async (workspaceId: string, registryId: string, unlinkModelToRegistryRequest: UnlinkModelToRegistryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('unlinkModelToRegistryV1', 'workspaceId', workspaceId)
            // verify required parameter 'registryId' is not null or undefined
            assertParamExists('unlinkModelToRegistryV1', 'registryId', registryId)
            // verify required parameter 'unlinkModelToRegistryRequest' is not null or undefined
            assertParamExists('unlinkModelToRegistryV1', 'unlinkModelToRegistryRequest', unlinkModelToRegistryRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/registry/{registry_id}/unlink`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"registry_id"}}`, encodeURIComponent(String(registryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unlinkModelToRegistryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelRegistryAPIsApi - functional programming interface
 * @export
 */
export const ModelRegistryAPIsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModelRegistryAPIsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create model registry  Args:     create_model_registry_request:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     validate_request: validate create registry request  Returns:     CreateModelRegistryResponse: create model registry
         * @summary Create model registry.
         * @param {string} workspaceId 
         * @param {CreateModelRegistryRequest} createModelRegistryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createModelRegistryV1(workspaceId: string, createModelRegistryRequest: CreateModelRegistryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateModelRegistryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createModelRegistryV1(workspaceId, createModelRegistryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create model registry  Args:     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     DeleteModelRegistryResponse: delete model registry
         * @summary Create model registry.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteModelRegistryV1(workspaceId: string, registryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteModelRegistryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteModelRegistryV1(workspaceId, registryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get model registry by registry_id  Args:     workspace_id (str): customer id that uniquely identifies the customer     registry_id (str): registry which needs to be fetched     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetModelRegistryResponse: model registry response
         * @summary Model registry by id.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelRegistryV1(workspaceId: string, registryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelRegistryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelRegistryV1(workspaceId, registryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Link model to registry  Args:     model_id: model which needs to be linked     link_model_to_registry_request: registry which needs to be linked with model     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     validate_request: validate link model request  Returns:     LinkModelToRegistryRequest: registry linked with model
         * @summary Link model to registry.
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {LinkModelToRegistryRequest} linkModelToRegistryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkModelToRegistryV1(workspaceId: string, modelId: string, linkModelToRegistryRequest: LinkModelToRegistryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkModelToRegistryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkModelToRegistryV1(workspaceId, modelId, linkModelToRegistryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get paginated list of model registry  Args:     registry_type:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsResponse: list of model registries
         * @summary List model registry paginated.
         * @param {string} workspaceId 
         * @param {MLModelClass} [registryType] 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listModelRegistryV11(workspaceId: string, registryType?: MLModelClass, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelRegistryPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listModelRegistryV11(workspaceId, registryType, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get paginated list of models linked with registry  Args:     workspace_id (str): customer id that uniquely identifies the customer     registry_id (str): registry linked with models     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsInRegistry: list of models in registry
         * @summary List models in a registry paginated.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listModelsInRegistryV1(workspaceId: string, registryId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelsInRegistryPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listModelsInRegistryV1(workspaceId, registryId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Staged models are the ones which have a stage assigned to it.
         * @summary List staged models in a registry paginated.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStagedModelsInRegistryV1(workspaceId: string, registryId: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelsInRegistryPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStagedModelsInRegistryV1(workspaceId, registryId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Transition model to corresponding stage  Args:     ts:     end:     start:     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     TransitionModelStageResponse: transition model to stage
         * @summary Transition history for models in a registry.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTransitionHistoryV1(workspaceId: string, registryId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionModelStageHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTransitionHistoryV1(workspaceId, registryId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Transition model to corresponding stage  Args:     model_id: transition model     transition_model_stage: transition model to stage     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     TransitionModelStageResponse: transition model to stage
         * @summary Transition model stage.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {string} modelId 
         * @param {TransitionModelStageRequest} transitionModelStageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transitionModelStageV1(workspaceId: string, registryId: string, modelId: string, transitionModelStageRequest: TransitionModelStageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransitionModelStageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transitionModelStageV1(workspaceId, registryId, modelId, transitionModelStageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unlink model to registry  Args:     registry_id: registry which needs to be unlinked     unlink_model_to_registry_request: model which needs to be unlinked with model     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     UnlinkModelToRegistryRequest: registry unlinked with model
         * @summary Unlink model to registry.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {UnlinkModelToRegistryRequest} unlinkModelToRegistryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlinkModelToRegistryV1(workspaceId: string, registryId: string, unlinkModelToRegistryRequest: UnlinkModelToRegistryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnlinkModelToRegistryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlinkModelToRegistryV1(workspaceId, registryId, unlinkModelToRegistryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModelRegistryAPIsApi - factory interface
 * @export
 */
export const ModelRegistryAPIsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModelRegistryAPIsApiFp(configuration)
    return {
        /**
         * Create model registry  Args:     create_model_registry_request:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     validate_request: validate create registry request  Returns:     CreateModelRegistryResponse: create model registry
         * @summary Create model registry.
         * @param {string} workspaceId 
         * @param {CreateModelRegistryRequest} createModelRegistryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createModelRegistryV1(workspaceId: string, createModelRegistryRequest: CreateModelRegistryRequest, options?: any): AxiosPromise<CreateModelRegistryResponse> {
            return localVarFp.createModelRegistryV1(workspaceId, createModelRegistryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create model registry  Args:     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     DeleteModelRegistryResponse: delete model registry
         * @summary Create model registry.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModelRegistryV1(workspaceId: string, registryId: string, options?: any): AxiosPromise<DeleteModelRegistryResponse> {
            return localVarFp.deleteModelRegistryV1(workspaceId, registryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get model registry by registry_id  Args:     workspace_id (str): customer id that uniquely identifies the customer     registry_id (str): registry which needs to be fetched     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetModelRegistryResponse: model registry response
         * @summary Model registry by id.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelRegistryV1(workspaceId: string, registryId: string, options?: any): AxiosPromise<ModelRegistryResponse> {
            return localVarFp.getModelRegistryV1(workspaceId, registryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Link model to registry  Args:     model_id: model which needs to be linked     link_model_to_registry_request: registry which needs to be linked with model     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     validate_request: validate link model request  Returns:     LinkModelToRegistryRequest: registry linked with model
         * @summary Link model to registry.
         * @param {string} workspaceId 
         * @param {string} modelId 
         * @param {LinkModelToRegistryRequest} linkModelToRegistryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkModelToRegistryV1(workspaceId: string, modelId: string, linkModelToRegistryRequest: LinkModelToRegistryRequest, options?: any): AxiosPromise<LinkModelToRegistryResponse> {
            return localVarFp.linkModelToRegistryV1(workspaceId, modelId, linkModelToRegistryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get paginated list of model registry  Args:     registry_type:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsResponse: list of model registries
         * @summary List model registry paginated.
         * @param {string} workspaceId 
         * @param {MLModelClass} [registryType] 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listModelRegistryV11(workspaceId: string, registryType?: MLModelClass, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<ModelRegistryPaginatedResponse> {
            return localVarFp.listModelRegistryV11(workspaceId, registryType, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Get paginated list of models linked with registry  Args:     workspace_id (str): customer id that uniquely identifies the customer     registry_id (str): registry linked with models     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsInRegistry: list of models in registry
         * @summary List models in a registry paginated.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listModelsInRegistryV1(workspaceId: string, registryId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<GetModelsInRegistryPaginated> {
            return localVarFp.listModelsInRegistryV1(workspaceId, registryId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Staged models are the ones which have a stage assigned to it.
         * @summary List staged models in a registry paginated.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStagedModelsInRegistryV1(workspaceId: string, registryId: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<GetModelsInRegistryPaginated> {
            return localVarFp.listStagedModelsInRegistryV1(workspaceId, registryId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Transition model to corresponding stage  Args:     ts:     end:     start:     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     TransitionModelStageResponse: transition model to stage
         * @summary Transition history for models in a registry.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTransitionHistoryV1(workspaceId: string, registryId: string, start: number, end: number, ts?: string, options?: any): AxiosPromise<TransitionModelStageHistoryResponse> {
            return localVarFp.modelTransitionHistoryV1(workspaceId, registryId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * Transition model to corresponding stage  Args:     model_id: transition model     transition_model_stage: transition model to stage     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     TransitionModelStageResponse: transition model to stage
         * @summary Transition model stage.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {string} modelId 
         * @param {TransitionModelStageRequest} transitionModelStageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitionModelStageV1(workspaceId: string, registryId: string, modelId: string, transitionModelStageRequest: TransitionModelStageRequest, options?: any): AxiosPromise<TransitionModelStageResponse> {
            return localVarFp.transitionModelStageV1(workspaceId, registryId, modelId, transitionModelStageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Unlink model to registry  Args:     registry_id: registry which needs to be unlinked     unlink_model_to_registry_request: model which needs to be unlinked with model     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     UnlinkModelToRegistryRequest: registry unlinked with model
         * @summary Unlink model to registry.
         * @param {string} workspaceId 
         * @param {string} registryId 
         * @param {UnlinkModelToRegistryRequest} unlinkModelToRegistryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkModelToRegistryV1(workspaceId: string, registryId: string, unlinkModelToRegistryRequest: UnlinkModelToRegistryRequest, options?: any): AxiosPromise<UnlinkModelToRegistryResponse> {
            return localVarFp.unlinkModelToRegistryV1(workspaceId, registryId, unlinkModelToRegistryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModelRegistryAPIsApi - object-oriented interface
 * @export
 * @class ModelRegistryAPIsApi
 * @extends {BaseAPI}
 */
export class ModelRegistryAPIsApi extends BaseAPI {
    /**
     * Create model registry  Args:     create_model_registry_request:     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     validate_request: validate create registry request  Returns:     CreateModelRegistryResponse: create model registry
     * @summary Create model registry.
     * @param {string} workspaceId 
     * @param {CreateModelRegistryRequest} createModelRegistryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelRegistryAPIsApi
     */
    public createModelRegistryV1(workspaceId: string, createModelRegistryRequest: CreateModelRegistryRequest, options?: AxiosRequestConfig) {
        return ModelRegistryAPIsApiFp(this.configuration).createModelRegistryV1(workspaceId, createModelRegistryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create model registry  Args:     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     DeleteModelRegistryResponse: delete model registry
     * @summary Create model registry.
     * @param {string} workspaceId 
     * @param {string} registryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelRegistryAPIsApi
     */
    public deleteModelRegistryV1(workspaceId: string, registryId: string, options?: AxiosRequestConfig) {
        return ModelRegistryAPIsApiFp(this.configuration).deleteModelRegistryV1(workspaceId, registryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get model registry by registry_id  Args:     workspace_id (str): customer id that uniquely identifies the customer     registry_id (str): registry which needs to be fetched     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetModelRegistryResponse: model registry response
     * @summary Model registry by id.
     * @param {string} workspaceId 
     * @param {string} registryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelRegistryAPIsApi
     */
    public getModelRegistryV1(workspaceId: string, registryId: string, options?: AxiosRequestConfig) {
        return ModelRegistryAPIsApiFp(this.configuration).getModelRegistryV1(workspaceId, registryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Link model to registry  Args:     model_id: model which needs to be linked     link_model_to_registry_request: registry which needs to be linked with model     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.     validate_request: validate link model request  Returns:     LinkModelToRegistryRequest: registry linked with model
     * @summary Link model to registry.
     * @param {string} workspaceId 
     * @param {string} modelId 
     * @param {LinkModelToRegistryRequest} linkModelToRegistryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelRegistryAPIsApi
     */
    public linkModelToRegistryV1(workspaceId: string, modelId: string, linkModelToRegistryRequest: LinkModelToRegistryRequest, options?: AxiosRequestConfig) {
        return ModelRegistryAPIsApiFp(this.configuration).linkModelToRegistryV1(workspaceId, modelId, linkModelToRegistryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get paginated list of model registry  Args:     registry_type:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsResponse: list of model registries
     * @summary List model registry paginated.
     * @param {string} workspaceId 
     * @param {MLModelClass} [registryType] 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelRegistryAPIsApi
     */
    public listModelRegistryV11(workspaceId: string, registryType?: MLModelClass, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return ModelRegistryAPIsApiFp(this.configuration).listModelRegistryV11(workspaceId, registryType, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get paginated list of models linked with registry  Args:     workspace_id (str): customer id that uniquely identifies the customer     registry_id (str): registry linked with models     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsInRegistry: list of models in registry
     * @summary List models in a registry paginated.
     * @param {string} workspaceId 
     * @param {string} registryId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelRegistryAPIsApi
     */
    public listModelsInRegistryV1(workspaceId: string, registryId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return ModelRegistryAPIsApiFp(this.configuration).listModelsInRegistryV1(workspaceId, registryId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Staged models are the ones which have a stage assigned to it.
     * @summary List staged models in a registry paginated.
     * @param {string} workspaceId 
     * @param {string} registryId 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelRegistryAPIsApi
     */
    public listStagedModelsInRegistryV1(workspaceId: string, registryId: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return ModelRegistryAPIsApiFp(this.configuration).listStagedModelsInRegistryV1(workspaceId, registryId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Transition model to corresponding stage  Args:     ts:     end:     start:     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     TransitionModelStageResponse: transition model to stage
     * @summary Transition history for models in a registry.
     * @param {string} workspaceId 
     * @param {string} registryId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelRegistryAPIsApi
     */
    public modelTransitionHistoryV1(workspaceId: string, registryId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig) {
        return ModelRegistryAPIsApiFp(this.configuration).modelTransitionHistoryV1(workspaceId, registryId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Transition model to corresponding stage  Args:     model_id: transition model     transition_model_stage: transition model to stage     registry_id: registry which needs to be deleted     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     TransitionModelStageResponse: transition model to stage
     * @summary Transition model stage.
     * @param {string} workspaceId 
     * @param {string} registryId 
     * @param {string} modelId 
     * @param {TransitionModelStageRequest} transitionModelStageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelRegistryAPIsApi
     */
    public transitionModelStageV1(workspaceId: string, registryId: string, modelId: string, transitionModelStageRequest: TransitionModelStageRequest, options?: AxiosRequestConfig) {
        return ModelRegistryAPIsApiFp(this.configuration).transitionModelStageV1(workspaceId, registryId, modelId, transitionModelStageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unlink model to registry  Args:     registry_id: registry which needs to be unlinked     unlink_model_to_registry_request: model which needs to be unlinked with model     workspace_id (str): customer id that uniquely identifies the customer     request (Request): FastAPI Request object     response (Response): FastAPI Response object     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     UnlinkModelToRegistryRequest: registry unlinked with model
     * @summary Unlink model to registry.
     * @param {string} workspaceId 
     * @param {string} registryId 
     * @param {UnlinkModelToRegistryRequest} unlinkModelToRegistryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelRegistryAPIsApi
     */
    public unlinkModelToRegistryV1(workspaceId: string, registryId: string, unlinkModelToRegistryRequest: UnlinkModelToRegistryRequest, options?: AxiosRequestConfig) {
        return ModelRegistryAPIsApiFp(this.configuration).unlinkModelToRegistryV1(workspaceId, registryId, unlinkModelToRegistryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModelRegistryRoutesApi - axios parameter creator
 * @export
 */
export const ModelRegistryRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get paginated list of model registry with user details
         * @param {string} workspaceId ID of the workspace.
         * @param {MLModelClass} registryType 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listModelRegistryV1: async (workspaceId: string, registryType: MLModelClass, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listModelRegistryV1', 'workspaceId', workspaceId)
            // verify required parameter 'registryType' is not null or undefined
            assertParamExists('listModelRegistryV1', 'registryType', registryType)
            const localVarPath = `/v1/workspace/{workspaceId}/registry`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (registryType !== undefined) {
                localVarQueryParameter['registryType'] = registryType;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (excludedUserIds) {
                localVarQueryParameter['excludedUserIds'] = excludedUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['recordIds'] = recordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['excludeDemoArtifacts'] = excludeDemoArtifacts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get paginated list of transition history with user details
         * @param {string} workspaceId ID of the workspace.
         * @param {string} registryId ID of the registry.
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listModelTransitionHistoryV1: async (workspaceId: string, registryId: string, start: number, end: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listModelTransitionHistoryV1', 'workspaceId', workspaceId)
            // verify required parameter 'registryId' is not null or undefined
            assertParamExists('listModelTransitionHistoryV1', 'registryId', registryId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('listModelTransitionHistoryV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('listModelTransitionHistoryV1', 'end', end)
            const localVarPath = `/v1/workspace/{workspaceId}/registry/{registryId}/transition-history`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"registryId"}}`, encodeURIComponent(String(registryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelRegistryRoutesApi - functional programming interface
 * @export
 */
export const ModelRegistryRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModelRegistryRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get paginated list of model registry with user details
         * @param {string} workspaceId ID of the workspace.
         * @param {MLModelClass} registryType 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listModelRegistryV1(workspaceId: string, registryType: MLModelClass, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelRegistryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listModelRegistryV1(workspaceId, registryType, start, end, ts, name, userIds, excludedUserIds, recordIds, excludeDemoArtifacts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get paginated list of transition history with user details
         * @param {string} workspaceId ID of the workspace.
         * @param {string} registryId ID of the registry.
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listModelTransitionHistoryV1(workspaceId: string, registryId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelTransitionHistoryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listModelTransitionHistoryV1(workspaceId, registryId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModelRegistryRoutesApi - factory interface
 * @export
 */
export const ModelRegistryRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModelRegistryRoutesApiFp(configuration)
    return {
        /**
         * Get paginated list of model registry with user details
         * @param {string} workspaceId ID of the workspace.
         * @param {MLModelClass} registryType 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listModelRegistryV1(workspaceId: string, registryType: MLModelClass, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options?: any): AxiosPromise<ModelRegistryListResponse> {
            return localVarFp.listModelRegistryV1(workspaceId, registryType, start, end, ts, name, userIds, excludedUserIds, recordIds, excludeDemoArtifacts, options).then((request) => request(axios, basePath));
        },
        /**
         * Get paginated list of transition history with user details
         * @param {string} workspaceId ID of the workspace.
         * @param {string} registryId ID of the registry.
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listModelTransitionHistoryV1(workspaceId: string, registryId: string, start: number, end: number, ts?: string, options?: any): AxiosPromise<ModelTransitionHistoryListResponse> {
            return localVarFp.listModelTransitionHistoryV1(workspaceId, registryId, start, end, ts, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModelRegistryRoutesApi - object-oriented interface
 * @export
 * @class ModelRegistryRoutesApi
 * @extends {BaseAPI}
 */
export class ModelRegistryRoutesApi extends BaseAPI {
    /**
     * Get paginated list of model registry with user details
     * @param {string} workspaceId ID of the workspace.
     * @param {MLModelClass} registryType 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludedUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelRegistryRoutesApi
     */
    public listModelRegistryV1(workspaceId: string, registryType: MLModelClass, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, options?: AxiosRequestConfig) {
        return ModelRegistryRoutesApiFp(this.configuration).listModelRegistryV1(workspaceId, registryType, start, end, ts, name, userIds, excludedUserIds, recordIds, excludeDemoArtifacts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get paginated list of transition history with user details
     * @param {string} workspaceId ID of the workspace.
     * @param {string} registryId ID of the registry.
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelRegistryRoutesApi
     */
    public listModelTransitionHistoryV1(workspaceId: string, registryId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig) {
        return ModelRegistryRoutesApiFp(this.configuration).listModelTransitionHistoryV1(workspaceId, registryId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OperatorsAPIsApi - axios parameter creator
 * @export
 */
export const OperatorsAPIsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes a user created template from the workspace.
         * @summary Delete a previously saved template
         * @param {string} workspaceId 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowTemplatesV1: async (workspaceId: string, templateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteWorkflowTemplatesV1', 'workspaceId', workspaceId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('deleteWorkflowTemplatesV1', 'templateId', templateId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/template/{template_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exports a workflow as a template specifically for internal usage for San Francisco.
         * @summary Export workflow as an internal MarkovML template
         * @param {string} workspaceId 
         * @param {ExportTemplateRequest} exportTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAsWorkflowTemplatesV1: async (workspaceId: string, exportTemplateRequest: ExportTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('exportAsWorkflowTemplatesV1', 'workspaceId', workspaceId)
            // verify required parameter 'exportTemplateRequest' is not null or undefined
            assertParamExists('exportAsWorkflowTemplatesV1', 'exportTemplateRequest', exportTemplateRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/export-as-template`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to return details of a
         * @summary Return details of a single operator
         * @param {string} workspaceId 
         * @param {string} operatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorDetailsV1: async (workspaceId: string, operatorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getOperatorDetailsV1', 'workspaceId', workspaceId)
            // verify required parameter 'operatorId' is not null or undefined
            assertParamExists('getOperatorDetailsV1', 'operatorId', operatorId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/operator/{operator_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"operator_id"}}`, encodeURIComponent(String(operatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets list of operators from json file
         * @summary Get list of operators
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperatorsV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listOperatorsV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/operators`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets list of template from json file
         * @summary Get list of templates
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplatesV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listTemplatesV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/templates`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to return a list of resources to be used in Operator async dropdowns
         * @summary This API returns an asked for resource in the syntax of `{label: string, value: string}` pairs. It can be used to power dropdowns in operators
         * @param {string} workspaceId 
         * @param {AsyncDropDownResources} resourceType 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {boolean} [useV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorResourceListV1: async (workspaceId: string, resourceType: AsyncDropDownResources, listingAPIParams: ListingAPIParams, useV2?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('operatorResourceListV1', 'workspaceId', workspaceId)
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('operatorResourceListV1', 'resourceType', resourceType)
            // verify required parameter 'listingAPIParams' is not null or undefined
            assertParamExists('operatorResourceListV1', 'listingAPIParams', listingAPIParams)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/resources/{resource_type}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"resource_type"}}`, encodeURIComponent(String(resourceType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (useV2 !== undefined) {
                localVarQueryParameter['use_v2'] = useV2;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingAPIParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores a given workflow as a template in user workspace, allowing users to create new workflows based on the saved template.
         * @summary Save workflow as a reusable template
         * @param {string} workspaceId 
         * @param {ExportTemplateRequest} exportTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAsWorkflowTemplatesV1: async (workspaceId: string, exportTemplateRequest: ExportTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('saveAsWorkflowTemplatesV1', 'workspaceId', workspaceId)
            // verify required parameter 'exportTemplateRequest' is not null or undefined
            assertParamExists('saveAsWorkflowTemplatesV1', 'exportTemplateRequest', exportTemplateRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/templates/save-as-template`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperatorsAPIsApi - functional programming interface
 * @export
 */
export const OperatorsAPIsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OperatorsAPIsApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes a user created template from the workspace.
         * @summary Delete a previously saved template
         * @param {string} workspaceId 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkflowTemplatesV1(workspaceId: string, templateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkflowTemplatesV1(workspaceId, templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exports a workflow as a template specifically for internal usage for San Francisco.
         * @summary Export workflow as an internal MarkovML template
         * @param {string} workspaceId 
         * @param {ExportTemplateRequest} exportTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportAsWorkflowTemplatesV1(workspaceId: string, exportTemplateRequest: ExportTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportAsWorkflowTemplatesV1(workspaceId, exportTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to return details of a
         * @summary Return details of a single operator
         * @param {string} workspaceId 
         * @param {string} operatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOperatorDetailsV1(workspaceId: string, operatorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOperatorDetailsV1(workspaceId, operatorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets list of operators from json file
         * @summary Get list of operators
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOperatorsV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOperatorsV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets list of template from json file
         * @summary Get list of templates
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTemplatesV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTemplatesV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to return a list of resources to be used in Operator async dropdowns
         * @summary This API returns an asked for resource in the syntax of `{label: string, value: string}` pairs. It can be used to power dropdowns in operators
         * @param {string} workspaceId 
         * @param {AsyncDropDownResources} resourceType 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {boolean} [useV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async operatorResourceListV1(workspaceId: string, resourceType: AsyncDropDownResources, listingAPIParams: ListingAPIParams, useV2?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsyncResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.operatorResourceListV1(workspaceId, resourceType, listingAPIParams, useV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stores a given workflow as a template in user workspace, allowing users to create new workflows based on the saved template.
         * @summary Save workflow as a reusable template
         * @param {string} workspaceId 
         * @param {ExportTemplateRequest} exportTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAsWorkflowTemplatesV1(workspaceId: string, exportTemplateRequest: ExportTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAsWorkflowTemplatesV1(workspaceId, exportTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OperatorsAPIsApi - factory interface
 * @export
 */
export const OperatorsAPIsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OperatorsAPIsApiFp(configuration)
    return {
        /**
         * Removes a user created template from the workspace.
         * @summary Delete a previously saved template
         * @param {string} workspaceId 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowTemplatesV1(workspaceId: string, templateId: string, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.deleteWorkflowTemplatesV1(workspaceId, templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Exports a workflow as a template specifically for internal usage for San Francisco.
         * @summary Export workflow as an internal MarkovML template
         * @param {string} workspaceId 
         * @param {ExportTemplateRequest} exportTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAsWorkflowTemplatesV1(workspaceId: string, exportTemplateRequest: ExportTemplateRequest, options?: any): AxiosPromise<TemplateModel> {
            return localVarFp.exportAsWorkflowTemplatesV1(workspaceId, exportTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * API to return details of a
         * @summary Return details of a single operator
         * @param {string} workspaceId 
         * @param {string} operatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorDetailsV1(workspaceId: string, operatorId: string, options?: any): AxiosPromise<OperatorModel> {
            return localVarFp.getOperatorDetailsV1(workspaceId, operatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets list of operators from json file
         * @summary Get list of operators
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperatorsV1(workspaceId: string, options?: any): AxiosPromise<OperatorListResponse> {
            return localVarFp.listOperatorsV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets list of template from json file
         * @summary Get list of templates
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplatesV1(workspaceId: string, options?: any): AxiosPromise<TemplateListResponse> {
            return localVarFp.listTemplatesV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * API to return a list of resources to be used in Operator async dropdowns
         * @summary This API returns an asked for resource in the syntax of `{label: string, value: string}` pairs. It can be used to power dropdowns in operators
         * @param {string} workspaceId 
         * @param {AsyncDropDownResources} resourceType 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {boolean} [useV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorResourceListV1(workspaceId: string, resourceType: AsyncDropDownResources, listingAPIParams: ListingAPIParams, useV2?: boolean, options?: any): AxiosPromise<AsyncResourceResponse> {
            return localVarFp.operatorResourceListV1(workspaceId, resourceType, listingAPIParams, useV2, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores a given workflow as a template in user workspace, allowing users to create new workflows based on the saved template.
         * @summary Save workflow as a reusable template
         * @param {string} workspaceId 
         * @param {ExportTemplateRequest} exportTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAsWorkflowTemplatesV1(workspaceId: string, exportTemplateRequest: ExportTemplateRequest, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.saveAsWorkflowTemplatesV1(workspaceId, exportTemplateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OperatorsAPIsApi - object-oriented interface
 * @export
 * @class OperatorsAPIsApi
 * @extends {BaseAPI}
 */
export class OperatorsAPIsApi extends BaseAPI {
    /**
     * Removes a user created template from the workspace.
     * @summary Delete a previously saved template
     * @param {string} workspaceId 
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorsAPIsApi
     */
    public deleteWorkflowTemplatesV1(workspaceId: string, templateId: string, options?: AxiosRequestConfig) {
        return OperatorsAPIsApiFp(this.configuration).deleteWorkflowTemplatesV1(workspaceId, templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exports a workflow as a template specifically for internal usage for San Francisco.
     * @summary Export workflow as an internal MarkovML template
     * @param {string} workspaceId 
     * @param {ExportTemplateRequest} exportTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorsAPIsApi
     */
    public exportAsWorkflowTemplatesV1(workspaceId: string, exportTemplateRequest: ExportTemplateRequest, options?: AxiosRequestConfig) {
        return OperatorsAPIsApiFp(this.configuration).exportAsWorkflowTemplatesV1(workspaceId, exportTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to return details of a
     * @summary Return details of a single operator
     * @param {string} workspaceId 
     * @param {string} operatorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorsAPIsApi
     */
    public getOperatorDetailsV1(workspaceId: string, operatorId: string, options?: AxiosRequestConfig) {
        return OperatorsAPIsApiFp(this.configuration).getOperatorDetailsV1(workspaceId, operatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets list of operators from json file
     * @summary Get list of operators
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorsAPIsApi
     */
    public listOperatorsV1(workspaceId: string, options?: AxiosRequestConfig) {
        return OperatorsAPIsApiFp(this.configuration).listOperatorsV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets list of template from json file
     * @summary Get list of templates
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorsAPIsApi
     */
    public listTemplatesV1(workspaceId: string, options?: AxiosRequestConfig) {
        return OperatorsAPIsApiFp(this.configuration).listTemplatesV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to return a list of resources to be used in Operator async dropdowns
     * @summary This API returns an asked for resource in the syntax of `{label: string, value: string}` pairs. It can be used to power dropdowns in operators
     * @param {string} workspaceId 
     * @param {AsyncDropDownResources} resourceType 
     * @param {ListingAPIParams} listingAPIParams 
     * @param {boolean} [useV2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorsAPIsApi
     */
    public operatorResourceListV1(workspaceId: string, resourceType: AsyncDropDownResources, listingAPIParams: ListingAPIParams, useV2?: boolean, options?: AxiosRequestConfig) {
        return OperatorsAPIsApiFp(this.configuration).operatorResourceListV1(workspaceId, resourceType, listingAPIParams, useV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores a given workflow as a template in user workspace, allowing users to create new workflows based on the saved template.
     * @summary Save workflow as a reusable template
     * @param {string} workspaceId 
     * @param {ExportTemplateRequest} exportTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorsAPIsApi
     */
    public saveAsWorkflowTemplatesV1(workspaceId: string, exportTemplateRequest: ExportTemplateRequest, options?: AxiosRequestConfig) {
        return OperatorsAPIsApiFp(this.configuration).saveAsWorkflowTemplatesV1(workspaceId, exportTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentRoutesApi - axios parameter creator
 * @export
 */
export const PaymentRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create workspace billing portal session to manage subscription.
         * @summary Create Workspace Billing Portal Session
         * @param {string} workspaceId 
         * @param {CreateWorkspaceBillingPortalSessionRequestModel} createWorkspaceBillingPortalSessionRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspaceBillingPortalSessionV1: async (workspaceId: string, createWorkspaceBillingPortalSessionRequestModel: CreateWorkspaceBillingPortalSessionRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createWorkspaceBillingPortalSessionV1', 'workspaceId', workspaceId)
            // verify required parameter 'createWorkspaceBillingPortalSessionRequestModel' is not null or undefined
            assertParamExists('createWorkspaceBillingPortalSessionV1', 'createWorkspaceBillingPortalSessionRequestModel', createWorkspaceBillingPortalSessionRequestModel)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/billing-portal-session`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkspaceBillingPortalSessionRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a payment checkout session for this workspace.
         * @summary Create Workspace Checkout Session
         * @param {string} workspaceId 
         * @param {PaymentSubscriptionType} subscriptionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspaceCheckoutSessionV1: async (workspaceId: string, subscriptionType: PaymentSubscriptionType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createWorkspaceCheckoutSessionV1', 'workspaceId', workspaceId)
            // verify required parameter 'subscriptionType' is not null or undefined
            assertParamExists('createWorkspaceCheckoutSessionV1', 'subscriptionType', subscriptionType)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/checkout-session`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subscriptionType !== undefined) {
                localVarQueryParameter['subscription_type'] = subscriptionType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a freemium subscription for the workspace.
         * @summary Create Workspace Freemium Subscription
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspaceFreemiumSubscriptionV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createWorkspaceFreemiumSubscriptionV1', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/subscription/freemium`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get payment checkout session for the provided checkout session ID.
         * @summary Get Checkout Session
         * @param {string} checkoutSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceCheckoutSessionV1: async (checkoutSessionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkoutSessionId' is not null or undefined
            assertParamExists('getWorkspaceCheckoutSessionV1', 'checkoutSessionId', checkoutSessionId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/checkout-session/{checkout_session_id}`
                .replace(`{${"checkout_session_id"}}`, encodeURIComponent(String(checkoutSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get workspace current Stripe subscription.
         * @summary Get Workspace Current Subscription Detail
         * @param {any} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceCurrentSubscriptionDetailV1: async (workspaceId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspaceCurrentSubscriptionDetailV1', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/subscription`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentRoutesApi - functional programming interface
 * @export
 */
export const PaymentRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create workspace billing portal session to manage subscription.
         * @summary Create Workspace Billing Portal Session
         * @param {string} workspaceId 
         * @param {CreateWorkspaceBillingPortalSessionRequestModel} createWorkspaceBillingPortalSessionRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkspaceBillingPortalSessionV1(workspaceId: string, createWorkspaceBillingPortalSessionRequestModel: CreateWorkspaceBillingPortalSessionRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWorkspaceBillingPortalSessionResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkspaceBillingPortalSessionV1(workspaceId, createWorkspaceBillingPortalSessionRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a payment checkout session for this workspace.
         * @summary Create Workspace Checkout Session
         * @param {string} workspaceId 
         * @param {PaymentSubscriptionType} subscriptionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkspaceCheckoutSessionV1(workspaceId: string, subscriptionType: PaymentSubscriptionType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWorkspaceCheckoutSessionResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkspaceCheckoutSessionV1(workspaceId, subscriptionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a freemium subscription for the workspace.
         * @summary Create Workspace Freemium Subscription
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkspaceFreemiumSubscriptionV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWorkspaceCurrentSubscriptionDetailResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkspaceFreemiumSubscriptionV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get payment checkout session for the provided checkout session ID.
         * @summary Get Checkout Session
         * @param {string} checkoutSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceCheckoutSessionV1(checkoutSessionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckoutSessionResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceCheckoutSessionV1(checkoutSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get workspace current Stripe subscription.
         * @summary Get Workspace Current Subscription Detail
         * @param {any} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceCurrentSubscriptionDetailV1(workspaceId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWorkspaceCurrentSubscriptionDetailResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceCurrentSubscriptionDetailV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentRoutesApi - factory interface
 * @export
 */
export const PaymentRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentRoutesApiFp(configuration)
    return {
        /**
         * Create workspace billing portal session to manage subscription.
         * @summary Create Workspace Billing Portal Session
         * @param {string} workspaceId 
         * @param {CreateWorkspaceBillingPortalSessionRequestModel} createWorkspaceBillingPortalSessionRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspaceBillingPortalSessionV1(workspaceId: string, createWorkspaceBillingPortalSessionRequestModel: CreateWorkspaceBillingPortalSessionRequestModel, options?: any): AxiosPromise<CreateWorkspaceBillingPortalSessionResponseModel> {
            return localVarFp.createWorkspaceBillingPortalSessionV1(workspaceId, createWorkspaceBillingPortalSessionRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a payment checkout session for this workspace.
         * @summary Create Workspace Checkout Session
         * @param {string} workspaceId 
         * @param {PaymentSubscriptionType} subscriptionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspaceCheckoutSessionV1(workspaceId: string, subscriptionType: PaymentSubscriptionType, options?: any): AxiosPromise<CreateWorkspaceCheckoutSessionResponseModel> {
            return localVarFp.createWorkspaceCheckoutSessionV1(workspaceId, subscriptionType, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a freemium subscription for the workspace.
         * @summary Create Workspace Freemium Subscription
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspaceFreemiumSubscriptionV1(workspaceId: string, options?: any): AxiosPromise<GetWorkspaceCurrentSubscriptionDetailResponseModel> {
            return localVarFp.createWorkspaceFreemiumSubscriptionV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get payment checkout session for the provided checkout session ID.
         * @summary Get Checkout Session
         * @param {string} checkoutSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceCheckoutSessionV1(checkoutSessionId: string, options?: any): AxiosPromise<CheckoutSessionResponseModel> {
            return localVarFp.getWorkspaceCheckoutSessionV1(checkoutSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get workspace current Stripe subscription.
         * @summary Get Workspace Current Subscription Detail
         * @param {any} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceCurrentSubscriptionDetailV1(workspaceId: any, options?: any): AxiosPromise<GetWorkspaceCurrentSubscriptionDetailResponseModel> {
            return localVarFp.getWorkspaceCurrentSubscriptionDetailV1(workspaceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentRoutesApi - object-oriented interface
 * @export
 * @class PaymentRoutesApi
 * @extends {BaseAPI}
 */
export class PaymentRoutesApi extends BaseAPI {
    /**
     * Create workspace billing portal session to manage subscription.
     * @summary Create Workspace Billing Portal Session
     * @param {string} workspaceId 
     * @param {CreateWorkspaceBillingPortalSessionRequestModel} createWorkspaceBillingPortalSessionRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRoutesApi
     */
    public createWorkspaceBillingPortalSessionV1(workspaceId: string, createWorkspaceBillingPortalSessionRequestModel: CreateWorkspaceBillingPortalSessionRequestModel, options?: AxiosRequestConfig) {
        return PaymentRoutesApiFp(this.configuration).createWorkspaceBillingPortalSessionV1(workspaceId, createWorkspaceBillingPortalSessionRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a payment checkout session for this workspace.
     * @summary Create Workspace Checkout Session
     * @param {string} workspaceId 
     * @param {PaymentSubscriptionType} subscriptionType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRoutesApi
     */
    public createWorkspaceCheckoutSessionV1(workspaceId: string, subscriptionType: PaymentSubscriptionType, options?: AxiosRequestConfig) {
        return PaymentRoutesApiFp(this.configuration).createWorkspaceCheckoutSessionV1(workspaceId, subscriptionType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a freemium subscription for the workspace.
     * @summary Create Workspace Freemium Subscription
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRoutesApi
     */
    public createWorkspaceFreemiumSubscriptionV1(workspaceId: string, options?: AxiosRequestConfig) {
        return PaymentRoutesApiFp(this.configuration).createWorkspaceFreemiumSubscriptionV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get payment checkout session for the provided checkout session ID.
     * @summary Get Checkout Session
     * @param {string} checkoutSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRoutesApi
     */
    public getWorkspaceCheckoutSessionV1(checkoutSessionId: string, options?: AxiosRequestConfig) {
        return PaymentRoutesApiFp(this.configuration).getWorkspaceCheckoutSessionV1(checkoutSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get workspace current Stripe subscription.
     * @summary Get Workspace Current Subscription Detail
     * @param {any} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRoutesApi
     */
    public getWorkspaceCurrentSubscriptionDetailV1(workspaceId: any, options?: AxiosRequestConfig) {
        return PaymentRoutesApiFp(this.configuration).getWorkspaceCurrentSubscriptionDetailV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentWebhookRoutesApi - axios parameter creator
 * @export
 */
export const PaymentWebhookRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Webhook endpoint to receive Stripe events.
         * @summary Handle Stripe Webhook Event
         * @param {object} body 
         * @param {string} [stripeSignature] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhookV1: async (body: object, stripeSignature?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('stripeWebhookV1', 'body', body)
            const localVarPath = `/haifa/v1/stripe-webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature !== undefined && stripeSignature !== null) {
                localVarHeaderParameter['stripe-signature'] = String(stripeSignature);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentWebhookRoutesApi - functional programming interface
 * @export
 */
export const PaymentWebhookRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentWebhookRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Webhook endpoint to receive Stripe events.
         * @summary Handle Stripe Webhook Event
         * @param {object} body 
         * @param {string} [stripeSignature] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeWebhookV1(body: object, stripeSignature?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeWebhookV1(body, stripeSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentWebhookRoutesApi - factory interface
 * @export
 */
export const PaymentWebhookRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentWebhookRoutesApiFp(configuration)
    return {
        /**
         * Webhook endpoint to receive Stripe events.
         * @summary Handle Stripe Webhook Event
         * @param {object} body 
         * @param {string} [stripeSignature] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhookV1(body: object, stripeSignature?: string, options?: any): AxiosPromise<any> {
            return localVarFp.stripeWebhookV1(body, stripeSignature, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentWebhookRoutesApi - object-oriented interface
 * @export
 * @class PaymentWebhookRoutesApi
 * @extends {BaseAPI}
 */
export class PaymentWebhookRoutesApi extends BaseAPI {
    /**
     * Webhook endpoint to receive Stripe events.
     * @summary Handle Stripe Webhook Event
     * @param {object} body 
     * @param {string} [stripeSignature] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentWebhookRoutesApi
     */
    public stripeWebhookV1(body: object, stripeSignature?: string, options?: AxiosRequestConfig) {
        return PaymentWebhookRoutesApiFp(this.configuration).stripeWebhookV1(body, stripeSignature, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlatformEventsManagementApi - axios parameter creator
 * @export
 */
export const PlatformEventsManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Publish event endpoint for all platform related events.
         * @summary Events publish endpoint
         * @param {Event} event 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishEventV1: async (event: Event, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'event' is not null or undefined
            assertParamExists('publishEventV1', 'event', event)
            const localVarPath = `/bangalore/v1/internal/events/publish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(event, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlatformEventsManagementApi - functional programming interface
 * @export
 */
export const PlatformEventsManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlatformEventsManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Publish event endpoint for all platform related events.
         * @summary Events publish endpoint
         * @param {Event} event 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishEventV1(event: Event, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublishPlatformEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishEventV1(event, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlatformEventsManagementApi - factory interface
 * @export
 */
export const PlatformEventsManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlatformEventsManagementApiFp(configuration)
    return {
        /**
         * Publish event endpoint for all platform related events.
         * @summary Events publish endpoint
         * @param {Event} event 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishEventV1(event: Event, options?: any): AxiosPromise<PublishPlatformEventsResponse> {
            return localVarFp.publishEventV1(event, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlatformEventsManagementApi - object-oriented interface
 * @export
 * @class PlatformEventsManagementApi
 * @extends {BaseAPI}
 */
export class PlatformEventsManagementApi extends BaseAPI {
    /**
     * Publish event endpoint for all platform related events.
     * @summary Events publish endpoint
     * @param {Event} event 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformEventsManagementApi
     */
    public publishEventV1(event: Event, options?: AxiosRequestConfig) {
        return PlatformEventsManagementApiFp(this.configuration).publishEventV1(event, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicRoutesApi - axios parameter creator
 * @export
 */
export const PublicRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * For public sharing , get app metadata
         * @summary For public sharing , get app metadata
         * @param {string} snippetId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppMetadataPublicSnippetV1: async (snippetId: string, appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('getAppMetadataPublicSnippetV1', 'snippetId', snippetId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppMetadataPublicSnippetV1', 'appId', appId)
            const localVarPath = `/reno/v1/public/snippet/{snippet_id}/app/{app_id}/get-app-metadata`
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For public sharing , get app response
         * @summary For public sharing , get app response
         * @param {string} snippetId 
         * @param {string} appId 
         * @param {ModelAppInputRequest} modelAppInputRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPublicSnippetV1: async (snippetId: string, appId: string, modelAppInputRequest: ModelAppInputRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('getAppPublicSnippetV1', 'snippetId', snippetId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppPublicSnippetV1', 'appId', appId)
            // verify required parameter 'modelAppInputRequest' is not null or undefined
            assertParamExists('getAppPublicSnippetV1', 'modelAppInputRequest', modelAppInputRequest)
            const localVarPath = `/reno/v1/public/snippet/{snippet_id}/app/{app_id}/get-app-result`
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modelAppInputRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For public sharing , save chart metadata content
         * @summary For public sharing , save chart metadata in different table to be able to read for public routes
         * @param {string} snippetId 
         * @param {string} chartId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartPublicSnippetV1: async (snippetId: string, chartId: string, start?: number, end?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('getChartPublicSnippetV1', 'snippetId', snippetId)
            // verify required parameter 'chartId' is not null or undefined
            assertParamExists('getChartPublicSnippetV1', 'chartId', chartId)
            const localVarPath = `/reno/v1/public/snippet/{snippet_id}/chart/{chart_id}/get-exp-eval-chart-data`
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)))
                .replace(`{${"chart_id"}}`, encodeURIComponent(String(chartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For publicly shared snippet , get chart metadata content
         * @summary For public snippet, get  chart metadata based on chart id
         * @param {string} snippetId 
         * @param {string} chartId 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartPublicSnippetV11: async (snippetId: string, chartId: string, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('getChartPublicSnippetV11', 'snippetId', snippetId)
            // verify required parameter 'chartId' is not null or undefined
            assertParamExists('getChartPublicSnippetV11', 'chartId', chartId)
            const localVarPath = `/vienna/v1/public/snippet/{snippet_id}/chart/{chart_id}/get-chart-data`
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)))
                .replace(`{${"chart_id"}}`, encodeURIComponent(String(chartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For publicly shared snippet , get snippet content
         * @summary For public snippet, fetch content of the snippet
         * @param {string} snippetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentPublicSnippetV1: async (snippetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('getContentPublicSnippetV1', 'snippetId', snippetId)
            const localVarPath = `/vienna/v1/public/snippet/{snippet_id}/get-content`
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicRoutesApi - functional programming interface
 * @export
 */
export const PublicRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * For public sharing , get app metadata
         * @summary For public sharing , get app metadata
         * @param {string} snippetId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppMetadataPublicSnippetV1(snippetId: string, appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAppMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppMetadataPublicSnippetV1(snippetId, appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * For public sharing , get app response
         * @summary For public sharing , get app response
         * @param {string} snippetId 
         * @param {string} appId 
         * @param {ModelAppInputRequest} modelAppInputRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppPublicSnippetV1(snippetId: string, appId: string, modelAppInputRequest: ModelAppInputRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppPublicSnippetV1(snippetId, appId, modelAppInputRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * For public sharing , save chart metadata content
         * @summary For public sharing , save chart metadata in different table to be able to read for public routes
         * @param {string} snippetId 
         * @param {string} chartId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartPublicSnippetV1(snippetId: string, chartId: string, start?: number, end?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSnippetChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartPublicSnippetV1(snippetId, chartId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * For publicly shared snippet , get chart metadata content
         * @summary For public snippet, get  chart metadata based on chart id
         * @param {string} snippetId 
         * @param {string} chartId 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartPublicSnippetV11(snippetId: string, chartId: string, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSnippetChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartPublicSnippetV11(snippetId, chartId, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * For publicly shared snippet , get snippet content
         * @summary For public snippet, fetch content of the snippet
         * @param {string} snippetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentPublicSnippetV1(snippetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSnippetContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentPublicSnippetV1(snippetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicRoutesApi - factory interface
 * @export
 */
export const PublicRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicRoutesApiFp(configuration)
    return {
        /**
         * For public sharing , get app metadata
         * @summary For public sharing , get app metadata
         * @param {string} snippetId 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppMetadataPublicSnippetV1(snippetId: string, appId: string, options?: any): AxiosPromise<GetAppMetadataResponse> {
            return localVarFp.getAppMetadataPublicSnippetV1(snippetId, appId, options).then((request) => request(axios, basePath));
        },
        /**
         * For public sharing , get app response
         * @summary For public sharing , get app response
         * @param {string} snippetId 
         * @param {string} appId 
         * @param {ModelAppInputRequest} modelAppInputRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPublicSnippetV1(snippetId: string, appId: string, modelAppInputRequest: ModelAppInputRequest, options?: any): AxiosPromise<GetAppResponse> {
            return localVarFp.getAppPublicSnippetV1(snippetId, appId, modelAppInputRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * For public sharing , save chart metadata content
         * @summary For public sharing , save chart metadata in different table to be able to read for public routes
         * @param {string} snippetId 
         * @param {string} chartId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartPublicSnippetV1(snippetId: string, chartId: string, start?: number, end?: number, options?: any): AxiosPromise<GetSnippetChartResponse> {
            return localVarFp.getChartPublicSnippetV1(snippetId, chartId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * For publicly shared snippet , get chart metadata content
         * @summary For public snippet, get  chart metadata based on chart id
         * @param {string} snippetId 
         * @param {string} chartId 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartPublicSnippetV11(snippetId: string, chartId: string, page?: number, limit?: number, options?: any): AxiosPromise<GetSnippetChartResponse> {
            return localVarFp.getChartPublicSnippetV11(snippetId, chartId, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * For publicly shared snippet , get snippet content
         * @summary For public snippet, fetch content of the snippet
         * @param {string} snippetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentPublicSnippetV1(snippetId: string, options?: any): AxiosPromise<GetSnippetContentResponse> {
            return localVarFp.getContentPublicSnippetV1(snippetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicRoutesApi - object-oriented interface
 * @export
 * @class PublicRoutesApi
 * @extends {BaseAPI}
 */
export class PublicRoutesApi extends BaseAPI {
    /**
     * For public sharing , get app metadata
     * @summary For public sharing , get app metadata
     * @param {string} snippetId 
     * @param {string} appId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicRoutesApi
     */
    public getAppMetadataPublicSnippetV1(snippetId: string, appId: string, options?: AxiosRequestConfig) {
        return PublicRoutesApiFp(this.configuration).getAppMetadataPublicSnippetV1(snippetId, appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * For public sharing , get app response
     * @summary For public sharing , get app response
     * @param {string} snippetId 
     * @param {string} appId 
     * @param {ModelAppInputRequest} modelAppInputRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicRoutesApi
     */
    public getAppPublicSnippetV1(snippetId: string, appId: string, modelAppInputRequest: ModelAppInputRequest, options?: AxiosRequestConfig) {
        return PublicRoutesApiFp(this.configuration).getAppPublicSnippetV1(snippetId, appId, modelAppInputRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * For public sharing , save chart metadata content
     * @summary For public sharing , save chart metadata in different table to be able to read for public routes
     * @param {string} snippetId 
     * @param {string} chartId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicRoutesApi
     */
    public getChartPublicSnippetV1(snippetId: string, chartId: string, start?: number, end?: number, options?: AxiosRequestConfig) {
        return PublicRoutesApiFp(this.configuration).getChartPublicSnippetV1(snippetId, chartId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * For publicly shared snippet , get chart metadata content
     * @summary For public snippet, get  chart metadata based on chart id
     * @param {string} snippetId 
     * @param {string} chartId 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicRoutesApi
     */
    public getChartPublicSnippetV11(snippetId: string, chartId: string, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return PublicRoutesApiFp(this.configuration).getChartPublicSnippetV11(snippetId, chartId, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * For publicly shared snippet , get snippet content
     * @summary For public snippet, fetch content of the snippet
     * @param {string} snippetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicRoutesApi
     */
    public getContentPublicSnippetV1(snippetId: string, options?: AxiosRequestConfig) {
        return PublicRoutesApiFp(this.configuration).getContentPublicSnippetV1(snippetId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RetailAppApi - axios parameter creator
 * @export
 */
export const RetailAppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a category to an existing retail project
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {AddCategoryToProjectRequest} addCategoryToProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCategoryToRetailProjectV2: async (workspaceId: string, projectId: string, addCategoryToProjectRequest: AddCategoryToProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addCategoryToRetailProjectV2', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addCategoryToRetailProjectV2', 'projectId', projectId)
            // verify required parameter 'addCategoryToProjectRequest' is not null or undefined
            assertParamExists('addCategoryToRetailProjectV2', 'addCategoryToProjectRequest', addCategoryToProjectRequest)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/project/{project_id}/category/add`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCategoryToProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds a new product to the database.
         * @summary Add a new product.
         * @param {string} workspaceId 
         * @param {AddRetailProductRequest} addRetailProductRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductV2: async (workspaceId: string, addRetailProductRequest: AddRetailProductRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addProductV2', 'workspaceId', workspaceId)
            // verify required parameter 'addRetailProductRequest' is not null or undefined
            assertParamExists('addProductV2', 'addRetailProductRequest', addRetailProductRequest)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/product/add`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addRetailProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new retail project.
         * @summary Create a new retail project.
         * @param {string} workspaceId 
         * @param {AddRetailProjectRequest} addRetailProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRetailProjectV2: async (workspaceId: string, addRetailProjectRequest: AddRetailProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addRetailProjectV2', 'workspaceId', workspaceId)
            // verify required parameter 'addRetailProjectRequest' is not null or undefined
            assertParamExists('addRetailProjectV2', 'addRetailProjectRequest', addRetailProjectRequest)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/project/add`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addRetailProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates keywords for the given product
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProductKeywordsV2: async (workspaceId: string, productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('generateProductKeywordsV2', 'workspaceId', workspaceId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('generateProductKeywordsV2', 'productId', productId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/product/{product_id}/generate-keywords`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves change events for a specific product.
         * @summary Get product change events.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {string} [startDate] The start date from which change events need to be recorded. Cannot be less than 30 days from now.
         * @param {string} [endDate] The end date after which change events need to be stopped. Cannot be more than current time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductChangeEventsV2: async (workspaceId: string, productId: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProductChangeEventsV2', 'workspaceId', workspaceId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductChangeEventsV2', 'productId', productId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/product/{product_id}/details/change-events`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the status of a specific product\'s competitors\' change events.
         * @summary Get product competitors\' change events status.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCompetitorsChangeEventsStatusV2: async (workspaceId: string, productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProductCompetitorsChangeEventsStatusV2', 'workspaceId', workspaceId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductCompetitorsChangeEventsStatusV2', 'productId', productId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/product/{product_id}/competitors/change-events/status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves change events for a specific product\'s competitors.
         * @summary Get product\'s competitors\' change events.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {string} [startDate] The start date from which change events need to be recorded. Cannot be less than 30 days from now.
         * @param {string} [endDate] The end date after which change events need to be stopped. Cannot be more than current time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCompetitorsChangeEventsV2: async (workspaceId: string, productId: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProductCompetitorsChangeEventsV2', 'workspaceId', workspaceId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductCompetitorsChangeEventsV2', 'productId', productId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/product/{product_id}/competitors/change-events`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves current details for a specific product.
         * @summary Get product current details.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCurrentDetailsV2: async (workspaceId: string, productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProductCurrentDetailsV2', 'workspaceId', workspaceId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductCurrentDetailsV2', 'productId', productId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/product/{product_id}/details/current`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the information of a specific product.
         * @summary Get product information.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInfoV2: async (workspaceId: string, productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProductInfoV2', 'workspaceId', workspaceId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductInfoV2', 'productId', productId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/product/{product_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product keyword analysis results.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductKeywordAnalysisV2: async (workspaceId: string, productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProductKeywordAnalysisV2', 'workspaceId', workspaceId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductKeywordAnalysisV2', 'productId', productId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/product/{product_id}/keyword-analysis`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of products with optional filters, sorting, and pagination.
         * @summary Returns a list of products.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {string} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductListV2: async (workspaceId: string, listingAPIParams: ListingAPIParams, projectId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProductListV2', 'workspaceId', workspaceId)
            // verify required parameter 'listingAPIParams' is not null or undefined
            assertParamExists('getProductListV2', 'listingAPIParams', listingAPIParams)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/products`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingAPIParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves recommendations for a specific product.
         * @summary Get product recommendations.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductRecommendationsV2: async (workspaceId: string, productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProductRecommendationsV2', 'workspaceId', workspaceId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductRecommendationsV2', 'productId', productId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/product/{product_id}/details/recommendations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves review analysis status for a specific product.
         * @summary Get product review analysis status
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReviewAnalysisStatusV1: async (workspaceId: string, productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProductReviewAnalysisStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductReviewAnalysisStatusV1', 'productId', productId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/product/{product_id}/review-analysis/status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves review analysis for a specific product.
         * @summary Get product review analysis
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReviewAnalysisV1: async (workspaceId: string, productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProductReviewAnalysisV1', 'workspaceId', workspaceId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductReviewAnalysisV1', 'productId', productId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/product/{product_id}/review-analysis`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the status of a specific product.
         * @summary Get product status.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductStatusV2: async (workspaceId: string, productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProductStatusV2', 'workspaceId', workspaceId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductStatusV2', 'productId', productId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/product/{product_id}/details/status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all supported filters for retail products of a workspace.
         * @summary Returns all retail product filters.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRetailProductListingFiltersV2: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getRetailProductListingFiltersV2', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/product-filters`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a retail project given its project_id
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRetailProjectV2: async (workspaceId: string, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getRetailProjectV2', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getRetailProjectV2', 'projectId', projectId)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/project/{project_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists all projects in the workspace.
         * @summary List all retail projects.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRetailProjectsV2: async (workspaceId: string, listingAPIParams: ListingAPIParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listRetailProjectsV2', 'workspaceId', workspaceId)
            // verify required parameter 'listingAPIParams' is not null or undefined
            assertParamExists('listRetailProjectsV2', 'listingAPIParams', listingAPIParams)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/project/list`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingAPIParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the information of an existing product.
         * @summary Update product information.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {UpdateRetailProductRequest} updateRetailProductRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductV2: async (workspaceId: string, productId: string, updateRetailProductRequest: UpdateRetailProductRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateProductV2', 'workspaceId', workspaceId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('updateProductV2', 'productId', productId)
            // verify required parameter 'updateRetailProductRequest' is not null or undefined
            assertParamExists('updateProductV2', 'updateRetailProductRequest', updateRetailProductRequest)
            const localVarPath = `/reno/v2/workspace/{workspace_id}/retail-ai/product/{product_id}/update`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRetailProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RetailAppApi - functional programming interface
 * @export
 */
export const RetailAppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RetailAppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds a category to an existing retail project
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {AddCategoryToProjectRequest} addCategoryToProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCategoryToRetailProjectV2(workspaceId: string, projectId: string, addCategoryToProjectRequest: AddCategoryToProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCategoryToProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCategoryToRetailProjectV2(workspaceId, projectId, addCategoryToProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adds a new product to the database.
         * @summary Add a new product.
         * @param {string} workspaceId 
         * @param {AddRetailProductRequest} addRetailProductRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProductV2(workspaceId: string, addRetailProductRequest: AddRetailProductRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetailProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProductV2(workspaceId, addRetailProductRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new retail project.
         * @summary Create a new retail project.
         * @param {string} workspaceId 
         * @param {AddRetailProjectRequest} addRetailProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRetailProjectV2(workspaceId: string, addRetailProjectRequest: AddRetailProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetailProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRetailProjectV2(workspaceId, addRetailProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates keywords for the given product
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateProductKeywordsV2(workspaceId: string, productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateProductKeywordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateProductKeywordsV2(workspaceId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves change events for a specific product.
         * @summary Get product change events.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {string} [startDate] The start date from which change events need to be recorded. Cannot be less than 30 days from now.
         * @param {string} [endDate] The end date after which change events need to be stopped. Cannot be more than current time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductChangeEventsV2(workspaceId: string, productId: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsChangeEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductChangeEventsV2(workspaceId, productId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the status of a specific product\'s competitors\' change events.
         * @summary Get product competitors\' change events status.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductCompetitorsChangeEventsStatusV2(workspaceId: string, productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductCompetitorsChangeEventsStatusV2(workspaceId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves change events for a specific product\'s competitors.
         * @summary Get product\'s competitors\' change events.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {string} [startDate] The start date from which change events need to be recorded. Cannot be less than 30 days from now.
         * @param {string} [endDate] The end date after which change events need to be stopped. Cannot be more than current time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductCompetitorsChangeEventsV2(workspaceId: string, productId: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCompetitorsChangeEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductCompetitorsChangeEventsV2(workspaceId, productId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves current details for a specific product.
         * @summary Get product current details.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductCurrentDetailsV2(workspaceId: string, productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductCurrentDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductCurrentDetailsV2(workspaceId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the information of a specific product.
         * @summary Get product information.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductInfoV2(workspaceId: string, productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetailProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductInfoV2(workspaceId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get product keyword analysis results.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductKeywordAnalysisV2(workspaceId: string, productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductKeywordAnalysisResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductKeywordAnalysisV2(workspaceId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of products with optional filters, sorting, and pagination.
         * @summary Returns a list of products.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {string} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductListV2(workspaceId: string, listingAPIParams: ListingAPIParams, projectId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetailProductListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductListV2(workspaceId, listingAPIParams, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves recommendations for a specific product.
         * @summary Get product recommendations.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductRecommendationsV2(workspaceId: string, productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsRecommendationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductRecommendationsV2(workspaceId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves review analysis status for a specific product.
         * @summary Get product review analysis status
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductReviewAnalysisStatusV1(workspaceId: string, productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductReviewAnalysisStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductReviewAnalysisStatusV1(workspaceId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves review analysis for a specific product.
         * @summary Get product review analysis
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductReviewAnalysisV1(workspaceId: string, productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductReviewAnalysisResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductReviewAnalysisV1(workspaceId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the status of a specific product.
         * @summary Get product status.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductStatusV2(workspaceId: string, productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductStatusV2(workspaceId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all supported filters for retail products of a workspace.
         * @summary Returns all retail product filters.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRetailProductListingFiltersV2(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllowedFilters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRetailProductListingFiltersV2(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a retail project given its project_id
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRetailProjectV2(workspaceId: string, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetailProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRetailProjectV2(workspaceId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists all projects in the workspace.
         * @summary List all retail projects.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRetailProjectsV2(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRetailProjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRetailProjectsV2(workspaceId, listingAPIParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the information of an existing product.
         * @summary Update product information.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {UpdateRetailProductRequest} updateRetailProductRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductV2(workspaceId: string, productId: string, updateRetailProductRequest: UpdateRetailProductRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetailProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProductV2(workspaceId, productId, updateRetailProductRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RetailAppApi - factory interface
 * @export
 */
export const RetailAppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RetailAppApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds a category to an existing retail project
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {AddCategoryToProjectRequest} addCategoryToProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCategoryToRetailProjectV2(workspaceId: string, projectId: string, addCategoryToProjectRequest: AddCategoryToProjectRequest, options?: any): AxiosPromise<AddCategoryToProjectResponse> {
            return localVarFp.addCategoryToRetailProjectV2(workspaceId, projectId, addCategoryToProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds a new product to the database.
         * @summary Add a new product.
         * @param {string} workspaceId 
         * @param {AddRetailProductRequest} addRetailProductRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductV2(workspaceId: string, addRetailProductRequest: AddRetailProductRequest, options?: any): AxiosPromise<RetailProduct> {
            return localVarFp.addProductV2(workspaceId, addRetailProductRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new retail project.
         * @summary Create a new retail project.
         * @param {string} workspaceId 
         * @param {AddRetailProjectRequest} addRetailProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRetailProjectV2(workspaceId: string, addRetailProjectRequest: AddRetailProjectRequest, options?: any): AxiosPromise<RetailProject> {
            return localVarFp.addRetailProjectV2(workspaceId, addRetailProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates keywords for the given product
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProductKeywordsV2(workspaceId: string, productId: string, options?: any): AxiosPromise<GenerateProductKeywordsResponse> {
            return localVarFp.generateProductKeywordsV2(workspaceId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves change events for a specific product.
         * @summary Get product change events.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {string} [startDate] The start date from which change events need to be recorded. Cannot be less than 30 days from now.
         * @param {string} [endDate] The end date after which change events need to be stopped. Cannot be more than current time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductChangeEventsV2(workspaceId: string, productId: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<ProductDetailsChangeEventsResponse> {
            return localVarFp.getProductChangeEventsV2(workspaceId, productId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the status of a specific product\'s competitors\' change events.
         * @summary Get product competitors\' change events status.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCompetitorsChangeEventsStatusV2(workspaceId: string, productId: string, options?: any): AxiosPromise<ProductDetailsStatusResponse> {
            return localVarFp.getProductCompetitorsChangeEventsStatusV2(workspaceId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves change events for a specific product\'s competitors.
         * @summary Get product\'s competitors\' change events.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {string} [startDate] The start date from which change events need to be recorded. Cannot be less than 30 days from now.
         * @param {string} [endDate] The end date after which change events need to be stopped. Cannot be more than current time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCompetitorsChangeEventsV2(workspaceId: string, productId: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<ProductCompetitorsChangeEventsResponse> {
            return localVarFp.getProductCompetitorsChangeEventsV2(workspaceId, productId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves current details for a specific product.
         * @summary Get product current details.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCurrentDetailsV2(workspaceId: string, productId: string, options?: any): AxiosPromise<GetProductCurrentDetailsResponse> {
            return localVarFp.getProductCurrentDetailsV2(workspaceId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the information of a specific product.
         * @summary Get product information.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInfoV2(workspaceId: string, productId: string, options?: any): AxiosPromise<RetailProduct> {
            return localVarFp.getProductInfoV2(workspaceId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get product keyword analysis results.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductKeywordAnalysisV2(workspaceId: string, productId: string, options?: any): AxiosPromise<ProductKeywordAnalysisResponse> {
            return localVarFp.getProductKeywordAnalysisV2(workspaceId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of products with optional filters, sorting, and pagination.
         * @summary Returns a list of products.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {string} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductListV2(workspaceId: string, listingAPIParams: ListingAPIParams, projectId?: string, options?: any): AxiosPromise<RetailProductListResponse> {
            return localVarFp.getProductListV2(workspaceId, listingAPIParams, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves recommendations for a specific product.
         * @summary Get product recommendations.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductRecommendationsV2(workspaceId: string, productId: string, options?: any): AxiosPromise<ProductDetailsRecommendationsResponse> {
            return localVarFp.getProductRecommendationsV2(workspaceId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves review analysis status for a specific product.
         * @summary Get product review analysis status
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReviewAnalysisStatusV1(workspaceId: string, productId: string, options?: any): AxiosPromise<ProductReviewAnalysisStatusResponse> {
            return localVarFp.getProductReviewAnalysisStatusV1(workspaceId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves review analysis for a specific product.
         * @summary Get product review analysis
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReviewAnalysisV1(workspaceId: string, productId: string, options?: any): AxiosPromise<ProductReviewAnalysisResponse> {
            return localVarFp.getProductReviewAnalysisV1(workspaceId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the status of a specific product.
         * @summary Get product status.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductStatusV2(workspaceId: string, productId: string, options?: any): AxiosPromise<ProductDetailsStatusResponse> {
            return localVarFp.getProductStatusV2(workspaceId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all supported filters for retail products of a workspace.
         * @summary Returns all retail product filters.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRetailProductListingFiltersV2(workspaceId: string, options?: any): AxiosPromise<AllowedFilters> {
            return localVarFp.getRetailProductListingFiltersV2(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a retail project given its project_id
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRetailProjectV2(workspaceId: string, projectId: string, options?: any): AxiosPromise<RetailProject> {
            return localVarFp.getRetailProjectV2(workspaceId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists all projects in the workspace.
         * @summary List all retail projects.
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRetailProjectsV2(workspaceId: string, listingAPIParams: ListingAPIParams, options?: any): AxiosPromise<ListRetailProjectsResponse> {
            return localVarFp.listRetailProjectsV2(workspaceId, listingAPIParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the information of an existing product.
         * @summary Update product information.
         * @param {string} workspaceId 
         * @param {string} productId 
         * @param {UpdateRetailProductRequest} updateRetailProductRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductV2(workspaceId: string, productId: string, updateRetailProductRequest: UpdateRetailProductRequest, options?: any): AxiosPromise<RetailProduct> {
            return localVarFp.updateProductV2(workspaceId, productId, updateRetailProductRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RetailAppApi - object-oriented interface
 * @export
 * @class RetailAppApi
 * @extends {BaseAPI}
 */
export class RetailAppApi extends BaseAPI {
    /**
     * 
     * @summary Adds a category to an existing retail project
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {AddCategoryToProjectRequest} addCategoryToProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public addCategoryToRetailProjectV2(workspaceId: string, projectId: string, addCategoryToProjectRequest: AddCategoryToProjectRequest, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).addCategoryToRetailProjectV2(workspaceId, projectId, addCategoryToProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds a new product to the database.
     * @summary Add a new product.
     * @param {string} workspaceId 
     * @param {AddRetailProductRequest} addRetailProductRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public addProductV2(workspaceId: string, addRetailProductRequest: AddRetailProductRequest, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).addProductV2(workspaceId, addRetailProductRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new retail project.
     * @summary Create a new retail project.
     * @param {string} workspaceId 
     * @param {AddRetailProjectRequest} addRetailProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public addRetailProjectV2(workspaceId: string, addRetailProjectRequest: AddRetailProjectRequest, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).addRetailProjectV2(workspaceId, addRetailProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates keywords for the given product
     * @param {string} workspaceId 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public generateProductKeywordsV2(workspaceId: string, productId: string, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).generateProductKeywordsV2(workspaceId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves change events for a specific product.
     * @summary Get product change events.
     * @param {string} workspaceId 
     * @param {string} productId 
     * @param {string} [startDate] The start date from which change events need to be recorded. Cannot be less than 30 days from now.
     * @param {string} [endDate] The end date after which change events need to be stopped. Cannot be more than current time.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public getProductChangeEventsV2(workspaceId: string, productId: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).getProductChangeEventsV2(workspaceId, productId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the status of a specific product\'s competitors\' change events.
     * @summary Get product competitors\' change events status.
     * @param {string} workspaceId 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public getProductCompetitorsChangeEventsStatusV2(workspaceId: string, productId: string, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).getProductCompetitorsChangeEventsStatusV2(workspaceId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves change events for a specific product\'s competitors.
     * @summary Get product\'s competitors\' change events.
     * @param {string} workspaceId 
     * @param {string} productId 
     * @param {string} [startDate] The start date from which change events need to be recorded. Cannot be less than 30 days from now.
     * @param {string} [endDate] The end date after which change events need to be stopped. Cannot be more than current time.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public getProductCompetitorsChangeEventsV2(workspaceId: string, productId: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).getProductCompetitorsChangeEventsV2(workspaceId, productId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves current details for a specific product.
     * @summary Get product current details.
     * @param {string} workspaceId 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public getProductCurrentDetailsV2(workspaceId: string, productId: string, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).getProductCurrentDetailsV2(workspaceId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the information of a specific product.
     * @summary Get product information.
     * @param {string} workspaceId 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public getProductInfoV2(workspaceId: string, productId: string, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).getProductInfoV2(workspaceId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get product keyword analysis results.
     * @param {string} workspaceId 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public getProductKeywordAnalysisV2(workspaceId: string, productId: string, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).getProductKeywordAnalysisV2(workspaceId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of products with optional filters, sorting, and pagination.
     * @summary Returns a list of products.
     * @param {string} workspaceId 
     * @param {ListingAPIParams} listingAPIParams 
     * @param {string} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public getProductListV2(workspaceId: string, listingAPIParams: ListingAPIParams, projectId?: string, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).getProductListV2(workspaceId, listingAPIParams, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves recommendations for a specific product.
     * @summary Get product recommendations.
     * @param {string} workspaceId 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public getProductRecommendationsV2(workspaceId: string, productId: string, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).getProductRecommendationsV2(workspaceId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves review analysis status for a specific product.
     * @summary Get product review analysis status
     * @param {string} workspaceId 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public getProductReviewAnalysisStatusV1(workspaceId: string, productId: string, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).getProductReviewAnalysisStatusV1(workspaceId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves review analysis for a specific product.
     * @summary Get product review analysis
     * @param {string} workspaceId 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public getProductReviewAnalysisV1(workspaceId: string, productId: string, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).getProductReviewAnalysisV1(workspaceId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the status of a specific product.
     * @summary Get product status.
     * @param {string} workspaceId 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public getProductStatusV2(workspaceId: string, productId: string, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).getProductStatusV2(workspaceId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all supported filters for retail products of a workspace.
     * @summary Returns all retail product filters.
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public getRetailProductListingFiltersV2(workspaceId: string, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).getRetailProductListingFiltersV2(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a retail project given its project_id
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public getRetailProjectV2(workspaceId: string, projectId: string, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).getRetailProjectV2(workspaceId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists all projects in the workspace.
     * @summary List all retail projects.
     * @param {string} workspaceId 
     * @param {ListingAPIParams} listingAPIParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public listRetailProjectsV2(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).listRetailProjectsV2(workspaceId, listingAPIParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the information of an existing product.
     * @summary Update product information.
     * @param {string} workspaceId 
     * @param {string} productId 
     * @param {UpdateRetailProductRequest} updateRetailProductRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetailAppApi
     */
    public updateProductV2(workspaceId: string, productId: string, updateRetailProductRequest: UpdateRetailProductRequest, options?: AxiosRequestConfig) {
        return RetailAppApiFp(this.configuration).updateProductV2(workspaceId, productId, updateRetailProductRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoleBasedAccessControlApi - axios parameter creator
 * @export
 */
export const RoleBasedAccessControlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Assign a role to a user for a resource in the RBAC system.
         * @summary Assign a role to a user
         * @param {string} resourceId 
         * @param {string} roleId 
         * @param {BodyAssignRoleToUserV1} bodyAssignRoleToUserV1 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRoleToUserV1: async (resourceId: string, roleId: string, bodyAssignRoleToUserV1: BodyAssignRoleToUserV1, domain?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('assignRoleToUserV1', 'resourceId', resourceId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('assignRoleToUserV1', 'roleId', roleId)
            // verify required parameter 'bodyAssignRoleToUserV1' is not null or undefined
            assertParamExists('assignRoleToUserV1', 'bodyAssignRoleToUserV1', bodyAssignRoleToUserV1)
            const localVarPath = `/haifa/v1/internal/rbac/resource/{resource_id}/role/{role_id}`
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)))
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyAssignRoleToUserV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or update resource in the RBAC system.
         * @summary Create or update resource
         * @param {string} userId 
         * @param {CreateOrUpdateRBACResourceRequest} createOrUpdateRBACResourceRequest 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateRBACResourceV1: async (userId: string, createOrUpdateRBACResourceRequest: CreateOrUpdateRBACResourceRequest, domain?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createOrUpdateRBACResourceV1', 'userId', userId)
            // verify required parameter 'createOrUpdateRBACResourceRequest' is not null or undefined
            assertParamExists('createOrUpdateRBACResourceV1', 'createOrUpdateRBACResourceRequest', createOrUpdateRBACResourceRequest)
            const localVarPath = `/haifa/v1/internal/rbac/resource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateRBACResourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or update role for a resource in the RBAC system.
         * @summary Create or update a RBAC role. If role already present then it will be updated.
         * @param {string} resourceId 
         * @param {string} userId 
         * @param {BodyCreateOrUpdateRBACRoleV1} bodyCreateOrUpdateRBACRoleV1 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateRBACRoleV1: async (resourceId: string, userId: string, bodyCreateOrUpdateRBACRoleV1: BodyCreateOrUpdateRBACRoleV1, domain?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('createOrUpdateRBACRoleV1', 'resourceId', resourceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createOrUpdateRBACRoleV1', 'userId', userId)
            // verify required parameter 'bodyCreateOrUpdateRBACRoleV1' is not null or undefined
            assertParamExists('createOrUpdateRBACRoleV1', 'bodyCreateOrUpdateRBACRoleV1', bodyCreateOrUpdateRBACRoleV1)
            const localVarPath = `/haifa/v1/internal/rbac/resource/{resource_id}/role`
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyCreateOrUpdateRBACRoleV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or update scope for a resource in the RBAC system.
         * @summary Create or update scope
         * @param {string} resourceId 
         * @param {string} userId 
         * @param {BodyCreateOrUpdateRBACScopeV1} bodyCreateOrUpdateRBACScopeV1 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateRBACScopeV1: async (resourceId: string, userId: string, bodyCreateOrUpdateRBACScopeV1: BodyCreateOrUpdateRBACScopeV1, domain?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('createOrUpdateRBACScopeV1', 'resourceId', resourceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createOrUpdateRBACScopeV1', 'userId', userId)
            // verify required parameter 'bodyCreateOrUpdateRBACScopeV1' is not null or undefined
            assertParamExists('createOrUpdateRBACScopeV1', 'bodyCreateOrUpdateRBACScopeV1', bodyCreateOrUpdateRBACScopeV1)
            const localVarPath = `/haifa/v1/internal/rbac/resource/{resource_id}/scope`
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyCreateOrUpdateRBACScopeV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a resource in the RBAC system.
         * @summary Delete a resource
         * @param {string} resourceId 
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRBACResourceV1: async (resourceId: string, userId: string, domain?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('deleteRBACResourceV1', 'resourceId', resourceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteRBACResourceV1', 'userId', userId)
            const localVarPath = `/haifa/v1/internal/rbac/resource/{resource_id}`
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a role for a resource in the RBAC system.
         * @summary Delete a role
         * @param {string} resourceId 
         * @param {string} roleId 
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRBACRoleV1: async (resourceId: string, roleId: string, userId: string, domain?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('deleteRBACRoleV1', 'resourceId', resourceId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deleteRBACRoleV1', 'roleId', roleId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteRBACRoleV1', 'userId', userId)
            const localVarPath = `/haifa/v1/internal/rbac/resource/{resource_id}/role/{role_id}`
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)))
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a scope for a resource in the RBAC system.
         * @summary Delete a scope
         * @param {string} resourceId 
         * @param {string} scopeId 
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRBACScopeV1: async (resourceId: string, scopeId: string, userId: string, domain?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('deleteRBACScopeV1', 'resourceId', resourceId)
            // verify required parameter 'scopeId' is not null or undefined
            assertParamExists('deleteRBACScopeV1', 'scopeId', scopeId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteRBACScopeV1', 'userId', userId)
            const localVarPath = `/haifa/v1/internal/rbac/resource/{resource_id}/scope/{scope_id}`
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)))
                .replace(`{${"scope_id"}}`, encodeURIComponent(String(scopeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the resources in the RBAC system.
         * @summary Get all the resources
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRBACResourcesV1: async (userId: string, domain?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllRBACResourcesV1', 'userId', userId)
            const localVarPath = `/haifa/v1/internal/rbac/resource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the roles for a resource in the RBAC system.
         * @summary Get all the roles
         * @param {string} resourceId 
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRBACRolesV1: async (resourceId: string, userId: string, domain?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getAllRBACRolesV1', 'resourceId', resourceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllRBACRolesV1', 'userId', userId)
            const localVarPath = `/haifa/v1/internal/rbac/resource/{resource_id}/role`
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the scopes for a resource in the RBAC system.
         * @summary Get all the scopes
         * @param {string} resourceId 
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRBACScopesV1: async (resourceId: string, userId: string, domain?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getAllRBACScopesV1', 'resourceId', resourceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllRBACScopesV1', 'userId', userId)
            const localVarPath = `/haifa/v1/internal/rbac/resource/{resource_id}/scope`
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the attached role for the user for a resource in the RBAC system.
         * @summary Get the attached role for a user
         * @param {string} userId 
         * @param {string} resourceId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRolesForUserV1: async (userId: string, resourceId: string, domain?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllRolesForUserV1', 'userId', userId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getAllRolesForUserV1', 'resourceId', resourceId)
            const localVarPath = `/haifa/v1/internal/user/{user_id}/rbac/resource/{resource_id}/attached-role`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleBasedAccessControlApi - functional programming interface
 * @export
 */
export const RoleBasedAccessControlApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleBasedAccessControlApiAxiosParamCreator(configuration)
    return {
        /**
         * Assign a role to a user for a resource in the RBAC system.
         * @summary Assign a role to a user
         * @param {string} resourceId 
         * @param {string} roleId 
         * @param {BodyAssignRoleToUserV1} bodyAssignRoleToUserV1 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignRoleToUserV1(resourceId: string, roleId: string, bodyAssignRoleToUserV1: BodyAssignRoleToUserV1, domain?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RBACUserResourceRoleMappingResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignRoleToUserV1(resourceId, roleId, bodyAssignRoleToUserV1, domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or update resource in the RBAC system.
         * @summary Create or update resource
         * @param {string} userId 
         * @param {CreateOrUpdateRBACResourceRequest} createOrUpdateRBACResourceRequest 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateRBACResourceV1(userId: string, createOrUpdateRBACResourceRequest: CreateOrUpdateRBACResourceRequest, domain?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RBACResourceResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateRBACResourceV1(userId, createOrUpdateRBACResourceRequest, domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or update role for a resource in the RBAC system.
         * @summary Create or update a RBAC role. If role already present then it will be updated.
         * @param {string} resourceId 
         * @param {string} userId 
         * @param {BodyCreateOrUpdateRBACRoleV1} bodyCreateOrUpdateRBACRoleV1 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateRBACRoleV1(resourceId: string, userId: string, bodyCreateOrUpdateRBACRoleV1: BodyCreateOrUpdateRBACRoleV1, domain?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RBACResourceRoleResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateRBACRoleV1(resourceId, userId, bodyCreateOrUpdateRBACRoleV1, domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or update scope for a resource in the RBAC system.
         * @summary Create or update scope
         * @param {string} resourceId 
         * @param {string} userId 
         * @param {BodyCreateOrUpdateRBACScopeV1} bodyCreateOrUpdateRBACScopeV1 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateRBACScopeV1(resourceId: string, userId: string, bodyCreateOrUpdateRBACScopeV1: BodyCreateOrUpdateRBACScopeV1, domain?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RBACResourceScopeResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateRBACScopeV1(resourceId, userId, bodyCreateOrUpdateRBACScopeV1, domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a resource in the RBAC system.
         * @summary Delete a resource
         * @param {string} resourceId 
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRBACResourceV1(resourceId: string, userId: string, domain?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRBACResourceV1(resourceId, userId, domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a role for a resource in the RBAC system.
         * @summary Delete a role
         * @param {string} resourceId 
         * @param {string} roleId 
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRBACRoleV1(resourceId: string, roleId: string, userId: string, domain?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRBACRoleV1(resourceId, roleId, userId, domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a scope for a resource in the RBAC system.
         * @summary Delete a scope
         * @param {string} resourceId 
         * @param {string} scopeId 
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRBACScopeV1(resourceId: string, scopeId: string, userId: string, domain?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRBACScopeV1(resourceId, scopeId, userId, domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the resources in the RBAC system.
         * @summary Get all the resources
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRBACResourcesV1(userId: string, domain?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRBACResourceResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRBACResourcesV1(userId, domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the roles for a resource in the RBAC system.
         * @summary Get all the roles
         * @param {string} resourceId 
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRBACRolesV1(resourceId: string, userId: string, domain?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRBACResourceRoles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRBACRolesV1(resourceId, userId, domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the scopes for a resource in the RBAC system.
         * @summary Get all the scopes
         * @param {string} resourceId 
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRBACScopesV1(resourceId: string, userId: string, domain?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRBACResourceScopeResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRBACScopesV1(resourceId, userId, domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the attached role for the user for a resource in the RBAC system.
         * @summary Get the attached role for a user
         * @param {string} userId 
         * @param {string} resourceId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRolesForUserV1(userId: string, resourceId: string, domain?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RBACUserResourceRoleMappingResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRolesForUserV1(userId, resourceId, domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleBasedAccessControlApi - factory interface
 * @export
 */
export const RoleBasedAccessControlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleBasedAccessControlApiFp(configuration)
    return {
        /**
         * Assign a role to a user for a resource in the RBAC system.
         * @summary Assign a role to a user
         * @param {string} resourceId 
         * @param {string} roleId 
         * @param {BodyAssignRoleToUserV1} bodyAssignRoleToUserV1 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRoleToUserV1(resourceId: string, roleId: string, bodyAssignRoleToUserV1: BodyAssignRoleToUserV1, domain?: string, options?: any): AxiosPromise<RBACUserResourceRoleMappingResponseModel> {
            return localVarFp.assignRoleToUserV1(resourceId, roleId, bodyAssignRoleToUserV1, domain, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or update resource in the RBAC system.
         * @summary Create or update resource
         * @param {string} userId 
         * @param {CreateOrUpdateRBACResourceRequest} createOrUpdateRBACResourceRequest 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateRBACResourceV1(userId: string, createOrUpdateRBACResourceRequest: CreateOrUpdateRBACResourceRequest, domain?: string, options?: any): AxiosPromise<RBACResourceResponseModel> {
            return localVarFp.createOrUpdateRBACResourceV1(userId, createOrUpdateRBACResourceRequest, domain, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or update role for a resource in the RBAC system.
         * @summary Create or update a RBAC role. If role already present then it will be updated.
         * @param {string} resourceId 
         * @param {string} userId 
         * @param {BodyCreateOrUpdateRBACRoleV1} bodyCreateOrUpdateRBACRoleV1 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateRBACRoleV1(resourceId: string, userId: string, bodyCreateOrUpdateRBACRoleV1: BodyCreateOrUpdateRBACRoleV1, domain?: string, options?: any): AxiosPromise<RBACResourceRoleResponseModel> {
            return localVarFp.createOrUpdateRBACRoleV1(resourceId, userId, bodyCreateOrUpdateRBACRoleV1, domain, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or update scope for a resource in the RBAC system.
         * @summary Create or update scope
         * @param {string} resourceId 
         * @param {string} userId 
         * @param {BodyCreateOrUpdateRBACScopeV1} bodyCreateOrUpdateRBACScopeV1 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateRBACScopeV1(resourceId: string, userId: string, bodyCreateOrUpdateRBACScopeV1: BodyCreateOrUpdateRBACScopeV1, domain?: string, options?: any): AxiosPromise<RBACResourceScopeResponseModel> {
            return localVarFp.createOrUpdateRBACScopeV1(resourceId, userId, bodyCreateOrUpdateRBACScopeV1, domain, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a resource in the RBAC system.
         * @summary Delete a resource
         * @param {string} resourceId 
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRBACResourceV1(resourceId: string, userId: string, domain?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRBACResourceV1(resourceId, userId, domain, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a role for a resource in the RBAC system.
         * @summary Delete a role
         * @param {string} resourceId 
         * @param {string} roleId 
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRBACRoleV1(resourceId: string, roleId: string, userId: string, domain?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRBACRoleV1(resourceId, roleId, userId, domain, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a scope for a resource in the RBAC system.
         * @summary Delete a scope
         * @param {string} resourceId 
         * @param {string} scopeId 
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRBACScopeV1(resourceId: string, scopeId: string, userId: string, domain?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRBACScopeV1(resourceId, scopeId, userId, domain, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the resources in the RBAC system.
         * @summary Get all the resources
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRBACResourcesV1(userId: string, domain?: string, options?: any): AxiosPromise<ListRBACResourceResponseModel> {
            return localVarFp.getAllRBACResourcesV1(userId, domain, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the roles for a resource in the RBAC system.
         * @summary Get all the roles
         * @param {string} resourceId 
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRBACRolesV1(resourceId: string, userId: string, domain?: string, options?: any): AxiosPromise<ListRBACResourceRoles> {
            return localVarFp.getAllRBACRolesV1(resourceId, userId, domain, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the scopes for a resource in the RBAC system.
         * @summary Get all the scopes
         * @param {string} resourceId 
         * @param {string} userId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRBACScopesV1(resourceId: string, userId: string, domain?: string, options?: any): AxiosPromise<ListRBACResourceScopeResponseModel> {
            return localVarFp.getAllRBACScopesV1(resourceId, userId, domain, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the attached role for the user for a resource in the RBAC system.
         * @summary Get the attached role for a user
         * @param {string} userId 
         * @param {string} resourceId 
         * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRolesForUserV1(userId: string, resourceId: string, domain?: string, options?: any): AxiosPromise<RBACUserResourceRoleMappingResponseModel> {
            return localVarFp.getAllRolesForUserV1(userId, resourceId, domain, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleBasedAccessControlApi - object-oriented interface
 * @export
 * @class RoleBasedAccessControlApi
 * @extends {BaseAPI}
 */
export class RoleBasedAccessControlApi extends BaseAPI {
    /**
     * Assign a role to a user for a resource in the RBAC system.
     * @summary Assign a role to a user
     * @param {string} resourceId 
     * @param {string} roleId 
     * @param {BodyAssignRoleToUserV1} bodyAssignRoleToUserV1 
     * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleBasedAccessControlApi
     */
    public assignRoleToUserV1(resourceId: string, roleId: string, bodyAssignRoleToUserV1: BodyAssignRoleToUserV1, domain?: string, options?: AxiosRequestConfig) {
        return RoleBasedAccessControlApiFp(this.configuration).assignRoleToUserV1(resourceId, roleId, bodyAssignRoleToUserV1, domain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or update resource in the RBAC system.
     * @summary Create or update resource
     * @param {string} userId 
     * @param {CreateOrUpdateRBACResourceRequest} createOrUpdateRBACResourceRequest 
     * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleBasedAccessControlApi
     */
    public createOrUpdateRBACResourceV1(userId: string, createOrUpdateRBACResourceRequest: CreateOrUpdateRBACResourceRequest, domain?: string, options?: AxiosRequestConfig) {
        return RoleBasedAccessControlApiFp(this.configuration).createOrUpdateRBACResourceV1(userId, createOrUpdateRBACResourceRequest, domain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or update role for a resource in the RBAC system.
     * @summary Create or update a RBAC role. If role already present then it will be updated.
     * @param {string} resourceId 
     * @param {string} userId 
     * @param {BodyCreateOrUpdateRBACRoleV1} bodyCreateOrUpdateRBACRoleV1 
     * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleBasedAccessControlApi
     */
    public createOrUpdateRBACRoleV1(resourceId: string, userId: string, bodyCreateOrUpdateRBACRoleV1: BodyCreateOrUpdateRBACRoleV1, domain?: string, options?: AxiosRequestConfig) {
        return RoleBasedAccessControlApiFp(this.configuration).createOrUpdateRBACRoleV1(resourceId, userId, bodyCreateOrUpdateRBACRoleV1, domain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or update scope for a resource in the RBAC system.
     * @summary Create or update scope
     * @param {string} resourceId 
     * @param {string} userId 
     * @param {BodyCreateOrUpdateRBACScopeV1} bodyCreateOrUpdateRBACScopeV1 
     * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleBasedAccessControlApi
     */
    public createOrUpdateRBACScopeV1(resourceId: string, userId: string, bodyCreateOrUpdateRBACScopeV1: BodyCreateOrUpdateRBACScopeV1, domain?: string, options?: AxiosRequestConfig) {
        return RoleBasedAccessControlApiFp(this.configuration).createOrUpdateRBACScopeV1(resourceId, userId, bodyCreateOrUpdateRBACScopeV1, domain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a resource in the RBAC system.
     * @summary Delete a resource
     * @param {string} resourceId 
     * @param {string} userId 
     * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleBasedAccessControlApi
     */
    public deleteRBACResourceV1(resourceId: string, userId: string, domain?: string, options?: AxiosRequestConfig) {
        return RoleBasedAccessControlApiFp(this.configuration).deleteRBACResourceV1(resourceId, userId, domain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a role for a resource in the RBAC system.
     * @summary Delete a role
     * @param {string} resourceId 
     * @param {string} roleId 
     * @param {string} userId 
     * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleBasedAccessControlApi
     */
    public deleteRBACRoleV1(resourceId: string, roleId: string, userId: string, domain?: string, options?: AxiosRequestConfig) {
        return RoleBasedAccessControlApiFp(this.configuration).deleteRBACRoleV1(resourceId, roleId, userId, domain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a scope for a resource in the RBAC system.
     * @summary Delete a scope
     * @param {string} resourceId 
     * @param {string} scopeId 
     * @param {string} userId 
     * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleBasedAccessControlApi
     */
    public deleteRBACScopeV1(resourceId: string, scopeId: string, userId: string, domain?: string, options?: AxiosRequestConfig) {
        return RoleBasedAccessControlApiFp(this.configuration).deleteRBACScopeV1(resourceId, scopeId, userId, domain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the resources in the RBAC system.
     * @summary Get all the resources
     * @param {string} userId 
     * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleBasedAccessControlApi
     */
    public getAllRBACResourcesV1(userId: string, domain?: string, options?: AxiosRequestConfig) {
        return RoleBasedAccessControlApiFp(this.configuration).getAllRBACResourcesV1(userId, domain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the roles for a resource in the RBAC system.
     * @summary Get all the roles
     * @param {string} resourceId 
     * @param {string} userId 
     * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleBasedAccessControlApi
     */
    public getAllRBACRolesV1(resourceId: string, userId: string, domain?: string, options?: AxiosRequestConfig) {
        return RoleBasedAccessControlApiFp(this.configuration).getAllRBACRolesV1(resourceId, userId, domain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the scopes for a resource in the RBAC system.
     * @summary Get all the scopes
     * @param {string} resourceId 
     * @param {string} userId 
     * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleBasedAccessControlApi
     */
    public getAllRBACScopesV1(resourceId: string, userId: string, domain?: string, options?: AxiosRequestConfig) {
        return RoleBasedAccessControlApiFp(this.configuration).getAllRBACScopesV1(resourceId, userId, domain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the attached role for the user for a resource in the RBAC system.
     * @summary Get the attached role for a user
     * @param {string} userId 
     * @param {string} resourceId 
     * @param {string} [domain] The domain of the resource where RBAC is applied. Default is markov global. If user wants to get resources for a specific domain, then the domain would be user\&#39;s personal workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleBasedAccessControlApi
     */
    public getAllRolesForUserV1(userId: string, resourceId: string, domain?: string, options?: AxiosRequestConfig) {
        return RoleBasedAccessControlApiFp(this.configuration).getAllRolesForUserV1(userId, resourceId, domain, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RuleBasedRelabelingRoutesApi - axios parameter creator
 * @export
 */
export const RuleBasedRelabelingRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint provides a sample from the specified dataset with the relabeling rules applied.
         * @summary Preview Dataset Sample with Applied Rules
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyRelabelingRulesV1: async (workspaceId: string, datasetId: string, dataOperationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('applyRelabelingRulesV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('applyRelabelingRulesV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('applyRelabelingRulesV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/rule-relabeling/{data_operation_id}/preview-rules-application`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Conflicts with other rules of the data_operation are checked. If there is a conflict rule_id of the conflicting rules are returned
         * @summary Check if a rule has conflicts with other rules of the data_operation
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} ruleId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRuleConflictV1: async (workspaceId: string, datasetId: string, ruleId: string, dataOperationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('checkRuleConflictV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('checkRuleConflictV1', 'datasetId', datasetId)
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('checkRuleConflictV1', 'ruleId', ruleId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('checkRuleConflictV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/rule-relabeling/{data_operation_id}/rule/{rule_id}/check-conflict`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"rule_id"}}`, encodeURIComponent(String(ruleId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new version of dataset with resolved recommendations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewDatasetRuleRelabelingV1: async (workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createNewDatasetRuleRelabelingV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('createNewDatasetRuleRelabelingV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('createNewDatasetRuleRelabelingV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'createNewVersionDatasetRequest' is not null or undefined
            assertParamExists('createNewDatasetRuleRelabelingV1', 'createNewVersionDatasetRequest', createNewVersionDatasetRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/rule-relabeling/{data_operation_id}/register-new-version`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNewVersionDatasetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch rule coverage summary for active rules
         * @summary Fetch rule coverage summary for all the rules. Coverage summary would only contain details for rules in \'ACTIVE\' state
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleCoverageSummaryV1: async (workspaceId: string, datasetId: string, dataOperationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('ruleCoverageSummaryV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('ruleCoverageSummaryV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('ruleCoverageSummaryV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/rule-relabeling/{data_operation_id}/rule-coverage-summary`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RuleBasedRelabelingRoutesApi - functional programming interface
 * @export
 */
export const RuleBasedRelabelingRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RuleBasedRelabelingRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint provides a sample from the specified dataset with the relabeling rules applied.
         * @summary Preview Dataset Sample with Applied Rules
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyRelabelingRulesV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuleApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyRelabelingRulesV1(workspaceId, datasetId, dataOperationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Conflicts with other rules of the data_operation are checked. If there is a conflict rule_id of the conflicting rules are returned
         * @summary Check if a rule has conflicts with other rules of the data_operation
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} ruleId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkRuleConflictV1(workspaceId: string, datasetId: string, ruleId: string, dataOperationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuleCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkRuleConflictV1(workspaceId, datasetId, ruleId, dataOperationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new version of dataset with resolved recommendations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewDatasetRuleRelabelingV1(workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNewVersionDatasetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewDatasetRuleRelabelingV1(workspaceId, datasetId, dataOperationId, createNewVersionDatasetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch rule coverage summary for active rules
         * @summary Fetch rule coverage summary for all the rules. Coverage summary would only contain details for rules in \'ACTIVE\' state
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ruleCoverageSummaryV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuleCoverageSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ruleCoverageSummaryV1(workspaceId, datasetId, dataOperationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RuleBasedRelabelingRoutesApi - factory interface
 * @export
 */
export const RuleBasedRelabelingRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RuleBasedRelabelingRoutesApiFp(configuration)
    return {
        /**
         * This endpoint provides a sample from the specified dataset with the relabeling rules applied.
         * @summary Preview Dataset Sample with Applied Rules
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyRelabelingRulesV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: any): AxiosPromise<RuleApplicationResponse> {
            return localVarFp.applyRelabelingRulesV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Conflicts with other rules of the data_operation are checked. If there is a conflict rule_id of the conflicting rules are returned
         * @summary Check if a rule has conflicts with other rules of the data_operation
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} ruleId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRuleConflictV1(workspaceId: string, datasetId: string, ruleId: string, dataOperationId: string, options?: any): AxiosPromise<RuleCheckResponse> {
            return localVarFp.checkRuleConflictV1(workspaceId, datasetId, ruleId, dataOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new version of dataset with resolved recommendations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewDatasetRuleRelabelingV1(workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options?: any): AxiosPromise<CreateNewVersionDatasetResponse> {
            return localVarFp.createNewDatasetRuleRelabelingV1(workspaceId, datasetId, dataOperationId, createNewVersionDatasetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch rule coverage summary for active rules
         * @summary Fetch rule coverage summary for all the rules. Coverage summary would only contain details for rules in \'ACTIVE\' state
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleCoverageSummaryV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: any): AxiosPromise<RuleCoverageSummaryResponse> {
            return localVarFp.ruleCoverageSummaryV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RuleBasedRelabelingRoutesApi - object-oriented interface
 * @export
 * @class RuleBasedRelabelingRoutesApi
 * @extends {BaseAPI}
 */
export class RuleBasedRelabelingRoutesApi extends BaseAPI {
    /**
     * This endpoint provides a sample from the specified dataset with the relabeling rules applied.
     * @summary Preview Dataset Sample with Applied Rules
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleBasedRelabelingRoutesApi
     */
    public applyRelabelingRulesV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig) {
        return RuleBasedRelabelingRoutesApiFp(this.configuration).applyRelabelingRulesV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Conflicts with other rules of the data_operation are checked. If there is a conflict rule_id of the conflicting rules are returned
     * @summary Check if a rule has conflicts with other rules of the data_operation
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} ruleId 
     * @param {string} dataOperationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleBasedRelabelingRoutesApi
     */
    public checkRuleConflictV1(workspaceId: string, datasetId: string, ruleId: string, dataOperationId: string, options?: AxiosRequestConfig) {
        return RuleBasedRelabelingRoutesApiFp(this.configuration).checkRuleConflictV1(workspaceId, datasetId, ruleId, dataOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new version of dataset with resolved recommendations
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleBasedRelabelingRoutesApi
     */
    public createNewDatasetRuleRelabelingV1(workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options?: AxiosRequestConfig) {
        return RuleBasedRelabelingRoutesApiFp(this.configuration).createNewDatasetRuleRelabelingV1(workspaceId, datasetId, dataOperationId, createNewVersionDatasetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch rule coverage summary for active rules
     * @summary Fetch rule coverage summary for all the rules. Coverage summary would only contain details for rules in \'ACTIVE\' state
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleBasedRelabelingRoutesApi
     */
    public ruleCoverageSummaryV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig) {
        return RuleBasedRelabelingRoutesApiFp(this.configuration).ruleCoverageSummaryV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScopedTokensApi - axios parameter creator
 * @export
 */
export const ScopedTokensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Validate Scoped Token and get it\'s details
         * @summary Fetch Validate Scoped Token
         * @param {ScopedTokenValidateRequestModel} scopedTokenValidateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAndValidateScopedTokenV1: async (scopedTokenValidateRequestModel: ScopedTokenValidateRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scopedTokenValidateRequestModel' is not null or undefined
            assertParamExists('getAndValidateScopedTokenV1', 'scopedTokenValidateRequestModel', scopedTokenValidateRequestModel)
            const localVarPath = `/tokyo/v1/scoped-token/fetch-and-validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scopedTokenValidateRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate Scoped Token for a resource
         * @summary Get Scoped Token
         * @param {ScopedTokenRequestModel} scopedTokenRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopedTokenV1: async (scopedTokenRequestModel: ScopedTokenRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scopedTokenRequestModel' is not null or undefined
            assertParamExists('getScopedTokenV1', 'scopedTokenRequestModel', scopedTokenRequestModel)
            const localVarPath = `/tokyo/v1/scoped-token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scopedTokenRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Revoke Scoped Token
         * @summary Revoke Scoped Token
         * @param {ScopedTokenRevokeRequestModel} scopedTokenRevokeRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeScopedTokenV1: async (scopedTokenRevokeRequestModel: ScopedTokenRevokeRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scopedTokenRevokeRequestModel' is not null or undefined
            assertParamExists('revokeScopedTokenV1', 'scopedTokenRevokeRequestModel', scopedTokenRevokeRequestModel)
            const localVarPath = `/tokyo/v1/scoped-token/revoke`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scopedTokenRevokeRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate Scoped Token
         * @summary Validate Scoped Token
         * @param {ScopedTokenValidateRequestModel} scopedTokenValidateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateScopedTokenV1: async (scopedTokenValidateRequestModel: ScopedTokenValidateRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scopedTokenValidateRequestModel' is not null or undefined
            assertParamExists('validateScopedTokenV1', 'scopedTokenValidateRequestModel', scopedTokenValidateRequestModel)
            const localVarPath = `/tokyo/v1/scoped-token/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scopedTokenValidateRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScopedTokensApi - functional programming interface
 * @export
 */
export const ScopedTokensApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScopedTokensApiAxiosParamCreator(configuration)
    return {
        /**
         * Validate Scoped Token and get it\'s details
         * @summary Fetch Validate Scoped Token
         * @param {ScopedTokenValidateRequestModel} scopedTokenValidateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAndValidateScopedTokenV1(scopedTokenValidateRequestModel: ScopedTokenValidateRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScopedTokenValidateResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAndValidateScopedTokenV1(scopedTokenValidateRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate Scoped Token for a resource
         * @summary Get Scoped Token
         * @param {ScopedTokenRequestModel} scopedTokenRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScopedTokenV1(scopedTokenRequestModel: ScopedTokenRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScopedTokenResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScopedTokenV1(scopedTokenRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Revoke Scoped Token
         * @summary Revoke Scoped Token
         * @param {ScopedTokenRevokeRequestModel} scopedTokenRevokeRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeScopedTokenV1(scopedTokenRevokeRequestModel: ScopedTokenRevokeRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScopedTokenRevokeResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeScopedTokenV1(scopedTokenRevokeRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validate Scoped Token
         * @summary Validate Scoped Token
         * @param {ScopedTokenValidateRequestModel} scopedTokenValidateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateScopedTokenV1(scopedTokenValidateRequestModel: ScopedTokenValidateRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScopedTokenValidateResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateScopedTokenV1(scopedTokenValidateRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScopedTokensApi - factory interface
 * @export
 */
export const ScopedTokensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScopedTokensApiFp(configuration)
    return {
        /**
         * Validate Scoped Token and get it\'s details
         * @summary Fetch Validate Scoped Token
         * @param {ScopedTokenValidateRequestModel} scopedTokenValidateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAndValidateScopedTokenV1(scopedTokenValidateRequestModel: ScopedTokenValidateRequestModel, options?: any): AxiosPromise<ScopedTokenValidateResponseModel> {
            return localVarFp.getAndValidateScopedTokenV1(scopedTokenValidateRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate Scoped Token for a resource
         * @summary Get Scoped Token
         * @param {ScopedTokenRequestModel} scopedTokenRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopedTokenV1(scopedTokenRequestModel: ScopedTokenRequestModel, options?: any): AxiosPromise<ScopedTokenResponseModel> {
            return localVarFp.getScopedTokenV1(scopedTokenRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Revoke Scoped Token
         * @summary Revoke Scoped Token
         * @param {ScopedTokenRevokeRequestModel} scopedTokenRevokeRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeScopedTokenV1(scopedTokenRevokeRequestModel: ScopedTokenRevokeRequestModel, options?: any): AxiosPromise<ScopedTokenRevokeResponseModel> {
            return localVarFp.revokeScopedTokenV1(scopedTokenRevokeRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Validate Scoped Token
         * @summary Validate Scoped Token
         * @param {ScopedTokenValidateRequestModel} scopedTokenValidateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateScopedTokenV1(scopedTokenValidateRequestModel: ScopedTokenValidateRequestModel, options?: any): AxiosPromise<ScopedTokenValidateResponseModel> {
            return localVarFp.validateScopedTokenV1(scopedTokenValidateRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScopedTokensApi - object-oriented interface
 * @export
 * @class ScopedTokensApi
 * @extends {BaseAPI}
 */
export class ScopedTokensApi extends BaseAPI {
    /**
     * Validate Scoped Token and get it\'s details
     * @summary Fetch Validate Scoped Token
     * @param {ScopedTokenValidateRequestModel} scopedTokenValidateRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopedTokensApi
     */
    public getAndValidateScopedTokenV1(scopedTokenValidateRequestModel: ScopedTokenValidateRequestModel, options?: AxiosRequestConfig) {
        return ScopedTokensApiFp(this.configuration).getAndValidateScopedTokenV1(scopedTokenValidateRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate Scoped Token for a resource
     * @summary Get Scoped Token
     * @param {ScopedTokenRequestModel} scopedTokenRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopedTokensApi
     */
    public getScopedTokenV1(scopedTokenRequestModel: ScopedTokenRequestModel, options?: AxiosRequestConfig) {
        return ScopedTokensApiFp(this.configuration).getScopedTokenV1(scopedTokenRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Revoke Scoped Token
     * @summary Revoke Scoped Token
     * @param {ScopedTokenRevokeRequestModel} scopedTokenRevokeRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopedTokensApi
     */
    public revokeScopedTokenV1(scopedTokenRevokeRequestModel: ScopedTokenRevokeRequestModel, options?: AxiosRequestConfig) {
        return ScopedTokensApiFp(this.configuration).revokeScopedTokenV1(scopedTokenRevokeRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate Scoped Token
     * @summary Validate Scoped Token
     * @param {ScopedTokenValidateRequestModel} scopedTokenValidateRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopedTokensApi
     */
    public validateScopedTokenV1(scopedTokenValidateRequestModel: ScopedTokenValidateRequestModel, options?: AxiosRequestConfig) {
        return ScopedTokensApiFp(this.configuration).validateScopedTokenV1(scopedTokenValidateRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchAppCopilotChatAPIsApi - axios parameter creator
 * @export
 */
export const SearchAppCopilotChatAPIsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete thread conversations and thread for the given Thread ID.
         * @param {string} workspaceId 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConversationThreadV1: async (workspaceId: string, threadId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteConversationThreadV1', 'workspaceId', workspaceId)
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('deleteConversationThreadV1', 'threadId', threadId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/search/chat/thread/{thread_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"thread_id"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} workflowId 
         * @param {string} workspaceId 
         * @param {ChatConversationRequestModel} chatConversationRequestModel 
         * @param {boolean} [summarizeWithLlm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowSearchChatResponseV1: async (workflowId: string, workspaceId: string, chatConversationRequestModel: ChatConversationRequestModel, summarizeWithLlm?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getWorkflowSearchChatResponseV1', 'workflowId', workflowId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkflowSearchChatResponseV1', 'workspaceId', workspaceId)
            // verify required parameter 'chatConversationRequestModel' is not null or undefined
            assertParamExists('getWorkflowSearchChatResponseV1', 'chatConversationRequestModel', chatConversationRequestModel)
            const localVarPath = `/vienna/v2/workspace/{workspace_id}/workflow/{workflow_id}/chat/similar-points`
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (summarizeWithLlm !== undefined) {
                localVarQueryParameter['summarize_with_llm'] = summarizeWithLlm;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatConversationRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a paginated list of threads for data source llm conversations for a user
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChatConversationThreads: async (workspaceId: string, resourceId: string, start?: number, end?: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listChatConversationThreads', 'workspaceId', workspaceId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('listChatConversationThreads', 'resourceId', resourceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/chat/resources/{resource_id}/threads`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of conversations for a given thread IDs and user.
         * @param {string} workspaceId 
         * @param {string} threadId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChatConversationsV2: async (workspaceId: string, threadId: string, start?: number, end?: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listChatConversationsV2', 'workspaceId', workspaceId)
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('listChatConversationsV2', 'threadId', threadId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/search/chat/thread/{thread_id}/conversations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"thread_id"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update thread artifact state for the given Thread IDs.
         * @param {string} workspaceId 
         * @param {UpdateChatConversationThreadsArtifactStateRequestModel} updateChatConversationThreadsArtifactStateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChatConversationThreadsArtifactStateV1: async (workspaceId: string, updateChatConversationThreadsArtifactStateRequestModel: UpdateChatConversationThreadsArtifactStateRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateChatConversationThreadsArtifactStateV1', 'workspaceId', workspaceId)
            // verify required parameter 'updateChatConversationThreadsArtifactStateRequestModel' is not null or undefined
            assertParamExists('updateChatConversationThreadsArtifactStateV1', 'updateChatConversationThreadsArtifactStateRequestModel', updateChatConversationThreadsArtifactStateRequestModel)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/search/chat/thread/artifact-state`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChatConversationThreadsArtifactStateRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchAppCopilotChatAPIsApi - functional programming interface
 * @export
 */
export const SearchAppCopilotChatAPIsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchAppCopilotChatAPIsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete thread conversations and thread for the given Thread ID.
         * @param {string} workspaceId 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConversationThreadV1(workspaceId: string, threadId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteConversationThreadResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConversationThreadV1(workspaceId, threadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} workflowId 
         * @param {string} workspaceId 
         * @param {ChatConversationRequestModel} chatConversationRequestModel 
         * @param {boolean} [summarizeWithLlm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowSearchChatResponseV1(workflowId: string, workspaceId: string, chatConversationRequestModel: ChatConversationRequestModel, summarizeWithLlm?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatConversationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowSearchChatResponseV1(workflowId, workspaceId, chatConversationRequestModel, summarizeWithLlm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a paginated list of threads for data source llm conversations for a user
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listChatConversationThreads(workspaceId: string, resourceId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListChatConversationThreadsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listChatConversationThreads(workspaceId, resourceId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of conversations for a given thread IDs and user.
         * @param {string} workspaceId 
         * @param {string} threadId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listChatConversationsV2(workspaceId: string, threadId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListChatConversationModels>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listChatConversationsV2(workspaceId, threadId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update thread artifact state for the given Thread IDs.
         * @param {string} workspaceId 
         * @param {UpdateChatConversationThreadsArtifactStateRequestModel} updateChatConversationThreadsArtifactStateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChatConversationThreadsArtifactStateV1(workspaceId: string, updateChatConversationThreadsArtifactStateRequestModel: UpdateChatConversationThreadsArtifactStateRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateChatConversationThreadsArtifactStateResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChatConversationThreadsArtifactStateV1(workspaceId, updateChatConversationThreadsArtifactStateRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchAppCopilotChatAPIsApi - factory interface
 * @export
 */
export const SearchAppCopilotChatAPIsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchAppCopilotChatAPIsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete thread conversations and thread for the given Thread ID.
         * @param {string} workspaceId 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConversationThreadV1(workspaceId: string, threadId: string, options?: any): AxiosPromise<DeleteConversationThreadResponseModel> {
            return localVarFp.deleteConversationThreadV1(workspaceId, threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} workflowId 
         * @param {string} workspaceId 
         * @param {ChatConversationRequestModel} chatConversationRequestModel 
         * @param {boolean} [summarizeWithLlm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowSearchChatResponseV1(workflowId: string, workspaceId: string, chatConversationRequestModel: ChatConversationRequestModel, summarizeWithLlm?: boolean, options?: any): AxiosPromise<ChatConversationModel> {
            return localVarFp.getWorkflowSearchChatResponseV1(workflowId, workspaceId, chatConversationRequestModel, summarizeWithLlm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a paginated list of threads for data source llm conversations for a user
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChatConversationThreads(workspaceId: string, resourceId: string, start?: number, end?: number, ts?: string, options?: any): AxiosPromise<ListChatConversationThreadsModel> {
            return localVarFp.listChatConversationThreads(workspaceId, resourceId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of conversations for a given thread IDs and user.
         * @param {string} workspaceId 
         * @param {string} threadId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChatConversationsV2(workspaceId: string, threadId: string, start?: number, end?: number, ts?: string, options?: any): AxiosPromise<ListChatConversationModels> {
            return localVarFp.listChatConversationsV2(workspaceId, threadId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update thread artifact state for the given Thread IDs.
         * @param {string} workspaceId 
         * @param {UpdateChatConversationThreadsArtifactStateRequestModel} updateChatConversationThreadsArtifactStateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChatConversationThreadsArtifactStateV1(workspaceId: string, updateChatConversationThreadsArtifactStateRequestModel: UpdateChatConversationThreadsArtifactStateRequestModel, options?: any): AxiosPromise<UpdateChatConversationThreadsArtifactStateResponseModel> {
            return localVarFp.updateChatConversationThreadsArtifactStateV1(workspaceId, updateChatConversationThreadsArtifactStateRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchAppCopilotChatAPIsApi - object-oriented interface
 * @export
 * @class SearchAppCopilotChatAPIsApi
 * @extends {BaseAPI}
 */
export class SearchAppCopilotChatAPIsApi extends BaseAPI {
    /**
     * 
     * @summary Delete thread conversations and thread for the given Thread ID.
     * @param {string} workspaceId 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchAppCopilotChatAPIsApi
     */
    public deleteConversationThreadV1(workspaceId: string, threadId: string, options?: AxiosRequestConfig) {
        return SearchAppCopilotChatAPIsApiFp(this.configuration).deleteConversationThreadV1(workspaceId, threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns similar points in the cluster for the dataset.
     * @param {string} workflowId 
     * @param {string} workspaceId 
     * @param {ChatConversationRequestModel} chatConversationRequestModel 
     * @param {boolean} [summarizeWithLlm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchAppCopilotChatAPIsApi
     */
    public getWorkflowSearchChatResponseV1(workflowId: string, workspaceId: string, chatConversationRequestModel: ChatConversationRequestModel, summarizeWithLlm?: boolean, options?: AxiosRequestConfig) {
        return SearchAppCopilotChatAPIsApiFp(this.configuration).getWorkflowSearchChatResponseV1(workflowId, workspaceId, chatConversationRequestModel, summarizeWithLlm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a paginated list of threads for data source llm conversations for a user
     * @param {string} workspaceId 
     * @param {string} resourceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchAppCopilotChatAPIsApi
     */
    public listChatConversationThreads(workspaceId: string, resourceId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig) {
        return SearchAppCopilotChatAPIsApiFp(this.configuration).listChatConversationThreads(workspaceId, resourceId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of conversations for a given thread IDs and user.
     * @param {string} workspaceId 
     * @param {string} threadId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchAppCopilotChatAPIsApi
     */
    public listChatConversationsV2(workspaceId: string, threadId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig) {
        return SearchAppCopilotChatAPIsApiFp(this.configuration).listChatConversationsV2(workspaceId, threadId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update thread artifact state for the given Thread IDs.
     * @param {string} workspaceId 
     * @param {UpdateChatConversationThreadsArtifactStateRequestModel} updateChatConversationThreadsArtifactStateRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchAppCopilotChatAPIsApi
     */
    public updateChatConversationThreadsArtifactStateV1(workspaceId: string, updateChatConversationThreadsArtifactStateRequestModel: UpdateChatConversationThreadsArtifactStateRequestModel, options?: AxiosRequestConfig) {
        return SearchAppCopilotChatAPIsApiFp(this.configuration).updateChatConversationThreadsArtifactStateV1(workspaceId, updateChatConversationThreadsArtifactStateRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchAppInWorkflowAPIsApi - axios parameter creator
 * @export
 */
export const SearchAppInWorkflowAPIsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the metadata associated with a search application specific to a workflow within a workspace. This includes details like search indexes, their creation and update dates, and other relevant metadata.
         * @summary Fetches metadata for the search app sidebar.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchAppMetadata: async (workspaceId: string, workflowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getSearchAppMetadata', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getSearchAppMetadata', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflow/{workflow_id}/search-metadata`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} workflowId 
         * @param {string} workspaceId 
         * @param {WorkflowSimilarityRequest} workflowSimilarityRequest 
         * @param {boolean} [summarizeWithLlm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowSimilarityV1: async (workflowId: string, workspaceId: string, workflowSimilarityRequest: WorkflowSimilarityRequest, summarizeWithLlm?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getWorkflowSimilarityV1', 'workflowId', workflowId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkflowSimilarityV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowSimilarityRequest' is not null or undefined
            assertParamExists('getWorkflowSimilarityV1', 'workflowSimilarityRequest', workflowSimilarityRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflow/{workflow_id}/similar-points`
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (summarizeWithLlm !== undefined) {
                localVarQueryParameter['summarize_with_llm'] = summarizeWithLlm;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workflowSimilarityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches all search apps in the given workspace
         * @summary Fetches all search apps in the given workspace
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSearchAppsV1: async (workspaceId: string, listingAPIParams: ListingAPIParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listSearchAppsV1', 'workspaceId', workspaceId)
            // verify required parameter 'listingAPIParams' is not null or undefined
            assertParamExists('listSearchAppsV1', 'listingAPIParams', listingAPIParams)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/search-apps`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingAPIParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set search app name for given workflow.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {UpdateWorkflowSearchAppRequest} updateWorkflowSearchAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNameForWorkflowSearch: async (workspaceId: string, workflowId: string, updateWorkflowSearchAppRequest: UpdateWorkflowSearchAppRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('setNameForWorkflowSearch', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('setNameForWorkflowSearch', 'workflowId', workflowId)
            // verify required parameter 'updateWorkflowSearchAppRequest' is not null or undefined
            assertParamExists('setNameForWorkflowSearch', 'updateWorkflowSearchAppRequest', updateWorkflowSearchAppRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflow/{workflow_id}/set-app-name`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkflowSearchAppRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Init pod creation for given workflow.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spinPodForWorkflowSearch: async (workspaceId: string, workflowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('spinPodForWorkflowSearch', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('spinPodForWorkflowSearch', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflow/{workflow_id}/create-pod`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks status of the search pod for given dataset_id
         * @param {string} workflowId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowSearchPodStatus: async (workflowId: string, workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('workflowSearchPodStatus', 'workflowId', workflowId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workflowSearchPodStatus', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflow/{workflow_id}/check-status`
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchAppInWorkflowAPIsApi - functional programming interface
 * @export
 */
export const SearchAppInWorkflowAPIsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchAppInWorkflowAPIsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the metadata associated with a search application specific to a workflow within a workspace. This includes details like search indexes, their creation and update dates, and other relevant metadata.
         * @summary Fetches metadata for the search app sidebar.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchAppMetadata(workspaceId: string, workflowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchAppMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchAppMetadata(workspaceId, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} workflowId 
         * @param {string} workspaceId 
         * @param {WorkflowSimilarityRequest} workflowSimilarityRequest 
         * @param {boolean} [summarizeWithLlm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowSimilarityV1(workflowId: string, workspaceId: string, workflowSimilarityRequest: WorkflowSimilarityRequest, summarizeWithLlm?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowSimilarityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowSimilarityV1(workflowId, workspaceId, workflowSimilarityRequest, summarizeWithLlm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches all search apps in the given workspace
         * @summary Fetches all search apps in the given workspace
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSearchAppsV1(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSearchAppsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSearchAppsV1(workspaceId, listingAPIParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set search app name for given workflow.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {UpdateWorkflowSearchAppRequest} updateWorkflowSearchAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setNameForWorkflowSearch(workspaceId: string, workflowId: string, updateWorkflowSearchAppRequest: UpdateWorkflowSearchAppRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setNameForWorkflowSearch(workspaceId, workflowId, updateWorkflowSearchAppRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Init pod creation for given workflow.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spinPodForWorkflowSearch(workspaceId: string, workflowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spinPodForWorkflowSearch(workspaceId, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks status of the search pod for given dataset_id
         * @param {string} workflowId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workflowSearchPodStatus(workflowId: string, workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchPodStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workflowSearchPodStatus(workflowId, workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchAppInWorkflowAPIsApi - factory interface
 * @export
 */
export const SearchAppInWorkflowAPIsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchAppInWorkflowAPIsApiFp(configuration)
    return {
        /**
         * Retrieves the metadata associated with a search application specific to a workflow within a workspace. This includes details like search indexes, their creation and update dates, and other relevant metadata.
         * @summary Fetches metadata for the search app sidebar.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchAppMetadata(workspaceId: string, workflowId: string, options?: any): AxiosPromise<SearchAppMetadataResponse> {
            return localVarFp.getSearchAppMetadata(workspaceId, workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns similar points in the cluster for the dataset.
         * @param {string} workflowId 
         * @param {string} workspaceId 
         * @param {WorkflowSimilarityRequest} workflowSimilarityRequest 
         * @param {boolean} [summarizeWithLlm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowSimilarityV1(workflowId: string, workspaceId: string, workflowSimilarityRequest: WorkflowSimilarityRequest, summarizeWithLlm?: boolean, options?: any): AxiosPromise<WorkflowSimilarityResponse> {
            return localVarFp.getWorkflowSimilarityV1(workflowId, workspaceId, workflowSimilarityRequest, summarizeWithLlm, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches all search apps in the given workspace
         * @summary Fetches all search apps in the given workspace
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSearchAppsV1(workspaceId: string, listingAPIParams: ListingAPIParams, options?: any): AxiosPromise<ListSearchAppsResponse> {
            return localVarFp.listSearchAppsV1(workspaceId, listingAPIParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set search app name for given workflow.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {UpdateWorkflowSearchAppRequest} updateWorkflowSearchAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNameForWorkflowSearch(workspaceId: string, workflowId: string, updateWorkflowSearchAppRequest: UpdateWorkflowSearchAppRequest, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.setNameForWorkflowSearch(workspaceId, workflowId, updateWorkflowSearchAppRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Init pod creation for given workflow.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spinPodForWorkflowSearch(workspaceId: string, workflowId: string, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.spinPodForWorkflowSearch(workspaceId, workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks status of the search pod for given dataset_id
         * @param {string} workflowId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowSearchPodStatus(workflowId: string, workspaceId: string, options?: any): AxiosPromise<SearchPodStatusResponse> {
            return localVarFp.workflowSearchPodStatus(workflowId, workspaceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchAppInWorkflowAPIsApi - object-oriented interface
 * @export
 * @class SearchAppInWorkflowAPIsApi
 * @extends {BaseAPI}
 */
export class SearchAppInWorkflowAPIsApi extends BaseAPI {
    /**
     * Retrieves the metadata associated with a search application specific to a workflow within a workspace. This includes details like search indexes, their creation and update dates, and other relevant metadata.
     * @summary Fetches metadata for the search app sidebar.
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchAppInWorkflowAPIsApi
     */
    public getSearchAppMetadata(workspaceId: string, workflowId: string, options?: AxiosRequestConfig) {
        return SearchAppInWorkflowAPIsApiFp(this.configuration).getSearchAppMetadata(workspaceId, workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns similar points in the cluster for the dataset.
     * @param {string} workflowId 
     * @param {string} workspaceId 
     * @param {WorkflowSimilarityRequest} workflowSimilarityRequest 
     * @param {boolean} [summarizeWithLlm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchAppInWorkflowAPIsApi
     */
    public getWorkflowSimilarityV1(workflowId: string, workspaceId: string, workflowSimilarityRequest: WorkflowSimilarityRequest, summarizeWithLlm?: boolean, options?: AxiosRequestConfig) {
        return SearchAppInWorkflowAPIsApiFp(this.configuration).getWorkflowSimilarityV1(workflowId, workspaceId, workflowSimilarityRequest, summarizeWithLlm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches all search apps in the given workspace
     * @summary Fetches all search apps in the given workspace
     * @param {string} workspaceId 
     * @param {ListingAPIParams} listingAPIParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchAppInWorkflowAPIsApi
     */
    public listSearchAppsV1(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig) {
        return SearchAppInWorkflowAPIsApiFp(this.configuration).listSearchAppsV1(workspaceId, listingAPIParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set search app name for given workflow.
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {UpdateWorkflowSearchAppRequest} updateWorkflowSearchAppRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchAppInWorkflowAPIsApi
     */
    public setNameForWorkflowSearch(workspaceId: string, workflowId: string, updateWorkflowSearchAppRequest: UpdateWorkflowSearchAppRequest, options?: AxiosRequestConfig) {
        return SearchAppInWorkflowAPIsApiFp(this.configuration).setNameForWorkflowSearch(workspaceId, workflowId, updateWorkflowSearchAppRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Init pod creation for given workflow.
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchAppInWorkflowAPIsApi
     */
    public spinPodForWorkflowSearch(workspaceId: string, workflowId: string, options?: AxiosRequestConfig) {
        return SearchAppInWorkflowAPIsApiFp(this.configuration).spinPodForWorkflowSearch(workspaceId, workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks status of the search pod for given dataset_id
     * @param {string} workflowId 
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchAppInWorkflowAPIsApi
     */
    public workflowSearchPodStatus(workflowId: string, workspaceId: string, options?: AxiosRequestConfig) {
        return SearchAppInWorkflowAPIsApiFp(this.configuration).workflowSearchPodStatus(workflowId, workspaceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SecretsManagementApi - axios parameter creator
 * @export
 */
export const SecretsManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Check if the secret with the given name and data data already exists!
         * @summary Check if the secret with the given name and data data already exists!
         * @param {string} workspaceId 
         * @param {CheckCredentialByNormNameRequest} checkCredentialByNormNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceCheckSecretNameV1: async (workspaceId: string, checkCredentialByNormNameRequest: CheckCredentialByNormNameRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceCheckSecretNameV1', 'workspaceId', workspaceId)
            // verify required parameter 'checkCredentialByNormNameRequest' is not null or undefined
            assertParamExists('workspaceCheckSecretNameV1', 'checkCredentialByNormNameRequest', checkCredentialByNormNameRequest)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/secrets/secret-name/check-get`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkCredentialByNormNameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the metadata of the secret registered by the customer with MarkovML by secret_id
         * @summary Update the metadata of the secret registered by the customer with MarkovML by secret_id
         * @param {string} workspaceId 
         * @param {string} secretId 
         * @param {SecretUpdateRequest} secretUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceEditSecretV1: async (workspaceId: string, secretId: string, secretUpdateRequest: SecretUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceEditSecretV1', 'workspaceId', workspaceId)
            // verify required parameter 'secretId' is not null or undefined
            assertParamExists('workspaceEditSecretV1', 'secretId', secretId)
            // verify required parameter 'secretUpdateRequest' is not null or undefined
            assertParamExists('workspaceEditSecretV1', 'secretUpdateRequest', secretUpdateRequest)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/secrets/secret-id/{secret_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"secret_id"}}`, encodeURIComponent(String(secretId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secretUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get api keys to connect to language model providers like open ai, bard, anthropic etc.
         * @summary This url is called by reno to fetch the api keys required for model object to talk to language models likeOpen ai, bard, etc.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetModelConnectorResponseV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetModelConnectorResponseV1', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/v1/credentials/model-connector/workspace/{workspace_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the secret registered by the customer with MarkovML by secret-name.
         * @summary Return the secret registered by the customer with MarkovML by secret-name.
         * @param {string} workspaceId 
         * @param {string} secretName 
         * @param {boolean} searchVault 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetSecretByNameV1: async (workspaceId: string, secretName: string, searchVault: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetSecretByNameV1', 'workspaceId', workspaceId)
            // verify required parameter 'secretName' is not null or undefined
            assertParamExists('workspaceGetSecretByNameV1', 'secretName', secretName)
            // verify required parameter 'searchVault' is not null or undefined
            assertParamExists('workspaceGetSecretByNameV1', 'searchVault', searchVault)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/secrets/secret-name/{secret_name}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"secret_name"}}`, encodeURIComponent(String(secretName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchVault !== undefined) {
                localVarQueryParameter['search_vault'] = searchVault;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the secret registered by the customer with MarkovML by secret_id.
         * @summary Return the secret registered by the customer with MarkovML by secret_id.
         * @param {string} workspaceId 
         * @param {string} secretId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetSecretV1: async (workspaceId: string, secretId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetSecretV1', 'workspaceId', workspaceId)
            // verify required parameter 'secretId' is not null or undefined
            assertParamExists('workspaceGetSecretV1', 'secretId', secretId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/secrets/secret-id/{secret_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"secret_id"}}`, encodeURIComponent(String(secretId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of secrets metadata stored for a workspace.
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [secretId] 
         * @param {Array<string>} [excludeNormNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetSecretsMetadataV1: async (workspaceId: string, start?: number, end?: number, secretId?: string, excludeNormNames?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetSecretsMetadataV1', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/secrets/metadata`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (secretId !== undefined) {
                localVarQueryParameter['secret_id'] = secretId;
            }

            if (excludeNormNames) {
                localVarQueryParameter['exclude_norm_names'] = excludeNormNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This will be called during migration of demo artifacts [vienna, airflow job (which does bulk migration)]
         * @summary Migrate the secrets needed to access the demo artifacts
         * @param {MigrateDemoArtifactSecretsRequest} migrateDemoArtifactSecretsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceMigrateDemoArtifactSecretsV1: async (migrateDemoArtifactSecretsRequest: MigrateDemoArtifactSecretsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'migrateDemoArtifactSecretsRequest' is not null or undefined
            assertParamExists('workspaceMigrateDemoArtifactSecretsV1', 'migrateDemoArtifactSecretsRequest', migrateDemoArtifactSecretsRequest)
            const localVarPath = `/haifa/v1/artifact/migrate-secrets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(migrateDemoArtifactSecretsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register the secret access credentials to access data/models.the cloud assets (S3 buckets).These credentials are requested from the customers during registration of a datasource and safely secured here to be accessed by markovML when required for an operation/run.
         * @summary Register the secret access credentials to access data/models
         * @param {string} workspaceId 
         * @param {SecretRegistrationRequest} secretRegistrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceRegisterSecretV1: async (workspaceId: string, secretRegistrationRequest: SecretRegistrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceRegisterSecretV1', 'workspaceId', workspaceId)
            // verify required parameter 'secretRegistrationRequest' is not null or undefined
            assertParamExists('workspaceRegisterSecretV1', 'secretRegistrationRequest', secretRegistrationRequest)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/register-secret`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secretRegistrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecretsManagementApi - functional programming interface
 * @export
 */
export const SecretsManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecretsManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Check if the secret with the given name and data data already exists!
         * @summary Check if the secret with the given name and data data already exists!
         * @param {string} workspaceId 
         * @param {CheckCredentialByNormNameRequest} checkCredentialByNormNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceCheckSecretNameV1(workspaceId: string, checkCredentialByNormNameRequest: CheckCredentialByNormNameRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckSecretDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceCheckSecretNameV1(workspaceId, checkCredentialByNormNameRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the metadata of the secret registered by the customer with MarkovML by secret_id
         * @summary Update the metadata of the secret registered by the customer with MarkovML by secret_id
         * @param {string} workspaceId 
         * @param {string} secretId 
         * @param {SecretUpdateRequest} secretUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceEditSecretV1(workspaceId: string, secretId: string, secretUpdateRequest: SecretUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditSecretResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceEditSecretV1(workspaceId, secretId, secretUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get api keys to connect to language model providers like open ai, bard, anthropic etc.
         * @summary This url is called by reno to fetch the api keys required for model object to talk to language models likeOpen ai, bard, etc.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetModelConnectorResponseV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAppCredentialResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetModelConnectorResponseV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the secret registered by the customer with MarkovML by secret-name.
         * @summary Return the secret registered by the customer with MarkovML by secret-name.
         * @param {string} workspaceId 
         * @param {string} secretName 
         * @param {boolean} searchVault 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetSecretByNameV1(workspaceId: string, secretName: string, searchVault: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSecretResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetSecretByNameV1(workspaceId, secretName, searchVault, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the secret registered by the customer with MarkovML by secret_id.
         * @summary Return the secret registered by the customer with MarkovML by secret_id.
         * @param {string} workspaceId 
         * @param {string} secretId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetSecretV1(workspaceId: string, secretId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSecretResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetSecretV1(workspaceId, secretId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of secrets metadata stored for a workspace.
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [secretId] 
         * @param {Array<string>} [excludeNormNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetSecretsMetadataV1(workspaceId: string, start?: number, end?: number, secretId?: string, excludeNormNames?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetSecretsMetadataV1(workspaceId, start, end, secretId, excludeNormNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This will be called during migration of demo artifacts [vienna, airflow job (which does bulk migration)]
         * @summary Migrate the secrets needed to access the demo artifacts
         * @param {MigrateDemoArtifactSecretsRequest} migrateDemoArtifactSecretsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceMigrateDemoArtifactSecretsV1(migrateDemoArtifactSecretsRequest: MigrateDemoArtifactSecretsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MigrateDemoArtifactSecretsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceMigrateDemoArtifactSecretsV1(migrateDemoArtifactSecretsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Register the secret access credentials to access data/models.the cloud assets (S3 buckets).These credentials are requested from the customers during registration of a datasource and safely secured here to be accessed by markovML when required for an operation/run.
         * @summary Register the secret access credentials to access data/models
         * @param {string} workspaceId 
         * @param {SecretRegistrationRequest} secretRegistrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceRegisterSecretV1(workspaceId: string, secretRegistrationRequest: SecretRegistrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretRegistrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceRegisterSecretV1(workspaceId, secretRegistrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SecretsManagementApi - factory interface
 * @export
 */
export const SecretsManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecretsManagementApiFp(configuration)
    return {
        /**
         * Check if the secret with the given name and data data already exists!
         * @summary Check if the secret with the given name and data data already exists!
         * @param {string} workspaceId 
         * @param {CheckCredentialByNormNameRequest} checkCredentialByNormNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceCheckSecretNameV1(workspaceId: string, checkCredentialByNormNameRequest: CheckCredentialByNormNameRequest, options?: any): AxiosPromise<CheckSecretDataResponse> {
            return localVarFp.workspaceCheckSecretNameV1(workspaceId, checkCredentialByNormNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the metadata of the secret registered by the customer with MarkovML by secret_id
         * @summary Update the metadata of the secret registered by the customer with MarkovML by secret_id
         * @param {string} workspaceId 
         * @param {string} secretId 
         * @param {SecretUpdateRequest} secretUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceEditSecretV1(workspaceId: string, secretId: string, secretUpdateRequest: SecretUpdateRequest, options?: any): AxiosPromise<EditSecretResponse> {
            return localVarFp.workspaceEditSecretV1(workspaceId, secretId, secretUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get api keys to connect to language model providers like open ai, bard, anthropic etc.
         * @summary This url is called by reno to fetch the api keys required for model object to talk to language models likeOpen ai, bard, etc.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetModelConnectorResponseV1(workspaceId: string, options?: any): AxiosPromise<GetAppCredentialResponse> {
            return localVarFp.workspaceGetModelConnectorResponseV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the secret registered by the customer with MarkovML by secret-name.
         * @summary Return the secret registered by the customer with MarkovML by secret-name.
         * @param {string} workspaceId 
         * @param {string} secretName 
         * @param {boolean} searchVault 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetSecretByNameV1(workspaceId: string, secretName: string, searchVault: boolean, options?: any): AxiosPromise<GetSecretResponse> {
            return localVarFp.workspaceGetSecretByNameV1(workspaceId, secretName, searchVault, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the secret registered by the customer with MarkovML by secret_id.
         * @summary Return the secret registered by the customer with MarkovML by secret_id.
         * @param {string} workspaceId 
         * @param {string} secretId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetSecretV1(workspaceId: string, secretId: string, options?: any): AxiosPromise<GetSecretResponse> {
            return localVarFp.workspaceGetSecretV1(workspaceId, secretId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of secrets metadata stored for a workspace.
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [secretId] 
         * @param {Array<string>} [excludeNormNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetSecretsMetadataV1(workspaceId: string, start?: number, end?: number, secretId?: string, excludeNormNames?: Array<string>, options?: any): AxiosPromise<SecretMetadataResponse> {
            return localVarFp.workspaceGetSecretsMetadataV1(workspaceId, start, end, secretId, excludeNormNames, options).then((request) => request(axios, basePath));
        },
        /**
         * This will be called during migration of demo artifacts [vienna, airflow job (which does bulk migration)]
         * @summary Migrate the secrets needed to access the demo artifacts
         * @param {MigrateDemoArtifactSecretsRequest} migrateDemoArtifactSecretsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceMigrateDemoArtifactSecretsV1(migrateDemoArtifactSecretsRequest: MigrateDemoArtifactSecretsRequest, options?: any): AxiosPromise<MigrateDemoArtifactSecretsResponse> {
            return localVarFp.workspaceMigrateDemoArtifactSecretsV1(migrateDemoArtifactSecretsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Register the secret access credentials to access data/models.the cloud assets (S3 buckets).These credentials are requested from the customers during registration of a datasource and safely secured here to be accessed by markovML when required for an operation/run.
         * @summary Register the secret access credentials to access data/models
         * @param {string} workspaceId 
         * @param {SecretRegistrationRequest} secretRegistrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceRegisterSecretV1(workspaceId: string, secretRegistrationRequest: SecretRegistrationRequest, options?: any): AxiosPromise<SecretRegistrationResponse> {
            return localVarFp.workspaceRegisterSecretV1(workspaceId, secretRegistrationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SecretsManagementApi - object-oriented interface
 * @export
 * @class SecretsManagementApi
 * @extends {BaseAPI}
 */
export class SecretsManagementApi extends BaseAPI {
    /**
     * Check if the secret with the given name and data data already exists!
     * @summary Check if the secret with the given name and data data already exists!
     * @param {string} workspaceId 
     * @param {CheckCredentialByNormNameRequest} checkCredentialByNormNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsManagementApi
     */
    public workspaceCheckSecretNameV1(workspaceId: string, checkCredentialByNormNameRequest: CheckCredentialByNormNameRequest, options?: AxiosRequestConfig) {
        return SecretsManagementApiFp(this.configuration).workspaceCheckSecretNameV1(workspaceId, checkCredentialByNormNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the metadata of the secret registered by the customer with MarkovML by secret_id
     * @summary Update the metadata of the secret registered by the customer with MarkovML by secret_id
     * @param {string} workspaceId 
     * @param {string} secretId 
     * @param {SecretUpdateRequest} secretUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsManagementApi
     */
    public workspaceEditSecretV1(workspaceId: string, secretId: string, secretUpdateRequest: SecretUpdateRequest, options?: AxiosRequestConfig) {
        return SecretsManagementApiFp(this.configuration).workspaceEditSecretV1(workspaceId, secretId, secretUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get api keys to connect to language model providers like open ai, bard, anthropic etc.
     * @summary This url is called by reno to fetch the api keys required for model object to talk to language models likeOpen ai, bard, etc.
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsManagementApi
     */
    public workspaceGetModelConnectorResponseV1(workspaceId: string, options?: AxiosRequestConfig) {
        return SecretsManagementApiFp(this.configuration).workspaceGetModelConnectorResponseV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the secret registered by the customer with MarkovML by secret-name.
     * @summary Return the secret registered by the customer with MarkovML by secret-name.
     * @param {string} workspaceId 
     * @param {string} secretName 
     * @param {boolean} searchVault 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsManagementApi
     */
    public workspaceGetSecretByNameV1(workspaceId: string, secretName: string, searchVault: boolean, options?: AxiosRequestConfig) {
        return SecretsManagementApiFp(this.configuration).workspaceGetSecretByNameV1(workspaceId, secretName, searchVault, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the secret registered by the customer with MarkovML by secret_id.
     * @summary Return the secret registered by the customer with MarkovML by secret_id.
     * @param {string} workspaceId 
     * @param {string} secretId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsManagementApi
     */
    public workspaceGetSecretV1(workspaceId: string, secretId: string, options?: AxiosRequestConfig) {
        return SecretsManagementApiFp(this.configuration).workspaceGetSecretV1(workspaceId, secretId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of secrets metadata stored for a workspace.
     * @param {string} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [secretId] 
     * @param {Array<string>} [excludeNormNames] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsManagementApi
     */
    public workspaceGetSecretsMetadataV1(workspaceId: string, start?: number, end?: number, secretId?: string, excludeNormNames?: Array<string>, options?: AxiosRequestConfig) {
        return SecretsManagementApiFp(this.configuration).workspaceGetSecretsMetadataV1(workspaceId, start, end, secretId, excludeNormNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This will be called during migration of demo artifacts [vienna, airflow job (which does bulk migration)]
     * @summary Migrate the secrets needed to access the demo artifacts
     * @param {MigrateDemoArtifactSecretsRequest} migrateDemoArtifactSecretsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsManagementApi
     */
    public workspaceMigrateDemoArtifactSecretsV1(migrateDemoArtifactSecretsRequest: MigrateDemoArtifactSecretsRequest, options?: AxiosRequestConfig) {
        return SecretsManagementApiFp(this.configuration).workspaceMigrateDemoArtifactSecretsV1(migrateDemoArtifactSecretsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register the secret access credentials to access data/models.the cloud assets (S3 buckets).These credentials are requested from the customers during registration of a datasource and safely secured here to be accessed by markovML when required for an operation/run.
     * @summary Register the secret access credentials to access data/models
     * @param {string} workspaceId 
     * @param {SecretRegistrationRequest} secretRegistrationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsManagementApi
     */
    public workspaceRegisterSecretV1(workspaceId: string, secretRegistrationRequest: SecretRegistrationRequest, options?: AxiosRequestConfig) {
        return SecretsManagementApiFp(this.configuration).workspaceRegisterSecretV1(workspaceId, secretRegistrationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ServiceHealthApi - axios parameter creator
 * @export
 */
export const ServiceHealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Health check to see if service is working as expected
         * @summary Test api which will be used to check if the user has the required permissions
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPermissions: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('checkPermissions', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/test/workspace/{workspace_id}/check-permissions`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceHealthApi - functional programming interface
 * @export
 */
export const ServiceHealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceHealthApiAxiosParamCreator(configuration)
    return {
        /**
         * Health check to see if service is working as expected
         * @summary Test api which will be used to check if the user has the required permissions
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPermissions(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPermissions(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceHealthApi - factory interface
 * @export
 */
export const ServiceHealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceHealthApiFp(configuration)
    return {
        /**
         * Health check to see if service is working as expected
         * @summary Test api which will be used to check if the user has the required permissions
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPermissions(workspaceId: string, options?: any): AxiosPromise<any> {
            return localVarFp.checkPermissions(workspaceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceHealthApi - object-oriented interface
 * @export
 * @class ServiceHealthApi
 * @extends {BaseAPI}
 */
export class ServiceHealthApi extends BaseAPI {
    /**
     * Health check to see if service is working as expected
     * @summary Test api which will be used to check if the user has the required permissions
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceHealthApi
     */
    public checkPermissions(workspaceId: string, options?: AxiosRequestConfig) {
        return ServiceHealthApiFp(this.configuration).checkPermissions(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SlackApi - axios parameter creator
 * @export
 */
export const SlackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Install Slack App
         * @param {SlackBotAppInstallRequestModel} slackBotAppInstallRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSlackBotInstallUrl: async (slackBotAppInstallRequestModel: SlackBotAppInstallRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slackBotAppInstallRequestModel' is not null or undefined
            assertParamExists('createSlackBotInstallUrl', 'slackBotAppInstallRequestModel', slackBotAppInstallRequestModel)
            const localVarPath = `/paris/v1/integrations/slack/install`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(slackBotAppInstallRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Workspace Slack Channel
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceSlackConfiguration: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspaceSlackConfiguration', 'workspaceId', workspaceId)
            const localVarPath = `/paris/v1/integrations/slack/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspace_id'] = workspaceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Slack Channels
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSlackChannels: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listSlackChannels', 'workspaceId', workspaceId)
            const localVarPath = `/paris/v1/integrations/slack/channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspaceId'] = workspaceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Handle Slack Oauth Redirect
         * @param {string} code 
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slackBotOAuthRedirect: async (code: string, state: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('slackBotOAuthRedirect', 'code', code)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('slackBotOAuthRedirect', 'state', state)
            const localVarPath = `/paris/v1/integrations/slack/oauth_redirect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Slack Channel
         * @param {UpdateSlackChannelRequestModel} updateSlackChannelRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSlackChannel: async (updateSlackChannelRequestModel: UpdateSlackChannelRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSlackChannelRequestModel' is not null or undefined
            assertParamExists('updateSlackChannel', 'updateSlackChannelRequestModel', updateSlackChannelRequestModel)
            const localVarPath = `/paris/v1/integrations/slack/channel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSlackChannelRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SlackApi - functional programming interface
 * @export
 */
export const SlackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SlackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Install Slack App
         * @param {SlackBotAppInstallRequestModel} slackBotAppInstallRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSlackBotInstallUrl(slackBotAppInstallRequestModel: SlackBotAppInstallRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlackBotAppInstallResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSlackBotInstallUrl(slackBotAppInstallRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Workspace Slack Channel
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceSlackConfiguration(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSlackConfigurationResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceSlackConfiguration(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Slack Channels
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSlackChannels(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlackBotChannelResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSlackChannels(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Handle Slack Oauth Redirect
         * @param {string} code 
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slackBotOAuthRedirect(code: string, state: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slackBotOAuthRedirect(code, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Slack Channel
         * @param {UpdateSlackChannelRequestModel} updateSlackChannelRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSlackChannel(updateSlackChannelRequestModel: UpdateSlackChannelRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSlackChannelResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSlackChannel(updateSlackChannelRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SlackApi - factory interface
 * @export
 */
export const SlackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SlackApiFp(configuration)
    return {
        /**
         * 
         * @summary Install Slack App
         * @param {SlackBotAppInstallRequestModel} slackBotAppInstallRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSlackBotInstallUrl(slackBotAppInstallRequestModel: SlackBotAppInstallRequestModel, options?: any): AxiosPromise<SlackBotAppInstallResponseModel> {
            return localVarFp.createSlackBotInstallUrl(slackBotAppInstallRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Workspace Slack Channel
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceSlackConfiguration(workspaceId: string, options?: any): AxiosPromise<GetSlackConfigurationResponseModel> {
            return localVarFp.getWorkspaceSlackConfiguration(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Slack Channels
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSlackChannels(workspaceId: string, options?: any): AxiosPromise<Array<SlackBotChannelResponseModel>> {
            return localVarFp.listSlackChannels(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Handle Slack Oauth Redirect
         * @param {string} code 
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slackBotOAuthRedirect(code: string, state: string, options?: any): AxiosPromise<any> {
            return localVarFp.slackBotOAuthRedirect(code, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Slack Channel
         * @param {UpdateSlackChannelRequestModel} updateSlackChannelRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSlackChannel(updateSlackChannelRequestModel: UpdateSlackChannelRequestModel, options?: any): AxiosPromise<UpdateSlackChannelResponseModel> {
            return localVarFp.updateSlackChannel(updateSlackChannelRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SlackApi - object-oriented interface
 * @export
 * @class SlackApi
 * @extends {BaseAPI}
 */
export class SlackApi extends BaseAPI {
    /**
     * 
     * @summary Install Slack App
     * @param {SlackBotAppInstallRequestModel} slackBotAppInstallRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlackApi
     */
    public createSlackBotInstallUrl(slackBotAppInstallRequestModel: SlackBotAppInstallRequestModel, options?: AxiosRequestConfig) {
        return SlackApiFp(this.configuration).createSlackBotInstallUrl(slackBotAppInstallRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Workspace Slack Channel
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlackApi
     */
    public getWorkspaceSlackConfiguration(workspaceId: string, options?: AxiosRequestConfig) {
        return SlackApiFp(this.configuration).getWorkspaceSlackConfiguration(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Slack Channels
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlackApi
     */
    public listSlackChannels(workspaceId: string, options?: AxiosRequestConfig) {
        return SlackApiFp(this.configuration).listSlackChannels(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Handle Slack Oauth Redirect
     * @param {string} code 
     * @param {string} state 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlackApi
     */
    public slackBotOAuthRedirect(code: string, state: string, options?: AxiosRequestConfig) {
        return SlackApiFp(this.configuration).slackBotOAuthRedirect(code, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Slack Channel
     * @param {UpdateSlackChannelRequestModel} updateSlackChannelRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlackApi
     */
    public updateSlackChannel(updateSlackChannelRequestModel: UpdateSlackChannelRequestModel, options?: AxiosRequestConfig) {
        return SlackApiFp(this.configuration).updateSlackChannel(updateSlackChannelRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SnippetCommentsApi - axios parameter creator
 * @export
 */
export const SnippetCommentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Endpoint to list all the conversations within a snippet with comments
         * @param {string} workspaceId ID of the workspace.
         * @param {string} snippetId ID of the snippet.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsV1: async (workspaceId: string, snippetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getConversationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('getConversationsV1', 'snippetId', snippetId)
            const localVarPath = `/v1/workspaces/{workspaceId}/snippets/{snippetId}/conversations`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippetId"}}`, encodeURIComponent(String(snippetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SnippetCommentsApi - functional programming interface
 * @export
 */
export const SnippetCommentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SnippetCommentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Endpoint to list all the conversations within a snippet with comments
         * @param {string} workspaceId ID of the workspace.
         * @param {string} snippetId ID of the snippet.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationsV1(workspaceId: string, snippetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConversationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationsV1(workspaceId, snippetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SnippetCommentsApi - factory interface
 * @export
 */
export const SnippetCommentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SnippetCommentsApiFp(configuration)
    return {
        /**
         * Endpoint to list all the conversations within a snippet with comments
         * @param {string} workspaceId ID of the workspace.
         * @param {string} snippetId ID of the snippet.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsV1(workspaceId: string, snippetId: string, options?: any): AxiosPromise<GetConversationsResponse> {
            return localVarFp.getConversationsV1(workspaceId, snippetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SnippetCommentsApi - object-oriented interface
 * @export
 * @class SnippetCommentsApi
 * @extends {BaseAPI}
 */
export class SnippetCommentsApi extends BaseAPI {
    /**
     * Endpoint to list all the conversations within a snippet with comments
     * @param {string} workspaceId ID of the workspace.
     * @param {string} snippetId ID of the snippet.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetCommentsApi
     */
    public getConversationsV1(workspaceId: string, snippetId: string, options?: AxiosRequestConfig) {
        return SnippetCommentsApiFp(this.configuration).getConversationsV1(workspaceId, snippetId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SnippetCommentsRouteApi - axios parameter creator
 * @export
 */
export const SnippetCommentsRouteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add comment to conversation, create conversation it not exists
         * @summary Add comment to conversation, create conversation it not exists
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {AddCommentToConversationRequest} addCommentToConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentInSnippetV1: async (workspaceId: string, snippetId: string, addCommentToConversationRequest: AddCommentToConversationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createCommentInSnippetV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('createCommentInSnippetV1', 'snippetId', snippetId)
            // verify required parameter 'addCommentToConversationRequest' is not null or undefined
            assertParamExists('createCommentInSnippetV1', 'addCommentToConversationRequest', addCommentToConversationRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippet/{snippet_id}/addComment`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCommentToConversationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete comment from a conversation
         * @summary Delete comment from a conversation
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {string} conversationId 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSnippetCommentV1: async (workspaceId: string, snippetId: string, conversationId: string, commentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteSnippetCommentV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('deleteSnippetCommentV1', 'snippetId', snippetId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('deleteSnippetCommentV1', 'conversationId', conversationId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteSnippetCommentV1', 'commentId', commentId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippets/{snippet_id}/conversations/{conversation_id}/comments/{comment_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)))
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit a comment in a conversation
         * @summary Edit a comment in a conversation
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {string} conversationId 
         * @param {string} commentId 
         * @param {EditCommentRequest} editCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSnippetCommentV1: async (workspaceId: string, snippetId: string, conversationId: string, commentId: string, editCommentRequest: EditCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('editSnippetCommentV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('editSnippetCommentV1', 'snippetId', snippetId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('editSnippetCommentV1', 'conversationId', conversationId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('editSnippetCommentV1', 'commentId', commentId)
            // verify required parameter 'editCommentRequest' is not null or undefined
            assertParamExists('editSnippetCommentV1', 'editCommentRequest', editCommentRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippets/{snippet_id}/conversations/{conversation_id}/comments/{comment_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)))
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Comments Info
         * @summary Get Comments Info
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnippetCommentsV1: async (workspaceId: string, snippetId: string, conversationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getSnippetCommentsV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('getSnippetCommentsV1', 'snippetId', snippetId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getSnippetCommentsV1', 'conversationId', conversationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippet/{snippet_id}/conversation/{conversation_id}/comments`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)))
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Conversations
         * @summary Get Conversations
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {string} [projection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnippetConversationsV1: async (workspaceId: string, snippetId: string, projection?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getSnippetConversationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('getSnippetConversationsV1', 'snippetId', snippetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippets/{snippet_id}/conversations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projection !== undefined) {
                localVarQueryParameter['projection'] = projection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set Resolve status for a conversation
         * @summary Set Resolve status for a  conversation
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {string} conversationId 
         * @param {UpdateConversationRequest} updateConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setResolveStatusInConversationV1: async (workspaceId: string, snippetId: string, conversationId: string, updateConversationRequest: UpdateConversationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('setResolveStatusInConversationV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('setResolveStatusInConversationV1', 'snippetId', snippetId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('setResolveStatusInConversationV1', 'conversationId', conversationId)
            // verify required parameter 'updateConversationRequest' is not null or undefined
            assertParamExists('setResolveStatusInConversationV1', 'updateConversationRequest', updateConversationRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippets/{snippet_id}/conversations/{conversation_id}/setResolveStatus`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)))
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateConversationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SnippetCommentsRouteApi - functional programming interface
 * @export
 */
export const SnippetCommentsRouteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SnippetCommentsRouteApiAxiosParamCreator(configuration)
    return {
        /**
         * Add comment to conversation, create conversation it not exists
         * @summary Add comment to conversation, create conversation it not exists
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {AddCommentToConversationRequest} addCommentToConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentInSnippetV1(workspaceId: string, snippetId: string, addCommentToConversationRequest: AddCommentToConversationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicesViennaApiModelsSnippetCommentModelsAddCommentToConversationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentInSnippetV1(workspaceId, snippetId, addCommentToConversationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete comment from a conversation
         * @summary Delete comment from a conversation
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {string} conversationId 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSnippetCommentV1(workspaceId: string, snippetId: string, conversationId: string, commentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteCommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSnippetCommentV1(workspaceId, snippetId, conversationId, commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit a comment in a conversation
         * @summary Edit a comment in a conversation
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {string} conversationId 
         * @param {string} commentId 
         * @param {EditCommentRequest} editCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editSnippetCommentV1(workspaceId: string, snippetId: string, conversationId: string, commentId: string, editCommentRequest: EditCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditCommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editSnippetCommentV1(workspaceId, snippetId, conversationId, commentId, editCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Comments Info
         * @summary Get Comments Info
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnippetCommentsV1(workspaceId: string, snippetId: string, conversationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommentsInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnippetCommentsV1(workspaceId, snippetId, conversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Conversations
         * @summary Get Conversations
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {string} [projection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnippetConversationsV1(workspaceId: string, snippetId: string, projection?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConversationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnippetConversationsV1(workspaceId, snippetId, projection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set Resolve status for a conversation
         * @summary Set Resolve status for a  conversation
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {string} conversationId 
         * @param {UpdateConversationRequest} updateConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setResolveStatusInConversationV1(workspaceId: string, snippetId: string, conversationId: string, updateConversationRequest: UpdateConversationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateConversationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setResolveStatusInConversationV1(workspaceId, snippetId, conversationId, updateConversationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SnippetCommentsRouteApi - factory interface
 * @export
 */
export const SnippetCommentsRouteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SnippetCommentsRouteApiFp(configuration)
    return {
        /**
         * Add comment to conversation, create conversation it not exists
         * @summary Add comment to conversation, create conversation it not exists
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {AddCommentToConversationRequest} addCommentToConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentInSnippetV1(workspaceId: string, snippetId: string, addCommentToConversationRequest: AddCommentToConversationRequest, options?: any): AxiosPromise<ServicesViennaApiModelsSnippetCommentModelsAddCommentToConversationResponse> {
            return localVarFp.createCommentInSnippetV1(workspaceId, snippetId, addCommentToConversationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete comment from a conversation
         * @summary Delete comment from a conversation
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {string} conversationId 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSnippetCommentV1(workspaceId: string, snippetId: string, conversationId: string, commentId: string, options?: any): AxiosPromise<DeleteCommentResponse> {
            return localVarFp.deleteSnippetCommentV1(workspaceId, snippetId, conversationId, commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit a comment in a conversation
         * @summary Edit a comment in a conversation
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {string} conversationId 
         * @param {string} commentId 
         * @param {EditCommentRequest} editCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSnippetCommentV1(workspaceId: string, snippetId: string, conversationId: string, commentId: string, editCommentRequest: EditCommentRequest, options?: any): AxiosPromise<EditCommentResponse> {
            return localVarFp.editSnippetCommentV1(workspaceId, snippetId, conversationId, commentId, editCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Comments Info
         * @summary Get Comments Info
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnippetCommentsV1(workspaceId: string, snippetId: string, conversationId: string, options?: any): AxiosPromise<GetCommentsInfoResponse> {
            return localVarFp.getSnippetCommentsV1(workspaceId, snippetId, conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Conversations
         * @summary Get Conversations
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {string} [projection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnippetConversationsV1(workspaceId: string, snippetId: string, projection?: string, options?: any): AxiosPromise<GetConversationResponse> {
            return localVarFp.getSnippetConversationsV1(workspaceId, snippetId, projection, options).then((request) => request(axios, basePath));
        },
        /**
         * Set Resolve status for a conversation
         * @summary Set Resolve status for a  conversation
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {string} conversationId 
         * @param {UpdateConversationRequest} updateConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setResolveStatusInConversationV1(workspaceId: string, snippetId: string, conversationId: string, updateConversationRequest: UpdateConversationRequest, options?: any): AxiosPromise<UpdateConversationResponse> {
            return localVarFp.setResolveStatusInConversationV1(workspaceId, snippetId, conversationId, updateConversationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SnippetCommentsRouteApi - object-oriented interface
 * @export
 * @class SnippetCommentsRouteApi
 * @extends {BaseAPI}
 */
export class SnippetCommentsRouteApi extends BaseAPI {
    /**
     * Add comment to conversation, create conversation it not exists
     * @summary Add comment to conversation, create conversation it not exists
     * @param {string} workspaceId 
     * @param {string} snippetId 
     * @param {AddCommentToConversationRequest} addCommentToConversationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetCommentsRouteApi
     */
    public createCommentInSnippetV1(workspaceId: string, snippetId: string, addCommentToConversationRequest: AddCommentToConversationRequest, options?: AxiosRequestConfig) {
        return SnippetCommentsRouteApiFp(this.configuration).createCommentInSnippetV1(workspaceId, snippetId, addCommentToConversationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete comment from a conversation
     * @summary Delete comment from a conversation
     * @param {string} workspaceId 
     * @param {string} snippetId 
     * @param {string} conversationId 
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetCommentsRouteApi
     */
    public deleteSnippetCommentV1(workspaceId: string, snippetId: string, conversationId: string, commentId: string, options?: AxiosRequestConfig) {
        return SnippetCommentsRouteApiFp(this.configuration).deleteSnippetCommentV1(workspaceId, snippetId, conversationId, commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit a comment in a conversation
     * @summary Edit a comment in a conversation
     * @param {string} workspaceId 
     * @param {string} snippetId 
     * @param {string} conversationId 
     * @param {string} commentId 
     * @param {EditCommentRequest} editCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetCommentsRouteApi
     */
    public editSnippetCommentV1(workspaceId: string, snippetId: string, conversationId: string, commentId: string, editCommentRequest: EditCommentRequest, options?: AxiosRequestConfig) {
        return SnippetCommentsRouteApiFp(this.configuration).editSnippetCommentV1(workspaceId, snippetId, conversationId, commentId, editCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Comments Info
     * @summary Get Comments Info
     * @param {string} workspaceId 
     * @param {string} snippetId 
     * @param {string} conversationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetCommentsRouteApi
     */
    public getSnippetCommentsV1(workspaceId: string, snippetId: string, conversationId: string, options?: AxiosRequestConfig) {
        return SnippetCommentsRouteApiFp(this.configuration).getSnippetCommentsV1(workspaceId, snippetId, conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Conversations
     * @summary Get Conversations
     * @param {string} workspaceId 
     * @param {string} snippetId 
     * @param {string} [projection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetCommentsRouteApi
     */
    public getSnippetConversationsV1(workspaceId: string, snippetId: string, projection?: string, options?: AxiosRequestConfig) {
        return SnippetCommentsRouteApiFp(this.configuration).getSnippetConversationsV1(workspaceId, snippetId, projection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set Resolve status for a conversation
     * @summary Set Resolve status for a  conversation
     * @param {string} workspaceId 
     * @param {string} snippetId 
     * @param {string} conversationId 
     * @param {UpdateConversationRequest} updateConversationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetCommentsRouteApi
     */
    public setResolveStatusInConversationV1(workspaceId: string, snippetId: string, conversationId: string, updateConversationRequest: UpdateConversationRequest, options?: AxiosRequestConfig) {
        return SnippetCommentsRouteApiFp(this.configuration).setResolveStatusInConversationV1(workspaceId, snippetId, conversationId, updateConversationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SnippetRoutesApi - axios parameter creator
 * @export
 */
export const SnippetRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create new snippet
         * @summary Create new snippet
         * @param {string} workspaceId 
         * @param {AddSnippetRequest} addSnippetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSnippetV1: async (workspaceId: string, addSnippetRequest: AddSnippetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createSnippetV1', 'workspaceId', workspaceId)
            // verify required parameter 'addSnippetRequest' is not null or undefined
            assertParamExists('createSnippetV1', 'addSnippetRequest', addSnippetRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippets`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addSnippetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a pre-signed s3 url for uploading snippets assets.
         * @param {string} workspaceId 
         * @param {CreatePlatformAssetsUploadUrlRequest} createPlatformAssetsUploadUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSnippetsAssetsUploadUrlV1: async (workspaceId: string, createPlatformAssetsUploadUrlRequest: CreatePlatformAssetsUploadUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createSnippetsAssetsUploadUrlV1', 'workspaceId', workspaceId)
            // verify required parameter 'createPlatformAssetsUploadUrlRequest' is not null or undefined
            assertParamExists('createSnippetsAssetsUploadUrlV1', 'createPlatformAssetsUploadUrlRequest', createPlatformAssetsUploadUrlRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippets/assets/create-upload-url`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlatformAssetsUploadUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete snippet
         * @summary Delete snippet
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSnippetV1: async (workspaceId: string, snippetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteSnippetV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('deleteSnippetV1', 'snippetId', snippetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippets/{snippet_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Snippet Document
         * @summary Get Snippet Document
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnippetDocumentV1: async (workspaceId: string, snippetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getSnippetDocumentV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('getSnippetDocumentV1', 'snippetId', snippetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippets/{snippet_id}/document`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Snippet Info
         * @summary Get Snippet Info
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnippetV1: async (workspaceId: string, snippetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getSnippetV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('getSnippetV1', 'snippetId', snippetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippets/{snippet_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all paginated versions for a snippet
         * @summary Get all paginated versions for a snippet
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnippetVersionListV1: async (workspaceId: string, snippetId: string, start?: number, end?: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getSnippetVersionListV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('getSnippetVersionListV1', 'snippetId', snippetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippets/{snippet_id}/versions`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Publish snippet so that anyone in workspace can view it
         * @summary Publish snippet
         * @param {string} workspaceId 
         * @param {PublishSnippetRequest} publishSnippetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishSnippetV1: async (workspaceId: string, publishSnippetRequest: PublishSnippetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('publishSnippetV1', 'workspaceId', workspaceId)
            // verify required parameter 'publishSnippetRequest' is not null or undefined
            assertParamExists('publishSnippetV1', 'publishSnippetRequest', publishSnippetRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/publish`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishSnippetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Saves the snippet, Captures the snippet history if further updates are made
         * @summary Snippet save
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {UpdateSnippetRequest} updateSnippetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSnippetV1: async (workspaceId: string, snippetId: string, updateSnippetRequest: UpdateSnippetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('saveSnippetV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('saveSnippetV1', 'snippetId', snippetId)
            // verify required parameter 'updateSnippetRequest' is not null or undefined
            assertParamExists('saveSnippetV1', 'updateSnippetRequest', updateSnippetRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippets/{snippet_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSnippetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the snippets document, Captures the snippet history if further updates are made
         * @summary Updates snippets document(content)
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {UpdateSnippetDocumentRequest} updateSnippetDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSnippetDocumentV1: async (workspaceId: string, snippetId: string, updateSnippetDocumentRequest: UpdateSnippetDocumentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateSnippetDocumentV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('updateSnippetDocumentV1', 'snippetId', snippetId)
            // verify required parameter 'updateSnippetDocumentRequest' is not null or undefined
            assertParamExists('updateSnippetDocumentV1', 'updateSnippetDocumentRequest', updateSnippetDocumentRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippets/{snippet_id}/document`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSnippetDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update snippet state to ARCHIVED/ACTIVE Args:     workspace_id: workspace_id where the snippet is present     update_request:         snippet_ids: snippet id of the snippet which will be archived         artifact_state: ARCHIVED/ACTIVE     user:  Returns:
         * @summary Update snippet resources
         * @param {string} workspaceId 
         * @param {UpdateSnippetArtifactState} updateSnippetArtifactState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSnippetStateV1: async (workspaceId: string, updateSnippetArtifactState: UpdateSnippetArtifactState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateSnippetStateV1', 'workspaceId', workspaceId)
            // verify required parameter 'updateSnippetArtifactState' is not null or undefined
            assertParamExists('updateSnippetStateV1', 'updateSnippetArtifactState', updateSnippetArtifactState)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippets/state`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSnippetArtifactState, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SnippetRoutesApi - functional programming interface
 * @export
 */
export const SnippetRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SnippetRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create new snippet
         * @summary Create new snippet
         * @param {string} workspaceId 
         * @param {AddSnippetRequest} addSnippetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSnippetV1(workspaceId: string, addSnippetRequest: AddSnippetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddSnippetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSnippetV1(workspaceId, addSnippetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a pre-signed s3 url for uploading snippets assets.
         * @param {string} workspaceId 
         * @param {CreatePlatformAssetsUploadUrlRequest} createPlatformAssetsUploadUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSnippetsAssetsUploadUrlV1(workspaceId: string, createPlatformAssetsUploadUrlRequest: CreatePlatformAssetsUploadUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSnippetsAssetsUploadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSnippetsAssetsUploadUrlV1(workspaceId, createPlatformAssetsUploadUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete snippet
         * @summary Delete snippet
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSnippetV1(workspaceId: string, snippetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnippetDeleteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSnippetV1(workspaceId, snippetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Snippet Document
         * @summary Get Snippet Document
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnippetDocumentV1(workspaceId: string, snippetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSnippetDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnippetDocumentV1(workspaceId, snippetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Snippet Info
         * @summary Get Snippet Info
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnippetV1(workspaceId: string, snippetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnippetInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnippetV1(workspaceId, snippetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all paginated versions for a snippet
         * @summary Get all paginated versions for a snippet
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnippetVersionListV1(workspaceId: string, snippetId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSnippetVersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnippetVersionListV1(workspaceId, snippetId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Publish snippet so that anyone in workspace can view it
         * @summary Publish snippet
         * @param {string} workspaceId 
         * @param {PublishSnippetRequest} publishSnippetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishSnippetV1(workspaceId: string, publishSnippetRequest: PublishSnippetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnippetPublishResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishSnippetV1(workspaceId, publishSnippetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Saves the snippet, Captures the snippet history if further updates are made
         * @summary Snippet save
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {UpdateSnippetRequest} updateSnippetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSnippetV1(workspaceId: string, snippetId: string, updateSnippetRequest: UpdateSnippetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSnippetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSnippetV1(workspaceId, snippetId, updateSnippetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the snippets document, Captures the snippet history if further updates are made
         * @summary Updates snippets document(content)
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {UpdateSnippetDocumentRequest} updateSnippetDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSnippetDocumentV1(workspaceId: string, snippetId: string, updateSnippetDocumentRequest: UpdateSnippetDocumentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSnippetDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSnippetDocumentV1(workspaceId, snippetId, updateSnippetDocumentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update snippet state to ARCHIVED/ACTIVE Args:     workspace_id: workspace_id where the snippet is present     update_request:         snippet_ids: snippet id of the snippet which will be archived         artifact_state: ARCHIVED/ACTIVE     user:  Returns:
         * @summary Update snippet resources
         * @param {string} workspaceId 
         * @param {UpdateSnippetArtifactState} updateSnippetArtifactState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSnippetStateV1(workspaceId: string, updateSnippetArtifactState: UpdateSnippetArtifactState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateChangeAck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSnippetStateV1(workspaceId, updateSnippetArtifactState, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SnippetRoutesApi - factory interface
 * @export
 */
export const SnippetRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SnippetRoutesApiFp(configuration)
    return {
        /**
         * Create new snippet
         * @summary Create new snippet
         * @param {string} workspaceId 
         * @param {AddSnippetRequest} addSnippetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSnippetV1(workspaceId: string, addSnippetRequest: AddSnippetRequest, options?: any): AxiosPromise<AddSnippetResponse> {
            return localVarFp.createSnippetV1(workspaceId, addSnippetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a pre-signed s3 url for uploading snippets assets.
         * @param {string} workspaceId 
         * @param {CreatePlatformAssetsUploadUrlRequest} createPlatformAssetsUploadUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSnippetsAssetsUploadUrlV1(workspaceId: string, createPlatformAssetsUploadUrlRequest: CreatePlatformAssetsUploadUrlRequest, options?: any): AxiosPromise<CreateSnippetsAssetsUploadUrlResponse> {
            return localVarFp.createSnippetsAssetsUploadUrlV1(workspaceId, createPlatformAssetsUploadUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete snippet
         * @summary Delete snippet
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSnippetV1(workspaceId: string, snippetId: string, options?: any): AxiosPromise<SnippetDeleteResponse> {
            return localVarFp.deleteSnippetV1(workspaceId, snippetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Snippet Document
         * @summary Get Snippet Document
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnippetDocumentV1(workspaceId: string, snippetId: string, options?: any): AxiosPromise<GetSnippetDocumentResponse> {
            return localVarFp.getSnippetDocumentV1(workspaceId, snippetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Snippet Info
         * @summary Get Snippet Info
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnippetV1(workspaceId: string, snippetId: string, options?: any): AxiosPromise<SnippetInfoResponse> {
            return localVarFp.getSnippetV1(workspaceId, snippetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all paginated versions for a snippet
         * @summary Get all paginated versions for a snippet
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnippetVersionListV1(workspaceId: string, snippetId: string, start?: number, end?: number, ts?: string, options?: any): AxiosPromise<ListSnippetVersionResponse> {
            return localVarFp.getSnippetVersionListV1(workspaceId, snippetId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * Publish snippet so that anyone in workspace can view it
         * @summary Publish snippet
         * @param {string} workspaceId 
         * @param {PublishSnippetRequest} publishSnippetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishSnippetV1(workspaceId: string, publishSnippetRequest: PublishSnippetRequest, options?: any): AxiosPromise<SnippetPublishResponse> {
            return localVarFp.publishSnippetV1(workspaceId, publishSnippetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Saves the snippet, Captures the snippet history if further updates are made
         * @summary Snippet save
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {UpdateSnippetRequest} updateSnippetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSnippetV1(workspaceId: string, snippetId: string, updateSnippetRequest: UpdateSnippetRequest, options?: any): AxiosPromise<UpdateSnippetResponse> {
            return localVarFp.saveSnippetV1(workspaceId, snippetId, updateSnippetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the snippets document, Captures the snippet history if further updates are made
         * @summary Updates snippets document(content)
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {UpdateSnippetDocumentRequest} updateSnippetDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSnippetDocumentV1(workspaceId: string, snippetId: string, updateSnippetDocumentRequest: UpdateSnippetDocumentRequest, options?: any): AxiosPromise<UpdateSnippetDocumentResponse> {
            return localVarFp.updateSnippetDocumentV1(workspaceId, snippetId, updateSnippetDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update snippet state to ARCHIVED/ACTIVE Args:     workspace_id: workspace_id where the snippet is present     update_request:         snippet_ids: snippet id of the snippet which will be archived         artifact_state: ARCHIVED/ACTIVE     user:  Returns:
         * @summary Update snippet resources
         * @param {string} workspaceId 
         * @param {UpdateSnippetArtifactState} updateSnippetArtifactState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSnippetStateV1(workspaceId: string, updateSnippetArtifactState: UpdateSnippetArtifactState, options?: any): AxiosPromise<StateChangeAck> {
            return localVarFp.updateSnippetStateV1(workspaceId, updateSnippetArtifactState, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SnippetRoutesApi - object-oriented interface
 * @export
 * @class SnippetRoutesApi
 * @extends {BaseAPI}
 */
export class SnippetRoutesApi extends BaseAPI {
    /**
     * Create new snippet
     * @summary Create new snippet
     * @param {string} workspaceId 
     * @param {AddSnippetRequest} addSnippetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetRoutesApi
     */
    public createSnippetV1(workspaceId: string, addSnippetRequest: AddSnippetRequest, options?: AxiosRequestConfig) {
        return SnippetRoutesApiFp(this.configuration).createSnippetV1(workspaceId, addSnippetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a pre-signed s3 url for uploading snippets assets.
     * @param {string} workspaceId 
     * @param {CreatePlatformAssetsUploadUrlRequest} createPlatformAssetsUploadUrlRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetRoutesApi
     */
    public createSnippetsAssetsUploadUrlV1(workspaceId: string, createPlatformAssetsUploadUrlRequest: CreatePlatformAssetsUploadUrlRequest, options?: AxiosRequestConfig) {
        return SnippetRoutesApiFp(this.configuration).createSnippetsAssetsUploadUrlV1(workspaceId, createPlatformAssetsUploadUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete snippet
     * @summary Delete snippet
     * @param {string} workspaceId 
     * @param {string} snippetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetRoutesApi
     */
    public deleteSnippetV1(workspaceId: string, snippetId: string, options?: AxiosRequestConfig) {
        return SnippetRoutesApiFp(this.configuration).deleteSnippetV1(workspaceId, snippetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Snippet Document
     * @summary Get Snippet Document
     * @param {string} workspaceId 
     * @param {string} snippetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetRoutesApi
     */
    public getSnippetDocumentV1(workspaceId: string, snippetId: string, options?: AxiosRequestConfig) {
        return SnippetRoutesApiFp(this.configuration).getSnippetDocumentV1(workspaceId, snippetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Snippet Info
     * @summary Get Snippet Info
     * @param {string} workspaceId 
     * @param {string} snippetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetRoutesApi
     */
    public getSnippetV1(workspaceId: string, snippetId: string, options?: AxiosRequestConfig) {
        return SnippetRoutesApiFp(this.configuration).getSnippetV1(workspaceId, snippetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all paginated versions for a snippet
     * @summary Get all paginated versions for a snippet
     * @param {string} workspaceId 
     * @param {string} snippetId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetRoutesApi
     */
    public getSnippetVersionListV1(workspaceId: string, snippetId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig) {
        return SnippetRoutesApiFp(this.configuration).getSnippetVersionListV1(workspaceId, snippetId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Publish snippet so that anyone in workspace can view it
     * @summary Publish snippet
     * @param {string} workspaceId 
     * @param {PublishSnippetRequest} publishSnippetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetRoutesApi
     */
    public publishSnippetV1(workspaceId: string, publishSnippetRequest: PublishSnippetRequest, options?: AxiosRequestConfig) {
        return SnippetRoutesApiFp(this.configuration).publishSnippetV1(workspaceId, publishSnippetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Saves the snippet, Captures the snippet history if further updates are made
     * @summary Snippet save
     * @param {string} workspaceId 
     * @param {string} snippetId 
     * @param {UpdateSnippetRequest} updateSnippetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetRoutesApi
     */
    public saveSnippetV1(workspaceId: string, snippetId: string, updateSnippetRequest: UpdateSnippetRequest, options?: AxiosRequestConfig) {
        return SnippetRoutesApiFp(this.configuration).saveSnippetV1(workspaceId, snippetId, updateSnippetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the snippets document, Captures the snippet history if further updates are made
     * @summary Updates snippets document(content)
     * @param {string} workspaceId 
     * @param {string} snippetId 
     * @param {UpdateSnippetDocumentRequest} updateSnippetDocumentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetRoutesApi
     */
    public updateSnippetDocumentV1(workspaceId: string, snippetId: string, updateSnippetDocumentRequest: UpdateSnippetDocumentRequest, options?: AxiosRequestConfig) {
        return SnippetRoutesApiFp(this.configuration).updateSnippetDocumentV1(workspaceId, snippetId, updateSnippetDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update snippet state to ARCHIVED/ACTIVE Args:     workspace_id: workspace_id where the snippet is present     update_request:         snippet_ids: snippet id of the snippet which will be archived         artifact_state: ARCHIVED/ACTIVE     user:  Returns:
     * @summary Update snippet resources
     * @param {string} workspaceId 
     * @param {UpdateSnippetArtifactState} updateSnippetArtifactState 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetRoutesApi
     */
    public updateSnippetStateV1(workspaceId: string, updateSnippetArtifactState: UpdateSnippetArtifactState, options?: AxiosRequestConfig) {
        return SnippetRoutesApiFp(this.configuration).updateSnippetStateV1(workspaceId, updateSnippetArtifactState, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SnippetShareRoutesApi - axios parameter creator
 * @export
 */
export const SnippetShareRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * For public sharing , save chart metadata content
         * @summary For public sharing , save chart metadata in different table to be able to read for public routes
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {SnippetChartMetadataRequest} snippetChartMetadataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addChartsMetadataPublicSnippetV1: async (workspaceId: string, snippetId: string, snippetChartMetadataRequest: SnippetChartMetadataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addChartsMetadataPublicSnippetV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('addChartsMetadataPublicSnippetV1', 'snippetId', snippetId)
            // verify required parameter 'snippetChartMetadataRequest' is not null or undefined
            assertParamExists('addChartsMetadataPublicSnippetV1', 'snippetChartMetadataRequest', snippetChartMetadataRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippet/{snippet_id}/save-charts-metadata`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(snippetChartMetadataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For public sharing , save content
         * @summary For public sharing , save content in different table to be able to read for public routes
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {SnippetContentRequest} snippetContentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContentPublicSnippetV1: async (workspaceId: string, snippetId: string, snippetContentRequest: SnippetContentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addContentPublicSnippetV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('addContentPublicSnippetV1', 'snippetId', snippetId)
            // verify required parameter 'snippetContentRequest' is not null or undefined
            assertParamExists('addContentPublicSnippetV1', 'snippetContentRequest', snippetContentRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippet/{snippet_id}/save-content`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(snippetContentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For public sharing , save content
         * @summary For public sharing , save content in different table to be able to read for public routes
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {PublicSnippetDataRequest} publicSnippetDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDataPublicSnippetV1: async (workspaceId: string, snippetId: string, publicSnippetDataRequest: PublicSnippetDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addDataPublicSnippetV1', 'workspaceId', workspaceId)
            // verify required parameter 'snippetId' is not null or undefined
            assertParamExists('addDataPublicSnippetV1', 'snippetId', snippetId)
            // verify required parameter 'publicSnippetDataRequest' is not null or undefined
            assertParamExists('addDataPublicSnippetV1', 'publicSnippetDataRequest', publicSnippetDataRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippet/{snippet_id}/save-snippet-data`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publicSnippetDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify shared users list for a snippet
         * @summary Modify shared users list for a snippet
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {ShareSnippetRequest} shareSnippetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateSharedSnippetsUsersV1: async (workspaceId: string, userId: string, shareSnippetRequest: ShareSnippetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addOrUpdateSharedSnippetsUsersV1', 'workspaceId', workspaceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addOrUpdateSharedSnippetsUsersV1', 'userId', userId)
            // verify required parameter 'shareSnippetRequest' is not null or undefined
            assertParamExists('addOrUpdateSharedSnippetsUsersV1', 'shareSnippetRequest', shareSnippetRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/user/{user_id}/snippets`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareSnippetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all snippets which are shared to the logged in user
         * @summary Get all snippets which are shared to the logged in user
         * @param {string} workspaceId 
         * @param {string} [state] 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnippetListV1: async (workspaceId: string, state?: string, start?: number, end?: number, ts?: string, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getSnippetListV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippets`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove list of users from shared users for a given snippet
         * @summary Remove list of users from shared users for a given snippet
         * @param {any} workspaceId 
         * @param {ShareSnippetRequest} shareSnippetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUsersFromSnippetV1: async (workspaceId: any, shareSnippetRequest: ShareSnippetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('removeUsersFromSnippetV1', 'workspaceId', workspaceId)
            // verify required parameter 'shareSnippetRequest' is not null or undefined
            assertParamExists('removeUsersFromSnippetV1', 'shareSnippetRequest', shareSnippetRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/snippets/users`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareSnippetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SnippetShareRoutesApi - functional programming interface
 * @export
 */
export const SnippetShareRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SnippetShareRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * For public sharing , save chart metadata content
         * @summary For public sharing , save chart metadata in different table to be able to read for public routes
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {SnippetChartMetadataRequest} snippetChartMetadataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addChartsMetadataPublicSnippetV1(workspaceId: string, snippetId: string, snippetChartMetadataRequest: SnippetChartMetadataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnippetChartMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addChartsMetadataPublicSnippetV1(workspaceId, snippetId, snippetChartMetadataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * For public sharing , save content
         * @summary For public sharing , save content in different table to be able to read for public routes
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {SnippetContentRequest} snippetContentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addContentPublicSnippetV1(workspaceId: string, snippetId: string, snippetContentRequest: SnippetContentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnippetContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addContentPublicSnippetV1(workspaceId, snippetId, snippetContentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * For public sharing , save content
         * @summary For public sharing , save content in different table to be able to read for public routes
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {PublicSnippetDataRequest} publicSnippetDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDataPublicSnippetV1(workspaceId: string, snippetId: string, publicSnippetDataRequest: PublicSnippetDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicSnippetDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDataPublicSnippetV1(workspaceId, snippetId, publicSnippetDataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify shared users list for a snippet
         * @summary Modify shared users list for a snippet
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {ShareSnippetRequest} shareSnippetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateSharedSnippetsUsersV1(workspaceId: string, userId: string, shareSnippetRequest: ShareSnippetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareSnippetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateSharedSnippetsUsersV1(workspaceId, userId, shareSnippetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all snippets which are shared to the logged in user
         * @summary Get all snippets which are shared to the logged in user
         * @param {string} workspaceId 
         * @param {string} [state] 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnippetListV1(workspaceId: string, state?: string, start?: number, end?: number, ts?: string, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSnippetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnippetListV1(workspaceId, state, start, end, ts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove list of users from shared users for a given snippet
         * @summary Remove list of users from shared users for a given snippet
         * @param {any} workspaceId 
         * @param {ShareSnippetRequest} shareSnippetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUsersFromSnippetV1(workspaceId: any, shareSnippetRequest: ShareSnippetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareSnippetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUsersFromSnippetV1(workspaceId, shareSnippetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SnippetShareRoutesApi - factory interface
 * @export
 */
export const SnippetShareRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SnippetShareRoutesApiFp(configuration)
    return {
        /**
         * For public sharing , save chart metadata content
         * @summary For public sharing , save chart metadata in different table to be able to read for public routes
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {SnippetChartMetadataRequest} snippetChartMetadataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addChartsMetadataPublicSnippetV1(workspaceId: string, snippetId: string, snippetChartMetadataRequest: SnippetChartMetadataRequest, options?: any): AxiosPromise<SnippetChartMetadataResponse> {
            return localVarFp.addChartsMetadataPublicSnippetV1(workspaceId, snippetId, snippetChartMetadataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * For public sharing , save content
         * @summary For public sharing , save content in different table to be able to read for public routes
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {SnippetContentRequest} snippetContentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContentPublicSnippetV1(workspaceId: string, snippetId: string, snippetContentRequest: SnippetContentRequest, options?: any): AxiosPromise<SnippetContentResponse> {
            return localVarFp.addContentPublicSnippetV1(workspaceId, snippetId, snippetContentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * For public sharing , save content
         * @summary For public sharing , save content in different table to be able to read for public routes
         * @param {string} workspaceId 
         * @param {string} snippetId 
         * @param {PublicSnippetDataRequest} publicSnippetDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDataPublicSnippetV1(workspaceId: string, snippetId: string, publicSnippetDataRequest: PublicSnippetDataRequest, options?: any): AxiosPromise<PublicSnippetDataResponse> {
            return localVarFp.addDataPublicSnippetV1(workspaceId, snippetId, publicSnippetDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Modify shared users list for a snippet
         * @summary Modify shared users list for a snippet
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {ShareSnippetRequest} shareSnippetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateSharedSnippetsUsersV1(workspaceId: string, userId: string, shareSnippetRequest: ShareSnippetRequest, options?: any): AxiosPromise<ShareSnippetResponse> {
            return localVarFp.addOrUpdateSharedSnippetsUsersV1(workspaceId, userId, shareSnippetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all snippets which are shared to the logged in user
         * @summary Get all snippets which are shared to the logged in user
         * @param {string} workspaceId 
         * @param {string} [state] 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnippetListV1(workspaceId: string, state?: string, start?: number, end?: number, ts?: string, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<ListSnippetResponse> {
            return localVarFp.getSnippetListV1(workspaceId, state, start, end, ts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove list of users from shared users for a given snippet
         * @summary Remove list of users from shared users for a given snippet
         * @param {any} workspaceId 
         * @param {ShareSnippetRequest} shareSnippetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUsersFromSnippetV1(workspaceId: any, shareSnippetRequest: ShareSnippetRequest, options?: any): AxiosPromise<ShareSnippetResponse> {
            return localVarFp.removeUsersFromSnippetV1(workspaceId, shareSnippetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SnippetShareRoutesApi - object-oriented interface
 * @export
 * @class SnippetShareRoutesApi
 * @extends {BaseAPI}
 */
export class SnippetShareRoutesApi extends BaseAPI {
    /**
     * For public sharing , save chart metadata content
     * @summary For public sharing , save chart metadata in different table to be able to read for public routes
     * @param {string} workspaceId 
     * @param {string} snippetId 
     * @param {SnippetChartMetadataRequest} snippetChartMetadataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetShareRoutesApi
     */
    public addChartsMetadataPublicSnippetV1(workspaceId: string, snippetId: string, snippetChartMetadataRequest: SnippetChartMetadataRequest, options?: AxiosRequestConfig) {
        return SnippetShareRoutesApiFp(this.configuration).addChartsMetadataPublicSnippetV1(workspaceId, snippetId, snippetChartMetadataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * For public sharing , save content
     * @summary For public sharing , save content in different table to be able to read for public routes
     * @param {string} workspaceId 
     * @param {string} snippetId 
     * @param {SnippetContentRequest} snippetContentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetShareRoutesApi
     */
    public addContentPublicSnippetV1(workspaceId: string, snippetId: string, snippetContentRequest: SnippetContentRequest, options?: AxiosRequestConfig) {
        return SnippetShareRoutesApiFp(this.configuration).addContentPublicSnippetV1(workspaceId, snippetId, snippetContentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * For public sharing , save content
     * @summary For public sharing , save content in different table to be able to read for public routes
     * @param {string} workspaceId 
     * @param {string} snippetId 
     * @param {PublicSnippetDataRequest} publicSnippetDataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetShareRoutesApi
     */
    public addDataPublicSnippetV1(workspaceId: string, snippetId: string, publicSnippetDataRequest: PublicSnippetDataRequest, options?: AxiosRequestConfig) {
        return SnippetShareRoutesApiFp(this.configuration).addDataPublicSnippetV1(workspaceId, snippetId, publicSnippetDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify shared users list for a snippet
     * @summary Modify shared users list for a snippet
     * @param {string} workspaceId 
     * @param {string} userId 
     * @param {ShareSnippetRequest} shareSnippetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetShareRoutesApi
     */
    public addOrUpdateSharedSnippetsUsersV1(workspaceId: string, userId: string, shareSnippetRequest: ShareSnippetRequest, options?: AxiosRequestConfig) {
        return SnippetShareRoutesApiFp(this.configuration).addOrUpdateSharedSnippetsUsersV1(workspaceId, userId, shareSnippetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all snippets which are shared to the logged in user
     * @summary Get all snippets which are shared to the logged in user
     * @param {string} workspaceId 
     * @param {string} [state] 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetShareRoutesApi
     */
    public getSnippetListV1(workspaceId: string, state?: string, start?: number, end?: number, ts?: string, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return SnippetShareRoutesApiFp(this.configuration).getSnippetListV1(workspaceId, state, start, end, ts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove list of users from shared users for a given snippet
     * @summary Remove list of users from shared users for a given snippet
     * @param {any} workspaceId 
     * @param {ShareSnippetRequest} shareSnippetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SnippetShareRoutesApi
     */
    public removeUsersFromSnippetV1(workspaceId: any, shareSnippetRequest: ShareSnippetRequest, options?: AxiosRequestConfig) {
        return SnippetShareRoutesApiFp(this.configuration).removeUsersFromSnippetV1(workspaceId, shareSnippetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionManagementApi - axios parameter creator
 * @export
 */
export const SubscriptionManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Attach subscription to a workspace
         * @summary Attach Subscription To Workspace
         * @param {string} accountId 
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachSubscriptionToWorkspaceV1: async (accountId: string, subscriptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('attachSubscriptionToWorkspaceV1', 'accountId', accountId)
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('attachSubscriptionToWorkspaceV1', 'subscriptionId', subscriptionId)
            const localVarPath = `/haifa/v1/account/{account_id}/subscription/{subscription_id}/attach`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new subscription. Users can attach this subscription to their workspaces.
         * @summary Create a new subscription.
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {SubscriptionType} subscriptionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionV1: async (workspaceId: string, userId: string, subscriptionType: SubscriptionType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createSubscriptionV1', 'workspaceId', workspaceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createSubscriptionV1', 'userId', userId)
            // verify required parameter 'subscriptionType' is not null or undefined
            assertParamExists('createSubscriptionV1', 'subscriptionType', subscriptionType)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/user/{user_id}/create-subscription`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subscriptionType !== undefined) {
                localVarQueryParameter['subscription_type'] = subscriptionType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Script to create workspaces subscription membership table
         * @summary Create Workspace Subscription Membership
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspaceSubscriptionMembershipV1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/haifa/v1/migration_script/add_credit_type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detach subscription from a workspace
         * @summary Detach Subscription From Workspace
         * @param {string} accountId 
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachSubscriptionToWorkspaceV1: async (accountId: string, subscriptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('detachSubscriptionToWorkspaceV1', 'accountId', accountId)
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('detachSubscriptionToWorkspaceV1', 'subscriptionId', subscriptionId)
            const localVarPath = `/haifa/v1/account/{account_id}/subscription/{subscription_id}/detach`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all subscriptions associated with the account_id
         * @summary Fetch Account Subscriptions
         * @param {string} accountId 
         * @param {string} workspaceId 
         * @param {SubscriptionState} [subscriptionState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAccountSubscriptionsV1: async (accountId: string, workspaceId: string, subscriptionState?: SubscriptionState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('fetchAccountSubscriptionsV1', 'accountId', accountId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('fetchAccountSubscriptionsV1', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/v1/account/{account_id}/subscriptions`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspace_id'] = workspaceId;
            }

            if (subscriptionState !== undefined) {
                localVarQueryParameter['subscription_state'] = subscriptionState;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Refresh credits used
         * @summary Refresh Workspace Subscription Credits
         * @param {string} accountId 
         * @param {string} subscriptionId 
         * @param {TargetCreditLimits} targetCreditLimits 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshCreditsUsedV1: async (accountId: string, subscriptionId: string, targetCreditLimits: TargetCreditLimits, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('refreshCreditsUsedV1', 'accountId', accountId)
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('refreshCreditsUsedV1', 'subscriptionId', subscriptionId)
            // verify required parameter 'targetCreditLimits' is not null or undefined
            assertParamExists('refreshCreditsUsedV1', 'targetCreditLimits', targetCreditLimits)
            const localVarPath = `/haifa/v1/account/{account_id}/subscription/{subscription_id}/refresh-credits`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(targetCreditLimits, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update subscription credits metadata
         * @summary Update Subscription Credits For Workspace
         * @param {string} workspaceId 
         * @param {UsageMetadataModel} usageMetadataModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionCreditsForWorkspaceV1: async (workspaceId: string, usageMetadataModel: UsageMetadataModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateSubscriptionCreditsForWorkspaceV1', 'workspaceId', workspaceId)
            // verify required parameter 'usageMetadataModel' is not null or undefined
            assertParamExists('updateSubscriptionCreditsForWorkspaceV1', 'usageMetadataModel', usageMetadataModel)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/subscription/update-credits`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usageMetadataModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if user belongs to workspace, check if subscription has enough credits
         * @summary Check if user belongs to workspace, check if subscription has enough credits
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {CreditsType} creditsType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWorkspaceSubscriptionV1: async (workspaceId: string, userId: string, creditsType: CreditsType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('validateWorkspaceSubscriptionV1', 'workspaceId', workspaceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('validateWorkspaceSubscriptionV1', 'userId', userId)
            // verify required parameter 'creditsType' is not null or undefined
            assertParamExists('validateWorkspaceSubscriptionV1', 'creditsType', creditsType)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/user/{user_id}/validate-subscription`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (creditsType !== undefined) {
                localVarQueryParameter['credits_type'] = creditsType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionManagementApi - functional programming interface
 * @export
 */
export const SubscriptionManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Attach subscription to a workspace
         * @summary Attach Subscription To Workspace
         * @param {string} accountId 
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachSubscriptionToWorkspaceV1(accountId: string, subscriptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachSubscriptionToWorkspaceV1(accountId, subscriptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create new subscription. Users can attach this subscription to their workspaces.
         * @summary Create a new subscription.
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {SubscriptionType} subscriptionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscriptionV1(workspaceId: string, userId: string, subscriptionType: SubscriptionType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscriptionV1(workspaceId, userId, subscriptionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Script to create workspaces subscription membership table
         * @summary Create Workspace Subscription Membership
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkspaceSubscriptionMembershipV1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkspaceSubscriptionMembershipV1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Detach subscription from a workspace
         * @summary Detach Subscription From Workspace
         * @param {string} accountId 
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachSubscriptionToWorkspaceV1(accountId: string, subscriptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachSubscriptionToWorkspaceV1(accountId, subscriptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all subscriptions associated with the account_id
         * @summary Fetch Account Subscriptions
         * @param {string} accountId 
         * @param {string} workspaceId 
         * @param {SubscriptionState} [subscriptionState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAccountSubscriptionsV1(accountId: string, workspaceId: string, subscriptionState?: SubscriptionState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscriptions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAccountSubscriptionsV1(accountId, workspaceId, subscriptionState, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Refresh credits used
         * @summary Refresh Workspace Subscription Credits
         * @param {string} accountId 
         * @param {string} subscriptionId 
         * @param {TargetCreditLimits} targetCreditLimits 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshCreditsUsedV1(accountId: string, subscriptionId: string, targetCreditLimits: TargetCreditLimits, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshCreditsUsedV1(accountId, subscriptionId, targetCreditLimits, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update subscription credits metadata
         * @summary Update Subscription Credits For Workspace
         * @param {string} workspaceId 
         * @param {UsageMetadataModel} usageMetadataModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscriptionCreditsForWorkspaceV1(workspaceId: string, usageMetadataModel: UsageMetadataModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscriptionCreditsForWorkspaceV1(workspaceId, usageMetadataModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check if user belongs to workspace, check if subscription has enough credits
         * @summary Check if user belongs to workspace, check if subscription has enough credits
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {CreditsType} creditsType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateWorkspaceSubscriptionV1(workspaceId: string, userId: string, creditsType: CreditsType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateWorkspaceSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateWorkspaceSubscriptionV1(workspaceId, userId, creditsType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionManagementApi - factory interface
 * @export
 */
export const SubscriptionManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionManagementApiFp(configuration)
    return {
        /**
         * Attach subscription to a workspace
         * @summary Attach Subscription To Workspace
         * @param {string} accountId 
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachSubscriptionToWorkspaceV1(accountId: string, subscriptionId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.attachSubscriptionToWorkspaceV1(accountId, subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new subscription. Users can attach this subscription to their workspaces.
         * @summary Create a new subscription.
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {SubscriptionType} subscriptionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionV1(workspaceId: string, userId: string, subscriptionType: SubscriptionType, options?: any): AxiosPromise<CreateSubscriptionResponse> {
            return localVarFp.createSubscriptionV1(workspaceId, userId, subscriptionType, options).then((request) => request(axios, basePath));
        },
        /**
         * Script to create workspaces subscription membership table
         * @summary Create Workspace Subscription Membership
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspaceSubscriptionMembershipV1(options?: any): AxiosPromise<boolean> {
            return localVarFp.createWorkspaceSubscriptionMembershipV1(options).then((request) => request(axios, basePath));
        },
        /**
         * Detach subscription from a workspace
         * @summary Detach Subscription From Workspace
         * @param {string} accountId 
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachSubscriptionToWorkspaceV1(accountId: string, subscriptionId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.detachSubscriptionToWorkspaceV1(accountId, subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all subscriptions associated with the account_id
         * @summary Fetch Account Subscriptions
         * @param {string} accountId 
         * @param {string} workspaceId 
         * @param {SubscriptionState} [subscriptionState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAccountSubscriptionsV1(accountId: string, workspaceId: string, subscriptionState?: SubscriptionState, options?: any): AxiosPromise<Subscriptions> {
            return localVarFp.fetchAccountSubscriptionsV1(accountId, workspaceId, subscriptionState, options).then((request) => request(axios, basePath));
        },
        /**
         * Refresh credits used
         * @summary Refresh Workspace Subscription Credits
         * @param {string} accountId 
         * @param {string} subscriptionId 
         * @param {TargetCreditLimits} targetCreditLimits 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshCreditsUsedV1(accountId: string, subscriptionId: string, targetCreditLimits: TargetCreditLimits, options?: any): AxiosPromise<boolean> {
            return localVarFp.refreshCreditsUsedV1(accountId, subscriptionId, targetCreditLimits, options).then((request) => request(axios, basePath));
        },
        /**
         * Update subscription credits metadata
         * @summary Update Subscription Credits For Workspace
         * @param {string} workspaceId 
         * @param {UsageMetadataModel} usageMetadataModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionCreditsForWorkspaceV1(workspaceId: string, usageMetadataModel: UsageMetadataModel, options?: any): AxiosPromise<Subscription> {
            return localVarFp.updateSubscriptionCreditsForWorkspaceV1(workspaceId, usageMetadataModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Check if user belongs to workspace, check if subscription has enough credits
         * @summary Check if user belongs to workspace, check if subscription has enough credits
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {CreditsType} creditsType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWorkspaceSubscriptionV1(workspaceId: string, userId: string, creditsType: CreditsType, options?: any): AxiosPromise<ValidateWorkspaceSubscriptionResponse> {
            return localVarFp.validateWorkspaceSubscriptionV1(workspaceId, userId, creditsType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionManagementApi - object-oriented interface
 * @export
 * @class SubscriptionManagementApi
 * @extends {BaseAPI}
 */
export class SubscriptionManagementApi extends BaseAPI {
    /**
     * Attach subscription to a workspace
     * @summary Attach Subscription To Workspace
     * @param {string} accountId 
     * @param {string} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionManagementApi
     */
    public attachSubscriptionToWorkspaceV1(accountId: string, subscriptionId: string, options?: AxiosRequestConfig) {
        return SubscriptionManagementApiFp(this.configuration).attachSubscriptionToWorkspaceV1(accountId, subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new subscription. Users can attach this subscription to their workspaces.
     * @summary Create a new subscription.
     * @param {string} workspaceId 
     * @param {string} userId 
     * @param {SubscriptionType} subscriptionType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionManagementApi
     */
    public createSubscriptionV1(workspaceId: string, userId: string, subscriptionType: SubscriptionType, options?: AxiosRequestConfig) {
        return SubscriptionManagementApiFp(this.configuration).createSubscriptionV1(workspaceId, userId, subscriptionType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Script to create workspaces subscription membership table
     * @summary Create Workspace Subscription Membership
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionManagementApi
     */
    public createWorkspaceSubscriptionMembershipV1(options?: AxiosRequestConfig) {
        return SubscriptionManagementApiFp(this.configuration).createWorkspaceSubscriptionMembershipV1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Detach subscription from a workspace
     * @summary Detach Subscription From Workspace
     * @param {string} accountId 
     * @param {string} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionManagementApi
     */
    public detachSubscriptionToWorkspaceV1(accountId: string, subscriptionId: string, options?: AxiosRequestConfig) {
        return SubscriptionManagementApiFp(this.configuration).detachSubscriptionToWorkspaceV1(accountId, subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all subscriptions associated with the account_id
     * @summary Fetch Account Subscriptions
     * @param {string} accountId 
     * @param {string} workspaceId 
     * @param {SubscriptionState} [subscriptionState] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionManagementApi
     */
    public fetchAccountSubscriptionsV1(accountId: string, workspaceId: string, subscriptionState?: SubscriptionState, options?: AxiosRequestConfig) {
        return SubscriptionManagementApiFp(this.configuration).fetchAccountSubscriptionsV1(accountId, workspaceId, subscriptionState, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Refresh credits used
     * @summary Refresh Workspace Subscription Credits
     * @param {string} accountId 
     * @param {string} subscriptionId 
     * @param {TargetCreditLimits} targetCreditLimits 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionManagementApi
     */
    public refreshCreditsUsedV1(accountId: string, subscriptionId: string, targetCreditLimits: TargetCreditLimits, options?: AxiosRequestConfig) {
        return SubscriptionManagementApiFp(this.configuration).refreshCreditsUsedV1(accountId, subscriptionId, targetCreditLimits, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update subscription credits metadata
     * @summary Update Subscription Credits For Workspace
     * @param {string} workspaceId 
     * @param {UsageMetadataModel} usageMetadataModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionManagementApi
     */
    public updateSubscriptionCreditsForWorkspaceV1(workspaceId: string, usageMetadataModel: UsageMetadataModel, options?: AxiosRequestConfig) {
        return SubscriptionManagementApiFp(this.configuration).updateSubscriptionCreditsForWorkspaceV1(workspaceId, usageMetadataModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check if user belongs to workspace, check if subscription has enough credits
     * @summary Check if user belongs to workspace, check if subscription has enough credits
     * @param {string} workspaceId 
     * @param {string} userId 
     * @param {CreditsType} creditsType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionManagementApi
     */
    public validateWorkspaceSubscriptionV1(workspaceId: string, userId: string, creditsType: CreditsType, options?: AxiosRequestConfig) {
        return SubscriptionManagementApiFp(this.configuration).validateWorkspaceSubscriptionV1(workspaceId, userId, creditsType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubsetAnalysesRoutesApi - axios parameter creator
 * @export
 */
export const SubsetAnalysesRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a subset of dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {CreateSubsetRequest} createSubsetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataSubsetV1: async (workspaceId: string, datasetId: string, createSubsetRequest: CreateSubsetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createDataSubsetV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('createDataSubsetV1', 'datasetId', datasetId)
            // verify required parameter 'createSubsetRequest' is not null or undefined
            assertParamExists('createDataSubsetV1', 'createSubsetRequest', createSubsetRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/subsets`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSubsetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns actual analysis data the status of full analyses tasks executed for subset of a dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSubsetAnalysesTasksV1: async (workspaceId: string, datasetId: string, subsetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataSubsetAnalysesTasksV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDataSubsetAnalysesTasksV1', 'datasetId', datasetId)
            // verify required parameter 'subsetId' is not null or undefined
            assertParamExists('getDataSubsetAnalysesTasksV1', 'subsetId', subsetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/subsets/{subset_id}/analysis-tasks`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"subset_id"}}`, encodeURIComponent(String(subsetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analysis groups object (metadata containing info about all analysis run) for the subset of dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSubsetAnalysisGroupsV1: async (workspaceId: string, datasetId: string, subsetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataSubsetAnalysisGroupsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDataSubsetAnalysisGroupsV1', 'datasetId', datasetId)
            // verify required parameter 'subsetId' is not null or undefined
            assertParamExists('getDataSubsetAnalysisGroupsV1', 'subsetId', subsetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/subsets/{subset_id}/analysis-groups`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"subset_id"}}`, encodeURIComponent(String(subsetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list of available analysis that can be run on a dataset subset
         * @summary Returns list of available analysis that can be run on a dataset subset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSubsetAvailableAnalysesV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataSubsetAvailableAnalysesV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDataSubsetAvailableAnalysesV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/subset-tasklets`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get details about the subset for a dataset.
         * @summary Get details about the subset for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSubsetDetailV1: async (workspaceId: string, datasetId: string, subsetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataSubsetDetailV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDataSubsetDetailV1', 'datasetId', datasetId)
            // verify required parameter 'subsetId' is not null or undefined
            assertParamExists('getDataSubsetDetailV1', 'subsetId', subsetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/subsets/{subset_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"subset_id"}}`, encodeURIComponent(String(subsetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists subset for a dataset.
         * @summary Lists subset for a dataset. Subset is the metadata/identifier. The actual list of points in the subset aren\'t returned in this API.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSubsetsV1: async (workspaceId: string, datasetId: string, start: number, end: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDataSubsetsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDataSubsetsV1', 'datasetId', datasetId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getDataSubsetsV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getDataSubsetsV1', 'end', end)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/subsets`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a download link for the results of a subset analysis on all tasklets for a dataset.
         * @summary Get Download link for subset analysis results for all tasklets
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadLinkForSubsetAnalysisAllResultsV1: async (workspaceId: string, datasetId: string, subsetId: string, segmentType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDownloadLinkForSubsetAnalysisAllResultsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDownloadLinkForSubsetAnalysisAllResultsV1', 'datasetId', datasetId)
            // verify required parameter 'subsetId' is not null or undefined
            assertParamExists('getDownloadLinkForSubsetAnalysisAllResultsV1', 'subsetId', subsetId)
            // verify required parameter 'segmentType' is not null or undefined
            assertParamExists('getDownloadLinkForSubsetAnalysisAllResultsV1', 'segmentType', segmentType)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/subsets/{subset_id}/segment/{segment_type}/results`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"subset_id"}}`, encodeURIComponent(String(subsetId)))
                .replace(`{${"segment_type"}}`, encodeURIComponent(String(segmentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a download link for the results of a subset analysis for a dataset for a tasklet.
         * @summary Get Download link for subset analysis results for a tasklet
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {string} taskletId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadLinkForSubsetAnalysisTaskletResultsV1: async (workspaceId: string, datasetId: string, subsetId: string, taskletId: string, segmentType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDownloadLinkForSubsetAnalysisTaskletResultsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDownloadLinkForSubsetAnalysisTaskletResultsV1', 'datasetId', datasetId)
            // verify required parameter 'subsetId' is not null or undefined
            assertParamExists('getDownloadLinkForSubsetAnalysisTaskletResultsV1', 'subsetId', subsetId)
            // verify required parameter 'taskletId' is not null or undefined
            assertParamExists('getDownloadLinkForSubsetAnalysisTaskletResultsV1', 'taskletId', taskletId)
            // verify required parameter 'segmentType' is not null or undefined
            assertParamExists('getDownloadLinkForSubsetAnalysisTaskletResultsV1', 'segmentType', segmentType)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/subsets/{subset_id}/analysis-tasks/{tasklet_id}/segment/{segment_type}/results`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"subset_id"}}`, encodeURIComponent(String(subsetId)))
                .replace(`{${"tasklet_id"}}`, encodeURIComponent(String(taskletId)))
                .replace(`{${"segment_type"}}`, encodeURIComponent(String(segmentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns data for a graph of dataset subset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {string} analysisId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubsetAnalysisDataV1: async (workspaceId: string, datasetId: string, subsetId: string, analysisId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getSubsetAnalysisDataV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getSubsetAnalysisDataV1', 'datasetId', datasetId)
            // verify required parameter 'subsetId' is not null or undefined
            assertParamExists('getSubsetAnalysisDataV1', 'subsetId', subsetId)
            // verify required parameter 'analysisId' is not null or undefined
            assertParamExists('getSubsetAnalysisDataV1', 'analysisId', analysisId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subsets/{subset_id}/analysis/{analysis_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"subset_id"}}`, encodeURIComponent(String(subsetId)))
                .replace(`{${"analysis_id"}}`, encodeURIComponent(String(analysisId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns grouping info for Analysis, e.g. n-grams, clustering, etc.Each analysis group can have one or more type of visualizations.
         * @summary Returns grouping info for Analysis, e.g. n-grams, clustering, etc.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubsetAnalysisGroupedByMetricsV1: async (workspaceId: string, datasetId: string, subsetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listSubsetAnalysisGroupedByMetricsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('listSubsetAnalysisGroupedByMetricsV1', 'datasetId', datasetId)
            // verify required parameter 'subsetId' is not null or undefined
            assertParamExists('listSubsetAnalysisGroupedByMetricsV1', 'subsetId', subsetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subsets/{subset_id}/analysis-groups-by-metrics`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"subset_id"}}`, encodeURIComponent(String(subsetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list containing metadata of graphs in an analysis group for subset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {string} analysisGroupId 
         * @param {string} metricName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubsetAnalysisMetricsV1: async (workspaceId: string, datasetId: string, subsetId: string, analysisGroupId: string, metricName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listSubsetAnalysisMetricsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('listSubsetAnalysisMetricsV1', 'datasetId', datasetId)
            // verify required parameter 'subsetId' is not null or undefined
            assertParamExists('listSubsetAnalysisMetricsV1', 'subsetId', subsetId)
            // verify required parameter 'analysisGroupId' is not null or undefined
            assertParamExists('listSubsetAnalysisMetricsV1', 'analysisGroupId', analysisGroupId)
            // verify required parameter 'metricName' is not null or undefined
            assertParamExists('listSubsetAnalysisMetricsV1', 'metricName', metricName)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subsets/{subset_id}/analysis-groups-by-metrics/{analysis_group_id}/metrics/{metric_name}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"subset_id"}}`, encodeURIComponent(String(subsetId)))
                .replace(`{${"analysis_group_id"}}`, encodeURIComponent(String(analysisGroupId)))
                .replace(`{${"metric_name"}}`, encodeURIComponent(String(metricName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger analysis on subset of dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {RunSubsetAnalysesRequest} runSubsetAnalysesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerAnalysesForDataSubsetV1: async (workspaceId: string, datasetId: string, subsetId: string, runSubsetAnalysesRequest: RunSubsetAnalysesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('triggerAnalysesForDataSubsetV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('triggerAnalysesForDataSubsetV1', 'datasetId', datasetId)
            // verify required parameter 'subsetId' is not null or undefined
            assertParamExists('triggerAnalysesForDataSubsetV1', 'subsetId', subsetId)
            // verify required parameter 'runSubsetAnalysesRequest' is not null or undefined
            assertParamExists('triggerAnalysesForDataSubsetV1', 'runSubsetAnalysesRequest', runSubsetAnalysesRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/subsets/{subset_id}/trigger`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"subset_id"}}`, encodeURIComponent(String(subsetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(runSubsetAnalysesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubsetAnalysesRoutesApi - functional programming interface
 * @export
 */
export const SubsetAnalysesRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubsetAnalysesRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a subset of dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {CreateSubsetRequest} createSubsetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDataSubsetV1(workspaceId: string, datasetId: string, createSubsetRequest: CreateSubsetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSubsetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDataSubsetV1(workspaceId, datasetId, createSubsetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns actual analysis data the status of full analyses tasks executed for subset of a dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataSubsetAnalysesTasksV1(workspaceId: string, datasetId: string, subsetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubsetAnalysisTasksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataSubsetAnalysesTasksV1(workspaceId, datasetId, subsetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analysis groups object (metadata containing info about all analysis run) for the subset of dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataSubsetAnalysisGroupsV1(workspaceId: string, datasetId: string, subsetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubsetAnalysesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataSubsetAnalysisGroupsV1(workspaceId, datasetId, subsetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns list of available analysis that can be run on a dataset subset
         * @summary Returns list of available analysis that can be run on a dataset subset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataSubsetAvailableAnalysesV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubsetAvailableAnalysesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataSubsetAvailableAnalysesV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get details about the subset for a dataset.
         * @summary Get details about the subset for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataSubsetDetailV1(workspaceId: string, datasetId: string, subsetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubsetDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataSubsetDetailV1(workspaceId, datasetId, subsetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists subset for a dataset.
         * @summary Lists subset for a dataset. Subset is the metadata/identifier. The actual list of points in the subset aren\'t returned in this API.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataSubsetsV1(workspaceId: string, datasetId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubsetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataSubsetsV1(workspaceId, datasetId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a download link for the results of a subset analysis on all tasklets for a dataset.
         * @summary Get Download link for subset analysis results for all tasklets
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownloadLinkForSubsetAnalysisAllResultsV1(workspaceId: string, datasetId: string, subsetId: string, segmentType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubsetAnalysisResultsDownloadLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadLinkForSubsetAnalysisAllResultsV1(workspaceId, datasetId, subsetId, segmentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a download link for the results of a subset analysis for a dataset for a tasklet.
         * @summary Get Download link for subset analysis results for a tasklet
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {string} taskletId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownloadLinkForSubsetAnalysisTaskletResultsV1(workspaceId: string, datasetId: string, subsetId: string, taskletId: string, segmentType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisResultsDownloadLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadLinkForSubsetAnalysisTaskletResultsV1(workspaceId, datasetId, subsetId, taskletId, segmentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns data for a graph of dataset subset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {string} analysisId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubsetAnalysisDataV1(workspaceId: string, datasetId: string, subsetId: string, analysisId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnalysisResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubsetAnalysisDataV1(workspaceId, datasetId, subsetId, analysisId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns grouping info for Analysis, e.g. n-grams, clustering, etc.Each analysis group can have one or more type of visualizations.
         * @summary Returns grouping info for Analysis, e.g. n-grams, clustering, etc.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSubsetAnalysisGroupedByMetricsV1(workspaceId: string, datasetId: string, subsetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAnalysisGroupsByMetricResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSubsetAnalysisGroupedByMetricsV1(workspaceId, datasetId, subsetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list containing metadata of graphs in an analysis group for subset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {string} analysisGroupId 
         * @param {string} metricName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSubsetAnalysisMetricsV1(workspaceId: string, datasetId: string, subsetId: string, analysisGroupId: string, metricName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAnalysisMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSubsetAnalysisMetricsV1(workspaceId, datasetId, subsetId, analysisGroupId, metricName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Trigger analysis on subset of dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {RunSubsetAnalysesRequest} runSubsetAnalysesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerAnalysesForDataSubsetV1(workspaceId: string, datasetId: string, subsetId: string, runSubsetAnalysesRequest: RunSubsetAnalysesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunSubsetAnalysesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerAnalysesForDataSubsetV1(workspaceId, datasetId, subsetId, runSubsetAnalysesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubsetAnalysesRoutesApi - factory interface
 * @export
 */
export const SubsetAnalysesRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubsetAnalysesRoutesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a subset of dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {CreateSubsetRequest} createSubsetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataSubsetV1(workspaceId: string, datasetId: string, createSubsetRequest: CreateSubsetRequest, options?: any): AxiosPromise<CreateSubsetResponse> {
            return localVarFp.createDataSubsetV1(workspaceId, datasetId, createSubsetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns actual analysis data the status of full analyses tasks executed for subset of a dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSubsetAnalysesTasksV1(workspaceId: string, datasetId: string, subsetId: string, options?: any): AxiosPromise<GetSubsetAnalysisTasksResponse> {
            return localVarFp.getDataSubsetAnalysesTasksV1(workspaceId, datasetId, subsetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analysis groups object (metadata containing info about all analysis run) for the subset of dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSubsetAnalysisGroupsV1(workspaceId: string, datasetId: string, subsetId: string, options?: any): AxiosPromise<GetSubsetAnalysesResponse> {
            return localVarFp.getDataSubsetAnalysisGroupsV1(workspaceId, datasetId, subsetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list of available analysis that can be run on a dataset subset
         * @summary Returns list of available analysis that can be run on a dataset subset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSubsetAvailableAnalysesV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<GetSubsetAvailableAnalysesResponse> {
            return localVarFp.getDataSubsetAvailableAnalysesV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get details about the subset for a dataset.
         * @summary Get details about the subset for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSubsetDetailV1(workspaceId: string, datasetId: string, subsetId: string, options?: any): AxiosPromise<GetSubsetDetailsResponse> {
            return localVarFp.getDataSubsetDetailV1(workspaceId, datasetId, subsetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists subset for a dataset.
         * @summary Lists subset for a dataset. Subset is the metadata/identifier. The actual list of points in the subset aren\'t returned in this API.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSubsetsV1(workspaceId: string, datasetId: string, start: number, end: number, ts?: string, options?: any): AxiosPromise<GetSubsetsResponse> {
            return localVarFp.getDataSubsetsV1(workspaceId, datasetId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a download link for the results of a subset analysis on all tasklets for a dataset.
         * @summary Get Download link for subset analysis results for all tasklets
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadLinkForSubsetAnalysisAllResultsV1(workspaceId: string, datasetId: string, subsetId: string, segmentType: string, options?: any): AxiosPromise<SubsetAnalysisResultsDownloadLinkResponse> {
            return localVarFp.getDownloadLinkForSubsetAnalysisAllResultsV1(workspaceId, datasetId, subsetId, segmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a download link for the results of a subset analysis for a dataset for a tasklet.
         * @summary Get Download link for subset analysis results for a tasklet
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {string} taskletId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadLinkForSubsetAnalysisTaskletResultsV1(workspaceId: string, datasetId: string, subsetId: string, taskletId: string, segmentType: string, options?: any): AxiosPromise<AnalysisResultsDownloadLinkResponse> {
            return localVarFp.getDownloadLinkForSubsetAnalysisTaskletResultsV1(workspaceId, datasetId, subsetId, taskletId, segmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns data for a graph of dataset subset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {string} analysisId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubsetAnalysisDataV1(workspaceId: string, datasetId: string, subsetId: string, analysisId: string, options?: any): AxiosPromise<GetAnalysisResponse> {
            return localVarFp.getSubsetAnalysisDataV1(workspaceId, datasetId, subsetId, analysisId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns grouping info for Analysis, e.g. n-grams, clustering, etc.Each analysis group can have one or more type of visualizations.
         * @summary Returns grouping info for Analysis, e.g. n-grams, clustering, etc.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubsetAnalysisGroupedByMetricsV1(workspaceId: string, datasetId: string, subsetId: string, options?: any): AxiosPromise<ListAnalysisGroupsByMetricResponse> {
            return localVarFp.listSubsetAnalysisGroupedByMetricsV1(workspaceId, datasetId, subsetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list containing metadata of graphs in an analysis group for subset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {string} analysisGroupId 
         * @param {string} metricName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubsetAnalysisMetricsV1(workspaceId: string, datasetId: string, subsetId: string, analysisGroupId: string, metricName: string, options?: any): AxiosPromise<ListAnalysisMetricsResponse> {
            return localVarFp.listSubsetAnalysisMetricsV1(workspaceId, datasetId, subsetId, analysisGroupId, metricName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger analysis on subset of dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} subsetId 
         * @param {RunSubsetAnalysesRequest} runSubsetAnalysesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerAnalysesForDataSubsetV1(workspaceId: string, datasetId: string, subsetId: string, runSubsetAnalysesRequest: RunSubsetAnalysesRequest, options?: any): AxiosPromise<RunSubsetAnalysesResponse> {
            return localVarFp.triggerAnalysesForDataSubsetV1(workspaceId, datasetId, subsetId, runSubsetAnalysesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubsetAnalysesRoutesApi - object-oriented interface
 * @export
 * @class SubsetAnalysesRoutesApi
 * @extends {BaseAPI}
 */
export class SubsetAnalysesRoutesApi extends BaseAPI {
    /**
     * 
     * @summary Create a subset of dataset
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {CreateSubsetRequest} createSubsetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetAnalysesRoutesApi
     */
    public createDataSubsetV1(workspaceId: string, datasetId: string, createSubsetRequest: CreateSubsetRequest, options?: AxiosRequestConfig) {
        return SubsetAnalysesRoutesApiFp(this.configuration).createDataSubsetV1(workspaceId, datasetId, createSubsetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns actual analysis data the status of full analyses tasks executed for subset of a dataset
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} subsetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetAnalysesRoutesApi
     */
    public getDataSubsetAnalysesTasksV1(workspaceId: string, datasetId: string, subsetId: string, options?: AxiosRequestConfig) {
        return SubsetAnalysesRoutesApiFp(this.configuration).getDataSubsetAnalysesTasksV1(workspaceId, datasetId, subsetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analysis groups object (metadata containing info about all analysis run) for the subset of dataset
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} subsetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetAnalysesRoutesApi
     */
    public getDataSubsetAnalysisGroupsV1(workspaceId: string, datasetId: string, subsetId: string, options?: AxiosRequestConfig) {
        return SubsetAnalysesRoutesApiFp(this.configuration).getDataSubsetAnalysisGroupsV1(workspaceId, datasetId, subsetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list of available analysis that can be run on a dataset subset
     * @summary Returns list of available analysis that can be run on a dataset subset
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetAnalysesRoutesApi
     */
    public getDataSubsetAvailableAnalysesV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return SubsetAnalysesRoutesApiFp(this.configuration).getDataSubsetAvailableAnalysesV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get details about the subset for a dataset.
     * @summary Get details about the subset for a dataset.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} subsetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetAnalysesRoutesApi
     */
    public getDataSubsetDetailV1(workspaceId: string, datasetId: string, subsetId: string, options?: AxiosRequestConfig) {
        return SubsetAnalysesRoutesApiFp(this.configuration).getDataSubsetDetailV1(workspaceId, datasetId, subsetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists subset for a dataset.
     * @summary Lists subset for a dataset. Subset is the metadata/identifier. The actual list of points in the subset aren\'t returned in this API.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetAnalysesRoutesApi
     */
    public getDataSubsetsV1(workspaceId: string, datasetId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig) {
        return SubsetAnalysesRoutesApiFp(this.configuration).getDataSubsetsV1(workspaceId, datasetId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a download link for the results of a subset analysis on all tasklets for a dataset.
     * @summary Get Download link for subset analysis results for all tasklets
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} subsetId 
     * @param {string} segmentType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetAnalysesRoutesApi
     */
    public getDownloadLinkForSubsetAnalysisAllResultsV1(workspaceId: string, datasetId: string, subsetId: string, segmentType: string, options?: AxiosRequestConfig) {
        return SubsetAnalysesRoutesApiFp(this.configuration).getDownloadLinkForSubsetAnalysisAllResultsV1(workspaceId, datasetId, subsetId, segmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a download link for the results of a subset analysis for a dataset for a tasklet.
     * @summary Get Download link for subset analysis results for a tasklet
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} subsetId 
     * @param {string} taskletId 
     * @param {string} segmentType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetAnalysesRoutesApi
     */
    public getDownloadLinkForSubsetAnalysisTaskletResultsV1(workspaceId: string, datasetId: string, subsetId: string, taskletId: string, segmentType: string, options?: AxiosRequestConfig) {
        return SubsetAnalysesRoutesApiFp(this.configuration).getDownloadLinkForSubsetAnalysisTaskletResultsV1(workspaceId, datasetId, subsetId, taskletId, segmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns data for a graph of dataset subset.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} subsetId 
     * @param {string} analysisId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetAnalysesRoutesApi
     */
    public getSubsetAnalysisDataV1(workspaceId: string, datasetId: string, subsetId: string, analysisId: string, options?: AxiosRequestConfig) {
        return SubsetAnalysesRoutesApiFp(this.configuration).getSubsetAnalysisDataV1(workspaceId, datasetId, subsetId, analysisId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns grouping info for Analysis, e.g. n-grams, clustering, etc.Each analysis group can have one or more type of visualizations.
     * @summary Returns grouping info for Analysis, e.g. n-grams, clustering, etc.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} subsetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetAnalysesRoutesApi
     */
    public listSubsetAnalysisGroupedByMetricsV1(workspaceId: string, datasetId: string, subsetId: string, options?: AxiosRequestConfig) {
        return SubsetAnalysesRoutesApiFp(this.configuration).listSubsetAnalysisGroupedByMetricsV1(workspaceId, datasetId, subsetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list containing metadata of graphs in an analysis group for subset.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} subsetId 
     * @param {string} analysisGroupId 
     * @param {string} metricName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetAnalysesRoutesApi
     */
    public listSubsetAnalysisMetricsV1(workspaceId: string, datasetId: string, subsetId: string, analysisGroupId: string, metricName: string, options?: AxiosRequestConfig) {
        return SubsetAnalysesRoutesApiFp(this.configuration).listSubsetAnalysisMetricsV1(workspaceId, datasetId, subsetId, analysisGroupId, metricName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trigger analysis on subset of dataset
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} subsetId 
     * @param {RunSubsetAnalysesRequest} runSubsetAnalysesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetAnalysesRoutesApi
     */
    public triggerAnalysesForDataSubsetV1(workspaceId: string, datasetId: string, subsetId: string, runSubsetAnalysesRequest: RunSubsetAnalysesRequest, options?: AxiosRequestConfig) {
        return SubsetAnalysesRoutesApiFp(this.configuration).triggerAnalysesForDataSubsetV1(workspaceId, datasetId, subsetId, runSubsetAnalysesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubsetRelabelingRoutesApi - axios parameter creator
 * @export
 */
export const SubsetRelabelingRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lists rows to relabel for a dataset subset.
         * @summary Lists records to be relabeled for for a subset (of a dataset).If available, a recommended label would be shared by the system.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {Array<string>} [targetClasses] 
         * @param {Array<SegmentType>} [segments] 
         * @param {boolean} [isResolved] 
         * @param {boolean} [isAssigned] 
         * @param {Array<string>} [assignedTo] 
         * @param {Array<string>} [resolvedBy] 
         * @param {SortOrder} [sortOrder] 
         * @param {boolean} [onlyMislabeled] 
         * @param {RelabelingSortField} [sortBy] 
         * @param {Array<number>} [regions] 
         * @param {Array<number>} [clusters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubsetRelabelingRecordsV1: async (workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, targetClasses?: Array<string>, segments?: Array<SegmentType>, isResolved?: boolean, isAssigned?: boolean, assignedTo?: Array<string>, resolvedBy?: Array<string>, sortOrder?: SortOrder, onlyMislabeled?: boolean, sortBy?: RelabelingSortField, regions?: Array<number>, clusters?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getSubsetRelabelingRecordsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getSubsetRelabelingRecordsV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('getSubsetRelabelingRecordsV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subset-relabeling/{data_operation_id}/rows`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (targetClasses) {
                localVarQueryParameter['target_classes'] = targetClasses;
            }

            if (segments) {
                localVarQueryParameter['segments'] = segments;
            }

            if (isResolved !== undefined) {
                localVarQueryParameter['is_resolved'] = isResolved;
            }

            if (isAssigned !== undefined) {
                localVarQueryParameter['is_assigned'] = isAssigned;
            }

            if (assignedTo) {
                localVarQueryParameter['assigned_to'] = assignedTo;
            }

            if (resolvedBy) {
                localVarQueryParameter['resolved_by'] = resolvedBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort_order'] = sortOrder;
            }

            if (onlyMislabeled !== undefined) {
                localVarQueryParameter['only_mislabeled'] = onlyMislabeled;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (regions) {
                localVarQueryParameter['regions'] = regions;
            }

            if (clusters) {
                localVarQueryParameter['clusters'] = clusters;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary of relabeling workflow for a dataset.
         * @summary Get summary of relabeling workflow for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubsetRelabelingSummaryV1: async (workspaceId: string, datasetId: string, dataOperationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getSubsetRelabelingSummaryV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getSubsetRelabelingSummaryV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('getSubsetRelabelingSummaryV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subset-relabeling/{data_operation_id}/summary`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accept recommendations i.e set resolved_label to predicted_label.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingAcceptRecommendationsV1: async (workspaceId: string, datasetId: string, dataOperationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('subsetRelabelingAcceptRecommendationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('subsetRelabelingAcceptRecommendationsV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('subsetRelabelingAcceptRecommendationsV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subset-relabeling/{data_operation_id}/accept-recommendations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds to the list of possible labels that can be applied
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {AddRelabelingLabelsRequest} addRelabelingLabelsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingAddLabelsV1: async (workspaceId: string, datasetId: string, dataOperationId: string, addRelabelingLabelsRequest: AddRelabelingLabelsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('subsetRelabelingAddLabelsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('subsetRelabelingAddLabelsV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('subsetRelabelingAddLabelsV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'addRelabelingLabelsRequest' is not null or undefined
            assertParamExists('subsetRelabelingAddLabelsV1', 'addRelabelingLabelsRequest', addRelabelingLabelsRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subset-relabeling/{data_operation_id}/labels`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addRelabelingLabelsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of applied labels
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingAppliedLabelsV1: async (workspaceId: string, datasetId: string, dataOperationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('subsetRelabelingAppliedLabelsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('subsetRelabelingAppliedLabelsV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('subsetRelabelingAppliedLabelsV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subset-relabeling/{data_operation_id}/applied-labels`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign mislabeled data records to relabeling  with assignee
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {AssignUserToRelabelingRequest} assignUserToRelabelingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingAssignUserV1: async (workspaceId: string, datasetId: string, dataOperationId: string, assignUserToRelabelingRequest: AssignUserToRelabelingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('subsetRelabelingAssignUserV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('subsetRelabelingAssignUserV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('subsetRelabelingAssignUserV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'assignUserToRelabelingRequest' is not null or undefined
            assertParamExists('subsetRelabelingAssignUserV1', 'assignUserToRelabelingRequest', assignUserToRelabelingRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subset-relabeling/{data_operation_id}/assign`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignUserToRelabelingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of clusters, and regions in the data to be relabeled
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingClustersRegionsLabelsV1: async (workspaceId: string, datasetId: string, dataOperationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('subsetRelabelingClustersRegionsLabelsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('subsetRelabelingClustersRegionsLabelsV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('subsetRelabelingClustersRegionsLabelsV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subset-relabeling/{data_operation_id}/available-regions-clusters`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new version of dataset utilising semi-supervised learning 
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingCreateDatasetWithSupervisionV1: async (workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('subsetRelabelingCreateDatasetWithSupervisionV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('subsetRelabelingCreateDatasetWithSupervisionV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('subsetRelabelingCreateDatasetWithSupervisionV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'createNewVersionDatasetRequest' is not null or undefined
            assertParamExists('subsetRelabelingCreateDatasetWithSupervisionV1', 'createNewVersionDatasetRequest', createNewVersionDatasetRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subset-relabeling/{data_operation_id}/relabel-with-supervision`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNewVersionDatasetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new version of dataset with resolved recommendations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingCreateNewVersionDatasetV1: async (workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('subsetRelabelingCreateNewVersionDatasetV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('subsetRelabelingCreateNewVersionDatasetV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('subsetRelabelingCreateNewVersionDatasetV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'createNewVersionDatasetRequest' is not null or undefined
            assertParamExists('subsetRelabelingCreateNewVersionDatasetV1', 'createNewVersionDatasetRequest', createNewVersionDatasetRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subset-relabeling/{data_operation_id}/register-new-version`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNewVersionDatasetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of dataset relabeling contributors
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingGetContributorsV1: async (workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('subsetRelabelingGetContributorsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('subsetRelabelingGetContributorsV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('subsetRelabelingGetContributorsV1', 'dataOperationId', dataOperationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subset-relabeling/{data_operation_id}/contributors`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign a new label to selected dataset clusters
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {ResolveClustersRequest} resolveClustersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingResolveClusterV1: async (workspaceId: string, datasetId: string, dataOperationId: string, resolveClustersRequest: ResolveClustersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('subsetRelabelingResolveClusterV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('subsetRelabelingResolveClusterV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('subsetRelabelingResolveClusterV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'resolveClustersRequest' is not null or undefined
            assertParamExists('subsetRelabelingResolveClusterV1', 'resolveClustersRequest', resolveClustersRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subset-relabeling/{data_operation_id}/resolve-clusters`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resolveClustersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign a new label to selected dataset subset regions
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {ResolveRegionRequest} resolveRegionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingResolveRegionV1: async (workspaceId: string, datasetId: string, dataOperationId: string, resolveRegionRequest: ResolveRegionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('subsetRelabelingResolveRegionV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('subsetRelabelingResolveRegionV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('subsetRelabelingResolveRegionV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'resolveRegionRequest' is not null or undefined
            assertParamExists('subsetRelabelingResolveRegionV1', 'resolveRegionRequest', resolveRegionRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subset-relabeling/{data_operation_id}/resolve-regions`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resolveRegionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign a new label to selected dataset rows
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {ResolveMislabeledRecordsRequest} resolveMislabeledRecordsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingResolveV1: async (workspaceId: string, datasetId: string, dataOperationId: string, resolveMislabeledRecordsRequest: ResolveMislabeledRecordsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('subsetRelabelingResolveV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('subsetRelabelingResolveV1', 'datasetId', datasetId)
            // verify required parameter 'dataOperationId' is not null or undefined
            assertParamExists('subsetRelabelingResolveV1', 'dataOperationId', dataOperationId)
            // verify required parameter 'resolveMislabeledRecordsRequest' is not null or undefined
            assertParamExists('subsetRelabelingResolveV1', 'resolveMislabeledRecordsRequest', resolveMislabeledRecordsRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/subset-relabeling/{data_operation_id}/resolve`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"data_operation_id"}}`, encodeURIComponent(String(dataOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resolveMislabeledRecordsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubsetRelabelingRoutesApi - functional programming interface
 * @export
 */
export const SubsetRelabelingRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubsetRelabelingRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Lists rows to relabel for a dataset subset.
         * @summary Lists records to be relabeled for for a subset (of a dataset).If available, a recommended label would be shared by the system.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {Array<string>} [targetClasses] 
         * @param {Array<SegmentType>} [segments] 
         * @param {boolean} [isResolved] 
         * @param {boolean} [isAssigned] 
         * @param {Array<string>} [assignedTo] 
         * @param {Array<string>} [resolvedBy] 
         * @param {SortOrder} [sortOrder] 
         * @param {boolean} [onlyMislabeled] 
         * @param {RelabelingSortField} [sortBy] 
         * @param {Array<number>} [regions] 
         * @param {Array<number>} [clusters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubsetRelabelingRecordsV1(workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, targetClasses?: Array<string>, segments?: Array<SegmentType>, isResolved?: boolean, isAssigned?: boolean, assignedTo?: Array<string>, resolvedBy?: Array<string>, sortOrder?: SortOrder, onlyMislabeled?: boolean, sortBy?: RelabelingSortField, regions?: Array<number>, clusters?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMislabeledRecordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubsetRelabelingRecordsV1(workspaceId, datasetId, dataOperationId, start, end, targetClasses, segments, isResolved, isAssigned, assignedTo, resolvedBy, sortOrder, onlyMislabeled, sortBy, regions, clusters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get summary of relabeling workflow for a dataset.
         * @summary Get summary of relabeling workflow for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubsetRelabelingSummaryV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelabelingSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubsetRelabelingSummaryV1(workspaceId, datasetId, dataOperationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Accept recommendations i.e set resolved_label to predicted_label.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subsetRelabelingAcceptRecommendationsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptAllRecommendationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subsetRelabelingAcceptRecommendationsV1(workspaceId, datasetId, dataOperationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds to the list of possible labels that can be applied
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {AddRelabelingLabelsRequest} addRelabelingLabelsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subsetRelabelingAddLabelsV1(workspaceId: string, datasetId: string, dataOperationId: string, addRelabelingLabelsRequest: AddRelabelingLabelsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subsetRelabelingAddLabelsV1(workspaceId, datasetId, dataOperationId, addRelabelingLabelsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of applied labels
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subsetRelabelingAppliedLabelsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelabelingAppliedLabelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subsetRelabelingAppliedLabelsV1(workspaceId, datasetId, dataOperationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign mislabeled data records to relabeling  with assignee
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {AssignUserToRelabelingRequest} assignUserToRelabelingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subsetRelabelingAssignUserV1(workspaceId: string, datasetId: string, dataOperationId: string, assignUserToRelabelingRequest: AssignUserToRelabelingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignUserToRelabelingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subsetRelabelingAssignUserV1(workspaceId, datasetId, dataOperationId, assignUserToRelabelingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of clusters, and regions in the data to be relabeled
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subsetRelabelingClustersRegionsLabelsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelabelingRegionsClustersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subsetRelabelingClustersRegionsLabelsV1(workspaceId, datasetId, dataOperationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new version of dataset utilising semi-supervised learning 
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subsetRelabelingCreateDatasetWithSupervisionV1(workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNewVersionDatasetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subsetRelabelingCreateDatasetWithSupervisionV1(workspaceId, datasetId, dataOperationId, createNewVersionDatasetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new version of dataset with resolved recommendations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subsetRelabelingCreateNewVersionDatasetV1(workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNewVersionDatasetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subsetRelabelingCreateNewVersionDatasetV1(workspaceId, datasetId, dataOperationId, createNewVersionDatasetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of dataset relabeling contributors
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subsetRelabelingGetContributorsV1(workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelabelingContributorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subsetRelabelingGetContributorsV1(workspaceId, datasetId, dataOperationId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign a new label to selected dataset clusters
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {ResolveClustersRequest} resolveClustersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subsetRelabelingResolveClusterV1(workspaceId: string, datasetId: string, dataOperationId: string, resolveClustersRequest: ResolveClustersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResolveMislabeledRecordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subsetRelabelingResolveClusterV1(workspaceId, datasetId, dataOperationId, resolveClustersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign a new label to selected dataset subset regions
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {ResolveRegionRequest} resolveRegionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subsetRelabelingResolveRegionV1(workspaceId: string, datasetId: string, dataOperationId: string, resolveRegionRequest: ResolveRegionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResolveMislabeledRecordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subsetRelabelingResolveRegionV1(workspaceId, datasetId, dataOperationId, resolveRegionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign a new label to selected dataset rows
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {ResolveMislabeledRecordsRequest} resolveMislabeledRecordsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subsetRelabelingResolveV1(workspaceId: string, datasetId: string, dataOperationId: string, resolveMislabeledRecordsRequest: ResolveMislabeledRecordsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResolveMislabeledRecordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subsetRelabelingResolveV1(workspaceId, datasetId, dataOperationId, resolveMislabeledRecordsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubsetRelabelingRoutesApi - factory interface
 * @export
 */
export const SubsetRelabelingRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubsetRelabelingRoutesApiFp(configuration)
    return {
        /**
         * Lists rows to relabel for a dataset subset.
         * @summary Lists records to be relabeled for for a subset (of a dataset).If available, a recommended label would be shared by the system.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {Array<string>} [targetClasses] 
         * @param {Array<SegmentType>} [segments] 
         * @param {boolean} [isResolved] 
         * @param {boolean} [isAssigned] 
         * @param {Array<string>} [assignedTo] 
         * @param {Array<string>} [resolvedBy] 
         * @param {SortOrder} [sortOrder] 
         * @param {boolean} [onlyMislabeled] 
         * @param {RelabelingSortField} [sortBy] 
         * @param {Array<number>} [regions] 
         * @param {Array<number>} [clusters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubsetRelabelingRecordsV1(workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, targetClasses?: Array<string>, segments?: Array<SegmentType>, isResolved?: boolean, isAssigned?: boolean, assignedTo?: Array<string>, resolvedBy?: Array<string>, sortOrder?: SortOrder, onlyMislabeled?: boolean, sortBy?: RelabelingSortField, regions?: Array<number>, clusters?: Array<number>, options?: any): AxiosPromise<ListMislabeledRecordsResponse> {
            return localVarFp.getSubsetRelabelingRecordsV1(workspaceId, datasetId, dataOperationId, start, end, targetClasses, segments, isResolved, isAssigned, assignedTo, resolvedBy, sortOrder, onlyMislabeled, sortBy, regions, clusters, options).then((request) => request(axios, basePath));
        },
        /**
         * Get summary of relabeling workflow for a dataset.
         * @summary Get summary of relabeling workflow for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubsetRelabelingSummaryV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: any): AxiosPromise<GetRelabelingSummaryResponse> {
            return localVarFp.getSubsetRelabelingSummaryV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accept recommendations i.e set resolved_label to predicted_label.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingAcceptRecommendationsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: any): AxiosPromise<AcceptAllRecommendationsResponse> {
            return localVarFp.subsetRelabelingAcceptRecommendationsV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds to the list of possible labels that can be applied
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {AddRelabelingLabelsRequest} addRelabelingLabelsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingAddLabelsV1(workspaceId: string, datasetId: string, dataOperationId: string, addRelabelingLabelsRequest: AddRelabelingLabelsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.subsetRelabelingAddLabelsV1(workspaceId, datasetId, dataOperationId, addRelabelingLabelsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of applied labels
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingAppliedLabelsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: any): AxiosPromise<GetRelabelingAppliedLabelsResponse> {
            return localVarFp.subsetRelabelingAppliedLabelsV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign mislabeled data records to relabeling  with assignee
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {AssignUserToRelabelingRequest} assignUserToRelabelingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingAssignUserV1(workspaceId: string, datasetId: string, dataOperationId: string, assignUserToRelabelingRequest: AssignUserToRelabelingRequest, options?: any): AxiosPromise<AssignUserToRelabelingResponse> {
            return localVarFp.subsetRelabelingAssignUserV1(workspaceId, datasetId, dataOperationId, assignUserToRelabelingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of clusters, and regions in the data to be relabeled
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingClustersRegionsLabelsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: any): AxiosPromise<GetRelabelingRegionsClustersResponse> {
            return localVarFp.subsetRelabelingClustersRegionsLabelsV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new version of dataset utilising semi-supervised learning 
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingCreateDatasetWithSupervisionV1(workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options?: any): AxiosPromise<CreateNewVersionDatasetResponse> {
            return localVarFp.subsetRelabelingCreateDatasetWithSupervisionV1(workspaceId, datasetId, dataOperationId, createNewVersionDatasetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new version of dataset with resolved recommendations
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingCreateNewVersionDatasetV1(workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options?: any): AxiosPromise<CreateNewVersionDatasetResponse> {
            return localVarFp.subsetRelabelingCreateNewVersionDatasetV1(workspaceId, datasetId, dataOperationId, createNewVersionDatasetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of dataset relabeling contributors
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingGetContributorsV1(workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, options?: any): AxiosPromise<GetRelabelingContributorsResponse> {
            return localVarFp.subsetRelabelingGetContributorsV1(workspaceId, datasetId, dataOperationId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign a new label to selected dataset clusters
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {ResolveClustersRequest} resolveClustersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingResolveClusterV1(workspaceId: string, datasetId: string, dataOperationId: string, resolveClustersRequest: ResolveClustersRequest, options?: any): AxiosPromise<ResolveMislabeledRecordsResponse> {
            return localVarFp.subsetRelabelingResolveClusterV1(workspaceId, datasetId, dataOperationId, resolveClustersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign a new label to selected dataset subset regions
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {ResolveRegionRequest} resolveRegionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingResolveRegionV1(workspaceId: string, datasetId: string, dataOperationId: string, resolveRegionRequest: ResolveRegionRequest, options?: any): AxiosPromise<ResolveMislabeledRecordsResponse> {
            return localVarFp.subsetRelabelingResolveRegionV1(workspaceId, datasetId, dataOperationId, resolveRegionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign a new label to selected dataset rows
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} dataOperationId 
         * @param {ResolveMislabeledRecordsRequest} resolveMislabeledRecordsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subsetRelabelingResolveV1(workspaceId: string, datasetId: string, dataOperationId: string, resolveMislabeledRecordsRequest: ResolveMislabeledRecordsRequest, options?: any): AxiosPromise<ResolveMislabeledRecordsResponse> {
            return localVarFp.subsetRelabelingResolveV1(workspaceId, datasetId, dataOperationId, resolveMislabeledRecordsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubsetRelabelingRoutesApi - object-oriented interface
 * @export
 * @class SubsetRelabelingRoutesApi
 * @extends {BaseAPI}
 */
export class SubsetRelabelingRoutesApi extends BaseAPI {
    /**
     * Lists rows to relabel for a dataset subset.
     * @summary Lists records to be relabeled for for a subset (of a dataset).If available, a recommended label would be shared by the system.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {Array<string>} [targetClasses] 
     * @param {Array<SegmentType>} [segments] 
     * @param {boolean} [isResolved] 
     * @param {boolean} [isAssigned] 
     * @param {Array<string>} [assignedTo] 
     * @param {Array<string>} [resolvedBy] 
     * @param {SortOrder} [sortOrder] 
     * @param {boolean} [onlyMislabeled] 
     * @param {RelabelingSortField} [sortBy] 
     * @param {Array<number>} [regions] 
     * @param {Array<number>} [clusters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetRelabelingRoutesApi
     */
    public getSubsetRelabelingRecordsV1(workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, targetClasses?: Array<string>, segments?: Array<SegmentType>, isResolved?: boolean, isAssigned?: boolean, assignedTo?: Array<string>, resolvedBy?: Array<string>, sortOrder?: SortOrder, onlyMislabeled?: boolean, sortBy?: RelabelingSortField, regions?: Array<number>, clusters?: Array<number>, options?: AxiosRequestConfig) {
        return SubsetRelabelingRoutesApiFp(this.configuration).getSubsetRelabelingRecordsV1(workspaceId, datasetId, dataOperationId, start, end, targetClasses, segments, isResolved, isAssigned, assignedTo, resolvedBy, sortOrder, onlyMislabeled, sortBy, regions, clusters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get summary of relabeling workflow for a dataset.
     * @summary Get summary of relabeling workflow for a dataset.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetRelabelingRoutesApi
     */
    public getSubsetRelabelingSummaryV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig) {
        return SubsetRelabelingRoutesApiFp(this.configuration).getSubsetRelabelingSummaryV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accept recommendations i.e set resolved_label to predicted_label.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetRelabelingRoutesApi
     */
    public subsetRelabelingAcceptRecommendationsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig) {
        return SubsetRelabelingRoutesApiFp(this.configuration).subsetRelabelingAcceptRecommendationsV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds to the list of possible labels that can be applied
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {AddRelabelingLabelsRequest} addRelabelingLabelsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetRelabelingRoutesApi
     */
    public subsetRelabelingAddLabelsV1(workspaceId: string, datasetId: string, dataOperationId: string, addRelabelingLabelsRequest: AddRelabelingLabelsRequest, options?: AxiosRequestConfig) {
        return SubsetRelabelingRoutesApiFp(this.configuration).subsetRelabelingAddLabelsV1(workspaceId, datasetId, dataOperationId, addRelabelingLabelsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of applied labels
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetRelabelingRoutesApi
     */
    public subsetRelabelingAppliedLabelsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig) {
        return SubsetRelabelingRoutesApiFp(this.configuration).subsetRelabelingAppliedLabelsV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign mislabeled data records to relabeling  with assignee
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {AssignUserToRelabelingRequest} assignUserToRelabelingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetRelabelingRoutesApi
     */
    public subsetRelabelingAssignUserV1(workspaceId: string, datasetId: string, dataOperationId: string, assignUserToRelabelingRequest: AssignUserToRelabelingRequest, options?: AxiosRequestConfig) {
        return SubsetRelabelingRoutesApiFp(this.configuration).subsetRelabelingAssignUserV1(workspaceId, datasetId, dataOperationId, assignUserToRelabelingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of clusters, and regions in the data to be relabeled
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetRelabelingRoutesApi
     */
    public subsetRelabelingClustersRegionsLabelsV1(workspaceId: string, datasetId: string, dataOperationId: string, options?: AxiosRequestConfig) {
        return SubsetRelabelingRoutesApiFp(this.configuration).subsetRelabelingClustersRegionsLabelsV1(workspaceId, datasetId, dataOperationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new version of dataset utilising semi-supervised learning 
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetRelabelingRoutesApi
     */
    public subsetRelabelingCreateDatasetWithSupervisionV1(workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options?: AxiosRequestConfig) {
        return SubsetRelabelingRoutesApiFp(this.configuration).subsetRelabelingCreateDatasetWithSupervisionV1(workspaceId, datasetId, dataOperationId, createNewVersionDatasetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new version of dataset with resolved recommendations
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {CreateNewVersionDatasetRequest} createNewVersionDatasetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetRelabelingRoutesApi
     */
    public subsetRelabelingCreateNewVersionDatasetV1(workspaceId: string, datasetId: string, dataOperationId: string, createNewVersionDatasetRequest: CreateNewVersionDatasetRequest, options?: AxiosRequestConfig) {
        return SubsetRelabelingRoutesApiFp(this.configuration).subsetRelabelingCreateNewVersionDatasetV1(workspaceId, datasetId, dataOperationId, createNewVersionDatasetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of dataset relabeling contributors
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetRelabelingRoutesApi
     */
    public subsetRelabelingGetContributorsV1(workspaceId: string, datasetId: string, dataOperationId: string, start?: number, end?: number, options?: AxiosRequestConfig) {
        return SubsetRelabelingRoutesApiFp(this.configuration).subsetRelabelingGetContributorsV1(workspaceId, datasetId, dataOperationId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign a new label to selected dataset clusters
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {ResolveClustersRequest} resolveClustersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetRelabelingRoutesApi
     */
    public subsetRelabelingResolveClusterV1(workspaceId: string, datasetId: string, dataOperationId: string, resolveClustersRequest: ResolveClustersRequest, options?: AxiosRequestConfig) {
        return SubsetRelabelingRoutesApiFp(this.configuration).subsetRelabelingResolveClusterV1(workspaceId, datasetId, dataOperationId, resolveClustersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign a new label to selected dataset subset regions
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {ResolveRegionRequest} resolveRegionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetRelabelingRoutesApi
     */
    public subsetRelabelingResolveRegionV1(workspaceId: string, datasetId: string, dataOperationId: string, resolveRegionRequest: ResolveRegionRequest, options?: AxiosRequestConfig) {
        return SubsetRelabelingRoutesApiFp(this.configuration).subsetRelabelingResolveRegionV1(workspaceId, datasetId, dataOperationId, resolveRegionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign a new label to selected dataset rows
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} dataOperationId 
     * @param {ResolveMislabeledRecordsRequest} resolveMislabeledRecordsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubsetRelabelingRoutesApi
     */
    public subsetRelabelingResolveV1(workspaceId: string, datasetId: string, dataOperationId: string, resolveMislabeledRecordsRequest: ResolveMislabeledRecordsRequest, options?: AxiosRequestConfig) {
        return SubsetRelabelingRoutesApiFp(this.configuration).subsetRelabelingResolveV1(workspaceId, datasetId, dataOperationId, resolveMislabeledRecordsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaggedResourcesApi - axios parameter creator
 * @export
 */
export const TaggedResourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Endpoint to fetch details about all the evaluations in a workspace for the given model class
         * @param {string} workspaceId ID of the workspace.
         * @param {string} modelClass class of the models.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEvaluationsListForModelClassWithTagsV1: async (workspaceId: string, modelClass: string, start?: number, end?: number, ts?: string, name?: string, datasetId?: string, modelId?: string, projectId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAllEvaluationsListForModelClassWithTagsV1', 'workspaceId', workspaceId)
            // verify required parameter 'modelClass' is not null or undefined
            assertParamExists('getAllEvaluationsListForModelClassWithTagsV1', 'modelClass', modelClass)
            const localVarPath = `/v1/workspaces/{workspaceId}/recordings/model-class/{modelClass}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"modelClass"}}`, encodeURIComponent(String(modelClass)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (datasetId !== undefined) {
                localVarQueryParameter['datasetId'] = datasetId;
            }

            if (modelId !== undefined) {
                localVarQueryParameter['modelId'] = modelId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (excludedUserIds) {
                localVarQueryParameter['excludedUserIds'] = excludedUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['recordIds'] = recordIds;
            }

            if (tagIds) {
                localVarQueryParameter['tagIds'] = tagIds;
            }

            if (includeDemoArtifacts !== undefined) {
                localVarQueryParameter['includeDemoArtifacts'] = includeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifactFilter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to fetch details about all the evaluations in a workspace.
         * @param {string} workspaceId ID of the workspace.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {boolean} [withMetrics] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEvaluationsListWithTagsV1: async (workspaceId: string, start?: number, end?: number, withMetrics?: boolean, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAllEvaluationsListWithTagsV1', 'workspaceId', workspaceId)
            const localVarPath = `/v1/workspaces/{workspaceId}/recordings`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (withMetrics !== undefined) {
                localVarQueryParameter['withMetrics'] = withMetrics;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (excludedUserIds) {
                localVarQueryParameter['excludedUserIds'] = excludedUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['recordIds'] = recordIds;
            }

            if (tagIds) {
                localVarQueryParameter['tagIds'] = tagIds;
            }

            if (includeDemoArtifacts !== undefined) {
                localVarQueryParameter['includeDemoArtifacts'] = includeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifactFilter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to fetch details about all the experiments in a workspace.
         * @param {string} workspaceId ID of the workspace.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllExperimentListWithTagsV1: async (workspaceId: string, start?: number, end?: number, ts?: string, name?: string, datasetId?: string, modelId?: string, projectId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAllExperimentListWithTagsV1', 'workspaceId', workspaceId)
            const localVarPath = `/v1/workspaces/{workspaceId}/experiments`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (datasetId !== undefined) {
                localVarQueryParameter['datasetId'] = datasetId;
            }

            if (modelId !== undefined) {
                localVarQueryParameter['modelId'] = modelId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (excludedUserIds) {
                localVarQueryParameter['excludedUserIds'] = excludedUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['recordIds'] = recordIds;
            }

            if (tagIds) {
                localVarQueryParameter['tagIds'] = tagIds;
            }

            if (includeDemoArtifacts !== undefined) {
                localVarQueryParameter['includeDemoArtifacts'] = includeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifactFilter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to fetch details about all the models in a workspace.
         * @param {string} workspaceId ID of the workspace.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllModelsListWithTagsV1: async (workspaceId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAllModelsListWithTagsV1', 'workspaceId', workspaceId)
            const localVarPath = `/v1/workspaces/{workspaceId}/models`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (excludedUserIds) {
                localVarQueryParameter['excludedUserIds'] = excludedUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['recordIds'] = recordIds;
            }

            if (tagIds) {
                localVarQueryParameter['tagIds'] = tagIds;
            }

            if (includeDemoArtifacts !== undefined) {
                localVarQueryParameter['includeDemoArtifacts'] = includeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifactFilter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to fetch details about a single dataset including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} datasetId ID of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetInfoWithTagsV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetInfoWithTagsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDatasetInfoWithTagsV1', 'datasetId', datasetId)
            const localVarPath = `/v1/workspaces/{workspaceId}/datasets/{datasetId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"datasetId"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to fetch a page of datasets including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [dataFamilyId] 
         * @param {string} [dataFamilyName] 
         * @param {string} [dataType] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetsListWithTagsV1: async (workspaceId: string, start?: number, end?: number, ts?: string, dataFamilyId?: string, dataFamilyName?: string, dataType?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetsListWithTagsV1', 'workspaceId', workspaceId)
            const localVarPath = `/v1/workspaces/{workspaceId}/datasets`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (dataFamilyId !== undefined) {
                localVarQueryParameter['dataFamilyId'] = dataFamilyId;
            }

            if (dataFamilyName !== undefined) {
                localVarQueryParameter['dataFamilyName'] = dataFamilyName;
            }

            if (dataType !== undefined) {
                localVarQueryParameter['dataType'] = dataType;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (excludedUserIds) {
                localVarQueryParameter['excludedUserIds'] = excludedUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['recordIds'] = recordIds;
            }

            if (tagIds) {
                localVarQueryParameter['tagIds'] = tagIds;
            }

            if (includeDemoArtifacts !== undefined) {
                localVarQueryParameter['includeDemoArtifacts'] = includeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifactFilter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to fetch details about a single evalaution including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} recordingId ID of the evaluation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationMetadataWithTagsV1: async (workspaceId: string, recordingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getEvaluationMetadataWithTagsV1', 'workspaceId', workspaceId)
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('getEvaluationMetadataWithTagsV1', 'recordingId', recordingId)
            const localVarPath = `/v1/workspaces/{workspaceId}/recordings/{recordingId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"recordingId"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to fetch details about a single experiment including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {string} experimentId ID of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentInfoWithTagsV1: async (workspaceId: string, projectId: string, experimentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getExperimentInfoWithTagsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getExperimentInfoWithTagsV1', 'projectId', projectId)
            // verify required parameter 'experimentId' is not null or undefined
            assertParamExists('getExperimentInfoWithTagsV1', 'experimentId', experimentId)
            const localVarPath = `/v1/workspaces/{workspaceId}/projects/{projectId}/experiments/{experimentId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"experimentId"}}`, encodeURIComponent(String(experimentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to fetch details about a single model including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {string} modelId ID of the model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInfoWithTagsV1: async (workspaceId: string, projectId: string, modelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getModelInfoWithTagsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getModelInfoWithTagsV1', 'projectId', projectId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('getModelInfoWithTagsV1', 'modelId', modelId)
            const localVarPath = `/v1/workspaces/{workspaceId}/projects/{projectId}/models/{modelId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to fetch details about all the evaluations in a project.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {string} [modelId] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectEvaluationsListWithTagsV1: async (workspaceId: string, projectId: string, start?: number, end?: number, ts?: string, name?: string, modelId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProjectEvaluationsListWithTagsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectEvaluationsListWithTagsV1', 'projectId', projectId)
            const localVarPath = `/v1/workspaces/{workspaceId}/projects/{projectId}/evaluations`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (modelId !== undefined) {
                localVarQueryParameter['modelId'] = modelId;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (excludedUserIds) {
                localVarQueryParameter['excludedUserIds'] = excludedUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['recordIds'] = recordIds;
            }

            if (tagIds) {
                localVarQueryParameter['tagIds'] = tagIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to fetch details about all the experiments in a project.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {string} [modelId] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectExperimentsListWithTagsV1: async (workspaceId: string, projectId: string, start?: number, end?: number, ts?: string, name?: string, modelId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProjectExperimentsListWithTagsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectExperimentsListWithTagsV1', 'projectId', projectId)
            const localVarPath = `/v1/workspaces/{workspaceId}/projects/{projectId}/experiments`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (modelId !== undefined) {
                localVarQueryParameter['modelId'] = modelId;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (excludedUserIds) {
                localVarQueryParameter['excludedUserIds'] = excludedUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['recordIds'] = recordIds;
            }

            if (tagIds) {
                localVarQueryParameter['tagIds'] = tagIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to fetch details about a single project including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectInfoWithTagsV1: async (workspaceId: string, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProjectInfoWithTagsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectInfoWithTagsV1', 'projectId', projectId)
            const localVarPath = `/v1/workspaces/{workspaceId}/projects/{projectId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to fetch details about all the models in a project.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectModelsListWithTagsV1: async (workspaceId: string, projectId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProjectModelsListWithTagsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectModelsListWithTagsV1', 'projectId', projectId)
            const localVarPath = `/v1/workspaces/{workspaceId}/projects/{projectId}/models`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (excludedUserIds) {
                localVarQueryParameter['excludedUserIds'] = excludedUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['recordIds'] = recordIds;
            }

            if (tagIds) {
                localVarQueryParameter['tagIds'] = tagIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to fetch details about all the projects in a workspace.
         * @param {string} workspaceId ID of the workspace.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsListWithTagsV1: async (workspaceId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProjectsListWithTagsV1', 'workspaceId', workspaceId)
            const localVarPath = `/v1/workspaces/{workspaceId}/projects`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (excludedUserIds) {
                localVarQueryParameter['excludedUserIds'] = excludedUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['recordIds'] = recordIds;
            }

            if (tagIds) {
                localVarQueryParameter['tagIds'] = tagIds;
            }

            if (includeDemoArtifacts !== undefined) {
                localVarQueryParameter['includeDemoArtifacts'] = includeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifactFilter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaggedResourcesApi - functional programming interface
 * @export
 */
export const TaggedResourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaggedResourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * Endpoint to fetch details about all the evaluations in a workspace for the given model class
         * @param {string} workspaceId ID of the workspace.
         * @param {string} modelClass class of the models.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEvaluationsListForModelClassWithTagsV1(workspaceId: string, modelClass: string, start?: number, end?: number, ts?: string, name?: string, datasetId?: string, modelId?: string, projectId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllEvaluationsListWithTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEvaluationsListForModelClassWithTagsV1(workspaceId, modelClass, start, end, ts, name, datasetId, modelId, projectId, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to fetch details about all the evaluations in a workspace.
         * @param {string} workspaceId ID of the workspace.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {boolean} [withMetrics] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEvaluationsListWithTagsV1(workspaceId: string, start?: number, end?: number, withMetrics?: boolean, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllEvaluationsListWithTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEvaluationsListWithTagsV1(workspaceId, start, end, withMetrics, ts, name, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to fetch details about all the experiments in a workspace.
         * @param {string} workspaceId ID of the workspace.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllExperimentListWithTagsV1(workspaceId: string, start?: number, end?: number, ts?: string, name?: string, datasetId?: string, modelId?: string, projectId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListExperimentsWithTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllExperimentListWithTagsV1(workspaceId, start, end, ts, name, datasetId, modelId, projectId, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to fetch details about all the models in a workspace.
         * @param {string} workspaceId ID of the workspace.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllModelsListWithTagsV1(workspaceId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListModelsWithTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllModelsListWithTagsV1(workspaceId, start, end, ts, name, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to fetch details about a single dataset including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} datasetId ID of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetInfoWithTagsV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDatasetDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetInfoWithTagsV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to fetch a page of datasets including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [dataFamilyId] 
         * @param {string} [dataFamilyName] 
         * @param {string} [dataType] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetsListWithTagsV1(workspaceId: string, start?: number, end?: number, ts?: string, dataFamilyId?: string, dataFamilyName?: string, dataType?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDatasetsWithTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetsListWithTagsV1(workspaceId, start, end, ts, dataFamilyId, dataFamilyName, dataType, name, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to fetch details about a single evalaution including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} recordingId ID of the evaluation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvaluationMetadataWithTagsV1(workspaceId: string, recordingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEvaluationMetadataWithTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvaluationMetadataWithTagsV1(workspaceId, recordingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to fetch details about a single experiment including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {string} experimentId ID of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentInfoWithTagsV1(workspaceId: string, projectId: string, experimentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExperimentDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentInfoWithTagsV1(workspaceId, projectId, experimentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to fetch details about a single model including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {string} modelId ID of the model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelInfoWithTagsV1(workspaceId: string, projectId: string, modelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelInfoWithTagsV1(workspaceId, projectId, modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to fetch details about all the evaluations in a project.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {string} [modelId] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectEvaluationsListWithTagsV1(workspaceId: string, projectId: string, start?: number, end?: number, ts?: string, name?: string, modelId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectEvaluationsListWithTagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectEvaluationsListWithTagsV1(workspaceId, projectId, start, end, ts, name, modelId, userIds, excludedUserIds, recordIds, tagIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to fetch details about all the experiments in a project.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {string} [modelId] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectExperimentsListWithTagsV1(workspaceId: string, projectId: string, start?: number, end?: number, ts?: string, name?: string, modelId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListExperimentsWithTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectExperimentsListWithTagsV1(workspaceId, projectId, start, end, ts, name, modelId, userIds, excludedUserIds, recordIds, tagIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to fetch details about a single project including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectInfoWithTagsV1(workspaceId: string, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectInfoWithTagsV1(workspaceId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to fetch details about all the models in a project.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectModelsListWithTagsV1(workspaceId: string, projectId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListModelsWithTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectModelsListWithTagsV1(workspaceId, projectId, start, end, ts, name, userIds, excludedUserIds, recordIds, tagIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to fetch details about all the projects in a workspace.
         * @param {string} workspaceId ID of the workspace.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsListWithTagsV1(workspaceId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListProjectsWithTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsListWithTagsV1(workspaceId, start, end, ts, name, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaggedResourcesApi - factory interface
 * @export
 */
export const TaggedResourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaggedResourcesApiFp(configuration)
    return {
        /**
         * Endpoint to fetch details about all the evaluations in a workspace for the given model class
         * @param {string} workspaceId ID of the workspace.
         * @param {string} modelClass class of the models.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEvaluationsListForModelClassWithTagsV1(workspaceId: string, modelClass: string, start?: number, end?: number, ts?: string, name?: string, datasetId?: string, modelId?: string, projectId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: any): AxiosPromise<GetAllEvaluationsListWithTagsResponse> {
            return localVarFp.getAllEvaluationsListForModelClassWithTagsV1(workspaceId, modelClass, start, end, ts, name, datasetId, modelId, projectId, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to fetch details about all the evaluations in a workspace.
         * @param {string} workspaceId ID of the workspace.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {boolean} [withMetrics] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEvaluationsListWithTagsV1(workspaceId: string, start?: number, end?: number, withMetrics?: boolean, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: any): AxiosPromise<GetAllEvaluationsListWithTagsResponse> {
            return localVarFp.getAllEvaluationsListWithTagsV1(workspaceId, start, end, withMetrics, ts, name, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to fetch details about all the experiments in a workspace.
         * @param {string} workspaceId ID of the workspace.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllExperimentListWithTagsV1(workspaceId: string, start?: number, end?: number, ts?: string, name?: string, datasetId?: string, modelId?: string, projectId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: any): AxiosPromise<ListExperimentsWithTagsResponse> {
            return localVarFp.getAllExperimentListWithTagsV1(workspaceId, start, end, ts, name, datasetId, modelId, projectId, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to fetch details about all the models in a workspace.
         * @param {string} workspaceId ID of the workspace.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllModelsListWithTagsV1(workspaceId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: any): AxiosPromise<ListModelsWithTagsResponse> {
            return localVarFp.getAllModelsListWithTagsV1(workspaceId, start, end, ts, name, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to fetch details about a single dataset including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} datasetId ID of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetInfoWithTagsV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<GetDatasetDetailsResponse> {
            return localVarFp.getDatasetInfoWithTagsV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to fetch a page of datasets including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [dataFamilyId] 
         * @param {string} [dataFamilyName] 
         * @param {string} [dataType] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetsListWithTagsV1(workspaceId: string, start?: number, end?: number, ts?: string, dataFamilyId?: string, dataFamilyName?: string, dataType?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: any): AxiosPromise<ListDatasetsWithTagsResponse> {
            return localVarFp.getDatasetsListWithTagsV1(workspaceId, start, end, ts, dataFamilyId, dataFamilyName, dataType, name, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to fetch details about a single evalaution including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} recordingId ID of the evaluation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationMetadataWithTagsV1(workspaceId: string, recordingId: string, options?: any): AxiosPromise<GetEvaluationMetadataWithTagsResponse> {
            return localVarFp.getEvaluationMetadataWithTagsV1(workspaceId, recordingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to fetch details about a single experiment including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {string} experimentId ID of the experiment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentInfoWithTagsV1(workspaceId: string, projectId: string, experimentId: string, options?: any): AxiosPromise<GetExperimentDetailsResponse> {
            return localVarFp.getExperimentInfoWithTagsV1(workspaceId, projectId, experimentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to fetch details about a single model including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {string} modelId ID of the model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInfoWithTagsV1(workspaceId: string, projectId: string, modelId: string, options?: any): AxiosPromise<GetModelDetailsResponse> {
            return localVarFp.getModelInfoWithTagsV1(workspaceId, projectId, modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to fetch details about all the evaluations in a project.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {string} [modelId] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectEvaluationsListWithTagsV1(workspaceId: string, projectId: string, start?: number, end?: number, ts?: string, name?: string, modelId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, options?: any): AxiosPromise<GetProjectEvaluationsListWithTagResponse> {
            return localVarFp.getProjectEvaluationsListWithTagsV1(workspaceId, projectId, start, end, ts, name, modelId, userIds, excludedUserIds, recordIds, tagIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to fetch details about all the experiments in a project.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {string} [modelId] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectExperimentsListWithTagsV1(workspaceId: string, projectId: string, start?: number, end?: number, ts?: string, name?: string, modelId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, options?: any): AxiosPromise<ListExperimentsWithTagsResponse> {
            return localVarFp.getProjectExperimentsListWithTagsV1(workspaceId, projectId, start, end, ts, name, modelId, userIds, excludedUserIds, recordIds, tagIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to fetch details about a single project including any associated tags.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectInfoWithTagsV1(workspaceId: string, projectId: string, options?: any): AxiosPromise<GetProjectDetailsResponse> {
            return localVarFp.getProjectInfoWithTagsV1(workspaceId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to fetch details about all the models in a project.
         * @param {string} workspaceId ID of the workspace.
         * @param {string} projectId ID of the project.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectModelsListWithTagsV1(workspaceId: string, projectId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, options?: any): AxiosPromise<ListModelsWithTagsResponse> {
            return localVarFp.getProjectModelsListWithTagsV1(workspaceId, projectId, start, end, ts, name, userIds, excludedUserIds, recordIds, tagIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to fetch details about all the projects in a workspace.
         * @param {string} workspaceId ID of the workspace.
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludedUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [tagIds] 
         * @param {boolean} [includeDemoArtifacts] 
         * @param {ArtifactFilterState} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsListWithTagsV1(workspaceId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: any): AxiosPromise<ListProjectsWithTagsResponse> {
            return localVarFp.getProjectsListWithTagsV1(workspaceId, start, end, ts, name, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaggedResourcesApi - object-oriented interface
 * @export
 * @class TaggedResourcesApi
 * @extends {BaseAPI}
 */
export class TaggedResourcesApi extends BaseAPI {
    /**
     * Endpoint to fetch details about all the evaluations in a workspace for the given model class
     * @param {string} workspaceId ID of the workspace.
     * @param {string} modelClass class of the models.
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {string} [datasetId] 
     * @param {string} [modelId] 
     * @param {string} [projectId] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludedUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [tagIds] 
     * @param {boolean} [includeDemoArtifacts] 
     * @param {ArtifactFilterState} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggedResourcesApi
     */
    public getAllEvaluationsListForModelClassWithTagsV1(workspaceId: string, modelClass: string, start?: number, end?: number, ts?: string, name?: string, datasetId?: string, modelId?: string, projectId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: AxiosRequestConfig) {
        return TaggedResourcesApiFp(this.configuration).getAllEvaluationsListForModelClassWithTagsV1(workspaceId, modelClass, start, end, ts, name, datasetId, modelId, projectId, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to fetch details about all the evaluations in a workspace.
     * @param {string} workspaceId ID of the workspace.
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {boolean} [withMetrics] 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludedUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [tagIds] 
     * @param {boolean} [includeDemoArtifacts] 
     * @param {ArtifactFilterState} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggedResourcesApi
     */
    public getAllEvaluationsListWithTagsV1(workspaceId: string, start?: number, end?: number, withMetrics?: boolean, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: AxiosRequestConfig) {
        return TaggedResourcesApiFp(this.configuration).getAllEvaluationsListWithTagsV1(workspaceId, start, end, withMetrics, ts, name, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to fetch details about all the experiments in a workspace.
     * @param {string} workspaceId ID of the workspace.
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {string} [datasetId] 
     * @param {string} [modelId] 
     * @param {string} [projectId] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludedUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [tagIds] 
     * @param {boolean} [includeDemoArtifacts] 
     * @param {ArtifactFilterState} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggedResourcesApi
     */
    public getAllExperimentListWithTagsV1(workspaceId: string, start?: number, end?: number, ts?: string, name?: string, datasetId?: string, modelId?: string, projectId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: AxiosRequestConfig) {
        return TaggedResourcesApiFp(this.configuration).getAllExperimentListWithTagsV1(workspaceId, start, end, ts, name, datasetId, modelId, projectId, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to fetch details about all the models in a workspace.
     * @param {string} workspaceId ID of the workspace.
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludedUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [tagIds] 
     * @param {boolean} [includeDemoArtifacts] 
     * @param {ArtifactFilterState} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggedResourcesApi
     */
    public getAllModelsListWithTagsV1(workspaceId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: AxiosRequestConfig) {
        return TaggedResourcesApiFp(this.configuration).getAllModelsListWithTagsV1(workspaceId, start, end, ts, name, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to fetch details about a single dataset including any associated tags.
     * @param {string} workspaceId ID of the workspace.
     * @param {string} datasetId ID of the dataset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggedResourcesApi
     */
    public getDatasetInfoWithTagsV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return TaggedResourcesApiFp(this.configuration).getDatasetInfoWithTagsV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to fetch a page of datasets including any associated tags.
     * @param {string} workspaceId ID of the workspace.
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [dataFamilyId] 
     * @param {string} [dataFamilyName] 
     * @param {string} [dataType] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludedUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [tagIds] 
     * @param {boolean} [includeDemoArtifacts] 
     * @param {ArtifactFilterState} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggedResourcesApi
     */
    public getDatasetsListWithTagsV1(workspaceId: string, start?: number, end?: number, ts?: string, dataFamilyId?: string, dataFamilyName?: string, dataType?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: AxiosRequestConfig) {
        return TaggedResourcesApiFp(this.configuration).getDatasetsListWithTagsV1(workspaceId, start, end, ts, dataFamilyId, dataFamilyName, dataType, name, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to fetch details about a single evalaution including any associated tags.
     * @param {string} workspaceId ID of the workspace.
     * @param {string} recordingId ID of the evaluation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggedResourcesApi
     */
    public getEvaluationMetadataWithTagsV1(workspaceId: string, recordingId: string, options?: AxiosRequestConfig) {
        return TaggedResourcesApiFp(this.configuration).getEvaluationMetadataWithTagsV1(workspaceId, recordingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to fetch details about a single experiment including any associated tags.
     * @param {string} workspaceId ID of the workspace.
     * @param {string} projectId ID of the project.
     * @param {string} experimentId ID of the experiment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggedResourcesApi
     */
    public getExperimentInfoWithTagsV1(workspaceId: string, projectId: string, experimentId: string, options?: AxiosRequestConfig) {
        return TaggedResourcesApiFp(this.configuration).getExperimentInfoWithTagsV1(workspaceId, projectId, experimentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to fetch details about a single model including any associated tags.
     * @param {string} workspaceId ID of the workspace.
     * @param {string} projectId ID of the project.
     * @param {string} modelId ID of the model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggedResourcesApi
     */
    public getModelInfoWithTagsV1(workspaceId: string, projectId: string, modelId: string, options?: AxiosRequestConfig) {
        return TaggedResourcesApiFp(this.configuration).getModelInfoWithTagsV1(workspaceId, projectId, modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to fetch details about all the evaluations in a project.
     * @param {string} workspaceId ID of the workspace.
     * @param {string} projectId ID of the project.
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {string} [modelId] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludedUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [tagIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggedResourcesApi
     */
    public getProjectEvaluationsListWithTagsV1(workspaceId: string, projectId: string, start?: number, end?: number, ts?: string, name?: string, modelId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, options?: AxiosRequestConfig) {
        return TaggedResourcesApiFp(this.configuration).getProjectEvaluationsListWithTagsV1(workspaceId, projectId, start, end, ts, name, modelId, userIds, excludedUserIds, recordIds, tagIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to fetch details about all the experiments in a project.
     * @param {string} workspaceId ID of the workspace.
     * @param {string} projectId ID of the project.
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {string} [modelId] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludedUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [tagIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggedResourcesApi
     */
    public getProjectExperimentsListWithTagsV1(workspaceId: string, projectId: string, start?: number, end?: number, ts?: string, name?: string, modelId?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, options?: AxiosRequestConfig) {
        return TaggedResourcesApiFp(this.configuration).getProjectExperimentsListWithTagsV1(workspaceId, projectId, start, end, ts, name, modelId, userIds, excludedUserIds, recordIds, tagIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to fetch details about a single project including any associated tags.
     * @param {string} workspaceId ID of the workspace.
     * @param {string} projectId ID of the project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggedResourcesApi
     */
    public getProjectInfoWithTagsV1(workspaceId: string, projectId: string, options?: AxiosRequestConfig) {
        return TaggedResourcesApiFp(this.configuration).getProjectInfoWithTagsV1(workspaceId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to fetch details about all the models in a project.
     * @param {string} workspaceId ID of the workspace.
     * @param {string} projectId ID of the project.
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludedUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [tagIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggedResourcesApi
     */
    public getProjectModelsListWithTagsV1(workspaceId: string, projectId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, options?: AxiosRequestConfig) {
        return TaggedResourcesApiFp(this.configuration).getProjectModelsListWithTagsV1(workspaceId, projectId, start, end, ts, name, userIds, excludedUserIds, recordIds, tagIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to fetch details about all the projects in a workspace.
     * @param {string} workspaceId ID of the workspace.
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludedUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [tagIds] 
     * @param {boolean} [includeDemoArtifacts] 
     * @param {ArtifactFilterState} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggedResourcesApi
     */
    public getProjectsListWithTagsV1(workspaceId: string, start?: number, end?: number, ts?: string, name?: string, userIds?: Array<string>, excludedUserIds?: Array<string>, recordIds?: Array<string>, tagIds?: Array<string>, includeDemoArtifacts?: boolean, artifactFilter?: ArtifactFilterState, options?: AxiosRequestConfig) {
        return TaggedResourcesApiFp(this.configuration).getProjectsListWithTagsV1(workspaceId, start, end, ts, name, userIds, excludedUserIds, recordIds, tagIds, includeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaggingManagementApi - axios parameter creator
 * @export
 */
export const TaggingManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Applying tags to a resource id. This will create a mapping in the Tagged Resource table.
         * @summary Apply multiple tags to a given resource id.
         * @param {string} workspaceId 
         * @param {ApplyTagsRequest} applyTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyTagsV1: async (workspaceId: string, applyTagsRequest: ApplyTagsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('applyTagsV1', 'workspaceId', workspaceId)
            // verify required parameter 'applyTagsRequest' is not null or undefined
            assertParamExists('applyTagsV1', 'applyTagsRequest', applyTagsRequest)
            const localVarPath = `/liverpool/v1/workspace/{workspace_id}/apply-tags`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyTagsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create tag along with metadata like description, tag type, scope, color
         * @summary Create new tag
         * @param {string} workspaceId 
         * @param {CreateTagRequest} createTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagV1: async (workspaceId: string, createTagRequest: CreateTagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createTagV1', 'workspaceId', workspaceId)
            // verify required parameter 'createTagRequest' is not null or undefined
            assertParamExists('createTagV1', 'createTagRequest', createTagRequest)
            const localVarPath = `/liverpool/v1/workspace/{workspace_id}/create-tag`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all tags
         * @summary Fetch all the tags created with prefix
         * @param {string} workspaceId 
         * @param {string} [prefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTagsV1: async (workspaceId: string, prefix?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('fetchTagsV1', 'workspaceId', workspaceId)
            const localVarPath = `/liverpool/v1/workspace/{workspace_id}/fetch-tags`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (prefix !== undefined) {
                localVarQueryParameter['prefix'] = prefix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Resources filtered by Tags and Resource Type. This will be used to filter resources displayed on the UI by tags.
         * @summary Get Resources filtered by Tags and Resource Type. Returns Resources with all tag information
         * @param {string} workspaceId 
         * @param {FilterByTagsRequest} filterByTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourcesForTagsV1: async (workspaceId: string, filterByTagsRequest: FilterByTagsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getResourcesForTagsV1', 'workspaceId', workspaceId)
            // verify required parameter 'filterByTagsRequest' is not null or undefined
            assertParamExists('getResourcesForTagsV1', 'filterByTagsRequest', filterByTagsRequest)
            const localVarPath = `/liverpool/v1/workspace/{workspace_id}/get-resources-for-tags`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterByTagsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get tags attached to a given resource. This will be used to fetch the latest tags applied to a resource
         * @summary Get tags attached to given resource ids
         * @param {string} workspaceId 
         * @param {GetTagsRequest} getTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsV1: async (workspaceId: string, getTagsRequest: GetTagsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getTagsV1', 'workspaceId', workspaceId)
            // verify required parameter 'getTagsRequest' is not null or undefined
            assertParamExists('getTagsV1', 'getTagsRequest', getTagsRequest)
            const localVarPath = `/liverpool/v1/workspace/{workspace_id}/get-tags-for-resources`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getTagsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove a list of tags attached to the given resource.
         * @summary Remove given tags from given resource
         * @param {string} workspaceId 
         * @param {RemoveTagsRequest} removeTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTagsFromResourceV1: async (workspaceId: string, removeTagsRequest: RemoveTagsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('removeTagsFromResourceV1', 'workspaceId', workspaceId)
            // verify required parameter 'removeTagsRequest' is not null or undefined
            assertParamExists('removeTagsFromResourceV1', 'removeTagsRequest', removeTagsRequest)
            const localVarPath = `/liverpool/v1/workspace/{workspace_id}/remove-tags`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeTagsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update tags applied to a given resource. This api will overwrite the existing tags with thegiven tags list in the request body.
         * @summary Update tags applied to a given resource id.
         * @param {string} workspaceId 
         * @param {UpdateTagsRequest} updateTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagsV1: async (workspaceId: string, updateTagsRequest: UpdateTagsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateTagsV1', 'workspaceId', workspaceId)
            // verify required parameter 'updateTagsRequest' is not null or undefined
            assertParamExists('updateTagsV1', 'updateTagsRequest', updateTagsRequest)
            const localVarPath = `/liverpool/v1/workspace/{workspace_id}/update-tags`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTagsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaggingManagementApi - functional programming interface
 * @export
 */
export const TaggingManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaggingManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Applying tags to a resource id. This will create a mapping in the Tagged Resource table.
         * @summary Apply multiple tags to a given resource id.
         * @param {string} workspaceId 
         * @param {ApplyTagsRequest} applyTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyTagsV1(workspaceId: string, applyTagsRequest: ApplyTagsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplyTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyTagsV1(workspaceId, applyTagsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create tag along with metadata like description, tag type, scope, color
         * @summary Create new tag
         * @param {string} workspaceId 
         * @param {CreateTagRequest} createTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTagV1(workspaceId: string, createTagRequest: CreateTagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTagV1(workspaceId, createTagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all tags
         * @summary Fetch all the tags created with prefix
         * @param {string} workspaceId 
         * @param {string} [prefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchTagsV1(workspaceId: string, prefix?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchTagsV1(workspaceId, prefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Resources filtered by Tags and Resource Type. This will be used to filter resources displayed on the UI by tags.
         * @summary Get Resources filtered by Tags and Resource Type. Returns Resources with all tag information
         * @param {string} workspaceId 
         * @param {FilterByTagsRequest} filterByTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResourcesForTagsV1(workspaceId: string, filterByTagsRequest: FilterByTagsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetResourceTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResourcesForTagsV1(workspaceId, filterByTagsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get tags attached to a given resource. This will be used to fetch the latest tags applied to a resource
         * @summary Get tags attached to given resource ids
         * @param {string} workspaceId 
         * @param {GetTagsRequest} getTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagsV1(workspaceId: string, getTagsRequest: GetTagsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetResourceTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagsV1(workspaceId, getTagsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove a list of tags attached to the given resource.
         * @summary Remove given tags from given resource
         * @param {string} workspaceId 
         * @param {RemoveTagsRequest} removeTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTagsFromResourceV1(workspaceId: string, removeTagsRequest: RemoveTagsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoveTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTagsFromResourceV1(workspaceId, removeTagsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update tags applied to a given resource. This api will overwrite the existing tags with thegiven tags list in the request body.
         * @summary Update tags applied to a given resource id.
         * @param {string} workspaceId 
         * @param {UpdateTagsRequest} updateTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTagsV1(workspaceId: string, updateTagsRequest: UpdateTagsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTagsV1(workspaceId, updateTagsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaggingManagementApi - factory interface
 * @export
 */
export const TaggingManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaggingManagementApiFp(configuration)
    return {
        /**
         * Applying tags to a resource id. This will create a mapping in the Tagged Resource table.
         * @summary Apply multiple tags to a given resource id.
         * @param {string} workspaceId 
         * @param {ApplyTagsRequest} applyTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyTagsV1(workspaceId: string, applyTagsRequest: ApplyTagsRequest, options?: any): AxiosPromise<ApplyTagsResponse> {
            return localVarFp.applyTagsV1(workspaceId, applyTagsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * create tag along with metadata like description, tag type, scope, color
         * @summary Create new tag
         * @param {string} workspaceId 
         * @param {CreateTagRequest} createTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagV1(workspaceId: string, createTagRequest: CreateTagRequest, options?: any): AxiosPromise<CreateTagResponse> {
            return localVarFp.createTagV1(workspaceId, createTagRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all tags
         * @summary Fetch all the tags created with prefix
         * @param {string} workspaceId 
         * @param {string} [prefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTagsV1(workspaceId: string, prefix?: string, options?: any): AxiosPromise<GetTagsResponse> {
            return localVarFp.fetchTagsV1(workspaceId, prefix, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Resources filtered by Tags and Resource Type. This will be used to filter resources displayed on the UI by tags.
         * @summary Get Resources filtered by Tags and Resource Type. Returns Resources with all tag information
         * @param {string} workspaceId 
         * @param {FilterByTagsRequest} filterByTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourcesForTagsV1(workspaceId: string, filterByTagsRequest: FilterByTagsRequest, options?: any): AxiosPromise<GetResourceTagsResponse> {
            return localVarFp.getResourcesForTagsV1(workspaceId, filterByTagsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get tags attached to a given resource. This will be used to fetch the latest tags applied to a resource
         * @summary Get tags attached to given resource ids
         * @param {string} workspaceId 
         * @param {GetTagsRequest} getTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsV1(workspaceId: string, getTagsRequest: GetTagsRequest, options?: any): AxiosPromise<GetResourceTagsResponse> {
            return localVarFp.getTagsV1(workspaceId, getTagsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove a list of tags attached to the given resource.
         * @summary Remove given tags from given resource
         * @param {string} workspaceId 
         * @param {RemoveTagsRequest} removeTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTagsFromResourceV1(workspaceId: string, removeTagsRequest: RemoveTagsRequest, options?: any): AxiosPromise<RemoveTagsResponse> {
            return localVarFp.removeTagsFromResourceV1(workspaceId, removeTagsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update tags applied to a given resource. This api will overwrite the existing tags with thegiven tags list in the request body.
         * @summary Update tags applied to a given resource id.
         * @param {string} workspaceId 
         * @param {UpdateTagsRequest} updateTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagsV1(workspaceId: string, updateTagsRequest: UpdateTagsRequest, options?: any): AxiosPromise<UpdateTagsResponse> {
            return localVarFp.updateTagsV1(workspaceId, updateTagsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaggingManagementApi - object-oriented interface
 * @export
 * @class TaggingManagementApi
 * @extends {BaseAPI}
 */
export class TaggingManagementApi extends BaseAPI {
    /**
     * Applying tags to a resource id. This will create a mapping in the Tagged Resource table.
     * @summary Apply multiple tags to a given resource id.
     * @param {string} workspaceId 
     * @param {ApplyTagsRequest} applyTagsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggingManagementApi
     */
    public applyTagsV1(workspaceId: string, applyTagsRequest: ApplyTagsRequest, options?: AxiosRequestConfig) {
        return TaggingManagementApiFp(this.configuration).applyTagsV1(workspaceId, applyTagsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create tag along with metadata like description, tag type, scope, color
     * @summary Create new tag
     * @param {string} workspaceId 
     * @param {CreateTagRequest} createTagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggingManagementApi
     */
    public createTagV1(workspaceId: string, createTagRequest: CreateTagRequest, options?: AxiosRequestConfig) {
        return TaggingManagementApiFp(this.configuration).createTagV1(workspaceId, createTagRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all tags
     * @summary Fetch all the tags created with prefix
     * @param {string} workspaceId 
     * @param {string} [prefix] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggingManagementApi
     */
    public fetchTagsV1(workspaceId: string, prefix?: string, options?: AxiosRequestConfig) {
        return TaggingManagementApiFp(this.configuration).fetchTagsV1(workspaceId, prefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Resources filtered by Tags and Resource Type. This will be used to filter resources displayed on the UI by tags.
     * @summary Get Resources filtered by Tags and Resource Type. Returns Resources with all tag information
     * @param {string} workspaceId 
     * @param {FilterByTagsRequest} filterByTagsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggingManagementApi
     */
    public getResourcesForTagsV1(workspaceId: string, filterByTagsRequest: FilterByTagsRequest, options?: AxiosRequestConfig) {
        return TaggingManagementApiFp(this.configuration).getResourcesForTagsV1(workspaceId, filterByTagsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get tags attached to a given resource. This will be used to fetch the latest tags applied to a resource
     * @summary Get tags attached to given resource ids
     * @param {string} workspaceId 
     * @param {GetTagsRequest} getTagsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggingManagementApi
     */
    public getTagsV1(workspaceId: string, getTagsRequest: GetTagsRequest, options?: AxiosRequestConfig) {
        return TaggingManagementApiFp(this.configuration).getTagsV1(workspaceId, getTagsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove a list of tags attached to the given resource.
     * @summary Remove given tags from given resource
     * @param {string} workspaceId 
     * @param {RemoveTagsRequest} removeTagsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggingManagementApi
     */
    public removeTagsFromResourceV1(workspaceId: string, removeTagsRequest: RemoveTagsRequest, options?: AxiosRequestConfig) {
        return TaggingManagementApiFp(this.configuration).removeTagsFromResourceV1(workspaceId, removeTagsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update tags applied to a given resource. This api will overwrite the existing tags with thegiven tags list in the request body.
     * @summary Update tags applied to a given resource id.
     * @param {string} workspaceId 
     * @param {UpdateTagsRequest} updateTagsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaggingManagementApi
     */
    public updateTagsV1(workspaceId: string, updateTagsRequest: UpdateTagsRequest, options?: AxiosRequestConfig) {
        return TaggingManagementApiFp(this.configuration).updateTagsV1(workspaceId, updateTagsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestAPIRoutesApi - axios parameter creator
 * @export
 */
export const TestAPIRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * For creating demo workspace which will be used to keep demo artifacts. This workspace will havecurated list of artifacts which will be copied to user\'s personal workspace during onboarding
         * @summary Create demo workspace
         * @param {BodyCreateDemoWorkspaceV1} bodyCreateDemoWorkspaceV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoWorkspaceV1: async (bodyCreateDemoWorkspaceV1: BodyCreateDemoWorkspaceV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyCreateDemoWorkspaceV1' is not null or undefined
            assertParamExists('createDemoWorkspaceV1', 'bodyCreateDemoWorkspaceV1', bodyCreateDemoWorkspaceV1)
            const localVarPath = `/haifa/v1/demo/workspace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyCreateDemoWorkspaceV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create pioneer workspace for Pioneers-2023
         * @summary Create pioneer workspace
         * @param {BodyCreatePioneerWorkspaceV1} bodyCreatePioneerWorkspaceV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPioneerWorkspaceV1: async (bodyCreatePioneerWorkspaceV1: BodyCreatePioneerWorkspaceV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyCreatePioneerWorkspaceV1' is not null or undefined
            assertParamExists('createPioneerWorkspaceV1', 'bodyCreatePioneerWorkspaceV1', bodyCreatePioneerWorkspaceV1)
            const localVarPath = `/haifa/v1/pioneer/workspace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyCreatePioneerWorkspaceV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Testing workspace with default entities is created for testing purpose only. Only limited number of testing workspaces can be created from the quota available. This creation is done by fetching the user\'s primary account. After test is completed this workspace is deleted by another API call.
         * @summary Create testing workspace from the available quota
         * @param {BodyCreateTestingWorkspaceV2} bodyCreateTestingWorkspaceV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingWorkspaceV2: async (bodyCreateTestingWorkspaceV2: BodyCreateTestingWorkspaceV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyCreateTestingWorkspaceV2' is not null or undefined
            assertParamExists('createTestingWorkspaceV2', 'bodyCreateTestingWorkspaceV2', bodyCreateTestingWorkspaceV2)
            const localVarPath = `/haifa/v1/workspace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyCreateTestingWorkspaceV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint deletes all the test workspaces.
         * @summary Delete all test workspaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllTestWorkspacesV1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/haifa/v1/test-workspaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default workspace is deleted for testing purpose only.This deletion is done by fetching the user\'s primary account.This workspace was created for the testing purpose.
         * @summary Delete workspace
         * @param {BodyDeleteTestingWorkspaceV1} bodyDeleteTestingWorkspaceV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestingWorkspaceV1: async (bodyDeleteTestingWorkspaceV1: BodyDeleteTestingWorkspaceV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyDeleteTestingWorkspaceV1' is not null or undefined
            assertParamExists('deleteTestingWorkspaceV1', 'bodyDeleteTestingWorkspaceV1', bodyDeleteTestingWorkspaceV1)
            const localVarPath = `/haifa/v1/workspace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyDeleteTestingWorkspaceV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is for testing purposes only.User will be completely deleted from the platform, and removed from all the workspaces they are part of.This is a destructive operation, and cannot be undone.User\'s default entities (workspace, subscription, account, profile) will be deleted as well.If the user is not found, API returns a 404 status in the response.
         * @summary Delete a user from the platform.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserV1: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserV1', 'userId', userId)
            const localVarPath = `/haifa/v1/user/{user_id}/hard-delete`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestAPIRoutesApi - functional programming interface
 * @export
 */
export const TestAPIRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestAPIRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * For creating demo workspace which will be used to keep demo artifacts. This workspace will havecurated list of artifacts which will be copied to user\'s personal workspace during onboarding
         * @summary Create demo workspace
         * @param {BodyCreateDemoWorkspaceV1} bodyCreateDemoWorkspaceV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDemoWorkspaceV1(bodyCreateDemoWorkspaceV1: BodyCreateDemoWorkspaceV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceAndTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDemoWorkspaceV1(bodyCreateDemoWorkspaceV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create pioneer workspace for Pioneers-2023
         * @summary Create pioneer workspace
         * @param {BodyCreatePioneerWorkspaceV1} bodyCreatePioneerWorkspaceV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPioneerWorkspaceV1(bodyCreatePioneerWorkspaceV1: BodyCreatePioneerWorkspaceV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceAndTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPioneerWorkspaceV1(bodyCreatePioneerWorkspaceV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Testing workspace with default entities is created for testing purpose only. Only limited number of testing workspaces can be created from the quota available. This creation is done by fetching the user\'s primary account. After test is completed this workspace is deleted by another API call.
         * @summary Create testing workspace from the available quota
         * @param {BodyCreateTestingWorkspaceV2} bodyCreateTestingWorkspaceV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestingWorkspaceV2(bodyCreateTestingWorkspaceV2: BodyCreateTestingWorkspaceV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceAndTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestingWorkspaceV2(bodyCreateTestingWorkspaceV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint deletes all the test workspaces.
         * @summary Delete all test workspaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllTestWorkspacesV1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDeletionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllTestWorkspacesV1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Default workspace is deleted for testing purpose only.This deletion is done by fetching the user\'s primary account.This workspace was created for the testing purpose.
         * @summary Delete workspace
         * @param {BodyDeleteTestingWorkspaceV1} bodyDeleteTestingWorkspaceV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTestingWorkspaceV1(bodyDeleteTestingWorkspaceV1: BodyDeleteTestingWorkspaceV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDeletionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTestingWorkspaceV1(bodyDeleteTestingWorkspaceV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This is for testing purposes only.User will be completely deleted from the platform, and removed from all the workspaces they are part of.This is a destructive operation, and cannot be undone.User\'s default entities (workspace, subscription, account, profile) will be deleted as well.If the user is not found, API returns a 404 status in the response.
         * @summary Delete a user from the platform.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserV1(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDeletionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserV1(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestAPIRoutesApi - factory interface
 * @export
 */
export const TestAPIRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestAPIRoutesApiFp(configuration)
    return {
        /**
         * For creating demo workspace which will be used to keep demo artifacts. This workspace will havecurated list of artifacts which will be copied to user\'s personal workspace during onboarding
         * @summary Create demo workspace
         * @param {BodyCreateDemoWorkspaceV1} bodyCreateDemoWorkspaceV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoWorkspaceV1(bodyCreateDemoWorkspaceV1: BodyCreateDemoWorkspaceV1, options?: any): AxiosPromise<WorkspaceAndTeam> {
            return localVarFp.createDemoWorkspaceV1(bodyCreateDemoWorkspaceV1, options).then((request) => request(axios, basePath));
        },
        /**
         * Create pioneer workspace for Pioneers-2023
         * @summary Create pioneer workspace
         * @param {BodyCreatePioneerWorkspaceV1} bodyCreatePioneerWorkspaceV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPioneerWorkspaceV1(bodyCreatePioneerWorkspaceV1: BodyCreatePioneerWorkspaceV1, options?: any): AxiosPromise<WorkspaceAndTeam> {
            return localVarFp.createPioneerWorkspaceV1(bodyCreatePioneerWorkspaceV1, options).then((request) => request(axios, basePath));
        },
        /**
         * Testing workspace with default entities is created for testing purpose only. Only limited number of testing workspaces can be created from the quota available. This creation is done by fetching the user\'s primary account. After test is completed this workspace is deleted by another API call.
         * @summary Create testing workspace from the available quota
         * @param {BodyCreateTestingWorkspaceV2} bodyCreateTestingWorkspaceV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingWorkspaceV2(bodyCreateTestingWorkspaceV2: BodyCreateTestingWorkspaceV2, options?: any): AxiosPromise<WorkspaceAndTeam> {
            return localVarFp.createTestingWorkspaceV2(bodyCreateTestingWorkspaceV2, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint deletes all the test workspaces.
         * @summary Delete all test workspaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllTestWorkspacesV1(options?: any): AxiosPromise<WorkspaceDeletionResponse> {
            return localVarFp.deleteAllTestWorkspacesV1(options).then((request) => request(axios, basePath));
        },
        /**
         * Default workspace is deleted for testing purpose only.This deletion is done by fetching the user\'s primary account.This workspace was created for the testing purpose.
         * @summary Delete workspace
         * @param {BodyDeleteTestingWorkspaceV1} bodyDeleteTestingWorkspaceV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestingWorkspaceV1(bodyDeleteTestingWorkspaceV1: BodyDeleteTestingWorkspaceV1, options?: any): AxiosPromise<WorkspaceDeletionResponse> {
            return localVarFp.deleteTestingWorkspaceV1(bodyDeleteTestingWorkspaceV1, options).then((request) => request(axios, basePath));
        },
        /**
         * This is for testing purposes only.User will be completely deleted from the platform, and removed from all the workspaces they are part of.This is a destructive operation, and cannot be undone.User\'s default entities (workspace, subscription, account, profile) will be deleted as well.If the user is not found, API returns a 404 status in the response.
         * @summary Delete a user from the platform.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserV1(userId: string, options?: any): AxiosPromise<UserDeletionResponse> {
            return localVarFp.deleteUserV1(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestAPIRoutesApi - object-oriented interface
 * @export
 * @class TestAPIRoutesApi
 * @extends {BaseAPI}
 */
export class TestAPIRoutesApi extends BaseAPI {
    /**
     * For creating demo workspace which will be used to keep demo artifacts. This workspace will havecurated list of artifacts which will be copied to user\'s personal workspace during onboarding
     * @summary Create demo workspace
     * @param {BodyCreateDemoWorkspaceV1} bodyCreateDemoWorkspaceV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestAPIRoutesApi
     */
    public createDemoWorkspaceV1(bodyCreateDemoWorkspaceV1: BodyCreateDemoWorkspaceV1, options?: AxiosRequestConfig) {
        return TestAPIRoutesApiFp(this.configuration).createDemoWorkspaceV1(bodyCreateDemoWorkspaceV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create pioneer workspace for Pioneers-2023
     * @summary Create pioneer workspace
     * @param {BodyCreatePioneerWorkspaceV1} bodyCreatePioneerWorkspaceV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestAPIRoutesApi
     */
    public createPioneerWorkspaceV1(bodyCreatePioneerWorkspaceV1: BodyCreatePioneerWorkspaceV1, options?: AxiosRequestConfig) {
        return TestAPIRoutesApiFp(this.configuration).createPioneerWorkspaceV1(bodyCreatePioneerWorkspaceV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Testing workspace with default entities is created for testing purpose only. Only limited number of testing workspaces can be created from the quota available. This creation is done by fetching the user\'s primary account. After test is completed this workspace is deleted by another API call.
     * @summary Create testing workspace from the available quota
     * @param {BodyCreateTestingWorkspaceV2} bodyCreateTestingWorkspaceV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestAPIRoutesApi
     */
    public createTestingWorkspaceV2(bodyCreateTestingWorkspaceV2: BodyCreateTestingWorkspaceV2, options?: AxiosRequestConfig) {
        return TestAPIRoutesApiFp(this.configuration).createTestingWorkspaceV2(bodyCreateTestingWorkspaceV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint deletes all the test workspaces.
     * @summary Delete all test workspaces
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestAPIRoutesApi
     */
    public deleteAllTestWorkspacesV1(options?: AxiosRequestConfig) {
        return TestAPIRoutesApiFp(this.configuration).deleteAllTestWorkspacesV1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Default workspace is deleted for testing purpose only.This deletion is done by fetching the user\'s primary account.This workspace was created for the testing purpose.
     * @summary Delete workspace
     * @param {BodyDeleteTestingWorkspaceV1} bodyDeleteTestingWorkspaceV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestAPIRoutesApi
     */
    public deleteTestingWorkspaceV1(bodyDeleteTestingWorkspaceV1: BodyDeleteTestingWorkspaceV1, options?: AxiosRequestConfig) {
        return TestAPIRoutesApiFp(this.configuration).deleteTestingWorkspaceV1(bodyDeleteTestingWorkspaceV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is for testing purposes only.User will be completely deleted from the platform, and removed from all the workspaces they are part of.This is a destructive operation, and cannot be undone.User\'s default entities (workspace, subscription, account, profile) will be deleted as well.If the user is not found, API returns a 404 status in the response.
     * @summary Delete a user from the platform.
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestAPIRoutesApi
     */
    public deleteUserV1(userId: string, options?: AxiosRequestConfig) {
        return TestAPIRoutesApiFp(this.configuration).deleteUserV1(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TimescaledbManagementApi - axios parameter creator
 * @export
 */
export const TimescaledbManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Provides http layer over insert operation on timescale database
         * @summary Insert records to timescale db over http post request
         * @param {string} database 
         * @param {string} tableName 
         * @param {TimescaledbInsertRecords} timescaledbInsertRecords 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timescaledbInsertRecordsV1: async (database: string, tableName: string, timescaledbInsertRecords: TimescaledbInsertRecords, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'database' is not null or undefined
            assertParamExists('timescaledbInsertRecordsV1', 'database', database)
            // verify required parameter 'tableName' is not null or undefined
            assertParamExists('timescaledbInsertRecordsV1', 'tableName', tableName)
            // verify required parameter 'timescaledbInsertRecords' is not null or undefined
            assertParamExists('timescaledbInsertRecordsV1', 'timescaledbInsertRecords', timescaledbInsertRecords)
            const localVarPath = `/bangalore/v1/database/{database}/table_name/{table_name}/insert`
                .replace(`{${"database"}}`, encodeURIComponent(String(database)))
                .replace(`{${"table_name"}}`, encodeURIComponent(String(tableName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timescaledbInsertRecords, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimescaledbManagementApi - functional programming interface
 * @export
 */
export const TimescaledbManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimescaledbManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Provides http layer over insert operation on timescale database
         * @summary Insert records to timescale db over http post request
         * @param {string} database 
         * @param {string} tableName 
         * @param {TimescaledbInsertRecords} timescaledbInsertRecords 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timescaledbInsertRecordsV1(database: string, tableName: string, timescaledbInsertRecords: TimescaledbInsertRecords, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimescaledbInsertRecordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timescaledbInsertRecordsV1(database, tableName, timescaledbInsertRecords, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TimescaledbManagementApi - factory interface
 * @export
 */
export const TimescaledbManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimescaledbManagementApiFp(configuration)
    return {
        /**
         * Provides http layer over insert operation on timescale database
         * @summary Insert records to timescale db over http post request
         * @param {string} database 
         * @param {string} tableName 
         * @param {TimescaledbInsertRecords} timescaledbInsertRecords 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timescaledbInsertRecordsV1(database: string, tableName: string, timescaledbInsertRecords: TimescaledbInsertRecords, options?: any): AxiosPromise<TimescaledbInsertRecordsResponse> {
            return localVarFp.timescaledbInsertRecordsV1(database, tableName, timescaledbInsertRecords, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimescaledbManagementApi - object-oriented interface
 * @export
 * @class TimescaledbManagementApi
 * @extends {BaseAPI}
 */
export class TimescaledbManagementApi extends BaseAPI {
    /**
     * Provides http layer over insert operation on timescale database
     * @summary Insert records to timescale db over http post request
     * @param {string} database 
     * @param {string} tableName 
     * @param {TimescaledbInsertRecords} timescaledbInsertRecords 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimescaledbManagementApi
     */
    public timescaledbInsertRecordsV1(database: string, tableName: string, timescaledbInsertRecords: TimescaledbInsertRecords, options?: AxiosRequestConfig) {
        return TimescaledbManagementApiFp(this.configuration).timescaledbInsertRecordsV1(database, tableName, timescaledbInsertRecords, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TokensApi - axios parameter creator
 * @export
 */
export const TokensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Access Token
         * @summary Access Token
         * @param {TokenRequest} tokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessTokenV1: async (tokenRequest: TokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenRequest' is not null or undefined
            assertParamExists('getAccessTokenV1', 'tokenRequest', tokenRequest)
            const localVarPath = `/tokyo/oauth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokensApi - functional programming interface
 * @export
 */
export const TokensApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokensApiAxiosParamCreator(configuration)
    return {
        /**
         * Access Token
         * @summary Access Token
         * @param {TokenRequest} tokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessTokenV1(tokenRequest: TokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessTokenV1(tokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TokensApi - factory interface
 * @export
 */
export const TokensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokensApiFp(configuration)
    return {
        /**
         * Access Token
         * @summary Access Token
         * @param {TokenRequest} tokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessTokenV1(tokenRequest: TokenRequest, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.getAccessTokenV1(tokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokensApi - object-oriented interface
 * @export
 * @class TokensApi
 * @extends {BaseAPI}
 */
export class TokensApi extends BaseAPI {
    /**
     * Access Token
     * @summary Access Token
     * @param {TokenRequest} tokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    public getAccessTokenV1(tokenRequest: TokenRequest, options?: AxiosRequestConfig) {
        return TokensApiFp(this.configuration).getAccessTokenV1(tokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsageTrackingManagementApi - axios parameter creator
 * @export
 */
export const UsageTrackingManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetches user count, unique daily active users, documents improved, writing sessions,    suggestions generated
         * @summary Fetches analytics of the copy edit application
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {number} lastNDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsCopyEditV11: async (workspaceId: string, appId: string, lastNDays: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('analyticsCopyEditV11', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('analyticsCopyEditV11', 'appId', appId)
            // verify required parameter 'lastNDays' is not null or undefined
            assertParamExists('analyticsCopyEditV11', 'lastNDays', lastNDays)
            const localVarPath = `/bangalore/v1/workspace/{workspace_id}/app/{app_id}/days/{last_n_days}/analytics/gen-ai-copy-edit`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"last_n_days"}}`, encodeURIComponent(String(lastNDays)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches user count, unique daily active users, documents improved, writing sessions,    suggestions generated
         * @summary Fetches analytics of the copy edit application
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {number} [days] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [projectIds] 
         * @param {Array<string>} [documentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsCopyEditV12: async (workspaceId: string, appId: string, days?: number, userIds?: Array<string>, projectIds?: Array<string>, documentIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('analyticsCopyEditV12', 'workspaceId', workspaceId)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('analyticsCopyEditV12', 'appId', appId)
            const localVarPath = `/bangalore/v2/workspace/{workspace_id}/app/{app_id}/analytics`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (days !== undefined) {
                localVarQueryParameter['days'] = days;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (projectIds) {
                localVarQueryParameter['project_ids'] = projectIds;
            }

            if (documentIds) {
                localVarQueryParameter['document_ids'] = documentIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Publish usage event to aws sqs queue and return the response, this event will be further consumed by the consumer lambda which will push the events to timescale db after further pre processing.
         * @summary Publish usage event to queue
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {PublishUsageEventsRequest} publishUsageEventsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishUsageEventV1: async (workspaceId: string, userId: string, publishUsageEventsRequest: PublishUsageEventsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('publishUsageEventV1', 'workspaceId', workspaceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('publishUsageEventV1', 'userId', userId)
            // verify required parameter 'publishUsageEventsRequest' is not null or undefined
            assertParamExists('publishUsageEventV1', 'publishUsageEventsRequest', publishUsageEventsRequest)
            const localVarPath = `/bangalore/v1/workspace/{workspace_id}/user/{user_id}/usage/publish`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishUsageEventsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches total compute time spent, total storage in bytes used for manual uploads, and total number of rows used for experiment tracking and evaluation records. This usage is for a single month
         * @summary Fetch total usage in a workspace in a month
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceUsageV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceUsageV1', 'workspaceId', workspaceId)
            const localVarPath = `/bangalore/v1/workspace/{workspace_id}/month-usage`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsageTrackingManagementApi - functional programming interface
 * @export
 */
export const UsageTrackingManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsageTrackingManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetches user count, unique daily active users, documents improved, writing sessions,    suggestions generated
         * @summary Fetches analytics of the copy edit application
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {number} lastNDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsCopyEditV11(workspaceId: string, appId: string, lastNDays: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenAIAnalyticsCopyEditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsCopyEditV11(workspaceId, appId, lastNDays, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches user count, unique daily active users, documents improved, writing sessions,    suggestions generated
         * @summary Fetches analytics of the copy edit application
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {number} [days] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [projectIds] 
         * @param {Array<string>} [documentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsCopyEditV12(workspaceId: string, appId: string, days?: number, userIds?: Array<string>, projectIds?: Array<string>, documentIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenAIAnalyticsCopyEditFilteredResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsCopyEditV12(workspaceId, appId, days, userIds, projectIds, documentIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Publish usage event to aws sqs queue and return the response, this event will be further consumed by the consumer lambda which will push the events to timescale db after further pre processing.
         * @summary Publish usage event to queue
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {PublishUsageEventsRequest} publishUsageEventsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishUsageEventV1(workspaceId: string, userId: string, publishUsageEventsRequest: PublishUsageEventsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublishUsageEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishUsageEventV1(workspaceId, userId, publishUsageEventsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches total compute time spent, total storage in bytes used for manual uploads, and total number of rows used for experiment tracking and evaluation records. This usage is for a single month
         * @summary Fetch total usage in a workspace in a month
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceUsageV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceUsageResponse1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceUsageV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsageTrackingManagementApi - factory interface
 * @export
 */
export const UsageTrackingManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsageTrackingManagementApiFp(configuration)
    return {
        /**
         * Fetches user count, unique daily active users, documents improved, writing sessions,    suggestions generated
         * @summary Fetches analytics of the copy edit application
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {number} lastNDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsCopyEditV11(workspaceId: string, appId: string, lastNDays: number, options?: any): AxiosPromise<GenAIAnalyticsCopyEditResponse> {
            return localVarFp.analyticsCopyEditV11(workspaceId, appId, lastNDays, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches user count, unique daily active users, documents improved, writing sessions,    suggestions generated
         * @summary Fetches analytics of the copy edit application
         * @param {string} workspaceId 
         * @param {string} appId 
         * @param {number} [days] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [projectIds] 
         * @param {Array<string>} [documentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsCopyEditV12(workspaceId: string, appId: string, days?: number, userIds?: Array<string>, projectIds?: Array<string>, documentIds?: Array<string>, options?: any): AxiosPromise<GenAIAnalyticsCopyEditFilteredResponse> {
            return localVarFp.analyticsCopyEditV12(workspaceId, appId, days, userIds, projectIds, documentIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Publish usage event to aws sqs queue and return the response, this event will be further consumed by the consumer lambda which will push the events to timescale db after further pre processing.
         * @summary Publish usage event to queue
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {PublishUsageEventsRequest} publishUsageEventsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishUsageEventV1(workspaceId: string, userId: string, publishUsageEventsRequest: PublishUsageEventsRequest, options?: any): AxiosPromise<PublishUsageEventResponse> {
            return localVarFp.publishUsageEventV1(workspaceId, userId, publishUsageEventsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches total compute time spent, total storage in bytes used for manual uploads, and total number of rows used for experiment tracking and evaluation records. This usage is for a single month
         * @summary Fetch total usage in a workspace in a month
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceUsageV1(workspaceId: string, options?: any): AxiosPromise<WorkspaceUsageResponse1> {
            return localVarFp.workspaceUsageV1(workspaceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsageTrackingManagementApi - object-oriented interface
 * @export
 * @class UsageTrackingManagementApi
 * @extends {BaseAPI}
 */
export class UsageTrackingManagementApi extends BaseAPI {
    /**
     * Fetches user count, unique daily active users, documents improved, writing sessions,    suggestions generated
     * @summary Fetches analytics of the copy edit application
     * @param {string} workspaceId 
     * @param {string} appId 
     * @param {number} lastNDays 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsageTrackingManagementApi
     */
    public analyticsCopyEditV11(workspaceId: string, appId: string, lastNDays: number, options?: AxiosRequestConfig) {
        return UsageTrackingManagementApiFp(this.configuration).analyticsCopyEditV11(workspaceId, appId, lastNDays, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches user count, unique daily active users, documents improved, writing sessions,    suggestions generated
     * @summary Fetches analytics of the copy edit application
     * @param {string} workspaceId 
     * @param {string} appId 
     * @param {number} [days] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [projectIds] 
     * @param {Array<string>} [documentIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsageTrackingManagementApi
     */
    public analyticsCopyEditV12(workspaceId: string, appId: string, days?: number, userIds?: Array<string>, projectIds?: Array<string>, documentIds?: Array<string>, options?: AxiosRequestConfig) {
        return UsageTrackingManagementApiFp(this.configuration).analyticsCopyEditV12(workspaceId, appId, days, userIds, projectIds, documentIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Publish usage event to aws sqs queue and return the response, this event will be further consumed by the consumer lambda which will push the events to timescale db after further pre processing.
     * @summary Publish usage event to queue
     * @param {string} workspaceId 
     * @param {string} userId 
     * @param {PublishUsageEventsRequest} publishUsageEventsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsageTrackingManagementApi
     */
    public publishUsageEventV1(workspaceId: string, userId: string, publishUsageEventsRequest: PublishUsageEventsRequest, options?: AxiosRequestConfig) {
        return UsageTrackingManagementApiFp(this.configuration).publishUsageEventV1(workspaceId, userId, publishUsageEventsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches total compute time spent, total storage in bytes used for manual uploads, and total number of rows used for experiment tracking and evaluation records. This usage is for a single month
     * @summary Fetch total usage in a workspace in a month
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsageTrackingManagementApi
     */
    public workspaceUsageV1(workspaceId: string, options?: AxiosRequestConfig) {
        return UsageTrackingManagementApiFp(this.configuration).workspaceUsageV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserCommentsRoutesApi - axios parameter creator
 * @export
 */
export const UserCommentsRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Endpoint to list all the conversations for a given resource
         * @param {string} workspaceId ID of the workspace.
         * @param {string} resourceId 
         * @param {CommentResourceTypes} resourceType 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConversationModelsV1: async (workspaceId: string, resourceId: string, resourceType: CommentResourceTypes, start?: number, end?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAllConversationModelsV1', 'workspaceId', workspaceId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getAllConversationModelsV1', 'resourceId', resourceId)
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('getAllConversationModelsV1', 'resourceType', resourceType)
            const localVarPath = `/v1/workspaces/{workspaceId}/collaborations/conversations`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceId !== undefined) {
                localVarQueryParameter['resourceId'] = resourceId;
            }

            if (resourceType !== undefined) {
                localVarQueryParameter['resourceType'] = resourceType;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to get a single conversation with comments
         * @param {string} workspaceId ID of the workspace.
         * @param {string} conversationId ID of the conversation.
         * @param {string} resourceId 
         * @param {CommentResourceTypes} resourceType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationCommentModelsV1: async (workspaceId: string, conversationId: string, resourceId: string, resourceType: CommentResourceTypes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getConversationCommentModelsV1', 'workspaceId', workspaceId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getConversationCommentModelsV1', 'conversationId', conversationId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getConversationCommentModelsV1', 'resourceId', resourceId)
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('getConversationCommentModelsV1', 'resourceType', resourceType)
            const localVarPath = `/v1/workspaces/{workspaceId}/collaborations/conversations/{conversationId}/comments`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceId !== undefined) {
                localVarQueryParameter['resourceId'] = resourceId;
            }

            if (resourceType !== undefined) {
                localVarQueryParameter['resourceType'] = resourceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserCommentsRoutesApi - functional programming interface
 * @export
 */
export const UserCommentsRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserCommentsRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * Endpoint to list all the conversations for a given resource
         * @param {string} workspaceId ID of the workspace.
         * @param {string} resourceId 
         * @param {CommentResourceTypes} resourceType 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllConversationModelsV1(workspaceId: string, resourceId: string, resourceType: CommentResourceTypes, start?: number, end?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConversationModelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllConversationModelsV1(workspaceId, resourceId, resourceType, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to get a single conversation with comments
         * @param {string} workspaceId ID of the workspace.
         * @param {string} conversationId ID of the conversation.
         * @param {string} resourceId 
         * @param {CommentResourceTypes} resourceType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationCommentModelsV1(workspaceId: string, conversationId: string, resourceId: string, resourceType: CommentResourceTypes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConversationDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationCommentModelsV1(workspaceId, conversationId, resourceId, resourceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserCommentsRoutesApi - factory interface
 * @export
 */
export const UserCommentsRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserCommentsRoutesApiFp(configuration)
    return {
        /**
         * Endpoint to list all the conversations for a given resource
         * @param {string} workspaceId ID of the workspace.
         * @param {string} resourceId 
         * @param {CommentResourceTypes} resourceType 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConversationModelsV1(workspaceId: string, resourceId: string, resourceType: CommentResourceTypes, start?: number, end?: number, options?: any): AxiosPromise<GetConversationModelsResponse> {
            return localVarFp.getAllConversationModelsV1(workspaceId, resourceId, resourceType, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to get a single conversation with comments
         * @param {string} workspaceId ID of the workspace.
         * @param {string} conversationId ID of the conversation.
         * @param {string} resourceId 
         * @param {CommentResourceTypes} resourceType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationCommentModelsV1(workspaceId: string, conversationId: string, resourceId: string, resourceType: CommentResourceTypes, options?: any): AxiosPromise<GetConversationDetailsResponse> {
            return localVarFp.getConversationCommentModelsV1(workspaceId, conversationId, resourceId, resourceType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserCommentsRoutesApi - object-oriented interface
 * @export
 * @class UserCommentsRoutesApi
 * @extends {BaseAPI}
 */
export class UserCommentsRoutesApi extends BaseAPI {
    /**
     * Endpoint to list all the conversations for a given resource
     * @param {string} workspaceId ID of the workspace.
     * @param {string} resourceId 
     * @param {CommentResourceTypes} resourceType 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCommentsRoutesApi
     */
    public getAllConversationModelsV1(workspaceId: string, resourceId: string, resourceType: CommentResourceTypes, start?: number, end?: number, options?: AxiosRequestConfig) {
        return UserCommentsRoutesApiFp(this.configuration).getAllConversationModelsV1(workspaceId, resourceId, resourceType, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to get a single conversation with comments
     * @param {string} workspaceId ID of the workspace.
     * @param {string} conversationId ID of the conversation.
     * @param {string} resourceId 
     * @param {CommentResourceTypes} resourceType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCommentsRoutesApi
     */
    public getConversationCommentModelsV1(workspaceId: string, conversationId: string, resourceId: string, resourceType: CommentResourceTypes, options?: AxiosRequestConfig) {
        return UserCommentsRoutesApiFp(this.configuration).getConversationCommentModelsV1(workspaceId, conversationId, resourceId, resourceType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserManagementApi - axios parameter creator
 * @export
 */
export const UserManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add workspaces to the enterprise domain, when new users are onboarded they will automatically be added to the workspaces in auto enroll list
         * @summary Add workspaces to the enterprise domain for which new users will enrolled
         * @param {string} accountId 
         * @param {WorkspaceEnrollList} workspaceEnrollList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEnterpriseWorkspacesToAutoEnrollV1: async (accountId: string, workspaceEnrollList: WorkspaceEnrollList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('addEnterpriseWorkspacesToAutoEnrollV1', 'accountId', accountId)
            // verify required parameter 'workspaceEnrollList' is not null or undefined
            assertParamExists('addEnterpriseWorkspacesToAutoEnrollV1', 'workspaceEnrollList', workspaceEnrollList)
            const localVarPath = `/haifa/v1/enterprise/account_id/{account_id}/auto-enroll/add-workspaces`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspaceEnrollList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns basic information for relevant users for the given list of `user_ids` in the request body.
         * @summary Information about the users in bulk
         * @param {BulkGetUsersRequest} bulkGetUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkGetUsersV1: async (bulkGetUsersRequest: BulkGetUsersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkGetUsersRequest' is not null or undefined
            assertParamExists('bulkGetUsersV1', 'bulkGetUsersRequest', bulkGetUsersRequest)
            const localVarPath = `/haifa/v1/internal/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkGetUsersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if an email is already registered
         * @summary Check Email
         * @param {BodyCheckEmailAvailableV1} bodyCheckEmailAvailableV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailAvailableV1: async (bodyCheckEmailAvailableV1: BodyCheckEmailAvailableV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyCheckEmailAvailableV1' is not null or undefined
            assertParamExists('checkEmailAvailableV1', 'bodyCheckEmailAvailableV1', bodyCheckEmailAvailableV1)
            const localVarPath = `/haifa/v1/user/check-email-available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyCheckEmailAvailableV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if username is unique and return 409 status in the response if username already exists
         * @summary Check if username is unique
         * @param {string} userId 
         * @param {BodyCheckUsernameUniqueV1} bodyCheckUsernameUniqueV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUsernameUniqueV1: async (userId: string, bodyCheckUsernameUniqueV1: BodyCheckUsernameUniqueV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('checkUsernameUniqueV1', 'userId', userId)
            // verify required parameter 'bodyCheckUsernameUniqueV1' is not null or undefined
            assertParamExists('checkUsernameUniqueV1', 'bodyCheckUsernameUniqueV1', bodyCheckUsernameUniqueV1)
            const localVarPath = `/haifa/v1/user/{user_id}/username-available`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyCheckUsernameUniqueV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an enterprise workspace and onboard the provided user as admin for this workspace
         * @summary This will create a new user, create a new enterprise workspace and onboard the user as admin into this workspace
         * @param {CreateEnterpriseSettings} createEnterpriseSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnterpriseV1: async (createEnterpriseSettings: CreateEnterpriseSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEnterpriseSettings' is not null or undefined
            assertParamExists('createEnterpriseV1', 'createEnterpriseSettings', createEnterpriseSettings)
            const localVarPath = `/haifa/v1/enterprise/onboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEnterpriseSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates an account for a user. A user can independently exist outside any workspace, team or account.
         * @summary Create an account for any new user.
         * @param {BaseUserCreate} baseUserCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserV1: async (baseUserCreate: BaseUserCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseUserCreate' is not null or undefined
            assertParamExists('createUserV1', 'baseUserCreate', baseUserCreate)
            const localVarPath = `/haifa/v1/user/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseUserCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deactivate membership of a user from the workspace
         * @summary Deactivate membership of a user from the workspace
         * @param {string} workspaceId 
         * @param {DeactivateUsersFromWorkspaceRequest} deactivateUsersFromWorkspaceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUserFromWorkspaceV1: async (workspaceId: string, deactivateUsersFromWorkspaceRequest: DeactivateUsersFromWorkspaceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deactivateUserFromWorkspaceV1', 'workspaceId', workspaceId)
            // verify required parameter 'deactivateUsersFromWorkspaceRequest' is not null or undefined
            assertParamExists('deactivateUserFromWorkspaceV1', 'deactivateUsersFromWorkspaceRequest', deactivateUsersFromWorkspaceRequest)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/deactivate-membership`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deactivateUsersFromWorkspaceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enroll users to workspace. List of users will get enrolled to workspace
         * @summary Enroll users to workspace.
         * @param {string} workspaceId 
         * @param {WorkspaceUserEnrollRequest} workspaceUserEnrollRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollUsersToWorkspaceV1: async (workspaceId: string, workspaceUserEnrollRequest: WorkspaceUserEnrollRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('enrollUsersToWorkspaceV1', 'workspaceId', workspaceId)
            // verify required parameter 'workspaceUserEnrollRequest' is not null or undefined
            assertParamExists('enrollUsersToWorkspaceV1', 'workspaceUserEnrollRequest', workspaceUserEnrollRequest)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/enroll-users`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspaceUserEnrollRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get information about all workspaces
         * @summary Get All Workspace Info
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<AccountOwnerType>} [workspaceAccountTypes] Optional filter workspaces owner account type. Whether the account is         owned by user or an enterprise. If filter isn\&#39;t selected, all types of workspaces would be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkspaceInfoListV1: async (pageNumber?: number, pageSize?: number, workspaceAccountTypes?: Array<AccountOwnerType>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/haifa/v1/workspace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (workspaceAccountTypes) {
                localVarQueryParameter['workspace_account_types'] = workspaceAccountTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns basic information about a user and their primary account by their email. If the email is not found, returns a USER_NOT_EXISTS status in the response.
         * @summary Information about a user and accounts by their email
         * @param {BodyGetEmailInfoV1} bodyGetEmailInfoV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailInfoV1: async (bodyGetEmailInfoV1: BodyGetEmailInfoV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyGetEmailInfoV1' is not null or undefined
            assertParamExists('getEmailInfoV1', 'bodyGetEmailInfoV1', bodyGetEmailInfoV1)
            const localVarPath = `/haifa/v1/user/email-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyGetEmailInfoV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List details for all enterprises. Such as enterprise domain, enterprise name, account id and whether auto enroll is enabled. This will help in finding whether enterprise with this domain already exists.
         * @summary List details for all enterprise accounts.
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [accountIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnterpriseOwnedAccountInfoV1: async (pageNumber?: number, pageSize?: number, accountIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/haifa/v1/enterprise/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (accountIds) {
                localVarQueryParameter['account_ids'] = accountIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns basic information about a user and their primary account by their user_id. If the user is not found, API returns a 404 status in the response.
         * @summary Information about a user and accounts by their user-id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAndAccountInfoV1: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserAndAccountInfoV1', 'userId', userId)
            const localVarPath = `/haifa/v1/user/{user_id}/info-with-account`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns basic information about a user by their user_id. If the user is not found, API returns a 404 status in the response.
         * @summary Information about a user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserV1: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserV1', 'userId', userId)
            const localVarPath = `/haifa/v1/user/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all activity performed by users in a workspace. Supported Activities are: 1. Users Added to Workspace Team2. Creation of Datasets3. Model Recordings4. Experiment Recordings
         * @summary Fetch all activity for a workspace
         * @param {any} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceActivityV1: async (workspaceId: any, start?: number, end?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspaceActivityV1', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/activity`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all activity performed by users in a particular studio in a workspace.
         * @summary Fetch all activity for a studio in workspace
         * @param {any} workspaceId 
         * @param {StudioIdType} studioId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceActivityV2: async (workspaceId: any, studioId: StudioIdType, start?: number, end?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspaceActivityV2', 'workspaceId', workspaceId)
            // verify required parameter 'studioId' is not null or undefined
            assertParamExists('getWorkspaceActivityV2', 'studioId', studioId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/studio/{studio_id}/activity`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"studio_id"}}`, encodeURIComponent(String(studioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the base_url of account with a given account_id.
         * @summary Account Base Url
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceBaseUrl: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspaceBaseUrl', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/base_url`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get information about a workspace
         * @summary Get Workspace Info
         * @param {any} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceInfoV1: async (workspaceId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspaceInfoV1', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get information about a workspace with additional information related to owner accounts, and details of a few members
         * @summary Get Workspace Info With Member Details
         * @param {any} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceInfoWithMemberDetailsV1: async (workspaceId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspaceInfoWithMemberDetailsV1', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/info-with-member-details`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a purpose built API to power workspace settings page.
         * @summary API with workspace settings
         * @param {any} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceSettingsV1: async (workspaceId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspaceSettingsV1', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/settings`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all artifact created by the logged in user in a workspace
         * @summary Fetch all artifact usage for the user in a workspace
         * @param {any} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceUsageInfoV1: async (workspaceId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspaceUsageInfoV1', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/usage`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get workspaces which will be auto enrolled
         * @summary Getch all workspaces which will be auto enrolled for a given user with enterprise domain
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesInAutoEnrollV1: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getWorkspacesInAutoEnrollV1', 'accountId', accountId)
            const localVarPath = `/haifa/v1/enterprise/account_id/{account_id}/auto-enroll/workspaces`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns basic information about a user, workspaces they are a part of. This can be used to create information for an ID token.
         * @summary Basic information about user and workspace memberships for id tokens.
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iDTokenInfoV1: async (userId: string, start?: number, end?: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('iDTokenInfoV1', 'userId', userId)
            const localVarPath = `/haifa/v1/user/{user_id}/id-token-info`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of all manually triggered runs for each component. For ex, dataset component would return alldataset comparison runs. Filters can be provided on user_id and component_ids.
         * @summary Get list of manually triggered runs and their statuses
         * @param {string} workspaceId 
         * @param {string} componentType 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [userId] 
         * @param {string} [componentId] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRunsV1: async (workspaceId: string, componentType: string, start?: number, end?: number, userId?: string, componentId?: string, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listRunsV1', 'workspaceId', workspaceId)
            // verify required parameter 'componentType' is not null or undefined
            assertParamExists('listRunsV1', 'componentType', componentType)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/component_type/{component_type}/runs`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"component_type"}}`, encodeURIComponent(String(componentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (componentId !== undefined) {
                localVarQueryParameter['componentId'] = componentId;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of workspaces that user is a part of. User may be part of more than one workspaces.Workspaces are grouped into personal, enterpeise, and shared workspaces
         * @summary List of workspaces that user is a part of grouped into hierarchy
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserWorkspacesGrouped: async (userId: string, start?: number, end?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('listUserWorkspacesGrouped', 'userId', userId)
            const localVarPath = `/haifa/v1/user/{user_id}/workspaces-grouped`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of workspaces that user is a part of. User may be part of more than one workspaces
         * @summary Paginated list of workspaces that user is a part of
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserWorkspacesV1: async (userId: string, start?: number, end?: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('listUserWorkspacesV1', 'userId', userId)
            const localVarPath = `/haifa/v1/user/{user_id}/workspaces`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of workspaces that user is a part of. User may be part of more than one workspaces. The response object would have additional information related to owner accounts, and details of a few members
         * @summary Paginated list of workspaces that user is a part of
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserWorkspacesWithMemberDetailsV1: async (userId: string, start?: number, end?: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('listUserWorkspacesWithMemberDetailsV1', 'userId', userId)
            const localVarPath = `/haifa/v1/user/{user_id}/workspaces-with-member-details`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all members of a workspace
         * @summary Paginated list of members of a workspace
         * @param {any} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
         * @param {Array<string>} [teamId] 
         * @param {Array<string>} [workspaceMembershipRole] 
         * @param {string} [userName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspaceMembersV1: async (workspaceId: any, start?: number, end?: number, ts?: string, teamId?: Array<string>, workspaceMembershipRole?: Array<string>, userName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listWorkspaceMembersV1', 'workspaceId', workspaceId)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/members`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (teamId) {
                localVarQueryParameter['team_id'] = teamId;
            }

            if (workspaceMembershipRole) {
                localVarQueryParameter['workspace_membership_role'] = workspaceMembershipRole;
            }

            if (userName !== undefined) {
                localVarQueryParameter['user_name'] = userName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove workspaces from the enterprise domain
         * @summary Remove workspaces from the enterprise domain, so new users will not be auto enrolled for the specified workspaces
         * @param {string} accountId 
         * @param {WorkspaceEnrollList} workspaceEnrollList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEnterpriseWorkspacesToAutoEnrollV1: async (accountId: string, workspaceEnrollList: WorkspaceEnrollList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('removeEnterpriseWorkspacesToAutoEnrollV1', 'accountId', accountId)
            // verify required parameter 'workspaceEnrollList' is not null or undefined
            assertParamExists('removeEnterpriseWorkspacesToAutoEnrollV1', 'workspaceEnrollList', workspaceEnrollList)
            const localVarPath = `/haifa/v1/enterprise/account_id/{account_id}/auto-enroll/remove-workspaces`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspaceEnrollList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the default status of a workspace for a user as default or not
         * @summary update default status of workspace
         * @param {any} workspaceId 
         * @param {boolean} defaultStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefaultStatusV1: async (workspaceId: any, defaultStatus: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateDefaultStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'defaultStatus' is not null or undefined
            assertParamExists('updateDefaultStatusV1', 'defaultStatus', defaultStatus)
            const localVarPath = `/haifa/v1//workspace/{workspace_id}/default/status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (defaultStatus !== undefined) {
                localVarQueryParameter['default_status'] = defaultStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update auto enroll flag for an enterprise account
         * @summary This will control whether new created users will be automatically enrolled to the auto enroll workspace list or not
         * @param {string} accountId 
         * @param {boolean} autoEnrollEnabled 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnterpriseAutoEnrollFlagV1: async (accountId: string, autoEnrollEnabled: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('updateEnterpriseAutoEnrollFlagV1', 'accountId', accountId)
            // verify required parameter 'autoEnrollEnabled' is not null or undefined
            assertParamExists('updateEnterpriseAutoEnrollFlagV1', 'autoEnrollEnabled', autoEnrollEnabled)
            const localVarPath = `/haifa/v1/enterprise/account_id/{account_id}/auto-enroll-enabled`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (autoEnrollEnabled !== undefined) {
                localVarQueryParameter['auto_enroll_enabled'] = autoEnrollEnabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the status of a workspace for a user as favorite or not
         * @summary Set status of workspace favorite
         * @param {any} workspaceId 
         * @param {boolean} favoriteStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFavoriteStatusV1: async (workspaceId: any, favoriteStatus: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateFavoriteStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'favoriteStatus' is not null or undefined
            assertParamExists('updateFavoriteStatusV1', 'favoriteStatus', favoriteStatus)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/favorite/status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (favoriteStatus !== undefined) {
                localVarQueryParameter['favorite_status'] = favoriteStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Cli Version
         * @param {string} userId 
         * @param {string} cliVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserCLIVersionV1: async (userId: string, cliVersion: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserCLIVersionV1', 'userId', userId)
            // verify required parameter 'cliVersion' is not null or undefined
            assertParamExists('updateUserCLIVersionV1', 'cliVersion', cliVersion)
            const localVarPath = `/haifa/v1/user/{user_id}/cli-version/{cli_version}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"cli_version"}}`, encodeURIComponent(String(cliVersion)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update basic information about as user by their user_id. If the user is not found, API returns a 404 status in the response.
         * @summary Update user information
         * @param {string} userId 
         * @param {BaseUserUpdate} baseUserUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserV1: async (userId: string, baseUserUpdate: BaseUserUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserV1', 'userId', userId)
            // verify required parameter 'baseUserUpdate' is not null or undefined
            assertParamExists('updateUserV1', 'baseUserUpdate', baseUserUpdate)
            const localVarPath = `/haifa/v1/user/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseUserUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update information about a workspace, such as name of the workspace. Certain fields such as workspace id are not allowed to be modified.
         * @summary Update workspace information
         * @param {any} workspaceId 
         * @param {WorkspaceUpdate} workspaceUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceInfoV1: async (workspaceId: any, workspaceUpdate: WorkspaceUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateWorkspaceInfoV1', 'workspaceId', workspaceId)
            // verify required parameter 'workspaceUpdate' is not null or undefined
            assertParamExists('updateWorkspaceInfoV1', 'workspaceUpdate', workspaceUpdate)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspaceUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user workspace membership role. Role states OWNER, MEMBER, or MAINTAINER
         * @summary Update user workspace membership role.
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {WorkspaceMembershipRoles} workspaceMembershipRole 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceMembershipRoleV1: async (workspaceId: string, userId: string, workspaceMembershipRole: WorkspaceMembershipRoles, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateWorkspaceMembershipRoleV1', 'workspaceId', workspaceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateWorkspaceMembershipRoleV1', 'userId', userId)
            // verify required parameter 'workspaceMembershipRole' is not null or undefined
            assertParamExists('updateWorkspaceMembershipRoleV1', 'workspaceMembershipRole', workspaceMembershipRole)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/user/{user_id}/role`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workspaceMembershipRole !== undefined) {
                localVarQueryParameter['workspace_membership_role'] = workspaceMembershipRole;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all objects matching the given search param. All the artifacts (recordings, datasets), teams, users matching the search param will be returned.The search param can be a partial match of the name of the object. A maximum of 3 results will be returned for each type of object.
         * @summary Get all objects matching the given search param
         * @param {any} workspaceId 
         * @param {any} searchString search string that will be used look for matching entities
         * @param {Array<SearchableEntities>} [searchEntities] List of entities to search
         * @param {number} [limit] Maximum number of results to return for each type of entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceSearchV1: async (workspaceId: any, searchString: any, searchEntities?: Array<SearchableEntities>, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceSearchV1', 'workspaceId', workspaceId)
            // verify required parameter 'searchString' is not null or undefined
            assertParamExists('workspaceSearchV1', 'searchString', searchString)
            const localVarPath = `/haifa/v1/workspace/{workspace_id}/search`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchEntities) {
                localVarQueryParameter['search_entities'] = searchEntities;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['search_string'] = searchString;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserManagementApi - functional programming interface
 * @export
 */
export const UserManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Add workspaces to the enterprise domain, when new users are onboarded they will automatically be added to the workspaces in auto enroll list
         * @summary Add workspaces to the enterprise domain for which new users will enrolled
         * @param {string} accountId 
         * @param {WorkspaceEnrollList} workspaceEnrollList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEnterpriseWorkspacesToAutoEnrollV1(accountId: string, workspaceEnrollList: WorkspaceEnrollList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceAutoEnrollSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEnterpriseWorkspacesToAutoEnrollV1(accountId, workspaceEnrollList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns basic information for relevant users for the given list of `user_ids` in the request body.
         * @summary Information about the users in bulk
         * @param {BulkGetUsersRequest} bulkGetUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkGetUsersV1(bulkGetUsersRequest: BulkGetUsersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkGetUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkGetUsersV1(bulkGetUsersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check if an email is already registered
         * @summary Check Email
         * @param {BodyCheckEmailAvailableV1} bodyCheckEmailAvailableV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkEmailAvailableV1(bodyCheckEmailAvailableV1: BodyCheckEmailAvailableV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkEmailAvailableV1(bodyCheckEmailAvailableV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check if username is unique and return 409 status in the response if username already exists
         * @summary Check if username is unique
         * @param {string} userId 
         * @param {BodyCheckUsernameUniqueV1} bodyCheckUsernameUniqueV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkUsernameUniqueV1(userId: string, bodyCheckUsernameUniqueV1: BodyCheckUsernameUniqueV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkUsernameUniqueV1(userId, bodyCheckUsernameUniqueV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create an enterprise workspace and onboard the provided user as admin for this workspace
         * @summary This will create a new user, create a new enterprise workspace and onboard the user as admin into this workspace
         * @param {CreateEnterpriseSettings} createEnterpriseSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEnterpriseV1(createEnterpriseSettings: CreateEnterpriseSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedWorkspaceWithDefaultEntities>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEnterpriseV1(createEnterpriseSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates an account for a user. A user can independently exist outside any workspace, team or account.
         * @summary Create an account for any new user.
         * @param {BaseUserCreate} baseUserCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserV1(baseUserCreate: BaseUserCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWithAccountDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserV1(baseUserCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deactivate membership of a user from the workspace
         * @summary Deactivate membership of a user from the workspace
         * @param {string} workspaceId 
         * @param {DeactivateUsersFromWorkspaceRequest} deactivateUsersFromWorkspaceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateUserFromWorkspaceV1(workspaceId: string, deactivateUsersFromWorkspaceRequest: DeactivateUsersFromWorkspaceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeactivatedUsersFromWorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateUserFromWorkspaceV1(workspaceId, deactivateUsersFromWorkspaceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enroll users to workspace. List of users will get enrolled to workspace
         * @summary Enroll users to workspace.
         * @param {string} workspaceId 
         * @param {WorkspaceUserEnrollRequest} workspaceUserEnrollRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enrollUsersToWorkspaceV1(workspaceId: string, workspaceUserEnrollRequest: WorkspaceUserEnrollRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceUserEnrollResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enrollUsersToWorkspaceV1(workspaceId, workspaceUserEnrollRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get information about all workspaces
         * @summary Get All Workspace Info
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<AccountOwnerType>} [workspaceAccountTypes] Optional filter workspaces owner account type. Whether the account is         owned by user or an enterprise. If filter isn\&#39;t selected, all types of workspaces would be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWorkspaceInfoListV1(pageNumber?: number, pageSize?: number, workspaceAccountTypes?: Array<AccountOwnerType>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWorkspaceInfoListV1(pageNumber, pageSize, workspaceAccountTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns basic information about a user and their primary account by their email. If the email is not found, returns a USER_NOT_EXISTS status in the response.
         * @summary Information about a user and accounts by their email
         * @param {BodyGetEmailInfoV1} bodyGetEmailInfoV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailInfoV1(bodyGetEmailInfoV1: BodyGetEmailInfoV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailInfoV1(bodyGetEmailInfoV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List details for all enterprises. Such as enterprise domain, enterprise name, account id and whether auto enroll is enabled. This will help in finding whether enterprise with this domain already exists.
         * @summary List details for all enterprise accounts.
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [accountIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnterpriseOwnedAccountInfoV1(pageNumber?: number, pageSize?: number, accountIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnterpriseOwnedAccountDetailedInfoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnterpriseOwnedAccountInfoV1(pageNumber, pageSize, accountIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns basic information about a user and their primary account by their user_id. If the user is not found, API returns a 404 status in the response.
         * @summary Information about a user and accounts by their user-id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAndAccountInfoV1(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWithAccountDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAndAccountInfoV1(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns basic information about a user by their user_id. If the user is not found, API returns a 404 status in the response.
         * @summary Information about a user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserV1(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserV1(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all activity performed by users in a workspace. Supported Activities are: 1. Users Added to Workspace Team2. Creation of Datasets3. Model Recordings4. Experiment Recordings
         * @summary Fetch all activity for a workspace
         * @param {any} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceActivityV1(workspaceId: any, start?: number, end?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceActivityV1(workspaceId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all activity performed by users in a particular studio in a workspace.
         * @summary Fetch all activity for a studio in workspace
         * @param {any} workspaceId 
         * @param {StudioIdType} studioId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceActivityV2(workspaceId: any, studioId: StudioIdType, start?: number, end?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceActivityV2(workspaceId, studioId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the base_url of account with a given account_id.
         * @summary Account Base Url
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceBaseUrl(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceBaseUrl(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get information about a workspace
         * @summary Get Workspace Info
         * @param {any} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceInfoV1(workspaceId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workspace>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceInfoV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get information about a workspace with additional information related to owner accounts, and details of a few members
         * @summary Get Workspace Info With Member Details
         * @param {any} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceInfoWithMemberDetailsV1(workspaceId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceWithMemberDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceInfoWithMemberDetailsV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This is a purpose built API to power workspace settings page.
         * @summary API with workspace settings
         * @param {any} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceSettingsV1(workspaceId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceSettingsV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all artifact created by the logged in user in a workspace
         * @summary Fetch all artifact usage for the user in a workspace
         * @param {any} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceUsageInfoV1(workspaceId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceUsageInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceUsageInfoV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get workspaces which will be auto enrolled
         * @summary Getch all workspaces which will be auto enrolled for a given user with enterprise domain
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspacesInAutoEnrollV1(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspacesInAutoEnrollV1(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns basic information about a user, workspaces they are a part of. This can be used to create information for an ID token.
         * @summary Basic information about user and workspace memberships for id tokens.
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async iDTokenInfoV1(userId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDTokenInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.iDTokenInfoV1(userId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of all manually triggered runs for each component. For ex, dataset component would return alldataset comparison runs. Filters can be provided on user_id and component_ids.
         * @summary Get list of manually triggered runs and their statuses
         * @param {string} workspaceId 
         * @param {string} componentType 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [userId] 
         * @param {string} [componentId] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRunsV1(workspaceId: string, componentType: string, start?: number, end?: number, userId?: string, componentId?: string, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRunsV1(workspaceId, componentType, start, end, userId, componentId, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of workspaces that user is a part of. User may be part of more than one workspaces.Workspaces are grouped into personal, enterpeise, and shared workspaces
         * @summary List of workspaces that user is a part of grouped into hierarchy
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserWorkspacesGrouped(userId: string, start?: number, end?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWorkspacesGrouped>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserWorkspacesGrouped(userId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of workspaces that user is a part of. User may be part of more than one workspaces
         * @summary Paginated list of workspaces that user is a part of
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserWorkspacesV1(userId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWorkspaceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserWorkspacesV1(userId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of workspaces that user is a part of. User may be part of more than one workspaces. The response object would have additional information related to owner accounts, and details of a few members
         * @summary Paginated list of workspaces that user is a part of
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserWorkspacesWithMemberDetailsV1(userId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceListWithMemberDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserWorkspacesWithMemberDetailsV1(userId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all members of a workspace
         * @summary Paginated list of members of a workspace
         * @param {any} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
         * @param {Array<string>} [teamId] 
         * @param {Array<string>} [workspaceMembershipRole] 
         * @param {string} [userName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWorkspaceMembersV1(workspaceId: any, start?: number, end?: number, ts?: string, teamId?: Array<string>, workspaceMembershipRole?: Array<string>, userName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceMembersList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWorkspaceMembersV1(workspaceId, start, end, ts, teamId, workspaceMembershipRole, userName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove workspaces from the enterprise domain
         * @summary Remove workspaces from the enterprise domain, so new users will not be auto enrolled for the specified workspaces
         * @param {string} accountId 
         * @param {WorkspaceEnrollList} workspaceEnrollList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeEnterpriseWorkspacesToAutoEnrollV1(accountId: string, workspaceEnrollList: WorkspaceEnrollList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceAutoEnrollSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeEnterpriseWorkspacesToAutoEnrollV1(accountId, workspaceEnrollList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the default status of a workspace for a user as default or not
         * @summary update default status of workspace
         * @param {any} workspaceId 
         * @param {boolean} defaultStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDefaultStatusV1(workspaceId: any, defaultStatus: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDefaultStatusV1(workspaceId, defaultStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update auto enroll flag for an enterprise account
         * @summary This will control whether new created users will be automatically enrolled to the auto enroll workspace list or not
         * @param {string} accountId 
         * @param {boolean} autoEnrollEnabled 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEnterpriseAutoEnrollFlagV1(accountId: string, autoEnrollEnabled: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnterpriseOwnedAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEnterpriseAutoEnrollFlagV1(accountId, autoEnrollEnabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the status of a workspace for a user as favorite or not
         * @summary Set status of workspace favorite
         * @param {any} workspaceId 
         * @param {boolean} favoriteStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFavoriteStatusV1(workspaceId: any, favoriteStatus: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFavoriteStatusV1(workspaceId, favoriteStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Cli Version
         * @param {string} userId 
         * @param {string} cliVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserCLIVersionV1(userId: string, cliVersion: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CLIVersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserCLIVersionV1(userId, cliVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update basic information about as user by their user_id. If the user is not found, API returns a 404 status in the response.
         * @summary Update user information
         * @param {string} userId 
         * @param {BaseUserUpdate} baseUserUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserV1(userId: string, baseUserUpdate: BaseUserUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserV1(userId, baseUserUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update information about a workspace, such as name of the workspace. Certain fields such as workspace id are not allowed to be modified.
         * @summary Update workspace information
         * @param {any} workspaceId 
         * @param {WorkspaceUpdate} workspaceUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkspaceInfoV1(workspaceId: any, workspaceUpdate: WorkspaceUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workspace>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkspaceInfoV1(workspaceId, workspaceUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user workspace membership role. Role states OWNER, MEMBER, or MAINTAINER
         * @summary Update user workspace membership role.
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {WorkspaceMembershipRoles} workspaceMembershipRole 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkspaceMembershipRoleV1(workspaceId: string, userId: string, workspaceMembershipRole: WorkspaceMembershipRoles, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceMembershipRoleUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkspaceMembershipRoleV1(workspaceId, userId, workspaceMembershipRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all objects matching the given search param. All the artifacts (recordings, datasets), teams, users matching the search param will be returned.The search param can be a partial match of the name of the object. A maximum of 3 results will be returned for each type of object.
         * @summary Get all objects matching the given search param
         * @param {any} workspaceId 
         * @param {any} searchString search string that will be used look for matching entities
         * @param {Array<SearchableEntities>} [searchEntities] List of entities to search
         * @param {number} [limit] Maximum number of results to return for each type of entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceSearchV1(workspaceId: any, searchString: any, searchEntities?: Array<SearchableEntities>, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceSearchV1(workspaceId, searchString, searchEntities, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserManagementApi - factory interface
 * @export
 */
export const UserManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserManagementApiFp(configuration)
    return {
        /**
         * Add workspaces to the enterprise domain, when new users are onboarded they will automatically be added to the workspaces in auto enroll list
         * @summary Add workspaces to the enterprise domain for which new users will enrolled
         * @param {string} accountId 
         * @param {WorkspaceEnrollList} workspaceEnrollList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEnterpriseWorkspacesToAutoEnrollV1(accountId: string, workspaceEnrollList: WorkspaceEnrollList, options?: any): AxiosPromise<WorkspaceAutoEnrollSettings> {
            return localVarFp.addEnterpriseWorkspacesToAutoEnrollV1(accountId, workspaceEnrollList, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns basic information for relevant users for the given list of `user_ids` in the request body.
         * @summary Information about the users in bulk
         * @param {BulkGetUsersRequest} bulkGetUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkGetUsersV1(bulkGetUsersRequest: BulkGetUsersRequest, options?: any): AxiosPromise<BulkGetUsersResponse> {
            return localVarFp.bulkGetUsersV1(bulkGetUsersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Check if an email is already registered
         * @summary Check Email
         * @param {BodyCheckEmailAvailableV1} bodyCheckEmailAvailableV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailAvailableV1(bodyCheckEmailAvailableV1: BodyCheckEmailAvailableV1, options?: any): AxiosPromise<boolean> {
            return localVarFp.checkEmailAvailableV1(bodyCheckEmailAvailableV1, options).then((request) => request(axios, basePath));
        },
        /**
         * Check if username is unique and return 409 status in the response if username already exists
         * @summary Check if username is unique
         * @param {string} userId 
         * @param {BodyCheckUsernameUniqueV1} bodyCheckUsernameUniqueV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUsernameUniqueV1(userId: string, bodyCheckUsernameUniqueV1: BodyCheckUsernameUniqueV1, options?: any): AxiosPromise<boolean> {
            return localVarFp.checkUsernameUniqueV1(userId, bodyCheckUsernameUniqueV1, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an enterprise workspace and onboard the provided user as admin for this workspace
         * @summary This will create a new user, create a new enterprise workspace and onboard the user as admin into this workspace
         * @param {CreateEnterpriseSettings} createEnterpriseSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnterpriseV1(createEnterpriseSettings: CreateEnterpriseSettings, options?: any): AxiosPromise<CreatedWorkspaceWithDefaultEntities> {
            return localVarFp.createEnterpriseV1(createEnterpriseSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates an account for a user. A user can independently exist outside any workspace, team or account.
         * @summary Create an account for any new user.
         * @param {BaseUserCreate} baseUserCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserV1(baseUserCreate: BaseUserCreate, options?: any): AxiosPromise<UserWithAccountDetails> {
            return localVarFp.createUserV1(baseUserCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Deactivate membership of a user from the workspace
         * @summary Deactivate membership of a user from the workspace
         * @param {string} workspaceId 
         * @param {DeactivateUsersFromWorkspaceRequest} deactivateUsersFromWorkspaceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUserFromWorkspaceV1(workspaceId: string, deactivateUsersFromWorkspaceRequest: DeactivateUsersFromWorkspaceRequest, options?: any): AxiosPromise<DeactivatedUsersFromWorkspaceResponse> {
            return localVarFp.deactivateUserFromWorkspaceV1(workspaceId, deactivateUsersFromWorkspaceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Enroll users to workspace. List of users will get enrolled to workspace
         * @summary Enroll users to workspace.
         * @param {string} workspaceId 
         * @param {WorkspaceUserEnrollRequest} workspaceUserEnrollRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollUsersToWorkspaceV1(workspaceId: string, workspaceUserEnrollRequest: WorkspaceUserEnrollRequest, options?: any): AxiosPromise<WorkspaceUserEnrollResponse> {
            return localVarFp.enrollUsersToWorkspaceV1(workspaceId, workspaceUserEnrollRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get information about all workspaces
         * @summary Get All Workspace Info
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<AccountOwnerType>} [workspaceAccountTypes] Optional filter workspaces owner account type. Whether the account is         owned by user or an enterprise. If filter isn\&#39;t selected, all types of workspaces would be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkspaceInfoListV1(pageNumber?: number, pageSize?: number, workspaceAccountTypes?: Array<AccountOwnerType>, options?: any): AxiosPromise<WorkspaceList> {
            return localVarFp.getAllWorkspaceInfoListV1(pageNumber, pageSize, workspaceAccountTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns basic information about a user and their primary account by their email. If the email is not found, returns a USER_NOT_EXISTS status in the response.
         * @summary Information about a user and accounts by their email
         * @param {BodyGetEmailInfoV1} bodyGetEmailInfoV1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailInfoV1(bodyGetEmailInfoV1: BodyGetEmailInfoV1, options?: any): AxiosPromise<EmailInfo> {
            return localVarFp.getEmailInfoV1(bodyGetEmailInfoV1, options).then((request) => request(axios, basePath));
        },
        /**
         * List details for all enterprises. Such as enterprise domain, enterprise name, account id and whether auto enroll is enabled. This will help in finding whether enterprise with this domain already exists.
         * @summary List details for all enterprise accounts.
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [accountIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnterpriseOwnedAccountInfoV1(pageNumber?: number, pageSize?: number, accountIds?: Array<string>, options?: any): AxiosPromise<EnterpriseOwnedAccountDetailedInfoList> {
            return localVarFp.getEnterpriseOwnedAccountInfoV1(pageNumber, pageSize, accountIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns basic information about a user and their primary account by their user_id. If the user is not found, API returns a 404 status in the response.
         * @summary Information about a user and accounts by their user-id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAndAccountInfoV1(userId: string, options?: any): AxiosPromise<UserWithAccountDetails> {
            return localVarFp.getUserAndAccountInfoV1(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns basic information about a user by their user_id. If the user is not found, API returns a 404 status in the response.
         * @summary Information about a user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserV1(userId: string, options?: any): AxiosPromise<BaseUser> {
            return localVarFp.getUserV1(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all activity performed by users in a workspace. Supported Activities are: 1. Users Added to Workspace Team2. Creation of Datasets3. Model Recordings4. Experiment Recordings
         * @summary Fetch all activity for a workspace
         * @param {any} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceActivityV1(workspaceId: any, start?: number, end?: number, options?: any): AxiosPromise<ActivityList> {
            return localVarFp.getWorkspaceActivityV1(workspaceId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all activity performed by users in a particular studio in a workspace.
         * @summary Fetch all activity for a studio in workspace
         * @param {any} workspaceId 
         * @param {StudioIdType} studioId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceActivityV2(workspaceId: any, studioId: StudioIdType, start?: number, end?: number, options?: any): AxiosPromise<ActivityList> {
            return localVarFp.getWorkspaceActivityV2(workspaceId, studioId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the base_url of account with a given account_id.
         * @summary Account Base Url
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceBaseUrl(workspaceId: string, options?: any): AxiosPromise<BaseUrlResponse> {
            return localVarFp.getWorkspaceBaseUrl(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get information about a workspace
         * @summary Get Workspace Info
         * @param {any} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceInfoV1(workspaceId: any, options?: any): AxiosPromise<Workspace> {
            return localVarFp.getWorkspaceInfoV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get information about a workspace with additional information related to owner accounts, and details of a few members
         * @summary Get Workspace Info With Member Details
         * @param {any} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceInfoWithMemberDetailsV1(workspaceId: any, options?: any): AxiosPromise<WorkspaceWithMemberDetails> {
            return localVarFp.getWorkspaceInfoWithMemberDetailsV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a purpose built API to power workspace settings page.
         * @summary API with workspace settings
         * @param {any} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceSettingsV1(workspaceId: any, options?: any): AxiosPromise<WorkspaceSettings> {
            return localVarFp.getWorkspaceSettingsV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all artifact created by the logged in user in a workspace
         * @summary Fetch all artifact usage for the user in a workspace
         * @param {any} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceUsageInfoV1(workspaceId: any, options?: any): AxiosPromise<WorkspaceUsageInfo> {
            return localVarFp.getWorkspaceUsageInfoV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get workspaces which will be auto enrolled
         * @summary Getch all workspaces which will be auto enrolled for a given user with enterprise domain
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesInAutoEnrollV1(accountId: string, options?: any): AxiosPromise<WorkspaceList> {
            return localVarFp.getWorkspacesInAutoEnrollV1(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns basic information about a user, workspaces they are a part of. This can be used to create information for an ID token.
         * @summary Basic information about user and workspace memberships for id tokens.
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iDTokenInfoV1(userId: string, start?: number, end?: number, ts?: string, options?: any): AxiosPromise<IDTokenInfo> {
            return localVarFp.iDTokenInfoV1(userId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of all manually triggered runs for each component. For ex, dataset component would return alldataset comparison runs. Filters can be provided on user_id and component_ids.
         * @summary Get list of manually triggered runs and their statuses
         * @param {string} workspaceId 
         * @param {string} componentType 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [userId] 
         * @param {string} [componentId] 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRunsV1(workspaceId: string, componentType: string, start?: number, end?: number, userId?: string, componentId?: string, ts?: string, options?: any): AxiosPromise<RunsList> {
            return localVarFp.listRunsV1(workspaceId, componentType, start, end, userId, componentId, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * List of workspaces that user is a part of. User may be part of more than one workspaces.Workspaces are grouped into personal, enterpeise, and shared workspaces
         * @summary List of workspaces that user is a part of grouped into hierarchy
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserWorkspacesGrouped(userId: string, start?: number, end?: number, options?: any): AxiosPromise<UserWorkspacesGrouped> {
            return localVarFp.listUserWorkspacesGrouped(userId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * List of workspaces that user is a part of. User may be part of more than one workspaces
         * @summary Paginated list of workspaces that user is a part of
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserWorkspacesV1(userId: string, start?: number, end?: number, ts?: string, options?: any): AxiosPromise<UserWorkspaceList> {
            return localVarFp.listUserWorkspacesV1(userId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * List of workspaces that user is a part of. User may be part of more than one workspaces. The response object would have additional information related to owner accounts, and details of a few members
         * @summary Paginated list of workspaces that user is a part of
         * @param {string} userId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserWorkspacesWithMemberDetailsV1(userId: string, start?: number, end?: number, ts?: string, options?: any): AxiosPromise<WorkspaceListWithMemberDetails> {
            return localVarFp.listUserWorkspacesWithMemberDetailsV1(userId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * List all members of a workspace
         * @summary Paginated list of members of a workspace
         * @param {any} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
         * @param {Array<string>} [teamId] 
         * @param {Array<string>} [workspaceMembershipRole] 
         * @param {string} [userName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspaceMembersV1(workspaceId: any, start?: number, end?: number, ts?: string, teamId?: Array<string>, workspaceMembershipRole?: Array<string>, userName?: string, options?: any): AxiosPromise<WorkspaceMembersList> {
            return localVarFp.listWorkspaceMembersV1(workspaceId, start, end, ts, teamId, workspaceMembershipRole, userName, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove workspaces from the enterprise domain
         * @summary Remove workspaces from the enterprise domain, so new users will not be auto enrolled for the specified workspaces
         * @param {string} accountId 
         * @param {WorkspaceEnrollList} workspaceEnrollList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEnterpriseWorkspacesToAutoEnrollV1(accountId: string, workspaceEnrollList: WorkspaceEnrollList, options?: any): AxiosPromise<WorkspaceAutoEnrollSettings> {
            return localVarFp.removeEnterpriseWorkspacesToAutoEnrollV1(accountId, workspaceEnrollList, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the default status of a workspace for a user as default or not
         * @summary update default status of workspace
         * @param {any} workspaceId 
         * @param {boolean} defaultStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefaultStatusV1(workspaceId: any, defaultStatus: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.updateDefaultStatusV1(workspaceId, defaultStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * Update auto enroll flag for an enterprise account
         * @summary This will control whether new created users will be automatically enrolled to the auto enroll workspace list or not
         * @param {string} accountId 
         * @param {boolean} autoEnrollEnabled 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnterpriseAutoEnrollFlagV1(accountId: string, autoEnrollEnabled: boolean, options?: any): AxiosPromise<EnterpriseOwnedAccount> {
            return localVarFp.updateEnterpriseAutoEnrollFlagV1(accountId, autoEnrollEnabled, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the status of a workspace for a user as favorite or not
         * @summary Set status of workspace favorite
         * @param {any} workspaceId 
         * @param {boolean} favoriteStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFavoriteStatusV1(workspaceId: any, favoriteStatus: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.updateFavoriteStatusV1(workspaceId, favoriteStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Cli Version
         * @param {string} userId 
         * @param {string} cliVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserCLIVersionV1(userId: string, cliVersion: string, options?: any): AxiosPromise<CLIVersionResponse> {
            return localVarFp.updateUserCLIVersionV1(userId, cliVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * Update basic information about as user by their user_id. If the user is not found, API returns a 404 status in the response.
         * @summary Update user information
         * @param {string} userId 
         * @param {BaseUserUpdate} baseUserUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserV1(userId: string, baseUserUpdate: BaseUserUpdate, options?: any): AxiosPromise<BaseUser> {
            return localVarFp.updateUserV1(userId, baseUserUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Update information about a workspace, such as name of the workspace. Certain fields such as workspace id are not allowed to be modified.
         * @summary Update workspace information
         * @param {any} workspaceId 
         * @param {WorkspaceUpdate} workspaceUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceInfoV1(workspaceId: any, workspaceUpdate: WorkspaceUpdate, options?: any): AxiosPromise<Workspace> {
            return localVarFp.updateWorkspaceInfoV1(workspaceId, workspaceUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user workspace membership role. Role states OWNER, MEMBER, or MAINTAINER
         * @summary Update user workspace membership role.
         * @param {string} workspaceId 
         * @param {string} userId 
         * @param {WorkspaceMembershipRoles} workspaceMembershipRole 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceMembershipRoleV1(workspaceId: string, userId: string, workspaceMembershipRole: WorkspaceMembershipRoles, options?: any): AxiosPromise<WorkspaceMembershipRoleUpdateResponse> {
            return localVarFp.updateWorkspaceMembershipRoleV1(workspaceId, userId, workspaceMembershipRole, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all objects matching the given search param. All the artifacts (recordings, datasets), teams, users matching the search param will be returned.The search param can be a partial match of the name of the object. A maximum of 3 results will be returned for each type of object.
         * @summary Get all objects matching the given search param
         * @param {any} workspaceId 
         * @param {any} searchString search string that will be used look for matching entities
         * @param {Array<SearchableEntities>} [searchEntities] List of entities to search
         * @param {number} [limit] Maximum number of results to return for each type of entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceSearchV1(workspaceId: any, searchString: any, searchEntities?: Array<SearchableEntities>, limit?: number, options?: any): AxiosPromise<SearchResults> {
            return localVarFp.workspaceSearchV1(workspaceId, searchString, searchEntities, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserManagementApi - object-oriented interface
 * @export
 * @class UserManagementApi
 * @extends {BaseAPI}
 */
export class UserManagementApi extends BaseAPI {
    /**
     * Add workspaces to the enterprise domain, when new users are onboarded they will automatically be added to the workspaces in auto enroll list
     * @summary Add workspaces to the enterprise domain for which new users will enrolled
     * @param {string} accountId 
     * @param {WorkspaceEnrollList} workspaceEnrollList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public addEnterpriseWorkspacesToAutoEnrollV1(accountId: string, workspaceEnrollList: WorkspaceEnrollList, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).addEnterpriseWorkspacesToAutoEnrollV1(accountId, workspaceEnrollList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns basic information for relevant users for the given list of `user_ids` in the request body.
     * @summary Information about the users in bulk
     * @param {BulkGetUsersRequest} bulkGetUsersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public bulkGetUsersV1(bulkGetUsersRequest: BulkGetUsersRequest, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).bulkGetUsersV1(bulkGetUsersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check if an email is already registered
     * @summary Check Email
     * @param {BodyCheckEmailAvailableV1} bodyCheckEmailAvailableV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public checkEmailAvailableV1(bodyCheckEmailAvailableV1: BodyCheckEmailAvailableV1, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).checkEmailAvailableV1(bodyCheckEmailAvailableV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check if username is unique and return 409 status in the response if username already exists
     * @summary Check if username is unique
     * @param {string} userId 
     * @param {BodyCheckUsernameUniqueV1} bodyCheckUsernameUniqueV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public checkUsernameUniqueV1(userId: string, bodyCheckUsernameUniqueV1: BodyCheckUsernameUniqueV1, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).checkUsernameUniqueV1(userId, bodyCheckUsernameUniqueV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an enterprise workspace and onboard the provided user as admin for this workspace
     * @summary This will create a new user, create a new enterprise workspace and onboard the user as admin into this workspace
     * @param {CreateEnterpriseSettings} createEnterpriseSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public createEnterpriseV1(createEnterpriseSettings: CreateEnterpriseSettings, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).createEnterpriseV1(createEnterpriseSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates an account for a user. A user can independently exist outside any workspace, team or account.
     * @summary Create an account for any new user.
     * @param {BaseUserCreate} baseUserCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public createUserV1(baseUserCreate: BaseUserCreate, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).createUserV1(baseUserCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deactivate membership of a user from the workspace
     * @summary Deactivate membership of a user from the workspace
     * @param {string} workspaceId 
     * @param {DeactivateUsersFromWorkspaceRequest} deactivateUsersFromWorkspaceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public deactivateUserFromWorkspaceV1(workspaceId: string, deactivateUsersFromWorkspaceRequest: DeactivateUsersFromWorkspaceRequest, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).deactivateUserFromWorkspaceV1(workspaceId, deactivateUsersFromWorkspaceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enroll users to workspace. List of users will get enrolled to workspace
     * @summary Enroll users to workspace.
     * @param {string} workspaceId 
     * @param {WorkspaceUserEnrollRequest} workspaceUserEnrollRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public enrollUsersToWorkspaceV1(workspaceId: string, workspaceUserEnrollRequest: WorkspaceUserEnrollRequest, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).enrollUsersToWorkspaceV1(workspaceId, workspaceUserEnrollRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get information about all workspaces
     * @summary Get All Workspace Info
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {Array<AccountOwnerType>} [workspaceAccountTypes] Optional filter workspaces owner account type. Whether the account is         owned by user or an enterprise. If filter isn\&#39;t selected, all types of workspaces would be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getAllWorkspaceInfoListV1(pageNumber?: number, pageSize?: number, workspaceAccountTypes?: Array<AccountOwnerType>, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getAllWorkspaceInfoListV1(pageNumber, pageSize, workspaceAccountTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns basic information about a user and their primary account by their email. If the email is not found, returns a USER_NOT_EXISTS status in the response.
     * @summary Information about a user and accounts by their email
     * @param {BodyGetEmailInfoV1} bodyGetEmailInfoV1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getEmailInfoV1(bodyGetEmailInfoV1: BodyGetEmailInfoV1, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getEmailInfoV1(bodyGetEmailInfoV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List details for all enterprises. Such as enterprise domain, enterprise name, account id and whether auto enroll is enabled. This will help in finding whether enterprise with this domain already exists.
     * @summary List details for all enterprise accounts.
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {Array<string>} [accountIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getEnterpriseOwnedAccountInfoV1(pageNumber?: number, pageSize?: number, accountIds?: Array<string>, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getEnterpriseOwnedAccountInfoV1(pageNumber, pageSize, accountIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns basic information about a user and their primary account by their user_id. If the user is not found, API returns a 404 status in the response.
     * @summary Information about a user and accounts by their user-id
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getUserAndAccountInfoV1(userId: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getUserAndAccountInfoV1(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns basic information about a user by their user_id. If the user is not found, API returns a 404 status in the response.
     * @summary Information about a user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getUserV1(userId: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getUserV1(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all activity performed by users in a workspace. Supported Activities are: 1. Users Added to Workspace Team2. Creation of Datasets3. Model Recordings4. Experiment Recordings
     * @summary Fetch all activity for a workspace
     * @param {any} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getWorkspaceActivityV1(workspaceId: any, start?: number, end?: number, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getWorkspaceActivityV1(workspaceId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all activity performed by users in a particular studio in a workspace.
     * @summary Fetch all activity for a studio in workspace
     * @param {any} workspaceId 
     * @param {StudioIdType} studioId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getWorkspaceActivityV2(workspaceId: any, studioId: StudioIdType, start?: number, end?: number, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getWorkspaceActivityV2(workspaceId, studioId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the base_url of account with a given account_id.
     * @summary Account Base Url
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getWorkspaceBaseUrl(workspaceId: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getWorkspaceBaseUrl(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get information about a workspace
     * @summary Get Workspace Info
     * @param {any} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getWorkspaceInfoV1(workspaceId: any, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getWorkspaceInfoV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get information about a workspace with additional information related to owner accounts, and details of a few members
     * @summary Get Workspace Info With Member Details
     * @param {any} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getWorkspaceInfoWithMemberDetailsV1(workspaceId: any, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getWorkspaceInfoWithMemberDetailsV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a purpose built API to power workspace settings page.
     * @summary API with workspace settings
     * @param {any} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getWorkspaceSettingsV1(workspaceId: any, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getWorkspaceSettingsV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all artifact created by the logged in user in a workspace
     * @summary Fetch all artifact usage for the user in a workspace
     * @param {any} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getWorkspaceUsageInfoV1(workspaceId: any, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getWorkspaceUsageInfoV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get workspaces which will be auto enrolled
     * @summary Getch all workspaces which will be auto enrolled for a given user with enterprise domain
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getWorkspacesInAutoEnrollV1(accountId: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getWorkspacesInAutoEnrollV1(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns basic information about a user, workspaces they are a part of. This can be used to create information for an ID token.
     * @summary Basic information about user and workspace memberships for id tokens.
     * @param {string} userId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public iDTokenInfoV1(userId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).iDTokenInfoV1(userId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of all manually triggered runs for each component. For ex, dataset component would return alldataset comparison runs. Filters can be provided on user_id and component_ids.
     * @summary Get list of manually triggered runs and their statuses
     * @param {string} workspaceId 
     * @param {string} componentType 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [userId] 
     * @param {string} [componentId] 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public listRunsV1(workspaceId: string, componentType: string, start?: number, end?: number, userId?: string, componentId?: string, ts?: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).listRunsV1(workspaceId, componentType, start, end, userId, componentId, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of workspaces that user is a part of. User may be part of more than one workspaces.Workspaces are grouped into personal, enterpeise, and shared workspaces
     * @summary List of workspaces that user is a part of grouped into hierarchy
     * @param {string} userId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public listUserWorkspacesGrouped(userId: string, start?: number, end?: number, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).listUserWorkspacesGrouped(userId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of workspaces that user is a part of. User may be part of more than one workspaces
     * @summary Paginated list of workspaces that user is a part of
     * @param {string} userId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public listUserWorkspacesV1(userId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).listUserWorkspacesV1(userId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of workspaces that user is a part of. User may be part of more than one workspaces. The response object would have additional information related to owner accounts, and details of a few members
     * @summary Paginated list of workspaces that user is a part of
     * @param {string} userId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public listUserWorkspacesWithMemberDetailsV1(userId: string, start?: number, end?: number, ts?: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).listUserWorkspacesWithMemberDetailsV1(userId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all members of a workspace
     * @summary Paginated list of members of a workspace
     * @param {any} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] Timestamp value of the last record returned to the previous response. This is a pivot for pagination. end-start+1 records from this pivot are returned.
     * @param {Array<string>} [teamId] 
     * @param {Array<string>} [workspaceMembershipRole] 
     * @param {string} [userName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public listWorkspaceMembersV1(workspaceId: any, start?: number, end?: number, ts?: string, teamId?: Array<string>, workspaceMembershipRole?: Array<string>, userName?: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).listWorkspaceMembersV1(workspaceId, start, end, ts, teamId, workspaceMembershipRole, userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove workspaces from the enterprise domain
     * @summary Remove workspaces from the enterprise domain, so new users will not be auto enrolled for the specified workspaces
     * @param {string} accountId 
     * @param {WorkspaceEnrollList} workspaceEnrollList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public removeEnterpriseWorkspacesToAutoEnrollV1(accountId: string, workspaceEnrollList: WorkspaceEnrollList, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).removeEnterpriseWorkspacesToAutoEnrollV1(accountId, workspaceEnrollList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the default status of a workspace for a user as default or not
     * @summary update default status of workspace
     * @param {any} workspaceId 
     * @param {boolean} defaultStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public updateDefaultStatusV1(workspaceId: any, defaultStatus: boolean, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).updateDefaultStatusV1(workspaceId, defaultStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update auto enroll flag for an enterprise account
     * @summary This will control whether new created users will be automatically enrolled to the auto enroll workspace list or not
     * @param {string} accountId 
     * @param {boolean} autoEnrollEnabled 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public updateEnterpriseAutoEnrollFlagV1(accountId: string, autoEnrollEnabled: boolean, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).updateEnterpriseAutoEnrollFlagV1(accountId, autoEnrollEnabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the status of a workspace for a user as favorite or not
     * @summary Set status of workspace favorite
     * @param {any} workspaceId 
     * @param {boolean} favoriteStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public updateFavoriteStatusV1(workspaceId: any, favoriteStatus: boolean, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).updateFavoriteStatusV1(workspaceId, favoriteStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Cli Version
     * @param {string} userId 
     * @param {string} cliVersion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public updateUserCLIVersionV1(userId: string, cliVersion: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).updateUserCLIVersionV1(userId, cliVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update basic information about as user by their user_id. If the user is not found, API returns a 404 status in the response.
     * @summary Update user information
     * @param {string} userId 
     * @param {BaseUserUpdate} baseUserUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public updateUserV1(userId: string, baseUserUpdate: BaseUserUpdate, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).updateUserV1(userId, baseUserUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update information about a workspace, such as name of the workspace. Certain fields such as workspace id are not allowed to be modified.
     * @summary Update workspace information
     * @param {any} workspaceId 
     * @param {WorkspaceUpdate} workspaceUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public updateWorkspaceInfoV1(workspaceId: any, workspaceUpdate: WorkspaceUpdate, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).updateWorkspaceInfoV1(workspaceId, workspaceUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user workspace membership role. Role states OWNER, MEMBER, or MAINTAINER
     * @summary Update user workspace membership role.
     * @param {string} workspaceId 
     * @param {string} userId 
     * @param {WorkspaceMembershipRoles} workspaceMembershipRole 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public updateWorkspaceMembershipRoleV1(workspaceId: string, userId: string, workspaceMembershipRole: WorkspaceMembershipRoles, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).updateWorkspaceMembershipRoleV1(workspaceId, userId, workspaceMembershipRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all objects matching the given search param. All the artifacts (recordings, datasets), teams, users matching the search param will be returned.The search param can be a partial match of the name of the object. A maximum of 3 results will be returned for each type of object.
     * @summary Get all objects matching the given search param
     * @param {any} workspaceId 
     * @param {any} searchString search string that will be used look for matching entities
     * @param {Array<SearchableEntities>} [searchEntities] List of entities to search
     * @param {number} [limit] Maximum number of results to return for each type of entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public workspaceSearchV1(workspaceId: any, searchString: any, searchEntities?: Array<SearchableEntities>, limit?: number, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).workspaceSearchV1(workspaceId, searchString, searchEntities, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserProfilesApi - axios parameter creator
 * @export
 */
export const UserProfilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get user\'s public profile. This endpoint is used to get user\'s public profile information. This endpoint isn\'t authenticated.
         * @summary Get user\'s public profile
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfileV1: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserProfileV1', 'userId', userId)
            const localVarPath = `/haifa/v1/user/{user_id}/profile`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update profile information about as user by their user_id. If the user is not found, API returns a 404 status in the response.
         * @summary Update user\'s profile information
         * @param {string} userId 
         * @param {UserProfileUpdateRequest} userProfileUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileV1: async (userId: string, userProfileUpdateRequest: UserProfileUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserProfileV1', 'userId', userId)
            // verify required parameter 'userProfileUpdateRequest' is not null or undefined
            assertParamExists('updateUserProfileV1', 'userProfileUpdateRequest', userProfileUpdateRequest)
            const localVarPath = `/haifa/v1/user/{user_id}/update-profile`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfilesApi - functional programming interface
 * @export
 */
export const UserProfilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfilesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get user\'s public profile. This endpoint is used to get user\'s public profile information. This endpoint isn\'t authenticated.
         * @summary Get user\'s public profile
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProfileV1(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfileV1(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update profile information about as user by their user_id. If the user is not found, API returns a 404 status in the response.
         * @summary Update user\'s profile information
         * @param {string} userId 
         * @param {UserProfileUpdateRequest} userProfileUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfileV1(userId: string, userProfileUpdateRequest: UserProfileUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfileV1(userId, userProfileUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserProfilesApi - factory interface
 * @export
 */
export const UserProfilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfilesApiFp(configuration)
    return {
        /**
         * Get user\'s public profile. This endpoint is used to get user\'s public profile information. This endpoint isn\'t authenticated.
         * @summary Get user\'s public profile
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfileV1(userId: string, options?: any): AxiosPromise<UserProfileResponse> {
            return localVarFp.getUserProfileV1(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update profile information about as user by their user_id. If the user is not found, API returns a 404 status in the response.
         * @summary Update user\'s profile information
         * @param {string} userId 
         * @param {UserProfileUpdateRequest} userProfileUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileV1(userId: string, userProfileUpdateRequest: UserProfileUpdateRequest, options?: any): AxiosPromise<UserProfileUpdateResponse> {
            return localVarFp.updateUserProfileV1(userId, userProfileUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfilesApi - object-oriented interface
 * @export
 * @class UserProfilesApi
 * @extends {BaseAPI}
 */
export class UserProfilesApi extends BaseAPI {
    /**
     * Get user\'s public profile. This endpoint is used to get user\'s public profile information. This endpoint isn\'t authenticated.
     * @summary Get user\'s public profile
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfilesApi
     */
    public getUserProfileV1(userId: string, options?: AxiosRequestConfig) {
        return UserProfilesApiFp(this.configuration).getUserProfileV1(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update profile information about as user by their user_id. If the user is not found, API returns a 404 status in the response.
     * @summary Update user\'s profile information
     * @param {string} userId 
     * @param {UserProfileUpdateRequest} userProfileUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfilesApi
     */
    public updateUserProfileV1(userId: string, userProfileUpdateRequest: UserProfileUpdateRequest, options?: AxiosRequestConfig) {
        return UserProfilesApiFp(this.configuration).updateUserProfileV1(userId, userProfileUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkflowsAPIsApi - axios parameter creator
 * @export
 */
export const WorkflowsAPIsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint fetches the record corresponding to the workflowId and clones the artifact
         * @summary Clones a workflow in draft & published state
         * @param {string} workspaceId 
         * @param {CloneWorkflowRequest} cloneWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneWorkflowsV1: async (workspaceId: string, cloneWorkflowRequest: CloneWorkflowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('cloneWorkflowsV1', 'workspaceId', workspaceId)
            // verify required parameter 'cloneWorkflowRequest' is not null or undefined
            assertParamExists('cloneWorkflowsV1', 'cloneWorkflowRequest', cloneWorkflowRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/clone`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cloneWorkflowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to create a new scheduled workflow in the specified workspace. Allows setting up schedules like CRON, interval, daily, weekly, etc.
         * @summary Create a new Scheduled workflow.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {CreateScheduledWorkflowRequest} createScheduledWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScheduledWorkflowV1: async (workspaceId: string, workflowId: string, createScheduledWorkflowRequest: CreateScheduledWorkflowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createScheduledWorkflowV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('createScheduledWorkflowV1', 'workflowId', workflowId)
            // verify required parameter 'createScheduledWorkflowRequest' is not null or undefined
            assertParamExists('createScheduledWorkflowV1', 'createScheduledWorkflowRequest', createScheduledWorkflowRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/schedule`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createScheduledWorkflowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Workflow App for the specified workflow in the given workspace.
         * @summary Create a Workflow App
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {CreateWorkflowAppModelRequest} createWorkflowAppModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowAppV1: async (workspaceId: string, workflowId: string, createWorkflowAppModelRequest: CreateWorkflowAppModelRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createWorkflowAppV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('createWorkflowAppV1', 'workflowId', workflowId)
            // verify required parameter 'createWorkflowAppModelRequest' is not null or undefined
            assertParamExists('createWorkflowAppV1', 'createWorkflowAppModelRequest', createWorkflowAppModelRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/apps`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkflowAppModelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a workflow debug run if not exist, else updates the existing debug run
         * @summary Create a workflow debug run
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {CreateWorkflowDebugRunRequest} createWorkflowDebugRunRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createWorkflowDebugRunV1: async (workspaceId: string, workflowId: string, createWorkflowDebugRunRequest: CreateWorkflowDebugRunRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createWorkflowDebugRunV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('createWorkflowDebugRunV1', 'workflowId', workflowId)
            // verify required parameter 'createWorkflowDebugRunRequest' is not null or undefined
            assertParamExists('createWorkflowDebugRunV1', 'createWorkflowDebugRunRequest', createWorkflowDebugRunRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/debug-run`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkflowDebugRunRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a workflow runs
         * @summary Create a workflow run
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {CreateWorkflowRunRequest} createWorkflowRunRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowRunV1: async (workspaceId: string, workflowId: string, createWorkflowRunRequest: CreateWorkflowRunRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createWorkflowRunV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('createWorkflowRunV1', 'workflowId', workflowId)
            // verify required parameter 'createWorkflowRunRequest' is not null or undefined
            assertParamExists('createWorkflowRunV1', 'createWorkflowRunRequest', createWorkflowRunRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/workflow-run`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkflowRunRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a workflow
         * @summary Create a workflow
         * @param {string} workspaceId 
         * @param {string} [workflowName] 
         * @param {string} [templateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowV1: async (workspaceId: string, workflowName?: string, templateId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createWorkflowV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflow`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workflowName !== undefined) {
                localVarQueryParameter['workflow_name'] = workflowName;
            }

            if (templateId !== undefined) {
                localVarQueryParameter['template_id'] = templateId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to delete a scheduled workflow in the specified workspace.
         * @summary Delete Scheduled workflow.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduledWorkflowV1: async (workspaceId: string, workflowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteScheduledWorkflowV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('deleteScheduledWorkflowV1', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/schedule`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint fetches all records corresponding to the workflowId and delete all those artifacts
         * @summary Deletes a workflow in draft state
         * @param {string} workspaceId 
         * @param {DeleteWorkflowsRequest} deleteWorkflowsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowsV1: async (workspaceId: string, deleteWorkflowsRequest: DeleteWorkflowsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteWorkflowsV1', 'workspaceId', workspaceId)
            // verify required parameter 'deleteWorkflowsRequest' is not null or undefined
            assertParamExists('deleteWorkflowsV1', 'deleteWorkflowsRequest', deleteWorkflowsRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/delete`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteWorkflowsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is to give the download link of a workflow, esp if available through a file sink such as S3
         * @summary Get a download link for a workflow\'s output.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWorkflowNodeOutputV1: async (workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('downloadWorkflowNodeOutputV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('downloadWorkflowNodeOutputV1', 'workflowId', workflowId)
            // verify required parameter 'workflowRunId' is not null or undefined
            assertParamExists('downloadWorkflowNodeOutputV1', 'workflowRunId', workflowRunId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('downloadWorkflowNodeOutputV1', 'nodeId', nodeId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/runs/{workflow_run_id}/node/{node_id}/download-output`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"workflow_run_id"}}`, encodeURIComponent(String(workflowRunId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [Deprecated as wflow can have multiple outputs, use the DownloadWorkflowNodeOutputV1 API] This API is to give the download link of a workflow, esp if available through a file sink such as S3
         * @summary Get a download link for a workflow\'s output.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        downloadWorkflowOutputV1: async (workspaceId: string, workflowId: string, workflowRunId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('downloadWorkflowOutputV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('downloadWorkflowOutputV1', 'workflowId', workflowId)
            // verify required parameter 'workflowRunId' is not null or undefined
            assertParamExists('downloadWorkflowOutputV1', 'workflowRunId', workflowRunId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/runs/{workflow_run_id}/download-output`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"workflow_run_id"}}`, encodeURIComponent(String(workflowRunId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint fetches the DAG corresponding to the workflow. This would be                     called when we want to persist DAG in FE, and while rendering DAG from a template
         * @summary Fetch DAG in Workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDAGsV1: async (workspaceId: string, workflowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('fetchDAGsV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('fetchDAGsV1', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/dag`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get unique email id for this workflow to send email triggers from
         * @summary Get unique email id for this workflow to send email triggers from
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTriggerEmailV1: async (workspaceId: string, workflowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getTriggerEmailV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getTriggerEmailV1', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/get-trigger-email`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the details of a specific workflow within the given workspace.
         * @summary Get Workflow App Details
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowAppDetailsByWorkflowIdV1: async (workspaceId: string, workflowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkflowAppDetailsByWorkflowIdV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getWorkflowAppDetailsByWorkflowIdV1', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/apps`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the details of a specific workflow app within the given workspace.
         * @summary Get Workflow App Details
         * @param {string} workspaceId 
         * @param {string} workflowAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowAppDetailsV1: async (workspaceId: string, workflowAppId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkflowAppDetailsV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowAppId' is not null or undefined
            assertParamExists('getWorkflowAppDetailsV1', 'workflowAppId', workflowAppId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/apps/{workflow_app_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_app_id"}}`, encodeURIComponent(String(workflowAppId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint fetches the input and output column schema for all the nodes of a dag for a given workflow
         * @summary Fetch the workflow dag nodes io schema. This is deprecated, please use v2 version of this API
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getWorkflowDagNodesSchemaV1: async (workspaceId: string, workflowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkflowDagNodesSchemaV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getWorkflowDagNodesSchemaV1', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/dag-nodes-schema`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint fetches the input and output column schema for all the nodes of a dag for a given workflow
         * @summary Fetch the workflow dag nodes io schema
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} [workflowRunId] 
         * @param {string} [nodeId] 
         * @param {string} [workflowAppId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowDagNodesSchemaV2: async (workspaceId: string, workflowId: string, workflowRunId?: string, nodeId?: string, workflowAppId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkflowDagNodesSchemaV2', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getWorkflowDagNodesSchemaV2', 'workflowId', workflowId)
            const localVarPath = `/vienna/v2/workspace/{workspace_id}/workflows/{workflow_id}/dag-nodes-schema`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workflowRunId !== undefined) {
                localVarQueryParameter['workflow_run_id'] = workflowRunId;
            }

            if (nodeId !== undefined) {
                localVarQueryParameter['node_id'] = nodeId;
            }

            if (workflowAppId !== undefined) {
                localVarQueryParameter['workflow_app_id'] = workflowAppId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all supported filters for workflows of a workspace. Deprecated: Use WorkflowListingFiltersV1
         * @summary Returns all workflows filters.
         * @param {string} [workspaceId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getWorkflowListingFiltersV1: async (workspaceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vienna/workflow-filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspace_id'] = workspaceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the credit usage of a workflow run with an operator level breakdown.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {boolean} [mock] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowRunCreditUsageV1: async (workspaceId: string, workflowId: string, workflowRunId: string, mock?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkflowRunCreditUsageV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getWorkflowRunCreditUsageV1', 'workflowId', workflowId)
            // verify required parameter 'workflowRunId' is not null or undefined
            assertParamExists('getWorkflowRunCreditUsageV1', 'workflowRunId', workflowRunId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/runs/{workflow_run_id}/credit-usage`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"workflow_run_id"}}`, encodeURIComponent(String(workflowRunId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mock !== undefined) {
                localVarQueryParameter['mock'] = mock;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the logfile of a workflow node for a given run and it\'s node.This is not an optimised API, prefer using the companion streaming API GetWorkflowRunNodeLogStreamV1.
         * @summary Get the logfile of a workflow node as a streaming response
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowRunNodeLogFileV1: async (workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkflowRunNodeLogFileV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getWorkflowRunNodeLogFileV1', 'workflowId', workflowId)
            // verify required parameter 'workflowRunId' is not null or undefined
            assertParamExists('getWorkflowRunNodeLogFileV1', 'workflowRunId', workflowRunId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('getWorkflowRunNodeLogFileV1', 'nodeId', nodeId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/runs/{workflow_run_id}/node/{node_id}/log-file`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"workflow_run_id"}}`, encodeURIComponent(String(workflowRunId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the logfile stream of a workflow node for a given run and it\'s node
         * @summary Get the logfile of a workflow node as a streaming response
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowRunNodeLogStreamV1: async (workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkflowRunNodeLogStreamV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getWorkflowRunNodeLogStreamV1', 'workflowId', workflowId)
            // verify required parameter 'workflowRunId' is not null or undefined
            assertParamExists('getWorkflowRunNodeLogStreamV1', 'workflowRunId', workflowRunId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('getWorkflowRunNodeLogStreamV1', 'nodeId', nodeId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/runs/{workflow_run_id}/node/{node_id}/log-file-stream`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"workflow_run_id"}}`, encodeURIComponent(String(workflowRunId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the preview of data after a node executes, to be used during debug. This is not applicable for full runs.
         * @summary Get the preview of data after a node executes, to be used during debug.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {string} nodeId 
         * @param {string} operatorCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowRunNodeOutputV1: async (workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, operatorCategory: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkflowRunNodeOutputV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getWorkflowRunNodeOutputV1', 'workflowId', workflowId)
            // verify required parameter 'workflowRunId' is not null or undefined
            assertParamExists('getWorkflowRunNodeOutputV1', 'workflowRunId', workflowRunId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('getWorkflowRunNodeOutputV1', 'nodeId', nodeId)
            // verify required parameter 'operatorCategory' is not null or undefined
            assertParamExists('getWorkflowRunNodeOutputV1', 'operatorCategory', operatorCategory)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/runs/{workflow_run_id}/node/{node_id}/node-output-preview`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"workflow_run_id"}}`, encodeURIComponent(String(workflowRunId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (operatorCategory !== undefined) {
                localVarQueryParameter['operator_category'] = operatorCategory;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get workflow run status with operators wise status details as well for a given workflow run id
         * @summary Get workflow run status for a given workflow run id
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowRunStatusV1: async (workspaceId: string, workflowId: string, workflowRunId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkflowRunStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getWorkflowRunStatusV1', 'workflowId', workflowId)
            // verify required parameter 'workflowRunId' is not null or undefined
            assertParamExists('getWorkflowRunStatusV1', 'workflowRunId', workflowRunId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/runs/{workflow_run_id}/status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"workflow_run_id"}}`, encodeURIComponent(String(workflowRunId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all details of a run given run id for a workflow
         * @summary Get all details of a run given run id for a workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowRunV1: async (workspaceId: string, workflowId: string, workflowRunId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkflowRunV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getWorkflowRunV1', 'workflowId', workflowId)
            // verify required parameter 'workflowRunId' is not null or undefined
            assertParamExists('getWorkflowRunV1', 'workflowRunId', workflowRunId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/runs/{workflow_run_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"workflow_run_id"}}`, encodeURIComponent(String(workflowRunId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all supported filters for workflows run of a workspace.
         * @summary Returns workflow runs  filters.
         * @param {string} [workspaceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowRunsListingFiltersV1: async (workspaceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vienna/workflow-run-filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspace_id'] = workspaceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API fetches a workflow by it\'s ID. If not found, returns a 404
         * @summary Get Workflow by ID
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowV1: async (workspaceId: string, workflowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkflowV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getWorkflowV1', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of all workflow apps for the given workspace.
         * @summary List Workflow Apps
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflowAppsV1: async (workspaceId: string, listingAPIParams: ListingAPIParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listWorkflowAppsV1', 'workspaceId', workspaceId)
            // verify required parameter 'listingAPIParams' is not null or undefined
            assertParamExists('listWorkflowAppsV1', 'listingAPIParams', listingAPIParams)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/apps`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingAPIParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of runs for a workflow
         * @summary Get list of runs for a workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflowRunsV1: async (workspaceId: string, workflowId: string, listingAPIParams: ListingAPIParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listWorkflowRunsV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('listWorkflowRunsV1', 'workflowId', workflowId)
            // verify required parameter 'listingAPIParams' is not null or undefined
            assertParamExists('listWorkflowRunsV1', 'listingAPIParams', listingAPIParams)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/runs`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingAPIParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of workflows
         * @summary Get list of workflows
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflowsV1: async (workspaceId: string, listingAPIParams: ListingAPIParams, searchQuery?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listWorkflowsV1', 'workspaceId', workspaceId)
            // verify required parameter 'listingAPIParams' is not null or undefined
            assertParamExists('listWorkflowsV1', 'listingAPIParams', listingAPIParams)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchQuery !== undefined) {
                localVarQueryParameter['search_query'] = searchQuery;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingAPIParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Publish the workflow along with setting trigger
         * @summary Publish the workflow along with setting trigger
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishTriggerWorkflowV1: async (workspaceId: string, workflowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('publishTriggerWorkflowV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('publishTriggerWorkflowV1', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/publish-with-trigger`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Publish a workflow, and optionally, start its execution.     This action transitions the workflow to ACTIVE state. The caller can also request immediate execution of the workflow.
         * @summary Publish a workflow, and optionally, start its execution
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {boolean} [execute] 
         * @param {string} [workflowName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishWorkflowV1: async (workspaceId: string, workflowId: string, execute?: boolean, workflowName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('publishWorkflowV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('publishWorkflowV1', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/publish`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (execute !== undefined) {
                localVarQueryParameter['execute'] = execute;
            }

            if (workflowName !== undefined) {
                localVarQueryParameter['workflow_name'] = workflowName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint stops a currently running workflow and updates its status accordingly. It ensures that all associated processes are properly terminated.
         * @summary Stops a running workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopWorkflowRunV1: async (workspaceId: string, workflowId: string, workflowRunId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('stopWorkflowRunV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('stopWorkflowRunV1', 'workflowId', workflowId)
            // verify required parameter 'workflowRunId' is not null or undefined
            assertParamExists('stopWorkflowRunV1', 'workflowRunId', workflowRunId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/runs/{workflow_run_id}/stop`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"workflow_run_id"}}`, encodeURIComponent(String(workflowRunId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint enables the update or insertion of Directed Acyclic Graphs (DAGs) within a specific                     workflow in a designated workspace. The DAG configuration provided in the request will overwrite                     the existing record, allowing for dynamic updates to the structure of the workflow.
         * @summary Upsert DAGs in Workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {UpsertDAGRequest} upsertDAGRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDAGsV1: async (workspaceId: string, workflowId: string, upsertDAGRequest: UpsertDAGRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateDAGsV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('updateDAGsV1', 'workflowId', workflowId)
            // verify required parameter 'upsertDAGRequest' is not null or undefined
            assertParamExists('updateDAGsV1', 'upsertDAGRequest', upsertDAGRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/dag`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertDAGRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update scheduled workflow status to Enabled/Disabled
         * @summary Update scheduled workflow status to Enabled/Disabled
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {WorkflowStatus} [status] The new status of the scheduled workflow, either \&#39;ENABLED\&#39; or \&#39;DISABLED\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScheduledWorkflowStateV1: async (workspaceId: string, workflowId: string, status?: WorkflowStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateScheduledWorkflowStateV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('updateScheduledWorkflowStateV1', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/schedule-status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to update the schedule details of a scheduled workflow in the specified workspace. Allows updating the schedules like CRON, interval, daily, weekly, etc.
         * @summary Update schedule details of a Scheduled workflow.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {UpdateScheduledWorkflowRequest} updateScheduledWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScheduledWorkflowV1: async (workspaceId: string, workflowId: string, updateScheduledWorkflowRequest: UpdateScheduledWorkflowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateScheduledWorkflowV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('updateScheduledWorkflowV1', 'workflowId', workflowId)
            // verify required parameter 'updateScheduledWorkflowRequest' is not null or undefined
            assertParamExists('updateScheduledWorkflowV1', 'updateScheduledWorkflowRequest', updateScheduledWorkflowRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/update-schedule`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateScheduledWorkflowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the specified workflow app in the given workspace.
         * @summary Update Workflow App
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {UpdateWorkflowAppDAGRequest} updateWorkflowAppDAGRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowAppV1: async (workspaceId: string, workflowId: string, updateWorkflowAppDAGRequest: UpdateWorkflowAppDAGRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateWorkflowAppV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('updateWorkflowAppV1', 'workflowId', workflowId)
            // verify required parameter 'updateWorkflowAppDAGRequest' is not null or undefined
            assertParamExists('updateWorkflowAppV1', 'updateWorkflowAppDAGRequest', updateWorkflowAppDAGRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}/apps`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkflowAppDAGRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint modifies workflow attributes like name, description, status                     of the given workflow
         * @summary Update Workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {UpdateWorkflowRequest} updateWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowV1: async (workspaceId: string, workflowId: string, updateWorkflowRequest: UpdateWorkflowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateWorkflowV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('updateWorkflowV1', 'workflowId', workflowId)
            // verify required parameter 'updateWorkflowRequest' is not null or undefined
            assertParamExists('updateWorkflowV1', 'updateWorkflowRequest', updateWorkflowRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflows/{workflow_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkflowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all supported filters for workflows of a workspace.
         * @summary Returns all workflows filters.
         * @param {string} [workspaceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowListingFiltersV1: async (workspaceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vienna/workflow-filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspace_id'] = workspaceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update state of workflow resource (Active, Draft, Archive) Args:     background_tasks:     update_request:         list{workflow_id}: list of workflow ids to be modified         artifact_state: ARCHIVED/ACTIVE/DRAFT     workspace_id:     user:  Returns:
         * @summary Update workflows artifact state
         * @param {string} workspaceId 
         * @param {UpdateWorkflowArtifactStateRequest} updateWorkflowArtifactStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowStateChangeV1: async (workspaceId: string, updateWorkflowArtifactStateRequest: UpdateWorkflowArtifactStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workflowStateChangeV1', 'workspaceId', workspaceId)
            // verify required parameter 'updateWorkflowArtifactStateRequest' is not null or undefined
            assertParamExists('workflowStateChangeV1', 'updateWorkflowArtifactStateRequest', updateWorkflowArtifactStateRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflow/state`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkflowArtifactStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowsAPIsApi - functional programming interface
 * @export
 */
export const WorkflowsAPIsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkflowsAPIsApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint fetches the record corresponding to the workflowId and clones the artifact
         * @summary Clones a workflow in draft & published state
         * @param {string} workspaceId 
         * @param {CloneWorkflowRequest} cloneWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneWorkflowsV1(workspaceId: string, cloneWorkflowRequest: CloneWorkflowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CloneWorkflowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloneWorkflowsV1(workspaceId, cloneWorkflowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to create a new scheduled workflow in the specified workspace. Allows setting up schedules like CRON, interval, daily, weekly, etc.
         * @summary Create a new Scheduled workflow.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {CreateScheduledWorkflowRequest} createScheduledWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScheduledWorkflowV1(workspaceId: string, workflowId: string, createScheduledWorkflowRequest: CreateScheduledWorkflowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateScheduledWorkflowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScheduledWorkflowV1(workspaceId, workflowId, createScheduledWorkflowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a Workflow App for the specified workflow in the given workspace.
         * @summary Create a Workflow App
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {CreateWorkflowAppModelRequest} createWorkflowAppModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkflowAppV1(workspaceId: string, workflowId: string, createWorkflowAppModelRequest: CreateWorkflowAppModelRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWorkflowAppModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkflowAppV1(workspaceId, workflowId, createWorkflowAppModelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a workflow debug run if not exist, else updates the existing debug run
         * @summary Create a workflow debug run
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {CreateWorkflowDebugRunRequest} createWorkflowDebugRunRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createWorkflowDebugRunV1(workspaceId: string, workflowId: string, createWorkflowDebugRunRequest: CreateWorkflowDebugRunRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWorkflowRunResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkflowDebugRunV1(workspaceId, workflowId, createWorkflowDebugRunRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a workflow runs
         * @summary Create a workflow run
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {CreateWorkflowRunRequest} createWorkflowRunRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkflowRunV1(workspaceId: string, workflowId: string, createWorkflowRunRequest: CreateWorkflowRunRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWorkflowRunResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkflowRunV1(workspaceId, workflowId, createWorkflowRunRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a workflow
         * @summary Create a workflow
         * @param {string} workspaceId 
         * @param {string} [workflowName] 
         * @param {string} [templateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkflowV1(workspaceId: string, workflowName?: string, templateId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWorkflowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkflowV1(workspaceId, workflowName, templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to delete a scheduled workflow in the specified workspace.
         * @summary Delete Scheduled workflow.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScheduledWorkflowV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteScheduledWorkflowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScheduledWorkflowV1(workspaceId, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint fetches all records corresponding to the workflowId and delete all those artifacts
         * @summary Deletes a workflow in draft state
         * @param {string} workspaceId 
         * @param {DeleteWorkflowsRequest} deleteWorkflowsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkflowsV1(workspaceId: string, deleteWorkflowsRequest: DeleteWorkflowsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteWorkflowsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkflowsV1(workspaceId, deleteWorkflowsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API is to give the download link of a workflow, esp if available through a file sink such as S3
         * @summary Get a download link for a workflow\'s output.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadWorkflowNodeOutputV1(workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowRunOutputDownloadLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadWorkflowNodeOutputV1(workspaceId, workflowId, workflowRunId, nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * [Deprecated as wflow can have multiple outputs, use the DownloadWorkflowNodeOutputV1 API] This API is to give the download link of a workflow, esp if available through a file sink such as S3
         * @summary Get a download link for a workflow\'s output.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async downloadWorkflowOutputV1(workspaceId: string, workflowId: string, workflowRunId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowRunOutputDownloadLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadWorkflowOutputV1(workspaceId, workflowId, workflowRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint fetches the DAG corresponding to the workflow. This would be                     called when we want to persist DAG in FE, and while rendering DAG from a template
         * @summary Fetch DAG in Workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchDAGsV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDAGResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchDAGsV1(workspaceId, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get unique email id for this workflow to send email triggers from
         * @summary Get unique email id for this workflow to send email triggers from
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTriggerEmailV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTriggerEmailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTriggerEmailV1(workspaceId, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the details of a specific workflow within the given workspace.
         * @summary Get Workflow App Details
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowAppDetailsByWorkflowIdV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWorkflowAppDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowAppDetailsByWorkflowIdV1(workspaceId, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the details of a specific workflow app within the given workspace.
         * @summary Get Workflow App Details
         * @param {string} workspaceId 
         * @param {string} workflowAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowAppDetailsV1(workspaceId: string, workflowAppId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWorkflowAppDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowAppDetailsV1(workspaceId, workflowAppId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint fetches the input and output column schema for all the nodes of a dag for a given workflow
         * @summary Fetch the workflow dag nodes io schema. This is deprecated, please use v2 version of this API
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getWorkflowDagNodesSchemaV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWorkflowDagNodesSchemaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowDagNodesSchemaV1(workspaceId, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint fetches the input and output column schema for all the nodes of a dag for a given workflow
         * @summary Fetch the workflow dag nodes io schema
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} [workflowRunId] 
         * @param {string} [nodeId] 
         * @param {string} [workflowAppId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowDagNodesSchemaV2(workspaceId: string, workflowId: string, workflowRunId?: string, nodeId?: string, workflowAppId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowNodeSchemaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowDagNodesSchemaV2(workspaceId, workflowId, workflowRunId, nodeId, workflowAppId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all supported filters for workflows of a workspace. Deprecated: Use WorkflowListingFiltersV1
         * @summary Returns all workflows filters.
         * @param {string} [workspaceId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getWorkflowListingFiltersV1(workspaceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllowedFilters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowListingFiltersV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the credit usage of a workflow run with an operator level breakdown.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {boolean} [mock] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowRunCreditUsageV1(workspaceId: string, workflowId: string, workflowRunId: string, mock?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCreditUsageForWorkflowRunResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowRunCreditUsageV1(workspaceId, workflowId, workflowRunId, mock, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the logfile of a workflow node for a given run and it\'s node.This is not an optimised API, prefer using the companion streaming API GetWorkflowRunNodeLogStreamV1.
         * @summary Get the logfile of a workflow node as a streaming response
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowRunNodeLogFileV1(workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowRunNodeLogFileV1(workspaceId, workflowId, workflowRunId, nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the logfile stream of a workflow node for a given run and it\'s node
         * @summary Get the logfile of a workflow node as a streaming response
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowRunNodeLogStreamV1(workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowRunNodeLogStreamV1(workspaceId, workflowId, workflowRunId, nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the preview of data after a node executes, to be used during debug. This is not applicable for full runs.
         * @summary Get the preview of data after a node executes, to be used during debug.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {string} nodeId 
         * @param {string} operatorCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowRunNodeOutputV1(workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, operatorCategory: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowRunNodeOutputV1(workspaceId, workflowId, workflowRunId, nodeId, operatorCategory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get workflow run status with operators wise status details as well for a given workflow run id
         * @summary Get workflow run status for a given workflow run id
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowRunStatusV1(workspaceId: string, workflowId: string, workflowRunId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWorkflowRunStatusDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowRunStatusV1(workspaceId, workflowId, workflowRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all details of a run given run id for a workflow
         * @summary Get all details of a run given run id for a workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowRunV1(workspaceId: string, workflowId: string, workflowRunId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowRun>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowRunV1(workspaceId, workflowId, workflowRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all supported filters for workflows run of a workspace.
         * @summary Returns workflow runs  filters.
         * @param {string} [workspaceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowRunsListingFiltersV1(workspaceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllowedFilters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowRunsListingFiltersV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API fetches a workflow by it\'s ID. If not found, returns a 404
         * @summary Get Workflow by ID
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowV1(workspaceId, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of all workflow apps for the given workspace.
         * @summary List Workflow Apps
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWorkflowAppsV1(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWorkflowAppsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWorkflowAppsV1(workspaceId, listingAPIParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of runs for a workflow
         * @summary Get list of runs for a workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWorkflowRunsV1(workspaceId: string, workflowId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWorkflowRunsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWorkflowRunsV1(workspaceId, workflowId, listingAPIParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of workflows
         * @summary Get list of workflows
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWorkflowsV1(workspaceId: string, listingAPIParams: ListingAPIParams, searchQuery?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWorkflowsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWorkflowsV1(workspaceId, listingAPIParams, searchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Publish the workflow along with setting trigger
         * @summary Publish the workflow along with setting trigger
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishTriggerWorkflowV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishTriggerWorkflowV1(workspaceId, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Publish a workflow, and optionally, start its execution.     This action transitions the workflow to ACTIVE state. The caller can also request immediate execution of the workflow.
         * @summary Publish a workflow, and optionally, start its execution
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {boolean} [execute] 
         * @param {string} [workflowName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishWorkflowV1(workspaceId: string, workflowId: string, execute?: boolean, workflowName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishWorkflowV1(workspaceId, workflowId, execute, workflowName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint stops a currently running workflow and updates its status accordingly. It ensures that all associated processes are properly terminated.
         * @summary Stops a running workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopWorkflowRunV1(workspaceId: string, workflowId: string, workflowRunId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopWorkflowRunV1(workspaceId, workflowId, workflowRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint enables the update or insertion of Directed Acyclic Graphs (DAGs) within a specific                     workflow in a designated workspace. The DAG configuration provided in the request will overwrite                     the existing record, allowing for dynamic updates to the structure of the workflow.
         * @summary Upsert DAGs in Workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {UpsertDAGRequest} upsertDAGRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDAGsV1(workspaceId: string, workflowId: string, upsertDAGRequest: UpsertDAGRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertDagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDAGsV1(workspaceId, workflowId, upsertDAGRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update scheduled workflow status to Enabled/Disabled
         * @summary Update scheduled workflow status to Enabled/Disabled
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {WorkflowStatus} [status] The new status of the scheduled workflow, either \&#39;ENABLED\&#39; or \&#39;DISABLED\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateScheduledWorkflowStateV1(workspaceId: string, workflowId: string, status?: WorkflowStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateScheduledWorkflowStateV1(workspaceId, workflowId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to update the schedule details of a scheduled workflow in the specified workspace. Allows updating the schedules like CRON, interval, daily, weekly, etc.
         * @summary Update schedule details of a Scheduled workflow.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {UpdateScheduledWorkflowRequest} updateScheduledWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateScheduledWorkflowV1(workspaceId: string, workflowId: string, updateScheduledWorkflowRequest: UpdateScheduledWorkflowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateScheduledWorkflowV1(workspaceId, workflowId, updateScheduledWorkflowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the specified workflow app in the given workspace.
         * @summary Update Workflow App
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {UpdateWorkflowAppDAGRequest} updateWorkflowAppDAGRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkflowAppV1(workspaceId: string, workflowId: string, updateWorkflowAppDAGRequest: UpdateWorkflowAppDAGRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateWorkflowAppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkflowAppV1(workspaceId, workflowId, updateWorkflowAppDAGRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint modifies workflow attributes like name, description, status                     of the given workflow
         * @summary Update Workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {UpdateWorkflowRequest} updateWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkflowV1(workspaceId: string, workflowId: string, updateWorkflowRequest: UpdateWorkflowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkflowV1(workspaceId, workflowId, updateWorkflowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all supported filters for workflows of a workspace.
         * @summary Returns all workflows filters.
         * @param {string} [workspaceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workflowListingFiltersV1(workspaceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllowedFilters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workflowListingFiltersV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update state of workflow resource (Active, Draft, Archive) Args:     background_tasks:     update_request:         list{workflow_id}: list of workflow ids to be modified         artifact_state: ARCHIVED/ACTIVE/DRAFT     workspace_id:     user:  Returns:
         * @summary Update workflows artifact state
         * @param {string} workspaceId 
         * @param {UpdateWorkflowArtifactStateRequest} updateWorkflowArtifactStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workflowStateChangeV1(workspaceId: string, updateWorkflowArtifactStateRequest: UpdateWorkflowArtifactStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workflowStateChangeV1(workspaceId, updateWorkflowArtifactStateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkflowsAPIsApi - factory interface
 * @export
 */
export const WorkflowsAPIsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkflowsAPIsApiFp(configuration)
    return {
        /**
         * This endpoint fetches the record corresponding to the workflowId and clones the artifact
         * @summary Clones a workflow in draft & published state
         * @param {string} workspaceId 
         * @param {CloneWorkflowRequest} cloneWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneWorkflowsV1(workspaceId: string, cloneWorkflowRequest: CloneWorkflowRequest, options?: any): AxiosPromise<CloneWorkflowResponse> {
            return localVarFp.cloneWorkflowsV1(workspaceId, cloneWorkflowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to create a new scheduled workflow in the specified workspace. Allows setting up schedules like CRON, interval, daily, weekly, etc.
         * @summary Create a new Scheduled workflow.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {CreateScheduledWorkflowRequest} createScheduledWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScheduledWorkflowV1(workspaceId: string, workflowId: string, createScheduledWorkflowRequest: CreateScheduledWorkflowRequest, options?: any): AxiosPromise<CreateScheduledWorkflowResponse> {
            return localVarFp.createScheduledWorkflowV1(workspaceId, workflowId, createScheduledWorkflowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a Workflow App for the specified workflow in the given workspace.
         * @summary Create a Workflow App
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {CreateWorkflowAppModelRequest} createWorkflowAppModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowAppV1(workspaceId: string, workflowId: string, createWorkflowAppModelRequest: CreateWorkflowAppModelRequest, options?: any): AxiosPromise<CreateWorkflowAppModelResponse> {
            return localVarFp.createWorkflowAppV1(workspaceId, workflowId, createWorkflowAppModelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a workflow debug run if not exist, else updates the existing debug run
         * @summary Create a workflow debug run
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {CreateWorkflowDebugRunRequest} createWorkflowDebugRunRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createWorkflowDebugRunV1(workspaceId: string, workflowId: string, createWorkflowDebugRunRequest: CreateWorkflowDebugRunRequest, options?: any): AxiosPromise<CreateWorkflowRunResponse> {
            return localVarFp.createWorkflowDebugRunV1(workspaceId, workflowId, createWorkflowDebugRunRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a workflow runs
         * @summary Create a workflow run
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {CreateWorkflowRunRequest} createWorkflowRunRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowRunV1(workspaceId: string, workflowId: string, createWorkflowRunRequest: CreateWorkflowRunRequest, options?: any): AxiosPromise<CreateWorkflowRunResponse> {
            return localVarFp.createWorkflowRunV1(workspaceId, workflowId, createWorkflowRunRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a workflow
         * @summary Create a workflow
         * @param {string} workspaceId 
         * @param {string} [workflowName] 
         * @param {string} [templateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowV1(workspaceId: string, workflowName?: string, templateId?: string, options?: any): AxiosPromise<CreateWorkflowResponse> {
            return localVarFp.createWorkflowV1(workspaceId, workflowName, templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to delete a scheduled workflow in the specified workspace.
         * @summary Delete Scheduled workflow.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduledWorkflowV1(workspaceId: string, workflowId: string, options?: any): AxiosPromise<DeleteScheduledWorkflowResponse> {
            return localVarFp.deleteScheduledWorkflowV1(workspaceId, workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint fetches all records corresponding to the workflowId and delete all those artifacts
         * @summary Deletes a workflow in draft state
         * @param {string} workspaceId 
         * @param {DeleteWorkflowsRequest} deleteWorkflowsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowsV1(workspaceId: string, deleteWorkflowsRequest: DeleteWorkflowsRequest, options?: any): AxiosPromise<DeleteWorkflowsResponse> {
            return localVarFp.deleteWorkflowsV1(workspaceId, deleteWorkflowsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is to give the download link of a workflow, esp if available through a file sink such as S3
         * @summary Get a download link for a workflow\'s output.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWorkflowNodeOutputV1(workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, options?: any): AxiosPromise<WorkflowRunOutputDownloadLink> {
            return localVarFp.downloadWorkflowNodeOutputV1(workspaceId, workflowId, workflowRunId, nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * [Deprecated as wflow can have multiple outputs, use the DownloadWorkflowNodeOutputV1 API] This API is to give the download link of a workflow, esp if available through a file sink such as S3
         * @summary Get a download link for a workflow\'s output.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        downloadWorkflowOutputV1(workspaceId: string, workflowId: string, workflowRunId: string, options?: any): AxiosPromise<WorkflowRunOutputDownloadLink> {
            return localVarFp.downloadWorkflowOutputV1(workspaceId, workflowId, workflowRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint fetches the DAG corresponding to the workflow. This would be                     called when we want to persist DAG in FE, and while rendering DAG from a template
         * @summary Fetch DAG in Workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDAGsV1(workspaceId: string, workflowId: string, options?: any): AxiosPromise<GetDAGResponse> {
            return localVarFp.fetchDAGsV1(workspaceId, workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get unique email id for this workflow to send email triggers from
         * @summary Get unique email id for this workflow to send email triggers from
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTriggerEmailV1(workspaceId: string, workflowId: string, options?: any): AxiosPromise<GetTriggerEmailResponse> {
            return localVarFp.getTriggerEmailV1(workspaceId, workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the details of a specific workflow within the given workspace.
         * @summary Get Workflow App Details
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowAppDetailsByWorkflowIdV1(workspaceId: string, workflowId: string, options?: any): AxiosPromise<GetWorkflowAppDetailResponse> {
            return localVarFp.getWorkflowAppDetailsByWorkflowIdV1(workspaceId, workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the details of a specific workflow app within the given workspace.
         * @summary Get Workflow App Details
         * @param {string} workspaceId 
         * @param {string} workflowAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowAppDetailsV1(workspaceId: string, workflowAppId: string, options?: any): AxiosPromise<GetWorkflowAppDetailResponse> {
            return localVarFp.getWorkflowAppDetailsV1(workspaceId, workflowAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint fetches the input and output column schema for all the nodes of a dag for a given workflow
         * @summary Fetch the workflow dag nodes io schema. This is deprecated, please use v2 version of this API
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getWorkflowDagNodesSchemaV1(workspaceId: string, workflowId: string, options?: any): AxiosPromise<GetWorkflowDagNodesSchemaResponse> {
            return localVarFp.getWorkflowDagNodesSchemaV1(workspaceId, workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint fetches the input and output column schema for all the nodes of a dag for a given workflow
         * @summary Fetch the workflow dag nodes io schema
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} [workflowRunId] 
         * @param {string} [nodeId] 
         * @param {string} [workflowAppId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowDagNodesSchemaV2(workspaceId: string, workflowId: string, workflowRunId?: string, nodeId?: string, workflowAppId?: string, options?: any): AxiosPromise<WorkflowNodeSchemaResponse> {
            return localVarFp.getWorkflowDagNodesSchemaV2(workspaceId, workflowId, workflowRunId, nodeId, workflowAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all supported filters for workflows of a workspace. Deprecated: Use WorkflowListingFiltersV1
         * @summary Returns all workflows filters.
         * @param {string} [workspaceId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getWorkflowListingFiltersV1(workspaceId?: string, options?: any): AxiosPromise<AllowedFilters> {
            return localVarFp.getWorkflowListingFiltersV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the credit usage of a workflow run with an operator level breakdown.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {boolean} [mock] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowRunCreditUsageV1(workspaceId: string, workflowId: string, workflowRunId: string, mock?: boolean, options?: any): AxiosPromise<GetCreditUsageForWorkflowRunResponse> {
            return localVarFp.getWorkflowRunCreditUsageV1(workspaceId, workflowId, workflowRunId, mock, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the logfile of a workflow node for a given run and it\'s node.This is not an optimised API, prefer using the companion streaming API GetWorkflowRunNodeLogStreamV1.
         * @summary Get the logfile of a workflow node as a streaming response
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowRunNodeLogFileV1(workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getWorkflowRunNodeLogFileV1(workspaceId, workflowId, workflowRunId, nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the logfile stream of a workflow node for a given run and it\'s node
         * @summary Get the logfile of a workflow node as a streaming response
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {string} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowRunNodeLogStreamV1(workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getWorkflowRunNodeLogStreamV1(workspaceId, workflowId, workflowRunId, nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the preview of data after a node executes, to be used during debug. This is not applicable for full runs.
         * @summary Get the preview of data after a node executes, to be used during debug.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {string} nodeId 
         * @param {string} operatorCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowRunNodeOutputV1(workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, operatorCategory: string, options?: any): AxiosPromise<NodeOutput> {
            return localVarFp.getWorkflowRunNodeOutputV1(workspaceId, workflowId, workflowRunId, nodeId, operatorCategory, options).then((request) => request(axios, basePath));
        },
        /**
         * Get workflow run status with operators wise status details as well for a given workflow run id
         * @summary Get workflow run status for a given workflow run id
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowRunStatusV1(workspaceId: string, workflowId: string, workflowRunId: string, options?: any): AxiosPromise<GetWorkflowRunStatusDetailsResponse> {
            return localVarFp.getWorkflowRunStatusV1(workspaceId, workflowId, workflowRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all details of a run given run id for a workflow
         * @summary Get all details of a run given run id for a workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowRunV1(workspaceId: string, workflowId: string, workflowRunId: string, options?: any): AxiosPromise<WorkflowRun> {
            return localVarFp.getWorkflowRunV1(workspaceId, workflowId, workflowRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all supported filters for workflows run of a workspace.
         * @summary Returns workflow runs  filters.
         * @param {string} [workspaceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowRunsListingFiltersV1(workspaceId?: string, options?: any): AxiosPromise<AllowedFilters> {
            return localVarFp.getWorkflowRunsListingFiltersV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * This API fetches a workflow by it\'s ID. If not found, returns a 404
         * @summary Get Workflow by ID
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowV1(workspaceId: string, workflowId: string, options?: any): AxiosPromise<Workflow> {
            return localVarFp.getWorkflowV1(workspaceId, workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of all workflow apps for the given workspace.
         * @summary List Workflow Apps
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflowAppsV1(workspaceId: string, listingAPIParams: ListingAPIParams, options?: any): AxiosPromise<ListWorkflowAppsResponse> {
            return localVarFp.listWorkflowAppsV1(workspaceId, listingAPIParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of runs for a workflow
         * @summary Get list of runs for a workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflowRunsV1(workspaceId: string, workflowId: string, listingAPIParams: ListingAPIParams, options?: any): AxiosPromise<ListWorkflowRunsResponse> {
            return localVarFp.listWorkflowRunsV1(workspaceId, workflowId, listingAPIParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of workflows
         * @summary Get list of workflows
         * @param {string} workspaceId 
         * @param {ListingAPIParams} listingAPIParams 
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflowsV1(workspaceId: string, listingAPIParams: ListingAPIParams, searchQuery?: string, options?: any): AxiosPromise<ListWorkflowsResponse> {
            return localVarFp.listWorkflowsV1(workspaceId, listingAPIParams, searchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * Publish the workflow along with setting trigger
         * @summary Publish the workflow along with setting trigger
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishTriggerWorkflowV1(workspaceId: string, workflowId: string, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.publishTriggerWorkflowV1(workspaceId, workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * Publish a workflow, and optionally, start its execution.     This action transitions the workflow to ACTIVE state. The caller can also request immediate execution of the workflow.
         * @summary Publish a workflow, and optionally, start its execution
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {boolean} [execute] 
         * @param {string} [workflowName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishWorkflowV1(workspaceId: string, workflowId: string, execute?: boolean, workflowName?: string, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.publishWorkflowV1(workspaceId, workflowId, execute, workflowName, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint stops a currently running workflow and updates its status accordingly. It ensures that all associated processes are properly terminated.
         * @summary Stops a running workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {string} workflowRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopWorkflowRunV1(workspaceId: string, workflowId: string, workflowRunId: string, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.stopWorkflowRunV1(workspaceId, workflowId, workflowRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint enables the update or insertion of Directed Acyclic Graphs (DAGs) within a specific                     workflow in a designated workspace. The DAG configuration provided in the request will overwrite                     the existing record, allowing for dynamic updates to the structure of the workflow.
         * @summary Upsert DAGs in Workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {UpsertDAGRequest} upsertDAGRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDAGsV1(workspaceId: string, workflowId: string, upsertDAGRequest: UpsertDAGRequest, options?: any): AxiosPromise<UpsertDagResponse> {
            return localVarFp.updateDAGsV1(workspaceId, workflowId, upsertDAGRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update scheduled workflow status to Enabled/Disabled
         * @summary Update scheduled workflow status to Enabled/Disabled
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {WorkflowStatus} [status] The new status of the scheduled workflow, either \&#39;ENABLED\&#39; or \&#39;DISABLED\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScheduledWorkflowStateV1(workspaceId: string, workflowId: string, status?: WorkflowStatus, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.updateScheduledWorkflowStateV1(workspaceId, workflowId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to update the schedule details of a scheduled workflow in the specified workspace. Allows updating the schedules like CRON, interval, daily, weekly, etc.
         * @summary Update schedule details of a Scheduled workflow.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {UpdateScheduledWorkflowRequest} updateScheduledWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScheduledWorkflowV1(workspaceId: string, workflowId: string, updateScheduledWorkflowRequest: UpdateScheduledWorkflowRequest, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.updateScheduledWorkflowV1(workspaceId, workflowId, updateScheduledWorkflowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the specified workflow app in the given workspace.
         * @summary Update Workflow App
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {UpdateWorkflowAppDAGRequest} updateWorkflowAppDAGRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowAppV1(workspaceId: string, workflowId: string, updateWorkflowAppDAGRequest: UpdateWorkflowAppDAGRequest, options?: any): AxiosPromise<UpdateWorkflowAppResponseModel> {
            return localVarFp.updateWorkflowAppV1(workspaceId, workflowId, updateWorkflowAppDAGRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint modifies workflow attributes like name, description, status                     of the given workflow
         * @summary Update Workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {UpdateWorkflowRequest} updateWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowV1(workspaceId: string, workflowId: string, updateWorkflowRequest: UpdateWorkflowRequest, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.updateWorkflowV1(workspaceId, workflowId, updateWorkflowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all supported filters for workflows of a workspace.
         * @summary Returns all workflows filters.
         * @param {string} [workspaceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowListingFiltersV1(workspaceId?: string, options?: any): AxiosPromise<AllowedFilters> {
            return localVarFp.workflowListingFiltersV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update state of workflow resource (Active, Draft, Archive) Args:     background_tasks:     update_request:         list{workflow_id}: list of workflow ids to be modified         artifact_state: ARCHIVED/ACTIVE/DRAFT     workspace_id:     user:  Returns:
         * @summary Update workflows artifact state
         * @param {string} workspaceId 
         * @param {UpdateWorkflowArtifactStateRequest} updateWorkflowArtifactStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowStateChangeV1(workspaceId: string, updateWorkflowArtifactStateRequest: UpdateWorkflowArtifactStateRequest, options?: any): AxiosPromise<AckResponse> {
            return localVarFp.workflowStateChangeV1(workspaceId, updateWorkflowArtifactStateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkflowsAPIsApi - object-oriented interface
 * @export
 * @class WorkflowsAPIsApi
 * @extends {BaseAPI}
 */
export class WorkflowsAPIsApi extends BaseAPI {
    /**
     * This endpoint fetches the record corresponding to the workflowId and clones the artifact
     * @summary Clones a workflow in draft & published state
     * @param {string} workspaceId 
     * @param {CloneWorkflowRequest} cloneWorkflowRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public cloneWorkflowsV1(workspaceId: string, cloneWorkflowRequest: CloneWorkflowRequest, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).cloneWorkflowsV1(workspaceId, cloneWorkflowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to create a new scheduled workflow in the specified workspace. Allows setting up schedules like CRON, interval, daily, weekly, etc.
     * @summary Create a new Scheduled workflow.
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {CreateScheduledWorkflowRequest} createScheduledWorkflowRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public createScheduledWorkflowV1(workspaceId: string, workflowId: string, createScheduledWorkflowRequest: CreateScheduledWorkflowRequest, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).createScheduledWorkflowV1(workspaceId, workflowId, createScheduledWorkflowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a Workflow App for the specified workflow in the given workspace.
     * @summary Create a Workflow App
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {CreateWorkflowAppModelRequest} createWorkflowAppModelRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public createWorkflowAppV1(workspaceId: string, workflowId: string, createWorkflowAppModelRequest: CreateWorkflowAppModelRequest, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).createWorkflowAppV1(workspaceId, workflowId, createWorkflowAppModelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a workflow debug run if not exist, else updates the existing debug run
     * @summary Create a workflow debug run
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {CreateWorkflowDebugRunRequest} createWorkflowDebugRunRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public createWorkflowDebugRunV1(workspaceId: string, workflowId: string, createWorkflowDebugRunRequest: CreateWorkflowDebugRunRequest, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).createWorkflowDebugRunV1(workspaceId, workflowId, createWorkflowDebugRunRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a workflow runs
     * @summary Create a workflow run
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {CreateWorkflowRunRequest} createWorkflowRunRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public createWorkflowRunV1(workspaceId: string, workflowId: string, createWorkflowRunRequest: CreateWorkflowRunRequest, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).createWorkflowRunV1(workspaceId, workflowId, createWorkflowRunRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a workflow
     * @summary Create a workflow
     * @param {string} workspaceId 
     * @param {string} [workflowName] 
     * @param {string} [templateId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public createWorkflowV1(workspaceId: string, workflowName?: string, templateId?: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).createWorkflowV1(workspaceId, workflowName, templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to delete a scheduled workflow in the specified workspace.
     * @summary Delete Scheduled workflow.
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public deleteScheduledWorkflowV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).deleteScheduledWorkflowV1(workspaceId, workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint fetches all records corresponding to the workflowId and delete all those artifacts
     * @summary Deletes a workflow in draft state
     * @param {string} workspaceId 
     * @param {DeleteWorkflowsRequest} deleteWorkflowsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public deleteWorkflowsV1(workspaceId: string, deleteWorkflowsRequest: DeleteWorkflowsRequest, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).deleteWorkflowsV1(workspaceId, deleteWorkflowsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is to give the download link of a workflow, esp if available through a file sink such as S3
     * @summary Get a download link for a workflow\'s output.
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {string} workflowRunId 
     * @param {string} nodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public downloadWorkflowNodeOutputV1(workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).downloadWorkflowNodeOutputV1(workspaceId, workflowId, workflowRunId, nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [Deprecated as wflow can have multiple outputs, use the DownloadWorkflowNodeOutputV1 API] This API is to give the download link of a workflow, esp if available through a file sink such as S3
     * @summary Get a download link for a workflow\'s output.
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {string} workflowRunId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public downloadWorkflowOutputV1(workspaceId: string, workflowId: string, workflowRunId: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).downloadWorkflowOutputV1(workspaceId, workflowId, workflowRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint fetches the DAG corresponding to the workflow. This would be                     called when we want to persist DAG in FE, and while rendering DAG from a template
     * @summary Fetch DAG in Workflow
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public fetchDAGsV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).fetchDAGsV1(workspaceId, workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get unique email id for this workflow to send email triggers from
     * @summary Get unique email id for this workflow to send email triggers from
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public getTriggerEmailV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).getTriggerEmailV1(workspaceId, workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the details of a specific workflow within the given workspace.
     * @summary Get Workflow App Details
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public getWorkflowAppDetailsByWorkflowIdV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).getWorkflowAppDetailsByWorkflowIdV1(workspaceId, workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the details of a specific workflow app within the given workspace.
     * @summary Get Workflow App Details
     * @param {string} workspaceId 
     * @param {string} workflowAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public getWorkflowAppDetailsV1(workspaceId: string, workflowAppId: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).getWorkflowAppDetailsV1(workspaceId, workflowAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint fetches the input and output column schema for all the nodes of a dag for a given workflow
     * @summary Fetch the workflow dag nodes io schema. This is deprecated, please use v2 version of this API
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public getWorkflowDagNodesSchemaV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).getWorkflowDagNodesSchemaV1(workspaceId, workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint fetches the input and output column schema for all the nodes of a dag for a given workflow
     * @summary Fetch the workflow dag nodes io schema
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {string} [workflowRunId] 
     * @param {string} [nodeId] 
     * @param {string} [workflowAppId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public getWorkflowDagNodesSchemaV2(workspaceId: string, workflowId: string, workflowRunId?: string, nodeId?: string, workflowAppId?: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).getWorkflowDagNodesSchemaV2(workspaceId, workflowId, workflowRunId, nodeId, workflowAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all supported filters for workflows of a workspace. Deprecated: Use WorkflowListingFiltersV1
     * @summary Returns all workflows filters.
     * @param {string} [workspaceId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public getWorkflowListingFiltersV1(workspaceId?: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).getWorkflowListingFiltersV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the credit usage of a workflow run with an operator level breakdown.
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {string} workflowRunId 
     * @param {boolean} [mock] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public getWorkflowRunCreditUsageV1(workspaceId: string, workflowId: string, workflowRunId: string, mock?: boolean, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).getWorkflowRunCreditUsageV1(workspaceId, workflowId, workflowRunId, mock, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the logfile of a workflow node for a given run and it\'s node.This is not an optimised API, prefer using the companion streaming API GetWorkflowRunNodeLogStreamV1.
     * @summary Get the logfile of a workflow node as a streaming response
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {string} workflowRunId 
     * @param {string} nodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public getWorkflowRunNodeLogFileV1(workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).getWorkflowRunNodeLogFileV1(workspaceId, workflowId, workflowRunId, nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the logfile stream of a workflow node for a given run and it\'s node
     * @summary Get the logfile of a workflow node as a streaming response
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {string} workflowRunId 
     * @param {string} nodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public getWorkflowRunNodeLogStreamV1(workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).getWorkflowRunNodeLogStreamV1(workspaceId, workflowId, workflowRunId, nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the preview of data after a node executes, to be used during debug. This is not applicable for full runs.
     * @summary Get the preview of data after a node executes, to be used during debug.
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {string} workflowRunId 
     * @param {string} nodeId 
     * @param {string} operatorCategory 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public getWorkflowRunNodeOutputV1(workspaceId: string, workflowId: string, workflowRunId: string, nodeId: string, operatorCategory: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).getWorkflowRunNodeOutputV1(workspaceId, workflowId, workflowRunId, nodeId, operatorCategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get workflow run status with operators wise status details as well for a given workflow run id
     * @summary Get workflow run status for a given workflow run id
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {string} workflowRunId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public getWorkflowRunStatusV1(workspaceId: string, workflowId: string, workflowRunId: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).getWorkflowRunStatusV1(workspaceId, workflowId, workflowRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all details of a run given run id for a workflow
     * @summary Get all details of a run given run id for a workflow
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {string} workflowRunId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public getWorkflowRunV1(workspaceId: string, workflowId: string, workflowRunId: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).getWorkflowRunV1(workspaceId, workflowId, workflowRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all supported filters for workflows run of a workspace.
     * @summary Returns workflow runs  filters.
     * @param {string} [workspaceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public getWorkflowRunsListingFiltersV1(workspaceId?: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).getWorkflowRunsListingFiltersV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API fetches a workflow by it\'s ID. If not found, returns a 404
     * @summary Get Workflow by ID
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public getWorkflowV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).getWorkflowV1(workspaceId, workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of all workflow apps for the given workspace.
     * @summary List Workflow Apps
     * @param {string} workspaceId 
     * @param {ListingAPIParams} listingAPIParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public listWorkflowAppsV1(workspaceId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).listWorkflowAppsV1(workspaceId, listingAPIParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of runs for a workflow
     * @summary Get list of runs for a workflow
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {ListingAPIParams} listingAPIParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public listWorkflowRunsV1(workspaceId: string, workflowId: string, listingAPIParams: ListingAPIParams, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).listWorkflowRunsV1(workspaceId, workflowId, listingAPIParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of workflows
     * @summary Get list of workflows
     * @param {string} workspaceId 
     * @param {ListingAPIParams} listingAPIParams 
     * @param {string} [searchQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public listWorkflowsV1(workspaceId: string, listingAPIParams: ListingAPIParams, searchQuery?: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).listWorkflowsV1(workspaceId, listingAPIParams, searchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Publish the workflow along with setting trigger
     * @summary Publish the workflow along with setting trigger
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public publishTriggerWorkflowV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).publishTriggerWorkflowV1(workspaceId, workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Publish a workflow, and optionally, start its execution.     This action transitions the workflow to ACTIVE state. The caller can also request immediate execution of the workflow.
     * @summary Publish a workflow, and optionally, start its execution
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {boolean} [execute] 
     * @param {string} [workflowName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public publishWorkflowV1(workspaceId: string, workflowId: string, execute?: boolean, workflowName?: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).publishWorkflowV1(workspaceId, workflowId, execute, workflowName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint stops a currently running workflow and updates its status accordingly. It ensures that all associated processes are properly terminated.
     * @summary Stops a running workflow
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {string} workflowRunId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public stopWorkflowRunV1(workspaceId: string, workflowId: string, workflowRunId: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).stopWorkflowRunV1(workspaceId, workflowId, workflowRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint enables the update or insertion of Directed Acyclic Graphs (DAGs) within a specific                     workflow in a designated workspace. The DAG configuration provided in the request will overwrite                     the existing record, allowing for dynamic updates to the structure of the workflow.
     * @summary Upsert DAGs in Workflow
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {UpsertDAGRequest} upsertDAGRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public updateDAGsV1(workspaceId: string, workflowId: string, upsertDAGRequest: UpsertDAGRequest, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).updateDAGsV1(workspaceId, workflowId, upsertDAGRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update scheduled workflow status to Enabled/Disabled
     * @summary Update scheduled workflow status to Enabled/Disabled
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {WorkflowStatus} [status] The new status of the scheduled workflow, either \&#39;ENABLED\&#39; or \&#39;DISABLED\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public updateScheduledWorkflowStateV1(workspaceId: string, workflowId: string, status?: WorkflowStatus, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).updateScheduledWorkflowStateV1(workspaceId, workflowId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to update the schedule details of a scheduled workflow in the specified workspace. Allows updating the schedules like CRON, interval, daily, weekly, etc.
     * @summary Update schedule details of a Scheduled workflow.
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {UpdateScheduledWorkflowRequest} updateScheduledWorkflowRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public updateScheduledWorkflowV1(workspaceId: string, workflowId: string, updateScheduledWorkflowRequest: UpdateScheduledWorkflowRequest, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).updateScheduledWorkflowV1(workspaceId, workflowId, updateScheduledWorkflowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the specified workflow app in the given workspace.
     * @summary Update Workflow App
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {UpdateWorkflowAppDAGRequest} updateWorkflowAppDAGRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public updateWorkflowAppV1(workspaceId: string, workflowId: string, updateWorkflowAppDAGRequest: UpdateWorkflowAppDAGRequest, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).updateWorkflowAppV1(workspaceId, workflowId, updateWorkflowAppDAGRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint modifies workflow attributes like name, description, status                     of the given workflow
     * @summary Update Workflow
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {UpdateWorkflowRequest} updateWorkflowRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public updateWorkflowV1(workspaceId: string, workflowId: string, updateWorkflowRequest: UpdateWorkflowRequest, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).updateWorkflowV1(workspaceId, workflowId, updateWorkflowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all supported filters for workflows of a workspace.
     * @summary Returns all workflows filters.
     * @param {string} [workspaceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public workflowListingFiltersV1(workspaceId?: string, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).workflowListingFiltersV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update state of workflow resource (Active, Draft, Archive) Args:     background_tasks:     update_request:         list{workflow_id}: list of workflow ids to be modified         artifact_state: ARCHIVED/ACTIVE/DRAFT     workspace_id:     user:  Returns:
     * @summary Update workflows artifact state
     * @param {string} workspaceId 
     * @param {UpdateWorkflowArtifactStateRequest} updateWorkflowArtifactStateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowsAPIsApi
     */
    public workflowStateChangeV1(workspaceId: string, updateWorkflowArtifactStateRequest: UpdateWorkflowArtifactStateRequest, options?: AxiosRequestConfig) {
        return WorkflowsAPIsApiFp(this.configuration).workflowStateChangeV1(workspaceId, updateWorkflowArtifactStateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkspaceCredentialsRoutesApi - axios parameter creator
 * @export
 */
export const WorkspaceCredentialsRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new user defined credentials
         * @param {string} workspaceId 
         * @param {AddCredentialRequest} addCredentialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceAddCredentialV1: async (workspaceId: string, addCredentialRequest: AddCredentialRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceAddCredentialV1', 'workspaceId', workspaceId)
            // verify required parameter 'addCredentialRequest' is not null or undefined
            assertParamExists('workspaceAddCredentialV1', 'addCredentialRequest', addCredentialRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/credentials`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCredentialRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Credentials 
         * @param {string} workspaceId 
         * @param {string} credentialId 
         * @param {EditCredentialRequest} editCredentialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceEditCredentialsV1: async (workspaceId: string, credentialId: string, editCredentialRequest: EditCredentialRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceEditCredentialsV1', 'workspaceId', workspaceId)
            // verify required parameter 'credentialId' is not null or undefined
            assertParamExists('workspaceEditCredentialsV1', 'credentialId', credentialId)
            // verify required parameter 'editCredentialRequest' is not null or undefined
            assertParamExists('workspaceEditCredentialsV1', 'editCredentialRequest', editCredentialRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/credentials/{credential_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"credential_id"}}`, encodeURIComponent(String(credentialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editCredentialRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of cloud credentials saved by user
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceListCredentialsV1: async (workspaceId: string, start?: number, end?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceListCredentialsV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/credentials`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspaceCredentialsRoutesApi - functional programming interface
 * @export
 */
export const WorkspaceCredentialsRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspaceCredentialsRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new user defined credentials
         * @param {string} workspaceId 
         * @param {AddCredentialRequest} addCredentialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceAddCredentialV1(workspaceId: string, addCredentialRequest: AddCredentialRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCredentialResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceAddCredentialV1(workspaceId, addCredentialRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Credentials 
         * @param {string} workspaceId 
         * @param {string} credentialId 
         * @param {EditCredentialRequest} editCredentialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceEditCredentialsV1(workspaceId: string, credentialId: string, editCredentialRequest: EditCredentialRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditCredentialResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceEditCredentialsV1(workspaceId, credentialId, editCredentialRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of cloud credentials saved by user
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceListCredentialsV1(workspaceId: string, start?: number, end?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCredentialsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceListCredentialsV1(workspaceId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkspaceCredentialsRoutesApi - factory interface
 * @export
 */
export const WorkspaceCredentialsRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspaceCredentialsRoutesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new user defined credentials
         * @param {string} workspaceId 
         * @param {AddCredentialRequest} addCredentialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceAddCredentialV1(workspaceId: string, addCredentialRequest: AddCredentialRequest, options?: any): AxiosPromise<AddCredentialResponse> {
            return localVarFp.workspaceAddCredentialV1(workspaceId, addCredentialRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Credentials 
         * @param {string} workspaceId 
         * @param {string} credentialId 
         * @param {EditCredentialRequest} editCredentialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceEditCredentialsV1(workspaceId: string, credentialId: string, editCredentialRequest: EditCredentialRequest, options?: any): AxiosPromise<EditCredentialResponse> {
            return localVarFp.workspaceEditCredentialsV1(workspaceId, credentialId, editCredentialRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of cloud credentials saved by user
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceListCredentialsV1(workspaceId: string, start?: number, end?: number, options?: any): AxiosPromise<ListCredentialsResponse> {
            return localVarFp.workspaceListCredentialsV1(workspaceId, start, end, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspaceCredentialsRoutesApi - object-oriented interface
 * @export
 * @class WorkspaceCredentialsRoutesApi
 * @extends {BaseAPI}
 */
export class WorkspaceCredentialsRoutesApi extends BaseAPI {
    /**
     * 
     * @summary Create new user defined credentials
     * @param {string} workspaceId 
     * @param {AddCredentialRequest} addCredentialRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceCredentialsRoutesApi
     */
    public workspaceAddCredentialV1(workspaceId: string, addCredentialRequest: AddCredentialRequest, options?: AxiosRequestConfig) {
        return WorkspaceCredentialsRoutesApiFp(this.configuration).workspaceAddCredentialV1(workspaceId, addCredentialRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Credentials 
     * @param {string} workspaceId 
     * @param {string} credentialId 
     * @param {EditCredentialRequest} editCredentialRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceCredentialsRoutesApi
     */
    public workspaceEditCredentialsV1(workspaceId: string, credentialId: string, editCredentialRequest: EditCredentialRequest, options?: AxiosRequestConfig) {
        return WorkspaceCredentialsRoutesApiFp(this.configuration).workspaceEditCredentialsV1(workspaceId, credentialId, editCredentialRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of cloud credentials saved by user
     * @param {string} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceCredentialsRoutesApi
     */
    public workspaceListCredentialsV1(workspaceId: string, start?: number, end?: number, options?: AxiosRequestConfig) {
        return WorkspaceCredentialsRoutesApiFp(this.configuration).workspaceListCredentialsV1(workspaceId, start, end, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkspaceDatasetRoutesApi - axios parameter creator
 * @export
 */
export const WorkspaceDatasetRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns class labels associated with the given dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetLabelsV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetLabelsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDatasetLabelsV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/labels`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the sample information associated to given sample_id
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} sampleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetSampleV1: async (workspaceId: string, datasetId: string, sampleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDatasetSampleV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('getDatasetSampleV1', 'datasetId', datasetId)
            // verify required parameter 'sampleId' is not null or undefined
            assertParamExists('getDatasetSampleV1', 'sampleId', sampleId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/sample/{sample_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"sample_id"}}`, encodeURIComponent(String(sampleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Failed Analysis Task Retrigger
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceAnalysisTaskRetriggerV1: async (workspaceId: string, datasetId: string, taskId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceAnalysisTaskRetriggerV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceAnalysisTaskRetriggerV1', 'datasetId', datasetId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('workspaceAnalysisTaskRetriggerV1', 'taskId', taskId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/analysis-tasks/{task_id}/re-trigger`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Re-trigger the analyser using tasklet_id. This is version2 where earlier one takes task id here it takes tasklet id
         * @summary Re-trigger the analyser using tasklet_id
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceAnalysisTaskRetriggerV2: async (workspaceId: string, datasetId: string, taskletId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceAnalysisTaskRetriggerV2', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceAnalysisTaskRetriggerV2', 'datasetId', datasetId)
            // verify required parameter 'taskletId' is not null or undefined
            assertParamExists('workspaceAnalysisTaskRetriggerV2', 'taskletId', taskletId)
            const localVarPath = `/vienna/v2/workspace/{workspace_id}/datasets/{dataset_id}/analysis-tasks/{tasklet_id}/re-trigger`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"tasklet_id"}}`, encodeURIComponent(String(taskletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Completes a multi part upload for uploading a dataset to s3.
         * @param {string} workspaceId 
         * @param {ServicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest} servicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceCompleteDatasetMultipartUploadV1: async (workspaceId: string, servicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest: ServicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceCompleteDatasetMultipartUploadV1', 'workspaceId', workspaceId)
            // verify required parameter 'servicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest' is not null or undefined
            assertParamExists('workspaceCompleteDatasetMultipartUploadV1', 'servicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest', servicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/complete-multipart-upload`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(servicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a data family
         * @param {string} workspaceId 
         * @param {CreateDataFamilyRequest} createDataFamilyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceCreateDataFamilyV1: async (workspaceId: string, createDataFamilyRequest: CreateDataFamilyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceCreateDataFamilyV1', 'workspaceId', workspaceId)
            // verify required parameter 'createDataFamilyRequest' is not null or undefined
            assertParamExists('workspaceCreateDataFamilyV1', 'createDataFamilyRequest', createDataFamilyRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/data-family`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDataFamilyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a pre-signed s3 url for uploading a dataset.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {CreateUploadUrlRequest} createUploadUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceCreateDatasetUploadUrlV1: async (workspaceId: string, workflowId: string, createUploadUrlRequest: CreateUploadUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceCreateDatasetUploadUrlV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('workspaceCreateDatasetUploadUrlV1', 'workflowId', workflowId)
            // verify required parameter 'createUploadUrlRequest' is not null or undefined
            assertParamExists('workspaceCreateDatasetUploadUrlV1', 'createUploadUrlRequest', createUploadUrlRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflow/{workflow_id}/dataset/create-upload-url`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUploadUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a workflow
         * @param {string} workspaceId 
         * @param {CreateWorkflowRequest} createWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceCreateWorkflowV1: async (workspaceId: string, createWorkflowRequest: CreateWorkflowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceCreateWorkflowV1', 'workspaceId', workspaceId)
            // verify required parameter 'createWorkflowRequest' is not null or undefined
            assertParamExists('workspaceCreateWorkflowV1', 'createWorkflowRequest', createWorkflowRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/workflow`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkflowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger a compare run between a primary dataset, and multiple secondary datasets.
         * @param {string} workspaceId 
         * @param {DataSetPair} dataSetPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceDataCompareV1: async (workspaceId: string, dataSetPair: DataSetPair, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceDataCompareV1', 'workspaceId', workspaceId)
            // verify required parameter 'dataSetPair' is not null or undefined
            assertParamExists('workspaceDataCompareV1', 'dataSetPair', dataSetPair)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/compare`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataSetPair, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Given a list of dataset ids return if they are valid
         * @param {string} workspaceId 
         * @param {string} dsIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceDataSetValidStatusV1: async (workspaceId: string, dsIds: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceDataSetValidStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'dsIds' is not null or undefined
            assertParamExists('workspaceDataSetValidStatusV1', 'dsIds', dsIds)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/exists`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dsIds !== undefined) {
                localVarQueryParameter['ds_ids'] = dsIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns info associated with for list of dataset ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [dsId] 
         * @param {Array<string>} [dsName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceDataSetsInfoBulkV1: async (workspaceId: string, dsId?: Array<string>, dsName?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceDataSetsInfoBulkV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/info`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dsId) {
                localVarQueryParameter['ds_id'] = dsId;
            }

            if (dsName) {
                localVarQueryParameter['ds_name'] = dsName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Archive dataset resource Args:     update_request:         list{dataset_id}:         artifact_state: ARCHIVED/ACTIVE     workspace_id:     user:  Returns:
         * @summary Update datasets artifact state
         * @param {string} workspaceId 
         * @param {UpdateDatasetArtifactState} updateDatasetArtifactState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceDatasetStateChangeV1: async (workspaceId: string, updateDatasetArtifactState: UpdateDatasetArtifactState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceDatasetStateChangeV1', 'workspaceId', workspaceId)
            // verify required parameter 'updateDatasetArtifactState' is not null or undefined
            assertParamExists('workspaceDatasetStateChangeV1', 'updateDatasetArtifactState', updateDatasetArtifactState)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/state`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDatasetArtifactState, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Failed Analysis Task Retrigger All
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceFailedAnalysisTaskRetriggerV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceFailedAnalysisTaskRetriggerV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceFailedAnalysisTaskRetriggerV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/analysis-tasks/re-trigger-failed`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns data for a graph.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} analysisId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetAnalysisDataV1: async (workspaceId: string, datasetId: string, analysisId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetAnalysisDataV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetAnalysisDataV1', 'datasetId', datasetId)
            // verify required parameter 'analysisId' is not null or undefined
            assertParamExists('workspaceGetAnalysisDataV1', 'analysisId', analysisId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/analysis/{analysis_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"analysis_id"}}`, encodeURIComponent(String(analysisId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary paginated API to get a list of analysis tasks with their status for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {AnalysisStateNames} [statusFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetAnalysisTasksResponseV1: async (workspaceId: string, datasetId: string, start?: number, end?: number, statusFilter?: AnalysisStateNames, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetAnalysisTasksResponseV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetAnalysisTasksResponseV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/analysis-tasks`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (statusFilter !== undefined) {
                localVarQueryParameter['status_filter'] = statusFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns model performance metrics like precision,accuracy of the baseline model trained on this dataset
         * @summary Returns baseline model metrics
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetBaselineMetricsV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetBaselineMetricsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetBaselineMetricsV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/baseline-model-metrics`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns baseline model tasklet status for the given dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetBaselineModelTaskletStatusV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetBaselineModelTaskletStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetBaselineModelTaskletStatusV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/baseline-model-tasklet-status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns class level data quality metrics for the given dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetClassDataQualityV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetClassDataQualityV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetClassDataQualityV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/class-level-data-quality-metrics`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the segment level similarity given primary, compare_with dataset ids and respective segments 
         * @param {string} workspaceId 
         * @param {DataSetSegmentSimilarity} dataSetSegmentSimilarity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetCrossDataSetSegmentSimilarityV1: async (workspaceId: string, dataSetSegmentSimilarity: DataSetSegmentSimilarity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetCrossDataSetSegmentSimilarityV1', 'workspaceId', workspaceId)
            // verify required parameter 'dataSetSegmentSimilarity' is not null or undefined
            assertParamExists('workspaceGetCrossDataSetSegmentSimilarityV1', 'dataSetSegmentSimilarity', dataSetSegmentSimilarity)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/ds-pair/segment-similarity`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataSetSegmentSimilarity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the dataset similarity across all segment combinations (cross product of primary with all compare_with datasets)
         * @param {string} workspaceId 
         * @param {DataSetPair} dataSetPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetCrossDataSetSimilarityV1: async (workspaceId: string, dataSetPair: DataSetPair, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetCrossDataSetSimilarityV1', 'workspaceId', workspaceId)
            // verify required parameter 'dataSetPair' is not null or undefined
            assertParamExists('workspaceGetCrossDataSetSimilarityV1', 'dataSetPair', dataSetPair)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/multi-dataset/similarity`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataSetPair, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of data families
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDataFamilyListV1: async (workspaceId: string, start?: number, end?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDataFamilyListV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/data-family`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns data quality score analyser status for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDataQualityStatusV1: async (datasetId: string, workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetDataQualityStatusV1', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDataQualityStatusV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/data-quality-status`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns info associated with a dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {boolean} [includeFeatureColumns] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDataSetsInfoV1: async (datasetId: string, workspaceId: string, includeFeatureColumns?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetDataSetsInfoV1', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDataSetsInfoV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/info`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeFeatureColumns !== undefined) {
                localVarQueryParameter['include_feature_columns'] = includeFeatureColumns;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the datasets.
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [dataFamilyName] 
         * @param {string} [dataFamilyId] 
         * @param {string} [dataType] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDataSetsListV1: async (workspaceId: string, start?: number, end?: number, ts?: string, dataFamilyName?: string, dataFamilyId?: string, dataType?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDataSetsListV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (dataFamilyName !== undefined) {
                localVarQueryParameter['data_family_name'] = dataFamilyName;
            }

            if (dataFamilyId !== undefined) {
                localVarQueryParameter['data_family_id'] = dataFamilyId;
            }

            if (dataType !== undefined) {
                localVarQueryParameter['data_type'] = dataType;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns class distribution in the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetClassDistributionV1: async (datasetId: string, workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetDatasetClassDistributionV1', 'datasetId', datasetId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDatasetClassDistributionV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/distribution`
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the correlation info types analyzed for the dataset segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetCorrelationInfoTypesV1: async (workspaceId: string, datasetId: string, segmentType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDatasetCorrelationInfoTypesV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetDatasetCorrelationInfoTypesV1', 'datasetId', datasetId)
            // verify required parameter 'segmentType' is not null or undefined
            assertParamExists('workspaceGetDatasetCorrelationInfoTypesV1', 'segmentType', segmentType)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/segment/{segment_type}/correlation-types`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"segment_type"}}`, encodeURIComponent(String(segmentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the correlation info b/w every pair of variables in the dataset segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {string} correlationType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetCorrelationInfoV1: async (workspaceId: string, datasetId: string, segmentType: string, correlationType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDatasetCorrelationInfoV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetDatasetCorrelationInfoV1', 'datasetId', datasetId)
            // verify required parameter 'segmentType' is not null or undefined
            assertParamExists('workspaceGetDatasetCorrelationInfoV1', 'segmentType', segmentType)
            // verify required parameter 'correlationType' is not null or undefined
            assertParamExists('workspaceGetDatasetCorrelationInfoV1', 'correlationType', correlationType)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/segment/{segment_type}/correlation`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"segment_type"}}`, encodeURIComponent(String(segmentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (correlationType !== undefined) {
                localVarQueryParameter['correlation_type'] = correlationType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of delimiters for dataset
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetDelimiterDataV1: async (workspaceId: string, workflowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDatasetDelimiterDataV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('workspaceGetDatasetDelimiterDataV1', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflow/{workflow_id}/dataset/delimiter`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the missing values for each variable in dataset segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetMissingValuesV1: async (workspaceId: string, datasetId: string, segmentType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDatasetMissingValuesV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetDatasetMissingValuesV1', 'datasetId', datasetId)
            // verify required parameter 'segmentType' is not null or undefined
            assertParamExists('workspaceGetDatasetMissingValuesV1', 'segmentType', segmentType)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/segment/{segment_type}/missing-values`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"segment_type"}}`, encodeURIComponent(String(segmentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dataset preview data snapshot
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetPreviewDataV1: async (workspaceId: string, workflowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDatasetPreviewDataV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('workspaceGetDatasetPreviewDataV1', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflow/{workflow_id}/dataset/preview`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetRegisterWorkflowV1: async (workspaceId: string, workflowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDatasetRegisterWorkflowV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('workspaceGetDatasetRegisterWorkflowV1', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/workflow/{workflow_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the overview for the dataset summary.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetSummaryOverviewV1: async (workspaceId: string, datasetId: string, segmentType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDatasetSummaryOverviewV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetDatasetSummaryOverviewV1', 'datasetId', datasetId)
            // verify required parameter 'segmentType' is not null or undefined
            assertParamExists('workspaceGetDatasetSummaryOverviewV1', 'segmentType', segmentType)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/segment/{segment_type}/overview`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"segment_type"}}`, encodeURIComponent(String(segmentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns dataset topology as table dict
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetTopologyV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDatasetTopologyV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetDatasetTopologyV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/topology`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of variables used in the given dataset and segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {string} variable 
         * @param {string} visualizationId 
         * @param {boolean} [groupByTarget] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetVariableChartDataV1: async (workspaceId: string, datasetId: string, segmentType: string, variable: string, visualizationId: string, groupByTarget?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDatasetVariableChartDataV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetDatasetVariableChartDataV1', 'datasetId', datasetId)
            // verify required parameter 'segmentType' is not null or undefined
            assertParamExists('workspaceGetDatasetVariableChartDataV1', 'segmentType', segmentType)
            // verify required parameter 'variable' is not null or undefined
            assertParamExists('workspaceGetDatasetVariableChartDataV1', 'variable', variable)
            // verify required parameter 'visualizationId' is not null or undefined
            assertParamExists('workspaceGetDatasetVariableChartDataV1', 'visualizationId', visualizationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/segment/{segment_type}/variables/chart`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"segment_type"}}`, encodeURIComponent(String(segmentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (variable !== undefined) {
                localVarQueryParameter['variable'] = variable;
            }

            if (visualizationId !== undefined) {
                localVarQueryParameter['visualization_id'] = visualizationId;
            }

            if (groupByTarget !== undefined) {
                localVarQueryParameter['group_by_target'] = groupByTarget;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of variables used in the given dataset and segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {string} variable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetVariableLayoutV1: async (workspaceId: string, datasetId: string, segmentType: string, variable: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDatasetVariableLayoutV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetDatasetVariableLayoutV1', 'datasetId', datasetId)
            // verify required parameter 'segmentType' is not null or undefined
            assertParamExists('workspaceGetDatasetVariableLayoutV1', 'segmentType', segmentType)
            // verify required parameter 'variable' is not null or undefined
            assertParamExists('workspaceGetDatasetVariableLayoutV1', 'variable', variable)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/segment/{segment_type}/variables/layout`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"segment_type"}}`, encodeURIComponent(String(segmentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (variable !== undefined) {
                localVarQueryParameter['variable'] = variable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of variables used in the given dataset and segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {string} variable 
         * @param {string} visualizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetVariableTableDataV1: async (workspaceId: string, datasetId: string, segmentType: string, variable: string, visualizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDatasetVariableTableDataV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetDatasetVariableTableDataV1', 'datasetId', datasetId)
            // verify required parameter 'segmentType' is not null or undefined
            assertParamExists('workspaceGetDatasetVariableTableDataV1', 'segmentType', segmentType)
            // verify required parameter 'variable' is not null or undefined
            assertParamExists('workspaceGetDatasetVariableTableDataV1', 'variable', variable)
            // verify required parameter 'visualizationId' is not null or undefined
            assertParamExists('workspaceGetDatasetVariableTableDataV1', 'visualizationId', visualizationId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/segment/{segment_type}/variables/table`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"segment_type"}}`, encodeURIComponent(String(segmentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (variable !== undefined) {
                localVarQueryParameter['variable'] = variable;
            }

            if (visualizationId !== undefined) {
                localVarQueryParameter['visualization_id'] = visualizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of variables used in the given dataset and segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetVariablesV1: async (workspaceId: string, datasetId: string, segmentType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDatasetVariablesV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetDatasetVariablesV1', 'datasetId', datasetId)
            // verify required parameter 'segmentType' is not null or undefined
            assertParamExists('workspaceGetDatasetVariablesV1', 'segmentType', segmentType)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/segment/{segment_type}/variables`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"segment_type"}}`, encodeURIComponent(String(segmentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns status of data profiling group by wrt target
         * @summary Returns status of data profiling group by wrt target
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetGroupByStatusV1: async (workspaceId: string, datasetId: string, segmentType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetGroupByStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetGroupByStatusV1', 'datasetId', datasetId)
            // verify required parameter 'segmentType' is not null or undefined
            assertParamExists('workspaceGetGroupByStatusV1', 'segmentType', segmentType)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/segment/{segment_type}/group-by-target-status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"segment_type"}}`, encodeURIComponent(String(segmentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Given list of dataset_ids return the class distribution of the dataset
         * @param {string} workspaceId 
         * @param {string} segmentType 
         * @param {string} dsIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetMultiDSClassDistributionV1: async (workspaceId: string, segmentType: string, dsIds: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetMultiDSClassDistributionV1', 'workspaceId', workspaceId)
            // verify required parameter 'segmentType' is not null or undefined
            assertParamExists('workspaceGetMultiDSClassDistributionV1', 'segmentType', segmentType)
            // verify required parameter 'dsIds' is not null or undefined
            assertParamExists('workspaceGetMultiDSClassDistributionV1', 'dsIds', dsIds)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/class-distributions`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (segmentType !== undefined) {
                localVarQueryParameter['segment_type'] = segmentType;
            }

            if (dsIds !== undefined) {
                localVarQueryParameter['ds_ids'] = dsIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets list of new analyzer tasks for a registered dataset. New analyzer tasks are tasks present in config but not in run record for dataset
         * @summary API to get a list of new analyzer tasks for a registered dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetNewAnalyzersV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetNewAnalyzersV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetNewAnalyzersV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/new-analyzer-tasks`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the pairs of classes that are often mislabeled as one another, The number of points in one class mislabeled as the other and its joint probability
         * @summary Returns overlapping class metrics
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [pageNo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetOverlappingClassMetricsV1: async (workspaceId: string, datasetId: string, pageNo?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetOverlappingClassMetricsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetOverlappingClassMetricsV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/overlapping-metrics`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['page_no'] = pageNo;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dataset preview data snapshot
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetRegisteredDatasetPreviewV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetRegisteredDatasetPreviewV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetRegisteredDatasetPreviewV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/{dataset_id}/preview`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Contains the result of a Similarity Runs for different data segments. The relations between primary and secondary should not be assumed to be symmetric i.e. A->B is not B->A
         * @summary Get segment comparison data
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetSegmentComparisonV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetSegmentComparisonV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetSegmentComparisonV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/segment-comparison`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the similarity metrics that will be applied for comparison of datasets
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetSimilarityMetricNamesV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetSimilarityMetricNamesV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/sim-metric-names`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the status of this comparison run given a primary ds_id (dataset id) and list ofcompare with ids. 
         * @param {string} workspaceId 
         * @param {DataSetPair} dataSetPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetSimilarityRunStatusV1: async (workspaceId: string, dataSetPair: DataSetPair, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetSimilarityRunStatusV1', 'workspaceId', workspaceId)
            // verify required parameter 'dataSetPair' is not null or undefined
            assertParamExists('workspaceGetSimilarityRunStatusV1', 'dataSetPair', dataSetPair)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/ds-comp-status`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataSetPair, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the top k features of the dataset along with its corresponding metric, k is not fixed here
         * @summary Returns topk features of the dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetTopKFeaturesV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetTopKFeaturesV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceGetTopKFeaturesV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/top-k-features`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of data_operations.
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {WorkflowMode} [modeFilter] 
         * @param {RegistrationFlowStatus} [statusFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetWorkflowListV1: async (workspaceId: string, start?: number, end?: number, ts?: string, modeFilter?: WorkflowMode, statusFilter?: RegistrationFlowStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetWorkflowListV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/workflow`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (modeFilter !== undefined) {
                localVarQueryParameter['mode_filter'] = modeFilter;
            }

            if (statusFilter !== undefined) {
                localVarQueryParameter['status_filter'] = statusFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns grouping info for Analysis, e.g. n-grams, clustering, etc.Each analysis group can have one or more type of visualizations.
         * @summary Returns grouping info for Analysis, e.g. n-grams, clustering, etc.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceListAnalysisGroupedByMetricsV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceListAnalysisGroupedByMetricsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceListAnalysisGroupedByMetricsV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/analysis-groups-by-metrics`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns grouping info for Analysis, e.g. n-grams, clustering, etc.Each analysis group can have one or more type of visualizations.Deprecation Notice:In the hierarchy metrics come before segment. Use ListAnalysisGroupedByMetricsV1 instead of this API.
         * @summary Returns grouping info for Analysis, e.g. n-grams, clustering, etc.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        workspaceListAnalysisGroupsV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceListAnalysisGroupsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceListAnalysisGroupsV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/analysis-groups`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list containing metadata of graphs in an analysis group. Deprecation Notice:In the hierarchy metrics come before segment. Use ListAnalysisMetricsV1 instead of this API.
         * @summary Returns list containing metadata of graphs in an analysis group.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} analysisGroupId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        workspaceListAnalysisMetadataV1: async (workspaceId: string, datasetId: string, analysisGroupId: string, segmentType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceListAnalysisMetadataV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceListAnalysisMetadataV1', 'datasetId', datasetId)
            // verify required parameter 'analysisGroupId' is not null or undefined
            assertParamExists('workspaceListAnalysisMetadataV1', 'analysisGroupId', analysisGroupId)
            // verify required parameter 'segmentType' is not null or undefined
            assertParamExists('workspaceListAnalysisMetadataV1', 'segmentType', segmentType)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/analysis-groups/{analysis_group_id}/{segment_type}/analysis`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"analysis_group_id"}}`, encodeURIComponent(String(analysisGroupId)))
                .replace(`{${"segment_type"}}`, encodeURIComponent(String(segmentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list containing metadata of graphs in an analysis group.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} analysisGroupId 
         * @param {string} metricName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceListAnalysisMetricsV1: async (workspaceId: string, datasetId: string, analysisGroupId: string, metricName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceListAnalysisMetricsV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceListAnalysisMetricsV1', 'datasetId', datasetId)
            // verify required parameter 'analysisGroupId' is not null or undefined
            assertParamExists('workspaceListAnalysisMetricsV1', 'analysisGroupId', analysisGroupId)
            // verify required parameter 'metricName' is not null or undefined
            assertParamExists('workspaceListAnalysisMetricsV1', 'metricName', metricName)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/analysis-groups-by-metrics/{analysis_group_id}/metrics/{metric_name}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)))
                .replace(`{${"analysis_group_id"}}`, encodeURIComponent(String(analysisGroupId)))
                .replace(`{${"metric_name"}}`, encodeURIComponent(String(metricName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of all data stores
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceListDataStoresV1: async (workspaceId: string, start?: number, end?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceListDataStoresV1', 'workspaceId', workspaceId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/data-store`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Executes new analyzer tasks for a registered dataset
         * @summary Start new analyzers tasks on registered dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceNewAnalyzersStartV1: async (workspaceId: string, datasetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceNewAnalyzersStartV1', 'workspaceId', workspaceId)
            // verify required parameter 'datasetId' is not null or undefined
            assertParamExists('workspaceNewAnalyzersStartV1', 'datasetId', datasetId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{dataset_id}/new-analyzer-tasks/start-all`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"dataset_id"}}`, encodeURIComponent(String(datasetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will update workflow
         * @summary Update dataset registration object
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {DatasetRegistrationWorkflowModel} datasetRegistrationWorkflowModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacePatchDatasetWorkflowV1: async (workspaceId: string, workflowId: string, datasetRegistrationWorkflowModel: DatasetRegistrationWorkflowModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspacePatchDatasetWorkflowV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('workspacePatchDatasetWorkflowV1', 'workflowId', workflowId)
            // verify required parameter 'datasetRegistrationWorkflowModel' is not null or undefined
            assertParamExists('workspacePatchDatasetWorkflowV1', 'datasetRegistrationWorkflowModel', datasetRegistrationWorkflowModel)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/dataset/workflow/{workflow_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetRegistrationWorkflowModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save datafamily of corresponding dataset workflow_id
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceRegisterDatasetV1: async (workspaceId: string, workflowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceRegisterDatasetV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('workspaceRegisterDatasetV1', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflow/{workflow_id}/dataset/register`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Editable fields of the dataset i.e name, description using this api.
         * @param {string} workspaceId 
         * @param {string} dsId 
         * @param {UpdateDatasetEditableFieldsRequest} updateDatasetEditableFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceUpdateDatasetEditableFieldsV1: async (workspaceId: string, dsId: string, updateDatasetEditableFieldsRequest: UpdateDatasetEditableFieldsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceUpdateDatasetEditableFieldsV1', 'workspaceId', workspaceId)
            // verify required parameter 'dsId' is not null or undefined
            assertParamExists('workspaceUpdateDatasetEditableFieldsV1', 'dsId', dsId)
            // verify required parameter 'updateDatasetEditableFieldsRequest' is not null or undefined
            assertParamExists('workspaceUpdateDatasetEditableFieldsV1', 'updateDatasetEditableFieldsRequest', updateDatasetEditableFieldsRequest)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/datasets/{ds_id}/edit-fields`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"ds_id"}}`, encodeURIComponent(String(dsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDatasetEditableFieldsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save dataset and authenticate segment URLs
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceVerifyDatasetAccessV1: async (workspaceId: string, workflowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceVerifyDatasetAccessV1', 'workspaceId', workspaceId)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('workspaceVerifyDatasetAccessV1', 'workflowId', workflowId)
            const localVarPath = `/vienna/v1/workspace/{workspace_id}/workflow/{workflow_id}/dataset/verify-access`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"workflow_id"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspaceDatasetRoutesApi - functional programming interface
 * @export
 */
export const WorkspaceDatasetRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspaceDatasetRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns class labels associated with the given dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetLabelsV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetLabelsInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetLabelsV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the sample information associated to given sample_id
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} sampleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetSampleV1(workspaceId: string, datasetId: string, sampleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDatasetSampleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetSampleV1(workspaceId, datasetId, sampleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Failed Analysis Task Retrigger
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceAnalysisTaskRetriggerV1(workspaceId: string, datasetId: string, taskId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisRetriggerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceAnalysisTaskRetriggerV1(workspaceId, datasetId, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Re-trigger the analyser using tasklet_id. This is version2 where earlier one takes task id here it takes tasklet id
         * @summary Re-trigger the analyser using tasklet_id
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceAnalysisTaskRetriggerV2(workspaceId: string, datasetId: string, taskletId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisRetriggerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceAnalysisTaskRetriggerV2(workspaceId, datasetId, taskletId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Completes a multi part upload for uploading a dataset to s3.
         * @param {string} workspaceId 
         * @param {ServicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest} servicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceCompleteDatasetMultipartUploadV1(workspaceId: string, servicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest: ServicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceCompleteDatasetMultipartUploadV1(workspaceId, servicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a data family
         * @param {string} workspaceId 
         * @param {CreateDataFamilyRequest} createDataFamilyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceCreateDataFamilyV1(workspaceId: string, createDataFamilyRequest: CreateDataFamilyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDataFamilyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceCreateDataFamilyV1(workspaceId, createDataFamilyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a pre-signed s3 url for uploading a dataset.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {CreateUploadUrlRequest} createUploadUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceCreateDatasetUploadUrlV1(workspaceId: string, workflowId: string, createUploadUrlRequest: CreateUploadUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceCreateDatasetUploadUrlV1(workspaceId, workflowId, createUploadUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a workflow
         * @param {string} workspaceId 
         * @param {CreateWorkflowRequest} createWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceCreateWorkflowV1(workspaceId: string, createWorkflowRequest: CreateWorkflowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDatasetRegistrationWorkflowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceCreateWorkflowV1(workspaceId, createWorkflowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Trigger a compare run between a primary dataset, and multiple secondary datasets.
         * @param {string} workspaceId 
         * @param {DataSetPair} dataSetPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceDataCompareV1(workspaceId: string, dataSetPair: DataSetPair, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCompareResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceDataCompareV1(workspaceId, dataSetPair, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Given a list of dataset ids return if they are valid
         * @param {string} workspaceId 
         * @param {string} dsIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceDataSetValidStatusV1(workspaceId: string, dsIds: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DSExistResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceDataSetValidStatusV1(workspaceId, dsIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns info associated with for list of dataset ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [dsId] 
         * @param {Array<string>} [dsName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceDataSetsInfoBulkV1(workspaceId: string, dsId?: Array<string>, dsName?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDatasetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceDataSetsInfoBulkV1(workspaceId, dsId, dsName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Archive dataset resource Args:     update_request:         list{dataset_id}:         artifact_state: ARCHIVED/ACTIVE     workspace_id:     user:  Returns:
         * @summary Update datasets artifact state
         * @param {string} workspaceId 
         * @param {UpdateDatasetArtifactState} updateDatasetArtifactState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceDatasetStateChangeV1(workspaceId: string, updateDatasetArtifactState: UpdateDatasetArtifactState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateChangeAck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceDatasetStateChangeV1(workspaceId, updateDatasetArtifactState, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Failed Analysis Task Retrigger All
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceFailedAnalysisTaskRetriggerV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisRetriggerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceFailedAnalysisTaskRetriggerV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns data for a graph.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} analysisId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetAnalysisDataV1(workspaceId: string, datasetId: string, analysisId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnalysisResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetAnalysisDataV1(workspaceId, datasetId, analysisId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary paginated API to get a list of analysis tasks with their status for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {AnalysisStateNames} [statusFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetAnalysisTasksResponseV1(workspaceId: string, datasetId: string, start?: number, end?: number, statusFilter?: AnalysisStateNames, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnalysisTasksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetAnalysisTasksResponseV1(workspaceId, datasetId, start, end, statusFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns model performance metrics like precision,accuracy of the baseline model trained on this dataset
         * @summary Returns baseline model metrics
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetBaselineMetricsV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaselineMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetBaselineMetricsV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns baseline model tasklet status for the given dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetBaselineModelTaskletStatusV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBaselineModelTaskletStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetBaselineModelTaskletStatusV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns class level data quality metrics for the given dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetClassDataQualityV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataQualityClassResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetClassDataQualityV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return the segment level similarity given primary, compare_with dataset ids and respective segments 
         * @param {string} workspaceId 
         * @param {DataSetSegmentSimilarity} dataSetSegmentSimilarity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetCrossDataSetSegmentSimilarityV1(workspaceId: string, dataSetSegmentSimilarity: DataSetSegmentSimilarity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSetPairSegmentSimilarityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetCrossDataSetSegmentSimilarityV1(workspaceId, dataSetSegmentSimilarity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return the dataset similarity across all segment combinations (cross product of primary with all compare_with datasets)
         * @param {string} workspaceId 
         * @param {DataSetPair} dataSetPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetCrossDataSetSimilarityV1(workspaceId: string, dataSetPair: DataSetPair, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSetMultiPairResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetCrossDataSetSimilarityV1(workspaceId, dataSetPair, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of data families
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDataFamilyListV1(workspaceId: string, start?: number, end?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDatasetFamilyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDataFamilyListV1(workspaceId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns data quality score analyser status for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDataQualityStatusV1(datasetId: string, workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDataQualityStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDataQualityStatusV1(datasetId, workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns info associated with a dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {boolean} [includeFeatureColumns] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDataSetsInfoV1(datasetId: string, workspaceId: string, includeFeatureColumns?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDataSetsInfoV1(datasetId, workspaceId, includeFeatureColumns, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the datasets.
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [dataFamilyName] 
         * @param {string} [dataFamilyId] 
         * @param {string} [dataType] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDataSetsListV1(workspaceId: string, start?: number, end?: number, ts?: string, dataFamilyName?: string, dataFamilyId?: string, dataType?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDatasetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDataSetsListV1(workspaceId, start, end, ts, dataFamilyName, dataFamilyId, dataType, name, userIds, excludeUserIds, recordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns class distribution in the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDatasetClassDistributionV1(datasetId: string, workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetClassDistributionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDatasetClassDistributionV1(datasetId, workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the correlation info types analyzed for the dataset segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDatasetCorrelationInfoTypesV1(workspaceId: string, datasetId: string, segmentType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetCorrelationInfoTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDatasetCorrelationInfoTypesV1(workspaceId, datasetId, segmentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the correlation info b/w every pair of variables in the dataset segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {string} correlationType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDatasetCorrelationInfoV1(workspaceId: string, datasetId: string, segmentType: string, correlationType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetCorrelationInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDatasetCorrelationInfoV1(workspaceId, datasetId, segmentType, correlationType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of delimiters for dataset
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDatasetDelimiterDataV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDatasetDelimiterDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDatasetDelimiterDataV1(workspaceId, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the missing values for each variable in dataset segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDatasetMissingValuesV1(workspaceId: string, datasetId: string, segmentType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetMissingValuesBarChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDatasetMissingValuesV1(workspaceId, datasetId, segmentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dataset preview data snapshot
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDatasetPreviewDataV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDatasetPreviewDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDatasetPreviewDataV1(workspaceId, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDatasetRegisterWorkflowV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDatasetRegistrationWorkflowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDatasetRegisterWorkflowV1(workspaceId, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the overview for the dataset summary.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDatasetSummaryOverviewV1(workspaceId: string, datasetId: string, segmentType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDatasetSummaryOverviewV1(workspaceId, datasetId, segmentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns dataset topology as table dict
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDatasetTopologyV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetTopologyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDatasetTopologyV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the list of variables used in the given dataset and segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {string} variable 
         * @param {string} visualizationId 
         * @param {boolean} [groupByTarget] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDatasetVariableChartDataV1(workspaceId: string, datasetId: string, segmentType: string, variable: string, visualizationId: string, groupByTarget?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetVariableChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDatasetVariableChartDataV1(workspaceId, datasetId, segmentType, variable, visualizationId, groupByTarget, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the list of variables used in the given dataset and segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {string} variable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDatasetVariableLayoutV1(workspaceId: string, datasetId: string, segmentType: string, variable: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetVariablesLayoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDatasetVariableLayoutV1(workspaceId, datasetId, segmentType, variable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the list of variables used in the given dataset and segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {string} variable 
         * @param {string} visualizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDatasetVariableTableDataV1(workspaceId: string, datasetId: string, segmentType: string, variable: string, visualizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetVariableTableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDatasetVariableTableDataV1(workspaceId, datasetId, segmentType, variable, visualizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the list of variables used in the given dataset and segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDatasetVariablesV1(workspaceId: string, datasetId: string, segmentType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetVariablesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDatasetVariablesV1(workspaceId, datasetId, segmentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns status of data profiling group by wrt target
         * @summary Returns status of data profiling group by wrt target
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetGroupByStatusV1(workspaceId: string, datasetId: string, segmentType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupByTargetStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetGroupByStatusV1(workspaceId, datasetId, segmentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Given list of dataset_ids return the class distribution of the dataset
         * @param {string} workspaceId 
         * @param {string} segmentType 
         * @param {string} dsIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetMultiDSClassDistributionV1(workspaceId: string, segmentType: string, dsIds: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DSAnalysisSegmentClassDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetMultiDSClassDistributionV1(workspaceId, segmentType, dsIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets list of new analyzer tasks for a registered dataset. New analyzer tasks are tasks present in config but not in run record for dataset
         * @summary API to get a list of new analyzer tasks for a registered dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetNewAnalyzersV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewTasksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetNewAnalyzersV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the pairs of classes that are often mislabeled as one another, The number of points in one class mislabeled as the other and its joint probability
         * @summary Returns overlapping class metrics
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [pageNo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetOverlappingClassMetricsV1(workspaceId: string, datasetId: string, pageNo?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassOverlapInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetOverlappingClassMetricsV1(workspaceId, datasetId, pageNo, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dataset preview data snapshot
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetRegisteredDatasetPreviewV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDatasetPreviewDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetRegisteredDatasetPreviewV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Contains the result of a Similarity Runs for different data segments. The relations between primary and secondary should not be assumed to be symmetric i.e. A->B is not B->A
         * @summary Get segment comparison data
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetSegmentComparisonV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSegmentComparisonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetSegmentComparisonV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return the similarity metrics that will be applied for comparison of datasets
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetSimilarityMetricNamesV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimMetricsNameResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetSimilarityMetricNamesV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return the status of this comparison run given a primary ds_id (dataset id) and list ofcompare with ids. 
         * @param {string} workspaceId 
         * @param {DataSetPair} dataSetPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetSimilarityRunStatusV1(workspaceId: string, dataSetPair: DataSetPair, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimilarityRunStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetSimilarityRunStatusV1(workspaceId, dataSetPair, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the top k features of the dataset along with its corresponding metric, k is not fixed here
         * @summary Returns topk features of the dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetTopKFeaturesV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopKFeaturesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetTopKFeaturesV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of data_operations.
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {WorkflowMode} [modeFilter] 
         * @param {RegistrationFlowStatus} [statusFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetWorkflowListV1(workspaceId: string, start?: number, end?: number, ts?: string, modeFilter?: WorkflowMode, statusFilter?: RegistrationFlowStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDatasetRegistrationWorkflowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetWorkflowListV1(workspaceId, start, end, ts, modeFilter, statusFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns grouping info for Analysis, e.g. n-grams, clustering, etc.Each analysis group can have one or more type of visualizations.
         * @summary Returns grouping info for Analysis, e.g. n-grams, clustering, etc.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceListAnalysisGroupedByMetricsV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAnalysisGroupsByMetricResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceListAnalysisGroupedByMetricsV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns grouping info for Analysis, e.g. n-grams, clustering, etc.Each analysis group can have one or more type of visualizations.Deprecation Notice:In the hierarchy metrics come before segment. Use ListAnalysisGroupedByMetricsV1 instead of this API.
         * @summary Returns grouping info for Analysis, e.g. n-grams, clustering, etc.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async workspaceListAnalysisGroupsV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAnalysisGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceListAnalysisGroupsV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns list containing metadata of graphs in an analysis group. Deprecation Notice:In the hierarchy metrics come before segment. Use ListAnalysisMetricsV1 instead of this API.
         * @summary Returns list containing metadata of graphs in an analysis group.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} analysisGroupId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async workspaceListAnalysisMetadataV1(workspaceId: string, datasetId: string, analysisGroupId: string, segmentType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAnalysisMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceListAnalysisMetadataV1(workspaceId, datasetId, analysisGroupId, segmentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list containing metadata of graphs in an analysis group.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} analysisGroupId 
         * @param {string} metricName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceListAnalysisMetricsV1(workspaceId: string, datasetId: string, analysisGroupId: string, metricName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAnalysisMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceListAnalysisMetricsV1(workspaceId, datasetId, analysisGroupId, metricName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of all data stores
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceListDataStoresV1(workspaceId: string, start?: number, end?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDataStoresResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceListDataStoresV1(workspaceId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Executes new analyzer tasks for a registered dataset
         * @summary Start new analyzers tasks on registered dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceNewAnalyzersStartV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerNewTasksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceNewAnalyzersStartV1(workspaceId, datasetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will update workflow
         * @summary Update dataset registration object
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {DatasetRegistrationWorkflowModel} datasetRegistrationWorkflowModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspacePatchDatasetWorkflowV1(workspaceId: string, workflowId: string, datasetRegistrationWorkflowModel: DatasetRegistrationWorkflowModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDatasetRegistrationWorkflowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspacePatchDatasetWorkflowV1(workspaceId, workflowId, datasetRegistrationWorkflowModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save datafamily of corresponding dataset workflow_id
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceRegisterDatasetV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceRegisterDatasetV1(workspaceId, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Editable fields of the dataset i.e name, description using this api.
         * @param {string} workspaceId 
         * @param {string} dsId 
         * @param {UpdateDatasetEditableFieldsRequest} updateDatasetEditableFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceUpdateDatasetEditableFieldsV1(workspaceId: string, dsId: string, updateDatasetEditableFieldsRequest: UpdateDatasetEditableFieldsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDatasetEditableFieldsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceUpdateDatasetEditableFieldsV1(workspaceId, dsId, updateDatasetEditableFieldsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save dataset and authenticate segment URLs
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceVerifyDatasetAccessV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyDatasetAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceVerifyDatasetAccessV1(workspaceId, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkspaceDatasetRoutesApi - factory interface
 * @export
 */
export const WorkspaceDatasetRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspaceDatasetRoutesApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns class labels associated with the given dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetLabelsV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<DatasetLabelsInfo> {
            return localVarFp.getDatasetLabelsV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the sample information associated to given sample_id
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} sampleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetSampleV1(workspaceId: string, datasetId: string, sampleId: string, options?: any): AxiosPromise<GetDatasetSampleResponse> {
            return localVarFp.getDatasetSampleV1(workspaceId, datasetId, sampleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Failed Analysis Task Retrigger
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceAnalysisTaskRetriggerV1(workspaceId: string, datasetId: string, taskId: string, options?: any): AxiosPromise<AnalysisRetriggerResponse> {
            return localVarFp.workspaceAnalysisTaskRetriggerV1(workspaceId, datasetId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * Re-trigger the analyser using tasklet_id. This is version2 where earlier one takes task id here it takes tasklet id
         * @summary Re-trigger the analyser using tasklet_id
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} taskletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceAnalysisTaskRetriggerV2(workspaceId: string, datasetId: string, taskletId: string, options?: any): AxiosPromise<AnalysisRetriggerResponse> {
            return localVarFp.workspaceAnalysisTaskRetriggerV2(workspaceId, datasetId, taskletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Completes a multi part upload for uploading a dataset to s3.
         * @param {string} workspaceId 
         * @param {ServicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest} servicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceCompleteDatasetMultipartUploadV1(workspaceId: string, servicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest: ServicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest, options?: any): AxiosPromise<any> {
            return localVarFp.workspaceCompleteDatasetMultipartUploadV1(workspaceId, servicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a data family
         * @param {string} workspaceId 
         * @param {CreateDataFamilyRequest} createDataFamilyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceCreateDataFamilyV1(workspaceId: string, createDataFamilyRequest: CreateDataFamilyRequest, options?: any): AxiosPromise<CreateDataFamilyResponse> {
            return localVarFp.workspaceCreateDataFamilyV1(workspaceId, createDataFamilyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a pre-signed s3 url for uploading a dataset.
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {CreateUploadUrlRequest} createUploadUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceCreateDatasetUploadUrlV1(workspaceId: string, workflowId: string, createUploadUrlRequest: CreateUploadUrlRequest, options?: any): AxiosPromise<UrlResponse> {
            return localVarFp.workspaceCreateDatasetUploadUrlV1(workspaceId, workflowId, createUploadUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a workflow
         * @param {string} workspaceId 
         * @param {CreateWorkflowRequest} createWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceCreateWorkflowV1(workspaceId: string, createWorkflowRequest: CreateWorkflowRequest, options?: any): AxiosPromise<CreateDatasetRegistrationWorkflowResponse> {
            return localVarFp.workspaceCreateWorkflowV1(workspaceId, createWorkflowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger a compare run between a primary dataset, and multiple secondary datasets.
         * @param {string} workspaceId 
         * @param {DataSetPair} dataSetPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceDataCompareV1(workspaceId: string, dataSetPair: DataSetPair, options?: any): AxiosPromise<DataCompareResponse> {
            return localVarFp.workspaceDataCompareV1(workspaceId, dataSetPair, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Given a list of dataset ids return if they are valid
         * @param {string} workspaceId 
         * @param {string} dsIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceDataSetValidStatusV1(workspaceId: string, dsIds: string, options?: any): AxiosPromise<DSExistResponse> {
            return localVarFp.workspaceDataSetValidStatusV1(workspaceId, dsIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns info associated with for list of dataset ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [dsId] 
         * @param {Array<string>} [dsName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceDataSetsInfoBulkV1(workspaceId: string, dsId?: Array<string>, dsName?: Array<string>, options?: any): AxiosPromise<ListDatasetsResponse> {
            return localVarFp.workspaceDataSetsInfoBulkV1(workspaceId, dsId, dsName, options).then((request) => request(axios, basePath));
        },
        /**
         * Archive dataset resource Args:     update_request:         list{dataset_id}:         artifact_state: ARCHIVED/ACTIVE     workspace_id:     user:  Returns:
         * @summary Update datasets artifact state
         * @param {string} workspaceId 
         * @param {UpdateDatasetArtifactState} updateDatasetArtifactState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceDatasetStateChangeV1(workspaceId: string, updateDatasetArtifactState: UpdateDatasetArtifactState, options?: any): AxiosPromise<StateChangeAck> {
            return localVarFp.workspaceDatasetStateChangeV1(workspaceId, updateDatasetArtifactState, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Failed Analysis Task Retrigger All
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceFailedAnalysisTaskRetriggerV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<AnalysisRetriggerResponse> {
            return localVarFp.workspaceFailedAnalysisTaskRetriggerV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns data for a graph.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} analysisId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetAnalysisDataV1(workspaceId: string, datasetId: string, analysisId: string, options?: any): AxiosPromise<GetAnalysisResponse> {
            return localVarFp.workspaceGetAnalysisDataV1(workspaceId, datasetId, analysisId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary paginated API to get a list of analysis tasks with their status for a dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {AnalysisStateNames} [statusFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetAnalysisTasksResponseV1(workspaceId: string, datasetId: string, start?: number, end?: number, statusFilter?: AnalysisStateNames, options?: any): AxiosPromise<GetAnalysisTasksResponse> {
            return localVarFp.workspaceGetAnalysisTasksResponseV1(workspaceId, datasetId, start, end, statusFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns model performance metrics like precision,accuracy of the baseline model trained on this dataset
         * @summary Returns baseline model metrics
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetBaselineMetricsV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<BaselineMetricsResponse> {
            return localVarFp.workspaceGetBaselineMetricsV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns baseline model tasklet status for the given dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetBaselineModelTaskletStatusV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<GetBaselineModelTaskletStatusResponse> {
            return localVarFp.workspaceGetBaselineModelTaskletStatusV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns class level data quality metrics for the given dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetClassDataQualityV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<DataQualityClassResponse> {
            return localVarFp.workspaceGetClassDataQualityV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the segment level similarity given primary, compare_with dataset ids and respective segments 
         * @param {string} workspaceId 
         * @param {DataSetSegmentSimilarity} dataSetSegmentSimilarity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetCrossDataSetSegmentSimilarityV1(workspaceId: string, dataSetSegmentSimilarity: DataSetSegmentSimilarity, options?: any): AxiosPromise<DataSetPairSegmentSimilarityResponse> {
            return localVarFp.workspaceGetCrossDataSetSegmentSimilarityV1(workspaceId, dataSetSegmentSimilarity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the dataset similarity across all segment combinations (cross product of primary with all compare_with datasets)
         * @param {string} workspaceId 
         * @param {DataSetPair} dataSetPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetCrossDataSetSimilarityV1(workspaceId: string, dataSetPair: DataSetPair, options?: any): AxiosPromise<DataSetMultiPairResponse> {
            return localVarFp.workspaceGetCrossDataSetSimilarityV1(workspaceId, dataSetPair, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of data families
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDataFamilyListV1(workspaceId: string, start?: number, end?: number, options?: any): AxiosPromise<ListDatasetFamilyResponse> {
            return localVarFp.workspaceGetDataFamilyListV1(workspaceId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns data quality score analyser status for the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDataQualityStatusV1(datasetId: string, workspaceId: string, options?: any): AxiosPromise<GetDataQualityStatusResponse> {
            return localVarFp.workspaceGetDataQualityStatusV1(datasetId, workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns info associated with a dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {boolean} [includeFeatureColumns] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDataSetsInfoV1(datasetId: string, workspaceId: string, includeFeatureColumns?: boolean, options?: any): AxiosPromise<DatasetInfoResponse> {
            return localVarFp.workspaceGetDataSetsInfoV1(datasetId, workspaceId, includeFeatureColumns, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the datasets.
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {string} [dataFamilyName] 
         * @param {string} [dataFamilyId] 
         * @param {string} [dataType] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDataSetsListV1(workspaceId: string, start?: number, end?: number, ts?: string, dataFamilyName?: string, dataFamilyId?: string, dataType?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<ListDatasetsResponse> {
            return localVarFp.workspaceGetDataSetsListV1(workspaceId, start, end, ts, dataFamilyName, dataFamilyId, dataType, name, userIds, excludeUserIds, recordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns class distribution in the dataset.
         * @param {string} datasetId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetClassDistributionV1(datasetId: string, workspaceId: string, options?: any): AxiosPromise<DatasetClassDistributionResponse> {
            return localVarFp.workspaceGetDatasetClassDistributionV1(datasetId, workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the correlation info types analyzed for the dataset segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetCorrelationInfoTypesV1(workspaceId: string, datasetId: string, segmentType: string, options?: any): AxiosPromise<DatasetCorrelationInfoTypesResponse> {
            return localVarFp.workspaceGetDatasetCorrelationInfoTypesV1(workspaceId, datasetId, segmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the correlation info b/w every pair of variables in the dataset segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {string} correlationType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetCorrelationInfoV1(workspaceId: string, datasetId: string, segmentType: string, correlationType: string, options?: any): AxiosPromise<DatasetCorrelationInfoResponse> {
            return localVarFp.workspaceGetDatasetCorrelationInfoV1(workspaceId, datasetId, segmentType, correlationType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of delimiters for dataset
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetDelimiterDataV1(workspaceId: string, workflowId: string, options?: any): AxiosPromise<GetDatasetDelimiterDataResponse> {
            return localVarFp.workspaceGetDatasetDelimiterDataV1(workspaceId, workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the missing values for each variable in dataset segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetMissingValuesV1(workspaceId: string, datasetId: string, segmentType: string, options?: any): AxiosPromise<DatasetMissingValuesBarChartResponse> {
            return localVarFp.workspaceGetDatasetMissingValuesV1(workspaceId, datasetId, segmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dataset preview data snapshot
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetPreviewDataV1(workspaceId: string, workflowId: string, options?: any): AxiosPromise<GetDatasetPreviewDataResponse> {
            return localVarFp.workspaceGetDatasetPreviewDataV1(workspaceId, workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a workflow
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetRegisterWorkflowV1(workspaceId: string, workflowId: string, options?: any): AxiosPromise<CreateDatasetRegistrationWorkflowResponse> {
            return localVarFp.workspaceGetDatasetRegisterWorkflowV1(workspaceId, workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the overview for the dataset summary.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetSummaryOverviewV1(workspaceId: string, datasetId: string, segmentType: string, options?: any): AxiosPromise<DatasetOverviewResponse> {
            return localVarFp.workspaceGetDatasetSummaryOverviewV1(workspaceId, datasetId, segmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns dataset topology as table dict
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetTopologyV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<DatasetTopologyResponse> {
            return localVarFp.workspaceGetDatasetTopologyV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list of variables used in the given dataset and segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {string} variable 
         * @param {string} visualizationId 
         * @param {boolean} [groupByTarget] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetVariableChartDataV1(workspaceId: string, datasetId: string, segmentType: string, variable: string, visualizationId: string, groupByTarget?: boolean, options?: any): AxiosPromise<DatasetVariableChartResponse> {
            return localVarFp.workspaceGetDatasetVariableChartDataV1(workspaceId, datasetId, segmentType, variable, visualizationId, groupByTarget, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list of variables used in the given dataset and segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {string} variable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetVariableLayoutV1(workspaceId: string, datasetId: string, segmentType: string, variable: string, options?: any): AxiosPromise<DatasetVariablesLayoutResponse> {
            return localVarFp.workspaceGetDatasetVariableLayoutV1(workspaceId, datasetId, segmentType, variable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list of variables used in the given dataset and segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {string} variable 
         * @param {string} visualizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetVariableTableDataV1(workspaceId: string, datasetId: string, segmentType: string, variable: string, visualizationId: string, options?: any): AxiosPromise<DatasetVariableTableResponse> {
            return localVarFp.workspaceGetDatasetVariableTableDataV1(workspaceId, datasetId, segmentType, variable, visualizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list of variables used in the given dataset and segment.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDatasetVariablesV1(workspaceId: string, datasetId: string, segmentType: string, options?: any): AxiosPromise<DatasetVariablesListResponse> {
            return localVarFp.workspaceGetDatasetVariablesV1(workspaceId, datasetId, segmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns status of data profiling group by wrt target
         * @summary Returns status of data profiling group by wrt target
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetGroupByStatusV1(workspaceId: string, datasetId: string, segmentType: string, options?: any): AxiosPromise<GroupByTargetStatusResponse> {
            return localVarFp.workspaceGetGroupByStatusV1(workspaceId, datasetId, segmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Given list of dataset_ids return the class distribution of the dataset
         * @param {string} workspaceId 
         * @param {string} segmentType 
         * @param {string} dsIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetMultiDSClassDistributionV1(workspaceId: string, segmentType: string, dsIds: string, options?: any): AxiosPromise<DSAnalysisSegmentClassDetailResponse> {
            return localVarFp.workspaceGetMultiDSClassDistributionV1(workspaceId, segmentType, dsIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets list of new analyzer tasks for a registered dataset. New analyzer tasks are tasks present in config but not in run record for dataset
         * @summary API to get a list of new analyzer tasks for a registered dataset.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetNewAnalyzersV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<GetNewTasksResponse> {
            return localVarFp.workspaceGetNewAnalyzersV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the pairs of classes that are often mislabeled as one another, The number of points in one class mislabeled as the other and its joint probability
         * @summary Returns overlapping class metrics
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {number} [pageNo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetOverlappingClassMetricsV1(workspaceId: string, datasetId: string, pageNo?: number, limit?: number, options?: any): AxiosPromise<ClassOverlapInfoResponse> {
            return localVarFp.workspaceGetOverlappingClassMetricsV1(workspaceId, datasetId, pageNo, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dataset preview data snapshot
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetRegisteredDatasetPreviewV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<GetDatasetPreviewDataResponse> {
            return localVarFp.workspaceGetRegisteredDatasetPreviewV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Contains the result of a Similarity Runs for different data segments. The relations between primary and secondary should not be assumed to be symmetric i.e. A->B is not B->A
         * @summary Get segment comparison data
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetSegmentComparisonV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<GetSegmentComparisonResponse> {
            return localVarFp.workspaceGetSegmentComparisonV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the similarity metrics that will be applied for comparison of datasets
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetSimilarityMetricNamesV1(workspaceId: string, options?: any): AxiosPromise<SimMetricsNameResponse> {
            return localVarFp.workspaceGetSimilarityMetricNamesV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the status of this comparison run given a primary ds_id (dataset id) and list ofcompare with ids. 
         * @param {string} workspaceId 
         * @param {DataSetPair} dataSetPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetSimilarityRunStatusV1(workspaceId: string, dataSetPair: DataSetPair, options?: any): AxiosPromise<SimilarityRunStatus> {
            return localVarFp.workspaceGetSimilarityRunStatusV1(workspaceId, dataSetPair, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the top k features of the dataset along with its corresponding metric, k is not fixed here
         * @summary Returns topk features of the dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetTopKFeaturesV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<TopKFeaturesResponse> {
            return localVarFp.workspaceGetTopKFeaturesV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of data_operations.
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [ts] 
         * @param {WorkflowMode} [modeFilter] 
         * @param {RegistrationFlowStatus} [statusFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetWorkflowListV1(workspaceId: string, start?: number, end?: number, ts?: string, modeFilter?: WorkflowMode, statusFilter?: RegistrationFlowStatus, options?: any): AxiosPromise<ListDatasetRegistrationWorkflowResponse> {
            return localVarFp.workspaceGetWorkflowListV1(workspaceId, start, end, ts, modeFilter, statusFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns grouping info for Analysis, e.g. n-grams, clustering, etc.Each analysis group can have one or more type of visualizations.
         * @summary Returns grouping info for Analysis, e.g. n-grams, clustering, etc.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceListAnalysisGroupedByMetricsV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<ListAnalysisGroupsByMetricResponse> {
            return localVarFp.workspaceListAnalysisGroupedByMetricsV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns grouping info for Analysis, e.g. n-grams, clustering, etc.Each analysis group can have one or more type of visualizations.Deprecation Notice:In the hierarchy metrics come before segment. Use ListAnalysisGroupedByMetricsV1 instead of this API.
         * @summary Returns grouping info for Analysis, e.g. n-grams, clustering, etc.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        workspaceListAnalysisGroupsV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<ListAnalysisGroupsResponse> {
            return localVarFp.workspaceListAnalysisGroupsV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list containing metadata of graphs in an analysis group. Deprecation Notice:In the hierarchy metrics come before segment. Use ListAnalysisMetricsV1 instead of this API.
         * @summary Returns list containing metadata of graphs in an analysis group.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} analysisGroupId 
         * @param {string} segmentType 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        workspaceListAnalysisMetadataV1(workspaceId: string, datasetId: string, analysisGroupId: string, segmentType: string, options?: any): AxiosPromise<ListAnalysisMetadataResponse> {
            return localVarFp.workspaceListAnalysisMetadataV1(workspaceId, datasetId, analysisGroupId, segmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list containing metadata of graphs in an analysis group.
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {string} analysisGroupId 
         * @param {string} metricName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceListAnalysisMetricsV1(workspaceId: string, datasetId: string, analysisGroupId: string, metricName: string, options?: any): AxiosPromise<ListAnalysisMetricsResponse> {
            return localVarFp.workspaceListAnalysisMetricsV1(workspaceId, datasetId, analysisGroupId, metricName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of all data stores
         * @param {string} workspaceId 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceListDataStoresV1(workspaceId: string, start?: number, end?: number, options?: any): AxiosPromise<ListDataStoresResponse> {
            return localVarFp.workspaceListDataStoresV1(workspaceId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * Executes new analyzer tasks for a registered dataset
         * @summary Start new analyzers tasks on registered dataset
         * @param {string} workspaceId 
         * @param {string} datasetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceNewAnalyzersStartV1(workspaceId: string, datasetId: string, options?: any): AxiosPromise<TriggerNewTasksResponse> {
            return localVarFp.workspaceNewAnalyzersStartV1(workspaceId, datasetId, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will update workflow
         * @summary Update dataset registration object
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {DatasetRegistrationWorkflowModel} datasetRegistrationWorkflowModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacePatchDatasetWorkflowV1(workspaceId: string, workflowId: string, datasetRegistrationWorkflowModel: DatasetRegistrationWorkflowModel, options?: any): AxiosPromise<UpdateDatasetRegistrationWorkflowResponse> {
            return localVarFp.workspacePatchDatasetWorkflowV1(workspaceId, workflowId, datasetRegistrationWorkflowModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save datafamily of corresponding dataset workflow_id
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceRegisterDatasetV1(workspaceId: string, workflowId: string, options?: any): AxiosPromise<DatasetInfoResponse> {
            return localVarFp.workspaceRegisterDatasetV1(workspaceId, workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Editable fields of the dataset i.e name, description using this api.
         * @param {string} workspaceId 
         * @param {string} dsId 
         * @param {UpdateDatasetEditableFieldsRequest} updateDatasetEditableFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceUpdateDatasetEditableFieldsV1(workspaceId: string, dsId: string, updateDatasetEditableFieldsRequest: UpdateDatasetEditableFieldsRequest, options?: any): AxiosPromise<UpdateDatasetEditableFieldsResponse> {
            return localVarFp.workspaceUpdateDatasetEditableFieldsV1(workspaceId, dsId, updateDatasetEditableFieldsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save dataset and authenticate segment URLs
         * @param {string} workspaceId 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceVerifyDatasetAccessV1(workspaceId: string, workflowId: string, options?: any): AxiosPromise<VerifyDatasetAccessResponse> {
            return localVarFp.workspaceVerifyDatasetAccessV1(workspaceId, workflowId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspaceDatasetRoutesApi - object-oriented interface
 * @export
 * @class WorkspaceDatasetRoutesApi
 * @extends {BaseAPI}
 */
export class WorkspaceDatasetRoutesApi extends BaseAPI {
    /**
     * 
     * @summary Returns class labels associated with the given dataset.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public getDatasetLabelsV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).getDatasetLabelsV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the sample information associated to given sample_id
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} sampleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public getDatasetSampleV1(workspaceId: string, datasetId: string, sampleId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).getDatasetSampleV1(workspaceId, datasetId, sampleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Failed Analysis Task Retrigger
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceAnalysisTaskRetriggerV1(workspaceId: string, datasetId: string, taskId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceAnalysisTaskRetriggerV1(workspaceId, datasetId, taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Re-trigger the analyser using tasklet_id. This is version2 where earlier one takes task id here it takes tasklet id
     * @summary Re-trigger the analyser using tasklet_id
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} taskletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceAnalysisTaskRetriggerV2(workspaceId: string, datasetId: string, taskletId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceAnalysisTaskRetriggerV2(workspaceId, datasetId, taskletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Completes a multi part upload for uploading a dataset to s3.
     * @param {string} workspaceId 
     * @param {ServicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest} servicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceCompleteDatasetMultipartUploadV1(workspaceId: string, servicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest: ServicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceCompleteDatasetMultipartUploadV1(workspaceId, servicesViennaApiModelsDatasetModelsCompleteMultipartUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a data family
     * @param {string} workspaceId 
     * @param {CreateDataFamilyRequest} createDataFamilyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceCreateDataFamilyV1(workspaceId: string, createDataFamilyRequest: CreateDataFamilyRequest, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceCreateDataFamilyV1(workspaceId, createDataFamilyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a pre-signed s3 url for uploading a dataset.
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {CreateUploadUrlRequest} createUploadUrlRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceCreateDatasetUploadUrlV1(workspaceId: string, workflowId: string, createUploadUrlRequest: CreateUploadUrlRequest, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceCreateDatasetUploadUrlV1(workspaceId, workflowId, createUploadUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a workflow
     * @param {string} workspaceId 
     * @param {CreateWorkflowRequest} createWorkflowRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceCreateWorkflowV1(workspaceId: string, createWorkflowRequest: CreateWorkflowRequest, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceCreateWorkflowV1(workspaceId, createWorkflowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trigger a compare run between a primary dataset, and multiple secondary datasets.
     * @param {string} workspaceId 
     * @param {DataSetPair} dataSetPair 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceDataCompareV1(workspaceId: string, dataSetPair: DataSetPair, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceDataCompareV1(workspaceId, dataSetPair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Given a list of dataset ids return if they are valid
     * @param {string} workspaceId 
     * @param {string} dsIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceDataSetValidStatusV1(workspaceId: string, dsIds: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceDataSetValidStatusV1(workspaceId, dsIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns info associated with for list of dataset ids.
     * @param {string} workspaceId 
     * @param {Array<string>} [dsId] 
     * @param {Array<string>} [dsName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceDataSetsInfoBulkV1(workspaceId: string, dsId?: Array<string>, dsName?: Array<string>, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceDataSetsInfoBulkV1(workspaceId, dsId, dsName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Archive dataset resource Args:     update_request:         list{dataset_id}:         artifact_state: ARCHIVED/ACTIVE     workspace_id:     user:  Returns:
     * @summary Update datasets artifact state
     * @param {string} workspaceId 
     * @param {UpdateDatasetArtifactState} updateDatasetArtifactState 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceDatasetStateChangeV1(workspaceId: string, updateDatasetArtifactState: UpdateDatasetArtifactState, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceDatasetStateChangeV1(workspaceId, updateDatasetArtifactState, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Failed Analysis Task Retrigger All
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceFailedAnalysisTaskRetriggerV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceFailedAnalysisTaskRetriggerV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns data for a graph.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} analysisId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetAnalysisDataV1(workspaceId: string, datasetId: string, analysisId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetAnalysisDataV1(workspaceId, datasetId, analysisId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary paginated API to get a list of analysis tasks with their status for a dataset.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {AnalysisStateNames} [statusFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetAnalysisTasksResponseV1(workspaceId: string, datasetId: string, start?: number, end?: number, statusFilter?: AnalysisStateNames, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetAnalysisTasksResponseV1(workspaceId, datasetId, start, end, statusFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns model performance metrics like precision,accuracy of the baseline model trained on this dataset
     * @summary Returns baseline model metrics
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetBaselineMetricsV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetBaselineMetricsV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns baseline model tasklet status for the given dataset
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetBaselineModelTaskletStatusV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetBaselineModelTaskletStatusV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns class level data quality metrics for the given dataset
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetClassDataQualityV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetClassDataQualityV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the segment level similarity given primary, compare_with dataset ids and respective segments 
     * @param {string} workspaceId 
     * @param {DataSetSegmentSimilarity} dataSetSegmentSimilarity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetCrossDataSetSegmentSimilarityV1(workspaceId: string, dataSetSegmentSimilarity: DataSetSegmentSimilarity, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetCrossDataSetSegmentSimilarityV1(workspaceId, dataSetSegmentSimilarity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the dataset similarity across all segment combinations (cross product of primary with all compare_with datasets)
     * @param {string} workspaceId 
     * @param {DataSetPair} dataSetPair 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetCrossDataSetSimilarityV1(workspaceId: string, dataSetPair: DataSetPair, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetCrossDataSetSimilarityV1(workspaceId, dataSetPair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of data families
     * @param {string} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDataFamilyListV1(workspaceId: string, start?: number, end?: number, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDataFamilyListV1(workspaceId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns data quality score analyser status for the dataset.
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDataQualityStatusV1(datasetId: string, workspaceId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDataQualityStatusV1(datasetId, workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns info associated with a dataset.
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {boolean} [includeFeatureColumns] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDataSetsInfoV1(datasetId: string, workspaceId: string, includeFeatureColumns?: boolean, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDataSetsInfoV1(datasetId, workspaceId, includeFeatureColumns, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the datasets.
     * @param {string} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {string} [dataFamilyName] 
     * @param {string} [dataFamilyId] 
     * @param {string} [dataType] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDataSetsListV1(workspaceId: string, start?: number, end?: number, ts?: string, dataFamilyName?: string, dataFamilyId?: string, dataType?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDataSetsListV1(workspaceId, start, end, ts, dataFamilyName, dataFamilyId, dataType, name, userIds, excludeUserIds, recordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns class distribution in the dataset.
     * @param {string} datasetId 
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDatasetClassDistributionV1(datasetId: string, workspaceId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDatasetClassDistributionV1(datasetId, workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the correlation info types analyzed for the dataset segment.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} segmentType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDatasetCorrelationInfoTypesV1(workspaceId: string, datasetId: string, segmentType: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDatasetCorrelationInfoTypesV1(workspaceId, datasetId, segmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the correlation info b/w every pair of variables in the dataset segment.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} segmentType 
     * @param {string} correlationType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDatasetCorrelationInfoV1(workspaceId: string, datasetId: string, segmentType: string, correlationType: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDatasetCorrelationInfoV1(workspaceId, datasetId, segmentType, correlationType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of delimiters for dataset
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDatasetDelimiterDataV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDatasetDelimiterDataV1(workspaceId, workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the missing values for each variable in dataset segment.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} segmentType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDatasetMissingValuesV1(workspaceId: string, datasetId: string, segmentType: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDatasetMissingValuesV1(workspaceId, datasetId, segmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dataset preview data snapshot
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDatasetPreviewDataV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDatasetPreviewDataV1(workspaceId, workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a workflow
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDatasetRegisterWorkflowV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDatasetRegisterWorkflowV1(workspaceId, workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the overview for the dataset summary.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} segmentType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDatasetSummaryOverviewV1(workspaceId: string, datasetId: string, segmentType: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDatasetSummaryOverviewV1(workspaceId, datasetId, segmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns dataset topology as table dict
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDatasetTopologyV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDatasetTopologyV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list of variables used in the given dataset and segment.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} segmentType 
     * @param {string} variable 
     * @param {string} visualizationId 
     * @param {boolean} [groupByTarget] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDatasetVariableChartDataV1(workspaceId: string, datasetId: string, segmentType: string, variable: string, visualizationId: string, groupByTarget?: boolean, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDatasetVariableChartDataV1(workspaceId, datasetId, segmentType, variable, visualizationId, groupByTarget, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list of variables used in the given dataset and segment.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} segmentType 
     * @param {string} variable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDatasetVariableLayoutV1(workspaceId: string, datasetId: string, segmentType: string, variable: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDatasetVariableLayoutV1(workspaceId, datasetId, segmentType, variable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list of variables used in the given dataset and segment.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} segmentType 
     * @param {string} variable 
     * @param {string} visualizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDatasetVariableTableDataV1(workspaceId: string, datasetId: string, segmentType: string, variable: string, visualizationId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDatasetVariableTableDataV1(workspaceId, datasetId, segmentType, variable, visualizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list of variables used in the given dataset and segment.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} segmentType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetDatasetVariablesV1(workspaceId: string, datasetId: string, segmentType: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetDatasetVariablesV1(workspaceId, datasetId, segmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns status of data profiling group by wrt target
     * @summary Returns status of data profiling group by wrt target
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} segmentType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetGroupByStatusV1(workspaceId: string, datasetId: string, segmentType: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetGroupByStatusV1(workspaceId, datasetId, segmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Given list of dataset_ids return the class distribution of the dataset
     * @param {string} workspaceId 
     * @param {string} segmentType 
     * @param {string} dsIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetMultiDSClassDistributionV1(workspaceId: string, segmentType: string, dsIds: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetMultiDSClassDistributionV1(workspaceId, segmentType, dsIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets list of new analyzer tasks for a registered dataset. New analyzer tasks are tasks present in config but not in run record for dataset
     * @summary API to get a list of new analyzer tasks for a registered dataset.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetNewAnalyzersV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetNewAnalyzersV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the pairs of classes that are often mislabeled as one another, The number of points in one class mislabeled as the other and its joint probability
     * @summary Returns overlapping class metrics
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {number} [pageNo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetOverlappingClassMetricsV1(workspaceId: string, datasetId: string, pageNo?: number, limit?: number, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetOverlappingClassMetricsV1(workspaceId, datasetId, pageNo, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dataset preview data snapshot
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetRegisteredDatasetPreviewV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetRegisteredDatasetPreviewV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Contains the result of a Similarity Runs for different data segments. The relations between primary and secondary should not be assumed to be symmetric i.e. A->B is not B->A
     * @summary Get segment comparison data
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetSegmentComparisonV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetSegmentComparisonV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the similarity metrics that will be applied for comparison of datasets
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetSimilarityMetricNamesV1(workspaceId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetSimilarityMetricNamesV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the status of this comparison run given a primary ds_id (dataset id) and list ofcompare with ids. 
     * @param {string} workspaceId 
     * @param {DataSetPair} dataSetPair 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetSimilarityRunStatusV1(workspaceId: string, dataSetPair: DataSetPair, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetSimilarityRunStatusV1(workspaceId, dataSetPair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the top k features of the dataset along with its corresponding metric, k is not fixed here
     * @summary Returns topk features of the dataset
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetTopKFeaturesV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetTopKFeaturesV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of data_operations.
     * @param {string} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [ts] 
     * @param {WorkflowMode} [modeFilter] 
     * @param {RegistrationFlowStatus} [statusFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceGetWorkflowListV1(workspaceId: string, start?: number, end?: number, ts?: string, modeFilter?: WorkflowMode, statusFilter?: RegistrationFlowStatus, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceGetWorkflowListV1(workspaceId, start, end, ts, modeFilter, statusFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns grouping info for Analysis, e.g. n-grams, clustering, etc.Each analysis group can have one or more type of visualizations.
     * @summary Returns grouping info for Analysis, e.g. n-grams, clustering, etc.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceListAnalysisGroupedByMetricsV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceListAnalysisGroupedByMetricsV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns grouping info for Analysis, e.g. n-grams, clustering, etc.Each analysis group can have one or more type of visualizations.Deprecation Notice:In the hierarchy metrics come before segment. Use ListAnalysisGroupedByMetricsV1 instead of this API.
     * @summary Returns grouping info for Analysis, e.g. n-grams, clustering, etc.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceListAnalysisGroupsV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceListAnalysisGroupsV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list containing metadata of graphs in an analysis group. Deprecation Notice:In the hierarchy metrics come before segment. Use ListAnalysisMetricsV1 instead of this API.
     * @summary Returns list containing metadata of graphs in an analysis group.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} analysisGroupId 
     * @param {string} segmentType 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceListAnalysisMetadataV1(workspaceId: string, datasetId: string, analysisGroupId: string, segmentType: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceListAnalysisMetadataV1(workspaceId, datasetId, analysisGroupId, segmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list containing metadata of graphs in an analysis group.
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {string} analysisGroupId 
     * @param {string} metricName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceListAnalysisMetricsV1(workspaceId: string, datasetId: string, analysisGroupId: string, metricName: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceListAnalysisMetricsV1(workspaceId, datasetId, analysisGroupId, metricName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of all data stores
     * @param {string} workspaceId 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceListDataStoresV1(workspaceId: string, start?: number, end?: number, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceListDataStoresV1(workspaceId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Executes new analyzer tasks for a registered dataset
     * @summary Start new analyzers tasks on registered dataset
     * @param {string} workspaceId 
     * @param {string} datasetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceNewAnalyzersStartV1(workspaceId: string, datasetId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceNewAnalyzersStartV1(workspaceId, datasetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will update workflow
     * @summary Update dataset registration object
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {DatasetRegistrationWorkflowModel} datasetRegistrationWorkflowModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspacePatchDatasetWorkflowV1(workspaceId: string, workflowId: string, datasetRegistrationWorkflowModel: DatasetRegistrationWorkflowModel, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspacePatchDatasetWorkflowV1(workspaceId, workflowId, datasetRegistrationWorkflowModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save datafamily of corresponding dataset workflow_id
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceRegisterDatasetV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceRegisterDatasetV1(workspaceId, workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Editable fields of the dataset i.e name, description using this api.
     * @param {string} workspaceId 
     * @param {string} dsId 
     * @param {UpdateDatasetEditableFieldsRequest} updateDatasetEditableFieldsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceUpdateDatasetEditableFieldsV1(workspaceId: string, dsId: string, updateDatasetEditableFieldsRequest: UpdateDatasetEditableFieldsRequest, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceUpdateDatasetEditableFieldsV1(workspaceId, dsId, updateDatasetEditableFieldsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save dataset and authenticate segment URLs
     * @param {string} workspaceId 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceDatasetRoutesApi
     */
    public workspaceVerifyDatasetAccessV1(workspaceId: string, workflowId: string, options?: AxiosRequestConfig) {
        return WorkspaceDatasetRoutesApiFp(this.configuration).workspaceVerifyDatasetAccessV1(workspaceId, workflowId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkspaceEvaluationsApi - axios parameter creator
 * @export
 */
export const WorkspaceEvaluationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return whether evaluation metrics (list ROC/PR curve etc.) are available for each evaluation; For each evaluation True/False is returned
         * @summary Return whether Evaluation Metrics (like ROC/PR curve etc.) are available for given evaluations
         * @param {string} workspaceId 
         * @param {Array<string>} evaluationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areEvaluationsROCAndPRCurveAvailableV1: async (workspaceId: string, evaluationIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('areEvaluationsROCAndPRCurveAvailableV1', 'workspaceId', workspaceId)
            // verify required parameter 'evaluationIds' is not null or undefined
            assertParamExists('areEvaluationsROCAndPRCurveAvailableV1', 'evaluationIds', evaluationIds)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/evaluations/are_evaluations_metrics_available`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (evaluationIds) {
                localVarQueryParameter['evaluation_ids'] = evaluationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to check whether the selected evaluations are comparable.
         * @summary Check whether given evaluations can be compared
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canCompareEvaluationsV1: async (workspaceId: string, evaluationIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('canCompareEvaluationsV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/evaluations/can-compare`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (evaluationIds) {
                localVarQueryParameter['evaluation_ids'] = evaluationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the class-level metrics computed for the evaluations  with the given evaluation-ids. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
         * @summary Return the class-level metrics computed for the given evaluation ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassLevelMetricsForEvaluationsV1: async (workspaceId: string, evaluationIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getClassLevelMetricsForEvaluationsV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/evaluations/class-metrics`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (evaluationIds) {
                localVarQueryParameter['evaluation_ids'] = evaluationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the confusion matrix computed for the evaluations  with the given evaluation-ids.
         * @summary Return the confusion matrix computed for the given evaluation ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfusionMatrixForEvaluationsV1: async (workspaceId: string, evaluationIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getConfusionMatrixForEvaluationsV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/evaluations/confusion-matrix`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (evaluationIds) {
                localVarQueryParameter['evaluation_ids'] = evaluationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the disagreements between actual and the inferred in this recording. This helps to understand where the model is making a mistake and what are records miss classified as. Disagreements are only available for classification problems
         * @summary Return the disagreements between the actual and the inferred.
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {boolean} [allDisagreements] Set to true to return all disagreements such that all given evaluations are in disagreement with ground truth
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [baseEvaluationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisagreementsForEvaluationsV1: async (workspaceId: string, evaluationIds?: Array<string>, allDisagreements?: boolean, start?: number, end?: number, baseEvaluationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDisagreementsForEvaluationsV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/evaluations/disagreements`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (evaluationIds) {
                localVarQueryParameter['evaluation_ids'] = evaluationIds;
            }

            if (allDisagreements !== undefined) {
                localVarQueryParameter['all_disagreements'] = allDisagreements;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (baseEvaluationId !== undefined) {
                localVarQueryParameter['base_evaluation_id'] = baseEvaluationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the distributions computed for the evaluations  with the given evaluation-ids. The distributions are returned as a chart response
         * @summary Return the distributions (actual and predicted) as a chart computed for the given evaluation ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributionsForEvaluationsV1: async (workspaceId: string, evaluationIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDistributionsForEvaluationsV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/evaluations/distributions`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (evaluationIds) {
                localVarQueryParameter['evaluation_ids'] = evaluationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the PR curve computed for the evaluations along with the best f1-score and best threshold.
         * @summary Return the PR curve for the given evaluations along with the best f1-score and best threshold.
         * @param {string} workspaceId 
         * @param {Array<string>} evaluationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationPRCurveChartV1: async (workspaceId: string, evaluationIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getEvaluationPRCurveChartV1', 'workspaceId', workspaceId)
            // verify required parameter 'evaluationIds' is not null or undefined
            assertParamExists('getEvaluationPRCurveChartV1', 'evaluationIds', evaluationIds)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/evaluations/pr_curve`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (evaluationIds) {
                localVarQueryParameter['evaluation_ids'] = evaluationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the ROC curve computed for the evaluations along with their AUC score.
         * @summary Return the ROC curve for the given evaluations along with their AUC score.
         * @param {string} workspaceId 
         * @param {Array<string>} evaluationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationRocCurveChartV1: async (workspaceId: string, evaluationIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getEvaluationRocCurveChartV1', 'workspaceId', workspaceId)
            // verify required parameter 'evaluationIds' is not null or undefined
            assertParamExists('getEvaluationRocCurveChartV1', 'evaluationIds', evaluationIds)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/evaluations/roc_auc`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (evaluationIds) {
                localVarQueryParameter['evaluation_ids'] = evaluationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return model-disagreement summary for all evaluations given a class/classes with respect to the ground truth
         * @summary Return model-disagreement summary for given class/classes
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {GetEvaluationsDisagreementsSummaryRequest} [getEvaluationsDisagreementsSummaryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationsDisagreementSummaryV1: async (workspaceId: string, evaluationIds?: Array<string>, getEvaluationsDisagreementsSummaryRequest?: GetEvaluationsDisagreementsSummaryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getEvaluationsDisagreementSummaryV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/evaluations/disagreements/summary`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (evaluationIds) {
                localVarQueryParameter['evaluation_ids'] = evaluationIds;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getEvaluationsDisagreementsSummaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the overall metrics computed for the evaluations  with the given evaluation-ids. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
         * @summary Return the overall metrics computed for the given evaluation ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverallMetricsForEvaluationsV1: async (workspaceId: string, evaluationIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getOverallMetricsForEvaluationsV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/evaluations/overall-metrics`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (evaluationIds) {
                localVarQueryParameter['evaluation_ids'] = evaluationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list active project evaluations with status
         * @summary list active project evaluations with status
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [modelId] 
         * @param {string} [datasetId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveProjectEvaluationsV1: async (workspaceId: string, projectId: string, start: number, end: number, ts?: string, modelId?: string, datasetId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listActiveProjectEvaluationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listActiveProjectEvaluationsV1', 'projectId', projectId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('listActiveProjectEvaluationsV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('listActiveProjectEvaluationsV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/{project_id}/evaluations`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (modelId !== undefined) {
                localVarQueryParameter['model_id'] = modelId;
            }

            if (datasetId !== undefined) {
                localVarQueryParameter['dataset_id'] = datasetId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the artifact state of the evaluations artifacts to ACTIVE/ARCHIVED Args:     workspace_id: workspace where the artifact exists     update_request: update request containing list of evaluation_ids and the updated artifact state     user: user who is making the update  Returns: Ack
         * @summary Update artifact state of Evaluations
         * @param {string} workspaceId 
         * @param {UpdateEvaluationArtifactState} updateEvaluationArtifactState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArtifactStateEvaluationsV1: async (workspaceId: string, updateEvaluationArtifactState: UpdateEvaluationArtifactState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateArtifactStateEvaluationsV1', 'workspaceId', workspaceId)
            // verify required parameter 'updateEvaluationArtifactState' is not null or undefined
            assertParamExists('updateArtifactStateEvaluationsV1', 'updateEvaluationArtifactState', updateEvaluationArtifactState)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/evaluations/state`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEvaluationArtifactState, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Editable fields of the evaluation i.e name, description using this api.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} evalId 
         * @param {UpdateEvalEditableFieldsRequest} updateEvalEditableFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvaluationEditableFieldsV1: async (workspaceId: string, projectId: string, evalId: string, updateEvalEditableFieldsRequest: UpdateEvalEditableFieldsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateEvaluationEditableFieldsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateEvaluationEditableFieldsV1', 'projectId', projectId)
            // verify required parameter 'evalId' is not null or undefined
            assertParamExists('updateEvaluationEditableFieldsV1', 'evalId', evalId)
            // verify required parameter 'updateEvalEditableFieldsRequest' is not null or undefined
            assertParamExists('updateEvaluationEditableFieldsV1', 'updateEvalEditableFieldsRequest', updateEvalEditableFieldsRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/{project_id}/evaluations/{eval_id}/edit-fields`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"eval_id"}}`, encodeURIComponent(String(evalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEvalEditableFieldsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspaceEvaluationsApi - functional programming interface
 * @export
 */
export const WorkspaceEvaluationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspaceEvaluationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Return whether evaluation metrics (list ROC/PR curve etc.) are available for each evaluation; For each evaluation True/False is returned
         * @summary Return whether Evaluation Metrics (like ROC/PR curve etc.) are available for given evaluations
         * @param {string} workspaceId 
         * @param {Array<string>} evaluationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areEvaluationsROCAndPRCurveAvailableV1(workspaceId: string, evaluationIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreEvaluationsMetricAvailable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areEvaluationsROCAndPRCurveAvailableV1(workspaceId, evaluationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to check whether the selected evaluations are comparable.
         * @summary Check whether given evaluations can be compared
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canCompareEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreEvaluationsComparableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.canCompareEvaluationsV1(workspaceId, evaluationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the class-level metrics computed for the evaluations  with the given evaluation-ids. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
         * @summary Return the class-level metrics computed for the given evaluation ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassLevelMetricsForEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvaluationsClassLevelMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassLevelMetricsForEvaluationsV1(workspaceId, evaluationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the confusion matrix computed for the evaluations  with the given evaluation-ids.
         * @summary Return the confusion matrix computed for the given evaluation ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfusionMatrixForEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvaluationsConfusionMatrixResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfusionMatrixForEvaluationsV1(workspaceId, evaluationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the disagreements between actual and the inferred in this recording. This helps to understand where the model is making a mistake and what are records miss classified as. Disagreements are only available for classification problems
         * @summary Return the disagreements between the actual and the inferred.
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {boolean} [allDisagreements] Set to true to return all disagreements such that all given evaluations are in disagreement with ground truth
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [baseEvaluationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDisagreementsForEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, allDisagreements?: boolean, start?: number, end?: number, baseEvaluationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvaluationDisagreementsPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDisagreementsForEvaluationsV1(workspaceId, evaluationIds, allDisagreements, start, end, baseEvaluationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the distributions computed for the evaluations  with the given evaluation-ids. The distributions are returned as a chart response
         * @summary Return the distributions (actual and predicted) as a chart computed for the given evaluation ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDistributionsForEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvaluationsDistributionsChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDistributionsForEvaluationsV1(workspaceId, evaluationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the PR curve computed for the evaluations along with the best f1-score and best threshold.
         * @summary Return the PR curve for the given evaluations along with the best f1-score and best threshold.
         * @param {string} workspaceId 
         * @param {Array<string>} evaluationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvaluationPRCurveChartV1(workspaceId: string, evaluationIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvaluationsPRCurveChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvaluationPRCurveChartV1(workspaceId, evaluationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the ROC curve computed for the evaluations along with their AUC score.
         * @summary Return the ROC curve for the given evaluations along with their AUC score.
         * @param {string} workspaceId 
         * @param {Array<string>} evaluationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvaluationRocCurveChartV1(workspaceId: string, evaluationIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvaluationRocCurveChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvaluationRocCurveChartV1(workspaceId, evaluationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return model-disagreement summary for all evaluations given a class/classes with respect to the ground truth
         * @summary Return model-disagreement summary for given class/classes
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {GetEvaluationsDisagreementsSummaryRequest} [getEvaluationsDisagreementsSummaryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvaluationsDisagreementSummaryV1(workspaceId: string, evaluationIds?: Array<string>, getEvaluationsDisagreementsSummaryRequest?: GetEvaluationsDisagreementsSummaryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvaluationsDisagreementSummaryDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvaluationsDisagreementSummaryV1(workspaceId, evaluationIds, getEvaluationsDisagreementsSummaryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the overall metrics computed for the evaluations  with the given evaluation-ids. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
         * @summary Return the overall metrics computed for the given evaluation ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOverallMetricsForEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvaluationsOverallMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOverallMetricsForEvaluationsV1(workspaceId, evaluationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list active project evaluations with status
         * @summary list active project evaluations with status
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [modelId] 
         * @param {string} [datasetId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActiveProjectEvaluationsV1(workspaceId: string, projectId: string, start: number, end: number, ts?: string, modelId?: string, datasetId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEvaluationsPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActiveProjectEvaluationsV1(workspaceId, projectId, start, end, ts, modelId, datasetId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the artifact state of the evaluations artifacts to ACTIVE/ARCHIVED Args:     workspace_id: workspace where the artifact exists     update_request: update request containing list of evaluation_ids and the updated artifact state     user: user who is making the update  Returns: Ack
         * @summary Update artifact state of Evaluations
         * @param {string} workspaceId 
         * @param {UpdateEvaluationArtifactState} updateEvaluationArtifactState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArtifactStateEvaluationsV1(workspaceId: string, updateEvaluationArtifactState: UpdateEvaluationArtifactState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateChangeAck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateArtifactStateEvaluationsV1(workspaceId, updateEvaluationArtifactState, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Editable fields of the evaluation i.e name, description using this api.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} evalId 
         * @param {UpdateEvalEditableFieldsRequest} updateEvalEditableFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEvaluationEditableFieldsV1(workspaceId: string, projectId: string, evalId: string, updateEvalEditableFieldsRequest: UpdateEvalEditableFieldsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateEvalEditableFieldsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEvaluationEditableFieldsV1(workspaceId, projectId, evalId, updateEvalEditableFieldsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkspaceEvaluationsApi - factory interface
 * @export
 */
export const WorkspaceEvaluationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspaceEvaluationsApiFp(configuration)
    return {
        /**
         * Return whether evaluation metrics (list ROC/PR curve etc.) are available for each evaluation; For each evaluation True/False is returned
         * @summary Return whether Evaluation Metrics (like ROC/PR curve etc.) are available for given evaluations
         * @param {string} workspaceId 
         * @param {Array<string>} evaluationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areEvaluationsROCAndPRCurveAvailableV1(workspaceId: string, evaluationIds: Array<string>, options?: any): AxiosPromise<AreEvaluationsMetricAvailable> {
            return localVarFp.areEvaluationsROCAndPRCurveAvailableV1(workspaceId, evaluationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * API to check whether the selected evaluations are comparable.
         * @summary Check whether given evaluations can be compared
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canCompareEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, options?: any): AxiosPromise<AreEvaluationsComparableResponse> {
            return localVarFp.canCompareEvaluationsV1(workspaceId, evaluationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the class-level metrics computed for the evaluations  with the given evaluation-ids. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
         * @summary Return the class-level metrics computed for the given evaluation ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassLevelMetricsForEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, options?: any): AxiosPromise<EvaluationsClassLevelMetricsResponse> {
            return localVarFp.getClassLevelMetricsForEvaluationsV1(workspaceId, evaluationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the confusion matrix computed for the evaluations  with the given evaluation-ids.
         * @summary Return the confusion matrix computed for the given evaluation ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfusionMatrixForEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, options?: any): AxiosPromise<EvaluationsConfusionMatrixResponse> {
            return localVarFp.getConfusionMatrixForEvaluationsV1(workspaceId, evaluationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the disagreements between actual and the inferred in this recording. This helps to understand where the model is making a mistake and what are records miss classified as. Disagreements are only available for classification problems
         * @summary Return the disagreements between the actual and the inferred.
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {boolean} [allDisagreements] Set to true to return all disagreements such that all given evaluations are in disagreement with ground truth
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {string} [baseEvaluationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisagreementsForEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, allDisagreements?: boolean, start?: number, end?: number, baseEvaluationId?: string, options?: any): AxiosPromise<EvaluationDisagreementsPaginatedResponse> {
            return localVarFp.getDisagreementsForEvaluationsV1(workspaceId, evaluationIds, allDisagreements, start, end, baseEvaluationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the distributions computed for the evaluations  with the given evaluation-ids. The distributions are returned as a chart response
         * @summary Return the distributions (actual and predicted) as a chart computed for the given evaluation ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributionsForEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, options?: any): AxiosPromise<EvaluationsDistributionsChartResponse> {
            return localVarFp.getDistributionsForEvaluationsV1(workspaceId, evaluationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the PR curve computed for the evaluations along with the best f1-score and best threshold.
         * @summary Return the PR curve for the given evaluations along with the best f1-score and best threshold.
         * @param {string} workspaceId 
         * @param {Array<string>} evaluationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationPRCurveChartV1(workspaceId: string, evaluationIds: Array<string>, options?: any): AxiosPromise<EvaluationsPRCurveChartResponse> {
            return localVarFp.getEvaluationPRCurveChartV1(workspaceId, evaluationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the ROC curve computed for the evaluations along with their AUC score.
         * @summary Return the ROC curve for the given evaluations along with their AUC score.
         * @param {string} workspaceId 
         * @param {Array<string>} evaluationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationRocCurveChartV1(workspaceId: string, evaluationIds: Array<string>, options?: any): AxiosPromise<EvaluationRocCurveChartResponse> {
            return localVarFp.getEvaluationRocCurveChartV1(workspaceId, evaluationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Return model-disagreement summary for all evaluations given a class/classes with respect to the ground truth
         * @summary Return model-disagreement summary for given class/classes
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {GetEvaluationsDisagreementsSummaryRequest} [getEvaluationsDisagreementsSummaryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationsDisagreementSummaryV1(workspaceId: string, evaluationIds?: Array<string>, getEvaluationsDisagreementsSummaryRequest?: GetEvaluationsDisagreementsSummaryRequest, options?: any): AxiosPromise<EvaluationsDisagreementSummaryDataResponse> {
            return localVarFp.getEvaluationsDisagreementSummaryV1(workspaceId, evaluationIds, getEvaluationsDisagreementsSummaryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the overall metrics computed for the evaluations  with the given evaluation-ids. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
         * @summary Return the overall metrics computed for the given evaluation ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [evaluationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverallMetricsForEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, options?: any): AxiosPromise<EvaluationsOverallMetricsResponse> {
            return localVarFp.getOverallMetricsForEvaluationsV1(workspaceId, evaluationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * list active project evaluations with status
         * @summary list active project evaluations with status
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [modelId] 
         * @param {string} [datasetId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveProjectEvaluationsV1(workspaceId: string, projectId: string, start: number, end: number, ts?: string, modelId?: string, datasetId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<GetEvaluationsPaginated> {
            return localVarFp.listActiveProjectEvaluationsV1(workspaceId, projectId, start, end, ts, modelId, datasetId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the artifact state of the evaluations artifacts to ACTIVE/ARCHIVED Args:     workspace_id: workspace where the artifact exists     update_request: update request containing list of evaluation_ids and the updated artifact state     user: user who is making the update  Returns: Ack
         * @summary Update artifact state of Evaluations
         * @param {string} workspaceId 
         * @param {UpdateEvaluationArtifactState} updateEvaluationArtifactState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArtifactStateEvaluationsV1(workspaceId: string, updateEvaluationArtifactState: UpdateEvaluationArtifactState, options?: any): AxiosPromise<StateChangeAck> {
            return localVarFp.updateArtifactStateEvaluationsV1(workspaceId, updateEvaluationArtifactState, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Editable fields of the evaluation i.e name, description using this api.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} evalId 
         * @param {UpdateEvalEditableFieldsRequest} updateEvalEditableFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvaluationEditableFieldsV1(workspaceId: string, projectId: string, evalId: string, updateEvalEditableFieldsRequest: UpdateEvalEditableFieldsRequest, options?: any): AxiosPromise<UpdateEvalEditableFieldsResponse> {
            return localVarFp.updateEvaluationEditableFieldsV1(workspaceId, projectId, evalId, updateEvalEditableFieldsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspaceEvaluationsApi - object-oriented interface
 * @export
 * @class WorkspaceEvaluationsApi
 * @extends {BaseAPI}
 */
export class WorkspaceEvaluationsApi extends BaseAPI {
    /**
     * Return whether evaluation metrics (list ROC/PR curve etc.) are available for each evaluation; For each evaluation True/False is returned
     * @summary Return whether Evaluation Metrics (like ROC/PR curve etc.) are available for given evaluations
     * @param {string} workspaceId 
     * @param {Array<string>} evaluationIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceEvaluationsApi
     */
    public areEvaluationsROCAndPRCurveAvailableV1(workspaceId: string, evaluationIds: Array<string>, options?: AxiosRequestConfig) {
        return WorkspaceEvaluationsApiFp(this.configuration).areEvaluationsROCAndPRCurveAvailableV1(workspaceId, evaluationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to check whether the selected evaluations are comparable.
     * @summary Check whether given evaluations can be compared
     * @param {string} workspaceId 
     * @param {Array<string>} [evaluationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceEvaluationsApi
     */
    public canCompareEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, options?: AxiosRequestConfig) {
        return WorkspaceEvaluationsApiFp(this.configuration).canCompareEvaluationsV1(workspaceId, evaluationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the class-level metrics computed for the evaluations  with the given evaluation-ids. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
     * @summary Return the class-level metrics computed for the given evaluation ids.
     * @param {string} workspaceId 
     * @param {Array<string>} [evaluationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceEvaluationsApi
     */
    public getClassLevelMetricsForEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, options?: AxiosRequestConfig) {
        return WorkspaceEvaluationsApiFp(this.configuration).getClassLevelMetricsForEvaluationsV1(workspaceId, evaluationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the confusion matrix computed for the evaluations  with the given evaluation-ids.
     * @summary Return the confusion matrix computed for the given evaluation ids.
     * @param {string} workspaceId 
     * @param {Array<string>} [evaluationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceEvaluationsApi
     */
    public getConfusionMatrixForEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, options?: AxiosRequestConfig) {
        return WorkspaceEvaluationsApiFp(this.configuration).getConfusionMatrixForEvaluationsV1(workspaceId, evaluationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the disagreements between actual and the inferred in this recording. This helps to understand where the model is making a mistake and what are records miss classified as. Disagreements are only available for classification problems
     * @summary Return the disagreements between the actual and the inferred.
     * @param {string} workspaceId 
     * @param {Array<string>} [evaluationIds] 
     * @param {boolean} [allDisagreements] Set to true to return all disagreements such that all given evaluations are in disagreement with ground truth
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {string} [baseEvaluationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceEvaluationsApi
     */
    public getDisagreementsForEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, allDisagreements?: boolean, start?: number, end?: number, baseEvaluationId?: string, options?: AxiosRequestConfig) {
        return WorkspaceEvaluationsApiFp(this.configuration).getDisagreementsForEvaluationsV1(workspaceId, evaluationIds, allDisagreements, start, end, baseEvaluationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the distributions computed for the evaluations  with the given evaluation-ids. The distributions are returned as a chart response
     * @summary Return the distributions (actual and predicted) as a chart computed for the given evaluation ids.
     * @param {string} workspaceId 
     * @param {Array<string>} [evaluationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceEvaluationsApi
     */
    public getDistributionsForEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, options?: AxiosRequestConfig) {
        return WorkspaceEvaluationsApiFp(this.configuration).getDistributionsForEvaluationsV1(workspaceId, evaluationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the PR curve computed for the evaluations along with the best f1-score and best threshold.
     * @summary Return the PR curve for the given evaluations along with the best f1-score and best threshold.
     * @param {string} workspaceId 
     * @param {Array<string>} evaluationIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceEvaluationsApi
     */
    public getEvaluationPRCurveChartV1(workspaceId: string, evaluationIds: Array<string>, options?: AxiosRequestConfig) {
        return WorkspaceEvaluationsApiFp(this.configuration).getEvaluationPRCurveChartV1(workspaceId, evaluationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the ROC curve computed for the evaluations along with their AUC score.
     * @summary Return the ROC curve for the given evaluations along with their AUC score.
     * @param {string} workspaceId 
     * @param {Array<string>} evaluationIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceEvaluationsApi
     */
    public getEvaluationRocCurveChartV1(workspaceId: string, evaluationIds: Array<string>, options?: AxiosRequestConfig) {
        return WorkspaceEvaluationsApiFp(this.configuration).getEvaluationRocCurveChartV1(workspaceId, evaluationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return model-disagreement summary for all evaluations given a class/classes with respect to the ground truth
     * @summary Return model-disagreement summary for given class/classes
     * @param {string} workspaceId 
     * @param {Array<string>} [evaluationIds] 
     * @param {GetEvaluationsDisagreementsSummaryRequest} [getEvaluationsDisagreementsSummaryRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceEvaluationsApi
     */
    public getEvaluationsDisagreementSummaryV1(workspaceId: string, evaluationIds?: Array<string>, getEvaluationsDisagreementsSummaryRequest?: GetEvaluationsDisagreementsSummaryRequest, options?: AxiosRequestConfig) {
        return WorkspaceEvaluationsApiFp(this.configuration).getEvaluationsDisagreementSummaryV1(workspaceId, evaluationIds, getEvaluationsDisagreementsSummaryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the overall metrics computed for the evaluations  with the given evaluation-ids. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
     * @summary Return the overall metrics computed for the given evaluation ids.
     * @param {string} workspaceId 
     * @param {Array<string>} [evaluationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceEvaluationsApi
     */
    public getOverallMetricsForEvaluationsV1(workspaceId: string, evaluationIds?: Array<string>, options?: AxiosRequestConfig) {
        return WorkspaceEvaluationsApiFp(this.configuration).getOverallMetricsForEvaluationsV1(workspaceId, evaluationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list active project evaluations with status
     * @summary list active project evaluations with status
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {string} [modelId] 
     * @param {string} [datasetId] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceEvaluationsApi
     */
    public listActiveProjectEvaluationsV1(workspaceId: string, projectId: string, start: number, end: number, ts?: string, modelId?: string, datasetId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return WorkspaceEvaluationsApiFp(this.configuration).listActiveProjectEvaluationsV1(workspaceId, projectId, start, end, ts, modelId, datasetId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the artifact state of the evaluations artifacts to ACTIVE/ARCHIVED Args:     workspace_id: workspace where the artifact exists     update_request: update request containing list of evaluation_ids and the updated artifact state     user: user who is making the update  Returns: Ack
     * @summary Update artifact state of Evaluations
     * @param {string} workspaceId 
     * @param {UpdateEvaluationArtifactState} updateEvaluationArtifactState 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceEvaluationsApi
     */
    public updateArtifactStateEvaluationsV1(workspaceId: string, updateEvaluationArtifactState: UpdateEvaluationArtifactState, options?: AxiosRequestConfig) {
        return WorkspaceEvaluationsApiFp(this.configuration).updateArtifactStateEvaluationsV1(workspaceId, updateEvaluationArtifactState, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Editable fields of the evaluation i.e name, description using this api.
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {string} evalId 
     * @param {UpdateEvalEditableFieldsRequest} updateEvalEditableFieldsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceEvaluationsApi
     */
    public updateEvaluationEditableFieldsV1(workspaceId: string, projectId: string, evalId: string, updateEvalEditableFieldsRequest: UpdateEvalEditableFieldsRequest, options?: AxiosRequestConfig) {
        return WorkspaceEvaluationsApiFp(this.configuration).updateEvaluationEditableFieldsV1(workspaceId, projectId, evalId, updateEvalEditableFieldsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkspaceExperimentsApi - axios parameter creator
 * @export
 */
export const WorkspaceExperimentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check whether the given experiments are comparable
         * @param {string} workspaceId 
         * @param {Array<string>} [experimentsIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canCompareExperimentsV1: async (workspaceId: string, experimentsIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('canCompareExperimentsV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/experiments/can-compare`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (experimentsIds) {
                localVarQueryParameter['experiments_ids'] = experimentsIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the package requirements of the experiment identified by the experiment_id
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentPackageRequirementsV1: async (workspaceId: string, projectId: string, experimentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getExperimentPackageRequirementsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getExperimentPackageRequirementsV1', 'projectId', projectId)
            // verify required parameter 'experimentId' is not null or undefined
            assertParamExists('getExperimentPackageRequirementsV1', 'experimentId', experimentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/{project_id}/experiments/{experiment_id}/package-requirements`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"experiment_id"}}`, encodeURIComponent(String(experimentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get experiment details
         * @summary Get experiment details.
         * @param {string} workspaceId 
         * @param {string} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentV1: async (workspaceId: string, experimentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getExperimentV1', 'workspaceId', workspaceId)
            // verify required parameter 'experimentId' is not null or undefined
            assertParamExists('getExperimentV1', 'experimentId', experimentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/experiments/{experiment_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"experiment_id"}}`, encodeURIComponent(String(experimentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the union of chart keys for given experiment ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [experimentsIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentsChartKeysV1: async (workspaceId: string, experimentsIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getExperimentsChartKeysV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/experiments/keys`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (experimentsIds) {
                localVarQueryParameter['experiments_ids'] = experimentsIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the chart data for given key and given experiment_ids
         * @param {string} workspaceId 
         * @param {string} key 
         * @param {Array<string>} experimentsIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentsKeyMetricsChartResponseV1: async (workspaceId: string, key: string, experimentsIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getExperimentsKeyMetricsChartResponseV1', 'workspaceId', workspaceId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getExperimentsKeyMetricsChartResponseV1', 'key', key)
            // verify required parameter 'experimentsIds' is not null or undefined
            assertParamExists('getExperimentsKeyMetricsChartResponseV1', 'experimentsIds', experimentsIds)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/experiments/metrics-by-key`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (experimentsIds) {
                localVarQueryParameter['experiments_ids'] = experimentsIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the experiment summary for multiple experiments
         * @param {string} workspaceId 
         * @param {Array<string>} [experimentsIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentsSummaryV1: async (workspaceId: string, experimentsIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getExperimentsSummaryV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/experiments/summary`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (experimentsIds) {
                localVarQueryParameter['experiments_ids'] = experimentsIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get project experiment detail
         * @summary get project experiment detail.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectExperimentV1: async (workspaceId: string, projectId: string, experimentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProjectExperimentV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectExperimentV1', 'projectId', projectId)
            // verify required parameter 'experimentId' is not null or undefined
            assertParamExists('getProjectExperimentV1', 'experimentId', experimentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/{project_id}/experiments/{experiment_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"experiment_id"}}`, encodeURIComponent(String(experimentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list active project experiments
         * @summary list active project experiments.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [modelId] 
         * @param {string} [datasetId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveProjectExperimentsV1: async (workspaceId: string, projectId: string, start: number, end: number, ts?: string, modelId?: string, datasetId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listActiveProjectExperimentsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listActiveProjectExperimentsV1', 'projectId', projectId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('listActiveProjectExperimentsV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('listActiveProjectExperimentsV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/{project_id}/experiments`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (modelId !== undefined) {
                localVarQueryParameter['model_id'] = modelId;
            }

            if (datasetId !== undefined) {
                localVarQueryParameter['dataset_id'] = datasetId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Manually end experiments using this API by changing the status from START to CANCELLED
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualEndExperimentsV1: async (workspaceId: string, projectId: string, experimentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('manualEndExperimentsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('manualEndExperimentsV1', 'projectId', projectId)
            // verify required parameter 'experimentId' is not null or undefined
            assertParamExists('manualEndExperimentsV1', 'experimentId', experimentId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/{project_id}/experiments/{experiment_id}/stop-experiment`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"experiment_id"}}`, encodeURIComponent(String(experimentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update artifact state of experiments artifact to ACTIVE/ARCHIVED Args:     workspace_id: workspace where the artifact exists     update_request: update request containing the artifact ids and the update artifact state     user: user who is making the update so that we track who updated the artifact  Returns: Ack
         * @summary Update artifact state of Experiments
         * @param {string} workspaceId 
         * @param {UpdateExperimentArtifactState} updateExperimentArtifactState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArtifactStateExperimentsV1: async (workspaceId: string, updateExperimentArtifactState: UpdateExperimentArtifactState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateArtifactStateExperimentsV1', 'workspaceId', workspaceId)
            // verify required parameter 'updateExperimentArtifactState' is not null or undefined
            assertParamExists('updateArtifactStateExperimentsV1', 'updateExperimentArtifactState', updateExperimentArtifactState)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/experiments/state`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExperimentArtifactState, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Editable fields of the experiment i.e name, description using this api.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} experimentId 
         * @param {UpdateExpEditableFieldsRequest} updateExpEditableFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperimentEditableFieldsV1: async (workspaceId: string, projectId: string, experimentId: string, updateExpEditableFieldsRequest: UpdateExpEditableFieldsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateExperimentEditableFieldsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateExperimentEditableFieldsV1', 'projectId', projectId)
            // verify required parameter 'experimentId' is not null or undefined
            assertParamExists('updateExperimentEditableFieldsV1', 'experimentId', experimentId)
            // verify required parameter 'updateExpEditableFieldsRequest' is not null or undefined
            assertParamExists('updateExperimentEditableFieldsV1', 'updateExpEditableFieldsRequest', updateExpEditableFieldsRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/{project_id}/experiments/{experiment_id}/edit-fields`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"experiment_id"}}`, encodeURIComponent(String(experimentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExpEditableFieldsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspaceExperimentsApi - functional programming interface
 * @export
 */
export const WorkspaceExperimentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspaceExperimentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check whether the given experiments are comparable
         * @param {string} workspaceId 
         * @param {Array<string>} [experimentsIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canCompareExperimentsV1(workspaceId: string, experimentsIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreExperimentsComparableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.canCompareExperimentsV1(workspaceId, experimentsIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the package requirements of the experiment identified by the experiment_id
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentPackageRequirementsV1(workspaceId: string, projectId: string, experimentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExperimentPackageRequirementsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentPackageRequirementsV1(workspaceId, projectId, experimentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get experiment details
         * @summary Get experiment details.
         * @param {string} workspaceId 
         * @param {string} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentV1(workspaceId: string, experimentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExperimentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentV1(workspaceId, experimentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the union of chart keys for given experiment ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [experimentsIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentsChartKeysV1(workspaceId: string, experimentsIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperimentsChartKeysResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentsChartKeysV1(workspaceId, experimentsIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the chart data for given key and given experiment_ids
         * @param {string} workspaceId 
         * @param {string} key 
         * @param {Array<string>} experimentsIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentsKeyMetricsChartResponseV1(workspaceId: string, key: string, experimentsIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperimentsKeyMetricResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentsKeyMetricsChartResponseV1(workspaceId, key, experimentsIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the experiment summary for multiple experiments
         * @param {string} workspaceId 
         * @param {Array<string>} [experimentsIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentsSummaryV1(workspaceId: string, experimentsIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultipleExperimentsSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentsSummaryV1(workspaceId, experimentsIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get project experiment detail
         * @summary get project experiment detail.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectExperimentV1(workspaceId: string, projectId: string, experimentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExperimentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectExperimentV1(workspaceId, projectId, experimentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list active project experiments
         * @summary list active project experiments.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [modelId] 
         * @param {string} [datasetId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActiveProjectExperimentsV1(workspaceId: string, projectId: string, start: number, end: number, ts?: string, modelId?: string, datasetId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExperimentsPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActiveProjectExperimentsV1(workspaceId, projectId, start, end, ts, modelId, datasetId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Manually end experiments using this API by changing the status from START to CANCELLED
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualEndExperimentsV1(workspaceId: string, projectId: string, experimentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManualExperimentEndResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualEndExperimentsV1(workspaceId, projectId, experimentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update artifact state of experiments artifact to ACTIVE/ARCHIVED Args:     workspace_id: workspace where the artifact exists     update_request: update request containing the artifact ids and the update artifact state     user: user who is making the update so that we track who updated the artifact  Returns: Ack
         * @summary Update artifact state of Experiments
         * @param {string} workspaceId 
         * @param {UpdateExperimentArtifactState} updateExperimentArtifactState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArtifactStateExperimentsV1(workspaceId: string, updateExperimentArtifactState: UpdateExperimentArtifactState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateChangeAck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateArtifactStateExperimentsV1(workspaceId, updateExperimentArtifactState, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Editable fields of the experiment i.e name, description using this api.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} experimentId 
         * @param {UpdateExpEditableFieldsRequest} updateExpEditableFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExperimentEditableFieldsV1(workspaceId: string, projectId: string, experimentId: string, updateExpEditableFieldsRequest: UpdateExpEditableFieldsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateExpEditableFieldsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExperimentEditableFieldsV1(workspaceId, projectId, experimentId, updateExpEditableFieldsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkspaceExperimentsApi - factory interface
 * @export
 */
export const WorkspaceExperimentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspaceExperimentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Check whether the given experiments are comparable
         * @param {string} workspaceId 
         * @param {Array<string>} [experimentsIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canCompareExperimentsV1(workspaceId: string, experimentsIds?: Array<string>, options?: any): AxiosPromise<AreExperimentsComparableResponse> {
            return localVarFp.canCompareExperimentsV1(workspaceId, experimentsIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the package requirements of the experiment identified by the experiment_id
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentPackageRequirementsV1(workspaceId: string, projectId: string, experimentId: string, options?: any): AxiosPromise<GetExperimentPackageRequirementsResponse> {
            return localVarFp.getExperimentPackageRequirementsV1(workspaceId, projectId, experimentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get experiment details
         * @summary Get experiment details.
         * @param {string} workspaceId 
         * @param {string} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentV1(workspaceId: string, experimentId: string, options?: any): AxiosPromise<GetExperimentResponse> {
            return localVarFp.getExperimentV1(workspaceId, experimentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the union of chart keys for given experiment ids.
         * @param {string} workspaceId 
         * @param {Array<string>} [experimentsIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentsChartKeysV1(workspaceId: string, experimentsIds?: Array<string>, options?: any): AxiosPromise<ExperimentsChartKeysResponse> {
            return localVarFp.getExperimentsChartKeysV1(workspaceId, experimentsIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the chart data for given key and given experiment_ids
         * @param {string} workspaceId 
         * @param {string} key 
         * @param {Array<string>} experimentsIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentsKeyMetricsChartResponseV1(workspaceId: string, key: string, experimentsIds: Array<string>, options?: any): AxiosPromise<ExperimentsKeyMetricResponse> {
            return localVarFp.getExperimentsKeyMetricsChartResponseV1(workspaceId, key, experimentsIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the experiment summary for multiple experiments
         * @param {string} workspaceId 
         * @param {Array<string>} [experimentsIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentsSummaryV1(workspaceId: string, experimentsIds?: Array<string>, options?: any): AxiosPromise<MultipleExperimentsSummaryResponse> {
            return localVarFp.getExperimentsSummaryV1(workspaceId, experimentsIds, options).then((request) => request(axios, basePath));
        },
        /**
         * get project experiment detail
         * @summary get project experiment detail.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectExperimentV1(workspaceId: string, projectId: string, experimentId: string, options?: any): AxiosPromise<GetExperimentResponse> {
            return localVarFp.getProjectExperimentV1(workspaceId, projectId, experimentId, options).then((request) => request(axios, basePath));
        },
        /**
         * list active project experiments
         * @summary list active project experiments.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [modelId] 
         * @param {string} [datasetId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveProjectExperimentsV1(workspaceId: string, projectId: string, start: number, end: number, ts?: string, modelId?: string, datasetId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<GetExperimentsPaginated> {
            return localVarFp.listActiveProjectExperimentsV1(workspaceId, projectId, start, end, ts, modelId, datasetId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Manually end experiments using this API by changing the status from START to CANCELLED
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} experimentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualEndExperimentsV1(workspaceId: string, projectId: string, experimentId: string, options?: any): AxiosPromise<ManualExperimentEndResponse> {
            return localVarFp.manualEndExperimentsV1(workspaceId, projectId, experimentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update artifact state of experiments artifact to ACTIVE/ARCHIVED Args:     workspace_id: workspace where the artifact exists     update_request: update request containing the artifact ids and the update artifact state     user: user who is making the update so that we track who updated the artifact  Returns: Ack
         * @summary Update artifact state of Experiments
         * @param {string} workspaceId 
         * @param {UpdateExperimentArtifactState} updateExperimentArtifactState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArtifactStateExperimentsV1(workspaceId: string, updateExperimentArtifactState: UpdateExperimentArtifactState, options?: any): AxiosPromise<StateChangeAck> {
            return localVarFp.updateArtifactStateExperimentsV1(workspaceId, updateExperimentArtifactState, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Editable fields of the experiment i.e name, description using this api.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} experimentId 
         * @param {UpdateExpEditableFieldsRequest} updateExpEditableFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperimentEditableFieldsV1(workspaceId: string, projectId: string, experimentId: string, updateExpEditableFieldsRequest: UpdateExpEditableFieldsRequest, options?: any): AxiosPromise<UpdateExpEditableFieldsResponse> {
            return localVarFp.updateExperimentEditableFieldsV1(workspaceId, projectId, experimentId, updateExpEditableFieldsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspaceExperimentsApi - object-oriented interface
 * @export
 * @class WorkspaceExperimentsApi
 * @extends {BaseAPI}
 */
export class WorkspaceExperimentsApi extends BaseAPI {
    /**
     * 
     * @summary Check whether the given experiments are comparable
     * @param {string} workspaceId 
     * @param {Array<string>} [experimentsIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceExperimentsApi
     */
    public canCompareExperimentsV1(workspaceId: string, experimentsIds?: Array<string>, options?: AxiosRequestConfig) {
        return WorkspaceExperimentsApiFp(this.configuration).canCompareExperimentsV1(workspaceId, experimentsIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the package requirements of the experiment identified by the experiment_id
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {string} experimentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceExperimentsApi
     */
    public getExperimentPackageRequirementsV1(workspaceId: string, projectId: string, experimentId: string, options?: AxiosRequestConfig) {
        return WorkspaceExperimentsApiFp(this.configuration).getExperimentPackageRequirementsV1(workspaceId, projectId, experimentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get experiment details
     * @summary Get experiment details.
     * @param {string} workspaceId 
     * @param {string} experimentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceExperimentsApi
     */
    public getExperimentV1(workspaceId: string, experimentId: string, options?: AxiosRequestConfig) {
        return WorkspaceExperimentsApiFp(this.configuration).getExperimentV1(workspaceId, experimentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the union of chart keys for given experiment ids.
     * @param {string} workspaceId 
     * @param {Array<string>} [experimentsIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceExperimentsApi
     */
    public getExperimentsChartKeysV1(workspaceId: string, experimentsIds?: Array<string>, options?: AxiosRequestConfig) {
        return WorkspaceExperimentsApiFp(this.configuration).getExperimentsChartKeysV1(workspaceId, experimentsIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the chart data for given key and given experiment_ids
     * @param {string} workspaceId 
     * @param {string} key 
     * @param {Array<string>} experimentsIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceExperimentsApi
     */
    public getExperimentsKeyMetricsChartResponseV1(workspaceId: string, key: string, experimentsIds: Array<string>, options?: AxiosRequestConfig) {
        return WorkspaceExperimentsApiFp(this.configuration).getExperimentsKeyMetricsChartResponseV1(workspaceId, key, experimentsIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the experiment summary for multiple experiments
     * @param {string} workspaceId 
     * @param {Array<string>} [experimentsIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceExperimentsApi
     */
    public getExperimentsSummaryV1(workspaceId: string, experimentsIds?: Array<string>, options?: AxiosRequestConfig) {
        return WorkspaceExperimentsApiFp(this.configuration).getExperimentsSummaryV1(workspaceId, experimentsIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get project experiment detail
     * @summary get project experiment detail.
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {string} experimentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceExperimentsApi
     */
    public getProjectExperimentV1(workspaceId: string, projectId: string, experimentId: string, options?: AxiosRequestConfig) {
        return WorkspaceExperimentsApiFp(this.configuration).getProjectExperimentV1(workspaceId, projectId, experimentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list active project experiments
     * @summary list active project experiments.
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {string} [modelId] 
     * @param {string} [datasetId] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceExperimentsApi
     */
    public listActiveProjectExperimentsV1(workspaceId: string, projectId: string, start: number, end: number, ts?: string, modelId?: string, datasetId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return WorkspaceExperimentsApiFp(this.configuration).listActiveProjectExperimentsV1(workspaceId, projectId, start, end, ts, modelId, datasetId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Manually end experiments using this API by changing the status from START to CANCELLED
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {string} experimentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceExperimentsApi
     */
    public manualEndExperimentsV1(workspaceId: string, projectId: string, experimentId: string, options?: AxiosRequestConfig) {
        return WorkspaceExperimentsApiFp(this.configuration).manualEndExperimentsV1(workspaceId, projectId, experimentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update artifact state of experiments artifact to ACTIVE/ARCHIVED Args:     workspace_id: workspace where the artifact exists     update_request: update request containing the artifact ids and the update artifact state     user: user who is making the update so that we track who updated the artifact  Returns: Ack
     * @summary Update artifact state of Experiments
     * @param {string} workspaceId 
     * @param {UpdateExperimentArtifactState} updateExperimentArtifactState 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceExperimentsApi
     */
    public updateArtifactStateExperimentsV1(workspaceId: string, updateExperimentArtifactState: UpdateExperimentArtifactState, options?: AxiosRequestConfig) {
        return WorkspaceExperimentsApiFp(this.configuration).updateArtifactStateExperimentsV1(workspaceId, updateExperimentArtifactState, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Editable fields of the experiment i.e name, description using this api.
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {string} experimentId 
     * @param {UpdateExpEditableFieldsRequest} updateExpEditableFieldsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceExperimentsApi
     */
    public updateExperimentEditableFieldsV1(workspaceId: string, projectId: string, experimentId: string, updateExpEditableFieldsRequest: UpdateExpEditableFieldsRequest, options?: AxiosRequestConfig) {
        return WorkspaceExperimentsApiFp(this.configuration).updateExperimentEditableFieldsV1(workspaceId, projectId, experimentId, updateExpEditableFieldsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkspaceModelsApi - axios parameter creator
 * @export
 */
export const WorkspaceModelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get project model projection (model object with selected columns) details.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} modelId 
         * @param {Array<string>} [projection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectModelProjectionV1: async (workspaceId: string, projectId: string, modelId: string, projection?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProjectModelProjectionV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectModelProjectionV1', 'projectId', projectId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('getProjectModelProjectionV1', 'modelId', modelId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/{project_id}/models-projected/{model_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projection) {
                localVarQueryParameter['projection'] = projection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project model details.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectModelV1: async (workspaceId: string, projectId: string, modelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProjectModelV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectModelV1', 'projectId', projectId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('getProjectModelV1', 'modelId', modelId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/{project_id}/models/{model_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get paginated list of deleted models  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetModelsResponse: response object with models
         * @summary List deleted project models.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeletedProjectModelsV1: async (workspaceId: string, projectId: string, start: number, end: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listDeletedProjectModelsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listDeletedProjectModelsV1', 'projectId', projectId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('listDeletedProjectModelsV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('listDeletedProjectModelsV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/{project_id}/deleted-models`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get paginated list of models  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsResponse: response object with models
         * @summary List active models.
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listModelsV1: async (workspaceId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listModelsV1', 'workspaceId', workspaceId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('listModelsV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('listModelsV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/models`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get paginated list of models for a project  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsResponse: response object with models
         * @summary List active project models.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectModelsV1: async (workspaceId: string, projectId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('listProjectModelsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listProjectModelsV1', 'projectId', projectId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('listProjectModelsV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('listProjectModelsV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/{project_id}/models`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Editable fields of the model i.e name, description using this api.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} modelId 
         * @param {UpdateModelEditableFieldsRequest} updateModelEditableFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelEditableFieldsV1: async (workspaceId: string, projectId: string, modelId: string, updateModelEditableFieldsRequest: UpdateModelEditableFieldsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateModelEditableFieldsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateModelEditableFieldsV1', 'projectId', projectId)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('updateModelEditableFieldsV1', 'modelId', modelId)
            // verify required parameter 'updateModelEditableFieldsRequest' is not null or undefined
            assertParamExists('updateModelEditableFieldsV1', 'updateModelEditableFieldsRequest', updateModelEditableFieldsRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/{project_id}/models/{model_id}/edit-fields`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateModelEditableFieldsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspaceModelsApi - functional programming interface
 * @export
 */
export const WorkspaceModelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspaceModelsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get project model projection (model object with selected columns) details.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} modelId 
         * @param {Array<string>} [projection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectModelProjectionV1(workspaceId: string, projectId: string, modelId: string, projection?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelProjectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectModelProjectionV1(workspaceId, projectId, modelId, projection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get project model details.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectModelV1(workspaceId: string, projectId: string, modelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectModelV1(workspaceId, projectId, modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get paginated list of deleted models  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetModelsResponse: response object with models
         * @summary List deleted project models.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDeletedProjectModelsV1(workspaceId: string, projectId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelsPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDeletedProjectModelsV1(workspaceId, projectId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get paginated list of models  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsResponse: response object with models
         * @summary List active models.
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listModelsV1(workspaceId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelsPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listModelsV1(workspaceId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get paginated list of models for a project  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsResponse: response object with models
         * @summary List active project models.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProjectModelsV1(workspaceId: string, projectId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelsPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProjectModelsV1(workspaceId, projectId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Editable fields of the model i.e name, description using this api.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} modelId 
         * @param {UpdateModelEditableFieldsRequest} updateModelEditableFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateModelEditableFieldsV1(workspaceId: string, projectId: string, modelId: string, updateModelEditableFieldsRequest: UpdateModelEditableFieldsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateModelEditableFieldsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateModelEditableFieldsV1(workspaceId, projectId, modelId, updateModelEditableFieldsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkspaceModelsApi - factory interface
 * @export
 */
export const WorkspaceModelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspaceModelsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get project model projection (model object with selected columns) details.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} modelId 
         * @param {Array<string>} [projection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectModelProjectionV1(workspaceId: string, projectId: string, modelId: string, projection?: Array<string>, options?: any): AxiosPromise<GetModelProjectionResponse> {
            return localVarFp.getProjectModelProjectionV1(workspaceId, projectId, modelId, projection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project model details.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectModelV1(workspaceId: string, projectId: string, modelId: string, options?: any): AxiosPromise<GetModelResponse> {
            return localVarFp.getProjectModelV1(workspaceId, projectId, modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get paginated list of deleted models  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetModelsResponse: response object with models
         * @summary List deleted project models.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeletedProjectModelsV1(workspaceId: string, projectId: string, start: number, end: number, ts?: string, options?: any): AxiosPromise<GetModelsPaginated> {
            return localVarFp.listDeletedProjectModelsV1(workspaceId, projectId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * Get paginated list of models  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsResponse: response object with models
         * @summary List active models.
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listModelsV1(workspaceId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<GetModelsPaginated> {
            return localVarFp.listModelsV1(workspaceId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Get paginated list of models for a project  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsResponse: response object with models
         * @summary List active project models.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectModelsV1(workspaceId: string, projectId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<GetModelsPaginated> {
            return localVarFp.listProjectModelsV1(workspaceId, projectId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Editable fields of the model i.e name, description using this api.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} modelId 
         * @param {UpdateModelEditableFieldsRequest} updateModelEditableFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelEditableFieldsV1(workspaceId: string, projectId: string, modelId: string, updateModelEditableFieldsRequest: UpdateModelEditableFieldsRequest, options?: any): AxiosPromise<UpdateModelEditableFieldsResponse> {
            return localVarFp.updateModelEditableFieldsV1(workspaceId, projectId, modelId, updateModelEditableFieldsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspaceModelsApi - object-oriented interface
 * @export
 * @class WorkspaceModelsApi
 * @extends {BaseAPI}
 */
export class WorkspaceModelsApi extends BaseAPI {
    /**
     * 
     * @summary Get project model projection (model object with selected columns) details.
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {string} modelId 
     * @param {Array<string>} [projection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceModelsApi
     */
    public getProjectModelProjectionV1(workspaceId: string, projectId: string, modelId: string, projection?: Array<string>, options?: AxiosRequestConfig) {
        return WorkspaceModelsApiFp(this.configuration).getProjectModelProjectionV1(workspaceId, projectId, modelId, projection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project model details.
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {string} modelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceModelsApi
     */
    public getProjectModelV1(workspaceId: string, projectId: string, modelId: string, options?: AxiosRequestConfig) {
        return WorkspaceModelsApiFp(this.configuration).getProjectModelV1(workspaceId, projectId, modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get paginated list of deleted models  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.  Returns:     GetModelsResponse: response object with models
     * @summary List deleted project models.
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceModelsApi
     */
    public listDeletedProjectModelsV1(workspaceId: string, projectId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig) {
        return WorkspaceModelsApiFp(this.configuration).listDeletedProjectModelsV1(workspaceId, projectId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get paginated list of models  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsResponse: response object with models
     * @summary List active models.
     * @param {string} workspaceId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceModelsApi
     */
    public listModelsV1(workspaceId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return WorkspaceModelsApiFp(this.configuration).listModelsV1(workspaceId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get paginated list of models for a project  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested model     end (int): end index of the requested model     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetModelsResponse: response object with models
     * @summary List active project models.
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceModelsApi
     */
    public listProjectModelsV1(workspaceId: string, projectId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return WorkspaceModelsApiFp(this.configuration).listProjectModelsV1(workspaceId, projectId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Editable fields of the model i.e name, description using this api.
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {string} modelId 
     * @param {UpdateModelEditableFieldsRequest} updateModelEditableFieldsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceModelsApi
     */
    public updateModelEditableFieldsV1(workspaceId: string, projectId: string, modelId: string, updateModelEditableFieldsRequest: UpdateModelEditableFieldsRequest, options?: AxiosRequestConfig) {
        return WorkspaceModelsApiFp(this.configuration).updateModelEditableFieldsV1(workspaceId, projectId, modelId, updateModelEditableFieldsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkspaceProjectsApi - axios parameter creator
 * @export
 */
export const WorkspaceProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create project.
         * @param {string} workspaceId 
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectV1: async (workspaceId: string, createProjectRequest: CreateProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createProjectV1', 'workspaceId', workspaceId)
            // verify required parameter 'createProjectRequest' is not null or undefined
            assertParamExists('createProjectV1', 'createProjectRequest', createProjectRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/create-project`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectV1: async (workspaceId: string, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProjectV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectV1', 'projectId', projectId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/{project_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get paginated list of projects  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested project     end (int): end index of the requested project     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetProjectsResponse: response object with projects
         * @summary Get list of projects.
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsV1: async (workspaceId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProjectsV1', 'workspaceId', workspaceId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getProjectsV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getProjectsV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/get-projects`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update artifact state of project
         * @param {string} workspaceId 
         * @param {UpdateProjectArtifactState} updateProjectArtifactState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArtifactStateProjectV1: async (workspaceId: string, updateProjectArtifactState: UpdateProjectArtifactState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateArtifactStateProjectV1', 'workspaceId', workspaceId)
            // verify required parameter 'updateProjectArtifactState' is not null or undefined
            assertParamExists('updateArtifactStateProjectV1', 'updateProjectArtifactState', updateProjectArtifactState)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/state`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectArtifactState, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Editable fields of the project i.e name, description using this api.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {UpdateEditableProjectFieldRequest} updateEditableProjectFieldRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectEditableFieldsV1: async (workspaceId: string, projectId: string, updateEditableProjectFieldRequest: UpdateEditableProjectFieldRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateProjectEditableFieldsV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateProjectEditableFieldsV1', 'projectId', projectId)
            // verify required parameter 'updateEditableProjectFieldRequest' is not null or undefined
            assertParamExists('updateProjectEditableFieldsV1', 'updateEditableProjectFieldRequest', updateEditableProjectFieldRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/{project_id}/edit-fields`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEditableProjectFieldRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspaceProjectsApi - functional programming interface
 * @export
 */
export const WorkspaceProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspaceProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create project.
         * @param {string} workspaceId 
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectV1(workspaceId: string, createProjectRequest: CreateProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectV1(workspaceId, createProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get project.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectV1(workspaceId: string, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectV1(workspaceId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get paginated list of projects  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested project     end (int): end index of the requested project     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetProjectsResponse: response object with projects
         * @summary Get list of projects.
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsV1(workspaceId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectsPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsV1(workspaceId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update artifact state of project
         * @param {string} workspaceId 
         * @param {UpdateProjectArtifactState} updateProjectArtifactState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArtifactStateProjectV1(workspaceId: string, updateProjectArtifactState: UpdateProjectArtifactState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateChangeAck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateArtifactStateProjectV1(workspaceId, updateProjectArtifactState, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Editable fields of the project i.e name, description using this api.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {UpdateEditableProjectFieldRequest} updateEditableProjectFieldRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectEditableFieldsV1(workspaceId: string, projectId: string, updateEditableProjectFieldRequest: UpdateEditableProjectFieldRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateEditableProjectFieldResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectEditableFieldsV1(workspaceId, projectId, updateEditableProjectFieldRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkspaceProjectsApi - factory interface
 * @export
 */
export const WorkspaceProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspaceProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create project.
         * @param {string} workspaceId 
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectV1(workspaceId: string, createProjectRequest: CreateProjectRequest, options?: any): AxiosPromise<CreateProjectResponse> {
            return localVarFp.createProjectV1(workspaceId, createProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectV1(workspaceId: string, projectId: string, options?: any): AxiosPromise<GetProjectResponse> {
            return localVarFp.getProjectV1(workspaceId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get paginated list of projects  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested project     end (int): end index of the requested project     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetProjectsResponse: response object with projects
         * @summary Get list of projects.
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsV1(workspaceId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<GetProjectsPaginated> {
            return localVarFp.getProjectsV1(workspaceId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update artifact state of project
         * @param {string} workspaceId 
         * @param {UpdateProjectArtifactState} updateProjectArtifactState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArtifactStateProjectV1(workspaceId: string, updateProjectArtifactState: UpdateProjectArtifactState, options?: any): AxiosPromise<StateChangeAck> {
            return localVarFp.updateArtifactStateProjectV1(workspaceId, updateProjectArtifactState, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Editable fields of the project i.e name, description using this api.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {UpdateEditableProjectFieldRequest} updateEditableProjectFieldRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectEditableFieldsV1(workspaceId: string, projectId: string, updateEditableProjectFieldRequest: UpdateEditableProjectFieldRequest, options?: any): AxiosPromise<UpdateEditableProjectFieldResponse> {
            return localVarFp.updateProjectEditableFieldsV1(workspaceId, projectId, updateEditableProjectFieldRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspaceProjectsApi - object-oriented interface
 * @export
 * @class WorkspaceProjectsApi
 * @extends {BaseAPI}
 */
export class WorkspaceProjectsApi extends BaseAPI {
    /**
     * 
     * @summary Create project.
     * @param {string} workspaceId 
     * @param {CreateProjectRequest} createProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceProjectsApi
     */
    public createProjectV1(workspaceId: string, createProjectRequest: CreateProjectRequest, options?: AxiosRequestConfig) {
        return WorkspaceProjectsApiFp(this.configuration).createProjectV1(workspaceId, createProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project.
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceProjectsApi
     */
    public getProjectV1(workspaceId: string, projectId: string, options?: AxiosRequestConfig) {
        return WorkspaceProjectsApiFp(this.configuration).getProjectV1(workspaceId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get paginated list of projects  Args:     workspace_id (str): customer id that uniquely identifies the customer     start (int): start of the requested project     end (int): end index of the requested project     request (Request): FastAPI Request object     response (Response): FastAPI Response object     ts (Optional[str], optional): timestamp value of the last record returned to the previous response. This is a pivot for pagination. Defaults to \"\".     user (WorkspaceUser, optional): object with details of the user who made this request.     filters (Dict, optional): filter by allowed filterable fields. (see list_query_filters)  Returns:     GetProjectsResponse: response object with projects
     * @summary Get list of projects.
     * @param {string} workspaceId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceProjectsApi
     */
    public getProjectsV1(workspaceId: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return WorkspaceProjectsApiFp(this.configuration).getProjectsV1(workspaceId, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update artifact state of project
     * @param {string} workspaceId 
     * @param {UpdateProjectArtifactState} updateProjectArtifactState 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceProjectsApi
     */
    public updateArtifactStateProjectV1(workspaceId: string, updateProjectArtifactState: UpdateProjectArtifactState, options?: AxiosRequestConfig) {
        return WorkspaceProjectsApiFp(this.configuration).updateArtifactStateProjectV1(workspaceId, updateProjectArtifactState, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Editable fields of the project i.e name, description using this api.
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {UpdateEditableProjectFieldRequest} updateEditableProjectFieldRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceProjectsApi
     */
    public updateProjectEditableFieldsV1(workspaceId: string, projectId: string, updateEditableProjectFieldRequest: UpdateEditableProjectFieldRequest, options?: AxiosRequestConfig) {
        return WorkspaceProjectsApiFp(this.configuration).updateProjectEditableFieldsV1(workspaceId, projectId, updateEditableProjectFieldRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkspaceRecordingsApi - axios parameter creator
 * @export
 */
export const WorkspaceRecordingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete the list of recordings.
         * @param {string} workspaceId 
         * @param {DeleteRecordingRequest} deleteRecordingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceDeleteRecordingsV1: async (workspaceId: string, deleteRecordingRequest: DeleteRecordingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceDeleteRecordingsV1', 'workspaceId', workspaceId)
            // verify required parameter 'deleteRecordingRequest' is not null or undefined
            assertParamExists('workspaceDeleteRecordingsV1', 'deleteRecordingRequest', deleteRecordingRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/recordings/delete`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteRecordingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return metric series against epoch for the given key for  the experiment
         * @summary Return metric series against epoch for the given key for  the experiment
         * @param {string} workspaceId 
         * @param {string} runId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceExperimentMetricV1: async (workspaceId: string, runId: string, key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceExperimentMetricV1', 'workspaceId', workspaceId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('workspaceExperimentMetricV1', 'runId', runId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('workspaceExperimentMetricV1', 'key', key)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/run/{run_id}/metric`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Disagreements
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {Array<string>} [recId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetComparisonDisagreementV1: async (workspaceId: string, start: number, end: number, recId?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetComparisonDisagreementV1', 'workspaceId', workspaceId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('workspaceGetComparisonDisagreementV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('workspaceGetComparisonDisagreementV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/dis-compare`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (recId) {
                localVarQueryParameter['rec_id'] = recId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the custom metrics computed for the recording  with the given recording id. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
         * @summary Return the custom metrics computed given the recording id.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetComputedCustomMetricsForRecordingV1: async (workspaceId: string, recordingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetComputedCustomMetricsForRecordingV1', 'workspaceId', workspaceId)
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('workspaceGetComputedCustomMetricsForRecordingV1', 'recordingId', recordingId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/recordings/{recording_id}/custom-metrics`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the distributions (actual and predicted) computed for the recording with the given recording id. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
         * @summary Return the distributions computed given the recording id.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetComputedDistributionsForRecordingV1: async (workspaceId: string, recordingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetComputedDistributionsForRecordingV1', 'workspaceId', workspaceId)
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('workspaceGetComputedDistributionsForRecordingV1', 'recordingId', recordingId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/recordings/{recording_id}/distributions`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the metrics computed for the recording  with the given recording id. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
         * @summary Return the metrics computed given the recording id.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetComputedMetricsForRecordingV1: async (workspaceId: string, recordingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetComputedMetricsForRecordingV1', 'workspaceId', workspaceId)
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('workspaceGetComputedMetricsForRecordingV1', 'recordingId', recordingId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/recordings/{recording_id}/metrics`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the confusion matrix and related metadata in the response. The rows and columns of the matrix are ordered in the order in which all_labels are ordered. e.g if the labels are ordered as =[\'cat\',\'dog\',\'cow\'], the confusion matrix has the form [\'cat\'],[\'dog\'],[\'cow\'] [\'cat][\'dog\'][\'cow\']
         * @summary Return the confusion matrix and related metadata in the response.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetConfusionMatrixForRecordingV1: async (workspaceId: string, recordingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetConfusionMatrixForRecordingV1', 'workspaceId', workspaceId)
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('workspaceGetConfusionMatrixForRecordingV1', 'recordingId', recordingId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/recordings/{recording_id}/confusion-matrix`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ``` Get all the deleted recordings by model class. Args:     model_class ():     user (WorkspaceUser): object with details of the user who made this request     workspace_id (str):  customer id that uniquely identifies the customer     start (int): start of the requested recording     end (int): end index of the requested recording     request (Request): FastAPI Request object. This is required by the decorator to extract header information     response (Response): FastAPI Response object. This is required by the decorator to set status     ts (str): timestamp value of the last record returned to the previous response. This is a pivot for pagination.     end-start+1 record from this pivot are returned     filters: filters to be applied to the query to filter the recordings             Supported filters are name, user_ids, exclude_user_ids. Detailed documentation available in the             implementation of the dependency `list_query_filters`. Returns:     GetRecordingsListResponse response object with the recordings and the metrics ```
         * @summary Get all the deleted recordings by model class.
         * @param {string} workspaceId 
         * @param {string} modelClass 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDeletedRecordingsListForModelClassV1: async (workspaceId: string, modelClass: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDeletedRecordingsListForModelClassV1', 'workspaceId', workspaceId)
            // verify required parameter 'modelClass' is not null or undefined
            assertParamExists('workspaceGetDeletedRecordingsListForModelClassV1', 'modelClass', modelClass)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('workspaceGetDeletedRecordingsListForModelClassV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('workspaceGetDeletedRecordingsListForModelClassV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/deleted-recordings/model-class/{model_class}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_class"}}`, encodeURIComponent(String(modelClass)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return summary of the recordings grouped by model_class.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDeletedRecordingsSummaryV1: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDeletedRecordingsSummaryV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/deleted-recordings-summary`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the disagreements between actual and the inferred in this recording. This helps to understand where the model is making a mistake and what are records miss classified as. Disagreements are only available for classification problems
         * @summary Return the disagreements between the actual and the inferred.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {number} start 
         * @param {number} end 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDisagreementsForRecordingV1: async (workspaceId: string, recordingId: string, start: number, end: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDisagreementsForRecordingV1', 'workspaceId', workspaceId)
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('workspaceGetDisagreementsForRecordingV1', 'recordingId', recordingId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('workspaceGetDisagreementsForRecordingV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('workspaceGetDisagreementsForRecordingV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/recordings/{recording_id}/disagreement`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the discordant pair map for this recording. discordant pair map\'s key is the true label and value is a dictionary with key as predicted_labeland value as number of times the true label was classified as this predicted label. This helps to understand how often are records misclassified something other than their true label and identify if pattern if any.
         * @summary Return the discordant pair map for this recording. 
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDiscordantPairMapForRecordingV1: async (workspaceId: string, recordingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetDiscordantPairMapForRecordingV1', 'workspaceId', workspaceId)
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('workspaceGetDiscordantPairMapForRecordingV1', 'recordingId', recordingId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/recordings/{recording_id}/dis-map`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return all the metric keys for this  experiment  this customer
         * @summary Return all the metric keys for this  experiment  this customer
         * @param {string} workspaceId 
         * @param {string} runId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetExperimentKeysV1: async (workspaceId: string, runId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetExperimentKeysV1', 'workspaceId', workspaceId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('workspaceGetExperimentKeysV1', 'runId', runId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/run/{run_id}/keys`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return all the experiments this workspace. This is a paginated API, and supports filters on the experiment name, experiments created by a user(s), experiments created by users other than the specified user(s).
         * @summary Return all the experiments this workspace.
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetExperimentsListV1: async (workspaceId: string, start: number, end: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetExperimentsListV1', 'workspaceId', workspaceId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('workspaceGetExperimentsListV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('workspaceGetExperimentsListV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/experiments`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (datasetId !== undefined) {
                localVarQueryParameter['dataset_id'] = datasetId;
            }

            if (modelId !== undefined) {
                localVarQueryParameter['model_id'] = modelId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Filter Disagreement results based on constraints provided in the request body
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {SimpleFilterRequest} simpleFilterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetFilteredDisagreementForRecordingV1: async (workspaceId: string, recordingId: string, simpleFilterRequest: SimpleFilterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetFilteredDisagreementForRecordingV1', 'workspaceId', workspaceId)
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('workspaceGetFilteredDisagreementForRecordingV1', 'recordingId', recordingId)
            // verify required parameter 'simpleFilterRequest' is not null or undefined
            assertParamExists('workspaceGetFilteredDisagreementForRecordingV1', 'simpleFilterRequest', simpleFilterRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/recordings/{recording_id}/filtered-disagreement`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(simpleFilterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the comparison of model recordings at class level for the multi-model comparison matrix
         * @param {string} workspaceId 
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetMultiModelComparisonV1: async (workspaceId: string, q: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetMultiModelComparisonV1', 'workspaceId', workspaceId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('workspaceGetMultiModelComparisonV1', 'q', q)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/multi-model-matrix/`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the meta data associated with a recording.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetRecordingMetadataV1: async (workspaceId: string, recordingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetRecordingMetadataV1', 'workspaceId', workspaceId)
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('workspaceGetRecordingMetadataV1', 'recordingId', recordingId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/recordings/{recording_id}/metadata`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ``` Display the recordings with metrics for the given workspace. Args:     model_class (str): model class name (e.g. tagging)     user (WorkspaceUser): object with details of the user who made this request     workspace_id (str):  customer id that uniquely identifies the customer     start (int): start of the requested recording     end (int): end index of the requested recording     request (Request): FastAPI Request object. This is required by the decorator to extract header information     response (Response): FastAPI Response object. This is required by the decorator to set status     ts (str): timestamp value of the last record returned to the previous response. This is a pivot for pagination.     end-start+1 record from this pivot are returned     filters: filters to be applied to the query to filter the recordings             Supported filters are name, user_ids, exclude_user_ids. Detailed documentation available in the             implementation of the dependency `list_query_filters`. Returns:     GetRecordingsListResponse: response object with the recordings and the metrics ```
         * @summary Get all the recordings by model class along with their statuses
         * @param {string} workspaceId 
         * @param {string} modelClass 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetRecordingsListForModelClassV1: async (workspaceId: string, modelClass: string, start: number, end: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetRecordingsListForModelClassV1', 'workspaceId', workspaceId)
            // verify required parameter 'modelClass' is not null or undefined
            assertParamExists('workspaceGetRecordingsListForModelClassV1', 'modelClass', modelClass)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('workspaceGetRecordingsListForModelClassV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('workspaceGetRecordingsListForModelClassV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/recordings/model-class/{model_class}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"model_class"}}`, encodeURIComponent(String(modelClass)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (datasetId !== undefined) {
                localVarQueryParameter['dataset_id'] = datasetId;
            }

            if (modelId !== undefined) {
                localVarQueryParameter['model_id'] = modelId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the records for the given recording id for this particular customer. The number of records returned are paginated by start and end indexes. In order to reduce IO, At a given time not more than 100 records can be requested
         * @summary Return all the recordings with metrics for this customer
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {boolean} [withMetrics] 
         * @param {string} [ts] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetRecordingsListV1: async (workspaceId: string, start: number, end: number, withMetrics?: boolean, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetRecordingsListV1', 'workspaceId', workspaceId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('workspaceGetRecordingsListV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('workspaceGetRecordingsListV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/recordings`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (withMetrics !== undefined) {
                localVarQueryParameter['with_metrics'] = withMetrics;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }

            if (datasetId !== undefined) {
                localVarQueryParameter['dataset_id'] = datasetId;
            }

            if (modelId !== undefined) {
                localVarQueryParameter['model_id'] = modelId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (excludeUserIds) {
                localVarQueryParameter['exclude_user_ids'] = excludeUserIds;
            }

            if (recordIds) {
                localVarQueryParameter['record_ids'] = recordIds;
            }

            if (excludeRecordIds) {
                localVarQueryParameter['exclude_record_ids'] = excludeRecordIds;
            }

            if (excludeDemoArtifacts !== undefined) {
                localVarQueryParameter['exclude_demo_artifacts'] = excludeDemoArtifacts;
            }

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return summary of the recordings grouped by model_class.
         * @param {string} workspaceId 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetRecordingsSummaryV1: async (workspaceId: string, artifactFilter?: ArtifactStateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetRecordingsSummaryV1', 'workspaceId', workspaceId)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/recordings-summary`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (artifactFilter !== undefined) {
                localVarQueryParameter['artifact_filter'] = artifactFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the records for the given recording id for this particular customer. The number of records returned are paginated by start and end indexes.
         * @summary display the records the given recording id.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetRecordsForRecordingV1: async (workspaceId: string, recordingId: string, start: number, end: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetRecordsForRecordingV1', 'workspaceId', workspaceId)
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('workspaceGetRecordsForRecordingV1', 'recordingId', recordingId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('workspaceGetRecordsForRecordingV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('workspaceGetRecordsForRecordingV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/recordings/{recording_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return all the experiments for this user of this workspace
         * @summary Return all the experiments for this user of this workspace
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetUserExperimentsV1: async (workspaceId: string, start: number, end: number, ts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceGetUserExperimentsV1', 'workspaceId', workspaceId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('workspaceGetUserExperimentsV1', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('workspaceGetUserExperimentsV1', 'end', end)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/user/experiments`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soft delete the recordings.
         * @param {string} workspaceId 
         * @param {DeleteRecordingRequest} deleteRecordingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceSoftDeleteRecordingsV1: async (workspaceId: string, deleteRecordingRequest: DeleteRecordingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceSoftDeleteRecordingsV1', 'workspaceId', workspaceId)
            // verify required parameter 'deleteRecordingRequest' is not null or undefined
            assertParamExists('workspaceSoftDeleteRecordingsV1', 'deleteRecordingRequest', deleteRecordingRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/recordings/soft-delete`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteRecordingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary undo soft delete of recordings
         * @param {string} workspaceId 
         * @param {DeleteRecordingRequest} deleteRecordingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceUndoSoftDeleteRecordingsV1: async (workspaceId: string, deleteRecordingRequest: DeleteRecordingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceUndoSoftDeleteRecordingsV1', 'workspaceId', workspaceId)
            // verify required parameter 'deleteRecordingRequest' is not null or undefined
            assertParamExists('workspaceUndoSoftDeleteRecordingsV1', 'deleteRecordingRequest', deleteRecordingRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/recordings/undo-soft-delete`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteRecordingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update editable fields for this given recording_id.The editable field are (note, name): 1. name i.e name of model 2. note i.e description field for this recording
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} recordingId 
         * @param {UpdateEditableEvalFieldsRequest} updateEditableEvalFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceUpdateEditableEvalFieldV1: async (workspaceId: string, projectId: string, recordingId: string, updateEditableEvalFieldsRequest: UpdateEditableEvalFieldsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspaceUpdateEditableEvalFieldV1', 'workspaceId', workspaceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('workspaceUpdateEditableEvalFieldV1', 'projectId', projectId)
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('workspaceUpdateEditableEvalFieldV1', 'recordingId', recordingId)
            // verify required parameter 'updateEditableEvalFieldsRequest' is not null or undefined
            assertParamExists('workspaceUpdateEditableEvalFieldV1', 'updateEditableEvalFieldsRequest', updateEditableEvalFieldsRequest)
            const localVarPath = `/reno/v1/workspace/{workspace_id}/projects/{project_id}/recordings/{recording_id}/edit-fields`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MKVHTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEditableEvalFieldsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspaceRecordingsApi - functional programming interface
 * @export
 */
export const WorkspaceRecordingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspaceRecordingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete the list of recordings.
         * @param {string} workspaceId 
         * @param {DeleteRecordingRequest} deleteRecordingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceDeleteRecordingsV1(workspaceId: string, deleteRecordingRequest: DeleteRecordingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteRecordingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceDeleteRecordingsV1(workspaceId, deleteRecordingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return metric series against epoch for the given key for  the experiment
         * @summary Return metric series against epoch for the given key for  the experiment
         * @param {string} workspaceId 
         * @param {string} runId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceExperimentMetricV1(workspaceId: string, runId: string, key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperimentMetricResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceExperimentMetricV1(workspaceId, runId, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Disagreements
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {Array<string>} [recId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetComparisonDisagreementV1(workspaceId: string, start: number, end: number, recId?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparisonDisagreementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetComparisonDisagreementV1(workspaceId, start, end, recId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the custom metrics computed for the recording  with the given recording id. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
         * @summary Return the custom metrics computed given the recording id.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetComputedCustomMetricsForRecordingV1(workspaceId: string, recordingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetComputedCustomMetricsForRecordingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetComputedCustomMetricsForRecordingV1(workspaceId, recordingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the distributions (actual and predicted) computed for the recording with the given recording id. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
         * @summary Return the distributions computed given the recording id.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetComputedDistributionsForRecordingV1(workspaceId: string, recordingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetComputedDistributionsForRecordingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetComputedDistributionsForRecordingV1(workspaceId, recordingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the metrics computed for the recording  with the given recording id. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
         * @summary Return the metrics computed given the recording id.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetComputedMetricsForRecordingV1(workspaceId: string, recordingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetComputedMetricsForRecordingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetComputedMetricsForRecordingV1(workspaceId, recordingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the confusion matrix and related metadata in the response. The rows and columns of the matrix are ordered in the order in which all_labels are ordered. e.g if the labels are ordered as =[\'cat\',\'dog\',\'cow\'], the confusion matrix has the form [\'cat\'],[\'dog\'],[\'cow\'] [\'cat][\'dog\'][\'cow\']
         * @summary Return the confusion matrix and related metadata in the response.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetConfusionMatrixForRecordingV1(workspaceId: string, recordingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConfusionMatrixResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetConfusionMatrixForRecordingV1(workspaceId, recordingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ``` Get all the deleted recordings by model class. Args:     model_class ():     user (WorkspaceUser): object with details of the user who made this request     workspace_id (str):  customer id that uniquely identifies the customer     start (int): start of the requested recording     end (int): end index of the requested recording     request (Request): FastAPI Request object. This is required by the decorator to extract header information     response (Response): FastAPI Response object. This is required by the decorator to set status     ts (str): timestamp value of the last record returned to the previous response. This is a pivot for pagination.     end-start+1 record from this pivot are returned     filters: filters to be applied to the query to filter the recordings             Supported filters are name, user_ids, exclude_user_ids. Detailed documentation available in the             implementation of the dependency `list_query_filters`. Returns:     GetRecordingsListResponse response object with the recordings and the metrics ```
         * @summary Get all the deleted recordings by model class.
         * @param {string} workspaceId 
         * @param {string} modelClass 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDeletedRecordingsListForModelClassV1(workspaceId: string, modelClass: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecordingsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDeletedRecordingsListForModelClassV1(workspaceId, modelClass, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return summary of the recordings grouped by model_class.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDeletedRecordingsSummaryV1(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecordingsSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDeletedRecordingsSummaryV1(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the disagreements between actual and the inferred in this recording. This helps to understand where the model is making a mistake and what are records miss classified as. Disagreements are only available for classification problems
         * @summary Return the disagreements between the actual and the inferred.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {number} start 
         * @param {number} end 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDisagreementsForRecordingV1(workspaceId: string, recordingId: string, start: number, end: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaginatedDisagreementsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDisagreementsForRecordingV1(workspaceId, recordingId, start, end, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the discordant pair map for this recording. discordant pair map\'s key is the true label and value is a dictionary with key as predicted_labeland value as number of times the true label was classified as this predicted label. This helps to understand how often are records misclassified something other than their true label and identify if pattern if any.
         * @summary Return the discordant pair map for this recording. 
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetDiscordantPairMapForRecordingV1(workspaceId: string, recordingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceServiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetDiscordantPairMapForRecordingV1(workspaceId, recordingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return all the metric keys for this  experiment  this customer
         * @summary Return all the metric keys for this  experiment  this customer
         * @param {string} workspaceId 
         * @param {string} runId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetExperimentKeysV1(workspaceId: string, runId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperimentMetricKeys>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetExperimentKeysV1(workspaceId, runId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return all the experiments this workspace. This is a paginated API, and supports filters on the experiment name, experiments created by a user(s), experiments created by users other than the specified user(s).
         * @summary Return all the experiments this workspace.
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetExperimentsListV1(workspaceId: string, start: number, end: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperimentRecordingsMetaData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetExperimentsListV1(workspaceId, start, end, ts, datasetId, modelId, projectId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Filter Disagreement results based on constraints provided in the request body
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {SimpleFilterRequest} simpleFilterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetFilteredDisagreementForRecordingV1(workspaceId: string, recordingId: string, simpleFilterRequest: SimpleFilterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceServiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetFilteredDisagreementForRecordingV1(workspaceId, recordingId, simpleFilterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return the comparison of model recordings at class level for the multi-model comparison matrix
         * @param {string} workspaceId 
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetMultiModelComparisonV1(workspaceId: string, q: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiModelComparisonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetMultiModelComparisonV1(workspaceId, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return the meta data associated with a recording.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetRecordingMetadataV1(workspaceId: string, recordingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecordingMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetRecordingMetadataV1(workspaceId, recordingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ``` Display the recordings with metrics for the given workspace. Args:     model_class (str): model class name (e.g. tagging)     user (WorkspaceUser): object with details of the user who made this request     workspace_id (str):  customer id that uniquely identifies the customer     start (int): start of the requested recording     end (int): end index of the requested recording     request (Request): FastAPI Request object. This is required by the decorator to extract header information     response (Response): FastAPI Response object. This is required by the decorator to set status     ts (str): timestamp value of the last record returned to the previous response. This is a pivot for pagination.     end-start+1 record from this pivot are returned     filters: filters to be applied to the query to filter the recordings             Supported filters are name, user_ids, exclude_user_ids. Detailed documentation available in the             implementation of the dependency `list_query_filters`. Returns:     GetRecordingsListResponse: response object with the recordings and the metrics ```
         * @summary Get all the recordings by model class along with their statuses
         * @param {string} workspaceId 
         * @param {string} modelClass 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetRecordingsListForModelClassV1(workspaceId: string, modelClass: string, start: number, end: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecordingsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetRecordingsListForModelClassV1(workspaceId, modelClass, start, end, ts, datasetId, modelId, projectId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the records for the given recording id for this particular customer. The number of records returned are paginated by start and end indexes. In order to reduce IO, At a given time not more than 100 records can be requested
         * @summary Return all the recordings with metrics for this customer
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {boolean} [withMetrics] 
         * @param {string} [ts] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetRecordingsListV1(workspaceId: string, start: number, end: number, withMetrics?: boolean, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecordingsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetRecordingsListV1(workspaceId, start, end, withMetrics, ts, datasetId, modelId, projectId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return summary of the recordings grouped by model_class.
         * @param {string} workspaceId 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetRecordingsSummaryV1(workspaceId: string, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecordingsSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetRecordingsSummaryV1(workspaceId, artifactFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the records for the given recording id for this particular customer. The number of records returned are paginated by start and end indexes.
         * @summary display the records the given recording id.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetRecordsForRecordingV1(workspaceId: string, recordingId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaginatedRecordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetRecordsForRecordingV1(workspaceId, recordingId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return all the experiments for this user of this workspace
         * @summary Return all the experiments for this user of this workspace
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceGetUserExperimentsV1(workspaceId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperimentRecordingsMetaData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceGetUserExperimentsV1(workspaceId, start, end, ts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Soft delete the recordings.
         * @param {string} workspaceId 
         * @param {DeleteRecordingRequest} deleteRecordingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceSoftDeleteRecordingsV1(workspaceId: string, deleteRecordingRequest: DeleteRecordingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateChangeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceSoftDeleteRecordingsV1(workspaceId, deleteRecordingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary undo soft delete of recordings
         * @param {string} workspaceId 
         * @param {DeleteRecordingRequest} deleteRecordingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceUndoSoftDeleteRecordingsV1(workspaceId: string, deleteRecordingRequest: DeleteRecordingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateChangeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceUndoSoftDeleteRecordingsV1(workspaceId, deleteRecordingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update editable fields for this given recording_id.The editable field are (note, name): 1. name i.e name of model 2. note i.e description field for this recording
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} recordingId 
         * @param {UpdateEditableEvalFieldsRequest} updateEditableEvalFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspaceUpdateEditableEvalFieldV1(workspaceId: string, projectId: string, recordingId: string, updateEditableEvalFieldsRequest: UpdateEditableEvalFieldsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateEditableEvalFieldsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspaceUpdateEditableEvalFieldV1(workspaceId, projectId, recordingId, updateEditableEvalFieldsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkspaceRecordingsApi - factory interface
 * @export
 */
export const WorkspaceRecordingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspaceRecordingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete the list of recordings.
         * @param {string} workspaceId 
         * @param {DeleteRecordingRequest} deleteRecordingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceDeleteRecordingsV1(workspaceId: string, deleteRecordingRequest: DeleteRecordingRequest, options?: any): AxiosPromise<DeleteRecordingsResponse> {
            return localVarFp.workspaceDeleteRecordingsV1(workspaceId, deleteRecordingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Return metric series against epoch for the given key for  the experiment
         * @summary Return metric series against epoch for the given key for  the experiment
         * @param {string} workspaceId 
         * @param {string} runId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceExperimentMetricV1(workspaceId: string, runId: string, key: string, options?: any): AxiosPromise<ExperimentMetricResponse> {
            return localVarFp.workspaceExperimentMetricV1(workspaceId, runId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Disagreements
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {Array<string>} [recId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetComparisonDisagreementV1(workspaceId: string, start: number, end: number, recId?: Array<string>, options?: any): AxiosPromise<ComparisonDisagreementResponse> {
            return localVarFp.workspaceGetComparisonDisagreementV1(workspaceId, start, end, recId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the custom metrics computed for the recording  with the given recording id. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
         * @summary Return the custom metrics computed given the recording id.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetComputedCustomMetricsForRecordingV1(workspaceId: string, recordingId: string, options?: any): AxiosPromise<GetComputedCustomMetricsForRecordingResponse> {
            return localVarFp.workspaceGetComputedCustomMetricsForRecordingV1(workspaceId, recordingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the distributions (actual and predicted) computed for the recording with the given recording id. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
         * @summary Return the distributions computed given the recording id.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetComputedDistributionsForRecordingV1(workspaceId: string, recordingId: string, options?: any): AxiosPromise<GetComputedDistributionsForRecordingResponse> {
            return localVarFp.workspaceGetComputedDistributionsForRecordingV1(workspaceId, recordingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the metrics computed for the recording  with the given recording id. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
         * @summary Return the metrics computed given the recording id.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetComputedMetricsForRecordingV1(workspaceId: string, recordingId: string, options?: any): AxiosPromise<GetComputedMetricsForRecordingResponse> {
            return localVarFp.workspaceGetComputedMetricsForRecordingV1(workspaceId, recordingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the confusion matrix and related metadata in the response. The rows and columns of the matrix are ordered in the order in which all_labels are ordered. e.g if the labels are ordered as =[\'cat\',\'dog\',\'cow\'], the confusion matrix has the form [\'cat\'],[\'dog\'],[\'cow\'] [\'cat][\'dog\'][\'cow\']
         * @summary Return the confusion matrix and related metadata in the response.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetConfusionMatrixForRecordingV1(workspaceId: string, recordingId: string, options?: any): AxiosPromise<GetConfusionMatrixResponse> {
            return localVarFp.workspaceGetConfusionMatrixForRecordingV1(workspaceId, recordingId, options).then((request) => request(axios, basePath));
        },
        /**
         * ``` Get all the deleted recordings by model class. Args:     model_class ():     user (WorkspaceUser): object with details of the user who made this request     workspace_id (str):  customer id that uniquely identifies the customer     start (int): start of the requested recording     end (int): end index of the requested recording     request (Request): FastAPI Request object. This is required by the decorator to extract header information     response (Response): FastAPI Response object. This is required by the decorator to set status     ts (str): timestamp value of the last record returned to the previous response. This is a pivot for pagination.     end-start+1 record from this pivot are returned     filters: filters to be applied to the query to filter the recordings             Supported filters are name, user_ids, exclude_user_ids. Detailed documentation available in the             implementation of the dependency `list_query_filters`. Returns:     GetRecordingsListResponse response object with the recordings and the metrics ```
         * @summary Get all the deleted recordings by model class.
         * @param {string} workspaceId 
         * @param {string} modelClass 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDeletedRecordingsListForModelClassV1(workspaceId: string, modelClass: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<GetRecordingsListResponse> {
            return localVarFp.workspaceGetDeletedRecordingsListForModelClassV1(workspaceId, modelClass, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return summary of the recordings grouped by model_class.
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDeletedRecordingsSummaryV1(workspaceId: string, options?: any): AxiosPromise<GetRecordingsSummaryResponse> {
            return localVarFp.workspaceGetDeletedRecordingsSummaryV1(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the disagreements between actual and the inferred in this recording. This helps to understand where the model is making a mistake and what are records miss classified as. Disagreements are only available for classification problems
         * @summary Return the disagreements between the actual and the inferred.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {number} start 
         * @param {number} end 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDisagreementsForRecordingV1(workspaceId: string, recordingId: string, start: number, end: number, limit?: number, options?: any): AxiosPromise<GetPaginatedDisagreementsResponse> {
            return localVarFp.workspaceGetDisagreementsForRecordingV1(workspaceId, recordingId, start, end, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the discordant pair map for this recording. discordant pair map\'s key is the true label and value is a dictionary with key as predicted_labeland value as number of times the true label was classified as this predicted label. This helps to understand how often are records misclassified something other than their true label and identify if pattern if any.
         * @summary Return the discordant pair map for this recording. 
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetDiscordantPairMapForRecordingV1(workspaceId: string, recordingId: string, options?: any): AxiosPromise<WorkspaceServiceResponse> {
            return localVarFp.workspaceGetDiscordantPairMapForRecordingV1(workspaceId, recordingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return all the metric keys for this  experiment  this customer
         * @summary Return all the metric keys for this  experiment  this customer
         * @param {string} workspaceId 
         * @param {string} runId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetExperimentKeysV1(workspaceId: string, runId: string, options?: any): AxiosPromise<ExperimentMetricKeys> {
            return localVarFp.workspaceGetExperimentKeysV1(workspaceId, runId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return all the experiments this workspace. This is a paginated API, and supports filters on the experiment name, experiments created by a user(s), experiments created by users other than the specified user(s).
         * @summary Return all the experiments this workspace.
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetExperimentsListV1(workspaceId: string, start: number, end: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<ExperimentRecordingsMetaData> {
            return localVarFp.workspaceGetExperimentsListV1(workspaceId, start, end, ts, datasetId, modelId, projectId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Filter Disagreement results based on constraints provided in the request body
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {SimpleFilterRequest} simpleFilterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetFilteredDisagreementForRecordingV1(workspaceId: string, recordingId: string, simpleFilterRequest: SimpleFilterRequest, options?: any): AxiosPromise<WorkspaceServiceResponse> {
            return localVarFp.workspaceGetFilteredDisagreementForRecordingV1(workspaceId, recordingId, simpleFilterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the comparison of model recordings at class level for the multi-model comparison matrix
         * @param {string} workspaceId 
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetMultiModelComparisonV1(workspaceId: string, q: string, options?: any): AxiosPromise<MultiModelComparisonResponse> {
            return localVarFp.workspaceGetMultiModelComparisonV1(workspaceId, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the meta data associated with a recording.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetRecordingMetadataV1(workspaceId: string, recordingId: string, options?: any): AxiosPromise<GetRecordingMetadataResponse> {
            return localVarFp.workspaceGetRecordingMetadataV1(workspaceId, recordingId, options).then((request) => request(axios, basePath));
        },
        /**
         * ``` Display the recordings with metrics for the given workspace. Args:     model_class (str): model class name (e.g. tagging)     user (WorkspaceUser): object with details of the user who made this request     workspace_id (str):  customer id that uniquely identifies the customer     start (int): start of the requested recording     end (int): end index of the requested recording     request (Request): FastAPI Request object. This is required by the decorator to extract header information     response (Response): FastAPI Response object. This is required by the decorator to set status     ts (str): timestamp value of the last record returned to the previous response. This is a pivot for pagination.     end-start+1 record from this pivot are returned     filters: filters to be applied to the query to filter the recordings             Supported filters are name, user_ids, exclude_user_ids. Detailed documentation available in the             implementation of the dependency `list_query_filters`. Returns:     GetRecordingsListResponse: response object with the recordings and the metrics ```
         * @summary Get all the recordings by model class along with their statuses
         * @param {string} workspaceId 
         * @param {string} modelClass 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetRecordingsListForModelClassV1(workspaceId: string, modelClass: string, start: number, end: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<GetRecordingsListResponse> {
            return localVarFp.workspaceGetRecordingsListForModelClassV1(workspaceId, modelClass, start, end, ts, datasetId, modelId, projectId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the records for the given recording id for this particular customer. The number of records returned are paginated by start and end indexes. In order to reduce IO, At a given time not more than 100 records can be requested
         * @summary Return all the recordings with metrics for this customer
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {boolean} [withMetrics] 
         * @param {string} [ts] 
         * @param {string} [datasetId] 
         * @param {string} [modelId] 
         * @param {string} [projectId] 
         * @param {string} [name] 
         * @param {Array<string>} [userIds] 
         * @param {Array<string>} [excludeUserIds] 
         * @param {Array<string>} [recordIds] 
         * @param {Array<string>} [excludeRecordIds] 
         * @param {boolean} [excludeDemoArtifacts] 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetRecordingsListV1(workspaceId: string, start: number, end: number, withMetrics?: boolean, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<GetRecordingsListResponse> {
            return localVarFp.workspaceGetRecordingsListV1(workspaceId, start, end, withMetrics, ts, datasetId, modelId, projectId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return summary of the recordings grouped by model_class.
         * @param {string} workspaceId 
         * @param {ArtifactStateType} [artifactFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetRecordingsSummaryV1(workspaceId: string, artifactFilter?: ArtifactStateType, options?: any): AxiosPromise<GetRecordingsSummaryResponse> {
            return localVarFp.workspaceGetRecordingsSummaryV1(workspaceId, artifactFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the records for the given recording id for this particular customer. The number of records returned are paginated by start and end indexes.
         * @summary display the records the given recording id.
         * @param {string} workspaceId 
         * @param {string} recordingId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetRecordsForRecordingV1(workspaceId: string, recordingId: string, start: number, end: number, ts?: string, options?: any): AxiosPromise<GetPaginatedRecordsResponse> {
            return localVarFp.workspaceGetRecordsForRecordingV1(workspaceId, recordingId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * Return all the experiments for this user of this workspace
         * @summary Return all the experiments for this user of this workspace
         * @param {string} workspaceId 
         * @param {number} start 
         * @param {number} end 
         * @param {string} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceGetUserExperimentsV1(workspaceId: string, start: number, end: number, ts?: string, options?: any): AxiosPromise<ExperimentRecordingsMetaData> {
            return localVarFp.workspaceGetUserExperimentsV1(workspaceId, start, end, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soft delete the recordings.
         * @param {string} workspaceId 
         * @param {DeleteRecordingRequest} deleteRecordingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceSoftDeleteRecordingsV1(workspaceId: string, deleteRecordingRequest: DeleteRecordingRequest, options?: any): AxiosPromise<StateChangeResponse> {
            return localVarFp.workspaceSoftDeleteRecordingsV1(workspaceId, deleteRecordingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary undo soft delete of recordings
         * @param {string} workspaceId 
         * @param {DeleteRecordingRequest} deleteRecordingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceUndoSoftDeleteRecordingsV1(workspaceId: string, deleteRecordingRequest: DeleteRecordingRequest, options?: any): AxiosPromise<StateChangeResponse> {
            return localVarFp.workspaceUndoSoftDeleteRecordingsV1(workspaceId, deleteRecordingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update editable fields for this given recording_id.The editable field are (note, name): 1. name i.e name of model 2. note i.e description field for this recording
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} recordingId 
         * @param {UpdateEditableEvalFieldsRequest} updateEditableEvalFieldsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspaceUpdateEditableEvalFieldV1(workspaceId: string, projectId: string, recordingId: string, updateEditableEvalFieldsRequest: UpdateEditableEvalFieldsRequest, options?: any): AxiosPromise<UpdateEditableEvalFieldsResponse> {
            return localVarFp.workspaceUpdateEditableEvalFieldV1(workspaceId, projectId, recordingId, updateEditableEvalFieldsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspaceRecordingsApi - object-oriented interface
 * @export
 * @class WorkspaceRecordingsApi
 * @extends {BaseAPI}
 */
export class WorkspaceRecordingsApi extends BaseAPI {
    /**
     * 
     * @summary Delete the list of recordings.
     * @param {string} workspaceId 
     * @param {DeleteRecordingRequest} deleteRecordingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceDeleteRecordingsV1(workspaceId: string, deleteRecordingRequest: DeleteRecordingRequest, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceDeleteRecordingsV1(workspaceId, deleteRecordingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return metric series against epoch for the given key for  the experiment
     * @summary Return metric series against epoch for the given key for  the experiment
     * @param {string} workspaceId 
     * @param {string} runId 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceExperimentMetricV1(workspaceId: string, runId: string, key: string, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceExperimentMetricV1(workspaceId, runId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Disagreements
     * @param {string} workspaceId 
     * @param {number} start 
     * @param {number} end 
     * @param {Array<string>} [recId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetComparisonDisagreementV1(workspaceId: string, start: number, end: number, recId?: Array<string>, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetComparisonDisagreementV1(workspaceId, start, end, recId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the custom metrics computed for the recording  with the given recording id. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
     * @summary Return the custom metrics computed given the recording id.
     * @param {string} workspaceId 
     * @param {string} recordingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetComputedCustomMetricsForRecordingV1(workspaceId: string, recordingId: string, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetComputedCustomMetricsForRecordingV1(workspaceId, recordingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the distributions (actual and predicted) computed for the recording with the given recording id. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
     * @summary Return the distributions computed given the recording id.
     * @param {string} workspaceId 
     * @param {string} recordingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetComputedDistributionsForRecordingV1(workspaceId: string, recordingId: string, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetComputedDistributionsForRecordingV1(workspaceId, recordingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the metrics computed for the recording  with the given recording id. Depending upon the ModelClass of the recording (Classification/Regression/Ranking etc., different metrics are generated.
     * @summary Return the metrics computed given the recording id.
     * @param {string} workspaceId 
     * @param {string} recordingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetComputedMetricsForRecordingV1(workspaceId: string, recordingId: string, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetComputedMetricsForRecordingV1(workspaceId, recordingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the confusion matrix and related metadata in the response. The rows and columns of the matrix are ordered in the order in which all_labels are ordered. e.g if the labels are ordered as =[\'cat\',\'dog\',\'cow\'], the confusion matrix has the form [\'cat\'],[\'dog\'],[\'cow\'] [\'cat][\'dog\'][\'cow\']
     * @summary Return the confusion matrix and related metadata in the response.
     * @param {string} workspaceId 
     * @param {string} recordingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetConfusionMatrixForRecordingV1(workspaceId: string, recordingId: string, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetConfusionMatrixForRecordingV1(workspaceId, recordingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ``` Get all the deleted recordings by model class. Args:     model_class ():     user (WorkspaceUser): object with details of the user who made this request     workspace_id (str):  customer id that uniquely identifies the customer     start (int): start of the requested recording     end (int): end index of the requested recording     request (Request): FastAPI Request object. This is required by the decorator to extract header information     response (Response): FastAPI Response object. This is required by the decorator to set status     ts (str): timestamp value of the last record returned to the previous response. This is a pivot for pagination.     end-start+1 record from this pivot are returned     filters: filters to be applied to the query to filter the recordings             Supported filters are name, user_ids, exclude_user_ids. Detailed documentation available in the             implementation of the dependency `list_query_filters`. Returns:     GetRecordingsListResponse response object with the recordings and the metrics ```
     * @summary Get all the deleted recordings by model class.
     * @param {string} workspaceId 
     * @param {string} modelClass 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetDeletedRecordingsListForModelClassV1(workspaceId: string, modelClass: string, start: number, end: number, ts?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetDeletedRecordingsListForModelClassV1(workspaceId, modelClass, start, end, ts, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return summary of the recordings grouped by model_class.
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetDeletedRecordingsSummaryV1(workspaceId: string, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetDeletedRecordingsSummaryV1(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the disagreements between actual and the inferred in this recording. This helps to understand where the model is making a mistake and what are records miss classified as. Disagreements are only available for classification problems
     * @summary Return the disagreements between the actual and the inferred.
     * @param {string} workspaceId 
     * @param {string} recordingId 
     * @param {number} start 
     * @param {number} end 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetDisagreementsForRecordingV1(workspaceId: string, recordingId: string, start: number, end: number, limit?: number, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetDisagreementsForRecordingV1(workspaceId, recordingId, start, end, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the discordant pair map for this recording. discordant pair map\'s key is the true label and value is a dictionary with key as predicted_labeland value as number of times the true label was classified as this predicted label. This helps to understand how often are records misclassified something other than their true label and identify if pattern if any.
     * @summary Return the discordant pair map for this recording. 
     * @param {string} workspaceId 
     * @param {string} recordingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetDiscordantPairMapForRecordingV1(workspaceId: string, recordingId: string, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetDiscordantPairMapForRecordingV1(workspaceId, recordingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return all the metric keys for this  experiment  this customer
     * @summary Return all the metric keys for this  experiment  this customer
     * @param {string} workspaceId 
     * @param {string} runId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetExperimentKeysV1(workspaceId: string, runId: string, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetExperimentKeysV1(workspaceId, runId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return all the experiments this workspace. This is a paginated API, and supports filters on the experiment name, experiments created by a user(s), experiments created by users other than the specified user(s).
     * @summary Return all the experiments this workspace.
     * @param {string} workspaceId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {string} [datasetId] 
     * @param {string} [modelId] 
     * @param {string} [projectId] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetExperimentsListV1(workspaceId: string, start: number, end: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetExperimentsListV1(workspaceId, start, end, ts, datasetId, modelId, projectId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Filter Disagreement results based on constraints provided in the request body
     * @param {string} workspaceId 
     * @param {string} recordingId 
     * @param {SimpleFilterRequest} simpleFilterRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetFilteredDisagreementForRecordingV1(workspaceId: string, recordingId: string, simpleFilterRequest: SimpleFilterRequest, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetFilteredDisagreementForRecordingV1(workspaceId, recordingId, simpleFilterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the comparison of model recordings at class level for the multi-model comparison matrix
     * @param {string} workspaceId 
     * @param {string} q 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetMultiModelComparisonV1(workspaceId: string, q: string, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetMultiModelComparisonV1(workspaceId, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the meta data associated with a recording.
     * @param {string} workspaceId 
     * @param {string} recordingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetRecordingMetadataV1(workspaceId: string, recordingId: string, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetRecordingMetadataV1(workspaceId, recordingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ``` Display the recordings with metrics for the given workspace. Args:     model_class (str): model class name (e.g. tagging)     user (WorkspaceUser): object with details of the user who made this request     workspace_id (str):  customer id that uniquely identifies the customer     start (int): start of the requested recording     end (int): end index of the requested recording     request (Request): FastAPI Request object. This is required by the decorator to extract header information     response (Response): FastAPI Response object. This is required by the decorator to set status     ts (str): timestamp value of the last record returned to the previous response. This is a pivot for pagination.     end-start+1 record from this pivot are returned     filters: filters to be applied to the query to filter the recordings             Supported filters are name, user_ids, exclude_user_ids. Detailed documentation available in the             implementation of the dependency `list_query_filters`. Returns:     GetRecordingsListResponse: response object with the recordings and the metrics ```
     * @summary Get all the recordings by model class along with their statuses
     * @param {string} workspaceId 
     * @param {string} modelClass 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {string} [datasetId] 
     * @param {string} [modelId] 
     * @param {string} [projectId] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetRecordingsListForModelClassV1(workspaceId: string, modelClass: string, start: number, end: number, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetRecordingsListForModelClassV1(workspaceId, modelClass, start, end, ts, datasetId, modelId, projectId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the records for the given recording id for this particular customer. The number of records returned are paginated by start and end indexes. In order to reduce IO, At a given time not more than 100 records can be requested
     * @summary Return all the recordings with metrics for this customer
     * @param {string} workspaceId 
     * @param {number} start 
     * @param {number} end 
     * @param {boolean} [withMetrics] 
     * @param {string} [ts] 
     * @param {string} [datasetId] 
     * @param {string} [modelId] 
     * @param {string} [projectId] 
     * @param {string} [name] 
     * @param {Array<string>} [userIds] 
     * @param {Array<string>} [excludeUserIds] 
     * @param {Array<string>} [recordIds] 
     * @param {Array<string>} [excludeRecordIds] 
     * @param {boolean} [excludeDemoArtifacts] 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetRecordingsListV1(workspaceId: string, start: number, end: number, withMetrics?: boolean, ts?: string, datasetId?: string, modelId?: string, projectId?: string, name?: string, userIds?: Array<string>, excludeUserIds?: Array<string>, recordIds?: Array<string>, excludeRecordIds?: Array<string>, excludeDemoArtifacts?: boolean, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetRecordingsListV1(workspaceId, start, end, withMetrics, ts, datasetId, modelId, projectId, name, userIds, excludeUserIds, recordIds, excludeRecordIds, excludeDemoArtifacts, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return summary of the recordings grouped by model_class.
     * @param {string} workspaceId 
     * @param {ArtifactStateType} [artifactFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetRecordingsSummaryV1(workspaceId: string, artifactFilter?: ArtifactStateType, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetRecordingsSummaryV1(workspaceId, artifactFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the records for the given recording id for this particular customer. The number of records returned are paginated by start and end indexes.
     * @summary display the records the given recording id.
     * @param {string} workspaceId 
     * @param {string} recordingId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetRecordsForRecordingV1(workspaceId: string, recordingId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetRecordsForRecordingV1(workspaceId, recordingId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return all the experiments for this user of this workspace
     * @summary Return all the experiments for this user of this workspace
     * @param {string} workspaceId 
     * @param {number} start 
     * @param {number} end 
     * @param {string} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceGetUserExperimentsV1(workspaceId: string, start: number, end: number, ts?: string, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceGetUserExperimentsV1(workspaceId, start, end, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soft delete the recordings.
     * @param {string} workspaceId 
     * @param {DeleteRecordingRequest} deleteRecordingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceSoftDeleteRecordingsV1(workspaceId: string, deleteRecordingRequest: DeleteRecordingRequest, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceSoftDeleteRecordingsV1(workspaceId, deleteRecordingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary undo soft delete of recordings
     * @param {string} workspaceId 
     * @param {DeleteRecordingRequest} deleteRecordingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceUndoSoftDeleteRecordingsV1(workspaceId: string, deleteRecordingRequest: DeleteRecordingRequest, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceUndoSoftDeleteRecordingsV1(workspaceId, deleteRecordingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update editable fields for this given recording_id.The editable field are (note, name): 1. name i.e name of model 2. note i.e description field for this recording
     * @param {string} workspaceId 
     * @param {string} projectId 
     * @param {string} recordingId 
     * @param {UpdateEditableEvalFieldsRequest} updateEditableEvalFieldsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRecordingsApi
     */
    public workspaceUpdateEditableEvalFieldV1(workspaceId: string, projectId: string, recordingId: string, updateEditableEvalFieldsRequest: UpdateEditableEvalFieldsRequest, options?: AxiosRequestConfig) {
        return WorkspaceRecordingsApiFp(this.configuration).workspaceUpdateEditableEvalFieldV1(workspaceId, projectId, recordingId, updateEditableEvalFieldsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


