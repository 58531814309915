import { useMediaQuery } from '@mantine/hooks';
import { MantineSize } from '@mantine/styles';
import { useLocation } from 'react-router-dom';
import { useMarkovTheme } from '@/shared/design-system/v2';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { useLayoutContext } from '../../contexts/layout/LayoutContext';
import { AppRoutes, getIsUserProfile } from '../../router/constants';
import { SideBarOptions } from './sidebar-v2/SidebarLinks';
import { NAV_BAR_COLLAPSED_SIZE, getFeatureFamilySidebar } from './sidebar-v3/Sidebar';

export interface SidebarElement {
  id: SideBarOptions;
  path?: AppRoutes;
  label: string;
  onClick: () => void;
  icon: JSX.Element;
}

export interface SidebarGroup {
  properties: {
    grow: boolean;
  };
  elements: SidebarElement[];
}

export const useIsMobileScreen = (breakpoint: MantineSize = 'sm') => {
  const theme = useMarkovTheme();
  const isMobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints[breakpoint]})`);
  return isMobileScreen;
};

// Hook to encapsulate sidebar width calculation, as it is determined by several factors.
export const useSidebarWidth = () => {
  const { sidebarExpanded } = useLayoutContext();
  const { activeSection } = useAppMetadata();
  const { pathname } = useLocation();

  const sidebarWidthBase = 0;
  const sidebarWidthExpanded = NAV_BAR_COLLAPSED_SIZE;

  const secondaryNavbar = getFeatureFamilySidebar(activeSection);
  let sidebarWidth = sidebarExpanded ? sidebarWidthExpanded : sidebarWidthBase;
  const isUserProfile = getIsUserProfile(pathname);
  const shouldExtend = secondaryNavbar !== null && !isUserProfile && sidebarExpanded;
  if (shouldExtend) {
    sidebarWidth += NAV_BAR_COLLAPSED_SIZE;
  }

  return sidebarWidth;
};
