import {
  Icon3dCubeSphere,
  IconActivity,
  IconArchive,
  IconBolt,
  IconBrandSpeedtest,
  IconCloudDataConnection,
  IconDatabase,
  IconEdit,
  IconFolderBolt,
  IconHome,
  IconListNumbers,
  IconSend2,
  IconTags,
  IconTestPipe,
  IconVersions,
  IconWorldStar,
} from '@tabler/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ampli } from '@/shared/ampli';
import { IconDashboardNew, IconDocs } from '@/shared/design-system';
import { Box, Horizontal, Navbar, Overlay, useMarkovTheme } from '@/shared/design-system/v2';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { useLayoutContext } from '../../../contexts/layout/LayoutContext';
import { MARKOVML_DOCS } from '../../../developer-docs/links';
import { AppRoutes, FeatureFamily, getIsUserProfile } from '../../../router/constants';
import { useAbsoluteRoutes } from '../../../router/hooks';
import { APP_HEADER_HEIGHT } from '../../app-header/AppHeader';
import { DATA_ANALYTICS_NAME } from '../../data-analytics/util';
import {
  SidebarColorScheme,
  useSidebarColors,
  useSidebarStyles,
} from '../sidebar-v2/Sidebar.style';
import { SideBarOptions, SidebarLinks } from '../sidebar-v2/SidebarLinks';
import { SidebarGroup, useIsMobileScreen, useSidebarWidth } from '../util';
import { PrimaryNavbar } from './PrimaryNavbar';

export const NAV_BAR_EXPANDED_SIZE = 208;
export const NAV_BAR_COLLAPSED_SIZE = 56;

const useSidebarNavigate = () => {
  const navigate = useNavigate();
  const isMobileScreen = useIsMobileScreen();
  const { setSidebarExpanded } = useLayoutContext();

  const sidebarNavigate = useCallback(
    (link: string) => {
      if (isMobileScreen) {
        setSidebarExpanded(false);
      }

      navigate(link);
    },
    [isMobileScreen],
  );

  return sidebarNavigate;
};

interface SidebarShellProps {
  groups: SidebarGroup[];
  colorScheme?: SidebarColorScheme;
  showSeparator?: boolean;
}

const SidebarShell = ({ groups, colorScheme, showSeparator }: SidebarShellProps) => {
  const theme = useMarkovTheme();
  const { bg: bgColor } = useSidebarColors(colorScheme);
  const { sidebarExpanded, setSidebarMouseEnter, sidebarMouseEnter } = useLayoutContext();
  const { classes } = useSidebarStyles();

  const handleMouseEnter = () => {
    setSidebarMouseEnter(true);
  };

  const handleMouseLeave = () => {
    setSidebarMouseEnter(false);
  };

  const navBarSize = NAV_BAR_COLLAPSED_SIZE;

  return (
    <Navbar
      p={0}
      hiddenBreakpoint="sm"
      hidden={!sidebarExpanded}
      withBorder
      width={{
        base: sidebarMouseEnter ? NAV_BAR_EXPANDED_SIZE : navBarSize,
        xs: navBarSize,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      pos="static"
    >
      <Box
        h="100%"
        bg={bgColor}
        w={sidebarMouseEnter ? NAV_BAR_EXPANDED_SIZE : navBarSize}
        className={classes.navSection}
      >
        {groups.map(({ properties, elements }, index) => (
          <Navbar.Section
            key={`navGroup-${index}`}
            grow={properties.grow}
            sx={{ borderBottom: showSeparator ? `1px solid ${theme.colors.gray[3]}` : 'none' }}
            mx="sm"
          >
            {elements.map((element, idx) => (
              <SidebarLinks key={`element-${idx}`} colorScheme={colorScheme} {...element} />
            ))}
          </Navbar.Section>
        ))}
      </Box>
    </Navbar>
  );
};

const DataStudioSidebar = () => {
  const navigate = useSidebarNavigate();
  const {
    getDataStudioHomeRoute,
    getModelAppsRoute,
    getDatasetsRoute,
    getDataSourcesRoute,
    getDataAnalyticsRoute,
    getDataLabelingRoute,
    getRunsRoute,
  } = useAbsoluteRoutes();

  const { featureConnectors, featureTextToSql, featureDataStudio } = useFlags();

  const sidebarGroups: SidebarGroup[] = [
    {
      properties: {
        grow: true,
      },
      elements: [
        {
          id: SideBarOptions.HOME,
          path: AppRoutes.BASE_ROUTE,
          label: 'Home',
          onClick: () => {
            ampli.navbarDashboardClicked();
            navigate(getDataStudioHomeRoute());
          },
          icon: <IconHome />,
        },
        {
          id: SideBarOptions.DATASETS,
          path: AppRoutes.DATASETS,
          label: 'Data Insights',
          onClick: () => {
            ampli.navbarDatasetsClicked();
            navigate(getDatasetsRoute());
          },
          icon: <IconDatabase />,
        },
        //put Data Analytics in here when data studio feature flag is on, else it goes in primary nav bar
        ...(featureTextToSql && featureDataStudio
          ? [
              {
                id: SideBarOptions.DATA_ANALYTICS,
                path: AppRoutes.DATA_ANALYTICS,
                label: DATA_ANALYTICS_NAME,
                onClick: () => {
                  navigate(getDataAnalyticsRoute());
                },
                icon: <IconActivity />,
              },
            ]
          : []),
        {
          id: SideBarOptions.DATA_LABELING,
          path: AppRoutes.DATA_LABELING,
          label: 'Data Labeling',
          onClick: () => {
            navigate(getDataLabelingRoute());
          },
          icon: <IconTags />,
        },
        {
          id: SideBarOptions.MODEL_APPS,
          path: AppRoutes.MODEL_APPS,
          label: 'Model Apps',
          onClick: () => {
            // TODO: Add this event in amplitude
            // ampli.navbarModelAppsClicked();
            navigate(getModelAppsRoute());
          },
          icon: <IconSend2 />,
        },
      ],
    },
    {
      properties: {
        grow: false,
      },
      elements: [
        //put data sources in here when data studio feature flag is on, else it goes in primary nav bar
        ...(featureConnectors && featureDataStudio
          ? [
              {
                id: SideBarOptions.DATA_SOURCES,
                path: AppRoutes.DATA_SOURCES,
                label: 'Data Sources',
                onClick: () => {
                  navigate(getDataSourcesRoute());
                },
                icon: <IconCloudDataConnection />,
              },
            ]
          : []),
      ],
    },
  ];

  return <SidebarShell groups={sidebarGroups} colorScheme="data-studio" />;
};

const ModelStudioSidebar = () => {
  const navigate = useSidebarNavigate();
  const {
    getModelStudioHomeRoute,
    getProjectsRoute,
    getModelsRoute,
    getExperimentsRoute,
    getEvaluationsRoute,
  } = useAbsoluteRoutes();

  const sidebarGroups: SidebarGroup[] = [
    {
      properties: {
        grow: true,
      },
      elements: [
        {
          id: SideBarOptions.HOME,
          path: AppRoutes.MODEL_STUDIO_HOME,
          label: 'Home',
          onClick: () => {
            // TODO: Add separate model studio dashboard clicked event
            // ampli.navbarDashboardClicked();
            navigate(getModelStudioHomeRoute());
          },
          icon: <IconHome />,
        },
        {
          id: SideBarOptions.PROJECTS,
          path: AppRoutes.PROJECTS,
          label: 'Projects',
          onClick: () => {
            ampli.navbarProjectsClicked();
            navigate(getProjectsRoute());
          },
          icon: <IconFolderBolt />,
        },
        {
          id: SideBarOptions.MODELS,
          path: AppRoutes.MODELS,
          label: 'Models',
          onClick: () => {
            ampli.navbarModelClicked();
            navigate(getModelsRoute());
          },
          icon: <Icon3dCubeSphere />,
        },
        {
          id: SideBarOptions.EXPERIMENTS,
          path: AppRoutes.EXPERIMENTS,
          label: 'Experiments',
          onClick: () => {
            ampli.navbarExperimentClicked();
            navigate(getExperimentsRoute());
          },
          icon: <IconTestPipe />,
        },
        {
          id: SideBarOptions.EVALUATION,
          path: AppRoutes.EVALUATIONS,
          label: 'Evaluations',
          onClick: () => {
            ampli.navbarEvaluationClicked();
            navigate(getEvaluationsRoute());
          },
          icon: <IconBrandSpeedtest />,
        },
      ],
    },
  ];

  return <SidebarShell groups={sidebarGroups} colorScheme="model-studio" />;
};

const WorkflowSidebar = () => {
  const navigate = useSidebarNavigate();
  const {
    getWorkflowsHomeRoute,
    getWorkflowsListRoute,
    getWorkflowsAppsRoute,
    getWorkflowsTemplatesRoute,
    getWorkflowsArchivedRoute,
  } = useAbsoluteRoutes();

  const sidebarGroups: SidebarGroup[] = [
    {
      properties: {
        grow: false,
      },
      elements: [
        {
          id: SideBarOptions.HOME,
          path: AppRoutes.WORKFLOWS_HOME,
          label: 'Home',
          onClick: () => {
            navigate(getWorkflowsHomeRoute());
          },
          icon: <IconHome strokeWidth="1.5px" />,
        },
      ],
    },
    {
      properties: {
        grow: true,
      },
      elements: [
        {
          id: SideBarOptions.WORKFLOWS_APPS,
          path: AppRoutes.WORKFLOW_APPS_LIST,
          label: 'AI Apps',
          onClick: () => {
            navigate(getWorkflowsAppsRoute());
          },
          icon: <IconBolt strokeWidth="1.5px" />,
        },
        {
          id: SideBarOptions.WORKFLOWS_TEMPLATES,
          path: AppRoutes.WORKFLOWS_TEMPLATES,
          label: 'Templates',
          onClick: () => {
            navigate(getWorkflowsTemplatesRoute());
          },
          icon: <IconVersions strokeWidth="1.5px" />,
        },
        {
          id: SideBarOptions.WORKFLOWS_LIST,
          path: AppRoutes.WORKFLOWS_LIST,
          label: 'All workflows',
          onClick: () => {
            navigate(getWorkflowsListRoute());
          },
          icon: <IconListNumbers strokeWidth="1.5px" />,
        },
      ],
    },
    {
      properties: {
        grow: false,
      },
      elements: [
        {
          id: SideBarOptions.WORKFLOWS_ARCHIVED,
          path: AppRoutes.WORKFLOWS_ARCHIVED,
          label: 'Archived',
          onClick: () => {
            navigate(getWorkflowsArchivedRoute());
          },
          icon: <IconArchive strokeWidth="1.5px" />,
        },
      ],
    },
  ];

  return <SidebarShell groups={sidebarGroups} colorScheme="data-studio" showSeparator />;
};

const AppBuilderSidebar = () => {
  const navigate = useSidebarNavigate();
  const { getAppBuilderRoute, getAiAppsRoute } = useAbsoluteRoutes();

  const sidebarGroups: SidebarGroup[] = [
    {
      properties: {
        grow: true,
      },
      elements: [
        {
          id: SideBarOptions.AI_APPS,
          path: AppRoutes.AI_APPS,
          label: 'Live apps',
          onClick: () => {
            navigate(getAiAppsRoute());
          },
          icon: <IconWorldStar strokeWidth="1.5px" />,
        },
        {
          id: SideBarOptions.APP_BUILDER,
          path: AppRoutes.APP_BUILDER,
          label: 'Builder',
          onClick: () => {
            navigate(getAppBuilderRoute());
          },
          icon: <IconEdit strokeWidth="1.5px" />,
        },
      ],
    },
  ];

  return <SidebarShell groups={sidebarGroups} colorScheme="data-studio" />;
};

// NOTE: Currently user profile page is outside the context of a workspace, so links to other workspace
// pages can't be constructed (requires active workspace). We may want to revisit this later.
const UserProfileSidebar = () => {
  const navigate = useSidebarNavigate();
  const { getWorkspaceRoute } = useAbsoluteRoutes();

  const sidebarGroups: SidebarGroup[] = [
    {
      properties: {
        grow: true,
      },
      elements: [
        {
          id: SideBarOptions.HOME,
          path: AppRoutes.BASE_ROUTE,
          label: 'Home',
          onClick: () => {
            ampli.navbarDashboardClicked();
            navigate(getWorkspaceRoute());
          },
          icon: <IconDashboardNew />,
        },
      ],
    },
    {
      properties: {
        grow: false,
      },
      elements: [
        {
          id: SideBarOptions.HELP_DOCS,
          label: 'Document center',
          onClick: () => {
            ampli.docsButtonClicked();
            navigate(MARKOVML_DOCS);
          },
          icon: <IconDocs />,
        },
      ],
    },
  ];

  return <SidebarShell groups={sidebarGroups} />;
};

const featureFamilySidebarMap: Record<FeatureFamily, JSX.Element | null> = {
  [FeatureFamily.GLOBAL_HOME]: null,
  [FeatureFamily.DATA_STUDIO]: <DataStudioSidebar />,
  [FeatureFamily.MODEL_STUDIO]: <ModelStudioSidebar />,
  [FeatureFamily.WORKFLOWS]: <WorkflowSidebar />,
  [FeatureFamily.SNIPPETS]: null,
  [FeatureFamily.APP_STORE]: null,
  [FeatureFamily.APP_BUILDER]: <AppBuilderSidebar />,
  [FeatureFamily.SETTINGS]: null,
  [FeatureFamily.DATA_ANALYTICS]: null,
  [FeatureFamily.DATA_SOURCES]: null,
};

export const getFeatureFamilySidebar = (family: FeatureFamily) => featureFamilySidebarMap[family];

export const SidebarV3 = (): JSX.Element => {
  const { sidebarExpanded, setSidebarExpanded } = useLayoutContext();
  const { activeSection } = useAppMetadata();
  const { pathname } = useLocation();

  const isMobileScreen = useIsMobileScreen();
  const sidebarWidth = useSidebarWidth();

  // User profile page needs to be handled separately for now
  const isUserProfile = getIsUserProfile(pathname);
  const primaryNavbar = isUserProfile ? <UserProfileSidebar /> : <PrimaryNavbar />;
  const secondaryNavbar = isUserProfile ? null : getFeatureFamilySidebar(activeSection);

  return (
    <>
      {/* z-index needed to keep expanded sidebar above page content/overlay */}
      <Horizontal spacing={0} pos="fixed" sx={{ top: APP_HEADER_HEIGHT, left: 0, zIndex: 201 }}>
        {/* Expanded state toggles primary navbar; secondary navbar is always shown (if present) */}
        {sidebarExpanded && primaryNavbar}
        {sidebarExpanded && secondaryNavbar}
      </Horizontal>
      {isMobileScreen && (
        <Overlay
          display={sidebarExpanded ? 'block' : 'none'}
          w={`calc(100% - ${sidebarWidth}px)`}
          top={APP_HEADER_HEIGHT}
          left={sidebarWidth}
          onClick={() => setSidebarExpanded(false)}
        />
      )}
    </>
  );
};
