import { PropsWithChildren, ReactNode } from 'react';
import { Popover, Text } from '@/shared/design-system/v2';

interface MoreInfoProps {
  popoverTarget?: ReactNode;
}

export const MoreInfoPopover = ({ popoverTarget, children }: PropsWithChildren<MoreInfoProps>) => {
  if (!children) return null;
  return (
    <Popover
      width={400}
      trapFocus
      position="bottom-start"
      shadow="xl"
      closeOnEscape={false}
      closeOnClickOutside={true}
    >
      <Popover.Target>
        {popoverTarget ?? (
          <Text variant="small02" color="gray.6">
            More Info
          </Text>
        )}
      </Popover.Target>
      <Popover.Dropdown px="lg" pb="xl">
        {children}
      </Popover.Dropdown>
    </Popover>
  );
};
