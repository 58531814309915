import { createQueryParams } from '@/shared/lib/router';
import { RegistrationUserIntent } from '../components/dataset-registration/util';
import { TabComponentsType } from '../components/settings/Settings';
import { useAppMetadata } from '../contexts/app-metadata/AppMetadata';
import { AppRoutes, FeatureFamily, getRoute } from './constants';

// Hook to provide absolute routes which can be used from any page in the app. This encapsulates
// retrieving the active workspace and centralizes routing logic to simplify usage in components.
export const useAbsoluteRoutes = () => {
  const { workspaceId } = useAppMetadata();

  const projects = getRoute(AppRoutes.PROJECTS);
  const snippets = getRoute(AppRoutes.SNIPPETS);
  const models = getRoute(AppRoutes.MODELS);
  const modelApps = getRoute(AppRoutes.MODEL_APPS);
  const modelAppsMizzen = getRoute(AppRoutes.MODEL_APPS_MIZZEN);
  const modelAppDrift = getRoute(AppRoutes.MODEL_APP_DRIFT);
  const modelRegistry = `${models}/${getRoute(AppRoutes.MODEL_REGISTRY)}`;
  const experiments = getRoute(AppRoutes.EXPERIMENTS);
  const experimentComparison = getRoute(AppRoutes.COMPARE_EXPERIMENTS);
  const evaluations = getRoute(AppRoutes.EVALUATIONS);
  const evaluationModelComparison = getRoute(AppRoutes.COMPARE_EVALUATIONS_MULTIMODEL);
  const insights = getRoute(AppRoutes.INSIGHTS);
  const dataViews = getRoute(AppRoutes.DATA_VIEWS);
  const dataSources = getRoute(AppRoutes.DATA_SOURCES);
  const connectors = getRoute(AppRoutes.CONNECTORS);
  const datasets = getRoute(AppRoutes.DATASETS);
  const datasetRegistration = getRoute(AppRoutes.DATASETS_REGISTRATION);
  const datasetComparison = getRoute(AppRoutes.COMPARE_DATASETS);
  const dataLabeling = getRoute(AppRoutes.DATA_LABELING);
  const manualRelabeling = getRoute(AppRoutes.MANUAL_RELABELING);
  const ruleBasedRelabeling = getRoute(AppRoutes.RULE_BASED_RELABELING);
  const subsetRelabeling = getRoute(AppRoutes.SUBSET_RELABELING);
  const chatWithDataset = getRoute(AppRoutes.CHAT_WITH_DATASET);
  const dataAnalytics = getRoute(AppRoutes.DATA_ANALYTICS);
  const workflowsHome = getRoute(AppRoutes.WORKFLOWS_HOME);
  const workflowsList = getRoute(AppRoutes.WORKFLOWS_LIST);
  const workflowsAppsList = getRoute(AppRoutes.WORKFLOW_APPS_LIST);
  const workflowsAppsRuns = getRoute(AppRoutes.WORKFLOW_APPS_RUNS);
  const workflowsTemplates = getRoute(AppRoutes.WORKFLOWS_TEMPLATES);
  const workflowsArchived = getRoute(AppRoutes.WORKFLOWS_ARCHIVED);
  const workflowBuilder = getRoute(AppRoutes.WORKFLOW_BUILDER);
  const workflowRuns = getRoute(AppRoutes.WORKFLOW_RUNS);
  const runs = getRoute(AppRoutes.RUNS);
  const settings = getRoute(AppRoutes.SETTINGS);
  const subscriptionPlans = getRoute(AppRoutes.SUBSCRIPTION_PLANS);
  const dataStudioHome = getRoute(AppRoutes.DATA_STUDIO_HOME);
  const modelStudioHome = getRoute(AppRoutes.MODEL_STUDIO_HOME);
  const docSearch = getRoute(AppRoutes.DOCUMENT_SEARCH);
  const appStore = getRoute(AppRoutes.APP_STORE);
  const appBuilder = getRoute(AppRoutes.APP_BUILDER);
  const aiApps = getRoute(AppRoutes.AI_APPS);
  const appStoreRoutes = {
    copyEdit: getRoute(AppRoutes.COPY_EDIT),
    summarization: getRoute(AppRoutes.SUMMARIZATION),
    chatWithMarko: getRoute(AppRoutes.CHAT_WITH_MARKO),
    docQnA: getRoute(AppRoutes.DOC_QNA),
    retailAi: getRoute(AppRoutes.RETAIL_AI),
  };

  const copyEditDetailsRoutes = {
    versionHistory: getRoute(AppRoutes.COPY_EDIT_VERSION_HISTORY),
    reviewMode: getRoute(AppRoutes.COPY_EDIT_REVIEW_MODE),
  };

  const withWorkspace = (restPath: string) => `/${workspaceId}/${restPath}`;
  const withProject = (projectId: string, restPath: string) =>
    withWorkspace(`${projects}/${projectId}/${restPath}`);

  return {
    getWorkspaceRoute: (wspId: string = workspaceId) => `/${wspId}`,
    getDataStudioHomeRoute: () => withWorkspace(dataStudioHome),
    getModelStudioHomeRoute: () => withWorkspace(modelStudioHome),
    getInsightsRoute: () => withWorkspace(insights),
    getInsightsDetailsRoute: (
      dataViewId: string,
      params?: Partial<{
        tab: string;
        taskletId: string;
        panelTab: string;
      }>,
    ) => withWorkspace(`${insights}/${dataViewId}` + createQueryParams(params)),
    getDataViewsRoute: () => withWorkspace(dataViews),
    getDataViewDetailsRoute: (dataViewId: string) => withWorkspace(`${dataViews}/${dataViewId}`),
    getDataSourcesRoute: (tab?: string) => withWorkspace(dataSources + createQueryParams({ tab })),
    getConnectorDetailsRoute: (connectorId: string) =>
      withWorkspace(`${dataSources}/${connectors}/${connectorId}`),
    getDatasetsRoute: () => withWorkspace(datasets),
    getDatasetDetailsRoute: (
      datasetId: string,
      tab?: string,
      taskletId?: string,
      panelTab?: string,
    ) =>
      withWorkspace(`${datasets}/${datasetId}` + createQueryParams({ tab, taskletId, panelTab })),
    getDataLabelingRoute: () => withWorkspace(dataLabeling),
    getManualRelabelingRoute: (datasetId: string, operationId?: string) =>
      withWorkspace(
        `${datasets}/${datasetId}/${manualRelabeling}` + createQueryParams({ operationId }),
      ),
    getRuleBasedRelabelingRoute: (datasetId: string, operationId: string) =>
      withWorkspace(
        `${datasets}/${datasetId}/${ruleBasedRelabeling}` + createQueryParams({ operationId }),
      ),
    getSubsetRelabelingRoute: (datasetId: string, operationId: string) =>
      withWorkspace(
        `${datasets}/${datasetId}/${subsetRelabeling}` + createQueryParams({ operationId }),
      ),
    getChatWithDatasetRoute: (datasetId: string) =>
      withWorkspace(`${datasets}/${datasetId}/${chatWithDataset}`),
    getDataAnalyticsRoute: () => withWorkspace(dataAnalytics),
    getDataAnalyticsDetailsRoute: (resourceId: string) =>
      withWorkspace(`${dataAnalytics}/${resourceId}`),
    getDatasetRegistrationRoute: (intent?: RegistrationUserIntent) =>
      withWorkspace(`${datasets}/${datasetRegistration}` + createQueryParams({ intent })),
    getDatasetComparisonRoute: () => withWorkspace(`${datasets}/${datasetComparison}`),
    getProjectsRoute: () => withWorkspace(projects),
    getProjectModelsRoute: (projectId: string) => withProject(projectId, models),
    getProjectsExperimentsRoute: (projectId: string) => withProject(projectId, experiments),
    getProjectsEvaluationsRoute: (projectId: string) => withProject(projectId, evaluations),
    getProjectDetailsRoute: (projectId: string) => withWorkspace(`${projects}/${projectId}`),

    getModelsRoute: () => withWorkspace(models),
    getModelAppsRoute: () => withWorkspace(modelApps),
    getModelAppDetailsRoute: (modelAppId: string) => withWorkspace(`${modelApps}/${modelAppId}`),
    getModelAppRelabelRoute: (modelAppId: string, inferenceId: string) =>
      withWorkspace(`${modelApps}/${modelAppId}/relabel/${inferenceId}`),
    getModelAppDriftRoute: (driftResultId: string) =>
      withWorkspace(`${modelApps}/${modelAppDrift.replace(':driftResultId', driftResultId)}`),
    // Model details route always requires projectId
    // Experiments and evaluations (recordings) will soon also require projectId
    getModelDetailsRoute: (projectId: string, modelId: string) =>
      withProject(projectId, `${models}/${modelId}`),
    getModelRegistryRoute: () => withWorkspace(modelRegistry),
    getModelRegistryDetails: (registryId: string) =>
      withWorkspace(`${modelRegistry}/${registryId}`),

    // Mizzen
    getModelAppMizzenRoute: (modelAppId: string) =>
      withWorkspace(`${modelApps}/${modelAppsMizzen.replace(':modelAppId', modelAppId)}`),

    // Workflows
    getWorkflowsHomeRoute: () => withWorkspace(workflowsHome),
    getWorkflowsListRoute: () => withWorkspace(`${workflowsHome}/${workflowsList}`),
    getWorkflowsAppsRoute: () => withWorkspace(`${workflowsHome}/${workflowsAppsList}`),
    getWorkflowsAppDetailsRoute: (appId: string) =>
      withWorkspace(`${workflowsHome}/${workflowsAppsList}/${appId}`),
    getWorkflowsAppRunsRoute: (appId: string) =>
      withWorkspace(`${workflowsHome}/${workflowsAppsList}/${appId}/${workflowsAppsRuns}`),
    getWorkflowsAppRunDetailRoute: (appId: string, runId: string) =>
      withWorkspace(`${workflowsHome}/${workflowsAppsList}/${appId}/${workflowsAppsRuns}/${runId}`),
    getWorkflowsTemplatesRoute: () => withWorkspace(`${workflowsHome}/${workflowsTemplates}`),
    getWorkflowsArchivedRoute: () => withWorkspace(`${workflowsHome}/${workflowsArchived}`),
    getWorkflowDetailsRoute: (workflowId: string) =>
      withWorkspace(`${workflowsHome}/${workflowId}`),
    getWorkflowBuilderRoute: (workflowId: string) =>
      withWorkspace(`${workflowsHome}/${workflowId}/${workflowBuilder}`),
    getWorkflowRunsRoute: (workflowId: string) =>
      withWorkspace(`${workflowsHome}/${workflowId}/${workflowRuns}`),
    getWorkflowRunDetailsRoute: (workflowId: string, runId: string) =>
      withWorkspace(`${workflowsHome}/${workflowId}/${workflowRuns}/${runId}`),

    getExperimentsRoute: () => withWorkspace(experiments),
    getExperimentDetailsRoute: (projectId: string, experimentId: string) =>
      withProject(projectId, `${experiments}/${experimentId}`),
    getExperimentComparisonRoute: (experimentIds: string[]) => {
      const qs = experimentIds.map(id => `id=${id}`).join('&');
      return withWorkspace(`${experiments}/${experimentComparison}?${qs}`);
    },
    getEvaluationsRoute: () => withWorkspace(evaluations),
    getEvaluationDetailsRoute: (evaluationId: string) =>
      withWorkspace(`${evaluations}/${evaluationId}`),
    getEvaluationModelComparisonRoute: (evaluationIds: string[]) => {
      const qs = evaluationIds.map(id => `id=${id}`).join('&');
      return withWorkspace(`${evaluations}/${evaluationModelComparison}?${qs}`);
    },
    getRunsRoute: () => withWorkspace(runs),
    getSettingsRoute: (tabName = TabComponentsType.ABOUT) =>
      withWorkspace(`${settings}?tab=${tabName}`),
    getSubscriptionPlansRoute: () => withWorkspace(subscriptionPlans),

    getSnippetsRoute: () => withWorkspace(snippets),
    getSnippetDetailRoute: (snippetId: string) => withWorkspace(`${snippets}/${snippetId}`),

    getDocSearchRoute: (appId: string) => withWorkspace(docSearch.replace(':appId', appId)),

    getAppStoreRoute: () => withWorkspace(appStore),
    getAiAppsRoute: () => withWorkspace(aiApps),
    getAiAppsDetailsRoute: (appId: string) => withWorkspace(`${aiApps}/${appId}`),
    getAppBuilderRoute: () => withWorkspace(`${aiApps}/${appBuilder}`),
    getAppBuilderDetailsRoute: (appId: string) => withWorkspace(`${aiApps}/${appBuilder}/${appId}`),

    // Copy Edit
    getCopyEditRoute: () => withWorkspace(`${appStore}/${appStoreRoutes.copyEdit}`),
    getCopyEditProjectRoute: (projectId: string) =>
      withWorkspace(`${appStore}/${appStoreRoutes.copyEdit}/${projectId}`),
    getCopyEditDetailRoute: (documentId: string, projectId: string) =>
      withWorkspace(`${appStore}/${appStoreRoutes.copyEdit}/${projectId}/${documentId}`),
    getCopyEditVersionHistoryRoute: (documentId: string, projectId: string) =>
      withWorkspace(
        `${appStore}/${appStoreRoutes.copyEdit}/${projectId}/${documentId}/${copyEditDetailsRoutes.versionHistory}`,
      ),
    getCopyEditReviewModeRoute: (documentId: string, projectId: string) =>
      withWorkspace(
        `${appStore}/${appStoreRoutes.copyEdit}/${projectId}/${documentId}/${copyEditDetailsRoutes.reviewMode}`,
      ),

    getSummarizationRoute: () => withWorkspace(`${appStore}/${appStoreRoutes.summarization}`),
    getSummarizationDetailRoute: (documentId: string) =>
      withWorkspace(`${appStore}/${appStoreRoutes.summarization}/${documentId}`),
    getChatWithMarkoRoute: () => withWorkspace(`${appStore}/${appStoreRoutes.chatWithMarko}`),
    getDocQnARoute: () => withWorkspace(`${appStore}/${appStoreRoutes.docQnA}`),
    getDocQnADetailRoute: (documentId = '') =>
      withWorkspace(`${appStore}/${appStoreRoutes.docQnA}/${documentId}`),
    getRetailAiRoute: () => withWorkspace(`${appStore}/${appStoreRoutes.retailAi}`),
    getRetailAiCategoryListRoute: (projectId: string) =>
      withWorkspace(`${appStore}/${appStoreRoutes.retailAi}/${projectId}`),
    getRetailAiProductListRoute: (projectId: string, categoryId: string) =>
      withWorkspace(`${appStore}/${appStoreRoutes.retailAi}/${projectId}/${categoryId}`),
    getRetailAiProductDetailRoute: (projectId: string, categoryId: string, productId: string) =>
      withWorkspace(
        `${appStore}/${appStoreRoutes.retailAi}/${projectId}/${categoryId}/${productId}`,
      ),
  };
};

export const useGetHomeRoute = () => {
  const {
    getWorkspaceRoute,
    getDataStudioHomeRoute,
    getModelStudioHomeRoute,
    getAppStoreRoute,
    getWorkflowsHomeRoute,
    getSnippetsRoute,
    getAiAppsRoute,
    getSettingsRoute,
    getDataAnalyticsRoute,
    getDataSourcesRoute,
  } = useAbsoluteRoutes();

  const homeRoutes: Record<FeatureFamily, string> = {
    [FeatureFamily.GLOBAL_HOME]: getWorkspaceRoute(),
    [FeatureFamily.DATA_STUDIO]: getDataStudioHomeRoute(),
    [FeatureFamily.MODEL_STUDIO]: getModelStudioHomeRoute(),
    [FeatureFamily.WORKFLOWS]: getWorkflowsHomeRoute(),
    [FeatureFamily.SNIPPETS]: getSnippetsRoute(),
    [FeatureFamily.APP_STORE]: getAppStoreRoute(),
    [FeatureFamily.APP_BUILDER]: getAiAppsRoute(),
    [FeatureFamily.SETTINGS]: getSettingsRoute(),
    [FeatureFamily.DATA_ANALYTICS]: getDataAnalyticsRoute(),
    [FeatureFamily.DATA_SOURCES]: getDataSourcesRoute(),
  };

  const getHomeRoute = (appSection: FeatureFamily) => homeRoutes[appSection];
  return getHomeRoute;
};
