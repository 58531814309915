import first from 'lodash/first';
import { Box, Button, Dropzone, FileWithPath, Text, Vertical } from '@/shared/design-system/v2';
import { getDropzoneAccept, getSupportedFilesLabel } from './util';

export interface FileUploadInputProps {
  supportedFilesTypes?: string[];
  maximumFileSize?: string;
  onFileDrop: (file: FileWithPath) => void;
}

export const FileUploadInput = ({
  supportedFilesTypes,
  maximumFileSize,
  onFileDrop,
}: FileUploadInputProps) => {
  const handleFileOnDrop = (files: FileWithPath[]) => {
    const file = first(files);

    if (file) {
      onFileDrop(file);
    }
  };

  return (
    <Box pos="relative">
      <Dropzone
        bg="gray.0"
        accept={getDropzoneAccept(supportedFilesTypes)}
        onDrop={handleFileOnDrop}
        py={32}
      >
        <Vertical align="center" spacing="sm">
          {maximumFileSize && (
            <Text variant="bodyShort03" color="gray.6">
              Maximum file size: {maximumFileSize}
            </Text>
          )}
          <Vertical spacing={0} align="center">
            <Text variant="subTitle04" color="gray.7">
              Drag and drop to upload your file
            </Text>
            <Text variant="subTitle04" color="gray.7">
              or
            </Text>
          </Vertical>
          <Button variant="outline">Choose a file</Button>
          {supportedFilesTypes && (
            <Text variant="bodyShort03" color="gray.6">
              {getSupportedFilesLabel(supportedFilesTypes)}
            </Text>
          )}
        </Vertical>
      </Dropzone>
    </Box>
  );
};
